import { anchorLinkRegex } from '@global/Utils/Regex/Regex';

export const additionalConsentValidator = (consent: string) => {
  if (!consent) return true;

  const fullDescription = consent.replace(/&gt;/g, '>').replace(/&lt;/g, '<');

  if (/<a.+\/a>/i.test(fullDescription))
    return anchorLinkRegex.test(fullDescription);

  return true;
};
