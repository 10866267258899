import React, { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import useHandleClose from '../../hooks/useHandleClose';
import useOpenModal from '../../hooks/useOpenModal';

import { useOverviewStore } from '../../Overview.store';
import { getColor } from '../../OverviewDataCard/OverviewDataCard.utils';
import { SelectedPoint } from '../../Overview.type';

const useCustomLegendService = () => {
  const { overviewDataPoints } = useOverviewStore(
    useShallow(state => ({
      overviewDataPoints: state.overviewDataPoints,
    }))
  );
  const { handleClose } = useHandleClose();
  const [selectedDataPoints, setSelectedDataPoints] = useState([]);
  const { openModal } = useOpenModal();

  useEffect(() => {
    const newDataPoints = overviewDataPoints?.selectedDataPoints?.map(
      (dataPoint: SelectedPoint, index: number) => ({
        color: getColor(dataPoint, index),
        dataPointType: dataPoint.dataPointType,
        dataPointName: dataPoint.dataPointName,
        maxScore: dataPoint.maxScore,
        dataPointDescription: dataPoint.dataPointDescription,
        scoreId: dataPoint.scoreId,
        scoreTypeId: dataPoint.scoreTypeId,
        healthDataKey: dataPoint.healthDataKey,
        dataPointUnit: dataPoint.dataPointUnit,
      })
    );
    setSelectedDataPoints(newDataPoints || []);
  }, [overviewDataPoints?.selectedDataPoints]);

  const shouldGetGraphViewData = selectedDataPoints.length > 1;

  return {
    selectedDataPoints,
    handleClose,
    openModal,
    shouldGetGraphViewData,
  };
};

export default useCustomLegendService;
