import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Icon, Button } from '@ui-common-files/components';
import { ButtonSize, ButtonVariant } from '@ui-common-files/utils';

const QrCodeOptions = ({ onEdit, onAssign }) => {
    const { t } = useTranslation();
    return (
        <>
            <Flex className="scanning-qrcode__spacer">
                <span>
                    <Icon name="info" size={20} />
                </span>
                <Box className="scanning-qrcode__text_info">
                    {t('qrCode.qrcodeDeactivatedInfo')}
                </Box>
            </Flex>

            <Flex className="scanning-qrcode__spacer">
                <span>
                    <Icon
                        name="edit"
                        size={40}
                    />
                </span>
                <Box>
                    <Box className="scanning-qrcode__text_info">
                        {t('qrCode.qrcodeRecurrenceEdit')}
                        <Button
                            variant={ButtonVariant.LINK}
                            onClick={onEdit}
                            label={t('qrCode.editCarepathwayButton')}
                        />
                    </Box>
                </Box>
            </Flex>

            <Box className="lineBetweenQrToggleContainers">
                <Box className="lineBetweenToggleContainers__half" />
                <span className="lineBetweenToggleContainers__or">
                    {t('qrCode.or')}
                </span>
                <Box className="lineBetweenToggleContainers__half" />
            </Box>

            <Flex className="scanning-qrcode__spacer">
                <span>
                    <Icon name="assign" size={40} />
                </span>
                <Box>
                    <Box className="scanning-qrcode__text_info">
                        {t('qrCode.qrcodeAssign')}
                        <Button
                            variant={ButtonVariant.LINK}
                            onClick={onAssign}
                            label={t('qrCode.qrcodeAssignButton')}
                        />
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default QrCodeOptions;
