export const MEDIA_QUERIES = {
  SCREEN_XL: 1920,
  SCREEN_L: 1600,
  SCREEN_MD: 1366,
  SCREEN_SM_IPAD: 1024,
};
export const SCREEN_CATEGORIES = {
  SCREEN_XL: 'SCREEN_XL',
  SCREEN_L: 'SCREEN_L',
  SCREEN_MD: 'SCREEN_MD',
  SCREEN_SM_IPAD: 'SCREEN_SM_IPAD',
};
