import i18next from 'i18next';
import { CareplanComponentTypes } from '../../../../../types/Careplan';
import { ComponentNameType } from './FilterCriteriaComponentAnswer.enum';

const fileUploadOptions = () => [
  {
    value: 'attached',
    labelLocation: 'file_filter_options.attached',
    label: i18next.t('file_filter_options.attached')
  },
  {
    value: 'not_attached',
    labelLocation: 'file_filter_options.not_attached',
    label: i18next.t('file_filter_options.not_attached')
  },
];

const yesNoOptions = () => [
  {
    value: `0`,
    labelLocation: 'common_labels.yes',
    label: i18next.t('common_labels.yes')
  },
  {
    value: `1`,
    labelLocation: 'common_labels.no',
    label: i18next.t('common_labels.no')
  },
];

const painLocationOptions = () => [
  {
    value: 'head',
    labelLocation: 'bodyChartImage.head',
    label: i18next.t('bodyChartImage.head')
  },
  {
    value: 'occiput',
    labelLocation: 'bodyChartImage.occiput',
    label: i18next.t('bodyChartImage.occiput')
  },
  {
    value: 'shoulder',
    labelLocation: 'bodyChartImage.shoulder',
    label: i18next.t('bodyChartImage.shoulder')
  },
  {
    value: 'leftChest',
    labelLocation: 'bodyChartImage.leftChest',
    label: i18next.t('bodyChartImage.leftChest')
  },
  {
    value: 'rightChest',
    labelLocation: 'bodyChartImage.rightChest',
    label: i18next.t('bodyChartImage.rightChest')
  },
  {
    value: 'stomach',
    labelLocation: 'bodyChartImage.stomach',
    label: i18next.t('bodyChartImage.stomach')
  },
  {
    value: 'alvus',
    labelLocation: 'bodyChartImage.alvus',
    label: i18next.t('bodyChartImage.alvus')
  },
  {
    value: 'bodyBack',
    labelLocation: 'bodyChartImage.bodyBack',
    label: i18next.t('bodyChartImage.bodyBack')
  },
  {
    value: 'fundament',
    labelLocation: 'bodyChartImage.fundament',
    label: i18next.t('bodyChartImage.fundament')
  },
  {
    value: 'leftUpperArm',
    labelLocation: 'bodyChartImage.leftUpperArm',
    label: i18next.t('bodyChartImage.leftUpperArm')
  },
  {
    value: 'leftLowerArm',
    labelLocation: 'bodyChartImage.leftLowerArm',
    label: i18next.t('bodyChartImage.leftLowerArm')
  },
  {
    value: 'rightUpperArm',
    labelLocation: 'bodyChartImage.rightUpperArm',
    label: i18next.t('bodyChartImage.rightUpperArm')
  },
  {
    value: 'rightLowerArm',
    labelLocation: 'bodyChartImage.rightLowerArm',
    label: i18next.t('bodyChartImage.rightLowerArm')
  },
  {
    value: 'leftLeg',
    labelLocation: 'bodyChartImage.leftLeg',
    label: i18next.t('bodyChartImage.leftLeg')
  },
  {
    value: 'rightLeg',
    labelLocation: 'bodyChartImage.rightLeg',
    label: i18next.t('bodyChartImage.rightLeg')
  },
  {
    value: 'neck',
    labelLocation: 'bodyChartImage.neck',
    label: i18next.t('bodyChartImage.neck')
  },
  {
    value: 'throat',
    labelLocation: 'bodyChartImage.throat',
    label: i18next.t('bodyChartImage.throat')
  },
  {
    value: 'genital',
    labelLocation: 'bodyChartImage.genital',
    label: i18next.t('bodyChartImage.genital')
  }
];

const choiceComponentOptions = (selectedComponent: ComponentNameType) => {
  return selectedComponent ?
    selectedComponent.componentOptions?.map((option, index) => ({
      value: `${index}`,
      label: option
    })) : [];
};

const checkIfComponentIsObjectValue = (componentType: number) => {
  return componentType === CareplanComponentTypes.UploadFile ||
  componentType === CareplanComponentTypes.Yesno ||
  componentType === CareplanComponentTypes.PainLocationChart;
}

export {
  fileUploadOptions,
  yesNoOptions,
  painLocationOptions,
  choiceComponentOptions,
  checkIfComponentIsObjectValue
};