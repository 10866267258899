import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Box } from '@ui-common-files/components';
import {
  CarePlanReportOccurrence,
  CareplanComponent,
  CareplanComponentAnswer,
} from '@type/Careplan';
import { getThresholdClass } from '../AnswerResult.services';
import { getIsThreshold } from '../../../ReportGenerator/Careplan/CareplanReport.utils';

const rows = [
  'bloodPressureOptions.systolic',
  'bloodPressureOptions.diastolic',
];

const renderComponentOptionContent = (
  allOccurrences: CarePlanReportOccurrence[],
  componentData: CareplanComponent,
  componentIndex: number,
  option: string
): JSX.Element[] => {
  return allOccurrences.map(
    (occurrence: CarePlanReportOccurrence, indexOccurrence: number) => {
      let optionContents: JSX.Element[];

      const answers = componentData.answers.filter(
        (answer: CareplanComponentAnswer) =>
          answer.allOccurrenceId === occurrence.id
      );
      optionContents = answers.map(
        (answer: CareplanComponentAnswer, index: number) => {
          let componentContent: string | number;
          if (answer.isSkipped) {
            componentContent = '-';
          } else {
            componentContent =
              option === rows[0]
                ? answer.answer_blood_pressure_systolic
                : answer.answer_blood_pressure_diastolic;
          }
          const isThreshold: boolean =
            !answer.isSkipped && getIsThreshold(componentData, answer);
          const thresholdClass = getThresholdClass(isThreshold);

          return (
            <Box
              key={`component_options-header-answer-${componentIndex}-${index}`}
              className={thresholdClass}
            >
              {componentContent}
            </Box>
          );
        }
      );

      return (
        <Flex
          key={`component_options-answer-${componentIndex}-${indexOccurrence}`}
          className="answer-component_options-item-columns-section-column"
          alignItems="center"
          justifyContent="center"
        >
          {optionContents}
        </Flex>
      );
    }
  );
};

export const renderComponentOptions = (
  allOccurrences: CarePlanReportOccurrence[],
  componentData: CareplanComponent,
  componentIndex: number
): JSX.Element[] => {
  const { t } = useTranslation();

  return rows.map((option: string, index: number) => (
    <Flex
      key={`col-option-${componentIndex}-${index}`}
      className="answer-component_options-item"
    >
      <Box flex={1} className="answer-component_options-item-title">
        <Flex alignItems="center">
          <Box flex={1}>
            {t(option)} ({t('bloodPressureOptions.unit')})
          </Box>
        </Flex>
      </Box>
      <Flex className="answer-component_options-item-columns-section">
        {renderComponentOptionContent(
          allOccurrences,
          componentData,
          componentIndex,
          option
        )}
      </Flex>
    </Flex>
  ));
};
