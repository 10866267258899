import I18n from 'i18next';
import moment from 'moment';
import {
  CheckIfValidDateProps,
  DatePickerDateType,
  DatePickerKeys,
} from './dateUtil.type';

function toLocalizeDate(dateISOString: string, local: string = null): string {
  return moment(dateISOString)
    .locale(local || I18n.language)
    .format('L');
}

function toBirthdateInYears(dateISOString: string): number {
  return moment().diff(dateISOString, 'years');
}
const differenceBetweenTwoDatesInDays = (startDate: Date, endDate: Date) => {
  return moment(endDate).diff(moment(startDate), 'days');
};

const checkIfValidDate: CheckIfValidDateProps = (
  date,
  maxDate = new Date(),
  minDate = new Date(1903, 0, 1)
) => {
  const { Day, Month, Year } = date;
  if ([Day, Month, Year].some(value => value === '' || +value === 0))
    return false;
  const daysInMonth = new Date(+Year, +Month, 0).getDate();
  const validDay = +Day <= daysInMonth;
  const validMonth = +Month < 13 && +Month > 0;
  const validYear =
    +Year >= minDate.getFullYear() && +Year <= maxDate.getFullYear();
  return validDay && validMonth && validYear;
};

function extractDateFromDateObject(date: DatePickerDateType) {
  return new Date(
    Date.UTC(
      +date[DatePickerKeys.Year],
      +date[DatePickerKeys.Month] - 1,
      +date[DatePickerKeys.Day]
    )
  );
}
const isISODate = isoString =>
  moment(isoString, moment.ISO_8601, true).isValid();

const getUTCDateFromLocalDate = (date: Date) =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

const setEndOfDayForMaxData = (utcDate: string) => {
  const endDate = new Date(utcDate);
  endDate.setUTCHours(23, 59, 59, 999);
  return endDate;
};

const getDateAdjustmentUnit = (unitLabel: string) => {
  switch (unitLabel) {
    case I18n.t('lifespan.HOUR'):
      return 'hours';
    case I18n.t('lifespan.DAY'):
      return 'days';
    case I18n.t('lifespan.WEEK'):
      return 'weeks';
    case I18n.t('lifespan.MONTH'):
      return 'months';
    case I18n.t('lifespan.YEAR'):
      return 'years';
    default:
      return null;
  }
};
const addTime = (date: moment.Moment, unitLabel: string, value: string) => {
  const unit = getDateAdjustmentUnit(unitLabel);
  if (unit === null) return date;
  return date.add(value, unit);
};
const subtractTime = (
  date: moment.Moment,
  unitLabel: string,
  value: string
) => {
  const unit = getDateAdjustmentUnit(unitLabel);
  if (unit === null) return date;
  return date.subtract(value, unit);
};
const adjustDateBySequenceDirection = (
  date: moment.MomentInput,
  sequenceLabel: string,
  unitLabel: string,
  unitValue: string
) => {
  return sequenceLabel === I18n.t('common_labels.label_before')
    ? subtractTime(moment(date), unitLabel, unitValue).toISOString()
    : addTime(moment(date), unitLabel, unitValue).toISOString();
};

export {
  toLocalizeDate,
  toBirthdateInYears,
  checkIfValidDate,
  extractDateFromDateObject,
  getUTCDateFromLocalDate,
  setEndOfDayForMaxData,
  isISODate,
  differenceBetweenTwoDatesInDays,
  adjustDateBySequenceDirection,
};
