import { createBrowserHistory } from 'history';

const BrowserHistory = createBrowserHistory();

const {
  go: BrowserHistoryGo,
  goBack: BrowserHistoryGoBack,
  push: BrowserHistoryPush,
  replace: BrowserHistoryReplace,
} = BrowserHistory;

export {
  BrowserHistoryGo,
  BrowserHistoryGoBack,
  BrowserHistoryPush,
  BrowserHistoryReplace,
};
export default BrowserHistory;
