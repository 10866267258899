import React, { useState, useEffect } from 'react';
import axios from 'axios';
import I18n from 'i18next';
import moment from 'moment';
import classNames from 'classnames';
import serviceConfig from '../../../serviceConfig.json';
import ASSET_TYPES from './assetTypes';
import getAssetIcon from './getAssetIcon';
import '../../css/assets.css';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import { IconButtonVariant } from '../../../caro-ui-commonfiles/utils/iconButton';
import { ApiClient } from '../../Global/Services';
require('image-compressor');

const getAssetTypeId = fileName => {
  let assetTypeId;
  const ext = fileName.substr(fileName.lastIndexOf('.') + 1).toUpperCase();

  switch (ext) {
    case 'DOC':
    case 'DOCX':
    case 'XLS':
    case 'PDF':
    case 'PPT':
      assetTypeId = ASSET_TYPES.DOCUMENT;
      break;
    case 'JPEG':
    case 'JPG':
    case 'PNG':
    case 'GIF':
    case 'BMP':
      assetTypeId = ASSET_TYPES.IMAGE;
      break;
    case 'MP3':
    case 'WMA':
      assetTypeId = ASSET_TYPES.AUDIO;
      break;
    case 'MP4':
    case 'AVI':
    case 'MOV':
    case 'WMV':
    case 'M4V':
      assetTypeId = ASSET_TYPES.VIDEO;
      break;
    default:
      assetTypeId = ASSET_TYPES.DOCUMENT;
  }
  return assetTypeId;
};

const isPreviewInViewer = extension => {
  return (
    extension === 'PDF' ||
    extension === 'JPEG' ||
    extension === 'JPG' ||
    extension === 'PNG' ||
    extension === 'GIF' ||
    extension === 'BMP' ||
    extension === 'MP3' ||
    extension === 'MP4' ||
    extension === 'M4V' ||
    extension === 'MOV'
  );
};

const uploadToResourceSpace = (
  file,
  isPatientFolderId,
  assetIds,
  setFlashMessage,
  patientCollectionId
) => {
  const formData = new FormData();

  const matchedElem = assetIds.find(elem => {
    const lastIndexOpeningParentheses = elem.name.lastIndexOf(' (');
    const lastIndexClosingParentheses = elem.name.lastIndexOf(')');
    const lastIndexOfDot = elem.name.lastIndexOf('.');
    if (
      lastIndexOpeningParentheses !== -1 &&
      lastIndexClosingParentheses ===
        elem.name.substr(0, lastIndexOfDot).length - 1
    ) {
      try {
        const fileExt = elem.name.substr(lastIndexOfDot + 1);
        elem.name = `${elem.name.slice(
          0,
          lastIndexOpeningParentheses
        )}.${fileExt}`;
      } catch (error) {
        log.error(`Failed to create asset: ${error.stack}`);
      }
    }
    if (elem.originalName === file.name) return elem;
  });

  formData.append('filedata', file);
  formData.append('recordid', matchedElem.recordId);
  formData.append('isUploadFromChat', isPatientFolderId);
  formData.append('patientCollectionId', patientCollectionId);
  try {
    return axios
      .post(
        serviceConfig.brokerService.createAssetInResourceSpace.uri,
        formData,
        {
          timeout: 300000,
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': 'form-data',
          },
        }
      )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        setFlashMessage({
          content: I18n.t(error.response.data.content),
          type: error.response.data.type,
        });
      });
  } catch (error) {
    setFlashMessage({
      content: I18n.t(error.response.data.content),
      type: error.response.data.type,
    });
  }
};
const showAssetRequest = (
  id,
  setAssetDataToShow,
  setOpenModal,
  setFlashMessage,
  activeTab,
  patientId,
  isPrivacyPolicy
) => {
  try {
    let shouldSkipAssetInstitutionsCheck;
    if (activeTab === undefined) {
      shouldSkipAssetInstitutionsCheck = true;
    } else shouldSkipAssetInstitutionsCheck = false;
    if (isPrivacyPolicy) {
      return axios
        .get(
          `${serviceConfig.brokerService.getPrivacyPolicyDetails.uri}${id}/${patientId}`,
          {
            params: {
              shouldSkipAssetInstitutionsCheck,
            },
          }
        )
        .then(response => {
          setAssetDataToShow(response.data);
          setOpenModal(true);
          setAssetDataToShow(response.data);
          setOpenModal(true);
        })
        .catch(error => {
          setFlashMessage({
            content: I18n.t(error.response.data.content),
            type: error.response.data.type,
          });
        });
    } else {
      return axios
        .get(`${serviceConfig.brokerService.getAssetById.uri}${id}`, {
          params: {
            shouldSkipAssetInstitutionsCheck,
          },
        })
        .then(response => {
          setAssetDataToShow(response.data);
          setOpenModal(true);
        })
        .catch(error => {
          setFlashMessage({
            content: I18n.t(error.response.data.content),
            type: error.response.data.type,
          });
        });
    }
  } catch (error) {
    setFlashMessage({
      content: I18n.t(error.response.data.content),
      type: error.response.data.type,
    });
  }
};

const assetUploadPromise = (
  response,
  file,
  setFlashMessage,
  isPatientFolderId,
  patientCollectionId
) => {
  return uploadToResourceSpace(
    file,
    isPatientFolderId,
    response.data.createdRecordIds,
    setFlashMessage,
    patientCollectionId
  )
    .then(uploadResponse => {
      return uploadResponse;
    })
    .catch(error => {
      return error;
    });
};

const validateFileType = fileList => {
  const acceptedFileTypes = [
    'PDF',
    'JPEG',
    'JPG',
    'PNG',
    'GIF',
    'BMP',
    'MP3',
    'MP4',
    'MOV',
    'M4V',
  ];

  const isTypeCorrect = [];
  for (let i = 0; i < fileList.length; i++) {
    const ext = fileList[i].name.substr(fileList[i].name.lastIndexOf('.') + 1);
    isTypeCorrect.push(
      fileList[i] && acceptedFileTypes.includes(ext.toUpperCase())
    );
  }
  if (isTypeCorrect.includes(false)) {
    return false;
  }
  return true;
};

const validateFileSize = (fileList, maxSizePerFile) => {
  let sum = 0;
  const maxSize = maxSizePerFile * fileList.length || 30 * 10 ** 6;
  for (let i = 0; i < fileList.length; i++) {
    sum += fileList[i].size;
  }
  return sum <= maxSize;
};

const validateFileLimit = (fileList, filesLimit) => {
  const limit = filesLimit || 3;
  return fileList.length !== 0 && fileList.length <= limit;
};

const validateFileNameLimit = fileList => {
  let isNameLimitCorrect = [];
  let checkValidation;
  for (let i = 0; i < fileList.length; i++) {
    if (fileList[i].name.length <= 50) {
      isNameLimitCorrect.push(true);
    } else {
      isNameLimitCorrect.push(false);
    }
  }
  for (let i = 0; i < fileList.length; i++) {
    if (isNameLimitCorrect[i] == true) {
      checkValidation = true;
    } else {
      checkValidation = false;
    }
  }
  return checkValidation;
};

const convertBase64UrlToFile = (fileName, base64Url) => {
  let byteString;
  if (base64Url.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(base64Url.split(',')[1]);
  } else {
    byteString = unescape(base64Url.split(',')[1]);
  }
  let ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blobFile = new Blob([ia], { type: 'image/png' });
  return new File([blobFile], fileName, { type: 'image/png' });
};

const convertLinkToBlob = link => {
  return fetch(link)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .catch(error => Promise.reject(error));
};

const showFileInRectangle = (
  assetTypeId,
  previewLink,
  isPreviewable,
  isShowingAsset
) => {
  switch (previewLink) {
    case isPreviewable && assetTypeId === ASSET_TYPES.VIDEO && previewLink:
      return (
        <video
          playsinline
          controls="true"
          className="img-obj__fit"
          frameBorder="0"
          src={previewLink}
          type="video/mp4"
        ></video>
      );
    case assetTypeId === ASSET_TYPES.IMAGE && previewLink:
      return <img src={previewLink} className="img-obj__fit" frameBorder="0" />;
    case isPreviewable && assetTypeId === ASSET_TYPES.DOCUMENT && previewLink:
      return (
        <iframe src={previewLink} className="img-obj__fit" frameBorder="0" />
      );

    case isPreviewable && assetTypeId === ASSET_TYPES.AUDIO && previewLink:
      return (
        <div className="audio-style">
          <audio
            playsinline
            controls="true"
            frameBorder="0"
            src={previewLink}
            type="audio/mp3"
          />
        </div>
      );
    default:
      return (
        <div className="default-preview-icon__div">
          {getAssetIcon(assetTypeId, 80)}
        </div>
      );
  }
};

const navigateToExternalUrl = url => {
  var popUp = window.open(url, '_blank');
  if (popUp == null || typeof popUp == 'undefined') {
    alert(I18n.t('asset_view.disablePopupBlocker'));
  }
};

const renderShowAssetFirstColumn = (assetDataToShow, isShowingAsset) => {
  const { assetData } = assetDataToShow;
  const { assetTypeId, extension, directLink } = assetData;
  const [blobURL, setBlobURL] = useState(directLink);
  const isPreviewable = isPreviewInViewer(extension.toUpperCase());
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (isPreviewable) {
      convertLinkToBlob(directLink)
        .then(convertedUrl => {
          setBlobURL(convertedUrl);
          setShouldShow(true);
        })
        .catch(() => {
          setBlobURL(null);
        });
    }
  }, [assetDataToShow]);

  const container = classNames({
    'asset-show-col1__container': !isShowingAsset,
    'asset-show-col1__container-full': isShowingAsset,
  });

  const showCSSClasses = classNames({
    'asset-show-file__container': true,
    'asset-show-file__container-image': assetTypeId === ASSET_TYPES.IMAGE,
    'asset-show-file__container-other': assetTypeId !== ASSET_TYPES.IMAGE,
  });

  return (
    <div className={container}>
      <div className={showCSSClasses}>
        {!isShowingAsset &&
          (shouldShow || isPreviewInViewer) &&
          assetTypeId !== ASSET_TYPES.VIDEO &&
          assetTypeId !== ASSET_TYPES.AUDIO && (
            <div className="asset-show-preview__icon">
              <IconButton
                type="button"
                name="previewInShow"
                size={36}
                variant={IconButtonVariant.PREVIEW}
                onClick={() => {
                  navigateToExternalUrl(blobURL || directLink);
                }}
                tooltipText={I18n.t('asset_view.openInBrowser')}
              />
            </div>
          )}
        {showFileInRectangle(
          assetTypeId,
          blobURL,
          isPreviewable,
          isShowingAsset
        )}
      </div>
      {!isShowingAsset && (
        <Flex otherStyles={{ marginTop: 16, width: '100%', height: '100%' }}>
          <Flex flexDirection="column">
            <Flex flexDirection="row" alignItems="center">
              <div className="asset-info__labels">
                {I18n.t('asset_view.fileSize')}
              </div>
            </Flex>

            <Flex
              flexDirection="row"
              alignItems="center"
              otherStyles={{ marginTop: '12px' }}
            >
              <div className="asset-info__labels">
                {I18n.t('asset_view.dataAdded')}
              </div>
            </Flex>

            <Flex
              flexDirection="row"
              alignItems="center"
              otherStyles={{ marginTop: '12px' }}
            >
              <div className="asset-info__labels">
                {I18n.t('asset_view.lastModified')}
              </div>
            </Flex>

            <Flex
              flexDirection="row"
              alignItems="center"
              otherStyles={{
                marginTop: '12px',
              }}
            >
              <div className="asset-info__labels">
                {I18n.t('asset_view.createdBy')}
              </div>
            </Flex>
          </Flex>

          <Flex
            flexDirection="column"
            otherStyles={{ marginLeft: 40, flex: '1' }}
          >
            <Flex flexDirection="row" alignItems="center">
              <div className="asset-info__values">{`${assetData.size} MB`}</div>
            </Flex>

            <Flex
              flexDirection="row"
              alignItems="center"
              otherStyles={{ marginTop: '12px' }}
            >
              <div className="asset-info__values">
                {moment(assetData.createdAt)
                  .locale(I18n.language)
                  .format('DD MMM YYYY')}
              </div>
            </Flex>

            <Flex
              flexDirection="row"
              alignItems="center"
              otherStyles={{ marginTop: '12px' }}
            >
              <div className="asset-info__values">
                {moment(assetData.updatedAt)
                  .locale(I18n.language)
                  .format('DD MMM YYYY')}
              </div>
            </Flex>

            <Flex
              flexDirection="row"
              alignItems="center"
              otherStyles={{
                marginTop: '12px',
              }}
            >
              <div className="asset-info__values">
                {assetDataToShow.assetLogs[0]
                  ? assetDataToShow.assetLogs[0].user.username
                  : I18n.t('patients_view.label_patient')}
              </div>
            </Flex>
          </Flex>
        </Flex>
      )}
    </div>
  );
};

const getAssetAssociations = (id, setAssetAssociations) => {
  return ApiClient.GET({
    url: `${serviceConfig.brokerService.getAssetAssociations.uri}${id}`,
  }).then(response => {
    if (response) setAssetAssociations(response.data);
  });
};

const clearPendingAssets = () => {
  return ApiClient.POST({
    url: serviceConfig.brokerService.clearPendingAssets.uri,
    payload: {},
  });
};

const checkAssetIsShared = (id, activeTab) => {
  return ApiClient.GET({
    url: `${serviceConfig.brokerService.checkAssetIsShared.uri}${id}`,
    payload: {
      shouldSkipAssetInstitutionsCheck: activeTab === undefined,
    },
  }).then(response => {
    return response.data;
  });
};

export {
  getAssetTypeId,
  showAssetRequest,
  assetUploadPromise,
  validateFileType,
  validateFileSize,
  validateFileLimit,
  isPreviewInViewer,
  renderShowAssetFirstColumn,
  uploadToResourceSpace,
  convertBase64UrlToFile,
  getAssetAssociations,
  convertLinkToBlob,
  clearPendingAssets,
  navigateToExternalUrl,
  checkAssetIsShared,
  validateFileNameLimit,
};
