/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import CareplanTemplateForAssign from '../CareplanTemplateForAssign';
import '../../css/careplans.scss';
import AssignTemplateStep2 from './AssignTemplateStep2';
import AssignTemplateStep3 from './AssignTemplateStep3';
import AssignTemplateStep4 from './AssignTemplateStep4';
import useCareplanModalHeaderFooter from '../../Utils/hooks/useCareplanModalHeaderFooter';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import {
  lifespanUnits,
  rruleFreqToRepeatTypeIndex,
} from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import AssignCareplanToPatientsRequest from '../../Utils/AssignCareplanToPatientsRequest';
import {
  ComponentTypes,
  Actions,
  SubmissionTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { nonMedical } from '../../../config';
import IsEmptyObject from '../../../caro-ui-commonfiles/global/IsEmptyObject/IsEmptyObject.ts';
import useAllTodoAssessments from '../Notifications/Notification.hooks';

const AddCareplanModalContent = ({
  selectedPatientsData,
  fromPatientOverview,
}) => {
  const patientIds = selectedPatientsData
    ? selectedPatientsData.map(patient => patient.id)
    : null;
  const { age } = selectedPatientsData[0];
  const [careplanData, setCareplanData] = useState({});
  const [careplanDataThresholds, setCareplanDataThresholds] = useState([]);
  const [careplanDataLogicJumps, setCareplanDataLogicJumps] = useState({});
  const [defaultDataThresholds, setDefaultDataThresholds] = useState([]);
  const [careplanScoringScheme, setCareplanScoringScheme] = useState([]);

  const { user } = useSelector(state => state.session);
  const {
    currentStep,
    setCurrentStep,
    shouldValidateForm,
    dispatchFormValidator,
    shouldSubmitForm,
    dispatchFormSubmittor,
    setFlashMessage,
    closeModal,
    setLandingTab,
    isTemplateSelected,
    setIsTemplateSelected,
    setShowUnder18,
    setCareplanHeaderFooter,
    navigateToCareplanAnswerView,
    usWarningCheckboxChecked,
    setUsWarningCheckboxChecked,
    setBottomPageReached,
    setRefreshCalendar,
    setIsCaretaskDataLoading,
  } = useContext(PatientOverviewContext);
  const [isEvaluating, setIsEvaluating] = useState(false);

  useEffect(() => {
    if (!IsEmptyObject(careplanData)) {
      setIsCaretaskDataLoading(false);
    }
  }, [careplanData]);

  const checkPatientOver18 = () => {
    if (
      age < 18 &&
      currentStep === 3 &&
      (nonMedical == false || nonMedical == 'false')
    ) {
      setCurrentStep(currentStep + 1);
      return false;
    }
    return true;
  };
  const { getAllTodos, allTodoAssessmentsController } = useAllTodoAssessments();

  useEffect(() => {
    if (
      shouldSubmitForm.component === ComponentTypes.CAREPLAN &&
      shouldSubmitForm.action === Actions.Create
    ) {
      let dataObject = {};
      if (careplanData.startNow) {
        const now = new Date();
        dataObject = {
          ...careplanData,
          lifespan: 1,
          selectedLifespanUnit: lifespanUnits()[1].label,
          selectedRecurrence: '1',
          occurrenceCount: 1,
          occurrenceCountCheck: true,
          repeatType: rruleFreqToRepeatTypeIndex()[3],
          repeatInterval: { value: 1, label: 1 },
          customTime: { hour: now.getHours(), minute: 0 },
          startDate: now,
          selectedPatients: patientIds,
          user,
          careplanDataThresholds,
          startNow: true,
          careplanDataLogicJumps,
          careplanScoringScheme,
        };
      } else {
        dataObject = {
          selectedLifespanUnit: careplanData.lifespanUnit.label,
          selectedRecurrence: careplanData.occurrenceCount.toString(),
          ...careplanData,
          selectedPatients: patientIds,
          user,
          careplanDataThresholds,
          careplanDataLogicJumps,
          careplanScoringScheme,
        };
      }

      setLandingTab(false);
      AssignCareplanToPatientsRequest(
        careplanData.id,
        closeModal,
        setFlashMessage,
        shouldSubmitForm.submissionType === SubmissionTypes.ASSIGN_AND_SEND,
        dataObject
      ).then(async response => {
        const { occurrenceId, careplanId } = response.data;

        if (careplanData.isAssessment) {
          getAllTodos();
        }
        if (careplanData.startNow && occurrenceId) {
          navigateToCareplanAnswerView({
            id: careplanId,
            isAssessment: careplanData.isAssessment,
            occurrenceId,
          });
        } else {
          setLandingTab(ComponentTypes.CAREPLAN);
          setRefreshCalendar(true);
        }
      });

      return function cleanup() {
        dispatchFormSubmittor({
          component: '',
          action: '',
          submissionType: false,
        });
        if (allTodoAssessmentsController) {
          allTodoAssessmentsController.abort();
        }
      };
    }
  }, [shouldSubmitForm, careplanDataThresholds, careplanData]);

  useEffect(() => {
    let hasThresholds = false;
    if (careplanDataThresholds.length) {
      careplanDataThresholds.map(careplan => {
        if (careplan[1].length > 0) {
          hasThresholds = true;
        }
      });
    }
    setShowUnder18(
      age < 18 &&
        (hasThresholds || isEvaluating) &&
        (nonMedical == false || nonMedical == 'false')
    );
  }, [careplanDataThresholds, isEvaluating]);

  useEffect(() => {
    if (
      careplanDataThresholds.length > 0 &&
      defaultDataThresholds.length === 0
    ) {
      setDefaultDataThresholds(careplanDataThresholds);
    }
  }, [careplanDataThresholds]);

  useCareplanModalHeaderFooter(careplanData, setCareplanHeaderFooter);
  return (
    <>
      <ModalContent>
        {currentStep === 1 && (
          <ModalForm
            formId="assign-careplan-from-overview"
            handleFormSubmit={e => {
              e.preventDefault();
            }}
          >
            <CareplanTemplateForAssign
              setCareplanScoringScheme={setCareplanScoringScheme}
              setCareplanData={setCareplanData}
              setCareplanDataThresholds={setCareplanDataThresholds}
              isTemplateSelected={isTemplateSelected}
              setIsTemplateSelected={setIsTemplateSelected}
              setIsEvaluating={setIsEvaluating}
              setCareplanDataLogicJumps={setCareplanDataLogicJumps}
              setIsCaretaskDataLoading={setIsCaretaskDataLoading}
            />
          </ModalForm>
        )}
        {currentStep === 2 && (
          <AssignTemplateStep2
            careplanData={careplanData}
            setCareplanData={setCareplanData}
            dispatchFormValidator={dispatchFormValidator}
            shouldValidateForm={shouldValidateForm}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            fromPatientOverview={fromPatientOverview || false}
          />
        )}
        {currentStep === 3 && checkPatientOver18() && (
          <AssignTemplateStep3
            careplanData={careplanData}
            careplanScoringScheme={careplanScoringScheme}
            careplanDataThresholds={careplanDataThresholds}
            setCareplanDataThresholds={setCareplanDataThresholds}
            isEvaluating={careplanData.isEvaluating}
            setBottomPageReached={setBottomPageReached}
            usWarningCheckboxChecked={usWarningCheckboxChecked}
            setUsWarningCheckboxChecked={setUsWarningCheckboxChecked}
            careplanLogicJumpData={careplanDataLogicJumps}
            defaultDataThresholds={defaultDataThresholds}
          />
        )}
        {currentStep === 4 && (
          <AssignTemplateStep4
            careplanData={careplanData}
            selectedPatientsData={selectedPatientsData}
            careplanNumberOfComponents={careplanDataThresholds.length}
          />
        )}
      </ModalContent>
    </>
  );
};

export default AddCareplanModalContent;
