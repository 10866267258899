import I18n from 'i18next';
import moment from 'moment';
import genderObject from '../../../Utils/gender.json';
import { ScoringTypes } from '../../../types/Careplan';

const processDataForPatientTable = data => {
  if (data) {
    const patients = data.data;
    return patients.map(patient => {
      const { compliance } = patient.institutions[0].patientInstitution;
      return {
        id: patient.id,
        healthIndicatorId: {
          profilePicture: patient.profilePicture,
          healthScore: patient.healthScore,
          scoringSchemeCategory: patient.scoringSchemeCategory,
        },
        deviceToken: patient.deviceToken,
        firstname: patient.firstname,
        lastname: patient.lastname,
        site: patient.institutions,
        gender: patient.gender,
        birthdate: patient.birthdate,
        collectionId: patient.collectionId,
        icds: patient.icds,
        medicalrecord: patient.medicalRecord,
        pseudonymCode: patient.pseudonymCode,
        age: patient.age,
        isBirthdayHardcoded: patient.isBirthdayHardcoded,
        compliance: parseInt(compliance) >= 0 ? compliance + '%' : '',
        satellites: Array.isArray(patient.satellites) ? patient.satellites : [],
      };
    });
  }
  return [];
};

const getGenderTranslation = gender => {
  if (gender && gender.toLowerCase() === genderObject.FEMALE) {
    return I18n.t('patients_view.gender_option2');
  }
  if (gender && gender.toLowerCase() === genderObject.MALE) {
    return I18n.t('patients_view.gender_option1');
  }
  return '';
};

const getActiveOptionPageSize = (items, limit) => {
  const index = items.findIndex(opt => parseInt(opt.key, 10) === limit);
  if (index > -1) {
    return index;
  }

  return 0;
};

const getBackgroundByHealtIndicatorCategory = healthIndicator => {
  switch (healthIndicator) {
    case ScoringTypes.Well:
      return 'rgba(40, 180, 100, 0.2)';
    case ScoringTypes.Fair:
      return 'rgba(240, 180, 40, 0.2)';
    case ScoringTypes.Poor:
      return 'rgba(233, 100, 100, 0.1)';
    default:
      return 'rgba(214, 214, 214, 0.3)';
  }
};

const getIconNameForHealthIndicatorByCategory = category => {
  switch (category) {
    case ScoringTypes.Well:
      return 'succesOutlined';
    case ScoringTypes.Fair:
      return 'fairCategory';
    case ScoringTypes.Poor:
      return 'errorOutlined';
    default:
      return 'questionOutlined';
  }
};

const getFormattedBirthdate = birthdate => {
  if (birthdate)
    return moment(birthdate).locale(I18n.language).format('DD MMM YYYY');
  return '';
};

const getHealthStatusTranslationByCategory = category => {
  switch (category) {
    case ScoringTypes.Well:
      return I18n.t('newCareplan_view.scoring_well');
    case ScoringTypes.Fair:
      return I18n.t('newCareplan_view.scoring_fair');
    case ScoringTypes.Poor:
      return I18n.t('newCareplan_view.scoring_poor');
    default:
      return I18n.t('patients_view.noHealthData');
  }
};

export {
  processDataForPatientTable,
  getGenderTranslation,
  getActiveOptionPageSize,
  getBackgroundByHealtIndicatorCategory,
  getIconNameForHealthIndicatorByCategory,
  getFormattedBirthdate,
  getHealthStatusTranslationByCategory,
};
