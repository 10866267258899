import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box } from '@ui-common-files/components';
import CareplanReportTemplate from './Careplan/CareplanReportTemplate';
import MedicationPlanReportTemplate from './MedicationPlan/MedicationPlanReportTemplate';
import { ReportsList } from './ReportGenerator.enum';
import './Careplan/style.scss';

export default function ReportGenerator() {
  const { reportName, reportId } = useParams<{
    reportName: ReportsList;
    reportId: string;
  }>();

  const { search } = useLocation();
  const authenticationToken = new URLSearchParams(search).get('accessToken');

  switch (reportName) {
    case ReportsList.Careplan:
      return (
        <CareplanReportTemplate
          reportId={reportId}
          authenticationToken={authenticationToken}
        />
      );
    case ReportsList.MedicationPlan:
      return (
        <MedicationPlanReportTemplate
          reportId={reportId}
          authenticationToken={authenticationToken}
        />
      );
    default:
      return <Box>Invalid Input</Box>;
  }
}
