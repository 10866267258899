import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAnalytics } from '@global/Services';
import { FlashMessageTypes } from '@ui-common-files/types';
import {
  ActionTranslationKeys,
  ComponentTranslationKeys,
  Greys,
} from '@ui-common-files/utils';
import { checkUserCredential } from '@utils';
import { FloatItem } from '@type/FloatItem/';
import { FlashMessageData } from '../PatientList.type';

import { addPatientRequest } from '../../../../actions/patients';

interface Config {
  isUsingOncobox: boolean;
}

export default function useFloatItems(config: Config) {
  const { t, i18n } = useTranslation();
  const { trackEventTrigger } = useAnalytics();
  const reduxDispatcher = useDispatch();
  const [exportModalToggle, setExportModalToggle] = useState(false);
  const [importModalToggle, setImportModalToggle] = useState(false);
  const [displayFlashMsg, setDisplayFlashMsg] = useState<FlashMessageData>({
    display: false,
    message: '',
    content: '',
    type: FlashMessageTypes.Warning,
    autoHide: true,
    hideLeftIcon: false,
  });

  const floatItems: FloatItem[] = useMemo(
    () =>
      [
        config.isUsingOncobox
          ? {
              icon: 'export',
              tooltipText: null,
              fill: Greys.DARK_GREY,
              onClick: async (close: () => void) => {
                close();
                const hasCredential = await checkUserCredential(
                  ComponentTranslationKeys.PATIENT,
                  ActionTranslationKeys.CREATE
                );
                if (hasCredential === true) {
                  setExportModalToggle(true);
                } else {
                  setDisplayFlashMsg({
                    display: true,
                    content: t(hasCredential.content),
                    type: hasCredential.type,
                  });
                }
              },
            }
          : null,
        config.isUsingOncobox
          ? {
              icon: 'import',
              fill: Greys.DARK_GREY,
              onClick: async (close: () => void) => {
                close();
                const hasCredential = await checkUserCredential(
                  ComponentTranslationKeys.PATIENT,
                  ActionTranslationKeys.CREATE
                );
                if (hasCredential === true) {
                  setImportModalToggle(true);
                } else {
                  setDisplayFlashMsg({
                    display: true,
                    content: t(hasCredential.content),
                    type: hasCredential.type,
                  });
                }
              },
            }
          : null,
        {
          icon: 'assign',
          fill: Greys.DARK_GREY,
          tooltipText: t('patients_view.label_addPatient'),
          onClick: async (close: Function) => {
            close();
            const hasCredential = await checkUserCredential(
              ComponentTranslationKeys.PATIENT,
              ActionTranslationKeys.CREATE
            );
            if (hasCredential === true) {
              trackEventTrigger({
                category: 'patient',
                action: 'Open-Create-Modal',
              });
              reduxDispatcher(addPatientRequest());
            } else {
              setDisplayFlashMsg({
                display: true,
                content: t(hasCredential.content),
                type: hasCredential.type,
              });
            }
          },
        },
      ].filter(item => item),
    [i18n.language]
  );

  return {
    floatItems,
    exportModalToggle,
    setExportModalToggle,
    importModalToggle,
    setImportModalToggle,
    displayFlashMsg,
    setDisplayFlashMsg,
  };
}
