/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import I18n from 'i18next';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';

import { HideFlashMessage } from '@global/Services';

import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import IconButtonDropdown from '../../../caro-ui-commonfiles/components/DropdownMenu/IconButtonDropdown';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import { ButtonVariant } from '../../../caro-ui-commonfiles/utils/button';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Checkbox from '../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import SelectedFiles from '../../../caro-ui-commonfiles/components/SelectedFiles/SelectedFiles';

import {
  ActionColors,
  PrimaryColor,
  Greys,
} from '../../../caro-ui-commonfiles/utils/colors';
import {
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import checkUserCredential from '../../Utils/checkUserCredential';
import useWindowWidthListener from '../../Utils/hooks/useWindowWidthListener';
import editUser from '../Users/edit';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import getAssetIcon from '../../Utils/Asset/getAssetIcon';
import {
  showAssetRequest,
  isPreviewInViewer,
  getAssetAssociations,
  convertLinkToBlob,
  navigateToExternalUrl,
  checkAssetIsShared,
} from '../../Utils/Asset/assetHelper';
import Actions from '../../Utils/actions';

import { useUserPermission } from '../../Utils/hooks/getUserPermission';
import Animation from './CellElements/Animationcomponent';
import NavigationJSON from '../../Utils/navigation.json';
import ASSET_TYPES from '../../Utils/Asset/assetTypes';

import '../../css/sites.css';

const AssetListView = ({
  fetchedData,
  loading,
  recordsTotal,
  activeTab,
  deleteAssetRequest,
  query,
  setQuery,
  isAttachFile,
  setAttachedFile,
  setSelectedFile,
  setFlashMessage,
  setAssetDataToShow,
  setOpenModal,
  setAction,
  setModalTitle,
  isSelectAll,
  setIsSelectAll,
  allAssetData,
  setAllAssetData,
  readAndUpdateAsset,
  isAttachFiles,
  deleteAssetFromPatientOverview,
  blobArray,
  setAssetAssociations,
  currentPage,
  setCurrentPage,
  setPageState,
  sort,
  setSort,
  patientId,
  isPrivacyPolicy,
}) => {
  const [deleteOperation, setDeleteOperation] = useState({
    display: false,
    id: 0,
    title: '',
    assetId: 0,
    name: '',
    dialogContent: '',
  });

  const [selectedAssets, setSelectedAssets] = useState([]);
  const [isAssetSelected, setIsAssetSelected] = useState(false);
  const { user } = useSelector(state => state.session);
  const [rowData, setRowData] = useState(fetchedData);
  const [hasAssetPermission, setHasAssetPermission] = useState(false);
  const responsive = useWindowWidthListener();

  const hasAssetSharePermission = useUserPermission(
    I18n.t('types.componentType.assets'),
    I18n.t('actions.share'),
    true
  );

  const hasSiteReadPermission = useUserPermission(
    I18n.t('types.componentType.sites'),
    I18n.t('actions.read'),
    true
  );

  useEffect(() => {
    const shouldUserShareAssets = !!(
      (isAttachFiles
        ? hasAssetSharePermission
        : hasSiteReadPermission && hasAssetSharePermission) &&
      activeTab &&
      !isAttachFile
    );

    setHasAssetPermission(shouldUserShareAssets);
  }, [
    hasSiteReadPermission,
    hasAssetSharePermission,
    activeTab,
    isAttachFile,
    isAttachFiles,
  ]);

  useEffect(() => {
    if (isAttachFiles) {
      setAttachedFile([...allAssetData]);
    }
  }, [allAssetData]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];
  const tableProps = {
    defaultSorted: [
      {
        id: 'record',
        desc: false,
      },
    ],
    onSortedChange: (newSorted, column, shiftKey) => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
    getTdProps: (state, rowInfo, column, instance) => {
      const { row } = rowInfo;
      const { id, name, directLink, thumbnailURL } = row._original;
      return {
        onClick: (e, handleOriginal) => {
          if (column.id !== 'rowOptions') {
            setIsAssetSelected(rowInfo.index);
            if ((setSelectedFile || setAttachedFile) && !isAttachFiles) {
              const fileInfo = {
                id,
                name,
                assetTypeId: activeTab,
                directLink,
                thumbnailURL,
              };
              if (setSelectedFile) {
                setSelectedFile(fileInfo);
              } else if (setAttachedFile) {
                setAttachedFile(fileInfo);
              }
              getAssetAssociations(id, setAssetAssociations);
              if (handleOriginal) {
                handleOriginal();
              }
            }
          }
        },
        style: {
          cursor: isAttachFile ? 'pointer' : 'auto',
          background:
            allAssetData.find(x => x.id === rowInfo.original.id) ||
            (isAttachFile && rowInfo.index === isAssetSelected)
              ? PrimaryColor.MAIN_COLOR_LIGHT
              : null,
        },
      };
    },
    getTrProps: (state, rowInfo, column, instance) => {
      const { assetStatusId } = rowInfo.original;
      return {
        style: {
          borderStyle: 'solid',
          borderWidth: assetStatusId !== 1 ? 0 : 1,
          borderColor: assetStatusId !== 1 ? '' : '#c4c4c4',
          borderRadius: assetStatusId !== 1 ? 0 : 4,
          color: assetStatusId !== 1 ? Greys.DARK_GREY : Greys.LIGHT_GREY,
        },
      };
    },
  };

  const closeMessagePrompt = () => {
    setDeleteOperation({
      display: false,
      id: 0,
      title: '',
      assetId: 0,
      name: '',
      dialogContent: '',
    });
  };

  useEffect(() => {
    if (isSelectAll) {
      setSelectedAssets(fetchedData);
    } else {
      setSelectedAssets([]);
    }
  }, [isSelectAll]);

  const checkSharedAssetAndDelete = async (id, assetId, name) => {
    try {
      const isShared = await checkAssetIsShared(id, activeTab);
      if (
        activeTab &&
        (isShared.isAssetAssociatedWithCareplanComponents ||
          isShared.isAssetAssociatedWithPatients)
      ) {
        setFlashMessage({
          type: 'warning',
          content: I18n.t('asset_view.msg_asset_has_associations'),
        });
      } else if (!activeTab && isShared.isAssetAssociatedWithInsitutions) {
        setDeleteOperation({
          display: true,
          id,
          title: `${I18n.t(
            'common_labels.msg_confirm_asset_remove_headline'
          )}: ${name}`,
          assetId,
          name,
          isShared,
          dialogContent: I18n.t('common_labels.msg_confirm_asset_remove'),
        });
      } else {
        setDeleteOperation({
          display: true,
          id,
          title: `${I18n.t(
            'common_labels.msg_confirm_asset_delete_headline'
          )}: ${name}`,
          assetId,
          name,
          isShared,
          dialogContent: I18n.t('common_labels.msg_confirm_asset_delete'),
        });
      }
    } catch (error) {
      setFlashMessage({
        content: I18n.t(error.response.data.content),
        type: error.response.data.type,
      });
    }
  };

  const Rows = data => {
    const retrievedData = [...data];
    if (retrievedData && retrievedData.length) {
      const assetResult = retrievedData.map((asset, index) => {
        const matchedElem = blobArray.find(elem => elem.id == asset.id);
        const assetIcon = getAssetIcon(asset.assetTypeId, 36);
        let isChecked = false;
        if (isSelectAll) {
          isChecked = true;
        }
        let newAssetList = allAssetData;
        const assetIds = allAssetData.filter(elem => elem.id == asset.id);
        if (allAssetData.length > 0 && assetIds.length > 0) {
          isChecked = true;
        }
        if (allAssetData.length > 0 && assetIds.length == 0) {
          isChecked = false;
        }
        if (allAssetData.length == 0) {
          isChecked = false;
        }
        const updateDate = isPrivacyPolicy ? asset.endDate : asset.updatedAt;
        return {
          assetCheckbox: isPrivacyPolicy ? null : hasAssetPermission ? (
            <Checkbox
              id={asset.id}
              label=""
              checked={isChecked}
              handleOnChange={e => {
                isChecked = e.target.checked;
                if (e.target.checked) {
                  isChecked = false;
                  newAssetList = allAssetData.concat(asset);
                } else {
                  isChecked = false;
                  const index = allAssetData.findIndex(
                    pat => pat.id === asset.id
                  );
                  if (index > -1) {
                    newAssetList = [
                      ...newAssetList.slice(0, index),
                      ...newAssetList.slice(index + 1),
                    ];
                  }
                }
                setAllAssetData(newAssetList);
              }}
            />
          ) : null,
          assetLogo:
            !asset.thumbnailURL || !matchedElem ? (
              <Animation isOn delay={index * 50}>
                {assetIcon}
              </Animation>
            ) : (
              <Animation isOn delay={index * 50}>
                <Box className="img-object__fit">
                  <img
                    src={matchedElem && matchedElem.blobThumbnailURL}
                    width="36px"
                    height="36px"
                    style={{ objectFit: 'cover' }}
                  />
                </Box>
              </Animation>
            ),
          name: asset.name,
          extension: asset.extension,
          updatedAt:
            updateDate &&
            moment(updateDate).locale(I18n.language).format('DD MMM YYYY'),
          size: asset.size !== '' ? `${asset.size} MB` : '',
          id: asset.id,
          thumbnailURL: asset.thumbnailURL,
          directLink: asset.directLink,
          assetId: asset.resourceSpaceAssetId,
          assetStatusId: asset.assetStatusId,
          assetTypeId: asset.assetTypeId,
          isPrivacyPolicy: asset.isPrivacyPolicy,
          createdAt: moment(asset.createdAt)
            .locale(I18n.language)
            .format('DD MMM YYYY'),
          isImported: asset.isImported,
          isMedicationPlan: asset.isMedicationPlan,
        };
      });
      return assetResult;
    }
    return [];
  };

  useEffect(() => {
    setRowData(Rows(fetchedData));
  }, [fetchedData, isSelectAll, allAssetData, hasAssetPermission, blobArray]);

  const displayEditModal = async selectedUser => {
    const hasCredential = await checkUserCredential(
      ComponentTranslationKeys.USER,
      ActionTranslationKeys.SHOW
    );
    if (hasCredential.type) {
      setFlashMessage({
        type: hasCredential.type,
        content: hasCredential.content,
      });
    } else if (hasCredential === true) {
      await editUser(selectedUser).then(response => {
        if (response.type) {
          setFlashMessage({
            content: response.content,
            type: response.type,
          });
        } else {
          setCurrUser(response);
          setDisplayModal(true);
        }
      });
    }
  };
  const columns = useMemo(() => {
    return [
      {
        Header: () => {
          if (hasAssetPermission && recordsTotal > 0 && !isPrivacyPolicy) {
            return (
              <Checkbox
                id="checkAll"
                isIndeterminate={
                  allAssetData.length > 0 && allAssetData.length < recordsTotal
                }
                checked={isSelectAll}
                handleOnChange={e => {
                  setIsSelectAll(e.target.checked);
                }}
              />
            );
          }
          return null;
        },
        accessor: 'assetCheckbox',
        headerStyle: {
          paddingLeft: 24,
        },
        sortable: false,
        filterable: false,
        width: 62,
        show: !(isAttachFile || !activeTab || isPrivacyPolicy),
        style: {
          paddingLeft: 24,
          borderRadius: isAttachFile || !activeTab ? 0 : '5px 0 0 5px',
        },
      },
      {
        Header: '',
        accessor: 'assetLogo',
        sortable: false,
        filterable: false,
        width: isAttachFile || !activeTab || isPrivacyPolicy ? 60 : 56,
        style: {
          borderRadius: isAttachFile || !activeTab ? '5px 0 0 5px' : 0,
          paddingLeft:
            isAttachFile || !activeTab || isPrivacyPolicy ? 24 : null,
        },
      },
      {
        Header: props => {
          return (
            <>
              <Box width={20}>
                {sort.column === props.column.id && (
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                )}
              </Box>
              <span>{I18n.t('asset_view.documentName')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        headerStyle: {
          marginLeft: isAttachFile || !activeTab || isPrivacyPolicy ? -14 : -21,
        },
        accessor: 'name',
        filterable: false,
        minWidth: 200,
        style: {
          paddingLeft: isAttachFile || !activeTab || isPrivacyPolicy ? 16 : 6,
        },
      },
      {
        Header: props => {
          return (
            <>
              <Box width={20}>
                {sort.column === props.column.id && (
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                )}
              </Box>
              <span>{I18n.t('asset_view.file_type')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'extension',
        show: !isPrivacyPolicy,
        style: {
          paddingLeft: 30,
        },
        filterable: false,
        minWidth: 130,
        maxWidth: 130,
      },
      {
        Header: props => {
          return (
            <>
              <Box width={20}>
                {sort.column === props.column.id && (
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                )}
              </Box>
              <span>{I18n.t('privacyPolicy.uploadedAt')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'createdAt',
        show: !!activeTab && isPrivacyPolicy,
        filterable: false,
        minWidth: 176,
        maxWidth: 176,
        style: {
          paddingLeft: 30,
        },
      },
      {
        Header: props => {
          return (
            <>
              <Box width={20}>
                {sort.column === props.column.id && (
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                )}
              </Box>
              <span>
                {isPrivacyPolicy
                  ? I18n.t('privacyPolicy.replacedAt')
                  : I18n.t('asset_view.lastUpdated')}
              </span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'updatedAt',
        filterable: false,
        minWidth: 176,
        maxWidth: 176,
        style: {
          paddingLeft: 30,
        },
      },
      {
        Header: props => {
          return (
            <>
              <Box width={20}>
                {sort.column === props.column.id && (
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                )}
              </Box>
              <span>{I18n.t('withings.size')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'size',
        minWidth: 120,
        maxWidth: 120,
        filterable: false,
        style: {
          paddingLeft: 30,
        },
      },
      {
        Header: '',
        accessor: 'rowOptions',
        sortable: false,
        filterable: false,
        minWidth: isPrivacyPolicy
          ? 124
          : isAttachFile || isAttachFiles
          ? 100
          : 160,
        maxWidth: isPrivacyPolicy
          ? 124
          : isAttachFile || isAttachFiles
          ? 100
          : 160,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'space-around',
          paddingRight: isAttachFile || !activeTab ? 0 : 30,
          borderRadius: '0 5px 5px 0',
        },
        Cell: props => {
          const {
            id,
            name,
            assetId,
            directLink,
            assetStatusId,
            assetTypeId,
            extension,
            isPrivacyPolicy,
            isImported,
            isMedicationPlan,
          } = props.original;
          return (
            <>
              {assetStatusId === 1 && (
                <Loader
                  type="Oval"
                  color={PrimaryColor.MAIN_COLOR}
                  height={24}
                  width={24}
                />
              )}
              {assetStatusId === 3 && (
                <>
                  <div style={{ marginRight: 10 }}>
                    <Button
                      label={I18n.t('common_labels.label_Retry')}
                      disabled={false}
                      variant={ButtonVariant.OUTLINED}
                      onClick={() => {
                        const updateData = {
                          assetId,
                          recordId: id,
                          assetTypeId,
                        };
                        readAndUpdateAsset([updateData], assetTypeId);
                      }}
                    >
                      <Icon name="refresh" size={12} />
                    </Button>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="delete"
                      fill={ActionColors.DELETE}
                      size={30}
                      onClick={() => {
                        checkSharedAssetAndDelete(id, assetId, name);
                      }}
                      tooltipText={I18n.t('actions.delete')}
                      disabled={isMedicationPlan === 1}
                    />
                  </div>
                </>
              )}
              {assetStatusId === 2 &&
                (responsive && !isAttachFile && !isAttachFiles ? (
                  <div
                    className={
                      isPrivacyPolicy
                        ? 'privacy-policy-button__dropdown'
                        : 'moreIconStyle'
                    }
                  >
                    <IconButtonDropdown
                      items={
                        isPrivacyPolicy
                          ? [
                              { value: I18n.t('actions.export') },
                              { value: I18n.t('actions.show') },
                            ]
                          : activeTab && !isImported
                          ? [
                              { value: I18n.t('actions.export') },
                              { value: I18n.t('actions.show') },
                              { value: I18n.t('common_labels.label_edit') },
                              ...(isMedicationPlan === 0
                                ? [{ value: I18n.t('actions.delete') }]
                                : []),                            ]
                          : !isImported
                          ? [
                              { value: I18n.t('actions.export') },
                              { value: I18n.t('actions.show') },
                              ...(isMedicationPlan === 0
                                ? [{ value: I18n.t('actions.delete') }]
                                : []),
                            ]
                          : [
                              { value: I18n.t('actions.export') },
                              { value: I18n.t('actions.show') },
                            ]
                      }
                      onClick={(e, action) => {
                        if (
                          action.value === I18n.t('common_labels.label_edit')
                        ) {
                          setModalTitle(
                            `${I18n.t('asset_view.editFile')}: ${name}`
                          );
                          setAction(Actions.Edit);
                          showAssetRequest(
                            id,
                            setAssetDataToShow,
                            setOpenModal,
                            setFlashMessage,
                            activeTab
                          );
                        } else if (action.value === I18n.t('actions.show')) {
                          setModalTitle(
                            `${I18n.t('asset_view.showFile')}: ${name}`
                          );
                          setAction(Actions.Show);
                          Promise.all([
                            showAssetRequest(
                              id,
                              setAssetDataToShow,
                              setOpenModal,
                              setFlashMessage,
                              activeTab,
                              patientId,
                              isPrivacyPolicy
                            ),
                            getAssetAssociations(id, setAssetAssociations),
                          ]);
                        } else if (action.value === I18n.t('actions.export')) {
                          saveAs(directLink, name);
                        } else {
                          checkSharedAssetAndDelete(id, assetId, name);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <>
                    {!isAttachFile && !isAttachFiles && (
                      <div style={{ marginRight: !activeTab ? 0 : 10 }}>
                        <IconButton
                          type="button"
                          name="export"
                          size={30}
                          onClick={() => {
                            saveAs(directLink, name);
                          }}
                          tooltipText={I18n.t('actions.export')}
                        />
                      </div>
                    )}
                    {(isAttachFile || isAttachFiles) && (
                      <div style={{ marginRight: 10 }}>
                        <IconButton
                          type="button"
                          name="preview"
                          size={36}
                          onClick={() => {
                            if (isPreviewInViewer(extension.toUpperCase())) {
                              convertLinkToBlob(directLink)
                                .then(convertedUrl => {
                                  const isVideo =
                                    assetTypeId === ASSET_TYPES.VIDEO;
                                  if (
                                    isVideo ||
                                    assetTypeId === ASSET_TYPES.AUDIO
                                  ) {
                                    const newWindow = window.open(
                                      `/${NavigationJSON.PREVIEW_VIDEO}`,
                                      '_blank'
                                    );
                                    newWindow.convertedUrl = convertedUrl;
                                    newWindow.isVideo = isVideo;
                                  } else {
                                    navigateToExternalUrl(convertedUrl);
                                  }
                                })
                                .catch(() => {
                                  navigateToExternalUrl(directLink);
                                });
                            } else {
                              navigateToExternalUrl(directLink);
                            }
                          }}
                          tooltipText={I18n.t('asset_view.preview')}
                        />
                      </div>
                    )}
                    {!isAttachFile && !isAttachFiles && (
                      <div
                        style={{
                          marginRight: activeTab ? 10 : 5,
                        }}
                      >
                        <IconButton
                          type="button"
                          name="view"
                          size={30}
                          onClick={() => {
                            setModalTitle(
                              `${I18n.t('asset_view.showFile')}: ${name}`
                            );
                            setAction(Actions.Show);
                            Promise.all([
                              showAssetRequest(
                                id,
                                setAssetDataToShow,
                                setOpenModal,
                                setFlashMessage,
                                activeTab,
                                patientId,
                                isPrivacyPolicy
                              ),
                              activeTab &&
                                getAssetAssociations(id, setAssetAssociations),
                            ]);
                          }}
                          tooltipText={I18n.t('actions.show')}
                        />
                      </div>
                    )}
                    {!isAttachFile && !isAttachFiles && (
                      <>
                        {activeTab && !isPrivacyPolicy && !isImported && (
                          <div
                            style={{ marginRight: isPrivacyPolicy ? 7 : 10 }}
                          >
                            <IconButton
                              type="button"
                              name="edit"
                              size={30}
                              onClick={() => {
                                setModalTitle(
                                  `${I18n.t('asset_view.editFile')}: ${name}`
                                );
                                setAction(Actions.Edit);
                                showAssetRequest(
                                  id,
                                  setAssetDataToShow,
                                  setOpenModal,
                                  setFlashMessage,
                                  activeTab
                                );
                              }}
                              tooltipText={I18n.t('common_labels.label_edit')}
                            />
                          </div>
                        )}
                        {(!isPrivacyPolicy || !activeTab) && !isImported && (
                          <div style={{ marginRight: 10 }}>
                            <IconButton
                              type="button"
                              name="delete"
                              fill={ActionColors.DELETE}
                              size={30}
                              onClick={() => {
                                checkSharedAssetAndDelete(id, assetId, name);
                              }}
                              disabled={
                                !!isPrivacyPolicy || isMedicationPlan === 1
                              }
                              tooltipText={
                                isPrivacyPolicy ? '' : I18n.t('actions.delete')
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
            </>
          );
        },
      },
    ];
  }, [
    sort.column,
    sort.dir,
    user.id,
    responsive,
    displayEditModal,
    selectedAssets,
    allAssetData,
  ]);

  return (
    <>
      {allAssetData.length ? (
        <SelectedFiles
          numberFiles={allAssetData.length}
          message={
            allAssetData.length > 1
              ? I18n.t('asset_view.filesSelected')
              : I18n.t('asset_view.fileSelected')
          }
        />
      ) : null}
      <div
        style={
          !activeTab ? { height: 'calc(100vh - 200px)', overflow: 'auto' } : {}
        }
      >
        <Box margin={responsive ? '55px 0' : '20px 0'}>
          <DataTable
            data={rowData}
            columns={columns}
            pageSize
            pageSizeOptions={items}
            pagination
            isLoading={loading}
            tableProps={tableProps}
            onTableChange={(index, size) => {
              setQuery({
                value: query.value,
                start:
                  rowData && index * size > recordsTotal ? 0 : index * size,
                length: size,
                dir: sort.dir,
                column: sort.column,
              });
              setCurrentPage(
                rowData && index * size > recordsTotal ? 0 : index
              );
              setPageState(size);
            }}
            page={currentPage}
            pages={Math.ceil(recordsTotal / query.length)}
            totalRecord={recordsTotal}
          />

          {deleteOperation.display && (
            <Modal
              isConfirmationDialog
              title={deleteOperation.title}
              contentComponent={
                <PromptContent message={deleteOperation.dialogContent} />
              }
              footerComponent={
                <PromptFooter
                  close={closeMessagePrompt}
                  confirmHandler={() => {
                    if (deleteOperation.id > 0) {
                      if (!activeTab) {
                        deleteAssetFromPatientOverview(deleteOperation);
                      } else {
                        deleteAssetRequest(deleteOperation);
                      }
                    }
                  }}
                />
              }
              openModal={deleteOperation.display}
              onClose={closeMessagePrompt}
              hideFlashMessage={HideFlashMessage}
            />
          )}
        </Box>
      </div>
    </>
  );
};

export default AssetListView;
