const colorObject = colorSet => {
  const colors = {};
  for (const [key, value] of Object.entries(colorSet)) {
    colors[key] = value;
  }
  return colors;
};

enum PrimaryColor {
  MAIN_COLOR = '#28b4e6',
  MAIN_COLOR_90 = '#3ebce8',
  MAIN_COLOR_70 = '#69cbee',
  MAIN_COLOR_30 = '#bfe9f8',
  MAIN_COLOR_10 = '#eaf7fc',
  MAIN_COLOR_LIGHT = '#e4f2f7',
  BRANDING_COLOR = '#e94c89',
  MAIN_COLOR_DARK = '#232a85',
}

const primaryColorDarkShade = {
  MAIN_COLOR: PrimaryColor.MAIN_COLOR,
  MAIN_COLOR_90: PrimaryColor.MAIN_COLOR_90,
  MAIN_COLOR_70: PrimaryColor.MAIN_COLOR_70,
};

const primaryColorLightShade = {
  MAIN_COLOR_30: PrimaryColor.MAIN_COLOR_30,
  MAIN_COLOR_10: PrimaryColor.MAIN_COLOR_10,
  MAIN_COLOR_LIGHT: PrimaryColor.MAIN_COLOR_LIGHT,
};

const primaryColorDarkShadeSwatch = colorObject(primaryColorDarkShade);
const primaryColorLightShadeSwatch = colorObject(primaryColorLightShade);

enum Greys {
  DARK_GREY = '#323232',
  DARK_GREY_70 = '#454b48',
  LIGHT_GREY = '#787878',
  LIGHT_GREY_75 = '#ababab',
  LIGHT_GREY_50 = '#cdcdcd',
  LIGHT_GREY_20 = '#ebebeb',
  LIGHT_GREY_10 = '#f6f6f6',
  WHITE = '#ffffff',
  NIMBUS_CLOUD = '#a7a8b9',
}

const greysDarkShade = {
  DARK_GREY: Greys.DARK_GREY,
  DARK_GREY_70: Greys.DARK_GREY_70,
  LIGHT_GREY: Greys.LIGHT_GREY,
  LIGHT_GREY_75: Greys.LIGHT_GREY_75,
};

const greysLightShade = {
  LIGHT_GREY_50: Greys.LIGHT_GREY_50,
  LIGHT_GREY_20: Greys.LIGHT_GREY_20,
  LIGHT_GREY_10: Greys.LIGHT_GREY_10,
  WHITE: Greys.WHITE,
};

const greysColorVars = colorObject(Greys);

const greysDarkShadeSwatch = colorObject(greysDarkShade);
const greysLightShadeSwatch = colorObject(greysLightShade);

const brandingColor = {
  MAIN_COLOR_DARK: PrimaryColor.MAIN_COLOR_DARK,
  BRANDING_COLOR: PrimaryColor.BRANDING_COLOR,
  NIMBUS_CLOUD: Greys.NIMBUS_CLOUD,
};
const brandingColorSwatch = colorObject(brandingColor);

enum AuxiliaryColors {
  RED = '#e96464',
  RED_90 = '#eb7474',
  RED_70 = '#f09393',
  RED_30 = '#f8d1d1',
  RED_10 = '#fdf0f0',
  LIGHT_SALMON_10 = '#ffd8cf',
  GREEN = '#73A511',
}

enum ComponentColors {
  CAREPLAN = '#28b4e6',
  MEDICATION = '#ffcb04',
  TASK = '#748c3a',
  APPOINTMENT = '#652c90',
}

const componentColorSwatch = colorObject(ComponentColors);

enum ActionColors {
  DELETE = '#e96464',
  ERROR = '#e96464',
  THRESHOLD = '#e96464',
  OFFLINE = '#e96464',
  SUCCESS = '#28b464',
  INFO = '#28b4e6',
  WARNING = '#f0b428',
  ACTIVE_10 = '#ebf7f0',
  PLANNED_10 = '#fef7ea',
  INACTIVE_10 = '#f6f6f6',
  ACTIVE_25 = '#c9ecd8',
  PLANNED_25 = '#fcecc9',
  INACTIVE_25 = '#dddddd',
}

const ScoringSchemaColors = {
  well: { bgColor: '#dff4e8', color: ActionColors.SUCCESS },
  fair: { bgColor: '#fdf4df', color: ActionColors.WARNING },
  poor: { bgColor: AuxiliaryColors.RED_10, color: AuxiliaryColors.RED },
};

const HealthIndicatorColors = {
  well: ScoringSchemaColors.well.color,
  fair: ScoringSchemaColors.fair.color,
  poor: ScoringSchemaColors.poor.color,
  default: PrimaryColor.MAIN_COLOR,
};

const feedbackColors = {
  DELETE: ActionColors.DELETE,
  ERROR: ActionColors.ERROR,
  THRESHOLD: ActionColors.THRESHOLD,
  OFFLINE: ActionColors.OFFLINE,
  SUCCESS: ActionColors.SUCCESS,
  INFO: ActionColors.INFO,
  WARNING: ActionColors.WARNING,
};

const actionColorSwatch = colorObject(feedbackColors);

const carePathwayStatus = {
  ACTIVE_10: ActionColors.ACTIVE_10,
  ACTIVE_25: ActionColors.ACTIVE_25,
  PLANNED_10: ActionColors.PLANNED_10,
  PLANNED_25: ActionColors.PLANNED_25,
  INACTIVE_10: ActionColors.INACTIVE_10,
  INACTIVE_25: ActionColors.INACTIVE_25,
};

const carePathwayStatusSwatch = colorObject(carePathwayStatus);

enum BackgroundColors {
  BG_COLOR_GREY = '#f9f9f9',
  BG_COLOR_WHITE = '#ffffff',
}

const backgroundColorSwatch = colorObject(BackgroundColors);

enum Opacity {
  OPACITY_60_HEX_DECIMAL = '99',
}

export {
  PrimaryColor,
  primaryColorDarkShadeSwatch,
  primaryColorLightShadeSwatch,
  brandingColorSwatch,
  Greys,
  greysColorVars,
  greysDarkShadeSwatch,
  greysLightShadeSwatch,
  AuxiliaryColors,
  ComponentColors,
  componentColorSwatch,
  ActionColors,
  actionColorSwatch,
  BackgroundColors,
  backgroundColorSwatch,
  carePathwayStatusSwatch,
  ScoringSchemaColors,
  HealthIndicatorColors,
  Opacity,
};
