import { Caretask } from '@type/Caretask';
import { Patient } from '@type/Patient';

export enum NotificationType {
  Threshold = 'threshold',
  Chat = 'chat',
  Todo = 'todo',
  Mention = 'mention'
}

export type NotificationReducer = {
  [NotificationType.Todo]?: {
    count: number;
    unseenNotesCount: number;
    items: (Caretask & { patient?: Patient })[];
  };
  [NotificationType.Chat]?: { count: number; items: [] };
  [NotificationType.Threshold]?: { count: number; items: [] };
};
