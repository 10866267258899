import React, { useState } from 'react';

import { FlashMessageTypes } from '@ui-common-files/types';

import { createAsset } from './AssetUploadCheck.api';
import {
  assetUploadPromise,
  validateFileLimit,
  validateFileNameLimit,
  validateFileSize,
  validateFileType,
} from '../../../Utils/Asset/assetHelper';
import { ShowFlashMessage } from '@global/Services';
import serviceConfig from '../../../../serviceConfig.json';

const assetUploadCheck = () => {
  const [uploadResponse, setUploadResponse] = useState('');

  const handleImageUpload = async e => {
    e.preventDefault();
    const fileList = e.target.files;
    let errorMessage = '';
    const isFileLimitCorrect = validateFileLimit(fileList);
    const isFileTypeCorrect = validateFileType(fileList);
    const isFileSizeCorrect = validateFileSize(fileList);
    const isFileNameLimitCorrect = validateFileNameLimit(fileList);

    if (
      isFileLimitCorrect &&
      isFileSizeCorrect &&
      isFileTypeCorrect &&
      isFileNameLimitCorrect
    ) {
      const formData = new FormData();
      for (let i = 0; i < fileList.length; i++) {
        formData.append('filedata', fileList[i]);
      }
      const url = serviceConfig.brokerService.createAsset.uri;
      const response = await createAsset(url, formData);
      let promises = [];
      for (let i = 0; i < fileList.length; i++) {
        promises.push(
          Promise.resolve(
            assetUploadPromise(response, fileList[i])
              .then(response => {
                if (response)
                  setUploadResponse(response[0].id ? 'OK' : 'Failed!');
              })
              .catch(() => {
                setUploadResponse('Failed!');
              })
          )
        );
      }
      Promise.all(promises);
    } else {
      setUploadResponse('');
    }
    if (!isFileLimitCorrect) {
      errorMessage = 'flash.maxNumberOfFilesUpload';
    } else if (!isFileSizeCorrect) {
      errorMessage = 'flash.maxFileSize';
    } else if (!isFileTypeCorrect) {
      errorMessage = 'flash.incorrectFileType';
    } else if (!isFileNameLimitCorrect) {
      errorMessage = 'flash.fileNameLimitExceed';
    }
    if (errorMessage)
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: errorMessage,
      });
  };

  return {
    uploadResponse,
    handleImageUpload,
  };
};
export default assetUploadCheck;
