import React, { useState } from 'react';
import '../../styles/main.scss';
import PropTypes from 'prop-types';
import DropdownMenu from './DropdownMenu';
import Icon from '../Icon/Icon';

import { Greys } from '../../utils/colors';

const RecordDropdown = ({ text, items, onClick, iconName, iconColor, active }) => {
  const [record, setRecord] = useState(items[active].value);

  const buildItems = () => {
    return items.map(function(item) {
      return (
        <li key={item.key} onClick={e => handleChange(e, item)}>
          <span>{item.value}</span>
        </li>
      );
    });
  };

  const handleChange = (e, item) => {
    setRecord(item.value);
    onClick(e, item);
  };

  return (
    <div className="record-container">
      <Icon name={iconName} size={30} fill={iconColor} />
      <span className="record-container__select">
        <DropdownMenu items={buildItems()} variant="record-container__options">
          <span className="record-container__record">{record}</span>
        </DropdownMenu>
      </span>
      <span className="records-per-page__span">{text}</span>
    </div>
  );
};

RecordDropdown.propTypes = {
  text: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  active:PropTypes.number
};
RecordDropdown.defaultProps = {
  text: '',
  iconName: 'paginate',
  iconColor: Greys.DARK_GREY,
  active:0
};

export default RecordDropdown;
