import { React } from 'react';
import axios from 'axios';
import I18n from 'i18next';

import serviceConfig from '../../../../serviceConfig.json';
import { ComponentTypes } from '../../../../caro-ui-commonfiles/utils/componentTypes';
import { Caretask } from '@type/Caretask';
import { ApiClient, ShowFlashMessage } from '../../../Global/Services';

const requestTimeOut = 10000;

const createCarePathway = async (
  setFlashMessage,
  carePathwayObject,
  onSubmit,
  triggerTrackingForResponse
) => {
  try {
    return axios
      .post(
        serviceConfig.brokerService.createCarePathway.uri,
        carePathwayObject,
        { timeout: requestTimeOut }
      )
      .then(response => {
        onSubmit();
        setFlashMessage({
          type: response.data.type,
          content: I18n.t(response.data.content),
        });
        triggerTrackingForResponse('carepathway', 'Create-Successful');
      });
  } catch (error) {
    setFlashMessage({
      type: error.response.data.type,
      content: I18n.t(error.response.data.content),
    });
    triggerTrackingForResponse('carepathway', 'Create-Failed');
  }
};

const updateCarePathway = async (
  setFlashMessage,
  carePathwayObject,
  onSubmit,
  carePathwayId,
  triggerTrackingForResponse
) => {
  try {
    return axios
      .post(
        serviceConfig.brokerService.updateCarePathway.uri + carePathwayId,
        carePathwayObject,
        { timeout: requestTimeOut }
      )
      .then(response => {
        onSubmit();
        setFlashMessage({
          type: response.data.message.type,
          content: I18n.t(response.data.message.content),
        });
        triggerTrackingForResponse('carepathway', 'Update-Successful');
      });
  } catch (error) {
    triggerTrackingForResponse('carepathway', 'Update-Failed');
    setFlashMessage({
      type: error.response.data.message.type,
      content: I18n.t(error.response.data.message.content),
    });
  }
};

const showCarePathwayRequest = async (
  carePathwayId,
  setFlashMessage,
  setCarePathwayTemplatesForShow,
  setCarePathwayTemplatesWithThresholdsForShow,
  setOwnershipLogs,
  setLoadingForShow,
  closeModal,
  triggerTrackingForResponse
) => {
  try {
    setLoadingForShow(true);
    const response = await axios.get(
      serviceConfig.brokerService.showCarePathwayTemplate.uri + carePathwayId,
      { timeout: requestTimeOut }
    );
    if (response) {
      triggerTrackingForResponse('carepathway', 'Show-Successful');
      setCarePathwayTemplatesForShow(
        response.data.carePathwayTemplateAssociatedTemplates
      );
      setCarePathwayTemplatesWithThresholdsForShow(
        response.data.templateDataList
      );
      if (setOwnershipLogs)
        setOwnershipLogs(response.data.carePathwayTemplateLogs);
      setLoadingForShow(false);
    }
  } catch (error) {
    triggerTrackingForResponse('carepathway', 'Show-Failed');
    setFlashMessage({
      type: error.response.data.type,
      content: error.response.data.content,
    });
    setLoadingForShow(false);
    closeModal();
  }
};

const assignCarePathway = async (
  carePathwayId,
  setCarePathway,
  setFlashMessage
) => {
  try {
    return axios
      .get(serviceConfig.brokerService.assignCarePathway.uri + carePathwayId, {
        timeout: requestTimeOut,
      })
      .then(response => {
        setCarePathway(response.data);
      });
  } catch (error) {
    setFlashMessage({
      type: error.response.data.type,
      content: I18n.t(error.response.data.content),
    });
  }
};

const assignCarePathwayToPatients = async (
  carePathwayId,
  patientIds,
  setFlashMessage,
  acceptedSendWarning,
  assignedCareplans,
  carePathway,
  isPatientOverView,
  isAdhoc,
  referenceDate,
  setLandingTab,
  isFromBulkAssign
) => {
  try {
    const careplansDetails = [];
    const carePathwayForAssign = JSON.parse(JSON.stringify(carePathway));
    const { templates } = carePathwayForAssign;
    const carePathwayTemplates = [];
    for (let index = 0, { length } = templates; index < length; index++) {
      const template = templates[index];
      const templateDetails = assignedCareplans.find(
        careplan => careplan.template.id === template.id
      );
      for (
        let subIndex = 0,
          count = template.carePathwayTemplateAppointmentType.length;
        subIndex < count;
        subIndex++
      ) {
        carePathwayTemplates.push({
          ...template,
          carePathwayTemplateAppointmentType:
            template.carePathwayTemplateAppointmentType[subIndex],
        });
        careplansDetails.push(templateDetails);
      }
    }
    carePathwayForAssign.templates = carePathwayTemplates;

    const selectedCareplans = careplansDetails.map(careplans => {
      const newCareplanObject = {};
      newCareplanObject.template = {
        id: careplans.template.id,
        isEvaluating: careplans.template.isEvaluating,
      };
      newCareplanObject.careplanTemplateWithLogicJumps =
        careplans.template.logicJumpPaths;
      newCareplanObject.careplanComponentsWithThresholds = careplans.thresholds;
      return newCareplanObject;
    });

    return await axios
      .post(
        serviceConfig.brokerService.assignCarePathwayToPatients.uri +
          carePathwayId,
        {
          patientIds,
          acceptedSendWarning,
          selectedCareplans,
          carePathway: carePathwayForAssign,
          isPatientOverView,
          isAdhoc,
          referenceDate,
        },
        { timeout: 60000 }
      )
      .then(() => {
        if(!isFromBulkAssign) {
          setLandingTab(false);
          setTimeout(() => {
            setLandingTab(ComponentTypes.CAREPATHWAYS);
          }, 300);
        }
      });
  } catch (error) {
    setFlashMessage({
      type: error.response.data.type,
      content: I18n.t(error.response.data.content),
    });
  }
};

const getPathwayTemplateDetails = async (
  templateIds: number[],
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedCareplans: React.Dispatch<React.SetStateAction<Caretask[]>>,
  selectedCareplans: Caretask[] = [],
  signal?: AbortController['signal']
) => {
  setIsLoading(true);
  let careplansDetails = selectedCareplans.length ? selectedCareplans : [];
  const alreadyFetchedBefore = selectedCareplans.length === templateIds.length

  if (templateIds.length > 0 && !alreadyFetchedBefore) {
    const response = await ApiClient.POST({
      url: serviceConfig.brokerService.getMultipleCareplanTemplates.uri,
      payload: { templateIds },
      signal
    });
    if (response?.data) {
      careplansDetails = response.data.fetchedTemplateListWithThreshold;
    }
  }
  setIsLoading(false);
  setSelectedCareplans(careplansDetails);
  return careplansDetails
};

const checkDuplicateCarePathway = async (
  patientIds,
  carePathwayTemplateId,
  setFlashMessage,
  setIsDuplicateCarePathway
) => {
  try {
    const response = await axios.get(
      serviceConfig.brokerService.checkDuplicateCarePathway.uri,
      {
        params: { patientIds, carePathwayTemplateId },
      }
    );
    if (response) {
      setIsDuplicateCarePathway(response.data);
    }
  } catch (error) {
    setFlashMessage({
      type: error.response.data.type,
      content: I18n.t(error.response.data.content),
    });
  }
};

const updateAdHocCarepathway = (
  patientId,
  carepathwayId,
  carepathwayName,
  referenceDate,
  close,
  setItemIdToUpdate
) => {
  close();
  ApiClient.POST({
    url: `${serviceConfig.brokerService.rescheduleCarePathway.uri}/${carepathwayId}`,
    payload: {
      carepathwayName,
      patientId,
      referenceDate,
    },
  }).then(response => {
    if (response) {
      setItemIdToUpdate && setItemIdToUpdate(carepathwayId);
      ShowFlashMessage({
        type: response.data.type,
        messageKey: response.data.content,
      });
    }
  });
};

export {
  createCarePathway,
  updateCarePathway,
  showCarePathwayRequest,
  assignCarePathway,
  assignCarePathwayToPatients,
  getPathwayTemplateDetails,
  checkDuplicateCarePathway,
  updateAdHocCarepathway,
};
