import React from 'react';
import Flex from '../../Flex/Flex';

import { useDatePickerInputService } from './DatePickerInput.service';
import { DatePickerInputProps } from './DatePickerInput.type';

import styles from './DatePickerInput.module.scss';

function DatePickerInput({
  type,
  value,
  onInput,
  error,
  refs,
  disabled = false,
}: DatePickerInputProps) {
  const {
    currentValue,
    onInputChange,
    placeholder,
    inputClassName,
    onKeyDown,
    onBlur,
  } = useDatePickerInputService({
    type,
    value,
    onInput,
    error,
    refs,
  });
  return (
    <Flex className={styles.inputContainer}>
      <input
        onBlur={onBlur}
        className={inputClassName}
        onInput={onInputChange}
        value={currentValue}
        ref={refs[type]}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Flex>
  );
}

export default DatePickerInput;
