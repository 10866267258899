import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Label,
  NumberInput,
  TextInput,
  Flex,
  Box,
} from '@ui-common-files/components';
import ComponentSettingsWarning from '../ComponentSettings/ComponentSettingsWarning';

import { MaxCharLimits } from '@ui-common-files/utils';
import defaultEnums from '../defaultEnums';
import { processMinMaxValidationError } from '../CareplanHelperUtility';

import '../../../css/componentSettings.css';

const NumericRangeComponent = ({
  searchTermComponents,
  componentCollection,
  setComponentCollection,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  isNegativeWarningVisible = false,
  setShowPromptForAdjustScoring,
}) => {
  const { t } = useTranslation();
  const getMatchedPosition = () => {
    return componentCollection.findIndex(elem => {
      return elem.id == searchTermComponents.id;
    });
  };

  const getCareplanComponentId = () => {
    return componentCollection[getMatchedPosition()].searchTerm
      .careplanComponentId;
  };

  const getComponentSettingsElementValue = key => {
    let value;
    const componentElement = componentCollection[getMatchedPosition()];
    switch (key) {
      case 'getMinLabel':
        value = getCareplanComponentId()
          ? componentElement.searchTerm.minLabel
          : componentElement.minLabel;
        break;
      case 'getMinimumValue':
        value = getCareplanComponentId()
          ? componentElement.searchTerm.minimumValue
          : componentElement.minimumValue;
        break;
      case 'getMaxLabel':
        value = getCareplanComponentId()
          ? componentElement.searchTerm.maxLabel
          : componentElement.maxLabel;
        break;
      case 'getMaximumValue':
        value = getCareplanComponentId()
          ? componentElement.searchTerm.maximumValue
          : componentElement.maximumValue;
        break;
      default:
        break;
    }
    return value;
  };

  const handleMinNumberOnChange = value => {
    const matchingComponent = componentCollection[getMatchedPosition()];
    if (
      componentCollection[getMatchedPosition()].searchTerm.careplanComponentId
    ) {
      processMinMaxValidationError(
        value,
        componentCollection[getMatchedPosition()].searchTerm.maximumValue,
        matchingComponent
      );
      componentCollection[getMatchedPosition()].searchTerm.minimumValue = value;
    } else {
      processMinMaxValidationError(
        value,
        componentCollection[getMatchedPosition()].maximumValue,
        matchingComponent
      );
      componentCollection[getMatchedPosition()].minimumValue = value;
    }
    setComponentCollection([...componentCollection]);
  };

  const onChangeMinLabel = (event, elementFromErrorCollection, temp) => {
    if (
      componentCollection[getMatchedPosition()].searchTerm.careplanComponentId
    ) {
      componentCollection[getMatchedPosition()].searchTerm.minLabel =
        event.target.value;
    } else {
      componentCollection[getMatchedPosition()].minLabel = event.target.value;
    }
    if (event.target.value.length > 0) {
      elementFromErrorCollection.minLabelRequired = false;
    } else {
      elementFromErrorCollection.minLabelRequired = true;
    }
    hasValidationErrorTemplate({ ...temp });
    setComponentCollection([...componentCollection]);
  };

  const handleMaxNumberOnChange = value => {
    const matchingComponent = componentCollection[getMatchedPosition()];
    if (
      componentCollection[getMatchedPosition()].searchTerm.careplanComponentId
    ) {
      processMinMaxValidationError(
        componentCollection[getMatchedPosition()].searchTerm.minimumValue,
        value,
        matchingComponent
      );
      componentCollection[getMatchedPosition()].searchTerm.maximumValue = value;
    } else {
      processMinMaxValidationError(
        componentCollection[getMatchedPosition()].minimumValue,
        value,
        matchingComponent
      );
      componentCollection[getMatchedPosition()].maximumValue = value;
    }
    setComponentCollection([...componentCollection]);
    setShowPromptForAdjustScoring(prevState => ({
      ...prevState,
      checkUserDeleteAddOrChangeScoreOfComponent: true,
    }));
  };

  const onChangeMaxLabel = (event, elementFromErrorCollection, temp) => {
    if (
      componentCollection[getMatchedPosition()].searchTerm.careplanComponentId
    ) {
      componentCollection[getMatchedPosition()].searchTerm.maxLabel =
        event.target.value;
    } else {
      componentCollection[getMatchedPosition()].maxLabel = event.target.value;
    }

    if (event.target.value.length > 0) {
      elementFromErrorCollection.maxLabelRequired = false;
    } else {
      elementFromErrorCollection.maxLabelRequired = true;
    }
    hasValidationErrorTemplate({ ...temp });
    setComponentCollection([...componentCollection]);
  };

  const renderNumberAndTextInput = (labelText: string) => {
    const isMin = labelText === t('previewQuestion.minValue');
    const isMax = !isMin;
    const matchingComponent = componentCollection[getMatchedPosition()];
    let hasMinMaxValidationError;
    let validationMessage;
    const existingQuestion = matchingComponent.searchTerm.careplanComponentId;
    const minimumValue = getComponentSettingsElementValue('getMinimumValue');
    const maximumValue = getComponentSettingsElementValue('getMaximumValue');

    if (existingQuestion) {
      hasMinMaxValidationError =
        matchingComponent.searchTerm.minMaxValidationError;
      validationMessage = matchingComponent.searchTerm.validationMessage;
    } else {
      hasMinMaxValidationError =
        matchingComponent.searchTerm.minMaxValidationError;
      validationMessage = matchingComponent.searchTerm.validationMessage;
    }

    const temp = validationErrorTemplate;
    let elementFromErrorCollection =
      temp.templateSettingsValidation.componentSettingsError.length > 0
        ? temp.templateSettingsValidation.componentSettingsError.filter(
            elem => {
              return elem.componentId == matchingComponent.id;
            }
          )
        : [];

    elementFromErrorCollection =
      elementFromErrorCollection.length > 0
        ? elementFromErrorCollection[0]
        : [];

    const isMinimumInputNotValid = () => {
      return minimumValue === '' || isNaN(+minimumValue);
    };

    const isMaximumInputNotValid = () => {
      if (maximumValue === '' || isNaN(+maximumValue)) {
        return true;
      }

      if (hasMinMaxValidationError) {
        const minNumberIsValid = minimumValue !== '' && !isNaN(+minimumValue);
        const minIsGreaterThanMax =
          !isNaN(+maximumValue) && +minimumValue >= +maximumValue;
        return minNumberIsValid && minIsGreaterThanMax;
      }

      return false;
    };

    return (
      <>
        <div style={{ width: '50%', marginRight: '1%' }}>
          {isMin && (
            <NumberInput
              value={getComponentSettingsElementValue('getMinimumValue')}
              name="minimumNumber"
              min={defaultEnums.NUMERIC_RANGE_MIN_VALUE}
              max={defaultEnums.NUMERIC_RANGE_MAX_VALUE}
              step={1}
              hasError={isMinimumInputNotValid()}
              validationMessage={t(validationMessage)}
              placeholder="1"
              isNegativeNumber
              changeValue={e => handleMinNumberOnChange(e)}
            />
          )}
          {isMax && (
            <NumberInput
              value={getComponentSettingsElementValue('getMaximumValue')}
              name="maximumNumber"
              min={defaultEnums.NUMERIC_RANGE_MIN_VALUE}
              max={defaultEnums.NUMERIC_RANGE_MAX_VALUE}
              step={1}
              hasError={isMaximumInputNotValid()}
              validationMessage={t(validationMessage)}
              placeholder="1"
              isNegativeNumber
              changeValue={e => handleMaxNumberOnChange(e)}
            />
          )}
        </div>
        <div style={{ width: '49%' }}>
          {isMin && (
            <TextInput
              value={getComponentSettingsElementValue('getMinLabel') || ''}
              name="minLabel"
              placeholder={t('previewQuestion.labelMinimum')}
              variant="char-count"
              maxLength={MaxCharLimits.carePlan.numericRange}
              maxChars={MaxCharLimits.carePlan.numericRange}
              handleOnChange={e =>
                onChangeMinLabel(e, elementFromErrorCollection, temp)
              }
              hasError={!!elementFromErrorCollection?.minLabelRequired}
              validationMessage={
                elementFromErrorCollection?.minLabelRequired
                  ? t('parsley.requiredField')
                  : ''
              }
            />
          )}
          {isMax && (
            <TextInput
              value={getComponentSettingsElementValue('getMaxLabel') || ''}
              name="maxLabel"
              placeholder={t('previewQuestion.labelMaximum')}
              variant="char-count"
              maxLength={MaxCharLimits.carePlan.numericRange}
              maxChars={MaxCharLimits.carePlan.numericRange}
              handleOnChange={e =>
                onChangeMaxLabel(e, elementFromErrorCollection, temp)
              }
              hasError={!!elementFromErrorCollection?.maxLabelRequired}
              validationMessage={
                elementFromErrorCollection?.maxLabelRequired
                  ? t('parsley.requiredField')
                  : ''
              }
            />
          )}
        </div>
      </>
    );
  };

  const renderLabelWithNumberAndTextInput = (
    labelText: string,
    disableMargin = false
  ) => {
    return (
      <>
        <Box className="toggleAndTextContainer">
          <Flex otherStyles={{ marginBottom: '19px' }}>
            <Label text={labelText} />
          </Flex>
          <Flex
            otherStyles={{
              marginBottom: disableMargin ? 0 : 19,
            }}
          >
            {renderNumberAndTextInput(labelText)}
          </Flex>
        </Box>
      </>
    );
  };

  const renderNumericRangeComponent = () => {
    return (
      <>
        <span className="lineBetweenToggleContainers" />
        {renderLabelWithNumberAndTextInput(t('previewQuestion.minValue'))}
        <span className="lineBetweenToggleContainers" />
        {renderLabelWithNumberAndTextInput(
          t('previewQuestion.maxValue'),
          isNegativeWarningVisible
        )}
        {isNegativeWarningVisible && <ComponentSettingsWarning />}
      </>
    );
  };

  return renderNumericRangeComponent();
};

export default NumericRangeComponent;
