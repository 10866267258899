import React from 'react';
import Loader from 'react-loader-spinner';
import {
  Box,
  Flex,
  Historial,
  HistorialStep,
} from '@ui-common-files/components';
import { PrimaryColor, generateRandomId } from '@ui-common-files/utils';
import { ErrorListProps } from './ErrorList.types';
import ErrorDetails from './ErrorDetails';

const ErrorList: React.FC<ErrorListProps> = ({ sessionErrors }) => {
  return (
    <Box>
      <Box>
        {!sessionErrors && (
          <Flex
            className="modal_content"
            justifyContent="center"
            alignItems="center"
          >
            <Loader
              type="Oval"
              visible
              color={PrimaryColor.MAIN_COLOR}
              height={60}
              width={60}
            />
          </Flex>
        )}
      </Box>
      {sessionErrors && (
        <Box>
          <Historial>
            {Object.entries(sessionErrors).map(([sessionId, sessionErrors]) => (
              <HistorialStep key={sessionId}>
                {sessionErrors.map(sessionError => (
                  <ErrorDetails
                    key={generateRandomId()}
                    sessionError={sessionError}
                    sessionId={sessionId}
                  />
                ))}
              </HistorialStep>
            ))}
          </Historial>
        </Box>
      )}
    </Box>
  );
};

export default ErrorList;
