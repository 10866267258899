import { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import classNames from 'classnames';

import { useCarePathWayBuilderStore } from '../CarePathWayBuilder.store';

const usePanelHeaderService = () => {
  const { nodes, errors, sidebar, hasErrorBasedOnId, updateSidebarVisibility } =
    useCarePathWayBuilderStore(
      useShallow(state => ({
        nodes: state.nodes,
        errors: state.errors,
        sidebar: state.sidebar,
        hasErrorBasedOnId: state.hasErrorBasedOnId,
        updateSidebarVisibility: state.updateSidebarVisibility,
      }))
    );

  const contentClasses = classNames({
    'panel-header_content': true,
    'sidebar-visibility': sidebar.isVisible,
  });

  const settingsHasError = useMemo(
    () => errors.some(error => error.type === 'settings'),
    [errors]
  );

  return {
    nodes,
    errors,
    sidebar,
    contentClasses,
    settingsHasError,
    hasErrorBasedOnId,
    updateSidebarVisibility,
  };
};

export default usePanelHeaderService;
