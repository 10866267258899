/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import I18n from 'i18next';
import useCollapse from 'react-collapsed';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

import { Icon, Box, Flex, Button, Checkbox } from '@ui-common-files/components';
import {
  ButtonType,
  ButtonVariant,
  PrimaryColor,
  CheckBoxRadioVariants,
} from '@ui-common-files/utils';
import { getAppointmentsLabel } from '@utils';

import '../../css/appointments.css';
import { FiltersComponentContext } from './FiltersComponet';

const FilterLayout = ({ label, filters, type }) => {
  const {
    statusesCheckList,
    setStatusesCheckList,
    typesCheckList,
    setTypesCheckList,
  } = useContext(FiltersComponentContext);

  const [allStatusesSelected, setAllStatusesSelected] = useState(false);
  const [allTypesSelected, setAllTypesSelected] = useState(false);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: true,
  });

  const [fetchedFilters, setFetchedFilters] = useState(filters);

  useEffect(() => {
    setFetchedFilters(filters);
  }, [filters]);

  const checkAllStatusesSelected = () => {
    let selected = 0;
    let listLength = 0;
    for (const key in statusesCheckList) {
      listLength++;
      if (statusesCheckList[key]) {
        selected++;
      }
    }

    selected === 0 && setAllStatusesSelected(false);
    if (listLength !== 0 && selected === listLength) {
      setAllStatusesSelected(true);
    }
  };

  useEffect(() => {
    checkAllStatusesSelected();
  }, [statusesCheckList]);

  const checkAllTypesSelected = () => {
    let selected = 0;
    let listLength = 0;
    for (const key in typesCheckList) {
      listLength++;
      if (typesCheckList[key]) {
        selected++;
      }
    }

    selected === 0 && setAllTypesSelected(false);
    if (listLength !== 0 && selected === listLength) {
      setAllTypesSelected(true);
    }
  };

  useEffect(() => {
    checkAllTypesSelected();
  }, [typesCheckList]);

  const clearAll = objectfilter => {
    const auxObjectFilter = objectfilter;
    for (const key in auxObjectFilter) {
      auxObjectFilter[key] = false;
    }
    return auxObjectFilter;
  };

  const selectAll = objectfilter => {
    const auxObjectFilter = objectfilter;
    for (const key in auxObjectFilter) {
      auxObjectFilter[key] = true;
    }
    return auxObjectFilter;
  };

  return (
    <Box width="max-content" margin="5px 0 0 0">
      <span
        className="appointment-manager__header-expand"
        {...getToggleProps()}
      >
        <Icon name={isExpanded ? 'arrowUp' : 'arrowDown'} size={24} />
        {label}
      </span>
      <section {...getCollapseProps()}>
        <div className="appointment-manager__expand-widget">
          {filters.length === 0 ? (
            <Box margin="0 0 0 40px">
              <Loader
                type="Oval"
                visible={filters.length === 0}
                color={PrimaryColor.MAIN_COLOR}
                height={60}
                width={60}
              />
            </Box>
          ) : (
            <Flex flexDirection="column">
              {fetchedFilters.map(status => {
                return (
                  <Box margin="5px 0">
                    {type === 'types' && (
                      <Checkbox
                        id={`type-${status.id}`}
                        name={status.name}
                        label={getAppointmentsLabel(status)}
                        variant={CheckBoxRadioVariants.DEFAULT}
                        checked={typesCheckList[status.appointmentTypeOrder]}
                        handleOnChange={e => {
                          setTypesCheckList({
                            ...typesCheckList,
                            [status.appointmentTypeOrder]: e.target.checked,
                          });
                        }}
                      />
                    )}
                    {type === 'statuses' && (
                      <Checkbox
                        id={`status-${status.id}`}
                        name={status.name}
                        label={getAppointmentsLabel(status)}
                        variant={CheckBoxRadioVariants.DEFAULT}
                        checked={statusesCheckList[status.id]}
                        handleOnChange={e => {
                          setStatusesCheckList({
                            ...statusesCheckList,
                            [status.id]: e.target.checked,
                          });
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Flex>
          )}
          <Box margin="10px 0 0 0">
            {type === 'statuses' && (
              <Button
                label={
                  allStatusesSelected
                    ? I18n.t('common_buttons.clear')
                    : I18n.t('common_buttons.selectAll')
                }
                type={ButtonType.BUTTON}
                variant={ButtonVariant.OUTLINED}
                onClick={() => {
                  if (allStatusesSelected) {
                    const clearedStatuses = clearAll(statusesCheckList);
                    setStatusesCheckList({
                      ...clearedStatuses,
                    });
                  } else {
                    const allSelectedStatuses = selectAll(statusesCheckList);
                    setStatusesCheckList({
                      ...allSelectedStatuses,
                    });
                  }
                }}
              />
            )}
            {type === 'types' && (
              <Button
                label={
                  allTypesSelected
                    ? I18n.t('common_buttons.clear')
                    : I18n.t('common_buttons.selectAll')
                }
                type={ButtonType.BUTTON}
                variant={ButtonVariant.OUTLINED}
                onClick={() => {
                  if (allTypesSelected) {
                    const clearedTypes = clearAll(typesCheckList);
                    setTypesCheckList({
                      ...clearedTypes,
                    });
                  } else {
                    const allSelectedTypes = selectAll(typesCheckList);
                    setTypesCheckList({
                      ...allSelectedTypes,
                    });
                  }
                }}
              />
            )}
          </Box>
        </div>
      </section>
    </Box>
  );
};

FilterLayout.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FilterLayout;
