import { WearablesDataTypes } from '@ui-common-files/utils/componentTypes';
import { OVERALL_SCORE } from './CustomTooltip.constant';

export const isBloodPressure = (id: number) =>
  id === WearablesDataTypes.BLOOD_PREASURE;

export const getUnit = (
  description: string,
  bloodPressureCheck: boolean,
  unit1: string,
  unit2: string
): string => {
  if (description === OVERALL_SCORE) return OVERALL_SCORE;
  if (bloodPressureCheck) return unit1;
  return unit2;
};

export const getDataPointName = (
  isBloodPressureStatus: boolean,
  defaultName: string,
  systolicName: string,
  diastolicName?: string
): string => {
  if (isBloodPressureStatus) {
    return diastolicName || systolicName;
  }
  return defaultName;
};
