/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n from 'i18next';
import cuid from 'cuid';
import Loader from 'react-loader-spinner';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { ApiClient, HideFlashMessage } from '@global/Services';

import {
  checkImageErrors,
  renderFirstColumn,
  renderSecondColumn,
} from './siteHelper';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import { assetUploadPromise } from '../../Utils/Asset/assetHelper';
import serviceConfig from '../../../serviceConfig.json';
import { additionalConsentValidator } from './Sites.util';
import { updateAvatarAsset } from '../../Utils/Asset/assetAvatarHelper';
import avatarType from '../../Utils/Asset/assetAvatarTypes.json';
import 'react-phone-number-input/style.css';
import '../../css/sites.css';
import '../../css/patient.css';

import { SubmissionTypes } from '../../../caro-ui-commonfiles/utils/componentTypes';
import { setCurrentUser } from '../../../reducers/session';

function EditSite(props) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.session);
  const [logo, setLogo] = useState({
    id: cuid(),
    src: props.logo,
  });
  const [siteLogoFile, setSiteLogoFile] = useState(props.logo);
  const [errors, setErrors] = useState({});
  const [validationMessages, setValidationMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const emailFormat =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let inputRef;
  const [privacyPolicyNotValid, setPrivacyPolicyNotValid] = useState({
    type: false,
    size: false,
    name: false,
  });

  const [deleteOperation, setDeleteOperation] = useState(false);
  const base64Image = logo.src.split(';base64,').pop();
  const siteObject = {
    siteName: props.childName,
    siteAddress: props.childAddress.label,
    siteEmail: props.childEmail,
    siteFullPhoneNumber: props.childPhone,
    userId: user.id,
    siteLogo: base64Image,
    collectionId: props.collectionId,
    serviceProviderId: props.childServiceProviderId,
  };

  const additionalConsents = props.privacyPolicyData.additionalConsents;

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if (!file) return;
    const imageError = checkImageErrors(file);
    setErrors(prevErrors => ({
      ...prevErrors,
      siteLogoError: !!imageError,
    }));
    setValidationMessages(prevMessages => ({
      ...prevMessages,
      siteLogoError: imageError,
    }));
    const reader = new FileReader();
    reader.onload = function (e) {
      setLogo({ id: cuid(), src: e.target.result });
    };
    reader.readAsDataURL(file);
    setSiteLogoFile(file);
  }, []);
  const validateInputs = () => {
    const inputValidationErrors: { [key: string]: boolean } = {};
    const inputValidationMessages: { [key: string]: string } = {};
    const email = props.childEmail;
    const phone = props.childPhone;
    const siteLogoError = checkImageErrors(siteLogoFile);
    const isEmailValid = emailFormat.test(String(email).toLowerCase());
    inputValidationErrors.name = !props.childName.trim();
    inputValidationErrors.address = !props.childAddress;
    inputValidationErrors.email = !email || !isEmailValid;
    inputValidationErrors.phone = !phone || !isValidPhoneNumber(phone);
    inputValidationErrors.consent = additionalConsents?.some(
      consent => !additionalConsentValidator(decodeURIComponent(consent))
    );
    inputValidationErrors.fileType = privacyPolicyNotValid.type;
    inputValidationErrors.siteLogoError = !!siteLogoError;

    if (email.length < 1) {
      inputValidationMessages.email = I18n.t('parsley.requiredField');
    } else if (!isEmailValid) {
      inputValidationMessages.email = I18n.t('common_labels.invalid_email');
    }

    if (!isValidPhoneNumber(phone)) {
      inputValidationMessages.phone = I18n.t('site_view.invalidPhone');
    }
    setErrors({
      name: inputValidationErrors.name,
      address: inputValidationErrors.address,
      email: inputValidationErrors.email,
      phone: inputValidationErrors.phone,
      consent: inputValidationErrors.consent,
      fileType: inputValidationErrors.fileType,
      siteLogoError: inputValidationErrors.siteLogoError,
    });

    setValidationMessages({
      name: 'parsley.requiredField',
      address: 'parsley.requiredField',
      email: inputValidationMessages.email,
      phone: !phone
        ? I18n.t('parsley.requiredField')
        : inputValidationMessages.phone,
      siteLogoError,
    });
    return inputValidationErrors;
  };

  const validateAndExecute = () => {
    const validationErrors = validateInputs();
    if (
      validationErrors.name == false &&
      validationErrors.email == false &&
      validationErrors.address == false &&
      validationErrors.phone == false &&
      validationErrors.consent == false &&
      validationErrors.fileType == false &&
      validationErrors.siteLogoError === false
    ) {
      updateSiteClickHandler();
    }
  };

  const updateSiteClickHandler = async () => {
    if (props.privacyPolicyData.isPrivacyPolicyChanged) {
      setDeleteOperation(true);
    } else if (props.privacyPolicyData.isNewPrivacyPolicy) {
      updateSiteWithPrivacyPolicy();
    } else {
      updateSite();
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    validateAndExecute();
    return false;
  };

  const handlePrivacyPolicyUpload = file => {
    const isFileSizeValid =
      file.size <= 30 * Math.pow(10, 6) && file.type == 'application/pdf';
    const isFileNameLimitCorrect = file.name.length <= 50;
    setPrivacyPolicyNotValid({
      type: false,
      size: !isFileSizeValid,
      name: !isFileNameLimitCorrect,
    });
    if (file && isFileSizeValid && isFileNameLimitCorrect) {
      props.setPrivacyPolicyData({
        isPrivacyPolicyChanged: !!props.privacyPolicyData.url,
        isNewPrivacyPolicy: !props.privacyPolicyData.url,
        url: URL.createObjectURL(file),
        name: file.name,
        size: `(${(file.size / Math.pow(10, 6)).toFixed(2)}MB)`,
        additionalConsents,
      });
      props.setPrivacyPolicyFile(file);
    }
  };

  const updateSite = async () => {
    setLoading(true);
    try {
      const response = await updateAvatarAsset(
        props.id,
        siteLogoFile,
        props.collectionId,
        siteObject.siteName,
        avatarType.SITES
      ).then(resource => {
        const { collection, profilePicture } = resource;
        siteObject.collectionId = collection;
        siteObject.siteLogo = profilePicture;
        return ApiClient.POST({
          url: `${serviceConfig.brokerService.updateSite.uri}/${props.id}`,
          payload: siteObject,
        });
      });
      if (response) {
        if (response.data.logo) {
          const updatedUser = {
            ...user,
            siteLogo: response.data.logo.directLink,
          };
          dispatch(setCurrentUser(updatedUser));
        }
        props.showFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
        props.reloadTable();
      }
    } catch (error) {
      props.showFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
    setLoading(false);
    props.closeModal();
  };

  const createFile = async (url, filename) => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: 'application/pdf',
    };
    let file = new File([data], filename, metadata);
    return file;
  };

  const updateSiteWithPrivacyPolicy = async () => {
    setLoading(true);
    const formData = new FormData();
    const encodedConsents = JSON.stringify(
      additionalConsents.map((consent: string) => encodeURIComponent(consent))
    );
    let privacyPolicyFile;
    if (!props.privacyPolicyFile) {
      privacyPolicyFile = await createFile(
        props.privacyPolicyData.url,
        props.privacyPolicyData.name.split('.pdf')[0] + '.pdf'
      );
    } else {
      privacyPolicyFile = props.privacyPolicyFile;
    }
    formData.append('filedata', privacyPolicyFile);
    formData.append('isPrivacyPolicy', true);
    formData.append('additionalConsents', encodedConsents);
    return ApiClient.POST({
      url: serviceConfig.brokerService.createAsset.uri,
      payload: formData,
      timeout: 30000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'form-data',
      },
    })
      .then(createdPrivacyPolicy => {
        siteObject.assetId =
          createdPrivacyPolicy.data.createdRecordIds[0].recordId;
        return assetUploadPromise(createdPrivacyPolicy, privacyPolicyFile).then(
          privacyPolicyAsset => {
            if (!privacyPolicyAsset.stack) {
              siteObject.privacyPolicyAssetLink =
                privacyPolicyAsset[0].directLink;
              siteObject.additionalConsents = additionalConsents
                ? encodedConsents
                : [];
              updateSite();
            } else {
              setLoading(false);
              props.closeModal();
              props.showFlashMessage({
                type: 'error',
                content: I18n.t('flash.sendFailed'),
              });
            }
          }
        );
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  const closeMessagePrompt = () => {
    setDeleteOperation(false);
  };
  return (
    <ModalContent>
      {loading && (
        <div className="roleOverlapSpinner">
          <Loader
            type="Oval"
            visible={true}
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </div>
      )}
      <ModalForm formId="edit-site-form" handleFormSubmit={handleFormSubmit}>
        {renderFirstColumn(
          onDrop,
          props.childName,
          props.childOnChangeNameText,
          errors,
          validationMessages,
          props.childAddress,
          props.childOnChangeAddressText,
          props.childEmail,
          props.childOnChangeEmailText,
          props.childPhone,
          props.childOnChangePhoneText,
          logo,
          props.childOnChangeServiceProviderIdText,
          props.childServiceProviderId
        )}

        {renderSecondColumn(
          handlePrivacyPolicyUpload,
          inputRef,
          privacyPolicyNotValid,
          setPrivacyPolicyNotValid,
          props.privacyPolicyData,
          props.setPrivacyPolicyData,
          errors.consent,
          setErrors
        )}
      </ModalForm>

      {deleteOperation && (
        <Modal
          isConfirmationDialog
          title={I18n.t('privacyPolicy.updatePrivacyPolicy')}
          contentComponent={
            <PromptContent
              message={I18n.t('privacyPolicy.updatePrivacyPolicyMessage')}
            />
          }
          footerComponent={
            <PromptFooter
              close={closeMessagePrompt}
              confirmHandler={() => {
                updateSiteWithPrivacyPolicy();
              }}
              submissionType={SubmissionTypes.CONFIRM}
            />
          }
          openModal={deleteOperation}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </ModalContent>
  );
}

export { EditSite };
