import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Box, Button } from '@ui-common-files/components';

import useOpenModal from '../hooks/useOpenModal';

import emptyChartSvg from '../../../../img/empty-chart.svg';

const NoDataComponent = () => {
  const { t } = useTranslation();

  const { openModal } = useOpenModal();

  return (
    <Flex otherStyles={{ width: '100%', padding: '4px 96px 36px 96px' }}>
      <Flex
        alignItems="center"
        justifyContent="center"
        otherStyles={{ width: '100%' }}
      >
        <Flex flexDirection="column" alignItems="center">
          <img src={emptyChartSvg} />
          <Flex className="overview__no-data__title" justifyContent="center">
            {t('patients_view.wearable_NoGraphData')}
          </Flex>

          <Flex className="overview__no-data__content">
            {t('patients_view.wearable_NoDataDisclaimer')}
          </Flex>

          <Box margin="32px 0 0 0">
            <Button
              label={t('patients_view.overview.button_add_data')}
              onClick={() => {
                openModal();
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NoDataComponent;
