import { useContext } from 'react';

import { PatientOverviewContext } from '../PatientOverview';
import { ApiClient, ShowFlashMessage } from '@global/Services';

import serviceConfig from '../../../../serviceConfig.json';

const useBreadcrumbPatientInfoService = (
  isPatientDemographicData: Boolean,
  patientInfo
) => {
  const {
    showQrCodeModal,
    onEditPatient,
    showPatientDetailsModal,
    isPatientSyncNeeded,
    setIsPatientSyncNeeded,
    showWithingsDeviceModal,
  } = useContext(PatientOverviewContext);

  const onSyncPatient = () => {
    ApiClient.POST({
      url: serviceConfig.brokerService.syncPatientProfile.uri,
      payload: {
        patientId: patientInfo.id,
      },
    }).then(response => {
      if (response?.data) {
        setIsPatientSyncNeeded(false);
        ShowFlashMessage({
          messageKey: response.data.content,
          type: response.data.type,
        });
      }
    });
  };

  const actionsDropdownItems = [
    {
      key: 'qr-code',
      value: 'patientOverview.registrationLetter',
      action: showQrCodeModal,
      icon: 'newQrCode',
    },
    ...(!isPatientDemographicData
      ? [
          {
            key: 'details',
            value: 'patientOverview.patientDetails',
            action: showPatientDetailsModal,
            icon: 'userDetails',
          },
        ]
      : []),
    {
      key: 'edit-patient',
      value: 'patients_view.label_editPatient',
      action: onEditPatient,
      icon: 'Edit',
    },
    ...(isPatientSyncNeeded
      ? [
          {
            key: 'sync',
            value: 'patientOverview.syncPatientDetails',
            action: onSyncPatient,
            icon: 'sync',
          },
        ]
      : []),
    {
      key: 'withings-device',
      value: 'patientOverview.withings_device.common.withings_device',
      action: showWithingsDeviceModal,
      icon: 'WithingsDevice',
    },
  ];

  return {
    actionsDropdownItems,
    onSyncPatient,
  };
};
export default useBreadcrumbPatientInfoService;
