import React, { useState, useEffect, useMemo } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';

import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import IconButtonDropdown from '../../../caro-ui-commonfiles/components/DropdownMenu/IconButtonDropdown';
import ICDViewer from '../../../caro-ui-commonfiles/components/ICD/ICDViewer';
import { Greys, ActionColors } from '../../../caro-ui-commonfiles/utils/colors';
import TagsCell from './CellElements/TagsCell';
import useWindowWidthListener from '../../Utils/hooks/useWindowWidthListener';
import { useResponsiveScreen } from '@ui-common-files/utils/hooks';
import { SCREEN_CATEGORIES } from '@ui-common-files/utils/layout';
import { getMarginRight } from './Carepathway/CarePathwayIndex.util';
import '../../css/aux.css';
import serviceConfig from '../../../serviceConfig.json';
import getAppointmentsLabel from '../../Utils/appointmentManagerUtilities';
import { getDurationTextForOverview } from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import { ApiClient } from '../../Global/Services';

function CarePathwayIndex({
  fetchedData,
  loading,
  onChange,
  currentPage,
  recordsTotal,
  pages,
  onShow,
  onTemplateShow,
  onEdit,
  onAssign,
  onDelete,
  onQrCode,
  onDeleteCareplanTemplate,
  sort,
  setSort,
  borderId,
  onExport,
  setFlashMessage,
}) {
  const [pathwayData, setPathwayData] = useState([]);
  const [expanded, setExpanded] = useState({});
  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];

  const responsive = useWindowWidthListener();
  const screenSize = useResponsiveScreen();
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);
  const marginRightValue = getMarginRight(screenSize);

  useEffect(() => {
    setExpanded({});
  }, [currentPage]);

  const fetchTemplates = async id => {
    setIsTemplatesLoading(id);
    return ApiClient.GET({
      url: serviceConfig.brokerService.getTemplatesByCarePathwayId.uri + id,
    })
      .then(response => {
        setIsTemplatesLoading(false);
        return response.data.data.templates;
      })
      .catch(error => {
        setIsTemplatesLoading(false);
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });
  };

  const getCarePathwayTemplates = (templates, id) => {
    const allAssociatedTemplates = [];
    for (let i = 0; i < templates.length; i++) {
      const carePathwayTemplateInfo =
        templates[i].carePathwayTemplateAppointmentType;
      for (let index = 0; index < carePathwayTemplateInfo.length; index++) {
        const isRoot = carePathwayTemplateInfo[index].isRoot;
        const recurrenceInfo = carePathwayTemplateInfo[index];
        const appointmentType = getAppointmentsLabel({
          appointmentTypeOrder: recurrenceInfo.appointmentTypeId,
        });
        const durationText = getDurationTextForOverview(
          recurrenceInfo,
          appointmentType
        );
        allAssociatedTemplates.push({
          carePathwayTemplateId: id,
          id: templates[i].id,
          name: templates[i].name,
          durationText,
          appointmentType,
          occurrenceId: recurrenceInfo.id,
          startDuration: recurrenceInfo.duration,
          roles: templates[i].roles,
          isAssessment: templates[i].isAssessment,
          isRoot: isRoot,
        });
      }
    }

    return allAssociatedTemplates;
  };

  const careplanSubComponent = (careplan, disableDeletion) => {
    const {
      carePathwayTemplateId,
      id,
      name,
      durationText,
      occurrenceId,
      roles,
      isAssessment,
    } = careplan;
    const rolePatients = [];
    if (!isAssessment) {
      rolePatients.push({ name: I18n.t('role_view.label_patients') });
    }
    let dropDownActions = [];
    if (disableDeletion) {
      dropDownActions = [{ value: I18n.t('actions.show') }];
    } else {
      dropDownActions = [
        { value: I18n.t('actions.show') },
        {
          value: I18n.t('actions.delete'),
        },
      ];
    }

    let actionButtons;

    if (screenSize === SCREEN_CATEGORIES.SCREEN_XL) {
      actionButtons = (
        <>
          <span style={{ marginRight: 176 }}>
            <IconButton
              type="button"
              name="view"
              size={30}
              onClick={() => {
                onTemplateShow(id, name);
              }}
              tooltipText={I18n.t('actions.show')}
            />
          </span>
          <span style={{ marginRight: -6 }}>
            <IconButton
              type="button"
              name="remove"
              size={18}
              fill={ActionColors.DELETE}
              onClick={async () => {
                onDeleteCareplanTemplate(
                  carePathwayTemplateId,
                  id,
                  name,
                  occurrenceId
                );
              }}
              tooltipText={I18n.t('actions.delete')}
              disabled={disableDeletion}
            />
          </span>
        </>
      );
    } else {
      actionButtons = (
        <div style={{ marginRight: marginRightValue }}>
          <IconButtonDropdown
            items={dropDownActions}
            onClick={async (e, action) => {
              if (action.value === I18n.t('actions.show')) {
                onTemplateShow(id, name);
              } else if (action.value === I18n.t('actions.delete')) {
                onDeleteCareplanTemplate(
                  carePathwayTemplateId,
                  id,
                  name,
                  occurrenceId
                );
              }
            }}
          />
        </div>
      );
    }

    return (
      <Flex
        alignItems="center"
        otherStyles={{
          border: '0.2px solid',
          borderColor: Greys.LIGHT_GREY_20,
          flex: '1 0 auto',
        }}
      >
        <div
          className="rt-td"
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: '60 0 auto',
            width: 60,
            maxWidth: 60,
            padding: '8px',
          }}
          role="gridcell"
        >
          <Box width={28}>
            <Icon name={isAssessment ? 'assessment' : 'careplan'} size={28} />
          </Box>
        </div>
        <div
          className="rt-td"
          style={
            responsive
              ? {
                  flex: '200 0 auto',
                  width: 200,
                  maxWidth: 250,
                }
              : {
                  flex: '330 0 auto',
                  width: 330,
                  maxWidth: 400,
                }
          }
          role="gridcell"
        >
          {name}
        </div>
        <div
          className="rt-td"
          style={
            responsive
              ? {
                  flex: '200 0 auto',
                  width: 200,
                  maxWidth: 250,
                }
              : {
                  flex: '150 0 auto',
                  width: 150,
                  maxWidth: 250,
                }
          }
          role="gridcell"
        >
          <Flex otherStyles={{ flexDirection: 'column' }}>
            <Box otherStyles={{ marginBottom: 8 }}>
              <strong>{`${I18n.t('common_labels.start')}: `}</strong>
              {durationText.startText ? durationText.startText : ''}
            </Box>
            <Box>
              <strong>{`${I18n.t('recurrence.repetition')}: `}</strong>
              {durationText.occurrence ? durationText.occurrence : ''}
            </Box>
          </Flex>
        </div>

        <div
          className="rt-td"
          style={{
            flex: '200 0 auto',
            width: 200,
          }}
          role="gridcell"
        >
          <TagsCell tagList={!isAssessment ? rolePatients : roles} roles />
        </div>
        <div
          className="rt-td"
          role="gridcell"
          style={{
            flex: '160 0 auto',
            width: 160,
          }}
        />
        <div
          className="rt-td"
          style={
            responsive
              ? {
                  flex: '100 0 auto',
                  width: 100,
                  maxWidth: 100,
                  display: 'flex',
                  overflow: 'visible',
                  justifyContent: 'flex-end',
                }
              : {
                  flex: '240 0 auto',
                  width: 240,
                  maxWidth: 240,

                  display: 'flex',
                  overflow: 'visible',
                  justifyContent: 'flex-end',
                  marginRight: '16px',
                }
          }
          role="gridcell"
        >
          {actionButtons}
        </div>
        <div
          className="rt-td"
          style={{
            flex: '35 0 auto',
            width: 35,
            maxWidth: 35,
          }}
          role="gridcell"
        />
      </Flex>
    );
  };

  const isExpandedRow = (index, test) => {
    const expandedKey = Object.keys(test);
    if (expandedKey.length > 0) {
      const indexFound = expandedKey.find(item => item == index);
      return indexFound > -1 && test[`${indexFound}`];
    }
    return false;
  };

  const subComponent = ({ original }) => {
    if (isTemplatesLoading === original.id) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{
            left: '50%',
          }}
        >
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Flex>
      );
    } else {
      const { templates } = original;

      if (templates && templates.length > 0) {
        return templates.map(careplan =>
          careplanSubComponent(careplan, templates.length === 1)
        );
      }
      return null;
    }
  };
  const tableProps = {
    defaultSorted: [
      {
        id: 'name',
        desc: false,
      },
    ],
    onSortedChange: newSorted => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    SubComponent: subComponent,
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
    getTrProps: (state, rowInfo) => {
      if (rowInfo) {
        return {
          style: {
            boxShadow: '0 1px 5px 0 rgba(205, 205, 205, 0.5)',
            borderRadius: '0px',
            border:
              borderId == rowInfo.original.id ? 'solid 1px #3ebce8' : '0px',
          },
        };
      }
      return {};
    },
    getTrGroupProps: (state, rowInfo) => {
      if (rowInfo) {
        return {
          className: 'care-pathway__rt-tr-group',
        };
      }
      return {};
    },
    expanded,
    onExpandedChange: (newExpanded, index, event) => {
      setExpanded(newExpanded);
      if (isExpandedRow(index, newExpanded) && pathwayData.length) {
        const { id } = pathwayData[index];
        let pathwayDataCopy = [...pathwayData];
        return Promise.all(
          pathwayDataCopy.map(async elem => {
            if (elem.id == id) {
              elem.templates = await fetchTemplates(id);
              elem.templates =
                elem.templates.length > 0
                  ? await getCarePathwayTemplates(elem.templates, elem.id)
                  : [];
              elem.templates =
                elem.templates &&
                elem.templates.filter(careplan => careplan.isRoot);
            }
            return elem;
          })
        ).then(() => {
          setPathwayData(pathwayDataCopy);
        });
      }
    },
  };

  useEffect(() => {
    if (fetchedData) {
      setPathwayData(fetchedData);
    }
  }, [fetchedData]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'carePathwayIndicator',
        filterable: false,
        maxWidth: 60,
        style: {
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        },
        headerStyle: {
          marginRight: -20,
        },
        Cell: props => {
          return (
            <Box width={30}>
              <Icon name="carepathway" size={30} />
            </Box>
          );
        },
      },
      {
        Header: ({ column }) => {
          const { id } = column;
          return (
            <>
              {sort.column === id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <Box>{I18n.t('newCareplan_view.title')}</Box>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'name',
        filterable: false,
        maxWidth: responsive ? 250 : 400,
        minWidth: responsive ? 200 : 330,
        style: {
          fontWeight: 600,
          wordBreak: 'break-word',
        },
        headerStyle: {
          marginRight: 20,
        },
      },
      {
        Header: () => {
          return <span>{I18n.t('common_labels.label_reference')}</span>;
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'reference',
        sortable: false,
        filterable: false,
        minWidth: responsive ? 200 : 150,
        maxWidth: 250,
      },
      {
        Header: () => {
          return <span>{I18n.t('newCareplan_view.conducted_by')}</span>;
        },
        accessor: 'roles',
        sortable: false,
        filterable: false,
        style: { overflow: 'visible' },
        minWidth: 150,
      },
      {
        Header: () => {
          return <span>{I18n.t('newCareplan_view.icd_code')}</span>;
        },
        accessor: 'icds',
        sortable: false,
        filterable: false,
        style: { overflow: 'visible' },
        minWidth: 215,
        Cell: ({ row }) => {
          return <ICDViewer icds={row.icds} />;
        },
      },
      {
        Header: '',
        accessor: 'buttons',
        sortable: false,
        filterable: false,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: responsive ? 'flex-end' : 'space-between',
          marginRight: responsive ? '6px' : '16px',
        },
        minWidth: responsive ? 60 : 240,
        maxWidth: responsive ? 100 : 240,
        Cell: ({ original }) => {
          const {
            id,
            carepathwayTemplateUUID,
            careplanTemplates,
            allFutureCareplans,
          } = original;
          const title = original.name;
          let isAssessment = true;
          const services = useSelector(state => state.services);
          for (let i = 0; i < careplanTemplates.length; i++) {
            if (
              !careplanTemplates[i].isAssessment &&
              careplanTemplates[i].carePathwayTemplateAppointmentType.isRoot
            ) {
              isAssessment = false;
              break;
            }
          }
          let smallScreenOptions = [
            {
              value: I18n.t('actions.show'),
            },
            {
              value: I18n.t('actions.edit'),
            },
          ];
          if (
            services.hasOwnProperty('appointmentservice.myoncare.care') &&
            services.hasOwnProperty('careplanservice.myoncare.care')
          ) {
            smallScreenOptions.push({
              value: I18n.t('actions.assign'),
            });
          }
          if (!isAssessment) {
            smallScreenOptions.push({
              value: I18n.t('actions.qrCode'),
            });
          }
          smallScreenOptions.push(
            {
              value: I18n.t('actions.export'),
            },
            {
              value: I18n.t('actions.delete'),
            }
          );

          return (
            <>
              {!responsive ? (
                <>
                  <span style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="view"
                      size={30}
                      onClick={() => {
                        onShow(id, title);
                      }}
                      tooltipText={I18n.t('actions.show')}
                    />
                  </span>
                  <span style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="edit"
                      size={30}
                      onClick={() => {
                        onEdit(id, title);
                      }}
                      tooltipText={I18n.t('actions.edit')}
                    />
                  </span>

                  <span style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="qrCode"
                      disabled={!!isAssessment}
                      size={30}
                      onClick={() => {
                        onQrCode(
                          id,
                          title,
                          carepathwayTemplateUUID,
                          allFutureCareplans
                        );
                      }}
                      tooltipText={
                        !isAssessment
                          ? I18n.t('actions.qrCode')
                          : I18n.t('actions.qrCodeDisable')
                      }
                    />
                  </span>

                  <span style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="assign"
                      size={30}
                      onClick={() => {
                        onAssign(id, title, allFutureCareplans);
                      }}
                      tooltipText={I18n.t('actions.assign')}
                    />
                  </span>
                  <span style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="export"
                      size={30}
                      onClick={() => {
                        onExport(id);
                      }}
                      tooltipText={I18n.t('actions.export')}
                    />
                  </span>
                  <span style={{ marginRight: 70 }}>
                    <IconButton
                      type="button"
                      name="delete"
                      size={30}
                      fill={ActionColors.DELETE}
                      onClick={async () => {
                        onDelete(id, title);
                      }}
                      tooltipText={I18n.t('actions.delete')}
                    />
                  </span>
                </>
              ) : (
                <div>
                  <IconButtonDropdown
                    items={smallScreenOptions}
                    onClick={async (e, action) => {
                      if (action.value === I18n.t('actions.show')) {
                        onShow(id, title);
                      } else if (action.value === I18n.t('actions.delete')) {
                        onDelete(id, title);
                      } else if (action.value === I18n.t('actions.assign')) {
                        onAssign(id, title, allFutureCareplans);
                      } else if (action.value === I18n.t('actions.edit')) {
                        onEdit(id, title);
                      } else if (action.value === I18n.t('actions.export')) {
                        onExport(id);
                      } else if (action.value === I18n.t('actions.qrCode')) {
                        onQrCode(
                          id,
                          title,
                          carepathwayTemplateUUID,
                          allFutureCareplans
                        );
                      }
                    }}
                  />
                </div>
              )}
            </>
          );
        },
      },
      {
        expander: true,
        Expander: props => {
          const { id } = props.original;
          return (
            <Icon
              name={props.isExpanded ? 'arrowDown' : 'arrowRight'}
              size={22}
            />
          );
        },
      },
    ];
  }, [
    responsive,
    sort.column,
    sort.dir,
    onShow,
    onEdit,
    onAssign,
    onDelete,
    onExport,
  ]);

  return (
    <DataTable
      data={pathwayData}
      columns={columns}
      pageSize
      pageSizeOptions={items}
      pagination
      isLoading={loading}
      tableProps={tableProps}
      onTableChange={onChange}
      page={currentPage}
      pages={pages}
      totalRecord={recordsTotal}
    />
  );
}

CarePathwayIndex.propTypes = {
  recordsTotal: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  setSort: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sort: PropTypes.shape({
    dir: PropTypes.string,
    column: PropTypes.string,
  }).isRequired,
  fetchedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onShow: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onTemplateShow: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteCareplanTemplate: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default CarePathwayIndex;
