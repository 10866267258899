import React from 'react';

import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import { Greys, ActionColors } from '../../utils/colors';
import { getMentionDetails } from '../../utils';

import './MentionItem.styles.scss';

const MentionItem = ({ mention, insertMention }) => {
  const { type } = mention;
  const iconFill = type === 'user' ? ActionColors.INFO : Greys.DARK_GREY_70;
  const iconName = type === 'user' ? 'User' : 'UserGroup';
  const { name } = getMentionDetails(mention)
  return (
    <li key={mention.id} onClick={() => insertMention(mention)}>
      <Box className="mention-info">
        <Icon name={iconName} fill={iconFill} size={25} />
        <Box>{name}</Box>
      </Box>
    </li>
  );
};
export default MentionItem;
