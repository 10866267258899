import { useState, useEffect } from 'react';

function useNewHeight(currentHeight) {
  const [height, setHeight] = useState(currentHeight);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight;
      setHeight(newHeight);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return height;
}

export default useNewHeight;
