/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Sortly, {
  ContextProvider,
  useDrag,
  useDrop,
  findDescendants,
  findParent,
} from 'react-sortly';
import PropTypes from 'prop-types';
import '../../styles/main.scss';
import { Flipper, Flipped } from 'react-flip-toolkit';

const DnDLayoutProvider = ({ children }) => (
  <DndProvider backend={HTML5Backend} context={window}>
    <ContextProvider>{children}</ContextProvider>
  </DndProvider>
);

DnDLayoutProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

const DnDItem = ({ depth, component, variant, id }) => {
  const [, drag] = useDrag();
  const [, drop] = useDrop();

  return (
    <Flipped flipId={id}>
      <div ref={drop} className={`dnd-item${variant}`}>
        <div
          ref={drag}
          style={{
            marginLeft: depth * 20,
          }}
        >
          <div>{component}</div>
        </div>
      </div>
    </Flipped>
  );
};

DnDItem.propTypes = {
  component: PropTypes.object.isRequired,
  depth: PropTypes.number.isRequired,
};

const DnDView = ({ component, items, onChange }) => {
  const getVariant = (index, depth) => {
    const parent = findParent(items, index);
    if (parent) {
      const parentIndex = items.indexOf(parent);
      const parentDescendants = findDescendants(items, parentIndex);
      if (items[index] === parentDescendants[parentDescendants.length - 1]) {
        return ' indented last';
      }
    }
    if (depth === 1) {
      return ' indented';
    }
    return '';
  };

  const handleChange = newList => {
    const indentedList = newList.map(item => {
      if (item.depth > 1) {
        item.depth = 1;
      }
      return item;
    });
    onChange(indentedList);
  };
  return (
    <Flipper flipKey={items.map(({ id }) => id).join('.')}>
      <Sortly items={items} onChange={handleChange}>
        {({ index, data, depth }) => {
          return (
            <DnDItem
              depth={depth}
              component={component({
                index,
                data,
                depth,
                hasDescendants: findDescendants(items, index).length > 0,
              })}
              variant={getVariant(index, depth)}
              id={data.id}
            />
          );
        }}
      </Sortly>
    </Flipper>
  );
};
DnDView.propTypes = {
  component: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { DnDLayoutProvider, DnDView };
