import React from 'react';
import '../../styles/main.scss';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import Icon from '../Icon/Icon';
import DropdownMenu from './DropdownMenu';
import Button from '../Button/Button';
import { ButtonSize, ButtonType, ButtonVariant } from '../../utils/button';

const ExportButtonDropdown = ({
  items,
  buttonLabel,
  buttonType,
  variant,
  onClick,
  disabled,
  fullWidth,
  size
}) => {
  const buildItems = () => {
    const result = items.map(function(item, index) {
      return (
        <li style={{ width: "280px", paddingLeft: "15px" }} key={item.key} onClick={e => onClick(e, item)}>
          <span>
            <Icon
              name={item.icon}
              size={20}
              fill="#000000"
              className="dropdown_icon-left"
            />
          </span>
          <span className="dropdown__item">{I18n.t(item.value)}</span>
        </li>
      );
    });
    return  [...result];
  };

  return (
    <DropdownMenu items={buildItems()} variant="button-dropdown">
      <Button label={buttonLabel} type={buttonType} size={size} variant={variant} disabled={disabled} fullWidth={fullWidth} />
    </DropdownMenu>
  );
};
ExportButtonDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  labelHeader: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  buttonType: PropTypes.oneOf([
    ButtonType.BUTTON,
    ButtonType.SUBMIT,
    ButtonType.RESET,
  ]),
  variant: PropTypes.oneOf([ButtonVariant.CONTAINED, ButtonVariant.OUTLINED]),
  buttonLabel: PropTypes.string.isRequired,
  fullWidth: false,
  size: ButtonSize.MD,
};

export default ExportButtonDropdown;
