import React from 'react';
import FlashMessageParams from '../../types/Common/FlashMessage.type';
import { User } from '../../types/User';

export interface GetUserRoleResponse {
  user: User;
}

export interface GetUserRolePayload {
  id: string;
}

export type SetFlashMessage = React.Dispatch<
  React.SetStateAction<FlashMessageParams>
>;

export type CarePlanExportData =
  | { id: number; name: string; template: { hasSubscoreFormula: boolean } }[]
  | null;

export enum CalendarCareTaskEventStatus {
  Deactivated,
  Activated,
  Sent,
  Answered,
}
