import moment, { Moment } from 'moment';
import I18n from 'i18next';

import { DateFormat } from '@ui-common-files/utils/dateUtils/dateUtil.type';

function isValidDate(date: string | Date) {
  const checkDate = moment(date);
  return checkDate.isValid();
}

export default function formatDate(
  date: string | Date,
  format: string = 'DD/MM/YYYY',
  local: string = I18n.language
): string {
  if (!isValidDate(date)) {
    return 'N/A';
  }
  return moment(date).locale(local).format(format);
}

export function formatStartOfDay(
  date: Date | string | Moment,
  dateFormat: string = DateFormat.DATE_HOUR_MINUTE_SECOND
) {
  const momentDate: Moment = moment.isMoment(date) ? date : moment(date);
  const startOfDayDate = momentDate.startOf('day').toDate();

  return formatDate(startOfDayDate, dateFormat);
}
