import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Moment from 'moment';
import I18n from 'i18next';

import { ApiClient } from '@global/Services';

import {
  DataTable,
  Icon,
  Box,
  FlashMessage,
} from '@ui-common-files/components';
import ModalSection from '../common/Layout/Modal/ModalSection';

import serviceConfig from '../../../serviceConfig.json';

const Occurrences = ({ data }) => {
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'id',
  });
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flashMessageDatatable, setFlashMessageDatatable] = useState({});
  const [query, setQuery] = useState({
    value: '',
    start: 0,
    length: 10,
    dir: 'asc',
    column: 'id',
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageState, setPageState] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(0);

  const prepareRowsForDatatables = occurrence => {
    const unixEpochs = Moment.unix(occurrence.unixEpochs)
      .utc()
      .locale(I18n.language)
      .format('D MMM YYYY HH:mm');
    const validUntil = Moment.unix(occurrence.unixEpochs)
      .utc()
      .add(occurrence.lifespan, occurrence.lifespanUnit)
      .locale(I18n.language)
      .format('D MMM YYYY HH:mm');
    let sentTime;
    if (
      occurrence.sentTime === 'usedForOnboarding' ||
      occurrence.sentTime === 'N/A'
    ) {
      sentTime = I18n.t(`occurenceView.${occurrence.sentTime}`);
    } else {
      sentTime = occurrence.sentTime
        ? Moment(occurrence.sentTime)
            .locale(I18n.language)
            .local()
            .format('D MMM YYYY HH:mm')
        : I18n.t('occurenceView.scheduled');
    }
    const notificationSent = occurrence.notificationSent
      ? I18n.t(`occurenceView.${occurrence.notificationSent}`)
      : I18n.t('occurenceView.scheduled');

    let receivedTime;

    if (occurrence.isAnswered && !occurrence.receivedTime) {
      receivedTime = I18n.t('occurenceView.notAvailable');
    } else {
      receivedTime = occurrence.receivedTime
        ? Moment(occurrence.receivedTime)
            .utc()
            .locale(I18n.language)
            .utcOffset(data.deviceOffset)
            .format('D MMM YYYY HH:mm')
        : I18n.t('occurenceView.notSeen');
    }

    return {
      id: occurrence.id,
      unixEpochs,
      validUntil,
      sentTime,
      notificationSent,
      receivedTime,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await ApiClient.GET({
        url:
          serviceConfig.brokerService.getCareplanOccurrencesByCareplanId.uri +
          data.carePlanId,
        payload: {
          query,
          userLanguage: I18n.language,
        },
      });
      if (result?.data) {
        const { occurrences, recordsTotal } = result.data;

        setFetchedData(
          occurrences.map(occurrence => {
            return prepareRowsForDatatables(occurrence);
          })
        );
        setRecordsTotal(recordsTotal);
      }
      setLoading(false);
    };

    fetchData();
  }, [query]);

  useEffect(() => {
    setQuery({
      start: currentPage * pageState,
      length: pageState,
      dir: sort.dir,
      column: sort.column,
      value: query.value,
    });
  }, [currentPage, pageState, sort]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];

  const tableProps = {
    defaultSorted: [
      {
        id: 'id',
        desc: false,
      },
    ],
    onSortedChange: (newSorted, column, shiftKey) => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
  };

  const columns = [
    {
      Header: I18n.t('occurenceView.id'),
      headerClassName: 'data-table__column-1__header',
      accessor: 'id',
      filterable: false,
      sortable: false,
      style: {
        fontWeight: 600,
        wordBreak: 'break-word',
      },
    },
    {
      Header: ({ column }) => {
        const { id } = column;
        return (
          <>
            {sort.column === id && (
              <Box width={20}>
                <Icon
                  name="sortArrow"
                  size={22}
                  className={
                    sort.column === id && sort.dir === 'desc' ? 'desc-sort' : ''
                  }
                />
              </Box>
            )}
            <span>{I18n.t('occurenceView.dateScheduled')}</span>
          </>
        );
      },
      headerClassName: 'data-table__column-1__header',
      accessor: 'unixEpochs',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('occurenceView.validUntil'),
      headerClassName: 'data-table__column-1__header',
      accessor: 'validUntil',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('occurenceView.dateSeen'),
      headerClassName: 'data-table__column-1__header',
      accessor: 'receivedTime',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('occurenceView.notificationStatus'),
      headerClassName: 'data-table__column-1__header',
      accessor: 'notificationSent',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('occurenceView.notificationDate'),
      headerClassName: 'data-table__column-1__header',
      accessor: 'sentTime',
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <>
      <ModalSection hasPadding>
        {flashMessageDatatable.content && (
          <FlashMessage
            message={I18n.t(flashMessageDatatable.content)}
            type={flashMessageDatatable.type}
            onClick={() => {
              setFlashMessageDatatable({});
            }}
          />
        )}
        <DataTable
          data={fetchedData}
          columns={columns}
          pageSize={false}
          pageSizeOptions={items}
          pagination
          isLoading={loading}
          tableProps={tableProps}
          onTableChange={(index, size) => {
            setCurrentPage(
              fetchedData && index * size > recordsTotal ? 0 : index
            );
            setPageState(size);
          }}
          page={currentPage}
          pages={Math.ceil(recordsTotal / query.length)}
          totalRecord={recordsTotal}
        />
      </ModalSection>
    </>
  );
};

export default withTranslation()(Occurrences);
