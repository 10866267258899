import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import { Greys } from '../../../../caro-ui-commonfiles/utils/colors';

import { PatientOverviewContext } from '../../PatientOverview/PatientOverview';
import IconButtonDropdown from '../../../../caro-ui-commonfiles/components/DropdownMenu/IconButtonDropdown';
import FileModal from '../FileModal';
import { useUserPermission } from '../../../Utils/hooks/getUserPermission';

const SendMessageForm = ({
  isEnabled,
  sendMessage,
  handleImageUpload,
  startTyping,
  stopTyping,
  fileName,
  handleAttachment,
  isPatientUsingNewVersion,
}) => {
  const imageUploader = useRef(null);
  const hasAssetSharePermission = useUserPermission(
    I18n.t('types.componentType.assets'),
    I18n.t('actions.share'),
    true
  );

  const hasAssetCreatePermission = useUserPermission(
    I18n.t('types.componentType.assets'),
    I18n.t('actions.create'),
    true
  );

  const [message, setMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const chatFooter = useRef({ current: null });

  const dispatchTimer = (state, timer) => {
    if (timer === null && state) {
      clearTimeout(state);
    }
    return timer;
  };

  const setOpen = val => {
    setOpenModal(val);
  };

  const [typingTimer, setTypingTimer] = useReducer(dispatchTimer, null);

  const { setFlashMessage } = useContext(PatientOverviewContext);

  useEffect(() => {
    setMessage(fileName);
  }, [fileName]);

  const handleChange = e => {
    if (e.target.value && e.target.value.length > 500) return;
    setMessage(e.target.value);
  };

  const isInputEmpty = message.trim() === '';

  const handleSubmit = e => {
    e.preventDefault();
    if (!isInputEmpty && isEnabled.isEnabled && message.length <= 500) {
      if (fileName.length) {
        handleImageUpload();
      } else {
        sendMessage(message, fileName.length > 0);
      }
    } else {
      setFlashMessage({
        type: 'warning',
        content: isEnabled.message,
      });
    }
    if (message.length <= 500) {
      setMessage('');
    }
  };

  const clearInputValueOnClick = e => {
    e.target.value = '';
  };

  const handleOnchange = e => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();

      reader.onload = ev => {
        handleAttachment({ data: ev.target.result, file });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="chat__input-box" ref={chatFooter}>
        {!isEnabled.isEnabled && (
          <div
            className="chat__cover"
            style={{
              height: chatFooter.current
                ? chatFooter.current.clientHeight
                : 100,
            }}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              otherStyles={{ width: 30 }}
            >
              <Icon name="info" size={24} fill={Greys.DARK_GREY} />
            </Flex>
            {I18n.t(isEnabled.message)}
          </div>
        )}
        <form className="chat__form" onSubmit={handleSubmit}>
          <textarea
            type="text"
            value={message}
            onChange={handleChange}
            onKeyDown={() => {
              if (isEnabled.isEnabled) {
                setTypingTimer(null);
                startTyping();
              }
            }}
            onKeyUp={() => {
              if (!typingTimer && isEnabled.isEnabled)
                setTypingTimer(setTimeout(stopTyping, 3000));
            }}
            onBlur={() => {
              if (isEnabled.isEnabled) {
                setTypingTimer(null);
                stopTyping();
              }
            }}
            placeholder={I18n.t('chatMessages.typeAMessage')}
            className="chat__input"
            wrap="soft"
            onKeyPress={e => {
              const isEnterKey = e.which === 13;
              if (message.length >= 500) {
                e.preventDefault();
              }
              if (isEnterKey && !isInputEmpty && message.length <= 500) {
                handleSubmit(e);
              }
            }}
          />
          <div className="chat__input__counter">
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              otherStyles={{ flex: 1 }}
            >
              <input
                type="file"
                id="fileUpload"
                onChange={e => handleOnchange(e)}
                onClick={e => clearInputValueOnClick(e)}
                ref={imageUploader}
                hidden
              />
              <IconButtonDropdown
                iconName="attachment"
                onClick={(e, action) => {
                  if (action.value === I18n.t('asset_view.uploadedFiles')) {
                    if (hasAssetSharePermission) {
                      if (isPatientUsingNewVersion) {
                        setOpen(true);
                      } else {
                        setFlashMessage({
                          content: I18n.t('asset_view.feature_not_available'),
                          type: 'warning',
                        });
                      }
                    } else {
                      setFlashMessage({
                        content: I18n.t('common_labels.permissionDenied'),
                        type: 'warning',
                      });
                    }
                  } else if (hasAssetCreatePermission) {
                    if (isPatientUsingNewVersion) {
                      imageUploader.current.click();
                    } else {
                      setFlashMessage({
                        content: I18n.t('asset_view.feature_not_available'),
                        type: 'warning',
                      });
                    }
                  } else {
                    setFlashMessage({
                      content: I18n.t('common_labels.permissionDenied'),
                      type: 'warning',
                    });
                  }
                }}
                items={[
                  { value: I18n.t('asset_view.uploadedFiles') },
                  { value: I18n.t('asset_view.browsePc') },
                ]}
                isAttachAsset
                variant="chat__file__dropdown"
              />
              <button
                type="submit"
                disabled={isInputEmpty}
                className="chat__input-btn"
              >
                <Icon
                  name="sendMessage"
                  size={30}
                  fill={isInputEmpty ? Greys.LIGHT_GREY_50 : Greys.DARK_GREY}
                  style={{ marginBottom: '15px', marginLeft: '16px' }}
                />
              </button>
            </Flex>
            <span className="icd-select__input__counter">
              {`${message.length}/500`}
            </span>
          </div>
        </form>
      </div>
      <FileModal openModal={openModal} setOpenModal={setOpen} />
    </>
  );
};

SendMessageForm.propTypes = {
  isEnabled: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  sendMessage: PropTypes.func.isRequired,
  startTyping: PropTypes.func.isRequired,
  stopTyping: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  handleAttachment: PropTypes.func.isRequired,
  isPatientUsingNewVersion: PropTypes.bool.isRequired,
};

export default SendMessageForm;
