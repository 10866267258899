import { EdgeMarkerType, MarkerType, getIncomers, Node, Edge } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import dagre from '@dagrejs/dagre';

import {
  getCareplanDuration,
  getDurationObjectForCareplan,
  getLifespanObject
} from '@ui-common-files/utils';
import { Greys } from '@ui-common-files/utils/colors';
import { ScoringTypes } from '@type/Careplan';

import { TransformedNode } from './CarePathWayBuilder.type';
import {
  NODE_WIDTH,
  NODE_HEIGHT,
  NODE_SORT_ORDER,
} from './CarePathWayBuilder.constant';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export const getEdgeColor = (sourceHandle: string) => {
  switch (sourceHandle) {
    case ScoringTypes.Well:
      return 'green';
    case ScoringTypes.Fair:
      return 'orange';
    case ScoringTypes.Poor:
      return 'red';
    default:
      return Greys.LIGHT_GREY_75;
  }
};

export const getEdgeMarkerEnd = (edgeColor: string): EdgeMarkerType => {
  return {
    type: MarkerType.ArrowClosed,
    color: edgeColor,
    width: 20,
    height: 20,
  };
};

export const addNewCSSClassToNode = (
  classNames: string,
  newClassName: string
): string => {
  return classNames.indexOf(newClassName) === -1
    ? `${classNames} ${newClassName}`
    : classNames;
};

export const removeCSSClassName = (
  classNames: string,
  className: string
): string => {
  const newClassNames = classNames.split(' ');
  return newClassNames.filter(CSSClass => CSSClass !== className).join(' ');
};

export const getChildNode = (
  edges: Edge[],
  nodes: Node[],
  sourceId: string,
  result: TransformedNode[] = []
): TransformedNode[] => {
  const edgeFrom = edges.filter(edge => edge.source === sourceId);
  if (edgeFrom.length === 0) return result;

  edgeFrom.forEach(edge => {
    const nodeInfo = nodes.find(node => node.id === edge.target);
    const lifespanUnit = getLifespanObject(nodeInfo.data.careTaskData.lifespanUnit)
    const { duration } = nodeInfo.data;
    result.push({
      id: edge.target,
      parentId: sourceId,
      templateId: nodeInfo.data.careTaskData.id,
      metadata: { position: nodeInfo.position },
      carePathwayTemplateAppointmentType: {
        lifespanUnit: duration
          ? duration.lifespanUnit
          : lifespanUnit,
        lifespan: duration
          ? duration.lifespan
          : nodeInfo.data.careTaskData?.lifespan,
      },
    });

    getChildNode(edges, nodes, edge.target, result);
  });
  return result;
};

export const getTransformedDurationObject = (duration: object) => {
  const DEFAULT_START_TIME = { hour: new Date().getHours(), minute: 0 };
  const newDuration = { ...duration };

  if (typeof newDuration.startTime === 'undefined') {
    newDuration.startTime = DEFAULT_START_TIME;
  } else if (typeof newDuration.startTime.hour === 'undefined') {
    newDuration.startTime = {
      hour: parseInt(newDuration.startTime.split(':')[0], 10),
      minute: parseInt(newDuration.startTime.split(':')[1], 10),
    };
  }

  if (typeof newDuration.occurrenceCount.value === 'undefined')
    newDuration.occurrenceCount = {
      value: newDuration.occurrenceCount,
      label: newDuration.occurrenceCount,
    };

  newDuration.appointmentDate = null;

  return { ...newDuration };
};

export const transformPathWayTemplateToSortedFlatList = templates => {
  const flatTemplateList = [];
  templates.forEach(template => {
    template.recurrences.forEach(recurrence => {
      const recurrenceNodes = [...recurrence.nodes];
      const duration = getDurationObjectForCareplan(recurrence);
      const durationObject = getCareplanDuration(duration);

      if (recurrenceNodes.length === 0) {
        recurrenceNodes.push({
          id: uuidv4(),
          templateId: template.id,
          template: {
            scoringScheme: template.scoringScheme,
            id: template.id,
            name: template.name,
          },
          parentId: null,
        });
      }
      const objectToAdd = {
        ...template,
        ...recurrence,
        id: template.id,
        nodes: recurrenceNodes,
        startDateObject: durationObject.startDate,
      };
      delete objectToAdd.carePathwayTemplateAppointmentType;
      delete objectToAdd.recurrences;
      flatTemplateList.push(objectToAdd);
    });
  });

  return flatTemplateList.sort((a, b) => a.startDateObject - b.startDateObject);
};

export const sortNodesBasedOnSourceSchema = (nodes, edges) => {
  const nodeConditionMap = {};
  edges.forEach(edge => {
    nodeConditionMap[edge.target] = edge.condition;
  });

  const sortedNodes = nodes.sort((a, b) => {
    const conditionA = nodeConditionMap[a.id];
    const conditionB = nodeConditionMap[b.id];

    const orderA = NODE_SORT_ORDER.indexOf(conditionA);
    const orderB = NODE_SORT_ORDER.indexOf(conditionB);

    return orderA - orderB;
  });

  return sortedNodes;
};

export const clearDagreGraph = () => {
  const currentNodes = dagreGraph.nodes();
  currentNodes.forEach(graphNodeId => {
    dagreGraph.removeNode(graphNodeId);
  });
};

export const getLayoutedNodes = (nodes, edges) => {
  dagreGraph.setGraph({ rankdir: 'TB' });
  const sortedNode = sortNodesBasedOnSourceSchema(nodes, edges);
  sortedNode.forEach(node => {
    dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
  });

  const sortedEdges = edges.sort((a, b) => {
    const orderA = NODE_SORT_ORDER.indexOf(a.condition);
    const orderB = NODE_SORT_ORDER.indexOf(b.condition);

    return orderA - orderB;
  });

  sortedEdges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  sortedNode.forEach(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = {
      x: nodeWithPosition.x - NODE_WIDTH / 2,
      y: nodeWithPosition.y - NODE_HEIGHT / 2,
    };
    return node;
  });

  return sortedNode;
};

export const getIncomingDepth = (
  node: Node,
  nodes: Node[],
  edges: Edge[],
  currentDepth: number = 0
): number => {
  const incomersArray = getIncomers(node, nodes, edges);
  if (incomersArray.length > 0)
    return getIncomingDepth(incomersArray[0], nodes, edges, currentDepth + 1);

  return currentDepth;
};

export const getMaxOutgoersDepth = (
  targetNodeId: string,
  nodes: Node[],
  edges: Edge[],
  currentDepth: number = 1
): number => {
  const connectedEdges = edges.filter(
    (edge: Edge) => edge.source === targetNodeId
  );

  if (connectedEdges.length === 0) {
    return currentDepth;
  }

  let maxDepth = currentDepth;

  connectedEdges.forEach(edge => {
    const targetNode = nodes.find(n => n.id === edge.target);
    const depth = getMaxOutgoersDepth(
      targetNode.id,
      nodes,
      edges,
      currentDepth + 1
    );
    maxDepth = Math.max(maxDepth, depth);
  });

  return maxDepth;
};

export const hasCycle = (nodes: Node[], edges: Edge[]) => {
  const graph = {};

  // Create an adjacency list representation of the graph
  edges.forEach(edge => {
    if (!graph[edge.source]) {
      graph[edge.source] = [];
    }
    graph[edge.source].push(edge.target);
  });

  // Helper function for DFS (Depth-First Search)
  function dfs(node, visited, stack) {
    visited[node] = true;
    stack[node] = true;

    if (graph[node]) {
      for (const neighbor of graph[node]) {
        if (!visited[neighbor]) {
          if (dfs(neighbor, visited, stack)) {
            return true;
          }
        } else if (stack[neighbor]) {
          // Cycle detected
          return true;
        }
      }
    }

    stack[node] = false;
    return false;
  }

  // Check for cycles in each node
  for (const node of nodes) {
    const nodeId = node.id;
    const visited = {};
    const stack = {};

    if (!visited[nodeId] && dfs(nodeId, visited, stack)) {
      return true; // Cycle detected
    }
  }

  return false; // No cycle detected
};
