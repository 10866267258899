import {
  extractDateFromDateObject,
  toBirthdateInYears,
} from '@ui-common-files/utils';
import { DatePickerKeys } from '../../../../caro-ui-commonfiles/utils/dateUtils/dateUtil.type';
import { Patient } from '../../../types/Patient';
import { convertDateToTimestamp, formatTimestamp } from '@global/Date';
import config from '../../../../config';

export const extractBirthDateObjectFromPatient = (patient: Patient) => {
  const date = new Date(patient.birthDate);
  return {
    [DatePickerKeys.Day]: !patient.isBirthdayHardcoded
      ? `${date.getDate()}`
      : '',
    [DatePickerKeys.Month]: !patient.isBirthdayHardcoded
      ? `${date.getMonth() + 1}`
      : '',
    [DatePickerKeys.Year]: !patient.isBirthdayHardcoded
      ? `${date.getFullYear()}`
      : '',
  };
};

export const checkIfPatientUnder18 = date => {
  const timestamp = convertDateToTimestamp(extractDateFromDateObject(date));
  const momentDate = formatTimestamp(timestamp, 'YYYY-MM-DD');
  const age = toBirthdateInYears(momentDate);
  return age < config.minPatientAge;
};
