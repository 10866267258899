/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import I18n from 'i18next';

import { Button, Icon, Box, Flex, Tag } from '@ui-common-files/components';
import {
  NumericComponent,
  SingleChoice,
  MultipleChoice,
  YesNoComponent,
  GroupDescriptionComponent,
  PainLocationChart,
  EditNumericComponent,
  EditPainLocationChart,
  EditYesNoComponent,
  EditGroupDescriptionComponent,
  EditSingleChoice,
  EditMultipleChoice,
  DatepickerComponent,
  EditDatepickerComponent,
  BMI,
  EditBMI,
  InformationComponent,
} from '../CareplanComponents';
import ComponentType from '../../../../Utils/ComponentType';
import { TagsType } from '@ui-common-files/utils';
import { ButtonVariant } from '@ui-common-files/utils/button';
import { PrimaryColor, ActionColors } from '@ui-common-files/utils/colors';
import { parseComponentName } from '../../CareplanHelperUtility';
import { shouldShowOrHideContentButton } from '../../../../Global/Careplan/Components/Utils';
import componentTypeOrder from '../../careplanComponentTypeOrder';
import { displayJumps } from '../../LogicJump/logicJumpTags';
import { nonMedical } from '../../../../../config';

const getIndividualComponentCareplan = (
  component,
  onChange,
  emptyThresholds,
  LogicJumpDataforTags,
  isEvaluating
) => {
  const componentId = component[0].careplanComponentTypeId;
  const threshold = [...component];
  const componentWithoutJumpObject = component[2] === undefined;
  if (
    LogicJumpDataforTags &&
    LogicJumpDataforTags.isJumpSet &&
    componentWithoutJumpObject
  )
    component.push(LogicJumpDataforTags);

  const renderAddConditionButton = () => (
    <Button
      variant={ButtonVariant.NO_BORDER}
      color="red"
      onClick={() => {
        const newThreshold = component[1].concat({
          comparison: null,
          numericValue: null,
        });
        threshold.splice(1, 1, newThreshold);
        onChange(threshold);
      }}
      label={I18n.t('assignTemplate_view.addMoreCondition')}
    />
  );

  switch (componentId) {
    case componentTypeOrder.SINGLE_CHOICE:
      return (
        <EditSingleChoice
          component={component}
          onChange={value => {
            onChange(value);
          }}
          emptyThresholds={emptyThresholds}
        />
      );
    case componentTypeOrder.MULTIPLE_CHOICE:
      return (
        <EditMultipleChoice
          component={component}
          onChange={val => {
            onChange(val);
          }}
          emptyThresholds={emptyThresholds}
        />
      );
    case componentTypeOrder.SORTABLE:
      return (
        <MultipleChoice component={component} isEvaluating={isEvaluating} />
      );
    case componentTypeOrder.NUMERIC_RANGE:
    case componentTypeOrder.NUMERIC_VALUE:
      return (
        <>
          <EditNumericComponent
            component={component}
            onChange={onChange}
            emptyThresholds={emptyThresholds}
          />
          {renderAddConditionButton()}
        </>
      );
    case componentTypeOrder.YESNO:
      return (
        <EditYesNoComponent
          component={component}
          onChange={onChange}
          emptyThresholds={emptyThresholds}
        />
      );
    case componentTypeOrder.INFORMATION:
      return (
        <InformationComponent
          component={component}
          onChange={onChange}
          emptyThresholds={emptyThresholds}
        />
      );
    case componentTypeOrder.PAIN_LOCATION_CHART:
      return (
        <>
          <EditPainLocationChart component={component} onChange={onChange} />

          {renderAddConditionButton()}
        </>
      );
    case componentTypeOrder.GROUP_DESCRIPTION:
      return (
        <EditGroupDescriptionComponent
          component={component}
          onChange={onChange}
          emptyThresholds={emptyThresholds}
        />
      );

    case componentTypeOrder.DATEPICKER:
      return (
        <>
          <EditDatepickerComponent
            component={component}
            onChange={onChange}
            emptyThresholds={emptyThresholds}
          />
        </>
      );
    case componentTypeOrder.BLOOD_PRESSURE:
    case componentTypeOrder.BMI:
      return (
        <EditBMI
          component={component}
          onChange={val => {
            onChange(val);
          }}
          emptyThresholds={emptyThresholds}
        />
      );
    default:
      return null;
  }
};

const DefaultContent = ({
  threshold,
  contentArray,
  LogicJumpDataforTags,
  isEvaluating,
  careplanComponentTypeId,
}) => {
  const [showContent, setShowContent] = useState(true);
  return (
    <div className="threshold-component__content">
      {showContent &&
        getDefaultComponentCareplan(
          threshold,
          LogicJumpDataforTags,
          isEvaluating
        )}
      <div className="threshold-component__content-list">
        {shouldShowOrHideContentButton(careplanComponentTypeId) && (
          <Button
            variant={ButtonVariant.NO_BORDER}
            onClick={() => {
              setShowContent(!showContent);
            }}
            label={
              showContent
                ? I18n.t('newCareplan_view.hideContent')
                : I18n.t('newCareplan_view.showContent')
            }
          />
        )}

        {!showContent &&
          threshold[1].length > 0 &&
          renderThresholdContent(contentArray)}
        {!showContent &&
          LogicJumpDataforTags.isJumpSet &&
          displayJumps(LogicJumpDataforTags)}
      </div>
    </div>
  );
};

const IndividualContent = ({
  threshold,
  onChange,
  emptyThresholds,
  LogicJumpDataforTags,
  isEvaluating,
}) => {
  const componentType = threshold[0].careplanComponentTypeId;
  const [showContent, setShowContent] = useState(true);
  return (
    <div className="threshold-component__content">
      {showContent
        ? getIndividualComponentCareplan(
            threshold,
            onChange,
            emptyThresholds,
            LogicJumpDataforTags,
            isEvaluating
          )
        : null}
      {null}

      <div className="threshold-component__content-list">
        <Button
          variant={ButtonVariant.NO_BORDER}
          onClick={() => setShowContent(!showContent)}
          label={
            showContent
              ? I18n.t('newCareplan_view.hideContent')
              : I18n.t('newCareplan_view.showContent')
          }
        />
        {componentType != componentTypeOrder.FREE_TEXT &&
          componentType != componentTypeOrder.NUMERIC_RANGE &&
          componentType != componentTypeOrder.NUMERIC_VALUE &&
          componentType != componentTypeOrder.PAIN_LOCATION_CHART &&
          componentType != componentTypeOrder.UPLOAD_FILE &&
          componentType != componentTypeOrder.INFORMATION &&
          componentType != componentTypeOrder.SORTABLE && (
            <span className="set-threshold-text">
              {I18n.t('assignTemplate_view.setThreshold')}
            </span>
          )}
        <Box className="individualJumpsTag">
          {!showContent &&
            LogicJumpDataforTags.isJumpSet &&
            displayJumps(LogicJumpDataforTags)}
        </Box>
      </div>
    </div>
  );
};

const renderThresholdContent = thresholdsList => {
  if (
    thresholdsList.length > 0 &&
    (nonMedical == false || nonMedical == 'false')
  ) {
    const thresholdContent = thresholdsList.map(value => {
      if (value)
        return (
          <Box margin="0 5px 0 0">
            <Tag
              text={value}
              fill={ActionColors.THRESHOLD}
              type={TagsType.SQUARED}
            />
          </Box>
        );
    });
    return [...thresholdContent];
  }
  return null;
};

const getDefaultComponentCareplan = (
  component,
  LogicJumpDataforTags,
  isEvaluating
) => {
  const componentId = component[0].careplanComponentTypeId;
  const componentWithoutJumpObject = component[2] === undefined;
  if (
    LogicJumpDataforTags &&
    LogicJumpDataforTags.isJumpSet &&
    componentWithoutJumpObject
  )
    component.push(LogicJumpDataforTags);
  switch (componentId) {
    case 2:
      return <SingleChoice component={component} isEvaluating={isEvaluating} />;
    case 3:
    case 8:
      return (
        <MultipleChoice component={component} isEvaluating={isEvaluating} />
      );
    case 4:
    case 5:
      return <NumericComponent component={component} />;
    case 6:
      return <YesNoComponent component={component} />;
    case 9:
      return <InformationComponent component={component} />;
    case 7:
      return <PainLocationChart component={component} />;
    case 10:
      return <GroupDescriptionComponent component={component} />;
    case componentTypeOrder.DATEPICKER:
      return <DatepickerComponent component={component} />;
    case componentTypeOrder.BLOOD_PRESSURE:
    case componentTypeOrder.BMI:
      return (
        <BMI
          component={component}
          isEvaluating={isEvaluating}
          componentTypeId={componentId}
        />
      );
    default:
      return null;
  }
};

const ThresholdCardComponent = ({
  threshold,
  edit,
  onChange,
  emptyThresholds,
  variant,
  componentIndex,
  LogicJumpDataforTags = [],
  isEvaluating,
  isAdditional,
}) => {
  const [dataThreshold, setDataThreshold] = useState(threshold);
  useEffect(() => {
    onChange(dataThreshold);
  }, [dataThreshold]);

  useEffect(() => {
    if (emptyThresholds && edit) {
      const newObj = [...dataThreshold];
      newObj[1] = [];
      setDataThreshold(newObj);
      onChange(dataThreshold);
    }
  }, [emptyThresholds, edit]);

  const [dataThresholdDefault, setDataThresholdDefault] = useState(threshold);

  const getCareplanComponentContent = component => {
    const componentId = component[0].careplanComponentTypeId;
    let listContent = [];
    if (component[1].length > 0) {
      switch (componentId) {
        case componentTypeOrder.NUMERIC_RANGE:
        case componentTypeOrder.NUMERIC_VALUE:
          listContent = component[1].map(
            currComponent =>
              `${currComponent.comparison} ${currComponent.numericValue}`
          );
          break;
        case componentTypeOrder.PAIN_LOCATION_CHART:
          listContent = component[1].map(val =>
            translatePainLocationChartContent(val.key)
          );
          break;
        case componentTypeOrder.SINGLE_CHOICE:
        case componentTypeOrder.MULTIPLE_CHOICE:
        case componentTypeOrder.SORTABLE:
          listContent = component[1][0];
          break;
        case componentTypeOrder.DATEPICKER:
          listContent = component[1].map(
            currComponent =>
              `${currComponent.comparison} ${currComponent.numericValue} ${currComponent.unit}`
          );
          break;
        case componentTypeOrder.BMI:
          listContent = component[1][0].map(choice =>
            I18n.t(`bmiThresholdsOptions.${choice}`)
          );
          break;
        case componentTypeOrder.BLOOD_PRESSURE:
          listContent = component[1][0].map(choice =>
            I18n.t(`bloodPressureOptions.labels.${choice}`)
          );
          break;
        default:
          listContent = component[1];
          break;
      }
    }
    return listContent;
  };

  const translatePainLocationChartContent = contentType => {
    switch (contentType) {
      case 'head':
        return I18n.t('bodyChartImage.head');
      case 'occiput':
        return I18n.t('bodyChartImage.occiput');
      case 'shoulder':
        return I18n.t('bodyChartImage.shoulder');
      case 'leftChest':
        return I18n.t('bodyChartImage.leftChest');
      case 'rightChest':
        return I18n.t('bodyChartImage.rightChest');
      case 'stomach':
        return I18n.t('bodyChartImage.stomach');
      case 'alvus':
        return I18n.t('bodyChartImage.alvus');
      case 'back':
        return I18n.t('bodyChartImage.bodyBack');
      case 'fundament':
        return I18n.t('bodyChartImage.fundament');
      case 'leftUpperArm':
        return I18n.t('bodyChartImage.leftUpperArm');
      case 'leftLowerArm':
        return I18n.t('bodyChartImage.leftLowerArm');
      case 'rightUpperArm':
        return I18n.t('bodyChartImage.rightUpperArm');
      case 'rightLowerArm':
        return I18n.t('bodyChartImage.rightLowerArm');
      case 'leftLeg':
        return I18n.t('bodyChartImage.leftLeg');
      case 'rightLeg':
        return I18n.t('bodyChartImage.rightLeg');
      case 'throat':
        return I18n.t('bodyChartImage.throat');
      case 'genital':
        return I18n.t('bodyChartImage.genital');
      default:
        return ' ';
    }
  };

  const contentArrayDefault = getCareplanComponentContent(dataThresholdDefault);
  const { questionType, iconName } = ComponentType(
    dataThreshold[0].careplanComponentTypeId
  );

  const componentData = dataThreshold[0];
  const {
    weight,
    careplanComponentTypeId,
    isScoreEnabled,
    minimumValue,
    maximumValue,
  } = componentData;
  let scoreText = weight;
  const hasWeights =
    componentData.optionWeights != 'null' &&
    componentData.optionWeights != null;
  const optionWeights =
    hasWeights && JSON.parse(componentData.optionWeights).weights;
  const allZeroWeight = hasWeights && optionWeights.every(item => item == 0);
  let showWeight = isEvaluating;
  const caretaskTemplateMapper = componentData.careplanComponentsTemplateMapper;
  const caretaskMapper = componentData.careplanCareplanComponentMapper;

  const isMandatoryComponent =
    (caretaskTemplateMapper && caretaskTemplateMapper.isMandatory) ||
    (caretaskMapper && caretaskMapper.isMandatory);
  const renderComponentName = className => {
    return (
      <Flex>
        <Box className={className}>
          <Icon name={iconName} size={24} />
          <span>{componentIndex}</span>
        </Box>
        <Box
          className="threshold-component__name"
          otherStyles={{ display: 'block' }}
        >
          {parseComponentName(componentData.name)}
          {isMandatoryComponent && <span>&nbsp;*</span>}
        </Box>
      </Flex>
    );
  };
  if (showWeight) {
    showWeight = !(
      careplanComponentTypeId === componentTypeOrder.SINGLE_CHOICE ||
      careplanComponentTypeId === componentTypeOrder.MULTIPLE_CHOICE ||
      careplanComponentTypeId === componentTypeOrder.SORTABLE ||
      careplanComponentTypeId === componentTypeOrder.BMI ||
      careplanComponentTypeId === componentTypeOrder.BLOOD_PRESSURE
    );
    if (
      careplanComponentTypeId === componentTypeOrder.NUMERIC_RANGE ||
      careplanComponentTypeId === componentTypeOrder.NUMERIC_VALUE
    ) {
      showWeight = isScoreEnabled;
      scoreText = `${parseFloat(minimumValue)}-${parseFloat(maximumValue)}`;
    } else if (
      showWeight &&
      (weight === 0 || weight === null) &&
      (!hasWeights || (optionWeights.length && allZeroWeight))
    )
      showWeight = false;
  }
  if (isAdditional) {
    return (
      <Box className={`threshold-component${variant}`}>
        <Flex justifyContent="space-between">
          {renderComponentName('threshold-additional-component__icon')}
          {showWeight ? (
            <Box className="threshold-component__name">
              <Tag
                text={`Score: ${scoreText}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </Box>
          ) : null}
          <Box className="threshold-component__type">{`- ${questionType} -`}</Box>
        </Flex>
        {edit ? (
          <IndividualContent
            threshold={dataThreshold}
            onChange={value => {
              setDataThreshold(value);
            }}
            emptyThresholds={emptyThresholds}
            LogicJumpDataforTags={LogicJumpDataforTags}
            isEvaluating={isEvaluating}
          />
        ) : (
          <DefaultContent
            threshold={dataThresholdDefault}
            contentArray={contentArrayDefault}
            LogicJumpDataforTags={LogicJumpDataforTags}
            isEvaluating={isEvaluating}
            careplanComponentTypeId={careplanComponentTypeId}
          />
        )}
      </Box>
    );
  }

  return (
    <Box className={`threshold-component${variant}`}>
      <Flex justifyContent="space-between">
        {renderComponentName('threshold-component__icon')}
        {showWeight ? (
          <Box className="threshold-component__name">
            <Tag
              text={`${I18n.t('analytic_view.Score')} ${scoreText}`}
              fill={PrimaryColor.MAIN_COLOR}
              type={TagsType.SQUARED}
            />
          </Box>
        ) : null}
        <Box className="threshold-component__type">{`- ${questionType} -`}</Box>
      </Flex>
      {edit ? (
        <IndividualContent
          threshold={dataThreshold}
          onChange={value => {
            setDataThreshold(value);
          }}
          emptyThresholds={emptyThresholds}
          LogicJumpDataforTags={LogicJumpDataforTags}
          isEvaluating={isEvaluating}
        />
      ) : (
        <DefaultContent
          threshold={dataThresholdDefault}
          contentArray={contentArrayDefault}
          LogicJumpDataforTags={LogicJumpDataforTags}
          isEvaluating={isEvaluating}
          careplanComponentTypeId={careplanComponentTypeId}
        />
      )}
    </Box>
  );
};

export default ThresholdCardComponent;
