/**
 * Remove invalid characters
 * @param data represents the data in which the invalid characters will be removed.
 */

const removeInvalidCharacters = (data: string): string => {
  const cleanTextWithoutExcludingEmojis = '[^[ -■|\u00a9|\u00ae|\u2000-\u3300|\ud83c\ud000-\udfff|\ud83d\ud000-\udfff]|\ud83e\ud000-\udfff]+';
  return data
    .replaceAll('', '')
    .replaceAll(cleanTextWithoutExcludingEmojis,'');
};
export default removeInvalidCharacters;
