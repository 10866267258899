import { call, put, takeEvery } from 'redux-saga/effects';
import { getAllCareplanTemplatesApiCall,
getAllCustomFiltersApiCall,
getAllTemplatesApiCall,
getAnalyticDataForDashboardApiCall,
getCareplanAnalyticDataApiCall,
getPatientCareplanDataApiCall,
updateCareplanPeerGroupApiCall
} from './apiCalls';


function* getCareplanPatientData(data) {
  const results = yield call(function() {
    return new Promise(function(resolve, reject) {
      return getPatientCareplanDataApiCall(data)
        .then(function(resultData) {
          resolve(resultData);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });
  yield put({
    type: 'CAREPLAN_PATIENT_DATA_SUCCESS',
    patientCareplanData: results.data.content.careplanResponse || {
      error: results,
    },
    peerGroupSelectedFromUI: results.data.content.careplanResponse.peerGroups,
  });
}

function* getCareplanAnalyticData(data) {
  const updatedData = data;
  const peerGrouplength = updatedData.peerGroupSelected.length;
  if (peerGrouplength > 0) {
    for (let i = 0; i < peerGrouplength; i++) {
      delete updatedData.peerGroupSelected[i].institutions;
    }
  }
  let results;
  if (data.fromDashboard) {
    results = yield call(function() {
      return new Promise(function(resolve, reject) {
        return getAnalyticDataForDashboardApiCall(updatedData)
          .then(function(analyticData) {
            resolve(analyticData);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    });
  } else {
    results = yield call(function() {
      return new Promise(function(resolve, reject) {
        return getCareplanAnalyticDataApiCall(data)
          .then(function(data) {
            resolve(data);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    });
  }

  yield put({
    type: 'CAREPLAN_ANALYTIC_DATA_SUCCESS',
    careplanAnalyticData: results.data.content || [{ error: results }],
  });
}

function* getAllCustomFilters(query) {
  const results = yield call(function() {
    return new Promise(function(resolve, reject) {
      return getAllCustomFiltersApiCall(query)
        .then(function(data) {
          resolve(data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });

  const peerGroupOptions = results.data.filtersList.map(function(filter) {
    return {
      value: filter.name,
      id: filter.id,
      institutions: filter.institutions.map(function(inst) {
        return inst.name;
      }),
      label: filter.name,
      name: filter.name,
      sqlQuery: filter.sqlQuery,
      rules: filter.rule,
    };
  });
  yield put({
    type: 'CUSTOMFILTER_DATA_SUCCESS',
    allCustomFilters: results.data || [{ error: results }],
    peerGroupOptions,
    isLoading: false,
  });
}

function* updateCareplanPeerGroup(data) {
  const results = yield call(function() {
    return new Promise(function(resolve, reject) {
      return updateCareplanPeerGroupApiCall(
        data.templateId,
        data.peerGroupSelected
      )
        .then(function(data) {
          resolve(data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });

  
  yield put({
    type: 'UPDATE_CAREPLAN_PEERGROUP_SUCCESS',
    peerGroupSelectedFromUI:data.peerGroupSelected,
    isLoading: false,
  });
}

function* getAllTemplatesWithIdAndName() {
  const results = yield call(function() {
    return new Promise(function(resolve, reject) {
      return getAllTemplatesApiCall()
        .then(function(data) {
          resolve(data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });
  yield put({
    type: 'GET_ALL_TEMPLATE_SUCCESS',
    allTemplates: results.data.content.templateResponse,
  });
}

function* getTemplateDetailForDashboard(data) {
  const results = yield call(function() {
    return new Promise(function(resolve, reject) {
      return getAllCareplanTemplatesApiCall(data)
        .then(function(data) {
          resolve(data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });
  yield put({
    type: 'GET_TEMPLATE_DETAIL_SUCCESS',
    selectedTemplates: results.data.content.templateResponse,
  });
}

function* getTemplateDetail(data) {
  const results = yield call(function() {
    return new Promise(function(resolve, reject) {
      return getAllCareplanTemplatesApiCall(data)
        .then(function(data) {
          resolve(data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });
  yield put({
    type: 'CLEAR_PREVIEWED_PEERGROUP_RESPOPNSE',
    selectedTemplates: results.data.content.templateResponse[0],
  });
}

function* actionWatcher() {
    yield takeEvery('CAREPLAN_PATIENT_DATA_REQUESTED', getCareplanPatientData);
    yield takeEvery('CAREPLAN_ANALYTIC_DATA_REQUESTED', getCareplanAnalyticData);
    yield takeEvery('CUSTOMFILTER_DATA_REQUESTED', getAllCustomFilters);
    yield takeEvery(
      'UPDATE_CAREPLAN_PEERGROUP_REQUESTED',
      updateCareplanPeerGroup
    );
    yield takeEvery('GET_ALL_TEMPLATE', getAllTemplatesWithIdAndName);
    yield takeEvery(
      'UPDATE_SELECTED_CAREPLAN_TEMPLATE',
      getTemplateDetailForDashboard
    );
    yield takeEvery(
      'CLEAR_PREVIEWED_PEERGROUP_REQUEST',
      getTemplateDetail
    );
  }

export const analytics = actionWatcher();
