import { formatDate } from '@global/Date';
import {
  getDataPointName,
  getUnit,
  isBloodPressure,
} from './CustomTooltip.utils';

import { DATE_FORMAT, SYSTOLIC, DIASTOLIC } from './CustomTooltip.constant';

const useCustomTooltipService = data => {
  if (!data) {
    return {
      dataPointEntries: [],
      formattedDate: '',
    };
  }

  const {
    wearableDataTypeId1,
    wearableDataTypeId2,
    dataPointName1,
    dataPointName2,
    dataPointName3,
    dataPointUnit1,
    dataPointUnit2,
    dataPointUnit3,
    date,
    dataPointDescription1,
    dataPointDescription2,
    dataPointDescription3,
  } = data;

  const firstDataPointName = getDataPointName(
    isBloodPressure(wearableDataTypeId1),
    dataPointName1,
    SYSTOLIC
  );

  const secondDataPointName = getDataPointName(
    isBloodPressure(wearableDataTypeId1),
    getDataPointName(
      isBloodPressure(wearableDataTypeId2),
      dataPointName2,
      SYSTOLIC
    ),
    DIASTOLIC
  );

  const thirdDataPointName = getDataPointName(
    isBloodPressure(wearableDataTypeId2),
    dataPointName3,
    DIASTOLIC
  );

  const dataPointEntries = [
    {
      name: firstDataPointName,
      unit: getUnit(
        dataPointDescription1,
        isBloodPressure(wearableDataTypeId1),
        dataPointUnit1,
        dataPointUnit1
      ),
      circular: true,
    },
    {
      name: secondDataPointName,
      unit: getUnit(
        dataPointDescription2,
        isBloodPressure(wearableDataTypeId1),
        dataPointUnit1,
        dataPointUnit2
      ),
      circular: isBloodPressure(wearableDataTypeId1),
    },
    {
      name: thirdDataPointName,
      unit: getUnit(
        dataPointDescription3,
        isBloodPressure(wearableDataTypeId2),
        dataPointUnit2,
        dataPointUnit3
      ),
      circular: false,
    },
  ];

  const formattedDate = formatDate(date, DATE_FORMAT);

  return {
    dataPointEntries,
    formattedDate,
  };
};

export default useCustomTooltipService;
