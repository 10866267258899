import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextInput from '../TextInput/TextInput';
import Icon from '../Icon/Icon';
import iconCheckbox from '../../utils/iconCheckbox';
import { Greys } from '../../utils/colors';
import '../../styles/main.scss';

const IconCheckbox = ({
  id,
  name,
  iconName,
  value,
  label,
  placeholder,
  isDisabled,
  variant,
  inputWidth,
  checked,
  handleOnChange,
  valueInput,
  handleInputOnChange,
  multiSelectEnabled,
  hasInlineInput,
  fill,
  size,
  iconOpacity,
  onBlur,
}) => {
  /* Classes
  ========================================================================== */
  const labelStyles = classNames({
    'icon-checkbox__label': true,
    disabled: isDisabled,
  });

  const svgStyles = classNames({
    'icon-checkbox__circle-svg': variant === iconCheckbox.variant.CIRCLE,
    'icon-checkbox__square-svg': variant === iconCheckbox.variant.SQUARE,
    'icon-checkbox__circle-svg__unchecked':
      variant === iconCheckbox.variant.CIRCLE && !checked,
    'icon-checkbox__square-svg__unchecked':
      variant === iconCheckbox.variant.SQUARE && !checked,
  });

  return (
    <label className={labelStyles} htmlFor={id} style={{ width: '100%' }}>
      <input
        type={!multiSelectEnabled ? 'radio' : 'checkbox'}
        name={name}
        id={id}
        value={value}
        className="icon-checkbox__input"
        disabled={isDisabled}
        onChange={handleOnChange}
        checked={checked}
      />
      {checked ? (
        <Icon
          name={iconName}
          fill={fill}
          size={size}
          className={svgStyles}
          opacity={iconOpacity}
        />
      ) : (
        <Icon
          name={`${iconName}Unchecked`}
          size={size}
          className={svgStyles}
          opacity={iconOpacity}
        />
      )}

      {hasInlineInput ? (
        <div style={{ width: inputWidth }}>
          <TextInput
            id={id}
            name={name}
            value={valueInput}
            placeholder={placeholder}
            isDisabled={isDisabled}
            autoComplete="off"
            variant="inline-input"
            handleOnChange={handleInputOnChange}
            onBlur={onBlur}
          />
        </div>
      ) : (
        label
      )}
    </label>
  );
};

IconCheckbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  value: PropTypes.string,
  /**  Radio Button label */
  label: PropTypes.string,
  /**  Disables the Radio Button */
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    iconCheckbox.variant.SQUARE,
    iconCheckbox.variant.CIRCLE,
  ]).isRequired,
  /**  It can be defined as fixed width or relative unit */
  inputWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  checked: PropTypes.bool,
  handleOnChange: PropTypes.func.isRequired,
  valueInput: PropTypes.string,
  handleInputOnChange: PropTypes.func,
  multiSelectEnabled: PropTypes.bool,
  hasInlineInput: PropTypes.bool,
  placeholder: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number,
  iconOpacity: PropTypes.number,
  onBlur: PropTypes.func,
};

IconCheckbox.defaultProps = {
  id: 'icon-checkbox-1',
  value: '',
  label: null,
  isDisabled: false,
  placeholder: '',
  multiSelectEnabled: false,
  hasInlineInput: false,
  fill: Greys.LIGHT_GREY,
  size: 24,
  iconOpacity: 1,
  onBlur: () => {},
  checked: false,
  valueInput: '',
  handleInputOnChange: () => {},
  inputWidth: 200,
};

export default IconCheckbox;
