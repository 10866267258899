import React, { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';

import {
  Box,
  Tabs,
  Modal,
  FlashMessage,
  FloatButton,
  Breadcrumb,
} from '@ui-common-files/components';
import {
  HideFlashMessage,
  ApiClient,
  ShowFlashMessage,
} from '@global/Services';
import { Greys, PrimaryColor } from '@ui-common-files/utils';
import { checkAddSiteCredential } from '@utils/Administration';
import {
  ActionTranslationKeys,
  ComponentTranslationKeys,
  NavigationJSON,
} from '@ui-common-files/utils';
import { FlashMessageTypes } from '@ui-common-files/types';
import { getRolePermission } from '@utils/hooks/getUserPermission';

import AddRole from './roles/addRole';
import Users from './Datatables/Users';
import Roles from './Datatables/Roles';
import CustomFilters from './Datatables/customFilters';
import Sites from './Datatables/Sites';
import ServicesList from './Diagnostics/ServicesList/ServicesList';
import FirebaseInformations from './Diagnostics/FireBaseInformations/FirebaseInformations';
import QueueCheck from './Diagnostics/QueueCheck/QueueCheck';
import AssetUploadCheck from './Diagnostics/AssetUploadCheck/AssetUploadCheck';
import Satellites from './Datatables/Satellites/Satellites';

import ModalFooter from './common/Layout/Modal/ModalFooter';
import { AddSite } from './Sites/Add';
import { SatelliteForm } from './Satellites/SatelliteForm';

import { CustomFilterCreateModalContent } from './CustomFilters/create';
import { UserCreateModalContent } from './Users/create';

import serviceConfig from '../../serviceConfig.json';
import RoleHelperUtility from '../Utils/RoleHelperUtility';
import modalTypes from '../Utils/modalType';
import tabPosition from '../Utils/Administration/tabPosition';

import { convertBase64UrlToFile } from '../Utils/Asset/assetHelper';
import userRoles from '../Utils/roles.json';
import PatientLogs from './Datatables/PatientLogs/PatientLogs';

import { BreadcrumbPortal } from './AppLayout';

const Administration = ({ history }) => {
  const {
    breadcrumbAdministrationObject,
  } = require('../Utils/breadcrumbObjects');

  const { user } = useSelector(state => state.session);
  const services = useSelector(state => state.services);
  const { t } = useTranslation();

  const [reloadTable, setReloadTable] = useState(false);
  const [title, setTitle] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [flashMessage, setFlashMessage] = useState({});
  const [action, setAction] = useState('');
  const [reloadTableHeader, setReloadTableHeader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [toRefreshUser, setToRefreshUser] = useState(false);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [userData, setUserData] = useState({});
  const [siteDefaultPicture, setSiteDefaultPicture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const changeTabPosition = () => {
    if (!services.usersRead && services.sitesRead) {
      return tabPosition.SECOND;
    }

    if (!services.usersRead && !services.sitesRead && services.filtersRead) {
      return tabPosition.THIRD;
    }

    if (
      !services.filtersRead &&
      !services.sitesRead &&
      !services.usersRead &&
      services.rolesRead
    ) {
      return tabPosition.FOURTH;
    }

    if (
      !services.filtersRead &&
      !services.sitesRead &&
      !services.usersRead &&
      !services.rolesRead &&
      services.satellitesRead
    ) {
      return tabPosition.SIXTH;
    }

    return tabPosition.FIRST;
  };

  const getActiveTab = (_state, data) => data;
  const [activeTab, setActiveTab] = useReducer(
    getActiveTab,
    changeTabPosition()
  );

  const getRecordsTotal = (_state, data) => {
    return data;
  };
  const [recordsTotal, setRecordsTotal] = useReducer(getRecordsTotal, {
    userCount: 0,
    siteCount: 0,
    filterCount: 0,
    rolesCount: 0,
    userPermission: -1,
    satelliteCount: 0,
  });

  const [resetRule, setResetRule] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [sites, setSites] = useState([]);
  const [existingFilters, setExistingFilters] = useState([]);
  const [preset, setPreset] = useState([]);
  const [satellite, setSatellite] = useState({ id: null, name: '' });

  const closeModal = () => {
    setDisplayModal(false);
    setSatellite({ id: null, name: '' });
    setSelectedSite(null);
  };

  const getUserData = async () => {
    try {
      const response = await ApiClient.POST({
        url: serviceConfig.brokerService.addUser.uri,
      });
      if (response) {
        const newValues = response.data;
        newValues.profilePicture = convertBase64UrlToFile(
          'default_profile-picture.png',
          `data:image;base64,${newValues.profilePicture}`
        );
        newValues.originSite = newValues.userOriginSite;
        newValues.role = newValues.prefilledRoles[0];
        return newValues;
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      throw new Error(error.response);
    }
  };
  const checkCustomFilterAdd = async () => {
    try {
      const res = await ApiClient.POST({
        url: serviceConfig.brokerService.addCustomFilter.uri,
      });
      if (res) {
        const responseData = res.data;
        if (responseData) {
          setExistingFilters(responseData.prefilledFilters);
          setPreset(responseData.presetNames);
          setSites(responseData.sites);
          setSelectedSite([responseData.userOriginSite]);
          return responseData;
        }
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      throw new Error(error.response);
    }
  };

  const hideFlashMessages = () => {
    setFlashMessage({});
  };

  const checkHasSatelliteCreationPermission = async () => {
    setIsLoading(true);
    const hasSatelliteCredential = await getRolePermission(
      t(ComponentTranslationKeys.SATELLITE),
      t(ActionTranslationKeys.CREATE),
      true
    );
    setIsLoading(false);
    setDisplayModal(hasSatelliteCredential);

    if (hasSatelliteCredential === false) {
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: 'common_labels.permissionDenied',
        isModalMessage: false,
      });
    }
  };

  const openAddSiteSatelliteCustomFilterModal = () => {
    setDisplayModal(true);
  };
  const openAddUserModal = fetchedData => {
    setUserData(fetchedData);
    setDisplayModal(true);
  };

  const refreshUser = () => {
    setToRefreshUser(!toRefreshUser);
  };

  const openAddRoleModal = async () => {
    const fetchedRolePermissions = await RoleHelperUtility.getRolePermissions(
      user
    );
    setRolePermissions(fetchedRolePermissions);
    const createPermission = fetchedRolePermissions.find(role => {
      return role.action == t('actions.create');
    });
    if (createPermission.allowed) {
      setOpenModal(true);
    } else {
      const permissionDeniedObject = {
        content: t('common_labels.msg_noPermission'),
        type: 'warning',
      };
      setFlashMessage(permissionDeniedObject);
    }
  };

  const fetchData = async () => {
    try {
      const fetchedResults = await ApiClient.GET({
        url: serviceConfig.brokerService.getAdministrationCounts.uri,
      });
      if (fetchedResults) {
        const { data } = fetchedResults;
        setRecordsTotal(data);
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  const onClickTabItem = tab => {
    setActiveTab(tab);
  };

  const handleModalSatelliteClick = (isEditModal, close = null) => {
    setTitle(
      isEditModal
        ? `${t('satellite_view.label_editSatellite')}: ${satellite.name}`
        : t('satellite_view.label_createNewSatellite')
    );
    setAction(modalTypes.SATELLITE);
    checkHasSatelliteCreationPermission();
    close && close();
  };

  useEffect(() => {
    if (satellite.id !== null) {
      handleModalSatelliteClick(true);
    }
  }, [satellite.id]);

  const renderTabs = () => {
    return (
      <div className="container-padding">
        <div style={{ position: 'relative' }}>
          <Tabs activeTab={activeTab} onClickTabItem={onClickTabItem}>
            {services.usersRead && (
              <div
                label={`${t('types.componentType.users')} (${
                  recordsTotal.userCount
                })`}
                tabId={tabPosition.FIRST}
              >
                <Users
                  hasPagesize
                  hasPagination
                  isLoading={false}
                  totalUsers={recordsTotal.userCount}
                  reloadTable={reloadTable}
                  setReloadTable={setReloadTable}
                  setToRefreshUser={refreshUser}
                  setFlashMessage={setFlashMessage}
                />
              </div>
            )}
            {(services.sitesRead || user.role.name == userRoles.SITE_ADMIN) && (
              <div
                label={`${t('types.componentType.sites')} (${
                  recordsTotal.sitePermission ? recordsTotal.siteCount : '1'
                })`}
                tabId={tabPosition.SECOND}
              >
                <Sites
                  hasPagesize
                  hasPagination
                  isLoading={false}
                  showFlashMessage={setFlashMessage}
                  reloadTable={reloadTable}
                  setReloadTable={setReloadTable}
                  sitePermission={recordsTotal.sitePermission}
                />
              </div>
            )}
            {services.filtersRead && (
              <div
                label={`${t('types.componentType.customFilters')} (${
                  recordsTotal.filterCount
                })`}
                tabId={tabPosition.THIRD}
              >
                <CustomFilters
                  hasPagesize
                  hasPagination
                  isLoading={false}
                  reloadTable={reloadTable}
                  setReloadTable={setReloadTable}
                  setFlashMessage={setFlashMessage}
                />
              </div>
            )}
            {services.rolesRead && (
              <div
                label={`${t('rolemanager_view.label_role_title')} (${
                  recordsTotal.rolesCount
                })`}
                tabId={tabPosition.FOURTH}
              >
                <Roles
                  fetchedData={fetchedData}
                  setFetchedData={setFetchedData}
                  flashMessage={flashMessage}
                  setFlashMessage={setFlashMessage}
                  hasPagesize
                  hasPagination
                  isLoading={false}
                  rolePermissions={rolePermissions}
                  setRolePermissions={setRolePermissions}
                  setReloadTable={() => setReloadTable(!reloadTable)}
                  reloadTable={reloadTable}
                  user={user}
                />
              </div>
            )}
            {user.role.name === userRoles.SUPER_ADMIN && (
              <Box label="Diagnostics" tabId={tabPosition.FIFTH}>
                <AssetUploadCheck />
                <FirebaseInformations />
                <QueueCheck />
                <ServicesList />
              </Box>
            )}
            {user.role.name === userRoles.SUPER_ADMIN && (
              <Box label="Patient Logs" tabId={tabPosition.SIXTH}>
                <PatientLogs reloadTable={reloadTable} />
              </Box>
            )}
            {services.satelliteRead && (
              <Box
                label={`${t('types.componentType.satellites')} (${
                  recordsTotal.satelliteCount
                })`}
                tabId={tabPosition.SEVENTH}
              >
                <Satellites
                  hasPagesize
                  hasPagination
                  reloadTable={reloadTable}
                  onEditSatellite={(id, name) => {
                    setSatellite({ id, name });
                  }}
                />
              </Box>
            )}
          </Tabs>
        </div>
      </div>
    );
  };

  const setActiveTabAndReload = tabId => {
    setReloadTable(!reloadTable);
    setActiveTab(tabId);
  };

  useEffect(() => {
    fetchData();
  }, [reloadTable, activeTab]);

  const reloadAllTables = () => {
    setReloadTableHeader(!reloadTableHeader);
  };

  const renderModalContent = () => {
    switch (action) {
      case modalTypes.SITE:
        return (
          <AddSite
            showFlashMessage={setFlashMessage}
            closeModal={closeModal}
            reloadTable={setActiveTabAndReload}
            defaultSiteImage={siteDefaultPicture}
          />
        );
      case modalTypes.SATELLITE:
        return (
          <SatelliteForm
            closeModal={closeModal}
            reloadTable={setActiveTabAndReload}
            selectedSite={selectedSite}
            sites={sites}
            setSelectedSite={setSelectedSite}
            setSites={setSites}
            id={satellite.id}
          />
        );

      case modalTypes.CUSTOM_FILTER:
        return (
          <CustomFilterCreateModalContent
            closeModal={closeModal}
            setFlashMessage={setFlashMessage}
            reloadTable={setActiveTabAndReload}
            setReloadTable={setReloadTable}
            reloadAllTables={reloadAllTables}
            resetRule={resetRule}
            setResetRule={setResetRule}
            selectedSite={selectedSite}
            setSelectedSite={setSelectedSite}
            sites={sites}
            setSites={setSites}
            existingFilters={existingFilters}
            preset={preset}
          />
        );

      case modalTypes.USER:
        return (
          <UserCreateModalContent
            closeModal={closeModal}
            userData={userData}
            setFlashMessage={setFlashMessage}
            reloadTable={setActiveTabAndReload}
          />
        );

      default:
        return <div />;
    }
  };

  const renderModalFooter = () => {
    switch (action) {
      case modalTypes.SITE:
        return (
          <ModalFooter
            close={closeModal}
            labelCTA={t('common_buttons.save')}
            form="create-site-form"
          />
        );
      case modalTypes.SATELLITE:
        return (
          <ModalFooter
            close={closeModal}
            labelCTA={
              satellite.id !== null
                ? t('common_buttons.update')
                : t('common_buttons.save')
            }
            form={
              satellite.id !== null
                ? 'edit-satellite-form'
                : 'create-satellite-form'
            }
          />
        );

      case modalTypes.CUSTOM_FILTER:
        return (
          <ModalFooter
            close={closeModal}
            labelCTA={t('common_buttons.save')}
            form="create-custom-filter-form"
          />
        );

      case modalTypes.USER:
        return (
          <ModalFooter
            close={closeModal}
            labelCTA={t('common_buttons.save')}
            form="create-user-form"
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {isLoading && (
        <Box className="roleOverlapSpinner">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Box>
      )}
      <BreadcrumbPortal>
        <Breadcrumb>
          <Link to={`/${NavigationJSON.ADMINISTRATION}`}>{t('nav.admin')}</Link>
          <span aria-current="page">
            {t(breadcrumbAdministrationObject[activeTab - 1].label)}
          </span>
        </Breadcrumb>
      </BreadcrumbPortal>
      {flashMessage.content && (
        <FlashMessage
          message={t(flashMessage.content)}
          type={flashMessage.type}
          onClick={hideFlashMessages}
        />
      )}
      {renderTabs()}
      <Modal
        title={title}
        contentComponent={renderModalContent()}
        footerComponent={renderModalFooter()}
        openModal={displayModal}
        onClose={closeModal}
        hideFlashMessage={HideFlashMessage}
      />
      <AddRole
        openModal={openModal}
        setOpenModal={setOpenModal}
        setFlashMessage={setFlashMessage}
        fetchedData={fetchedData}
        setFetchedData={setFetchedData}
        reloadTable={setActiveTabAndReload}
      />
      <FloatButton
        menuItems={[
          {
            icon: 'users',
            fill: Greys.DARK_GREY,
            tooltipText: t('newUser_view.label_createUser'),
            onClick: close => {
              setTitle(t('newUser_view.label_createUser'));
              getUserData().then(res => {
                if (res) openAddUserModal(res);
              });
              setAction(modalTypes.USER);
              close();
            },
          },
          {
            icon: 'Satelitte',
            fill: Greys.DARK_GREY,
            tooltipText: t('satellite_view.label_createNewSatellite'),
            onClick: close => {
              handleModalSatelliteClick(false, close);
            },
          },
          {
            icon: 'institution',
            fill: Greys.DARK_GREY,
            tooltipText: t('site_view.label_createNewSite'),
            onClick: close => {
              setTitle(t('site_view.label_createNewSite'));
              setAction(modalTypes.SITE);

              checkAddSiteCredential().then(response => {
                if (response) {
                  const { profilePicture } = response.data;
                  setSiteDefaultPicture(
                    convertBase64UrlToFile(
                      'default_profile-picture.png',
                      `data:image;base64,${profilePicture}`
                    )
                  );
                  openAddSiteSatelliteCustomFilterModal();
                }
              });
              close();
            },
          },
          {
            icon: 'filter',
            fill: Greys.DARK_GREY,
            tooltipText: t('filter_dropdown_list.create_filter'),
            onClick: close => {
              setTitle(t('customFilters_view.label_add_new_filter'));
              setAction(modalTypes.CUSTOM_FILTER);
              checkCustomFilterAdd().then(response => {
                if (response) openAddSiteSatelliteCustomFilterModal();
              });
              close();
            },
          },
          {
            icon: 'roleManager',
            fill: Greys.DARK_GREY,
            tooltipText: t('newRole_view.label_addUserRole'),
            onClick: close => {
              setTitle(t('newRole_view.label_addUserRole'));
              setAction(modalTypes.ROLE_MANAGER);
              openAddRoleModal();
              close();
            },
          },
        ]}
      />
    </>
  );
};

export default Administration;
