import patientFilterActions from '../../src/Utils/ReduxUtilities/Actions/patientFilter.json';

export const onFilterSelection = filter => ({
  type: patientFilterActions.SELECT_FILTER,
  payload: filter,
});
export const onCustomFilterSelection = filter => ({
  type: patientFilterActions.SELECT_CUSTOM_FILTER,
  payload: filter,
});
export const resetPatientFilters = () => ({
  type: patientFilterActions.RESET_PATIENTS_FILTER,
});

export const addCustomFilter = filter => ({
  type: patientFilterActions.ADD_CUSTOM_FILTER,
  payload: filter,
});

export const updateCustomFilter = filter => ({
  type: patientFilterActions.UPDATE_CUSTOM_FILTER,
  payload: filter,
});

export const deleteCustomFilter = id => ({
  type: patientFilterActions.DELETE_CUSTOM_FILTER,
  payload: id,
});
