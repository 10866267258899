import { useEffect, useState } from 'react';

import { ShowCareplanTemplateRequest } from '@utils';

const useCareTaskShowModalService = careTaskId => {
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [careTaskData, setCareTaskData] = useState([]);
  const [careTaskScoringScheme, setCareTaskScoringScheme] = useState([]);
  const [careTaskDataLogicJumps, setCareTaskDataLogicJumps] = useState([]);
  const [ownershipLogs, setOwnershipLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ShowCareplanTemplateRequest(
      careTaskId,
      setCareTaskData,
      () => {},
      setCareTaskDataLogicJumps,
      setCareTaskScoringScheme,
      setOwnershipLogs,
      setIsEvaluating
    ).then(() => setIsLoading(false));
  }, []);

  return {
    isEvaluating,
    careTaskData,
    careTaskScoringScheme,
    careTaskDataLogicJumps,
    ownershipLogs,
    isLoading,
  };
};
export default useCareTaskShowModalService;
