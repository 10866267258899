import { useEffect, useMemo, useState } from 'react';
import { lifespanUnits } from '../Recurrence/recurrenceUtils';

const useAvailableLifespan = (
  repeatType,
  lifespanUnitStartIndex,
  lifespanUnit,
  setLifespanUnit
) => {

  const lifespanUnitsObj = lifespanUnits();
  const [availableLifespanUnits, setAvailableLifespanUnits] = useState(
    lifespanUnitsObj.slice(lifespanUnitStartIndex, 2)
  );

  useEffect(() => {
    if (repeatType)
      setAvailableLifespanUnits(
        lifespanUnitsObj.slice(lifespanUnitStartIndex, repeatType.value + 1)
      );
    if (lifespanUnit && repeatType && lifespanUnit.value > repeatType.value) {
      setLifespanUnit(lifespanUnitsObj[repeatType.value]);
    }
  }, [repeatType]);

  return availableLifespanUnits;
};

export default useAvailableLifespan;
