import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '../../../../caro-ui-commonfiles/utils/button';
import NumericConditionInput from '../settingsForComponentTypes/NumericConditionInput';

import defaultEnums from '../defaultEnums';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';

import '../../../css/careplanComponent.scss';

const NumericRange = ({
  item,
  componentCollection,
  setLogicJumpValueForComponent,
  componentIndex,
  isLogicJumpOn,
  setLogicJumpError,
  setJumpContradictionError,
}) => {
  const [itemCollection, setItemCollection] = useState(
    item.logicJumpCollection && item.logicJumpCollection.length > 0
      ? item.logicJumpCollection
      : [{ name: '', value: '', destination: -1 }]
  );

  const isFloatingNumber = item.searchTerm.careplanComponentId
    ? item.searchTerm.decimal
    : item.decimal;

  const addJumpCondition = () => {
    const obj = { name: '', value: '', destination: -1 };
    const newItemCollection = [...itemCollection, obj];
    setItemCollection(newItemCollection);
  };

  const deleteJumpConditions = optionIndex => {
    const updateItemCollection = [...componentCollection];
    updateItemCollection[componentIndex].logicJumpCollection.splice(
      optionIndex,
      1
    );
    setLogicJumpValueForComponent(
      'logicJumpCollection',
      updateItemCollection[componentIndex].logicJumpCollection
    );
    setItemCollection(item.logicJumpCollection);
  };

  return (
    <div>
      {isFloatingNumber && (
        <div className="floatingTextContainer">
          <span className="floatingTextSpan">
            {I18n.t('previewCareplanComponent.floatingPointNumber')}
          </span>
        </div>
      )}
      {isLogicJumpOn && (
        <>
          {itemCollection.map((elem, i) => {
            return (
              <NumericConditionInput
                index={i}
                item={elem}
                parentComponentIndex={componentIndex}
                itemCollection={itemCollection}
                setLogicJumpValueForComponent={setLogicJumpValueForComponent}
                isFloatingNumber={isFloatingNumber}
                isThreshold={false}
                color={ActionColors.THRESHOLD}
                setLogicJumpError={setLogicJumpError}
                setJumpContradictionError={setJumpContradictionError}
                deleteJumpConditions={deleteJumpConditions}
                min={defaultEnums.NUMERIC_RANGE_MIN_VALUE}
                max={defaultEnums.NUMERIC_RANGE_MAX_VALUE}
              />
            );
          })}
          <Box margin="10px 0 0 50px">
            <Button
              label={`+ ${
                itemCollection.length > 0
                  ? I18n.t('threshold.addMoreThresholdLabel')
                  : I18n.t('threshold.addThresholdLabel')
              }`}
              variant={ButtonVariant.NO_BORDER}
              color={ButtonColor.RED}
              size={ButtonSize.MD}
              onClick={() => addJumpCondition()}
            />
          </Box>
        </>
      )}
    </div>
  );
};

NumericRange.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  componentCollection: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  setLogicJumpValueForComponent: PropTypes.func.isRequired,
  isLogicJumpOn: PropTypes.bool.isRequired,
  setLogicJumpError: PropTypes.func.isRequired,
  setJumpContradictionError: PropTypes.func.isRequired,
  componentIndex: PropTypes.number.isRequired,
};

export default NumericRange;
