import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Tag,
  DataTable,
  IconButton,
  ToggleButton,
  Modal,
  IconButtonDropdown,
  Icon,
  Box,
  TextInput,
  Tooltip,
} from '@ui-common-files/components';
import {
  MaxCharLimits,
  TagsVariant,
  ComponentTranslationKeys,
  ActionTranslationKeys,
  SubmissionTypes,
  inputField,
} from '@ui-common-files/utils';
import {
  useUserPermission,
  checkUserCredential,
  convertLinkToBlob,
  roles,
} from '@utils';
import { HideFlashMessage } from '@global/Services';
import { UserUpdateModalContent } from '../Users/update';
import TagsCell from './CellElements/TagsCell';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import ModalFooter from '../common/Layout/Modal/ModalFooter';

import useWindowWidthListener from '../../Utils/hooks/useWindowWidthListener';
import editUser from '../Users/edit';
import getUserInfoWithSessionHistory from '../Users/getUserInfoWithSessionHistory';
import retrieveOptIns from '../Users/retrieveOptIns';
import { UserModalShowContent, UserModalShowFooter } from '../Users/showUser';
import Animation from './CellElements/Animationcomponent';
import ShowModalFooter from '../common/ShowModalFooter';

import { getActionButtonItems } from './Satellites/Satellites.util';

import prefilledUsers from '../../Utils/prefilledUsers.json';
import { ApiClient } from '../../Global/Services';
import serviceConfig from '../../../serviceConfig.json';
import '../../css/sites.css';

const Users = ({
  hasPagesize,
  hasPagination,
  totalUsers,
  reloadTable,
  setReloadTable,
  setToRefreshUser,
  setFlashMessage,
}) => {
  const { user } = useSelector(state => state.session);
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState({
    value: '',
    start: 0,
    length: 10,
    dir: 'asc',
    column: 'firstname',
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageState, setPageState] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'firstname',
  });
  const [modalTitle, setModalTitle] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [currUser, setCurrUser] = useState(false);
  const [userToUpdateRole, setUserToUpdateRole] = useState(true);
  const [typingTimeOut, setTypingTimeOut] = useState(0);
  const [userSessions, setUserSessions] = useState({});
  const [userOptIns, setUserOptIns] = useState({});
  const [displayShowUserModal, setDisplayShowUserModal] = useState(false);
  const [modalTitleForUser, setModalTitleForUser] = useState('');
  const [displayRevoke, setDisplayRevoke] = useState(false);
  const [updatetUserPopup, setUpdatetUserPopup] = useState({ display: false });
  const [deactivateOperation, setDeactivateOperation] = useState({
    display: false,
    id: 0,
    title: '',
  });
  const { t } = useTranslation();
  const closeMessagePrompt = () => {
    setDeactivateOperation({
      display: false,
      id: 0,
      title: '',
    });
    setUpdatetUserPopup({
      display: false,
    });
  };
  const responsive = useWindowWidthListener();
  const hasSatelliteCredentials = useUserPermission(
    t(ComponentTranslationKeys.SATELLITE),
    t(ActionTranslationKeys.CREATE),
    true
  );

  useEffect(() => {
    const Rows = async data => {
      const rows = [];
      const retrievedData = [...data];
      if (retrievedData) {
        for (let index = 0; index < retrievedData.length; index++) {
          let profiPic;
          if (retrievedData[index].profilePicture.directLink) {
            await convertLinkToBlob(
              retrievedData[index].profilePicture.directLink
            )
              .then(blob => {
                profiPic = blob;
              })
              .catch(() => {
                profiPic = retrievedData[index].profilePicture.directLink;
              });
          } else {
            profiPic = `data:image;base64,${retrievedData[index].profilePicture}`;
          }

          rows.push({
            logo: (
              <Animation isOn delay={index * 50}>
                <Box width="40px" height="40px">
                  <div
                    style={{
                      backgroundImage: `url(${profiPic})`,
                    }}
                    className="notification__table-profile-pic"
                  />
                </Box>
              </Animation>
            ),
            profiPic,
            firstName: retrievedData[index].firstname,
            lastName: retrievedData[index].lastname,
            username: retrievedData[index].username,
            email: retrievedData[index].email,
            role: retrievedData[index].role
              ? retrievedData[index].role.name
              : '',
            site: retrievedData[index].institutions.filter(
              (singleInstitution: {
                userInstitution: { isPrimary: boolean };
              }) => singleInstitution.userInstitution.isPrimary == true
            ),
            satelliteName: retrievedData[index].satellite?.name,
            satellite: retrievedData[index].satellite,
            isActive: retrievedData[index].isActive,
            id: retrievedData[index].id,
            satelitteId: retrievedData[index].satelliteId,
          });
        }
      }
      return rows;
    };
    if (typingTimeOut > 0) clearTimeout(typingTimeOut);
    const fetchData = () => {
      setLoading(true);
      ApiClient.POST({
        url: serviceConfig.brokerService.getAllUsersByQuery.uri,
        payload: {
          query,
          totalUsersLength: totalUsers,
        },
        timeout: 30000,
      })
        .then(async result => {
          setFetchedData(await Rows(result.data.data));
          setRecordsTotal(result.data.recordsTotal);
        })
        .catch(error => {
          setFlashMessage({
            type: error.response.data.type,
            content: error.response.data.content,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (totalUsers && query.value.length === 0) fetchData();
    else if (totalUsers && query.value.length > 1) {
      const timeOut = setTimeout(() => {
        fetchData();
      }, 500);
      setTypingTimeOut(timeOut);
    }
    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [query, reloadTable, totalUsers]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];
  const tableProps = {
    defaultSorted: [
      {
        id: 'firstName',
        desc: false,
      },
    ],
    onSortedChange: newSorted => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: t('datatable.previous'),
    nextText: t('datatable.next'),
    noDataText: t('common_labels.label_noData'),
    pageText: t('datatable.showing'),
    ofText: t('datatable.of'),
    rowsText: t('datatable.entries'),
    rowsSelectorText: t('datatable.recordsPerPage'),
  };

  const activateRow = useCallback(async (id: string) => {
    try {
      const response = await ApiClient.GET({
        url: serviceConfig.brokerService.activateUser.uri + id,
      });

      if (response) {
        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
      }
      return response;
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      return error.response;
    }
  }, []);

  const deactivateRow = useCallback(async (id: string) => {
    try {
      const response = await ApiClient.POST({
        url: serviceConfig.brokerService.deactivateUser.uri + id,
      });
      if (response) {
        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
      }
      return response;
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      return error.response;
    }
  }, []);

  const handleOnCheckToggle = useCallback(
    (id, isChecked) => {
      const defineIsUserActive = (id, shouldBeActive) => {
        const newAllUsers = [...fetchedData];
        const foundActiveUser = newAllUsers.find(user => user.id === id);
        if (foundActiveUser) {
          foundActiveUser.isActive = shouldBeActive;
          setFetchedData(newAllUsers);
        }
      };
      if (isChecked) {
        defineIsUserActive(id, true);
        activateRow(id).then(res => {
          if (res.status == 500 || res.status == 403) {
            defineIsUserActive(id, false);
          }
        });
      } else {
        defineIsUserActive(id, false);
        deactivateRow(id).then(res => {
          if (res.status == 500 || res.status == 403) {
            defineIsUserActive(id, true);
          }
        });
      }
    },
    [activateRow, deactivateRow, fetchedData]
  );

  const isUserDisable = original => {
    if (!original.satellite) {
      return original.site.every((site: { isActive }) => !site.isActive);
    }
    return !original.satellite?.isActive;
  };

  const displayEditModal = async selectedUser => {
    const hasCredential = await checkUserCredential(
      ComponentTranslationKeys.USER,
      ActionTranslationKeys.SHOW
    );
    const hasCredentialUpdateRole = await checkUserCredential(
      ComponentTranslationKeys.USERROLE,
      ActionTranslationKeys.UPDATE
    );
    if (
      Object.keys(hasCredentialUpdateRole).length > 0 &&
      hasCredentialUpdateRole.type === 'warning' &&
      hasCredentialUpdateRole.content == 'common_labels.permissionDenied'
    ) {
      setUserToUpdateRole(false);
    }
    if (hasCredential.type && selectedUser != user.id) {
      setFlashMessage({
        type: hasCredential.type,
        content: hasCredential.content,
      });
    } else if (hasCredential === true || selectedUser == user.id) {
      await editUser(selectedUser).then(response => {
        if (response.type) {
          setFlashMessage({
            content: response.content,
            type: response.type,
          });
        } else {
          setCurrUser(response);
          setDisplayModal(true);
        }
      });
    }
  };

  const showUserModal = async selectedUser => {
    const hasCredential = await checkUserCredential(
      ComponentTranslationKeys.USER,
      ActionTranslationKeys.SHOW
    );
    if (hasCredential.type) {
      setFlashMessage({
        type: hasCredential.type,
        content: hasCredential.content,
      });
    } else if (hasCredential === true) {
      const optIns = await retrieveOptIns(selectedUser);
      const userSessionsHistory = await getUserInfoWithSessionHistory(
        selectedUser
      );
      if (userSessionsHistory.type) {
        setFlashMessage({
          content: userSessionsHistory.content,
          type: userSessionsHistory.type,
        });
      } else {
        let profiPic;
        if (userSessionsHistory.profilePicture.directLink) {
          await convertLinkToBlob(userSessionsHistory.profilePicture.directLink)
            .then(blob => {
              profiPic = blob;
            })
            .catch(() => {
              profiPic = userSessionsHistory.profilePicture.directLink;
            });
        } else {
          profiPic = `data:image;base64,${userSessionsHistory.profilePicture}`;
        }
        userSessionsHistory.profilePicture = profiPic;
        setUserSessions(userSessionsHistory);
        setUserOptIns(optIns);
        setDisplayShowUserModal(true);
        setModalTitleForUser(
          `${userSessionsHistory.firstname} ${userSessionsHistory.lastname}`
        );
      }
    }
  };

  const closeModal = () => {
    setDisplayRevoke(false);
    setDisplayModal(false);
    setCurrUser(false);
  };

  const closeModalForUser = () => {
    setDisplayShowUserModal(false);
    setCurrUser(false);
  };

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'logo',
        sortable: false,
        filterable: false,
        width: 50,
      },
      {
        Header: (props: { column: { id: string } }) => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{t('admin_view.label_firstNameUser')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'firstName',
      },
      {
        Header: (props: { column: { id: string } }) => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{t('admin_view.label_lastNameUser')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'lastName',
        filterable: false,
      },
      {
        Header: (props: { column: { id: string } }) => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{t('admin_view.label_username')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'username',
        filterable: false,
      },
      {
        Header: (props: { column: { id: string } }) => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{t('admin_view.label_email_user')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'email',
        filterable: false,
        Cell: ({ row }) => {
          const { email } = row;
          return (
            <Tooltip content={email}>
              <Box
                className="tags__ellipsis"
                otherStyles={{ marginTop: '10px' }}
              >
                {email}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        Header: () => {
          return <span>{t('admin_view.label_userRole')}</span>;
        },
        accessor: 'role',
        sortable: false,
        filterable: false,
      },
      {
        Header: () => {
          return <span>{t('admin_view.label_site')}</span>;
        },
        accessor: 'site',
        sortable: false,
        filterable: false,
        minWidth: 120,
        style: {
          overflow: 'visible',
        },
        Cell: ({ row }) => {
          const { site } = row;

          return <TagsCell tagList={site} />;
        },
      },
      {
        ...(hasSatelliteCredentials && {
          Header: () => {
            return <span>{t('satellite_view.label_satellite')}</span>;
          },
          accessor: 'satelliteName',
          sortable: false,
          filterable: false,
          minWidth: 120,
          style: {
            overflow: 'visible',
          },
          Cell: ({ row }) => {
            const { satelliteName } = row;

            return (
              satelliteName && (
                <Tag
                  text={satelliteName}
                  variant={TagsVariant.OUTLINED}
                  shouldHaveEllipsis
                />
              )
            );
          },
        }),
      },
      {
        Header: '',
        accessor: 'rowOptions',
        sortable: false,
        filterable: false,
        minWidth: 160,
        maxWidth: 160,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'space-around',
        },
        Cell: (props: {
          original: {
            isActive;
            id;
            firstName;
            lastName;
            role;
            username;
          };
          row;
        }) => {
          const { isActive, id, firstName, lastName, role, username } =
            props.original;
          const [active, setActive] = useState(isActive);
          const enabledDeactivateButton =
            (user.role.name == roles.SUPER_ADMIN &&
              user.username == prefilledUsers.ADMIN &&
              user.id != id) ||
            (user.role.name == roles.SUPER_ADMIN &&
              user.username != prefilledUsers.ADMIN &&
              username != prefilledUsers.ADMIN &&
              user.id != id) ||
            (user.role.name == roles.SITE_ADMIN &&
              role != roles.SUPER_ADMIN &&
              user.id != id &&
              role != roles.SITE_ADMIN);
          const enabledEditButton =
            user.username == prefilledUsers.ADMIN ||
            (user.role.name == roles.SUPER_ADMIN &&
              user.username != prefilledUsers.ADMIN &&
              username != prefilledUsers.ADMIN) ||
            (user.role.name == roles.SITE_ADMIN &&
              user.id != id &&
              role != roles.SUPER_ADMIN &&
              role != roles.SITE_ADMIN) ||
            user.id == id;

          const actionButtonItems = getActionButtonItems(active, () =>
            isUserDisable(props.original)
          ).map(item => ({ value: t(item) }));

          return (
            <>
              {responsive ? (
                <div className="moreIconStyle">
                  <IconButtonDropdown
                    items={
                      enabledEditButton
                        ? enabledDeactivateButton
                          ? [
                              { value: t('common_labels.label_edit') },
                              { value: t('common_labels.label_show') },
                              ...actionButtonItems,
                            ]
                          : [
                              { value: t('common_labels.label_edit') },
                              { value: t('common_labels.label_show') },
                            ]
                        : enabledDeactivateButton
                        ? [
                            { value: t('common_labels.label_show') },
                            {
                              value: active
                                ? t('common_labels.deactivateBtn')
                                : t('common_labels.activateBtn'),
                            },
                          ]
                        : [{ value: t('common_labels.label_show') }]
                    }
                    onClick={(e, action) => {
                      if (action.value == t('common_labels.label_edit')) {
                        displayEditModal(id);
                        setModalTitle(`${firstName} ${lastName}`);
                      } else if (
                        action.value == t('common_labels.label_show')
                      ) {
                        showUserModal(id, props.row);
                      } else {
                        setActive(!active);
                        handleOnCheckToggle(id, !isActive);
                      }
                    }}
                  />
                </div>
              ) : (
                <>
                  <div style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="edit"
                      size={30}
                      onClick={() => {
                        displayEditModal(id, props.row);
                        setModalTitle(`${firstName} ${lastName}`);
                      }}
                      tooltipText={t('common_labels.label_edit')}
                      disabled={!enabledEditButton}
                    />
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="view"
                      size={30}
                      onClick={() => {
                        showUserModal(id, props.row);
                      }}
                      tooltipText={t('common_labels.label_show')}
                    />
                  </div>
                  <ToggleButton
                    checked={isActive}
                    handleOnChange={e => {
                      if (!e.target.checked) {
                        setDeactivateOperation({
                          display: true,
                          id,
                          title: `${t(
                            'common_labels.msg_confirm_user_deactivate_headline'
                          )}: `,
                        });
                      } else {
                        handleOnCheckToggle(id, e.target.checked);
                      }
                    }}
                    isDisabled={
                      !enabledDeactivateButton || isUserDisable(props.original)
                    }
                  />
                </>
              )}
            </>
          );
        },
      },
    ];
  }, [sort.column, sort.dir, user.id, responsive, handleOnCheckToggle]);

  useEffect(() => {
    setQuery({
      start: currentPage * pageState,
      length: pageState,
      dir: sort.dir,
      column: sort.column,
      value: query.value,
    });
  }, [currentPage, pageState, sort]);

  return (
    <>
      <div style={{ width: 250, position: 'absolute', top: 0, right: 0 }}>
        <TextInput
          id="specialId"
          name="Name"
          placeholder={t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={query.value}
          handleOnChange={e => {
            setQuery({
              value: e.target.value,
              start: query.start,
              length: query.length,
              dir: sort.dir,
              column: sort.column,
            });
            setCurrentPage(0);
            setPageState(query.length);
          }}
        />
      </div>
      <DataTable
        data={fetchedData}
        columns={columns}
        pageSize={hasPagesize}
        pageSizeOptions={items}
        pagination={hasPagination}
        isLoading={loading}
        tableProps={tableProps}
        onTableChange={(index, size) => {
          setQuery({
            value: query.value,
            start:
              fetchedData && index * size > recordsTotal ? 0 : index * size,
            length: size,
            dir: sort.dir,
            column: sort.column,
          });
          setCurrentPage(
            fetchedData && index * size > recordsTotal ? 0 : index
          );
          setPageState(size);
        }}
        page={currentPage}
        pages={Math.ceil(recordsTotal / query.length)}
        totalRecord={recordsTotal}
      />
      {currUser && (
        <Modal
          title={`${t('newUser_view.label_editUser')}: ${modalTitle}`}
          contentComponent={
            <UserUpdateModalContent
              userData={currUser}
              closeModal={closeModal}
              reloadTable={() => setReloadTable(!reloadTable)}
              setFlashMessage={setFlashMessage}
              setToRefreshUser={setToRefreshUser}
              setDisplayRevoke={setDisplayRevoke}
              displayRevoke={displayRevoke}
              setUpdatetUserPopup={setUpdatetUserPopup}
              userToUpdateRole={userToUpdateRole}
            />
          }
          footerComponent={
            <ModalFooter
              close={closeModal}
              labelCTA={t('common_buttons.update')}
              form="update-user-form"
            />
          }
          openModal={displayModal}
          onClose={closeModal}
          hideFlashMessage={HideFlashMessage}
        />
      )}
      {displayShowUserModal && (
        <Modal
          title={`${t('newUser_view.label_showUser')}: ${modalTitleForUser}`}
          contentComponent={
            <UserModalShowContent
              userInformation={userSessions}
              userInformation={userSessions}
              userOptIns={userOptIns}
            />
          }
          footerComponent={<UserModalShowFooter close={closeModalForUser} />}
          openModal={displayShowUserModal}
          onClose={() => {
            closeModalForUser();
          }}
          hideFlashMessage={HideFlashMessage}
        />
      )}
      {deactivateOperation.display && (
        <Modal
          isConfirmationDialog
          title={deactivateOperation.title}
          contentComponent={
            <PromptContent
              message={`${t()} ${t('common_labels.msg_confirmDeactivate')} `}
            />
          }
          footerComponent={
            <PromptFooter
              close={closeMessagePrompt}
              submissionType={SubmissionTypes.DEACTIVATE}
              confirmHandler={() => {
                if (deactivateOperation.id > 0) {
                  handleOnCheckToggle(deactivateOperation.id, false);
                }
              }}
            />
          }
          openModal={deactivateOperation.display}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}

      {updatetUserPopup.display && (
        <Modal
          isConfirmationDialog
          title={t('common_labels.msg_role_update_headline')}
          contentComponent={
            <PromptContent message={t('common_labels.msg_role_update')} />
          }
          footerComponent={<ShowModalFooter close={closeMessagePrompt} />}
          openModal={updatetUserPopup.display}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </>
  );
};
export default Users;
