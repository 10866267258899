import React from 'react';
import I18n from 'i18next';
import PhoneInput from 'react-phone-number-input';
import { Document, Page } from 'react-pdf';

import {
  Box,
  TextInput,
  Label,
  Tooltip,
  ComponentWithLabel,
  AddressSearch,
  Icon,
  Button,
  Flex,
  IconButton,
  TextArea,
} from '@ui-common-files/components';
import ModalSection from '../common/Layout/Modal/ModalSection';
import CustomCountrySelect from '../common/CustomCountrySelect/CustomCountrySelect';
import Dropzone from './Dropzone';

import {
  inputField,
  ButtonVariant,
  ActionColors,
  Greys,
  ButtonSize,
  MaxCharLimits,
} from '@ui-common-files/utils';

import {
  navigateToExternalUrl,
  validateFileSize,
} from '@utils/Asset/assetHelper';
import { additionalConsentValidator } from './Sites.util';

export const checkImageErrors = file => {
  if (
    typeof file === 'string' &&
    (file.indexOf('blob') === 0 || file.indexOf('data:image;base64') === 0)
  )
    return false;
  const maxSize = 10 ** 7;
  const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  if (!acceptedImageTypes.includes(file.type)) {
    return I18n.t('patients_view.invalidImageNotification');
  }
  if (!validateFileSize([file], maxSize)) {
    return I18n.t('site_view.imageTooBig');
  }
  return false;
};

export const renderFirstColumn = (
  onDrop,
  name,
  onChangeNameText,
  errors,
  validationMessages,
  address,
  onChangeAddressText,
  email,
  onChangeEmailText,
  phone,
  onChangePhoneText,
  logo,
  onChangeServiceProviderIdText,
  serviceProviderId,
) => (
  <ModalSection>
    <Box margin="32px 20px">
      <Label
        text={I18n.t('site_view.label_siteLogo')}
        id="siteLogo"
        hasIcon
        tooltip={I18n.t('site_view.image_format')}
        tooltipPosition="right"
      />
      <div className="dropzone-sub-content">
        <Dropzone
          onDrop={onDrop}
          accept="image/*"
          logo={logo}
          error={validationMessages.siteLogoError}
        />
      </div>
      <ComponentWithLabel
        id="Name"
        htmlFor="Name"
        hasIcon={false}
        labelText={`* ${I18n.t('site_view.label_siteName')}`}
        tooltipText="Name"
        hasToggleButton={false}
        idToggleButton="toggleBtn"
        valueToggleButton="toggleBtn"
        isDisabledToggleButton={false}
        checked={false}
      >
        <TextInput
          value={name}
          id="Name"
          name={I18n.t('site_view.label_siteName')}
          placeholder={I18n.t('site_view.add_siteName')}
          variant={inputField.variant.CHAR_COUNT}
          maxLength={MaxCharLimits.sites.siteNameMaxCharLimit}
          maxChars={MaxCharLimits.sites.siteNameMaxCharLimit}
          handleOnChange={onChangeNameText}
          hasError={errors.name}
          validationMessage={I18n.t(validationMessages.name)}
        />
      </ComponentWithLabel>
      <ComponentWithLabel
        id="Address"
        htmlFor="Address"
        hasIcon={false}
        labelText={`* ${I18n.t('site_view.label_address')}`}
        tooltipText="Address"
        hasToggleButton={false}
        idToggleButton="toggleBtn"
        valueToggleButton="toggleBtn"
        isDisabledToggleButton={false}
        checked={false}
      >
        <AddressSearch
          value={address}
          handleOnChange={onChangeAddressText}
          placeholder={I18n.t('appointment.select address')}
          hasError={errors.address}
          validationMessage={I18n.t(validationMessages.address)}
        />
      </ComponentWithLabel>
      <ComponentWithLabel
        id="Email"
        htmlFor="Email"
        hasIcon={false}
        labelText={`* ${I18n.t('site_view.label_email')}`}
        tooltipText="Email"
        hasToggleButton={false}
        idToggleButton="toggleBtn"
        valueToggleButton="toggleBtn"
        isDisabledToggleButton={false}
        checked={false}
      >
        <TextInput
          value={email}
          id="Email"
          name={I18n.t('site_view.label_email')}
          placeholder={I18n.t('patients_view.add_emailAddress')}
          variant={inputField.variant.CHAR_COUNT}
          maxLength={MaxCharLimits.sites.siteEmailMaxCharLimit}
          maxChars={MaxCharLimits.sites.siteEmailMaxCharLimit}
          handleOnChange={onChangeEmailText}
          hasError={errors.email}
          validationMessage={validationMessages.email}
        />
      </ComponentWithLabel>
      <ComponentWithLabel
        id="ServiceProviderId"
        htmlFor="ServiceProviderId"
        hasIcon={false}
        labelText={`${I18n.t('site_view.label_serviceProviderId')}`}
        hasToggleButton={false}
        idToggleButton="toggleBtn"
        valueToggleButton="toggleBtn"
        isDisabledToggleButton={false}
        checked={false}
      >
        <TextInput
          value={serviceProviderId}
          id="ServiceProviderId"
          name={I18n.t('site_view.label_serviceProviderId')}
          placeholder={I18n.t('site_view.add_serviceProviderId')}
          variant={inputField.variant.CHAR_COUNT}
          maxLength={MaxCharLimits.sites.siteEmailMaxCharLimit}
          maxChars={MaxCharLimits.sites.siteEmailMaxCharLimit}
          handleOnChange={onChangeServiceProviderIdText}
          hasError={errors.serviceProviderId}
          validationMessage={validationMessages.serviceProviderId}
        />
      </ComponentWithLabel>
      <ComponentWithLabel
        id="mobile"
        htmlFor="mobile"
        labelText={`* ${I18n.t('site_view.label_phone')}`}
      >
        <PhoneInput
          defaultCountry="DE"
          placeholder={I18n.t('site_view.add_phone')}
          maxLength={MaxCharLimits.sites.sitePhoneMaxCharLimit}
          value={phone}
          className={`patients__phone-field${errors.phone ? ' error' : ''}`}
          inputClassName="patients__phone-input"
          onChange={onChangePhoneText}
          countrySelectComponent={props => {
            return <CustomCountrySelect {...props} />;
          }}
        />
        {errors.phone === true && (
          <span className="patients__phone-field error">
            {validationMessages.phone}
          </span>
        )}
      </ComponentWithLabel>
    </Box>
  </ModalSection>
);

export const renderSecondColumn = (
  handlePrivacyPolicyUpload,
  inputRef,
  privacyPolicyNotValid,
  setPrivacyPolicyNotValid,
  privacyPolicyData,
  setPrivacyPolicyData,
  consentError,
  setErrors
) => {
  const handleOnClick = () => {
    setPrivacyPolicyData({
      isPrivacyPolicyChanged: true,
      isNewPrivacyPolicy: privacyPolicyData.isNewPrivacyPolicy,
      url: privacyPolicyData.url,
      name: privacyPolicyData.name,
      size: privacyPolicyData.size,
      additionalConsents:
        privacyPolicyData.additionalConsents == undefined
          ? ['']
          : [...privacyPolicyData.additionalConsents, ''],
    });
  };

  const deleteAdditionalConsent = arrayIndex => {
    let deletableArray = privacyPolicyData.additionalConsents;
    deletableArray.splice(arrayIndex, 1);
    setPrivacyPolicyData({
      isPrivacyPolicyChanged: true,
      isNewPrivacyPolicy: privacyPolicyData.isNewPrivacyPolicy,
      url: privacyPolicyData.url,
      name: privacyPolicyData.name,
      size: privacyPolicyData.size,
      additionalConsents: deletableArray,
    });
  };

  const onChangeAdditionalConsent = (arrayIndex, text) => {
    if (text.length > MaxCharLimits.privacyPolicy
      .privacyPolicyAdditionalConsent) return;
    let modifiedArray = privacyPolicyData.additionalConsents;
    modifiedArray.splice(arrayIndex, 1, text);
    setPrivacyPolicyData(oldPrivacyPolicyData => ({
      ...oldPrivacyPolicyData,
      isPrivacyPolicyChanged: true,
      additionalConsents: modifiedArray,
    }));
  };

  const handleFileError = () => {
    return <div />;
  };

  const onLoadErrorFile = () => {
    setPrivacyPolicyNotValid({
      type: true,
      size: privacyPolicyNotValid.size,
      name: privacyPolicyNotValid.name,
    });
  };

  return (
    <ModalSection>
      <Box margin="32px 0px 0px 23px">
        <Label
          text={I18n.t('privacyPolicy.attachPrivacyPolicy')}
          id="privacyPolicy"
        />
        <Box margin="0px 20px 0 0">
          <span className="privacy-policy__decription">
            {I18n.t('privacyPolicy.uploadDescription')}
          </span>
          <Box margin={!privacyPolicyData.url ? '32px 0' : '0 0'}>
            {!privacyPolicyData.url ? (
              <>
                <input
                  id="image-upload"
                  type="file"
                  onChange={e => {
                    handlePrivacyPolicyUpload(e.target.files[0]);
                  }}
                  ref={input => (inputRef = input)}
                  hidden
                  accept="application/pdf"
                />
                <Button
                  label={I18n.t('privacyPolicy.attachPrivacyPolicy')}
                  variant={ButtonVariant.OUTLINED}
                  disabled={false}
                  onClick={() => inputRef.click()}
                  size={ButtonSize.MD}
                  fullWidth
                >
                  <Icon name="attachment" />
                </Button>
              </>
            ) : null}
            {privacyPolicyData.url ? (
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <div className="privacy-poly__border">
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <span className="doc-style">
                      <Document
                        file={privacyPolicyData.url}
                        error={handleFileError}
                        onLoadError={onLoadErrorFile}
                      >
                        <Page pageNumber={1} width={36} height={36} />
                      </Document>
                    </span>

                    <Box
                      margin="16px 4px 16px 10px"
                      otherStyles={{ width: '383px' }}
                    >
                      <span className="privacy-policy__name">
                        {privacyPolicyData.name}
                        &nbsp;&nbsp;
                      </span>
                      <span className="privacy-policy__size">
                        {' '}
                        {privacyPolicyData.size}{' '}
                      </span>
                      <Box
                        margin="16px 83px 16px 12px"
                        otherStyles={{ float: 'right' }}
                      >
                        <IconButton
                          type="button"
                          name="preview"
                          size={36}
                          onClick={() => {
                            navigateToExternalUrl(privacyPolicyData.url);
                          }}
                        />
                      </Box>
                    </Box>
                  </Flex>
                </div>
                <Box margin="32px 0 0 10px">
                  <input
                    id="replace-file"
                    type="file"
                    onChange={e => {
                      handlePrivacyPolicyUpload(e.target.files[0]);
                    }}
                    ref={input => (inputRef = input)}
                    hidden
                    accept="application/pdf"
                  />
                  <Button
                    label={I18n.t('privacyPolicy.replace')}
                    onClick={() => inputRef.click()}
                  />
                </Box>
              </Flex>
            ) : null}
            {privacyPolicyNotValid.size || privacyPolicyNotValid.type ? (
              <Box margin="16px 0">
                <span className="file-size__error">
                  {I18n.t('privacyPolicy.fileSizeError')}
                </span>
              </Box>
            ) : null}
            {privacyPolicyNotValid.name ? (
              <Box margin="16px 0">
                <span className="file-size__error">
                  {I18n.t('flash.fileNameLimitExceed')}
                </span>
              </Box>
            ) : null}
          </Box>
        </Box>

        {privacyPolicyData.url && (
          <Box margin="24px 20px 0 0">
            <Flex flexDirection="row" otherStyles={{ marginBottom: 12 }}>
              <Label
                text={I18n.t('privacyPolicy.additionalConsentTitle')}
                isLongText
              />
              <Tooltip
                content={
                  <div className="tooltip-text--w250">
                    {I18n.t('privacyPolicy.additionalConsentInformation')}{' '}
                  </div>
                }
              >
                <Icon name="info" size={24} fill={Greys.DARK_GREY} />
              </Tooltip>
            </Flex>

            {privacyPolicyData.additionalConsents &&
              privacyPolicyData.additionalConsents.length > 0 &&
              privacyPolicyData.additionalConsents.map(
                (additionalConsent, index) => (
                  <div>
                    <Flex
                      flexDirection="row"
                      alignItems="space-between"
                      justifyContent="center"
                      otherStyles={{ marginTop: index == 0 ? '0px' : '15px' }}
                    >
                      <TextArea
                        type="text"
                        value={additionalConsent}
                        handleOnChange={event => {
                          onChangeAdditionalConsent(index, event.target.value);
                        }}
                        placeholder={I18n.t(
                          'privacyPolicy.additionalConsentDescription'
                        )}
                        wrap="soft"
                        style={{ height: '173px', marginRight: '10px' }}
                        variant={inputField.variant.CHAR_COUNT}
                        maxChars={
                          MaxCharLimits.privacyPolicy
                            .privacyPolicyAdditionalConsent
                        }
                        hasError={additionalConsent == '' && consentError}
                      />
                      <Flex alignItems="center">
                        <IconButton
                          onClick={() => {
                            deleteAdditionalConsent(index);
                          }}
                          name="delete"
                          size={30}
                          fill={ActionColors.DELETE}
                          tooltipText={I18n.t('toolTipsText.delete')}
                        />
                      </Flex>
                    </Flex>
                    {additionalConsent == '' && consentError && (
                      <span className="patients__phone-field error">
                        {I18n.t('privacyPolicy.additionalConsentValidate')}
                      </span>
                    )}
                    {!additionalConsentValidator(additionalConsent) && (
                      <span className="patients__phone-field error">
                        {I18n.t('privacyPolicy.additionalConsentValidateLink')}
                      </span>
                    )}
                  </div>
                )
              )}

            <Button
              label={I18n.t('privacyPolicy.additionalConsentAdd')}
              variant={ButtonVariant.NO_BORDER}
              disabled={
                privacyPolicyData.additionalConsents &&
                privacyPolicyData.additionalConsents.length == 3
                  ? true
                  : false
              }
              onClick={() => handleOnClick()}
              style={{
                marginTop:
                  privacyPolicyData.additionalConsents &&
                  privacyPolicyData.additionalConsents.length == 0
                    ? '0px'
                    : '15px',
                padding: '0px',
                marginBottom: '15px',
              }}
              size={ButtonSize.XS}
            >
              <Icon name="add" size={14} />
            </Button>
          </Box>
        )}
      </Box>
    </ModalSection>
  );
};
