import React, { PropsWithChildren } from 'react';

import Flex from '../Flex/Flex';
import Box from '../Box/Box';
import IconButton from '../IconButton/IconButton';

import { DismissibleContainerProps } from './DismissibleContainer.type';

import './DismissibleContainer.style.scss';

const DismissibleContainer: React.FC<PropsWithChildren<
  DismissibleContainerProps
>> = ({ children, onClose, otherStyles, className }) => {
  return (
    <Box className={`dismissible-card ${className}`} otherStyles={otherStyles}>
      <Flex>
        <Flex className="card-content">{children}</Flex>
        <IconButton name="clear" onClick={onClose} size={18} />
      </Flex>
    </Box>
  );
};

export default DismissibleContainer;
