import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import {
  BubbleVideo,
  BubbleAudio,
  BubblePdf,
  BubbleLink,
  BubbleImage,
} from './index';
import { getFileType, isDataLink } from '../../../Utils/Chat/chatUtility';
import ASSET_TYPES from "../../../Utils/Asset/assetTypes";

const BubbleContent = ({ note, isFileData, data, blobURL, thumbnailBlobURL, isOwnNote }) => {

  const renderFileContent = () => {
    const fileType = getFileType(note);
    switch (fileType) {
      case ASSET_TYPES.IMAGE:
        return (<BubbleImage note={note} blobURL={blobURL} />);
      case ASSET_TYPES.AUDIO:
        return (<BubbleAudio blobURL={blobURL} />);
      case ASSET_TYPES.DOCUMENT:
        return (<BubblePdf note={note} data={data} blobs={[blobURL, thumbnailBlobURL]} />);
      case ASSET_TYPES.VIDEO:
        return (<BubbleVideo blobURL={blobURL} />);
      default:
        return (<BubbleLink note={note} data={data} blobURL={blobURL} />);
    }
  }
  return (
    <>
      {isFileData && renderFileContent()}
      {!isFileData && isDataLink(note) && (
        <BubbleLink note={note} data={data} blobURL={blobURL} isLink={true} />
      )}
      {!isFileData && !isDataLink(note) && (
        <p className={`note-bubble__text${isOwnNote ? '__self' : ''}`}>{ReactHtmlParser(note)}</p>
      )}
    </>
  );
};

BubbleContent.propTypes = {
  blobURL: PropTypes.string.isRequired,
};

export default React.memo(BubbleContent);