import React from 'react';
import I18n from 'i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import CheckboxRadio from '../../../../caro-ui-commonfiles/utils/CheckboxRadio';
import Checkbox from '../../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import IconButton from '../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import TextInput from '../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Tag from '../../../../caro-ui-commonfiles/components/Tags/Tags';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import { TagsType, TagsVariant } from '@ui-common-files/utils';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';

import { isThresholdSet, validateOptions } from '../CareplanHelperUtility';
import { getSumOfArray } from '../../../Global/Arrays';

import inputField from '../../../../caro-ui-commonfiles/utils/inputField';
import { MaxCharLimits } from '@ui-common-files/utils';

const handleSortable = (
  event,
  item,
  index,
  componentCollection,
  setComponentCollection
) => {
  item.sortableCollection.sortables[index].name = event.target.value;
  item.sortableCollection.sortables[index].isChecked = event.target.checked;
  setComponentCollection([...componentCollection]);
};

const handleSortableList = (
  event,
  item,
  componentCollection,
  setComponentCollection
) => {
  item.sortableCollection.addSortable = event.target.value;
  setComponentCollection([...componentCollection]);
};

const addSortables = (
  item,
  componentCollection,
  setComponentCollection,
  setBlurr,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  searchTermComponents
) => {
  const collection = item.sortableCollection.sortables;
  const { optionWeights } = item.sortableCollection;
  const globalIndexOfSelectedComponent = componentCollection.findIndex(
    component => component.id === searchTermComponents.id
  );
  const component = componentCollection[globalIndexOfSelectedComponent];
  let evaluationLine;
  let filteredElement;
  const temp = validationErrorTemplate;
  if (component.searchTerm.careplanComponentId) {
    evaluationLine =
      component.searchTerm.additional_configuration.evaluationLine;
  } else {
    evaluationLine = component.additional_configuration.evaluationLine;
  }
  setBlurr(true);
  if (item.sortableCollection.addSortable) {
    if (collection.length < MaxCharLimits.carePlan.componentOptionsLimit) {
      const obj = {
        name: item.sortableCollection.addSortable.trim(),
        isChecked: false,
      };
      collection.push(obj);
      optionWeights.weights.push(0);

      const sum =
        optionWeights.weights.length > 0
          ? getSumOfArray(optionWeights.weights)
          : 0;
      optionWeights.total = sum;
    }
  }
  item.sortableCollection.addSortable = '';
  setComponentCollection([...componentCollection]);

  if (evaluationLine <= collection.length) {
    if (temp.templateSettingsValidation.noComponentsError.length > 0) {
      filteredElement =
        temp.templateSettingsValidation.evaluationLineErrorForSortable.find(
          elem => {
            return elem.componentId === globalIndexOfSelectedComponent;
          }
        );
      if (filteredElement) {
        filteredElement.evaluationLineError = false;
      }
    }
  } else if (collection.length > 2) {
    filteredElement =
      temp.templateSettingsValidation.evaluationLineErrorForSortable.find(
        elem => {
          return elem.componentId === globalIndexOfSelectedComponent;
        }
      );
    if (filteredElement) {
      filteredElement.evaluationLineError = true;
    } else {
      temp.templateSettingsValidation.evaluationLineErrorForSortable.push({
        componentId: globalIndexOfSelectedComponent,
        evaluationLineError: true,
      });
    }
  }
  hasValidationErrorTemplate({ ...temp });
  validateOptions(
    item,
    collection,
    validationErrorTemplate,
    hasValidationErrorTemplate
  );
};

const removeSortables = (
  item,
  index,
  componentCollection,
  setComponentCollection,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  searchTermComponents,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring
) => {
  item.sortableCollection.sortables.splice(index, 1);
  item.sortableCollection.optionWeights.weights.splice(index, 1);
  let total = 0;
  for (
    let index = 0;
    index < item.sortableCollection.optionWeights.weights.length;
    index++
  ) {
    total += parseInt(item.sortableCollection.optionWeights.weights[index], 10);
  }
  item.sortableCollection.optionWeights.total = total;
  const temp = [...componentCollection];
  setComponentCollection(temp);

  if (
    item.sortableCollection.optionWeights.total > 0 &&
    item.sortableCollection.optionWeights.weights[index] > 0
  ) {
    showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent =
      true;
    setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
  }

  const globalIndexOfSelectedComponent = componentCollection.findIndex(
    component => component.id === searchTermComponents.id
  );
  const component = componentCollection[globalIndexOfSelectedComponent];
  let evaluationLine;
  let filteredElement;
  const validationTemp = validationErrorTemplate;
  if (component.searchTerm.careplanComponentId) {
    evaluationLine =
      component.searchTerm.additional_configuration.evaluationLine;
  } else {
    evaluationLine = component.additional_configuration.evaluationLine;
  }
  if (evaluationLine <= component.sortableCollection.sortables.length) {
    if (
      validationTemp.templateSettingsValidation.noComponentsError.length > 0
    ) {
      filteredElement =
        validationTemp.templateSettingsValidation.evaluationLineErrorForSortable.find(
          elem => {
            return elem.componentId === globalIndexOfSelectedComponent;
          }
        );
      if (filteredElement) {
        filteredElement.evaluationLineError = false;
      }
    }
  } else if (component.sortableCollection.sortables.length > 0) {
    filteredElement =
      validationTemp.templateSettingsValidation.evaluationLineErrorForSortable.find(
        elem => {
          return elem.componentId === globalIndexOfSelectedComponent;
        }
      );
    if (filteredElement) {
      filteredElement.evaluationLineError = true;
    } else {
      validationTemp.templateSettingsValidation.evaluationLineErrorForSortable.push(
        {
          componentId: globalIndexOfSelectedComponent,
          evaluationLineError: true,
        }
      );
    }
  }
  hasValidationErrorTemplate({ ...validationTemp });
  validateOptions(
    item,
    item.sortableCollection.sortables,
    validationErrorTemplate,
    hasValidationErrorTemplate
  );
};

const onChangeOptionName = (
  event,
  item,
  i,
  componentCollection,
  setComponentCollection
) => {
  const updatedOption = event.target.value;
  const matchedPosition = componentCollection.findIndex(elem => {
    return elem.id == item.id;
  });
  componentCollection[matchedPosition].sortableCollection.sortables[i] = {
    name: updatedOption,
    isChecked:
      componentCollection[matchedPosition].sortableCollection.sortables[i]
        .isChecked,
  };
  if (
    componentCollection[matchedPosition].sortableCollection.selectedSortable
      .index == i
  ) {
    componentCollection[
      matchedPosition
    ].sortableCollection.selectedSortable.name = updatedOption;
  }
  setComponentCollection([...componentCollection]);
};

const SortableItem = SortableElement(
  ({
    id,
    key,
    index,
    item,
    value,
    thresholdToggle,
    componentCollection,
    setComponentCollection,
    i,
    elem,
    validationErrorTemplate,
    hasValidationErrorTemplate,
    searchTermComponents,
    setShowPromptForAdjustScoring,
    showPromptForAdjustScoring,
  }) => {
    return (
      <li style={{ listStyleType: 'none' }}>
        <div className="singleChoices">
          <Flex flexDirection="row" otherStyles={{ flex: 1 }}>
            <Flex alignItems="center" otherStyles={{ width: '100%' }}>
              <IconButton
                name="ranking"
                size={20}
                onClick={() => {
                  return '';
                }}
              />
              <Box>
                <Checkbox
                  id={id}
                  name={id}
                  value={value}
                  variant={
                    thresholdToggle
                      ? CheckboxRadio.Threshold
                      : CheckboxRadio.Default
                  }
                  isDisabled={!thresholdToggle}
                  inputWidth="100%"
                  checked={
                    thresholdToggle
                      ? item.sortableCollection.sortables[i].isChecked
                      : false
                  }
                  handleOnChange={e =>
                    thresholdToggle
                      ? handleSortable(
                          e,
                          item,
                          i,
                          componentCollection,
                          setComponentCollection
                        )
                      : ''
                  }
                />
              </Box>

              <TextInput
                id={id}
                value={value}
                name="option"
                placeholder=""
                variant={inputField.variant.CHAR_COUNT}
                maxLength={MaxCharLimits.carePlan.sortableMaxCharLimit}
                maxChars={MaxCharLimits.carePlan.sortableMaxCharLimit}
                isDisabled={false}
                handleOnChange={() =>
                  onChangeOptionName(
                    event,
                    item,
                    i,
                    componentCollection,
                    setComponentCollection
                  )
                }
              />
            </Flex>
            {thresholdToggle && item.sortableCollection.sortables[i].isChecked && (
              <div className="tag-container">
                <Tag
                  text={I18n.t('threshold.threshold')}
                  variant={TagsVariant.CONTAINED}
                  type={TagsType.SQUARED}
                  fill={ActionColors.THRESHOLD}
                />
              </div>
            )}
          </Flex>
          <div className="closeChoice">
            <IconButton
              name="close"
              size={20}
              tooltipText={I18n.t('toolTipsText.remove')}
              onClick={() => {
                removeSortables(
                  item,
                  i,
                  componentCollection,
                  setComponentCollection,
                  validationErrorTemplate,
                  hasValidationErrorTemplate,
                  searchTermComponents,
                  setShowPromptForAdjustScoring,
                  showPromptForAdjustScoring
                );
              }}
              onPointerDown={() => {
                removeSortables(
                  item,
                  i,
                  componentCollection,
                  setComponentCollection,
                  validationErrorTemplate,
                  hasValidationErrorTemplate,
                  searchTermComponents,
                  setShowPromptForAdjustScoring,
                  showPromptForAdjustScoring
                );
              }}
            />
          </div>
        </div>
      </li>
    );
  }
);

const SortableList = SortableContainer(
  ({
    item,
    index,
    componentCollection,
    setComponentCollection,
    searchTermComponents,
    setBlurr,
    validationErrorTemplate,
    hasValidationErrorTemplate,
    setShowPromptForAdjustScoring,
    showPromptForAdjustScoring,
  }) => {
    let id;
    const thresholdToggle = isThresholdSet(
      index,
      componentCollection,
      searchTermComponents
    );
    const newOptionText = I18n.t('newCareplan_view.newOption');
    return (
      <div
        className="choicesParent"
        onBlur={() =>
          addSortables(
            item,
            componentCollection,
            setComponentCollection,
            setBlurr,
            validationErrorTemplate,
            hasValidationErrorTemplate,
            searchTermComponents
          )
        }
      >
        <div>
          {item.sortableCollection.sortables.map((elem, i) => {
            const value = `${elem.name}`;
            id = `sortables_${i}_${index}`;
            return (
              <React.Fragment key={`sortableElement_${i}_${index}`}>
                <SortableItem
                  id={id}
                  key={id}
                  index={i}
                  item={item}
                  value={value}
                  thresholdToggle={thresholdToggle}
                  componentCollection={componentCollection}
                  setComponentCollection={setComponentCollection}
                  validationErrorTemplate={validationErrorTemplate}
                  hasValidationErrorTemplate={hasValidationErrorTemplate}
                  i={i}
                  elem={elem}
                  validationErrorTemplate={validationErrorTemplate}
                  hasValidationErrorTemplate={hasValidationErrorTemplate}
                  searchTermComponents={searchTermComponents}
                  setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
                  showPromptForAdjustScoring={showPromptForAdjustScoring}
                />
              </React.Fragment>
            );
          })}
        </div>
        <div className="addChoice">
          <Flex otherStyles={{ flex: 1 }}>
            <Checkbox
              id={`${id}_newSortableOption`}
              name={item.sortableCollection.addSortable}
              value={item.sortableCollection.addSortable}
              label={newOptionText}
              placeholder={newOptionText}
              valueInput={item.sortableCollection.addSortable}
              isDisabled={false}
              variant={CheckboxRadio.InlineInput}
              checked={false}
              inputWidth="100%"
              handleInputOnChange={e =>
                handleSortableList(
                  e,
                  item,
                  componentCollection,
                  setComponentCollection
                )
              }
              handleOnChange={() => {}}
            />
          </Flex>
          <div style={{ flexBasis: 40 }} />
        </div>
      </div>
    );
  }
);

const onSortEnd = (
  oldIndex,
  newIndex,
  item,
  componentCollection,
  setComponentCollection
) => {
  item.sortableCollection.sortables = arrayMoveImmutable(
    item.sortableCollection.sortables,
    oldIndex,
    newIndex
  );
  item.sortableCollection.optionWeights.weights = arrayMoveImmutable(
    item.sortableCollection.optionWeights.weights,
    oldIndex,
    newIndex
  );
  setComponentCollection([...componentCollection]);
};

const renderSortable = (
  item,
  componentCollection,
  searchTermComponents,
  setComponentCollection,
  setBlurr,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring
) => {
  const index = componentCollection.findIndex(comp => comp.id === item.id);
  return (
    <SortableList
      item={item}
      index={index}
      componentCollection={componentCollection}
      setComponentCollection={setComponentCollection}
      searchTermComponents={searchTermComponents}
      setBlurr={setBlurr}
      validationErrorTemplate={validationErrorTemplate}
      hasValidationErrorTemplate={hasValidationErrorTemplate}
      setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
      showPromptForAdjustScoring={showPromptForAdjustScoring}
      onSortEnd={({ oldIndex, newIndex }) =>
        onSortEnd(
          oldIndex,
          newIndex,
          item,
          componentCollection,
          setComponentCollection
        )
      }
    />
  );
};

export default renderSortable;
