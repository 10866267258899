const MODAL_VARIANT = {
  TOP: 'top',
  BOTTOM: 'bottom',
  COL_W20: 'col--w20',
  COL_W30: 'col--w30',
  COL_W40: 'col--w40',
  COL_W60: 'col--w60',
  COL_W70: 'col--w70',
  COL_W80: 'col--w80',
};

const MODAL_FORM_DIRECTION = {
  ROW: 'row',
  COLUMN: 'column',
};

export { MODAL_VARIANT, MODAL_FORM_DIRECTION };
