import React from 'react';
import classNames from 'classnames';
import {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../utils/button';
import { PrimaryColor, Greys, ActionColors } from '../../utils/colors';
import '../../styles/main.scss';
import { ButtonProps } from './Button.interface';

/**
 * The Button component is a versatile UI element designed for interactivity and user engagement.
 * It serves as a clickable element that triggers actions or events within your application.
 */
function Button({
  type = ButtonType.BUTTON,
  variant = ButtonVariant.CONTAINED,
  fullWidth = false,
  label,
  onClick,
  form,
  children,
  color = ButtonColor.MAIN_COLOR,
  size = ButtonSize.SM,
  ...rest
}: ButtonProps): JSX.Element {
  const { disabled } = rest;

  const buttonStyles = classNames({
    btn: true,
    'btn--full-width': fullWidth,
    'btn--xs': size === ButtonSize.XS,
    'btn--sm': size === ButtonSize.SM,
    'btn--md': size === ButtonSize.MD,
    'btn--link': variant === ButtonVariant.LINK,
    'btn--contained': variant === ButtonVariant.CONTAINED,
    'btn--contained--red':
      color === ButtonColor.RED && variant === ButtonVariant.CONTAINED,
    'btn--outlined': variant === ButtonVariant.OUTLINED,
    'btn--outlined--red':
      color === ButtonColor.RED && variant === ButtonVariant.OUTLINED,
    'btn--no-border': variant === ButtonVariant.NO_BORDER,
    'btn--no-border--red':
      color === ButtonColor.RED && variant === ButtonVariant.NO_BORDER,
  });

  function getFillConditions(): string {
    if (variant === ButtonVariant.CONTAINED) {
      if (disabled) {
        return Greys.LIGHT_GREY;
      }
      return Greys.WHITE;
    }

    if (
      disabled &&
      (variant === ButtonVariant.OUTLINED ||
        variant === ButtonVariant.NO_BORDER)
    ) {
      return Greys.LIGHT_GREY;
    }

    if (
      color === ButtonColor.RED &&
      (variant === ButtonVariant.OUTLINED ||
        variant === ButtonVariant.NO_BORDER)
    ) {
      return ActionColors.THRESHOLD;
    }
    return PrimaryColor.MAIN_COLOR;
  }

  const childDetail = {
    style: { marginRight: '12px' },
    fill: getFillConditions(),
  };

  const childrenRender =
    children &&
    React.Children.map<React.ReactNode, React.ReactNode>(children, child => {
      if (React.isValidElement(child))
        return React.cloneElement(
          child as React.ReactElement<any>,
          childDetail
        );
      return null;
    });

  return (
    <button
      form={form}
      type={type}
      onClick={onClick}
      className={buttonStyles}
      {...rest}
    >
      {childrenRender}
      {label}
    </button>
  );
}

export default Button;
