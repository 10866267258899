import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import '../../../css/carepathway.css';
import axios from 'axios';
import {
  Checkbox,
  Icon,
  IconButton,
  CheckboxWarning,
  ComponentWithLabel,
  Box,
  Flex,
  RadioButton,
  ICDViewer,
  DatePicker,
  TimePicker,
  Modal,
} from '@ui-common-files/components';
import {
  ComponentTypes,
  getDurationTextForOverview,
  sortCarepathwayCareplanTemplateByStartingTime,
  ActionColors,
  PrimaryColor,
  ComponentColors,
  CheckBoxRadioVariants,
  SubmissionTypes,
  isReferenceDateToday,
} from '@ui-common-files/utils';
import {
  PromptContent,
  PromptFooter,
} from 'src/Views/common/ConfirmationPrompt';
import ModalForm from '../../common/Layout/Modal/ModalForm';
import ModalSection from '../../common/Layout/Modal/ModalSection';
import { ShowCaretaskData } from '../CaretaskData';

import { MODAL_VARIANT } from '../../../Utils/Layout/modalSection';

import serviceConfig from '../../../../serviceConfig.json';
import { FirebaseContext } from '../../../Utils/Firebase/firebase';
import { CareTaskInfoRender } from '../../CarePathWay/Builder/Components/CareTasksInfoRender/CareTaskInfoRender';
import { nonMedical } from '../../../../config';
import { dropdownAppointmentTypes } from '../../../Utils/dropdownTypes';
import Occurrence from '@type/Common/Occurrence.enum';

const CarePathwayModalStep3 = ({
  isLoading,
  careplanData,
  setBottomPageReached,
  usWarningCheckboxChecked,
  setUsWarningCheckboxChecked,
}) => {
  const careplanComponentsDivWrapper = useRef(null);
  useEffect(() => {
    if (
      isLoading ||
      usWarningCheckboxChecked.confirmCheckbox === true ||
      usWarningCheckboxChecked.awareCheckbox === true
    ) {
      setBottomPageReached(true);
    } else {
      setBottomPageReached(false);
    }
    if (careplanData.length > 0 && !isLoading) {
      const divWrapper = careplanComponentsDivWrapper.current;
      if (
        divWrapper.scrollHeight - divWrapper.scrollTop ===
        divWrapper.clientHeight
      ) {
        setBottomPageReached(true);
      }
    }
  }, [isLoading, careplanData]);
  const handleScroll = () => {
    const element = careplanComponentsDivWrapper.current;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setBottomPageReached(true);
    } else if (
      usWarningCheckboxChecked.confirmCheckbox === false ||
      usWarningCheckboxChecked.awareCheckbox === false
    ) {
      setBottomPageReached(false);
    }
  };

  return (
    <>
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{
            width: '100%',
            height: '100%',
            position: 'inherit',
          }}
        >
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Flex>
      )}
      {careplanData.length > 0 && !isLoading && (
        <Box
          className="carepathway_modal-layout"
          onScroll={handleScroll}
          ref={careplanComponentsDivWrapper}
        >
          <CareTaskInfoRender selectedCareTasks={careplanData} />

          <Box>
            <CheckboxWarning
              usWarningCheckboxChecked={usWarningCheckboxChecked}
              nonMedical={nonMedical}
              changeAwareCheckbox={event => {
                setUsWarningCheckboxChecked({
                  ...usWarningCheckboxChecked,
                  awareCheckbox: event.target.checked,
                });
              }}
              changeConfirmCheckbox={event => {
                setUsWarningCheckboxChecked({
                  ...usWarningCheckboxChecked,
                  confirmCheckbox: event.target.checked,
                });
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

const ChooseAppointment = ({
  carePathwayData,
  isPatientOverView,
  isAdhoc,
  setIsAdhoc,
  appointmentDate,
  setAppointmentDate,
  appointmentTime,
  setAppointmentTime,
  isUnspecified,
  setIsUnspecified,
  fromEditAssignment,
  patient,
  setOpenPopupConfirmation,
  openPopupConfirmation,
  setCurrentStep,
  currentStep,
  setIsBeforeCurrentTime,
}) => {
  const { icds, templates } = carePathwayData;

  const [appExistingDate, setAppExistingDate] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [appointmentTitle, setAppointmentTitle] = useState('');
  const [values, setValues] = useState({
    isReferenceApp: isAdhoc,
    unspecificDate: isUnspecified,
    referenceDate: appointmentDate,
    referenceTime: appointmentTime,
    isOpen: false,
  });
  const onValueChange = useCallback(
    (value, key) => {
      setValues({
        ...values,
        [key]: value,
      });
    },
    [values]
  );
  const defaultTime = {
    hour: 12,
    minute: 0,
  };
  const referenceType = dropdownAppointmentTypes().filter(
    option =>
      option.value ==
      templates[0].carePathwayTemplateAppointmentType[0].appointmentTypeId
  );
  const getPatientAppointment = async (
    patientId,
    appointmentTypeId,
    carepathwayIcdsIds
  ) => {
    try {
      const response = await axios.get(
        `${
          serviceConfig.brokerService.getAppointmentByPatientId.uri + patientId
        }/${appointmentTypeId}`,
        {
          params: {
            carepathwayIcdsIds,
          },
        }
      );
      if (response.data && response.data[0]) {
        setAppointmentTitle(response.data[1]);
        return moment(response.data[0])
          .locale(I18n.language)
          .format('DD MMM YYYY HH:mm');
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };
  useEffect(() => {
    const getAppointmentData = async () => {
      const carepathwayIcdsIds = icds.map(icd => icd.id);
      const appointmentTypeId = referenceType[0].value;
      const patientApp = await Promise.all(
        patient.map(patientData => {
          return getPatientAppointment(
            patientData.id,
            appointmentTypeId,
            carepathwayIcdsIds
          );
        })
      );
      setAppExistingDate(
        patientApp.filter(patienAppointment => patienAppointment != undefined)
      );
      setIsLoaded(true);
    };

    getAppointmentData();
  }, []);

  const getAllCareplanWithOccurrences = () => {
    const carePathwayTemplates = [];
    for (let index = 0, { length } = templates; index < length; index++) {
      const template = templates[index];
      for (
        let subIndex = 0,
          count = template.carePathwayTemplateAppointmentType.length;
        subIndex < count;
        subIndex++
      ) {
        carePathwayTemplates.push({
          ...template,
          carePathwayTemplateAppointmentType:
            template.carePathwayTemplateAppointmentType[subIndex],
        });
      }
    }
    return carePathwayTemplates;
  };

  const rootCaretasksLength = useMemo(
    () =>
      getAllCareplanWithOccurrences().filter(
        template => template.carePathwayTemplateAppointmentType.isRoot
      ).length,
    [templates]
  );

  const buildTasks = () => {
    const allCareplansWithOccurrences = getAllCareplanWithOccurrences();
    return allCareplansWithOccurrences
      .filter(template => template.carePathwayTemplateAppointmentType.isRoot)
      .sort(sortCarepathwayCareplanTemplateByStartingTime)
      .map(template => {
        const { startText, endText, occurrence } = getDurationTextForOverview(
          template.carePathwayTemplateAppointmentType,
          referenceType[0].label
        );

        return (
          <ShowCaretaskData
            caretaskName={template.name}
            caretask={template.carePathwayTemplateAppointmentType}
            allCareplans={allCareplansWithOccurrences}
            startText={startText}
            occurrence={occurrence}
            endText={endText}
          />
        );
      });
  };

  const CarepathwayDetailItem = ({ title, children }) => {
    return (
      <Flex className="carepathway-details-content">
        <label for="Care Pathway" className="label-step-2">
          {I18n.t(title)}:
        </label>
        <Box className="step-2-text">{children}</Box>
      </Flex>
    );
  };

  useEffect(() => {
    const carePathwayReferenceTime =
      appointmentTime.hour * 60 + appointmentTime.minute;
    const isBeforeCurrentTime = templates.some(template => {
      return template.carePathwayTemplateAppointmentType.some(careTask => {
        if (careTask.isRoot) {
          if (
            isReferenceDateToday(appointmentDate) &&
            careTask.lifespanUnit.toLowerCase() === Occurrence.Hour
          ) {
            const appointmentValidityTime =
              careTask.recurrence.byhour * 60 +
              careTask.recurrence.byminute +
              careTask.lifespan * 60;
            return carePathwayReferenceTime < appointmentValidityTime;
          }
          return true;
        }
        return false;
      });
    });
    setIsBeforeCurrentTime(isBeforeCurrentTime);
  }, [values]);

  return (
    <ModalForm>
      <ModalSection>
        <Box className="appointment-layout">
          <h3 className="title-step-2">
            {I18n.t('chooseAppointment_view.defineReferenceDate')}
          </h3>
          <Flex>
            <RadioButton
              id="referenceDate"
              name="referenceDate"
              label={I18n.t('chooseAppointment_view.chooseReferenceDate')}
              variant={CheckBoxRadioVariants.Default}
              checked={values.isReferenceApp}
              handleOnChange={e => {
                onValueChange(e.target.checked, 'isReferenceApp');
                setIsAdhoc(e.target.checked);
              }}
            />
            <Box className="info-icon">
              <IconButton
                name="info"
                size={22}
                tooltipText={
                  <Box className="tooltip-appoinment-content">
                    {I18n.t('chooseAppointment_view.setReferenceDay_info')}
                  </Box>
                }
              />
            </Box>
          </Flex>
          <Box className="appointment-ref-date">
            <Flex>
              <span className="appointment-text">
                {' '}
                {I18n.t('chooseAppointment_view.defineDateTime')}
              </span>
              <Box className="info-icon">
                <IconButton
                  name="info"
                  size={22}
                  tooltipText={
                    <Box className="tooltip-appoinment-content">
                      {I18n.t('chooseAppointment_view.defineDate_info')}
                    </Box>
                  }
                />
              </Box>
            </Flex>

            <Flex flexDirection="row" justifyContent="space-between">
              <Box flex="1" margin="14px 6px 17px 15px">
                <DatePicker
                  date={values.referenceDate}
                  minDate={new Date()}
                  placeholder={I18n.t('chooseAppointment_view.selectDate')}
                  handleDate={date => {
                    onValueChange(date, 'referenceDate');
                    setAppointmentDate(date);
                  }}
                  validationMessage={I18n.t('recurrence.startDateError')}
                  shouldShowValidationMessage
                  disabled={!values.isReferenceApp}
                />
              </Box>
              <Box flex="1" margin="14px 0 0 0">
                <TimePicker
                  isDisabled={!values.isReferenceApp || values.unspecificDate}
                  value={
                    values.unspecificDate ? defaultTime : values.referenceTime
                  }
                  isMinuteDisabled={false}
                  minuteIncrement={15}
                  getTimeStamp={time => {
                    onValueChange(time, 'referenceTime');
                    setAppointmentTime(time);
                  }}
                />
              </Box>
            </Flex>
            <Box margin="0 0 15px 15px">
              <Checkbox
                id={1}
                name="unspecificDate"
                label={I18n.t('chooseAppointment_view.unspecifiedDate')}
                checked={values.unspecificDate}
                variant={CheckBoxRadioVariants.Default}
                handleOnChange={e => {
                  onValueChange(e.target.checked, 'unspecificDate');
                  setIsUnspecified(e.target.checked);
                }}
                isDisabled={!values.isReferenceApp}
              />
            </Box>
          </Box>
          <Box margin="17px 0">
            {!fromEditAssignment ? (
              <Box>
                <Flex>
                  <RadioButton
                    id="existingApp"
                    name="existingApp"
                    label={I18n.t('chooseAppointment_view.existingAppointment')}
                    variant={CheckBoxRadioVariants.Default}
                    checked={!values.isReferenceApp}
                    handleOnChange={e => {
                      onValueChange(!e.target.checked, 'isReferenceApp');
                      setIsAdhoc(!e.target.checked);
                    }}
                    isDisabled={!appExistingDate.length && isLoaded}
                  />
                  <Box className="info-icon">
                    <IconButton
                      name="info"
                      size={22}
                      tooltipText={
                        <Box className="tooltip-appoinment-content">
                          {I18n.t(
                            'chooseAppointment_view.existingAppointment_info'
                          )}
                        </Box>
                      }
                    />
                  </Box>
                </Flex>
                <Box margin="15px 0px 11px 33px">
                  {!appExistingDate.length && isLoaded ? (
                    <Flex>
                      <Box margin="2px 0 0 0">
                        <Icon
                          name="errorIcon"
                          size={15}
                          fill={ActionColors.ERROR}
                        />
                      </Box>
                      <Box>
                        <span
                          className="select-patient"
                          style={{ marginLeft: '7px' }}
                        >
                          {I18n.t('warningMessage.noAppointmentAssigned')}
                        </span>
                      </Box>
                    </Flex>
                  ) : (
                    <>
                      <Flex alignItems="center">
                        <Icon
                          name={ComponentTypes.APPOINTMENT}
                          size={30}
                          fill={
                            !values.isReferenceApp
                              ? ComponentColors.APPOINTMENT
                              : null
                          }
                          color={ComponentColors.APPOINTMENT}
                        />
                        <Box
                          className="card-title-text"
                          style={{ marginLeft: '11px' }}
                        >
                          {isPatientOverView
                            ? appointmentTitle
                            : I18n.t('chooseAppointment_view.referenceApp')}
                        </Box>
                      </Flex>
                      <Box className="appointment-details">
                        {isPatientOverView ? (
                          <Box className="carepathway-details-content">
                            <label for="Care Pathway" className="label-step-2">
                              {I18n.t('appointment.dateAndTime')}:{' '}
                            </label>
                            <p className="appointment-text">
                              {appExistingDate}
                            </p>
                          </Box>
                        ) : (
                          ''
                        )}

                        <Box className="carepathway-details-content">
                          <label
                            for="Care Pathway"
                            className="label-step-2"
                            style={{ width: '100%' }}
                          >
                            {' '}
                            {I18n.t('appointment.type')}:{' '}
                          </label>
                          <p className="appointment-text">
                            {referenceType[0].label}
                          </p>
                        </Box>
                        <Box className="carepathway-details-content">
                          <label for="Care Pathway" className="label-step-2">
                            {' '}
                            {I18n.t('task.ICDReference')}:{' '}
                          </label>
                          <Box
                            className="icds_container"
                            style={{ marginLeft: '70px' }}
                          >
                            <Box className="icds_text">{icds[0].code}</Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>
        <Modal
          isConfirmationDialog
          title={I18n.t('chooseAppointment_view.popupTitle')}
          contentComponent={
            <PromptContent
              message={I18n.t(
                'chooseAppointment_view.popupConfirmationMessage'
              )}
            />
          }
          footerComponent={
            <PromptFooter
              close={() => setOpenPopupConfirmation(false)}
              confirmHandler={() => {
                setCurrentStep(currentStep + 1);
              }}
              submissionType={SubmissionTypes.CONFIRM}
              btnLabelRight={I18n.t(
                'chooseAppointment_view.acceptWarningButton'
              )}
            />
          }
          openModal={openPopupConfirmation}
          onClose={() => setOpenPopupConfirmation(false)}
        />
      </ModalSection>
      <ModalSection>
        <Box className="carepathway-details">
          <h3 className="title-step-2">
            {I18n.t('chooseAppointment_view.carePathwayDetails')}{' '}
          </h3>
          <Box>
            <CarepathwayDetailItem title="newCareplan_view.carePathway">
              {carePathwayData.name}
            </CarepathwayDetailItem>
            <CarepathwayDetailItem title="common_labels.label_referenceType">
              {referenceType[0].label}
            </CarepathwayDetailItem>
            <CarepathwayDetailItem title="task.ICDReference">
              <Box className="icds_container">
                <Box className="icds_text">{icds[0].code}</Box>
              </Box>
            </CarepathwayDetailItem>
            <CarepathwayDetailItem title="nav.tasks">
              <Box
                className="tasks-dropdown"
                onClick={e => {
                  e.preventDefault();
                  onValueChange(!values.isOpen, 'isOpen');
                }}
              >
                <Flex>
                  <p style={{ margin: '2px 8px 0 0' }} className="step-2-text">
                    {rootCaretasksLength}{' '}
                    {I18n.t('chooseAppointment_view.tasks')}{' '}
                  </p>
                  <Icon
                    name={values.isOpen ? 'arrowUp' : 'arrowDown'}
                    size={18}
                    fill="454b48"
                    className="task-dropdown__arrow"
                  />
                </Flex>
              </Box>
            </CarepathwayDetailItem>
          </Box>
          <Box className="tasks-content">{values.isOpen && buildTasks()}</Box>
        </Box>
      </ModalSection>
    </ModalForm>
  );
};

const AsignSendStep = ({ carePathwayData, selectedPatientsData }) => {
  const { icds, templates } = carePathwayData;
  const { hidePatientDemographics } = useContext(FirebaseContext);
  const getAllCareplanWithOccurrences = () => {
    const carePathwayTemplates = [];
    for (let index = 0, { length } = templates; index < length; index++) {
      const template = templates[index];
      for (
        let subIndex = 0,
          count = template.carePathwayTemplateAppointmentType.length;
        subIndex < count;
        subIndex++
      ) {
        carePathwayTemplates.push({
          ...template,
          carePathwayTemplateAppointmentType:
            template.carePathwayTemplateAppointmentType[subIndex],
        });
      }
    }

    return carePathwayTemplates;
  };
  const sortCarepathwayTemplatesbyDurationForAssign = templates => {
    const carePathwayTemplates = [...templates];
    return carePathwayTemplates.sort(
      sortCarepathwayCareplanTemplateByStartingTime
    );
  };

  const renderSelectedpatients = () => {
    return selectedPatientsData.map(selectedPatient => (
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '5px 5px 10px 40px',
        }}
      >
        <Box width="40px" height="40px">
          <Box
            style={{
              backgroundImage: `url(${selectedPatient.profilePicture})`,
            }}
            className="notification__table-profile-pic"
          />
        </Box>
        <Box className="assign-template__patient-name">
          {hidePatientDemographics
            ? ''
            : `${selectedPatient.firstname} ${selectedPatient.lastname}`}
        </Box>
      </Box>
    ));
  };
  const sortedCareplanTemplates = sortCarepathwayTemplatesbyDurationForAssign(
    getAllCareplanWithOccurrences()
  );
  const rootCaretasks = sortedCareplanTemplates.filter(
    template => template.carePathwayTemplateAppointmentType.isRoot
  );

  const renderCareplansDuration = () => {
    return (
      <Flex
        flexDirection="column"
        otherStyles={{ width: '100%', fontSize: 14 }}
      >
        {rootCaretasks.map(template => {
          const { startText, endText, occurrence } = getDurationTextForOverview(
            template.carePathwayTemplateAppointmentType,
            carePathwayData.appointmentType
          );
          return (
            <ShowCaretaskData
              caretaskName={template.name}
              caretask={template.carePathwayTemplateAppointmentType}
              allCareplans={sortedCareplanTemplates}
              startText={startText}
              occurrence={occurrence}
              endText={endText}
            />
          );
        })}
      </Flex>
    );
  };

  return (
    <ModalForm>
      <ModalSection variant={MODAL_VARIANT.COL_W30}>
        <h3 className="assigned-to">
          {I18n.t('assignTemplate_view.assignedTo')}
        </h3>
        {renderSelectedpatients()}
      </ModalSection>
      <ModalSection variant={MODAL_VARIANT.COL_W70}>
        <Box padding="20px 0" margin="0 0 0 -20px">
          <ComponentWithLabel
            id="pathwayName"
            htmlFor="pathwayName"
            labelText={I18n.t('newCareplan_view.carePathway')}
          >
            <Box style={{ wordBreak: 'break-word' }}>
              {carePathwayData.name}
            </Box>
          </ComponentWithLabel>
          <ComponentWithLabel
            id="pathwayIcds"
            htmlFor="pathwayIcds"
            labelText={I18n.t('task.ICDReference')}
          >
            <ICDViewer icds={icds} shouldShowAll />
          </ComponentWithLabel>

          <ComponentWithLabel
            id="pathwayCareplans"
            htmlFor="pathwayCareplans"
            labelText={I18n.t('nav.caretasks')}
          >
            {rootCaretasks.length}
          </ComponentWithLabel>
          <ComponentWithLabel
            id="pathway"
            htmlFor="pathway"
            labelText={I18n.t(
              'newCareplan_view.view_carePathwayTemplateSequence'
            )}
          >
            {renderCareplansDuration()}
          </ComponentWithLabel>
        </Box>
      </ModalSection>
    </ModalForm>
  );
};

CarePathwayModalStep3.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  careplanData: PropTypes.objectOf(PropTypes.object).isRequired,
  setBottomPageReached: PropTypes.func.isRequired,
  usWarningCheckboxChecked: PropTypes.shape({
    awareCheckbox: PropTypes.bool,
    confirmCheckbox: PropTypes.bool,
  }).isRequired,
  setUsWarningCheckboxChecked: PropTypes.func.isRequired,
};
export { CarePathwayModalStep3, ChooseAppointment, AsignSendStep };
