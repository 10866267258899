import React from 'react';

import { Box, DataTable, Flex } from '@ui-common-files/components';

import useBloodPressureJumpService from './BloodPressureJump.service';
import { BloodPressureJumpProps } from './BloodPressureJump.type';

import styles from './BloodPressureJump.module.scss';

export default function BloodPressureJump(props: BloodPressureJumpProps) {
  const { errorMessage, data, columns } = useBloodPressureJumpService(props);
  return (
    <Box className="blood-pressure-data-table">
      {errorMessage && <Flex className={styles.error}>{errorMessage}</Flex>}
      <DataTable
        customClassName="customMargin"
        onTableChange={() => {}}
        pagination={false}
        columns={columns}
        data={data}
        pages={1}
      />
    </Box>
  );
}
