/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import I18n from 'i18next';
import axios from 'axios';
import moment from 'moment';

import { ApiClient, HideFlashMessage } from '@global/Services';
import {
  ComponentTypes,
  Actions,
  SubmissionTypes,
  rruleFreqToRepeatTypeIndex,
  generateWeekdayArray,
} from '@ui-common-files/utils';
import {
  AppointmentRecurrence,
  IcdSelector,
  ModalContent,
  Modal,
} from '@ui-common-files/components';
import { communicationBitValues, icdThresholds } from '@utils';

import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import CarePathwayCardsList from '../AssignmentCards/CarePathwayCardsList';
import ApptModalContentForm from './ApptModalContentForm';
import ApptInformationOverview from './ApptInformationOverview';
import ModalForm from '../common/Layout/Modal/ModalForm';
import serviceConfig from '../../../serviceConfig.json';
import '../../css/patient.css';
import '../../css/user.css';
import '../../css/patientOverview.scss';
import '../../css/careplans.scss';

const ApptUpdateModalContent = ({ data, hasCarePathways, carePathways }) => {
  const {
    currentStep,
    setCurrentStep,
    shouldValidateForm,
    setFlashMessage,
    closeModal,
    shouldSubmitForm,
    dispatchFormSubmittor,
    patientInfo,
    setLandingTab,
    setDoFetch,
    doFetch,
  } = useContext(PatientOverviewContext);
  const [icds, setIcds] = useState(data.icdRecords ? data.icdRecords : []);
  const { appointmentType } = data;
  const repeatTypesArray = rruleFreqToRepeatTypeIndex();

  const startDateTime = moment(data.reminderElement.startsAt);
  const endDateTime = moment(data.reminderElement.endsAt);
  const [values, setValues] = useState({
    name: data.name,
    appointmentType: {
      value: appointmentType.id,
      label: I18n.t(appointmentType.name),
    },
    startDate: startDateTime.toDate(),
    startTime: {
      hour: startDateTime.get('hour'),
      minute: startDateTime.get('minute'),
    },
    repeatType: repeatTypesArray[repeatTypesArray.length - 1],
    repeatInterval: { value: 1, label: 1 },
    endDate: endDateTime.toDate(),
    endTime: {
      hour: endDateTime.get('hour'),
      minute: endDateTime.get('minute'),
    },
    attendees: [],
    weeklyRecurrence: new Array(7).fill(false),
    location: { value: data.place, label: data.place },
    notes: '',
    communicationModeBitValue:
      communicationBitValues.PUSH_NOTIFICATION_BIT_VALUE,
  });

  const [settings, setSettings] = useState({
    isSmsEnabled: false,
    users: [],
    maxIcdSelection: icdThresholds.APPOINTMENT,
  });

  const [hasErrors, setHasErrors] = useState({
    name: false,
    appointmentType: false,
    startDateTime: false,
    endDateTime: false,
    location: false,
    recurrenceRepeat: false,
  });

  const onValueChange = useCallback(
    (value, key) => {
      setValues({
        ...values,
        [key]: value,
      });
    },
    [values]
  );

  const getRepeatType = recurrenceRule => {
    if (recurrenceRule.origOptions.freq) {
      if (recurrenceRule.origOptions.count === 1)
        return repeatTypesArray[repeatTypesArray.length - 1];
      return repeatTypesArray[recurrenceRule.origOptions.freq];
    }
    return values.repeatType;
  };

  const [promptMessage, setPromptMessage] = useState({
    display: false,
    displayPathwayAssociatedPrompt: false,
    title: '',
    message: '',
  });

  const closeMessage = () => {
    setPromptMessage({
      display: false,
      title: '',
      message: '',
      displayed: true,
    });
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.editAppointment.uri,
        {
          params: {
            reminderId: data.reminderElement.reminderId,
          },
        }
      );
      if (response.data) {
        const { appointment, recurrenceRule } = response.data;
        setValues({
          ...values,
          repeatType: getRepeatType(recurrenceRule),
          repeatInterval: recurrenceRule.origOptions.interval
            ? {
                value: recurrenceRule.origOptions.interval,
                label: recurrenceRule.origOptions.interval,
              }
            : values.repeatInterval,
          attendees: response.data.appointmentUsers,
          weeklyRecurrence: recurrenceRule.options.byweekday
            ? generateWeekdayArray(recurrenceRule.options.byweekday)
            : values.weeklyRecurrence,
          notes: appointment.detail,
          communicationModeBitValue: response.data.communicationMode,
        });

        setSettings({
          users: response.data.users,
          maxIcdSelection: response.data.maxIcdSelection,
          isSmsEnabled: response.data.smsFlag,
        });
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  const appointmentHandler = async (
    associatedPathway = false,
    submissionType
  ) => {
    closeModal();
    let message = '';
    setLandingTab(false);
    try {
      const response = await axios.post(
        serviceConfig.brokerService.updateAppointment.uri,
        {
          reminderId: data.reminderElement.reminderId,
          patientId: patientInfo.id,
          selectedICDs: icds.map(icd => icd.id) || [],
          startDateTime: moment(values.startDate)
            .hour(values.startTime.hour)
            .minute(values.startTime.minute)
            .toISOString(),
          endDateTime: moment(values.endDate)
            .hour(values.endTime.hour)
            .minute(values.endTime.minute)
            .toISOString(),
          ...values,
          location: values.location.label,
        }
      );
      let unassignAppointmentResponse;
      if (response) {
        if (data.reminderElement.firebaseKey) {
          const isDeactivate =
            submissionType !== SubmissionTypes.ASSIGN_AND_SEND;
          
            unassignAppointmentResponse = await ApiClient.GET({
            url: serviceConfig.brokerService.unassignAppointment.uri,
            payload: {
              reminderId: data.reminderElement.reminderId,
              appointmentTypeId: data.appointmentType.id,
              isDeactivate,
            },
          });
        }
        
        let sendResponse = response;
        message = !hasCarePathways
          ? `${I18n.t(response.data.content)}`
          : `${I18n.t(response.data.content)} ${I18n.t(
              'flash.carePathwaysRescheduled'
            )}`;
        const isSendOutImmediately =
          shouldSubmitForm.submissionType === SubmissionTypes.ASSIGN_AND_SEND;
        if (isSendOutImmediately) {
          sendResponse = await ApiClient.POST({
            url: serviceConfig.brokerService.sendAppointment.uri,
            payload: {
              reminderId: data.reminderElement.reminderId,
              isUpdateAppointment: true,
              isUnAssigned: data.reminderElement.firebaseKey ? false : true,
            },
          })
        }
        if (sendResponse) {
          setFlashMessage({
            type: sendResponse.data.type,
            content: isSendOutImmediately
              ? `${message} ${I18n.t(sendResponse.data.content)}`
              : message,
          });
        }
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: `${I18n.t(error.response.data.content)}${message}`,
      });
    }
    setLandingTab(
      associatedPathway == true
        ? ComponentTypes.CAREPATHWAYS
        : ComponentTypes.APPOINTMENT
    );
    setDoFetch(!doFetch);
  };

  useEffect(() => {
    const updateAppointment = async submissionType => {
      appointmentHandler(false, submissionType);
    };

    if (
      shouldSubmitForm.component === ComponentTypes.APPOINTMENT &&
      shouldSubmitForm.action === Actions.Update
    ) {
      if (currentStep === 2 || currentStep === 3) {
        updateAppointment(shouldSubmitForm.submissionType);
      }
      return function cleanup() {
        dispatchFormSubmittor({
          component: '',
          action: '',
          submissionType: false,
        });
      };
    }
  }, [shouldSubmitForm]);

  const validateForm = () => {
    const startDateTime = moment(values.startDate)
      .hour(values.startTime.hour)
      .minute(values.startTime.minute)
      .unix();
    const endDateTime = moment(values.endDate)
      .hour(values.endTime.hour)
      .minute(values.endTime.minute)
      .unix();
    const currentTime = moment().unix();

    const nameError = values.name.trim().length < 5;
    const locationError = !(values.location || values.location.length);
    const recurrenceRepeatError =
      values.repeatType.value !== 2
        ? false
        : !values.weeklyRecurrence.find(elem => elem === true);
    const startDateTimeError = moment(startDateTime).isValid()
      ? startDateTime <= currentTime
      : true;
    const endDateTimeError = moment(endDateTime).isValid()
      ? endDateTime <= currentTime || endDateTime <= startDateTime
      : true;

    setHasErrors({
      ...hasErrors,
      name: nameError,
      recurrenceRepeat: recurrenceRepeatError,
      startDateTime: startDateTimeError,
      endDateTime: endDateTimeError,
      location: locationError,
      appointmentType: !values.appointmentType,
    });

    return !(
      nameError ||
      recurrenceRepeatError ||
      startDateTimeError ||
      endDateTimeError ||
      locationError ||
      !values.appointmentType
    );
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    return false;
  };

  const fetchIcdData = term => {
    return axios(serviceConfig.getIcds.uri, {
      params: {
        searchTerm: term,
      },
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    if (
      shouldValidateForm.component === ComponentTypes.APPOINTMENT &&
      shouldValidateForm.action === Actions.Update
    ) {
      if (validateForm()) setCurrentStep(currentStep + 1);
    }
  }, [shouldValidateForm]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <ModalContent>
      {currentStep === 1 && (
        <ModalForm
          formId="create-appointment-form"
          handleFormSubmit={handleFormSubmit}
        >
          <ApptModalContentForm
            values={values}
            users={settings.users}
            isSmsEnabled={settings.isSmsEnabled}
            recurrenceComponent={
              <AppointmentRecurrence
                onValueChange={onValueChange}
                values={values}
                hasErrors={hasErrors}
              />
            }
            icdSelectorComponent={
              <IcdSelector
                maxLengthItem={settings.maxIcdSelection}
                onSelect={setIcds}
                icdSelected={icds}
                fetchData={fetchIcdData}
                placeholder={I18n.t('newCareplan_view.placeholderICDReference')}
                isDisabled
              />
            }
            onValueChange={onValueChange}
            hasErrors={hasErrors}
            isUpdate
          />
        </ModalForm>
      )}

      {currentStep === 2 && hasCarePathways && (
        <div style={{ width: '100%', marginTop: '8px', padding: '0 20px' }}>
          <CarePathwayCardsList
            patientId={patientInfo.id}
            appointmentData={values}
            comesFromUpdateAppointment
            carePathways={carePathways.data}
          />
        </div>
      )}

      {currentStep === 2 && !hasCarePathways && (
        <ApptInformationOverview values={values} icds={icds} />
      )}

      {currentStep === 3 && (
        <ApptInformationOverview values={values} icds={icds} />
      )}
      {promptMessage.display && (
        <Modal
          isConfirmationDialog
          title={promptMessage.title}
          contentComponent={<PromptContent message={promptMessage.message} />}
          footerComponent={
            <PromptFooter
              close={closeMessage}
              confirmHandler={() => {
                closeMessage;
              }}
              btnLabelRight={I18n.t('common_buttons.confirm')}
            />
          }
          openModal={promptMessage.display}
          onClose={closeMessage}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </ModalContent>
  );
};

export default ApptUpdateModalContent;
