const isInclusiveBetween = (bodyCoordinate, lowerLimit, upperLimit) => {
  return bodyCoordinate > lowerLimit && bodyCoordinate < upperLimit;
};

const getBodyClickPosition = (bodyCoordinates, imgSize, isBackSelected) => {
  const bodyCoordinateX = bodyCoordinates.x / imgSize.width;
  const bodyCoordinateY = bodyCoordinates.y / imgSize.height;

  if (
    isInclusiveBetween(bodyCoordinateX, 0.249, 0.5085) &&
    isInclusiveBetween(bodyCoordinateY, -0.0385, 0.0827)
  ) {
    return !isBackSelected ? 'head' : 'occiput';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.2976, 0.563) &&
    isInclusiveBetween(bodyCoordinateY, 0.0827, 0.113)
  ) {
    return !isBackSelected ? 'throat' : 'neck';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.08673, 0.6786) &&
    isInclusiveBetween(bodyCoordinateY, 0.113, 0.1516)
  ) {
    return 'shoulder';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.1752, 0.379) &&
    isInclusiveBetween(bodyCoordinateY, 0.1516, 0.3031)
  ) {
    return !isBackSelected ? 'rightChest' : 'back';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.379, 0.5833) &&
    isInclusiveBetween(bodyCoordinateY, 0.1516, 0.3031)
  ) {
    return !isBackSelected ? 'leftChest' : 'back';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.0594, 0.379) &&
    isInclusiveBetween(bodyCoordinateY, 0.1516, 0.3463)
  ) {
    return !isBackSelected ? 'rightUpperArm' : 'leftUpperArm';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.5833, 0.9029) &&
    isInclusiveBetween(bodyCoordinateY, 0.1516, 0.3463)
  ) {
    return !isBackSelected ? 'leftUpperArm' : 'rightUpperArm';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, -0.1037, 0.1208) &&
    isInclusiveBetween(bodyCoordinateY, 0.3463, 0.5538)
  ) {
    return !isBackSelected ? 'rightLowerArm' : 'leftLowerArm';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.631, 1) &&
    isInclusiveBetween(bodyCoordinateY, 0.3463, 0.5538)
  ) {
    return !isBackSelected ? 'leftLowerArm' : 'rightLowerArm';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.1888, 0.5833) &&
    isInclusiveBetween(bodyCoordinateY, 0.3031, 0.394)
  ) {
    return !isBackSelected ? 'stomach' : 'back';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.1684, 0.5902) &&
    isInclusiveBetween(bodyCoordinateY, 0.394, 0.4821)
  ) {
    if (
      isInclusiveBetween(bodyCoordinateX, 0.3316, 0.4629) &&
      isInclusiveBetween(bodyCoordinateY, 0.4711, 0.4821)
    ) {
      return !isBackSelected ? 'genital' : 'fundament';
    }
    return !isBackSelected ? 'alvus' : 'fundament';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.1616, 0.3827) &&
    isInclusiveBetween(bodyCoordinateY, 0.4821, 1)
  ) {
    return !isBackSelected ? 'rightLeg' : 'leftLeg';
  }
  if (
    isInclusiveBetween(bodyCoordinateX, 0.3827, 0.6038) &&
    isInclusiveBetween(bodyCoordinateY, 0.4821, 1)
  ) {
    return !isBackSelected ? 'leftLeg' : 'rightLeg';
  }
  return 'undefinedBodyPart';
};

export default getBodyClickPosition;
