import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import moment from 'moment';
import Loader from 'react-loader-spinner';

import MessageListSection from './MessageListSection';

import { PrimaryColor } from '@ui-common-files/utils';

const MessageList = ({
  messages,
  patientInfo,
  isPatientTyping,
  sendSiteTimeStamp,
  fileRef,
  isUploading,
  reloadChat,
  isPatientUsingNewVersion,
  loading,
}) => {
  const patientName = `${patientInfo.firstname} ${patientInfo.lastname}`;
  const messagesEnd = useRef();

  const scrollToEnd = () => {
    if (messagesEnd.current)
      messagesEnd.current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
  };

  const renderMessageBubble = useCallback(() => {
    return Object.keys(messages).map(dateRetrieved => {
      const todayMessage = moment().format('ll');
      const date =
        dateRetrieved === todayMessage
          ? `${I18n.t('chatMessages.TODAY')}`
          : dateRetrieved;
      return (
        <MessageListSection
          key={`section_${date}`}
          numberOfMessages={messages[dateRetrieved].length}
          listOfMessages={messages[dateRetrieved]}
          patientInfo={patientInfo}
          fileRef={fileRef}
          date={date}
          isPatientUsingNewVersion={isPatientUsingNewVersion}
        />
      );
    });
  }, [messages]);

  useEffect(() => {
    sendSiteTimeStamp();
  }, [messages]);

  useEffect(() => {
    scrollToEnd();
    setTimeout(scrollToEnd, 1500);
  }, [messages, isUploading, isPatientTyping]);
  const renderLoadingWheel = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader
          type="Oval"
          color={PrimaryColor.MAIN_COLOR}
          height={40}
          width={40}
        />
      </div>
    );
  };

  return (
    <div
      className={
        reloadChat
          ? 'chat__messages-box justify-content-center__loader'
          : 'chat__messages-box'
      }
    >
      {loading && renderLoadingWheel()}
      {reloadChat ? (
        <div style={{ textAlign: 'center', marginTop: 0 }}>
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </div>
      ) : (
        <>
          {renderMessageBubble()}
          <div ref={messagesEnd} className="chat__message-list__end" />
          {isUploading && (
            <div style={{ textAlign: 'center' }}>
              <Loader
                type="Oval"
                color={PrimaryColor.MAIN_COLOR}
                height={60}
                width={60}
              />
            </div>
          )}
          {isPatientTyping && (
            <p className="chat__is-typing">
              {I18n.t('common_labels.typingMsg', { Patient: patientName })}
            </p>
          )}
        </>
      )}
    </div>
  );
};

MessageList.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ])
  ).isRequired,
  isPatientTyping: PropTypes.bool.isRequired,
  sendSiteTimeStamp: PropTypes.func.isRequired,
  fileRef: PropTypes.objectOf(PropTypes.object).isRequired,
  isUploading: PropTypes.bool.isRequired,
};

export default React.memo(MessageList);
