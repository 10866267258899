import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@ui-common-files/components';
import {
  PromptContent,
  PromptFooter,
} from '../../../common/ConfirmationPrompt';

import { HideFlashMessage } from '@global/Services';
import { ReviewAssessmentsModalProps } from './ReviewAssessmentsModal.type';

export default function ReviewAssessmentsModal({
  deleteOperation,
  closeMessagePrompt,
  deleteCaretask,
  active,
}: ReviewAssessmentsModalProps) {
  const { t } = useTranslation();
  if (!active) return null;

  return (
    <Modal
      isConfirmationDialog
      title={deleteOperation.title}
      contentComponent={
        <PromptContent
          message={t('common_labels.msg_confirm_template_delete')}
        />
      }
      footerComponent={
        <PromptFooter
          close={closeMessagePrompt}
          confirmHandler={() => {
            if (deleteOperation.id > 0) {
              deleteCaretask(deleteOperation.id);
            }
          }}
        />
      }
      openModal={deleteOperation.display}
      onClose={closeMessagePrompt}
      hideFlashMessage={HideFlashMessage}
    />
  );
}
