import React from 'react';
import { useDropzone } from 'react-dropzone';
import I18n from 'i18next';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';

const ImportTemplateDropzone = ({ onDrop, accept, file, submitQuery, isOncoBox, errors }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    file,
  });

  return (
    <div {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content" />
        ) : (
          <div className="drag-and-drop__inner">
            <h3 className="drag_and_drop__files" style={{ height: 20 }}>
              {I18n.t('newTemplate_view.dragAndDrop')}
            </h3>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                label={I18n.t('newTemplate_view.selectFile')}
                onClick={() => {}}
              />
            </div>

            <h3
              className={
                (submitQuery || errors) && !file.name 
                  ? 'care-file___name-error'
                  : 'care-file___name'
              }
            >
              {!file.name && (submitQuery || (errors && errors.incorrectMimeType))
                ? I18n.t(isOncoBox ? 'patients_view.xmlFileRequired' : 'newTemplate_view.careFileRequired')
                : 
                errors && errors.incorrectNameLength ?
                file.name.substring(0,50) + '...'
                : file.name}
              {errors && errors.incorrectFileSize && !file.name
                && I18n.t('patients_view.maximumFileSize')}
              {errors && errors.multipleFiles && !file.name
                && I18n.t('patients_view.multipleFileNotAllowed')}
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportTemplateDropzone;
