const inputField = {
  type: {
    EMAIL: 'email',
    PASSWORD: 'password',
    SEARCH: 'search',
    TEL: 'tel',
    TEXT: 'text',
  },
  variant: {
    DEFAULT: 'default',
    WITH_ICON: 'with-icon',
    VALUE_RIGHT: 'value-right',
    CHAR_COUNT: 'char-count',
    INLINE_INPUT: 'inline-input',
  },
  autocomplete: {
    ON: 'on',
    OFF: 'off',
  },
};

export default inputField;
