import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';

import { roles } from '@utils';
import { USER_GUIDE_LINKS } from '@utils/GuideLinks/UserGuideLinks.constant';

import { AuxiliaryColors, PrimaryColor } from '@ui-common-files/utils/colors';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalSection from '../common/Layout/Modal/ModalSection';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import TextInput from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Dropdown from '../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import validateFirstnameLastname from '../../Utils/hooks/validateFirstnameLastname';
import serviceConfig from '../../../serviceConfig.json';
import inputField from '../../../caro-ui-commonfiles/utils/inputField';
import ImageUpload from '../../../caro-ui-commonfiles/components/molecules/ImageUpload/ImageUpload';
import tabPosition from '../../Utils/Administration/tabPosition';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import maxCharLimit from '../../../caro-ui-commonfiles/utils/maxcharlimits.json';
import '../../css/user.css';
import {
  createAvatarAsset,
  createAvatarResource,
} from '../../Utils/Asset/assetAvatarHelper';
import avatarType from '../../Utils/Asset/assetAvatarTypes.json';
import { onSatelliteChange } from './users.utils';
import useSatelliteData from './useSatelliteData.service';

const UserCreateModalContent = ({
  closeModal,
  reloadTable,
  setFlashMessage,
  userData,
}) => {
  const { user } = useSelector(state => state.session);
  const { t, i18n } = useTranslation();

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    ward: '',
    password: '',
    repeatPassword: '',
    profilePicture: userData.profilePicture,
    role: userData.role,
    originSite: userData.originSite,
    treatedAISID: '',
  });
  const [hasErrors, setHasErrors] = useState({
    firstNameIsEmpty: false,
    firstNameIsInvalid: false,
    lastNameIsEmpty: false,
    lastNameIsInvalid: false,
    username: false,
    email: false,
    password: false,
    repeatPassword: false,
    profilePicture: false,
    isSatelliteEmpty: false,
    role: false,
    originSite: false,
    treatedAISID: false,
  });

  const [allRoles, setAllRoles] = useState(userData.prefilledRoles);
  const [allSites, setAllSites] = useState(userData.sites);
  const [emailErrorMsg, setEmailErrorMsg] = useState({
    required: false,
    invalid: false,
  });
  const [loading, setLoading] = useState(false);

  const emailFormat =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordFormat =
    /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#\$%\^&\*]).*$/;

  const pdfIFUEng = USER_GUIDE_LINKS.EN;
  const pdfIFUDe = USER_GUIDE_LINKS.DE;

  const language = i18n.language.trim();
  const pdf = language.split('-')[0] === 'de' ? pdfIFUDe : pdfIFUEng;
  const siteId = values.originSite.site.id;
  const {
    allSatellite,
    satellite,
    isExternalHCP,
    setIsExternalHCP,
    setSatellite,
  } = useSatelliteData(siteId, setLoading);
  const onValueChange = (value, key) => {
    if (key === 'role') {
      setIsExternalHCP(value.label === roles.EXTERNAL_HCP);
    }
    setValues({
      ...values,
      [key]: value,
    });
  };
  const onValueErrorChange = (value, key) => {
    setHasErrors({
      ...hasErrors,
      [key]: value,
    });
  };

  const validateEmail = () => {
    if (values.email.length < 1) {
      setEmailErrorMsg({
        required: t('parsley.requiredField'),
        invalid: false,
      });
      return false;
    }
    const isEmailValid = emailFormat.test(String(values.email).toLowerCase());
    !isEmailValid
      ? setEmailErrorMsg({
          required: false,
          invalid: t('common_labels.invalid_email'),
        })
      : setEmailErrorMsg({ required: false, invalid: false });
    return isEmailValid;
  };

  const validatePassword = () => {
    setHasErrors({
      ...hasErrors,
      password: !passwordFormat.test(values.password),
    });
  };

  const validateRepeatPassword = () => {
    setHasErrors({
      ...hasErrors,
      repeatPassword: values.password !== values.repeatPassword,
    });
  };
  const validateForm = () => {
    const formErrors = {
      ...hasErrors,
      firstNameIsEmpty: values.firstName.trim().length < 1,
      firstNameIsInvalid: validateFirstnameLastname(values.firstName.trim()),
      lastNameIsEmpty: values.lastName.trim() < 1,
      lastNameIsInvalid: validateFirstnameLastname(values.lastName.trim()),
      username: values.username.trim().length < 1,
      email: !validateEmail(),
      isSatelliteEmpty: isExternalHCP ? !satellite : null,
      password: !passwordFormat.test(values.password),
      repeatPassword: values.password !== values.repeatPassword,
    };
    setHasErrors(formErrors);
    return !Object.keys(formErrors).some(k => formErrors[k]);
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    if (validateForm()) {
      createUser();
    }
    return false;
  };

  const createUser = async () => {
    setLoading(true);
    try {
      values.password = values.password;
      values.repeatPassword = values.repeatPassword;
      let resp;
      let asset;
      const response = await createAvatarAsset(
        values.profilePicture,
        values.username,
        avatarType.USERS
      )
        .then(resource => {
          const { collectionId, assetId } = resource.data;
          asset = assetId;
          values.collectionId = collectionId;
          return axios.post(serviceConfig.brokerService.createUser.uri, {
            userId: user.id,
            satelliteId: isExternalHCP ? satellite.satelliteData.id : null,
            ...values,
          });
        })
        .then(response => {
          resp = response;
          if (resp) {
            return createAvatarResource(
              response.data.userId,
              avatarType.USERS,
              asset
            );
          }
        })
        .then(() => resp);

      if (response) {
        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
        reloadTable(tabPosition.FIRST);
        closeModal();
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
    setLoading(false);
  };

  return (
    <ModalContent>
      {loading && (
        <div className="roleOverlapSpinner">
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </div>
      )}
      <ModalForm formId="create-user-form" handleFormSubmit={handleFormSubmit}>
        <ModalSection>
          <ComponentWithLabel
            id="profile-picture"
            htmlFor="profile-picture"
            hasIcon={false}
            labelText={t('admin_view.label_profile_picture')}
            tooltipText={t('admin_view.label_profile_picture')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <ImageUpload
              blob={values.profilePicture}
              handleOnChange={imgUrl => onValueChange(imgUrl, 'profilePicture')}
              styleProps={{
                height: '80px',
                width: '80px',
                borderRadius: '50%',
              }}
              setHasError={value => onValueErrorChange(value, 'profilePicture')}
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="firstName"
            htmlFor="firstName"
            hasIcon={false}
            labelText={`* ${t('admin_view.label_firstNameUser')}`}
            tooltipText={t('admin_view.label_firstNameUser')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={values.firstName}
              hasError={
                hasErrors.firstNameIsEmpty || hasErrors.firstNameIsInvalid
              }
              id="firstName"
              name="firstName"
              placeholder={t('patients_view.add_firstname')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.user.firstNameMaxCharLimit}
              maxChars={maxCharLimit.user.firstNameMaxCharLimit}
              handleOnChange={event => {
                onValueChange(event.target.value, 'firstName');
              }}
              validationMessage={
                hasErrors.firstNameIsEmpty
                  ? t('parsley.requiredField')
                  : t('patients_view.invalidFirstName')
              }
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="lastName"
            htmlFor="lastName"
            hasIcon={false}
            labelText={`* ${t('admin_view.label_lastNameUser')}`}
            tooltipText={t('admin_view.label_lastNameUser')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={values.lastName}
              hasError={
                hasErrors.lastNameIsEmpty || hasErrors.lastNameIsInvalid
              }
              id="lastName"
              name="lastName"
              placeholder={t('patients_view.add_lastname')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.user.lastNameMaxCharLimit}
              maxChars={maxCharLimit.user.lastNameMaxCharLimit}
              handleOnChange={event => {
                onValueChange(event.target.value, 'lastName');
              }}
              validationMessage={
                hasErrors.lastNameIsEmpty
                  ? t('parsley.requiredField')
                  : t('patients_view.invalidLastName')
              }
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="username"
            htmlFor="username"
            hasIcon={false}
            labelText={`* ${t('admin_view.label_username')}`}
            tooltipText={t('admin_view.label_username')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={values.username}
              hasError={hasErrors.username}
              id="username"
              name="username"
              placeholder={t('admin_view.add_username')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.user.usernameMaxCharLimit}
              maxChars={maxCharLimit.user.usernameMaxCharLimit}
              handleOnChange={event => {
                onValueChange(event.target.value, 'username');
              }}
              validationMessage={t('parsley.requiredField')}
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="ward"
            htmlFor="ward"
            hasIcon={false}
            labelText={t('admin_view.label_ward')}
            tooltipText={t('admin_view.label_ward')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={values.ward}
              hasError={hasErrors.ward}
              id="ward"
              name="ward"
              maxChars={maxCharLimit.user.wardMaxCharLimit}
              maxLength={maxCharLimit.user.wardMaxCharLimit}
              placeholder={t('admin_view.add_ward')}
              handleOnChange={event =>
                onValueChange(event.target.value, 'ward')
              }
              validationMessage={t('parsley.requiredField')}
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="treatedAISID"
            htmlFor="treatedAISID"
            hasIcon={false}
            labelText={t('admin_view.label_treatedAISID')}
          >
            <TextInput
              value={values.treatedAISID}
              hasError={hasErrors.treatedAISID}
              id="treatedAISID"
              name="treatedAISID"
              maxChars={maxCharLimit.user.wardMaxCharLimit}
              maxLength={maxCharLimit.user.wardMaxCharLimit}
              placeholder={t('admin_view.add_treatedAISID')}
              handleOnChange={event =>
                onValueChange(event.target.value, 'treatedAISID')
              }
              validationMessage={t('parsley.requiredField')}
            />
          </ComponentWithLabel>
        </ModalSection>

        <ModalSection>
          <ComponentWithLabel
            id="email"
            htmlFor="email"
            hasIcon={false}
            labelText={`* ${t('admin_view.label_email_user')}`}
            tooltipText={t('admin_view.label_email_user')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={values.email}
              hasError={hasErrors.email}
              id="email"
              name="email"
              placeholder={t('patients_view.add_emailAddress')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.user.emailMaxCharLimit}
              maxChars={maxCharLimit.user.emailMaxCharLimit}
              handleOnChange={event => {
                onValueChange(event.target.value, 'email');
              }}
              validationMessage={
                !emailErrorMsg.required
                  ? emailErrorMsg.invalid || ''
                  : emailErrorMsg.required
              }
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="originSite"
            htmlFor="originSite"
            hasIcon={false}
            labelText={t('admin_view.label_userOriginSite')}
            tooltipText={t('admin_view.label_userOriginSite')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <Dropdown
              value={values.originSite}
              options={allSites}
              placeholder={t('admin_view.label_userOriginSite')}
              handleOnChange={event => onValueChange(event, 'originSite')}
              isDisabled={user.role.name === roles.SITE_ADMIN}
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="role"
            htmlFor="role"
            hasIcon={false}
            labelText={t('admin_view.label_Role')}
            tooltipText={t('admin_view.label_userRole')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
            labelDescription={
              <div>
                <a
                  href={pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="privacy-policy__linkIFU"
                >
                  {t('admin_view.label_IFU')}
                  <Icon
                    name="roleDescription"
                    size={16}
                    class="roleDescription"
                  />
                </a>
              </div>
            }
          >
            <Dropdown
              value={values.role}
              options={allRoles}
              placeholder={t('admin_view.label_userRole')}
              handleOnChange={event => onValueChange(event, 'role')}
            />
          </ComponentWithLabel>
          {isExternalHCP && (
            <ComponentWithLabel
              id="satellite"
              htmlFor="satellite"
              labelText={`* ${t('satellite_view.label_satellite')}`}
              tooltipText={t('satellite_view.label_satellite')}
            >
              <Dropdown
                isSearchable
                value={satellite}
                options={allSatellite}
                placeholder={t('satellite_view.add_satellite')}
                hasError={hasErrors.isSatelliteEmpty}
                handleOnChange={event =>
                  onSatelliteChange(
                    event,
                    setHasErrors,
                    hasErrors,
                    setSatellite
                  )
                }
              />
              {hasErrors.isSatelliteEmpty && (
                <span
                  style={{ color: AuxiliaryColors.RED }}
                  className="helperText"
                >
                  {t('parsley.requiredField')}
                </span>
              )}
            </ComponentWithLabel>
          )}
          <div id="password-section">
            <div className="wrapping-tip">
              <ComponentWithLabel
                id="password"
                htmlFor="password"
                hasIcon
                labelText={`* ${t('common_labels.label_newPassword')}`}
                tooltipText={
                  <div className="tooltip-text--w250">
                    {t('admin_view.password_pattern')}{' '}
                  </div>
                }
                hasToggleButton={false}
                idToggleButton="toggleBtn"
                valueToggleButton="toggleBtn"
                isDisabledToggleButton={false}
                checked={false}
              >
                <TextInput
                  value={values.password}
                  hasError={hasErrors.password}
                  id="password"
                  name="password"
                  placeholder={t('common_labels.add_newPassword')}
                  handleOnChange={event =>
                    onValueChange(event.target.value, 'password')
                  }
                  onBlur={() => validatePassword()}
                  validationMessage={t('admin_view.password_pattern')}
                  type={inputField.type.PASSWORD}
                />
              </ComponentWithLabel>
            </div>
            <ComponentWithLabel
              id="repeatPassword"
              htmlFor="repeatPassword"
              hasIcon={false}
              labelText={`* ${t('admin_view.label_retypePassword')}`}
              tooltipText={t('admin_view.label_retypePassword')}
              hasToggleButton={false}
              idToggleButton="toggleBtn"
              valueToggleButton="toggleBtn"
              isDisabledToggleButton={false}
              checked={false}
            >
              <TextInput
                value={values.repeatPassword}
                hasError={hasErrors.repeatPassword}
                id="repeatPassword"
                name="repeatPassword"
                placeholder={t('admin_view.label_retypePassword')}
                handleOnChange={event =>
                  onValueChange(event.target.value, 'repeatPassword')
                }
                onBlur={() => validateRepeatPassword()}
                validationMessage={t('admin_view.password_no_match')}
                type={inputField.type.PASSWORD}
              />
            </ComponentWithLabel>
          </div>
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
};

UserCreateModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  reloadTable: PropTypes.func.isRequired,
  setFlashMessage: PropTypes.func.isRequired,
};

export { UserCreateModalContent };
