import { Satellite } from '../../types';
import { AxiosResponse } from 'axios';

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  username: string;
}
export interface Role {
  id: number;
  name: string;
}
interface MentionListResponse {
  users: [User];
  roles: [Role];
  satellites: [Satellite];
}

export type MentionListCall = (
  value: string,
  signal: AbortSignal
) => Promise<AxiosResponse<MentionListResponse>>

export interface WYSIWYGEditorProps {
  onChange: (content: string) => void;
  value: string;
  placeholder?: string;
  maxChars?: number;
  hasLink?: boolean;
  hasMention?: boolean;
  mentionListCall?: MentionListCall;
  hideToolbar?: boolean;
  hideWordCount?: boolean;
  className?: string;
  textBoxClassName?: string;
}

export interface WYSIWYGServiceParams {
  maxChars: number;
  onChange: (content: string) => void;
  hasLink: boolean;
  placeholder: string;
  value: string;
  hasMention?: boolean;
  mentionListCall?: MentionListCall;
  hideToolbar?: boolean;
  hideWordCount?: boolean;
}

export interface Mention {
  id: number;
  name: string;
}
export interface WYSIWYGinitGetter {
  hasLink: boolean;
}

export enum WYSIWYGToolbar {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Forecolor = 'forecolor',
  Link = 'link',
}

export enum WYSIWYGPlugins {
  Wordcount = 'wordcount',
  Link = 'link',
}
