import moment from 'moment';

export function sortByDate<T>(
  list: T[],
  getDate: (item: T) => string | Date,
  order: 'asc' | 'desc' = 'desc'
): T[] {
  return list.sort((a, b) => {
    const dateA = moment(getDate(a)).valueOf();
    const dateB = moment(getDate(b)).valueOf();
    return order === 'desc' ? dateB - dateA : dateA - dateB;
  });
}
