import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { TopBarIconServiceProps } from './TopBarIcon.type';

import styles from './TopBarIcon.module.scss';

export default function useTopBarIconService({
  variant,
  count,
}: TopBarIconServiceProps) {
  const [countNumber, setCountNumber] = useState('');

  const buttonClass = classNames({
    [styles.button__count]: true,
    [styles.button__count__variant]: variant,
  });

  useEffect(() => {
    const newCount = count > 100 ? '99+' : `${count || ''}`;
    setCountNumber(newCount);
  }, [count]);

  return {
    buttonClass,
    countNumber,
  };
}
