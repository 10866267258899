/* eslint-disable react-hooks/exhaustive-deps */
import I18n from 'i18next';
import React, { useEffect, useState } from 'react';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import { Greys } from '../../../caro-ui-commonfiles/utils/colors';
import { MEDIA_QUERIES } from '@ui-common-files/utils/layout';
import {
  DestructuredOncoboxENPatient,
  TumorBoard,
} from '../PatientOverview/oncobox.interface';

const OncoBoxSectionCard = ({
  sectionName,
  sectionData,
}: {
  sectionName: string;
  sectionData: DestructuredOncoboxENPatient | TumorBoard;
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [toggle, setToggle] = useState(false);

  const onExpand = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <div className="oncobox__wrapper__card__oncobox">
      <div
        className={`oncobox__wrapper__card__oncobox__container ${
          toggle ? 'oncobox__wrapper__card__oncobox__container--blue' : ''
        }`}
      >
        <Box width="100%">
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div
              onClick={() => onExpand()}
              style={{ flex: 1, cursor: 'pointer' }}
            >
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                otherStyles={{ width: '100%' }}
              >
                <div
                  className={`oncobox__wrapper__card__oncobox__title ${
                    toggle ? 'oncobox__wrapper__card__oncobox__title--blue' : ''
                  }`}
                >
                  {I18n.t(`oncoboxImportedData.${sectionName}`)}
                </div>
                <div style={{ color: Greys.DARK_GREY }}>
                  {`${
                    Object.keys(sectionData.patient.masterData).length
                  } ${I18n.t('oncoboxImportedData.elements')}`}
                </div>
              </Flex>
            </div>
          </Flex>
        </Box>
        <Flex
          flexDirection={
            windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'
          }
          justifyContent="space-between"
          otherStyles={{ padding: '20px 0' }}
        >
          <div
            className={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? 'card__content-container card__container--row'
                : 'card__content-container card__container--column'
            }
          >
            <Flex alignItems="center">
              <IconButton
                name={toggle ? 'arrowUp' : 'arrowDown'}
                size={24}
                fill={Greys.DARK_GREY}
                onClick={() => {
                  onExpand();
                }}
              />
            </Flex>
          </div>
        </Flex>
      </div>
      <Box width="100%">
        {toggle && sectionData?.patient?.masterData
          ? Object.entries(sectionData.patient.masterData).map(
              ([key, value]) => (
                <Flex
                  key={key}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  otherStyles={{ width: '100%' }}
                >
                  <div className="oncobox__wrapper__card__oncobox__container--blue oncobox__wrapper__card__oncobox__subtitle">
                    {I18n.t(`oncoboxImportedData.${key}`)}
                  </div>
                  <div className="oncobox__wrapper__card__oncobox__elementValue">
                    {value}
                  </div>
                </Flex>
              )
            )
          : null}
      </Box>
    </div>
  );
};

export default OncoBoxSectionCard;
