/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import I18n from 'i18next';
import PhoneInput from 'react-phone-number-input';
import Loader from 'react-loader-spinner';
import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import ModalContent from '../../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../../common/Layout/Modal/ModalForm';
import ModalSection from '../../common/Layout/Modal/ModalSection';
import CustomCountrySelect from '../../common/CustomCountrySelect/CustomCountrySelect';
import FormGroupTitle from '../../common/FormGroupTitle/FormGroupTitle';
import ComponentWithLabel from '../../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import TextInput from '../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Dropdown from '../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import ImageUpload from '../../../../caro-ui-commonfiles/components/molecules/ImageUpload/ImageUpload';
import DatePicker from '../../../../caro-ui-commonfiles/components/DatePicker';
import IcdSelector from '../../../../caro-ui-commonfiles/components/ICDDropdownSelect/ICDSelect';
import inputField from '../../../../caro-ui-commonfiles/utils/inputField';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import SingleSelectPatientSearchable from '../../../../caro-ui-commonfiles/components/SingleSelectPatientSearchable/SingleSelectPatientSearchable';

import Actions from '../../../Utils/actions';
import { MODAL_VARIANT } from '../../../Utils/Layout/modalSection';

import { PrimaryColor } from '../../../../caro-ui-commonfiles/utils/colors';
import {
  toLocalizeDate,
  toBirthdateInYears,
} from '../../../../caro-ui-commonfiles/utils/dateUtils';
import modification from '../../../../caro-ui-commonfiles/utils/modification.json';
import maxCharLimit from '../../../../caro-ui-commonfiles/utils/maxcharlimits.json';
import {
  enableOps,
  enableOpsPatientView,
  isRunningFhir,
} from '../../../../config';
import { PatientFormContext } from '../PatientFormModal';
import serviceConfig from '../../../../serviceConfig.json';
import {
  fhirEntry,
  fhirLink,
} from '../../../../caro-ui-commonfiles/components/SingleSelectPatientSearchable/SingleSelectPatientDropMenu.types';
import { PatientFormContentProps } from './PatientFormContent.type';
import { ApiClient } from '../../../Global/Services';
import { isPatientDataEditable, isBlockchainActive } from '../../../../config';

function PatientFormContent({
  action,
  onSubmit,
  fetchIcdsOrOpsData,
  loading,
  operation
}: PatientFormContentProps) {
  const {
    birthDate,
    setBirthDate,
    values,
    setValues,
    hasErrors,
    setHasErrors,
    allSites,
    icdsLength,
    opsLength,
    fetchedIcds,
    fetchedOps,
    setAllIcds,
    setAllOps,
  } = useContext(PatientFormContext);
  const { t } = useTranslation();
  const [paginationController, setPaginationController] = useState({});
  const MAX_RESULTS_PER_FHIR_REQ = 10;
  const hasNumbersInString = (param: string) => {
    const numbersAndStringRegex = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/;
    return numbersAndStringRegex.test(param);
  };
  type Resource = {
    resource: {
      contact: [
        {
          name: { given: string[] };
        }
      ];
      id: string;
      identifier: [{ value: string }];
    };
  };
  const enableEditPatientData =
    action === Actions.Edit
      ? isPatientDataEditable && !isBlockchainActive
      : true;
  const hidePatientDemographics =
    values.isPatientDemographicData && action === Actions.Edit;
  const genders = [
    { value: 'Female', label: I18n.t('patients_view.gender_option2') },
    { value: 'Male', label: I18n.t('patients_view.gender_option1') },
  ];

  const onValueChange = (value, key) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const onValueErrorChange = (value, key) => {
    setHasErrors({
      ...hasErrors,
      [key]: value,
    });
  };

  const filterOptions = (
    inputValue: string | number,
    fhirPatients: Resource[]
  ): Resource[] => {
    if (
      fhirPatients &&
      Array.isArray(fhirPatients) &&
      fhirPatients.length > 0
    ) {
      return fhirPatients;
    } else {
      return [];
    }
  };

  const getInsuranceOptions = (optionList) =>
    optionList?.map(option => ({
      ...option,
      label: t(option.label),
      labelKey: option.label,
    }));

  let validationMessage;

  if (!hasErrors.medicalRecordIsEmpty) {
    validationMessage = hasErrors.patientIdIsInvalid
      ? t('patients_view.invalidPatientID')
      : t('patients_view.warningMessage_patientRecord');
  } else {
    validationMessage = t('parsley.requiredField');
  }

  const fetchData = (TextMetrics: string | number) => {
    return ApiClient.GET({
      url: serviceConfig.brokerService.fhirPatientsIndex.uri,
      payload: {
        params: {
          _count: MAX_RESULTS_PER_FHIR_REQ,
          identifier:
            hasNumbersInString(TextMetrics as string) ||
              !isNaN(parseInt(TextMetrics as string))
              ? TextMetrics
              : null,
          phonetic:
            !hasNumbersInString(TextMetrics as string) &&
              isNaN(parseInt(TextMetrics as string))
              ? TextMetrics
              : null,
        },
      },
    })
      .then(
        (response: AxiosResponse<{ entry: fhirEntry; link: fhirLink[] }>) => {
          setPaginationController(response?.data?.link);
          return filterOptions(TextMetrics, response?.data?.entry);
        }
      )
      .catch(err => log.error(err));
  };

   const getInsuranceValue = () => {
     const { InsuranceType, insuranceTypes } = values;
     const insuranceLabel = InsuranceType?.label;
     const isEditOperation = operation === Actions.Edit;

     if (insuranceLabel) {
       return {
         ...InsuranceType,
         label: t(insuranceLabel),
       };
     }
     if (
       isEditOperation &&
       InsuranceType &&
       insuranceTypes?.some(
         insuranceType => insuranceType?.label === InsuranceType
       )
     ) {
       return {
         ...InsuranceType,
         label: t(InsuranceType),
       };
     }
     if (InsuranceType) {
       return {
         label: InsuranceType,
         value: null,
       };
     }
     return null;
   }; 

  const renderSingleSelectPatientSearchable = () => {
    if (isRunningFhir && action === Actions.Create) {
      return (
        <div
          style={{
            width: '250px',
            marginLeft: 'auto',
            marginTop: '2%',
            marginRight: '3%',
          }}
        >
          <SingleSelectPatientSearchable
            paginationController={paginationController}
            setPaginationController={setPaginationController}
            patientFormValues={values}
            setPatientFormValues={setValues}
            fetchData={(term: string | number): Promise<Resource[]> => {
              return fetchData(term);
            }}
            placeholder={I18n.t('patients_view.fhir_input_reference')}
          />
        </div>
      );
    }
  };

  return (
    <ModalContent>
      {loading && (
        <div className="roleOverlapSpinner">
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </div>
      )}
      <ModalForm formId="form__add-patient" handleFormSubmit={onSubmit}>
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <Box margin="40px 0 0 0">
            <ImageUpload
              blob={values.profilePicture}
              handleOnChange={imgUrl => {
                onValueChange(imgUrl, 'profilePicture');
              }}
              styleProps={{
                height: '80px',
                width: '80px',
                borderRadius: '50%',
              }}
              setHasError={value => onValueErrorChange(value, 'profilePicture')}
              hidePatientDemographics={hidePatientDemographics}
            />
          </Box>
        </ModalSection>

        <ModalSection variant={MODAL_VARIANT.COL_W70}>
          {isRunningFhir &&
            action === Actions.Create &&
            renderSingleSelectPatientSearchable()}
          <FormGroupTitle>
            {I18n.t('patients_view.field-group_title_personal-data')}
          </FormGroupTitle>

          {!hidePatientDemographics && (
            <Flex flexDirection="row" justifyContent="space-between">
              <Box flex="1">
                <ComponentWithLabel
                  id="firstName"
                  htmlFor="firstName"
                  labelText={`* ${I18n.t('patients_view.label_firstName')}`}
                >
                  <TextInput
                    value={values.firstName}
                    hasError={
                      hasErrors.firstNameIsEmpty || hasErrors.firstNameIsInvalid
                    }
                    id="firstName"
                    name="firstName"
                    placeholder={I18n.t('patients_view.add_firstname')}
                    variant={inputField.variant.CHAR_COUNT}
                    maxChars={maxCharLimit.createPatient.firstNameMaxCharLimit}
                    maxLength={maxCharLimit.createPatient.firstNameMaxCharLimit}
                    handleOnChange={event => {
                      onValueChange(event.target.value, 'firstName');
                    }}
                    validationMessage={
                      hasErrors.firstNameIsEmpty
                        ? I18n.t('parsley.requiredField')
                        : I18n.t('patients_view.invalidFirstName')
                    }
                    isDisabled={!enableEditPatientData}
                  />
                </ComponentWithLabel>
              </Box>

              <Box flex="1">
                <ComponentWithLabel
                  id="lastName"
                  htmlFor="lastName"
                  labelText={`* ${I18n.t('patients_view.label_lastName')}`}
                >
                  <TextInput
                    value={values.lastName}
                    hasError={
                      hasErrors.lastNameIsEmpty || hasErrors.lastNameIsInvalid
                    }
                    id="lastName"
                    name="lastName"
                    placeholder={I18n.t('patients_view.add_lastname')}
                    variant={inputField.variant.CHAR_COUNT}
                    maxChars={maxCharLimit.createPatient.lastNameMaxCharLimit}
                    maxLength={maxCharLimit.createPatient.lastNameMaxCharLimit}
                    handleOnChange={event => {
                      onValueChange(event.target.value, 'lastName');
                    }}
                    validationMessage={
                      hasErrors.lastNameIsEmpty
                        ? I18n.t('parsley.requiredField')
                        : I18n.t('patients_view.invalidLastName')
                    }
                    isDisabled={!enableEditPatientData}
                  />
                </ComponentWithLabel>
              </Box>
            </Flex>
          )}

          <Flex flexDirection="row" justifyContent="space-between">
            <Box flex="1">
              {!hidePatientDemographics && (
                <ComponentWithLabel
                  id="birthDate"
                  htmlFor="birthDate"
                  labelText={`* ${I18n.t('patients_view.label_dateOfBirth')}`}
                >
                  <DatePicker
                    date={birthDate}
                    maxDate={new Date()}
                    error={hasErrors.birthDate}
                    handleDate={date => setBirthDate(date)}
                    validationMessage={I18n.t('parsley.requiredField')}
                    disabled={!enableEditPatientData}
                  />
                </ComponentWithLabel>
              )}
              {hidePatientDemographics && (
                <ComponentWithLabel
                  id="age"
                  htmlFor="age"
                  labelText={`${I18n.t('patientOverview.labelAge')}`}
                >
                  <TextInput
                    value={
                      !values.isBirthdayHardcoded
                        ? `${values.birthDate} ${I18n.t('patients_view.year')} `
                        : ' '
                    }
                    placeholder={I18n.t('patientOverview.labelAge')}
                    isDisabled
                  />
                </ComponentWithLabel>
              )}
            </Box>

            <Box flex="1">
              <ComponentWithLabel
                id="gender"
                htmlFor="gender"
                labelText={I18n.t('patients_view.label_gender')}
              >
                <Dropdown
                  value={values.gender}
                  options={genders}
                  placeholder={I18n.t('patients_view.add_gender')}
                  handleOnChange={event => onValueChange(event, 'gender')}
                  isDisabled={!enableEditPatientData}
                  hasError={hasErrors.gender}
                />
              </ComponentWithLabel>
            </Box>
          </Flex>

          {!hidePatientDemographics && (
            <FormGroupTitle>
              {I18n.t('patients_view.field-group_title_contact-information')}
            </FormGroupTitle>
          )}

          {!hidePatientDemographics && (
            <Flex flexDirection="row" justifyContent="space-between">
              <Box flex="1">
                <ComponentWithLabel
                  id="email"
                  htmlFor="email"
                  labelText={I18n.t('patients_view.label_email')}
                >
                  <TextInput
                    value={values.email}
                    hasError={hasErrors.email}
                    id="email"
                    name="email"
                    placeholder={I18n.t('patients_view.add_emailAddress')}
                    variant={inputField.variant.CHAR_COUNT}
                    maxChars={maxCharLimit.createPatient.emailMaxCharLimit}
                    maxLength={maxCharLimit.createPatient.emailMaxCharLimit}
                    handleOnChange={event => {
                      onValueChange(event.target.value, 'email');
                    }}
                    validationMessage={I18n.t('common_labels.invalid_email')}
                    isDisabled={!enableEditPatientData}
                  />
                </ComponentWithLabel>
              </Box>

              <Box flex="1">
                <ComponentWithLabel
                  id="mobile"
                  htmlFor="mobile"
                  labelText={I18n.t('site_view.label_phone')}
                >
                  <PhoneInput
                    defaultCountry="DE"
                    placeholder={I18n.t('site_view.add_phone')}
                    maxLength={
                      maxCharLimit.createPatient.mobileNumberMaxCharLimit
                    }
                    value={values.mobile}
                    className={`patients__phone-field${
                      !enableEditPatientData
                        ? ' patients__phone-field--disabled'
                        : hasErrors.mobile
                        ? ' error'
                        : ''
                    }`}
                    inputClassName="patients__phone-input"
                    onChange={mobile => {
                      onValueChange(mobile, 'mobile');
                    }}
                    countrySelectComponent={props => {
                      return <CustomCountrySelect action={action} {...props} />;
                    }}
                    disabled={!enableEditPatientData}
                  />

                  {hasErrors.mobile === true && (
                    <span className="patients__phone-field error">
                      {I18n.t('site_view.invalidPhone')}
                    </span>
                  )}
                </ComponentWithLabel>
              </Box>
            </Flex>
          )}
          {!hidePatientDemographics && (
            <Flex flexDirection="row" justifyContent="space-between">
              <Box flex="1">
                <ComponentWithLabel
                  id="address"
                  htmlFor="address"
                  labelText={I18n.t('appointment.place')}
                >
                  <TextInput
                    value={values.address}
                    hasError={hasErrors.address}
                    id="address"
                    name="address"
                    placeholder={I18n.t('appointment.select address')}
                    variant={inputField.variant.CHAR_COUNT}
                    maxChars={maxCharLimit.createPatient.addressMaxCharLimit}
                    maxLength={maxCharLimit.createPatient.addressMaxCharLimit}
                    handleOnChange={event => {
                      onValueChange(event.target.value, 'address');
                    }}
                    isDisabled={!enableEditPatientData}
                  />
                </ComponentWithLabel>
              </Box>

              <Box flex="1">
                <ComponentWithLabel
                  id="city"
                  htmlFor="city"
                  labelText={I18n.t('patients_view.label_city')}
                >
                  <TextInput
                    value={values.city}
                    hasError={hasErrors.city}
                    id="city"
                    name="city"
                    placeholder={I18n.t('patients_view.placeholder_city')}
                    variant={inputField.variant.CHAR_COUNT}
                    maxChars={maxCharLimit.createPatient.cityMaxCharLimit}
                    maxLength={maxCharLimit.createPatient.cityMaxCharLimit}
                    handleOnChange={event => {
                      onValueChange(event.target.value, 'city');
                    }}
                    isDisabled={!enableEditPatientData}
                  />
                </ComponentWithLabel>
              </Box>
            </Flex>
          )}
          {!hidePatientDemographics && (
            <Flex flexDirection="row" justifyContent="space-between">
              <Box flex="1">
                <ComponentWithLabel
                  id="postalCode"
                  htmlFor="postalCode"
                  labelText={I18n.t('patients_view.label_postal-code')}
                >
                  <TextInput
                    value={values.postalCode}
                    hasError={hasErrors.postalCode}
                    id="postalCode"
                    name="postalCode"
                    placeholder={I18n.t(
                      'patients_view.placeholder_postal-code'
                    )}
                    variant={inputField.variant.CHAR_COUNT}
                    maxChars={maxCharLimit.createPatient.postalCodeMaxCharLimit}
                    maxLength={
                      maxCharLimit.createPatient.postalCodeMaxCharLimit
                    }
                    handleOnChange={event => {
                      onValueChange(event.target.value, 'postalCode');
                    }}
                    isDisabled={!enableEditPatientData}
                  />
                </ComponentWithLabel>
              </Box>

              <Box flex="1">
                <ComponentWithLabel
                  id="country"
                  htmlFor="country"
                  labelText={I18n.t('patients_view.label_country')}
                >
                  <TextInput
                    value={values.country}
                    hasError={hasErrors.country}
                    id="country"
                    name="country"
                    placeholder={I18n.t('patients_view.placeholder_country')}
                    variant={inputField.variant.CHAR_COUNT}
                    maxChars={maxCharLimit.createPatient.countryMaxCharLimit}
                    maxLength={maxCharLimit.createPatient.countryMaxCharLimit}
                    handleOnChange={event => {
                      onValueChange(event.target.value, 'country');
                    }}
                    isDisabled={!enableEditPatientData}
                  />
                </ComponentWithLabel>
              </Box>
            </Flex>
          )}
          {!hidePatientDemographics && (
            <Flex flexDirection="row" justifyContent="space-between">
              <Box flex="1">
                <ComponentWithLabel
                  id="insuranceType"
                  htmlFor="insuranceType"
                  labelText={I18n.t('patients_view.label_insurance-type')}
                >
                  <Dropdown
                    value={getInsuranceValue()}
                    options={getInsuranceOptions(values.insuranceTypes)}
                    placeholder={t('patients_view.placeholder_insurance-type')}
                    handleOnChange={selectedValue => {
                      onValueChange(selectedValue, 'InsuranceType');
                    }}
                    isDisabled={!enableEditPatientData}
                    hasError={hasErrors.InsuranceType}
                  />
                  {hasErrors.InsuranceType && (
                    <span className="patients__phone-field error">
                      {t('types.insuranceTypeError.selectValidInsuranceType')}
                    </span>
                  )}
                </ComponentWithLabel>
              </Box>

              <Box flex="1">
                <ComponentWithLabel
                  id="insuranceCompany"
                  htmlFor="insuranceCompany"
                  labelText={I18n.t('patients_view.label_insurance-company')}
                >
                  <TextInput
                    value={values.InsuranceCompany}
                    hasError={hasErrors.InsuranceCompany}
                    id="insuranceCompany"
                    name="insuranceCompany"
                    placeholder={I18n.t(
                      'patients_view.placeholder_insurance-company'
                    )}
                    variant={inputField.variant.CHAR_COUNT}
                    maxChars={
                      maxCharLimit.createPatient.InsuranceCompanyMaxCharLimit
                    }
                    maxLength={
                      maxCharLimit.createPatient.InsuranceCompanyMaxCharLimit
                    }
                    handleOnChange={event => {
                      onValueChange(event.target.value, 'InsuranceCompany');
                    }}
                    isDisabled={!enableEditPatientData}
                  />
                </ComponentWithLabel>
              </Box>
            </Flex>
          )}
          {!hidePatientDemographics && (
            <ComponentWithLabel
              id="insuranceNumber"
              htmlFor="insuranceNumber"
              labelText={I18n.t('patients_view.label_insurance-number')}
            >
              <TextInput
                value={values.InsuranceNumber}
                hasError={hasErrors.InsuranceNumber}
                id="insuranceNumber"
                name="insuranceNumber"
                placeholder={I18n.t(
                  'patients_view.placeholder_insurance-number'
                )}
                variant={inputField.variant.CHAR_COUNT}
                maxChars={
                  maxCharLimit.createPatient.InsuranceNumberMaxCharLimit
                }
                maxLength={
                  maxCharLimit.createPatient.InsuranceNumberMaxCharLimit
                }
                handleOnChange={event => {
                  onValueChange(event.target.value, 'InsuranceNumber');
                }}
                isDisabled={!enableEditPatientData}
              />
            </ComponentWithLabel>
          )}

          <FormGroupTitle>
            {I18n.t('patients_view.field-group_title_medical-information')}
          </FormGroupTitle>

          <Flex flexDirection="row" justifyContent="space-between">
            <Box flex="1">
              <ComponentWithLabel
                id="medicalRecord"
                htmlFor="medicalRecord"
                labelText={I18n.t('patients_view.label_medicalRecord')}
                hasIcon
                tooltipText={
                  <div className="tooltip-text--w250">
                    {I18n.t('patients_view.medical_id_tooltip')}{' '}
                  </div>
                }
              >
                <TextInput
                  value={values.medicalRecord}
                  hasError={
                    hasErrors.medicalRecordIsEmpty ||
                    hasErrors.medicalRecord ||
                    hasErrors.patientIdIsInvalid
                  }
                  id="medicalRecord"
                  name="medicalRecord"
                  placeholder={I18n.t('patients_view.add_medicalRecord')}
                  variant={inputField.variant.CHAR_COUNT}
                  maxChars={
                    maxCharLimit.createPatient.medicalRecordMaxCharLimit
                  }
                  maxLength={
                    maxCharLimit.createPatient.medicalRecordMaxCharLimit
                  }
                  handleOnChange={event => {
                    onValueChange(event.target.value, 'medicalRecord');
                  }}
                  validationMessage={validationMessage}
                />
              </ComponentWithLabel>
            </Box>

            <Box flex="1">
              <ComponentWithLabel
                id="caseNumber"
                htmlFor="caseNumber"
                labelText={I18n.t('patients_view.label_case-number')}
              >
                <TextInput
                  value={values.caseNumber}
                  hasError={hasErrors.caseNumber}
                  id="caseNumber"
                  name="caseNumber"
                  placeholder={I18n.t('patients_view.placeholder_case-number')}
                  variant={inputField.variant.CHAR_COUNT}
                  maxChars={maxCharLimit.createPatient.caseNumberMaxCharLimit}
                  maxLength={maxCharLimit.createPatient.caseNumberMaxCharLimit}
                  handleOnChange={event => {
                    onValueChange(event.target.value, 'caseNumber');
                  }}
                />
              </ComponentWithLabel>
            </Box>
          </Flex>

          <Box flex="1" otherStyles={{ width: '50%' }}>
            <ComponentWithLabel
              id="pseudonymCode"
              htmlFor="pseudonymCode"
              labelText={I18n.t('patients_view.label_pseudonymCode')}
            >
              <TextInput
                value={values.pseudonymCode}
                hasError={hasErrors.pseudonymCode}
                id="pseudonymCode"
                name="pseudonymCode"
                placeholder={I18n.t('patients_view.placeholder_pseudonymCode')}
                variant={inputField.variant.CHAR_COUNT}
                maxChars={maxCharLimit.createPatient.pseudonymCodeMaxCharLimit}
                maxLength={maxCharLimit.createPatient.pseudonymCodeMaxCharLimit}
                validationMessage={I18n.t('patients_view.invalidPseudonymCode')}
                handleOnChange={event => {
                  onValueChange(event.target.value, 'pseudonymCode');
                }}
              />
            </ComponentWithLabel>
          </Box>

          {(enableOps == true || enableOps == 'true') &&
            (enableOpsPatientView == true ||
              enableOpsPatientView == 'true') && (
              <ComponentWithLabel
                id="icds"
                htmlFor="icds"
                labelText={I18n.t('task.OPSReference')}
              >
                <IcdSelector
                  maxLengthItem={opsLength}
                  onSelect={selected => {
                    setAllOps(selected);
                  }}
                  icdSelected={fetchedOps}
                  modification={modification.OPS}
                  fetchData={term => {
                    return fetchIcdsOrOpsData(term, false);
                  }}
                  placeholder={I18n.t('patients_view.ops_reference')}
                />
              </ComponentWithLabel>
            )}

          <ComponentWithLabel
            id="icds"
            htmlFor="icds"
            labelText={I18n.t('task.ICDReference')}
          >
            <IcdSelector
              maxLengthItem={icdsLength}
              onSelect={selected => {
                setAllIcds(selected);
              }}
              icdSelected={fetchedIcds}
              fetchData={term => {
                return fetchIcdsOrOpsData(term, true);
              }}
              placeholder={I18n.t('newCareplan_view.placeholderICDReference')}
            />
          </ComponentWithLabel>
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
}

export default PatientFormContent;
