import axios from 'axios';
import serviceConfig from '../../../serviceConfig.json';
import { ApiClient } from '../../Global/Services';

const createAvatarAsset = (profilePicture, nameId, typeId) => {
  const formData = new FormData();
  formData.append('fileObject', profilePicture);
  formData.append('nameId', nameId);
  formData.append('fileSize', profilePicture.size);
  formData.append('typeId', typeId);
  return axios.post(
    serviceConfig.brokerService.createAvatarAsset.uri,
    formData,
    {
      timeout: 30000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'form-data',
      },
    }
  );
};

const createAvatarResource = (profileId, avatarTypeId, assetId) => {
  return axios
    .post(serviceConfig.brokerService.createAvatarResource.uri, {
      profileId,
      avatarTypeId,
      assetId,
    })
    .catch(error => Promise.reject(error));
};

const updateAvatarAsset = (
  patientId,
  profilePicture,
  collectionId,
  nameId,
  typeId
) => {
  if (Object.prototype.toString.call(profilePicture) === '[object File]') {
    const formData = new FormData();
    formData.append('fileObject', profilePicture);
    formData.append('patientId', patientId);
    formData.append('collectionId', collectionId);
    formData.append('nameId', nameId);
    formData.append('fileSize', profilePicture.size);
    formData.append('typeId', typeId);
    return ApiClient.POST({
      url: serviceConfig.brokerService.updateAvatarAsset.uri,
      payload: formData,
      timeout: 30000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'form-data',
      },
    })
      .then(collId => {
        return { collection: collId.data.collection, profilePicture: null };
      })
      .catch(error => Promise.reject(error));
  }
  if (profilePicture.includes('blob:https://')) {
    return Promise.resolve({
      collection: collectionId,
      profilePicture: null,
    });
  }
  return Promise.resolve({
    collection: collectionId,
    profilePicture,
  });
};

const deleteAvatarResource = (profileId, avatarTypeId) => {
  return axios
    .post(serviceConfig.brokerService.deleteAvatarResource.uri, {
      profileId,
      avatarTypeId,
    })
    .catch(error => Promise.reject(error));
};

export {
  createAvatarAsset,
  createAvatarResource,
  updateAvatarAsset,
  deleteAvatarResource,
};
