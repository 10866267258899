/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext, useCallback } from 'react';
import I18n from 'i18next';
import axios from 'axios';
import moment from 'moment';

import { HideFlashMessage } from '@global/Services';

import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import serviceConfig from '../../../serviceConfig.json';
import {
  ComponentTypes,
  Actions,
  SubmissionTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import '../../css/patient.css';
import IcdSelector from '../../../caro-ui-commonfiles/components/ICDDropdownSelect/ICDSelect';

import ApptModalContentForm from './ApptModalContentForm';
import ApptInformationOverview from './ApptInformationOverview';
import '../../css/user.css';
import '../../css/patientOverview.scss';
import '../../css/careplans.scss';
import RecurrenceComponent from '../../../caro-ui-commonfiles/components/molecules/Recurrence/AppointmentRecurrence';
import communicationMode from '../../Utils/communicationBitValues';
import icdThresholds from '../../Utils/icdThresholds.json';
import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';

const ApptCreateModalContent = () => {
  const currMinute = Math.ceil(moment().minute() / 15) * 15;
  const startTimeObj = {
    hour: moment().hour() + Math.floor(currMinute / 60),
    minute: currMinute % 60,
  };

  const {
    currentStep,
    setCurrentStep,
    shouldValidateForm,
    setFlashMessage,
    closeModal,
    shouldSubmitForm,
    dispatchFormSubmittor,
    patientInfo,
    setLandingTab,
    setRefreshCalendar,
    setDoFetch,
    doFetch,
  } = useContext(PatientOverviewContext);

  const [icds, setIcds] = useState([]);
  const { dropdownAppointmentTypes } = require('../../Utils/dropdownTypes');
  const {
    apptRepeatTypes,
    getEndTime,
  } = require('../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils');
  const repeatTypesArray = apptRepeatTypes();
  const [data, setData] = useState({
    users: [],
    maxIcdSelection: icdThresholds.APPOINTMENT,
    isSmsEnabled: false,
  });
  const [values, setValues] = useState({
    name: '',
    appointmentType: null,
    startDate: moment().toDate(),
    startTime: startTimeObj,
    repeatType: repeatTypesArray[0],
    repeatInterval: { value: 1, label: 1 },
    endDate: moment().toDate(),
    endTime: getEndTime(startTimeObj),
    attendees: [],
    weeklyRecurrence: new Array(7).fill(false),
    location: {},
    notes: '',
    communicationModeBitValue: communicationMode.PUSH_NOTIFICATION_BIT_VALUE,
  });

  const [hasErrors, setHasErrors] = useState({
    name: false,
    appointmentType: false,
    startDateTime: false,
    endDateTime: false,
    location: false,
    recurrenceRepeat: false,
  });

  const onValueChange = useCallback(
    (value, key) => {
      setValues({
        ...values,
        [key]: value,
      });
    },
    [values]
  );

  const [promptMessage, setPromptMessage] = useState({
    display: false,
    displayPathwayAssociatedPrompt: false,
    title: '',
    message: '',
    displayed: false,
  });

  const closeMessage = () => {
    setPromptMessage({
      display: false,
      displayPathwayAssociatedPrompt: false,
      title: '',
      message: '',
      displayed: true,
    });
  };

  const closeMessagePrompt = () => {
    dispatchFormSubmittor({
      component: '',
      action: '',
      submissionType: false,
    });
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.addAppointment.uri
      );
      if (response.data) {
        setData({
          users: response.data.users,
          maxIcdSelection: response.data.maxIcdSelection,
          isSmsEnabled: response.data.smsFlag,
        });
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  const createAppointment = async (associatedPathway = false) => {
    closeModal();
    let message = '';
    setLandingTab(false);
    try {
      const response = await axios.post(
        serviceConfig.brokerService.createAppointment.uri,
        {
          patientId: patientInfo.id,
          selectedICDs: icds.map(icd => icd.id) || [],
          startDateTime: moment(values.startDate)
            .hour(values.startTime.hour)
            .minute(values.startTime.minute)
            .toISOString(),
          endDateTime: moment(values.endDate)
            .hour(values.endTime.hour)
            .minute(values.endTime.minute)
            .toISOString(),
          ...values,
          location: values.location.label,
        }
      );
      if (response) {
        let sendResponse = response;
        message = `${I18n.t(response.data.content)}`;
        const isSendOutImmediately =
          shouldSubmitForm.submissionType === SubmissionTypes.ASSIGN_AND_SEND;
        if (isSendOutImmediately) {
          sendResponse = await axios.post(
            serviceConfig.brokerService.sendAppointment.uri,
            {
              reminderId: response.data.reminderId,
              isCreateAppointment: true
            }
          );
        }
        if (sendResponse) {
          setFlashMessage({
            type: sendResponse.data.type,
            content: isSendOutImmediately
              ? `${message} ${I18n.t(sendResponse.data.content)}`
              : message,
          });
          setDoFetch(!doFetch);
        }
      }
    } catch (error) {
      setDoFetch(!doFetch);
      setFlashMessage({
        type: error.response.data.type,
        content: `${I18n.t(error.response.data.content)}${message}`,
      });
    }
    setLandingTab(
      associatedPathway == true
        ? ComponentTypes.CAREPATHWAYS
        : ComponentTypes.APPOINTMENT
    );
    setRefreshCalendar(true);
  };

  useEffect(() => {
    if (
      shouldSubmitForm.component === ComponentTypes.APPOINTMENT &&
      shouldSubmitForm.action === Actions.Create
    ) {
      if (
        icds.length === 0 &&
        values.name !== '' &&
        values.location !== {} &&
        !promptMessage.displayed
      ) {
        setPromptMessage({
          display: true,
          title: `${I18n.t('warningMessage.carepathwayNoICD_title')}`,
          message: I18n.t('warningMessage.appointmentNoIcd'),
          createAppointment: createAppointment.bind(this),
        });
      } else if (values.name !== '' && values.location !== {}) {
        createAppointment();
      }
      return function cleanup() {
        dispatchFormSubmittor({
          component: '',
          action: '',
          submissionType: false,
        });
      };
    }
  }, [shouldSubmitForm]);

  const validateForm = () => {
    const startDateTime = moment(values.startDate)
      .hour(values.startTime.hour)
      .minute(values.startTime.minute)
      .unix();
    const endDateTime = moment(values.endDate)
      .hour(values.endTime.hour)
      .minute(values.endTime.minute)
      .unix();
    const currentTime = moment().unix();

    const nameError = values.name.trim().length < 5;
    const locationError = !values.location.label;
    const recurrenceRepeatError =
      values.repeatType.value !== 2
        ? false
        : !values.weeklyRecurrence.find(elem => elem === true);
    const startDateTimeError = moment(startDateTime).isValid()
      ? startDateTime <= currentTime
      : true;
    const endDateTimeError = moment(endDateTime).isValid()
      ? endDateTime <= currentTime || endDateTime <= startDateTime
      : true;

    setHasErrors({
      ...hasErrors,
      name: nameError,
      recurrenceRepeat: recurrenceRepeatError,
      startDateTime: startDateTimeError,
      endDateTime: endDateTimeError,
      location: locationError,
      appointmentType: !values.appointmentType,
    });

    return !(
      nameError ||
      recurrenceRepeatError ||
      startDateTimeError ||
      endDateTimeError ||
      locationError ||
      !values.appointmentType
    );
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    return false;
  };

  const fetchIcdData = term => {
    return axios(serviceConfig.getIcds.uri, {
      params: {
        searchTerm: term,
      },
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    if (
      shouldValidateForm.component === ComponentTypes.APPOINTMENT &&
      shouldValidateForm.action === Actions.Create
    ) {
      if (validateForm()) setCurrentStep(currentStep + 1);
    }
  }, [shouldValidateForm]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <ModalContent>
      {currentStep === 1 && (
        <ModalForm
          formId="create-appointment-form"
          handleFormSubmit={handleFormSubmit}
        >
          <ApptModalContentForm
            values={values}
            users={data.users}
            isSmsEnabled={data.isSmsEnabled}
            recurrenceComponent={
              <RecurrenceComponent
                onValueChange={onValueChange}
                values={values}
                hasErrors={hasErrors}
              />
            }
            icdSelectorComponent={
              <IcdSelector
                maxLengthItem={data.maxIcdSelection}
                onSelect={setIcds}
                icdSelected={icds}
                fetchData={fetchIcdData}
                placeholder={I18n.t('newCareplan_view.placeholderICDReference')}
              />
            }
            onValueChange={onValueChange}
            hasErrors={hasErrors}
          />
        </ModalForm>
      )}

      {currentStep === 2 && (
        <ApptInformationOverview values={values} icds={icds} />
      )}
      {promptMessage.display && (
        <Modal
          isConfirmationDialog
          title={promptMessage.title}
          contentComponent={<PromptContent message={promptMessage.message} />}
          footerComponent={
            <PromptFooter
              close={() => {
                promptMessage.createAppointment();
                closeMessagePrompt();
              }}
              confirmHandler={() => {
                closeMessage();
              }}
              btnLabelLeft={I18n.t('common_buttons.ignore')}
              btnLabelRight={I18n.t('common_buttons.addIcd')}
              closePopUpMessage={closeMessage}
              comingFromAppointment
            />
          }
          openModal={promptMessage.display}
          onClose={closeMessage}
          hideFlashMessage={HideFlashMessage}
        />
      )}
      {promptMessage.displayPathwayAssociatedPrompt && (
        <Modal
          isConfirmationDialog
          title={promptMessage.title}
          contentComponent={<PromptContent message={promptMessage.message} />}
          footerComponent={
            <PromptFooter
              close={closeMessage}
              confirmHandler={() => {
                promptMessage.createAppointment(true);
                closeMessage();
              }}
              btnLabelRight={I18n.t('common_buttons.confirm')}
              btnLabelLeft={I18n.t('common_buttons.cancel')}
              closePopUpMessage={closeMessage}
            />
          }
          openModal={promptMessage.displayPathwayAssociatedPrompt}
          onClose={closeMessage}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </ModalContent>
  );
};

export default ApptCreateModalContent;
