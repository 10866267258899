import React, { useCallback } from 'react';

import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import AnswerHeaderComponent from '../AnswerHeaderComponent';

import { formatTimestamp } from '../../../../Global/Date';
import { CareplanComponentAnswer } from '../../../../types/Careplan';
import { GeneralAnswerType } from '../GeneralAnswer.type';
import {
  getComponentOccurrencesAnswer,
  occurrenceHasAnswer,
} from '../AnswerResult.services';

import '../AnswerResultCommon.scss';

function FreeTextAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const renderComponentAnswers = useCallback(
    (
      occurrenceAnswers: CareplanComponentAnswer[],
      index: number,
      answerDate: number
    ) => {
      let answerContent = '-';
      if (occurrenceHasAnswer(occurrenceAnswers)) {
        answerContent = occurrenceAnswers[0].answer_text;
      }

      return (
        <Flex
          className="answer-component_row-section-item"
          key={`col-header-${componentIndex}-${index}`}
          alignItems="center"
        >
          <span className="answer-component_row-section-item-date">
            {formatTimestamp(answerDate)}
          </span>
          <span className="answer-component_row-section-item-bar">|</span>
          <span className="answer-component_row-section-item-content">
            {answerContent}
          </span>
        </Flex>
      );
    },
    []
  );

  return (
    <Box className="answer-component">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName="FreeText"
        title={componentData.name}
      />
      <Box className="answer-component_row-section">
        {getComponentOccurrencesAnswer(
          allOccurrences,
          componentData.answers,
          renderComponentAnswers
        )}
      </Box>
    </Box>
  );
}
export default FreeTextAnswer;
