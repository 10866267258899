import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '../../../../../../../caro-ui-commonfiles/components/Box/Box';
import Datepicker from '../../../../../../../caro-ui-commonfiles/components/DateTimePicker/Datepicker';
import Flex from '../../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import IconButton from '../../../../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import { CaretaskComponent } from '../../../../../../types/Caretask';
import {
  selectCurrentAnswer,
  selectCurrentComponent,
} from '../../CaretaskAnswering.reducer';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { AnsweringComponentsProps } from '../AnsweringTypes';

export default function AnsweringDatePicker({
  handleOnChange,
}: AnsweringComponentsProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>();

  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );

  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);

  const defaultValue: Date = currentAnswer?.answer;

  useEffect(() => {
    handleOnChange({ answer: selectedDate, isSkipped: !selectedDate });
    if (selectedDate || errorMessage) setErrorMessage(false);
  }, [selectedDate]);

  useEffect(() => {
    setSelectedDate(defaultValue);
  }, [currentComponent]);

  return (
    <>
      <Box>
        <p>{t('datePicker.chooseDate')}</p>
      </Box>

      <Box>
        <Flex alignItems="center">
          <Datepicker
            date={selectedDate}
            placeholder={t('medication.startDate')}
            handleDate={setSelectedDate}
            hasError={errorMessage}
            validationMessage={t('recurrence.startDateError')}
          />
          <Box margin="0 0 0 8px">
            <IconButton
              name="close"
              onClick={() => setSelectedDate('')}
              tooltipText={t('common_buttons.clear')}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
}
