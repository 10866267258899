import React, {useState } from 'react';

import config from '../../../config';

const useLoginFooterService = () => {
  const [openModal, setOpenModal] = useState(false);
  const openSupportForm = () => {
    window.open(config.supportUrl, '_blank', 'noopener,noreferrer');
  };
  return {
    openModal,
    setOpenModal,
    openSupportForm,
  };
};

export default useLoginFooterService;
