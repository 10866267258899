import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import IconButton from '../../../../caro-ui-commonfiles/components/IconButton/IconButton';

const LogicJumpIcon = ({ onPointerDownHandler, className }) => {
  return (
    <>
      <Box className={className} margin="0 0 0 10px">
        <IconButton
          onClick={onPointerDownHandler}
          name="logicJump"
          size={24}
          tooltipText={I18n.t('newQuestion_view.logicJump')}
        />
      </Box>
    </>
  );
};

LogicJumpIcon.propTypes = {
  onPointerDownHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
};

LogicJumpIcon.defaultProps = {
  className: 'answer-view__checked-option-label',
};

export default LogicJumpIcon;
