import { ScoringTypes } from '@type/Careplan';
import { Edge } from './CaretaskData.type';

export const sortEdgesByCondition = (edges: Array<Edge>) => {
  const conditionOrder = [
    ScoringTypes.Well,
    ScoringTypes.Fair,
    ScoringTypes.Poor,
  ];
  const sortedEdges = edges.sort(
    (a, b) =>
      conditionOrder.indexOf(a.condition) - conditionOrder.indexOf(b.condition)
  );
  return sortedEdges;
};
