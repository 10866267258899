import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Flex,
  Icon,
  Label,
  ModalContent,
} from '@ui-common-files/components';
import { formatDate } from '@global/Date';
import { Greys } from '@ui-common-files/utils';
import { renderShowAssetFirstColumn } from '@utils/Asset/assetHelper';
import useShowAssetModalContentService from './ShowAssetModalContent.service';

import '../../MedicationPlanCard.scss';

function ShowAssetModalContent({ assetDataToShow }) {
  const { t } = useTranslation();
  const {
    assetData,
    fetchedPrivacyPolicyData,
    consentData,
    revokeData,
    revokeTime,
    additionalConsents,
  } = useShowAssetModalContentService(assetDataToShow);

  return (
    <ModalContent>
      <Flex otherStyles={{ width: '100%', minHeight: '100%' }}>
        <Box otherStyles={{ flex: 5 }}>
          {renderShowAssetFirstColumn(assetDataToShow, true)}
        </Box>
        <Box otherStyles={{ flex: 3 }}>
          <Box otherStyles={{ marginTop: 32, marginLeft: 20 }}>
            <Box className="asset-data__labels">{t('asset_view.fileName')}</Box>
            <Box margin="16px 0 0">
              <Box className="asset-info__values">{assetData.name}</Box>
            </Box>
            <Flex
              otherStyles={{ marginTop: 16, width: '100%', height: '100%' }}
            >
              <Flex flexDirection="column">
                <Flex flexDirection="row" alignItems="center">
                  <Box className="asset-info__labels">
                    {t('asset_view.fileSize')}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{ marginTop: '12px' }}
                >
                  <Box className="asset-info__labels">
                    {t('asset_view.dataAdded')}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{ marginTop: '12px' }}
                >
                  <Box className="asset-info__labels">
                    {t('asset_view.lastModified')}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{
                    marginTop: '12px',
                  }}
                >
                  <Box className="asset-info__labels">
                    {t('asset_view.createdBy')}
                  </Box>
                </Flex>
              </Flex>

              <Flex
                flexDirection="column"
                otherStyles={{ marginLeft: 40, flex: '1' }}
              >
                <Flex flexDirection="row" alignItems="center">
                  <Box className="asset-info__values">{`${assetData.size} MB`}</Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{ marginTop: '12px' }}
                >
                  <Box className="asset-info__values">
                    {formatDate(assetData.createdAt)}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{ marginTop: '12px' }}
                >
                  <Box className="asset-info__values">
                    {formatDate(assetData.updatedAt)}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{
                    marginTop: '12px',
                  }}
                >
                  <Box className="asset-info__values">
                    {assetDataToShow.assetLogs[0]?.user?.username ??
                      t('patients_view.label_patient') ??
                      'Unknown'}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            {assetData.description && (
              <Box>
                <Box className="asset-data__labels">
                  {t('asset_view.description')}
                </Box>
                <Box margin="16px 0 0">
                  <Box className="asset-info__values">
                    {assetData.description}
                  </Box>
                </Box>
              </Box>
            )}
            {assetData.isPrivacyPolicy && consentData && (
              <Box>
                <Box className="asset-data__labels">
                  {t('asset_view.patientsConsent')}
                </Box>
                <Flex
                  otherStyles={{
                    marginTop: 10,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Flex flexDirection="column">
                    <Flex flexDirection="row" alignItems="center">
                      <Box className="asset-info__labels">
                        {t('asset_view.consentedOn')}
                      </Box>
                    </Flex>
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      otherStyles={{ marginTop: '12px' }}
                    >
                      <Box className="asset-info__labels">
                        {t('asset_view.revokedOn')}
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    otherStyles={{ marginLeft: 40, flex: '1' }}
                  >
                    <Flex flexDirection="row" alignItems="center">
                      <Box className="asset-info__values">
                        {formatDate(consentData.timeOfAction)}
                      </Box>
                    </Flex>
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      otherStyles={{ marginTop: '12px' }}
                    >
                      <Box className="asset-info__values">
                        {revokeData ? revokeTime : '-'}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
                <Box margin="24px 15px 12px 0">
                  <Label
                    text={t('asset_view.defaultConsentsDescription')}
                    isLongText={true}
                  />
                  <Box
                    className="asset-info__values"
                    style={{ marginTop: '12px' }}
                  >
                    {t('asset_view.defaultConsentsDescriptionText')}
                  </Box>
                </Box>
                {additionalConsents.length > 0 && (
                  <Box margin="24px 0 0 0">
                    <Flex flexDirection="row">
                      <Label
                        text={t('asset_view.additionalConsentsDescription')}
                        isLongText={true}
                      />
                      <Icon name="info" size={24} fill={Greys.DARK_GREY} />
                    </Flex>
                    <Box margin="0 15px 0 0">
                      {additionalConsents.map(additionalConsent => (
                        <Box
                          className="asset-info__values"
                          otherStyles={{ marginBottom: '24px' }}
                        >
                          {additionalConsent}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
                {fetchedPrivacyPolicyData &&
                  fetchedPrivacyPolicyData.length > 2 && (
                    <Box>
                      <Box className="asset-data__labels">
                        {t('asset_view.patientsConsentHistory')}
                      </Box>
                      <Flex
                        otherStyles={{
                          marginTop: 10,
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Flex flexDirection="column">
                          {fetchedPrivacyPolicyData.map(
                            (fetchedPrivacyPolicyDataItem, i) => {
                              return fetchedPrivacyPolicyDataItem.action ===
                                'actions.consent' ? (
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  otherStyles={{ marginTop: '12px' }}
                                >
                                  <Box className="asset-info__labels">
                                    {t('asset_view.consentedOn')}
                                  </Box>
                                </Flex>
                              ) : (
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  otherStyles={{ marginTop: '12px' }}
                                >
                                  <Box className="asset-info__labels">
                                    {t('asset_view.revokedOn')}
                                  </Box>
                                </Flex>
                              );
                            }
                          )}
                        </Flex>
                        <Flex
                          flexDirection="column"
                          otherStyles={{ marginLeft: 40, flex: '1' }}
                        >
                          {fetchedPrivacyPolicyData.map(
                            (fetchedPrivacyPolicyDataItem, i) => {
                              return (
                                <Flex
                                  flexDirection="row"
                                  alignItems="center"
                                  otherStyles={{ marginTop: '12px' }}
                                >
                                  <Box className="asset-info__values">
                                    {formatDate(
                                      fetchedPrivacyPolicyDataItem.timeOfAction
                                    )}
                                  </Box>
                                </Flex>
                              );
                            }
                          )}
                        </Flex>
                      </Flex>
                    </Box>
                  )}
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
    </ModalContent>
  );
}

export default ShowAssetModalContent;
