import { useCallback, useEffect, useMemo, useState } from 'react';
import I18n from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useShallow } from 'zustand/react/shallow';

import {
  RecurrenceValidation,
  lifespanUnitsSmall,
  useAvailableLifespan,
  adjustDateBySequenceDirection,
  calculateStartDuration,
} from '@ui-common-files/utils';
import {
  calculateDate,
  checkLifespanOverlapping,
  getDurationText,
  validateCareplanRecurrenceTag,
} from './CareTaskConfiguration/CareTaskConfiguration.utils';

import { useCarePathWayBuilderStore } from '../CarePathWayBuilder.store';

export const DEFAULT_ERRORS_OBJECT = {
  lifespan: false,
  recurrence: false,
  recurrenceRepeat: false,
  startDate: false,
  endDate: false,
  occurrenceCount: false,
  recurrenceTag: false,
  overlapping: false,
};

export const useCommonCareTaskConfiguration = () => {
  const { t } = useTranslation();
  const { nodes, sidebar, nodeData, updateSidebarVisibility } =
    useCarePathWayBuilderStore(
      useShallow(state => ({
        nodes: state.nodes,
        sidebar: state.sidebar,
        updateSidebarVisibility: state.updateSidebarVisibility,
        nodeData: state.nodes.find(
          node => node.id === state.sidebar.configurationNodeId
        )?.data,
      }))
    );
  const [configuration, setConfiguration] = useState({
    ...(nodeData.duration || nodeData.durationDefault),
  });

  const [hasErrors, setHasErrors] = useState(DEFAULT_ERRORS_OBJECT);

  const lifespanUnitsObjSmall = useMemo(
    () => lifespanUnitsSmall(),
    [I18n.language]
  );

  useEffect(() => {
    setConfiguration({
      ...(nodeData.duration || nodeData.durationDefault),
    });
    setHasErrors(DEFAULT_ERRORS_OBJECT);
  }, [sidebar.configurationNodeId]);

  const validateSettings = () => {
    RecurrenceValidation(
      {
        customTimeChecked: true,
        customTime: configuration.startTime,
        lifespan: configuration.lifespan,
        lifespanUnit: configuration.lifespanUnit,
        occurrenceCount: configuration.occurrenceCount.value,
        occurrenceCountCheck: configuration.occurrenceCountCheck,
        repeatType: configuration.repeatType,
        repeatInterval: configuration.repeatInterval,
        startDate: configuration.startDate,
        endDate: configuration.endDate,
        endDateUnit: configuration.endDateUnit,
        endDateSequence: configuration.endDateSequence.label,
        weeklyRecurrence: configuration.weeklyRecurrence,
        recurrenceTag:
          configuration.recurrenceTag && configuration.recurrenceTag.length > 0
            ? validateCareplanRecurrenceTag(
                nodes,
                nodeData,
                sidebar.configurationNodeId,
                configuration.recurrenceTag
              )
            : false,
      },
      setHasErrors,
      hasErrors,
      () => {},
      true
    );
  };

  const availableLifespanUnits = useAvailableLifespan(
    configuration.repeatType,
    0,
    configuration.lifespanUnit,
    () => {}
  );

  const onConfigurationChange = useCallback(
    (value, key) => {
      setConfiguration(prevConfiguration => {
        const newConfig = {
          ...prevConfiguration,
          [key]: value,
        };

        if (
          key === 'repeatType' &&
          newConfig.lifespanUnit.value > newConfig.repeatType.value
        ) {
          newConfig.lifespanUnit =
            availableLifespanUnits[newConfig.repeatType.value - 1];
        }
        return { ...newConfig };
      });
      validateSettings();
    },
    [availableLifespanUnits]
  );

  const calculateDateByConfigType = (type: string) => {
    const timestamp = moment().unix();
    const date = calculateDate(
      timestamp,
      configuration[`${type}Sequence`].value,
      configuration[`${type}Unit`].value,
      configuration[`${type}Value`]
    );
    return adjustDateBySequenceDirection(
      date,
      configuration[`${type}Sequence`].label,
      configuration[`${type}Unit`].label,
      configuration[`${type}Value`]
    );
  };
  useEffect(() => {
    const calculateDuration = () => {
      if (configuration.appointmentDate === null) {
        const startDate = calculateDateByConfigType('startDate');
        const endDate = calculateDateByConfigType('endDate');
        setConfiguration({
          ...configuration,
          startDate,
          endDate,
          durationText: getDurationText(configuration),
          startDuration: calculateStartDuration(
            configuration.startDateValue,
            configuration.startDateSequence
          ),
        });
      } else {
        const appointmentUnix = moment(configuration.appointmentDate).unix();
        const startDate = calculateDate(
          appointmentUnix,
          configuration.startDateSequence.value,
          configuration.startDateUnit.value,
          configuration.startDateValue
        );
        const endDate = calculateDate(
          appointmentUnix,
          configuration.endDateSequence.value,
          configuration.endDateUnit.value,
          configuration.endDateValue
        );
        setConfiguration({
          ...configuration,
          startDate,
          endDate,
          durationText: `${moment(startDate).format('DD MM YYYY')} - ${moment(
            endDate
          ).format('DD MM YYYY')}`,
        });
      }
    };
    calculateDuration();
  }, [
    configuration.startDateSequence,
    configuration.startDateUnit,
    configuration.startDateValue,
    configuration.endDateSequence,
    configuration.endDateUnit,
    configuration.endDateValue,
    configuration.endDate,
    configuration.occurrenceCountCheck,
    configuration.repeatType,
    configuration.repeatInterval,
    configuration.startTime,
    JSON.stringify(configuration.weeklyRecurrence),
    configuration.occurrenceCount,
    configuration.lifespan,
    configuration.lifespanUnit,
  ]);

  useEffect(() => {
    validateSettings();
  }, [
    configuration.lifespan,
    configuration.lifespanUnit,
    configuration.endDateUnit,
    configuration.endDateValue,
    configuration.endDate,
    configuration.startTime,
    configuration.repeatType,
    configuration.repeatInterval,
    configuration.recurrenceTag,
    configuration.startDateValue,
    configuration.startDateSequence,
    configuration.startDateUnit,
  ]);

  const getTranslatedLabel = useCallback(
    (type: string) => {
      const option = {
        ...configuration[type],
        label: t(configuration[type].key),
      };
      return option;
    },
    [
      configuration.startDateUnit,
      configuration.startDateSequence,
      configuration.repeatType,
      configuration.endDateUnit,
      configuration.endDateSequence,
      configuration.lifespanUnit,
      I18n.language,
    ]
  );

  const saveConfiguration = () => {
    const isValidConfiguration = !Object.keys(hasErrors).some(
      e => hasErrors[e]
    );

    if (isValidConfiguration || !nodeData.isParent) {
      const overlappedWithNodeId = checkLifespanOverlapping(
        nodes,
        configuration,
        nodeData?.careTaskData.id,
        sidebar.configurationNodeId
      );

      if (overlappedWithNodeId && nodeData.isParent) {
        nodeData.validateNodeConfiguration(
          sidebar.configurationNodeId,
          overlappedWithNodeId
        );
        setHasErrors({
          ...hasErrors,
          overlapping: true,
        });
      } else {
        const durationText = getDurationText(configuration);
        nodeData.updateNodeConfiguration(sidebar.configurationNodeId, {
          ...configuration,
          occurrence: durationText.occurrence,
          durationText,
          startDuration: calculateStartDuration(
            configuration.startDateValue,
            configuration.startDateSequence
          ),
        });
        updateSidebarVisibility(false);
      }
    }
  };

  useEffect(() => {
    if (
      configuration.startDateValue === 0 &&
      configuration.startDateUnit.value > 1
    ) {
      setConfiguration({
        ...configuration,
        startDateValue: 1,
      });
    }
    if (
      configuration.endDateValue === 0 &&
      configuration.endDateUnit.value > 1
    ) {
      setConfiguration({
        ...configuration,
        endDateValue: 1,
      });
    }
  }, [configuration.startDateUnit, configuration.endDateUnit]);

  return {
    lifespanUnitsObjSmall,
    nodeData,
    configuration,
    onConfigurationChange,
    saveConfiguration,
    getTranslatedLabel,
    DEFAULT_ERRORS_OBJECT,
    hasErrors,
  };
};
