import React from 'react';

import AnswerBloodPressureAndBMIBase from './AnswerBloodPressureAndBMIBase';
import { AnswerInputOption } from './AnswerBloodPressureAndBMI.type';

export default function AnswerBloodPressure({
  component,
  isEvaluating,
  hasSubScores,
}) {
  const answerSystolic =
    component.answers[0].answer_blood_pressure_systolic || 0;
  const answerDiastolic =
    component.answers[0].answer_blood_pressure_diastolic || 0;

  const inputOptionSystolic: AnswerInputOption = {
    label: 'bloodPressureOptions.systolic',
    unit: 'bloodPressureOptions.unit',
    name: 'systolic',
    value: answerSystolic,
  };

  const inputOptionDiastolic: AnswerInputOption = {
    label: 'bloodPressureOptions.diastolic',
    unit: 'bloodPressureOptions.unit',
    name: 'diastolic',
    value: answerDiastolic,
  };

  const getIsChecked = option => {
    return option === component.answers[0].answer_blood_pressure_value;
  };

  return (
    <AnswerBloodPressureAndBMIBase
      component={component}
      isEvaluating={isEvaluating}
      hasSubScores={hasSubScores}
      inputOption1={inputOptionSystolic}
      inputOption2={inputOptionDiastolic}
      optionTranslation="bloodPressureOptions.labels"
      getIsChecked={getIsChecked}
    />
  );
}
