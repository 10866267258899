import React, { useContext, useLayoutEffect } from 'react';
import { Router } from 'react-router';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import I18n from 'i18next';

import { BrowserHistory } from '@global/Router';
import { ComponentTypes } from '@ui-common-files/utils';
import { NotificationTypes } from '@type/Notifications';

import Forgot from '../Views/Forgot';
import Reset from '../Views/Reset';
import Login from '../Views/Login/Login';
import Optins from '../Views/Optins';
import Verification from '../Views/Verification';
import Deactivated from '../Views/Deactivated';
import Careplans from '../Views/Careplan';
import Patient from '../Views/PatientsList';
import Administration from '../Views/Administration';
import AppointmentManager from '../Views/AppointmentManager';
import NotificationCenter from '../Views/notificationCenter';
import NavigationJSON from '../Utils/navigation.json';
import Sites from '../Views/Datatables/Sites';
import { CaretaskBuilder } from '../Views/Caretask';
import ImportCareplanTemplate from '../Views/Careplans/ImportCareplanTemplate';
import { PatientOverview } from '../Views/PatientOverview/PatientOverview';
import ExportDataToPdf from '../Views/PatientOverview/dataExport/ExportDataToPdf';
import Page404 from '../Views/Page404';
import Files from '../Views/Files';
import { FirebaseContext } from '../Utils/Firebase/firebase';
import checkUserPinAndOptins from '../Utils/Administration/checkUserPinAndOptins';
import PreviewView from '../Views/Files/PreviewFile';
import CarePathWayBuilderProvider from '../Views/CarePathWay/Builder/CarePathWayBuilderProvider';
import ScanQrCode from '../Views/ScanQrCode';
import ReportGenerator from '../Views/ReportGenerator/ReportGenerator';
import { NotificationType } from 'src/Views/Notifications/Notification.type';
import { AppLayout } from 'src/Views/AppLayout';
import PatientsList from 'src/Views/PatientsList';
import ReviewAssessments from 'src/Views/ReviewAssessments';

const LoggedInRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector(state => state.session);
  const {
    userPinAndOptinsInfo,
    setUserPinAndOptinsInfo,
    setHidePatientDemographics,
  } = useContext(FirebaseContext);

  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const response = await checkUserPinAndOptins(user.id);
        setUserPinAndOptinsInfo(response);
        if (response.logoutFlag) {
          return <Redirect to="/" />;
        }
      } catch (err) {
        return <Redirect to="/" />;
      }
    };
    if (user) {
      setHidePatientDemographics(user.role.isPatientDemographicData);
      fetchData();
    }
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        if (!user && !rest.isLoggedIn)
          return <Redirect to={`/${NavigationJSON.LOGIN}`} />;
        if (Object.keys(userPinAndOptinsInfo).length > 0) {
          if (
            userPinAndOptinsInfo.logoutFlag &&
            userPinAndOptinsInfo.logoutFlag === true
          ) {
            return <Redirect to={`/${NavigationJSON.LOGIN}`} />;
          }
          if (
            userPinAndOptinsInfo.isTermsAndConditions === false ||
            userPinAndOptinsInfo.isPrivacyPolicy === false
          )
            return <Redirect to={`/${NavigationJSON.OPTINS}`} />;
          if (!userPinAndOptinsInfo.isTwoFactorAuthentication)
            return <Component {...props} />;
          if (userPinAndOptinsInfo.isPinVerified === true)
            return <Component {...props} />;
          if (userPinAndOptinsInfo.isPinVerified === false) {
            return <Redirect to={`/${NavigationJSON.VERIFICATION}`} />;
          }
        }
      }}
    />
  );
};

const PrivateRoute = ({ component: Component, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={() =>
        routeProps.permissions ? (
          <LoggedInRoute
            component={Component}
            isLoggedIn={routeProps.isLoggedIn}
          />
        ) : (
          <Redirect to={`/${NavigationJSON.PATIENT}`} />
        )
      }
    />
  );
};

const getDataFromDeepLink = deepLinkData => {
  const { userId, typeId } = deepLinkData;
  switch (typeId) {
    case NotificationType.Chat:
      return {
        pathname: `/${NavigationJSON.NOTIFICATION_CENTER}?notificationType=${NotificationTypes.CHAT}`,
        state: {
          deepLink: true,
          userId,
        },
      };
    
    case NotificationType.Mention:
      return {
        pathname: `/${NavigationJSON.PATIENT_OVERVIEW}/${deepLinkData.patientId}`,
        state: {
          deepLink: true,
          isTaggedUser: true,
          userTaggedNoteId: deepLinkData.userTaggedNoteId,
        }
      }
    case NotificationType.Threshold:
      return {
        pathname: `/${NavigationJSON.PATIENT_OVERVIEW}/${deepLinkData.patientId}?tab=0`,
        state: {
          deepLink: true,
          userId,
          careplanId: parseInt(deepLinkData.careplanId, 10),
          answerTimestamp: new Date(
            deepLinkData.timestamp * 1000
          ).toISOString(),
          seen: true,
          isAssessment: deepLinkData.isAssessment === 'true',
          isCarepathway: deepLinkData.isCarepathway === 'true',
          nodeId: deepLinkData.nodeId,
          caretaskParentId: deepLinkData.caretaskParentId,
          carePathwayId: deepLinkData.carePathwayId,
          carePathwayName: deepLinkData.carePathwayName,
          careplanName: deepLinkData.careplanName,
        },
      };

    case NotificationType.Todo:
      return {
        pathname: `/${NavigationJSON.PATIENT_OVERVIEW}/${deepLinkData.patientId}?tab=0`,
        state: {
          deepLink: true,
          userId,
          careplanId: parseInt(deepLinkData.careplanId, 10),
          isAssessment: true,
          answer: true,
          redirectTo: ComponentTypes.TODOS,
          occurrenceId: parseInt(deepLinkData.occurrenceId, 10),
        },
      };

    default:
      return {
        pathname: `/${NavigationJSON.PATIENT_OVERVIEW}/${deepLinkData.patientId}?tab=0`,
        state: {
          deepLink: true,
          userId,
          careplanId: deepLinkData.careplanId,
        },
      };
  }
};

const NotificationsDeepLinkComponent = ({ isLoggedIn }) => {
  const params = useParams();
  const state = getDataFromDeepLink(params);
  const { user } = useSelector(state => state.session);
  const redirectTo = params.typeId === NotificationType.Chat ? state.pathname : state;
  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: `/${NavigationJSON.LOGIN}`,
          state,
        }}
      />
    );
  }
  if (user && user.id === parseInt(params.userId, 10)) {
    return <Redirect to={redirectTo} />;
  }
  return (
    <Redirect
      to={{
        pathname: `/${NavigationJSON.LOGIN}`,
        state: {
          deepLink: true,
          type: 'error',
          content: I18n.t('admin_view.email_notification_error'),
        },
      }}
    />
  );
};

const navigation = ({ isLoggedIn }) => {
  const services = useSelector(state => state.services);
  return (
    <Router history={BrowserHistory}>
      <Switch>
        <Route
          exact
          path="/"
          render={() =>
            isLoggedIn ? (
              <Redirect to={`/${NavigationJSON.PATIENT}`} />
            ) : (
              <Redirect to={`/${NavigationJSON.LOGIN}`} />
            )
          }
        />
        <Route
          path={`/${NavigationJSON.DEACTIVATED}`}
          component={Deactivated}
        />
        <Route path={`/${NavigationJSON.FORGOT}`} component={Forgot} />
        <Route path={`/${NavigationJSON.LOGIN}`} component={Login} />
        <Route
          path={`/${NavigationJSON.VERIFICATION}`}
          component={Verification}
        />
        <Route path={`/${NavigationJSON.OPTINS}`} component={Optins} />
        <LoggedInRoute
          isLoggedIn={isLoggedIn}
          path={`/${NavigationJSON.EXPORT_DATA_TO_PDF}`}
          component={ExportDataToPdf}
        />
        <Route
          path={`/${NavigationJSON.REPORT_GENERATOR}/:reportName/:reportId`}
          component={ReportGenerator}
        />
        <Route
          isLoggedIn={isLoggedIn}
          path={`/${NavigationJSON.RESET}/:token`}
          component={Reset}
        />
        <AppLayout>
          <Switch>
            <Route path="/thresholdsDeepLink/:userId/:typeId/:patientId/:careplanId/:timestamp/:isAssessment/:isCarepathway/:nodeId/:caretaskParentId/:careplanName/:carePathwayId/:carePathwayName">
              <NotificationsDeepLinkComponent isLoggedIn={isLoggedIn} />
            </Route>
            <Route path="/thresholdsDeepLink/:userId/:typeId/:patientId/:careplanId/:timestamp/:isAssessment/:isCarepathway">
              <NotificationsDeepLinkComponent isLoggedIn={isLoggedIn} />
            </Route>
            <Route path="/assessmentsDeepLink/:userId/:typeId/:patientId/:careplanId/:occurrenceId">
              <NotificationsDeepLinkComponent isLoggedIn={isLoggedIn} />
            </Route>
            <Route path="/assessmentsDeepLink/:userId/:typeId/:patientId/:careplanId">
              <NotificationsDeepLinkComponent isLoggedIn={isLoggedIn} />
            </Route>
            <Route path="/chatDeepLink/:userId/:typeId">
              <NotificationsDeepLinkComponent isLoggedIn={isLoggedIn} />
            </Route>
            <Route path="/mentionDeepLink/:userId/:typeId/:patientId/:userTaggedNoteId">
              <NotificationsDeepLinkComponent isLoggedIn={isLoggedIn} />
            </Route>
            <PrivateRoute
              permissions={
                services
                  ? services.thresholdNotification || services.chatNotification
                  : true
              }
              isLoggedIn={isLoggedIn}
              path={`/${NavigationJSON.NOTIFICATION_CENTER}`}
              component={NotificationCenter}
            />

            <LoggedInRoute
              isLoggedIn={isLoggedIn}
              path={`/${NavigationJSON.CREATE_CAREPLAN_TEMPLATE}`}
              component={CaretaskBuilder}
            />

            <LoggedInRoute
              isLoggedIn={isLoggedIn}
              path={`/${NavigationJSON.EDIT_CAREPLAN_TEMPLATE}`}
              component={CaretaskBuilder}
            />

            <LoggedInRoute
              isLoggedIn={isLoggedIn}
              path={`/${NavigationJSON.PATIENT}`}
              component={PatientsList}
            />

            <LoggedInRoute
              isLoggedIn={isLoggedIn}
              path={`/${NavigationJSON.SITES}`}
              component={Sites}
            />

            <LoggedInRoute
              isLoggedIn={isLoggedIn}
              path={`/${NavigationJSON.REVIEW_CARETASKS}`}
              component={ReviewAssessments}
            />

            <LoggedInRoute
              isLoggedIn={isLoggedIn}
              path={`/${NavigationJSON.PATIENT_OVERVIEW}/:patientId`}
              component={PatientOverview}
            />

            <LoggedInRoute
              isLoggedIn={isLoggedIn}
              path={`/${NavigationJSON.IMPORT_CAREPLAN_TEMPLATE}`}
              component={ImportCareplanTemplate}
            />

            <PrivateRoute
              path={`/${NavigationJSON.ADMINISTRATION}`}
              permissions={
                services
                  ? services.hasOwnProperty(
                    'administrationservice.myoncare.care'
                  )
                  : true
              }
              isLoggedIn={isLoggedIn}
              component={Administration}
            />

            <PrivateRoute
              path={`/${NavigationJSON.APPOINTMENTMANAGER}`}
              permissions={
                services
                  ? services.hasOwnProperty('appointmentservice.myoncare.care')
                  : true
              }
              isLoggedIn={isLoggedIn}
              component={AppointmentManager}
            />

            <PrivateRoute
              path={`/${NavigationJSON.CAREPLAN}`}
              permissions={
                services
                  ? services.hasOwnProperty('templateservice.myoncare.care')
                  : true
              }
              isLoggedIn={isLoggedIn}
              component={Careplans}
            />

            <PrivateRoute
              path={`/${NavigationJSON.FILES}`}
              permissions={
                services
                  ? services.hasOwnProperty('assetservice.myoncare.care')
                  : true
              }
              isLoggedIn={isLoggedIn}
              component={Files}
            />

            <PrivateRoute
              path={`/${NavigationJSON.PREVIEW_VIDEO}`}
              permissions={
                services
                  ? services.hasOwnProperty('assetservice.myoncare.care')
                  : true
              }
              isLoggedIn={isLoggedIn}
              component={PreviewView}
            />

            <PrivateRoute
              path={`/${NavigationJSON.CAREPATHWAY_BUILDER_CREATE}`}
              permissions={
                services
                  ? services.hasOwnProperty('templateservice.myoncare.care')
                  : true
              }
              isLoggedIn={isLoggedIn}
              component={CarePathWayBuilderProvider}
            />

            <PrivateRoute
              path={`/${NavigationJSON.CAREPATHWAY_BUILDER_EDIT}`}
              permissions={
                services
                  ? services.hasOwnProperty('templateservice.myoncare.care')
                  : true
              }
              isLoggedIn={isLoggedIn}
              component={CarePathWayBuilderProvider}
            />

            <Route
              path={`/${NavigationJSON.SCAN_QRCODE}`}
              component={ScanQrCode}
            />
          </Switch>
        </AppLayout>
        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
};

export default navigation;
