import { useState, useEffect, useMemo } from 'react';

import { useInteractiveTable } from '@global/Hooks';
import { getStandardValues } from './AnalyticsDataSettings.api';

export const useAnalyticsData = (
  currComponent,
  componentCollection,
  setComponentCollection
) => {
  const [openModal, setOpenModal] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const matchedPosition = useMemo(() => {
    return componentCollection.findIndex(elem => elem.id === currComponent.id);
  }, [componentCollection, currComponent.id]);
  const matchingComponent = componentCollection[matchedPosition];
  const initialStandardValue =
    matchingComponent.searchTerm.standardValue ||
    matchingComponent.standardValue;
  const [standardDataValue, setStandardDataValue] =
    useState(initialStandardValue);
  const [attachBtnClicked, setAttachBtnClicked] = useState(
    !!initialStandardValue
  );

  const fetchData = async controller => {
    setIsLoading(true);
    const response = await getStandardValues(controller.signal, {
      offset: query.start,
      limit: query.length,
      searchValue: query.value,
    });
    if (response) {
      setFetchedData(response.data.rows);
      setRecordsTotal(response.data.count);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const componentCollectionTemp = [...componentCollection];
    const matchingComponentTemp = componentCollectionTemp[matchedPosition];
    if (attachBtnClicked && standardDataValue) {
      matchingComponentTemp.standardValueId =
        standardDataValue.id && standardDataValue.id;
      matchingComponentTemp.standardValue = standardDataValue;
      if (matchingComponentTemp.searchTerm) {
        matchingComponentTemp.searchTerm.standardValueId =
          standardDataValue?.id;
        matchingComponentTemp.searchTerm.standardValue = standardDataValue;
      }
    }
    componentCollectionTemp[matchedPosition] = matchingComponentTemp;
    setComponentCollection(componentCollectionTemp);
  }, [attachBtnClicked, standardDataValue]);

  const clearAttachedList = () => {
    setAttachBtnClicked(false);
    const componentCollectionTemp = [...componentCollection];
    const matchingComponentTemp = componentCollectionTemp[matchedPosition];
    matchingComponentTemp.standardValueId = null;
    if (typeof matchingComponentTemp.searchTerm === 'object') {
      matchingComponentTemp.searchTerm.standardValueId = null;
    }
    componentCollectionTemp[matchedPosition] = matchingComponentTemp;
    setComponentCollection(componentCollectionTemp);
  };

  const { query, currentPage, handleSearch, handleTableOnChange } =
    useInteractiveTable(fetchData, false, recordsTotal);

  return {
    openModal,
    setOpenModal,
    fetchedData,
    query,
    standardDataValue,
    setStandardDataValue,
    attachBtnClicked,
    setAttachBtnClicked,
    clearAttachedList,
    isLoading,
    currentPage,
    fetchData,
    handleSearch,
    handleTableOnChange,
    recordsTotal,
    matchingComponent,
  };
};
