import moment from 'moment';
import I18n from 'i18next';

export default function formatTimestamp(
  timestamp: number,
  format: string = 'L',
  local: string = I18n.language
): string {
  return moment.unix(timestamp).locale(local).format(format);
}
