import { Dosage } from 'src/Views/AssignmentCards/MedicationPlan/MedicationPlan.types';
import { TimeOfDay } from './timeOfDay.enum';
import { DosageCategory } from './dosageCategory.enum';

function getDosageCategory(hour: number) {
  if (hour < TimeOfDay.MORNING_START || hour > TimeOfDay.NIGHT_END)
    return DosageCategory.UNKNOWN;

  if (hour < TimeOfDay.MORNING_END) return DosageCategory.MORNINGS;

  if (hour < TimeOfDay.EVENING_START) return DosageCategory.NOONS;

  if (hour < TimeOfDay.NIGHT_START) return DosageCategory.EVENINGS;

  return DosageCategory.BEFORE_SLEEP;
}

export const categorizeDosage = (dosages?: Dosage[]) => {
  return dosages?.map(dose => {
    if (!dose.duration) return { ...dose, category: DosageCategory.UNKNOWN };
    const hour = parseInt(dose.duration.split(':')[0], 10);
    const category = getDosageCategory(hour);
    return { ...dose, category };
  });
};
