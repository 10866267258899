import React, { memo } from 'react';
import {
  BaseEdge,
  EdgeProps,
  getBezierPath,
  EdgeLabelRenderer,
} from 'reactflow';

import { Box, IconButton } from '@ui-common-files/components';

import './CareTaskEdge.styles.scss';

const CareTaskEdge: React.FC = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  selected,
  data,
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      {selected && (
        <EdgeLabelRenderer>
          <Box
            otherStyles={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            }}
            className="edge-action"
          >
            <IconButton
              fill={style.stroke}
              size={14}
              name="delete"
              onClick={() => {
                data.removeEdge(id);
              }}
            />
          </Box>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

CareTaskEdge.displayName = 'CareTaskEdge';

export default memo(CareTaskEdge);
