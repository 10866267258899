import React from 'react';
import i18next from 'i18next';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import { FilterCriteriaProps } from '../FilterCriteria/FilterCriteria.type';
import { useFilterCriteriaComponentAnswerService } from './FilterCriteriaComponentAnswer.service';
import FilterCriteriaFieldSelector from '../FilterCriteriaFieldSelector/FilterCriteriaFieldSelector';
import FilterCriteriaMultipleOperator from '../FilterCriteriaMultipleOperator/FilterCriteriaMultipleOperator';
import Dropdown from '../../../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import SingleSelectQuestionSearchable from '../../../../../../caro-ui-commonfiles/components/SingleSelectQuestionSearchable/SingleSelectQuestionSearchable';
import IconButton from '../../../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import FilterCriteriaOperator from '../FilterCriteriaOperator/FilterCriteriaOperator';
import { getOperators } from '../../../../../Utils/PatientFilter/PatientFilter';
import TextInput from '../../../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';
import { AuxiliaryColors } from '../../../../../../caro-ui-commonfiles/utils/colors';
import { CareplanComponentTypes } from '../../../../../types/Careplan';
import {
  fileUploadOptions,
  yesNoOptions,
  painLocationOptions,
  choiceComponentOptions,
  checkIfComponentIsObjectValue,
} from './FilterCrtieriaComponentAnswer.utils';

function FilterCriteriaComponentAnswer({
  criteria,
  type,
  setDisplayFlashMsg,
}: FilterCriteriaProps) {
  const {
    componentType,
    selectOptions,
    componentTypes,
    changeComponentType,
    selectedComponent,
    components,
    setSelectedComponent,
    checkNoValueField,
    onClickRemoveHandler,
    setAnswerValue,
    searchComponents,
    noComponentSelectedError,
    error,
  } = useFilterCriteriaComponentAnswerService({
    criteria,
    type,
    setDisplayFlashMsg,
  });

  function AnswerComponent(position: number) {
    const value = criteria.value[position]
      ? checkIfComponentIsObjectValue(componentType.value)
        ? {
            ...criteria.value[position],
            label: i18next.t(criteria.value[position].labelLocation),
          }
        : criteria.value[position]
      : '';
    switch (componentType.value) {
      case CareplanComponentTypes.UploadFile: {
        return (
          <Dropdown
            value={value}
            options={fileUploadOptions()}
            placeholder={i18next.t('common_field_placeholders.criteria')}
            handleOnChange={e => setAnswerValue({ value: e, position })}
          />
        );
      }
      case CareplanComponentTypes.Yesno: {
        return (
          <Dropdown
            value={value}
            options={yesNoOptions()}
            placeholder={i18next.t('common_field_placeholders.criteria')}
            handleOnChange={e => setAnswerValue({ value: e, position })}
          />
        );
      }
      case CareplanComponentTypes.PainLocationChart: {
        return (
          <Dropdown
            value={value}
            options={painLocationOptions()}
            placeholder={i18next.t('common_field_placeholders.criteria')}
            handleOnChange={e => setAnswerValue({ value: e, position })}
          />
        );
      }
      case CareplanComponentTypes.SingleChoice:
      case CareplanComponentTypes.MultipleChoice: {
        return (
          <Dropdown
            value={value}
            options={choiceComponentOptions(selectedComponent)}
            placeholder={i18next.t('common_field_placeholders.criteria')}
            handleOnChange={e => setAnswerValue({ value: e, position })}
            noOptionsMessage={() =>
              i18next.t('filter_dropdown_list.define_component_message')
            }
          />
        );
      }
      default: {
        return (
          <TextInput
            value={value}
            id="Text"
            name="Text"
            placeholder={i18next.t('common_field_placeholders.text')}
            handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAnswerValue({ value: e.target.value, position })
            }
          />
        );
      }
    }
  }

  const hideOperator = [CareplanComponentTypes.UploadFile].includes(
    componentType.value
  );

  const answerClassName = (() => {
    let ans = '';
    if (criteria.value.length === 1) ans = 'full';
    else ans = 'half';
    return `filters__answer-value-container filters__answer-value-container__${ans}`;
  })();

  return (
    <Flex className="filters__answer">
      <Box className="filters__answer-container">
        <Flex className="filters__answer-type-container">
          <FilterCriteriaMultipleOperator criteria={criteria} />
          <div className="filters__answer-type-title">
            <Dropdown
              value={{
                label: i18next.t('patients_complexsearch.componentTypeAndName'),
              }}
              isDisabled
              options={[]}
            />
          </div>
          <div className="filters__answer-type-dropdown">
            <Dropdown
              value={{
                ...componentType,
                label: i18next.t(componentType.labelLocation),
              }}
              options={selectOptions(componentTypes)}
              placeholder={i18next.t('common_field_placeholders.criteria')}
              handleOnChange={changeComponentType}
            />
          </div>
          <div className="filters__answer-type-select">
            <SingleSelectQuestionSearchable
              value={selectedComponent}
              defaultOptions={components}
              placeholder={i18next.t('patients_complexsearch.componentName')}
              handleOnChange={setSelectedComponent}
              loadOptions={searchComponents}
            />
          </div>
        </Flex>
        <Flex className="filters__answer-container">
          <div className="filters__answer-tag">
            {i18next.t('patients_complexsearch.if').toString()}
          </div>
          <div className="filters__answer-dropdown">
            <FilterCriteriaFieldSelector criteria={criteria} type={type} />
          </div>
          <FilterCriteriaOperator
            hide={hideOperator}
            criteria={criteria}
            operators={getOperators(componentType.id)}
          />
          <div
            className={`filters__answer-value${hideOperator ? '__full' : ''}`}
          >
            {!checkNoValueField(criteria.check.id) &&
              criteria.value.map((_, position) => [
                position > 0 && <div className="filters__criteria-separator" />,
                <div className={answerClassName}>
                  {AnswerComponent(position)}
                  {!noComponentSelectedError && error && (
                    <div className="filters__answer-value-error">
                      {i18next
                        .t(
                          `patients_complexsearch.validations_${error.label}`,
                          error.options
                        )
                        .toString()}
                    </div>
                  )}
                </div>,
              ])}
          </div>
        </Flex>
        {noComponentSelectedError && (
          <div className="filters__answer__error-no-name">
            {i18next
              .t('patients_complexsearch.error_componentAnswerIncomplete')
              .toString()}
          </div>
        )}
      </Box>
      <Flex className="filters__criteria-delete">
        <IconButton
          name="delete"
          size={30}
          fill={AuxiliaryColors.RED}
          onClick={onClickRemoveHandler}
          disabled={!criteria.position}
        />
      </Flex>
    </Flex>
  );
}

export default FilterCriteriaComponentAnswer;
