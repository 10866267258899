import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { Flex, Button, ContentLoader } from '@ui-common-files/components';

import { FlashMessageTypes } from '@ui-common-files/types/components';

import { ButtonType, ButtonVariant } from '@ui-common-files/utils/button';
import { MEDIA_QUERIES } from '@ui-common-files/utils';
import { ComponentTypes, Actions } from '@ui-common-files/utils/componentTypes';
import { ShowCareplanRequest, convertLinkToBlob } from '@utils';
import { getCarePlanIconColor } from '@utils/Careplan';

import { ApiClient, ShowFlashMessage } from '@global/Services';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import EditCareplanModalContent from '../Careplans/EditCareplanModalContent';
import EditCareplanModalFooter from '../Careplans/EditCareplanModalFooter';
import EditCareplanModalHeader from '../Careplans/EditCareplanModalHeader';
import CareplanShowModalContent from '../Careplans/CareplanShowModalContent';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import CardInfoContent from '../common/CardInfoContent';
import renderReccuerenceModalContent from './renderReccuerenceModalContent';
import CareplanResultsModalFooter from '../Careplans/CareplanResultsModalFooter';
import CareplanResultsModalContent from '../Careplans/CareplanResultsModalContent';
import useAllTodoAssessments from '../Notifications/Notification.hooks';
import { ViewCareplanModalFooter } from '../Careplans/ViewCareplan';
import { PatientOverviewCareTasks } from './AssignmentCards';

import CareplanGraphCard from './CareplanGraphCard';
import { Title, Footer } from './CardComponents';

import serviceConfig from '../../../serviceConfig.json';

import '../../css/analytics.css';

const CareplanCard = ({ data }) => {
  const careplanID = `careplan_${data.id}`;
  const {
    setModalContent,
    setMessagePrompt,
    setLandingTab,
    closeMessagePrompt,
    closeModal,
    showCareplanResults,
    setShowCareplanResults,
    navigateToCareplanAnswerView,
    setForceScroll,
    careplanId,
    patientInfo,
    setPatientSectionalModal,
  } = useContext(PatientOverviewContext);
  const {
    setItemIdToUpdate,
    setRestPagesToUpdate,
    setItemIdToShowLoading,
  } = useContext(PatientOverviewCareTasks);

  const { t } = useTranslation();

  const history = useHistory();
  const stateData = history.location.state ? history.location.state : {};

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showModalGraph, setShowModalGraph] = useState(false);

  const checkEditCareplanCredential = async id => {
    ApiClient.GET({
      url: serviceConfig.brokerService.editCareplan.uri + id,
    })
      .then(response => {
        if (careplanId !== id) {
          if (response && response.status === 200) {
            setEditCareplanModalContent(response.data);
          } else {
            setModalContent({
              content: <ContentLoader />,
            });
          }
        } else {
          setMessagePrompt({
            title: `${t('patientOverview.editAssignment')}: ${data.name}`,
            content: (
              <PromptContent
                message={`${t('common_labels.unable_to_edit_caretask')}`}
              />
            ),
            footer: (
              <PromptFooter
                close={closeMessagePrompt}
                confirmHandler={closeMessagePrompt}
                btnLabelRight={t('common_buttons.ok')}
                hasOneBtn
              />
            ),
          });
        }
      })
      .catch(error => {
        closeModal();
        ShowFlashMessage({
          type: error.response.data.type,
          messageKey: error.response.data.content,
        });
      });
  };

  const getCareplanAnswers = async id => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.getCareplanAnswers.uri + id
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      ShowFlashMessage({
        type: error.response.data.type,
        messageKey: error.response.data.content,
      });
    }
  };

  const setEditCareplanModalContent = async careplanData => {
    careplanData.endDate = data.endsAt;
    careplanData.title = data.name;
    careplanData.phaseType = careplanData.phaseTypeObject
      ? {
          id: careplanData.phaseTypeObject.id,
          label: t(careplanData.phaseTypeObject.name),
        }
      : null;
    if (careplanData.patient.profilePicture.previewURL) {
      await convertLinkToBlob(careplanData.patient.profilePicture.previewURL)
        .then(blob => {
          careplanData.patient.profilePicture = blob;
        })
        .catch(() => {
          careplanData.patient.profilePicture =
            careplanData.patient.profilePicture.previewURL;
        });
    } else {
      careplanData.patient.profilePicture = `data:image;base64,${careplanData.patient.profilePicture}`;
    }
    setModalContent({
      title: `${t('patientOverview.editAssignment')}: ${data.name}`,
      content: (
        <EditCareplanModalContent
          careplan={careplanData}
          data={data}
          setItemIdToUpdate={setItemIdToUpdate}
        />
      ),
      footer: (
        <EditCareplanModalFooter
          componentType={ComponentTypes.CAREPLAN}
          actionType={Actions.Update}
        />
      ),
      header: <EditCareplanModalHeader />,
    });
  };

  const sendCareplan = async () => {
    setItemIdToShowLoading(data.id);
    return ApiClient.GET({
      url: serviceConfig.brokerService.sendCareplan.uri + data.id,
    })
      .then(response => {
        if (response) {
          setItemIdToUpdate(data.id);
        }
        return response;
      })
      .finally(() => {
        setItemIdToShowLoading(null);
      });
  };

  const showCareplanAnswers = careplan => {
    let careplanData = { ...careplan };
    careplanData.template = {
      hasSubScoreFormula: data?.template?.hasSubScoreFormula,
    };
    setPatientSectionalModal({
      isOpen: true,
      title: `${t('common_labels.label_results')}: ${data.name}`,
      content: (
        <CareplanResultsModalContent
          careplanData={[careplanData]}
          chosenDate={showCareplanResults.answerTimestamp}
        />
      ),
      footer: <CareplanResultsModalFooter careplanData={[careplanData]} />,
    });
  };

  const unassignCareplan = async () => {
    setItemIdToShowLoading(data.id);
    return ApiClient.GET({
      url: serviceConfig.brokerService.unassignCareplan.uri + data.id,
    })
      .then(response => {
        if (response) {
          setItemIdToUpdate(data?.id);
        }
        return response;
      })
      .finally(() => {
        setItemIdToShowLoading(null);
      });
  };

  const { getAllTodos, allTodoAssessmentsController } = useAllTodoAssessments();
  const deleteCareplan = () => {
    allTodoAssessmentsController.abort();
    ApiClient.GET({
      url: serviceConfig.brokerService.deleteCareplan.uri + data.id,
    }).then(response => {
      if (response) {
        if (response.data.type === FlashMessageTypes.Success) {
          setRestPagesToUpdate(data.id);
          if (data.isAssessment) {
            getAllTodos();
          }
        }
        ShowFlashMessage({
          type: response.data.type,
          messageKey: response.data.content,
        });
      }
    });
  };

  const sendHandler =
    data.firebaseKey || data.isAssessment ? null : sendCareplan;

  const unassignHandler =
    sendHandler || data.isAssessment ? null : unassignCareplan;

  const clearThresholds = async answers => {
    const answerIds = [];
    answers.forEach(answer => {
      if (answer.answers[0]) {
        answerIds.push(answer.answers[0].id);
      }
    });
    return await axios.post(
      serviceConfig.brokerService.clearCareplanThresholdNotifications.uri,
      {
        answerIds,
        careplan: data,
        patientId: data.patientId,
      }
    );
  };

  const getCareplanAnswersWithSpecificDate = async (id, answerTimestamp) => {
    try {
      const response = await axios.get(
        `${
          serviceConfig.brokerService.getCareplanAnswersWithSpecificDate.uri +
          data.id
        }/${answerTimestamp}`
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      ShowFlashMessage({
        type: error.response.data.type,
        messageKey: error.response.data.content,
      });
    }
  };

  const resultHandler = data.isAnswered
    ? () => {
        setPatientSectionalModal({
          isOpen: true,
          title: `${t('common_labels.label_results')}: ${data.name}`,
          content: <ContentLoader />,
        });

        return getCareplanAnswers(data.id).then(res => {
          if (
            stateData.seen &&
            stateData.seen === true &&
            stateData.answerTimestamp
          ) {
            getCareplanAnswersWithSpecificDate(
              data.id,
              stateData.answerTimestamp
            ).then(res => {
              clearThresholds(res.questionWithThresholdsAndJumps);
            });
          }
          if (res !== undefined) {
            showCareplanAnswers(res);
          }
        });
      }
    : null;

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    if (
      showCareplanResults &&
      showCareplanResults.careplanId === data.id &&
      resultHandler
    ) {
      resultHandler();
      setShowCareplanResults(false);
      history.replace(history.location.pathname, {
        ...history.location.state,
        careplanId: null,
        answerTimestamp: null,
        refreshCareplanCardViewer: null,
      });
    }
  }, [showCareplanResults, resultHandler]);

  const renderDeleteDialog = user => {
    setMessagePrompt({
      title: `${t('common_labels.msg_confirm_template_delete_headline')}: ${
        data.name
      }`,
      content: (
        <PromptContent
          message={
            careplanId !== data.id
              ? `${t('common_labels.warning_careplan_delete')} ${t(
                  'common_labels.msg_confirm_careplan_delete'
                )}`
              : `${t('common_labels.unable_to_delete_caretask')}`
          }
        />
      ),
      footer:
        careplanId !== data.id ? (
          <PromptFooter
            close={closeMessagePrompt}
            confirmHandler={() => deleteCareplan(user)}
          />
        ) : (
          <PromptFooter
            close={closeMessagePrompt}
            confirmHandler={closeMessagePrompt}
            btnLabelRight={t('common_buttons.ok')}
            hasOneBtn
          />
        ),
    });
  };

  const renderShowModalContent = careplanDataForShow => {
    setModalContent({
      title: `${t('newCareplan_view.view')} ${data.name}`,
      content: (
        <CareplanShowModalContent careplanDataForShow={careplanDataForShow} />
      ),
      footer: <ViewCareplanModalFooter close={closeModal} />,
    });
  };

  const navigateToAnswer = ({
    id,
    isAssessment,
    occurrenceId,
  }: {
    id: number;
    isAssessment: boolean;
    occurrenceId: number;
  }) => {
    navigateToCareplanAnswerView({
      id,
      isAssessment,
      occurrenceId,
      setItemIdToUpdate: () => {
        setItemIdToUpdate(data.id);
      },
    });
  };

  const getAnswerButton = () => {
    const {
      firebaseKey,
      id,
      isAssessment,
      occurrenceId,
      previousOccurrenceStillAnswerAble,
      isAnswerable,
      shouldUserAnswerCareplan,
    } = data;
    const isActivatedCaretaskOrAssessment = firebaseKey || isAssessment;

    if (
      (previousOccurrenceStillAnswerAble || isAnswerable) &&
      shouldUserAnswerCareplan &&
      isActivatedCaretaskOrAssessment
    ) {
      return (
        <div className="margin-left-10">
          <Button
            label={
              isAssessment
                ? t('newCareplan_view.startNow')
                : t('newCareplan_view.answerOnBehalf.text')
            }
            disabled={false}
            type={ButtonType.BUTTON}
            onClick={() => {
              navigateToAnswer({ id, isAssessment, occurrenceId });
            }}
            variant={ButtonVariant.CONTAINED}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="assignment-card">
      <Title
        iconName={
          data.isAssessment
            ? ComponentTypes.ASSESSMENT
            : ComponentTypes.CAREPLAN
        }
        color={getCarePlanIconColor(data)}
        data={data}
        onEditIconClick={() => {
          checkEditCareplanCredential(data.id);
        }}
        onDeleteClick={renderDeleteDialog}
        onShowIconClick={() => {
          setModalContent({
            title: `${t('newCareplan_view.view')} ${data.name}`,
            content: <ContentLoader />,
            footer: <ViewCareplanModalFooter close={closeModal} />,
          });

          return ShowCareplanRequest(data.id).then(response => {
            const careplanDataForShow = {
              ownershipLogs: response.ownershipLogs,
              lifespan: response.lifespan,
              carePathways: response.qc.carePathways.map(speciality => ({
                label: speciality.name,
                value: speciality.id,
              })),
              scoringScheme: response.qc.scoringScheme,
              startDate: response.firstOccurrence,
              phaseType: response.phaseType,
              phaseTypeId: response.phaseTypeId,
              careplansWithThresholds: response.questionsWithThresholds,
              customTime: response.hours,
              lifespanUnit: response.lifespanUnit,
              recurrenceText: data.recurrenceText,
              title: response.qc.name,
              icds: data.icdRecords,
              nbrComponents: data.questionNumber,
              careplanLogicJumps: response.careplanLogicJumps,
              isEvaluating: response.isEvaluating,
            };

            renderShowModalContent(careplanDataForShow);
          });
        }}
        onShowReccuerenceIconClick={() => {
          renderReccuerenceModalContent(
            data.name,
            data.id,
            setModalContent,
            closeModal,
            patientInfo.deviceOffset
          );
        }}
      />

      <Flex
        flexDirection={windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'}
        justifyContent="space-between"
        otherStyles={{ paddingLeft: '60px', margin: '10px 0' }}
      >
        <div id={careplanID}>
          <CardInfoContent
            data={data}
            variant="careplan"
            setLandingTab={setLandingTab}
            setForceScroll={setForceScroll}
          />
        </div>
        <CareplanGraphCard
          data={data}
          key={data.id}
          show={showModalGraph}
          onClose={() => setShowModalGraph(false)}
        />
      </Flex>
      <Footer
        sendHandler={sendHandler}
        unassignHandler={unassignHandler}
        resultHandler={resultHandler}
        sendAndUnassignEnabled={!data.isAssessment}
        isCareplanAnswered={data.isAnswered}
        isLastOccurrenceAnswered={data.isLastOccurrenceAnswered}
        isLastOccurrenceBeforeToday={data.isLastOccurrenceBeforeToday}
        componentType={ComponentTypes.CAREPLAN}
        firebaseKey={data.firebaseKey}
        answerButton={getAnswerButton()}
        carePathwayExpirationDate={
          data.carePathways && data.carePathways.length > 0
            ? data.carePathways[0].expirationDate
            : null
        }
        careplanData={data}
        setItemIdToUpdate={setItemIdToUpdate}
      />
    </div>
  );
};

export default CareplanCard;
