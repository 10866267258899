import React from 'react';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  Flex,
  Box,
  TextInput,
  RadioButton,
  Icon,
} from '@ui-common-files/components';
import { PrimaryColor } from '@ui-common-files/utils';

import EmptyState from './EmptyState';
import Pagination from './Pagination';

import useCareTaskListService from './CareTaskList.service';
import { PAGE_SIZE } from '../CarePathWayBuilder.constant';

import './CareTaskList.styles.scss';

const CareTaskList: React.FC = () => {
  const { t } = useTranslation();
  const {
    careTask,
    onDragStart,
    isAddingParent,
    onKeywordChange,
    setIsAddingParent,
    hasNoResultsBySearch,
    hasNoTemplates,
    onPageChange,
  } = useCareTaskListService();

  const careTaskListContentParentClassName = classNames({
    'care-task-list_content': !hasNoResultsBySearch && !hasNoTemplates,
    'care-task-list_content-empty_state':
      hasNoResultsBySearch || hasNoTemplates,
  });

  return (
    <>
      <Flex className="care-task-list_options">
        <Box className="care-task-list_options-title">
          {t('common_buttons.add_caretask')}
        </Box>
        <TextInput
          value={careTask.keyword}
          handleOnChange={onKeywordChange}
          placeholder={t('carePathway_builder.careTask_list.placeholder')}
        />

        <RadioButton
          name="new-caretask-type"
          checked={isAddingParent}
          label={t('carePathway_builder.careTask_list.parent_Caretask')}
          handleOnChange={() => {
            setIsAddingParent(true);
          }}
        />
        <RadioButton
          name="new-caretask-type"
          checked={!isAddingParent}
          label={t('carePathway_builder.careTask_list.linked_Caretask')}
          handleOnChange={() => {
            setIsAddingParent(false);
          }}
        />
        <Flex alignItems="center" className="care-task-list_options-info">
          <Icon name="infoView" size={16} />
          <Box margin="0 0 0 4px">
            {t('carePathway_builder.careTask_list.drag_n_drop')}
          </Box>
        </Flex>
      </Flex>
      {careTask.isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{ height: '100%' }}
        >
          <Loader
            className="loader"
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            width={40}
            height={40}
          />
        </Flex>
      )}
      {!careTask.isLoading && (
        <Box className={careTaskListContentParentClassName}>
          {hasNoResultsBySearch || hasNoTemplates ? (
            <EmptyState
              iconName={hasNoResultsBySearch ? 'SearchOff' : 'Careplan'}
              textContent={
                hasNoResultsBySearch
                  ? 'carePathway_builder.careTask_list.no_CareTask_found'
                  : 'carePathway_builder.careTask_list.no_CareTask_added'
              }
            />
          ) : (
            careTask.items?.map(item => (
              <div
                key={`care-task-id-${item.id}`}
                className="care-task-list_content-item"
                onDragStart={event => onDragStart(event, item)}
                draggable
              >
                <Icon
                  name={item.isAssessment ? 'assessment' : 'careplan'}
                  fill={PrimaryColor.MAIN_COLOR}
                />
                <Box className="care-task-list_content-item-alias">
                  {item.alias}
                </Box>
                <Box className="care-task-list_content-item-title">
                  {item.name}
                </Box>
                {item.scoringScheme && (
                  <Flex otherStyles={{ marginTop: '12px' }}>
                    {item.scoringScheme.map(schema =>
                      schema.min === -1 && schema.max === -1 ? null : (
                        <Box
                          key={`schema-id-${item.id}-${schema.id}`}
                          className="care-task-list_content-item-schema"
                          otherStyles={{
                            backgroundColor: schema.bgColor,
                            color: schema.color,
                          }}
                        >
                          {schema.id}: {schema.min}-{schema.max}
                        </Box>
                      )
                    )}
                  </Flex>
                )}
              </div>
            ))
          )}
          <Pagination
            currentPage={careTask.page}
            totalCount={careTask.totalRecords}
            pageSize={PAGE_SIZE}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </>
  );
};

export default CareTaskList;
