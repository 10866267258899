import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { FlashMessageTypes } from '@ui-common-files/types/components';

import config from '../../../../config';
import storeInfo from '../../../../store';
import { Institution } from '../../../types/Institution';
import { BrowserHistoryReplace } from '../../Router/BrowserHistory/BrowserHistory';
import { ShowFlashMessage } from '../FlashMessage/ShowFlashMessage';

export function requestConfigHandler(defaultConfig: AxiosRequestConfig) {
  const requestConfig: AxiosRequestConfig = { ...defaultConfig };
  const user = storeInfo.store.getState().session?.user;
  if (user) {
    requestConfig.headers.Authorization = user.accessToken;
    requestConfig.headers.Sessionid = user.sessionId;
    requestConfig.headers.Userid = user.id;
    requestConfig.headers.Roleid = user.roleId;
    requestConfig.headers.InstitutionIds = JSON.stringify(
      user.institutions.map((institution: Institution) => {
        return institution.id;
      })
    );
  }

  requestConfig.timeout = defaultConfig.timeout
    ? defaultConfig.timeout
    : parseInt(config.axios_timeout, 10);

  requestConfig.responseType = 'json';

  return { ...defaultConfig, ...requestConfig };
}

export function requestErrorHandler(error: any) {
  return Promise.reject(error);
}

export function responseDataHandler(response: AxiosResponse<never>) {
  return response;
}

export function responseErrorHandler(error: AxiosError) {
  if (error.code !== 'ERR_CANCELED' && error.config.errorCallback)
    error.config.errorCallback(error);

  switch (error.code) {
    case 'ERR_NETWORK': // No Network connection
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: 'flash.errorNetwork',
      });
      return null;
    case 'ECONNABORTED': // Timeout
      ShowFlashMessage({
        type: FlashMessageTypes.Error,
        messageKey: 'flash.errorServerTimeout',
      });
      return null;
    case 'ERR_CANCELED':
      return null;
    default:
      switch (error.response?.status) {
        case 401: // Unauthorized
          storeInfo.store.dispatch({ type: 'resetCurrentUser' });
          BrowserHistoryReplace('/login');
          return null;
        case 403: // Forbidden
          ShowFlashMessage({
            type: FlashMessageTypes.Warning,
            messageKey: 'common_labels.permissionDenied',
          });
          return null;
        case 400: // Bad Request
        case 404: // Not Found
        case 405: // Method Not Allowed
        case 409: // Conflict
        case 500: // Internal Server Error
        case 502: // Bad Gateway
        case 413: // Payload Too Large
        case 503: // Service Unavailable
          ShowFlashMessage({
            type: error.response?.data?.type || FlashMessageTypes.Error,
            messageKey: error.response?.data?.content,
            message: error.message,
          });
          return null;
        default:
          return Promise.reject(error);
      }
  }
}
