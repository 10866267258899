import serviceConfig from '../../../serviceConfig.json';
import { ApiClient } from '../../Global/Services';

function checkUserPinAndOptins(userId) {
  return ApiClient.GET({
    url: `${serviceConfig.brokerService.checkUserPinAndOptins.uri}${userId}`,
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return {
        type: error.response.data.type,
        content: error.response.data.content,
        logoutFlag: error.response.data.logoutFlag ? error.response.data.logoutFlag : null
      };
    });
}

export default checkUserPinAndOptins;