import { useState, useRef, useEffect } from 'react';
import i18n from 'i18next';

import {
  getInitExtraProperties,
  getPlugins,
  getToolbar,
  localizeLangToTinymce,
} from './WYSIWYGEditor.util';
import { useDebounce } from '../../utils/hooks';
import { getMentionDetails } from '../../utils';
import { Role, User, WYSIWYGServiceParams } from './WYSIWYGEditor.type';
import { Satellite } from '../../types/components/satellite';

export const useWYSIWYGEditor = ({
  maxChars,
  onChange,
  hasLink,
  placeholder,
  hasMention,
  mentionListCall,
  hideToolbar,
  hideWordCount,
}: WYSIWYGServiceParams) => {
  const editorRef = useRef<any>(null);
  const [wordCount, setWordCount] = useState<number>(0);
  const [userList, setUserList] = useState<User[]>([]);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [rolesList, setRolesList] = useState<Role[]>([]);
  const [satellitesList, setSatellitesList] = useState<Satellite[]>([]);
  const [mentionQuery, setMentionQuery] = useState<string>('');
  const abortControllerRef = useRef(null);
  const debouncedQuery = useDebounce(mentionQuery);

  const handleEditorKeydown = event => {
    if (hasMention && event.key === '@') {
      setPopupVisible(true);
    }
  };

  const handleEditorInput = () => {
    const editor = editorRef.current;
    if (!editor) return;
    const selection = editor.selection.getRng();
    const { startContainer } = selection;
    let content = '';
    if (startContainer.nodeType === Node.TEXT_NODE) {
      content = startContainer.data.slice(0, selection.startOffset);
    } else {
      content = startContainer.textContent;
    }

    const mentionTriggerIndex = content.lastIndexOf('@');

    if (mentionTriggerIndex !== -1) {
      const query = content.slice(mentionTriggerIndex + 1);
      setMentionQuery(query);
      setPopupVisible(true);
    } else {
      setPopupVisible(false);
      setMentionQuery('');
    }
  };

  const init = {
    height: 200,
    menubar: false,
    inline: true,
    xss_sanitization: true,
    placeholder,
    language: localizeLangToTinymce(i18n.language),
    plugins: getPlugins({ hasLink }),
    toolbar: hideToolbar ? false : getToolbar({ hasLink }),
    setup: hasMention
      ? editor => {
          editor.on('keydown', handleEditorKeydown);
          editor.on('input', handleEditorInput);
        }
      : null,
    ...getInitExtraProperties({ hasLink }),
  };

  const getAllUsersData = async value => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    mentionListCall(value, controller.signal).then(response => {
      setSatellitesList(response?.data.satellites || []);
      setRolesList(response?.data.roles || []);
      setUserList(response?.data.users || []);
    });
  };

  useEffect(() => {
    if (hasMention && popupVisible) {
      getAllUsersData(debouncedQuery);
    }
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
    };
  }, [debouncedQuery, popupVisible]);

  const getInputLength = (editor: any) => {
    const charLength = editor.plugins.wordcount.body.getCharacterCount();
    return charLength ;
  };

  const handleEditorChange = (content: string, editor: any) => {
    if (hideWordCount) {
      return onChange(content);
    }
    const charLength = getInputLength(editor);
    if (charLength <= maxChars) {
      setWordCount(charLength);
      onChange(content);
    } else {
      const truncatedContent = content.substring(0, maxChars);
      editor.setContent(truncatedContent);
      setWordCount(maxChars);
      onChange(truncatedContent);
    }
  };

  const onKeyPress = (evt, editor) => {
    const counter = getInputLength(editor);
    if (counter >= maxChars) {
      evt.preventDefault();
    }
  };

  const handleInit = (_content: string, editor: any) => {
    const charLength = getInputLength(editor);
    !hideWordCount && setWordCount(charLength);
    editorRef.current = editor;
  };

  const insertMention = mention => {
    const editor = editorRef.current;
    const { name, tagTypeId } = getMentionDetails(mention);
    if (editor) {
      const { selection } = editor;
      const mentionElement = `<span class="mention-box" contenteditable="false" data-id="${mention.id}" data-tag-type-id="${tagTypeId}">@${name}</span>`;
      const range = selection.getRng();
      const startOffset = range.startOffset - mentionQuery.length - 1;
      range.setStart(range.startContainer, startOffset);
      range.deleteContents();
      editor.insertContent(mentionElement);
      editor.selection.collapse(false);
    }
    setPopupVisible(false);
  };

  const handleClickOutside = event => {
    const popup = document.getElementById('mention-popup');
    if (
      popup &&
      event.target instanceof Node &&
      !popup.contains(event.target)
    ) {
      setPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return {
    wordCount,
    handleEditorChange,
    handleInit,
    init,
    onKeyPress,
    userList,
    popupVisible,
    rolesList,
    satellitesList,
    insertMention,
  };
};
