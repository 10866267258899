import React from 'react';
import { useHistory } from 'react-router-dom';
import I18n from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  Box,
  IconButton,
  Flex,
  LanguageDropdown,
  Modal,
} from '@ui-common-files/components';

import { LanguageItems } from '@type/Common/';
import { EnumToArray } from '@global/Arrays';
import { appendRoute } from '@global/Strings';
import { HideFlashMessage } from '@global/Services';
import Imprint from './Imprint/Imprint';
import { PromptFooter } from '../common/ConfirmationPrompt';
import useLoginFooterService from './LoginFooter.service';

const LoginFooter = ({ setIsLanguageChanged }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { openModal, setOpenModal, openSupportForm } = useLoginFooterService();

  const changeLanguage = lng => {
    I18n.changeLanguage(lng);
    setIsLanguageChanged(true);
    history.push(`${history.location.pathname}?setLng=${lng}`);
  };
  const languagesArray = EnumToArray(LanguageItems);
  return (
    <Box className="login-credential__footer">
      <Box className="login-links">
        &copy;
        {` ONCARE GmbH ${new Date().getFullYear()}`}
      </Box>
      <Flex alignItems="center">
        <a
          href={appendRoute('terms-and-conditions')}
          type="button"
          className="login-links"
          target="__blank"
        >
          {t('common_labels.term_cond')}
        </a>
        <a
          href={appendRoute('privacy-policy')}
          type="button"
          className="login-links"
          style={{ marginRight: '4px' }}
          target="__blank"
        >
          {t('common_labels.privacy_policy')}
        </a>
        <LanguageDropdown
          onClick={lang => {
            changeLanguage(lang.value);
          }}
          items={languagesArray}
          active={languagesArray.findIndex(
            item => item.value.split('-')[0] === I18n.language.split('-')[0]
          )}
        />

        <Box otherStyles={{ marginLeft: 16 }}>
          <IconButton
            type="button"
            name="imprintInfo"
            onClick={() => {
              setOpenModal(true);
            }}
            size={24}
          />
        </Box>
      </Flex>
      <Box>
        <Modal
          title={t('imprint.eIFUTitle')}
          contentComponent={<Imprint />}
          footerComponent={
            <PromptFooter
              close={() => setOpenModal(false)}
              confirmHandler={openSupportForm}
              btnLabelRight={t('common_labels.support')}
              btnLabelLeft={t('common_buttons.close')}
            />
          }
          openModal={openModal}
          onClose={() => setOpenModal(false)}
          hideFlashMessage={HideFlashMessage}
        />
      </Box>
    </Box>
  );
};

export default LoginFooter;
