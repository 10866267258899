import { useTranslation } from 'react-i18next';

const calendarLocale = () => {
  const { t } = useTranslation();
  return {
    month: t('recurrence.month'),
    day: t('recurrence.day'),
    week: t('recurrence.week'),
    year: t('recurrence.year'),
    ytd: t('recurrence.ytd'),
    max: t('recurrence.max'),
    today: t('recurrence.today'),
    lastData: t('recurrence.lastData'),
    showMore: t('common_labels.label_more'),
    filter: t('common_labels.label_filters'),
    patient: t('nav.patient'),
    record: t('patients_view.label_medicalRecord'),
    start: t('newCareplan_view.careplanStartTime'),
    end: t('common_labels.label_end_time'),
    status: t('patients_view.label_status'),
    type: t('appointment.type'),
    location: t('appointment.place'),
  };
};

export default calendarLocale;
