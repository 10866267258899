import React from 'react';
import I18n from 'i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import CheckboxRadio from '../../../../caro-ui-commonfiles/utils/CheckboxRadio';
import Checkbox from '../../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import { isThresholdSet, validateOptions } from '../CareplanHelperUtility';
import { addMultipleChoices } from '../../../Utils/Careplan/careplanComponentUtilities';

import IconButton from '../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import Tag from '../../../../caro-ui-commonfiles/components/Tags/Tags';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import { TagsType, TagsVariant, ActionColors } from '@ui-common-files/utils';

const handleSortable = (
  event,
  item,
  index,
  componentCollection,
  setComponentCollection
) => {
  item.choiceCollection.choices[index].name = event.target.value;
  item.choiceCollection.choices[index].isChecked = event.target.checked;
  setComponentCollection([...componentCollection]);
};

const handleSortableList = (
  event,
  item,
  componentCollection,
  setComponentCollection
) => {
  item.choiceCollection.addChoice = event.target.value;
  setComponentCollection([...componentCollection]);
};

const removeSortables = (
  item,
  index,
  componentCollection,
  setComponentCollection,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  searchTermComponents,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring
) => {
  item.choiceCollection.choices.splice(index, 1);
  item.choiceCollection.optionWeights.weights.splice(index, 1);
  let total = 0;
  for (
    let index = 0;
    index < item.choiceCollection.optionWeights.weights.length;
    index++
  ) {
    total += parseInt(item.choiceCollection.optionWeights.weights[index], 10);
  }
  item.choiceCollection.optionWeights.total = total;

  const temp = [...componentCollection];
  setComponentCollection(temp);
  if (
    item.choiceCollection.optionWeights.total > 0 &&
    item.choiceCollection.optionWeights.weights[index] > 0
  ) {
    showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent =
      true;
    setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
  }
  const validationTemp = validationErrorTemplate;
  hasValidationErrorTemplate({ ...validationTemp });
  validateOptions(
    item,
    item.choiceCollection.choices,
    validationErrorTemplate,
    hasValidationErrorTemplate
  );
};

const onChangeOptionName = (
  event,
  item,
  i,
  componentCollection,
  setComponentCollection
) => {
  const updatedOption = event.target.value;
  const matchedPosition = componentCollection.findIndex(elem => {
    return elem.id == item.id;
  });
  componentCollection[matchedPosition].choiceCollection.choices[i] = {
    name: updatedOption,
    isChecked:
      componentCollection[matchedPosition].choiceCollection.choices[i]
        .isChecked,
  };
  if (
    componentCollection[matchedPosition].choiceCollection.selectedChoice
      .index == i
  ) {
    componentCollection[matchedPosition].choiceCollection.selectedChoice.name =
      updatedOption;
  }
  setComponentCollection([...componentCollection]);
};

const SortableItem = SortableElement(
  ({
    id,
    key,
    index,
    item,
    value,
    thresholdToggle,
    componentCollection,
    setComponentCollection,
    i,
    elem,
    validationErrorTemplate,
    hasValidationErrorTemplate,
    searchTermComponents,
    setShowPromptForAdjustScoring,
    showPromptForAdjustScoring,
  }) => {
    return (
      <li style={{ listStyleType: 'none' }}>
        <div className="singleChoices">
          <Flex flexDirection="row" otherStyles={{ flex: 1 }}>
            <Flex alignItems="center" otherStyles={{ width: '100%' }}>
              <Checkbox
                id={id}
                name={id}
                value={value}
                variant={
                  thresholdToggle
                    ? CheckboxRadio.Threshold
                    : CheckboxRadio.InlineInput
                }
                isDisabled={false}
                hasInlineInput={thresholdToggle}
                inputWidth="100%"
                valueInput={value}
                checked={
                  thresholdToggle
                    ? item.choiceCollection.choices[i].isChecked
                    : false
                }
                handleOnChange={e =>
                  thresholdToggle
                    ? handleSortable(
                        e,
                        item,
                        i,
                        componentCollection,
                        setComponentCollection
                      )
                    : ''
                }
                handleInputOnChange={event =>
                  onChangeOptionName(
                    event,
                    item,
                    i,
                    componentCollection,
                    setComponentCollection
                  )
                }
              />
            </Flex>
            {thresholdToggle && item.choiceCollection.choices[i].isChecked && (
              <div className="tag-container">
                <Tag
                  text={I18n.t('threshold.threshold')}
                  variant={TagsVariant.CONTAINED}
                  type={TagsType.SQUARED}
                  fill={ActionColors.THRESHOLD}
                />
              </div>
            )}
          </Flex>
          <div className="closeChoice">
            <IconButton
              name="close"
              size={20}
              tooltipText={I18n.t('toolTipsText.remove')}
              onClick={() => {
                removeSortables(
                  item,
                  i,
                  componentCollection,
                  setComponentCollection,
                  validationErrorTemplate,
                  hasValidationErrorTemplate,
                  searchTermComponents,
                  setShowPromptForAdjustScoring,
                  showPromptForAdjustScoring
                );
              }}
              onPointerDown={() => {
                removeSortables(
                  item,
                  i,
                  componentCollection,
                  setComponentCollection,
                  validationErrorTemplate,
                  hasValidationErrorTemplate,
                  searchTermComponents,
                  setShowPromptForAdjustScoring,
                  showPromptForAdjustScoring
                );
              }}
            />
          </div>
        </div>
      </li>
    );
  }
);

const SortableList = SortableContainer(
  ({
    item,
    index,
    componentCollection,
    setComponentCollection,
    searchTermComponents,
    setBlurr,
    validationErrorTemplate,
    hasValidationErrorTemplate,
    setShowPromptForAdjustScoring,
    showPromptForAdjustScoring,
  }) => {
    let id;
    const thresholdToggle = isThresholdSet(
      index,
      componentCollection,
      searchTermComponents
    );
    const newOptionText = I18n.t('newCareplan_view.newOption');
    return (
      <div
        className="choicesParent"
        onBlur={() =>
          addMultipleChoices(
            item,
            componentCollection,
            setComponentCollection,
            setBlurr
          )
        }
      >
        <p>
          <span className="multiselection">
            {I18n.t('newCareplan_view.multipleChoice')}
          </span>
        </p>
        <div>
          {item.choiceCollection.choices.map((elem, i) => {
            const value = `${elem.name}`;
            id = `multipleChoice_${i}_${index}`;
            return (
              <React.Fragment key={`multipleChoiceElement_${i}_${index}`}>
                <SortableItem
                  id={id}
                  key={id}
                  index={i}
                  item={item}
                  value={value}
                  thresholdToggle={thresholdToggle}
                  componentCollection={componentCollection}
                  setComponentCollection={setComponentCollection}
                  validationErrorTemplate={validationErrorTemplate}
                  hasValidationErrorTemplate={hasValidationErrorTemplate}
                  i={i}
                  elem={elem}
                  searchTermComponents={searchTermComponents}
                  setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
                  showPromptForAdjustScoring={showPromptForAdjustScoring}
                />
              </React.Fragment>
            );
          })}
        </div>
        <div className="addChoice">
          <Flex otherStyles={{ flex: 1 }}>
            <Checkbox
              id={`${id}_newMultipleChoiceOption`}
              name={item.choiceCollection.addChoice}
              value={item.choiceCollection.addChoice}
              label={newOptionText}
              placeholder={newOptionText}
              valueInput={item.choiceCollection.addChoice}
              isDisabled={false}
              variant={CheckboxRadio.InlineInput}
              checked={false}
              inputWidth="100%"
              handleInputOnChange={e =>
                handleSortableList(
                  e,
                  item,
                  componentCollection,
                  setComponentCollection
                )
              }
              handleOnChange={() => {}}
            />
          </Flex>
          <div style={{ flexBasis: 40 }} />
        </div>
      </div>
    );
  }
);

const onSortEnd = (
  oldIndex,
  newIndex,
  item,
  componentCollection,
  setComponentCollection
) => {
  item.choiceCollection.choices = arrayMoveImmutable(
    item.choiceCollection.choices,
    oldIndex,
    newIndex
  );
  item.choiceCollection.optionWeights.weights = arrayMoveImmutable(
    item.choiceCollection.optionWeights.weights,
    oldIndex,
    newIndex
  );
  setComponentCollection([...componentCollection]);
};

const renderSortable = (
  item,
  componentCollection,
  searchTermComponents,
  setComponentCollection,
  setBlurr,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring
) => {
  const index = componentCollection.findIndex(comp => comp.id === item.id);
  return (
    <SortableList
      item={item}
      index={index}
      componentCollection={componentCollection}
      setComponentCollection={setComponentCollection}
      searchTermComponents={searchTermComponents}
      setBlurr={setBlurr}
      validationErrorTemplate={validationErrorTemplate}
      hasValidationErrorTemplate={hasValidationErrorTemplate}
      setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
      showPromptForAdjustScoring={showPromptForAdjustScoring}
      onSortEnd={({ oldIndex, newIndex }) =>
        onSortEnd(
          oldIndex,
          newIndex,
          item,
          componentCollection,
          setComponentCollection
        )
      }
    />
  );
};

export default renderSortable;
