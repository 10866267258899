import React, { useCallback } from 'react';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import AnswerHeaderComponent from '../AnswerHeaderComponent';

import { GeneralAnswerType } from '../GeneralAnswer.type';
import componentTypeOrder from '../../careplanComponentTypeOrder';
import { CareplanComponentAnswer } from '../../../../types/Careplan';

import { getIsThreshold } from '../../../ReportGenerator/Careplan/CareplanReport.utils';
import { alphapet } from '../../CareplanAdditionalComponentUtils';
import { renderComponentOptions } from './SingleChoiceAnswer.service';
import {
  getComponentOccurrencesAnswer,
  getThresholdClass,
} from '../AnswerResult.services';

import '../AnswerResultCommon.scss';

function SingleChoiceAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const renderComponentAnswers = useCallback(
    (occurrenceAnswers: CareplanComponentAnswer[], index: number) => {
      let answerContent: JSX.Element | JSX.Element[] = (
        <span className="answer-component_header-columns-column-answer">-</span>
      );
      if (occurrenceAnswers.length > 0) {
        answerContent = occurrenceAnswers.map(
          (answer: CareplanComponentAnswer, indexAnswer: number) => {
            const answerOutput: { content: string; key?: string }[] = [];
            if (answer.isSkipped) {
              answerOutput.push({
                content: '-',
              });
            } else if (answer.answer_json) {
              if (answer.answer_json.optionIndex !== undefined) {
                answerOutput.push({
                  content: alphapet[answer.answer_json?.optionIndex],
                });
              } else {
                Object.keys(answer.answer_json).forEach((key: string) => {
                  answerOutput.push({
                    content: alphapet[parseInt(key)],
                    key: key,
                  });
                });
              }
            }

            return (
              <Flex
                key={`col-header-answer-${componentIndex}-${indexAnswer}`}
                justifyContent="center"
                flexWrap="wrap"
              >
                {answerOutput.map(
                  (
                    answerItem: { content: string; key?: string },
                    indexAnswerOutput: number
                  ) => {
                    const isThreshold: boolean = !answer.isSkipped && getIsThreshold(
                      componentData,
                      answer,
                      answerItem.key
                    );
                    const headerContentStyles = getThresholdClass(isThreshold);
                    return (
                      <span
                        key={`col-header-answer-item-${componentIndex}-${indexAnswer}-${indexAnswerOutput}`}
                        className={headerContentStyles}
                      >
                        {answerItem.content}
                        {isThreshold === true && <>*</>}
                      </span>
                    );
                  }
                )}
              </Flex>
            );
          }
        );
      }
      return (
        <Flex
          key={`col-header-${componentIndex}-${index}`}
          className="answer-component_header-columns-column"
          alignItems="center"
          justifyContent="center"
        >
          {answerContent}
        </Flex>
      );
    },
    []
  );

  return (
    <Box className="answer-component">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName={
          componentData.careplanComponentTypeId ===
          componentTypeOrder.SINGLE_CHOICE
            ? 'SingleChoices'
            : 'MultipleChoices'
        }
        title={componentData.name}
        columnOccurrencesRenderer={() => {
          return getComponentOccurrencesAnswer(
            allOccurrences,
            componentData.answers,
            renderComponentAnswers
          );
        }}
      />
      <Box className="answer-component_options">
        {renderComponentOptions(allOccurrences, componentData, componentIndex)}
      </Box>
    </Box>
  );
}

export default SingleChoiceAnswer;
