import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextInput from '../TextInput/TextInput';
import CheckboxRadio from '../../utils/CheckboxRadio';
import { PrimaryColor, ActionColors, Greys } from '../../utils/colors';
import '../../styles/main.scss';
import inputField from '../../utils/inputField';
import maxCharLimit from '../../utils/maxcharlimits.json';

const RadioButton = ({
  id,
  name,
  value,
  label,
  placeholder,
  isDisabled,
  variant,
  inputWidth,
  checked,
  handleOnChange,
  valueInput,
  handleInputOnChange,
  hasInlineInput,
  multiSelectEnabled,
}) => {
  /* Classes
  ========================================================================== */
  const getCircleColor = useCallback(() => {
    switch (variant) {
      case CheckboxRadio.Threshold:
        return ActionColors.THRESHOLD;
      case CheckboxRadio.NonEditable:
        return Greys.LIGHT_GREY;
      case CheckboxRadio.SuccessView:
        return ActionColors.SUCCESS;
      case CheckboxRadio.WarningView:
        return ActionColors.WARNING;
      case CheckboxRadio.Default:
      default:
        return PrimaryColor.MAIN_COLOR;
    }
  }, [variant]);

  const Circle = useCallback(() => {
    const circleColor = getCircleColor();

    if (checked && circleColor) {
      return <circle cx="12" cy="12" r="8.4" fill={circleColor} />;
    }
    return null;
  }, [checked, getCircleColor]);

  const labelStyles = classNames({
    'checkbox-radio__label': true,
    'checkbox-radio__label__non-editable':
      variant === CheckboxRadio.NonEditable,
    disabled: isDisabled,
  });

  const inputStyles = classNames({
    'checkbox-radio__input': true,
    'checkbox-radio__input__non-editable':
      variant === CheckboxRadio.NonEditable,
    disabled: isDisabled,
  });

  const svgStyles = classNames({
    'checkbox-radio__circle-svg': true,
    'checkbox-radio__circle-svg__threshold':
      variant === CheckboxRadio.Threshold,
    'checkbox-radio__circle-svg__success':
      variant === CheckboxRadio.SuccessView,
    'checkbox-radio__circle-svg__warning':
      variant === CheckboxRadio.WarningView,
    'checkbox-radio__circle-svg__inline-input':
      variant === CheckboxRadio.InlineInput,
    'checkbox-radio__circle-svg__non-editable':
      variant === CheckboxRadio.NonEditable,
  });

  return (
    <label
      className={labelStyles}
      htmlFor={id}
      style={{
        width:
          variant === CheckboxRadio.InlineInput ||
          (variant === CheckboxRadio.Threshold && hasInlineInput)
            ? '100%'
            : null,
      }}
    >
      <input
        type={!multiSelectEnabled ? 'radio' : 'checkbox'}
        name={name}
        id={id}
        value={value}
        className={inputStyles}
        disabled={variant === CheckboxRadio.InlineInput ? true : isDisabled}
        onChange={handleOnChange}
        checked={variant === CheckboxRadio.InlineInput ? false : checked}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={svgStyles}
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z"
          />
          <path
            fill={PrimaryColor.MAIN_COLOR}
            d="M12 22.8c5.965 0 10.8-4.835 10.8-10.8 0-5.965-4.835-10.8-10.8-10.8C6.035 1.2 1.2 6.035 1.2 12c0 5.965 4.835 10.8 10.8 10.8zm0 1.2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z"
          />
          <Circle />
        </g>
      </svg>
      {variant === CheckboxRadio.InlineInput || hasInlineInput ? (
        <div style={{ width: inputWidth }}>
          <TextInput
            id={id}
            name={name}
            value={valueInput}
            onChange={handleInputOnChange}
            placeholder={placeholder}
            variant={inputField.variant.CHAR_COUNT}
            maxLength={maxCharLimit.carePlan.singleChoiceMaxCharLimit}
            maxChars={maxCharLimit.carePlan.singleChoiceMaxCharLimit}
            isDisabled={isDisabled}
            autoComplete="off"
            handleOnChange={handleInputOnChange}
          />
        </div>
      ) : (
        label
      )}
    </label>
  );
};

RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  /**  Radio Button label */
  label: PropTypes.string,
  placeholder: PropTypes.string,
  /**  Disables the Radio Button */
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    CheckboxRadio.Default,
    CheckboxRadio.Threshold,
    CheckboxRadio.InlineInput,
    CheckboxRadio.NonEditable,
  ]),
  /**  It can be defined as fixed width or relative unit */
  inputWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  checked: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  valueInput: PropTypes.string,
  handleInputOnChange: PropTypes.func,
  hasInlineInput: PropTypes.bool,
  multiSelectEnabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  value: '',
  label: null,
  isDisabled: false,
  placeholder: '',
  hasInlineInput: false,
  variant: CheckboxRadio.Default,
  multiSelectEnabled: false,
};

export default RadioButton;
