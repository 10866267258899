/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  Bar,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  ErrorBar,
} from 'recharts';
import moment from 'moment';
import PropTypes from 'prop-types';

import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import CalendarSelector from '../../../caro-ui-commonfiles/components/Calendar/CalendarToolbar';
import { lifespanEnum } from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import { WearablesDataTypes } from '../../../caro-ui-commonfiles/utils/componentTypes';
import { Greys, PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';

import calendarLocale from '../../Utils/CalendarLocale';
import DateViewer from './DateViewer';

const ActivityDataChart = ({
  data,
  history,
  selector,
  selectorValue,
  onSelectorChange,
  dateRange,
  onDateChange,
  disabledFilters,
  noDataContent,
}) => {
  const setDateRangeOnViewChange = filter => {
    switch (filter) {
      case lifespanEnum.WEEK:
        return {
          start: moment()
            .subtract(7, 'days')
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
          end: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
      case lifespanEnum.MONTH:
        return {
          start: moment()
            .subtract(30, 'days')
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
          end: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
      case lifespanEnum.YEAR:
        return {
          start: moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
          end: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
      default:
        return {
          start: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          end: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
    }
  };

  const renderLine = line => {
    return (
      data.length > 0 &&
      line &&
      (line.type === WearablesDataTypes.WEIGHT ||
        line.type === WearablesDataTypes.HEIGHT ||
        line.type === WearablesDataTypes.HEART_RATE ||
        line.type === WearablesDataTypes.BLOOD_PREASURE)
    );
  };
  const renderBar = line => {
    return (
      data.length > 0 &&
      line &&
      (line.type === WearablesDataTypes.STEPS ||
        line.type === WearablesDataTypes.DISTANCE ||
        line.type === WearablesDataTypes.CALORIES ||
        line.type === WearablesDataTypes.SLEEP)
    );
  };

  const renderBloodPresureLineArea = () => {
    return (
      data.length > 0 &&
      history.line1 &&
      history.line1.type === WearablesDataTypes.BLOOD_PREASURE
    );
  };

  const renderErrorBar = line => {
    return (
      data.length > 0 && line && line.type === WearablesDataTypes.HEART_RATE
    );
  };

  const CustomDotSquad = ({ cx, cy, stroke, payload }) => {
    if (payload.line2) {
      return (
        <rect
          x={cx - 4}
          y={cy - 4}
          width="8"
          height="8"
          style={{ fill: stroke }}
        />
      );
    }
    return null;
  };

  const CustomDotSquadHover = props => {
    const { cx, cy, fill, payload } = props;
    if (payload.line2) {
      return (
        <rect
          x={cx - 6}
          y={cy - 6}
          width="12"
          height="12"
          style={{ fill, fillOpacity: 0.25 }}
        />
      );
    }
    return null;
  };

  const CustomDot = ({ cx, cy, stroke, payload }) => {
    if (payload.line1) {
      return <circle cx={cx} cy={cy} r="4" fill={stroke} />;
    }
    return null;
  };

  const CustomDotHover = ({ cx, cy, fill, payload }) => {
    if (payload.line1) {
      return (
        <circle cx={cx} cy={cy} r="6" style={{ fill, fillOpacity: 0.25 }} />
      );
    }
    return null;
  };

  const TooltipDataInfo = ({
    payload,
  }: {
    payload: {
      payload: {
        line1: string;
        line2: string;
        source: string;
        isMedical: string;
      };
    }[];
  }) => {
    return (
      <Flex>
        <Box>
          {history &&
            (history.line1 || history.line2) &&
            payload &&
            (!!payload[0].payload.line1 || !!payload[0].payload.line2) &&
            !!payload[0].payload.source && (
              <Box className="activity-data_graph-custom-tooltip_history-button">
                <Flex
                  otherStyles={{ marginLeft: 8, color: history.line1.color }}
                >
                  {`${payload[0].payload.source} - ${payload[0].payload.isMedical}`}
                </Flex>
              </Box>
            )}
        </Box>
      </Flex>
    );
  };

  const CutomTooltip = ({ active, payload }) => {
    if (!active) return null;

    return (
      <div className="activity-data_graph-custom-tooltip">
        <div className="activity-data_graph-custom-tooltip_header">
          <Box className="activity-data_graph-custom-tooltip_header_source-type">
            {payload && payload[0].payload.sourceType}
          </Box>
          {payload && payload[0].payload.xLabel}
        </div>
        <Flex flexDirection="column">
          {history && history.line1 && payload && !!payload[0].payload.line1 && (
            <div className="activity-data_graph-custom-tooltip_history-button">
              <Flex
                otherStyles={{
                  background: history.line1.color,
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                }}
              />
              <Flex otherStyles={{ marginLeft: 8, color: history.line1.color }}>
                {`${
                  history.line1.bpText
                    ? history.line1.bpText
                    : history.line1.text
                }: ${payload[0].payload.line1}`}
              </Flex>
            </div>
          )}

          {history && history.line2 && payload && !!payload[0].payload.line2 && (
            <div className="activity-data_graph-custom-tooltip_history-button">
              <Flex
                otherStyles={{
                  background: history.line2.color,
                  width: 12,
                  height: 12,
                }}
              />
              <Flex otherStyles={{ marginLeft: 8, color: history.line2.color }}>
                {`${history.line2.text}: ${payload[0].payload.line2}`}
              </Flex>
            </div>
          )}
        </Flex>
        {/* <TooltipDataInfo payload={payload} /> // needs to be removed in next released in tooltip redesigned */}
      </div>
    );
  };

  const NoDataComponent = ({ title, content }) => {
    const { t } = useTranslation();
    return (
      <Flex otherStyles={{ width: '100%', padding: '4px 96px 36px 96px' }}>
        <Box className="activity-data_no-data">
          <Flex flexDirection="column" otherStyles={{ width: 400 }}>
            <Flex
              otherStyles={{
                color: Greys.DARK_GREY,
                fontSize: 24,
                fontWeight: 'bold',
              }}
              justifyContent="center"
            >
              {t(title)}
            </Flex>
            <Flex
              otherStyles={{
                color: Greys.LIGHT_GREY_50,
                fontSize: 14,
                marginTop: 16,
                textAlign: 'center',
              }}
            >
              {t(content)}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        otherStyles={{
          padding:
            history.line2 || data.length === 0
              ? '0 76px 16px'
              : '0 16px 16px 76px',
          marginBottom: 8,
        }}
      >
        <Flex otherStyles={{ flex: 1 }}>
          {history && history.line1 && (
            <div className="activity-data_history-button">
              <Flex
                otherStyles={{
                  background: history.line1.color,
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                }}
              />
              <Box margin="0 0 0 8px">
                {history.line1.bpText
                  ? history.line1.bpText
                  : history.line1.text}
              </Box>
            </div>
          )}
          {history && history.line2 && (
            <div className="activity-data_history-button">
              <Flex
                otherStyles={{
                  background: history.line2.color,
                  width: 12,
                  height: 12,
                  marginLeft: 16,
                }}
              />

              <Box margin="0 0 0 8px">{history.line2.text}</Box>
            </div>
          )}
        </Flex>
        {selector && (
          <Flex justifyContent="center" otherStyles={{ flex: 1 }}>
            <DateViewer
              date={dateRange}
              onChange={onDateChange}
              filter={selectorValue}
            />
          </Flex>
        )}
        <Flex otherStyles={{ flex: 1, justifyContent: 'flex-end' }}>
          {selector && (
            <CalendarSelector
              view={selectorValue}
              filterAction={false}
              onView={filter => {
                if (selector) {
                  const filterObject = {
                    filter,
                    date: setDateRangeOnViewChange(filter),
                  };
                  onSelectorChange(filterObject);
                }
              }}
              locale={calendarLocale()}
              selectors={[
                lifespanEnum.DAY,
                lifespanEnum.WEEK,
                lifespanEnum.MONTH,
                lifespanEnum.YEAR,
              ]}
              disabled={disabledFilters}
            />
          )}
        </Flex>
      </Flex>

      <Flex justifyContent="center" otherStyles={{ height: 300 }}>
        {!data.length > 0 && noDataContent && (
          <NoDataComponent
            title={noDataContent.title}
            content={noDataContent.content}
          />
        )}
        {data.length > 0 && (
          <ResponsiveContainer id="wearables-graph-card" width="98%">
            <ComposedChart data={data} barGap={15} barSize={24}>
              <YAxis
                yAxisId="left"
                label={{
                  value:
                    history.line1 && history.line1.text
                      ? history.line1.text
                      : '',
                  angle: -90,
                  position: 'insideLeft',
                  style: { fontSize: 12, textAnchor: 'middle' },
                }}
                tick={{ fontSize: 12 }}
              />
              {history.line2 &&
                history.line1 &&
                history.line1.type !== WearablesDataTypes.BLOOD_PREASURE && (
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    label={{
                      value:
                        history.line2 && history.line2.text
                          ? history.line2.text
                          : '',
                      angle: 90,
                      position: 'insideRight',
                      style: { fontSize: 12, textAnchor: 'middle' },
                    }}
                    tick={{ fontSize: 12 }}
                  />
                )}
              <XAxis
                dataKey="xLabel"
                tick={{ fontSize: 12 }}
                interval="preserveStartEnd"
                stroke={Greys.DARK_GREY}
              />
              <CartesianGrid stroke={Greys.LIGHT_GREY_20} strokeWidth={0.5} />
              <Tooltip
                cursor={
                  data.length > 0
                    ? {
                        stroke: Greys.LIGHT_GREY_50,
                        strokeWidth: 1,
                        strokeDasharray: '3 4',
                      }
                    : false
                }
                content={<CutomTooltip />}
                filterNull={false}
              />
              {renderBloodPresureLineArea() && (
                <Area
                  dataKey="area"
                  stroke="none"
                  fill={PrimaryColor.MAIN_COLOR_LIGHT}
                  dot={false}
                  activeDot={false}
                  fillOpacity={0.7}
                  yAxisId="left"
                  name={false}
                />
              )}
              {history.line1 && renderBar(history.line1) && (
                <Bar
                  yAxisId="left"
                  dataKey="line1"
                  minPointSize={5}
                  fill={
                    history.line1 && history.line1.color
                      ? history.line1.color
                      : ''
                  }
                />
              )}
              {history.line2 && renderBar(history.line2) && (
                <Bar
                  yAxisId="right"
                  dataKey="line2"
                  minPointSize={5}
                  fill={
                    history.line2 && history.line2.color
                      ? history.line2.color
                      : ''
                  }
                />
              )}
              {history.line1 && renderLine(history.line1) && (
                <Line
                  yAxisId="left"
                  dataKey="line1"
                  stroke={history.line1.color ? history.line1.color : ''}
                  strokeWidth={2}
                  dot={<CustomDot />}
                  activeDot={<CustomDotHover />}
                >
                  {history.line1 && renderErrorBar(history.line1) && (
                    <ErrorBar
                      dataKey="error"
                      width={4}
                      strokeWidth={2}
                      stroke={history.line1.color}
                      direction="y"
                    />
                  )}
                </Line>
              )}

              {history.line2 && renderLine(history.line2) && (
                <Line
                  yAxisId={
                    history.line2 &&
                    history.line2.type === WearablesDataTypes.BLOOD_PREASURE
                      ? 'left'
                      : 'right'
                  }
                  dataKey="line2"
                  stroke={
                    history.line2 && history.line2.color
                      ? history.line2.color
                      : ''
                  }
                  strokeWidth={2}
                  dot={<CustomDotSquad />}
                  activeDot={<CustomDotSquadHover />}
                >
                  {history.line2 && renderErrorBar(history.line2) && (
                    <ErrorBar
                      dataKey="error"
                      width={4}
                      strokeWidth={1}
                      stroke={history.line2.color}
                      direction="y"
                    />
                  )}
                </Line>
              )}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Flex>
    </>
  );
};

ActivityDataChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.objectOf(PropTypes.string).isRequired,
  selector: PropTypes.bool,
  selectorValue: PropTypes.string,
  onSelectorChange: PropTypes.func,
  dateRange: PropTypes.objectOf(PropTypes.string),
  onDateChange: PropTypes.func,
  disabledFilters: PropTypes.arrayOf(PropTypes.string),
};

export default ActivityDataChart;
