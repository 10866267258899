const CaretaskDefaultValidationErrorTemplate = {
  templateSettingsValidation: {
    noTemplateOrganizations: false,
    isComponentCollectionEmpty: false,
    templateNameError: false,
    noComponentsError: {},
    componentSettingsError: [],
    twoOptionsRequiredError: {},
    emptyOptionError: {},
    itemsWithDuplicateOptionsError: [],
    evaluationLineErrorForSortable: [],
    scoringSchemeError: false,
    noTemplateType: false,
    equationError: false,
    additionalComponentsWithComplexScoreError: false,
    healthIndicatorInactiveWithTotalScoreError: false,
  },
  logicJumpCollectionError: {},
  logicJumpContradictionError: {},
  itemsWithJumpExceedingLimitError: {},
};

export default CaretaskDefaultValidationErrorTemplate;
