import React, { useState, useEffect } from 'react';
import I18n from 'i18next';

import Dropdown from '../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import NumberInput from '../../../../caro-ui-commonfiles/components/NumberInput/NumberInput';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';

import {
  setPrefilledThresholdFromDropdown,
  handleDropDownState,
} from '../CareplanHelperUtility';

import { nonMedical } from '../../../../config';

import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';
import { setThresholdValueForComponent } from './thresholdHelper';

const periodOfTimeOptions = () => {
  return [
    {
      value: 'more',
      label: I18n.t('common_labels.label_more'),
      key: 'common_labels.label_more',
    },
    {
      value: 'less',
      label: I18n.t('common_labels.label_less'),
      key: 'common_labels.label_less',
    },
  ];
};

const timeUnitOptions = () => {
  return [
    { value: 'day(s)', label: I18n.t('lifespan.DAY'), key: 'lifespan.DAY' },
    { value: 'week(s)', label: I18n.t('lifespan.WEEK'), key: 'lifespan.WEEK' },
    {
      value: 'month(s)',
      label: I18n.t('lifespan.MONTH'),
      key: 'lifespan.MONTH',
    },
    { value: 'year(s)', label: I18n.t('lifespan.YEAR'), key: 'lifespan.YEAR' },
  ];
};

const isThresholdSet = (index, componentCollection) => {
  if (componentCollection[index]) {
    const shouldToggle =
      componentCollection[index].searchTerm.careplanComponentId &&
      (nonMedical == false || nonMedical == 'false')
        ? componentCollection[index].searchTerm.shouldThresholdToggle
        : componentCollection[index].shouldThresholdToggle;
    return shouldToggle;
  }
};

const DatepickerNumberInput = ({
  thresholdDropdown,
  thresholdNumber,
  setThresholdNumber,
  index,
  setValueForComponent,
  componentCollection,
}) => {
  const [itemCollection, setItemCollection] = useState([
    { name: thresholdDropdown, value: thresholdNumber },
  ]);

  const [itemThreshold] = itemCollection;

  useEffect(() => {
    const { thresholds } = componentCollection[index].thresholdCollection;
    const thresholdCollectionObject =
      thresholds.length > 0
        ? thresholds
        : [{ name: thresholdDropdown, value: 1 }];
    setItemCollection(thresholdCollectionObject);
  }, [componentCollection, index, thresholdDropdown, thresholdNumber]);

  return (
    <NumberInput
      value={itemThreshold.value}
      id="date-request_threshold"
      name="date-request_threshold"
      min={1}
      max={100}
      step={1}
      placeholder="0"
      isFloatingNumber={false}
      isNegativeNumber={false}
      changeValue={value => {
        const dropdowninputvalue = [...itemCollection];
        dropdowninputvalue[0].value = value;
        setThresholdNumber(value);
        setValueForComponent('thresholdCollection', index, dropdowninputvalue);
      }}
    />
  );
};

const DatepickerWithThreshold = ({
  item,
  index,
  componentCollection,
  setComponentCollection,
  thresholdDropdown,
  setThresholdDropdown,
  thresholdNumber,
  setThresholdNumber,
}) => {
  const thresholdToggle = isThresholdSet(index, componentCollection);

  if (thresholdToggle && item.thresholdCollection.thresholds.length === 0) {
    const obj = [
      {
        name: {
          value: 'more',
          label: I18n.t('common_labels.label_more'),
          key: 'common_labels.label_more',
        },
        value: 1,
      },
      {
        name: {
          value: 'day(s)',
          label: I18n.t('lifespan.DAY'),
          key: 'lifespan.DAY',
        },
        value: 1,
      },
    ];
    item.thresholdCollection.thresholds.push(...obj);
    setComponentCollection([...componentCollection]);
  }

  const setValueForComponent = (key, optionIndex, value) => {
    const updatedComponentCollection = setThresholdValueForComponent(key,index, optionIndex, value, componentCollection)
    setComponentCollection(updatedComponentCollection);
  };

  return (
    <>
      {thresholdToggle && (
        <>
          <p className="templateRequired" style={{ margin: 0 }}>
            {I18n.t('newCareplan_view.defineThresholdDatePicker')}
          </p>

          <Flex alignItems="start">
            <Box flex={0} margin="15px 15px 0 0">
              <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
            </Box>

            <Flex alignItems="center" flexWrap="wrap">
              <p
                className="link"
                style={{ margin: 0, marginTop: 8, marginRight: 8 }}
              >
                {I18n.t('newCareplan_view.thresholdDatePickerPart1')}
              </p>

              <Flex alignItems="center" otherStyles={{ marginTop: 8 }}>
                <Box margin="0 8px 0 0" width={90}>
                  <Dropdown
                    value={setPrefilledThresholdFromDropdown(
                      item.thresholdCollection.thresholds[0] === undefined
                        ? {
                            name: {
                            value: 'more',
                            key: 'common_labels.label_more',
                            },
                        }
                        : item.thresholdCollection.thresholds[0]
                    )}
                    options={periodOfTimeOptions()}
                    placeholder=""
                    handleOnChange={state => {
                      handleDropDownState(
                        item,
                        0,
                        state,
                        setThresholdDropdown,
                        componentCollection,
                        setComponentCollection
                      );
                    }}
                    fontSize={14}
                    heightControl={36}
                  />
                </Box>

                <p className="link" style={{ margin: 0, marginTop: 8 }}>
                  {I18n.t('threshold.than')}
                </p>

                <Box margin="0 8px" width={90}>
                  <DatepickerNumberInput
                    thresholdDropdown={thresholdDropdown}
                    thresholdNumber={thresholdNumber}
                    setThresholdNumber={setThresholdNumber}
                    index={index}
                    setValueForComponent={setValueForComponent}
                    componentCollection={componentCollection}
                  />
                </Box>

                <Box margin="0 8px" width={90}>
                  <Dropdown
                    value={setPrefilledThresholdFromDropdown(
                      item.thresholdCollection.thresholds[1] === undefined
                        ? {
                            name: {
                              value: 'day(s)',
                              key: 'lifespan.DAY',
                            },
                        }
                        : item.thresholdCollection.thresholds[1]
                    )}
                    options={timeUnitOptions()}
                    placeholder=""
                    handleOnChange={state => {
                      handleDropDownState(
                        item,
                        1,
                        state,
                        setThresholdDropdown,
                        componentCollection,
                        setComponentCollection
                      );
                    }}
                    fontSize={14}
                    heightControl={36}
                  />
                </Box>
              </Flex>

              <p className="link" style={{ margin: 0, marginTop: 8 }}>
                {I18n.t('newCareplan_view.thresholdDatePickerPart2')}
              </p>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

const renderDatepicker = (
  item,
  componentCollection,
  setComponentCollection,
  thresholdDropdown,
  setThresholdDropdown,
  thresholdNumber,
  setThresholdNumber
) => {
  const index = componentCollection.findIndex(comp => comp.id === item.id);
  return (
    <>
      <DatepickerWithThreshold
        item={item}
        index={index}
        componentCollection={componentCollection}
        setComponentCollection={setComponentCollection}
        thresholdDropdown={thresholdDropdown}
        setThresholdDropdown={setThresholdDropdown}
        thresholdNumber={thresholdNumber}
        setThresholdNumber={setThresholdNumber}
      />
    </>
  );
};

export default renderDatepicker;
