import React from 'react';
import I18n from 'i18next';
import Icon from '../../caro-ui-commonfiles/components/Icon/Icon';
import Flex from '../../caro-ui-commonfiles/components/Flex/Flex';

const ScrollDownComponent = () => (
  <Flex>
    <Flex
      otherStyles={{
        marginRight: '10px',
        marginTop: '5px',
      }}
    >
      <Icon name="info" size={24} />
    </Flex>

    <Flex
      otherStyles={{
        marginRight: '20px',
        marginTop: '5px',
      }}
    >
      <span className="scroll-down-txt">
        {I18n.t('newCareplan_view.scroll-down__txt')}
      </span>
    </Flex>
  </Flex>
);

export default ScrollDownComponent;
