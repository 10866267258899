import React from 'react';
import PropTypes from 'prop-types';

import { MODAL_FORM_DIRECTION } from '../../../../Utils/Layout/modalSection';

const ModalForm = ({
  formId,
  handleFormSubmit,
  children,
  direction,
  styles,
}) => {
  const Component = formId ? 'form' : 'div';
  const componentType = Component === 'form' ? handleFormSubmit : undefined;

  return (
    <Component
      id={formId}
      onSubmit={componentType}
      className={
        direction === MODAL_FORM_DIRECTION.ROW
          ? 'section-wrapper__row-container'
          : 'section-wrapper__column-container'
      }
      style={styles}
    >
      {children}
    </Component>
  );
};

ModalForm.propTypes = {
  children: PropTypes.node.isRequired,
  formId: PropTypes.string,
  handleFormSubmit: PropTypes.func,
  direction: PropTypes.oneOf([
    MODAL_FORM_DIRECTION.ROW,
    MODAL_FORM_DIRECTION.COLUMN,
  ]),
};

ModalForm.defaultProps = {
  direction: MODAL_FORM_DIRECTION.ROW,
  formId: null,
  handleFormSubmit: () => {},
};

export default ModalForm;
