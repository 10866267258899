/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import I18n from 'i18next';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { MaxCharLimits } from '@ui-common-files/utils';
import { HideFlashMessage } from '@global/Services';

import Input from '../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import inputField from '../../../../caro-ui-commonfiles/utils/inputField';
import serviceConfig from '../../../../serviceConfig.json';
import CareplanIndexTable from '../../Datatables/CareplanIndex';
import { prepareRowsForDatatables } from '../CareplanHelperUtility';
import { PromptContent, PromptFooter } from '../../common/ConfirmationPrompt';
import Modal from '../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import { calcCurrentPage } from '../../../Utils/DataTableUtilities';
import {
  onSearchAction,
  onTableChangeAction,
} from '../../../../actions/Datatables/templates';
import useAnalytics from '../../../Global/Services/Analytics/UseAnalytics';
import '../../../css/qrcode.css';

const CareplanAssessment = ({
  onEdit,
  onAssign,
  onExport,
  onShow,
  setFlashMessage,
  setServices,
  reloadTable,
  setReloadTable,
  setShowQrCodePanel,
  borderId,
  setBorderId,
}) => {
  const query = useSelector(state => state.templates);
  const reduxDispatcher = useDispatch();
  const [deleteOperation, setDeleteOperation] = useState({
    display: false,
    id: 0,
    title: '',
  });
  const { trackEventTrigger } = useAnalytics();
  const [careplanTemplatesData, setCareplanTemplatesData] = useState([]);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [responsive, setResponsive] = useState(false);
  const [typingTimeOut, setTypingTimeOut] = useState(0);

  const fetchCareplanAssessments = async () => {
    try {
      setLoading(true);
      const queryData = {
        ...query,
      };
      const searchTermLength = query.value.length;
      if (searchTermLength > 1 || searchTermLength === 0) {
        return await axios
          .get(
            serviceConfig.brokerService.getAllCareplanTemplatesForDatatable.uri,
            {
              params: {
                queryData,
              },
              timeout: 30000,
            }
          )
          .then(function (result) {
            setServices(result.data.services);
            setLoading(false);
            setCareplanTemplatesData(
              prepareRowsForDatatables(result.data.data)
            );
            setRecordsTotal(result.data.recordsTotal);
          });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  const onQrCode = async (templateId, templateTitle, templateUUID) => {
    setBorderId(templateId);
    setShowQrCodePanel({
      id: templateId,
      name: templateTitle,
      templateUUID,
      shouldShow: true,
    });
    trackEventTrigger({
      category: 'caretask',
      action: 'Open-QrCode-Modal',
    });
  };

  const getTemplateCareplans = async id => {
    return await axios
      .get(serviceConfig.brokerService.getTemplateCareplans.uri, {
        params: { userLanguage: I18n.language, templateId: id },
      })
      .then(response => {
        return response.data.count;
      })
      .catch(error => {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });
  };

  const onDelete = async (templateId, templateTitle) => {
    trackEventTrigger({
      category: 'caretask',
      action: 'Open-Delete-Modal',
    });
    const careplansCount = await getTemplateCareplans(templateId);
    if (careplansCount == 0) {
      setDeleteOperation({
        display: true,
        id: templateId,
        title: `${I18n.t(
          'common_labels.msg_confirm_template_delete_headline'
        )}: ${templateTitle}`,
      });
    } else if (careplansCount !== undefined) {
      trackEventTrigger({
        category: 'caretask',
        action: 'Delete-Failed',
      });
      setFlashMessage({
        type: 'warning',
        content: I18n.t('warningMessage.msg_template_associated_careplan'),
      });
    }
  };

  const deleteCareplan = async id => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.deleteCareplanTemplate.uri + id
      );
      if (response) {
        const page = calcCurrentPage(
          recordsTotal - 1,
          query.length,
          query.currentPage
        );
        if (page !== query.currentPage) {
          reduxDispatcher(
            onTableChangeAction({
              dir: query.dir,
              column: query.column,
              currentPage: page,
              length: query.length,
            })
          );
        } else {
          setReloadTable(!reloadTable);
        }

        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
        setDeleteOperation({
          display: false,
          id: 0,
          title: '',
        });
        trackEventTrigger({
          category: 'caretask',
          action: 'Delete-Successful',
        });
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      trackEventTrigger({
        category: 'caretask',
        action: 'Delete-Failed',
      });
      throw new Error(error.response);
    }
  };
  const screenSize = useCallback(() => {
    const isResponsive = window.innerWidth <= 1366;
    setResponsive(isResponsive);
  }, [responsive]);

  useEffect(() => {
    screenSize();
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  useEffect(() => {
    if (typingTimeOut > 0) clearTimeout(typingTimeOut);
    if (query.value.length === 0) {
      fetchCareplanAssessments();
    } else {
      const timeOut = setTimeout(() => {
        fetchCareplanAssessments();
      }, 1000);
      setTypingTimeOut(timeOut);
    }

    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [query, reloadTable]);

  const closeMessagePrompt = () => {
    trackEventTrigger({
      category: 'caretask',
      action: 'Close-Delete-Modal',
    });
    setDeleteOperation({
      display: false,
      id: 0,
      title: '',
    });
  };

  return (
    <>
      <div style={{ width: 250, position: 'absolute', top: 0, right: 0 }}>
        <Input
          id="careplanTemplateSearch"
          placeholder={I18n.t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={query.value}
          handleOnChange={e => {
            const searchText = e.target.value;
            reduxDispatcher(onSearchAction(searchText));
          }}
        />
      </div>
      <CareplanIndexTable
        fetchedData={careplanTemplatesData}
        loading={loading}
        onChange={(page, length) => {
          const newPage =
            recordsTotal > 0
              ? calcCurrentPage(recordsTotal, length, page)
              : page;
          reduxDispatcher(
            onTableChangeAction({
              dir: query.dir,
              column: query.column,
              currentPage: newPage,
              length,
            })
          );
        }}
        currentPage={query.currentPage}
        recordsTotal={careplanTemplatesData ? recordsTotal : 0}
        pages={
          careplanTemplatesData ? Math.ceil(recordsTotal / query.length) : 1
        }
        sort={{
          dir: query.dir,
          column: query.column,
        }}
        setSort={({ dir, column }) => {
          reduxDispatcher(
            onTableChangeAction({
              currentPage: query.currentPage,
              length: query.length,
              dir,
              column,
            })
          );
        }}
        length={query.length}
        onEdit={onEdit}
        onDelete={onDelete}
        onAssign={onAssign}
        onExport={onExport}
        onShow={onShow}
        setFlashMessage={setFlashMessage}
        onQrCode={onQrCode}
        borderId={borderId}
      />
      {deleteOperation.display && (
        <Modal
          isConfirmationDialog
          title={deleteOperation.title}
          contentComponent={
            <PromptContent
              message={I18n.t('common_labels.msg_confirm_template_delete')}
            />
          }
          footerComponent={
            <PromptFooter
              close={closeMessagePrompt}
              confirmHandler={() => {
                if (deleteOperation.id > 0) {
                  deleteCareplan(deleteOperation.id);
                }
              }}
            />
          }
          openModal={deleteOperation.display}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </>
  );
};
export default CareplanAssessment;
