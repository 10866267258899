import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import reportAPI from '../axios';
import serviceConfig from '../../../../serviceConfig.json';
import type { MedicationPlanReport } from './MedicationPlanReport.type';

const useMedicationPlanReportTemplateService = (
  reportId: string,
  authenticationToken: string
) => {
  const [reportData, setReportData] = useState<
    Partial<MedicationPlanReport['medicationPlan']>
  >({});
  const [isReportRendered, setIsReportRendered] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { i18n } = useTranslation();

  const initializeTemplate = async () => {
    const axiosInstance = reportAPI(authenticationToken);
    const { data } = await axiosInstance({
      method: 'GET',
      url: serviceConfig.brokerService.getOnTheFlyBMPData.uri,
      params: {
        hash: reportId,
      },
    });
    const { success, medicationPlan, message, reportLanguage } = data;
    if (!success) {
      setErrorMessage(message || 'Report not generated');
      return;
    }

    setReportData(medicationPlan);

    if (reportLanguage) {
      i18n.changeLanguage(reportLanguage);
    }
    setIsReportRendered(true);
  };

  useEffect(() => {
    initializeTemplate();
  }, []);

  return {
    reportData,
    isReportRendered,
    errorMessage,
  };
};

export default useMedicationPlanReportTemplateService;
