/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import { ActionColors } from '../../utils/colors';
import ModalSize from './ModalSize';
import { updateModalStatus } from '../FlashMessage/FlashMessages.reducer';
import { DeleteAllModalMessages } from '../../../src/Global/Services/FlashMessage/HideFlashMessage';

import FlashMessagesWrapper from '../FlashMessage/FlashMessagesWrapper';
import { ModalProps } from './ModalLayout.type';

function Modal({
  isExport = false,
  isConfirmationDialog = false,
  title,
  contentComponent = null,
  headerComponent = null,
  footerComponent = null,
  modalHeightSize = ModalSize.Default,
  modalWidthSize = ModalSize.Default,
  openModal,
  onClose,
  closePressed = () => {},
  hideFlashMessage,
}: ModalProps) {
  const [responsive, setResponsive] = useState(false);
  const screenSize = useCallback(() => {
    window.innerWidth <= 1024 ? setResponsive(true) : setResponsive(false);
  }, [responsive]);
  const dispatch = useDispatch();

  useEffect(() => {
    screenSize();
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  const onOpenPopup = () => {
    dispatch(updateModalStatus(true));
  };

  const onClosePopup = () => {
    onClose();
    DeleteAllModalMessages();
    dispatch(updateModalStatus(false));
  };

  const layoutClasses = classNames({
    'modal-message__layout': isConfirmationDialog,
    modal__layout: !isConfirmationDialog,
    modal__layout__default__height:
      !isConfirmationDialog && modalHeightSize === ModalSize.Default,
    modal__layout__large__height:
      !isConfirmationDialog && modalHeightSize === ModalSize.Large,
  });

  let maxWidth = '1024px';
  if (modalWidthSize === ModalSize.Large) {
    maxWidth = '96vw';
  } else if (isConfirmationDialog) {
    maxWidth = '700px';
  }

  return (
    <Popup
      contentStyle={{
        maxWidth,
        width: isConfirmationDialog ? '700px' : '100vw',
        border: 'none',
        margin: responsive && !isConfirmationDialog ? 0 : 'auto',
        borderRadius: 2,
        padding: 0,
      }}
      modal
      open={openModal}
      onOpen={onOpenPopup}
      onClose={onClosePopup}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      {close => (
        <>
          <div className="modal__close">
            <button
              onClick={() => {
                close();
                closePressed();
              }}
              type="button"
            >
              <Icon name="close" size={24} />
            </button>
          </div>
          <div className={layoutClasses}>
            <div
              className="modal__title"
              style={{ paddingBottom: headerComponent && 0 }}
            >
              <span />
              {isExport && (
                <Icon
                  name="warning"
                  size={39}
                  fill={ActionColors.ERROR}
                  style={{ 'padding-right': 8 }}
                />
              )}{' '}
              <h4 className="modal__title__text">{title}</h4>
            </div>
            {headerComponent && (
              <div className="modal__header">{headerComponent}</div>
            )}
            <FlashMessagesWrapper
              hideFlashMessage={hideFlashMessage}
              isModalMessage
            />
            {contentComponent}
            {footerComponent && (
              <div
                className={
                  isConfirmationDialog
                    ? 'modal-message__footer'
                    : 'modal__footer'
                }
              >
                {footerComponent}
              </div>
            )}
          </div>
        </>
      )}
    </Popup>
  );
}

export default Modal;
