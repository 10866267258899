import React from "react";
import ReactDOM from "react-dom";

import Box from "../../Box/Box";
import IconButton from "../../IconButton/IconButton";

import { SectionalModalProps } from "./SectionalModal.type";

import "./SectionalModal.styles.scss";

const SectionalModal: React.FC<SectionalModalProps> = ({
  title,
  wizard,
  isOpen,
  footer,
  onClose,
  children,
  sectionId
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Box className="sectional-modal">
      <Box className="sectional-modal-overlay">
        <Box className="sectional-modal-container">
          <Box className="sectional-modal-container-header modal__header">
            {title}
            {wizard && (
              <Box className="sectional-modal-container-header-wizard">
                {wizard}
              </Box>
            )}
            <Box className="sectional-modal-container-header-close">
              <IconButton name="clear" onClick={onClose} />
            </Box>
          </Box>
          <Box className="sectional-modal-container-content">
            <Box className="sectional-modal-container-content-inner">
              {children}
            </Box>
          </Box>
          {footer && (
            <Box className="sectional-modal-container-footer">{footer}</Box>
          )}
        </Box>
      </Box>
    </Box>,
    sectionId ? document.getElementById(sectionId) : document.body
  );
};

export default SectionalModal;
