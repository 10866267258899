import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@ui-common-files/components';
import { selectTodos } from '../Notifications/Notification.reducer';
import { NotificationReducer } from '../Notifications/Notification.type';
import { TodoNotificationIcon } from '../AppLayout/TopBarWrapper/TopBarIcon/Icons';

const TodoTopBarIconButton = ({
  onTodosNotificationIconClick,
}: {
  onTodosNotificationIconClick: (
    e: React.MouseEvent<HTMLButtonElement>
  ) => void;
}) => {
  const todo: NotificationReducer['todo'] = useSelector(selectTodos);
  const todoCounter = todo.unseenNotesCount + todo.count;
  return (
    <Box className="top-bar__menu-item" otherStyles={{ marginTop: '5px' }}>
      <button
        className="top-bar__menu-button"
        onClick={e => onTodosNotificationIconClick(e)}
      >
        <TodoNotificationIcon />
      </button>
      {todoCounter > 0 && <Box className="threshold-number">{todoCounter}</Box>}
    </Box>
  );
};

export default TodoTopBarIconButton;
