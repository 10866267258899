import React, { Children } from 'react';
import PropTypes from 'prop-types';

import Step from './Step';
import StepSeparator from './StepSeparator';

const Stepper = ({ children, currentStep }) => {
  let steps = Children.toArray(children);

  steps = steps.map((step, index) => {
    let checkCurrent = false;

    if (index <= currentStep - 1) {
      checkCurrent = true;
    }

    return (
      <Step
        key={`step__${index}`}
        number={index + 1}
        isCurrent={checkCurrent}
        {...step.props}
      />
    );
  });

  const stepsWithSeparator = [];

  steps.forEach((step, index) => {
    const notFirst = index !== 0;
    if (notFirst) {
      stepsWithSeparator.push(
        <StepSeparator key={`step__separator${index}`} {...step.props} />
      );
    }
    stepsWithSeparator.push(step);
  });

  const content = steps[currentStep - 1].props.children;

  return (
    <>
      <ol className="stepper">{stepsWithSeparator}</ol>
      {content}
    </>
  );
};

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default Stepper;
