/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext, useMemo } from 'react';
import moment from 'moment';
import I18n from 'i18next';
import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import RadioButton from '../../../caro-ui-commonfiles/components/RadioButton/RadioButton';
import TagsCell from './CellElements/TagsCell';

import CheckboxRadio from '../../../caro-ui-commonfiles/utils/CheckboxRadio';
import { CreateAppointmentContext } from '../Appointments/AppointmentManager/CreateAppointmentModal';
import { convertLinkToBlob } from '../../Utils/Asset/assetHelper';
import Animation from './CellElements/Animationcomponent';

const PatientsAppointments = ({ data, loading, onChange, page }) => {
  const [dataInfo, setDataInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [hidePatientDemographics, setHidePatientDemographics] = useState(false);
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'medicalRecord',
  });
  const { existingPatient, setExistingPatient } = useContext(
    CreateAppointmentContext
  );
  const items = [
    { key: '10', value: '10' },
    { key: '25', value: '25' },
    { key: '50', value: '50' },
    { key: '100', value: '100' },
  ];
  const tableProps = {
    defaultSorted: [
      {
        id: 'medicalRecord',
        desc: false,
      },
    ],
    onSortedChange: (newSorted, column, shiftKey) => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
  };

  useEffect(() => {
    const fetchData = () => {
      if (data) {
        const patients = data.data;
        const showDemographics = data.isPatientDemographicData;
        setHidePatientDemographics(showDemographics);
        const patientResult = patients.map(function (patient) {
          return {
            id: patient.id,
            medicalRecord: patient.medicalRecord,
            pseudonymCode: patient.pseudonymCode,
            profilePicture: patient.profilePicture,
            firstname: !showDemographics ? patient.firstname : '',
            lastname: !showDemographics ? patient.lastname : '',
            birthdate: !showDemographics
              ? patient.birthdate
                ? moment(patient.birthdate)
                    .locale(I18n.language)
                    .format('DD MMM YYYY')
                : ''
              : `${patient.age} ${I18n.t('patients_view.years')}`,
            site: patient.institutions,
            isBirthdayHardcoded: patient.isBirthdayHardcoded,
          };
        });
        return patientResult;
      }
      return [];
    };
    setDataInfo([]);
    const result = fetchData();
    setDataInfo(result);
  }, [data]);

  useEffect(() => {
    onChange(currentPage, pageSize, sort);
  }, [currentPage, pageSize, sort]);

  const ProfileCell = ({ profilePicture, index }) => {
    const [avatar, setAvatar] = useState('');
    useEffect(() => {
      const setProfile = async () => {
        if (profilePicture.directLink) {
          await convertLinkToBlob(profilePicture.directLink)
            .then(blob => {
              setAvatar(blob);
            })
            .catch(() => {
              setAvatar(profilePicture.directLink);
            });
        } else {
          setAvatar(`data:image;base64,${profilePicture}`);
        }
      };
      setProfile();
    }, []);

    return (
      <Animation isOn={true} index={index}>
        <Box width="40px" height="40px">
          <div
            style={{
              backgroundImage: `url("${avatar}")`,
            }}
            className="notification__table-profile-pic"
          />
        </Box>
      </Animation>
    );
  };

  let columns = [
    {
      Cell: props => {
        const checked =
          existingPatient.id && props.original.id === existingPatient.id;
        return (
          <Box>
            <RadioButton
              id={props.original.id}
              name={props.original.id}
              variant={CheckboxRadio.Default}
              checked={checked}
              handleOnChange={e => {
                setExistingPatient(props.original);
              }}
            />
          </Box>
        );
      },
      Header: '',
      accessor: 'patientRadio',
      sortable: false,
      filterable: false,
      width: hidePatientDemographics ? 60 : 40,
    },
    {
      Header: props => {
        return (
          <>
            {sort.column === props.column.id && (
              <Box width={20}>
                <Icon
                  name="sortArrow"
                  size={22}
                  className={
                    sort.column === props.column.id && sort.dir === 'desc'
                      ? 'desc-sort'
                      : ''
                  }
                />
              </Box>
            )}
            <span>{I18n.t('patients_view.label_medicalRecord')}</span>
          </>
        );
      },
      headerClassName: 'data-table__column-1__header',
      className: 'data-table__column-1__body',
      accessor: 'medicalRecord',
      width: hidePatientDemographics ? 250 : 160,
      maxWidth: hidePatientDemographics ? 250 : 160,
    },
  ];
  if (hidePatientDemographics) {
    columns.push({
      Header: props => {
        return (
          <>
            {sort.column === props.column.id && (
              <Box width={20}>
                <Icon
                  name="sortArrow"
                  size={22}
                  className={
                    sort.column === props.column.id && sort.dir === 'desc'
                      ? 'desc-sort'
                      : ''
                  }
                />
              </Box>
            )}
            <span>{I18n.t('patients_view.label_pseudonymCode')}</span>
          </>
        );
      },
      headerClassName: 'data-table__column-1__header',
      accessor: 'pseudonymCode',
      filterable: false,
      minWidth: hidePatientDemographics ? 150 : 140,
    });
  } else {
    columns.push(
      {
        Header: '',
        accessor: 'profilePicture',
        sortable: false,
        filterable: false,
        minWidth: 110,
        maxWidth: 110,
        style: {
          padding: '0 0 0 20px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        },
        Cell: ({ value }) => <ProfileCell profilePicture={value} />,
      },
      {
        Header: props => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{I18n.t('patients_view.label_firstName')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'firstname',
        filterable: false,
        minWidth: 140,
      },
      {
        Header: props => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}

              <span>{I18n.t('patients_view.label_lastName')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'lastname',
        filterable: false,
        minWidth: 140,
      }
    );
  }
  columns.push(
    {
      Header: props => {
        return (
          <>
            {sort.column === props.column.id && (
              <Box width={20}>
                <Icon
                  name="sortArrow"
                  size={22}
                  className={
                    sort.column === props.column.id && sort.dir === 'desc'
                      ? 'desc-sort'
                      : ''
                  }
                />
              </Box>
            )}
            <span>
              {!hidePatientDemographics
                ? I18n.t('patients_view.label_dateOfBirth')
                : I18n.t('patientOverview.labelAge')}
            </span>
          </>
        );
      },
      headerClassName: 'data-table__column-1__header',
      accessor: 'birthdate',
      filterable: false,
      minWidth: 150,
      Cell: ({ original, value }) => {
        const { isBirthdayHardcoded } = original;
        return !isBirthdayHardcoded ? value : null;
      },
    },
    {
      Header: I18n.t('nav.site'),
      accessor: 'site',
      sortable: false,
      filterable: false,
      style: { overflow: 'visible' },
      minWidth: 150,
      Cell: ({ value }) => {
        return <TagsCell tagList={value} />;
      },
    }
  );

  return (
    <DataTable
      data={dataInfo}
      columns={columns}
      pageSize={false}
      pageSizeOptions={items}
      pagination
      isLoading={loading}
      onTableChange={(index, size) => {
        setCurrentPage(data && index * size > data.recordsTotal ? 0 : index);
        setPageSize(size);
      }}
      page={page}
      pages={data ? Math.ceil(data.recordsTotal / pageSize) : 1}
      totalRecord={data ? data.recordsTotal : 0}
      tableProps={tableProps}
    />
  );
};

export default PatientsAppointments;
