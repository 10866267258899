/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import I18n from 'i18next';

import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import {
  ComponentTypes,
  Actions,
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { ComponentColors } from '../../../caro-ui-commonfiles/utils/colors';
import { Title, Footer } from './CardComponents';
import TaskUpdateModalContent from '../Tasks/TaskUpdateModalContent';
import PatientOverviewModalFooter from '../common/PatientOverviewModalFooter';
import { PatientOverviewModalHeader } from '../common/PatientOverviewModalHeader';
import TaskShowModalContent from '../Tasks/TaskShowModalContent';
import ShowModalFooter from '../common/ShowModalFooter';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import serviceConfig from '../../../serviceConfig.json';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import CardInfoContent from '../common/CardInfoContent';
import { MEDIA_QUERIES } from '@ui-common-files/utils/layout';

const TaskCard = ({ data }) => {
  const {
    setModalContent,
    setFlashMessage,
    setMessagePrompt,
    setLandingTab,
    closeMessagePrompt,
    closeModal,
    checkUserCredential,
  } = useContext(PatientOverviewContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const sendTask = async () => {
    const response = await axios.post(
      serviceConfig.brokerService.sendTask.uri,
      {
        reminderId: data.reminderElement.reminderId,
      }
    );
    return response;
  };

  const unassignTask = async () => {
    const response = await axios.get(
      serviceConfig.brokerService.unassignTask.uri,
      {
        params: {
          reminderId: data.reminderElement.reminderId,
        },
      }
    );
    return response;
  };

  const deleteTask = async () => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.deleteTask.uri,
        {
          params: {
            reminderId: data.reminderElement.reminderId,
          },
        }
      );
      if (response) {
        setLandingTab(false);
        setLandingTab(ComponentTypes.TASK);
        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };
  const sendHandler = data.reminderElement.firebaseKey ? null : sendTask;
  const unassignHandler = sendHandler ? null : unassignTask;
  const resultHandler = null;

  const setEditTaskModalContent = async () => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.TASK,
      ActionTranslationKeys.UPDATE
    );
    if (userHasCredential) {
      setModalContent({
        title: `${I18n.t('task.editTask')}: ${data.name}`,
        content: <TaskUpdateModalContent data={data} />,
        footer: (
          <PatientOverviewModalFooter
            componentType={ComponentTypes.TASK}
            actionType={Actions.Update}
          />
        ),
        header: (
          <PatientOverviewModalHeader
            settingsStepTranslation={I18n.t('steps.taskSettings')}
          />
        ),
      });
    }
  };

  const setShowTaskModalContent = async () => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.TASK,
      ActionTranslationKeys.SHOW
    );
    if (userHasCredential) {
      setModalContent({
        title: `${I18n.t('task.showTask')}: ${data.name}`,
        content: <TaskShowModalContent data={data} />,
        footer: <ShowModalFooter close={closeModal} />,
      });
    }
  };

  const onDeleteClick = async user => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.TASK,
      ActionTranslationKeys.DELETE
    );
    if (userHasCredential) {
      setMessagePrompt({
        title: `${I18n.t('common_labels.msg_confirm_task_delete_headline')}: ${
          data.name
        }`,
        content: (
          <PromptContent
            message={I18n.t('common_labels.msg_confirm_task_delete')}
          />
        ),
        footer: (
          <PromptFooter
            close={closeMessagePrompt}
            confirmHandler={() => deleteTask(user)}
          />
        ),
      });
    }
  };

  return (
    <div className="assignment-card">
      <Title
        iconName={ComponentTypes.TASK}
        color={ComponentColors.TASK}
        data={data}
        onEditIconClick={setEditTaskModalContent}
        onShowIconClick={setShowTaskModalContent}
        onDeleteClick={onDeleteClick}
      />

      <Flex
        flexDirection={windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'}
        otherStyles={{ paddingLeft: '60px', marginTop: '10px' }}
      >
        <CardInfoContent data={data} variant="task" />

        <div className="empty-container" />
      </Flex>

      <Footer
        sendHandler={sendHandler}
        unassignHandler={unassignHandler}
        resultHandler={resultHandler}
        componentType={ComponentTypes.TASK}
        firebaseKey={data.reminderElement.firebaseKey}
      />
    </div>
  );
};

TaskCard.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default TaskCard;
