import React, {
  useContext,
  useState,
  useCallback,
  createContext,
  useEffect,
} from 'react';
import i18n from 'i18next';

import { Box, InfiniteScrolling } from '@ui-common-files/components';
import { ComponentTypes } from '@ui-common-files/utils/componentTypes';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import CarePathwayCardForAppointment from './CarePathwayCardForAppointment';
import CarePathwaysCard from './CarePathwaysCard';
import serviceConfig from '../../../serviceConfig.json';

import { useDelaySearch } from '../../Global/Hooks';
import { ApiClient } from '../../Global/Services';

import '../../css/analytics.css';
import '../../css/patientOverview.scss';

export const PatientOverviewCarePathWays = createContext(null);

function CarePathwayCardsList({
  patientId,
  carePathways,
  appointmentData,
  comesFromUpdateAppointment,
  setCardCount,
  searchValue,
  patientInfo,
}) {
  const [keyword, setKeyword] = useState(searchValue);
  const [itemIdToShowLoading, setItemIdToShowLoading] = useState(null);
  const [itemIdToUpdate, setItemIdToUpdate] = useState(null);
  const [restPagesToUpdate, setRestPagesToUpdate] = useState(null);

  const { landingTab } = useContext(PatientOverviewContext);

  const fetchData = useCallback(
    async (page, size, itemId) => {
      return ApiClient.GET({
        url: serviceConfig.brokerService.getAllCarepathwaysByPatientId.uri,
        payload: {
          searchValue: keyword,
          patientId,
          page,
          size,
          carepathwayId: itemId,
        },
      })
        .then(response => response?.data)
        .finally(() => {
          setItemIdToUpdate(null);
          setRestPagesToUpdate(null);
        });
    },
    [keyword, landingTab]
  );

  const fetchCount = async () => {
    const response = await ApiClient.GET({
      url: serviceConfig.brokerService.getCarePathwaysCount.uri,
      payload: {
        patientId,
        searchValue: keyword,
      },
    });
    if (response) {
      setCardCount(prevCount => {
        const indexOfCardCount = prevCount.findIndex(
          item => item.component === ComponentTypes.CAREPATHWAYS
        );
        const tempCount = [...prevCount];
        tempCount[indexOfCardCount].total = response.data.carePathwaysCount;
        return tempCount;
      });
    }
  };

  useEffect(() => {
    fetchCount();
  }, [keyword, patientId, landingTab]);

  useEffect(() => {
    if (restPagesToUpdate === null) {
      fetchCount();
    }
  }, [restPagesToUpdate]);

  useDelaySearch({
    input: searchValue,
    callback: () => {
      setKeyword(searchValue);
    },
  });

  if (comesFromUpdateAppointment && carePathways && carePathways.length) {
    return carePathways.map((assignment, i) => {
      return (
        <CarePathwayCardForAppointment
          key={`carepathway-${assignment.id}`}
          data={assignment}
          appointmentData={appointmentData}
        />
      );
    });
  }
  return (
    <PatientOverviewCarePathWays.Provider
      value={{
        setItemIdToUpdate,
        setRestPagesToUpdate,
        setItemIdToShowLoading,
        itemIdToUpdate,
      }}
    >
      <InfiniteScrolling
        dataFetcher={fetchData}
        itemIdToUpdate={itemIdToUpdate}
        restPagesToUpdate={restPagesToUpdate}
        itemIdToShowLoading={itemIdToShowLoading}
        emptyItem={
          <Box className="empty-card-viewer">
            {i18n.t('common_labels.no_carepathways_assigned')}
          </Box>
        }
        renderItem={itemData => {
          return (
            <CarePathwaysCard
              data={itemData}
              hasOnlyAssessment={itemData.hasAllAssessment}
              patientId={patientId}
              patientInfo={patientInfo}
            />
          );
        }}
      />
    </PatientOverviewCarePathWays.Provider>
  );
}

export default CarePathwayCardsList;
