import React from 'react';
import moment from 'moment';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import I18n from 'i18next';
import {
  SmallDot,
  removeDuplicatedY,
  hasWhiteSpace,
} from '../../Careplans/AnswerComponents/AnswerGraphs';
import {
  PrimaryColor,
  ActionColors,
} from '../../../../caro-ui-commonfiles/utils/colors';
const dateFormat = 'DD MMM YYYY';
import { nonMedical } from '../../../../config';

export const SingleChoiceGraph = ({ component, dataForVisualisation }) => {
  const { thresholds } = component;
  const threshold = thresholds[0];
  const data = [];
  const thresholdData = [];
  const optionsList = JSON.parse(JSON.stringify(component.options));
  let yAxisWidth = 100;
  for (let i = 0; i < optionsList.length; i++) {
    let yText = optionsList[i];
    if (!hasWhiteSpace(yText)) {
      yText =
        yText.substring(0, 25) +
        ' ' +
        yText.substring(25, 50) +
        ' ' +
        yText.substring(50, 74);
      optionsList[i] = yText;
    }
    if (yText.length > 10 && yText.length <= 20) yAxisWidth = 150;
    else if (yText.length > 20 && yText.length <= 40) yAxisWidth = 200;
    else if (yText.length > 40 && yText.length <= 60) yAxisWidth = 250;
    else if (yText.length > 60) yAxisWidth = 320;
  }
  const { answers } = dataForVisualisation;
  if (answers) {
    answers.map(answer => {
      const dataElem = {};
      const answerIndex = answer.answer_json && answer.answer_json.optionIndex;
      const unixEpcs = new Date(answer.savedAt);
      dataElem.x = moment(unixEpcs).locale(I18n.language).format(dateFormat);
      dataElem.y = answerIndex;
      data.push(dataElem);
      if (
        parseInt(threshold) == parseInt(optionsList[answerIndex]) &&
        (nonMedical == false || nonMedical == 'false')
      ) {
        thresholdData.push(dataElem);
      }
    });
  }
  if (data) {
    return (
      <ScatterChart
        width={600}
        height={optionsList.length === 1 ? 180 : 64 * optionsList.length + 20}
        margin={{
          top: 20,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="x"
          allowDuplicatedCategory={false}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <YAxis
          type="number"
          dataKey="y"
          allowDecimals={false}
          tickCount={optionsList.length}
          domain={[0, optionsList.length - 1]}
          tickFormatter={index => optionsList[index] || ''}
          allowDuplicatedCategory={false}
          width={yAxisWidth}
          maxLines={2}
          padding={{ bottom: 20, top: 20, left: 20, right: 20 }}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          formatter={(index, key) => {
            if (key === 'y') {
              return optionsList[index];
            } else {
              return index;
            }
          }}
        />
        <Scatter
          data={data}
          line={{ stroke: PrimaryColor.MAIN_COLOR, strokeWidth: 2 }}
          className="scatter-point"
        />
        <Scatter
          data={thresholdData}
          shape={<SmallDot color={ActionColors.THRESHOLD} />}
        />
      </ScatterChart>
    );
  }
  return null;
};

export const YesNoGraph = ({ component, dataForVisualisation }) => {
  const yesNoData = [];
  const yesNoThreshold = [];
  const { thresholds } = component;

  if (dataForVisualisation.answers) {
    const threshold =
      component.thresholds.length &&
      component.thresholds[0] == I18n.t('common_labels.yes')
        ? 1
        : 0;
    dataForVisualisation.answers.map(answer => {
      const dataElem = {};
      const answerIndex = answer.answer_boolean == true ? 1 : 0;
      const timestamp = answer.savedAt;
      const unixEpcs = new Date(timestamp);
      dataElem.x = moment(unixEpcs).locale(I18n.language).format(dateFormat);
      dataElem.y = answerIndex;
      yesNoData.push(dataElem);
      if (
        thresholds &&
        thresholds.length &&
        threshold == answerIndex &&
        (nonMedical == false || nonMedical == 'false')
      ) {
        yesNoThreshold.push(dataElem);
      }
    });
  }

  return (
    <ScatterChart
      width={600}
      height={148}
      margin={{
        top: 20,
        left: -30,
      }}
    >
      <CartesianGrid />
      <XAxis
        dataKey="x"
        type="category"
        allowDuplicatedCategory={false}
        tick={{
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '1.4',
          color: 'dark-grey',
        }}
      />
      <YAxis
        dataKey="y"
        type="number"
        allowDecimals={false}
        tickCount={2}
        domain={[0, 1]}
        allowDuplicatedCategory={false}
        width={100}
        height={200}
        maxLines={2}
        padding={{ bottom: 20, top: 20, left: 20, right: 20 }}
        tick={{
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '1.4',
          color: 'dark-grey',
        }}
      />

      <Tooltip cursor={{ strokeDasharray: '3 3' }} />

      <Scatter
        data={yesNoData}
        line={{ stroke: PrimaryColor.MAIN_COLOR, strokeWidth: 2 }}
        className="scatter-point"
      />

      <Scatter
        data={yesNoThreshold}
        shape={<SmallDot color={ActionColors.THRESHOLD} />}
      />
    </ScatterChart>
  );
};

export const NumericGraph = ({ component, dataForVisualisation }) => {
  const { thresholds } = component;
  const numericData = [];
  const numericThreshold = [];
  const { answers } = dataForVisualisation;
  if (answers) {
    answers.map(answer => {
      const dataElem = {};
      const { answer_numeric } = answer;
      const timestamp = answer.savedAt;
      const unixEpcs = new Date(timestamp);
      dataElem.x = moment(unixEpcs).locale(I18n.language).format(dateFormat);
      dataElem.y = answer_numeric;
      let thresholdAnswerEval = thresholds.length;
      thresholds.length &&
        thresholds.map(thresholdElem => {
          if (!eval(`${answer_numeric} ${thresholdElem}`)) {
            thresholdAnswerEval = false;
          }
        });
      if (
        thresholdAnswerEval &&
        (nonMedical == false || nonMedical == 'false')
      ) {
        numericThreshold.push(dataElem);
      }
      numericData.push(dataElem);
    });
  }
  if (numericData.length) {
    return (
      <ScatterChart
        width={600}
        height={numericData.length === 1 ? 180 : 64 * numericData.length + 20}
        margin={{
          top: 20,
          left: -30,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="x"
          type="category"
          allowDuplicatedCategory={false}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <YAxis
          dataKey="y"
          type="number"
          allowDuplicatedCategory={false}
          allowDecimals={false}
          width={100}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />

        <Tooltip cursor={{ strokeDasharray: '3 3' }} />

        <Scatter
          data={numericData}
          line={{ stroke: PrimaryColor.MAIN_COLOR, strokeWidth: 2 }}
          className="scatter-point"
        />

        <Scatter
          data={numericThreshold}
          shape={<SmallDot color={ActionColors.THRESHOLD} />}
        />
      </ScatterChart>
    );
  }
  return null;
};

export const MultipleChoiceGraph = ({ component, dataForVisualisation }) => {
  const { thresholds } = component;

  const multipleChoiceData = [];
  const multipleChoiceThreshold = [];
  const { answers } = dataForVisualisation;
  const optionsList = JSON.parse(JSON.stringify(component.options));
  let yAxisWidth = 100;
  for (let i = 0; i < optionsList.length; i++) {
    let yText = optionsList[i];
    if (!hasWhiteSpace(yText)) {
      yText =
        yText.substring(0, 25) +
        ' ' +
        yText.substring(25, 50) +
        ' ' +
        yText.substring(50, 74);
      optionsList[i] = yText;
    }
    if (yText.length > 10 && yText.length <= 20) yAxisWidth = 150;
    else if (yText.length > 20 && yText.length <= 40) yAxisWidth = 200;
    else if (yText.length > 40 && yText.length <= 60) yAxisWidth = 250;
    else if (yText.length > 60) yAxisWidth = 320;
  }
  if (answers) {
    answers.map((answer, i) => {
      const timestamp = answer.savedAt;

      const unixEpcs = new Date(timestamp);

      const answerObject = answer.answer_json;
      for (const key in answerObject) {
        const dataElem = {};
        if (answerObject[key] == true) {
          dataElem.x = moment(unixEpcs)
            .locale(I18n.language)
            .format(dateFormat);
          dataElem.y = key;
          if (
            thresholds &&
            (thresholds.includes(optionsList[parseInt(key)]) ||
              thresholds.includes(optionsList[key].trim())) &&
            (nonMedical == false || nonMedical == 'false')
          ) {
            multipleChoiceThreshold.push(dataElem);
          }
          multipleChoiceData.push(dataElem);
        }
      }
    });
  }
  if (multipleChoiceData.length) {
    return (
      <ScatterChart
        width={700}
        height={optionsList.length === 1 ? 180 : 64 * optionsList.length + 20}
        margin={{
          top: 20,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="x"
          interval={0}
          allowDuplicatedCategory={false}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <YAxis
          type="number"
          dataKey="y"
          allowDecimals={false}
          tickCount={optionsList.length}
          domain={[0, optionsList.length - 1]}
          tickFormatter={index => optionsList[index] || ''}
          allowDuplicatedCategory={false}
          width={yAxisWidth}
          maxLines={2}
          padding={{ bottom: 20, top: 20, left: 20, right: 20 }}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />

        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          formatter={(index, key) => {
            if (key === 'y') {
              return optionsList[index];
            } else {
              return index;
            }
          }}
        />
        <Scatter
          data={multipleChoiceData}
          fill={PrimaryColor.MAIN_COLOR}
          className="scatter-point__choice"
        />

        <Scatter
          data={multipleChoiceThreshold}
          shape={<SmallDot color={ActionColors.THRESHOLD} />}
        />
      </ScatterChart>
    );
  }
  return null;
};
