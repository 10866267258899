/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import { Greys, PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import {
  ActionTranslationKeys,
  ComponentTranslationKeys,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import config from '../../../config';
import serviceConfig from '../../../serviceConfig.json';
import '../../css/chat.scss';
import { ApiClient } from '../../Global/Services';
import tabPosition from '../../Utils/Administration/tabPosition';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import CaretaskAnswering from '../Patients/Caretask/Answering';
import Chat from './Chat/Chat';
import Notes from './Notes/Notes';
import TelemedicineHeader from './TelemedicineHeader';
import initVideoCall from './VideoCall/InitVideoCall';
import PopUpDisabledModal from './VideoCall/PopUpDisabledModal';

require('image-compressor');

const TelemedicineContext = React.createContext(null);

const TelemedicineSidebar = ({
  telemedicineActiveTab,
  setTelemedicineActiveTab,
  closeSidebar,
  patientInfo,
  setFlashMessage,
  careTeamPrimaryInstitutions,
  isPatientLoggedIn,
  isChatNotified,
  reloadAssetTable,
  setAllMessagesList,
  reloadChat,
  setReloadChat,
  isChatOpened,
  answerCaretask,
  setAnswerCaretask,
  patientId,
  careplanId,
  setCareplanId,
  occurrenceId,
  setAnsweredCaretask,
}) => {
  const { t } = useTranslation();
  const PATIENT_UNPAIRED_OBJ = {
    isEnabled: false,
    message: 'patientOverview.patientNotLoggedIn',
  };
  const {
    checkUserCredential,
    setDoFetch,
    isReviewReport,
    caretaskReviewName,
  } = useContext(PatientOverviewContext);

  const chatContainer = useRef(null);

  const { user } = useSelector(state => state.session);
  const services = useSelector(state => state.services);

  const [isEnabled, setIsEnabled] = useReducer(
    (_state, enabled) => enabled,
    PATIENT_UNPAIRED_OBJ
  );
  const [isPatientDemographicData, setPatientDemographicData] = useState(false);
  const [videoCall, setVideoCall] = useState(false);
  const [attachedFile, setAttachedFile] = useState(null);
  const [attachBtnClicked, setAttachBtnClicked] = useState(false);
  const getFileActiveTab = (_state, data) => data;
  const changeFileTabPosition = () => {
    return tabPosition.FIRST;
  };

  const [fileActiveTab, setFileActiveTab] = useReducer(
    getFileActiveTab,
    changeFileTabPosition()
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getUserRole = async () => {
      const response = await ApiClient.POST({
        url: serviceConfig.brokerService.editUser.uri,
        payload: { id: user.id },
      });
      if (response?.data?.user?.role) {
        setPatientDemographicData(
          response.data.user.role.isPatientDemographicData
        );
      }
    };
    getUserRole();
  }, []);
  useEffect(() => {
    if (answerCaretask) {
      if (!isChatOpened) {
        closeAndResetSidebar();
      }
      setTelemedicineActiveTab(tabPosition.THIRD);
      setAnswerCaretask(false);
    }
  }, [answerCaretask]);

  useEffect(() => {
    setDoFetch(true);
    const getTelemedicinePermissionObj = async () => {
      const hasTelemedicineSendPermission = await checkUserCredential(
        ComponentTranslationKeys.TELEMEDICINE,
        ActionTranslationKeys.SEND
      );

      if (hasTelemedicineSendPermission) {
        setIsEnabled({ isEnabled: true });
      } else {
        setIsEnabled({
          isEnabled: false,
          message: 'chatMessages.noSendingRights',
        });
      }
    };

    if (isPatientLoggedIn) {
      getTelemedicinePermissionObj();
    } else {
      setIsEnabled(PATIENT_UNPAIRED_OBJ);
    }
  }, [isPatientLoggedIn]);

  const startVideoCall = async () => {
    setFlashMessage({
      type: 'warning',
      content: I18n.t(`warningMessage.video_warning`),
    });
    if (config.jitsiUrl) {
      let roomId = '';
      await axios
        .get('/broker/patients/generateVideoRoomId')
        .then(function (res) {
          roomId = res.data;
        });
      const currentRoom = `myoncare-Room-${roomId}`;
      const jitsiChatUrl = config.jitsiUrl + currentRoom;
      initVideoCall(user, patientInfo, jitsiChatUrl);
      const videoCallWindow = window.open(
        jitsiChatUrl,
        'newwindow',
        'width=800, height=500'
      );
      if (
        (!videoCallWindow ||
          videoCallWindow.closed ||
          typeof videoCallWindow.closed == 'undefined') &&
        window.navigator.userAgent.includes('Safari')
      ) {
        setIsModalOpen(true);
      }
    } else {
      initVideoCall(user, patientInfo).then(response => {
        if (response.type) {
          setFlashMessage(response);
          api.executeCommand('toggleVideo');
        } else {
          window.open(response, '_blank');
        }
      });
    }
  };

  const closeAndResetSidebar = () => {
    setVideoCall(false);
    closeSidebar();
  };

  return (
    <TelemedicineContext.Provider
      value={{
        attachedFile,
        setAttachedFile,
        attachBtnClicked,
        setAttachBtnClicked,
        fileActiveTab,
        setFileActiveTab,
      }}
    >
      <div
        className={isChatOpened ? 'chat' : 'chat__closed'}
        ref={chatContainer}
      >
        <div className="chat__sidebar__actions">
          <button
            type="button"
            onClick={() => {
              closeAndResetSidebar();
            }}
            className="chat__sidebar__toggle"
            style={{ justifyContent: isChatOpened ? 'center' : 'unset' }}
          >
            <Icon
              name={isChatOpened ? 'arrowRight' : 'arrowLeft'}
              size={20}
              fill={Greys.LIGHT_GREY}
            />
          </button>

          <Flex
            flexDirection="column"
            otherStyles={{
              marginTop: '40px',
            }}
          >
            {!isPatientDemographicData &&
              services['chatservice.myoncare.care'] && (
                <div
                  className={
                    telemedicineActiveTab == tabPosition.FIRST
                      ? 'icon-btn-active'
                      : ''
                  }
                >
                  <IconButton
                    type="button"
                    name="chat"
                    size={17}
                    onClick={() => {
                      if (!isChatOpened) closeAndResetSidebar();
                      setTelemedicineActiveTab(tabPosition.FIRST);
                    }}
                    fill={PrimaryColor.MAIN_COLOR}
                    tooltipText={t('patientOverview.chat')}
                  />
                </div>
              )}

            <div
              style={{ marginTop: !isPatientDemographicData && '24px' }}
              className={
                telemedicineActiveTab == tabPosition.SECOND
                  ? 'icon-btn-active'
                  : ''
              }
            >
              <IconButton
                type="button"
                name="notes"
                size={16}
                onClick={() => {
                  if (!isChatOpened) closeAndResetSidebar();
                  setTelemedicineActiveTab(tabPosition.SECOND);
                }}
                fill={PrimaryColor.MAIN_COLOR}
                tooltipText={I18n.t('notes.tab_label')}
              />
            </div>
            <div
              style={{ marginTop: '24px' }}
              className={
                telemedicineActiveTab == tabPosition.THIRD
                  ? 'icon-btn-active'
                  : ''
              }
            >
              <IconButton
                type="button"
                name="tasks"
                size={16}
                onClick={() => {
                  if (!isChatOpened) closeAndResetSidebar();
                  setTelemedicineActiveTab(tabPosition.THIRD);
                }}
                fill={PrimaryColor.MAIN_COLOR}
                tooltipText={I18n.t('nav.caretasks')}
              />
            </div>
          </Flex>
        </div>

        {isChatOpened && (
          <div className="chat__wrapper" id="chat__wrapper">
            {!isPatientDemographicData && (
              <TelemedicineHeader
                isEnabled={isEnabled.isEnabled}
                execVideoCall={startVideoCall}
                activeTab={telemedicineActiveTab}
                isChatServiceActive={services['chatservice.myoncare.care']}
                isReviewReport={isReviewReport}
              />
            )}

            {telemedicineActiveTab == tabPosition.FIRST &&
              !isPatientDemographicData &&
              services['chatservice.myoncare.care'] && (
                <Chat
                  isEnabled={isEnabled}
                  patientInfo={patientInfo}
                  setFlashMessage={setFlashMessage}
                  careTeamPrimaryInstitutions={careTeamPrimaryInstitutions}
                  isPatientLoggedIn={isPatientLoggedIn}
                  isChatNotified={isChatNotified}
                  reloadAssetTable={reloadAssetTable}
                  setAllMessagesList={setAllMessagesList}
                  reloadChat={reloadChat}
                  setReloadChat={setReloadChat}
                  videoCall={videoCall}
                />
              )}

            {telemedicineActiveTab === tabPosition.SECOND && (
              <Notes
                patientInfo={patientInfo}
                setFlashMessage={setFlashMessage}
                reloadAssetTable={reloadAssetTable}
                videoCall={videoCall}
              />
            )}
            {telemedicineActiveTab === tabPosition.THIRD && (
              <CaretaskAnswering
                caretaskId={careplanId}
                setCaretaskId={setCareplanId}
                occurrenceId={occurrenceId}
                setAnsweredCaretask={setAnsweredCaretask}
                setFlashMessage={setFlashMessage}
              />
            )}
          </div>
        )}
      </div>
      <PopUpDisabledModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </TelemedicineContext.Provider>
  );
};

TelemedicineSidebar.propTypes = {
  closeSidebar: PropTypes.func.isRequired,
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ])
  ).isRequired,
  setFlashMessage: PropTypes.func.isRequired,
  careTeamPrimaryInstitutions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPatientLoggedIn: PropTypes.bool.isRequired,
  isChatNotified: PropTypes.bool.isRequired,
  reloadAssetTable: PropTypes.bool.isRequired,
  setAllMessagesList: PropTypes.func.isRequired,
  reloadChat: PropTypes.bool.isRequired,
  setReloadChat: PropTypes.func.isRequired,
  isChatOpened: PropTypes.bool.isRequired,
};
export { TelemedicineContext, TelemedicineSidebar };
