import axios, { AxiosRequestConfig } from 'axios';
import { AxiosInstance } from 'axios';

export default function reportAPI(authenticationToken: string): AxiosInstance {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (defaultConfig: AxiosRequestConfig) => {
      const requestConfig: AxiosRequestConfig = { ...defaultConfig };
      requestConfig.headers.Authorization = authenticationToken;
      return requestConfig;
    }
  );

  return axiosInstance;
}
