/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';

import { Icon } from '@ui-common-files/components';

import 'react-h5-audio-player/lib/styles.css';

const BubbleAudio = ({ blobURL }) => {
  return (
    <div className="file-link__container">
      <AudioPlayer
        src={blobURL}
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        showSkipControls={false}
        showJumpControls={false}
        customProgressBarSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.PROGRESS_BAR]}
        customControlsSection={[
          RHAP_UI.CURRENT_TIME,
          <div>/</div>,
          RHAP_UI.DURATION,
        ]}
        customIcons={{
          play: <Icon name="playButton" size={16} fill="#191c47" />,
          pause: <Icon name="pauseButton" size={16} fill="#191c47" />
        }}
      />
    </div>
  );
};

BubbleAudio.propTypes = {
  blobURL: PropTypes.string.isRequired,
};

export default React.memo(BubbleAudio);
