import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Flex from '../Flex/Flex';

import { PrimaryColor, Greys } from '../../utils/colors';
import slider from '../../utils/slider';

import '../../styles/main.scss';

const Slider = ({
  id,
  min,
  max,
  minLabel,
  maxLabel,
  value,
  onInput,
  onChange,
  variant,
  fromAnswerCaretask,
}) => {
  const [responsive, setResponsive] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);
  const inputValue = value !== '-' ? value : min;

  const screenSize = useCallback(() => {
    window.innerWidth <= 1366 ? setResponsive(true) : setResponsive(false);
  }, [responsive]);

  const sliderStyles = classNames({
    slider__range: true,
    'slider__range--default': variant === slider.variant.DEFAULT,
    'slider__range--non-editable': variant === slider.variant.NON_EDITABLE,
    'slider__range--from-answer': fromAnswerCaretask && responsive,
  });

  const widthSScreen = 270;
  const widthLScreen = 320;
  const width = responsive ? widthSScreen : widthLScreen;
  useEffect(() => {
    screenSize();
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  useEffect(() => {
    if (value !== '-') {
      setProgressWidth((width / (max - min)) * (value - min));
    } else {
      setProgressWidth(0);
    }
  }, [value]);

  return (
    <Flex flexDirection="column" otherStyles={{ width: width }}>
      <div className="slider__circle">
        <div className="slider__circle__value">{value}</div>
      </div>
      <Flex justifyContent="space-between">
        <h3 className="slider__min-max">{min}</h3>
        <h3 className="slider__min-max">{max}</h3>
      </Flex>
      <div className="slider__range__wrapper">
        <input
          className={sliderStyles}
          type="range"
          id={id}
          min={min}
          max={max}
          value={inputValue}
          onInput={onInput}
          onChange={onChange}
        />

        <div
          className="slider-filler"
          style={{
            width: `${progressWidth}px`,
            backgroundColor:
              variant === slider.variant.DEFAULT
                ? PrimaryColor.MAIN_COLOR
                : Greys.LIGHT_GREY,
            cursor: variant === slider.variant.DEFAULT ? 'pointer' : 'default',
          }}
        />
      </div>

      <Flex justifyContent="space-between">
        <h3 className="slider__min-max">{minLabel}</h3>
        <h3 className="slider__min-max">{maxLabel}</h3>
      </Flex>
    </Flex>
  );
};

Slider.propTypes = {
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  minLabel: PropTypes.string.isRequired,
  maxLabel: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    slider.variant.DEFAULT,
    slider.variant.NON_EDITABLE,
  ]),
};

Slider.defaultProps = {
  variant: slider.variant.DEFAULT,
};

export default Slider;
