import React from 'react';
import { ComposedChart, XAxis, Area } from 'recharts';
import { useTranslation } from 'react-i18next';

import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';
import {
  Greys,
  PrimaryColor,
} from '../../../../../../caro-ui-commonfiles/utils/colors';
import { ScoreChartProps } from './CareplanReportFirstPage.type';

import './CareplanReportFirstPage.scss';

const CustomizedDot = (props: any) => {
  const { cx, cy, payload } = props;
  if (payload.score === null) return null;
  const topMarginOfDotScoreText = 25;
  return (
    <g>
      <line
        x={cx}
        y={cy}
        stroke={Greys.LIGHT_GREY_50}
        stroke-dasharray="4"
        x1={cx}
        y1={cy}
        x2={cx}
        y2="220"
      />
      <circle
        r="7"
        type="monotone"
        strokeWidth="2"
        stroke={Greys.WHITE}
        fillOpacity="1"
        fill={PrimaryColor.MAIN_COLOR}
        width="650"
        height="190"
        cx={cx}
        cy={cy}
        className="recharts-dot recharts-area-dot"
      />
      <defs>
        <filter x="-0.1" y="-0.1" width="1.2" height="1.2" id="solid">
          <feFlood flood-color={Greys.WHITE} flo />
          <feComposite
            in="SourceGraphic"
            operator="xor"
            fill={PrimaryColor.MAIN_COLOR}
          />
        </filter>
      </defs>
      <text
        orientation="bottom"
        width="650"
        height="30"
        type="category"
        x={cx}
        y={cy - topMarginOfDotScoreText}
        stroke="none"
        fill={PrimaryColor.MAIN_COLOR}
        fontSize="12"
        textAnchor="middle"
      >
        <tspan x={cx} dy="0.71em">
          {payload.score}
        </tspan>
      </text>
      <text
        orientation="bottom"
        width="650"
        height="30"
        type="category"
        x={cx}
        y={cy - topMarginOfDotScoreText}
        fill={PrimaryColor.MAIN_COLOR}
        fontSize="12"
        textAnchor="middle"
      >
        <tspan
          x={cx}
          dy="0.71em"
          fill={PrimaryColor.MAIN_COLOR}
          filter="url(#solid)"
        >
          {payload.score}
        </tspan>
      </text>
    </g>
  );
};

const CareplanReportScoreChart = ({
  chartData,
  totalScore,
}: ScoreChartProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box className="first-page_chart">
      <Flex
        className="first-page_chart-header"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <h3 className="first-page_chart-title">
            {t('newQuestion_view.score')}
          </h3>
        </Box>
        {totalScore && (
          <Box>
            {t('PDFReport.maxPossibleScore')}: {totalScore}
          </Box>
        )}
      </Flex>
      <Box className="first-page_chart-chart">
        <ComposedChart
          width={700}
          height={270}
          data={chartData}
          margin={{ right: 20, left: 20, top: 30, bottom: 20 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={PrimaryColor.MAIN_COLOR_LIGHT} />
              <stop offset="100%" stopColor={Greys.WHITE} />
            </linearGradient>
          </defs>

          <Area
            dataKey="score"
            strokeWidth={1}
            stroke={PrimaryColor.MAIN_COLOR_70}
            strokeDasharray="4"
            fillOpacity={1}
            dot={<CustomizedDot />}
            activeDot={true}
            fill="url(#colorUv)"
            isAnimationActive={false}
            connectNulls={true}
          />
          <XAxis
            dataKey="time"
            tick={{ fontSize: 12, color: Greys.LIGHT_GREY_10 }}
            tickMargin={10}
          />
        </ComposedChart>
      </Box>
    </Box>
  );
};

export default CareplanReportScoreChart;
