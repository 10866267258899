/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import I18n from "i18next";

import { HideFlashMessage } from '@global/Services';

import Modal from "../../../../caro-ui-commonfiles/components/Modals/ModalLayout";
import { ButtonVariant } from "../../../../caro-ui-commonfiles/utils/button";
import Box from "../../../../caro-ui-commonfiles/components/Box/Box";
import Flex from "../../../../caro-ui-commonfiles/components/Flex/Flex";
import Button from "../../../../caro-ui-commonfiles/components/Button/Button";
import PopUpDisabledModalContent from "./PopUpDisabledModalContent";

const PopUpDisabledModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      isConfirmationDialog
      title={I18n.t("popUpCheck.title")}
      contentComponent={
        <PopUpDisabledModalContent />
      }
      footerComponent={
        <Box width="100%">
          <Flex justifyContent="flex-end">
            <Box margin="0 16px 0 0">
              <Button
                label={I18n.t("common_buttons.close")}
                onClick={() => setIsModalOpen(false)}
                variant={ButtonVariant.CONTAINED}
              />
            </Box>
          </Flex>
        </Box>
      }
      openModal={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      hideFlashMessage={HideFlashMessage}
    />
  );
};

export default PopUpDisabledModal;
