import { useState, useMemo, useCallback } from 'react';
import { Case } from '../PatientOverview/oncobox.interface';

export const useOncoBoxCaseSectionService = (sectionData: Case) => {
  const [toggle, setToggle] = useState(false);
  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >({});

  const toggleSection = useCallback((sectionKey: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
    }));
  }, []);

  const caseNumber = useMemo(
    () => sectionData?.caseInfo?.caseNumber,
    [sectionData]
  );
  const arraySections = ['systemicTherapy', 'tumorBoard'];
  const preparedSections = useMemo(() => {
    return Object.entries(sectionData)
      .filter(([sectionKey]) => !arraySections.includes(sectionKey))
      .map(([sectionKey, section]) => {
        const valueLength = Array.isArray(section)
          ? section.length
          : Object.keys(section).length;

        const sectionValue = Array.isArray(section) ? section : [section];
        return {
          sectionKey,
          sectionValue,
          valueLength,
        };
      });
  }, [sectionData]);

  const systemicTherapies = useMemo(() => {
    if (!sectionData?.systemicTherapy) return [];
    return sectionData?.systemicTherapy.map(therapy => ({
      ...therapy,
      preparedData: Object.entries(therapy),
    }));
  }, [sectionData]);

  const tumorBoard = useMemo(() => {
    if (!sectionData?.tumorBoard) return [];
    return sectionData?.tumorBoard.map(tumor => ({
      ...tumor,
      preparedData: Object.entries(tumor),
    }));
  }, [sectionData]);

  return {
    toggle,
    setToggle,
    expandedSections,
    toggleSection,
    caseNumber,
    preparedSections,
    systemicTherapies,
    tumorBoard,
  };
};
