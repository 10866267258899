/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import I18n from 'i18next';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import {
  filterHl7AttributeBasedOnAttributeName,
  getDate,
  renderPatientAttributeForBasicInfo,
} from './PatientHelperUtility';

import { enableOpsPatientView } from '../../../config';

const PatientBasicInformation = ({
  patientInfo,
  patientAdditionalInformation,
  isLoading,
  setIsloading,
}) => {

  const renderInstitutions = useCallback(() => {
    if (patientInfo.institutions.length) {
      return patientInfo.institutions.map(institution => (
        <div key={institution.id} style={{ marginBottom: 5 }}>
          {institution.name}
        </div>
      ));
    }
  }, [patientInfo]);

  useEffect(() => {
    if (patientInfo.id && enableOpsPatientView)
      setIsloading(false);
  }, [patientInfo]);

  const renderAdditionalDetailsSection = () => {
    return (
      <Flex flexDirection="row">
        {patientInfo.institutions.length > 0 && (
          <div className="section-container" style={{ marginTop: '5px' }}>
            <div
              className="section-title-container"
              style={{ paddingBottom: 20 }}
            >
              {I18n.t('patientOverview.institutions')}
            </div>
            <div className="content-text">{renderInstitutions()}</div>
          </div>
        )}
      </Flex>
    );
  };

  const renderPatientDetails = () => {
    return (
      <>
        <div className="overview__patient-activity-patient-details patient-details-bg-color">
          <Flex flexDirection="row" alignItems="flex-start">
            <div className="patient-name-title">
              {I18n.t('hl7Attribute.sections.basicInfo')}
            </div>
          </Flex>
          {renderPatientAttributeForBasicInfo(
            I18n.t('patients_view.label_medicalRecord'),
            patientInfo.medicalRecord
          )}
          {renderPatientAttributeForBasicInfo(
            I18n.t('hl7Attribute.postCode'),
            filterHl7AttributeBasedOnAttributeName(
              'hl7Attribute.postCode',
              patientAdditionalInformation
            )
          )}
          {renderPatientAttributeForBasicInfo(
            I18n.t('hl7Attribute.patientDeceasedIn30Days'),
            filterHl7AttributeBasedOnAttributeName(
              'hl7Attribute.patientDeceasedIn30Days',
              patientAdditionalInformation
            )
          )}
        </div>
        <div
          className="patient-details-container"
          style={{ marginRight: 10, flex: 1 }}
        >
          {renderAdditionalDetailsSection()}
        </div>
      </>
    );
  };

  return (
    <div>
      {isLoading && (
        <div className="pdf-loader-container">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="room-info-container" style={{ display: 'flex' }}>
            {renderPatientDetails()}
          </div>
        </>
      )}
    </div>
  );
};

PatientBasicInformation.propTypes = {
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  patientAdditionalInformation: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PatientBasicInformation;
