import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Box from '../../../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import CheckboxRadio from '../../../../../../../caro-ui-commonfiles/utils/CheckboxRadio';

import { CaretaskComponent } from '../../../../../../types/Caretask';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { AnsweringComponentsProps } from '../AnsweringTypes';

import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';
import Checkbox from '../../../../../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import { useReducer } from 'react';

export default function AnsweringMultipleChoice({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);
  const getInitialState = () => {
    if (currentComponent.optionJson && currentComponent.optionJson.options) {
      return new Array(currentComponent.optionJson.options.length).fill(false);
    }
    return [];
  };

  const getCheckedIndices = (state, action) => {
    if (action && action.index > -1) {
      const updatedState = [...state];
      updatedState[action.index] = action.isChecked;
      return updatedState;
    }
    if (action && action.length) {
      return action;
    }
    return getInitialState();
  };
  const defaultValue =
    currentAnswer?.answer !== null ? currentAnswer?.answer : getInitialState();

  const [checkedIndex, setCheckedIndex] = useReducer(
    getCheckedIndices,
    defaultValue
  );

  useEffect(() => {
    handleOnChange({ answer: checkedIndex, isSkipped: !checkedIndex.includes(true)});
  }, [checkedIndex, handleOnChange]);
  useEffect(() => {
    setCheckedIndex(defaultValue);
  }, [currentComponent]);

  return (
    <Box>
      {currentComponent.optionJson?.options.map((option, i) => {
        return (
          <Flex
            key={`container-${option}`}
            otherStyles={{ padding: '5px 0px' }}
          >
            <Checkbox
              id={`option-${i}`}
              key={`checkbox-key-${option}`}
              name={option}
              variant={CheckboxRadio.Default}
              checked={checkedIndex[i]}
              handleOnChange={e => {
                setCheckedIndex({
                  index: i,
                  isChecked: e.target.checked,
                });
              }}
            />
            <Box key={`label-key-${option}`} margin="0 0 0 10px">
              {option}
            </Box>
          </Flex>
        );
      })}
    </Box>
  );
}
