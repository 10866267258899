import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Flex, Box } from '@ui-common-files/components';
import TroubleshootSolutionsList from './TroubleshootSolutionsList';

import styles from './TroubleshootSection.module.scss';

const TroubleshootSection = ({ patientInfo }) => {
  const { t } = useTranslation();

  return (
    <Box className={styles['troubleshoot__container']}>
      <Flex flexDirection="row" justifyContent="space-between">
        <Box className="patient-details-container">
          <Box className="section-container">
            <Box
              className={cx(
                styles['troubleshoot__heading-and-title'],
                styles['troubleshoot__heading-and-title__text-spacing']
              )}
            >
              {t('patient.troubleshoot.heading')}
            </Box>

            <Box className={styles['troubleshoot__info']}>
              {t('patient.troubleshoot.info')}
            </Box>
          </Box>

          <TroubleshootSolutionsList patientInfo={patientInfo} />
        </Box>
      </Flex>
    </Box>
  );
};

export default TroubleshootSection;
