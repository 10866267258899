import React from 'react';
import { Box } from '@ui-common-files/components';
import {
  renderComponents,
  renderAdditionalComponents,
} from 'src/Views/Careplans/CareplanAdditionalComponentUtils';
import { isMainQuestion } from 'src/Views/Careplans/CareplanHelperUtility';
import { CarePathWayComponentsProps } from '../PathWayReviewModal.types';

const CareTaskComponentsInfoStep = ({
  careTaskTemplate,
  thresholds,
  logicJumps,
}: CarePathWayComponentsProps) => {
  return (
    <Box padding="20px 0 0">
      {renderComponents(
        thresholds.filter(component =>
          isMainQuestion(component[0].healthCategory)
        ),
        logicJumps,
        careTaskTemplate.isEvaluating,
        true
      )}
      {careTaskTemplate.isEvaluating &&
        renderAdditionalComponents(
          careTaskTemplate.scoringScheme,
          thresholds,
          logicJumps,
          careTaskTemplate.isEvaluating
        )}
    </Box>
  );
};

export default CareTaskComponentsInfoStep;
