/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'i18next';

import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Historial from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialLayout';
import HistorialStep from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialStep';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import ToolTip from '../../../caro-ui-commonfiles/components/Tooltip/Tooltip';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Action from '../../../caro-ui-commonfiles/components/Action/Action';

import {
  repeatTypesEnum,
  weekdays,
} from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import { getAction, getComponent } from '../../Utils/History';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';

const TaskInformationOverview = ({
  values,
  startingArrays,
  icds,
  showAssignee,
}) => {
  const { patientInfo } = useContext(PatientOverviewContext);
  const dateFormat = 'DD MMM YYYY';

  const renderAssigneeInfoPane = () => {
    return (
      <div className="navigation-container">
        <div
          className="circular-picture-sm"
          style={{
            backgroundImage: `url(${patientInfo.profilePicture})`,
          }}
        />
        <div className="care-team-member-name">
          {patientInfo.firstname} {patientInfo.lastname}
        </div>
      </div>
    );
  };

  const renderIcdInfo = () => {
    if (icds.length) {
      return icds.map(icd => {
        if (icd) {
          return (
            <Flex key={icd.code} flexDirection="row">
              <ToolTip position="right" content={icd.description}>
                <Icon name="info" size={24} />
              </ToolTip>
              <span className="icd-code-style">{icd.code}</span>
            </Flex>
          );
        }
        return null;
      });
    }
  };

  const renderOccurrencInfo = () => {
    let recurrenceTimes = null;
    if (startingArrays.startingTimes.length) {
      recurrenceTimes = startingArrays.startingTimes.map(time => (
        <div key={`startingTime-${time}`} className="content-text">
          {time}:00
        </div>
      ));
    }

    if (values.recurrenceText && recurrenceTimes) return recurrenceTimes;

    let recurrenceDays = null;
    if (
      startingArrays.startingDays.length &&
      values.repeatType.value === repeatTypesEnum.WEEKLY
    ) {
      const weekdaysTranslation = weekdays();
      recurrenceDays = startingArrays.startingDays.map((day, i) => (
        <div key={`weekday-${day}`} className="content-text">
          {i !== 0 && ', '}
          {weekdaysTranslation[day - 1].translation}
        </div>
      ));
    }

    return (
      <>
        {recurrenceTimes && recurrenceTimes}
        {recurrenceDays && (
          <Flex flexDirection="row">
            <span style={{ marginRight: '5px' }}>
              {I18n.t('recurrence.on')}
            </span>
            {recurrenceDays}
          </Flex>
        )}
        {values.endDate && !values.occurrenceCountCheck && (
          <div className="content-text">
            {I18n.t('recurrence.until')}{' '}
            {moment(values.endDate).locale(I18n.language).format(dateFormat)}
          </div>
        )}
        {values.occurrenceCountCheck && (
          <div className="content-text">
            {I18n.t('recurrence.after')} {values.occurrenceCount}{' '}
            {I18n.t('recurrence.occurences')}
          </div>
        )}
      </>
    );
  };

  const renderTaskInfoPane = () => {
    return (
      <Flex flexDirection="row">
        <div className="info-container">
          <div className="section-container">
            <div>{I18n.t('task.task')}</div>
            <div className="content-text">{values.name}</div>
          </div>
          <div className="section-container">
            <div>{I18n.t('task.taskType')}</div>
            {values.URL && (
              <Action href={values.URL} target="_blank">
                {values.taskType.label}
              </Action>
            )}
            {!values.URL && (
              <div className="content-text">{values.taskType.label}</div>
            )}
          </div>
          <div className="section-container">
            <div>{I18n.t('task.phaseType')}</div>
            <div className="content-text">{values.phaseType.label}</div>
          </div>
          <div className="section-container">
            <div>{I18n.t('task.taskPriority')}</div>
            <div className="content-text">{values.taskPriority.label}</div>
          </div>
          {icds.length > 0 && (
            <div className="section-container">
              <div>{I18n.t('common_labels.referenced_icd_code')}</div>
              <div className="content-text content-text-container">
                {renderIcdInfo()}
              </div>
            </div>
          )}
          <div className="section-container">
            <div>{I18n.t('recurrence.startsOn')}</div>
            <div className="content-text content-text-container">
              {moment(values.startDate)
                .locale(I18n.language)
                .format(dateFormat)}
            </div>
          </div>
          {!values.recurrenceText && (
            <div className="section-container">
              <div>{I18n.t('recurrence.title')}</div>
              <div className="content-text content-text-container">
                {renderOccurrencInfo()}
              </div>
            </div>
          )}
          {values.recurrenceText && (
            <div className="section-container">
              <div>{I18n.t('recurrence.title')}</div>
              <div className="content-text content-text-container">
                {values.recurrenceText}
              </div>
            </div>
          )}
          <div className="section-container">
            <div>{I18n.t('recurrence.lifespan')}</div>
            <div className="content-text content-text-container">
              {values.lifespan} {values.lifespanUnit.label}
            </div>
          </div>
        </div>
      </Flex>
    );
  };

  const renderTaskHistory = () =>
    values.ownershipLogs.map(log => (
      <Historial>
        <HistorialStep>
          <div className="show-patient-history">
            <span>
              {I18n.t('history.assignment', {
                user: log.user.username,
                action: getAction(log.action).toLowerCase(),
                component: getComponent(log.component),
              })}
            </span>
            <span className="show-patient__history-date">
              {`${moment(log.timeOfAction)
                .locale(I18n.language)
                .format(dateFormat)} - ${moment(log.timeOfAction).format(
                'HH:mm'
              )}`}
            </span>
          </div>
        </HistorialStep>
      </Historial>
    ));

  return (
    <ModalForm>
      {showAssignee && (
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <Box padding="30px 20px 0 40px">
            <div className="section-container-nav">
              <div>{I18n.t('patientOverview.assignedTo')}</div>
            </div>
            {renderAssigneeInfoPane()}
          </Box>
        </ModalSection>
      )}
      {!showAssignee && (
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <div className="history-label">
            <Label text={I18n.t('common_labels.history')} />
            <div className="show-assignment__historial-wrapper">
              {renderTaskHistory()}
            </div>
          </div>
        </ModalSection>
      )}
      <ModalSection variant={MODAL_VARIANT.COL_W70}>
        {renderTaskInfoPane()}
      </ModalSection>
    </ModalForm>
  );
};

TaskInformationOverview.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  startingArrays: PropTypes.objectOf(PropTypes.array).isRequired,
  icds: PropTypes.arrayOf(PropTypes.object),
  showAssignee: PropTypes.bool,
};

TaskInformationOverview.defaultProps = {
  icds: [],
  showAssignee: true,
};

export default memo(TaskInformationOverview);
