/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import I18n from "i18next";
import { PromptContent } from "../../common/ConfirmationPrompt";
import enablePopUpImage from "../../../img/enable-pop-up-safari.png";

const PopUpDisabledModalContent = () => {
  return (
    <>
      <PromptContent message={I18n.t("popUpCheck.content")} />
      <div style={{padding: "1%", maxWidth: "700px"}}>
        <img src={enablePopUpImage} style={{maxWidth: "100%" }}/>
      </div>
    </>
  );
};

export default PopUpDisabledModalContent;
