/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import I18n from 'i18next';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import serviceConfig from '../../../serviceConfig.json';
import { PatientOverviewContext } from './PatientOverview';
import ModalFooter from '../common/Layout/Modal/ModalFooter';
import { convertLinkToBlob } from '../../Utils/Asset/assetHelper';
import { FlashMessageTypes } from '../../../caro-ui-commonfiles/types/components';
import { ShowFlashMessage } from '../../Global/Services';

import NavigationJSON from '../../Utils/navigation.json';

function ExportResultsModalFooter({ carePlanExportData }) {
  const exportCsvTrigger = useRef(null);
  const { closeModal, dataForExport, patientInfo } = useContext(
    PatientOverviewContext
  );
  const [clicked, setClicked] = useState(false);
  const [exportedData, setExportedData] = useState([]);
  const history = useHistory();
  const { healthScore } = patientInfo;
  const { scoringSchemeCategory } = patientInfo;
  const careplanToBeExportedId = dataForExport?.selectSpecificOption?.[0] ?? null;

  const exportResults = async () => {
    try {
      if (dataForExport !== {} && clicked) {
        const response = await axios.post(
          serviceConfig.brokerService.exportDataOfPatient.uri,
          {
            ...dataForExport,
          }
        );
        if (response) {
          const stateData = response.data;
          const hasSubScoreFormula = carePlanExportData.some(
            careplan =>
              careplanToBeExportedId === careplan.id &&
              careplan.template?.hasSubScoreFormula
          );
          if (stateData.patient) {
            if (stateData.patient.profilePicture.id) {
              await convertLinkToBlob(
                stateData.patient.profilePicture.previewURL
              )
                .then(blob => {
                  stateData.patient.profilePicture = blob;
                })
                .catch(() => {
                  stateData.patient.profilePicture =
                    stateData.patient.profilePicture.previewURL;
                });
            } else {
              stateData.patient.profilePicture = `data:image;base64,${response.data.patient.profilePicture}`;
            }
            stateData.answers = stateData.answers.reverse();
            history.push(`/${NavigationJSON.EXPORT_DATA_TO_PDF}`, {
              data: {
                ...stateData,
                healthScore,
                scoringSchemeCategory,
              },
            });
          } else {
            if (response.data.length) {
              setExportedData(stateData);
            }
            if (stateData.content) {
              if (stateData.type === FlashMessageTypes.Warning) closeModal();
              ShowFlashMessage({
                type: stateData.type,
                messageKey: stateData.content,
                isModalMessage: false,
              });
            } else {
              ShowFlashMessage({
                type: FlashMessageTypes.Success,
                messageKey: 'flash.exportCsvFileProgressFinished',
                isModalMessage: false,
              });
            }
          }
        }
      }
    } catch (error) {
      closeModal();
      ShowFlashMessage({
        type: error.response.data.type,
        message: error.response.data.content,
        isModalMessage: false,
      });
    }
  };

  useEffect(() => {
    exportResults();
  }, [dataForExport]);

  useEffect(() => {
    if (exportedData.length && exportCsvTrigger.current) {
      exportCsvTrigger.current.link.click();
      closeModal();
    }
  }, [exportedData]);

  return (
    <ModalFooter
      close={() => {
        closeModal();
      }}
      additionalFunctionality={
        <CSVLink
          ref={exportCsvTrigger}
          style={{ display: 'none' }}
          filename={`${moment().format('YYYY-MM-DD')}.csv`}
          data={exportedData}
        />
      }
      labelCTA={I18n.t('common_buttons.exportAnswers')}
      form="form__export-results"
      onClickCTA={() => {
        setClicked(true);
      }}
    />
  );
}

export default ExportResultsModalFooter;
