import { ApiClient } from '@global/Services';
import serviceConfig from '../../../../serviceConfig.json';

export function checkPatientWithingsAccount(patientId: string) {
  return ApiClient.GET({
    url: `${serviceConfig.brokerService.withingsCheckExistence.uri}/${patientId}`,
  });
}

export function getAttachedDevices(patientId: string) {
  return ApiClient.GET({
    url: `${serviceConfig.brokerService.withingsAttachedDevices.uri}/${patientId}`,
  });
}

export function createWithingsAccount(
  patientId: string,
  macAddresses: string[]
) {
  return ApiClient.POST({
    url: `${serviceConfig.brokerService.withingsCreate.uri}/${patientId}`,
    payload: { macAddresses },
  });
}

export function unLinkWithingsDevice(
  patientId: string,
  macAddresses: string
) {
  return ApiClient.POST({
    url: `${serviceConfig.brokerService.withingsLinkDevice.uri}/${patientId}`,
    payload: { macAddresses, unlink: true },
  });
}
