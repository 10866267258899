import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import I18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { HideFlashMessage } from '@global/Services';
import {
  Checkbox,
  Flex,
  Box,
  Modal,
  FloatButton,
  ComplexSearch,
  FlashMessage,
  Calendar,
  UsFilterTopWarning,
} from '@ui-common-files/components';

import { FiltersComponent } from './Appointments/FiltersComponet';
import {
  FiltersModalContent,
  FiltersModalFooter,
} from './Appointments/FiltersModal';
import { CreateAppointmentModal } from './Appointments/AppointmentManager/CreateAppointmentModal';
import EditFilter from './CustomFilters/updateFromIndex';

import {
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../caro-ui-commonfiles/utils/componentTypes';
import { Greys, PrimaryColor } from '../../caro-ui-commonfiles/utils/colors';
import { FlashMessageTypes } from '../../caro-ui-commonfiles/types/components';
import checkUserCredential from '../Utils/checkUserCredential';
import complexSearchData from '../Utils/complexSearchData';
import calendarLocale from '../Utils/CalendarLocale';
import serviceConfig from '../../serviceConfig.json';

import { FirebaseContext } from '../Utils/Firebase/firebase';

import '../css/appointments.css';
import { ApiClient } from '../Global/Services';
import BreadcrumbPortal from './AppLayout/BreadcrumbPortal/BreadcrumbPortal';
import { Breadcrumb } from '@ui-common-files/components';
import { setEndOfDayForMaxData } from '@ui-common-files/utils/dateUtils/dateUtil';

const AppointmentManager = ({ history }) => {
  const { user } = useSelector(state => state.session);
  const services = useSelector(state => state.services);
  const { allFilters, savedFilter } = useSelector(
    state => state.patientFilters
  );
  const { t } = useTranslation();

  const customFilterData = complexSearchData(services);

  const retrievedSelectedRule = savedFilter.rule;
  const defaultFilter = savedFilter.index;

  const [filterKey, setFilterKey] = useState({
    id: allFilters[defaultFilter].filterId,
    name: allFilters[defaultFilter].key,
  });
  const getInitialRange = () => {
    const curr = new Date();
    const first = curr.getDate() - curr.getDay();
    const last = first + 6;
    const minDate = new Date(curr.setDate(first)).toUTCString();
    const maxDate = setEndOfDayForMaxData(new Date(curr.setDate(last)).toUTCString())
    return { minDate, maxDate };
  };

  const [displayModal, setDisplayModal] = useState(false);
  const [didUpdate, setDidUpdate] = useState(false);
  const [forceRender, setForceRender] = useState(true);

  const [sqlQuery, setSqlQuery] = useState({
    sql: '',
    params: [],
  });
  const [appointmentStatuses, setAppointmentStatuses] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [displayFilters, setDisplayFilters] = useState(true);
  const [range, setRange] = useState(getInitialRange());
  const [events, setEvents] = useState([]);
  const [minView, setMinView] = useState(false);
  const [showAddModal, setShowAddModal] = useState({
    show: false,
    data: null,
  });
  const [selectedRule, setSelectedRule] = useState(retrievedSelectedRule);
  const [displayFlashMeg, setDisplayFlashMsg] = useState({
    display: false,
    content: '',
    type: '',
  });

  const [auxState, setAuxState] = useState(null);
  const [filters, setFilters] = useState({
    types: [],
    statuses: [],
  });
  const [filtersModal, setFiltersModal] = useState({
    types: [],
    statuses: [],
  });

  const [currLang, setCurrlang] = useState(I18n.language);
  const [displayEditFilter, setDisplayEditFilter] = useState(false);
  const [showUsWarningTopPage, setShowUsWarningTopPage] = useState(
    allFilters[defaultFilter].filterId != 1
  );
  const [complexQuery, setComplexQuery] = useState(false);
  const complexQueryHandle = e => {
    setComplexQuery(e.target.checked);
  };
  const { hidePatientDemographics } = useContext(FirebaseContext);

  const screenSize = () => {
    if (window.innerWidth <= 1024) {
      setMinView(true);
      setDisplayFilters(false);
    } else {
      setMinView(false);
      setDisplayFilters(true);
    }
  };

  I18n.on('languageChanged', function (lng) {
    setCurrlang(lng);
  });

  useEffect(() => {
    screenSize();
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, []);

  useEffect(() => {
    if (minView && displayFilters) {
      setDisplayModal(true);
    } else {
      setDisplayModal(false);
    }
  }, [displayFilters]);

  useEffect(() => {
    const handleChange = rule => {
      setSelectedRule(rule);
      setForceRender(true);
    };
    handleChange(auxState);
  }, [forceRender]);

  useEffect(() => {
    const getAllFilters = async () => {
      try {
        const response = await ApiClient.GET({
          url: serviceConfig.appointmentManager.index.uri,
        });
        if (response && response.data) {
          setAppointmentStatuses(response.data.appointmentStatuses);
          setAppointmentTypes(response.data.appointmentTypes);
        }
      } catch (error) {
        const { content, type } =
          error.response && error.response.data
            ? error.response.data
            : {
                content: 'flash.unexpectedError',
                type: FlashMessageTypes.Error,
              };
        setDisplayFlashMsg({
          display: true,
          content,
          type,
        });
      }
    };
    getAllFilters();
  }, []);

  useEffect(() => {
    if (
      defaultFilter >= 0 &&
      allFilters.length > 0 &&
      allFilters[defaultFilter].rule
    ) {
      setSelectedRule(
        typeof allFilters[defaultFilter].rule == 'string'
          ? JSON.parse(allFilters[defaultFilter].rule)
          : allFilters[defaultFilter].rule
      );
      setFilterKey({
        id: allFilters[defaultFilter].filterId,
        name: allFilters[defaultFilter].key,
      });
    }
  }, [defaultFilter]);

  useEffect(() => {
    const controller = new AbortController();
    const getFilteredData = async () => {
      if (filters.statuses.length > 0 && filters.types.length > 0) {
        try {
          const filteredData = await ApiClient.GET({
            url: serviceConfig.appointmentManager.getFilteredData.uri,
            payload: {
              sql: sqlQuery.sql,
              params: JSON.stringify(sqlQuery.params),
              getRule: selectedRule,
              complexQuery,
              statusIds: JSON.stringify(filters.statuses),
              typeIds: JSON.stringify(filters.types),
              calendarMinDate: range.minDate,
              calendarMaxDate: range.maxDate,
              filterKey,
            },
            signal: controller.signal,
          });

          if (filteredData && filteredData.data.length) {
            setEvents(
              filteredData.data.map(filter => {
                const start = new Date(filter.start_date);
                const end = new Date(filter.end_date);
                return {
                  id: filter.appointment_id,
                  title: filter.text,
                  allDay: false,
                  start,
                  end,
                  data: filter,
                };
              })
            );
          } else {
            setEvents([]);
          }
        } catch (error) {
          const { content, type } =
            error.response && error.response.data
              ? error.response.data
              : {
                  content: 'flash.unexpectedError',
                  type: FlashMessageTypes.Error,
                };
          setDisplayFlashMsg({
            display: true,
            content,
            type,
          });
        }
      } else {
        setEvents([]);
      }
      setDidUpdate(false);
    };
    getFilteredData();
    return () => controller.abort();
  }, [sqlQuery, filters, range, didUpdate, complexQuery]);

  const getUserCredentials = async (component, action) => {
    const response = await checkUserCredential(component, action);
    if (response.type && response.type === FlashMessageTypes.Error) {
      setDisplayFlashMsg({
        display: true,
        type: response.type,
        content: response.content,
      });
    } else {
      return response;
    }
  };

  const floatItems = [
    {
      icon: 'appointmentManager',
      fill: Greys.DARK_GREY,
      tooltipText: t('newAppointment_view.label_addAppointment'),
      onClick: async close => {
        close();
        const hasCredential = await getUserCredentials(
          ComponentTranslationKeys.PATIENT,
          ActionTranslationKeys.READ
        );
        if (hasCredential === true) {
          getUserCredentials(
            ComponentTranslationKeys.APPOINTMENT,
            ActionTranslationKeys.CREATE
          ).then(hasCredential => {
            if (hasCredential === true) {
              setShowAddModal({
                show: true,
              });
            }
          });
        }
      },
    },
  ];

  const eventCalendarHandler = async event => {
    try {
      await ApiClient.POST({
        url: `${serviceConfig.appointmentManager.updateFromCalendar.uri}/${event.id}`,
        payload: {
          patientId: event.data.patientId,
          unixEpochId: event.data.unixEpochId,
          name: event.data.text,
          start_date: event.start.toISOString(),
          end_date: event.end.toISOString(),
        },
      });
    } catch (error) {
      setDisplayFlashMsg({
        display: true,
        content: error.response.data.content,
        type: FlashMessageTypes.Error,
      });
    }
    setDidUpdate(true);
  };

  return (
    <>
      <BreadcrumbPortal>
        <Breadcrumb>
          <span aria-current="page">{t('nav.appointmentTab')}</span>
        </Breadcrumb>
      </BreadcrumbPortal>
      {displayFlashMeg.display && (
        <FlashMessage
          message={t(displayFlashMeg.content)}
          type={displayFlashMeg.type}
          onClick={() => {
            setDisplayFlashMsg({
              display: false,
              content: '',
              type: '',
            });
          }}
        />
      )}
      <div className="container-padding" style={{ height: '100%' }}>
        <Flex otherStyles={{ maxHeight: '100%' }}>
          <FiltersComponent
            displayFilters={displayFilters}
            statuses={appointmentStatuses}
            types={appointmentTypes}
            getFilters={({ statusesIds, typesIds }) => {
              setFilters({
                types: typesIds,
                statuses: statusesIds,
              });
            }}
          />
          <div
            className={`appointment-manager__calendar-widget${
              !displayFilters ? ' hidden' : ''
            }`}
          >
            <Box margin="0 0 10px 0" padding="10px">
              <Flex alignItems="center">
                <Box margin="0 15px 0 0">
                  <Checkbox
                    name="complexQuery"
                    checked={complexQuery}
                    label={t('types.componentType.customFilters')}
                    handleOnChange={e => {
                      complexQueryHandle(e);
                    }}
                  />
                </Box>
                {complexQuery && allFilters.length && defaultFilter > -1 ? (
                  <UsFilterTopWarning
                    message={t(
                      'patients_view.us-market-warning__patient-index-top'
                    )}
                    items={allFilters}
                    active={defaultFilter}
                    showUsWarningTopPage={showUsWarningTopPage}
                    onClick={(e, item) => {
                      setFilterKey({
                        name: item.key,
                        id: item.filterId,
                      });
                      setAuxState(
                        typeof item.rule == 'string'
                          ? JSON.parse(item.rule)
                          : item.rule
                      );
                      setForceRender(false);
                      setShowUsWarningTopPage(item.filterId !== 1);
                    }}
                    showEditIcon={filterKey && filterKey.id !== 1}
                    tooltip={t('actions.edit')}
                    onEditFilterClick={async () => {
                      const hasCredential = await getUserCredentials(
                        ComponentTranslationKeys.CUSTOM_FILTER,
                        ActionTranslationKeys.UPDATE
                      );
                      if (hasCredential === true) {
                        setDisplayEditFilter(true);
                      }
                    }}
                  />
                ) : null}
                {complexQuery && forceRender && customFilterData !== null && (
                  <ComplexSearch
                    filters={customFilterData.filterData}
                    rules={selectedRule}
                    getSql={value => {
                      setSqlQuery(value);
                    }}
                    hide
                  />
                )}
              </Flex>
            </Box>

            <Calendar
              events={events}
              onEventChange={eventCalendarHandler}
              onDoubleClickEvent={event => {}}
              filterAction
              filterActive={displayFilters}
              hidePatientDemographics={hidePatientDemographics}
              onFilter={() => {
                setDisplayFilters(!displayFilters);
              }}
              getRange={({ minDate, maxDate }) => {
                setRange({
                  minDate,
                  maxDate,
                });
              }}
              locale={calendarLocale()}
              currLang={currLang}
              key={currLang}
            />
          </div>
        </Flex>
      </div>
      <Modal
        title={t('common_labels.label_filters')}
        contentComponent={
          <FiltersModalContent
            statuses={appointmentStatuses}
            types={appointmentTypes}
            getFilters={({ statusesIds, typesIds }) => {
              setFiltersModal({
                types: typesIds,
                statuses: statusesIds,
              });
            }}
            currentFilters={filters}
          />
        }
        footerComponent={
          <FiltersModalFooter
            close={() => {
              setDisplayFilters(false);
              setDisplayModal(false);
            }}
            onApply={() => {
              setFilters(filtersModal);
              setDisplayFilters(false);
              setDisplayModal(false);
            }}
          />
        }
        openModal={displayModal}
        onClose={() => {
          setDisplayFilters(false);
          setDisplayModal(false);
        }}
        hideFlashMessage={HideFlashMessage}
      />
      <CreateAppointmentModal
        open={showAddModal.show}
        onClose={() => setShowAddModal({ show: false, data: null })}
        setFlash={flash => {
          setDisplayFlashMsg(flash);
        }}
        didUpdate={() => {
          setDidUpdate(true);
        }}
      />
      {displayEditFilter && (
        <EditFilter
          setFlashMessage={msg => {
            setDisplayFlashMsg({
              display: true,
              content: msg.content,
              type: msg.type,
            });
          }}
          currCustomFilterId={filterKey.id}
          closeModal={() => {
            setDisplayEditFilter(false);
          }}
          reloadFilters={() => {
            const updated = allFilters.find(
              filter => filter.filterId === filterKey.id
            );
            if (updated) {
              setAuxState(JSON.parse(updated.rule));
              setForceRender(false);
            }
          }}
        />
      )}
      <FloatButton menuItems={floatItems} />
    </>
  );
};

AppointmentManager.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withTranslation()(AppointmentManager);
