import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import { Flex, Button } from '@ui-common-files/components';
import { ButtonType } from '@ui-common-files/utils';
import { FlashMessageTypes } from '@ui-common-files/types';
import { ApiClient, ShowFlashMessage } from '@global/Services';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

import serviceConfig from '../../../serviceConfig.json';

const CareplanResultsModalFooter = ({ careplanData }) => {
  const [exportedData, setExportedData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const { user } = useSelector(state => state.session);
  const { closeModal, setFlashMessage } = useContext(PatientOverviewContext);
  const downloadCareplanResults = useRef(null);
  const { t } = useTranslation();

  const convertStringSourceToArray = stringSource => {
    return stringSource
      .substring(1)
      .replace(',,', ',"",')
      .slice(0, -1)
      .split('","');
  };

  const processCSVContent = content => {
    const processedContent = content.map(value => {
      if (typeof value === 'string') {
        return convertStringSourceToArray(value);
      } else {
        return value;
      }
    });
    return processedContent;
  };

  const processCareplanDataToBeExported = data => {
    const [nestedData] = data;
    const [first, ...rest] = nestedData;
    return [first, ...processCSVContent(rest)];
  };

  const exportCareplanResults = async () => {
    try {
      let hasSubScoreFormula = false;
      if (clicked) {
        const response = await Promise.all(
          careplanData.map(async careplan => {
            hasSubScoreFormula = careplan.template.hasSubScoreFormula;

            const requestData = {
              patientId: careplan.patient.id,
              isSignOffSheet: false,
              input1: '',
              input2: '',
              selectOption: '1',
              selectSpecificOption: careplan.qc.id,
            };

            const apiResponse = await ApiClient.POST({
              url: serviceConfig.brokerService.exportDataOfPatient.uri,
              payload: requestData,
            });

            return apiResponse.data;
          })
        );
        if (response && response.every(data => data !== null)) {
          setExportedData(processCareplanDataToBeExported(response));
        }
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      throw new Error(error);
    }
  };

  const generatePDF = async () => {
    const primaryInstitution = user.institutions.find(
      inst => inst.userInstitution.isPrimary
    );
    closeModal();
    setIsGeneratingPDF(true);
    ShowFlashMessage({
      type: FlashMessageTypes.Info,
      messageKey: 'flash.PDFGenerationStarted',
      isModalMessage: false,
    });
    const response = await ApiClient.GET({
      url: serviceConfig.brokerService.generatePdf.uri,
      payload: {
        careplanId: careplanData[0]?.qc?.id,
        primaryInstitution: primaryInstitution?.id,
      },
      timeout: 60000,
    });
    if (response) {
      ShowFlashMessage({
        type: FlashMessageTypes.Success,
        messageKey: careplanData[0]?.qc.isReportReview
          ? 'flash.ReviewReportsuccessfulGeneration'
          : 'flash.PDFsuccessfulGeneration',
        isModalMessage: false,
      });
    }
    setIsGeneratingPDF(false);
  };

  useEffect(() => {
    exportCareplanResults();
  }, [clicked]);

  useEffect(() => {
    if (exportedData.length && downloadCareplanResults.current) {
      downloadCareplanResults.current.link.click();
      closeModal();
    }
  }, [exportedData]);

  return (
    <Flex
      flexDirection="row"
      justifyContent="flex-end"
      otherStyles={{ flex: 1 }}
    >
      {careplanData[0].qc.isReportEnabled && (
        <Button
          style={{ marginRight: '10px' }}
          disabled={isGeneratingPDF}
          label={t('common_buttons.generatePdf')}
          type={ButtonType.SUBMIT}
          onClick={generatePDF}
          variant="outlined"
        />
      )}
      <div style={{ marginRight: '10px' }}>
        <CSVLink
          ref={downloadCareplanResults}
          style={{ display: 'none' }}
          filename={`${moment().format('YYYY-MM-DD')}.csv`}
          data={exportedData}
        />

        <Button
          label={t('patientOverview.exportResults')}
          type={ButtonType.SUBMIT}
          onClick={() => {
            setClicked(true);
          }}
          variant="outlined"
        />
      </div>
      <Button
        label={t('common_buttons.close')}
        type={ButtonType.SUBMIT}
        onClick={closeModal}
      />
    </Flex>
  );
};

export default CareplanResultsModalFooter;
