import IcdOps from '@type/Common/IcdOps.type';
import { Connection, Node, Edge } from 'reactflow';

import {
  hasCycle,
  getIncomingDepth,
  getMaxOutgoersDepth,
} from './CarePathWayBuilder.utils';
import { MAX_INCOMING_DEPTH } from './CarePathWayBuilder.constant';
import { generateWarningToast } from './CarePathWayBuilder.toast';

export const isValidConnection = (connection: Connection): boolean => {
  return connection.source !== connection.target;
};

export const validateAppointmentType = (
  appointmentType: Object | null
): boolean => {
  return appointmentType === null;
};

export const validateIcds = (icds: IcdOps[]): boolean => {
  return icds.length === 0;
};

export const validateCarePathwayName = (name: string): boolean => {
  return name.trim().length === 0;
};

export const isValidDepth = (
  sourceId: string,
  targetId: string,
  nodes: Node[],
  edges: Edge[],
  connection: Connection
) => {
  const maxOutgoersDepth = getMaxOutgoersDepth(targetId, nodes, edges);
  const incomingDepth = getIncomingDepth({ id: sourceId }, nodes, edges);

  if (maxOutgoersDepth + incomingDepth > MAX_INCOMING_DEPTH) {
    generateWarningToast('carePathway_builder.errors.limit_levels');
    return false;
  }

  const newEdges: Edge[] = [...edges, connection];
  if (hasCycle(nodes, newEdges)) {
    generateWarningToast('carePathway_builder.errors.link_loop');
    return false;
  }

  return true;
};
