import React from 'react';
import moment from 'moment';
import I18n from 'i18next';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import FlexBox from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import BodyChart from '../../../../caro-ui-commonfiles/components/BodyChart/BodyChart';

import {
  PrimaryColor,
  ActionColors,
} from '../../../../caro-ui-commonfiles/utils/colors';

const dateFormat = 'DD MMM YYYY';

export const SmallDot = props => {
  return (
    <Icon
      x={props.cx - 10}
      y={props.cy - 10}
      name="threshold"
      size={20}
      fill={props.color}
    />
  );
};

export const removeDuplicatedY = data => {
  const lookup = new Set();
  const nonDuplicatedY = data.filter(
    obj => !lookup.has(obj.y) && lookup.add(obj.y)
  );
  return nonDuplicatedY.length;
};

export const SingleChoiceGraph = ({ component, dataForVisualisation }) => {
  const thresholds = component.componentThresholds;
  const thresholdIndex =
    thresholds[0] &&
    thresholds[0].optionJson &&
    thresholds[0].optionJson.optionIndex;
  const data = [];
  const thresholdData = [];
  const optionsList = JSON.parse(JSON.stringify(component.optionJson.options));
  let yAxisWidth = 100;
  for (let i = 0; i < optionsList.length; i++) {
    let yText = optionsList[i];
    if (!hasWhiteSpace(yText)) {
      yText =
        yText.substring(0, 25) +
        ' ' +
        yText.substring(25, 50) +
        ' ' +
        yText.substring(50, 74);
      optionsList[i] = yText;
    }
    if (yText.length > 10 && yText.length <= 20) yAxisWidth = 150;
    else if (yText.length > 20 && yText.length <= 40) yAxisWidth = 200;
    else if (yText.length > 40 && yText.length <= 60) yAxisWidth = 250;
    else if (yText.length > 60) yAxisWidth = 320;
  }

  const { answers } = dataForVisualisation;
  if (answers) {
    answers.map(answer => {
      const dataElem = {};
      const answerIndex = answer.answer_json && answer.answer_json.optionIndex;
      const unixEpcs = new Date(answer.savedAt);
      dataElem.x = moment(unixEpcs)
        .locale(I18n.language)
        .format(dateFormat);
      dataElem.y = answerIndex;
      data.push(dataElem);
      if (
        thresholdIndex &&
        (thresholdIndex.includes(parseInt(answerIndex)) ||
          thresholdIndex.includes(answerIndex.toString()))
      ) {
        thresholdData.push(dataElem);
      }
    });
  }

  if (data.length) {
    return (
      <ScatterChart
        width={700}
        height={optionsList.length === 1 ? 180 : 64 * optionsList.length + 20}
        margin={{
          top: 20,
          right: 30,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="x"
          allowDuplicatedCategory={false}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <YAxis
          type="number"
          dataKey="y"
          allowDecimals={false}
          tickCount={optionsList.length}
          domain={[0, optionsList.length - 1]}
          tickFormatter={index => optionsList[index] || ''}
          allowDuplicatedCategory={false}
          width={yAxisWidth}
          maxLines={2}
          padding={{ bottom: 20, top: 20, left: 20, right: 20 }}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          formatter={(index, key) => {
            if (key === 'y') {
              return optionsList[index];
            } else {
              return index;
            }
          }}
        />
        <Scatter
          data={data}
          line={{ stroke: PrimaryColor.MAIN_COLOR, strokeWidth: 2 }}
          className="scatter-point"
        />
        <Scatter
          data={thresholdData}
          shape={<SmallDot color={ActionColors.THRESHOLD} width={100} />}
        />
      </ScatterChart>
    );
  }
  return null;
};

export const PainLocationGraph = ({ dataForVisualisation }) => {
  if (dataForVisualisation.answers) {
    const frontPos = [];
    const backPos = [];
    dataForVisualisation.answers.map(answer => {
      const answerX = answer.answer_x;
      const answerY = answer.answer_y;
      const pos = {
        marginTop: 333 * answerY - 22.5,
        marginLeft: 121 * answerX - 17.5,
      };
      if (answerX && answerY) {
        if (answer.back) {
          backPos.push(pos);
        } else {
          frontPos.push(pos);
        }
      }
    });
    return (
      <FlexBox alignItems="center">
        <Box margin="0 5px 0 0">
          <BodyChart circlePos={frontPos} type="front" onClick={() => {}} />
        </Box>
        <Box margin="0 10px 0 0">
          <BodyChart circlePos={backPos} type="back" onClick={() => {}} />
        </Box>
      </FlexBox>
    );
  }
  return null;
};

export const YesNoGraph = ({ component, dataForVisualisation }) => {
  const thresholds = component.componentThresholds;
  const yesNoData = [];
  const yesNoThreshold = [];
  if (dataForVisualisation.answers) {
    const threshold =
      thresholds[0] && thresholds[0].booleanValue == true ? 1 : 0;
    dataForVisualisation.answers.map(answer => {
      const dataElem = {};
      const answerIndex = answer.answer_boolean == true ? 1 : 0;
      const timestamp = answer.savedAt;
      const unixEpcs = new Date(timestamp);
      dataElem.x = moment(unixEpcs)
        .locale(I18n.language)
        .format(dateFormat);
      dataElem.y = answerIndex;
      yesNoData.push(dataElem);
      if (thresholds && thresholds.length && threshold == answerIndex) {
        yesNoThreshold.push(dataElem);
      }
    });
  }
  return (
    <ScatterChart
      width={600}
      height={148}
      margin={{
        top: 20,
        left: -30,
      }}
    >
      <CartesianGrid />
      <XAxis
        dataKey="x"
        type="category"
        allowDuplicatedCategory={false}
        tick={{
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '1.4',
          color: 'dark-grey',
        }}
      />
      <YAxis
        dataKey="y"
        type="number"
        allowDecimals={false}
        tickCount={2}
        domain={[0, 1]}
        allowDuplicatedCategory={false}
        width={100}
        height={200}
        maxLines={2}
        padding={{ bottom: 20, top: 20, left: 20, right: 20 }}
        tick={{
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '1.4',
          color: 'dark-grey',
        }}
      />
      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
      <Scatter
        data={yesNoData}
        line={{ stroke: PrimaryColor.MAIN_COLOR, strokeWidth: 2 }}
        className="scatter-point"
      />
      <Scatter
        data={yesNoThreshold}
        shape={<SmallDot color={ActionColors.THRESHOLD} />}
      />
    </ScatterChart>
  );
};

export const NumericGraph = ({ component, dataForVisualisation }) => {
  const thresholds = component.componentThresholds;
  const thresholdArray = thresholds.map(
    threshold => threshold.comparison + threshold.numericValue
  );
  const numericData = [];
  const numericThreshold = [];
  const { answers } = dataForVisualisation;
  if (answers) {
    answers.map(answer => {
      const dataElem = {};
      const { answer_numeric } = answer;
      const timestamp = answer.savedAt;
      const unixEpcs = new Date(timestamp);
      dataElem.x = moment(unixEpcs)
        .locale(I18n.language)
        .format(dateFormat);
      dataElem.y = answer_numeric;
      let thresholdAnswerEval = thresholds.length;
      thresholds.length &&
        thresholdArray.map(thresholdElem => {
          if (!eval(`${answer_numeric} ${thresholdElem}`)) {
            thresholdAnswerEval = false;
          }
        });
      if (thresholdAnswerEval) {
        numericThreshold.push(dataElem);
      }
      numericData.push(dataElem);
    });
  }
  if (numericData.length) {
    return (
      <ScatterChart
        width={600}
        height={numericData.length === 1 ? 180 : 64 * numericData.length + 20}
        margin={{
          top: 20,
          left: -30,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="x"
          type="category"
          allowDuplicatedCategory={false}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <YAxis
          dataKey="y"
          type="number"
          allowDecimals={false}
          allowDuplicatedCategory={false}
          width={100}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Scatter
          data={numericData}
          line={{ stroke: PrimaryColor.MAIN_COLOR, strokeWidth: 2 }}
          className="scatter-point"
        />
        <Scatter
          data={numericThreshold}
          shape={<SmallDot color={ActionColors.THRESHOLD} />}
        />
      </ScatterChart>
    );
  }
  return null;
};

export function hasWhiteSpace(s) {
  return s.indexOf(' ') >= 0;
}

export const MultipleChoiceGraph = ({ component, dataForVisualisation }) => {
  const thresholds = component.componentThresholds;
  const thresholdIndex =
    thresholds[0] &&
    thresholds[0].optionJson &&
    thresholds[0].optionJson.optionIndex;
  const multipleChoiceData = [];
  const multipleChoiceThreshold = [];
  const { answers } = dataForVisualisation;
  const optionsList = JSON.parse(JSON.stringify(component.optionJson.options));
  let yAxisWidth = 100;
  for (let i = 0; i < optionsList.length; i++) {
    let yText = optionsList[i];
    if (!hasWhiteSpace(yText)) {
      yText =
        yText.substring(0, 25) +
        ' ' +
        yText.substring(25, 50) +
        ' ' +
        yText.substring(50, 74);
      optionsList[i] = yText;
    }
    if (yText.length > 10 && yText.length <= 20) yAxisWidth = 150;
    else if (yText.length > 20 && yText.length <= 40) yAxisWidth = 200;
    else if (yText.length > 40 && yText.length <= 60) yAxisWidth = 250;
    else if (yText.length > 60) yAxisWidth = 320;
  }
  if (answers) {
    answers.map((answer, i) => {
      const timestamp = answer.savedAt;

      const unixEpcs = new Date(timestamp);

      const answerObject = answer.answer_json;
      for (const key in answerObject) {
        const dataElem = {};
        if (answerObject[key] == true) {
          dataElem.x = moment(unixEpcs)
            .locale(I18n.language)
            .format(dateFormat);
          dataElem.y = key;

          if (thresholdIndex && thresholdIndex.includes(parseInt(key))) {
            multipleChoiceThreshold.push(dataElem);
          }
          multipleChoiceData.push(dataElem);
        }
      }
    });
  }
  if (multipleChoiceData.length) {
    return (
      <ScatterChart
        width={700}
        height={optionsList.length === 1 ? 180 : 64 * optionsList.length + 20}
        margin={{
          top: 20,
          right: 30,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="x"
          interval={0}
          allowDuplicatedCategory={false}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <YAxis
          type="number"
          dataKey="y"
          allowDecimals={false}
          tickCount={optionsList.length}
          domain={[0, optionsList.length - 1]}
          tickFormatter={index => optionsList[index] || ''}
          allowDuplicatedCategory={false}
          width={yAxisWidth}
          maxLines={2}
          padding={{ bottom: 20, top: 20, left: 20, right: 20 }}
          tick={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '1.4',
            color: 'dark-grey',
          }}
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          formatter={(index, key) => {
            if (key === 'y') {
              return optionsList[index];
            } else {
              return index;
            }
          }}
        />
        <Scatter
          data={multipleChoiceData}
          fill={PrimaryColor.MAIN_COLOR}
          className="scatter-point__choice"
        />

        <Scatter
          data={multipleChoiceThreshold}
          shape={<SmallDot color={ActionColors.THRESHOLD} />}
        />
      </ScatterChart>
    );
  }
  return null;
};
