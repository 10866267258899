import React from 'react';

import usePatientShowModalService from './ShowPatientModal.service';
import { ShowPatient } from '../../Patients/ShowPatientModal';

import { ShowPatientData } from './ShowPatientModal.type';

function ShowPatientModal({
  show,
  data,
  healthScore,
  scoringSchemeCategory,
  setShowPatient,
}: ShowPatientData) {
  const { onCloseShowPatientModal } =
    usePatientShowModalService(setShowPatient);
  if (!show) {
    return null;
  }
  return (
    <ShowPatient
      show={show}
      data={data}
      healthScore={healthScore}
      scoringSchemeCategory={scoringSchemeCategory}
      onClose={onCloseShowPatientModal}
    />
  );
}

export default ShowPatientModal;
