import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

import {
  Box,
  Button,
  TextInput,
  FlashMessagesWrapper,
} from '@ui-common-files/components';
import { FlashMessageTypes } from '@ui-common-files/types/components';
import { ShowFlashMessage, HideFlashMessage } from '@global/Services';

import serviceConfig from '../../serviceConfig.json';

import LoginHeader from './Login/LoginHeader';
import LoginFooter from './Login/LoginFooter';
import CustomLoader from './Login/CustomLoader';
import NavigationJSON from '../Utils/navigation.json';

import '../css/login.scss';

const Reset = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errMsgKey, setErrMsgKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const passwordFormat = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#\$%\^&\*]).*$/;
  const history = useHistory();
  const { token } = useParams();

  const handleGoHome = () => history.push('/', { logout: true });

  const onChangePasswordText = event => setPassword(event.target.value);

  const onChangeConfirmPasswordText = event =>
    setConfirmPassword(event.target.value);

  const changePassword = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        serviceConfig.brokerService.resetPassword.uri + token,
        {
          password,
          userLanguage: I18n.language,
          isReset: true,
        }
      );
      if (response) {
        const flashMessageForLogin = {
          type: response.data.type,
          messageKey: response.data.content,
        };
        if (response.data.type == 'warning') {
          setIsLoading(false);
          ShowFlashMessage(flashMessageForLogin);
        } else {
          history.push(`/${NavigationJSON.LOGIN}`, flashMessageForLogin);
        }
      } else throw new Error();
    } catch (error) {
      setIsLoading(false);
      ShowFlashMessage({
        type: FlashMessageTypes.Error,
        messageKey: 'flash.unexpectedError',
      });
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    setErrMsgKey('');
    if (!password) {
      setErrMsgKey('parsley.emptyPassword');
    } else if (!passwordFormat.test(password)) {
      setErrMsgKey('parsley.passwordPattern');
    } else if (password !== confirmPassword) {
      setErrMsgKey('parsley.passwordsDontMatch');
    } else {
      changePassword();
    }
  };

  return (
    <Box width="100%" height="100%">
      <div className="login-container">
        <FlashMessagesWrapper hideFlashMessage={HideFlashMessage} />
        <LoginHeader handleGoHome={handleGoHome} />

        <div className="login-content-container">
          <div className="credential-container">
            <form
              className="login__widget-panel__container"
              onSubmit={handleFormSubmit}
            >
              <div className="login-header__wrapper">
                <span className="login-header">
                  {I18n.t('common_labels.label_resetYourPassword')}
                </span>
              </div>

              <div className="login-info-container login__text-rows-spacing">
                <span className="privacy-policy-text">
                  {I18n.t('common_labels.password_rules_intro')}
                </span>
              </div>
              <div className="login-info-container">
                <span className="privacy-policy-text reset-password-rules-list">
                  {ReactHtmlParser(I18n.t('common_labels.password_rules_list'))}
                </span>
              </div>

              <div className="Text-inputContainer">
                <TextInput
                  id="Password"
                  name="Password"
                  placeholder={I18n.t('common_labels.placeholder_newPassword')}
                  hasError={!!errMsgKey}
                  rightHelperText="words"
                  iconName="administration"
                  handleOnChange={onChangePasswordText}
                  value={password}
                  type="password"
                />

                <div className="Text-inputstyle">
                  <TextInput
                    id="ConfirmPassword"
                    name="ConfirmPassword"
                    placeholder={I18n.t(
                      'common_labels.placeholder_confirmPassword'
                    )}
                    hasError={!!errMsgKey}
                    rightHelperText="words"
                    iconName="administration"
                    handleOnChange={onChangeConfirmPasswordText}
                    value={confirmPassword}
                    type="password"
                  />
                </div>
              </div>

              {errMsgKey && (
                <div className="err-msg">
                  {ReactHtmlParser(I18n.t(errMsgKey))}
                </div>
              )}

              <div className="button-container">
                {isLoading ? (
                  <CustomLoader />
                ) : (
                  <Button
                    label={I18n.t('common_labels.label_ChangePassword')}
                    type="submit"
                    fullWidth
                    size="md"
                  />
                )}
              </div>
            </form>

            <LoginFooter />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default withTranslation()(Reset);
