/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import I18n from 'i18next';
import axios from 'axios';

import { Box, Button, Flex, Icon, Tag } from '@ui-common-files/components';
import { TagsType, PrimaryColor } from '@ui-common-files/utils';
import {
  Freetext,
  NumericComponent,
  SingleChoice,
  MultipleChoice,
  YesNoComponent,
  PainLocationChart,
  GroupDescriptionComponent,
  UploadFile,
  Datepicker,
  InformationComponent,
} from './AnswerElements';
import AnswerBMI from './AnswerBMI';
import AnswerBloodPressure from './AnswerBloodPressure';
import {
  SingleChoiceGraph,
  YesNoGraph,
  NumericGraph,
  MultipleChoiceGraph,
  PainLocationGraph,
} from './AnswerGraphs';
import ComponentType from '../../../Utils/ComponentType';
import serviceConfig from '../../../../serviceConfig.json';
import { PatientOverviewContext } from '../../PatientOverview/PatientOverview';
import componentTypeOrder from '../careplanComponentTypeOrder';
import { ButtonVariant } from '../../../../caro-ui-commonfiles/utils/button';
import { parseComponentName, isAnswerSkipped } from '../CareplanHelperUtility';
import '../../../css/careplans.scss';
import {
  renderTagsForNumericCompononet,
  renderFormattedJumpData,
} from '../LogicJump/logicJumpTags';

function AnswerCardComponent({
  careplanComponent,
  index,
  variant,
  isEvaluating,
  isAdditionalComponent = false,
  scores,
}) {
  const { setFlashMessage, closeModal } = useContext(PatientOverviewContext);
  const [dataForVisualisation, setDataForVisualisation] = useState({});
  const [showVisualisation, setShowVisualisation] = useState(false);

  const id = careplanComponent.careplanCareplanComponentMapper.careplanId;
  const careplanCareplanComponentMapperId =
    careplanComponent.careplanCareplanComponentMapper.id;
  const componentId = careplanComponent.id;
  const componentTypeId = careplanComponent.careplanComponentTypeId;
  const hasSubScores = scores && scores.length > 0;

  const DefaultContent = ({ careplanComponent }) => {
    const answers = careplanComponent.answers[0];
    const logicJumpDataForTags = renderFormattedJumpData(careplanComponent);
    return (
      <Box className="threshold-component__content">
        {(answers && isAnswerSkipped(componentTypeId, answers)) ||
          careplanComponent.answers.length === 0 ? (
          <h3 className="select-patient">
            {I18n.t('QuestionCatalog_view.patientSkippedQuestion')}
          </h3>
        ) : (
          <>
            {getDefaultComponentCareplan(careplanComponent, hasSubScores)}
            {(componentTypeId === componentTypeOrder.NUMERIC_VALUE ||
              componentTypeId === componentTypeOrder.NUMERIC_RANGE) &&
              logicJumpDataForTags.isJumpSet &&
              logicJumpDataForTags.logicJumpCollection &&
              logicJumpDataForTags.logicJumpCollection.length > 0 && (
                <Box margin="10px 0 0 0">
                  {renderTagsForNumericCompononet(logicJumpDataForTags)}
                </Box>
              )}
            <Box className="threshold-component__content-list">
              {componentTypeId !== componentTypeOrder.SORTABLE &&
                componentTypeId !== componentTypeOrder.FREE_TEXT &&
                componentTypeId !== componentTypeOrder.UPLOAD_FILE &&
                componentTypeId !== componentTypeOrder.DATEPICKER &&
                componentTypeId !== componentTypeOrder.INFORMATION &&
                componentTypeId !== componentTypeOrder.BMI &&
                componentTypeId !== componentTypeOrder.BLOOD_PRESSURE ? (
                <Box margin="20px 0 5px 0">
                  <Button
                    variant={ButtonVariant.NO_BORDER}
                    onClick={() => setShowVisualisation(!showVisualisation)}
                    label={
                      showVisualisation
                        ? I18n.t('newCareplan_view.hideVisualisation')
                        : I18n.t('newCareplan_view.showVisualisation')
                    }
                  />
                </Box>
              ) : null}
            </Box>
            {showVisualisation && renderVisualisation(careplanComponent)}
          </>
        )}
      </Box>
    );
  };
  const getComponentAnswersAllDates = async componentId => {
    try {
      const response = await axios.get(
        `${serviceConfig.brokerService.getCareplanAnswersAllDates.uri + id
        }/${componentId}/${careplanCareplanComponentMapperId}`
      );
      if (response) {
        setDataForVisualisation(response.data);
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      throw new Error(error.response);
    }
  };

  useEffect(() => {
    if (showVisualisation) getComponentAnswersAllDates(componentId);
  }, [showVisualisation]);

  const getDefaultComponentCareplan = (component, hasSubScores) => {
    const componentId = component.careplanComponentTypeId;
    switch (componentId) {
      case componentTypeOrder.FREE_TEXT:
        return <Freetext component={component} />;
      case componentTypeOrder.SINGLE_CHOICE:
        return (
          <SingleChoice
            component={component}
            isEvaluating={isEvaluating}
            hasSubScores={hasSubScores}
          />
        );
      case componentTypeOrder.MULTIPLE_CHOICE:
      case componentTypeOrder.SORTABLE:
        return (
          <MultipleChoice
            component={component}
            isEvaluating={isEvaluating}
            hasSubScores={hasSubScores}
          />
        );
      case componentTypeOrder.NUMERIC_RANGE:
      case componentTypeOrder.NUMERIC_VALUE:
        return <NumericComponent component={component} />;
      case componentTypeOrder.YESNO:
        return <YesNoComponent component={component} />;
      case componentTypeOrder.INFORMATION:
        return (
          <InformationComponent
            component={component}
            isEvaluating={isEvaluating}
            hasSubScores={hasSubScores}
          />
        );
      case componentTypeOrder.PAIN_LOCATION_CHART:
        return <PainLocationChart component={component} />;
      case componentTypeOrder.GROUP_DESCRIPTION:
        return <GroupDescriptionComponent component={component} />;
      case componentTypeOrder.UPLOAD_FILE:
        return <UploadFile component={component} />;
      case componentTypeOrder.BMI:
        return (
          <AnswerBMI
            component={component}
            isEvaluating={isEvaluating}
            hasSubScores={hasSubScores}
          />
        );
      case componentTypeOrder.BLOOD_PRESSURE:
        return (
          <AnswerBloodPressure
            component={component}
            isEvaluating={isEvaluating}
            hasSubScores={hasSubScores}
          />
        );
      case componentTypeOrder.DATEPICKER:
        return <Datepicker component={component} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    renderVisualisation(careplanComponent);
  }, [dataForVisualisation]);

  const AnalyticScore = ({ score }) => {
    return (
      <Box className="threshold-component__name">
        <Tag
          text={`${I18n.t('analytic_view.Score')} ${score}`}
          fill={PrimaryColor.MAIN_COLOR}
          type={TagsType.SQUARED}
        />
      </Box>
    );
  };


  const AnalyticScoreView = ({
    weight,
    value,
    showWeight,
    showValue,
    isEvaluating,
    hasSubScores,
  }) => {
    if (isEvaluating || hasSubScores) {
      if (showWeight) {
        return <AnalyticScore score={weight} />;
      }

      if (showValue) {
        return <AnalyticScore score={value} />;
      }
    }
    return <></>;
  };
  const renderVisualisation = component => {
    const componentId = component.careplanComponentTypeId;
    switch (componentId) {
      case componentTypeOrder.SINGLE_CHOICE:
        return (
          <SingleChoiceGraph
            component={component}
            dataForVisualisation={dataForVisualisation}
          />
        );
      case componentTypeOrder.PAIN_LOCATION_CHART:
        return (
          <PainLocationGraph dataForVisualisation={dataForVisualisation} />
        );
      case componentTypeOrder.YESNO:
      case componentTypeOrder.INFORMATION:
      case componentTypeOrder.GROUP_DESCRIPTION:
        return (
          <YesNoGraph
            component={component}
            dataForVisualisation={dataForVisualisation}
          />
        );
      case componentTypeOrder.NUMERIC_RANGE:
      case componentTypeOrder.NUMERIC_VALUE:
        return (
          <NumericGraph
            component={component}
            dataForVisualisation={dataForVisualisation}
          />
        );
      case componentTypeOrder.MULTIPLE_CHOICE:
      case componentTypeOrder.SORTABLE:
        return (
          <MultipleChoiceGraph
            component={component}
            dataForVisualisation={dataForVisualisation}
          />
        );
      default:
        return null;
    }
  };

  const { weight, careplanComponentTypeId } = careplanComponent;
  const hasWeights =
    careplanComponent.optionWeights != 'null' &&
    careplanComponent.optionWeights != null;
  const optionWeights =
    hasWeights && JSON.parse(careplanComponent.optionWeights).weights;
  const allZeroWeight = hasWeights && optionWeights.every(item => item == 0);
  let showWeight = !(
    careplanComponentTypeId === componentTypeOrder.SINGLE_CHOICE ||
    careplanComponentTypeId === componentTypeOrder.MULTIPLE_CHOICE ||
    careplanComponentTypeId === componentTypeOrder.SORTABLE ||
    careplanComponentTypeId === componentTypeOrder.BMI ||
    careplanComponentTypeId === componentTypeOrder.BLOOD_PRESSURE
  );
  const isComponentNumeric =
    careplanComponentTypeId === componentTypeOrder.NUMERIC_VALUE ||
    careplanComponentTypeId === componentTypeOrder.NUMERIC_RANGE;
  const numericValue =
    careplanComponent.answers[0] && careplanComponent.answers[0].answer_numeric;
  const isScoringEnabled = careplanComponent.isScoreEnabled;
  const isNumericWithValue = isComponentNumeric && numericValue && isScoringEnabled;

  if (
    (weight == 0 || weight == null) &&
    (!hasWeights || (optionWeights.length && allZeroWeight))
  )
    showWeight = false;
  const { questionType, iconName } = ComponentType(componentTypeId);
  const { isMandatory } = careplanComponent.careplanCareplanComponentMapper;

  return (
    <Box
      style={{ wordBreak: 'break-word' }}
      className={`threshold-component${variant}`}
    >
      <Flex justifyContent="space-between">
        <Flex>
          <Box
            className={`threshold-component__icon ${isAdditionalComponent
              ? 'threshold-component__icon-additional'
              : ''
              }`}
          >
            <Icon name={iconName.toString()} size={24} />
            <span>{index}</span>
          </Box>
          <Box className="threshold-component__name" otherStyles={{ display: 'block' }}>
            {parseComponentName(careplanComponent.name)}
            {isMandatory && (
              <span>
                &nbsp;*
              </span>
            )}
          </Box>
        </Flex>
        <AnalyticScoreView
          weight={weight}
          value={numericValue}
          showWeight={showWeight && !isNumericWithValue}
          showValue={isNumericWithValue}
          isEvaluating={isEvaluating}
          hasSubScores={hasSubScores}
        />
        <Box className="threshold-component__type">{`- ${questionType} -`}</Box>
      </Flex>
      <DefaultContent careplanComponent={careplanComponent} />
    </Box>
  );
}

export default AnswerCardComponent;
