/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import Loader from 'react-loader-spinner';

import Box from '../../../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../../../../../caro-ui-commonfiles/components/Icon/Icon';
import DropdownMenu from '../../../../../../../caro-ui-commonfiles/components/DropdownMenu/DropdownMenu';
import Button from '../../../../../../../caro-ui-commonfiles/components/Button/Button';
import UploadedFileViewer from './UploadedFileViewer';
import UploadFileModal from './UploadFileModal';

import { PrimaryColor } from '../../../../../../../caro-ui-commonfiles/utils/colors';

import useAnsweringFileRequestService from './AnsweringFileRequest.service';
import { AnsweringComponentsProps } from '../AnsweringTypes';

const UploadFileContext = React.createContext(null);

export default function AnsweringFileRequest({
  handleOnChange,
  setFlashMessage,
}: AnsweringComponentsProps): JSX.Element {
  const {
    t,
    imageUploader,
    openModal,
    setOpen,
    clearInputValueOnClick,
    handleUploadFileFromPC,
    handleAttachmentButtonClick,
    selectedFile,
    setSelectedFile,
    fileActiveTab,
    setFileActiveTab,
    fileIsUploading,
  } = useAnsweringFileRequestService({ handleOnChange, setFlashMessage });

  const items = [
    { key: 'uploadFile', value: t('asset_view.uploadedFiles') },
    { key: 'browsePc', value: t('asset_view.browsePc') },
  ];

  const buildItems = () => {
    return items.map((item, index) => {
      return (
        <li
          key={item.key}
          onClick={e => handleAttachmentButtonClick(item, index)}
          style={{ width: '160px' }}
        >
          <span>{item.value}</span>
        </li>
      );
    });
  };

  return (
    <UploadFileContext.Provider
      value={{
        selectedFile,
        setSelectedFile,
        fileActiveTab,
        setFileActiveTab,
      }}
    >
      <Flex otherStyles={{ marginLeft: '35%', marginTop: '15px' }}>
        <input
          type="file"
          id="fileUpload"
          onChange={handleUploadFileFromPC}
          onClick={clearInputValueOnClick}
          ref={imageUploader}
          hidden
        />
        <DropdownMenu
          items={buildItems()}
          variant="button-dropdown"
          onClick={handleAttachmentButtonClick}
        >
          <Button
            label={t('common_buttons.uploadFile')}
            disabled={selectedFile || fileIsUploading}
          >
            <Icon name="attachment" size={26} />
          </Button>
        </DropdownMenu>
      </Flex>
      <UploadFileModal
        openModal={openModal}
        setOpenModal={setOpen}
        setFlashMessage={setFlashMessage}
      />
      {fileIsUploading && (
        <Box className="answer-view__container-loading">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={40}
            width={40}
          />
          <Box>{t('flash.assetCreated')}</Box>
        </Box>
      )}
      {selectedFile && !openModal && (
        <UploadedFileViewer
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      )}
    </UploadFileContext.Provider>
  );
}
export { UploadFileContext };
