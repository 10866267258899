/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';

import moment from 'moment';
import { HideFlashMessage } from '@global/Services';

import ModalContent from '../../../../caro-ui-commonfiles/components/Modals/ModalContent';
import Modal from '../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import {
  ButtonType,
  ButtonVariant,
} from '../../../../caro-ui-commonfiles/utils/button';
import Stepper from '../../../../caro-ui-commonfiles/components/Stepper/Stepper';
import Step from '../../../../caro-ui-commonfiles/components/Stepper/Step';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import UsFilterTopWarning from '../../../../caro-ui-commonfiles/components/UsFilterWarnings/UsFilterTopWarning';

import PatientsForAssign from '../../PatientForAssign';
import '../../../css/carepathway.css';
import ScrollDownComponent from '../../../Utils/ScrollDownComponent';
import {
  checkUnder18Patients,
  isAssessmentWithIsRoot,
} from '../CareplanHelperUtility';
import checkUserCredential from '../../../Utils/checkUserCredential';
import {
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../../../caro-ui-commonfiles/utils/componentTypes';
import { PromptContent, PromptFooter } from '../../common/ConfirmationPrompt';
import { SubmissionTypes } from '../../../../caro-ui-commonfiles/utils/componentTypes';

import {
  assignCarePathway,
  assignCarePathwayToPatients,
  getPathwayTemplateDetails,
} from './CarePathwayApiCalls';
import {
  CarePathwayModalStep3,
  ChooseAppointment,
  AsignSendStep,
} from './CarePathwayModalContentComponents';
import { nonMedical } from '../../../../config';

import { convertLinkToBlob } from '../../../Utils/Asset/assetHelper';
import useAllTodoAssessments from 'src/Views/Notifications/Notification.hooks';
import { checkIfLogicJumpsThresholdsAndScore } from './Carepathway.utils';

const CarePathwayAssignHeader = ({ currentStep }) => {
  return (
    <Box width="70%" height={60}>
      <Stepper currentStep={currentStep}>
        <Step text={('Step 1 text', I18n.t('steps.choosePatients'))} />
        <Step
          text={
            ('Step 2 text',
            I18n.t('steps.carePathwayAssignment_appointmentSettings'))
          }
        />
        <Step text={('Step 3 text', I18n.t('steps.thresholdSettings'))} />
        <Step text={('Step 4 text', I18n.t('steps.assignAndSend'))} />
      </Stepper>
    </Box>
  );
};

const CarePathwayAssignContent = ({
  carePathwayId,
  currentStep,
  setCurrentStep,
  selectedPatientsData,
  setSelectedPatientsData,
  isSelectAll,
  setIsSelectAll,
  nbrPatientsSelected,
  setNbrPatientsSelected,
  setFlashMessage,
  setBottomPageReached,
  setUsWarningCheckboxChecked,
  usWarningCheckboxChecked,
  setUnder18,
  sendToPatient,
  close,
  hasThresholds,
  setHasThresholds,
  handleSelectedCareplans,
  selectedCareplans,
  setDisableNext,
  isAdhoc,
  setIsAdhoc,
  appointmentDate,
  setAppointmentDate,
  appointmentTime,
  setAppointmentTime,
  isUnspecified,
  setIsUnspecified,
  allFutureCareplans,
  carePathway,
  setCarePathway,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openPopupConfirmation, setOpenPopupConfirmation] = useState(false);
  const [isBeforeCurrentTime, setIsBeforeCurrentTime] = useState(false);

  useEffect(() => {
    const initPathwayModal = () => {
      assignCarePathway(carePathwayId, setCarePathway, setFlashMessage);
    };
    initPathwayModal();
  }, []);

  const { getAllTodos, allTodoAssessmentsController } = useAllTodoAssessments();

  useEffect(() => {
    const abortController = new AbortController();
    const processSelectedPatientsAvatar = async () => {
      if (currentStep === 3) {
        const processedPatients = await Promise.all(
          selectedPatientsData.map(async patient => {
            if (patient.profilePicture.previewURL) {
              await convertLinkToBlob(patient.profilePicture.previewURL)
                .then(blob => {
                  patient.profilePicture = blob;
                })
                .catch(() => {
                  patient.profilePicture = patient.profilePicture.previewURL;
                });
            } else if (
              !patient.profilePicture.includes('blob:https://') &&
              !patient.profilePicture.includes('data:image;base64')
            ) {
              patient.profilePicture = `data:image;base64,${patient.profilePicture}`;
            }
            return patient;
          })
        );
        setSelectedPatientsData(processedPatients);
      }
    };
    if (currentStep === 2) processSelectedPatientsAvatar();
    if (currentStep === 3) {
      const templatesIds = carePathway.templates.map(template => template.id);
      fetchCarepathwayCaretasks(templatesIds, abortController.signal);
    }

    return () => abortController.abort();
  }, [currentStep]);

  const fetchCarepathwayCaretasks = async (templatesIds, signal) => {
    const pathwayCaretasks = await getPathwayTemplateDetails(
      templatesIds,
      setIsLoading,
      handleSelectedCareplans,
      selectedCareplans,
      signal
    );
    if (pathwayCaretasks.length) {
      checkIfLogicJumpsThresholdsAndScore(
        pathwayCaretasks,
        currentStep,
        setCurrentStep,
        setHasThresholds
      );
    }
  };

  const checkpatientUnder18 = () => {
    if (
      checkUnder18Patients(selectedPatientsData) &&
      (nonMedical == false || nonMedical == 'false')
    ) {
      setUnder18(true);
      setBottomPageReached(true);
      return true;
    }
    setUnder18(false);
    return false;
  };

  const onSubmit = e => {
    const isPatientOverView = false;
    e.preventDefault();
    if (currentStep === 1 && nbrPatientsSelected) {
      setCurrentStep(currentStep + 1);
    }
    if (currentStep === 2) {
      if (allFutureCareplans || isBeforeCurrentTime) {
        setCurrentStep(currentStep + 1);
      } else {
        setOpenPopupConfirmation(true);
      }
    }
    if (currentStep === 3) {
      setCurrentStep(currentStep + 1);
    }
    if (currentStep === 4) {
      const patientIds = selectedPatientsData.map(patient => patient.id);
      close();
      setFlashMessage({
        type: 'info',
        content: `${I18n.t(
          'newCareplan_view.carepathwayAssignProgressStart'
        )} "${carePathway.name}" ${I18n.t(
          'newCareplan_view.carepathwayAssignProgress'
        )}`,
      });

      const referenceDate = isAdhoc
        ? moment(appointmentDate)
            .hour(appointmentTime.hour)
            .minute(appointmentTime.minute)
            .toISOString()
        : null;
      assignCarePathwayToPatients(
        carePathwayId,
        patientIds,
        setFlashMessage,
        sendToPatient,
        selectedCareplans,
        carePathway,
        isPatientOverView,
        isAdhoc,
        referenceDate,
        null,
        true
      ).finally(() => {
        const hasAssessment = selectedCareplans.some(
          careplan => careplan.template.isAssessment
        );
        if (hasAssessment) {
          getAllTodos();
        }
      });
    }
    return () => allTodoAssessmentsController.abort();
  };

  return (
    <ModalContent>
      <form
        id="form-assign-carepathway"
        onSubmit={onSubmit}
        className="careplan-flex__ver-2col"
      >
        {currentStep === 1 && (
          <PatientsForAssign
            isSelectAll={isSelectAll}
            setIsSelectAll={setIsSelectAll}
            nbrPatientsSelected={nbrPatientsSelected}
            setNbrPatientsSelected={setNbrPatientsSelected}
            selectedPatientsData={selectedPatientsData}
            setSelectedPatientsData={setSelectedPatientsData}
            setDisableNext={setDisableNext}
          />
        )}

        {currentStep === 2 && (
          <ChooseAppointment
            carePathwayData={carePathway}
            isPatientOverView={false}
            isAdhoc={isAdhoc}
            setIsAdhoc={setIsAdhoc}
            appointmentDate={appointmentDate}
            setAppointmentDate={setAppointmentDate}
            appointmentTime={appointmentTime}
            setAppointmentTime={setAppointmentTime}
            isUnspecified={isUnspecified}
            setIsUnspecified={setIsUnspecified}
            patient={selectedPatientsData}
            setOpenPopupConfirmation={setOpenPopupConfirmation}
            openPopupConfirmation={openPopupConfirmation}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setIsBeforeCurrentTime={setIsBeforeCurrentTime}
          />
        )}

        {currentStep === 3 &&
          (checkpatientUnder18() && hasThresholds ? (
            <Box margin="auto 0" padding="0 60px">
              <UsFilterTopWarning
                message={I18n.t('newCareplan_view.under18')}
                showUsWarningTopPage
                showEditIcon={false}
                showFilter={false}
              />
            </Box>
          ) : (
            <CarePathwayModalStep3
              careplanData={selectedCareplans}
              isLoading={isLoading}
              setBottomPageReached={setBottomPageReached}
              usWarningCheckboxChecked={usWarningCheckboxChecked}
              setUsWarningCheckboxChecked={setUsWarningCheckboxChecked}
            />
          ))}

        {currentStep === 4 && (
          <AsignSendStep
            carePathwayData={carePathway}
            selectedPatientsData={selectedPatientsData}
          />
        )}
      </form>

      <Modal
        isConfirmationDialog={true}
        title={I18n.t('chooseAppointment_view.popupTitle')}
        contentComponent={
          <PromptContent
            message={I18n.t('chooseAppointment_view.popupConfirmationMessage')}
          />
        }
        footerComponent={
          <PromptFooter
            close={() => setOpenPopupConfirmation(false)}
            confirmHandler={() => {
              setCurrentStep(currentStep + 1);
            }}
            submissionType={SubmissionTypes.CONFIRM}
            btnLabelRight={I18n.t('chooseAppointment_view.acceptWarningButton')}
          />
        }
        openModal={openPopupConfirmation}
        onClose={() => setOpenPopupConfirmation(false)}
        hideFlashMessage={HideFlashMessage}
      />
    </ModalContent>
  );
};

const CarePathwayAssignFooter = ({
  currentStep,
  setCurrentStep,
  bottomPageReached,
  usWarningCheckboxChecked,
  nbrPatientsSelected,
  under18,
  setUnder18,
  setSendToPatient,
  hasThreshold,
  disableNext,
  triggerTrackAssigment,
  carePathway,
}) => {
  const [showError, setShowError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [hasSendCredential, setHasSendCredential] = useState(false);

  let hasOnlyAssessment = isAssessmentWithIsRoot(carePathway);

  useEffect(() => {
    if (currentStep === 3) {
      const isDisable =
        !usWarningCheckboxChecked.awareCheckbox ||
        !usWarningCheckboxChecked.confirmCheckbox ||
        under18;
      setDisableButton(isDisable);
    }
    if (currentStep === 1) {
      setDisableButton(false);
      setUnder18(false);
    }
  }, [currentStep, usWarningCheckboxChecked, under18]);

  useEffect(() => {
    const checkSendCredential = async () => {
      const hasSendCarePathwayTemplateCredential = await checkUserCredential(
        ComponentTranslationKeys.CAREPATHWAY_TEMPLATE,
        ActionTranslationKeys.SEND
      );
      const hasSendCareplanTemplateCredential = await checkUserCredential(
        ComponentTranslationKeys.CAREPLAN_TEMPLATE,
        ActionTranslationKeys.SEND
      );
      if (
        hasSendCarePathwayTemplateCredential === true &&
        hasSendCareplanTemplateCredential === true
      ) {
        setHasSendCredential(true);
      }
    };
    checkSendCredential();
  }, []);

  return (
    <Flex justifyContent="space-between" otherStyles={{ width: '100%' }}>
      <Box>
        <>
          {currentStep === 1 && (
            <Flex flexDirection="row" alignItems="center">
              {showError && !nbrPatientsSelected ? (
                <>
                  <Box margin="5px 5px 0 0">
                    <Icon name="error" size={20} fill={ActionColors.ERROR} />
                  </Box>
                  <Box>
                    <span className="select-patient">
                      {I18n.t('warningMessage.notSelectedPatient')}
                    </span>
                  </Box>
                </>
              ) : null}
            </Flex>
          )}
          {currentStep > 1 && (
            <Button
              label={I18n.t('common_buttons.back')}
              type={ButtonType.BUTTON}
              onClick={() => {
                if (currentStep === 4 && !hasThreshold) {
                  setCurrentStep(currentStep - 2);
                } else {
                  setCurrentStep(currentStep - 1);
                }
                setDisableButton(false);
              }}
              variant={ButtonVariant.OUTLINED}
            />
          )}
        </>
      </Box>
      <Flex alignItems="center">
        {currentStep === 3 && !bottomPageReached && <ScrollDownComponent />}
        {currentStep < 4 && (
          <Button
            label={I18n.t('common_buttons.next')}
            type={ButtonType.SUBMIT}
            form="form-assign-carepathway"
            variant={ButtonVariant.CONTAINED}
            disabled={disableButton || disableNext}
            onClick={() => {
              if (!nbrPatientsSelected) {
                setShowError(true);
              } else {
                setShowError(false);
              }
            }}
          />
        )}
        {currentStep === 4 && (
          <Button
            label={I18n.t('assignTemplate_view.addToAssignments')}
            type={ButtonType.SUBMIT}
            form="form-assign-carepathway"
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              triggerTrackAssigment('carepathway');
              setSendToPatient(false);
            }}
          />
        )}
        {currentStep === 4 && hasSendCredential && !hasOnlyAssessment && (
          <Box margin="0 0 0 10px">
            <Button
              label={I18n.t('assignTemplate_view.send_immediately')}
              form="form-assign-carepathway"
              type={ButtonType.SUBMIT}
              onClick={() => {
                triggerTrackAssigment('carepathway');
                setSendToPatient(true);
              }}
              variant={ButtonVariant.CONTAINED}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

CarePathwayAssignHeader.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

CarePathwayAssignContent.propTypes = {
  carePathwayId: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setFlashMessage: PropTypes.func.isRequired,
  setBottomPageReached: PropTypes.func.isRequired,
  setUsWarningCheckboxChecked: PropTypes.func.isRequired,
  usWarningCheckboxChecked: PropTypes.shape({
    awareCheckbox: PropTypes.bool,
    confirmCheckbox: PropTypes.bool,
  }).isRequired,
  setUnder18: PropTypes.bool.isRequired,
  selectedPatientsData: PropTypes.objectOf(PropTypes.object).isRequired,
  setSelectedPatientsData: PropTypes.func.isRequired,
  setIsSelectAll: PropTypes.func.isRequired,
  isSelectAll: PropTypes.bool.isRequired,
  nbrPatientsSelected: PropTypes.number.isRequired,
  setNbrPatientsSelected: PropTypes.func.isRequired,
  sendToPatient: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

CarePathwayAssignFooter.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  bottomPageReached: PropTypes.bool.isRequired,
  usWarningCheckboxChecked: PropTypes.shape({
    awareCheckbox: PropTypes.bool,
    confirmCheckbox: PropTypes.bool,
  }).isRequired,
  nbrPatientsSelected: PropTypes.number.isRequired,
  under18: PropTypes.bool.isRequired,
  setUnder18: PropTypes.func.isRequired,
  setSendToPatient: PropTypes.func.isRequired,
};

export {
  CarePathwayAssignContent,
  CarePathwayAssignFooter,
  CarePathwayAssignHeader,
};
