import { SCREEN_CATEGORIES } from '@ui-common-files/utils/layout';

export const getMarginRight = (screenSize: number): number => {
  let marginRightValue;
  switch (screenSize) {
    case SCREEN_CATEGORIES.SCREEN_SM_IPAD:
      marginRightValue = 36;
      break;
    case SCREEN_CATEGORIES.SCREEN_MD:
      marginRightValue = 6;
      break;
    default:
      marginRightValue = -6;
  }
  return marginRightValue;
};
