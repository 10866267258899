import I18n from 'i18next';
import Moment from 'moment';
import { personNameRegex } from '@global/Utils';
import { ScoringTypes } from '../../types/Careplan';
import validateFirstnameLastname from '../hooks/validateFirstnameLastname';
import { FieldTypes } from '../../Views/Patients/Filter/PatientFilterContext/PatientFilter.type';
import { OperatorTypes } from './PatientFilter.enum';
import { CareplanComponentTypes } from '../../types/Careplan';
import { toCamelCase } from '../../Global/Strings';
import { FieldIdType } from '../../Views/Patients/Filter/FilterCriteria/FilterCriteriaFieldSelector/FilterCriteriaFieldSelector.type';
import { ComponentMinMax } from '../../Views/Patients/Filter/FilterCriteria/FilterCriteriaComponentAnswer/FilterCriteriaComponentAnswer.enum';

const calculateAge = () => 'TIMESTAMPDIFF(YEAR,patient.birthdate, NOW())';

export const getOperators = (type: FieldTypes | CareplanComponentTypes) => {
  const typeSpecificOperators = () => {
    switch (type) {
      case FieldTypes.String:
        return [
          {
            id: OperatorTypes.Contains,
            labelLocation: 'filter_operators.contains',
          },
          {
            id: OperatorTypes.DoesNotContain,
            labelLocation: 'filter_operators.does_not_contain',
          },
        ];
      case FieldTypes.Date:
      case FieldTypes.Integer:
      case CareplanComponentTypes.NumericRange:
      case CareplanComponentTypes.NumericValue:
      case CareplanComponentTypes.BMI:
        return [
          {
            id: OperatorTypes.Less,
            labelLocation: 'filter_operators.less',
          },
          {
            id: OperatorTypes.LessOrEqual,
            labelLocation: 'filter_operators.less_or_equal',
          },
          {
            id: OperatorTypes.Greater,
            labelLocation: 'filter_operators.greater',
          },
          {
            id: OperatorTypes.GreaterOrEqual,
            labelLocation: 'filter_operators.greater_or_equal',
          },
          {
            id: OperatorTypes.Between,
            labelLocation: 'filter_operators.between',
          },
          {
            id: OperatorTypes.NotBetween,
            labelLocation: 'filter_operators.not_between',
          },
        ];
      case CareplanComponentTypes.FreeText:
        return [
          {
            id: OperatorTypes.Contains,
            labelLocation: 'filter_operators.contains',
          },
          {
            id: OperatorTypes.DoesNotContain,
            labelLocation: 'filter_operators.does_not_contain',
          },
        ];
      default:
        return [];
    }
  };

  return [
    {
      id: OperatorTypes.Equal,
      labelLocation: 'filter_operators.equal',
    },
    {
      id: OperatorTypes.NotEqual,
      labelLocation: 'filter_operators.not_equal',
    },
    ...typeSpecificOperators(),
    {
      id: OperatorTypes.IsEmpty,
      labelLocation: 'filter_operators.is_empty',
    },
    {
      id: OperatorTypes.IsNotEmpty,
      labelLocation: 'filter_operators.is_not_empty',
    },
  ];
};

export const getCareplanComponentTypes = () =>
  Object.keys(CareplanComponentTypes)
    .filter(key => Number.isNaN(Number(key)))
    .filter(
      key =>
        CareplanComponentTypes[key] !== CareplanComponentTypes.Sortable &&
        CareplanComponentTypes[key] !== CareplanComponentTypes.Datepicker &&
        CareplanComponentTypes[key] !==
          CareplanComponentTypes.GroupDescription &&
        CareplanComponentTypes[key] !== CareplanComponentTypes.Information
    )
    .map((componentType: keyof typeof CareplanComponentTypes) => {
      return {
        id: CareplanComponentTypes[componentType],
        value: CareplanComponentTypes[componentType],
        labelLocation: `types.careplanComponentType.${toCamelCase(
          componentType
        )}`,
        name: toCamelCase(componentType),
        label: `types.careplanComponentType.${toCamelCase(componentType)}`,
      };
    });

const checkBirthdateAndAppointmentDates = (
  value: [string, string],
  operators: { operator: { type: OperatorTypes } }
) => {
  if (
    operators.operator.type === OperatorTypes.Between ||
    operators.operator.type === OperatorTypes.NotBetween
  ) {
    const startDate = value[0] && Date.parse(value[0]);
    const endDate = value[1] && Date.parse(value[1]);
    if (startDate && endDate && startDate > endDate) {
      return I18n.t('recurrence.endDateError');
    }
    return true;
  }
  if (value == null) {
    return 'datetime_empty';
  }
  return true;
};

export const getCareplanComponentById = (id: number) => {
  const componentType: string = Object.keys(CareplanComponentTypes).find(
    (componentType: keyof typeof CareplanComponentTypes) =>
      CareplanComponentTypes[componentType] === id
  );
  if (componentType)
    return {
      id: CareplanComponentTypes[componentType],
      value: CareplanComponentTypes[componentType],
      labelLocation: `types.careplanComponentType.${toCamelCase(
        componentType
      )}`,
      name: toCamelCase(componentType),
      label: `types.careplanComponentType.${toCamelCase(componentType)}`,
    };
};

const patientCriteria = [
  {
    id: 'patient.firstname',
    labelLocation: 'patients_complexsearch.label_firstName',
    type: FieldTypes.String,
    operators: getOperators(FieldTypes.String),
    optgroup: I18n.t('nav.patients'),
    validation: {
      callback(value: unknown) {
        const isNotValid = validateFirstnameLastname(value);
        if (value) {
          if (isNotValid) {
            return I18n.t('patients_view.invalidFirstName');
          }
          return true;
        }
        return 'datetime_empty';
      },
    },
  },
  {
    id: 'patient.lastname',
    labelLocation: 'patients_complexsearch.label_lastName',
    type: FieldTypes.String,
    operators: getOperators(FieldTypes.String),
    optgroup: I18n.t('nav.patients'),
    validation: {
      callback(value: unknown) {
        const isNotValid = validateFirstnameLastname(value);
        if (value) {
          if (isNotValid) {
            return I18n.t('patients_view.invalidFirstName');
          }
          return true;
        }
        return 'datetime_empty';
      },
    },
  },
  {
    id: 'patient.medicalRecord',
    labelLocation: 'patients_complexsearch.label_medicalRecord',
    type: FieldTypes.Record,
    operators: getOperators(FieldTypes.Record),
    optgroup: I18n.t('nav.patients'),
    validation: {
      format: /^([^//?]*)$/,
      messages: {
        format: 'Invalid text',
      },
    },
  },
  {
    id: 'patient.caseNumber',
    labelLocation: 'patients_complexsearch.label_caseNumber',
    type: FieldTypes.Record,
    operators: getOperators(FieldTypes.Record),
    optgroup: I18n.t('nav.patients'),
    validation: {
      format: /^([^//?]*)$/,
      messages: {
        format: 'Invalid text',
      },
    },
  },
  {
    id: calculateAge(),
    labelLocation: 'patients_complexsearch.label_age',
    type: FieldTypes.Integer,
    operators: getOperators(FieldTypes.Integer).slice(0, -2),
    optgroup: I18n.t('nav.patients'),
  },
  {
    id: 'patient.birthdate',
    labelLocation: 'patients_complexsearch.label_dateOfBirth',
    type: FieldTypes.Date,
    operators: getOperators(FieldTypes.Date),
    optgroup: I18n.t('nav.patients'),
    validation: {
      format: Moment('YYYY-MM-DD').isValid(),
      callback(
        value: [string, string],
        operators: { operator: { type: OperatorTypes } }
      ) {
        return checkBirthdateAndAppointmentDates(value, operators);
      },
      messages: {
        format: 'Invalid text',
      },
    },
    plugin: 'datepicker',
    plugin_config: {
      format: 'yyyy-mm-dd',
      todayBtn: 'linked',
      todayHighlight: true,
      autoclose: true,
    },
  },
  {
    id: 'patient.gender',
    labelLocation: 'patients_complexsearch.label_gender',
    type: FieldTypes.Select,
    values: [
      {
        value: 'Male',
        labelLocation: 'patients_view.gender_option1',
      },
      {
        value: 'Female',
        labelLocation: 'patients_view.gender_option2',
      },
    ],
    operators: [
      {
        id: OperatorTypes.Equal,
        labelLocation: 'filter_operators.equal',
      },
    ],
    optgroup: I18n.t('nav.patients'),
  },
  {
    id: 'patient.insuranceType',
    labelLocation: 'patients_complexsearch.label_insuranceType',
    type: FieldTypes.Select,
    operators: [
      {
        id: OperatorTypes.Equal,
        labelLocation: 'filter_operators.equal',
      },
    ],
    optgroup: I18n.t('nav.patients'),
  },
  {
    id: 'patient.healthStatus',
    labelLocation: 'patients_complexsearch.label_healthStatus',
    type: FieldTypes.Select,
    values: [
      {
        id: ScoringTypes.Well,
        labelLocation: 'newCareplan_view.scoring_well',
      },
      {
        id: ScoringTypes.Fair,
        labelLocation: 'newCareplan_view.scoring_fair',
      },
      {
        id: ScoringTypes.Poor,
        labelLocation: 'newCareplan_view.scoring_poor',
      },
    ],
    operators: [
      {
        id: OperatorTypes.Equal,
        labelLocation: 'filter_operators.equal',
      },
    ],
    optgroup: I18n.t('nav.patients'),
  },
  {
    id: 'icd',
    labelLocation: 'patients_complexsearch.label_icdsCode',
    type: FieldTypes.ICD,
    operators: [
      {
        id: OperatorTypes.Equal,
        labelLocation: 'filter_operators.equal',
      },
    ],
  },
  {
    id: 'ops',
    labelLocation: 'patients_complexsearch.label_opsCode',
    type: FieldTypes.OPS,
    operators: [
      {
        id: OperatorTypes.Equal,
        labelLocation: 'filter_operators.equal',
      },
    ],
  },
];

const caretaskCriteria = [
  {
    id: 'careplans.name',
    labelLocation: 'patients_complexsearch.label_careplanName',
    type: FieldTypes.CareplanName,
    optgroup: I18n.t('nav.caretasks'),
    operators: getOperators(FieldTypes.String),
  },
  {
    id: 'careplans.answerDate',
    labelLocation: 'patients_complexsearch.label_careplanAnswer',
    type: FieldTypes.Date,
    operators: [
      {
        id: OperatorTypes.Equal,
        labelLocation: 'filter_operators.equal',
      },
      {
        id: OperatorTypes.Between,
        labelLocation: 'filter_operators.between',
      },
    ],
  },
  {
    id: 'careplans.score',
    labelLocation: 'patients_complexsearch.label_careplanScore',
    type: FieldTypes.Integer,
    operators: getOperators(FieldTypes.Integer),
  },
  {
    id: 'icd',
    labelLocation: 'patients_complexsearch.label_icdsCode',
    type: FieldTypes.ICD,
    operators: [
      {
        id: OperatorTypes.Equal,
        labelLocation: 'filter_operators.equal',
      },
    ],
  },
  {
    id: 'careplans.isAnswered',
    labelLocation: 'patients_complexsearch.label_anyCaretaskAnswered',
    type: FieldTypes.Select,
    values: [
      {
        id: 1,
        value: true,
        labelLocation: 'patients_complexsearch.true',
      },
      {
        id: 0,
        value: false,
        labelLocation: 'patients_complexsearch.false',
      },
    ],
    operators: [
      {
        id: OperatorTypes.Equal,
        labelLocation: 'filter_operators.equal',
      },
    ],
  },
  {
    id: 'careplanComponents.name',
    labelLocation: 'patients_complexsearch.componentName',
    type: FieldTypes.String,
    operators: getOperators(FieldTypes.String).slice(0, -2),
  },
  {
    id: 'careplanComponents.careplanComponentTypeId',
    labelLocation: 'patients_complexsearch.componentType',
    type: FieldTypes.Select,
    values: getCareplanComponentTypes(),
    operators: [
      {
        id: OperatorTypes.Equal,
        labelLocation: 'filter_operators.equal',
      },
    ],
  },
  {
    id: 'careplanComponents.typeAndName',
    labelLocation: 'patients_complexsearch.componentTypeAndName',
    type: FieldTypes.ComponentTypeAndName,
    operators: getCareplanComponentTypes(),
  },
  {
    id: 'careplanComponents.careplanComponentAnswer',
    labelLocation: 'patients_complexsearch.componentAnswered',
    type: FieldTypes.ComponentAnswer,
    operators: getOperators(FieldTypes.Select),
  },
];

const validators = {
  fieldRequired: (value: string) => !value && { label: 'fieldRequired' },
  regex: (regex: RegExp, message?: string) => (value: string) =>
    !regex.test(value) && { label: message || 'containsInvalidCharacters' },
  isNumber: (number: string) =>
    isNaN(Number(number)) && { label: 'isNotNumber' },
  range: (min: number, max: number, message?: string) => (value: string) =>
    (isNaN(Number(value)) || Number(value) < min || Number(value) > max) && {
      label: message || 'between',
      options: { min, max },
    },
  moreThan: (number: string) => (value: string) =>
    Number(value) <= Number(number) && {
      label: 'higherThan',
      options: { number },
    },
  isInteger: (number: string) =>
    !Number.isInteger(Number(number)) && { label: 'onlyIntegers' },
};

const validate = (value: string, validatorsList = [] as Array<Function>) => {
  validatorsList.unshift(validators.fieldRequired);
  for (let i = 0; i < validatorsList.length; i++) {
    const validation = validatorsList[i](value);
    if (validation) {
      return validation;
    }
  }
};

const generateNameValidator = (fieldName: string) => {
  return (valueList: string[]) => {
    const value = valueList[0];
    const validatorsList = [
      validators.regex(new RegExp(personNameRegex), `${fieldName}Characters`),
    ];
    return validate(value, validatorsList);
  };
};

export const filterCriteriaErrors = {
  [FieldIdType.FirstName]: generateNameValidator('firstName'),
  [FieldIdType.LastName]: generateNameValidator('lastName'),
  [FieldIdType.MedicalRecord]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.CaseNumber]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.ComponentName]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.Age]: (valueList: string[]) => {
    const value = valueList[0];
    const validatorsList = [
      validators.isNumber,
      validators.range(0, 150, 'ageInvalid'),
    ];
    if (valueList.length === 2) {
      const error0 = validate(valueList[0], validatorsList);
      const error1 = validate(valueList[1], [
        ...validatorsList,
        validators.moreThan(valueList[0]),
      ]);
      if (error0 || error1) return [error0, error1];
    }
    return validate(value, validatorsList);
  },
  [FieldIdType.Score]: (valueList: string[]) => {
    const value = valueList[0];
    const validatorsList = [validators.isNumber, validators.isInteger];
    if (valueList.length === 2) {
      const error0 = validate(valueList[0], [...validatorsList]);
      const error1 = validate(valueList[1], [
        ...validatorsList,
        validators.moreThan(valueList[0]),
      ]);
      if (error0 || error1) return [error0, error1];
    }
    return validate(value, validatorsList);
  },
  [FieldIdType.Gender]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.InsuranceType]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.HealthStatus]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.IsAnswered]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.CareplanComponentTypeId]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.Birthdate]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.AnswerDate]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.ICD]: (valueList: string[][]) => {
    const value = valueList[0][0];
    return validate(value);
  },
  [FieldIdType.OPS]: (valueList: string[][]) => {
    const value = valueList[0][0];
    return validate(value);
  },
  [FieldIdType.CareplanName]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.TypeAndName]: (valueList: string[]) => {
    const value = valueList[0];
    return validate(value);
  },
  [FieldIdType.CareplanComponentAnswer]: (
    valueList: string[],
    component: { type: CareplanComponentTypes; range: ComponentMinMax }
  ) => {
    const value = valueList[0];
    let validatorsList: Function[] = [];
    switch (component.type) {
      case CareplanComponentTypes.NumericRange:
      case CareplanComponentTypes.NumericValue:
        {
          validatorsList.push(
            validators.isNumber,
            validators.range(
              Number(component.range?.minValue),
              Number(component.range?.maxValue),
              'numberRange'
            )
          );
        }
        break;
      case CareplanComponentTypes.Bmi:
        {
          validatorsList.push(validators.isNumber, validators.range(0, 75));
        }
        break;
    }
    return validate(value, validatorsList);
  },
};

export const isPatientDemographicData = [
  FieldIdType.FirstName,
  FieldIdType.LastName,
  FieldIdType.Birthdate,
];

const getCriteria = (userRoleIsPatientDemographicData: boolean) => {
  const patientCriteriaWithoutDemographicData = patientCriteria.filter(
    criteria => !isPatientDemographicData.includes(criteria.id)
  );
  const conditionalDemographicPatientCriteria = userRoleIsPatientDemographicData
    ? patientCriteriaWithoutDemographicData
    : patientCriteria;
  return {
    patient: conditionalDemographicPatientCriteria,
    caretask: caretaskCriteria,
  };
};

export const showOperator = (type: FieldTypes) =>
  ![FieldTypes.CareplanName, FieldTypes.ICD, FieldTypes.OPS].includes(type);

export default getCriteria;
