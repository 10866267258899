import React from 'react';

import { Box, DataTable, Flex, Tag } from '@ui-common-files/components';

import { ActionColors, PrimaryColor } from '@ui-common-files/utils';

import getQueueStatus from './QueueCheck.service';

const QueueCheck = ({}) => {
  const { fetchedData, loading, columns } = getQueueStatus();

  return (
    <Flex
      flexDirection="column"
      otherStyles={{
        marginTop: 35,
      }}
    >
      <Box
        className="custom-label"
        otherStyles={{ color: PrimaryColor.MAIN_COLOR }}
      >
        Redis & RabbitMQ:
      </Box>
        <DataTable
          data={fetchedData}
          columns={columns}
          isLoading={loading}
          onTableChange={() => {}}
        />
    </Flex>
  );
};
export default QueueCheck;
