import React, { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import I18n from 'i18next';
import moment from 'moment';

import {
  ActionTranslationKeys,
  ComponentTranslationKeys,
} from '@ui-common-files/utils';
import { ApiClient, ShowFlashMessage } from '@global/Services';
import { updateLocale } from '@global/Date';
import { FirebaseContext, checkUserCredential, navigation } from '@utils';
import { NotificationTypes } from '@type/Notifications';
import { USER_GUIDE_LINKS } from '@utils/GuideLinks/UserGuideLinks.constant';
import {
  selectTodos,
  setTodos,
  setThresholds,
  setMessages,
} from 'src/Views/Notifications/Notification.reducer';
import NavigationJSON from '@utils/navigation.json';

import config from '../../../../config';
import { resetPatientFilters } from '../../../../actions/Datatables/patientFilter';
import { resetPatientTable } from '../../../../actions/Datatables/patients';
import { setCurrentUser, resetCurrentUser } from '../../../../reducers/session';

import serviceConfig from '../../../../serviceConfig.json';
import {
  resetAssessmentsTable,
  resetTemplateTable,
} from '../../../../actions/Datatables/templates';

import editUser from '../../Users/edit';

export const useTopBarWrapperService = (
  setIsModalOpen,
  setDisplayModal,
  setDisplayRevoke,
  setUserToUpdate,
  setNumThresholdsNotifications,
  setNumChatNotifications,
  notificationRef,
  dropDownThresholdRef,
  dropDownRef,
  setNotificationOffset,
  showThresholdsNotificationDropdown,
  setShowThresholdsNotificationDropdown,
  showChatNotificationDropdown,
  setChatShowNotificationDropdown
) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.session);
  const {
    setIsLoggedIn,
    userPinAndOptinsInfo: { isConsentForTracking },
  } = useContext(FirebaseContext);
  const [responsive, setResponsive] = useState(false);
  const [reportsCount, setReportsCount] = useState(0);
  const todos = useSelector(selectTodos);
  const [lang, setLang] = useState(false);

  const getNotificationCount = async (notificationType: NotificationTypes) => {
    const response = await ApiClient.GET({
      url: serviceConfig.brokerService.getNotificationCount.uri,
      payload: {
        userOffset: moment().utcOffset(),
        notificationType,
      },
    });

    if (response) {
      const notificationsCountObject = response.data;
      const {
        numChatNotifications,
        numThresholdNotifications,
        numTodosNotifications,
        numReportsForReview,
        noteTotalCount,
        unseenNotesCount,
      } = notificationsCountObject;

      if ('numReportsForReview' in notificationsCountObject) {
        setReportsCount(numReportsForReview);
      }

      if ('numChatNotifications' in notificationsCountObject) {
        setNumChatNotifications({
          display: numChatNotifications > 0,
          numChatNotifications: numChatNotifications,
        });
        dispatch(
          setMessages({
            count: numThresholdNotifications,
          })
        );
      }

      if ('numThresholdNotifications' in notificationsCountObject) {
        setNumThresholdsNotifications({
          display: numThresholdNotifications > 0,
          numThresholdsNotifications: numThresholdNotifications,
        });

        dispatch(
          setThresholds({
            count: numThresholdNotifications,
          })
        );
      }

      if ('numTodosNotifications' in notificationsCountObject) {
        dispatch(
          setTodos({
            ...todos,
            noteTotalCount,
            unseenNotesCount,
            count: numTodosNotifications,
          })
        );
      }
    }
  };

  const onChatNotificationIconClick = e => {
    getNotificationCount(NotificationTypes.CHAT);
    if (showChatNotificationDropdown > -1) {
      setChatShowNotificationDropdown(!showChatNotificationDropdown);
    }
  };

  const hideChatNotificationDropdown = e => {
    if (
      dropDownRef.current &&
      showChatNotificationDropdown &&
      !dropDownRef.current.contains(e.target)
    ) {
      setChatShowNotificationDropdown(false);
    }
  };

  const onThresholdsNotificationIconClick = e => {
    getNotificationCount(NotificationTypes.THRESHOLD);
    if (showThresholdsNotificationDropdown > -1) {
      setShowThresholdsNotificationDropdown(
        !showThresholdsNotificationDropdown
      );
    }
  };
  const hideThresholdNotificationDropdown = e => {
    if (
      dropDownThresholdRef.current &&
      showThresholdsNotificationDropdown &&
      !dropDownThresholdRef.current.contains(e.target)
    ) {
      setShowThresholdsNotificationDropdown(false);
    }
  };

  const onTodosNotificationIconClick = e => {
    getNotificationCount(NotificationTypes.TODOS);
    history.push(
      `/${NavigationJSON.NOTIFICATION_CENTER}?notificationType=${NotificationTypes.TODOS}`
    );
  };

  const screenSize = useCallback(() => {
    window.innerWidth <= 1600 ? setResponsive(true) : setResponsive(false);
    if (notificationRef.current) {
      setNotificationOffset(
        window.innerWidth - notificationRef.current.offsetLeft - 30
      );
    }
  }, [responsive]);

  const logoutCleanUp = () => {
    history.index = 0;
    history.push('/', { logout: true });
    dispatch(resetPatientTable());
    dispatch(resetPatientFilters());
    dispatch(resetTemplateTable());
    dispatch(resetAssessmentsTable());
    dispatch(resetCurrentUser());
  };

  const logOut = async () => {
    try {
      toast.dismiss();
      setIsLoggedIn(false);
      await ApiClient.GET({ url: serviceConfig.brokerService.logOut.uri });
    } catch (error) {
      ShowFlashMessage({
        type: error.response.data.type,
        messageKey: error.response.data.content,
      });
    } finally {
      logoutCleanUp();
    }
  };

  const language = I18n.language.trim();
  const pdfIFUEng = USER_GUIDE_LINKS.EN;
  const pdfIFUDe = USER_GUIDE_LINKS.DE;
  const pdfIFUIta = USER_GUIDE_LINKS.IT;
  const pdfIFUPta = USER_GUIDE_LINKS.PT;
  const pdfIFUFr = USER_GUIDE_LINKS.FR;
  let pdf;

  if (language.split('-')[0] === 'de') {
    pdf = pdfIFUDe;
  } else if (language.split('-')[0] === 'it') {
    pdf = pdfIFUIta;
  } else if (language.split('-')[0] === 'pt') {
    pdf = pdfIFUPta;
  } else if (language.split('-')[0] === 'fr') {
    pdf = pdfIFUFr;
  } else {
    pdf = pdfIFUEng;
  }

  const topBarIcons = [
    {
      name: 'Reports',
      onClick: () => {
        getNotificationCount(NotificationTypes.REPORTS);
        history.push(`/${navigation.REVIEW_CARETASKS}`);
      },
      count: reportsCount,
    },
  ];

  const displayEditModal = async selectedUser => {
    const hasCredential = await checkUserCredential(
      ComponentTranslationKeys.USER,
      ActionTranslationKeys.SHOW
    );
    if (hasCredential.type && selectedUser != user.id) {
      ShowFlashMessage({
        type: hasCredential.type,
        messageKey: hasCredential.content,
      });
    } else if (hasCredential === true || selectedUser == user.id) {
      await editUser(selectedUser).then(response => {
        if (response.type) {
          ShowFlashMessage({
            message: response.content,
            type: response.type,
          });
        } else {
          setUserToUpdate(response);
          setDisplayModal(true);
        }
      });
    }
  };

  const changeLanguage = async userLng => {
    I18n.changeLanguage(userLng);
    setLang(!lang);
    updateLocale(userLng);
    return await ApiClient.POST({
      url: serviceConfig.brokerService.updateUserLanguagePreference.uri,
      payload: {
        userId: user.id,
        languagePreference: I18n.language,
      },
    }).then(function (response) {
      const userLanguagePreference = response.data.userLanguagePreference;
      dispatch(
        setCurrentUser({
          ...user,
          languagePreference: userLanguagePreference,
        })
      );
    });
  };

  const openInformation = information => {
    if (information === I18n.t('common_labels.imprint')) setIsModalOpen(true);
    else if (information === I18n.t('common_labels.support'))
      window.open(config.supportUrl, '_blank', 'noopener,noreferrer');
    else window.open(pdf, '_blank', 'noopener,noreferrer');
  };

  const closeModal = () => {
    setDisplayModal(false);
    setIsModalOpen(false);
    setDisplayRevoke(false);
  };

  return {
    openInformation,
    closeModal,
    changeLanguage,
    displayEditModal,
    logOut,
    screenSize,
    hideThresholdNotificationDropdown,
    onThresholdsNotificationIconClick,
    hideChatNotificationDropdown,
    onChatNotificationIconClick,
    getNotificationCount,
    topBarIcons,
    onTodosNotificationIconClick,
  };
};
