import React from 'react';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import AnswerHeaderComponent from '../AnswerHeaderComponent';
import { GeneralAnswerType } from '../GeneralAnswer.type';

import '../AnswerResultCommon.scss';

function InformationAnswer({
  componentData,
  componentIndex,
}: GeneralAnswerType): JSX.Element {
  return (
    <Box className="answer-component">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName="Todos"
        title={componentData.name}
      />
    </Box>
  );
}
export default InformationAnswer;
