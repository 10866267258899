/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import I18n from 'i18next';
import cuid from 'cuid';
import Loader from 'react-loader-spinner';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import tabPosition from '../../Utils/Administration/tabPosition';
import serviceConfig from '../../../serviceConfig.json';
import defaultSiteLogo from '../../img/default-institution-image.png';
import {
  createAvatarAsset,
  createAvatarResource,
} from '../../Utils/Asset/assetAvatarHelper';
import avatarType from '../../Utils/Asset/assetAvatarTypes.json';
import { assetUploadPromise } from '../../Utils/Asset/assetHelper';
import {
  checkImageErrors,
  renderFirstColumn,
  renderSecondColumn,
} from './siteHelper';
import { additionalConsentValidator } from './Sites.util';
import 'react-phone-number-input/style.css';
import '../../css/sites.css';
import '../../css/patient.css';

function AddSite({
  closeModal,
  showFlashMessage,
  reloadTable,
  defaultSiteImage,
}) {
  const { user } = useSelector(state => state.session);
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  const [validationMessages, setValidationMessages] = useState({});
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [serviceProviderId, setServiceProviderId] = useState('');
  const [siteLogoFile, setSiteLogoFile] = useState(defaultSiteImage);
  const [logo, setLogo] = useState({
    id: null,
    src: defaultSiteLogo,
  });
  let inputRef;
  const [loading, setLoading] = useState(false);
  const emailFormat =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [privacyPolicyNotValid, setPrivacyPolicyNotValid] = useState({
    type: false,
    size: false,
    name: false,
  });
  const [privacyPolicyData, setPrivacyPolicyData] = useState({
    additionalConsents: [],
  });
  const [privacyPolicyFile, setPrivacyPolicyFile] = useState(null);

  const additionalConsents = privacyPolicyData.additionalConsents;

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if (!file) return;
    const imageError = checkImageErrors(file);
    setErrors({
      ...errors,
      siteLogoError: !!imageError,
    });
    setValidationMessages(prevMessages => ({
      ...prevMessages,
      siteLogoError: imageError,
    }));
    const reader = new FileReader();
    reader.onload = function (e) {
      setLogo({ id: cuid(), src: e.target.result });
    };
    reader.readAsDataURL(file);
    setSiteLogoFile(file);
  }, []);

  const onChangeNameText = event => {
    setName(event.target.value);
  };

  const validateAndExecute = () => {
    const validationErrors = validateInputs();
    if (
      validationErrors.name == false &&
      validationErrors.email == false &&
      validationErrors.address == false &&
      validationErrors.phone == false &&
      validationErrors.consent == false &&
      validationErrors.fileType == false &&
      validationErrors.siteLogoError === false
    ) {
      createSite();
    }
  };

  const validateInputs = () => {
    const inputValidationErrors: { [key: string]: boolean } = {};
    const inputValidationMessages: { [key: string]: string } = {};
    const siteLogoError = checkImageErrors(siteLogoFile);
    const isEmailValid = emailFormat.test(String(email).toLowerCase());
    inputValidationErrors.name = !name;
    inputValidationErrors.address = !address;
    inputValidationErrors.email = !email || !isEmailValid;
    inputValidationErrors.phone = !phone || !isValidPhoneNumber(phone);
    inputValidationErrors.consent = additionalConsents?.some(
      consent => !additionalConsentValidator(decodeURIComponent(consent))
    );
    inputValidationErrors.fileType = privacyPolicyNotValid.type;
    inputValidationErrors.siteLogoError = !!siteLogoError;

    if (email.length < 1) {
      inputValidationMessages.email = I18n.t('parsley.requiredField');
    } else if (!isEmailValid) {
      inputValidationMessages.email = I18n.t('common_labels.invalid_email');
    }
    if (!isValidPhoneNumber(phone)) {
      inputValidationMessages.phone = I18n.t('site_view.invalidPhone');
    }
    setErrors({
      name: inputValidationErrors.name,
      address: inputValidationErrors.address,
      email: inputValidationErrors.email,
      phone: inputValidationErrors.phone,
      consent: inputValidationErrors.consent,
      fileType: inputValidationErrors.fileType,
      siteLogoError: inputValidationErrors.siteLogoError,
    });

    setValidationMessages({
      name: 'parsley.requiredField',
      address: 'parsley.requiredField',
      email: inputValidationMessages.email,
      phone: !phone
        ? I18n.t('parsley.requiredField')
        : inputValidationMessages.phone,
      siteLogoError,
    });

    return inputValidationErrors;
  };

  const createSite = async () => {
    setLoading(true);

    const formData = new FormData();
    const encodedConsents = JSON.stringify(
      additionalConsents.map((consent: string) => encodeURIComponent(consent))
    );
    if (privacyPolicyFile) {
      formData.append('filedata', privacyPolicyFile);
      formData.append('isPrivacyPolicy', true);
      formData.append('additionalConsents', encodedConsents);
    }

    const base64Image = logo.src.includes(';base64,')
      ? logo.src.split(';base64,').pop()
      : null;
    const siteObject = {
      siteName: name,
      siteAddress: address.label,
      siteEmail: email,
      siteFullPhoneNumber: phone,
      userId: user.id,
      siteLogo: base64Image,
      serviceProviderId,
    };

    try {
      let resp;
      let asset;
      const response = await createAvatarAsset(
        siteLogoFile,
        siteObject.siteName,
        avatarType.SITES
      )
        .then(resource => {
          const { collectionId, assetId } = resource.data;
          asset = assetId;
          siteObject.collectionId = collectionId;
          if (privacyPolicyFile) {
            return axios
              .post(serviceConfig.brokerService.createAsset.uri, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Content-Disposition': 'form-data',
                },
              })
              .then(createdPrivacyPolicy => {
                siteObject.assetId =
                  createdPrivacyPolicy.data.createdRecordIds[0].recordId;
                return assetUploadPromise(
                  createdPrivacyPolicy,
                  privacyPolicyFile,
                  showFlashMessage
                ).then(privacyPolicyAsset => {
                  siteObject.privacyPolicyAssetLink =
                    privacyPolicyAsset[0].directLink;
                  siteObject.additionalConsents = additionalConsents
                    ? encodedConsents
                    : [];
                });
              })
              .catch(error => {
                throw new Error(error);
              });
          }
        })
        .then(() => {
          return axios
            .post(serviceConfig.brokerService.createSite.uri, siteObject)
            .then(createdSite => {
              resp = createdSite;
              return createAvatarResource(
                createdSite.data.siteId,
                avatarType.SITES,
                asset
              );
            });
        });
      if (resp) {
        setLoading(false);
        closeModal();
        reloadTable(tabPosition.SECOND);
        showFlashMessage({
          type: resp.data.type,
          content: resp.data.content,
        });
      }
    } catch (error) {
      setLoading(false);
      closeModal();
      showFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    validateAndExecute();
    return false;
  };

  const onChangeEmailText = event => {
    setEmail(event.target.value);
  };

  const onChangeAddressText = event => {
    setAddress(event);
  };

  const onChangePhoneText = value => {
    setPhone(value);
  };

  const onChangeServiceProviderIdText = event => {
    setServiceProviderId(event.target.value);
  };

  const handlePrivacyPolicyUpload = file => {
    const isFileSizeValid =
      file.size <= 30 * Math.pow(10, 6) && file.type == 'application/pdf';
    const isFileNameLimitCorrect = file.name.length <= 50;
    setPrivacyPolicyNotValid({
      type: false,
      size: !isFileSizeValid,
      name: !isFileNameLimitCorrect,
    });
    if (file && isFileSizeValid && isFileNameLimitCorrect) {
      setPrivacyPolicyFile(file);
      setPrivacyPolicyData({
        isPrivacyPolicyChanged: true,
        isNewPrivacyPolicy: true,
        url: URL.createObjectURL(file),
        name: file.name,
        size: `(${(file.size / Math.pow(10, 6)).toFixed(2)}MB)`,
        additionalConsents,
      });
    }
  };

  return (
    <ModalContent>
      {loading && (
        <div className="roleOverlapSpinner">
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </div>
      )}
      <ModalForm formId="create-site-form" handleFormSubmit={handleFormSubmit}>
        {renderFirstColumn(
          onDrop,
          name,
          onChangeNameText,
          errors,
          validationMessages,
          address,
          onChangeAddressText,
          email,
          onChangeEmailText,
          phone,
          onChangePhoneText,
          logo,
          onChangeServiceProviderIdText,
          serviceProviderId
        )}
        {renderSecondColumn(
          handlePrivacyPolicyUpload,
          inputRef,
          privacyPolicyNotValid,
          setPrivacyPolicyNotValid,
          privacyPolicyData,
          setPrivacyPolicyData,
          errors.consent,
          setErrors
        )}
      </ModalForm>
    </ModalContent>
  );
}

export { AddSite };
