import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useShallow } from 'zustand/react/shallow';
import { sortCareplansByStartingTime } from '@ui-common-files/utils';
import { useCarePathWayBuilderStore } from '../../../CarePathWayBuilder.store';

export function usePathWayReviewStepPreview() {
  const currentUser = useSelector(state => state.session).user;

  const { nodes, settings, transformPathWayObject } =
    useCarePathWayBuilderStore(
      useShallow(state => ({
        nodes: state.nodes,
        settings: state.settings,
        edges: state.edges,
        transformPathWayObject: state.transformPathWayObject,
      }))
    );

  const transformedPathwayObject = useMemo(() => {
    return transformPathWayObject(currentUser.institutions);
  }, [currentUser.institutions, transformPathWayObject]);

  const sortedCaretasksByStartingTime = useMemo(() => {
    return transformedPathwayObject.templateList
      .slice()
      .sort(sortCareplansByStartingTime);
  }, [transformedPathwayObject.templateList]);
  return {
    nodes,
    settings,
    sortedCaretasksByStartingTime,
  };
}
