import React, { useReducer, useContext, useEffect } from 'react';
import I18n from 'i18next';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import {
  ButtonType,
  ButtonVariant,
} from '../../../caro-ui-commonfiles/utils/button';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import { SubmissionTypes } from '../../../caro-ui-commonfiles/utils/componentTypes';
import ScrollDownComponent from '../../Utils/ScrollDownComponent';

const EditCareplanModalFooter = ({ componentType, actionType }) => {
  const {
    currentStep,
    setCurrentStep,
    dispatchFormSubmittor,
    dispatchFormValidator,
    careplanHeaderFooter,
    usWarningCheckboxChecked,
    bottomPageReached,
    showUnder18Warning,
    isCaretaskDataLoading,
  } = useContext(PatientOverviewContext);

  const disableButton =
    !showUnder18Warning &&
    (!usWarningCheckboxChecked.awareCheckbox ||
      !usWarningCheckboxChecked.confirmCheckbox);

  const [buttonClicked, setButtonClicked] = useReducer(
    (_state, clicked) => clicked,
    true
  );

  const formSubmittor = submissionType => {
    if (buttonClicked) {
      setButtonClicked(false);
      dispatchFormSubmittor({
        component: componentType,
        action: actionType,
        submissionType,
      });
    }
  };

  if (currentStep !== 3) {
    return (
      <>
        {currentStep === 2 && (
          <Button
            label={I18n.t('common_buttons.back')}
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
            variant={ButtonVariant.OUTLINED}
          />
        )}
        <Flex justifyContent="flex-end" otherStyles={{ flex: 1 }}>
          {currentStep === 2 && !bottomPageReached && <ScrollDownComponent />}
          {currentStep === 1 && (
            <Button
              label={I18n.t('common_buttons.next')}
              type={ButtonType.SUBMIT}
              onClick={() => {
                dispatchFormValidator({
                  component: componentType,
                  action: actionType,
                  shouldValidate: true,
                });
              }}
              disabled={!!isCaretaskDataLoading}
            />
          )}
          {currentStep !== 1 && (
            <Button
              label={I18n.t('common_buttons.next')}
              type={ButtonType.SUBMIT}
              onClick={() => {
                setCurrentStep(currentStep + 1);
              }}
              disabled={currentStep === 2 && disableButton}
            />
          )}
        </Flex>
      </>
    );
  }

  return (
    <>
      <Button
        label={I18n.t('common_buttons.back')}
        onClick={() => {
          if (showUnder18Warning) {
            setCurrentStep(currentStep - 2);
          } else {
            setCurrentStep(currentStep - 1);
          }
        }}
        variant={ButtonVariant.OUTLINED}
      />
      <Flex justifyContent="flex-end" otherStyles={{ flex: 1 }}>
        {!careplanHeaderFooter.startNow && (
          <>
            <div style={{ marginRight: '10px' }}>
              <Button
                label={I18n.t('common_buttons.save')}
                type={ButtonType.SUBMIT}
                onClick={() => formSubmittor(SubmissionTypes.ASSIGN_ONLY)}
                variant={ButtonVariant.OUTLINED}
              />
            </div>
            {!careplanHeaderFooter.isAssessment && (
              <Button
                label={I18n.t('assignTemplate_view.send_immediately')}
                type={ButtonType.SUBMIT}
                onClick={() => formSubmittor(SubmissionTypes.ASSIGN_AND_SEND)}
              />
            )}
          </>
        )}
        {careplanHeaderFooter.startNow && (
          <Button
            label={
              careplanHeaderFooter.isAssessment
                ? I18n.t('newCareplan_view.startNow')
                : I18n.t('newCareplan_view.answerNow')
            }
            type={ButtonType.SUBMIT}
            onClick={() => formSubmittor(SubmissionTypes.ASSIGN_ONLY)}
          />
        )}
      </Flex>
    </>
  );
};

export default EditCareplanModalFooter;
