import { getComponentWeight } from '../CareplanHelperUtility';
import calculationErrorType from './CalculationError.type';

export const MILLION: number = 9999999;
export const ZERO: number = 0;

export const equationFunction = (
  equation,
  componentCollection,
  setWithinIntervals = null
) => {
  if (equation.length === 0) return 0;
  if (!!componentCollection && componentCollection.length < 1) {
    return equation;
  }

  let upperCaseEquation = equation.toUpperCase();
  try {
    componentCollection.forEach((component, index) => {
      const regex = new RegExp(`\\bC` + (index + 1) + `\\b`, 'g');
      upperCaseEquation = upperCaseEquation.replace(
        regex,
        getComponentWeight(component)
      );
    });
    const equationEvaluated = eval(upperCaseEquation);
    const result = Math.round(equationEvaluated);
    if (setWithinIntervals) {
      if (result > MILLION) {
        setWithinIntervals(calculationErrorType.MoreThanMillion);
      } else if (result < ZERO) {
        setWithinIntervals(calculationErrorType.LessThanZero);
      }
    }
    return result === Infinity || result === -Infinity ? NaN : result;
  } catch (error) {
    return NaN;
  }
};
