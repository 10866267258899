import { ApiClient } from '@global/Services';

import serviceConfig from '../../../../serviceConfig.json';

import { OncoboxData } from '../oncobox.interface';

export const getOncoboxImportedData = (patientId: number) => {

    return ApiClient.GET<{ response: OncoboxData }, { patientId: number }>({
      url: serviceConfig.brokerService.getOncoboxImportedData.uri,
      payload: {
        patientId,
      },
    })
  };
