/* eslint-disable react/jsx-wrap-multilines */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';

import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import NumberInput from '../../../../caro-ui-commonfiles/components/NumberInput/NumberInput';
import Tag from '../../../../caro-ui-commonfiles/components/Tags/Tags';
import { TagsType, TagsVariant } from '@ui-common-files/utils';
import { PrimaryColor } from '../../../../caro-ui-commonfiles/utils/colors';

const JumpSelection = ({
  parentComponentIndex,
  destination,
  setDestination,
}) => {
  const handleOnChange = value => {
    setDestination(value);
  };

  const handleOnBlur = () => {
    if (typeof destination === 'object') setDestination(destination);
    else setDestination({ jump: destination, isBlurred: true });
  };

  const inputValue = useMemo(() => {
    if (typeof destination === 'object' || destination.hasError)
      return destination.jump > -1 ? destination.jump : '';
    return destination > -1 ? destination : '';
  }, [destination]);

  return (
    <div className="logic-jump__selection-container">
      <Flex flexDirection="row" alignItems="center" justifyContent="flex-end">
        <div className="logic-jump__selector-label">
          <Tag
            text={I18n.t('newQuestion_view.jumpToTag')}
            variant={TagsVariant.CONTAINED}
            type={TagsType.SQUARED}
            fill={PrimaryColor.MAIN_COLOR}
          />
        </div>
        <div className="logic-jump__selector" onBlur={handleOnBlur}>
          <NumberInput
            name="jump-selection"
            min={1}
            max={999}
            step={1}
            hasError={destination.hasError || false}
            placeholder=""
            value={inputValue}
            changeValue={handleOnChange}
            onBlur={handleOnBlur}
          />
        </div>
      </Flex>
    </div>
  );
};

JumpSelection.propTypes = {
  destination: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  setDestination: PropTypes.func.isRequired,
  parentComponentIndex: PropTypes.number.isRequired,
};

JumpSelection.defaultProps = {
  destination: -1,
};
export default withTranslation()(JumpSelection);
