import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from './locale/de.json';
import en from './locale/en.json';
import it from './locale/it.json';
import pt from './locale/pt.json';
import ar from './locale/ar.json';
import fr from './locale/fr.json';

i18n.use(LanguageDetector).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en,
    de,
    it,
    pt,
    ar,
    fr,
  },
  detection: {
    caches: [],
    lookupQuerystring: 'setLng',
  },
});

export default i18n;
