import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  ComponentWithLabel,
  Dropdown,
  Label,
  DatePicker,
  Flex,
  FlashMessage,
  ModalContent,
} from '@ui-common-files/components';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';

import { FlashMessageTypes } from '@ui-common-files/types';

import serviceConfig from '../../../serviceConfig.json';
import { PatientOverviewContext } from './PatientOverview';
import { ApiClient, ShowFlashMessage } from '@global/Services';

import '../../css/careplans.scss';
import '../../css/answers.css';

const ExportResultsModalContent = ({
  patientId,
  setCarePlanExportData,
}) => {
  const { setDataForExport } = useContext(PatientOverviewContext);
  const { t } = useTranslation();

  const [hasErrors, setHasErrors] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [assignment, setAssignment] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [assignments, setAssignments] = useState([]);

  const getSpecificOptions = async () => {
    const response = await ApiClient.POST({
      url: serviceConfig.brokerService.getSpecificOptions.uri,
      payload: { patientId },
    })
      .then(response => {
        if (response?.data) {
          setCarePlanExportData(response.data.data);
          setAssignments(
            response.data.data.map(elem => ({
              value: elem.id,
              label: elem.name,
            }))
          );
        }
      })
      .catch(error => {
        ShowFlashMessage({
          type: error.response.data.type,
          messageKey: error.response.data.content,
          isModalMessage: true,
        });
      });
  };
  const validateAndExecute = () => {
    const errors = validateInputs();
    if (errors === false) {
      const selectSpecificOption = assignment.map(elem => elem.value);
      setDataForExport({
        patientId,
        input1: startDate,
        input2: endDate,
        selectSpecificOption,
        isSignOffSheet: isChecked,
      });
    }
  };
  useEffect(() => {
    if (patientId && assignment) {
      validateAndExecute();
    }
  }, [hasErrors]);

  const validateInputs = () => {
    const errors = !assignment;
    setHasErrors(errors);
    return errors;
  };

  useEffect(() => {
    getSpecificOptions();
  }, []);

  const handleFormSubmit = event => {
    event.preventDefault();
    validateAndExecute();
    return false;
  };

  return (
    <ModalContent>
      <Flex otherStyles={{ width: '100%' }}>
        <FlashMessage
          type={FlashMessageTypes.Info}
          message={t('flash.exportCsvFileInfo')}
          autoHide={false}
        />
      </Flex>
      <ModalForm
        formId="form__export-results"
        handleFormSubmit={handleFormSubmit}
      >
        <ModalSection>
          <div style={{ marginLeft: '30px', marginTop: '30px' }}>
            <Label text={t('patientOverview.note')} id="note" />
            <p className="latest-answer">{t('sign_off_sheet.note')}</p>
          </div>
        </ModalSection>

        <ModalSection>
          <div style={{ marginLeft: '20px', marginTop: '15px' }}>
            <ComponentWithLabel
              id="assignmentSelection"
              htmlFor="assignmentSelection"
              labelText={`* ${t('patientOverview.assignmentSelection')}`}
            >
              <Dropdown
                value={assignment}
                options={assignments}
                placeholder={t('patientOverview.selectOptions')}
                handleOnChange={event => {
                  setAssignment([event]);
                }}
                hasError={hasErrors}
                noOptionsMessage={() =>
                  t('common_labels.label_noOptions_Available')
                }
              />

              {hasErrors === true && (
                <span className="patients__phone-field error">
                  {t('parsley.requiredField')}
                </span>
              )}
            </ComponentWithLabel>
          </div>
          <div
            style={{
              marginLeft: '22px',
              marginTop: '10px',
              whiteSpace: 'nowrap',
            }}
          >
            <ComponentWithLabel
              id="dateRange"
              htmlFor="dateRange"
              labelText={t('patientOverview.dateRange')}
            >
              <Flex flexDirection="row">
                <h3 className="from-label">{t('common_labels.from')}</h3>
                <div style={{ width: '149px' }}>
                  <DatePicker
                    date={startDate}
                    placeholder=""
                    handleDate={date => {
                      setStartDate(date);
                    }}
                    minDate={moment().subtract(190, 'years').toDate()}
                    maxDate={endDate || new Date()}
                  />
                </div>
                <h3 className="to-label">{t('common_labels.to')}</h3>
                <div style={{ width: '149px' }}>
                  <DatePicker
                    date={endDate}
                    placeholder=""
                    handleDate={date => {
                      setEndDate(date);
                    }}
                    minDate={
                      startDate || moment().subtract(190, 'years').toDate()
                    }
                    maxDate={new Date()}
                  />
                </div>
              </Flex>
            </ComponentWithLabel>
          </div>
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
};

export default ExportResultsModalContent;
