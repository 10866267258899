import React, { useCallback } from 'react';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';

import { lifespanEnum } from '../../utils/Recurrence/recurrenceUtils';
import useCalendarToolbarService from './CalendarToolbar.service';

const CalendarSelector = ({
  onView,
  view,
  locale,
  selectors,
  devicesQuery,
  setDevicesQuery,
}) => {
  const { handleClick, handleTodayandLastDataChange } =
    useCalendarToolbarService(devicesQuery, setDevicesQuery);

  const renderButton = useCallback(
    (label, isActive, onClick, isDisabled = false) => (
      <button
        className={`rbc-view--button${isActive ? ' active' : ''}`}
        type="button"
        onClick={onClick}
        disabled={isDisabled}
      >
        {label}
      </button>
    ),
    []
  );

  return (
    <Box>
      {selectors.length > 0 && (
        <Flex>
          <Box className="rbc-view-group last">
            {renderButton(
              locale.today,
              false,
              () => handleTodayandLastDataChange(true, false),
              devicesQuery.filter === lifespanEnum.YTD
            )}
            {renderButton(
              locale.lastData,
              false,
              () => handleTodayandLastDataChange(false, true),
              devicesQuery.filter === lifespanEnum.YTD
            )}
          </Box>
          <Box className="rbc-view-group">
            {selectors.map(selector => {
              if (selector !== lifespanEnum.TODAY) {
                const isActive = view === selector;
                const label = locale[selector];
                const handleClickWrapper = () => handleClick(selector, onView);
                return renderButton(label, isActive, handleClickWrapper);
              }
            })}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default CalendarSelector;
