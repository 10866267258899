import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import { CareplanReportPageFooterProps } from './CareplanReportPageFooter.type';
import './CareplanReportPageFooter.scss';

const FIRST_PAGE_NUMBER = 1;

const CareplanReportPageFooter = React.forwardRef<
  HTMLDivElement,
  CareplanReportPageFooterProps
>(({ pageNumber, totalPages }, ref): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box className="page-footer" ref={ref}>
      <Box otherStyles={{ height: 20 }} />
      <Flex otherStyles={{ padding: '0 25px' }}>
        <Flex className="page-footer_notation" otherStyles={{ flex: 1 }}>
          {pageNumber !== FIRST_PAGE_NUMBER && (
            <>
              <Box>{t('PDFReport.noAnswer')} = -</Box>
              <Flex>
                <Box>
                  {`${t('threshold.threshold')} = ${t(
                    'PDFReport.thresholdExplanation'
                  )}`}
                  &nbsp;
                </Box>
                <Box className="page-footer_notation-bold-label">
                  {t('PDFReport.thresholdExplanationTriggered')}*
                </Box>
              </Flex>
              <Box>{t('PDFReport.componentNotShown')}</Box>
            </>
          )}
        </Flex>
        <Box className="page-footer_page-numbers">
          <Flex>
            <Box className="page-footer_page-numbers-label">
              {t('newRole_view.label_roleMatrixHeader_Page')}
            </Box>
            <Box>{pageNumber}</Box>
            <Box>/</Box>
            <Box>{totalPages}</Box>
          </Flex>
        </Box>
      </Flex>
      <Box otherStyles={{ height: 20 }} />
    </Box>
  );
});

export default CareplanReportPageFooter;
