import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { FilterCriteriaProps } from '../FilterCriteria/FilterCriteria.type';
import { FilterCriteriaOptions } from '../FilterCriteriaValue/FilterCriteriaValue.type';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import {
  FilterActions,
  OperatorTypes,
} from '../../../../../Utils/PatientFilter/PatientFilter.enum';
import {
  ComponentNameType,
  GetComponentNamesResponse,
} from './FilterCriteriaComponentAnswer.enum';
import { getCareplanComponentsByType } from './FilterCriteriaComponentAnswer.api';
import {
  getCareplanComponentById,
  getCareplanComponentTypes,
} from '../../../../../Utils/PatientFilter/PatientFilter';
import componentTypeOrder from '../../../../Careplans/careplanComponentTypeOrder';
import { PatientFilterErrorType } from '../../PatientFilterContext/PatientFilter.type';

export const useFilterCriteriaComponentAnswerService = ({
  criteria,
  type,
  setDisplayFlashMsg,
}: FilterCriteriaProps) => {
  const { updateCriterias, errors } = usePatientFilterContext();
  const componentTypes = getCareplanComponentTypes();
  const [components, setComponents] = useState([]);
  const [componentType, setComponentType] = useState(
    getCareplanComponentById(criteria.careplanComponentTypeId) ||
      componentTypes[0]
  );
  const [selectedComponent, setSelectedComponent] =
    useState<ComponentNameType>();
  const [answerValue, setAnswerValue] = useState({
    position: undefined,
    value: undefined,
  });
  const [noComponentSelectedError, setNoComponentSelectedError] =
    useState(false);

  const changeAnswer = ({
    position,
    value,
  }: {
    position: number;
    value: any;
  }) => {
    if (!selectedComponent) setNoComponentSelectedError(true);
    else setAnswerValue({ position, value });
  };

  // Resetting everything on component type change
  useEffect(() => {
    if (criteria) {
      const values = {
        ...criteria,
        check: criteria.field.operators[0],
        field: criteria.field,
        careplanComponentTypeId: componentType.id,
        value: [''],
      };
      if (criteria.careplanComponentTypeId !== componentType.id) {
        setSelectedComponent(null);
        setAnswerValue({ position: 0, value: '' });
      }
      updateCriterias({
        action: FilterActions.Update,
        criteria: { values, position: criteria.position },
      });
    }
    getCareplanComponentsByType(componentType.id).then(
      (response: GetComponentNamesResponse) => {
        if (response === null) return;
        setComponents(response.data);
        if (!criteria.careplanComponentId) return;
        const currentComponent = response.data.find(
          component => component.id === criteria.careplanComponentId
        );
        if (currentComponent) setSelectedComponent(currentComponent);
      }
    );
  }, [componentType]);

  const selectOptions = (optionList: FilterCriteriaOptions[]) =>
    optionList.map(option => ({
      ...option,
      label: i18next.t(option.labelLocation),
    }));

  const onClickRemoveHandler = () => {
    updateCriterias({
      action: FilterActions.Remove,
      criteria: { ...criteria, type },
    });
  };

  const changeComponentType = (
    componentType: React.SetStateAction<{
      id: number;
      value: number;
      labelLocation: string;
      label: string;
    }>
  ) => {
    setComponentType(componentType);
    setComponents(null);
    switch (componentType.value) {
      case componentTypeOrder.UPLOAD_FILE: {
        setAnswerValue({ value: null, position: 0 });
        return;
      }
      default: {
        setAnswerValue({ value: '', position: 0 });
        return;
      }
    }
  };

  useEffect(() => {
    if (componentType && selectedComponent) {
      setNoComponentSelectedError(false);
      const values = {
        ...criteria,
        field: criteria.field,
        careplanComponentTypeId: componentType.id,
        careplanComponentId: selectedComponent.id,
        careplanComponentMinMax: selectedComponent.componentMinMax,
        value: [...criteria.value],
      };
      values.value[answerValue.position] = answerValue.value;
      updateCriterias({
        action: FilterActions.Update,
        criteria: { values, position: criteria.position },
      });
    }
  }, [selectedComponent, answerValue, criteria.check]);

  const checkNoValueField = (check: OperatorTypes) =>
    [OperatorTypes.IsEmpty, OperatorTypes.IsNotEmpty].includes(check);

  const searchComponents = (input: string) => {
    const filteredCaretasks = components.filter(component =>
      component.label.toLowerCase().includes(input.toLowerCase())
    );
    return Promise.resolve(filteredCaretasks);
  };
  const error = (errors as { [key: string]: PatientFilterErrorType })[
    `${criteria.field.id}_${criteria.position}`
  ];

  return {
    componentType,
    selectOptions,
    componentTypes,
    setComponentType,
    changeComponentType,
    selectedComponent,
    components,
    setSelectedComponent,
    checkNoValueField,
    onClickRemoveHandler,
    setAnswerValue: changeAnswer,
    searchComponents,
    noComponentSelectedError,
    error,
    setDisplayFlashMsg,
  };
};
