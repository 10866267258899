import React, { useCallback } from 'react';
import I18n from 'i18next';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import AnswerHeaderComponent from '../AnswerHeaderComponent';

import { GeneralAnswerType } from '../GeneralAnswer.type';
import { CareplanComponentAnswer } from '../../../../types/Careplan';
import { getIsThreshold } from '../../../ReportGenerator/Careplan/CareplanReport.utils';
import {
  getThresholdClass,
  occurrenceHasAnswer,
  getComponentOccurrencesAnswer,
} from '../AnswerResult.services';

import '../AnswerResultCommon.scss';

function YesNoAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const renderComponentAnswers = useCallback(
    (occurrenceAnswers: CareplanComponentAnswer[], index: number) => {
      const isThreshold: boolean = getIsThreshold(
        componentData,
        occurrenceAnswers[0]
      );

      const thresholdClass = getThresholdClass(isThreshold);

      let answerContent = '-';
      if (occurrenceHasAnswer(occurrenceAnswers)) {
        answerContent =
          occurrenceAnswers[0].answer_boolean === true
            ? I18n.t('common_labels.yes')
            : I18n.t('common_labels.no');
        if (isThreshold) answerContent += '*';
      }

      return (
        <Flex
          key={`col-header-${componentIndex}-${index}`}
          className="answer-component_header-columns-column"
          alignItems="center"
          justifyContent="center"
        >
          <span className={thresholdClass}>{answerContent}</span>
        </Flex>
      );
    },
    []
  );

  return (
    <Box className="answer-component">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName="YesOrNo"
        title={componentData.name}
        columnOccurrencesRenderer={() => {
          return getComponentOccurrencesAnswer(
            allOccurrences,
            componentData.answers,
            renderComponentAnswers
          );
        }}
      />
    </Box>
  );
}
export default YesNoAnswer;
