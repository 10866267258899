import { useDispatch } from 'react-redux';

import { useAnalytics } from '@global/Services';

import { PatientDeleteModalProps } from './PatientDeleteModal.type';
import { deletePatientRequest } from '../../../../actions/patients';

function usePatientDeleteModalService({
  deleteOperation,
  setDeleteOperation,
}: PatientDeleteModalProps) {
  const reduxDispatcher = useDispatch();
  const { trackEventTrigger } = useAnalytics();

  const closeMessagePrompt = () => {
    trackEventTrigger({ category: 'patient', action: 'Close-Delete-Modal' });
    setDeleteOperation(oldDeleteOperation => ({
      ...oldDeleteOperation,
      display: false,
      id: 0,
      title: '',
    }));
  };

  const onConfirmClose = () => {
    if (deleteOperation.id > 0) {
      reduxDispatcher(
        deletePatientRequest({
          id: deleteOperation.id,
          collectionId: deleteOperation.collectionId,
        })
      );
    }
  };

  return {
    reduxDispatcher,
    onConfirmClose,
    closeMessagePrompt,
  };
}
export default usePatientDeleteModalService;
