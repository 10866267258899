import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Box, Flex, TextInput } from '@ui-common-files/components';
import SelectedDataPoints from '../SelectedDataPoints/SelectedDataPoints';

import { TextInputAutoComplete } from '@ui-common-files/components/TextInput/TextInput.type';
import { inputField } from '@ui-common-files/utils';
import maxCharLimits from '@ui-common-files/utils/maxcharlimits.json';

import { useOverviewStore } from '../../../Overview.store';

import useOverviewDataModalHeaderService from './OverviewDataModalHeader.service';

import './OverviewDataModal.styles.scss';

const OverviewDataModalHeader = () => {
  const { t } = useTranslation();

  const { overviewCaretasks, overviewDataPoints } = useOverviewStore(
    useShallow(state => ({
      overviewCaretasks: state.overviewCaretasks,
      overviewDataPoints: state.overviewDataPoints,
    }))
  );
  const { onKeywordChange } = useOverviewDataModalHeaderService();

  return (
    <Box className="overview_data_header">
      <Flex className="overview_modal_selected_data_section">
        {overviewDataPoints?.selectedDataPoints?.map((dataPoint, index) => (
          <SelectedDataPoints
            dataPoint={dataPoint}
            index={index}
            className="selected-data-point"
          />
        ))}
      </Flex>
      <TextInput
        autoComplete={TextInputAutoComplete.OFF}
        id="searchDataPoints"
        name="searchDataPoints"
        placeholder={t(
          'patients_view.overview.modal_select_data.search.placeholder'
        )}
        maxChars={maxCharLimits.searchInputs.searchKeyword}
        variant={inputField.variant.CHAR_COUNT}
        value={overviewCaretasks.keyword}
        handleOnChange={onKeywordChange}
      />
    </Box>
  );
};

export default OverviewDataModalHeader;
