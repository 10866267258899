import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';
import Loader from 'react-loader-spinner';

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Button,
  DataTable,
} from '@ui-common-files/components';

import {
  ButtonType,
  ButtonVariant,
  Greys,
  MEDIA_QUERIES,
  ComponentTypes,
  Actions,
  PrimaryColor,
} from '@ui-common-files/utils';
import { convertLinkToBlob, getRecurrenceText } from '@utils';
import { getCareplanAnswers } from '@utils/Careplan';

import { ApiClient } from '@global/Services';
import { sortByDate } from '@global/Date';

import CardInfoContent from '../common/CardInfoContent';
import CareplanGraphCard from './CareplanGraphCard';
import { CareplanTitle, Footer } from './CardComponents';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import EditCareplanModalContent from '../Careplans/EditCareplanModalContent';
import EditCareplanModalFooter from '../Careplans/EditCareplanModalFooter';
import EditCareplanModalHeader from '../Careplans/EditCareplanModalHeader';
import CarepathwayTriggeredResult from './CarepathwayTriggeredResult/CarepathwayTriggeredResult';

import renderReccuerenceModalContent from './renderReccuerenceModalContent';
import showCareplanAnswers from './showCareplanAnswers';
import { PatientOverviewCarePathWays } from './CarePathwayCardsList';

import serviceConfig from '../../../serviceConfig.json';

const CareplanWithReccurrencesCard = ({ careplan, index, data, status }) => {
  const { t, i18n } = useTranslation();
  const [showModalGraph, setShowModalGraph] = useState(false);
  const {
    setModalContent,
    setFlashMessage,
    navigateToCareplanAnswerView,
    closeModal,
    patientInfo,
    setPatientSectionalModal,
  } = useContext(PatientOverviewContext);
  const { setItemIdToUpdate, itemIdToUpdate } = useContext(
    PatientOverviewCarePathWays
  );

  const [showCareplansReccurrencesIcon, setShowCareplansReccurrencesIcon] =
    useState('arrowDown');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [caretaskConfiguration, setCaretaskConfiguration] = useState(null);
  const [isConfigurationsLoading, setIsConfigurationsLoading] = useState(false);

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const checkEditCareplanCredential = async id => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.editCareplan.uri + id
      );
      if (response) {
        return response;
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      throw new Error(error.response);
    }
  };

  const setEditCareplanModalContent = async (dataParam, id) => {
    const careplanData = JSON.parse(JSON.stringify(dataParam));
    careplanData.endDate = careplanData.endsAt;
    careplanData.title = careplan.name;
    careplanData.phaseType = careplanData.phaseTypeObject
      ? {
          id: careplanData.phaseTypeObject.id,
          label: t(careplanData.phaseTypeObject.name),
        }
      : null;
    if (careplanData.patient.profilePicture.previewURL) {
      await convertLinkToBlob(careplanData.patient.profilePicture.previewURL)
        .then(blob => {
          careplanData.patient.profilePicture = blob;
        })
        .catch(() => {
          careplanData.patient.profilePicture =
            careplanData.patient.profilePicture.previewURL;
        });
    } else {
      careplanData.patient.profilePicture = `data:image;base64,${careplanData.patient.profilePicture}`;
    }
    const dataForEdit = caretaskConfiguration.careplanRecurrences.find(
      recu => recu.id === id
    );

    setModalContent({
      title: `${t('patientOverview.editAssignment')}: ${careplan.name}`,
      content: (
        <EditCareplanModalContent
          careplan={careplanData}
          data={dataForEdit}
          isComingFromCarePathway
          setItemIdToUpdate={setItemIdToUpdate}
          setIsConfigurationsLoading={setIsConfigurationsLoading}
        />
      ),
      footer: (
        <EditCareplanModalFooter
          componentType={ComponentTypes.CAREPLAN}
          actionType={Actions.Update}
        />
      ),
      header: <EditCareplanModalHeader />,
    });
  };
  const getOccurenceIdFromOccurenceListByCaretaskId = (
    occurenceList,
    caretaskId
  ) => {
    const occurenceObject = occurenceList.find(
      occurence => occurence.careplanId === caretaskId
    );
    return occurenceObject.id;
  };

  const processCareplanInfo = () => {
    const processedCareplan = { ...caretaskConfiguration };
    if (caretaskConfiguration) {
      if (caretaskConfiguration.careplanRecurrences?.length > 1) {
        processedCareplan.recurrenceText = t('common_labels.multiple');
      } else {
        processedCareplan.recurrenceText = getRecurrenceText(
          caretaskConfiguration.careplanRecurrences[0]?.recurrenceString
        );
      }
      const nextOccurence = caretaskConfiguration.allOccurrences.find(
        occurrence =>
          !occurrence.isAnswered &&
          moment
            .unix(occurrence.unixEpochs)
            .isAfter(moment().startOf('day').add(1, 'days'))
      );
      processedCareplan.nextOccurrence = nextOccurence
        ? moment
            .unix(nextOccurence.unixEpochs)
            .utc()
            .locale(i18n.language)
            .format('DD MMM YYYY HH:mm')
        : 'N/A';
    }
    return processedCareplan;
  };

  const processCareplanResultsData = () => {
    const asnwered = caretaskConfiguration.careplanRecurrences.filter(
      cp => cp.isAnswered
    );
    if (asnwered.length > 0) {
      return async () => {
        const results = await Promise.all(
          asnwered.map(answer => getCareplanAnswers(answer.id).then(res => res))
        );
        const sortedList = sortByDate(
          results,
          item => item.dates[0].savedAt,
          'desc'
        );
        setPatientSectionalModal(
          showCareplanAnswers(
            careplan,
            sortedList,
            t('common_labels.label_results')
          )
        );
      };
    }
    return null;
  };

  const getCareplanStatusClass = () => {
    if (!careplan?.isAnswered) {
      return 'notAnswered';
    }
    if (careplan.lastOccurrenceHealthIndicator) {
      return careplan.lastOccurrenceHealthIndicator || 'answered';
    }
    if (caretaskConfiguration?.graphData?.length) {
      const lastOccurrenceHealthIndicator =
        caretaskConfiguration.graphData[
          caretaskConfiguration.graphData.length - 1
        ].occurrenceHealthIndicator;

      return lastOccurrenceHealthIndicator || 'answered';
    }
    return 'answered';
  };

  const tableProps = {
    getTrGroupProps: () => ({
      style: {
        margin: '0px',
      },
    }),
    getTdProps: () => ({
      style: {
        borderTop: '0.2px solid',
        borderColor: Greys.LIGHT_GREY_20,
        minHeight: '80px',
      },
    }),
    getTheadThProps: () => ({
      style: {
        borderTop: '0.2px solid',
        borderColor: Greys.LIGHT_GREY_20,
        backgroundColor: Greys.LIGHT_GREY_20,
        minHeight: '32px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
      },
    }),
    getTheadProps: () => ({
      style: {
        minHeight: '32px',
        height: '32px',
      },
    }),
  };

  const tColumns = [
    {
      Header: '',
      accessor: 'logo',
      sortable: false,
      filterable: false,
      Cell: props => {
        const {
          original: { data },
        } = props;

        return (
          <div style={{ paddingLeft: '40px' }}>
            <Flex alignItems="center">
              <Icon name="files" size={24} />
              {data.careplanTag && (
                <div className="carepathway__table-row--tag">
                  {`#${data.careplanTag}`}
                </div>
              )}
            </Flex>
          </div>
        );
      },
    },
    {
      Header: () => <Box>{t('recurrence.startDay')}</Box>,
      accessor: 'start',
      sortable: false,
      filterable: false,
      Cell: props => {
        return props.original.status ? (
          <div>
            <span style={{ color: '#28b4e6' }}>{props.value}</span>
          </div>
        ) : (
          <div>
            <span>{props.value}</span>
          </div>
        );
      },
    },
    {
      Header: () => (
        <Box padding="0px 0px 0px 20px">{t('recurrence.occurrence')}</Box>
      ),
      accessor: 'occurrence',
      sortable: false,
      filterable: false,
      Cell: ({ value }) => {
        return (
          <div
            style={{
              borderLeft: `solid ${Greys.LIGHT_GREY_50}`,
              borderWidth: '0 1px',
              paddingLeft: 20,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      Header: () => <Box>{t('recurrence.status')}</Box>,
      accessor: 'status',
      sortable: false,
      filterable: false,
      Cell: ({ value }) => {
        return value ? (
          <div>
            <span style={{ backgroundColor: '#28b4e6' }} className="dot" />
            <span style={{ color: '#28b4e6' }}>
              {t('types.carePathwayStatus.active')}
            </span>
          </div>
        ) : (
          <div>
            <span className="dot" />
            <span>{t('types.carePathwayStatus.inactive')}</span>
          </div>
        );
      },
    },
    {
      accessor: 'answerRouteDetails',
      sortable: false,
      filterable: false,
      minWidth: 120,
      Cell: ({
        value: {
          isAssessment,
          caretaskId,
          occurrenceId,
          status,
          userShouldAnswerCareplan,
          validOngoingCouldBeAnsweredOccurrence,
        },
        original: {
          data: { isAnswered, isLinkable },
        },
      }) => {
        return (
          <Flex flexDirection="column">
            {status &&
              userShouldAnswerCareplan &&
              validOngoingCouldBeAnsweredOccurrence && (
                <Button
                  label={
                    isAssessment
                      ? t('newCareplan_view.startNow')
                      : t('newCareplan_view.answerOnBehalf.text')
                  }
                  type={ButtonType.BUTTON}
                  onClick={() => {
                    navigateToCareplanAnswerView({
                      id: caretaskId,
                      isAssessment,
                      occurrenceId,
                    });
                  }}
                  variant={ButtonVariant.OUTLINED}
                />
              )}
            {isAnswered && isLinkable && (
              <Button
                type={ButtonType.BUTTON}
                onClick={() => {
                  setPatientSectionalModal({
                    isOpen: true,
                    title: `${t('common_labels.label_results')}: ${data.name}`,
                    content: (
                      <CarepathwayTriggeredResult caretaskId={caretaskId} />
                    ),
                  });
                }}
                variant={ButtonVariant.OUTLINED}
              >
                <>
                  <Icon name="branch" fill={PrimaryColor.MAIN_COLOR} />
                  <Box otherStyles={{ paddingLeft: 5 }}>
                    {t('newCareplan_view.triggeredResults')}
                  </Box>
                </>
              </Button>
            )}
          </Flex>
        );
      },
    },
    {
      Header: '',
      accessor: 'icons',
      sortable: false,
      filterable: false,
      minWidth: 160,
      maxWidth: 160,
      style: { overflow: 'visible' },
      Cell: ({ original }) => {
        const { data } = original;

        return (
          <div
            style={{
              borderLeft: `solid ${Greys.LIGHT_GREY_50}`,
              borderWidth: '0 1px',
              padding: '0 20px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <div style={{ marginLeft: 15 }}>
              {!data.isAssessment && (
                <IconButton
                  type="button"
                  name="reccuerenceIcon"
                  size={24}
                  onPointerDown={() => {
                    renderReccuerenceModalContent(
                      data.name,
                      data.id,
                      setModalContent,
                      closeModal,
                      patientInfo.deviceOffset
                    );
                  }}
                  tooltipText={t('recurrence.repetition')}
                />
              )}
            </div>
            <div style={{ marginLeft: 25 }}>
              <IconButton
                type="button"
                name="edit"
                size={24}
                onPointerDown={() => {
                  checkEditCareplanCredential(data.id).then(res => {
                    setEditCareplanModalContent(res.data, data.id);
                  });
                }}
                tooltipText={t('common_labels.label_edit')}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const TogglCareplanReccurrences = () => {
    return showCareplansReccurrencesIcon === 'arrowUp'
      ? setShowCareplansReccurrencesIcon('arrowDown')
      : setShowCareplansReccurrencesIcon('arrowUp');
  };

  const renderConfigurations = () => {
    const careplanDataForTable = caretaskConfiguration.careplanRecurrences.map(
      recurrence => {
        const occurrence = getRecurrenceText(recurrence.recurrenceString);
        let occurrenceId,
          validOngoingCouldBeAnsweredOccurrence = false;
        if (recurrence.validOccurrenceToAnswer) {
          occurrenceId = recurrence.validOccurrenceToAnswer.occurrenceId;
          validOngoingCouldBeAnsweredOccurrence =
            recurrence.validOccurrenceToAnswer
              .validOngoingCouldBeAnsweredOccurrence;
        } else {
          occurrenceId = getOccurenceIdFromOccurenceListByCaretaskId(
            caretaskConfiguration.allOccurrences,
            recurrence.id
          );
        }
        const answerRouteDetails = {
          occurrenceId,
          caretaskId: recurrence.id,
          isAssessment: recurrence.isAssessment,
          status: recurrence.isActive,
          userShouldAnswerCareplan: recurrence.userShouldAnswerCareplan,
          validOngoingCouldBeAnsweredOccurrence,
        };
        return {
          start: moment(recurrence.startsAt)
            .locale(i18n.language)
            .format('DD MMM YYYY'),
          occurrence,
          status: recurrence.isActive,
          data: recurrence,
          answerRouteDetails,
        };
      }
    );
    return (
      <>
        <div className="careplanWithReccurrences-configuration-text">
          {t('common_labels.configurations')}
        </div>
        <DataTable
          pagination={false}
          data={careplanDataForTable}
          columns={tColumns}
          pageSize={false}
          onTableChange={() => {}}
          page={1}
          pages={1}
          totalRecord={2}
          tableProps={tableProps}
        />
      </>
    );
  };

  useEffect(() => {
    if (showCareplansReccurrencesIcon === 'arrowUp') {
      setIsConfigurationsLoading(true);
    }
  }, [showCareplansReccurrencesIcon]);

  useEffect(() => {
    const getCaretaskConfigurationsData = () => {
      ApiClient.GET({
        url: `${serviceConfig.brokerService.getCareplansDataByCarePathwayId.uri}${careplan.templateId}/${data.id}`,
        payload: {
          patientId: patientInfo.id,
        },
      })
        .then(response => {
          if (response?.data) {
            setCaretaskConfiguration(response.data);
          }
        })
        .finally(() => setIsConfigurationsLoading(false));
    };

    if (isConfigurationsLoading || itemIdToUpdate) {
      getCaretaskConfigurationsData();
    }
  }, [isConfigurationsLoading, itemIdToUpdate]);

  const evenStyles = {};
  const oddStyles = {
    borderTop: index === 0 ? `0.2px solid ${Greys.LIGHT_GREY_20}` : 'none',
  };

  return (
    <Box className="assignment-card__carepathway__reccurrences">
      <Box
        className={`myCareplanTitle ${getCareplanStatusClass()}`}
        otherStyles={index % 2 === 0 && index >= 1 ? evenStyles : oddStyles}
      >
        <Box width="100%">
          <CareplanTitle
            data={data}
            onExpand={TogglCareplanReccurrences}
            careplan={careplan}
          />
        </Box>
        <Flex
          flexDirection={
            windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'
          }
          justifyContent="space-between"
          otherStyles={{ padding: '20px 0', marginLeft: 20 }}
        >
          <Box
            className={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? 'card__content-container card__container--row'
                : 'card__content-container card__container--column'
            }
          >
            {isConfigurationsLoading && (
              <Box
                otherStyles={{
                  left: '90%',
                  position: 'absolute',
                }}
              >
                <Loader
                  type="Oval"
                  visible
                  color={PrimaryColor.MAIN_COLOR}
                  height={32}
                  width={32}
                />
              </Box>
            )}
            <Flex alignItems="center">
              <IconButton
                name={showCareplansReccurrencesIcon}
                size={24}
                fill={Greys.DARK_GREY}
                onClick={() => {
                  TogglCareplanReccurrences();
                }}
              />
            </Flex>
          </Box>
        </Flex>
      </Box>
      {showCareplansReccurrencesIcon === 'arrowUp' &&
        caretaskConfiguration &&
        !isConfigurationsLoading && (
          <Box
            className="assignment-card"
            otherStyles={{
              border: `0.2px solid ${Greys.LIGHT_GREY_20}`,
              padding: '0px',
              borderRadius: '0px',
              marginBottom: '0px',
              maxWidth: '100%',
            }}
          >
            <Flex
              flexDirection={
                windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'
              }
              otherStyles={{
                paddingLeft: '55px',
                marginTop: '10px',
                paddingRight: '40px',
              }}
              justifyContent="space-between"
            >
              <CardInfoContent
                data={processCareplanInfo()}
                variant="carepathway"
              />
              <CareplanGraphCard
                data={caretaskConfiguration}
                key={careplan.id}
                show={showModalGraph}
                onClose={() => setShowModalGraph(false)}
              />
            </Flex>
            <Box otherStyles={{ margin: '15px 40px 15px' }}>
              <Footer
                componentType={ComponentTypes.CAREPLAN}
                resultHandler={processCareplanResultsData()}
                isCareplanAnswered
                isLastOccurrenceBeforeToday
              />
            </Box>
            <Box otherStyles={{ marginTop: '10px' }}>
              {renderConfigurations()}
            </Box>
          </Box>
        )}
    </Box>
  );
};

export default CareplanWithReccurrencesCard;
