import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Caretask, CaretaskComponent } from '../../../../types/Caretask';
import {
  CaretaskAnsweringReducer,
  ComponentAnswer,
} from './CaretaskAnswering.type';

export const TITLE_DESCRIPTION_INDEX: number = -1;

const initialState: CaretaskAnsweringReducer = {
  Caretask: null,
  IsAnsweringMain: true,
  AdditionalComponents: [],
  Navigator: {
    Current: 0,
    Previous: -1,
  },
  Answers: [],
};

const caretaskAnsweringSlice = createSlice({
  name: 'caretaskAnswering',
  initialState,
  reducers: {
    setCaretask: (state, action: PayloadAction<Caretask>) => {
      return {
        ...state,
        Caretask: { ...action.payload },
        Navigator: {
          Current: action.payload.description
            ? TITLE_DESCRIPTION_INDEX
            : state.Navigator.Current,
          Previous: action.payload.description
            ? state.Navigator.Previous - 1
            : state.Navigator.Previous,
        },
      };
    },
    resetAnswering: () => {
      return initialState;
    },
    setAdditionalComponents: (state, action) => {
      return {
        ...state,
        AdditionalComponents: action.payload,
      };
    },
    nextToAdditionalComponents: state => {
      return {
        ...state,
        IsAnsweringMain: false,
        Navigator: {
          Current: 0,
          Previous: -1,
        },
      };
    },
    nextComponent: state => {
      const mainComponentsCount = state.Caretask.careplanComponents.length;
      const targetAdditionalComponentsIndex =
        state.Navigator.Current + mainComponentsCount;

      const currentAnswerIndex = state.IsAnsweringMain
        ? state.Navigator.Current
        : targetAdditionalComponentsIndex;

      const currentAnswer = state.Answers[currentAnswerIndex];

      return {
        ...state,
        Navigator: {
          Current: currentAnswer?.jumpTo || state.Navigator.Current + 1,
          Previous: state.Navigator.Current,
        },
        Answers: currentAnswer?.jumpTo
          ? state.Answers.map((answer: ComponentAnswer, index: number) => {
              if (
                index > state.Navigator.Current &&
                index < currentAnswer?.jumpTo
              ) {
                return {
                  ...answer,
                  isSkipped: true,
                  answer: null,
                  jumpTo: null,
                  hasError: false,
                };
              } else {
                return answer;
              }
            })
          : state.Answers,
      };
    },
    previousComponent: state => {
      const targetAnswerIndex: number = state.Answers.findIndex(
        item => item.jumpTo === state.Navigator.Current
      );
      const target: number =
        state.IsAnsweringMain && targetAnswerIndex > -1
          ? targetAnswerIndex
          : state.Navigator.Previous;

      return {
        ...state,
        Navigator: {
          Current: target,
          Previous: target - 1,
        },
      };
    },
    createAnswersObject: (
      state,
      action: PayloadAction<CaretaskComponent[]>
    ) => {
      const answersObject: ComponentAnswer[] = [...state.Answers];
      action.payload.forEach((component: CaretaskComponent) => {
        answersObject.push({
          answer: null,
          jumpTo: null,
          careplanCareplanComponentMapperId:
            component.careplanCareplanComponentMapper.id,
          careplanComponentId: component.id,
          careplanComponentTypeId: component.careplanComponentTypeId,
          careplanComponentSequence:
            component.careplanCareplanComponentMapper.seq,
          isSkipped: true,
          hasError: false,
        });
      });
      return { ...state, Answers: [...answersObject] };
    },
    updateComponentAnswer: (state, action: PayloadAction<ComponentAnswer>) => {
      const indexOfAnswer: number = state.Answers.findIndex(
        item =>
          item.careplanCareplanComponentMapperId ===
          action.payload.careplanCareplanComponentMapperId
      );
      const answers: ComponentAnswer[] = [...state.Answers];
      const newAnswer: ComponentAnswer = {
        ...answers[indexOfAnswer],
        answer: action.payload.answer,
        isSkipped: action.payload.isSkipped,
        jumpTo: action.payload.jumpTo,
        hasError: action.payload.hasError,
      };
      answers[indexOfAnswer] = newAnswer;
      return { ...state, Answers: answers };
    },
  },
});

export const selectCaretask = state => state.caretaskAnswering.Caretask;
export const selectNavigator = state => state.caretaskAnswering.Navigator;
export const selectCaretaskAnswers = state => state.caretaskAnswering.Answers;
export const selectIsAnsweringMain = state =>
  state.caretaskAnswering.IsAnsweringMain;
export const selectMainComponents = state =>
  state.caretaskAnswering.Caretask?.careplanComponents
    ? state.caretaskAnswering.Caretask.careplanComponents
    : [];
export const selectAdditionalComponents = state =>
  state.caretaskAnswering.AdditionalComponents;

export const selectMainComponentsCount = state =>
  state.caretaskAnswering.Caretask?.careplanComponents.length;

export const selectAdditionalComponentsCount = state =>
  state.caretaskAnswering.AdditionalComponents.length;

export const selectIsLastComponent = state => {
  let result: boolean = false;

  const isAnsweringMain = state.caretaskAnswering.IsAnsweringMain;
  const currentAnswer = selectCurrentAnswer(state);

  const components = isAnsweringMain
    ? selectMainComponents(state)
    : state.caretaskAnswering.AdditionalComponents;
  const componentsLength: number = components.length;
  const currentIndex = state.caretaskAnswering.Navigator.Current;
  const isEvaluating = state.caretaskAnswering.Caretask.isEvaluating;

  const isMainAnsweringFinalComponent =
    isAnsweringMain && !isEvaluating && componentsLength - 1 === currentIndex;

  if (componentsLength > 0) {
    result =
      currentAnswer?.jumpTo >= componentsLength ||
      (!isAnsweringMain && componentsLength - 1 === currentIndex) ||
      isMainAnsweringFinalComponent;
    if (componentsLength === currentIndex) {
      result = true;
    }
  }

  return result;
};

export const selectCurrentComponent = state => {
  const isAnsweringMain = state.caretaskAnswering.IsAnsweringMain;

  return isAnsweringMain
    ? selectMainComponents(state)[state.caretaskAnswering.Navigator.Current]
    : state.caretaskAnswering.AdditionalComponents[
        state.caretaskAnswering.Navigator.Current
      ];
};

export const selectCurrentAnswer = state => {
  const currentComponentObject: CaretaskComponent = selectCurrentComponent(
    state
  );
  const currentAnswerObject: ComponentAnswer[] = state.caretaskAnswering.Answers.filter(
    item =>
      item.careplanCareplanComponentMapperId ===
      currentComponentObject?.careplanCareplanComponentMapper?.id
  );
  return currentAnswerObject.length > 0 ? currentAnswerObject[0] : null;
};

export const selectHasError = state => {
  const currentAnswer = selectCurrentAnswer(state);
  return currentAnswer ? currentAnswer.hasError : false;
};

export const {
  setCaretask,
  resetAnswering,
  nextToAdditionalComponents,
  nextComponent,
  previousComponent,
  createAnswersObject,
  updateComponentAnswer,
  setAdditionalComponents,
} = caretaskAnsweringSlice.actions;

export default caretaskAnsweringSlice.reducer;
