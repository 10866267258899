import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalSize } from '@ui-common-files/components';
import { ShowFlashMessage } from '@global/Services';
import { isPreviewInViewer, showAssetRequest } from '@utils/Asset/assetHelper';

export const useMedicationPlanCardService = data => {
  const { t } = useTranslation();
  const [assetDataToShow, setAssetDataToShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleViewMedicationPlanPDF = useCallback(() => {
    setModalTitle(`${t('asset_view.showFile')}: ${data.asset.name}`);
    showAssetRequest(
      data.assetId,
      setAssetDataToShow,
      setOpenModal,
      ShowFlashMessage,
      data.patientId,
      false
    );
  }, [data.assetId, data.patientId]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const isPreviewable = useMemo(
    () =>
      assetDataToShow
        ? isPreviewInViewer(assetDataToShow.assetData.extension.toUpperCase())
        : false,
    [assetDataToShow]
  );

  const getModalSize = useCallback(() => {
    return isPreviewable ? ModalSize.Large : ModalSize.Default;
  }, [isPreviewable]);

  return {
    assetDataToShow,
    modalTitle,
    openModal,
    closeModal,
    getModalSize,
    handleViewMedicationPlanPDF,
  };
};
