import React from 'react';
import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';

type OtherPagesProps = {
  headerComponent: JSX.Element;
  footerComponent: JSX.Element;
  children: JSX.Element | JSX.Element[];
};

export default function CareplanReportPage({
  headerComponent,
  footerComponent,
  children,
}: OtherPagesProps): JSX.Element {
  return (
    <>
      {headerComponent}
      <Box className="page-content">{children}</Box>
      {footerComponent}
    </>
  );
}
