function toCamelCase (str:string) : string {
    return str.replace(/[-_\s](.)/g, function(a) {
                      return a.toUpperCase();
                  })
                  .replace(/[-_\s]/g, '')
                  .replace(/^(.)/, function(b) {
                      return b.toLowerCase();
                  });
};

export default toCamelCase;