import { useReducer, useEffect } from 'react';
import RoleHelperUtility from '../RoleHelperUtility';

import storeInfo from '../../../store';

const getRolePermission = async (
  componentType,
  actions,
  isLoggedIn: boolean
) => {
  const { user } = storeInfo.store.getState().session;

  if (isLoggedIn && user) {
    try {
      const userPermission =
        await RoleHelperUtility.getLoggedInUserRolePermissions(
          user,
          componentType
        );

      if (userPermission && userPermission.length > 0) {
        const userPermissionFilter = userPermission.filter(
          role => role.action === actions
        );
        return userPermissionFilter.length > 0
          ? userPermissionFilter[0].allowed
          : false;
      }
    } catch (error) {
      return false;
    }
  }
};

const useUserPermission = (componentType, actions, isLoggedIn: boolean) => {
  const getUserPermission = (_state, permission) => {
    return permission;
  };

  const [hasUserPermission, setUserPermission] = useReducer(
    getUserPermission,
    null
  );

  useEffect(() => {
    const getRoleAccess = async () => {
      const rolePermission = await getRolePermission(
        componentType,
        actions,
        isLoggedIn
      );
      setUserPermission(rolePermission);
    };

    getRoleAccess();
  }, [isLoggedIn]);

  return hasUserPermission;
};

export { useUserPermission, getRolePermission };
