import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Box } from '@ui-common-files/components';

import CaretaskAnsweringContentAttachment from './CaretaskAnsweringContentAttachment/CaretaskAnsweringContentAttachment';
import useCaretaskAnsweringContentService from './CaretaskAnsweringContent.service';

import './CaretaskAnsweringContent.style.scss';

export default function CaretaskAnsweringContent({ setFlashMessage }) {
  const {
    currentComponent,
    getTitleContent,
    getDescriptionContent,
    shouldShowDescription,
    getCurrentComponentView,
  } = useCaretaskAnsweringContentService(setFlashMessage);

  return (
    <Box className="answer-view__caretask-components">
      <Box className="answer-view__caretask-components-title">
        {ReactHtmlParser(getTitleContent())}
      </Box>
      {shouldShowDescription && (
        <Box className="answer-view__caretask-components-description">
          {ReactHtmlParser(getDescriptionContent())}
        </Box>
      )}
      {currentComponent?.asset && currentComponent?.assetId && (
        <CaretaskAnsweringContentAttachment />
      )}
      {getCurrentComponentView()}
    </Box>
  );
}
