import React, { useContext } from 'react';
import Stepper from '../../../caro-ui-commonfiles/components/Stepper/Stepper';
import Step from '../../../caro-ui-commonfiles/components/Stepper/Step';
import I18n from 'i18next';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

const EditCareplanModalHeader = ({}) => {
  const { currentStep } = useContext(PatientOverviewContext);
  return (
    <div style={{ width: '70%' }}>
      <Stepper currentStep={currentStep}>
        <Step text={('Step 1 text', I18n.t('steps.settings'))} />

        <Step text={('Step 2 text', I18n.t('steps.thresholdSettings'))} />

        <Step text={('Step 3 text', I18n.t('steps.assignAndSend'))} />
      </Stepper>
    </div>
  );
};

export default EditCareplanModalHeader;
