import { useEffect, useRef } from 'react';

export default function useAbortController() {
  const abortControllerRef = useRef<AbortController>(null);

  const resetAbortController = () => {
    abortControllerRef.current = new AbortController();
  };

  useEffect(() => {
    resetAbortController();
    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  return {
    getAbortController: () => {
      if (!abortControllerRef.current) {
        resetAbortController();
      }
      return abortControllerRef.current;
    },
    resetAbortController,
  };
}
