import axios from 'axios';
import I18n from 'i18next';
import moment from 'moment';
import serviceConfig from '../../serviceConfig.json';

const AssignCareplanRequest = async (
  id,
  setFlashMessage,
  setCareplanData,
  setCareplanScoringScheme,
  icds,
  tags,
  nbrComponents
) => {
  const {
    lifespanUnits,
    rruleFreqToRepeatTypeIndex,
    generateWeekdayArray,
  } = require('../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils');
  const repeatTypesArray = rruleFreqToRepeatTypeIndex();

  const lifespanUnitsObj = lifespanUnits();
  try {
    const response = await axios.post(
      serviceConfig.brokerService.assignCareplan.uri + id
    );
    if (response) {
      const careplan = response.data;
      const recurrenceRule = careplan.rruleRecurrence;
      const startTime = careplan.template.startTime.split(':');
      const repeatTypeIndex = careplan.rruleRecurrence.origOptions.freq || 3;
      const hours = parseInt(startTime[0]);
      const minutes = parseInt(startTime[1]);
      if(response.data.template.scoringScheme){
        setCareplanScoringScheme(response.data.template.scoringScheme);}else {
          setCareplanScoringScheme([])
        }
    
      setCareplanData({
        id: careplan.template.id,
        title: careplan.template ? careplan.template.name : '',
        description: careplan.template.description ? careplan.template.description : '',
        phaseType: careplan.phaseTypeObject
          ? {
              value: careplan.phaseTypeObject.id,
              label: I18n.t(careplan.phaseTypeObject.name),
            }
          : null,
        startDate: moment()
          .hour(hours)
          .minute(minutes)
          .toDate(),
        customTime: careplan.template.startTime
          ? { hour: hours, minute: minutes }
          : { hour: 9, minute: 0 },
        customTimeChecked: careplan.timeOfDayRecurrence[0] === false ? false : careplan.timeOfDayRecurrence[0],
        lifespan: careplan.template.lifespan,
        lifespanUnit: lifespanUnitsObj[careplan.lifespanUnitIndex],
        rruleRecurrence: careplan.rruleRecurrence,
        repeatType: repeatTypesArray[repeatTypeIndex],
        repeatInterval: recurrenceRule.origOptions.interval
          ? {
              value: recurrenceRule.origOptions.interval,
              label: recurrenceRule.origOptions.interval,
            }
          : { value: 1, label: 1 },
        occurrenceCount: recurrenceRule.origOptions.count || 1,
        endDate: moment()
          .endOf('day')
          .toDate(),
        weeklyRecurrence: recurrenceRule.options.byweekday
          ? generateWeekdayArray(recurrenceRule.options.byweekday)
          : new Array(7).fill(false),
        occurrenceCountCheck: !recurrenceRule.origOptions.until,
        icds,
        nbrComponents,
        isAssessment: careplan.isAssessment,
        isEvaluating: careplan.template.isEvaluating,
        shouldUserAnswerCareplan: careplan.shouldUserAnswerCareplan,
        isReportEnabled: careplan.template.isReportEnabled,
        reportOccurenceNumber: careplan.template.reportOccurenceNumber,
        reportLanguage: careplan.template.reportLanguage,
        shareOverAllScore: careplan.template.shareOverAllScore,
      });
    }
    return response;
  } catch (error) {
    setFlashMessage({
      type: error.response.data.type,
      content: error.response.data.content,
    });
    throw new Error(error.response);
  }
};

export default AssignCareplanRequest;
