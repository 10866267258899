import { useState, useEffect, useContext, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { AxiosResponse } from 'axios';

import {
  convertDateStringToMsTimestamp,
  convertDateStringToSecTimestamp,
  formatDate,
  isDateInFuture,
} from '@global/Date';
import { AuxiliaryColors, PrimaryColor } from '@ui-common-files/utils';
import { DateFormat } from '@ui-common-files/utils/dateUtils/dateUtil.type';
import { setDateRangeOnViewChange } from './OverviewDataCard.utils';

import { PatientOverviewContext } from '../../../PatientOverview/PatientOverview';

import { GraphData, MedicationData, SelectedPoint } from '../Overview.type';
import { getGraphData, getMedicationData } from '../Overview.api';
import { useOverviewStore } from '../Overview.store';

const useOverviewDataCardService = () => {
  const initialGraphData: GraphData = useMemo(
    () => ({
      isBloodPressureSelected: false,
      numberOfLines: 0,
      colorMap: {},
      points: [],
    }),
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const availableColors = [PrimaryColor.MAIN_COLOR, AuxiliaryColors.GREEN];
  const [graphData, setGraphData] = useState<GraphData>(initialGraphData);
  const {
    patientInfo,
    devicesQuery,
    setDevicesQuery,
    shouldGetGraphViewData,
    setShouldGetGraphViewData,
    isChatOpened,
  } = useContext(PatientOverviewContext);

  const {
    setOverviewData,
    overviewDataPoints,
    setMedicationData,
    medicationData,
  } = useOverviewStore(
    useShallow(state => ({
      setOverviewData: state.setOverviewData,
      overviewDataPoints: state.overviewDataPoints,
      setMedicationData: state.setMedicationData,
      medicationData: state.medicationData,
    }))
  );

  const onSelect = activities => {
    setDevicesQuery({
      ...devicesQuery,
      activities,
    });
  };

  useEffect(() => {
    const newDataPoints = overviewDataPoints?.selectedDataPoints?.map(
      (dataPoint: SelectedPoint, index: number) => ({
        key: dataPoint.healthDataKey,
        color: index === 0 ? availableColors[0] : availableColors[1],
      })
    );
    onSelect(newDataPoints || []);
  }, [overviewDataPoints?.selectedDataPoints]);

  useEffect(() => {
    setShouldGetGraphViewData(true);
    if (devicesQuery.isTodayActive) {
      const newDate = setDateRangeOnViewChange(devicesQuery.filter);
      setDevicesQuery({
        ...devicesQuery,
        date: newDate,
      });
    }
  }, [
    devicesQuery.isTodayActive,
    devicesQuery.filter,
    devicesQuery.isLastDataActive,
  ]);

  useEffect(() => {
    let graphDataResponse: AxiosResponse<GraphData> = null;

    const fetchGraphData = async () => {
      setIsLoading(true);

      if (devicesQuery.activities.length > 0 && shouldGetGraphViewData) {
        graphDataResponse = await getGraphData({
          patientId: patientInfo.id,
          filter: devicesQuery.filter,
          isLastDataActive: devicesQuery.isLastDataActive,
          startDate: formatDate(
            devicesQuery.date?.start?.toString(),
            DateFormat.DATE_HOUR_MINUTE_SECOND,
            'en'
          ),
          endDate: formatDate(
            devicesQuery.date?.end?.toString(),
            DateFormat.DATE_HOUR_MINUTE_SECOND,
            'en'
          ),
          selectedPoints: overviewDataPoints.selectedDataPoints,
        });

        if (graphDataResponse) {
          const { data }: { data: GraphData } = graphDataResponse;
          setOverviewData(data);

          if (
            devicesQuery.isLastDataActive &&
            data.date &&
            data.date !== devicesQuery.date
          ) {
            setDevicesQuery({
              ...devicesQuery,
              date: data.date,
            });
          }

          setOverviewData(data || null);
          setGraphData(data || initialGraphData);
        }
      }

      if (devicesQuery.activities.length === 0) {
        setGraphData(initialGraphData);
        setOverviewData(null);
      }

      setIsLoading(false);
    };

    const fetchMedicationData = async () => {
      if (devicesQuery.activities.length > 0 && shouldGetGraphViewData) {
        setShouldGetGraphViewData(false);

        const dateToSend =
          devicesQuery.isLastDataActive && graphDataResponse
            ? graphDataResponse.data.date
            : devicesQuery.date;

        const medicationResponse = await getMedicationData({
          patientId: patientInfo.id,
          startDate: convertDateStringToSecTimestamp(
            dateToSend.start.toString()
          ),
          endDate: convertDateStringToSecTimestamp(dateToSend.end.toString()),
        });

        if (medicationResponse?.data) {
          const { data } = medicationResponse;
          const startTimestamp = convertDateStringToMsTimestamp(
            dateToSend.start
          );

          if (!isDateInFuture(startTimestamp)) {
            setMedicationData(data as MedicationData[]);
          }
        }
      }
    };

    const fetchData = async () => {
      await fetchGraphData();
      await fetchMedicationData();
    };

    fetchData();
  }, [devicesQuery, shouldGetGraphViewData]);

  return {
    graphData,
    medicationData,
    devicesQuery,
    setDevicesQuery,
    isChatOpened,
    isLoading,
  };
};
export default useOverviewDataCardService;
