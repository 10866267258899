import axios from 'axios';
import serviceConfig from '../../serviceConfig.json';

const AssignCareplanToPatientsRequest = async (
  id,
  closeModal,
  setFlashMessage,
  acceptedSendWarning,
  dataObject,
  isBulkAssignment = false
) => {
  try {
    const assignmentUrl = isBulkAssignment
      ? serviceConfig.brokerService.bulkAssignCareplanToPatients.uri
      : serviceConfig.brokerService.assignCareplanToPatients.uri;
    let response = await axios.post(assignmentUrl + id, {
      acceptedSendWarning: acceptedSendWarning,
      ...dataObject,
    });
    if (response) {
      closeModal();
      setFlashMessage({
        type: response.data.type,
        content: response.data.content,
      });
      return response;
    }
  } catch (error) {
    closeModal();
    setFlashMessage({
      type: error.response.data.type,
      content: error.response.data.content,
    });
    throw new Error(error.response);
  }
};

export default AssignCareplanToPatientsRequest;
