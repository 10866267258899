import {
  MILLION,
  ZERO,
} from '../ComplexScoreSetting/ComplexScoreSetting.service';

const isScoreSuitable = (score: number | null): boolean => {
  const hasAdequateScoreRange = score >= ZERO && score <= MILLION;
  return score !== null && hasAdequateScoreRange;
};

export { isScoreSuitable };
