import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  Box,
  Flex,
  Tag,
  Checkbox,
  Dropdown,
  RadioButton,
  Slider,
  TextInput,
  IconButton,
  BodyChart,
} from '@ui-common-files/components';
import {
  TagsType,
  CheckBoxRadioVariants,
  slider,
  PrimaryColor,
  ActionColors,
  Greys,
  toLocalizeDate,
  IconButtonVariant,
} from '@ui-common-files/utils';
import {
  convertLinkToBlob,
  navigateToExternalUrl,
} from '../../../Utils/Asset/assetHelper';
import ASSET_TYPES from '../../../Utils/Asset/assetTypes';

import componentTypeOrder from '../careplanComponentTypeOrder';
import {
  renderTagsForChoices,
  renderFormattedJumpData,
} from '../LogicJump/logicJumpTags';
import { nonMedical } from '../../../../config';
import serviceConfig from '../../../../serviceConfig.json';
import RankingTitleComponent from './RankingTitle';
import { ApiClient } from '@global/Services';

export const SingleChoice = ({ component, isEvaluating, hasSubScores }) => {
  const { t } = useTranslation();
  const optionsList =
    component.careplanComponentTypeId === componentTypeOrder.INFORMATION
      ? ['Yes', 'No']
      : component.optionJson.options;
  const answerIndex =
    component.answers[0] &&
    component.answers[0].answer_json &&
    component.answers[0].answer_json.optionIndex;
  const thresholds = component.componentThresholds;
  const thresholdIndex =
    thresholds.length && thresholds[0].optionJson.optionIndex;
  let parsedOptionWeights = [];
  const { optionWeights } = component;
  if (optionWeights !== null && optionWeights !== 'null') {
    parsedOptionWeights = JSON.parse(optionWeights).weights;
  }
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);

  const logicJumpDataForTags = renderFormattedJumpData(component);
  return optionsList.map((option, i) => {
    const isChecked = i == answerIndex;
    const showWeight =
      optionWeights !== 'null' && optionWeights !== null && !allZeroWeight;
    let optionWeight = parsedOptionWeights[i];
    if (showWeight && !optionWeight) {
      optionWeight = 0;
    }
    return (
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name={option}
            id={option}
            label={option}
            inputWidth={30}
            checked={isChecked}
            handleOnChange={() => {}}
            valueInput=""
            handleInputOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
          {thresholdIndex &&
          (thresholdIndex.includes(i) ||
            thresholdIndex.includes(i.toString())) &&
          (nonMedical == false || nonMedical == 'false') ? (
            <Box margin="5px 0 0 5px">
              <Tag
                text={t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
          {logicJumpDataForTags.isJumpSet &&
            logicJumpDataForTags.logicJumpCollection &&
            logicJumpDataForTags.logicJumpCollection.length > 0 &&
            logicJumpDataForTags.logicJumpCollection[i] &&
            renderTagsForChoices(logicJumpDataForTags, i)}
          {showWeight && (isEvaluating || hasSubScores) ? (
            <div
              className="threshold-component__name"
              style={{ margin: '5px 0 0 10px' }}
            >
              <Tag
                text={`${t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const Freetext = ({ component }) => {
  const answer = component.answers[0]?.answer_text?.replace(/\n/g, ' ');

  if (answer != null && answer != 'skipped' && answer != 'überspringen') {
    return (
      <Box margin="24px 0 0 0">
        <Box otherStyles={{ width: '100%' }}>
          <Box
            otherStyles={{
              backgroundColor: Greys.LIGHT_GREY_20,
              padding: '5px 5px 5px 5px',
              border: ` 1px solid ${Greys.LIGHT_GREY_50} `,
              borderRadius: '2px',
            }}
          >
            <Box
              otherStyles={{
                fontSize: '16px',
                padding: '10px',
                color: Greys.DARK_GREY,
              }}
            >
              {answer}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  return null;
};

export const MultipleChoice = ({ component, isEvaluating, hasSubScores }) => {
  const { t } = useTranslation();
  let parsedOptionWeights = [];

  if (component.optionWeights && component.optionWeights !== 'null') {
    parsedOptionWeights = JSON.parse(component.optionWeights).weights;
  }
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  const optionsList = component.optionJson.options;
  const answerList = component.answers[0] && component.answers[0].answer_json;
  const thresholds = component.componentThresholds;
  const thresholdIndex =
    thresholds.length && thresholds[0].optionJson.optionIndex;
  const sortedList =
    answerList && answerList.order
      ? answerList.order.map(function (singleElem, index) {
        return (
          <>
            <Box margin="5px 0 0 0" key={index}>
              {index === 0 && (
                <RankingTitleComponent
                  title={t('common_labels.answer')}
                />
              )}
            </Box>
            <Flex>
              <Checkbox
                id={optionsList[singleElem]}
                label={optionsList[singleElem]}
                variant={CheckBoxRadioVariants.NON_EDITABLE}
                checked={false}
                handleOnChange={() => { }}
                disableActionOnly
              />
            </Flex>
          </>
        );
      })
      : null;

  const originList = optionsList.map((option, i) => {
    const isChecked = answerList && answerList[i] == true;
    const showWeight =
      component.optionWeights !== 'null' &&
      component.optionWeights !== null &&
      !allZeroWeight;
    let optionWeight = parsedOptionWeights[i];
    if (showWeight && (optionWeight == null || optionWeight == 'null')) {
      optionWeight = 0;
    }
    return (
      <Box margin="5px 0 0 0">
        {answerList && answerList.order && i === 0 && (
          <RankingTitleComponent
            title={t('common_labels.default_order')}
          />
        )}
        <Flex>
          <Checkbox
            id={option}
            label={option}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
            checked={isChecked}
            handleOnChange={() => {}}
            disableActionOnly
          />
          {thresholdIndex &&
          (thresholdIndex.includes(i) ||
            thresholdIndex.includes(i.toString())) &&
          (nonMedical == false || nonMedical == 'false') ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
          {showWeight && (isEvaluating || hasSubScores) ? (
            <div className="threshold-component__name">
              <Tag
                text={`${t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
  return (
    <Flex>
      <Box margin="5px 0">{originList}</Box>
      <Box margin="5px 0 0 30px">{sortedList}</Box>
    </Flex>
  );
};

export const NumericComponent = ({ component }) => {
  const { t } = useTranslation()
  const value = component.answers[0] && component.answers[0].answer_numeric;
  const thresholds = component.componentThresholds;
  const isFloatingNumber = component.decimal;
  const { careplanComponentTypeId } = component;
  if (value != null) {
    return (
      <>
        {careplanComponentTypeId === componentTypeOrder.NUMERIC_RANGE && (
          <div style={{ height: '120px', marginTop: 24, width: '100px' }}>
            <Slider
              id="sliderId"
              name="slider"
              value={value}
              min={component.minimumValue}
              max={component.maximumValue}
              minLabel={component.minLabel}
              maxLabel={component.maxLabel}
              onInput={() => {}}
              onChange={() => {}}
              variant={slider.variant.NON_EDITABLE}
              fromAnswerCaretask={true}
            />
          </div>
        )}
        {careplanComponentTypeId === componentTypeOrder.NUMERIC_VALUE && (
          <Box margin="24px 0 10px 0">
            <TextInput
              value={value}
              id="numericValueAnswer"
              isDisabled
              name="numericValueAnswer"
              placeholder=""
              handleOnChange={() => {}}
            />
          </Box>
        )}
        {isFloatingNumber && (
          <div style={{ height: '20px' }}>
            <span className="answerTextNumericValue">
              {t('previewCareplanComponent.floatingPointNumber')}
            </span>
          </div>
        )}
        {thresholds.map(threshold => (
          <div
            style={{
              display: 'inline-flex',
              flexDirection: 'row',
              marginLeft: '10px',
            }}
          >
            {(nonMedical == false || nonMedical == 'false') && (
              <Tag
                text={threshold.comparison + threshold.numericValue}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            )}
          </div>
        ))}
      </>
    );
  }
  return null;
};

export const YesNoComponent = ({ component }) => {
  const { t } = useTranslation()
  const answer = component.answers[0] && component.answers[0].answer_boolean;
  const thresholds = component.componentThresholds;
  let threshold = null;
  if (thresholds.length) threshold = thresholds[0].booleanValue;

  return (
    <>
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name="Yes"
            id="Yes"
            label={t('common_labels.yes')}
            inputWidth={30}
            checked={answer == true}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
          {threshold == true &&
            answer == true &&
            (nonMedical == false || nonMedical == 'false') && (
              <Box margin="0 0 0 5px">
              <Tag
                text={t('threshold.threshold')}
                  fill={ActionColors.THRESHOLD}
                  type="squared"
                />
              </Box>
            )}
        </Flex>
      </Box>
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name="No"
            id="No"
            label={t('common_labels.no')}
            inputWidth={30}
            checked={answer == false}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
          {threshold == false &&
            answer == false &&
            (nonMedical == false || nonMedical == 'false') && (
              <Box margin="0 0 0 5px">
                <Tag
                text={t('threshold.threshold')}
                  fill={ActionColors.THRESHOLD}
                  type="squared"
                />
              </Box>
            )}
        </Flex>
      </Box>
    </>
  );
};

export const InformationComponent = ({
  component,
  isEvaluating,
  hasSubScores,
}) => {
  const { t } = useTranslation()
  const optionsList =
    component.careplanComponentTypeId === componentTypeOrder.INFORMATION
      ? ['Yes', 'No']
      : component.optionJson.options;
  const answerIndex =
    component.answers[0] &&
    component.answers[0].answer_json &&
    component.answers[0].answer_json.optionIndex;
  const thresholds = component.componentThresholds;
  const thresholdIndex =
    thresholds.length > 0 && thresholds[0].optionJson
      ? thresholds[0].optionJson.optionIndex
      : -1;
  let parsedOptionWeights = [];
  const { optionWeights } = component;
  if (optionWeights !== null && optionWeights !== 'null') {
    parsedOptionWeights = JSON.parse(optionWeights).weights;
  }
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);

  const logicJumpDataForTags = renderFormattedJumpData(component);
  return optionsList.map((option, i) => {
    const isChecked = i == answerIndex;
    const showWeight =
      optionWeights !== 'null' && optionWeights !== null && !allZeroWeight;
    let optionWeight = parsedOptionWeights[i];
    if (showWeight && !optionWeight) {
      optionWeight = 0;
    }

    return (
      <Box margin="5px 0">
        <Flex>
          {thresholdIndex > -1 &&
          i == thresholdIndex &&
          (nonMedical == false || nonMedical == 'false') &&
          answerIndex == thresholdIndex ? (
            <Box margin="5px 0 0 5px">
              <Tag
                text={t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
          {logicJumpDataForTags.isJumpSet &&
            logicJumpDataForTags.logicJumpCollection &&
            logicJumpDataForTags.logicJumpCollection.length > 0 &&
            logicJumpDataForTags.logicJumpCollection[i] &&
            renderTagsForChoices(logicJumpDataForTags, i)}
          {showWeight && (isEvaluating || hasSubScores) ? (
            <div
              className="threshold-component__name"
              style={{ margin: '5px 0 0 10px' }}
            >
              <Tag
                text={`${t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const PainLocationChart = ({ component }) => {
  const answer = component.answers[0];

  if (
    answer &&
    answer.bodychart != null &&
    answer.bodychart != 'SKIPPED' &&
    answer.bodychart != 'überspringen'
  ) {
    const body = answer.bodychart;
    const thresholds = component.componentThresholds.map(t => {
      return t.bodyChart;
    });
    const answerX = answer.answer_x;
    const answerY = answer.answer_y;
    const circlePos = [
      {
        marginTop: 300 * answerY - 15,
        marginLeft: 122 * answerX - 17.5,
      },
    ];
    return (
      <>
        <BodyChart
          circlePos={circlePos}
          type={answer.back ? 'back' : 'front'}
          onClick={() => {}}
        />
        <div style={{ marginTop: '21px' }}>
          <Flex alignItems="center">
            {thresholds &&
              thresholds.includes(answer.bodychart) &&
              (nonMedical == false || nonMedical == 'false') && (
                <Box margin="0 5px 0 0">
                  <Icon
                    name="threshold"
                    size={24}
                    fill={ActionColors.THRESHOLD}
                  />
                </Box>
              )}
            <Box margin="5px 0 0 0" width="100%">
              <PainLocationChartDropdown
                placeholder=""
                isDisabled
                onChange={() => {}}
                option={body}
              />
            </Box>
          </Flex>
        </div>
      </>
    );
  }
  return null;
};

export const PainLocationChartDropdown = ({
  placeholder,
  onChange,
  option,
  isDisabled,
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(
    option ? { value: option, label: t(`bodyChartImage.${option}`) } : null
  );
  return (
    <Flex alignItems="center">
      <Box margin="0 5px 10px 0" width="100%">
        <Dropdown
          value={value}
          options={[value]}
          isDisabled={isDisabled}
          placeholder={placeholder}
          handleOnChange={val => {
            setValue(val);
            onChange(val);
          }}
          heightControl={36}
        />
      </Box>
    </Flex>
  );
};

export const GroupDescriptionComponent = ({ component }) => {
  const { t } = useTranslation()
  const answer = component.answers[0] && component.answers[0].answer_boolean;
  const thresholds = component.componentThresholds;
  let threshold = null;
  if (thresholds.length) threshold = thresholds[0].booleanValue;

  return (
    <>
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name="Yes"
            id="Yes"
            label={t('common_labels.enter')}
            inputWidth={30}
            checked={answer == true}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
          {threshold == true &&
            answer == true &&
            (nonMedical == false || nonMedical == 'false') && (
              <Box margin="0 0 0 5px">
                <Tag
                  text={t('threshold.threshold')}
                  fill={ActionColors.THRESHOLD}
                  type="squared"
                />
              </Box>
            )}
        </Flex>
      </Box>
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name="No"
            id="No"
            label={t('common_labels.skip')}
            inputWidth={30}
            checked={answer == false}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
          {threshold == false &&
            answer == false &&
            (nonMedical == false || nonMedical == 'false') && (
              <Box margin="0 0 0 5px">
                <Tag
                  text={t('threshold.threshold')}
                  fill={ActionColors.THRESHOLD}
                  type="squared"
                />
              </Box>
            )}
        </Flex>
      </Box>
    </>
  );
};

export const UploadFile = ({ component }) => {
  const { t } = useTranslation()
  const answer = component.answers[0];
  const [fileUrl, setFileUrl] = useState('');
  const [blobURL, setBlobURL] = useState(fileUrl);
  const [assetTypeId, setAssetTypeId] = useState(null);
  useEffect(() => {
    if (answer && answer.assetId) {
      ApiClient.GET({
        url: `${serviceConfig.brokerService.getAssetById.uri}${answer.assetId}`,
      }).then(response => {
        if (response) {
          const file = response.data.assetData;
          setFileUrl(file.directLink);
          setAssetTypeId(file.assetTypeId);
          convertLinkToBlob(file.directLink)
            .then(convertedUrl => {
              setBlobURL(convertedUrl);
            })
            .catch(() => {
              setBlobURL(null);
            });
        }
      });
    }
  }, []);
  const showFile = () => {
    switch (fileUrl) {
      case assetTypeId === ASSET_TYPES.VIDEO && fileUrl:
        return (
          <video
            playsinline
            controls="true"
            className="uploadedFile-resultView"
            frameBorder="0"
            src={fileUrl}
            type="video/mp4"
          ></video>
        );
      case assetTypeId === ASSET_TYPES.IMAGE && fileUrl:
        return (
          <>
            <div className="asset-show-preview__icon-upload-file">
              <IconButton
                type="button"
                name="previewInShow"
                size={26}
                variant={IconButtonVariant.PREVIEW}
                onClick={() => {
                  navigateToExternalUrl(blobURL || fileUrl);
                }}
                tooltipText={t('asset_view.openInBrowser')}
              />
            </div>
            <img src={fileUrl} className="uploadedFile-resultView" />
          </>
        );
      case assetTypeId === ASSET_TYPES.IMAGE && fileUrl:
        return <img src={fileUrl} className="uploadedFile-frame" />;
      case assetTypeId === ASSET_TYPES.DOCUMENT && fileUrl:
        return (
          <>
            <div className="asset-show-preview__icon-upload-file">
              <IconButton
                type="button"
                name="previewInShow"
                size={26}
                variant={IconButtonVariant.PREVIEW}
                onClick={() => {
                  navigateToExternalUrl(fileUrl);
                }}
                tooltipText={t('asset_view.openInBrowser')}
              />
            </div>
            <iframe src={fileUrl} className="uploadedFile-resultView" />
          </>
        );
      case assetTypeId === ASSET_TYPES.AUDIO && fileUrl:
        return (
          <div
            className="audio-style"
            style={{ width: '60%', marginTop: '10px' }}
          >
            <audio playsinline controls="true" src={fileUrl} type="audio/mp3" />
          </div>
        );
      default:
        return <div className="default-preview-icon__div"></div>;
    }
  };
  return showFile();
};

export const Datepicker = ({ component }) => {
  const { t } = useTranslation()
  const answer = component.answers[0] && component.answers[0].answer_date;
  const [thresholds] = component.componentThresholds;
  const { comparison, numericValue, unit } = thresholds || {};
  const inputContent =
    answer !== null && answer !== undefined
      ? toLocalizeDate(answer)
      : '--/--/----';
  const thresholdCrossed =
    answer !== null && answer !== undefined && answer.thresholdCrossed
      ? answer.thresholdCrossed
      : false;
  let unitTranslation;
  if (unit) {
    unitTranslation = t(`datePicker.${unit.split('(')[0]}`);
  }

  return (
    <>
      <Flex alignItems="center" otherStyles={{ margin: '15px 0 0 0' }}>
        {thresholdCrossed && (
          <Box margin="0 5px 0 0">
            <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
          </Box>
        )}
        <Box width={140}>
          <TextInput
            value={inputContent}
            id="datepickerAnswer"
            isDisabled
            name="datepickerAnswer"
            placeholder=""
            handleOnChange={() => {}}
          />
        </Box>
        {thresholds && (
          <div className="displayThresholdParentContainer">
            <span className="thresholdSpan">{`${t(
              `common_labels.label_${comparison}`
            )} ${numericValue} ${unitTranslation}`}</span>
          </div>
        )}
      </Flex>
    </>
  );
};
