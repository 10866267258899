import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Loader from 'react-loader-spinner';

import { HideFlashMessage, ApiClient } from '@global/Services';
import serviceConfig from '../../../../serviceConfig.json';

import {
  Dropdown,
  IconButton,
  Box,
  Flex,
  Label,
  ToggleButton,
  Modal,
  InputFieldWithCopy,
  ExportButtonDropdown,
} from '@ui-common-files/components';

import {
  ComponentTranslationKeys,
  ActionTranslationKeys,
  SubmissionTypes,
  ButtonSize,
  ButtonVariant,
  PrimaryColor,
} from '@ui-common-files/utils';
import checkUserCredential from '../../../Utils/checkUserCredential';
import { PromptContent, PromptFooter } from '../../common/ConfirmationPrompt';
import QrCodeOptions from './QrCodeOptions';

import useQrCodePanelService from './QrCodePanel.service';

const QrCodePanel = ({
  onEdit,
  onAssign,
  showQrCodePanel,
  closePanel,
  setQrCodeActiveToggle,
  qrCodeActiveToggle,
  setFlashMessage,
}) => {
  const { user } = useSelector(state => state.session);
  const siteName = user.institutions[0].name;
  const siteId = user.institutions[0].id;
  const [qrCodeImg, setQrCodeImg] = useState('');
  const [qrCodeExportedImg, setQrCodeExportedImg] = useState('');
  const [siteLogo, setSiteLogo] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [qrCodeDetails, setQrCodeDetails] = useState('');
  const [qrCodeDataObject, setQrCodeDataObject] = useState('');
  const [isUserHasRight, setIsUserHasRight] = useState(false);
  const [isDisclaimerModalOpenForToggle, setIsDisclaimerModalOpenForToggle] =
    useState(false);
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const [imageFileName, setImageFileName] = useState('');
  const [imageExt, setImageExt] = useState('');
  const [disabledQrCode, setDisabledQrCode] = useState(false);
  const {
    qrCodeType,
    setQrCodeType,
    qrCodeDropdownOptions,
    hasSatelliteCredentials,
  } = useQrCodePanelService(siteId, siteName);
  const qrCodeTypeValue =
    qrCodeType.value !== 0 ? qrCodeType.satelliteData.id : null;
  const { t } = useTranslation();

  const toggleQrCode = (id, isCarePathway, shouldShowMessage = true) => {
    ApiClient.POST({
      url: serviceConfig.brokerService.toggleQrCodeActiveState.uri,
      payload: {
        id,
        isCarePathway,
      },
    }).then(response => {
      if (shouldShowMessage && response?.data) {
        setQrCodeActiveToggle(response.data.qrcodeResponse.isActive);
        setFlashMessage({
          type: 'success',
          content: t(
            response.data.qrcodeResponse.isActive
              ? 'qrCode.qrCodeActivationFlashMessage'
              : 'qrCode.qrCodeDeActivationFlashMessage'
          ),
        });
      }
    });
  };

  useEffect(() => {
    setIsImageLoading(true);
    setDisabledQrCode(
      !showQrCodePanel.allFutureCareplans &&
        showQrCodePanel.carepathwayTemplateUUID
    );
    async function getQrCodeImage() {
      const userRights = await checkUserCredential(
        showQrCodePanel.carepathwayTemplateUUID
          ? ComponentTranslationKeys.CAREPATHWAY
          : ComponentTranslationKeys.CAREPLAN,
        ActionTranslationKeys.SEND
      );
      setIsUserHasRight(userRights == true);
      const templateType = showQrCodePanel.carepathwayTemplateUUID
        ? t('types.componentType.carepathway')
        : t('types.componentType.careplan');
      const {
        data: { qrCodeImageResponse, exportedQrcode, siteLogo, qrCodeData },
      } = await axios.post(
        serviceConfig.brokerService.getTemplateQrCodeImage.uri,
        {
          templateUUID: showQrCodePanel.templateUUID,
          carepathwayTemplateUUID: showQrCodePanel.carepathwayTemplateUUID,
          siteName,
          siteId,
          templateName: showQrCodePanel.name,
          templateType,
          satelliteId: qrCodeTypeValue,
          satelliteName: qrCodeType.satelliteData?.name,
        }
      );
      if (qrCodeImageResponse) {
        setQrCodeExportedImg(exportedQrcode);
        setSiteLogo(siteLogo);
        setQrCodeDataObject(qrCodeData);
        setQrCodeImg(qrCodeImageResponse);
      }

      let apiUrl =
        serviceConfig.brokerService.getQrCodeByCarePathwayTemplateUUID.uri;
      let templateUUID = showQrCodePanel.carepathwayTemplateUUID;
      if (showQrCodePanel.templateUUID) {
        apiUrl = serviceConfig.brokerService.getQrCodeByTemplateUUID.uri;
        templateUUID = showQrCodePanel.templateUUID;
      }
      const qrCodeDetailsResponse = await axios.post(apiUrl, {
        id: templateUUID,
        satelliteId: qrCodeTypeValue,
      });
      if (qrCodeDetailsResponse?.data) {
        setQrCodeDetails(qrCodeDetailsResponse.data.qrcodeResponse);
        setQrCodeActiveToggle(
          qrCodeDetailsResponse.data.qrcodeResponse.isActive
        );
        if (
          !showQrCodePanel.allFutureCareplans &&
          showQrCodePanel.carepathwayTemplateUUID &&
          qrCodeDetailsResponse.data.qrcodeResponse.isActive
        ) {
          toggleQrCode(
            qrCodeDetailsResponse.data.qrcodeResponse.id,
            qrCodeDetailsResponse.carepathwayTemplateUUID,
            false
          );
        }
      }
      if (qrCodeImageResponse && qrCodeDetailsResponse?.data) {
        setIsImageLoading(false);
      }
    }
    getQrCodeImage();
  }, [qrCodeType]);
  let separatorMarginTop;
  const careplanNameLength = showQrCodePanel.name.length;
  switch (careplanNameLength) {
    case careplanNameLength <= 23:
      separatorMarginTop = '74px';
      break;
    case careplanNameLength > 23:
      separatorMarginTop = '100px';
      break;
    case careplanNameLength > 46:
      separatorMarginTop = '126px';
      break;

    default:
      separatorMarginTop = '74px';
      break;
  }

  const handleToggleButtonChanged = async checked => {
    if (!isUserHasRight) {
      setQrCodeActiveToggle(!checked);
      return;
    }
    if (checked) {
      setIsDisclaimerModalOpenForToggle(true);
    } else {
      toggleQrCode(qrCodeDetails.id, qrCodeDetails.carepathwayTemplateUUID);
    }
  };

  const renderToggleButton = labelText => {
    return (
      <ToggleButton
        id={labelText}
        isDisabled={!isUserHasRight}
        checked={qrCodeActiveToggle}
        tooltipText={isUserHasRight ? '' : t('qrCode.qrCodeToggleTooltip')}
        handleOnChange={e => handleToggleButtonChanged(e.target.checked)}
      />
    );
  };

  const renderLabelWithToggleButton = labelText => {
    return (
      <div
        className="toggleIcdContainer"
        style={{ justifyContent: 'space-between' }}
      >
        <div>
          <Label text={labelText} isLongText={false} />
        </div>
        <div>{renderToggleButton(labelText)}</div>
      </div>
    );
  };

  const exportMenuItems = [
    {
      key: 0,
      value: 'PNG',
      icon: 'image',
      position: 1,
      label: 'png',
    },
    {
      key: 1,
      value: 'JPG',
      icon: 'image',
      position: 2,
      label: 'jpg',
    },
  ];

  const exportQrCode = item => {
    setImageExt(item.value);
    setIsDisclaimerModalOpen(true);
    setImageFileName(`QrCode_${showQrCodePanel.name}.${item.label}`);
  };
  const closeMessagePrompt = () => {
    setIsDisclaimerModalOpen(false);
    setIsDisclaimerModalOpenForToggle(false);
  };
  const closeMessagePromptToggle = () => {
    setIsDisclaimerModalOpenForToggle(false);
    setIsDisclaimerModalOpen(false);
  };
  const confirmAcknowledged = () => {
    switch (imageExt) {
      case 'PNG':
        saveAs(qrCodeExportedImg, imageFileName);
        break;
      case 'JPG':
        saveAs(qrCodeExportedImg, imageFileName);
        break;
      default:
        return;
    }
  };
  const ScanQrPopupModal = () => {
    return (
      <Modal
        isConfirmationDialog
        isExport={true}
        title={t(
          `qrCode.${
            qrCodeActiveToggle
              ? 'qrCodeDislaimerModalActivate'
              : 'qrCodeDislaimerModal'
          }`
        )}
        contentComponent={
          <PromptContent message={t('qrCode.qrCodeDisclaimer')} />
        }
        footerComponent={
          <PromptFooter
            setClose={false}
            close={
              qrCodeActiveToggle ? closeMessagePromptToggle : closeMessagePrompt
            }
            confirmHandler={() => {
              setIsDisclaimerModalOpenForToggle(false);
              if (qrCodeActiveToggle) {
                confirmAcknowledged();
                setIsDisclaimerModalOpen(false);
              } else {
                toggleQrCode(
                  qrCodeDetails.id,
                  qrCodeDetails.carepathwayTemplateUUID
                );
              }
            }}
            submissionType={SubmissionTypes.ACKNOWLEDGE_EXPORT}
          />
        }
        openModal={isDisclaimerModalOpenForToggle || isDisclaimerModalOpen}
        onClose={
          qrCodeActiveToggle ? closeMessagePromptToggle : closeMessagePrompt
        }
        hideFlashMessage={HideFlashMessage}
      />
    );
  };

  return (
    <Box>
      <Box className="careplan-name__div">
        <Box className="careplan-name__text">{showQrCodePanel.name}</Box>
        {hasSatelliteCredentials && (
          <Box otherStyles={{ margin: '16px 24px' }}>
            <Dropdown
              value={qrCodeType}
              options={qrCodeDropdownOptions}
              placeholder="QrCodeType"
              handleOnChange={event => {
                setQrCodeType(event);
              }}
            />
          </Box>
        )}
      </Box>
      {hasSatelliteCredentials && (
        <Box className="careplan-name__text" otherStyles={{ fontSize: 16 }}>
          {qrCodeType.label} {t('common_labels.QRCode')}
        </Box>
      )}
      <Box className="scanning-qrcode__text">
        {showQrCodePanel.carepathwayTemplateUUID
          ? !disabledQrCode && t('qrCode.scanningCarepathwayQrCodeAssigned')
          : t('qrCode.scanningCaretaskQrCodeAssigned')}
      </Box>
      <>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          otherStyles={{
            marginRight: '31.6px',
            marginLeft: '31.6px',
            marginTop: '53.6px',
          }}
        >
          {isImageLoading ? (
            <div>
              <Loader
                type="Oval"
                color={PrimaryColor.MAIN_COLOR}
                height={60}
                width={60}
              />
            </div>
          ) : (
            <div>
              {(!qrCodeActiveToggle || disabledQrCode) && (
                <div
                  style={{
                    width: '290px',
                    height: '290px',
                    backgroundColor: '#ffffffbf',
                    position: 'absolute',
                    zIndex: '0',
                    marginTop: '-20px',
                    marginLeft: '-10px',
                  }}
                ></div>
              )}
              <div style={{ width: '270px', height: '380' }}>
                <svg
                  style={{
                    position: 'absolute',
                    marginTop: '-30px',
                    marginLeft: '-20px',
                  }}
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.4445 2.55078H10.5557C6.13739 2.55078 2.55566 6.13251 2.55566 10.5508V27.3707"
                    stroke="#CDCDCD"
                    stroke-width="4"
                    stroke-linecap="round"
                  />
                </svg>
                <svg
                  style={{
                    position: 'absolute',
                    marginTop: '-30px',
                    marginLeft: '260px',
                  }}
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.444 27.37V10.55a8 8 0 0 0-8-8H2.555"
                    stroke="#CDCDCD"
                    stroke-width="4"
                    stroke-linecap="round"
                  />
                </svg>
                <Flex alignItems="center" justifyContent="center">
                  {siteLogo && (
                    <img
                      style={{
                        marginTop: '-25px',
                        backgroundColor: 'white',
                        position: 'absolute',
                        display: 'block',
                        maxHeight: '60px',
                        maxWidth: '128px',
                        padding: '4px',
                      }}
                      alt="siteLogo"
                      src={siteLogo}
                    />
                  )}
                  <img
                    style={{ marginTop: '-25px' }}
                    height="300"
                    width="300"
                    alt="qrCodeImg"
                    src={qrCodeImg}
                  />
                </Flex>

                <div style={{ marginLeft: '6px', marginTop: '-21px' }}>
                  <svg
                    style={{
                      marginLeft: '-26px',
                      marginTop: '-8px',
                      position: 'absolute',
                    }}
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.55469 2.62932L2.55469 19.4492C2.55469 23.8675 6.13642 27.4492 10.5547 27.4492L27.4436 27.4492"
                      stroke="#CDCDCD"
                      stroke-width="4"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    style={{
                      position: 'absolute',
                      marginTop: '-8px',
                      marginLeft: '254px',
                    }}
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.556 27.45h16.89a8 8 0 0 0 8-8V2.63"
                      stroke="#CDCDCD"
                      stroke-width="4"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </Flex>
        {!isImageLoading && (
          <div>
            {!disabledQrCode ? (
              <>
                <Box margin="58px 24px 0px 24px">
                  <Box padding="0px 0px 24px 0px">
                    <InputFieldWithCopy
                      iconName="Copy"
                      isDisabled={true}
                      buttonToolTip={t('toolTipsText.copy')}
                      value={qrCodeDataObject}
                    />
                  </Box>
                  <ExportButtonDropdown
                    items={exportMenuItems}
                    buttonLabel={t('qrCode.export')}
                    buttonType="button"
                    onClick={(e, item) => exportQrCode(item)}
                    variant={ButtonVariant.CONTAINED}
                    disabled={!qrCodeActiveToggle || disabledQrCode}
                    fullWidth
                    size={ButtonSize.MD}
                  />
                </Box>
                <Box margin="0px 3px 0px 3px">
                  {renderLabelWithToggleButton(t('qrCode.qrCodeActivate'))}
                  <span className="lineBetweenToggleContainers" />
                  <Box className="scanning-qrcode__text_bottom">
                    {t('qrCode.qrcodeInfo')}
                  </Box>
                </Box>
              </>
            ) : (
              <Box margin="58px 24px 0px 24px">
                <QrCodeOptions
                  onEdit={() =>
                    onEdit(showQrCodePanel.id, showQrCodePanel.name)
                  }
                  onAssign={() =>
                    onAssign(
                      showQrCodePanel.id,
                      showQrCodePanel.name,
                      showQrCodePanel.allFutureCareplans
                    )
                  }
                />
              </Box>
            )}
          </div>
        )}
        {(isDisclaimerModalOpen || isDisclaimerModalOpenForToggle) && (
          <ScanQrPopupModal />
        )}
      </>
      <div className="close-qrcode-panel">
        <IconButton
          name="close"
          onClick={closePanel}
          size={24}
          tooltipText={t('toolTipsText.close')}
        />
      </div>
    </Box>
  );
};

QrCodePanel.defaultProps = {
  closePanel: () => {},
};

export default QrCodePanel;
