import { ApiClient } from '@global/Services';

import serviceConfig from '../../../../serviceConfig.json';
import { GetTodosResponse, GetTodosCountResponse } from './TodoCards.type';

export const getTodos = (id: number, search: string) =>
  ApiClient.GET<GetTodosResponse, void>({
    url: `${
      serviceConfig.brokerService.getTodos.uri
    }?limit=999&patientId=${id}${search ? `&value=${search}` : ''}`,
  });

export const getTodosCount = (id: number) =>
  ApiClient.GET<GetTodosCountResponse, void>({
    url: `${serviceConfig.brokerService.getTodosCount.uri}?patientId=${id}`,
  });
