import React, { memo, useState } from 'react';
import { NodeProps, Position, NodeToolbar } from 'reactflow';
import { useTranslation } from 'react-i18next';

import {
  Flex,
  Box,
  Icon,
  IconButton,
  Modal,
} from '@ui-common-files/components';

import {
  PrimaryColor,
  ActionColors,
  Greys,
} from '@ui-common-files/utils/colors';
import { lifespanUnits } from '@ui-common-files/utils';
import { ScoringScheme } from '@type/Caretask';
import { HideFlashMessage } from '@global/Services';

import CareTaskShowModal from '../CareTaskShowModal/CareTaskShowModal';
import CareTaskNodeReferenceDate from './CareTaskNodeReferenceDate';
import CareTaskNodeSourceHandle from '../CareTaskNodeSourceHandle/CareTaskNodeSourceHandle';
import CareTaskNodeTargetHandle from '../CareTaskNodeTargetHandle/CareTaskNodeTargetHandle';
import { ViewCareplanModalFooter } from '../../../../Careplans/ViewCareplan';

import './CareTaskNode.styles.scss';

const SHOW_CARE_TASK_MODAL_DEFAULT: {
  isOpen: boolean;
  title: string | null;
  id: number | null;
} = {
  isOpen: false,
  title: null,
  id: null,
};

const CareTaskNode: React.FC = (node: NodeProps) => {
  const { t } = useTranslation();
  const { id: nodeId, data } = node;
  const { careTaskData, duration } = data;
  const sidebarType = data.isParent ? 'configuration' : 'linkedCareTask';
  const lifespan = `${careTaskData.lifespan} 
  ${
  lifespanUnits().find(
    item => item.key === `lifespan.${careTaskData.lifespanUnit}`
  ).label
}`;
  const [careTaskModal, setCareTaskModal] = useState(
    SHOW_CARE_TASK_MODAL_DEFAULT
  );

  return (
    <>
      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Right}>
        <Flex className="node-toolbar">
          <IconButton
            fill={PrimaryColor.MAIN_COLOR}
            size={30}
            name="configuration"
            tooltipText={t('carePathway_builder.tooltips.add_configuration')}
            tooltipPosition="right"
            onClick={() => {
              data.openConfiguration(sidebarType);
            }}
          />
          <IconButton
            fill={Greys.DARK_GREY_70}
            size={30}
            name="view"
            tooltipText={t('common_labels.label_show')}
            tooltipPosition="right"
            onClick={() => {
              setCareTaskModal({
                title: careTaskData.name,
                id: careTaskData.id,
                isOpen: true,
              });
            }}
          />
          <IconButton
            fill={Greys.DARK_GREY_70}
            size={30}
            name="graphNodes"
            tooltipText={t('carePathway_builder.tooltips.change_node_type')}
            tooltipPosition="right"
            onClick={() => {
              data.toggleNodeType(nodeId);
            }}
          />
          <IconButton
            fill={ActionColors.DELETE}
            size={30}
            name="delete"
            tooltipText={t('common_labels.label_delete')}
            tooltipPosition="right"
            onClick={() => {
              data.removeNode(node);
            }}
          />
        </Flex>
      </NodeToolbar>
      {!data.isParent && <CareTaskNodeTargetHandle />}
      <Box className="node-content">
        <Flex justifyContent="space-between">
          <Box>
            <Icon
              name={careTaskData.isAssessment ? 'assessment' : 'careplan'}
              size="16"
              fill={PrimaryColor.MAIN_COLOR}
            />
          </Box>
          {data.isParent && duration && (
            <CareTaskNodeReferenceDate
              startText={duration.durationText.startText}
              startTime={duration.startTime}
            />
          )}
        </Flex>
        <Box className="node-content_title">{data.label}</Box>
        {data.isParent && (
          <Flex className="node-content_item">
            <Box className="node-content_item-label">
              {t('common_labels.start')}:
            </Box>
            <Box className="node-content_item-content">
              {duration ? duration.durationText.startText : ''}
            </Box>
          </Flex>
        )}
        <Flex className="node-content_item">
          <Box className="node-content_item-label">
            {t('recurrence.repetition')}:
          </Box>
          <Box className="node-content_item-content">
            {data.isParent
              ? duration
                ? duration.durationText.occurrence
                : ''
              : ` 1 ${t('recurrence.times')}`}
          </Box>
        </Flex>
        {data.isParent && (
          <Flex className="node-content_item">
            <Box className="node-content_item-label">
              {t('common_labels.end')}:
            </Box>
            <Box className="node-content_item-content">
              {duration ? duration.durationText.endText : ''}
            </Box>
          </Flex>
        )}
        <Flex className="node-content_item">
          <Box className="node-content_item-label">
            {t('recurrence.lifespan')}:
          </Box>
          <Box className="node-content_item-content">
            {duration
              ? duration.durationText.lifeSpan
              : !data.isParent
                ? lifespan
                : ''}
          </Box>
        </Flex>
      </Box>
      {careTaskData.scoringScheme?.length > 0 && (
        <Box className="scoring-schema">
          {careTaskData.scoringScheme.map(
            (schema: ScoringScheme, index: number) =>
              schema.min === -1 && schema.max === -1 ? null : (
                <CareTaskNodeSourceHandle
                  key={`${nodeId}-${schema.id}`}
                  schema={schema}
                  index={index}
                />
              )
          )}
        </Box>
      )}
      <Modal
        title={t(careTaskModal.title)}
        openModal={careTaskModal.isOpen}
        contentComponent={<CareTaskShowModal careTaskId={careTaskModal.id} />}
        onClose={() => {
          setCareTaskModal({ title: null, id: null, isOpen: false });
        }}
        footerComponent={
          <ViewCareplanModalFooter
            close={() => {
              setCareTaskModal({ title: null, id: null, isOpen: false });
            }}
          />
        }
        hideFlashMessage={HideFlashMessage}
      />
    </>
  );
};

CareTaskNode.displayName = 'CareTaskNode';

export default memo(CareTaskNode);
