import React from 'react';
import I18n from 'i18next';
import moment from 'moment';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import ICDViewer from '../../../caro-ui-commonfiles/components/ICD/ICDViewer';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import '../../css/careplans.scss';
import { dropdownPhaseTypes } from '../../Utils/dropdownTypes';
import {
  renderAdditionalComponents,
  renderComponents,
} from './CareplanAdditionalComponentUtils';
import { isMainQuestion } from './CareplanHelperUtility';

const CareplanDataPreview = ({ careplanData }) => {
  const { icds } = careplanData;
  const { careplansWithThresholds, careplanLogicJumps } = careplanData;
  const phaseType = dropdownPhaseTypes()[careplanData.phaseTypeId - 1];
  const { customTime } = careplanData;
  const startTime = customTime && customTime.split(':');
  const careplanScoringScheme = careplanData.scoringScheme;

  const startDate = moment(careplanData.startDate)
    .hour(parseInt(startTime[0]))
    .minute(parseInt(startTime[1]))
    .locale(I18n.language)
    .format('DD MMM YYYY HH:mm');

  return (
    <>
      <div className="title-step-4">
        <Label
          className="title-step-4"
          text={I18n.t('newCareplan_view.title')}
        />
      </div>
      <h3 className="link">{careplanData.title}</h3>
      <div className="title-step-4">
        <Label text={I18n.t('common_labels.numberOfQuestions')} />
      </div>
      <h3 className="link">{careplansWithThresholds.length}</h3>
      <div className="title-step-4">
        <Label text={I18n.t('task.ICDReference')} />
      </div>
      <ICDViewer icds={icds} />
      <div className="title-step-4">
        <Label text={I18n.t('task.phaseType')} />
      </div>
      <h3 className="link">{phaseType.label}</h3>
      <div className="title-step-4">
        <Label text={I18n.t('recurrence.startsFrom')} />
      </div>
      <h3 className="link">{startDate}</h3>
      <div className="title-step-4">
        <Label text={I18n.t('medication.period')} />
      </div>
      <h3 className="link">{careplanData.recurrenceText}</h3>
      <div className="title-step-4">
        <Label text={I18n.t('recurrence.lifespan')} />
      </div>
      <h3 className="link">
        {`${careplanData.lifespan} ${careplanData.lifespanUnit}`}
      </h3>

      <h3 className="careplan-preview">
        {I18n.t('newCareplan_view.careplanPreview')}
      </h3>
      <Box padding="20px 0 0">
        {renderComponents(
          careplansWithThresholds.filter(component =>
            isMainQuestion(component[0].healthCategory)
          ),
          careplanLogicJumps,
          careplanData.isEvaluating,
          true
        )}
        {careplanData.isEvaluating &&
          renderAdditionalComponents(
            careplanScoringScheme,
            careplansWithThresholds,
            careplanLogicJumps,
            careplanData.isEvaluating
          )}
      </Box>
    </>
  );
};

export default CareplanDataPreview;
