import React from 'react';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  Box,
  Flex,
  Historial,
  HistorialStep,
  Label,
} from '@ui-common-files/components';
import { PrimaryColor } from '@ui-common-files/utils';
import { formatDate } from '@global/Date';
import { getAction, getComponent } from '@utils';

import {
  renderAdditionalComponents,
  renderComponents,
} from '../../../../Careplans/CareplanAdditionalComponentUtils';
import { isMainQuestion } from '../../../../Careplans/CareplanHelperUtility';
import { CareTaskShowModalProps } from './CareTaskShowModal.types';
import useCareTaskShowModalService from './CareTaskShowModal.service';

const CareTaskShowModal: React.FC = ({
  careTaskId,
}: CareTaskShowModalProps) => {
  const {
    isLoading,
    isEvaluating,
    careTaskData,
    ownershipLogs,
    careTaskScoringScheme,
    careTaskDataLogicJumps,
  } = useCareTaskShowModalService(careTaskId);
  const { t } = useTranslation();

  const renderCareplanTemplateHistory = () => {
    return ownershipLogs.map(log => (
      <Historial>
        <HistorialStep key="showTemplateHistorial">
          <Box className="show-template-history">
            <span>
              {t('history.assignment', {
                user: log.user ? log.user.username : '',
                action: getAction(log.action).toLowerCase(),
                component: getComponent(log.component),
                interpolation: { escapeValue: false },
              })}
            </span>
            <span className="show-template__history-date">
              {`${formatDate(
                moment(log.timeOfAction),
                'DD MMM YYYY'
              )} - ${formatDate(moment(log.timeOfAction), 'HH:mm')}`}
            </span>
          </Box>
        </HistorialStep>
      </Historial>
    ));
  };

  return (
    <>
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{ height: '60vh', flex: 1 }}
        >
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Flex>
      )}
      {!isLoading && (
        <Flex otherStyles={{ flex: 1 }}>
          <Box
            otherStyles={{
              height: '60vh',
              overflowY: 'auto',
              width: '43%',
            }}
          >
            <Box className="history-label">
              <Label text={t('common_labels.history')} />
            </Box>
            <Box className="show-template__historial-wrapper">
              {ownershipLogs && renderCareplanTemplateHistory()}
            </Box>
          </Box>
          <Flex
            flexDirection="column"
            otherStyles={{
              height: '60vh',
              paddingLeft: '40px',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            <h3 className="careplan-preview">
              {t('newCareplan_view.careplanPreview')}
            </h3>
            <Box padding="20px 0 0">
              {renderComponents(
                careTaskData.filter(component =>
                  isMainQuestion(component[0].healthCategory)
                ),
                careTaskDataLogicJumps,
                isEvaluating,
                true
              )}
              {isEvaluating &&
                renderAdditionalComponents(
                  careTaskScoringScheme,
                  careTaskData,
                  careTaskDataLogicJumps,
                  isEvaluating
                )}
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CareTaskShowModal;
