export const getActionButtonItems = (isActive, shouldDisableFn) => {
  let dropdownItems = [];
  if (isActive) {
    dropdownItems.push('common_labels.deactivateBtn');
  } else if (!shouldDisableFn()) {
    dropdownItems.push('common_labels.activateBtn');
  }
  return dropdownItems;
};

export const isSatelliteDisable = original => {
  return !original.isActive && original.site.every(site => !site.isActive);
};
