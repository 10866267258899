import axios, { AxiosResponse } from 'axios';
import serviceConfig from '../../../serviceConfig.json';
import { ScoringTypes } from '../../types/Careplan';
import IcdOps from '../../types/Common/IcdOps.type';

function getIcdsOrOpsByIds(
  ids: number[],
  icdRequested: boolean,
  updateStateByResult: React.Dispatch<React.SetStateAction<IcdOps[]>>
): void {
  
  axios.get(icdRequested ? serviceConfig.getIcds.uri : serviceConfig.getOps.uri, {
      params: {
        ids,
      },
    })
    .then((response: AxiosResponse<IcdOps[]>): void => {
      updateStateByResult(response.data);
    }).catch((error)=>{});;
}

function fetchIcdsOrOpsData(
  term: string,
  icdRequested: boolean
): Promise<IcdOps[]> {
  
  return axios.get(
    icdRequested ? serviceConfig.getIcds.uri : serviceConfig.getOps.uri,
    {
      params: {
        searchTerm: term,
      },
    }
  ).then((response: AxiosResponse<IcdOps[]>): IcdOps[] => response.data);
}

function getScoreFraction(id: ScoringTypes ): number {
  if (id === ScoringTypes.Well) return 75;
  if (id === ScoringTypes.Fair) return 50;
  if (id === ScoringTypes.Poor) return 25;
  return 0;
}

export { getIcdsOrOpsByIds, fetchIcdsOrOpsData, getScoreFraction };