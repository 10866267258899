import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '../../../../../../../caro-ui-commonfiles/components/Box/Box';
import AnsweringFreeText from '../AnsweringFreeText/AnsweringFreeText';
import AnsweringSingleChoice from '../AnsweringSingleChoice/AnsweringSingleChoice';
import AnsweringRanking from '../AnsweringRanking/AnsweringRanking';
import AnsweringYesNoSection from '../AnsweringYesNoSection/AnsweringYesNoSection';
import AnsweringNumericValue from '../AnsweringNumericValue/AnsweringNumericValue';
import AnsweringNumericRange from '../AnsweringNumericRange/AnsweringNumericRange';
import AnsweringPainlocation from '../AnsweringPainlocation/AnsweringPainlocation';
import AnsweringInformation from '../AnsweringInformation/AnsweringInformation';
import AnsweringBMI from '../AnsweringBMI/AnsweringBMI';
import AnsweringDatePicker from '../AnsweringDatePicker/AnsweringDatePicker';
import AnsweringMultipleChoice from '../AnsweringMulipleChoice/AnsweringMultipleChoice';
import AnsweringFileRequest from '../AnsweringFileRequest/AnsweringFileRequest';
import AnsweringBloodPressure from '../AnsweringBloodPressure/AnsweringBloodPressure';

import { Caretask, CaretaskComponent } from '../../../../../../types/Caretask';
import componentTypeOrder from '../../../../../Careplans/careplanComponentTypeOrder';
import { AnswerOnChange } from './CaretaskAnsweringContent.type';
import {
  CaretaskAnsweringNavigator,
  ComponentAnswer,
} from '../../CaretaskAnswering.type';

import {
  selectCaretask,
  selectNavigator,
  selectCurrentComponent,
  updateComponentAnswer,
  TITLE_DESCRIPTION_INDEX,
  selectMainComponentsCount,
  selectIsAnsweringMain,
} from '../../CaretaskAnswering.reducer';

import './CaretaskAnsweringContent.style.scss';

export default function useCaretaskAnsweringContentService(setFlashMessage) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const caretaskData: Caretask = useSelector(selectCaretask);
  const navigator: CaretaskAnsweringNavigator = useSelector(selectNavigator);
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const mainComponentsCount: number = useSelector(selectMainComponentsCount);
  const isAnsweringMain: boolean = useSelector(selectIsAnsweringMain);

  const handleOnChange = useCallback(
    ({
      answer,
      isSkipped,
      jumpTo = null,
      hasError = false,
    }: AnswerOnChange) => {
      if (currentComponent) {
        const answerObject: ComponentAnswer = {
          answer,
          isSkipped,
          jumpTo,
          careplanCareplanComponentMapperId:
            currentComponent.careplanCareplanComponentMapper.id,
          hasError,
        };
        dispatch(updateComponentAnswer(answerObject));
      }
    },
    [currentComponent]
  );

  const isMainComponentsLastScreen =
    navigator.Current === mainComponentsCount && isAnsweringMain;

  const getCurrentComponentView = useCallback(() => {
    if (
      navigator.Current === TITLE_DESCRIPTION_INDEX ||
      isMainComponentsLastScreen
    )
      return null;
    const getKey = (id: number) => {
      for (let key in componentTypeOrder) {
        if (componentTypeOrder[key] === id) {
          return key;
        }
      }
    };

    switch (currentComponent.careplanComponentTypeId) {
      case componentTypeOrder.FREE_TEXT:
        return <AnsweringFreeText handleOnChange={handleOnChange} />;
      case componentTypeOrder.SINGLE_CHOICE:
        return <AnsweringSingleChoice handleOnChange={handleOnChange} />;
      case componentTypeOrder.DATEPICKER:
        return <AnsweringDatePicker handleOnChange={handleOnChange} />;
      case componentTypeOrder.MULTIPLE_CHOICE:
        return <AnsweringMultipleChoice handleOnChange={handleOnChange} />;
      case componentTypeOrder.PAIN_LOCATION_CHART:
        return <AnsweringPainlocation handleOnChange={handleOnChange} />;
      case componentTypeOrder.SORTABLE:
        return <AnsweringRanking handleOnChange={handleOnChange} />;
      case componentTypeOrder.NUMERIC_RANGE:
        return <AnsweringNumericRange handleOnChange={handleOnChange} />;
      case componentTypeOrder.BMI:
        return <AnsweringBMI handleOnChange={handleOnChange} />;
      case componentTypeOrder.UPLOAD_FILE:
        return (
          <AnsweringFileRequest
            handleOnChange={handleOnChange}
            setFlashMessage={setFlashMessage}
          />
        );
      case componentTypeOrder.YESNO:
      case componentTypeOrder.GROUP_DESCRIPTION:
        return (
          <AnsweringYesNoSection
            handleOnChange={handleOnChange}
            YesNo={
              currentComponent.careplanComponentTypeId ===
              componentTypeOrder.YESNO
            }
          />
        );
      case componentTypeOrder.NUMERIC_VALUE:
        return <AnsweringNumericValue handleOnChange={handleOnChange} />;
      case componentTypeOrder.INFORMATION:
        return <AnsweringInformation handleOnChange={handleOnChange} />;
      case componentTypeOrder.BLOOD_PRESSURE:
        return <AnsweringBloodPressure handleOnChange={handleOnChange} />;
      default:
        return <Box>{getKey(currentComponent.careplanComponentTypeId)}</Box>;
    }
  }, [currentComponent]);

  const getTitleContent = () => {
    if (navigator.Current === TITLE_DESCRIPTION_INDEX) {
      return caretaskData.name;
    }

    if (isMainComponentsLastScreen) {
      return t('answer_view.additional_components.first_page_info_title');
    }

    const { isMandatory } = currentComponent.careplanCareplanComponentMapper;
    const componentName = isMandatory
      ? currentComponent.nameHtml.replace(/<\/p>$/, '<span>*</span></p>')
      : currentComponent.nameHtml;
    return componentName;
  };

  const getDescriptionContent = () => {
    if (navigator.Current === TITLE_DESCRIPTION_INDEX) {
      return caretaskData.description;
    }

    if (isMainComponentsLastScreen) {
      return t('answer_view.additional_components.first_page_info_content');
    }

    return currentComponent.description;
  };

  const shouldShowDescription: boolean =
    navigator.Current === TITLE_DESCRIPTION_INDEX ||
    isMainComponentsLastScreen ||
    !!currentComponent.description;

  return {
    getCurrentComponentView,
    getTitleContent,
    getDescriptionContent,
    shouldShowDescription,
    currentComponent,
  };
}
