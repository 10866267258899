const filterItems = (services, menuList) => {
  const items = [];
  for (let i = menuList?.length - 1; i >= 0; i--) {
    const item = menuList[i];
    const serviceName = item.service;
    let isServiceActive = true;
    for (let j = 0; j < serviceName?.length; j++) {
      isServiceActive = services[serviceName[j]];
      if (!isServiceActive)
        break;
    }
    if (!serviceName || isServiceActive) {
      items.push(item);
    }
  }
  return items;
};

const getAllowedServicesAmong = (services, options) => {
  return filterItems(services, options);
};

export default getAllowedServicesAmong;