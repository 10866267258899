import React from 'react';

const ReportNotificationIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.75325 26.9464C7.2711 26.9464 6.85836 26.7748 6.51502 26.4314C6.17167 26.0881 6 25.6753 6 25.1932V5.95942C6 5.47727 6.17167 5.06453 6.51502 4.72119C6.85836 4.37784 7.2711 4.20617 7.75325 4.20617H13.3344C13.4123 3.81656 13.6023 3.48539 13.9042 3.21266C14.2062 2.93994 14.5714 2.80357 15 2.80357C15.4286 2.80357 15.7938 2.93994 16.0958 3.21266C16.3977 3.48539 16.5877 3.81656 16.6656 4.20617H22.2468C22.7289 4.20617 23.1416 4.37784 23.485 4.72119C23.8283 5.06453 24 5.47727 24 5.95942V25.1932C24 25.6753 23.8283 26.0881 23.485 26.4314C23.1416 26.7748 22.7289 26.9464 22.2468 26.9464H7.75325ZM7.75325 26.1282H22.2468C22.4805 26.1282 22.6948 26.0308 22.8896 25.836C23.0844 25.6412 23.1818 25.4269 23.1818 25.1932V5.95942C23.1818 5.72565 23.0844 5.51136 22.8896 5.31656C22.6948 5.12175 22.4805 5.02435 22.2468 5.02435H19.3247V5.55032C19.3247 5.7987 19.2418 6.0069 19.076 6.17492C18.9102 6.34294 18.7048 6.42695 18.4597 6.42695H11.5397C11.2946 6.42695 11.0893 6.34294 10.9237 6.17492C10.7581 6.0069 10.6753 5.7987 10.6753 5.55032V5.02435H7.75325C7.51948 5.02435 7.30519 5.12175 7.11039 5.31656C6.91558 5.51136 6.81818 5.72565 6.81818 5.95942V25.1932C6.81818 25.4269 6.91558 25.6412 7.11039 25.836C7.30519 26.0308 7.51948 26.1282 7.75325 26.1282Z"
      fill="#323232"
    />
    <path
      d="M10.6752 16.3036C10.5583 16.3036 10.4609 16.3425 10.383 16.4205C10.3051 16.4984 10.2661 16.5958 10.2661 16.7127V22.5568C10.2661 22.6737 10.3051 22.7711 10.383 22.849C10.4609 22.9269 10.5583 22.9659 10.6752 22.9659C10.7921 22.9659 10.8895 22.9269 10.9674 22.849C11.0453 22.7711 11.0843 22.6737 11.0843 22.5568V16.7127C11.0843 16.5958 11.0453 16.4984 10.9674 16.4205C10.8895 16.3425 10.7921 16.3036 10.6752 16.3036ZM14.9999 12.7971C14.883 12.7971 14.7856 12.836 14.7077 12.914C14.6297 12.9919 14.5908 13.0893 14.5908 13.2062V22.5568C14.5908 22.6737 14.6297 22.7711 14.7077 22.849C14.7856 22.9269 14.883 22.9659 14.9999 22.9659C15.1168 22.9659 15.2142 22.9269 15.2921 22.849C15.37 22.7711 15.409 22.6737 15.409 22.5568V13.2062C15.409 13.0893 15.37 12.9919 15.2921 12.914C15.2142 12.836 15.1168 12.7971 14.9999 12.7971ZM19.3246 19.8101C19.2077 19.8101 19.1103 19.849 19.0323 19.9269C18.9544 20.0049 18.9155 20.1023 18.9155 20.2192V22.5568C18.9155 22.6737 18.9544 22.7711 19.0323 22.849C19.1103 22.9269 19.2077 22.9659 19.3246 22.9659C19.4414 22.9659 19.5388 22.9269 19.6168 22.849C19.6947 22.7711 19.7336 22.6737 19.7336 22.5568V20.2192C19.7336 20.1023 19.6947 20.0049 19.6168 19.9269C19.5388 19.849 19.4414 19.8101 19.3246 19.8101Z"
      fill="#323232"
    />
  </svg>
);

export default ReportNotificationIcon;
