import moment from 'moment';

export function convertDateToTimestamp(date: Date): number {
  return date.getTime() / 1000;
}

export function convertDateStringToSecTimestamp(
  dateString: string,
  format: string = 'YYYY-MM-DD HH:mm:ss'
): number {
  return moment(dateString, format).unix();
}

export function convertDateStringToMsTimestamp(dateString: string): number {
  return moment.utc(dateString).valueOf();
}
