
import React from 'react';
import I18n from 'i18next';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import ToolTip from '../../../caro-ui-commonfiles/components/Tooltip/Tooltip';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import moment from 'moment';


    const getDiagnosisTypes = (type) => {
        let response; 
        switch (type) {
        case '1':
          response = I18n.t('hl7Attribute.diagnosisType.1');
          break;
        case '1.1':
          response = I18n.t('hl7Attribute.diagnosisType.1-1');
          break;
        case '1.2':
          response = I18n.t('hl7Attribute.diagnosisType.1-2');
          break;
        case '2':
          response = I18n.t('hl7Attribute.diagnosisType.2');
          break;
        case '2.1':
          response = I18n.t('hl7Attribute.diagnosisType.2-1');
          break;
        case '2.2':
          response = I18n.t('hl7Attribute.diagnosisType.2-2');
          break;
        default:
          response = I18n.t('hl7Attribute.diagnosisType.2X');
          break;      
     }
     return response;
    }

    const createDurationStitchHash = (patientAdditionalInformation) => {
        let durationHash = {};
        let durationStitches = patientAdditionalInformation.durationIncisionStitches;
        for(let i=0,len=durationStitches.length;i<len;i++) {
            durationHash[durationStitches[i].opId] = durationStitches[i];
        }
        return durationHash; 
    }

    const computeHoursMinutes = (durationMinutes) => {
      if(durationMinutes > 0) {
        let computedHours = Math.floor(durationMinutes/60);
        let computedMinutes = (durationMinutes/60 - computedHours) * 60; 
        return `${computedHours} ${I18n.t('hl7Attribute.durationIncisionStitchHourPlural')} ${Math.round(computedMinutes)} ${I18n.t('hl7Attribute.durationIncisionStitchMinutePlural')}`;
      } else {
        return `${0} ${I18n.t('hl7Attribute.durationIncisionStitchMinuteSingular')}`;
      }
    }

    const renderIcdOrOpsInfo = ({processIcd = false, processOps = false, patientAdditionalInformation}={}) => {
        if(processIcd) {
            return patientAdditionalInformation.icds.map(icd => {
                if (icd) {
                return (
                    <Flex key={icd.code} flexDirection="row" otherStyles={{paddingBottom: 20}}>
                    <ToolTip position="right" content={icd.description}>
                        <Icon name="info" size={24} />
                    </ToolTip>
                    <span className="icd-code-style">{icd.code}</span>
                    <span className="icd-code-style" style={{marginLeft:5}}>{'- '+getDiagnosisTypes(icd.patientIcd.diagnosisTypeCode)}</span>
                    </Flex>
                );
                }
                return null;
            });
        }
        if(processOps) {
            let durationStitchHash = createDurationStitchHash(patientAdditionalInformation);
            return patientAdditionalInformation.durationIncisionStitches.map(opsCode => {
                if (opsCode) {
                    if(durationStitchHash.hasOwnProperty(opsCode.opId)) {
                        let durationMinutes = durationStitchHash[opsCode.opId].durationIncisionStitch;
                        return (
                            <Flex key={opsCode.id} flexDirection="row" otherStyles={{paddingBottom: 20}}>
                            <ToolTip position="right" content={opsCode.op.description}>
                                <Icon name="info" size={24} />
                            </ToolTip>
                            <span className="icd-code-style">{opsCode.op.code}</span>
                            <span className="icd-code-style" style={{marginLeft:5}}>{`- ${I18n.t('hl7Attribute.durationIncisionStitch')} : ${computeHoursMinutes(durationMinutes)}`}</span>
                            </Flex>
                        );
                        }    
                    }
                return null;
            });
        }
        if(!processIcd && !processOps) {
            return <React.Fragment></React.Fragment>;
        }
    };

    const processWard = (nurseUnit) => {
      return `${I18n.t('hl7Attribute.nurseUnit')}: ${nurseUnit}; ${I18n.t('hl7Attribute.department')}: n/a`;
    }

    const filterHl7AttributeBasedOnAttributeName = (attributeName, patientAdditionalInformation) => {
        if(patientAdditionalInformation.hl7PatientAttributes && patientAdditionalInformation.hl7PatientAttributes.length > 0){
          let hl7Attribute =  patientAdditionalInformation.hl7PatientAttributes.filter(function(elem) {
            return elem.hl7Attribute.attributeName == attributeName;
          });
          return hl7Attribute.length > 0  ? attributeName == 'hl7Attribute.ward' ? processWard(hl7Attribute[0].value) : hl7Attribute[0].value : '';
        }
        return '';
    }

    const getDate = birthdate => {
        if (birthdate) return moment(birthdate).locale(I18n.language).format("DD MMM YYYY");
        return '';
    };

    const renderPatientAttribute = (key, value) => {
        return (
          <React.Fragment>
            {(key && value) &&
            <div className="patient-overview-detail-row">
                <div className="patient-info patient-overview-detail-key">{key+':'}</div>
                <div className="patient-info patient-overview-detail-value">{value}</div> 
            </div>
            } 
          </React.Fragment>  
        );
    }

    const renderPatientAttributeForBasicInfo = (key, value) => {
        return (
            <React.Fragment>
            {(key && value) &&
            <div className="patient-overview-detail-row">
                <div className="patient-info patient-overview-detail-basic-info-key">{key+':'}</div>
                <div className="patient-info patient-overview-detail-basic-info-value">{value}</div> 
            </div>
            }
            </React.Fragment>
        );
    }

export {
    getDiagnosisTypes,
    renderIcdOrOpsInfo,
    filterHl7AttributeBasedOnAttributeName,
    getDate,
    renderPatientAttribute,
    renderPatientAttributeForBasicInfo
}