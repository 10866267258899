import { ApiClient } from '@global/Services';
import { CreateAsset } from './AssetUploadCheck.type';

export const createAsset: CreateAsset = async (url, query) =>
  await ApiClient.POST({
    url,
    payload: query,
    timeout: 3000000000,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'form-data',
    },
  });
