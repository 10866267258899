import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

const ToastThreshold = ({ thresholdTitle, patientName, question, answer }) => (
  <>
    <div className="toast toast__threshold-title">
      <Icon name="notification" size={24} fill="white" />
      {thresholdTitle}
    </div>
    <div className="toast toast__threshold-patient">
      <Icon name="user" size={24} fill="white" />
      {patientName}
    </div>
    <div className="toast toast__threshold-question">
      <Icon name="question" size={24} fill="white" />
      {question}
    </div>
    <div className="toast toast__threshold-answer">
      <Icon name="threshold" size={24} fill="white" />
      {answer}
    </div>
  </>
);

ToastThreshold.propTypes = {
  thresholdTitle: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default ToastThreshold;
