import { ComplexScoringType, ComplexFormula } from './ComplexScoring.type';

export const getOverallScore = (
  formulas: ComplexFormula[]
): ComplexFormula | null => {
  const overallScore: ComplexFormula[] = formulas.filter(
    formula => formula.scoringTypeId === ComplexScoringType.OverallScore
  );
  return overallScore.length > 0 ? overallScore[0] : null;
};

export const hasOverallScore = (formulas: ComplexFormula[]): boolean => {
  return getOverallScore(formulas) !== null;
};

export const getSectionScores = (
  formulas: ComplexFormula[]
): ComplexFormula[] => {
  return formulas.filter(
    formula => formula.scoringTypeId === ComplexScoringType.SectionScore
  );
};

export const processOverallScoreToBeSentToBackend = (
  overallScoringToggle: boolean,
  formulas: ComplexFormula[]
): ComplexFormula | string => {
  if (!overallScoringToggle) return '';

  const overallScore = getOverallScore(formulas);
  if (overallScore === null) return '';
  /**
   * * If the id is generated in FE it must be deleted. otherwise, the backend will try to update the existing formula which doesn't exist.
   * * If the id is a number it's coming from the backend so it must be sent again.
   */
  if (typeof overallScore.id === 'string') delete overallScore.id;
  return overallScore;
};

export const processSectionScoreToBeSentToBackend = (
  overallScoringToggle: boolean,
  formulas: ComplexFormula[]
): ComplexFormula[] => {
  if (!overallScoringToggle) return [];

  const sectionScores = getSectionScores(formulas).map(sectionScore => {
    const sectionScoreTmp = { ...sectionScore };
    /**
     * * If the id is generated in FE it must be deleted. otherwise, the backend will try to update the existing formula which doesn't exist.
     * * If the id is a number it's coming from the backend so it must be sent again.
     */
    if (typeof sectionScoreTmp.id === 'string') delete sectionScoreTmp.id;
    return sectionScoreTmp;
  });
  return sectionScores;
};
