import React, { useEffect, useState, useCallback, RefCallback } from 'react';
import useResizeObserver from 'use-resize-observer';
import { useTranslation } from 'react-i18next';

import reportAPI from '../axios';
import serviceConfig from '../../../../serviceConfig.json';
import { RenderAnswerComponent } from './CareplanReport.utils';
import { ReportPage, CarePlanReport } from './CareplanReport.type';

function useGetComponentHeight(): [RefCallback<HTMLDivElement>, number] {
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();
  return [ref, height];
}

const useCarePlanReportTemplateService = (
  reportId: string,
  authenticationToken: string
) => {
  const [reportData, setReportData] = useState<CarePlanReport>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isReportRendered, setIsReportRendered] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [firstPageRef, firstPageHeight] = useGetComponentHeight();
  const [headerRef, headerHeight] = useGetComponentHeight();
  const [footerRef, footerHeight] = useGetComponentHeight();
  const [componentHeights, setComponentHeights] = useState<number[]>([]);
  const { i18n } = useTranslation();
  const setCurrentComponentHeight = useCallback(
    (height: number, index: number) => {
      setComponentHeights((prevHeights: number[]) => {
        const prevHeightsClone = [...prevHeights];
        prevHeightsClone[index] = height;
        return prevHeightsClone;
      });
    },
    []
  );

  useEffect(() => {
    setIsLoading(true);
    setIsReportRendered(false);
    const axiosInstance = reportAPI(authenticationToken);
    axiosInstance({
      method: 'GET',
      url: serviceConfig.brokerService.questionsExportPdfAnswers.uri,
      params: {
        careplanId: reportId,
      },
    }).then(
      ({
        data,
      }: {
        data: CarePlanReport & { success: boolean; message?: string };
      }) => {
        setIsLoading(false);
        if (data.success !== false) {
          setReportData(data);
          setComponentHeights(new Array(data.careplanComponents.length));
          if (data.reportLanguage && data.reportLanguage !== '') {
            i18n.changeLanguage(data.reportLanguage);
          }
          setTimeout(() => {
            setIsReportRendered(true);
          }, 5000);
        } else {
          setErrorMessage(data.message);
        }
      }
    );
  }, []);

  let currentPage = 0;
  const pages: ReportPage[] = [];
  if (reportData && reportData.careplanComponents.length > 0) {
    pages.push({
      remainHeight: firstPageHeight - headerHeight - footerHeight,
      content: [],
    });

    reportData.careplanComponents.forEach((component, index: number) => {
      const elementToBeRendered = (
        <RenderAnswerComponent
          key={`component-${index}`}
          component={component}
          componentIndex={index}
          allOccurrences={reportData.allOccurrences}
          setCurrentComponentHeight={setCurrentComponentHeight}
        />
      );

      if (pages[currentPage]?.remainHeight > componentHeights[index]) {
        pages[currentPage].content.push(elementToBeRendered);
        pages[currentPage].remainHeight -= componentHeights[index];
      } else {
        pages.push({
          remainHeight:
            firstPageHeight -
            componentHeights[index] -
            headerHeight -
            footerHeight,
          content: [elementToBeRendered],
        });
        currentPage++;
      }
    });
  }

  return {
    isLoading,
    reportData,
    firstPageRef,
    pages,
    headerRef,
    headerHeight,
    footerRef,
    footerHeight,
    isReportRendered,
    errorMessage,
  };
};

export default useCarePlanReportTemplateService;
