/* This modification includes the possibility of decimal numbers (the dot character)
  Also checks for any character through its length if it's a number or not
  
  Scenarios:
  123 -> true
  -123 -> true
  1.23 -> true
  -1.23 -> true
  1,23 -> false
  a -> false
  a12 -> false
  12a -> false 
*/
function isValidNumber(input) {
  const pattern = /^-?\d+\.?\d*$/;
  return pattern.test(input);
}

/* This function validates if the input is a single digit integer 

  Scenarios:
  123 -> false
  1 -> true
  12 -> false
  1.9 -> false
  a12 -> false
  8 -> true 
*/

function isValidPositiveSingleDigit(input) {
  const pattern = /^[0-9]$/;
  return pattern.test(input);
}

export { isValidNumber, isValidPositiveSingleDigit };
