import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Icon } from '@ui-common-files/components';

import AnswerCardComponent from './AnswerComponents/AnswerCardComponent';
import { ScoresSection } from './Results';

import { ScoringTypes } from '@type/Careplan';
import {
  isMainQuestion,
  getComponentsByHealthCategory,
  componentHealthCategories,
} from './CareplanHelperUtility';

import '../../css/careplans.scss';
import '../../css/answers.css';

function CareplanResultsModalContentAnswers({
  questionWithThresholdsAndJumps,
  careplanData,
  scores,
  shouldHaveAnswerTitle = true,
  overallScore,
}) {
  const { t } = useTranslation();

  const hasAnyScores =
    (careplanData.score >= 0 && careplanData.isEvaluating) ||
    scores?.length > 0;
  const overallScoreForTriggeredResult =
    careplanData.overallScore >= 0 ? careplanData.overallScore : null;
  const careplanTotalScore =
    overallScore !== undefined ? overallScore : overallScoreForTriggeredResult;
  const renderAnswers = () => {
    if (!questionWithThresholdsAndJumps?.length) return null;
    const answersCollection = questionWithThresholdsAndJumps
      .filter(item => isMainQuestion(item.healthCategory))
      .map((careplanComponent, index) => {
        let variant = '';
        if (
          careplanComponent.careplanCareplanComponentMapper.depth === 0 &&
          index + 1 < questionWithThresholdsAndJumps.length &&
          questionWithThresholdsAndJumps[index + 1]
            .careplanCareplanComponentMapper.depth === 1
        ) {
          variant = ' is-parent';
        } else if (
          careplanComponent.careplanCareplanComponentMapper.depth === 1 &&
          (index + 1 === questionWithThresholdsAndJumps.length ||
            (index + 1 < questionWithThresholdsAndJumps.length &&
              questionWithThresholdsAndJumps[index + 1]
                .careplanCareplanComponentMapper.depth === 0))
        ) {
          variant = ' is-last-child';
        } else if (
          careplanComponent.careplanCareplanComponentMapper.depth === 1
        ) {
          variant = ' is-child';
        }

        const currIndex = `${index + 1}`;
        return (
          <AnswerCardComponent
            careplanComponent={careplanComponent}
            index={currIndex}
            variant={variant}
            isEvaluating={careplanComponent.isEvaluating}
            scores={scores}
          />
        );
      });

    if (careplanData.scoringScheme) {
      const patientScoreScheme = careplanData.scoringScheme.filter(
        item => item.min <= overallScore && overallScore <= item.max
      )[0];
      if (patientScoreScheme) {
        const additionalComponents = getComponentsByHealthCategory(
          questionWithThresholdsAndJumps,
          patientScoreScheme.id === ScoringTypes.Well
            ? componentHealthCategories.WELL_QUESTION
            : patientScoreScheme.id === ScoringTypes.Fair
            ? componentHealthCategories.FAIR_QUESTION
            : componentHealthCategories.POOR_QUESTION
        );
        if (additionalComponents.length) {
          answersCollection.push(
            <Box
              className="threshold-component__name"
              otherStyles={{ paddingLeft: 0, marginTop: 20 }}
            >
              {t('newCareplan_view.additional_component_based_on_score')}
            </Box>
          );

          answersCollection.push(
            <Flex
              alignItems="center"
              otherStyles={{
                backgroundColor: patientScoreScheme.bgColor,
                width: 240,
                marginTop: 23,
                marginBottom: 10,
              }}
            >
              <Flex alignItems="center" otherStyles={{ padding: '6px 10px' }}>
                <Icon
                  name={patientScoreScheme.icon}
                  size={24}
                  fill={patientScoreScheme.color}
                />
                <Flex
                  otherStyles={{
                    margin: '0 10px',
                    fontFamily: 'Open sans',
                    fontSize: 16,
                    color: patientScoreScheme.color,
                  }}
                >
                  {patientScoreScheme.id === ScoringTypes.Well
                    ? t('newCareplan_view.scoring_well')
                    : patientScoreScheme.id === ScoringTypes.Fair
                    ? t('newCareplan_view.scoring_fair')
                    : t('newCareplan_view.scoring_poor')}
                  : {t('newQuestion_view.score')} {patientScoreScheme.min}{' '}
                  {t('newCareplan_view.to')} {patientScoreScheme.max}
                </Flex>
              </Flex>
            </Flex>
          );

          additionalComponents.map((careplanComponent, index) => {
            const currIndex = `${index + 1}`;
            answersCollection.push(
              <AnswerCardComponent
                careplanComponent={careplanComponent}
                index={currIndex}
                variant=""
                isEvaluating={careplanComponent.isEvaluating}
                isAdditionalComponent
                scores={scores}
              />
            );
          });
        }
      }
    }
    return answersCollection;
  };

  return (
    <>
      {hasAnyScores && (
        <ScoresSection
          scores={scores}
          overallScore={careplanTotalScore}
          isEvaluating={careplanData.isEvaluating}
        />
      )}
      <Box margin="32px 0">
        {shouldHaveAnswerTitle && (
          <Box margin="0 0 6px 0">
            <h2 className="caretask-results__section-title">
              {t('QuestionCatalog_view.sections.answers')}
            </h2>
          </Box>
        )}
        {renderAnswers()}
      </Box>
    </>
  );
}

export default CareplanResultsModalContentAnswers;
