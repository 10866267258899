/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { roles } from '@utils';
import { AuxiliaryColors, PrimaryColor } from '@ui-common-files/utils/colors';
import { FlashMessageTypes } from '@ui-common-files/types/components';
import { ApiClient, Logger, ShowFlashMessage } from '@global/Services';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import CheckBox from '../../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import Dropdown from '../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import ModalContent from '../../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ComponentWithLabel from '../../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import ImageUpload from '../../../../caro-ui-commonfiles/components/molecules/ImageUpload/ImageUpload';
import TextInput from '../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Tooltip from '../../../../caro-ui-commonfiles/components/Tooltip/Tooltip';
import CheckBoxRadioVariants from '../../../../caro-ui-commonfiles/utils/CheckboxRadio';
import inputField from '../../../../caro-ui-commonfiles/utils/inputField';
import maxCharLimit from '../../../../caro-ui-commonfiles/utils/maxcharlimits.json';
import { nonMedical } from '../../../../config';
import { setCurrentUser } from '../../../../reducers/session';
import serviceConfig from '../../../../serviceConfig.json';
import appendRoute from '../../../Global/Strings/AppendRoute/AppendRoute';
import { updateAvatarAsset } from '../../../Utils/Asset/assetAvatarHelper';
import avatarType from '../../../Utils/Asset/assetAvatarTypes.json';
import { FirebaseContext } from '../../../Utils/Firebase/firebase';
import validateFirstnameLastname from '../../../Utils/hooks/validateFirstnameLastname';
import prefilledUsers from '../../../Utils/prefilledUsers.json';
import userRoles from '../../../Utils/roles.json';
import ModalForm from '../../common/Layout/Modal/ModalForm';
import ModalSection from '../../common/Layout/Modal/ModalSection';
import UpdatePasswordComponent from './UpdatePasswordComponent';
import { checkCurrentPassword } from './UpdatePasswordComponent/UpdatePasswordComponent.api';
import { passwordRegex } from '../../../Global/Utils';
import { onSatelliteChange } from '../users.utils';
import useSatelliteData from '../useSatelliteData.service';

import '../../../css/user.css';

function UserUpdateModalContent({
  updateCurrentUser,
  userData,
  closeModal,
  reloadTable,
  setToRefreshUser,
  setProfilePicture,
  comesFromAdministration,
  setDisplayRevoke,
  setUpdatetUserPopup,
  userToUpdateRole,
}: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.session);
  const history = useHistory();
  const defaultSatellite = userData.satellites.find(
    item => item.satelliteData?.id === userData.user.satelliteId
  );
  const isSuperAdmin = user.roleId === 1;
  const isSiteAdmin = user.role.name === roles.SITE_ADMIN;
  const userInfo = userData ? userData.user : null;
  const hasTwoFactorAuthenticationPermission = user.id === userInfo.id;
  const { setIsLoggedIn } = useContext(FirebaseContext);

  const blockSiteUpdate = !isSuperAdmin || userData.isUserHasCreatedContent;
  const getEmailNotificationBasedOnMedical = () => {
    let emailNotificationConfig;
    const emailNotificationForMedical = {
      chatMessages: false,
      assessments: false,
      thresholds: false,
      mentions: false,
    };
    emailNotificationConfig =
      userInfo.emailNotification || emailNotificationForMedical;

    if (nonMedical === true) {
      delete userInfo.emailNotification?.thresholds;
      delete emailNotificationConfig?.thresholds;
    }
    return emailNotificationConfig;
  };

  const [values, setValues] = useState({
    firstName: userInfo.firstname,
    lastName: userInfo.lastname,
    email: userInfo.email,
    username: userInfo.username,
    profilePicture: userInfo.profilePicture,
    ward: userInfo.ward ? userInfo.ward : '',
    currentPassword: '',
    password: '',
    repeatPassword: '',
    currentEmail: userInfo.email,
    role: {
      value: userInfo.role.id,
      label: userInfo.role.name,
    },
    oldRole: {
      value: userInfo.role.id,
      label: userInfo.role.name,
    },
    originSite: userData.userOriginSite,
    isTwoFactorAuthentication: userInfo.isTwoFactorAuthentication,
    isTermsAndConditions: userInfo.isTermsAndConditions,
    isPrivacyPolicy: userInfo.isPrivacyPolicy,
    isConsentForTracking: userInfo.isConsentForTracking,
    emailNotification: getEmailNotificationBasedOnMedical(),
    treatedAISID: userInfo.treatedAISID ?? '',
  });

  const siteId = values.originSite?.value;
  const notificationTranslations = {
    chatMessages: t('admin_view.email_notification_chat'),
    assessments: t('admin_view.email_notification_assessments'),
    thresholds: t('admin_view.email_notification_threshold'),
    mentions: t('admin_view.email_notification_mention'),
  };
  const [hasErrors, setHasErrors] = useState({
    firstNameIsEmpty: false,
    firstNameIsInvalid: false,
    lastNameIsEmpty: false,
    lastNameIsInvalid: false,
    username: false,
    email: false,
    currentPassword: false,
    password: false,
    repeatPassword: false,
    profilePicture: false,
    isSatelliteEmpty: false,
    role: false,
    originSite: false,
    treatedAISID: false,
  });

  const allRoles = userData.prefilledRoles;
  const allSites = userData.sites;
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(
    !!userInfo.emailNotification &&
      (userInfo.emailNotification?.chatMessages ||
        userInfo.emailNotification?.assessments ||
        userInfo.emailNotification?.thresholds ||
        userInfo.emailNotification?.mentions)
  );

  const hasSuperAdminRole =
    values.role.label === userRoles.SUPER_ADMIN && !isSuperAdmin;
  const hasSameEmail = userInfo.email === user.email;

  const language = user.languagePreference.trim();
  const pdfIFUEng = '/static/pdfs/Role_description_EN.pdf';
  const pdfIFUDe = '/static/pdfs/Role_description_DE.pdf';
  const pdfIFUIta = '/static/pdfs/Role_description_ITA.pdf';
  const pdfIFUTha = '/static/pdfs/Role_description_THA.pdf';
  const pdfIFUPt = '/static/pdfs/Role_description_PT.pdf';
  const pdfIFUFr = '/static/pdfs/Role_description_FR.pdf';
  let pdf;
  const splittedLanguage = language.split('-')[0];
  if (splittedLanguage === 'de') {
    pdf = pdfIFUDe;
  } else if (splittedLanguage === 'it') {
    pdf = pdfIFUIta;
  } else if (splittedLanguage === 'th') {
    pdf = pdfIFUTha;
  } else if (splittedLanguage === 'pt') {
    pdf = pdfIFUPt;
  } else if (splittedLanguage === 'fr') {
    pdf = pdfIFUFr;
  } else {
    pdf = pdfIFUEng;
  }

  const {
    allSatellite,
    satellite,
    isExternalHCP,
    setIsExternalHCP,
    setSatellite,
  } = useSatelliteData(
    siteId,
    setLoading,
    userData.satellites,
    defaultSatellite || null,
    !!userData.user.satelliteId,
    false
  );

  const onValueChange = (value, key) => {
    if (key === 'role' || key === 'originSite') {
      if (
        values.role.label === roles.EXTERNAL_HCP ||
        values.originSite.value !== siteId
      ) {
        setSatellite(null);
        setHasErrors(prevHasErrors => ({
          ...prevHasErrors,
          isSatelliteEmpty: false,
        }));
      }
    }
    if (key === 'role') {
      setIsExternalHCP(value.label === roles.EXTERNAL_HCP);
    }
    if (key === 'isPrivacyPolicy' && !value && userInfo.id === user.id) {
      setDisplayRevoke(true);
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: 'common_labels.privacyPolicy_label_revoke',
        isModalMessage: true,
      });
    } else if (key === 'isPrivacyPolicy' && value) {
      setDisplayRevoke(false);
    }
    setValues(prevValues => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const onValueErrorChange = (value, key) => {
    setHasErrors(prevHasErrors => ({
      ...prevHasErrors,
      [key]: value,
    }));
  };

  const showHidePassword = () => {
    if (showEditPassword) {
      setValues(prevValues => ({
        ...prevValues,
        currentPassword: '',
        email: values.currentEmail,
        password: '',
        repeatPassword: '',
      }));
      setHasErrors(prevHasErrors => ({
        ...prevHasErrors,
        currentPassword: false,
        email: false,
        password: false,
        repeatPassword: false,
      }));
    }
    setShowEditPassword(!showEditPassword);
  };

  const validateForm = async () => {
    let password;
    let repeatPassword;
    let currentPassword;
    if (showEditPassword) {
      if (values.currentPassword !== '') {
        const { data: isCorrectCurrentPassword } = await checkCurrentPassword(
          values.currentPassword
        );
        if (!isCorrectCurrentPassword)
          currentPassword = t('admin_view.password_incorrect');
      }
      if (values.password && !passwordRegex.test(values.password))
        password = t('admin_view.password_not_strong');
      if (values.password && !values.repeatPassword)
        repeatPassword = t('admin_view.password_retypePattern');
      else if (values.password !== values.repeatPassword)
        repeatPassword = t('admin_view.password_no_match');
    }
    const passwordEntered =
      showEditPassword && (values.password || values.repeatPassword);
    const formErrors = {
      ...hasErrors,
      firstNameIsEmpty: values.firstName.trim().length < 1,
      firstNameIsInvalid: validateFirstnameLastname(values.firstName.trim()),
      lastNameIsEmpty: values.lastName.trim() < 1,
      lastNameIsInvalid: validateFirstnameLastname(values.lastName.trim()),
      username: values.username.trim().length < 1,
      password,
      isSatelliteEmpty: isExternalHCP ? !satellite : null,
      repeatPassword,
      currentPassword:
        currentPassword ||
        ((values.email !== values.currentEmail || passwordEntered) &&
          values.currentPassword === ''),
    };
    setHasErrors(formErrors);
    return !Object.keys(formErrors).some(k => formErrors[k]);
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formResults = await validateForm();
    if (formResults) {
      updateUser();
    }
    return false;
  };

  const logOut = async () => {
    try {
      toast.dismiss();
      await ApiClient.GET({
        url: serviceConfig.brokerService.logOut.uri,
      }).then(() => {
        setIsLoggedIn(false);
        localStorage.clear();
        history.index = 0;
        history.push('/', { logout: true });
      });
    } catch (error) {
      ShowFlashMessage({
        type: error.response.data.type,
        message: t(error.response.data.content),
        isModalMessage: false,
      });
    }
  };

  const updateUser = async () => {
    setLoading(true);
    try {
      updateAvatarAsset(
        userInfo.id,
        values.profilePicture,
        userInfo.collectionId,
        values.username,
        avatarType.USERS
      ).then(async resource => {
        const { collection, profilePicture } = resource;
        values.collectionId = collection;
        values.profilePicture = profilePicture;
        const response = await ApiClient.POST({
          url: serviceConfig.brokerService.updateUser.uri,
          payload: {
            id: userInfo.id,
            satelliteId: isExternalHCP ? satellite.satelliteData.id : null,
            ...values,
            showEditPassword,
          },
        });

        if (response) {
          if (response.data.action === 'action.revoked') {
            if (userInfo.id === user.id) {
              logOut();
            }
          }
          if (updateCurrentUser || userInfo.id === user.id) {
            let profilePicture;
            if (response.data.updateUser.profilePicture.id) {
              profilePicture =
                response.data.updateUser.profilePicture.directLink;
            } else {
              profilePicture = `data:image/jpeg;base64,${response.data.updateUser.profilePicture}`;
            }
            let logo;
            if (response.data.siteLogo) {
              if (response.data.siteLogo.id) {
                logo = response.data.siteLogo.directLink;
              } else {
                logo = `data:image/jpeg;base64,${response.data.siteLogo}`;
              }
            } else {
              logo = user.siteLogo;
            }
            const updatedUser = {
              ...response.data.updateUser,
              institutions: response.data.userInstitutions,
              role: {
                name: values.role.label,
                isPatientDemographicData: user.role.isPatientDemographicData,
              },
              profilePicture,
              siteLogo: logo,
            };
            updateCurrentUser && setProfilePicture(updatedUser.profilePicture);
            dispatch(setCurrentUser(updatedUser));
            Logger.setUser({
              UUID: updatedUser.uuid,
              Username: updatedUser.username,
              RoleName: updatedUser.role.name,
            });
            setToRefreshUser && setToRefreshUser();
          }

          if (comesFromAdministration)
            setUpdatetUserPopup({
              display: true,
            });

          reloadTable();
          ShowFlashMessage({
            type: response.data.type,
            messageKey: response.data.content,
            isModalMessage: false,
          });
        }
      });
    } catch ({ response }) {
      if (response.data)
        ShowFlashMessage({
          type: response.data.type,
          messageKey: response.data.content,
          isModalMessage: false,
        });
    } finally {
      closeModal();
      setLoading(false);
    }
  };

  const handleChangeNotificationsConsent = (checked, key) => {
    const shalowCopyFromvalues = { ...values.emailNotification };
    shalowCopyFromvalues[`${key}`] = checked;
    onValueChange(shalowCopyFromvalues, 'emailNotification');
    if (
      Object.keys(shalowCopyFromvalues).some(val => shalowCopyFromvalues[val])
    ) {
      setEmailNotifications(true);
    } else {
      setEmailNotifications(false);
    }
  };

  const isEmailNotificationIndeterminate = () =>
    Object.keys(values.emailNotification).some(
      val => values.emailNotification[val]
    ) &&
    !Object.keys(values.emailNotification).every(
      val => values.emailNotification[val]
    );

  const renderNotificationsCheck = () =>
    Object.keys(values.emailNotification).map(key => (
      <Flex otherStyles={{ margin: '4px 0' }}>
        <CheckBox
          id={`user-notification-${key}`}
          variant={CheckBoxRadioVariants.DEFAULT}
          checked={values.emailNotification[`${key}`]}
          handleOnChange={e =>
            handleChangeNotificationsConsent(e.target.checked, key)
          }
        />
        <Box>{notificationTranslations[key]}</Box>
      </Flex>
    ));
  return (
    <ModalContent>
      {loading && (
        <div className="roleOverlapSpinner">
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </div>
      )}
      <ModalForm formId="update-user-form" handleFormSubmit={handleFormSubmit}>
        <ModalSection>
          <ComponentWithLabel
            id="profile-picture"
            htmlFor="profile-picture"
            hasIcon={false}
            labelText={t('admin_view.label_profile_picture')}
            tooltipText={t('admin_view.label_profile_picture')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <ImageUpload
              blob={values.profilePicture}
              handleOnChange={imgUrl => onValueChange(imgUrl, 'profilePicture')}
              styleProps={{
                height: '80px',
                width: '80px',
                borderRadius: '50%',
              }}
              setHasError={value => onValueErrorChange(value, 'profilePicture')}
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="firstName"
            htmlFor="firstName"
            hasIcon={false}
            labelText={`* ${t('admin_view.label_firstNameUser')}`}
            tooltipText={t('admin_view.label_firstNameUser')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={values.firstName}
              hasError={
                hasErrors.firstNameIsEmpty || hasErrors.firstNameIsInvalid
              }
              id="firstName"
              name="firstName"
              placeholder={t('patients_view.add_firstname')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.user.firstNameMaxCharLimit}
              maxChars={maxCharLimit.user.firstNameMaxCharLimit}
              handleOnChange={event =>
                onValueChange(event.target.value, 'firstName')
              }
              validationMessage={
                hasErrors.firstNameIsEmpty
                  ? t('parsley.requiredField')
                  : t('patients_view.invalidFirstName')
              }
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="lastName"
            htmlFor="lastName"
            hasIcon={false}
            labelText={`* ${t('admin_view.label_lastNameUser')}`}
            tooltipText={t('admin_view.label_lastNameUser')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={values.lastName}
              hasError={
                hasErrors.lastNameIsEmpty || hasErrors.lastNameIsInvalid
              }
              id="lastName"
              name="lastName"
              placeholder={t('patients_view.add_lastname')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.user.lastNameMaxCharLimit}
              maxChars={maxCharLimit.user.lastNameMaxCharLimit}
              handleOnChange={event =>
                onValueChange(event.target.value, 'lastName')
              }
              validationMessage={
                hasErrors.lastNameIsEmpty
                  ? t('parsley.requiredField')
                  : t('patients_view.invalidLastName')
              }
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="username"
            htmlFor="username"
            hasIcon={false}
            labelText={`* ${t('admin_view.label_username')}`}
            tooltipText={t('admin_view.label_username')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={values.username}
              hasError={hasErrors.username}
              id="username"
              name="username"
              placeholder={t('admin_view.add_username')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.user.usernameMaxCharLimit}
              maxChars={maxCharLimit.user.usernameMaxCharLimit}
              handleOnChange={event =>
                onValueChange(event.target.value, 'username')
              }
              validationMessage={t('parsley.requiredField')}
              disabled={values.username === prefilledUsers.ADMIN}
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="ward"
            htmlFor="ward"
            hasIcon={false}
            labelText={t('admin_view.label_ward')}
            tooltipText={t('admin_view.label_ward')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={values.ward}
              hasError={hasErrors.ward}
              id="ward"
              name="ward"
              maxChars={maxCharLimit.user.wardMaxCharLimit}
              maxLength={maxCharLimit.user.wardMaxCharLimit}
              placeholder={t('admin_view.add_ward')}
              handleOnChange={event =>
                onValueChange(event.target.value, 'ward')
              }
              validationMessage={t('parsley.requiredField')}
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="treatedAISID"
            htmlFor="treatedAISID"
            hasIcon={false}
            labelText={t('admin_view.label_treatedAISID')}
          >
            <TextInput
              value={values.treatedAISID}
              hasError={hasErrors.treatedAISID}
              id="treatedAISID"
              name="treatedAISID"
              maxChars={maxCharLimit.user.wardMaxCharLimit}
              maxLength={maxCharLimit.user.wardMaxCharLimit}
              placeholder={t('admin_view.add_treatedAISID')}
              handleOnChange={event =>
                onValueChange(event.target.value, 'treatedAISID')
              }
              validationMessage={t('parsley.requiredField')}
            />
          </ComponentWithLabel>
        </ModalSection>

        <ModalSection>
          <ComponentWithLabel
            id="originSite"
            htmlFor="originSite"
            hasIcon={false}
            labelText={t('admin_view.label_userOriginSite')}
            tooltipText={t('admin_view.label_userOriginSite')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <Dropdown
              value={values.originSite}
              options={allSites}
              placeholder={t('admin_view.label_userOriginSite')}
              handleOnChange={event => onValueChange(event, 'originSite')}
              isDisabled={blockSiteUpdate}
            />
          </ComponentWithLabel>
          {comesFromAdministration && userToUpdateRole && (
            <ComponentWithLabel
              id="role"
              htmlFor="role"
              hasIcon={false}
              labelText={t('admin_view.label_Role')}
              tooltipText={t('admin_view.label_userRole')}
              hasToggleButton={false}
              idToggleButton="toggleBtn"
              valueToggleButton="toggleBtn"
              isDisabledToggleButton={false}
              checked={false}
              labelDescription={
                <div>
                  <a
                    href={pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="privacy-policy__linkIFU"
                  >
                    {t('admin_view.label_IFU')}
                    <Icon
                      name="roleDescription"
                      size={16}
                      class="roleDescription"
                    />
                  </a>
                </div>
              }
            >
              <Dropdown
                value={values.role}
                options={allRoles}
                placeholder={t('admin_view.label_userRole')}
                handleOnChange={event => onValueChange(event, 'role')}
                isDisabled={hasSuperAdminRole || hasSameEmail}
              />
            </ComponentWithLabel>
          )}
          {isExternalHCP && (
            <ComponentWithLabel
              id="satellite"
              htmlFor="satellite"
              labelText={`* ${t('satellite_view.label_satellite')}`}
              tooltipText={t('satellite_view.label_satellite')}
            >
              <Dropdown
                isSearchable
                value={satellite}
                options={allSatellite}
                placeholder={t('satellite_view.add_satellite')}
                hasError={hasErrors.isSatelliteEmpty}
                isDisabled={!(isSuperAdmin || isSiteAdmin)}
                handleOnChange={event =>
                  onSatelliteChange(
                    event,
                    setHasErrors,
                    hasErrors,
                    setSatellite
                  )
                }
              />
              {hasErrors.isSatelliteEmpty && (
                <span
                  style={{ color: AuxiliaryColors.RED }}
                  className="helperText"
                >
                  {t('parsley.requiredField')}
                </span>
              )}
            </ComponentWithLabel>
          )}
          <UpdatePasswordComponent
            hasErrors={hasErrors}
            onValueChange={onValueChange}
            showEditPassword={showEditPassword}
            showHidePassword={showHidePassword}
            setHasErrors={setHasErrors}
            values={values}
            setValues={setValues}
          />
          {hasTwoFactorAuthenticationPermission && (
            <>
              <div className="twoFactorAndPrivacyPolicy">
                <CheckBox
                  id="user-isTwoFactorAuthentication"
                  variant={CheckBoxRadioVariants.DEFAULT}
                  checked={!!values.isTwoFactorAuthentication}
                  handleOnChange={e => {
                    onValueChange(
                      e.target.checked,
                      'isTwoFactorAuthentication'
                    );
                  }}
                  label={ReactHtmlParser(t('admin_view.label-two-factor'))}
                />
              </div>

              <div className="twoFactorAndPrivacyPolicy">
                <CheckBox
                  id="user-isPrivacyPolicy"
                  variant={CheckBoxRadioVariants.DEFAULT}
                  checked={!!values.isPrivacyPolicy}
                  handleOnChange={e => {
                    onValueChange(e.target.checked, 'isPrivacyPolicy');
                  }}
                  label={
                    <Flex>
                      {ReactHtmlParser(
                        t('common_labels.privacyPolicy_label_consent', {
                          name: 'privacy-policy__link',
                          target: '__blank',
                          href: appendRoute('privacy-policy'),
                        })
                      )}
                      <Tooltip
                        content={
                          <Box width={400}>
                            {t('admin_view.label-privacy-policy-consent-info')}
                          </Box>
                        }
                        position="top"
                      >
                        <span style={{ marginTop: '10%' }}>
                          <Icon name="info" size={20} />
                        </span>
                      </Tooltip>
                    </Flex>
                  }
                />
              </div>
              <div className="twoFactorAndPrivacyPolicy">
                <CheckBox
                  id="user-isConsentForTracking"
                  variant={CheckBoxRadioVariants.DEFAULT}
                  checked={!!values.isConsentForTracking}
                  handleOnChange={e => {
                    onValueChange(e.target.checked, 'isConsentForTracking');
                  }}
                  label={ReactHtmlParser(
                    t('common_labels.tracking_label_consent', {
                      name: 'privacy-policy__link',
                      target: '__blank',
                      href: appendRoute('privacy-policy'),
                    })
                  )}
                />
              </div>
              {!user.role.isPatientDemographicData && (
                <Box className="twoFactorAndPrivacyPolicy">
                  <Flex alignItems="flex-start" flexDirection="column">
                    <CheckBox
                      id="user-notificationConsent"
                      variant={CheckBoxRadioVariants.DEFAULT}
                      checked={emailNotifications}
                      handleOnChange={e => {
                        const shallowCopyFromNotificationConsent = {
                          ...values.emailNotification,
                        };
                        for (const key in shallowCopyFromNotificationConsent) {
                          shallowCopyFromNotificationConsent[key] =
                            e.target.checked;
                        }
                        onValueChange(
                          shallowCopyFromNotificationConsent,
                          'emailNotification'
                        );
                        setEmailNotifications(!emailNotifications);
                      }}
                      isIndeterminate={isEmailNotificationIndeterminate()}
                      label={
                        <Flex>
                          <Box margin="0 8px 0 0">
                            {t('admin_view.email_notification')}
                          </Box>
                          <Tooltip
                            content={
                              <Box width={400}>
                                {t('admin_view.email_notification_info')}
                              </Box>
                            }
                            position="top"
                          >
                            <Icon name="info" size={20} />
                          </Tooltip>
                        </Flex>
                      }
                    />
                    {emailNotifications && (
                      <Flex
                        flexDirection="column"
                        otherStyles={{ marginLeft: 32, marginTop: 8 }}
                      >
                        {renderNotificationsCheck()}
                      </Flex>
                    )}
                  </Flex>
                </Box>
              )}
            </>
          )}
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
}

UserUpdateModalContent.propTypes = {
  updateCurrentUser: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  setToRefreshUser: PropTypes.func,
  reloadTable: PropTypes.func,
  userData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
  ),
  comesFromAdministration: PropTypes.bool,
};

UserUpdateModalContent.defaultProps = {
  reloadTable: () => {},
  setToRefreshUser: () => {},
  userData: {},
  updateCurrentUser: false,
  comesFromAdministration: true,
};

export { UserUpdateModalContent };
