import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import serviceConfig from '../../../../../../../serviceConfig.json';

import { CaretaskComponent } from '../../../../../../types/Caretask';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { AnsweringComponentsProps } from '../AnsweringTypes';
import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';
import tabPosition from '../../../../../../Utils/Administration/tabPosition';
import {
  assetUploadPromise,
  validateFileNameLimit,
  validateFileSize,
  validateFileType,
} from '../../../../../../Utils/Asset/assetHelper';

import { isJson } from '../../../../../../Global/Objects/IsJson/IsJson';
import { ApiClient } from '../../../../../../Global/Services';

export default function useAnsweringFileRequestService({
  handleOnChange,
  setFlashMessage,
}: AnsweringComponentsProps) {
  const { t } = useTranslation();
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );

  const defaultValue = currentAnswer?.answer || null;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState(defaultValue);
  const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);

  useEffect(() => {
    handleOnChange({
      answer: selectedFile,
      isSkipped: isJson(selectedFile),
      hasError: fileIsUploading,
    });
  }, [selectedFile, fileIsUploading]);

  useEffect(() => {
    setSelectedFile(defaultValue);
  }, [currentComponent]);

  const getFileActiveTab = (_state, data) => data;
  const changeFileTabPosition = () => {
    return tabPosition.FIRST;
  };
  const imageUploader = useRef(null);
  const [fileActiveTab, setFileActiveTab] = useReducer(
    getFileActiveTab,
    changeFileTabPosition()
  );

  const setOpen = val => {
    setOpenModal(val);
  };

  const clearInputValueOnClick = e => {
    e.target.value = '';
  };

  const handleUploadFileFromPC = e => {
    const file = e.target.files[0];
    if (!file) return;

    let errorMessage = '';
    const isFileTypeCorrect = validateFileType([file]);
    const isFileSizeCorrect = validateFileSize([file]);
    const isFileNameLimitCorrect = validateFileNameLimit([file]);

    if (isFileSizeCorrect && isFileTypeCorrect && isFileNameLimitCorrect) {
      setFileIsUploading(true);
      const formData = new FormData();
      formData.append('filedata', file);
      formData.append('nameId', file.name);
      formData.append('fileSize', file.size);
      formData.append('typeId', file.type);

      ApiClient.POST({
        url: serviceConfig.brokerService.createAsset.uri,
        payload: formData,
        timeout: 3000000000,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'form-data',
        },
      })
        .then(response => {
          assetUploadPromise(response, file, setFlashMessage)
            .then(results => {
              setSelectedFile({
                assetId: results[0].id,
                name: results[0].name,
                assetTypeId: results[0].assetTypeId,
                directLink: results[0].directLink,
                thumbnailURL: results[0].thumbnailURL,
              });
              setFileIsUploading(false);
            })
            .catch(error => {
              setFileIsUploading(false);
              setFlashMessage({
                content: t(error.response.data.content),
                type: error.response.data.type,
              });
            });
        })
        .catch(error => {
          setFileIsUploading(false);
          setFlashMessage({
            content: t(error.response.data.content),
            type: error.response.data.type,
          });
        });
    } else {
      if (!isFileSizeCorrect) {
        errorMessage = 'flash.maxFileSize';
      } else if (!isFileTypeCorrect) {
        errorMessage = 'flash.incorrectFileType';
      } else if (!isFileNameLimitCorrect) {
        errorMessage = 'flash.fileNameLimitExceed';
      }
      setFlashMessage({
        status: 403,
        content: errorMessage,
        type: 'warning',
      });
    }
  };

  const handleAttachmentButtonClick = (e, action) => {
    if (e.value === t('asset_view.uploadedFiles')) {
      setOpen(true);
    } else {
      imageUploader.current.click();
    }
  };

  return {
    t,
    imageUploader,
    openModal,
    setOpen,
    clearInputValueOnClick,
    handleUploadFileFromPC,
    handleAttachmentButtonClick,
    selectedFile,
    setSelectedFile,
    fileActiveTab,
    setFileActiveTab,
    fileIsUploading,
  };
}
