import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FlashMessage, Box, Tabs } from '@ui-common-files/components';

import ChatMessages from './Datatables/ChatMessages';
import Thresholds from './Datatables/Thresholds';
import TaggedUsersList from './TaggedUsers/TaggedUsersList';
import tabPosition from '@utils/Administration/tabPosition';
import Todos from './Datatables/Todos';

import { ApiClient } from '@global/Services';
import serviceConfig from '../../serviceConfig.json';
import { nonMedical } from '../../config';
import BreadcrumbPortal from './AppLayout/BreadcrumbPortal/BreadcrumbPortal';
import { NavigationJSON } from '@ui-common-files/utils';
import { useLocation } from 'react-router-dom';
import { NotificationTypes } from '@type/Notifications';
import { breadcrumbNotificationObject } from '../Utils/breadcrumbObjects';
import {
  selectThreshold,
  selectTodos,
  selectMessage,
} from './Notifications/Notification.reducer';
import { TabPosition } from '@type/TabPosition';

const NotificationCenter = () => {
  const services = useSelector(state => state.services);
  const { user } = useSelector(state => state.session);
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const notificationType = queryParams.get('notificationType');
  const [unseenNotifications, setUnseenNotifications] = useState(0);
  const [flashMessage, setFlashMessage] = useState({});
  const thresholds = useSelector(selectThreshold);
  const todos = useSelector(selectTodos);
  const messages = useSelector(selectMessage);

  let thresholdsOrMessagesOrTodosString,
    thresholdsOrMessagesOrTodosComponent,
    childTabComponent = '',
    taggedUsersComponent,
    allMentionedNotificationsString,
    allMentionedNotificationsUnseenString;

  const [recordsTotal, setRecordsTotal] = useReducer(
    (_state, records) => records,
    {
      totalChatMessages: 0,
      thresholdNotificationsLength: 0,
      nbUnseenChatMsgs: 0,
    }
  );

  const [refreshCount, setRefreshCount] = useReducer(
    () => !refreshCount,
    false
  );

  const [activeTab, setActiveTab] = useState(TabPosition.FIRST);

  const fetchThresholds = async (
    controller: AbortController
  ): Promise<void> => {
    const result = await ApiClient.GET({
      url: serviceConfig.brokerService.thresholds.uri,
      payload: {
        userId: user.id,
      },
      signal: controller.signal,
    });
    if (result?.data) setRecordsTotal(result.data);
  };

  switch (notificationType) {
    case NotificationTypes.THRESHOLD:
      thresholdsOrMessagesOrTodosString = `${t('threshold.allThresholds')} (${recordsTotal.thresholdNotificationsLength
        }) / ${t('chatMessages.unseen')} (${unseenNotifications})`;

      thresholdsOrMessagesOrTodosComponent = services.thresholdNotification &&
        (nonMedical == false || nonMedical == 'false') && (
          <Box margin="30px 0 0 0">
            <Thresholds
              hasPagesize
              hasPagination
              setRefreshCount={setRefreshCount}
              totalThresholdNotifications={
                recordsTotal.thresholdNotificationsLength
              }
            />
          </Box>
        );
      childTabComponent = t(`${breadcrumbNotificationObject[0].label}`);
      break;

    case NotificationTypes.CHAT:
      thresholdsOrMessagesOrTodosString = `${t(
        'chatMessages.messageNotifications'
      )} (${recordsTotal.totalChatMessages}) / ${t('chatMessages.unseen')} (${recordsTotal.nbUnseenChatMsgs
        })`;

      thresholdsOrMessagesOrTodosComponent = services.chatNotification && (
        <Box margin="30px 0 0 0">
          <ChatMessages
            hasPagesize
            hasPagination
            totalChatMessages={recordsTotal.totalChatMessages}
          />
        </Box>
      );
      childTabComponent = t(`${breadcrumbNotificationObject[1].label}`);
      break;

    case NotificationTypes.TODOS:
      thresholdsOrMessagesOrTodosString = `${t(
        'todos.allTodos'
      )} (${unseenNotifications})`;

      allMentionedNotificationsString = `${t(
        'todos.allMentionedNotifications'
      )} (${todos.noteTotalCount})`;

      allMentionedNotificationsUnseenString = `/ ${t('todos.unseen')} (${todos.unseenNotesCount})`;

      thresholdsOrMessagesOrTodosComponent = (
        <Box margin="30px 0 0 0">
          <Todos hasPagesize hasPagination setRefreshCount={setRefreshCount} />
        </Box>
      );

      taggedUsersComponent = (
        <Box>
          <TaggedUsersList hasPagesize hasPagination />
        </Box>
      );
      childTabComponent = t(`${breadcrumbNotificationObject[2].label}`);
      break;
  }

  useEffect(() => {
    const controller = new AbortController();
    switch (notificationType) {
      case NotificationTypes.THRESHOLD:
        setUnseenNotifications(thresholds.count);
        fetchThresholds(controller);
        break;
      case NotificationTypes.CHAT:
        setUnseenNotifications(messages.count);
        fetchThresholds(controller);
        break;
      case NotificationTypes.TODOS:
        setUnseenNotifications(todos.count);
        break;
    }
    return () => controller.abort();
  }, [thresholds, todos, messages]);

  const tabs = (
    <div>
      {flashMessage.content && (
        <FlashMessage
          message={t(flashMessage.content)}
          type={flashMessage.type}
          onClick={() => setFlashMessage({})}
        />
      )}

      <div style={{ padding: 20 }}>
        <Box>
          <div style={{ position: 'relative' }}>
            {notificationType === NotificationTypes.TODOS ? (
              <div>
                <Tabs activeTab={activeTab} onClickTabItem={setActiveTab}>
                  <div
                    className="notification-center__headline"
                    label={t(thresholdsOrMessagesOrTodosString)}
                    tabId={tabPosition.FIRST}
                  />
                  <div
                    className="notification-center__headline"
                    label={`${t(allMentionedNotificationsString)}  ${t(
                      allMentionedNotificationsUnseenString
                    )}`}
                    tabId={tabPosition.SECOND}
                  />
                </Tabs>
                <div style={{ marginTop: 20 }}>
                  {activeTab === tabPosition.FIRST &&
                    thresholdsOrMessagesOrTodosComponent}
                  {activeTab === tabPosition.SECOND && taggedUsersComponent}
                </div>
              </div>
            ) : (
              <div>
                <Box className="notification-center__headline">
                  {thresholdsOrMessagesOrTodosString}
                </Box>
                {thresholdsOrMessagesOrTodosComponent}
              </div>
            )}
          </div>
        </Box>
      </div>
    </div>
  );

  return (
    <>
      <BreadcrumbPortal
        parentTab={NavigationJSON.NOTIFICATION_CENTER}
        childTab={childTabComponent}
      />
      {tabs}
    </>
  );
};

export default NotificationCenter;
