import React from 'react';

import { Box, DataTable } from '@ui-common-files/components';

import { PrimaryColor } from '@ui-common-files/utils';

import getAllServices from './ServicesList.service';

const ServicesList = ({}) => {
  const { fetchedData, loading, columns, buildNumber } = getAllServices();

  return (
    <>
      <Box
        className="custom-label"
        otherStyles={{ color: PrimaryColor.MAIN_COLOR, marginTop: 35 }}
      >
        Services list | <Box> Build Number: {buildNumber}</Box>
      </Box>
      <DataTable
        data={fetchedData}
        columns={columns}
        isLoading={loading}
        onTableChange={() => {}}
      />
    </>
  );
};
export default ServicesList;
