import React from 'react';

import { Box, Button, CustomLoader } from '../index';

import { DualStateButtonProps } from './DualStateButton.type';

const DualStateButton: React.FC<DualStateButtonProps> = ({
  isLoading = false,
  onClick,
  isDisabled = false,
  label,
  className = '',
  width = '100%',
}) => {
  return (
    <Box width={isLoading ? width : null} className={className}>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <Button label={label} onClick={onClick} disabled={isDisabled} />
      )}
    </Box>
  );
};

export default DualStateButton;
