import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Box from '../../../../../../../caro-ui-commonfiles/components/Box/Box';
import Button from '../../../../../../../caro-ui-commonfiles/components/Button/Button';
import Flex from '../../../../../../../caro-ui-commonfiles/components/Flex/Flex';

import {
  ButtonType,
  ButtonVariant,
} from '../../../../../../../caro-ui-commonfiles/utils/button';
import useAnsweringRankingService from './AnsweringRanking.service';
import { AnsweringComponentsProps } from '../AnsweringTypes';

import './AnsweringRanking.style.scss';

const SortableItem = SortableElement(({ value, isActive, sortIndex }) => {
  return (
    <Box
      className={`answer-view__sortable-items-item ${isActive ? 'active' : ''}`}
    >
      <Flex alignItems="center" otherStyles={{ width: '102%' }}>
        <Box
          className={`answer-view__sortable-items-item-index ${
            isActive ? 'active' : ''
          }`}
        >
          {isActive && sortIndex + 1}
        </Box>
        <Box className="answer-view__sortable-items-item-value">{value}</Box>
      </Flex>
    </Box>
  );
});

const SortableList = SortableContainer(({ items, sortableListState }) => {
  return (
    <Flex flexDirection="column" alignItems="flex-start">
      {sortableListState?.index?.map((origIndex, i) => (
        <SortableItem
          key={`item-${origIndex}`}
          value={items[origIndex]}
          isActive={sortableListState.isActive}
          index={i}
          sortIndex={i}
        />
      ))}
    </Flex>
  );
});

export default function AnsweringRanking({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  const { t, items, sortableListState, setSortableListState, onSortEnd } =
    useAnsweringRankingService({ handleOnChange });

  return (
    <Box className="answer-view__sortable">
      <Box className="answer-view__sortable-items">
        <SortableList
          items={items}
          sortableListState={sortableListState}
          onSortEnd={onSortEnd}
          lockAxis="y"
        />
      </Box>
      <Box className="answer-view__sortable-footer">
        <Button
          label={t('patients_complexsearch.button_reset')}
          type={ButtonType.BUTTON}
          variant={ButtonVariant.OUTLINED}
          onClick={() => setSortableListState(null)}
        />
      </Box>
    </Box>
  );
}
