import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useLayoutEffect,
  useRef,
} from 'react';
import moment from 'moment';
import I18n from 'i18next';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { HideFlashMessage } from '@global/Services';

import FlashMessage from '../../caro-ui-commonfiles/components/FlashMessage/FlashMessage';
import FloatButton from '../../caro-ui-commonfiles/components/FloatingButton/FloatingMenuButton';
import Modal from '../../caro-ui-commonfiles/components/Modals/ModalLayout';
import Tabs from '../../caro-ui-commonfiles/components/Tabs/Tabs';
import {
  ViewCareplan,
  ViewCareplanModalFooter,
} from './Careplans/ViewCareplan';
import AssignCareplanTemplate from './Careplans/AssignCareplanTemplate';
import AssignCareplanModalFooter from './Careplans/AssignCareplanModalFooter';
import AssignCareplanModalHeader from './Careplans/AssignCareplanModalHeader';
import {
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../caro-ui-commonfiles/utils/componentTypes';
import Actions from '../Utils/actions';
import AssignCareplanRequest from '../Utils/AssignCareplanRequest';
import ShowCareplanTemplateRequest from '../Utils/ShowCareplanTemplateRequest';
import serviceConfig from '../../serviceConfig.json';
import NavigationJSON from '../Utils/navigation.json';

import { Greys } from '../../caro-ui-commonfiles/utils/colors';
import tabPosition from '../Utils/Administration/tabPosition';
import Carepathway from './Careplans/CarePathway/CarePathway';
import CareplanTemplates from './Careplans/CareplanTemplates/CareplanTemplates';

import {
  CarePathwayAssignContent,
  CarePathwayAssignHeader,
  CarePathwayAssignFooter,
} from './Careplans/CarePathway/CarePathwayAssignModalComponents';

import RoleHelperUtility from '../Utils/RoleHelperUtility.js';
import QrCodePanel from './Careplans/QrCode/QrCodePanel';
import removeInvalidSymbolsFromFileName from '../Global/Careplan/Validations/RemoveInvalidSymbolsFromFileName/RemoveInvalidSymbolsFromFileName';
import useAnalytics from '../Global/Services/Analytics/UseAnalytics';

import { onTabChange } from '../../actions/Datatables/templates';
import { ApiClient } from '../Global/Services';
import BreadcrumbPortal from './AppLayout/BreadcrumbPortal/BreadcrumbPortal';
import { Breadcrumb } from '@ui-common-files/components';
import ReviewAssessments from './Careplans/ReviewAssessments';

const { breadcrumbCareplanObject } = require('../Utils/breadcrumbObjects');

function Careplans() {
  const myoncareServices = useSelector(state => state.services);
  const carePathwayRead = myoncareServices.carePathwayRead;
  const appointmentservice =
    myoncareServices['appointmentservice.myoncare.care'];
  const { user } = useSelector(state => state.session);
  const { trackEventTrigger } = useAnalytics();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [nbrPatientsSelected, setNbrPatientsSelected] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [reloadTable, setReloadTable] = useState(false);
  const [reloadPathwayTable, setReloadPathwayTable] = useState(false);
  const [reloadTemplateTable, setReloadTemplateTable] = useState(false);
  const [bottomPageReached, setBottomPageReached] = useState(false);
  const defaultFormValidationObj = {
    component: '',
    action: '',
    shouldValidate: false,
  };
  const [shouldValidateForm, dispatchFormValidator] = useReducer(
    (_state, action) => action,
    defaultFormValidationObj
  );
  const currMinute = Math.ceil(moment().minute() / 15) * 15;
  const startTimeObj = {
    hour: moment().hour() + Math.floor(currMinute / 60),
    minute: currMinute % 60,
  };
  const [selectedPatientsData, setSelectedPatientsData] = useState([]);
  const [services, setServices] = useState({});
  const [id, setId] = useState('');
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [careplanData, setCareplanData] = useState({});
  const [careplanDataThresholds, setCareplanDataThresholds] = useState({});
  const [careplanScoringScheme, setCareplanScoringScheme] = useState([]);
  const [defaultDataThresholds, setDefaultDataThresholds] = useState({});
  const [showData, setShowData] = useState({});
  const [careplanDataLogicJumps, setCareplanDataLogicJumps] = useState({});
  const [title, setTitle] = useState('');
  const [responsive, setResponsive] = useState(false);
  const [flashMessage, setFlashMessage] = useState({});
  const [displayModal, setDisplayModal] = useState(false);
  const [action, setAction] = useState('');
  const [ownershipLogs, setOwnershipLogs] = useState([]);
  const [isAdhoc, setIsAdhoc] = useState(true);
  const [appointmentDate, setAppointmentDate] = useState(moment().toDate());
  const [isUnspecified, setIsUnspecified] = useState(false);
  const [appointmentTime, setAppointmentTime] = useState(startTimeObj);
  const history = useHistory();
  const activeTab = useSelector(state => state.careManager);
  const reduxDispatcher = useDispatch();
  const [borderId, setBorderId] = useState(0);
  const [showQrCodePanel, setShowQrCodePanel] = useState({
    id: null,
    name: null,
    carepathwayTemplateUUID: null,
    shouldShow: false,
    allFutureCareplans: true,
  });
  const [qrCodeActiveToggle, setQrCodeActiveToggle] = useState(false);
  const [allFutureCareplans, setAllFutureCareplans] = useState(true);
  const templateFlashMsg = history.location.state
    ? history.location.state.templateFlashMsg
    : null;
  const importTemplateFlashMsg = history.location.state
    ? history.location.state.importTemplateFlashMsg
    : null;
  const NoPermissionflashMessage = history.location.state
    ? history.location.state.NoPermissionflashMessage
    : null;
  const [usWarningCheckboxChecked, setUsWarningCheckboxChecked] = useState({
    awareCheckbox: false,
    confirmCheckbox: false,
  });
  const [under18, setUnder18] = useState(true);
  const [sendToPatient, setSendToPatient] = useState(false);
  const [carePathway, setCarePathway] = useState(null);

  const [showUnder18Warning, setShowUnder18Warning] = useState(false);
  const [carePathwayHasThresholds, setcarePathwayHasThresholds] =
    useState(false);
  const [selectedCareplans, setSelectedCareplans] = useState([]);
  const handleSelectedCareplans = data => {
    setSelectedCareplans(data);
  };
  const carePathwayActions = {
    ASSIGN_CAREPATHWAY: 'assign_carepathway',
  };
  const [disableNext, setDisableNext] = useState(false);
  const ref = useRef(null);
  useLayoutEffect(() => {
    if (history.location.state) {
      if (history.location.state.isAssessment)
        reduxDispatcher(onTabChange(tabPosition.THIRD));
      if (history.location.state.redirectToCaretaskList)
        reduxDispatcher(onTabChange(tabPosition.SECOND));
      if (history.location.state.redirectToCarePathWayList)
        reduxDispatcher(onTabChange(tabPosition.FIRST));
    }
    if (!carePathwayRead && activeTab === tabPosition.SECOND) {
      reduxDispatcher(onTabChange(tabPosition.SECOND));
    }
  }, []);
  useEffect(() => {
    if (importTemplateFlashMsg) {
      setFlashMessage(importTemplateFlashMsg);
      if (importTemplateFlashMsg.content === 'flash.templateImported')
        reduxDispatcher(onTabChange(tabPosition.SECOND));
    }
  }, [importTemplateFlashMsg]);

  const deselectAll = shouldSelectAll => {
    if (!shouldSelectAll) {
      setSelectedPatientsData([]);
      setNbrPatientsSelected(0);
    }
  };

  useEffect(() => {
    deselectAll(isSelectAll);
  }, [isSelectAll]);

  useEffect(() => {
    if (templateFlashMsg) setFlashMessage(templateFlashMsg);
  }, [templateFlashMsg]);

  useEffect(() => {
    if (NoPermissionflashMessage) setFlashMessage(NoPermissionflashMessage);
  }, [NoPermissionflashMessage]);

  const floatItems = [
    {
      icon: 'careplan',
      tooltipText: I18n.t('breadcrumbs.createTemplate'),
      fill: Greys.DARK_GREY,
      onClick: async () => {
        const userHasCredential = await checkUserCredential(
          ComponentTranslationKeys.CAREPLAN_TEMPLATE,
          ActionTranslationKeys.CREATE
        );
        if (userHasCredential) {
          trackEventTrigger({
            category: 'caretask',
            action: 'Click-Create-Button',
          });
          history.push(`/${NavigationJSON.CREATE_CAREPLAN_TEMPLATE}`);
        }
      },
    },
    {
      icon: 'carepathway',
      fill: Greys.DARK_GREY,
      tooltipText: I18n.t('newCareplan_view.createCarePathway'),
      onClick: async close => {
        if (appointmentservice) {
          close();
          const hasCredential = await checkUserCredential(
            ComponentTranslationKeys.CAREPATHWAY_TEMPLATE,
            ActionTranslationKeys.CREATE
          );
          if (hasCredential === true) {
            trackEventTrigger({
              category: 'carepathway',
              action: 'Click-Create-Button',
            });
            history.push(`/${NavigationJSON.CAREPATHWAY_BUILDER_CREATE}`);
          } else {
            setFlashMessage({
              content: I18n.t(hasCredential.content),
              type: hasCredential.type,
            });
          }
        } else {
          setFlashMessage({
            type: 'error',
            content: 'common_labels.appointment_service_error',
          });
        }
      },
    },
    {
      icon: 'import',
      fill: Greys.DARK_GREY,
      tooltipText: I18n.t('newTemplate_view.label_importTemplate'),
      onClick: () => {
        checkImportTemplateCredential().then(() => {
          return history.push(`/${NavigationJSON.IMPORT_CAREPLAN_TEMPLATE}`);
        });
      },
    },
  ];
  const checkUserCredential = async (component, action) => {
    return await ApiClient.GET({
      url: serviceConfig.brokerService.checkUserCredential.uri,
      payload: {
        component,
        action,
      },
    })
      .then(response => {
        if (response.data.hasCredential) return response.data.hasCredential;
        return false;
      })
      .catch(error => {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });
  };
  const checkImportTemplateCredential = async () =>
    ApiClient.GET({
      url: serviceConfig.brokerService.checkImportTemplate.uri,
    })
      .then(response => response)
      .catch(error => {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });

  const triggerTrackAssigment = (category: string) => {
    trackEventTrigger({
      category,
      action: `Assign-Successful`,
    });
  };

  const renderModalContent = () => {
    switch (action) {
      case Actions.Show:
        return (
          <ViewCareplan
            closeModal={closeModal}
            id={id}
            showData={showData}
            careplanData={careplanDataThresholds}
            careplanLogicJumpData={careplanDataLogicJumps}
            ownershipLogs={ownershipLogs}
            isEvaluating={isEvaluating}
            careplanScoringScheme={careplanScoringScheme}
          />
        );
      case Actions.Assign:
        return (
          <AssignCareplanTemplate
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            isSelectAll={isSelectAll}
            setIsSelectAll={value => {
              deselectAll(value);
              setIsSelectAll(value);
            }}
            nbrPatientsSelected={nbrPatientsSelected}
            setNbrPatientsSelected={setNbrPatientsSelected}
            careplanData={careplanData}
            setCareplanData={setCareplanData}
            careplanDataThresholds={careplanDataThresholds}
            setCareplanDataThresholds={setCareplanDataThresholds}
            selectedPatientsData={selectedPatientsData}
            setSelectedPatientsData={setSelectedPatientsData}
            shouldValidateForm={shouldValidateForm}
            dispatchFormValidator={dispatchFormValidator}
            isEvaluating={isEvaluating}
            usWarningCheckboxChecked={usWarningCheckboxChecked}
            setUsWarningCheckboxChecked={setUsWarningCheckboxChecked}
            setBottomPageReached={setBottomPageReached}
            careplanLogicJumpData={careplanDataLogicJumps}
            setShowUnder18Warning={setShowUnder18Warning}
            defaultDataThresholds={defaultDataThresholds}
            setDisableNext={setDisableNext}
            careplanScoringScheme={careplanScoringScheme}
          />
        );
      case carePathwayActions.ASSIGN_CAREPATHWAY:
        return (
          <CarePathwayAssignContent
            carePathwayId={id}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            selectedPatientsData={selectedPatientsData}
            setSelectedPatientsData={setSelectedPatientsData}
            isSelectAll={isSelectAll}
            setIsSelectAll={value => {
              deselectAll(value);
              setIsSelectAll(value);
            }}
            nbrPatientsSelected={nbrPatientsSelected}
            setNbrPatientsSelected={setNbrPatientsSelected}
            setFlashMessage={setFlashMessage}
            setBottomPageReached={setBottomPageReached}
            usWarningCheckboxChecked={usWarningCheckboxChecked}
            setUsWarningCheckboxChecked={setUsWarningCheckboxChecked}
            setUnder18={setUnder18}
            sendToPatient={sendToPatient}
            close={closeModal}
            hasThresholds={carePathwayHasThresholds}
            setHasThresholds={setcarePathwayHasThresholds}
            handleSelectedCareplans={handleSelectedCareplans}
            selectedCareplans={selectedCareplans}
            setDisableNext={setDisableNext}
            isAdhoc={isAdhoc}
            setIsAdhoc={setIsAdhoc}
            appointmentDate={appointmentDate}
            setAppointmentDate={setAppointmentDate}
            appointmentTime={appointmentTime}
            setAppointmentTime={setAppointmentTime}
            isUnspecified={isUnspecified}
            setIsUnspecified={setIsUnspecified}
            allFutureCareplans={allFutureCareplans}
            carePathway={carePathway}
            setCarePathway={setCarePathway}
          />
        );

      default:
        return <div />;
    }
  };

  const renderModalFooter = () => {
    switch (action) {
      case Actions.Show:
        return <ViewCareplanModalFooter close={closeModal} />;
      case Actions.Assign:
        return (
          <AssignCareplanModalFooter
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            nbrPatientsSelected={nbrPatientsSelected}
            careplanData={careplanData}
            closeModal={closeModal}
            setFlashMessage={setFlashMessage}
            selectedPatientsData={selectedPatientsData}
            careplanDataThresholds={careplanDataThresholds}
            dispatchFormValidator={dispatchFormValidator}
            isEvaluating={isEvaluating}
            usWarningCheckboxChecked={usWarningCheckboxChecked}
            bottomPageReached={bottomPageReached}
            careplanDataLogicJumps={careplanDataLogicJumps}
            showUnder18Warning={showUnder18Warning}
            disableNext={disableNext}
            triggerTrackAssigment={triggerTrackAssigment}
          />
        );
      case carePathwayActions.ASSIGN_CAREPATHWAY:
        return (
          <CarePathwayAssignFooter
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            usWarningCheckboxChecked={usWarningCheckboxChecked}
            bottomPageReached={bottomPageReached}
            nbrPatientsSelected={nbrPatientsSelected}
            selectedPatientsData={selectedPatientsData}
            under18={under18}
            setUnder18={setUnder18}
            setSendToPatient={setSendToPatient}
            hasThreshold={carePathwayHasThresholds}
            disableNext={disableNext}
            triggerTrackAssigment={triggerTrackAssigment}
            carePathway={carePathway}
          />
        );

      default:
        return <div />;
    }
  };

  const renderModalHeader = () => {
    switch (action) {
      case Actions.Show:
        return null;
      case Actions.Assign:
        return <AssignCareplanModalHeader currentStep={currentStep} />;
      case carePathwayActions.ASSIGN_CAREPATHWAY:
        return <CarePathwayAssignHeader currentStep={currentStep} />;

      default:
        return <div />;
    }
  };

  const closeModal = () => {
    setDisplayModal(false);
    setCurrentStep(1);
    setIsSelectAll(false);
    setSelectedPatientsData([]);
    setNbrPatientsSelected(0);
    setIsEvaluating(false);
    setUsWarningCheckboxChecked({
      awareCheckbox: false,
      confirmCheckbox: false,
    });
    setBottomPageReached(false);
    setUnder18(false);
    setSendToPatient(false);
    setcarePathwayHasThresholds(false);
    setSelectedCareplans([]);
    setDisableNext(false);
    setIsAdhoc(true);
    setAppointmentDate(moment().toDate());
    setAppointmentTime(startTimeObj);
    setIsUnspecified(false);
    trackEventTrigger({
      category: 'Modal',
      action: `Close-modal`,
    });
  };

  const openShowModal = id => {
    setId(id);
    setDisplayModal(true);
  };

  const hideFlashMessages = () => {
    if (
      history.location.state &&
      (history.location.state.templateFlashMsg ||
        history.location.state.importTemplateFlashMsg)
    ) {
      const state = { ...history.location.state };
      delete state.templateFlashMsg;
      delete state.importTemplateFlashMsg;
      history.replace({ ...history.location, state });
    }
    setFlashMessage({});
  };

  const screenSize = useCallback(() => {
    const isResponsive = window.innerWidth <= 1366;
    setResponsive(isResponsive);
  }, [responsive]);

  useEffect(() => {
    screenSize();
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  const exportCareplanTemplate = async id => {
    trackEventTrigger({
      category: 'caretask',
      action: `Click-Export-Button`,
    });
    ApiClient.GET({
      url: serviceConfig.brokerService.exportCareplanTemplate.uri + id,
    })
      .then(response => {
        const { data } = response;
        const fileName = data[0];
        const zip = new JSZip();
        const fileNameWithoutSymboles =
          removeInvalidSymbolsFromFileName(fileName);
        zip.file(`${fileNameWithoutSymboles}.care`, response.data[1]);
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, `${fileNameWithoutSymboles}.zip`);
        });
        trackEventTrigger({
          category: 'caretask',
          action: `Export-Successful`,
        });
      })
      .catch(error => {
        trackEventTrigger({
          category: 'caretask',
          action: `Export-Failed`,
        });
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });
  };

  const exportCarePathwayTemplate = async carePathwayTemplateId => {
    trackEventTrigger({
      category: 'carepathway',
      action: `Click-Export-Button`,
    });
    await ApiClient.GET({
      url:
        serviceConfig.brokerService.exportCarePathwayTemplate.uri +
        carePathwayTemplateId,
    })
      .then(response => {
        const { data } = response;
        const fileName = data[0];
        const zip = new JSZip();
        const fileNameWithoutSymboles =
          removeInvalidSymbolsFromFileName(fileName);
        zip.file(`${fileNameWithoutSymboles}.care`, response.data[1]);
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, `${fileNameWithoutSymboles}.zip`);
        });
        trackEventTrigger({
          category: 'carepathway',
          action: `Export-Successful`,
        });
      })
      .catch(error => {
        trackEventTrigger({
          category: 'carepathway',
          action: `Export-Failed`,
        });
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });
  };

  const editCareplanTemplate = async id => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPLAN_TEMPLATE,
      ActionTranslationKeys.UPDATE
    );
    if (userHasCredential) {
      trackEventTrigger({
        category: 'caretask',
        action: 'Click-Edit-Button',
      });
      history.push(
        `/${NavigationJSON.EDIT_CAREPLAN_TEMPLATE.replace(':id', id)}`
      );
    }
  };

  const onShow = (templateId, templateTitle) => {
    setAction(Actions.Show);
    setId(templateId);
    ShowCareplanTemplateRequest(
      templateId,
      setCareplanDataThresholds,
      setShowData,
      setCareplanDataLogicJumps,
      setCareplanScoringScheme,
      setOwnershipLogs,
      setIsEvaluating
    ).then(() => {
      trackEventTrigger({ category: 'caretask', action: 'Open-Show-Modal' });
      openShowModal(templateId);
      const component = I18n.t('newCareplan_view.view');
      setTitle(`${component} ${templateTitle}`);
    });
  };

  const onAssign = (
    templateId,
    templateTitle,
    isEvaluatingTemplate,
    icds,
    tags,
    nbrComponents
  ) => {
    trackEventTrigger({ category: 'caretask', action: 'Open-Assign-Modal' });
    setIsEvaluating(isEvaluatingTemplate);
    setAction(Actions.Assign);
    return AssignCareplanRequest(
      templateId,
      setFlashMessage,
      setCareplanData,
      () => {},
      icds,
      tags,
      nbrComponents
    ).then(hasRights => {
      if (hasRights) {
        openShowModal(templateId);
        ShowCareplanTemplateRequest(
          templateId,
          setCareplanDataThresholds,
          setShowData,
          setCareplanDataLogicJumps,
          setCareplanScoringScheme,
          setOwnershipLogs,
          setIsEvaluating
        ).then(response => {
          if (response.data.template.scoringScheme) {
            setCareplanScoringScheme(response.data.template.scoringScheme);
          } else {
            setCareplanScoringScheme([]);
          }
          if (response.data.careplanComponentsWithThresholds) {
            setDefaultDataThresholds(
              response.data.careplanComponentsWithThresholds
            );
          }
        });

        setTitle(
          `${I18n.t('common_labels.label_assign')} "${templateTitle}" ${I18n.t(
            'common_labels.to_patients'
          )}`
        );
      }
    });
  };
  const onExport = templateId => {
    setId(templateId);
    trackEventTrigger({
      category: 'caretask',
      action: `Click-Export-Button`,
    });
    exportCareplanTemplate(templateId);
  };

  const onExportCarePathway = carePathwayTemplateId => {
    setId(carePathwayTemplateId);
    exportCarePathwayTemplate(carePathwayTemplateId);
  };

  const onEditPathway = async (id, title) => {
    if (appointmentservice) {
      const hasCredential = await checkUserCredential(
        ComponentTranslationKeys.CAREPATHWAY_TEMPLATE,
        ActionTranslationKeys.UPDATE
      );
      if (hasCredential === true) {
        trackEventTrigger({
          category: 'carepathway',
          action: 'Click-Edit-Button',
        });
        history.push(
          `/${NavigationJSON.CAREPATHWAY_BUILDER_EDIT.replace(':id', id)}`
        );
      } else {
        trackEventTrigger({
          category: 'carepathway',
          action: 'Edit-Failed',
        });
        setFlashMessage({
          content: I18n.t(hasCredential.content),
          type: hasCredential.type,
        });
      }
    } else {
      trackEventTrigger({
        category: 'carepathway',
        action: 'Edit-Failed',
      });
      setFlashMessage({
        type: 'error',
        content: 'common_labels.appointment_service_error',
      });
    }
  };

  const onAssignCarepathway = async (
    carePathwayId,
    carePathwayTitle,
    isAllFutureCareplans
  ) => {
    trackEventTrigger({
      category: 'carepathway',
      action: 'Open-Assign-Modal',
    });
    setAllFutureCareplans(isAllFutureCareplans);
    const hasAssignCarePathwayCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPATHWAY,
      ActionTranslationKeys.CREATE
    );
    const hasAssignCareplanCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPLAN,
      ActionTranslationKeys.CREATE
    );
    if (
      hasAssignCarePathwayCredential === true &&
      hasAssignCareplanCredential === true
    ) {
      setId(carePathwayId);
      setAction(carePathwayActions.ASSIGN_CAREPATHWAY);
      setTitle(
        `${I18n.t(
          'common_labels.label_assign'
        )} Care Pathway "${carePathwayTitle}" ${I18n.t(
          'common_labels.to_patients'
        )}`
      );
      setDisplayModal(true);
    } else {
      setFlashMessage({
        content: I18n.t(hasCredential.content),
        type: hasCredential.type,
      });
    }
  };

  const closeQrPanel = () => {
    setShowQrCodePanel({
      id: null,
      name: null,
      carepathwayTemplateUUID: null,
      shouldShow: false,
      allFutureCareplans: true,
    });
    setBorderId(0);
  };

  const checkPermissionBeforeClickingToTabs = async tab => {
    const action = ActionTranslationKeys.READ;
    const component =
      tab === 1 || tab === 3
        ? ComponentTranslationKeys.CAREPLAN_TEMPLATE
        : ComponentTranslationKeys.CAREPATHWAY_TEMPLATE;
    RoleHelperUtility.getLoggedInUserRolePermissions(
      user,
      I18n.t(component),
      I18n.t(action)
    )
      .then(checkPermission => {
        closeQrPanel();
        if (checkPermission && checkPermission.allowed) {
          reduxDispatcher(onTabChange(tab));
        } else {
          setFlashMessage({
            type: 'warning',
            content: I18n.t('common_labels.permissionDenied'),
          });
        }
      })
      .catch(error => {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });
  };

  const containerWidth = ref.current ? ref.current.offsetWidth : 0;
  const flashMessageWidth = containerWidth - 340 + 17;

  return (
    <div>
      <BreadcrumbPortal>
        <Breadcrumb>
          <Link to={`/${NavigationJSON.CAREPLAN}`}>
            {I18n.t('nav.careManager')}
          </Link>
          <span aria-current="page">
            {I18n.t(breadcrumbCareplanObject[activeTab - 1].label)}
          </span>
        </Breadcrumb>
      </BreadcrumbPortal>
      {flashMessage.content && (
        <div
          style={{
            width: showQrCodePanel.shouldShow && `${flashMessageWidth}px`,
          }}
        >
          <FlashMessage
            message={I18n.t(flashMessage.content)}
            type={flashMessage.type}
            onClick={hideFlashMessages}
          />
        </div>
      )}

      <div ref={ref} className="container-padding">
        <div style={{ position: 'relative' }}>
          <Tabs
            activeTab={activeTab}
            onClickTabItem={tab => checkPermissionBeforeClickingToTabs(tab)}
          >
            {carePathwayRead && (
              <div
                label={I18n.t('breadcrumbs.carepathway')}
                tabId={tabPosition.FIRST}
              >
                <Carepathway
                  reloadTable={reloadPathwayTable}
                  setReloadTable={setReloadPathwayTable}
                  onEdit={onEditPathway}
                  setFlashMessage={setFlashMessage}
                  onAssign={onAssignCarepathway}
                  setShowQrCodePanel={setShowQrCodePanel}
                  borderId={borderId}
                  setBorderId={setBorderId}
                  onExport={onExportCarePathway}
                />
              </div>
            )}
            <div
              label={I18n.t('breadcrumbs.careplan')}
              tabId={tabPosition.SECOND}
            >
              <CareplanTemplates
                reloadTable={reloadTemplateTable}
                setReloadTable={setReloadTemplateTable}
                onEdit={editCareplanTemplate}
                onAssign={onAssign}
                onExport={onExport}
                onShow={onShow}
                setFlashMessage={setFlashMessage}
                setServices={setServices}
                setShowQrCodePanel={setShowQrCodePanel}
                borderId={borderId}
                setBorderId={setBorderId}
              />
            </div>
            <div
              label={I18n.t('breadcrumbs.reviewCaretasks')}
              tabId={tabPosition.THIRD}
            >
              <ReviewAssessments
                reloadTable={reloadTemplateTable}
                setReloadTable={setReloadTemplateTable}
                onEdit={editCareplanTemplate}
                onAssign={onAssign}
                onExport={onExport}
                onShow={onShow}
                setFlashMessage={setFlashMessage}
                setServices={setServices}
                borderId={borderId}
              />
            </div>
          </Tabs>
        </div>
      </div>
      {showQrCodePanel.shouldShow && (
        <div className="qrcode-panel-wrapper">
          <QrCodePanel
            onEdit={onEditPathway}
            onAssign={onAssignCarepathway}
            closePanel={() => {
              closeQrPanel();
              const category = showQrCodePanel.carepathwayTemplateUUID
                ? 'carepathway'
                : 'caretask';
              trackEventTrigger({
                category,
                action: `Close-Qrcode-Modal`,
              });
            }}
            showQrCodePanel={showQrCodePanel}
            setQrCodeActiveToggle={setQrCodeActiveToggle}
            qrCodeActiveToggle={qrCodeActiveToggle}
            setFlashMessage={setFlashMessage}
          />
        </div>
      )}

      <Modal
        title={title}
        headerComponent={renderModalHeader()}
        contentComponent={renderModalContent()}
        footerComponent={renderModalFooter()}
        openModal={displayModal}
        onClose={closeModal}
        hideFlashMessage={HideFlashMessage}
      />

      <FloatButton menuItems={floatItems} />
    </div>
  );
}

export default Careplans;
