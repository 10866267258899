import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Icon,
  TextInput,
  Tooltip,
  Collapsible,
  DnDLayoutProvider,
  ButtonDropdown,
} from '@ui-common-files/components';
import { ButtonVariant, ButtonType } from '@ui-common-files/utils';
import {
  ActionColors,
  AuxiliaryColors,
  Greys,
} from '@ui-common-files/utils/colors';
import Position from '@ui-common-files/utils/positions';
import { useResponsiveScreen } from '@ui-common-files/utils/hooks';
import { isScreenSmIpad } from '@ui-common-files/global/responsiveUtils/responsiveValidation.js';
import { ScoringTypes } from '@type/Careplan';
import { nonMedical } from '../../../config';
import CareplanComponents from './CareplanComponents';
import componentTypeOrder from './careplanComponentTypeOrder';
import {
  addComponentHandler,
  componentHealthCategories,
  getComponentsByHealthCategory,
  getScoringCategoryTranslation,
} from './CareplanHelperUtility';
import { getOverallScore } from './ComplexScoreSetting/ComplexScoring.utils';

const MIN_MAX_DEFAULT = -1;

const scoringState = [
  {
    id: ScoringTypes.Well,
    max: MIN_MAX_DEFAULT,
    min: MIN_MAX_DEFAULT,
    color: ActionColors.SUCCESS,
    bgColor: '#dff4e8',
    icon: 'scoreWell',
  },
  {
    id: ScoringTypes.Fair,
    max: MIN_MAX_DEFAULT,
    min: MIN_MAX_DEFAULT,
    color: ActionColors.WARNING,
    bgColor: '#fdf4df',
    icon: 'scoreFair',
  },
  {
    id: ScoringTypes.Poor,
    max: MIN_MAX_DEFAULT,
    min: MIN_MAX_DEFAULT,
    color: ActionColors.ERROR,
    bgColor: AuxiliaryColors.RED_10,
    icon: 'scorePoor',
  },
];

function ScoringSchemeComponent({
  isCareplanOrAssessment,
  renderLabelWithToggleButton,
  scoringFormulas,
  careplanTemplateOverallScoringToggle,
  scoringValues,
  totalScoring,
  onChange,
  isValid,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring,
  careplanComponentTypes,
  componentCollection,
  setComponentCollection,
  counter,
  setCounter,
  setSettings,
  setTemplateSettings,
  searchTermComponents,
  setSearchTermComponents,
  updateSelectedComponent,
  setOpenPrompt,
  setOpenPromptForParentComponentDeletion,
  setTemplateNames,
  isEditTemplate,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  setParentId,
  isEditable,
  setIsEditable,
}) {
  const { t } = useTranslation();

  const overallScore = getOverallScore(scoringFormulas);
  const hasOverallScore = overallScore && !overallScore.equationHasError;

  const additionalComponentFound = componentCollection.some(ele => {
    return ele.healthCategory !== componentHealthCategories.MAIN_QUESTION;
  });
  const well = scoringValues.find(score => score.id === ScoringTypes.Well);
  const fair = scoringValues.find(score => score.id === ScoringTypes.Fair);
  const poor = scoringValues.find(score => score.id === ScoringTypes.Poor);

  const numericExp =
    /([0-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|1000)/;

  const errorActions = {
    ON_ERROR_UPDATE: 'on_error_update',
    ON_RESET_ERROR: 'on_reset_error',
  };

  const errorTypes = {
    UNDEFINED_RANGE: 'undefined_range',
    INVALID_STRUCTURE: 'incorrect_structure',
    OVERLAPS_RANGE: 'overlaps_range',
    MINMAX_VALIDATION: 'minmax_validation',
    SPACES_BETWEEN_RANGES: 'spaces_between_ranges',
    INVALID_MAX: 'invalid_max',
    INVALID_MIN: 'invalid_min',
  };

  const scoringErrorState = [
    {
      id: ScoringTypes.Well,
      hasError: false,
      max: false,
      min: false,
      message: '',
    },
    {
      id: ScoringTypes.Fair,
      hasError: false,
      max: false,
      min: false,
      message: '',
    },
    {
      id: ScoringTypes.Poor,
      hasError: false,
      max: false,
      min: false,
      message: '',
    },
  ];

  const availableScoreComponents = [
    componentTypeOrder.FREE_TEXT,
    componentTypeOrder.INFORMATION,
    componentTypeOrder.SINGLE_CHOICE,
    componentTypeOrder.MULTIPLE_CHOICE,
    componentTypeOrder.YESNO,
    componentTypeOrder.NUMERIC_VALUE,
    componentTypeOrder.NUMERIC_RANGE,
    componentTypeOrder.DATEPICKER,
    componentTypeOrder.UPLOAD_FILE,
    componentTypeOrder.PAIN_LOCATION_CHART,
    componentTypeOrder.BMI,
    componentTypeOrder.BLOOD_PRESSURE,
  ];

  const errorReducer = (state, action) => {
    const copyFromError = [...state];
    switch (action.type) {
      case errorActions.ON_ERROR_UPDATE: {
        copyFromError.splice(action.payload.index, 1, action.payload.error);
        return copyFromError;
      }
      case errorActions.ON_RESET_ERROR: {
        const resetError = {
          ...state[action.payload],
          hasError: false,
          max: false,
          min: false,
          message: '',
        };
        copyFromError.splice(action.payload, 1, resetError);
        return copyFromError;
      }
    }
  };

  const [hasError, dispatchUpdateError] = useReducer(
    errorReducer,
    scoringErrorState
  );
  const shouldShowError =
    totalScoring !== 0 &&
    hasError.some(err => err.message === errorTypes.UNDEFINED_RANGE);

  const validateMinimumMaximum = scoringId => {
    if (scoringId === well.id && well.max !== 0) {
      if (well.max > poor.max && well.max !== totalScoring) {
        return {
          hasError: true,
          max: true,
          min: false,
          message: errorTypes.INVALID_MAX,
        };
      }
      if (well.max < poor.max && well.min !== 0) {
        return {
          hasError: true,
          max: false,
          min: true,
          message: errorTypes.INVALID_MIN,
        };
      }
    }
    if (scoringId === poor.id && poor.max !== 0) {
      if (poor.max > well.max && poor.max !== totalScoring) {
        return {
          hasError: true,
          max: true,
          min: false,
          message: errorTypes.INVALID_MAX,
        };
      }
      if (poor.max < well.max && poor.min !== 0) {
        return {
          hasError: true,
          max: false,
          min: true,
          message: errorTypes.INVALID_MIN,
        };
      }
    }
    return null;
  };

  const inBetween = (value, min, max) => {
    if (value === '' || min === '' || max === '') return false;
    return value >= min && value <= max;
  };

  const validateValueRange = (value, index) => {
    for (let i = 0; i < scoringValues.length; i++) {
      const score = scoringValues[i];
      if (
        i !== index &&
        inBetween(value, score.min, score.max) &&
        value !== '' &&
        value >= 0
      )
        return true;
    }
    return false;
  };

  const isValidSchemeStructure = () => {
    if (
      fair.max > 0 &&
      (fair.max === Math.max(well.max, fair.max, poor.max) ||
        fair.max === Math.min(well.max, fair.max, poor.max))
    ) {
      return false;
    }
    return true;
  };

  const isInvalidInterval = scoreId => {
    switch (scoreId) {
      case ScoringTypes.Well: {
        if (fair.min > MIN_MAX_DEFAULT && fair.max > MIN_MAX_DEFAULT) {
          if (well.min > fair.max && well.min - fair.max > 1) {
            return { invalid: true, value: 'min' };
          }
          if (well.max < fair.min && fair.min - well.max > 1) {
            return { invalid: true, value: 'max' };
          }
        } else {
          if (well.min > poor.max && well.min - poor.max > 1) {
            return { invalid: true, value: 'min' };
          }
          if (well.max < poor.min && poor.min - well.max > 1) {
            return { invalid: true, value: 'max' };
          }
        }
        return { invalid: false, value: '' };
      }
      case ScoringTypes.Poor: {
        if (fair.min > MIN_MAX_DEFAULT && fair.max > MIN_MAX_DEFAULT) {
          if (poor.min > fair.max && poor.min - fair.max > 1) {
            return { invalid: true, value: 'min' };
          }
          if (poor.max < fair.min && fair.min - poor.max > 1) {
            return { invalid: true, value: 'max' };
          }
        } else {
          if (poor.min > well.max && poor.min - well.max > 1) {
            return { invalid: true, value: 'min' };
          }
          if (poor.max < well.min && well.min - poor.max > 1) {
            return { invalid: true, value: 'max' };
          }
        }
        return { invalid: false, value: '' };
      }
      default:
        return { invalid: false, value: '' };
    }
  };

  const validateScoringInterval = (scoring, index) => {
    if (
      scoring.id !== ScoringTypes.Fair &&
      (scoring.max === MIN_MAX_DEFAULT ||
        scoring.min === MIN_MAX_DEFAULT ||
        scoring.max === '' ||
        scoring.min === '')
    ) {
      const error = {
        ...hasError[index],
        hasError: true,
        max: scoring.max === MIN_MAX_DEFAULT,
        min: scoring.min === MIN_MAX_DEFAULT,
        message: errorTypes.UNDEFINED_RANGE,
      };
      dispatchUpdateError({
        type: errorActions.ON_ERROR_UPDATE,
        payload: { error, index },
      });
      return false;
    }
    if (scoring.max < scoring.min) {
      const error = {
        ...hasError[index],
        hasError: true,
        max: true,
        min: true,
        message: errorTypes.MINMAX_VALIDATION,
      };
      dispatchUpdateError({
        type: errorActions.ON_ERROR_UPDATE,
        payload: { error, index },
      });
      return false;
    }
    if (
      (scoring.max !== MIN_MAX_DEFAULT &&
        validateValueRange(scoring.max, index)) ||
      (scoring.min !== MIN_MAX_DEFAULT &&
        validateValueRange(scoring.min, index))
    ) {
      const error = {
        ...hasError[index],
        hasError: true,
        max: validateValueRange(scoring.max, index),
        min: validateValueRange(scoring.min, index),
        message: errorTypes.OVERLAPS_RANGE,
      };

      dispatchUpdateError({
        type: errorActions.ON_ERROR_UPDATE,
        payload: { error, index },
      });
      return false;
    }
    if (scoring.id === ScoringTypes.Fair && !isValidSchemeStructure()) {
      const error = {
        ...hasError[index],
        hasError: true,
        max: true,
        min: true,
        message: errorTypes.INVALID_STRUCTURE,
      };
      dispatchUpdateError({
        type: errorActions.ON_ERROR_UPDATE,
        payload: { error, index },
      });
      return false;
    }

    const validateInterval = isInvalidInterval(scoring.id);
    if (validateInterval.invalid) {
      const error = {
        ...hasError[index],
        hasError: true,
        max: validateInterval.value === 'max',
        min: validateInterval.value === 'min',
        message: errorTypes.SPACES_BETWEEN_RANGES,
      };
      dispatchUpdateError({
        type: errorActions.ON_ERROR_UPDATE,
        payload: { error, index },
      });
      return false;
    }

    const validateMinMax = validateMinimumMaximum(scoring.id);
    if (validateMinMax) {
      const error = {
        ...hasError[index],
        ...validateMinMax,
      };
      dispatchUpdateError({
        type: errorActions.ON_ERROR_UPDATE,
        payload: { error, index },
      });
      return false;
    }

    dispatchUpdateError({ type: errorActions.ON_RESET_ERROR, payload: index });
    return true;
  };

  const validateScoring = () => {
    let valid = true;
    for (let index = 0; index < scoringValues.length; index++) {
      if (!validateScoringInterval(scoringValues[index], index)) {
        valid = false;
      }
    }
    isValid(valid);
  };

  const setScoring = (value, key, index) => {
    const copyFromScoring = [...scoringValues];
    const newValue = {
      ...copyFromScoring[index],
      [key]: value !== '' ? parseInt(value, 10) : '',
    };
    copyFromScoring.splice(index, 1, newValue);
    onChange(copyFromScoring);
  };

  const getErrorTranslations = key => {
    switch (key) {
      case errorTypes.UNDEFINED_RANGE:
        return t('newCareplan_view.scoring_undefinedRange');
      case errorTypes.OVERLAPS_RANGE:
        return t('newCareplan_view.scoring_rangeOverlapsError');
      case errorTypes.INVALID_STRUCTURE:
        return t('newCareplan_view.scoring_invalidOrderError');
      case errorTypes.MINMAX_VALIDATION:
        return t('newCareplan_view.scoring_minMaxError');
      case errorTypes.SPACES_BETWEEN_RANGES:
        return t('newCareplan_view.scoring_incorrectInterval');
      case errorTypes.INVALID_MAX:
        return t('newCareplan_view.scoringMaxScore');
      case errorTypes.INVALID_MIN:
        return t('newCareplan_view.scoringMinScore');
      default:
        return '';
    }
  };

  const addScoreComponentHandler = (event, item, scoreId) => {
    addComponentHandler(
      event,
      item,
      scoreId === ScoringTypes.Well
        ? componentHealthCategories.WELL_QUESTION
        : scoreId === ScoringTypes.Fair
        ? componentHealthCategories.FAIR_QUESTION
        : componentHealthCategories.POOR_QUESTION,
      showPromptForAdjustScoring || null,
      setShowPromptForAdjustScoring || null,
      counter,
      setCounter,
      componentCollection,
      setComponentCollection,
      setSearchTermComponents
    );
  };

  const getAddComponentStatus = score => {
    if (score.id === ScoringTypes.Fair && score.min == 0 && score.max == 0)
      return true;
    return (
      getComponentsByHealthCategory(
        componentCollection,
        score.id === ScoringTypes.Well
          ? componentHealthCategories.WELL_QUESTION
          : score.id === ScoringTypes.Fair
          ? componentHealthCategories.FAIR_QUESTION
          : componentHealthCategories.POOR_QUESTION
      ).length >= 10
    );
  };

  useEffect(() => {
    validateScoring();
    onChange(scoringValues);
  }, [scoringValues, totalScoring]);

  useEffect(() => {
    const temp = { ...validationErrorTemplate };
    temp.templateSettingsValidation.additionalComponentsWithComplexScoreError =
      hasOverallScore;
    hasValidationErrorTemplate({ ...temp });
  }, [hasOverallScore]);

  const conditionOnTrigger =
    validationErrorTemplate.templateSettingsValidation
      .additionalComponentsWithComplexScoreError &&
    careplanTemplateOverallScoringToggle &&
    totalScoring !== 0;

  const conditionDynamic =
    additionalComponentFound && hasOverallScore && totalScoring !== 0;

  const shouldDisplayComplexScoreValidationMessage =
    conditionOnTrigger || conditionDynamic;

  const screenSize = useResponsiveScreen();

  const renderScores = () => {
    return scoringValues.map((score, index) => {
      return (
        <Flex flexDirection="column" key={`${score.id}-${index}`}>
          <Collapsible
            isCollapsible
            isExpanded={true}
            headerComponent={() => {
              return (
                <Flex alignItems="flex-start">
                  <Flex
                    alignItems="center"
                    otherStyles={{ backgroundColor: score.bgColor, width: 124 }}
                  >
                    <Flex
                      alignItems="center"
                      otherStyles={{ padding: '6px 10px' }}
                    >
                      <Icon name={score.icon} size={24} fill={score.color} />
                      <Flex
                        otherStyles={{
                          margin: '0 10px',
                          fontFamily: 'Open sans',
                          fontSize: 16,
                          fontWeight: 600,
                          color: score.color,
                        }}
                      >
                        {getScoringCategoryTranslation(score.id)}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex flexDirection="Column">
                    <Flex>
                      <Box margin="0 0 0 10px" width={170}>
                        <TextInput
                          value={score.min === MIN_MAX_DEFAULT ? '' : score.min}
                          handleOnChange={e => {
                            showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent =
                              false;
                            setShowPromptForAdjustScoring({
                              ...showPromptForAdjustScoring,
                            });
                            if (
                              e.target.value === '' ||
                              (numericExp.test(e.target.value) &&
                                parseInt(e.target.value, 10) <= totalScoring)
                            ) {
                              if (
                                index === 1 &&
                                (e.target.value === '' ||
                                  parseInt(e.target.value, 10) === 0)
                              ) {
                                setScoring(MIN_MAX_DEFAULT, 'min', index);
                              } else {
                                setScoring(e.target.value, 'min', index);
                              }
                            }
                          }}
                          rightHelperText={t('newCareplan_view.min')}
                          variant="value-right"
                          hasError={
                            totalScoring !== 0 && hasError[index].hasError
                          }
                          isDisabled={totalScoring === 0}
                          name={`${score.id}-min`}
                          placeholder=""
                        />
                      </Box>
                      <Box margin="0 0 0 10px" width={170}>
                        <TextInput
                          value={score.max === MIN_MAX_DEFAULT ? '' : score.max}
                          handleOnChange={e => {
                            showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent =
                              false;
                            setShowPromptForAdjustScoring({
                              ...showPromptForAdjustScoring,
                            });

                            if (
                              e.target.value === '' ||
                              (numericExp.test(e.target.value) &&
                                parseInt(e.target.value, 10) <= totalScoring)
                            ) {
                              if (
                                index === 1 &&
                                (e.target.value === '' ||
                                  parseInt(e.target.value, 10) === 0)
                              ) {
                                setScoring(MIN_MAX_DEFAULT, 'max', index);
                              } else {
                                setScoring(e.target.value, 'max', index);
                              }
                            }
                          }}
                          rightHelperText={t('newCareplan_view.max')}
                          variant="value-right"
                          hasError={
                            totalScoring !== 0 && hasError[index].hasError
                          }
                          isDisabled={totalScoring === 0}
                          name={`${score.id}-max`}
                          placeholder=""
                        />
                      </Box>
                    </Flex>
                    {hasError[index].hasError &&
                      hasError[index].message !==
                        errorTypes.UNDEFINED_RANGE && (
                        <Box
                          margin="8px 0 0 10px"
                          otherStyles={{
                            color: ActionColors.ERROR,
                            fontSize: 12,
                            fontFamily: 'Open sans',
                          }}
                        >
                          {getErrorTranslations(hasError[index].message)}
                        </Box>
                      )}
                  </Flex>
                </Flex>
              );
            }}
            actionsComponent={() => {
              return (
                <Box
                  className={`${
                    isScreenSmIpad(screenSize)
                      ? 'buttonDropDownAdditionalComponentForSmallScreen'
                      : ''
                  } 
                `}
                >
                  <ButtonDropdown
                    items={careplanComponentTypes.filter(c =>
                      availableScoreComponents.includes(c.key)
                    )}
                    buttonLabel={t('common_labels.label_addComponent')}
                    buttonType={ButtonType.BUTTON}
                    variant={ButtonVariant.OUTLINED}
                    labelHeader={t('common_buttons.questionType_choose')}
                    onClick={(event, item) =>
                      addScoreComponentHandler(event, item, score.id)
                    }
                    isDisabled={getAddComponentStatus(score) || hasOverallScore}
                  />
                </Box>
              );
            }}
            bodyComponent={() => {
              return (
                <Flex
                  flexDirection="column"
                  otherStyles={{
                    flex: 1,
                    margin: `${
                      isScreenSmIpad(screenSize) ? '0 0 0 0' : '0 0 0 114px'
                    }`,
                  }}
                >
                  <DnDLayoutProvider>
                    <CareplanComponents
                      additionalComponent={true}
                      componentCollection={componentCollection}
                      setComponentCollection={setComponentCollection}
                      setSettings={setSettings}
                      setTemplateSettings={setTemplateSettings}
                      searchTermComponents={searchTermComponents}
                      setSearchTermComponents={setSearchTermComponents}
                      updateSelectedComponent={updateSelectedComponent}
                      setCounter={setCounter}
                      setOpenPrompt={setOpenPrompt}
                      setTemplateNames={setTemplateNames}
                      isEditTemplate={isEditTemplate}
                      validationErrorTemplate={validationErrorTemplate}
                      hasValidationErrorTemplate={hasValidationErrorTemplate}
                      setOpenPromptForParentComponentDeletion={
                        setOpenPromptForParentComponentDeletion
                      }
                      setParentId={setParentId}
                      isEditable={isEditable}
                      setIsEditable={setIsEditable}
                      setShowPromptForAdjustScoring={
                        setShowPromptForAdjustScoring
                      }
                      showPromptForAdjustScoring={showPromptForAdjustScoring}
                      healthCategory={
                        score.id === ScoringTypes.Well
                          ? componentHealthCategories.WELL_QUESTION
                          : score.id === ScoringTypes.Fair
                          ? componentHealthCategories.FAIR_QUESTION
                          : componentHealthCategories.POOR_QUESTION
                      }
                    />
                  </DnDLayoutProvider>
                </Flex>
              );
            }}
            separatorComponent={() => {
              return index < scoringValues.length - 1 ? (
                <div
                  style={{
                    borderBottom: '1px solid #EBEBEB',
                    margin: '28px 28px 28px 134px',
                  }}
                ></div>
              ) : null;
            }}
          />
        </Flex>
      );
    });
  };

  return (
    <Box>
      <Flex className="careplanTemplateHealthIndicator" flexDirection="column">
        <Flex alignItems="center" otherStyles={{ marginBottom: 28 }}>
          <Flex
            otherStyles={{
              fontFamily: 'Open sans',
              fontSize: 16,
              marginRight: 24,
            }}
          >
            <Flex>
              {t('newCareplan_view.scoring_total')}
              <Box margin="0 0 0 5px">
                <Tooltip
                  content={
                    <Box width={200}>{t('newCareplan_view.scoring_info')}</Box>
                  }
                  position={Position.bottom}
                >
                  <Flex alignItems="center">
                    <Icon name="info" size={20} fill={Greys.DARK_GREY_70} />
                  </Flex>
                </Tooltip>
              </Box>
            </Flex>
          </Flex>
          <Flex otherStyles={{ width: '148px' }}>
            <TextInput
              value={totalScoring.toString()}
              handleOnChange={e => {}}
              isDisabled
              name="total-scoring"
              placeholder=""
            />
          </Flex>
          {totalScoring === 0 && (
            <Flex className="template__settings__scoring-validation">
              {t('newCareplan_view.scoring_scoresNotDefined')}
            </Flex>
          )}
          {shouldDisplayComplexScoreValidationMessage && (
            <Flex className="template__settings__scoring-validation">
              {t(
                'newCareplan_view.complex_score.validation.additional_components'
              )}
            </Flex>
          )}
        </Flex>
        {shouldShowError && (
          <Flex className="careplanTemplateHealthIndicatorError">
            <Icon
              name="exclamationCircle"
              size={16}
              fill={ActionColors.ERROR}
            />
            <Box otherStyles={{ marginLeft: 8 }}>
              {t('newCareplan_view.scoring_undefinedRange')}
            </Box>
          </Flex>
        )}
        {(nonMedical === false || nonMedical === 'false') && renderScores()}
      </Flex>
      <Box otherStyles={{ marginLeft: '64px' }}>
        {!isCareplanOrAssessment && totalScoring > 0 && (
          <Box>
            <Flex>
              {renderLabelWithToggleButton(
                t('common_labels.shareOverallScoreWithPatient')
              )}
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
}

ScoringSchemeComponent.propTypes = {
  scoringValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalScoring: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.func.isRequired,
};

export { ScoringSchemeComponent, scoringState, MIN_MAX_DEFAULT };
