import { useMemo, useRef, useState } from 'react';

import { checkIfCorrectDateInput } from './DatePickerInput/DatePickerInput.util';
import { DatePickerKeys } from '../../utils/dateUtils/dateUtil.type';
import { checkIfValidDate } from '../../utils/dateUtils/dateUtil';
import { checkIfPatientUnder18 } from 'src/Views/Patients/PatientFormModal/PatientFormModal.util';
import { DatePickerServiceProps } from './DatePicker.type';

const useDatePickerWithoutCalendarService = ({
  date,
  handleDate,
  error,
}: DatePickerServiceProps) => {
  const [errorList, setErrorList] = useState<DatePickerKeys[]>([]);
  const dayRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  const refs = {
    [DatePickerKeys.Day]: dayRef,
    [DatePickerKeys.Month]: monthRef,
    [DatePickerKeys.Year]: yearRef,
  };

  const onInput = (input: string, type: DatePickerKeys) => {
    const correctDate = checkIfCorrectDateInput(input, type);
    const dateCopy = { ...date };
    if (correctDate) {
      handleDate({
        ...dateCopy,
        [type]: input,
      });
    }
  };

  const inputList = [
    DatePickerKeys.Day,
    DatePickerKeys.Month,
    DatePickerKeys.Year,
  ];

  const errorMessage = useMemo(() => {
    const isUnder18 = checkIfPatientUnder18(date);
    const validDate = checkIfValidDate(date);

    if (error) {
      const errors: DatePickerKeys[] = [
        DatePickerKeys.Day,
        DatePickerKeys.Month,
        DatePickerKeys.Year,
      ].filter(key => date[key] === '');
      setErrorList(errors);

      if (errors.length > 0)
        return 'patients_view.warningMessage_dateOfBirth_incomplete';
      if (!validDate) return 'patients_view.warningMessage_dateOfBirth_invalid';
    }
    if (isUnder18 && validDate) return 'patients_view.warningMessage_dateOfBirth_under18';
    return '';
  }, [date, error]);

  return {
    inputList,
    onInput,
    errorMessage,
    errorList,
    refs,
  };
};

export default useDatePickerWithoutCalendarService;
