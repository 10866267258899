import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';

import { useCarePathWayBuilderStore } from '../CarePathWayBuilder.store';
import { SIDEBAR_CONTENT_HEIGHT } from '../CarePathWayBuilder.constant';

const usePanelSidebarService = () => {
  const contentRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const sidebar = useCarePathWayBuilderStore(state => state.sidebar);

  const updateDimensions = () => {
    setIsSmallScreen(contentRef.current?.clientHeight < SIDEBAR_CONTENT_HEIGHT);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const contentClasses = classNames({
    'panel-sidebar_content': true,
    'small-screen': isSmallScreen,
  });

  const updateSidebarVisibility = useCarePathWayBuilderStore(
    state => state.updateSidebarVisibility
  );

  const panelClasses = classNames({
    'panel-sidebar': true,
    'sidebar-visibility': sidebar.isVisible,
  });

  return {
    sidebar,
    contentRef,
    panelClasses,
    contentClasses,
    updateSidebarVisibility,
  };
};

export default usePanelSidebarService;
