/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

import { ApiClient, HideFlashMessage } from '@global/Services';

import Modal from '../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ModalFooter from '../../common/Layout/Modal/ModalFooter';
import {
  getIcdsOrOpsByIds,
  fetchIcdsOrOpsData,
} from '../../../Utils/PatientOverview/patientOverviewHelper';
import PatientFormContent from '../PatientFormContent';

import validateFirstnameLastname from '../../../Utils/hooks/validateFirstnameLastname';
import IcdOps from '../../../types/Common/IcdOps.type';
import {
  checkIfValidDate,
  extractDateFromDateObject,
} from '../../../../caro-ui-commonfiles/utils/dateUtils/dateUtil';
import actions from '../../../Utils/actions';
import serviceConfig from '../../../../serviceConfig.json';
import {
  checkIfPatientUnder18,
  extractBirthDateObjectFromPatient,
} from './PatientFormModal.util';
import { isEmptyString } from '../../../Global/Strings';

import {
  DatePickerDateType,
  DatePickerKeys,
} from '../../../../caro-ui-commonfiles/utils/dateUtils/dateUtil.type';
import { PatientFormModalProps } from './PatientFormModal.type';

import '../../../css/patient.css';

const PatientFormContext = React.createContext(null);

function PatientFormModal({
  open,
  onClose,
  onCreate,
  onUpdate,
  data,
  operation,
  loading,
}: PatientFormModalProps) {
  const [icds, setIcds] = useState([]);
  const [ops, setOps] = useState([]);
  const [allIcds, setAllIcds] = useState([]);
  const [allOps, setAllOps] = useState([]);
  const [icdsLength, setIcdsLength] = useState(0);
  const [opsLength, setOpsLength] = useState(0);
  const [fetchedIcds, setFetchedIcds] = useState<IcdOps[]>([]);
  const [fetchedOps, setFetchedOps] = useState<IcdOps[]>([]);
  const [allSites, setAllSites] = useState([]);
  const [birthDate, setBirthDate] = useState<DatePickerDateType>({
    [DatePickerKeys.Day]: '',
    [DatePickerKeys.Month]: '',
    [DatePickerKeys.Year]: '',
  });

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    medicalRecord: '',
    pseudonymCode: '',
    birthDate: null,
    email: '',
    mobile: '',
    gender: null,
    room: '',
    ward: '',
    bed: '',
    inHousePatient: false,
    currentSite: null,
    profilePicture: null,
    address: '',
    city: '',
    postalCode: '',
    country: '',
    InsuranceType: '',
    insuranceTypes: [],
    InsuranceCompany: '',
    InsuranceNumber: '',
    caseNumber: '',
  });
  const isPatientDemographicData = data.patient
    ? data.patient.isPatientDemographicData
    : false;

  const [hasErrors, setHasErrors] = useState({
    firstNameIsEmpty: false,
    firstNameIsInvalid: false,
    lastNameIsEmpty: false,
    lastNameIsInvalid: false,
    medicalRecordIsEmpty: false,
    medicalRecord: false,
    pseudonymCode: false,
    patientIdIsInvalid: false,
    birthDate: false,
    email: false,
    mobile: false,
    gender: false,
    room: false,
    ward: false,
    bed: false,
    currentSite: false,
    profilePicture: false,
    address: false,
    city: false,
    postalCode: false,
    country: false,
    InsuranceType: false,
    InsuranceCompany: false,
    InsuranceNumber: false,
    caseNumber: false,
  });

  const validateEmail = () => {
    const emailFormat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (values.email.length > 0) {
      return !emailFormat.test(String(values.email).toLowerCase());
    }
    return false;
  };

  const validatePseudonymCodeAndMedicalRecord = value => {
    const inputFormat = /^[a-zA-Z0-9-_]+$/g;
    if (value.length > 0) {
      return !inputFormat.test(String(value));
    }
    return false;
  };

  const validateMedicalRecord = async (medicalRecord: string) => {
    const response = await ApiClient.POST({
      url:
        serviceConfig.patientService.checkMedicalIdExists.uri + medicalRecord,
    });
    if (response && response.data && response.data !== '') {
      if (response.data.id !== data.patientId)
        return medicalRecord !== response.data.medicalRecord;
    }
    return true;
  };

  const isInsuranceTypeInvalid = () => {
    const { InsuranceType, insuranceTypes } = values;
    const isCreateOperation = operation === actions.Create;
    const isEditOperation = operation === actions.Edit;

    if (!InsuranceType || isCreateOperation) return false;
    if (isEditOperation) {
      const isInsuranceTypeMatch = insuranceTypes?.some(
        insuranceType =>
          insuranceType.label === InsuranceType ||
          insuranceType.value === InsuranceType.value
      );
      return !isInsuranceTypeMatch;
    }
    return true;
  };
  

  const validateForm = async () => {
    const formErrors = {
      ...hasErrors,
      birthDate:
        !checkIfValidDate(birthDate) || checkIfPatientUnder18(birthDate),
      medicalRecord: !(await validateMedicalRecord(values.medicalRecord)),
      medicalRecordIsEmpty:
        operation === actions.Edit && isEmptyString(values.medicalRecord),
      email: validateEmail(),
      pseudonymCode: validatePseudonymCodeAndMedicalRecord(
        values.pseudonymCode
      ),
      patientIdIsInvalid: validatePseudonymCodeAndMedicalRecord(
        values.medicalRecord
      ),
      mobile:
        values.mobile &&
        values.mobile.length > 0 &&
        !parsePhoneNumberFromString(values.mobile),
      InsuranceType: isInsuranceTypeInvalid(),
    };
    if (!isPatientDemographicData) {
      formErrors.firstNameIsEmpty = isEmptyString(values.firstName);
      formErrors.firstNameIsInvalid = validateFirstnameLastname(
        values.firstName.trim()
      );
      formErrors.lastNameIsEmpty = isEmptyString(values.lastName);
      formErrors.lastNameIsInvalid = validateFirstnameLastname(
        values.lastName.trim()
      );
    }
    setHasErrors(formErrors);
    return !Object.keys(formErrors).some(k => formErrors[k]);
  };

  const cleanUp = () => {
    setIcds([]);
    setOps([]);
    setAllIcds([]);
    setAllOps([]);
    setAllSites([]);
    setIcdsLength(0);
    setOpsLength(0);
    setFetchedIcds([]);
    setFetchedOps([]);
    setBirthDate({
      [DatePickerKeys.Day]: '',
      [DatePickerKeys.Month]: '',
      [DatePickerKeys.Year]: '',
    });
    setValues({
      firstName: '',
      lastName: '',
      medicalRecord: '',
      pseudonymCode: '',
      birthDate: null,
      email: '',
      mobile: '',
      gender: null,
      room: '',
      ward: '',
      bed: '',
      inHousePatient: false,
      profilePicture: null,
      address: '',
      city: '',
      postalCode: '',
      country: '',
      InsuranceType: '',
      InsuranceCompany: '',
      InsuranceNumber: '',
      caseNumber: '',
    });
    setHasErrors({
      firstNameIsEmpty: false,
      firstNameIsInvalid: false,
      lastNameIsEmpty: false,
      lastNameIsInvalid: false,
      medicalRecordIsEmpty: false,
      medicalRecord: false,
      pseudonymCode: false,
      patientIdIsInvalid: false,
      birthDate: false,
      email: false,
      mobile: false,
      gender: false,
      room: false,
      ward: false,
      bed: false,
      currentSite: false,
      profilePicture: false,
      address: false,
      city: false,
      postalCode: false,
      country: false,
      InsuranceType: false,
      InsuranceCompany: false,
      InsuranceNumber: false,
      caseNumber: false,
    });
  };

  const createPatient = () => {
    onCreate({
      ...values,
      selectedICDs: allIcds,
      selectedOps: allOps,
      currentSite: allSites,
      componentURL: '/patients/add',
    });
  };

  const updatePatient = () => {
    const birthDateCopy = isPatientDemographicData
      ? undefined
      : values.birthDate;
    onUpdate({
      patientId: data.patientId,
      body: {
        selectedICDs: allIcds,
        selectedOps: allOps,
        componentURL: '/patients/add',
        ...values,
        birthDate: birthDateCopy,
      },
    });
  };

  useEffect(() => {
    const initModal = async () => {
      if (isPatientDemographicData) {
        data.patient.firstName = undefined;
        data.patient.lastName = undefined;
      }
      setValues(data.patient);
      if (data.patient.birthDate && !isPatientDemographicData)
        setBirthDate(extractBirthDateObjectFromPatient(data.patient));
      setIcdsLength(data.icdsMaxLength);
      setOpsLength(data.icdsMaxLength);
      if (data.sites && data.sites.length > 0) setAllSites(data.sites);
      if (data.patientIcds && data.patientIcds.length > 0) {
        getIcdsOrOpsByIds(data.patientIcds, true, setFetchedIcds);
      }
      if (data.patientOps && data.patientOps.length > 0) {
        getIcdsOrOpsByIds(data.patientOps, false, setFetchedOps);
      }
    };
    if (open) initModal();
  }, [data]);

  const handleFormSubmit = async event => {
    event.preventDefault();

    if (await validateForm()) {
      if (operation === actions.Create) {
        createPatient();
      } else if (operation === actions.Edit) {
        updatePatient();
      }
    }
  };

  useEffect(() => {
    const validBirthDate = checkIfValidDate(birthDate);
    if (validBirthDate) {
      setValues(prevValues => ({
        ...prevValues,
        birthDate: extractDateFromDateObject(birthDate),
      }));
      setHasErrors(prevHasErrors => ({
        ...prevHasErrors,
        birthDate: false,
      }));
    }
  }, [birthDate]);

  return (
    <PatientFormContext.Provider
      value={{
        values,
        setValues,
        hasErrors,
        setHasErrors,
        allSites,
        icdsLength,
        opsLength,
        fetchedIcds,
        fetchedOps,
        setAllIcds,
        setAllOps,
        birthDate,
        setBirthDate,
      }}
    >
      <Modal
        title={
          operation === actions.Create
            ? I18n.t('patients_view.label_addPatient')
            : operation === actions.Edit &&
            `${I18n.t('patients_view.label_editPatient')}: ${isPatientDemographicData
              ? values.medicalRecord
              : `${values.firstName} ${values.lastName}`
            }`
        }
        contentComponent={
          <PatientFormContent
            action={operation}
            onSubmit={handleFormSubmit}
            fetchIcdsOrOpsData={fetchIcdsOrOpsData}
            loading={loading}
            operation={operation}
          />
        }
        footerComponent={
          <ModalFooter
            close={onClose}
            labelCTA={
              operation === actions.Edit
                ? I18n.t('common_buttons.update')
                : I18n.t('common_buttons.save')
            }
            form="form__add-patient"
          />
        }
        openModal={open}
        onClose={() => {
          cleanUp();
          onClose();
        }}
        hideFlashMessage={HideFlashMessage}
      />
    </PatientFormContext.Provider>
  );
}

export { PatientFormModal, PatientFormContext };
