import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import I18n from 'i18next';
import {
  browserName,
  deviceDetect,
  isIOS,
  isMobile,
  isTablet,
} from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ApiClient, Logger } from '@global/Services';

import serviceConfig from '../../../serviceConfig.json';
import NavigationJSON from '../../Utils/navigation.json';

import { FirebaseContext } from '../../Utils/Firebase/firebase';
import { setCurrentUser } from '../../../reducers/session';
import { onFilterSelection } from '../../../actions/Datatables/patientFilter';
import { getServices } from '../../../thunks/services';
import LanguageItems from '../../types/Common/LanguageItems.enum';

const useLoginService = () => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errMsgKey, setErrMsgKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLanguageChanged, setIsLanguageChanged] = useState(false);
  const {
    setIsLoggedIn,
    setUserPinAndOptinsInfo,
    userPinAndOptinsInfo,
    setHidePatientDemographics,
  } = useContext(FirebaseContext);
  const forgotPasswordHandler = () => history.push(`/${NavigationJSON.FORGOT}`);
  const onChangePasswordText = event => setPassword(event.target.value);
  const onChangeUsernameText = event => setUsername(event.target.value);

  const dispatch = useDispatch();
  const { savedFilter } = useSelector(state => state.patientFilters);

  useEffect(() => {
    localStorage.clear();
    let queryParams = new URLSearchParams(history.location.search);
    const changedLanguage = queryParams.get('setLng');
    if (changedLanguage) {
      setIsLanguageChanged(true);
    }
  }, []);

  async function getDefaultFilter() {
    try {
      dispatch(getServices());
      const { allFilters, defaultFilter } = (
        await axios.get(serviceConfig.patientService.index.uri)
      ).data;
      const filters = allFilters.map(filter => ({
        key: filter.name,
        value: filter.name,
        rule: filter.rule,
        filterId: filter.id,
      }));

      if (filters.length > 0) {
        const defaultFilterIndex: number = defaultFilter
          ? allFilters.findIndex(filter => filter.id === defaultFilter.id)
          : savedFilter.index;

        dispatch(
          onFilterSelection({
            allFilters: filters,
            id: filters[defaultFilterIndex].filterId,
            index: defaultFilterIndex,
            name: filters[defaultFilterIndex].key,
            rule: filters[defaultFilterIndex].rule,
          })
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  const SubmitLoginForm = async () => {
    try {
      setIsLoading(true);
      const pinAndOptinsInfo = userPinAndOptinsInfo;
      pinAndOptinsInfo.logoutFlag = false;
      delete pinAndOptinsInfo.type;
      delete pinAndOptinsInfo.content;
      setUserPinAndOptinsInfo({ ...pinAndOptinsInfo });
      const sessionInformation = deviceDetect();
      if ((isTablet || isMobile) && isIOS === false) {
        sessionInformation.browserName = browserName;
        sessionInformation.osName = `${sessionInformation.os} ${sessionInformation.osVersion}`;
      }
      const userLanguage = Object.values(LanguageItems).find(
        key => I18n.language.slice(0, 2) === key.slice(0, 2)
      );
      const userObject = {
        username,
        password,
        sessionInformation: {
          ...sessionInformation,
        },
        userLanguage: userLanguage || 'en-US',
      };
      const loginResult = await ApiClient.POST({
        url: serviceConfig.brokerService.logIn.uri,
        payload: userObject,
      });
      const user = loginResult.data.user[0];
      const isActive = loginResult.data.user[1];
      const isBlocked = loginResult.data.user[2];
      const sessionId = loginResult.data.user[5];
      const isPinVerified = loginResult.data.user[6];

      if (isActive === false || isBlocked === true) {
        history.push(`/${NavigationJSON.DEACTIVATED}`);
      } else if (!user) {
        setIsLoading(false);
        setErrMsgKey('common_labels.incorrect_user_password');
      } else {
        pinAndOptinsInfo.isPinVerified = isPinVerified;
        pinAndOptinsInfo.isTermsAndConditions = user.isTermsAndConditions;
        pinAndOptinsInfo.isPrivacyPolicy = user.isPrivacyPolicy;
        pinAndOptinsInfo.isTwoFactorAuthentication =
          user.isTwoFactorAuthentication;
        pinAndOptinsInfo.isActive = user.isActive;
        setHidePatientDemographics(user.role.isPatientDemographicData);
        setUserPinAndOptinsInfo({ ...pinAndOptinsInfo });
        if (user.profilePicture.id) {
          user.profilePicture = user.profilePicture.directLink;
        } else {
          user.profilePicture = `data:image/jpeg;base64,${user.profilePicture}`;
        }
        if (user.siteLogo.id) {
          user.siteLogo = user.siteLogo.directLink;
        } else {
          user.siteLogo = `data:image/jpeg;base64,${user.siteLogo}`;
        }

        delete user.isTwoFactorAuthentication;
        delete user.isPrivacyPolicy;
        delete user.isTermsAndConditions;
        user.sessionId = sessionId;
        dispatch(setCurrentUser(user));
        Logger.setUser({
          UUID: user.uuid,
          SessionId: sessionId,
          Username: user.username,
          RoleName: user.role.name,
          DemographicData: user.role.isPatientDemographicData,
        });
        const language = isLanguageChanged
          ? I18n.language
          : user.languagePreference;
        const response = await ApiClient.POST({
          url: serviceConfig.brokerService.updateUserLanguagePreference.uri,
          payload: {
            languagePreference: language,
          },
        });

        const { type, userLanguagePreference } = response.data;
        const userLanguage =
          type === 'success' ? userLanguagePreference : I18n.language;
        I18n.changeLanguage(userLanguage);
        user.languagePreference = userLanguage;

        dispatch(
          setCurrentUser({
            ...user,
            languagePreference: userLanguage,
          })
        );
        setIsLoggedIn(true);
        await getDefaultFilter();
        if (
          !userPinAndOptinsInfo.isPrivacyPolicy ||
          !userPinAndOptinsInfo.isTermsAndConditions
        ) {
          history.push(`/${NavigationJSON.OPTINS}`);
        } else if (
          userPinAndOptinsInfo.isTwoFactorAuthentication &&
          isPinVerified === false
        ) {
          history.push(`/${NavigationJSON.VERIFICATION}`);
        } else if (
          history.location.state &&
          history.location.state.state &&
          history.location.state.state.deepLink &&
          user.id === parseInt(history.location.state.state.userId, 10) &&
          !history.location.state.state.type
        ) {
          const { pathname, state } = history.location.state;
          history.push(pathname, state);
        } else {
          history.push(`/${NavigationJSON.VERIFICATION}`);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    setErrMsgKey('');
    if (!username && !password) {
      setErrMsgKey('common_labels.empty_username_password');
    } else if (!username) {
      setErrMsgKey('common_labels.empty_username');
    } else if (!password) {
      setErrMsgKey('common_labels.empty_password');
    } else {
      SubmitLoginForm();
    }
  };

  const handleChangeInstance = isOnDesktop => {
    if (isOnDesktop) {
      const { ipcRenderer } = window;
      ipcRenderer.send('redirectToLandingPage', I18n.language);
    }
  };

  return {
    handleFormSubmit,
    forgotPasswordHandler,
    username,
    password,
    errMsgKey,
    onChangeUsernameText,
    onChangePasswordText,
    isLoading,
    setIsLanguageChanged,
    handleChangeInstance,
  };
};

export default useLoginService;
