import { StartTimeType } from './CareTaskNode.type';

export const getReferenceDatePreposition = (startText: string) => {
  return {
    isAfterReferenceDate: startText.includes('after'),
    isBeforeReferenceDate: startText.includes('before'),
  };
};

export const formatTime = (startTime: StartTimeType) => {
  const hour = startTime?.hour ?? 0;
  const minute = startTime?.minute ?? 0;

  return hour === 0 && minute === 0
    ? '00:00'
    : `${('0' + hour).slice(-2)}:${('0' + minute).slice(-2)}`;
};
