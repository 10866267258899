import React from 'react';
import '../../styles/main.scss';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import Icon from '../Icon/Icon';
import DropdownMenu from './DropdownMenu';
import Button from '../Button/Button';
import { ButtonType, ButtonVariant } from '../../utils/button';

const ButtonDropdown = ({
  items,
  buttonLabel,
  buttonType,
  variant,
  labelHeader,
  onClick,
  isDisabled = false,
}) => {
  const buildItems = () => {
    const result = items.map(function (item, index) {
      return (
        <li key={item.key} onClick={e => onClick(e, item)}>
          {item.icon && (
            <span className="dropdown__icon-box">
              <Icon
                name={item.icon}
                size={20}
                fill="#ffffff"
                className="dropdown_icon-left"
              />
            </span>
          )}
          <span className="dropdown__item">{I18n.t(item.value)}</span>
        </li>
      );
    });
    const header = labelHeader ? (
      <li key="header" className="button-dropdown__header-info">
        <span>{labelHeader}</span>
      </li>
    ) : null;

    return [header, ...result];
  };

  return (
    <DropdownMenu
      items={buildItems()}
      variant={labelHeader ? 'button-dropdown' : ''}
      shouldOptionsBeFullWidth={!labelHeader}
    >
      <Button
        label={buttonLabel}
        type={buttonType}
        variant={variant}
        disabled={isDisabled}
      />
    </DropdownMenu>
  );
};
ButtonDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  labelHeader: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  buttonType: PropTypes.oneOf([
    ButtonType.BUTTON,
    ButtonType.SUBMIT,
    ButtonType.RESET,
  ]),
  variant: PropTypes.oneOf([ButtonVariant.CONTAINED, ButtonVariant.OUTLINED]),
  buttonLabel: PropTypes.string.isRequired,
};

export default ButtonDropdown;
