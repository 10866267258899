import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FirebaseContext } from '../Utils/Firebase/firebase';
import logOut from '../Utils/logOut';

import Box from '../../caro-ui-commonfiles/components/Box/Box';

import '../css/login.scss';

import LoginHeader from './Login/LoginHeader';
import LoginFooter from './Login/LoginFooter';

const Deactivated = () => {
  const history = useHistory();

  const { setIsLoggedIn } = useContext(FirebaseContext);

  const { user } = useSelector(state => state.session);

  const handleLogOut = () => logOut(setIsLoggedIn, history, user);

  return (
    <Box width="100%" height="100%">
      <div className="login-container">
        <LoginHeader handleGoHome={handleLogOut} />

        <div className="login-content-container">
          <div className="credential-container">
            <div className="login__widget-panel__container">
              <div className="login-header__wrapper">
                <span className="login-header">
                  {I18n.t('common_labels.label_deactivated')}
                </span>
              </div>

              <div className="login-info-container login__text-rows-spacing">
                <span>{I18n.t('common_labels.deactivated_explanation')}</span>
              </div>

              <div className="login-info-container">
                <span>{I18n.t('common_labels.deactivated_cta')}</span>
              </div>
            </div>

            <LoginFooter />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default withTranslation()(Deactivated);
