import React, {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import I18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import {
  Flex,
  Box,
  Icon,
  TextInput,
  IconButton,
  Button,
} from '@ui-common-files/components';

import {
  Greys,
  PrimaryColor,
  ComponentTranslationKeys,
  ComponentTypes,
  inputField,
  MaxCharLimits,
  ButtonVariant,
  ButtonType,
} from '@ui-common-files/utils';

import { ApiClient } from '@global/Services';
import useAnalytics from '@global/Services/Analytics/UseAnalytics';

import TodoCards from '../TodoCards';

import {
  AppointmentCards,
  AssessmentCards,
  CareplanCards,
  MedicationCards,
} from '../AssignmentCards';
import OverviewDataCard from '../Overview';

import CarePathwayCardsList from '../CarePathwayCardsList';
import CareplanResultsModalContent from '../../Careplans/CareplanResultsModalContent';
import CareplanResultsModalFooter from '../../Careplans/CareplanResultsModalFooter';

import serviceConfig from '../../../../serviceConfig.json';
import { PatientOverviewContext } from '../../PatientOverview/PatientOverview';
import CarepathwayTriggeredResult from '../CarepathwayTriggeredResult/CarepathwayTriggeredResult';
import { selectTodos } from '../../Notifications/Notification.reducer';
import { getTodosCount } from '../TodoCards/TodoCards.api';
import PatientOverviewCalendar from '../../PatientOverview/PatientOverviewCalendar';
import Assets from '../../Datatables/Assets';
import OncoboxImportedData from 'src/Views/PatientOverview/OncoboxImportedData/OncoboxImportedData';

import useAssignmentCardViewerService from './AssignmentCardViewer.service';

import { toUpperSnakeCase } from '@global/Strings';

import '../../../css/patientOverview.scss';

function AssignmentCardViewer({
  patientId,
  componentDataCount,
  setFlashMessage,
  isChatOpened,
  setShowCareplanResults,
  patientInfo,
  answeredCaretask,
}) {
  const history = useHistory();
  const { trackEventTrigger } = useAnalytics();
  const reduxDispatcher = useDispatch();
  const stateData = history.location.state ? history.location.state : {};
  const services = useSelector(state => state.services);
  const {
    landingTab,
    setLandingTab,
    setPatientSectionalModal,
    renderExportAnswersModal,
    setActiveTabAndReload,
    CHAT_BOX_OFFSET,
  } = useContext(PatientOverviewContext);
  const [tabsList, setTabsList] = useState([]);
  const [medicationCards, setMedicationCards] = useState(null);
  const [taskCards, setTaskCards] = useState(null);
  const [appointmentCards, setAppointmentCards] = useState(null);
  const [cardViewerHeight, setCardViewerHeight] = useState(0);
  const [assessmentCards, setAssessmentCards] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const assignmentCardContainer = useRef(null);
  const careplanSearchDefaultValue: string = !stateData.isCarepathway
    ? stateData.careplanName || ''
    : '';

  const [careplanSearchValue, setCareplanSearchValue] = useState(
    careplanSearchDefaultValue
  );

  const carePathwaySearchDefaultValue: string = stateData.isCarepathway
    ? stateData.carePathwayName || ''
    : '';

  const [carePathwaySearchValue, setcarePathwaySearchValue] = useState(
    carePathwaySearchDefaultValue
  );
  const [todoSearchValue, setTodoSearchValue] = useState('');

  const [reloadTable, setReloadTable] = useState(false);
  const [reloadChat, setReloadChat] = useState(false);

  const todos = useSelector(selectTodos);

  const {
    displayNavigationArrow,
    navigationItems,
    handleNext,
    handlePrev,
    navigationBarWidth,
  } = useAssignmentCardViewerService(tabsList, isChatOpened, windowWidth);

  const patient = [
    {
      id: patientInfo.id,
      firstname: patientInfo.firstname,
      lastname: patientInfo.lastname,
      profilePicture: patientInfo.profilePicture,
      birthdate: patientInfo.birthdate,
      age: moment().diff(patientInfo.birthdate, 'years'),
      patientAppts: patientInfo.patientAppts,
      patientApptsIcds: patientInfo.patientApptsIcds,
    },
  ];

  const { t } = useTranslation();

  useEffect(() => {
    if (stateData.careplanName?.length > 0)
      setCareplanSearchValue(stateData.careplanName);
  }, [stateData.careplanName]);

  useEffect(() => {
    if (stateData.carePathwayName?.length > 0)
      setcarePathwaySearchValue(stateData.carePathwayName);
  }, [stateData.carePathwayName]);

  const getCareplanAnswers = async ({ careplanId, answerTimestamp }) => {
    try {
      const response = await axios.get(
        `${serviceConfig.brokerService.getCareplanAnswers.uri}${careplanId}/${answerTimestamp}`
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  const showCareplanAnswers = careplanData => {
    setPatientSectionalModal({
      isOpen: true,
      title: `${t('common_labels.label_results')}: ${careplanData.qc.name}`,
      content: (
        <CareplanResultsModalContent
          careplanData={[careplanData]}
          chosenDate={stateData.answerTimestamp}
        />
      ),
      footer: <CareplanResultsModalFooter careplanData={[careplanData]} />,
    });
  };

  const changeThresoldStatus = async (
    answers: Array<Object>,
    patientId: number
  ) => {
    const answerIds = answers.map(answerElem => answerElem.answer.id);
    ApiClient.POST({
      url: serviceConfig.brokerService.clearCareplanThresholdNotifications.uri,
      payload: {
        answerIds,
        careplan: stateData,
        patientId,
      },
    });
  };

  const clearThresholdSeen = (shouldShowAnswers = false) => {
    const { careplanId, answerTimestamp } = stateData;
    getCareplanAnswers({ careplanId, answerTimestamp }).then(answers => {
      if (shouldShowAnswers) {
        showCareplanAnswers(answers);
      }
      if (stateData?.seen) {
        changeThresoldStatus(answers.mapQuesAnswers, answers.patient.id);
      }
    });
  };

  const fetchCarePathwayAnswersAndShowModal = () => {
    if (!!stateData.caretaskParentId) {
      setPatientSectionalModal({
        isOpen: true,
        title: `${t('common_labels.label_results')}: ${stateData.careplanName}`,
        content: (
          <CarepathwayTriggeredResult
            caretaskId={stateData.caretaskParentId}
            caretaskIdTriggeredThreshold={stateData.careplanId}
            answerTimestamp={
              stateData.rootAnswerTimestamp || stateData.answerTimestamp
            }
          />
        ),
        footer: null,
      });
      clearThresholdSeen();
    } else {
      clearThresholdSeen(true);
    }
  };

  const setAssignmentCards = useCallback(
    data => {
      switch (landingTab) {
        case ComponentTypes.MEDICATION:
          if (services.hasOwnProperty('medicationservice.myoncare.care')) {
            setMedicationCards(
              <MedicationCards data={data} setCardCount={setTabsList} />
            );
          }
          break;
        case ComponentTypes.APPOINTMENT:
          if (services.hasOwnProperty('appointmentservice.myoncare.care')) {
            setAppointmentCards(
              <AppointmentCards data={data} patientId={patientId} />
            );
          }
          break;
        case ComponentTypes.ASSESSMENT:
          if (services.hasOwnProperty('careplanservice.myoncare.care')) {
            setAssessmentCards(<AssessmentCards data={data} />);
          }
          break;
        case ComponentTypes.OVERVIEW: {
          setTaskCards(<OverviewDataCard />);
          break;
        }
        default:
          break;
      }
    },
    [landingTab, services, I18n.language]
  );

  const showLoader = () => {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        otherStyles={{
          top: '60%',
          left: isChatOpened ? '44%' : '50.3%',
          position: 'fixed',
        }}
      >
        <Loader
          type="Oval"
          color={PrimaryColor.MAIN_COLOR}
          height={60}
          width={60}
        />
      </Flex>
    );
  };

  const getData = async () => {
    let route;
    switch (landingTab) {
      case ComponentTypes.MEDICATION:
        route = serviceConfig.brokerService.getMedicationDetails.uri;
        break;
      case ComponentTypes.APPOINTMENT:
        route = serviceConfig.brokerService.getAppointmentDetails.uri;
        break;
      case ComponentTypes.OVERVIEW:
        route = serviceConfig.brokerService.getAppointmentDetails.uri;
        break;
      default:
        break;
    }
    if (services && route) {
      setAssessmentCards(showLoader());
      setMedicationCards(showLoader());
      setTaskCards(showLoader());
      setAppointmentCards(showLoader());

      const payload = {
        patientId,
      };

      ApiClient.GET({
        url: route,
        payload,
      }).then(async result => {
        if (result?.data) {
          setAssignmentCards(result.data);
        }
      });
    }
  };

  const inputValue = useMemo(() => {
    switch (landingTab) {
      case ComponentTypes.CAREPLAN:
        return careplanSearchValue;
      case ComponentTypes.CAREPATHWAYS:
        return carePathwaySearchValue;
      case ComponentTypes.TODOS:
        return todoSearchValue;
      default:
        return '';
    }
  }, [
    landingTab,
    careplanSearchValue,
    carePathwaySearchValue,
    todoSearchValue,
  ]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    const text = e.target.value;
    switch (landingTab) {
      case ComponentTypes.CAREPLAN:
        setCareplanSearchValue(text);
        break;
      case ComponentTypes.CAREPATHWAYS:
        setcarePathwaySearchValue(text);
        break;
      case ComponentTypes.TODOS:
        setTodoSearchValue(text);
        break;
      default:
        break;
    }
  };

  const setViewerHeight = () => {
    setCardViewerHeight(
      window.innerHeight -
        assignmentCardContainer.current?.getBoundingClientRect().top
    );
  };

  const updateCardCount = (component: ComponentTypes, total: number) => {
    setTabsList(oldCards => {
      const newCards = oldCards.map(card => {
        if (card.component === component) return { ...card, total };
        return card;
      });
      return newCards;
    });
  };

  useEffect(() => {
    if (
      landingTab &&
      landingTab !== ComponentTypes.CAREPLAN &&
      landingTab !== ComponentTypes.CAREPATHWAYS
    )
      getData();
  }, [landingTab]);

  useEffect(() => {
    setTabsList(componentDataCount);
    setViewerHeight();
  }, [componentDataCount]);

  useEffect(() => {
    setViewerHeight();
  }, [isChatOpened, assignmentCardContainer.current]);

  useEffect(() => {
    window.addEventListener('resize', setViewerHeight);
    return function cleanup() {
      window.removeEventListener('resize', setViewerHeight);
    };
  }, []);

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    if (services.hasOwnProperty('careplanservice.myoncare.care')) {
      if (
        landingTab !== ComponentTypes.CAREPLAN &&
        stateData.careplanId &&
        stateData.answerTimestamp &&
        !stateData.isCarepathway &&
        !stateData.isAssessment
      ) {
        setLandingTab(ComponentTypes.CAREPLAN);
      } else if (
        stateData.isAssessment &&
        stateData.redirectTo === ComponentTypes.TODOS
      ) {
        setLandingTab(ComponentTypes.TODOS);
      } else if (
        landingTab !== ComponentTypes.CAREPATHWAYS &&
        stateData.careplanId &&
        stateData.answerTimestamp &&
        stateData.isCarepathway
      ) {
        setLandingTab(ComponentTypes.CAREPATHWAYS);
      } else if (
        (landingTab !== ComponentTypes.ASSESSMENT && stateData.isAssessment) ||
        (answeredCaretask && landingTab !== ComponentTypes.TODOS)
      ) {
        setLandingTab(ComponentTypes.CAREPLAN);
        getData();
      } else if (stateData.refreshCareplanCardViewer) {
        getData();
      }
      if (stateData.answerTimestamp) {
        stateData.isCarepathway
          ? fetchCarePathwayAnswersAndShowModal()
          : setShowCareplanResults(stateData);
      }
    }
  }, [JSON.stringify(stateData), answeredCaretask]);

  useEffect(() => {
    getTodosCount(patientId).then(({ data: { count } }) =>
      updateCardCount(ComponentTypes.TODOS, count)
    );
  }, [todos]);

  const renderTabs = () =>
    navigationItems.map(componentTotal => {
      let icon;
      const buttonClass = classNames('component-tab-container', {
        'component-tab-container--active':
          landingTab === componentTotal.component,
      });
      if (componentTotal) {
        icon =
          componentTotal.component === ComponentTypes.TODOS
            ? 'Assessment'
            : componentTotal.component;

        return (
          <Button
            className={buttonClass}
            type={ButtonType.BUTTON}
            onClick={() => {
              trackEventTrigger({
                category: 'patientoverview',
                action: `${componentTotal.component}`,
              });
              setLandingTab(componentTotal.component);
            }}
          >
            <Flex>
              <Icon
                name={icon}
                size={24}
                fill={
                  landingTab !== componentTotal.component
                    ? Greys.DARK_GREY
                    : PrimaryColor.MAIN_COLOR
                }
              />
            </Flex>
            <Box
              className={
                landingTab !== componentTotal.component
                  ? 'inactive-component'
                  : 'active-component'
              }
            >
              <Flex otherStyles={{ gap: '8px' }}>
                <Box className="component-name">
                  {t(
                    ComponentTranslationKeys[
                      toUpperSnakeCase(componentTotal.component)
                    ]
                  )}
                </Box>

                {componentTotal.component !== ComponentTypes.OVERVIEW &&
                  componentTotal.component !== ComponentTypes.ASSETS &&
                  componentTotal.component !== ComponentTypes.CALENDAR &&
                  componentTotal.component !==
                    ComponentTypes.ONCOBOX_IMPORTED_DATA && (
                    <Box className="component-total">
                      {componentTotal.total}
                    </Box>
                  )}
              </Flex>
            </Box>
          </Button>
        );
      }
    });

  return (
    <div id="bannerContainer">
      <Box
        className="component-nav-tab-container"
        otherStyles={{ width: `${navigationBarWidth}%` }}
      >
        <Box
          otherStyles={{
            cursor: 'pointer',
            display: displayNavigationArrow ? 'block' : 'none',
          }}
        >
          <IconButton
            name="arrowLeft"
            size={20}
            onClick={handlePrev}
            disabled={tabsList[0] === navigationItems[0]}
          />
        </Box>
        {renderTabs()}
        <Box
          otherStyles={{
            cursor: 'pointer',
            display: displayNavigationArrow ? 'block' : 'none',
          }}
        >
          <IconButton
            name="arrowRight"
            size={20}
            onClick={handleNext}
            disabled={
              tabsList[tabsList.length - 1] ===
              navigationItems[navigationItems.length - 1]
            }
          />
        </Box>
      </Box>

      <div
        style={{
          maxHeight: cardViewerHeight,
          overflowY:
            landingTab !== ComponentTypes.CAREPLAN &&
            landingTab !== ComponentTypes.CAREPATHWAYS &&
            landingTab !== ComponentTypes.CALENDAR &&
            landingTab !== ComponentTypes.ASSETS &&
            landingTab !== ComponentTypes.ONCOBOX_IMPORTED_DATA
              ? 'auto'
              : null,
          paddingRight: 10,
          marginTop: landingTab === ComponentTypes.OVERVIEW ? null : 30,
          overflowX:
            landingTab !== ComponentTypes.CAREPLAN &&
            landingTab !== ComponentTypes.CAREPATHWAYS &&
            landingTab !== ComponentTypes.CALENDAR &&
            landingTab !== ComponentTypes.ASSETS &&
            landingTab !== ComponentTypes.ONCOBOX_IMPORTED_DATA
              ? 'hidden'
              : null,
        }}
      >
        {landingTab === ComponentTypes.CAREPLAN ||
        landingTab === ComponentTypes.CAREPATHWAYS ||
        landingTab === ComponentTypes.TODOS ? (
          <Flex
            justifyContent="flex-end"
            otherStyles={{ marginTop: 20, marginBottom: 20 }}
          >
            {landingTab === ComponentTypes.CAREPLAN && (
              <Box otherStyles={{ marginRight: 20 }}>
                <Button
                  label={t('newCareplan_view.exportAnswers')}
                  type={ButtonType.BUTTON}
                  onClick={() => renderExportAnswersModal()}
                  variant={ButtonVariant.OUTLINED}
                />
              </Box>
            )}
            <Box
              otherStyles={{
                width: 250,
              }}
            >
              <TextInput
                id="searchCaretask"
                name="Name"
                placeholder={t('common_labels.label_search')}
                variant={inputField.variant.CHAR_COUNT}
                maxChars={MaxCharLimits.searchInputs.searchKeyword}
                value={inputValue}
                handleOnChange={handleInputChange}
              />
            </Box>
          </Flex>
        ) : null}
        <div style={{ marginBottom: 50 }} ref={assignmentCardContainer}>
          {(landingTab === ComponentTypes.CAREPLAN ||
            landingTab === ComponentTypes.CAREPATHWAYS) && (
            <div
              style={{
                height:
                  window.innerHeight -
                  assignmentCardContainer.current?.getBoundingClientRect().top,
              }}
            >
              {landingTab === ComponentTypes.CAREPLAN && (
                <CareplanCards
                  patientId={patientId}
                  setCardCount={setTabsList}
                  searchValue={careplanSearchValue}
                />
              )}
              {landingTab === ComponentTypes.CAREPATHWAYS && (
                <CarePathwayCardsList
                  patientId={patientId}
                  setCardCount={setTabsList}
                  searchValue={carePathwaySearchValue}
                  patientInfo={patient}
                />
              )}
            </div>
          )}
          {landingTab === ComponentTypes.MEDICATION && medicationCards}
          {landingTab === ComponentTypes.OVERVIEW && taskCards}
          {landingTab === ComponentTypes.APPOINTMENT && appointmentCards}
          {landingTab === ComponentTypes.ASSESSMENT && assessmentCards}
          {landingTab === ComponentTypes.CALENDAR && (
            <PatientOverviewCalendar
              patientId={patientId}
              services={services}
            />
          )}
          {landingTab === ComponentTypes.ASSETS && (
            <Box
              otherStyles={{
                margin: '30px 0 30px 0',
                position: 'relative',
              }}
            >
              <Assets
                hasPagesize
                reloadTable={reloadTable}
                setReloadTable={setActiveTabAndReload}
                setFlashMessage={setFlashMessage}
                rightOffset={isChatOpened ? CHAT_BOX_OFFSET + 10 : 65}
                patientInfo={patientInfo}
                setReloadChat={setReloadChat}
                isChatOpened={isChatOpened}
                comesFromPatientOverview
              />
            </Box>
          )}
          {landingTab === ComponentTypes.TODOS && (
            <TodoCards
              patientId={patientId}
              searchValue={todoSearchValue}
              updateCardCount={updateCardCount}
            />
          )}
          {landingTab === ComponentTypes.ONCOBOX_IMPORTED_DATA && (
            <OncoboxImportedData patientId={patientId} />
          )}
        </div>
      </div>
    </div>
  );
}

AssignmentCardViewer.propTypes = {
  patientId: PropTypes.string.isRequired,
  landingTab: PropTypes.string.isRequired,
  componentDataCount: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFlashMessage: PropTypes.func.isRequired,
  isChatOpened: PropTypes.bool.isRequired,
  setShowCareplanResults: PropTypes.func.isRequired,
};

export default AssignmentCardViewer;
