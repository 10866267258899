import { React } from 'react';
import { ApiClient } from '@global/Services';
import serviceConfig from '../../../../../../serviceConfig.json';

export const getPathwayTemplateDetails = async (
  templateIds: number[],
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);

  if (templateIds && templateIds.length > 0) {
    const response = await ApiClient.POST({
      url: serviceConfig.brokerService.getMultipleCareplanTemplates.uri,
      payload: { templateIds },
    });
    if (response && response.data) {
      setIsLoading(false);
      return response;
    }
  }
};
