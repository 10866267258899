import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { OverviewState, OverviewActions } from './Overview.type';
import { STORE_NAME, initialState } from './Overview.constant';

import {
  resetOverviewData,
  resetOverviewCaretasks,
  setOverviewActivityStatus,
  setOverviewData,
  setOverviewCaretasks,
  setOverviewError,
  setOverviewDataPoints,
  setMedicationData,
} from './Overview.actions';

export const useOverviewStore = create<OverviewState & OverviewActions>()(
  devtools(
    (set, get) => ({
      ...initialState,
      resetOverviewData: resetOverviewData(set),
      resetOverviewCaretasks: resetOverviewCaretasks(set),
      setOverviewActivityStatus: setOverviewActivityStatus(set),
      setOverviewData: setOverviewData(set),
      setOverviewCaretasks: setOverviewCaretasks(set),
      setOverviewDataPoints: setOverviewDataPoints(set),
      setMedicationData: setMedicationData(set),
      setOverviewError: setOverviewError(set),
    }),
    { name: STORE_NAME }
  )
);
