import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Action,
  Box,
  Button,
  FlashMessagesWrapper,
  TextInput,
} from '@ui-common-files/components';
import CustomLoader from './CustomLoader';
import LoginFooter from './LoginFooter';
import LoginHeader from './LoginHeader';
import { DownloadDesktopAppBanner } from './DownloadDesktopAppBanner';

import { ButtonVariant, ButtonSize, ButtonType } from '@ui-common-files/utils';

import { HideFlashMessage } from '@global/Services';

import { useDownloadDesktopAppButtonService } from './DownloadDesktopAppBanner/DownloadDesktopAppButton.service';
import useLoginService from './Login.service';

import config from '../../../config';

import '../../css/login.scss';

function Login() {
  const { t } = useTranslation();

  const {
    handleFormSubmit,
    forgotPasswordHandler,
    username,
    password,
    errMsgKey,
    onChangeUsernameText,
    onChangePasswordText,
    isLoading,
    setIsLanguageChanged,
    handleChangeInstance,
  } = useLoginService();

  const { siteName, isOnDesktop } = useDownloadDesktopAppButtonService();

  return (
    <Box width="100%" height="100%">
      <div
        className="login-container"
        onTouchStart={() => localStorage.setItem('isTouchDevice', 'true')}
      >
        <FlashMessagesWrapper hideFlashMessage={HideFlashMessage} />
        <LoginHeader
          handleChangeInstance={handleChangeInstance}
          isOnDesktop={isOnDesktop}
        />
        <Box className="login-content-container">
          <Box className="credential-container">
            <form
              className="login__widget-panel__container"
              onSubmit={handleFormSubmit}
            >
              <Box className="login-header__wrapper">
                <Box className="login-header">
                  {t('common_labels.login')}
                  {isOnDesktop ? ` ${t('common_labels.in')} ${siteName}` : ''}
                </Box>
              </Box>
              <Box>
                <TextInput
                  value={username}
                  id="username"
                  name="username"
                  placeholder={t('common_labels.placeholder_user')}
                  hasError={!!errMsgKey && !username}
                  rightHelperText="words"
                  iconName="administration"
                  maxChars={10}
                  handleOnChange={onChangeUsernameText}
                />
              </Box>
              <Box className="Text-inputstyle">
                <TextInput
                  value={password}
                  id="password"
                  name="password"
                  type="password"
                  placeholder={t('common_labels.placeholder_password')}
                  hasError={!!errMsgKey && !password}
                  rightHelperText="words"
                  iconName="administration"
                  maxChars={10}
                  handleOnChange={onChangePasswordText}
                />
              </Box>
              {errMsgKey && (
                <Box className="err-msg">{t(errMsgKey).toString()}</Box>
              )}
              <Box className="button-container">
                {isLoading ? (
                  <CustomLoader />
                ) : (
                  <Button
                    label={t('common_buttons.signIn')}
                    type={ButtonType.SUBMIT}
                    fullWidth
                    size={ButtonSize.MD}
                    id="login-button"
                  />
                )}
              </Box>
              <Box className="link-button__container">
                <Button
                  variant={ButtonVariant.NO_BORDER}
                  label={t('common_labels.label_forgotPassword')}
                  type={ButtonType.BUTTON}
                  onClick={forgotPasswordHandler}
                  fullWidth
                  size={ButtonSize.MD}
                />
              </Box>
              <Box className="login-info-container login-info-container__training-info">
                <Box>
                  {t('login_view.training_info.message')}
                  <Action
                    href={config.trainingResourcesUrl}
                    className="login-info-container__training-info__link"
                  >
                    {t('login_view.training_info.cta')}
                  </Action>
                </Box>
              </Box>
            </form>
            <DownloadDesktopAppBanner />
            <LoginFooter setIsLanguageChanged={setIsLanguageChanged} />
          </Box>
        </Box>
      </div>
    </Box>
  );
}
export default Login;
