import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import I18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MaxCharLimits } from '@ui-common-files/utils';

import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import Input from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import processThresholdTable from '../../Utils/thresholdUtilities';
import serviceConfig from '../../../serviceConfig.json';
import { ApiClient } from '../../Global/Services';
import inputField from '../../../caro-ui-commonfiles/utils/inputField';
import NavigationJSON from '../../Utils/navigation.json';
import { ActionColors } from '../../../caro-ui-commonfiles/utils/colors';
import componentTypeOrder from '../Careplans/careplanComponentTypeOrder';

const Thresholds = ({
  hasPagesize,
  hasPagination,
  totalThresholdNotifications,
  setRefreshCount,
}) => {
  const { user } = useSelector(state => state.session);
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({ value: '', start: 0, length: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await axios.post(
        `${serviceConfig.brokerService.getAllThresholdsByQuery.uri}?userId=${user.id}`,
        {
          query,
          totalThresholdsLength: totalThresholdNotifications,
        }
      );
      setFetchedData(Rows(result.data.data));
      setRecordsTotal(result.data.recordsTotal);
      setLoading(false);
      setRefreshCount();
    };
    fetchData();
  }, [
    query.value,
    query.start,
    query.length,
    setRefreshCount,
    totalThresholdNotifications,
    user.id,
    query,
  ]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];
  const tableProps = {
    defaultSorted: [
      {
        id: 'record',
        desc: false,
      },
    ],
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
    getTdProps: (state, row, col) => ({
      onClick: () => {
        ApiClient.GET({
          url:
            serviceConfig.brokerService
              .getCarePathwayCareplanAppointmentTypesByCareplanId.uri +
            row.original.navigationData.careplanId,
        }).then(response => {
          if (response) {
            const isCarepathway = Object.keys(response.data).length > 0;
            const navigationData = {
              ...row.original.navigationData,
              ...response.data,
              isCarepathway,
            };
            if (col.id !== 'rowOptions') {
              history.push(
                `/${NavigationJSON.PATIENT_OVERVIEW}/${row.original.patientId}?tab=0`,
                navigationData
              );
            }
          }
        });
      },
    }),
    getTrProps: (state, row) => ({
      style: {
        cursor: 'pointer',
        color:
          row.original.status === 'chatMessages.unseen'
            ? ActionColors.THRESHOLD
            : null,
      },
    }),
  };
  const Rows = data => {
    const rows = [];
    if (data) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].answer != null) {
          rows.push({
            patient: `${data[index].patient.firstname} ${data[index].patient.lastname}`,
            component: data[index].careplanComponent.name,
            componentTypeId:
              data[index].careplanComponent.careplanComponentTypeId,
            careplan: data[index].answer.careplan.name,
            answer: processThresholdTable(
              data[index].answer,
              data[index].careplanComponent,
              data[index].threshold,
              'answerColumn',
              I18n.t('common_labels.yes'),
              I18n.t('common_labels.no'),
              I18n.t('common_labels.enter'),
              I18n.t('common_labels.skip')
            ),
            threshold: processThresholdTable(
              data[index].answer,
              data[index].careplanComponent,
              data[index].threshold,
              'thresholdColumn',
              I18n.t('common_labels.yes'),
              I18n.t('common_labels.no'),
              I18n.t('common_labels.enter'),
              I18n.t('common_labels.skip')
            ),
            date: data[index].createdAt,
            patientId: data[index].patient.id,
            navigationData: {
              careplanName: data[index].answer.careplan.name,
              careplanId:
                data[index].threshold.careplanCareplanComponentMapperCareplanId,
              answerTimestamp: data[index].answer.timestamp,
              seen: data[index].users[0].userThresholdNotification
                .showInDropdown,
              isAssessment: data[index].answer.careplan.isAssessment,
            },
            status:
              data[index].users[0].userThresholdNotification.showInDropdown ===
              true
                ? 'chatMessages.unseen'
                : 'chatMessages.seen',
          });
        }
      }
    }
    return rows;
  };

  const columns = [
    {
      Header: I18n.t('chatMessages.status'),
      accessor: 'status',
      sortable: false,
      filterable: false,
      width: 100,
      Cell: ({ value }) => I18n.t(value),
    },
    {
      Header: I18n.t('patients_view.label_patient'),
      accessor: 'patient',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('common_labels.component'),
      accessor: 'component',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('common_labels.careplan'),
      accessor: 'careplan',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('common_labels.answer'),
      accessor: 'answer',
      sortable: false,
      filterable: false,
      Cell: rowData =>
        rowData.original.componentTypeId === componentTypeOrder.DATEPICKER
          ? moment(rowData.value).locale(I18n.language).format('DD MMM YYYY')
          : rowData.value,
    },
    {
      Header: I18n.t('threshold.threshold'),
      accessor: 'threshold',
      sortable: false,
      filterable: false,
      Cell: rowData => {
        const { threshold, componentTypeId } = rowData.original;
        if (
          componentTypeId === componentTypeOrder.DATEPICKER &&
          threshold !== null &&
          threshold.comparison
        ) {
          const comparison = I18n.t(
            `common_labels.label_${threshold.comparison.toLowerCase()}`
          );
          const unit = threshold.unit.split('(')[0];
          const unitTranslation = I18n.t(`datePicker.${unit}`);
          return `${comparison} ${threshold.value} ${unitTranslation}`;
        } else {
          return rowData.value;
        }
      },
    },
    {
      Header: I18n.t('common_labels.placeholder_date'),
      accessor: 'date',
      sortable: false,
      filterable: false,
      Cell: ({ value }) =>
        moment(value).locale(I18n.language).format('DD MMM YYYY HH:mm'),
    },
  ];

  return (
    <>
      <div style={{ width: 200, position: 'absolute', top: 0, right: 0 }}>
        <Input
          id="specialId"
          name="Name"
          placeholder={I18n.t('common_labels.label_search')}
          variant={inputField.variant.CHAR_COUNT}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          value={query.value}
          handleOnChange={e => {
            setQuery({ value: e.target.value, start: 0, length: 10 });
            setCurrentPage(0);
          }}
        />
      </div>
      <DataTable
        data={fetchedData}
        columns={columns}
        pageSize={hasPagesize}
        pageSizeOptions={items}
        pagination={hasPagination}
        isLoading={loading}
        tableProps={tableProps}
        onTableChange={(index, size) => {
          setQuery({
            value: query.value,
            start:
              fetchedData && index * size > recordsTotal ? 0 : index * size,
            length: size,
          });
          setCurrentPage(
            fetchedData && index * size > recordsTotal ? 0 : index
          );
        }}
        page={currentPage}
        pages={Math.ceil(recordsTotal / query.length)}
        totalRecord={recordsTotal}
      />
    </>
  );
};
export default Thresholds;
