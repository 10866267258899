import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  Flex,
  Box,
  Dropdown,
  RadioButton,
  ComponentWithLabel,
  Tooltip,
} from '@ui-common-files/components';

import { Greys, Position } from '@ui-common-files/utils';
import CheckboxRadio from '@ui-common-files/utils/CheckboxRadio';

import LanguageItems from '../../../types/Common/LanguageItems.enum';
import EnumToArray from '../../../Global/Arrays/EnumToArray/EnumToArray';

const ReportReviewDropdown = ({
  reportReviewAssessment,
  filteredReviewAssessmentArray,
  setReportReviewAssessment,
}) => {
  const { t } = useTranslation();
  filteredReviewAssessmentArray.unshift({
    label: t('newCareplan_view.none'),
    value: null,
    isNone: true,
  });

  return (
    <Flex alignItems="space-between">
      <Box width="23%">
        <ComponentWithLabel
          id="assessements"
          labelText={t('newCareplan_view.DefaultReviewCaretask')}
        />
      </Box>
      <Box flex="0 0 285px">
        <Dropdown
          isClearable={true}
          isSearchable={true}
          placeholder={t('newCareplan_view.choose_review_caretask')}
          value={reportReviewAssessment}
          options={filteredReviewAssessmentArray}
          handleOnChange={assessment => {
            if (assessment.label === t('newCareplan_view.none')) {
              setReportReviewAssessment(null);
            } else {
              setReportReviewAssessment(assessment);
            }
          }}
        />
      </Box>
    </Flex>
  );
};
const ReportSettingsComponent = ({
  occurrences,
  setOccurrences,
  reportLanguage,
  setReportLanguage,
  hasThreshold,
  reportReviewToggle,
  reviewAssessments,
  renderLabelWithToggleButton,
  reportReviewAssessment,
  setReportReviewAssessment,
  templateResponse,
}) => {
  let languagesArray = EnumToArray(LanguageItems);
  const { t } = useTranslation();
  const reviewAssessmentsArray = reviewAssessments?.map(item => {
    item.label = item.name;
    item.value = item.name;
    return item;
  });
  const filteredReviewAssessmentArray = reviewAssessmentsArray.filter(
    item => item && item.id !== templateResponse?.template?.id
  );
  languagesArray = languagesArray.map(language => {
    language.label = language.key;
    return language;
  });

  const renderReportOccurrences = () => {
    return (
      <Flex flexDirection="row" alignItems="center">
        <Box>
          <Box margin="0 0 15px">
            <RadioButton
              id="occurence"
              name="occurence"
              value={occurrences}
              label={t('newCareplan_view.afterAnswering')}
              variant={CheckboxRadio.Default}
              inputWidth={200}
              checked={occurrences === 1 || occurrences === 0}
              handleOnChange={() => setOccurrences(1)}
            />
          </Box>
          <Box margin="0 0 15px">
            <RadioButton
              id="occurence"
              name="occurence"
              value={occurrences}
              label={t('newCareplan_view.afterThresholdExceeded')}
              variant={CheckboxRadio.Default}
              inputWidth={200}
              checked={occurrences === 2}
              handleOnChange={() => setOccurrences(2)}
            />
          </Box>
          {!hasThreshold && occurrences === 2 && (
            <Flex otherStyles={{ marginLeft: '33px' }}>
              <Box>
                <span className="templateRequired">
                  {t('warningMessage.shouldHasThreshold')}
                </span>
              </Box>
            </Flex>
          )}
        </Box>
      </Flex>
    );
  };

  const renderReportLanguages = () => {
    return (
      <Flex>
        <Box flex="0 0 285px">
          <Dropdown
            value={reportLanguage}
            options={languagesArray}
            handleOnChange={language => {
              setReportLanguage(language);
            }}
          />
        </Box>
      </Flex>
    );
  };
  return (
    <div className="recurrenceComp">
      <Flex
        flexDirection="row"
        alignItems="center"
        otherStyles={{ marginBottom: '10px' }}
      >
        <Box width="23%">
          <ComponentWithLabel
            id="occurrences"
            htmlFor="occurrences"
            labelText={t('newCareplan_view.createItEvery')}
          />
        </Box>
        <Box>{renderReportOccurrences()}</Box>
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="center"
        otherStyles={{ marginBottom: '10px' }}
      >
        <Box width="23%">
          <ComponentWithLabel
            id="language"
            htmlFor="language"
            labelText={t('newCareplan_view.language')}
          />
        </Box>
        <Box>{renderReportLanguages()}</Box>
      </Flex>
      <Flex alignItems="center">
        {renderLabelWithToggleButton(t('newCareplan_view.reportReview'))}
        <Tooltip
          content={
            <Box width={200}>{t('newCareplan_view.reportReviewTooltip')}</Box>
          }
          position={Position.bottom}
        >
          <Icon name="info" size={40} fill={Greys.DARK_GREY_70} />
        </Tooltip>
      </Flex>

      {reportReviewToggle && (
        <ReportReviewDropdown
          reportReviewAssessment={reportReviewAssessment}
          filteredReviewAssessmentArray={filteredReviewAssessmentArray}
          setReportReviewAssessment={setReportReviewAssessment}
        />
      )}
    </div>
  );
};
export default ReportSettingsComponent;
