import { useEffect, useCallback, useState } from 'react';
import { MEDIA_QUERIES, SCREEN_CATEGORIES } from '../layout/mediaQueries';

const getCurrenScreenSizeCaregory = innerWidth => {
  if (
    innerWidth <= MEDIA_QUERIES.SCREEN_L &&
    innerWidth > MEDIA_QUERIES.SCREEN_MD
  )
    return SCREEN_CATEGORIES.SCREEN_L;

  if (
    innerWidth <= MEDIA_QUERIES.SCREEN_MD &&
    innerWidth > MEDIA_QUERIES.SCREEN_SM_IPAD
  )
    return SCREEN_CATEGORIES.SCREEN_MD;

  if (innerWidth <= MEDIA_QUERIES.SCREEN_SM_IPAD)
    return SCREEN_CATEGORIES.SCREEN_SM_IPAD;

  return SCREEN_CATEGORIES.SCREEN_XL;
};

const useResponsiveScreen = () => {
  const [screen, setScreen] = useState(
    getCurrenScreenSizeCaregory(window.innerWidth)
  );

  const calculateScreenSize = useCallback(() => {
    setScreen(getCurrenScreenSizeCaregory(window.innerWidth));
  }, [setScreen]);

  useEffect(() => {
    window.addEventListener('resize', calculateScreenSize);
    return () => {
      window.removeEventListener('resize', calculateScreenSize);
    };
  }, [calculateScreenSize]);

  return screen;
};

export default useResponsiveScreen;
