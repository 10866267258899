import React from 'react';
import iconDefinitions from './iconDefinitions';
import { Greys } from '../../utils/colors';
import { IconProps, SVGTypes } from './iconDefinitions.type';

/**
 * The Icon component is a visual representation of actions, commands and helps in the comprehension of important information.
 * It can be customized by size and color.
 */
function Icon({
  name,
  fill = Greys.DARK_GREY,
  size = 24,
  ...props
}: IconProps): JSX.Element {
  const selectedIcon = iconDefinitions.find(
    icon => icon.title.toLowerCase() === name.toLowerCase()
  );

  const elements = selectedIcon?.svgElements.map(el => {
    switch (el.type) {
      case SVGTypes.Rect:
        return (
          <rect
            key={el.width}
            fill={el.fill ? el.fill : fill}
            width={el.width}
            height={el.height}
            x={el.x}
            y={el.y}
            rx={el.rx}
          />
        );
      case SVGTypes.Circle:
        return (
          <circle
            key={`${el.cx}-${el.cy}`}
            cx={el.cx}
            cy={el.cy}
            r={el.r}
            fill={el.fill}
          />
        );
      case SVGTypes.Path:
        return (
          <path
            key={el.d}
            fill={el.fill ? el.fill : fill}
            stroke={el.stroke ? fill || el.stroke : 'none'}
            d={el.d}
            transform={el.transform}
            strokeLinecap={el.strokeLinecap ? el.strokeLinecap : null}
            strokeLinejoin={el.strokeLinejoin ? el.strokeLinejoin : null}
          />
        );

      case SVGTypes.Defs:
        return (
          <defs key={el.x1}>
            <linearGradient
              id={el.id}
              x1={el.x1}
              y1={el.y1}
              x2={el.x2}
              y2={el.y2}
              gradientUnits={el.gradientUnits}
            >
              {el.stops.map(stop => {
                return <stop stopColor={stop.stopColor} offset={stop.offset} />;
              })}
            </linearGradient>
          </defs>
        );

      case SVGTypes.Group:
        return (
          <g
            clipPath={el.clipPath}
            fillRule={el.fillRule}
            clipRule={el.clipRule}
            fill={el.fill ? el.fill : fill}
          >
            {el.paths.map(path => {
              return <path d={path.d} />;
            })}
          </g>
        );

      case SVGTypes.ClipPath:
        return (
          <defs>
            <clipPath id={el.id}>
              {el.paths.map(path => {
                return <path d={path.d} />;
              })}
            </clipPath>
          </defs>
        );
      default:
        return <path />;
    }
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={selectedIcon?.viewBox}
      aria-labelledby={name}
      fillRule={selectedIcon?.fillRule}
      {...props}
    >
      {elements}
    </svg>
  );
}

export default Icon;
