/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from 'react';
import '../../styles/main.scss';
import PropTypes from 'prop-types';
import DropdownMenu from './DropdownMenu';
import Icon from '../Icon/Icon';
import Box from '../Box/Box';

const ProfileDropdown = ({ userName, role, imgSrc, items, onClick }) => {
  const [responsive, setResponsive] = useState(false);

  const screenSize = useCallback(() => {
    window.innerWidth <= 1366 ? setResponsive(true) : setResponsive(false);
  }, [responsive]);

  useEffect(() => {
    screenSize();
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  const buildItems = () => {
    const result = items.map(function(item) {
      return (
        <li key={item.key} onClick={e => onClick(e, item)}>
          <span className="dropdown__item">{item.value}</span>
        </li>
      );
    });
    return result;
  };

  const profile = (
    <div className="profile-button">
      <div className="profile-button__container">
        <Box width="40px" height="40px" margin="0 5px 0 0">
          <div
            style={{
              backgroundImage: `url(${imgSrc})`,
            }}
            className="notification__table-profile-pic"
          />
        </Box>
        {!responsive && (
          <span className="profile-button__wrapper">
            <span className="profile-button__wrapper--name">{userName}</span>
            <span className="profile-button__wrapper--role">{role}</span>
          </span>
        )}
      </div>
      {!responsive && (
        <div className="profile-button__arrow">
          <Icon name="expanded" size={25} fill="454b48" />
        </div>
      )}
    </div>
  );
  return (
    <DropdownMenu
      items={buildItems()}
      variant={
        responsive ? 'profile-button__short-view' : 'profile-button__options'
      }
    >
      {profile}
    </DropdownMenu>
  );
};

ProfileDropdown.propTypes = {
  userName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProfileDropdown;
