import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import Icon from '../Icon/Icon';
import { dropdownStyles } from '../../utils/dropdownSelectStyles';
import { Greys, ActionColors } from '../../utils/colors';
import Box from '../Box/Box';
import i18next from 'i18next';

/*
 * Replace the opening indicator of select.
 * By default is a chevron pointed down.
 */
const DropdownIndicator = ({ selectProps, ...innerProps }) => {
  return (
    <components.DropdownIndicator {...innerProps}>
      <Icon name="expanded" fill={Greys.LIGHT_GREY} />
      {selectProps.hasError && (
        <Icon
          name="error"
          fill={ActionColors.ERROR}
          className="input__text__icon right-icon"
        />
      )}
    </components.DropdownIndicator>
  );
};

const Dropdown = ({
  options,
  isDisabled,
  isSearchable,
  placeholder,
  value,
  handleOnChange,
  fontSize,
  heightControl,
  hasError,
  noOptionsMessage
}) => {
  return (
    <Select
      options={options}
      getOptionValue={options => options.label} 
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      placeholder={placeholder}
      value={value === undefined || value === null ? '' : value}
      onChange={handleOnChange}
      styles={dropdownStyles(fontSize, heightControl)}
      classNamePrefix="mySelect"
      components={{ DropdownIndicator }}
      hasError={hasError}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

Dropdown.propTypes = {
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  /** Can be null or an object */
  value: PropTypes.object,
  handleOnChange: PropTypes.func.isRequired,
  fontSize: PropTypes.number,
  heightControl: PropTypes.number,
  hasError: PropTypes.bool,
};

Dropdown.defaultProps = {
  isDisabled: false,
  isSearchable: false,
  fontSize: 16,
  heightControl: 34,
  hasError: false,
  noOptionsMessage: i18next.t('common_labels.label_noOptions_Available')
};

export default Dropdown;
