import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TextInput from '../../../../../../../caro-ui-commonfiles/components/TextInput/TextInput';

import inputField from '../../../../../../../caro-ui-commonfiles/utils/inputField';
import maxCharLimit from '../../../../../../../caro-ui-commonfiles/utils/maxcharlimits.json';

import { CaretaskComponent } from '../../../../../../types/Caretask';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { AnsweringComponentsProps } from '../AnsweringTypes';

import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';

export default function AnsweringFreeText({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  const { t } = useTranslation();
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);

  const defaultValue: string = currentAnswer?.answer || '';

  const [userInput, setUserInput] = useState<string>(defaultValue);

  useEffect(() => {
    handleOnChange({ answer: userInput, isSkipped: !userInput });
  }, [userInput, handleOnChange]);

  useEffect(() => {
    setUserInput(defaultValue);
  }, [currentComponent]);

  return (
    <TextInput
      name="answer-view-free-text"
      placeholder={t('careplanComponentType.FREETEXT')}
      value={userInput}
      handleOnChange={e => setUserInput(e.target.value)}
      variant={inputField.variant.CHAR_COUNT}
      maxChars={maxCharLimit.carePlan.freeTextMaxCharLimit}
    />
  );
}
