/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import PropTypes, { objectOf } from 'prop-types';

import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import { lifespanEnum } from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import { Greys } from '../../../caro-ui-commonfiles/utils/colors';

const DateViewer = ({ onChange, date, filter }) => {
  const addDaysToDate = days => {
    const endDate = moment(date.end).add(days, 'days');
    const end =
      endDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        ? moment().format('YYYY-MM-DD HH:mm:ss')
        : endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const start = moment(date.end)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    return {
      start,
      end,
    };
  };
  const subDaysToDate = days => {
    const start = moment(date.start)
      .subtract(days, 'days')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const end = moment(date.start)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    return {
      start,
      end,
    };
  };

  const onNext = () => {
    let start = '';
    let end = '';
    switch (filter) {
      case lifespanEnum.WEEK:
        onChange(addDaysToDate(7));
        break;
      case lifespanEnum.MONTH:
        onChange(addDaysToDate(30));
        break;
      case lifespanEnum.YEAR:
        {
          const year = moment(date.start).add(1, 'years');
          start = year.startOf('year').format('YYYY-MM-DD HH:mm:ss');
          end =
            year.year() === moment().year()
              ? moment().format('YYYY-MM-DD HH:mm:ss')
              : year.endOf('year').format('YYYY-MM-DD HH:mm:ss');
          onChange({
            start,
            end,
          });
        }
        break;
      default:
        {
          const day = moment(date.start).add(1, 'days');
          start = day.startOf('day').format('YYYY-MM-DD HH:mm:ss');
          end =
            day.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
              ? moment().format('YYYY-MM-DD HH:mm:ss')
              : day.endOf('day').format('YYYY-MM-DD HH:mm:ss');
          onChange({
            start,
            end,
          });
        }
        break;
    }
  };

  const onPrev = () => {
    let start = '';
    let end = '';
    switch (filter) {
      case lifespanEnum.WEEK:
        onChange(subDaysToDate(7));
        break;
      case lifespanEnum.MONTH:
        onChange(subDaysToDate(30));
        break;
      case lifespanEnum.YEAR:
        {
          const year = moment(date.start).subtract(1, 'years');
          start = year.startOf('year').format('YYYY-MM-DD HH:mm:ss');
          end = year.endOf('year').format('YYYY-MM-DD HH:mm:ss');
          onChange({
            start,
            end,
          });
        }
        break;
      default:
        {
          const day = moment(date.start).subtract(1, 'days');
          start = day.startOf('days').format('YYYY-MM-DD HH:mm:ss');
          end = day.endOf('day').format('YYYY-MM-DD HH:mm:ss');
          onChange({
            start,
            end,
          });
        }
        break;
    }
  };

  const getDateFormat = () => {
    switch (filter) {
      case lifespanEnum.WEEK:
        return `${moment(date.start).format('MMM DD')} - ${moment(
          date.end
        ).format('MMM DD')}`;
      case lifespanEnum.MONTH:
        return `${moment(date.start).format('MMM DD')} - ${moment(
          date.end
        ).format('MMM DD')}`;
      case lifespanEnum.YEAR:
        return moment(date.start).format('YYYY');

      default:
        return moment(date.start).format('MMM DD');
    }
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <button onClick={onPrev} type="button">
        <Icon size={14} name="arrowLeft" fill={Greys.LIGHT_GREY_75} />
      </button>
      <Flex
        otherStyles={{
          margin: '0 8px',
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 1.5,
        }}
      >
        {getDateFormat()}
      </Flex>
      {moment(date.end).format('YYYY-MM-DD') <
        moment().format('YYYY-MM-DD') && (
        <button onClick={onNext} type="button">
          <Icon size={14} name="arrowRight" fill={Greys.LIGHT_GREY_75} />
        </button>
      )}
    </Flex>
  );
};

DateViewer.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: objectOf(PropTypes.string).isRequired,
  filter: PropTypes.string.isRequired,
};

export default DateViewer;
