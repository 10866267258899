import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteractiveTable } from '@global/Hooks';
import { ShowFlashMessage } from '@global/Services';

import {
  getAllSatellites,
  activateSatelliteRequest,
  deactivateSatelliteRequest,
} from '../../Satellites/Satellites.api';

const useSatellites = (isActive, reloadTable) => {
  const { t } = useTranslation();

  const [fetchedData, setFetchedData] = useState([]);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const tableProps = {
    defaultSorted: [
      {
        id: 'name',
        desc: false,
      },
    ],
    onSortedChange: newSorted => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: t('datatable.previous'),
    nextText: t('datatable.next'),
    noDataText: t('common_labels.label_noData'),
    pageText: t('datatable.showing'),
    ofText: t('datatable.of'),
    rowsText: t('datatable.entries'),
    rowsSelectorText: t('datatable.recordsPerPage'),
  };

  const Rows = async data => {
    const rows = [];
    const retrievedData = [...data];
    if (retrievedData) {
      for (let index = 0; index < retrievedData.length; index++) {
        rows.push({
          name: retrievedData[index].name,
          phoneNumber: retrievedData[index].phoneNumber,
          email: retrievedData[index].email,
          address: retrievedData[index].address,
          site: new Array(retrievedData[index].institution),
          isActive: retrievedData[index].isActive,
          id: retrievedData[index].id,
        });
      }
    }
    return rows;
  };

  const fetchData = async controller => {
    setLoading(true);

    const response = await getAllSatellites(controller, query, isActive);
    if (response?.data) {
      setFetchedData(await Rows(response.data.data.rows));
      setRecordsTotal(response.data.recordsTotal);
    }
    setLoading(false);
  };

  const activateRow = useCallback(async id => {
    const response = await activateSatelliteRequest(id);
    if (response) {
      ShowFlashMessage({
        type: response.data.type,
        messageKey: response.data.content,
      });
    }
  }, []);

  const deactivateRow = useCallback(async id => {
    const response = await deactivateSatelliteRequest(id);
    if (response) {
      ShowFlashMessage({
        type: response.data.type,
        messageKey: response.data.content,
      });
    }
  }, []);

  const handleOnCheckToggle = useCallback(
    (id: string, isChecked: boolean) => {
      const newAllSatellites = [...fetchedData];
      const foundActiveSatellite = newAllSatellites.find(sat => sat.id === id);
      if (foundActiveSatellite) {
        foundActiveSatellite.isActive = isChecked;
        setFetchedData(newAllSatellites);
      }
      if (isChecked) {
        activateRow(id);
      } else {
        deactivateRow(id);
      }
    },
    [fetchedData]
  );

  const {
    sort,
    setSort,
    query,
    currentPage,
    handleSearch,
    handleTableOnChange,
  } = useInteractiveTable(fetchData, reloadTable, recordsTotal);

  return {
    fetchedData,
    recordsTotal,
    loading,
    tableProps,
    sort,
    query,
    currentPage,
    handleSearch,
    handleTableOnChange,
    handleOnCheckToggle,
  };
};

export default useSatellites;
