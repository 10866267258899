import moment from 'moment';
import { TodosPayload, TodosResponse } from './Todos.types';
import serviceConfig from '../../../serviceConfig.json';
import { ApiClient } from '@global/Services';

export const getAllTodoAssessments = (
  controller: AbortController,
  payload: TodosPayload
) => {
  return ApiClient.GET<TodosResponse, TodosPayload>({
    signal: controller.signal,
    url: serviceConfig.brokerService.todos.uri,
    payload: { ...payload, userOffset: moment().utcOffset() },
  });
};
