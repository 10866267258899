import React from 'react';
import I18n from 'i18next';
import RadioButton from '../../../../caro-ui-commonfiles/components/RadioButton/RadioButton';
import CheckboxRadio from '../../../../caro-ui-commonfiles/utils/CheckboxRadio';
import Tag from '../../../../caro-ui-commonfiles/components/Tags/Tags';
import { TagsType, TagsVariant, ActionColors } from '@ui-common-files/utils';
import { nonMedical } from '../../../../config';

const isThresholdSet = (index, componentCollection) => {
  if (componentCollection[index]) {
    let shouldToggle =
      componentCollection[index].searchTerm.careplanComponentId &&
      (nonMedical == false || nonMedical == 'false')
        ? componentCollection[index].searchTerm.shouldThresholdToggle
        : componentCollection[index].shouldThresholdToggle;
    return shouldToggle;
  }
};

const handleCheckRadio = (
  event,
  item,
  componentCollection,
  setComponentCollection
) => {
  item.radioCollection.isChecked = event.target.checked;
  item.radioCollection.name = event.target.value;
  setComponentCollection([...componentCollection]);
};

const renderRadioButton = (
  label,
  item,
  index,
  componentCollection,
  setComponentCollection
) => {
  let value = `${label}_${item.id}`;
  let thresholdToggle = isThresholdSet(index, componentCollection);
  let id = `${value}_${item.id}`;
  return (
    <div className="yesNoContainer">
      <div>
        <RadioButton
          id={id}
          name={id}
          value={value}
          label={label}
          variant={
            thresholdToggle ? CheckboxRadio.Threshold : CheckboxRadio.Default
          }
          isDisabled={!thresholdToggle}
          inputWidth={200}
          checked={thresholdToggle ? item.radioCollection.name == value : false}
          handleOnChange={e =>
            thresholdToggle
              ? handleCheckRadio(
                  e,
                  item,
                  componentCollection,
                  setComponentCollection
                )
              : ''
          }
        />
      </div>
      {thresholdToggle && item.radioCollection.name == value && (
        <div className="tag-container">
          <Tag
            text={I18n.t('threshold.threshold')}
            variant={TagsVariant.CONTAINED}
            type={TagsType.SQUARED}
            fill={ActionColors.THRESHOLD}
          />
        </div>
      )}
    </div>
  );
};

const renderYesNo = (item, componentCollection, setComponentCollection) => {
  let index = componentCollection.findIndex(comp => comp.id === item.id);
  return (
    <div className="yesNoParent">
      <div className="yesRadio">
        {renderRadioButton(
          I18n.t('common_labels.yes'),
          item,
          index,
          componentCollection,
          setComponentCollection
        )}
      </div>
      <div className="noRadio">
        {renderRadioButton(
          I18n.t('common_labels.no'),
          item,
          index,
          componentCollection,
          setComponentCollection
        )}
      </div>
    </div>
  );
};

export default renderYesNo;
