import templatesTableActions from '../../src/Utils/ReduxUtilities/Actions/Datatables/templates.json';

const initialState = 1;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case templatesTableActions.ON_TAB_CHANGE:
      return action.payload;

    case templatesTableActions.RESET_TAB_POSITION:
      return 1;

    default:
      return state;
  }
};

export default reducer;
