import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import I18n from 'i18next';
import '../../../css/componentSettings.css';
import NumberInput from '../../../../caro-ui-commonfiles/components/NumberInput/NumberInput';
import Label from '../../../../caro-ui-commonfiles/components/Label/Label';
import componentTypes from './componentTypes.json';
import { prepareOptionAndTheirWeights } from '../CareplanHelperUtility';
import defaultEnums from '../defaultEnums';

import { getSumOfArray } from '../../../Global/Arrays';

const OptionWeightsComponent = ({
  searchTermComponents,
  setComponentCollection,
  componentCollection,
  componentTypeId,
  showPromptForAdjustScoring,
  setShowPromptForAdjustScoring,
}) => {

  const getMatchedPosition = () => {
    return componentCollection.findIndex(elem => {
      return elem.id == searchTermComponents.id;
    });
  };

  const getNewlyCreatedComponentOptionsAndWeights = () => {
    let newlyCreatedOptions;
    let newlyCreatedComponentOptionsWeights;
    let newlyCreatedOptionsWithWeights = [];
    if (getMatchedPosition() !== -1) {
      if (
        componentCollection[getMatchedPosition()].choiceCollection.choices
          .length > 0
      ) {
        newlyCreatedOptions =
          componentCollection[getMatchedPosition()].choiceCollection.choices;
        newlyCreatedComponentOptionsWeights =
          componentCollection[getMatchedPosition()].choiceCollection
            .optionWeights.weights;
        newlyCreatedOptionsWithWeights = prepareOptionAndTheirWeights(
          newlyCreatedOptions,
          newlyCreatedComponentOptionsWeights,
          true
        );
      }
    }
    return newlyCreatedOptionsWithWeights;
  };

  const getNewlyCreatedComponentOptionsAndWeightsForSortables = () => {
    let newlyCreatedOptions;
    let newlyCreatedComponentOptionsWeights;
    let newlyCreatedOptionsWithWeights = [];
    if (
      componentCollection[getMatchedPosition()].sortableCollection.sortables
        .length > 0
    ) {
      newlyCreatedOptions =
        componentCollection[getMatchedPosition()].sortableCollection.sortables;
      newlyCreatedComponentOptionsWeights =
        componentCollection[getMatchedPosition()].sortableCollection
          .optionWeights.weights;
      newlyCreatedOptionsWithWeights = prepareOptionAndTheirWeights(
        newlyCreatedOptions,
        newlyCreatedComponentOptionsWeights,
        true
      );
    }

    return newlyCreatedOptionsWithWeights;
  };

  const updateOptionWeightsForSearchTerm = (
    updatedWeight,
    weightsObject,
    optionWeightActualIndex
  ) => {
    const parsedWeightObject = JSON.parse(weightsObject);
    parsedWeightObject.weights[optionWeightActualIndex] = updatedWeight;

    const sum = getSumOfArray(parsedWeightObject.weights);
    parsedWeightObject.total = sum;

    componentCollection[
      getMatchedPosition()
    ].searchTerm.optionWeights = JSON.stringify(parsedWeightObject);
    setComponentCollection([...componentCollection]);
  };

  const updateOptionWeightsForNewlyCreated = (
    updatedWeight,
    weightsObject,
    optionWeightActualIndex
  ) => {
    weightsObject.weights[optionWeightActualIndex] = updatedWeight;

    const sum = getSumOfArray(weightsObject.weights);
    weightsObject.total = sum;

    componentTypeId == componentTypes.SORTABLE
      ? (componentCollection[
        getMatchedPosition()
      ].sortableCollection.optionWeights = weightsObject)
      : (componentCollection[
        getMatchedPosition()
      ].choiceCollection.optionWeights = weightsObject);
    setComponentCollection([...componentCollection]);
  };

  const fillArrayWithZero = length => new Array(length).fill(null).map(() => 0);

  const createWeightsObject = (newCreatedWeights, newCreatedChoicesLength) => {
    let weightsObject;
    const { weights, total } = newCreatedWeights;

    if (weights.length === 0) {
      weightsObject = {
        total: 0,
        weights: fillArrayWithZero(newCreatedChoicesLength),
      };
    } else if (weights.length !== newCreatedChoicesLength) {
      const arrayBasedOnOptions = fillArrayWithZero(
        newCreatedChoicesLength - weights.length
      );

      const weightsLengthMatchedWithOptions = [
        ...weights,
        ...arrayBasedOnOptions,
      ];

      weightsObject = {
        total,
        weights: weightsLengthMatchedWithOptions,
      };
    } else {
      weightsObject = newCreatedWeights;
    }

    return weightsObject;
  };

  const handleComponentOptionWeightOnChange = (
    updatedWeight,
    index,
    key,
    optionWeightActualIndex
  ) => {
    if (key === 'searchTerm') {
      const searchTermWeights =
        componentCollection[getMatchedPosition()].searchTerm.optionWeights;
      updateOptionWeightsForSearchTerm(
        updatedWeight,
        searchTermWeights,
        optionWeightActualIndex
      );
    } else if (key === 'newlyCreated') {
      const newCreatedWeights =
        componentTypeId === componentTypes.SORTABLE
          ? componentCollection[getMatchedPosition()].sortableCollection
            .optionWeights
          : componentCollection[getMatchedPosition()].choiceCollection
            .optionWeights;

      const newCreatedChoicesLength =
        componentTypeId === componentTypes.SORTABLE
          ? componentCollection[getMatchedPosition()].sortableCollection
            .sortables.length
          : componentCollection[getMatchedPosition()].choiceCollection.choices
            .length;

      const weights = createWeightsObject(
        newCreatedWeights,
        newCreatedChoicesLength
      );

      updateOptionWeightsForNewlyCreated(
        updatedWeight,
        weights,
        optionWeightActualIndex
      );
    }
    showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent = true;
    setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
  };

  const renderComponentOptionWeights = () => {
    let mergePrefillWithNewOptions;
    const newlyCreatedComponentOptionsAndWeights =
      componentTypeId == componentTypes.SORTABLE
        ? getNewlyCreatedComponentOptionsAndWeightsForSortables()
        : getNewlyCreatedComponentOptionsAndWeights();
    mergePrefillWithNewOptions = newlyCreatedComponentOptionsAndWeights;

    if (mergePrefillWithNewOptions) {
      return mergePrefillWithNewOptions.map((optionsAndWeight, index) => {
        return (
          <div key={`unique-${index}`} className="weightAndLabelContainer">
            <div>
              <Label
                style={{
                  overflow: 'hidden !important',
                  whiteSpace: 'nowrap !important',
                  textOverflow: 'ellipsis !important',
                }}
                text={
                  componentTypeId === componentTypes.BMI
                    ? I18n.t(`bmiOptions.${optionsAndWeight.option}`)
                    : componentTypeId === componentTypes.BLOOD_PRESSURE
                    ? `${I18n.t(
                      `bloodPressureOptions.abreviations.${optionsAndWeight.option}`
                    )}`
                    : optionsAndWeight.option
                }
                isScore
              />
            </div>
            <div style={{ width: '100px' }}>
              <NumberInput
                value={
                  optionsAndWeight.weight !== null &&
                  optionsAndWeight.weight !== 'null' &&
                  optionsAndWeight.weight !== undefined
                    ? optionsAndWeight.weight
                    : 0
                }
                name="Name"
                min={defaultEnums.OPTION_WEIGHT_MIN_VALUE}
                max={defaultEnums.OPTION_WEIGHT_MAX_VALUE}
                step={1}
                placeholder="0"
                changeValue={e =>
                  handleComponentOptionWeightOnChange(
                    e,
                    index,
                    optionsAndWeight.key,
                    optionsAndWeight.index
                  )}
              />
            </div>
          </div>
        );
      });
    }
    return <></>;
  };

  return renderComponentOptionWeights();
};

export default withTranslation()(OptionWeightsComponent);
