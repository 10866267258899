import React from 'react';

import { Box, Icon } from '..';

import { PrimaryColor, Greys, TagsType, TagsVariant } from '../../utils';
import useTagsService from './Tags.service';

import { TagsProps } from './Tags.interface';

function Tag({
  text,
  closeable = false,
  hasMark = false,
  fill = PrimaryColor.MAIN_COLOR,
  onClose = () => {},
  variant = TagsVariant.CONTAINED,
  type = TagsType.ROUNDED,
  shouldHaveEllipsis = false,
  shouldWidthBeMaxContent = true,
  colorVariant,
}: TagsProps): JSX.Element {
  const { style, tagsClass } = useTagsService({
    text,
    closeable,
    hasMark,
    fill,
    onClose,
    variant,
    type,
    shouldHaveEllipsis,
    shouldWidthBeMaxContent,
    colorVariant,
  });
  return (
    <Box>
      {type !== TagsType.ARROW ? (
        <Box className={tagsClass} otherStyles={style}>
          {hasMark && type !== TagsType.SQUARED ? (
            <Box
              className="tags__marker"
              otherStyles={{ backgroundColor: `${fill}` }}
            />
          ) : null}
          {text}
          {closeable && type !== TagsType.SQUARED && !shouldHaveEllipsis ? (
            <button
              type="button"
              className="tags__close"
              onClick={onClose}
              onPointerDown={onClose}
            >
              <Icon name="close" fill={Greys.LIGHT_GREY} size={12} />
            </button>
          ) : null}
        </Box>
      ) : (
        <Box className={tagsClass}>
          <Box className="tags__outlined-arrow-container">
            <Box
              className="tags__arrow tags__arrow__solid"
              otherStyles={{ borderRightColor: fill }}
            />
            <Box className="tags__arrow tags__arrow__cover" />
          </Box>
          <Box
            className="tags__arrow-text-container"
            otherStyles={{ borderColor: fill, color: Greys.LIGHT_GREY }}
          >
            {text}
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default Tag;
