import React from 'react';

type BoxType = {
  id?: string;
  children?: React.ReactNode;
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  height?: number | string;
  maxHeight?: number | string;
  minHeight?: number | string;
  margin?: number | string;
  padding?: number | string;
  border?: string;
  flex?: number | string;
  order?: number | string;
  className?: string;
  otherStyles?: React.CSSProperties;
  onClick?: () => void;
};

const Box = React.forwardRef<HTMLDivElement, BoxType>(
  (
    {
      id,
      width,
      maxWidth,
      minWidth,
      height,
      maxHeight,
      minHeight,
      margin,
      padding,
      border,
      flex,
      order,
      className,
      children,
      otherStyles,
      onClick,
    },
    ref
  ): JSX.Element => {
    return (
      <div
        id={id}
        ref={ref}
        className={className}
        onClick={onClick}
        style={{
          width,
          maxWidth,
          minWidth,
          height,
          maxHeight,
          minHeight,
          margin,
          padding,
          border,
          flex,
          order,
          ...otherStyles,
        }}
      >
        {children}
      </div>
    );
  }
);

Box.defaultProps = {
  width: '',
  maxWidth: '',
  minWidth: '',
  height: '',
  maxHeight: '',
  minHeight: '',
  margin: '',
  padding: '',
  border: '',
  flex: '',
  order: '',
  className: null,
  otherStyles: null,
};

export default Box;
