import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CaretaskComponent } from '../../../../../../types/Caretask';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { AnsweringComponentsProps } from '../AnsweringTypes';

import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';
import Slider from '../../../../../../../caro-ui-commonfiles/components/Slider/Slider';
import { jumpCalculationForNumericalComponent } from '../../../../../Careplans/CareplanHelperUtility';

export default function AnsweringNumericRange({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);
  const defaultValue: string = currentAnswer?.answer || '';
  const [userInput, setUserInput] = useState<string>(defaultValue);

  useEffect(() => {
    let jumpTo: number = jumpCalculationForNumericalComponent(
      currentComponent,
      userInput
    );
    handleOnChange({
      answer: userInput,
      isSkipped: !userInput,
      jumpTo,
    });
  }, [userInput, handleOnChange]);

  useEffect(() => {
    setUserInput(defaultValue);
  }, [currentComponent]);

  return (
    <Slider
      id={`id-${currentComponent.name}`}
      name={currentComponent.name}
      value={userInput === '' ? '-' : userInput}
      min={currentComponent.minimumValue.toString()}
      max={currentComponent.maximumValue.toString()}
      minLabel={currentComponent.minLabel}
      maxLabel={currentComponent.maxLabel}
      onInput={e => setUserInput(e.target.value)}
      onChange={e => setUserInput(e.target.value)}
      fromAnswerCaretask={true}
    />
  );
}
