import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import TimePicker from '../../DateTimePicker/TimePicker';
import Checkbox from '../../Checkbox/Checkbox';
import Flex from '../../Flex/Flex';
import Box from '../../Box/Box';
import TextInput from '../../TextInput/TextInput';

const MedicationDailyOccurrence = ({
  onValueChange,
  values,
  hasErrors,
  recurrenceErrors,
  setRecurrenceErrors,
  resetDosage,
  unit,
}) => {
  const dosages = [
    {
      id: 'morning',
      label: `${I18n.t('medication.morning')}`,
      input: 'dosageInMorning',
    },
    {
      id: 'midday',
      label: `${I18n.t('medication.midday')}`,
      input: 'dosageInAfternoon',
    },
    {
      id: 'evening',
      label: `${I18n.t('medication.evening')}`,
      input: 'dosageInEvening',
    },
    {
      id: 'night',
      label: `${I18n.t('medication.night')}`,
      input: 'dosageAtNight',
    },
    {
      id: 'customTimeChecked',
      label: `${I18n.t('medication.customTime')}`,
      input: 'dosageSpecificTime',
    },
  ];

  const renderDosages = dosages.map(dosage => (
    <Flex key={`dosage-${dosage.id}`}>
      <Box width="50%">
        <Box margin="10px 0 0 0">
          <Checkbox
            id={dosage.id}
            name={dosage.id}
            label={dosage.label}
            checked={values[dosage.id]}
            handleOnChange={e => {
              setRecurrenceErrors({ ...recurrenceErrors, recurrence: false });
              onValueChange(e.target.checked, dosage.id);
              resetDosage(e.target.checked, dosage.id, dosage.input);
            }}
          />
        </Box>
      </Box>
      <Box width="50%">
        <Box margin="10px 0 0 0">
          {dosage.id === 'customTimeChecked' ? (
            <Box width="100%">
              <TimePicker
                value={values.customTime}
                getTimeStamp={date => {
                  onValueChange(date, 'customTime');
                }}
                isDisabled={!values[dosage.id] === true}
                isMinuteDisabled
              />
              <Box margin="10px 0 0 0">
              <TextInput
                  id={dosage.input}
                  value={values[dosage.input]}
                  name={dosage.input}
                  placeholder="1" 
                  variant="value-right"
                  rightHelperText={unit}
                  isLeftIcon={false}
                  iconName="search"
                  maxChars={20}
                  hasError={hasErrors[dosage.input]}
                  validationMessage={
                    !values[dosage.input].trim().length 
                      ? I18n.t('parsley.requiredField'):  I18n.t('common_labels.valid_number_error')
                  }
                  isDisabled={!values[dosage.id] === true}
                  handleOnChange={event => {
                    onValueChange(event.target.value, dosage.input);
                  }}
                />
              </Box>
            </Box>
          ) : (
              <Box margin="10px 0 0 0">
               <TextInput
                  id={dosage.input}
                  value={values[dosage.input]}
                  name={dosage.input}
                  placeholder="1" 
                  variant="value-right"
                  rightHelperText={unit}
                  isLeftIcon={false}
                  iconName="search"
                  maxChars={20}
                  hasError={hasErrors[dosage.input]}
                  validationMessage={
                    !values[dosage.input].trim().length 
                      ? I18n.t('parsley.requiredField'):  I18n.t('common_labels.valid_number_error')
                  } 
                  isDisabled={!values[dosage.id] === true}
                  handleOnChange={event => {
                    onValueChange(event.target.value, dosage.input);
                  }}
                />
            </Box>
          )}
        </Box>
      </Box>
    </Flex>
  ));

  return <>{renderDosages}</>;
};

MedicationDailyOccurrence.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.array,
      PropTypes.number,
      PropTypes.bool,
    ])
  ),
};

MedicationDailyOccurrence.defaultProps = {
  values: {},
};

export default MedicationDailyOccurrence;
