import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Tag from '../../../../caro-ui-commonfiles/components/Tags/Tags';

import { TagsType } from '@ui-common-files/utils';
import TagColorVariants from '../../../../caro-ui-commonfiles/components/Tags/TagColorVariants.type';
import { decimalNumberFormat } from '../../../Global/Numbers';
import { isScoreSuitable } from './ScoresSection.utils';

const SectionScoresWrapper = ({ element, hasNaNTag }) => {
  const { t } = useTranslation();
  const { score, description } = element;

  const textCondition = isScoreSuitable(score)
    ? decimalNumberFormat(score)
    : t('QuestionCatalog_view.sections.scores.warning.not_a_number');

  return (
    <Flex alignItems={'baseline'} otherStyles={{ marginBottom: '12px' }}>
      <Box margin="0 12px 0 0" otherStyles={{ width: '12%' }}>
        <Tag
          text={textCondition}
          colorVariant={
            isScoreSuitable(score)
              ? TagColorVariants.lightBlue
              : TagColorVariants.lightRed
          }
          type={TagsType.SQUARED}
          shouldWidthBeMaxContent={false}
        />
      </Box>
      <Box margin="0 12px 0 0" otherStyles={{ flex: 0 }}>
        {'='}
      </Box>
      <Box
        margin="0 12px 0 0"
        otherStyles={{ flex: 1, wordBreak: 'break-word' }}
      >
        <Box>
          {description}
        </Box>
        {hasNaNTag &&
          <Box margin="10px 0 0 0">
            <p className="select-patient select-patient--margin-reset">
              {t('QuestionCatalog_view.sections.scores.warning.calculation')}
            </p>
          </Box>
        }
      </Box>
    </Flex>
  );
};

export default SectionScoresWrapper;
