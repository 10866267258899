import { ApiClient } from '@global/Services';
import config from '../../../../../../serviceConfig.json';

import {
  GetAllCareplansResponseType,
  InsuranceType,
} from './FilterCriteriaValue.type';

export const getFilterCareplanNames = () =>
  ApiClient.GET<GetAllCareplansResponseType, null>({
    url: config.brokerService.getAllCareplansWithIdAndName.uri,
  });

export const getFilterInsuranceTypes = () =>
  ApiClient.GET<InsuranceType[], null>({
    url: config.brokerService.getInsuranceTypes.uri,
  });
