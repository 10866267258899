import React, { useState, useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import axios from 'axios';
import moment from 'moment';
import I18n from 'i18next';
import { useSelector } from 'react-redux';
import { getStorage, ref, uploadString } from 'firebase/storage';

import config from '../../../../config';
import serviceConfig from '../../../../serviceConfig.json';
import MessageList from './MessageList';
import SendMessageForm from './SendMessageForm';

import {
  getFileExtension,
  getUserPrimaryInstitutionId,
  isDataImage,
  getMessageObject,
} from '../../../Utils/Chat/chatUtility';
import {
  assetUploadPromise,
  validateFileSize,
  validateFileType,
  validateFileLimit,
} from '../../../Utils/Asset/assetHelper';
import '../../../css/chat.scss';
import { TelemedicineContext } from '../TelemedicineSidebar';

require('image-compressor');

const Chat = ({
  isEnabled,
  patientInfo,
  setFlashMessage,
  careTeamPrimaryInstitutions,
  isChatNotified,
  reloadAssetTable,
  setAllMessagesList,
  reloadChat,
  setReloadChat,
  videoCall,
}) => {
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const {
    attachedFile,
    attachBtnClicked,
    setAttachBtnClicked,
    fileActiveTab,
  } = useContext(TelemedicineContext);

  const [reloadTable, setReloadTable] = useState(false);

  const { user } = useSelector(state => state.session);

  const [messages, setMessages] = useReducer(
    (_state, newMessages) => newMessages,
    false
  );
  const [isPatientTyping, setIsPatientTyping] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [memoizedFileRef, setMemoizedFileRef] = useState(null);

  const userPrimaryInstitution = getUserPrimaryInstitutionId(user);

  const matchedInstitution = patientInfo.institutions.find(institution => {
    return institution.id === userPrimaryInstitution;
  });

  const isPatientUsingNewVersion =
    patientInfo.collectionId &&
    matchedInstitution &&
    matchedInstitution.patientInstitution.encryption;

  const sendChatMessage = async messageObj => {
    try {
      await axios.post(serviceConfig.chatService.sendChatMessage.uri, {
        data: messageObj,
        userId: user.id,
      });
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: I18n.t(error.response.data.content),
      });
    }
  };

  const appendChatMessage = (messageObj, attachedFile) => {
    const dates = Object.keys(messages);
    const today = moment().format('L');
    const lastDate = dates.length - 1;
    const newMessagesObj = { ...messages };
    let updatedMessages;

    let findLastDate = dates[lastDate]
      ? newMessagesObj[dates[lastDate]][0].timestamp
      : dates[lastDate];
    if (findLastDate) findLastDate = moment(findLastDate).format('L');

    if (findLastDate === today) {
      newMessagesObj[dates[lastDate]].push(messageObj);
      updatedMessages = {
        ...newMessagesObj,
      };
    } else if (attachedFile && attachedFile.length > 1) {
      const today = moment().format('ll');
      if (!newMessagesObj[today]) newMessagesObj[today] = new Array();
      fullResponse.map(message => {
        newMessagesObj[today].push(message);
      });

      updatedMessages = {
        ...newMessagesObj,
      };
    } else {
      updatedMessages = {
        ...newMessagesObj,
        [today]: [messageObj],
      };
    }
    setMessages(updatedMessages);
  };

  const handleNewMessage = messageObj => {
    try {
      if (messageObj.isFileData && !messageObj.isPatientInitiated) {
        setIsUploading(true);
        if (isDataImage(messageObj.message)) {
          uploadCompressedImageFileToFirebase(messageObj);
        } else {
          uploadFileToFirebase(messageObj, attachment.data);
        }
      } else {
        if (!messageObj.isPatientInitiated) sendChatMessage(messageObj);
        appendChatMessage(messageObj);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const changePatientTypingStatus = data => {
    const { chatStatusObj } = data;
    if (chatStatusObj !== null) {
      setIsPatientTyping(true);
    } else {
      setIsPatientTyping(false);
    }
  };

  useEffect(() => {
    setAllMessagesList(messages);
  }, [messages]);

  useEffect(() => {
    const socket = io(`${window.location.host}`, config.socketOptions);

    if (careTeamPrimaryInstitutions && messages) {
      careTeamPrimaryInstitutions.map(institution => {
        if (institution.userId === user.id) {
          socket.on(
            `${patientInfo.firebaseId}/${institution.institutionId}/notifyChatClient`,
            handleNewMessage
          );
          socket.on(
            `${patientInfo.firebaseId}_${institution.institutionId}_${user.id}/typingStatus`,
            changePatientTypingStatus
          );
        }
      });
    }

    return function cleanup() {
      if (socket) {
        careTeamPrimaryInstitutions.map(institution => {
          if (institution.userId === user.id) {
            socket.removeListener(
              `${patientInfo.firebaseId}/${institution.institutionId}/notifyChatClient`,
              handleNewMessage
            );
            socket.removeListener(
              `${patientInfo.firebaseId}_${institution.institutionId}_${user.id}/typingStatus`,
              changePatientTypingStatus
            );
          }
        });
        socket.close();
      }
    };
  }, [messages]);

  const sendSiteTimeStamp = async () => {
    try {
      await axios.post(serviceConfig.chatService.sendSiteTimestamp.uri, {
        patientFirebaseId: patientInfo.firebaseId,
        userId: user.id,
        timestamp: moment().toISOString(),
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const getChatHistory = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          serviceConfig.chatService.getChatHistory.uri,
          {
            params: {
              patientId: patientInfo.id,
            },
          }
        );

        const { data } = response;
        setMessages(data);
        setReloadChat(false);
        const chatMessageIds = [];
        let patientId;

        Object.keys(data).forEach(key => {
          const record = data[key];
          for (let i = 0; i < record.length; i++) {
            if (record[i].users.length !== 0) {
              if (
                record[i].isPatientInitiated === true &&
                record[i].users[0].userChatMessage.showInDropdown === true
              ) {
                chatMessageIds.push(record[i].id);
                patientId = record[i].patientId;
              }
            } else if (record[i].isPatientInitiated === true) {
              chatMessageIds.push(record[i].id);
              patientId = record[i].patientId;
            }
          }
        });

        if (chatMessageIds.length > 0) {
          await axios.post(
            serviceConfig.chatService.setMessageNotificationsToRead.uri,
            {
              chatMessageIds,
              patientId,
            }
          );
        }
      } catch (error) {
        setReloadChat(false);
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      }
      setLoading(false);
    };

    const getTypingStatus = async () => {
      try {
        await axios.post(serviceConfig.chatService.getTypingStatus.uri, {
          patientFirebaseId: patientInfo.firebaseId,
          userId: user.id,
        });
      } catch (error) {
        throw new Error(error);
      }
    };

    if (patientInfo) {
      getChatHistory();
      getTypingStatus();

      const storage = getStorage();
      const patientFilesRef = ref(
        storage,
        `patients/${patientInfo.firebaseId}/files/`
      );

      setMemoizedFileRef(patientFilesRef);
    }
  }, [patientInfo, isChatNotified, reloadChat]);

  const sendTypingStatus = async status => {
    try {
      await axios.post(serviceConfig.chatService.sendTypingStatus.uri, {
        patientFirebaseId: patientInfo.firebaseId,
        status,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  let fullResponse = new Array();

  const sendMessage = async (
    text,
    isFileObject,
    assetId,
    assetDirectLink,
    thumbnailURL,
    attachedFile
  ) => {
    const timestamp = moment().toISOString();
    const message = !isFileObject
      ? text
      : assetDirectLink
        ? { fileName: text, assetId, assetDirectLink, thumbnailURL }
        : { fileName: text, assetId };
    const messageObj = getMessageObject(
      timestamp,
      message,
      isFileObject,
      patientInfo,
      user
    );

    try {
      const response = await axios.post(
        serviceConfig.chatService.createChatMessage.uri,
        {
          data: messageObj,
        }
      );
      if (response) {
        if (isPatientUsingNewVersion) {
          messageObj.id = response.data.id;
          fullResponse.push({
            ...response.data,
            assetDirectLink,
            thumbnailURL,
          });
          setIsUploading(false);
          appendChatMessage(
            { ...response.data, assetDirectLink, thumbnailURL },
            attachedFile
          );
          setAttachBtnClicked(false);
          reloadAssetTable();
          sendChatMessage(messageObj);
        } else if (response) handleNewMessage(response.data);
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: I18n.t(error.response.data.content),
      });
    }
  };

  useEffect(() => {
    if (attachBtnClicked) {
      attachedFile.map(file => {
        if (isPatientUsingNewVersion) {
          sendMessage(
            file.name,
            file.name.length > 0,
            file.id,
            file.directLink,
            file.thumbnailURL,
            attachedFile
          ).then(() => {
            reloadAssetTable();
          });
        } else {
          sendMessage(file.name, file.name.length > 0, file.id);
        }
      });
    }
  }, [attachBtnClicked, attachedFile]);

  const uploadFileToFirebase = (messageObj, dataToUpload) => {
    try {
      const modifiedName = messageObj.message.replace(/\s+/g, '_');
      messageObj.message = modifiedName;

      const filesStorageRef = ref(
        memoizedFileRef,
        `${moment(messageObj.timestamp).format('x')}_${messageObj.message}`
      );

      uploadString(filesStorageRef, dataToUpload, 'data_url');

      const fileUploadTask = uploadBytesResumable(
        filesStorageRef,
        dataToUpload
      );

      fileUploadTask.on('state_changed', {
        next: null,
        error: error => {
          throw new Error(error);
        },
        complete: function complete() {
          setIsUploading(false);
          appendChatMessage(messageObj);
          setAttachment(null);
          sendChatMessage(messageObj);
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const uploadCompressedImageFileToFirebase = messageObj => {
    const imageCompressor = new ImageCompressor();
    const compressorSettings = {
      toHeight: 300,
      mimeType: `image/${getFileExtension(messageObj.message).toLowerCase()}`,
      mode: 'strict',
      quality: 0.4,
      speed: 'high',
    };

    imageCompressor.run(
      attachment.data,
      compressorSettings,
      proceedCompressedImage
    );

    // Create callback only as ES5 syntax so the library can recognize as a function
    function proceedCompressedImage(compressedSrc) {
      uploadFileToFirebase(messageObj, compressedSrc);
    }
  };

  const handleImageUpload = () => {
    const fileList = [attachment.file];
    setAttachment(null);
    let errorMessage = '';

    const isFileLimitCorrect = validateFileLimit(fileList);
    const isFileTypeCorrect = validateFileType(fileList);
    const isFileSizeCorrect = validateFileSize(fileList);

    if (isFileLimitCorrect && isFileSizeCorrect && isFileTypeCorrect) {
      setLoading(true);
      setDisableBtn(true);
      const formData = new FormData();
      for (let i = 0; i < fileList.length; i++) {
        formData.append('filedata', fileList[i]);
      }
      formData.append('isChat', true);
      if (isPatientUsingNewVersion) {
        return axios
          .post(serviceConfig.brokerService.createAsset.uri, formData, {
            timeout: 3000000000,
            headers: {
              'Content-Type': 'multipart/form-data',
              'Content-Disposition': 'form-data',
            },
          })
          .then(response => {
            const { assetTypeId } = response.data;
            setLoading(false);
            setReloadTable(assetTypeId);
            setIsUploading(true);
            return assetUploadPromise(
              response,
              fileList[0],
              setFlashMessage,
              true,
              patientInfo.collectionId
            )
              .then(results => {
                let shouldSkipAssetInstitutionsCheck;
                if (fileActiveTab === undefined) {
                  shouldSkipAssetInstitutionsCheck = true;
                } else shouldSkipAssetInstitutionsCheck = false;
                return axios
                  .get(
                    `${serviceConfig.brokerService.getAssetById.uri}${results[0].id}`,
                    {
                      params: {
                        shouldSkipAssetInstitutionsCheck,
                      },
                    }
                  )
                  .then(response => {
                    const {
                      data: { assetData },
                    } = response;

                    sendMessage(
                      assetData.name,
                      assetData.name.length > 0,
                      assetData.id,
                      assetData.directLink,
                      assetData.thumbnailURL
                    ).then(() => {
                      reloadAssetTable();
                    });
                  })
                  .catch(error => {
                    setIsUploading(false);
                    setLoading(false);
                    setDisableBtn(false);
                    setFlashMessage({
                      content: I18n.t(error.response.data.content),
                      type: error.response.data.type,
                    });
                  });
              })
              .catch(() => {
                setIsUploading(false);
                setAttachment(null);
              });
          })
          .catch(error => {
            setAttachment(null);
            setLoading(false);
            setDisableBtn(false);
            setFlashMessage({
              content: error.response.data.content,
              type: error.response.data.type,
            });
          });
      }
      sendMessage(fileList[0].name, fileList[0].name.length > 0);
    }

    if (!isFileLimitCorrect) {
      errorMessage = 'flash.maxNumberOfFilesUpload';
    } else if (!isFileSizeCorrect) {
      errorMessage = 'flash.maxFileSize';
    } else if (!isFileTypeCorrect) {
      errorMessage = 'flash.incorrectFileType';
    }
    setFlashMessage({
      status: 403,
      content: errorMessage,
      type: 'warning',
    });
  };

  return (
    <>
      {!videoCall && (
        <MessageList
          messages={messages}
          isPatientTyping={isPatientTyping}
          patientInfo={patientInfo}
          sendSiteTimeStamp={sendSiteTimeStamp}
          fileRef={memoizedFileRef}
          loading={loading}
          isUploading={isUploading}
          reloadChat={reloadChat}
          isPatientUsingNewVersion={!!isPatientUsingNewVersion}
        />
      )}
      {!videoCall && (
        <SendMessageForm
          isEnabled={isEnabled}
          startTyping={() => sendTypingStatus('true')}
          stopTyping={() => sendTypingStatus('false')}
          sendMessage={sendMessage}
          fileName={attachment ? attachment.file.name : ''}
          handleImageUpload={handleImageUpload}
          handleAttachment={attach => {
            setAttachment(attach);
          }}
          isPatientUsingNewVersion={!!isPatientUsingNewVersion}
        />
      )}
    </>
  );
};

Chat.propTypes = {
  closeChat: PropTypes.func.isRequired,
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ])
  ).isRequired,
  setFlashMessage: PropTypes.func.isRequired,
  careTeamPrimaryInstitutions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default Chat;
