import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { emailRegex } from '@global/Utils';
import userRoles from '@utils/roles.json';
import tabPosition from '@utils/Administration/tabPosition';
import { ShowFlashMessage } from '@global/Services';
import { FlashMessageTypes } from '@ui-common-files/types';

import {
  getAllSitesForModals,
  addSatellite,
  editSatelliteRequest,
  updateSatelliteRequest,
} from './Satellites.api';
import { SatelliteData, SatelliteFields } from './Satellite.types';

export function useSatelliteForm({
  closeModal,
  reloadTable,
  setSelectedSite,
  setSites,
  selectedSite,
  id,
}) {
  const [loading, setLoading] = useState(false);
  const [satelliteFields, setSatelliteFields] = useState<SatelliteFields>({
    name: { hasError: false, errorMessage: '' },
    address: { hasError: false, errorMessage: '' },
    email: { hasError: false, errorMessage: '' },
    phoneNumber: { hasError: false, errorMessage: '' },
    serviceProviderId: { hasError: false, errorMessage: '' },
  });
  const [satelliteData, setSatelliteData] = useState<SatelliteData>({
    name: '',
    address: '',
    phoneNumber: '',
    email: '',
    serviceProviderId: '',
  });

  const { user } = useSelector(state => state.session);

  const fetchEditSatelliteData = async id => {
    setLoading(true);

    const response = await editSatelliteRequest(id);
    setLoading(false);

    if (response?.data) {
      const {
        name,
        address,
        phoneNumber,
        email,
        institution,
        serviceProviderId,
      } = response.data;

      setSatelliteData({
        name,
        address: { value: address, label: address },
        phoneNumber,
        email,
        institution,
        serviceProviderId: serviceProviderId ?? '',
      });
      setSelectedSite({ label: institution.name, value: institution.id });
    }
  };

  useEffect(() => {
    if (id !== null) {
      fetchEditSatelliteData(id);
    }
  }, [id]);

  const onChangeField = (fieldName, value) => {
    setSatelliteData(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    const fetchSitesData = async () => {
      const getAllSitesForModalsResponse = await getAllSitesForModals();
      const allSites = getAllSitesForModalsResponse.data.allSites;
      const institutions = allSites.map(site => ({
        label: site.name,
        value: site.id,
      }));

      if (user.role.name === userRoles.SITE_ADMIN) {
        const userInstitution = user.institutions[0];
        const currentUserInstitution = {
          label: userInstitution.name,
          value: userInstitution.id,
        };
        setSites([currentUserInstitution]);
        setSelectedSite(currentUserInstitution);
      } else if (id === null) {
        setSites(institutions);
        setSelectedSite(institutions[0]);
      }
    };

    fetchSitesData();
  }, [setSites, setSelectedSite, id, user]);

  const handleSiteChange = selectedOption => {
    setSelectedSite(selectedOption);
  };
  const validateInputs = () => {
    const inputValidationErrors: SatelliteFields = {
      name: { hasError: !satelliteData.name, errorMessage: '' },
      address: { hasError: !satelliteData.address, errorMessage: '' },
      email: {
        hasError:
          !satelliteData.email ||
          !emailRegex.test(String(satelliteData.email).toLowerCase()),
        errorMessage: '',
      },
      phoneNumber: {
        hasError:
          !satelliteData.phoneNumber ||
          !isValidPhoneNumber(satelliteData.phoneNumber),
        errorMessage: '',
      },
    };

    const setError = (field, errorKey) => {
      inputValidationErrors[field].errorMessage = inputValidationErrors[field]
        .hasError
        ? errorKey
        : '';
    };

    setError('name', 'parsley.requiredField');
    setError('address', 'parsley.requiredField');
    setError('email', 'common_labels.invalid_email');
    setError('phoneNumber', 'site_view.invalidPhone');

    setSatelliteFields(inputValidationErrors);

    return inputValidationErrors;
  };

  const handleSatelliteAction = async (
    satelliteRequestAction,
    successMessageKey,
    id
  ) => {
    setLoading(true);

    const { address, ...restFormData } = satelliteData;
    const editedId = id ?? undefined;

    const response = await satelliteRequestAction(
      {
        ...restFormData,
        address: address.label,
        institutionId: selectedSite.value,
        id: editedId,
      },
      editedId
    );

    if (response) {
      closeModal();
      reloadTable(tabPosition.SEVENTH);
      ShowFlashMessage({
        type: FlashMessageTypes.Success,
        messageKey: successMessageKey,
        isModalMessage: false,
      });
    }

    setLoading(false);
    closeModal();
  };

  const createOrUpdateSatellite = isCreate => {
    const satelliteRequestAction = isCreate
      ? addSatellite
      : updateSatelliteRequest;
    const successMessageKey = isCreate
      ? 'satellite_view.satelliteCreated'
      : 'satellite_view.satelliteUpdated';

    handleSatelliteAction(satelliteRequestAction, successMessageKey, id);
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    const validationErrors = validateInputs();
    const hasErrors = Object.values(validationErrors).some(
      field => field.hasError
    );

    if (!hasErrors) {
      createOrUpdateSatellite(id === null);
    }
  };

  return {
    loading,
    satelliteData,
    satelliteFields,
    handleFormSubmit,
    handleSiteChange,
    onChangeField,
  };
}
