import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
  useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ModalContent,
  TextInput,
  Button,
  IconButton,
  Flex,
  Icon,
  Box,
  InputFieldWithCopy
} from '@ui-common-files/components';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

import serviceConfig from '../../../serviceConfig.json';
import {
  ButtonType,
  PrimaryColor,
  isValidPositiveSingleDigit,
} from '@ui-common-files/utils';

import '../../css/patientOverview.scss';

const QrCodeGenerator = ({
  closeModal,
  setFlashMessage,
  patientInfo,
  qrPin,
  isPatientDemographicData
}) => {
  const {t} = useTranslation()
  const { user } = useSelector(state => state.session);
  const [qrCodeImg, setQrCodeImg] = useReducer((_state, data) => data, '');
  const [pin, setPin] = useState({
    0: qrPin ? qrPin[0] : '*',
    1: qrPin ? qrPin[1] : '*',
    2: qrPin ? qrPin[2] : '*',
    3: qrPin ? qrPin[3] : '*',
  });
  const [refreshQrCode, setRefreshQrCode] = useState(false);
  const [QRCodeURL, setQRCodeURL] = useState("");
  const [pageNumber, setPageNumber] = useReducer((_state, number) => number, 1);
  const [letterNumPages, setLetterNumPages] = useReducer(
    (_state, number) => number,
    2
  );
  const [pdfData, setPdfData] = useReducer((_state, data) => data, '');
  const [qrCodeUuid, setQrCodeUuid] = useReducer((_state, data) => data, '');
  const pinContainerRef = useRef(null);
  const downloadBtn = useRef(null);
  const [isImageLoading, setIsImageLoading] = useReducer(
    (_state, isLaoding) => isLaoding,
    true
  );
  const [isPdfLoading, setIsPdfLoading] = useReducer(
    (_state, isLaoding) => isLaoding,
    true
  );
  const patientId = patientInfo ? patientInfo.id : useParams().patientId;

  const onPinChange = (value, pos) => {
    const newInput = value.substr(value.length - 1);
    if (isValidPositiveSingleDigit(newInput)) {
      setPin({
        ...pin,
        [pos]: newInput,
      });
    }
  };

  const { setDisableDownloadInvitation } = useContext(PatientOverviewContext);

  const renderLoader = () => (
    <div className="pdf-loader-container">
      <Loader
        type="Oval"
        color={PrimaryColor.MAIN_COLOR}
        height={60}
        width={60}
      />
    </div>
  );

  const onDocumentLoadSuccess = ({ numPages }) => {
    setLetterNumPages(numPages);
    setDisableDownloadInvitation(false);
  };

  const getQrCodeImage = useCallback(async () => {
    try {
      setIsImageLoading(true);
      if (qrPin) {
        const qrCodeImageResponse = await axios.post(
          serviceConfig.brokerService.getQrCodeImage.uri + patientId,
          {
            pkey: pin['0'] + pin['1'] + pin['2'] + pin['3'],
          }
        );

        if (qrCodeImageResponse) {
          setQrCodeImg(qrCodeImageResponse.data.qrcodePng);
          setQRCodeURL(qrCodeImageResponse.data.qrcodeData)
          setIsImageLoading(false);
        }
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  }, [closeModal, patientId, pin, qrPin, setFlashMessage, user.id]);

  const getInvitationLetter = useCallback(async () => {
    try {
      setIsPdfLoading(true);
      if (qrPin) {
        const invitationLetterResponse = await axios.post(
          serviceConfig.brokerService.getInvitationLetter.uri + patientId,
          {
            pkey: pin['0'] + pin['1'] + pin['2'] + pin['3'],
          }
        );

        if (invitationLetterResponse) {
          setQrCodeUuid(invitationLetterResponse.data.qrCodeUuid);
          setPdfData(invitationLetterResponse.data.pdfData);
          setIsPdfLoading(false);
        }
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  }, [closeModal, patientId, pin, qrPin, setFlashMessage, user.id]);

  useEffect(() => {
    getQrCodeImage();
    getInvitationLetter();
  }, [refreshQrCode]);

  const downloadInvitationLetter = event => {
    event.preventDefault();
    const downloadElem = document.getElementById('download-btn');
    downloadElem.href = pdfData;
    downloadElem.click();
    downloadElem.href = '';
  };

  return (
    <ModalContent>
      <form id="generate-invitation" onSubmit={downloadInvitationLetter}>
        <Flex flexDirection="row">
          <div id="qrcode-container" ref={pinContainerRef}>
            <div className="section-container ">
              <div>{t('common_labels.QRCode')}</div>
              <div id="qrCode" style={{ marginLeft: -8 }}>
                {isImageLoading && renderLoader()}
                {!isImageLoading && <img alt="qrCodeImg" src={qrCodeImg} />}

                <Box className="registrationLinkContainer">
                  <Box className="registrationLink">
                    {t('common_labels.registrationLink')}
                  </Box>
                  <InputFieldWithCopy
                    iconName="Copy"
                    buttonToolTip={t('toolTipsText.copy')}
                    value={QRCodeURL}
                    isDisabled
                    />
                </Box>
              </div>
              <div>{t('common_labels.placeholder_password')}</div>
              <div className="description-container">
                {t('patientOverview.keyDescription')}
              </div>
              <div id="qrCodePin">
                <div className="pin-digit-container">
                  <TextInput
                    value={pin['0']}
                    hasError={false}
                    id="pin-digit-0"
                    name="pin-digit-0"
                    placeholder="*"
                    handleOnChange={event =>
                      onPinChange(event.target.value, '0')
                    }
                    style={{ textAlign: 'center' }}
                    validationMessage={t('parsley.requiredField')}
                  />
                </div>
                <div className="pin-digit-container">
                  <TextInput
                    value={pin['1']}
                    hasError={false}
                    id="pin-digit-1"
                    name="pin-digit-1"
                    placeholder="*"
                    handleOnChange={event =>
                      onPinChange(event.target.value, '1')
                    }
                    style={{ textAlign: 'center' }}
                    validationMessage={t('parsley.requiredField')}
                  />
                </div>
                <div className="pin-digit-container">
                  <TextInput
                    value={pin['2']}
                    hasError={false}
                    id="pin-digit-2"
                    name="pin-digit-2"
                    placeholder="*"
                    handleOnChange={event =>
                      onPinChange(event.target.value, '2')
                    }
                    style={{ textAlign: 'center' }}
                    validationMessage={t('parsley.requiredField')}
                  />
                </div>
                <div className="pin-digit-container">
                  <TextInput
                    value={pin['3']}
                    hasError={false}
                    id="pin-digit-3"
                    name="pin-digit-3"
                    placeholder="*"
                    handleOnChange={event =>
                      onPinChange(event.target.value, '3')
                    }
                    style={{ textAlign: 'center' }}
                    validationMessage={t('parsley.requiredField')}
                  />
                </div>
                <button
                  type="button"
                  className="pin-digit-container"
                  onClick={event => {
                    event.preventDefault();
                    setDisableDownloadInvitation(true);
                    setRefreshQrCode(!refreshQrCode);
                  }}
                >
                  <Icon name="sync" size={30} />
                </button>
              </div>
              <div className="description-container-sm">
                {t('patientOverview.keyInstruction')}
              </div>
            </div>
          </div>
          <div id="invitation-preview">
            {isPdfLoading && renderLoader()}
            {!isPdfLoading && (
              <div className="overlay-container">
                <Document
                  file={pdfData}
                  onLoadError={err => {
                    closeModal();
                    setFlashMessage({ type: 'error', content: err.message });
                  }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    width={
                      pinContainerRef.current
                        ? pinContainerRef.current.offsetWidth - 35
                        : 0
                    }
                    pageNumber={pageNumber}
                  />
                  <div className="overlay">
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      otherStyles={{ height: '100%', paddingHorizontal: 10 }}
                    >
                      <div className="navigation-btn">
                        {pageNumber > 1 && (
                          <IconButton
                            name="arrowLeft"
                            fill={PrimaryColor.MAIN_COLOR}
                            size={35}
                            onClick={() => setPageNumber(pageNumber - 1)}
                          />
                        )}
                      </div>
                      <div className="navigation-btn">
                        {pageNumber < letterNumPages && (
                          <IconButton
                            name="arrowRight"
                            fill={PrimaryColor.MAIN_COLOR}
                            size={35}
                            onClick={() => setPageNumber(pageNumber + 1)}
                          />
                        )}
                      </div>
                    </Flex>
                  </div>
                </Document>
              </div>
            )}
            <a
              id="download-btn"
              download={
                !isPatientDemographicData
                  ? `${patientInfo.firstname} ${patientInfo.lastname}_${qrCodeUuid}.pdf`
                  : `${qrCodeUuid}.pdf`
              }
              ref={downloadBtn}
            />
          </div>
        </Flex>
      </form>
    </ModalContent>
  );
};

const QrCodeGeneratorFooter = () => {
  const { disableDownloadInvitation } = useContext(PatientOverviewContext);
  const {t} = useTranslation()

  return (
    <span
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div className="footer-text">
        {t('patientOverview.qrCodeUpdate')}
      </div>
      <Button
        disabled={disableDownloadInvitation}
        label={t('patientOverview.downloadInvitation')}
        type={ButtonType.SUBMIT}
        form="generate-invitation"
        variant="contained"
      />
    </span>
  );
};

QrCodeGenerator.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setFlashMessage: PropTypes.func.isRequired,
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
    ])
  ).isRequired,
  qrPin: PropTypes.string.isRequired,
};

export { QrCodeGenerator, QrCodeGeneratorFooter };
