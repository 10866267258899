import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, DropdownMenu } from '@ui-common-files/components';
import { InformationIcon, LanguageIcon } from '../Icons';
import { TopBarDropDownIconProps } from './TopBarDropDownIcons.type';

const TopBarDropDownIcon: React.FC<TopBarDropDownIconProps> = ({
  iconName,
  items,
  onClick,
}) => {
  const { t } = useTranslation();

  const buildItems = () => {
    return items.map(item => (
      <li key={item.key} onClick={e => onClick(e, item)}>
        <span
          className={`dropdown__item${
            item.value === t('common_labels.label_delete') ||
            item.value === t('asset_view.detach')
              ? ' icon-dropdown__delete'
              : ''
          }`}
        >
          {iconName === 'information' ? item.value : item.key}
        </span>
      </li>
    ));
  };

  const renderIcon = () => {
    if (iconName === 'information') {
      return <InformationIcon />;
    } else if (iconName === 'language') {
      return <LanguageIcon />;
    }
    return null;
  };

  return (
    <DropdownMenu items={buildItems()}>
      <Flex className="icon-dropdown">
        <Flex className="icon-dropdown__button">{renderIcon()}</Flex>
      </Flex>
    </DropdownMenu>
  );
};

export default TopBarDropDownIcon;
