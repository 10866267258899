import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../Flex/Flex';
import Box from '../Box/Box';
import Tooltip from '../Tooltip/Tooltip';
import Position from '../../utils/positions';

import { useResponsiveScreen } from '../../utils/hooks';
import { SCREEN_CATEGORIES } from '../../utils/layout';

const IcdTag = ({ code }) => (
  <div className="icds_container">
    <div className="icds_text">{code}</div>
  </div>
);

const HiddenIcd = ({ icds }) => {
  const tooltipComponent = (
    <Flex flexDirection="column">
      {icds.map(icd => (
        <Box margin="4px 0">
          <IcdTag code={icd.code} />
        </Box>
      ))}
    </Flex>
  );
  return (
    <Tooltip content={tooltipComponent} position={Position.bottom}>
      <div style={{ cursor: 'default' }}> ... </div>
    </Tooltip>
  );
};

const IcdsComponent = ({ icds, hiddenIcds }) => (
  <Flex alignItems="center">
    {icds.map(icd => (
      <Box margin="0 8px 0 0">
        <IcdTag code={icd.code} />
      </Box>
    ))}
    {hiddenIcds.length > 0 && <HiddenIcd icds={hiddenIcds} />}
  </Flex>
);

const ICDViewer = ({ icds, shouldShowAll, fromAssignModal }) => {
  const screenSize = useResponsiveScreen();
  const renderICDViewerComponent = () => {
    if (shouldShowAll) {
      return (
        <IcdsComponent icds={icds.slice(0, icds.length)} hiddenIcds={[]} />
      );
    } else {
      if (fromAssignModal) {
        return (
          <IcdsComponent icds={icds.slice(0, 1)} hiddenIcds={icds.slice(1)} />
        );
      } else {
        switch (screenSize) {
          case SCREEN_CATEGORIES.SCREEN_SM_IPAD: {
            return (
              <IcdsComponent
                icds={icds.slice(0, 1)}
                hiddenIcds={icds.slice(1)}
              />
            );
          }
          case SCREEN_CATEGORIES.SCREEN_MD:
          case SCREEN_CATEGORIES.SCREEN_L: {
            return (
              <IcdsComponent
                icds={icds.slice(0, 1)}
                hiddenIcds={icds.slice(1)}
              />
            );
          }
          default: {
            return (
              <IcdsComponent
                icds={icds.slice(0, 3)}
                hiddenIcds={icds.slice(3)}
              />
            );
          }
        }
      }
    }
  };
  return icds.length > 0 ? renderICDViewerComponent() : null;
};

ICDViewer.propTypes = {
  icds: PropTypes.arrayOf(PropTypes.object),
};

ICDViewer.defaultProps = {
  icds: [],
};

export default ICDViewer;
