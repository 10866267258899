import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useShallow } from 'zustand/react/shallow';

import NavigationJSON from '@utils/navigation.json';

import { ShowFlashMessage, useAnalytics } from '@global/Services';
import { useCarePathWayBuilderStore } from '../../../CarePathWayBuilder.store';
import { submitCarePathWayData } from './PathWayReviewModalFooter.api';

const usePathWayReviewModalFooterService = () => {
  const { id } = useParams();
  const history = useHistory();
  const isEditMode: boolean = !!id;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { trackEventTrigger } = useAnalytics();
  const currentUser = useSelector(state => state.session).user;
  const { pathWayReview, setPathWayReview, transformPathWayObject } =
    useCarePathWayBuilderStore(
      useShallow(state => ({
        pathWayReview: state.pathWayReview,
        setPathWayReview: state.setPathWayReview,
        transformPathWayObject: state.transformPathWayObject,
      }))
    );

  const submitCarePathWayTemplate = () => {
    const dataToSendToBackend = transformPathWayObject(
      currentUser.institutions
    );

    setIsLoading(true);

    submitCarePathWayData(isEditMode, dataToSendToBackend, id)
      .then(response => {
        if (response) {
          trackEventTrigger({
            category: 'carepathway',
            action: id ? 'Updated-Successful' : 'Create-Successful',
          });
          ShowFlashMessage({
            type: response.data.type || response.data.message.type,
            messageKey: response.data.content || response.data.message.content,
            isModalMessage: false,
          });
          setPathWayReview({
            title: null,
            id: null,
            isOpen: false,
          });
          if (pathWayReview.shouldCloseBuilder) {
            history.push(`/${NavigationJSON.CAREPLAN}`, {
              redirectToCarePathWayList: true,
            });
          } else if (!id) {
            history.replace(
              `/${NavigationJSON.CAREPATHWAY_BUILDER_EDIT.replace(
                ':id',
                response.data.data.carepathwayTemplateId
              )}`
            );
          }
        } else {
          trackEventTrigger({
            category: 'carepathway',
            action: id ? 'Update-Failed' : 'Create-Failed',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    setPathWayReview,
    submitCarePathWayTemplate,
    pathWayReview,
  };
};

export default usePathWayReviewModalFooterService;
