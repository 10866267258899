enum Position {
  top = 'top',
  topRight = 'top-right',
  topCenter = 'top-center',
  topLeft = 'top-left',
  bottom = 'bottom',
  bottomRight = 'bottom-right',
  bottomCenter = 'bottom-center',
  bottomLeft = 'bottom-left',
  right = 'right',
  left = 'left',
}

export default Position;
