import React, { memo } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import ModalSection from '../common/Layout/Modal/ModalSection';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import TextInput from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Dropdown from '../../../caro-ui-commonfiles/components/Dropdown/Dropdown';

const TaskModalContentForm = ({
  values,
  recurrenceComponent,
  icdSelectorComponent,
  onValueChange,
  hasErrors,
  isUpdate,
  allRoles,
  allPhaseType,
  allTaskType,
  allTaskPriority,
}) => {
  return (
    <>
      <ModalSection>
        <ComponentWithLabel
          id="name"
          htmlFor="Name of the task"
          hasIcon={false}
          labelText={`* ${I18n.t('task.taskName')}`}
          tooltipText={I18n.t('task.taskName')}
          hasToggleButton={false}
          idToggleButton="toggleBtn"
          valueToggleButton="toggleBtn"
          isDisabledToggleButton={false}
          checked={false}
        >
          <TextInput
            value={values.name}
            id="name"
            name="name"
            placeholder={I18n.t('task.add_taskName')}
            handleOnChange={event => onValueChange(event.target.value, 'name')}
            hasError={hasErrors.name}
            validationMessage={I18n.t('medication.atLeast6Characters')}
          />
        </ComponentWithLabel>
        <ComponentWithLabel
          id="taskType"
          htmlFor="taskType"
          hasIcon={false}
          labelText={I18n.t('task.taskType')}
          tooltipText={I18n.t('task.taskType')}
          hasToggleButton={false}
          idToggleButton="toggleBtn"
          valueToggleButton="toggleBtn"
          isDisabledToggleButton={false}
          checked={false}
        >
          <div>
            <Dropdown
              value={values.taskType}
              options={allTaskType}
              isDisabled={isUpdate}
              placeholder={I18n.t('task.taskType')}
              handleOnChange={event => onValueChange(event, 'taskType')}
            />
          </div>
        </ComponentWithLabel>
        {values.taskType.value === 1 && (
          <ComponentWithLabel
            id="Url"
            htmlFor="Url"
            hasIcon={false}
            labelText="Url"
            tooltipText="Url"
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <div>
              <TextInput
                value={values.URL}
                id="Url"
                name="Url"
                placeholder="Url"
                rightHelperText="words"
                isLeftIcon={false}
                iconName="search"
                maxChars={10}
                handleOnChange={event =>
                  onValueChange(event.target.value, 'URL')
                }
                hasError={hasErrors.URL}
                validationMessage={I18n.t('task.validUrl')}
              />
            </div>
          </ComponentWithLabel>
        )}
        <ComponentWithLabel
          id="PhaseType"
          htmlFor="PhaseType"
          hasIcon={false}
          labelText={I18n.t('task.phaseType')}
          tooltipText="Phase Type"
          hasToggleButton={false}
          idToggleButton="toggleBtn"
          valueToggleButton="toggleBtn"
          isDisabledToggleButton={false}
          checked={false}
        >
          <div>
            <Dropdown
              value={values.phaseType}
              options={allPhaseType}
              isDisabled={isUpdate}
              placeholder={I18n.t('task.phaseType')}
              handleOnChange={event => onValueChange(event, 'phaseType')}
            />
          </div>
        </ComponentWithLabel>
        <ComponentWithLabel
          id="taskPriority"
          htmlFor="taskPriority"
          hasIcon={false}
          labelText={I18n.t('task.taskPriority')}
          tooltipText="Task Priority"
          hasToggleButton={false}
          idToggleButton="toggleBtn"
          valueToggleButton="toggleBtn"
          isDisabledToggleButton={false}
          checked={false}
        >
          <div>
            <Dropdown
              value={values.taskPriority}
              options={allTaskPriority}
              isDisabled={isUpdate}
              placeholder={I18n.t('task.taskPriority')}
              handleOnChange={event => onValueChange(event, 'taskPriority')}
            />
          </div>
        </ComponentWithLabel>
        <ComponentWithLabel
          id="role"
          htmlFor="role"
          hasIcon={false}
          labelText={I18n.t('admin_view.label_userRole')}
          tooltipText={I18n.t('admin_view.label_userRole')}
          hasToggleButton={false}
          idToggleButton="toggleBtn"
          valueToggleButton="toggleBtn"
          isDisabledToggleButton={false}
          checked={false}
        >
          <Dropdown
            value={values.role}
            options={allRoles}
            isDisabled={isUpdate}
            placeholder={I18n.t('admin_view.label_userRole')}
            handleOnChange={event => onValueChange(event, 'role')}
          />
        </ComponentWithLabel>
        <ComponentWithLabel
          id="icds"
          htmlFor="icds"
          labelText={I18n.t('task.ICDReference')}
        >
          {icdSelectorComponent}
        </ComponentWithLabel>
      </ModalSection>

      <ModalSection>{recurrenceComponent}</ModalSection>
    </>
  );
};

TaskModalContentForm.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  recurrenceComponent: PropTypes.element.isRequired,
  icdSelectorComponent: PropTypes.element.isRequired,
  onValueChange: PropTypes.func.isRequired,
  hasErrors: PropTypes.objectOf(PropTypes.bool).isRequired,
  isUpdate: PropTypes.bool,
  allRoles: PropTypes.arrayOf(PropTypes.object),
};

TaskModalContentForm.defaultProps = {
  allRoles: [],
  isUpdate: false,
};

export default memo(TaskModalContentForm);
