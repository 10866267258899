import React, { useContext, useEffect, useState } from 'react';
import I18n from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

import { Box, Flex, IconButton } from '@ui-common-files/components';
import {
  Greys,
  Actions,
  ActionTranslationKeys,
  ComponentTranslationKeys,
  PrimaryColor,
  ComponentTypes,
} from '@ui-common-files/utils';

import CAREPATHWAY_STATUS from '../../Utils/carePathwayStatus';
import checkUserCredential from '../../Utils/checkUserCredential';
import { MEDIA_QUERIES } from '@ui-common-files/utils/layout';
import { ViewCareplanModalFooter } from '../Careplans/ViewCareplan';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import { CarePathwayTitle } from './CardComponents';
import ShowCarePathwayView from './ShowCarePathwayView';
import CareplanWithReccurrencesCard from './CareplanWithReccurrencesCard';
import LinkedCareplanCard from './LinkedCareplanCard';
import {
  CreateCarePathwayContent,
  CreateCarePathwayFooter,
  CreateCarePathwayHeader,
} from '../Careplans/CarePathway/CreateCarePathwayModalContent';
import { PatientOverviewCarePathWays } from './CarePathwayCardsList';
import { ApiClient, ShowFlashMessage } from '../../Global/Services';
import serviceConfig from '../../../serviceConfig.json';
import { FlashMessageTypes } from '@ui-common-files/types';
import { Caretask } from '@type/Caretask';
import useAllTodoAssessments from '../Notifications/Notification.hooks';

import '../../css/analytics.css';

const CarePathwaysCard = ({
  data,
  patientId,
  hasOnlyAssessment,
  patientInfo,
}) => {
  const {
    setLandingTab,
    setMessagePrompt,
    closeMessagePrompt,
    setModalContent,
    closeModal,
    forceScroll,
    setForceScroll,
  } = useContext(PatientOverviewContext);
  const {
    setItemIdToUpdate,
    setRestPagesToUpdate,
    setItemIdToShowLoading,
  } = useContext(PatientOverviewCarePathWays);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showCareplansIcon, setShowCareplansIcon] = useState('arrowDown');
  const [cpwCaretasks, setCpwCaretasks] = useState([]);
  const [isCaretasksLoading, setIsCareTasksLoading] = useState(false);

  const ToggleAttachedCareplans = () => {
    return showCareplansIcon == 'arrowUp'
      ? setShowCareplansIcon('arrowDown')
      : setShowCareplansIcon('arrowUp');
  };

  const ShowCareplanRequest = () => {
    ToggleAttachedCareplans();
  };

  const redirectTab = (componentType, rowInfo) => {
    setLandingTab(false);
    const newScrollObject = forceScroll;
    if (
      rowInfo.isAssessment === true &&
      componentType === ComponentTypes.CAREPLAN
    ) {
      setLandingTab(ComponentTypes.ASSESSMENT);
      newScrollObject.assessment = true;
      newScrollObject.assessmentId = rowInfo.id;
    } else if (
      rowInfo.isAssessment === false &&
      componentType === ComponentTypes.CAREPLAN
    ) {
      setLandingTab(ComponentTypes.CAREPLAN);
      newScrollObject.careplan = true;
      newScrollObject.careplanId = rowInfo.id;
    } else {
      setLandingTab(ComponentTypes.APPOINTMENT);
      newScrollObject.appointment = true;
      newScrollObject.appointmentId = rowInfo.id;
    }
    setForceScroll(newScrollObject);
  };

  const sendCareplan = async () => {
    setItemIdToShowLoading(data.id);
    const hasSendCarePathwayCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPATHWAY,
      ActionTranslationKeys.SEND
    );
    const hasSendCareplanCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPLAN,
      ActionTranslationKeys.SEND
    );
    if (
      hasSendCarePathwayCredential === true &&
      hasSendCareplanCredential === true
    ) {
      return ApiClient.POST({
        url: serviceConfig.brokerService.sendCarePathway.uri,
        payload: {
          carePathwayId: data.id,
          carePathwayName: data.name,
          patientId: patientId,
        },
      })
        .then(response => {
          if (response) {
            setItemIdToUpdate(data.id);
          }
          return response;
        })
        .finally(() => {
          setItemIdToShowLoading(null);
        });
    } else {
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: 'common_labels.permissionDenied',
      });
      setItemIdToShowLoading(null);
    }
  };

  const unassignCareplan = async () => {
    setItemIdToShowLoading(data.id);
    const hasUnassignCarePathwayCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPATHWAY,
      ActionTranslationKeys.UNASSIGN
    );
    const hasUnassignCareplanCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPLAN,
      ActionTranslationKeys.UNASSIGN
    );
    if (
      hasUnassignCarePathwayCredential === true &&
      hasUnassignCareplanCredential === true
    ) {
      return ApiClient.GET({
        url: `${serviceConfig.brokerService.unassignCarePathway.uri}/${data.id}`,
      })
        .then(response => {
          if (response) {
            setItemIdToUpdate(data.id);
          }
          return response;
        })
        .finally(() => {
          setItemIdToShowLoading(null);
        });
    } else {
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: 'common_labels.permissionDenied',
      });
      setItemIdToShowLoading(null);
    }
  };

  const sendHandler = sendCareplan;
  const unassignHandler = unassignCareplan;
  const resultHandler = null;

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const { getAllTodos, allTodoAssessmentsController } = useAllTodoAssessments();

  const deleteCarepathway = async () => {
    allTodoAssessmentsController.abort();
    ShowFlashMessage({
      type: FlashMessageTypes.Info,
      message: `${I18n.t('newCareplan_view.carePathway')} "${
        data.name
      }" ${I18n.t('newCareplan_view.carepathwayDeleteProgress')}`,
    });

    ApiClient.GET({
      url: serviceConfig.brokerService.deleteCarePathway.uri,
      payload: {
        carePathwayId: data.id,
        carePathwayName: data.name,
      },
    }).then(response => {
      if (response) {
        setRestPagesToUpdate(data.id);
        if (data.hasAssessment) {
          getAllTodos();
        }
      }
    });
  };

  const renderDeleteDialog = async () => {
    const hasDeleteCarePathwayCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPATHWAY,
      ActionTranslationKeys.DELETE
    );
    const hasDeleteCareplanCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPLAN,
      ActionTranslationKeys.DELETE
    );
    if (
      hasDeleteCarePathwayCredential === true &&
      hasDeleteCareplanCredential === true
    ) {
      setMessagePrompt({
        title: `${I18n.t(
          'common_labels.msg_confirm_carePathwayTemplate_delete_headline'
        )}: ${data.name}`,
        content: (
          <PromptContent
            message={I18n.t(
              'common_labels.msg_confirm_carePathwayTemplate_delete'
            )}
          />
        ),
        footer: (
          <PromptFooter
            close={closeMessagePrompt}
            confirmHandler={() => deleteCarepathway()}
          />
        ),
      });
    } else {
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: 'common_labels.permissionDenied',
      });
    }
  };

  const showCareplanPathway = async () => {
    const hasShowCarePathwayCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPATHWAY,
      ActionTranslationKeys.SHOW
    );
    const hasShowCareplanCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPLAN,
      ActionTranslationKeys.SHOW
    );
    if (
      hasShowCarePathwayCredential === true &&
      hasShowCareplanCredential === true
    ) {
      setModalContent({
        title: `${I18n.t('newCareplan_view.view_carePathway')} ${data.name}`,
        content: <ShowCarePathwayView id={data.id} />,
        footer: <ViewCareplanModalFooter close={closeModal} />,
      });
    } else {
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: 'common_labels.permissionDenied',
      });
    }
  };
  const updateCareplanPathwayAssignment = async () => {
    const hasUpdateCarePathwayAssignmentCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPATHWAY,
      ActionTranslationKeys.UPDATE
    );
    const hasUpdateCareplanAssignmentCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPLAN,
      ActionTranslationKeys.UPDATE
    );
    if (
      hasUpdateCarePathwayAssignmentCredential === true &&
      hasUpdateCareplanAssignmentCredential === true
    ) {
      setModalContent({
        header: <CreateCarePathwayHeader />,
        title: `${I18n.t('newCareplan_view.editCarePathwayAssignment')} ${
          data.name
        }`,
        content: (
          <CreateCarePathwayContent
            patientData={patientInfo}
            close={closeModal}
            fromEditAssignment={true}
            carePathwayData={data}
            setItemIdToUpdate={setItemIdToUpdate}
          />
        ),
        footer: (
          <CreateCarePathwayFooter
            componentType={ComponentTypes.CAREPATHWAYS}
            actionType={Actions.Create}
            fromEditAssignment={true}
          />
        ),
      });
    } else {
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: 'common_labels.permissionDenied',
      });
    }
  };

  const getPathwayStatus = (statusId, expirationDate) => {
    const isExpirationDateBeforeToday = moment(expirationDate).isBefore(
      moment()
    );

    const chosenStatusId = isExpirationDateBeforeToday
      ? CAREPATHWAY_STATUS.INACTIVE
      : statusId;

    switch (chosenStatusId) {
      case CAREPATHWAY_STATUS.ACTIVE:
        return 'active';
      case CAREPATHWAY_STATUS.PLANNED:
        return 'planned';
      default:
        return 'inactive';
    }
  };

  useEffect(() => {
    const getCaretasksData = () => {
      ApiClient.GET({
        url:
          serviceConfig.brokerService.getCareplansByCarePathwayId.uri + data.id,
        payload: {
          patientId,
        },
      })
        .then(response => {
          if (response?.data) setCpwCaretasks(response.data);
        })
        .finally(() => setIsCareTasksLoading(false));
    };

    if (showCareplansIcon === 'arrowUp') {
      setIsCareTasksLoading(true);
      getCaretasksData();
    }
  }, [showCareplansIcon]);

  return (
    <div
      className={`assignment-card__carepathway ${getPathwayStatus(
        data.carePathwayStatus.id,
        data.expirationDate
      )}`}
      id={`carepathway_${data.id}`}
    >
      <div
        className={`myCarepathwayTitle ${getPathwayStatus(
          data.carePathwayStatus.id,
          data.expirationDate
        )}`}
      >
        <Box width="100%">
          <CarePathwayTitle
            data={data}
            onDeleteClick={renderDeleteDialog}
            onShowIconClick={showCareplanPathway}
            onEditIconClick={updateCareplanPathwayAssignment}
            sendHandler={sendHandler}
            unassignHandler={unassignHandler}
            resultHandler={resultHandler}
            sendAndUnassignEnabled
            componentType={ComponentTypes.CAREPATHWAYS}
            firebaseKey={data.firebaseKey}
            hasOnlyAssessment={hasOnlyAssessment}
            carePathwayReferenceDate={data.referenceDate}
            carePathwayExpirationDate={data.expirationDate}
            appointmentStartDate={data.appointmentStartDate}
            responsive={windowWidth <= MEDIA_QUERIES.SCREEN_SM_IPAD}
            redirectTab={redirectTab}
            onExpand={() => ShowCareplanRequest()}
          />
        </Box>

        <Flex
          flexDirection={
            windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'
          }
          justifyContent="space-between"
          otherStyles={{ padding: '20px 0', marginLeft: 20 }}
        >
          <div
            className={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? 'card__content-container card__container--row'
                : 'card__content-container card__container--column'
            }
          >
            <Flex alignItems="center">
              <IconButton
                name={showCareplansIcon}
                size={24}
                fill={Greys.DARK_GREY}
                onClick={() => {
                  ShowCareplanRequest();
                }}
              />
            </Flex>
          </div>
        </Flex>
      </div>
      {!isCaretasksLoading &&
        showCareplansIcon === 'arrowUp' &&
        cpwCaretasks?.careplans?.map((careplan, index) => (
          <CareplanWithReccurrencesCard
            careplan={careplan}
            index={index}
            data={data}
            status={getPathwayStatus(
              data.carePathwayStatus.id,
              data.expirationDate
            )}
            redirectTab={redirectData =>
              redirectTab(ComponentTypes.CAREPLAN, redirectData)
            }
          />
        ))}
      {isCaretasksLoading && (
        <Box
          otherStyles={{
            left: '59%',
            top: '35px',
            position: 'absolute',
          }}
        >
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={32}
            width={32}
          />
        </Box>
      )}
      {showCareplansIcon === 'arrowUp' &&
        cpwCaretasks?.fairPoorLinkedCaretasks?.map(
          (careplan: Caretask, index: number) => (
            <LinkedCareplanCard
              careplan={careplan}
              index={index}
              data={data}
              status={careplan.healthIndicator}
              redirectTab={redirectData =>
                redirectTab(ComponentTypes.CAREPLAN, redirectData)
              }
            />
          )
        )}
    </div>
  );
};

CarePathwaysCard.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default CarePathwaysCard;
