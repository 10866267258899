import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Box, Flex, IconButton } from '@ui-common-files/components';

import { Greys } from '@ui-common-files/utils';

import { SystemicTherapy } from '../PatientOverview/oncobox.interface';
import {
  OncoboxArrayItemType,
  OncoBoxCaseSectionCardType,
} from './OncoBoxCaseSectionCard.type';
import { useOncoBoxCaseSectionService } from './OncoboxCaseSectionCard.service';

const OncoboxArrayItem = ({
  titleIndex,
  item,
  expandedSections,
  toggleSection,
  itemToggleKey,
}: OncoboxArrayItemType) => {
  const { t } = useTranslation();
  const therapyType =
    itemToggleKey === 'systemicTherapy' &&
    (item as SystemicTherapy).therapyType;
  return (
    <Box key={`tumor-${titleIndex}`} width="100%">
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        otherStyles={{ marginRight: 30, padding: '10px 0px 0px' }}
      >
        <Box
          otherStyles={{ flex: 1, cursor: 'pointer' }}
          className="oncobox__wrapper__card__oncobox__container--blue oncobox__wrapper__card__oncobox__subtitle"
        >
          {t(`oncoboxImportedData.${itemToggleKey}`)} {therapyType}
        </Box>
        <Box otherStyles={{ color: Greys.DARK_GREY }}>
          {`${item.preparedData.length} ${t('oncoboxImportedData.elements')}`}
        </Box>
        <IconButton
          name={
            expandedSections[`${itemToggleKey}_${titleIndex}`]
              ? 'arrowUp'
              : 'arrowDown'
          }
          size={24}
          fill={Greys.DARK_GREY}
          onClick={() => toggleSection(`${itemToggleKey}_${titleIndex}`)}
        />
      </Flex>

      {expandedSections[`${itemToggleKey}_${titleIndex}`] && (
        <Box className="subsection-content">
          {item.preparedData.map(([key, value], tumorValueIndex) => (
            <Flex
              key={`tumorValue-${key}-${tumorValueIndex}`}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              otherStyles={{ width: '100%' }}
            >
              <Box className="oncobox__wrapper__card__oncobox__container--blue oncobox__wrapper__card__oncobox__subtitle">
                {t(`oncoboxImportedData.${key}`)}
              </Box>
              <Box className="oncobox__wrapper__card__oncobox__elementValue">
                {value}
              </Box>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
};

const OncoBoxCaseSectionCard = ({
  sectionName,
  sectionData,
}: OncoBoxCaseSectionCardType) => {
  const { t } = useTranslation();
  const {
    toggle,
    setToggle,
    expandedSections,
    toggleSection,
    caseNumber,
    preparedSections,
    systemicTherapies,
    tumorBoard,
  } = useOncoBoxCaseSectionService(sectionData);

  return (
    <Box className="oncobox__wrapper__card__oncobox">
      <Box
        className={classNames('oncobox__wrapper__card__oncobox__container', {
          'oncobox__wrapper__card__oncobox__container--blue': toggle,
        })}
      >
        <Box width="100%">
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            otherStyles={{ marginRight: 10 }}
          >
            <Box
              onClick={() => setToggle(prevState => !prevState)}
              otherStyles={{ flex: 1, cursor: 'pointer' }}
            >
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                otherStyles={{ width: '100%' }}
              >
                <Box
                  className={classNames(
                    'oncobox__wrapper__card__oncobox__title',
                    {
                      'oncobox__wrapper__card__oncobox__title--blue': toggle,
                    }
                  )}
                  otherStyles={{ width: '100%' }}
                >
                  {t(`oncoboxImportedData.${sectionName}`)}
                  {caseNumber && ` (${caseNumber})`}
                </Box>
                <Box otherStyles={{ color: Greys.DARK_GREY }}>
                  {`${Object.keys(sectionData).length} ${t(
                    'oncoboxImportedData.elements'
                  )}`}
                </Box>
              </Flex>
            </Box>

            <IconButton
              name={toggle ? 'arrowUp' : 'arrowDown'}
              size={24}
              fill={Greys.DARK_GREY}
              onClick={() => setToggle(prevState => !prevState)}
            />
          </Flex>
        </Box>
      </Box>

      <Box width="100%">
        {toggle &&
          preparedSections.map(
            ({ sectionKey, sectionValue, valueLength }, index) => (
              <Box key={`section-${sectionKey}-${index}`} width="100%">
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  otherStyles={{ marginRight: 30, padding: '10px 0px 0px' }}
                >
                  <Box
                    otherStyles={{ flex: 1, cursor: 'pointer' }}
                    className="oncobox__wrapper__card__oncobox__container--blue oncobox__wrapper__card__oncobox__subtitle"
                  >
                    {t(`oncoboxImportedData.${sectionKey}`)}
                  </Box>
                  <Box otherStyles={{ color: Greys.DARK_GREY }}>
                    {`${valueLength} ${t('oncoboxImportedData.elements')}`}
                  </Box>
                  <IconButton
                    name={
                      expandedSections[sectionKey] ? 'arrowUp' : 'arrowDown'
                    }
                    size={24}
                    fill={Greys.DARK_GREY}
                    onClick={() => toggleSection(sectionKey)}
                  />
                </Flex>

                {expandedSections[sectionKey] && (
                  <Box className="subsection-content">
                    {sectionValue.map((item, itemIndex) => (
                      <Box key={`item-${sectionKey}-${itemIndex}`}>
                        {Object.entries(item).map(
                          ([itemKey, itemValue], subIndex) => (
                            <Flex
                              key={`itemValue-${itemKey}-${subIndex}`}
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                              otherStyles={{ width: '100%' }}
                            >
                              <Box className="oncobox__wrapper__card__oncobox__container--blue oncobox__wrapper__card__oncobox__subtitle">
                                {t(`oncoboxImportedData.${itemKey}`)}
                              </Box>
                              <Box className="oncobox__wrapper__card__oncobox__elementValue">
                                {itemValue}
                              </Box>
                            </Flex>
                          )
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )
          )}

        {toggle &&
          tumorBoard.map((tumor, tumorTitleIndex) => (
            <OncoboxArrayItem
              titleIndex={tumorTitleIndex}
              item={tumor}
              expandedSections={expandedSections}
              toggleSection={toggleSection}
              itemToggleKey={'tumorBoard'}
            />
          ))}
        {toggle &&
          systemicTherapies.map((therapy, therapyTitleIndex) => (
            <OncoboxArrayItem
              titleIndex={therapyTitleIndex}
              item={therapy}
              expandedSections={expandedSections}
              toggleSection={toggleSection}
              itemToggleKey={'systemicTherapy'}
            />
          ))}
      </Box>
    </Box>
  );
};

export default OncoBoxCaseSectionCard;
