import { useEffect, useState } from 'react';
import ClassNames from 'classnames';

import { Greys } from '../../../../../../caro-ui-commonfiles/utils/colors';
import { CurrentPasswordButtonState } from '../UpdatePasswordComponent.type';

import styles from './CheckCurrentPasswordBox.module.scss';

export const useCheckCurrentPasswordBoxService = ({
  state,
}: {
  state: CurrentPasswordButtonState;
}) => {
  const [currentDot, setCurrentDot] = useState(0);
  const dots = Array.from(Array(3).keys());
  const fillColor =
    state === CurrentPasswordButtonState.Active
      ? Greys.WHITE
      : Greys.LIGHT_GREY;

  const containerClassName = ClassNames({
    [styles.box]: true,
    [styles.box__active]: state !== CurrentPasswordButtonState.Inactive,
  });

  const dotClassName = (index: number) =>
    ClassNames({
      [styles.box__dot]: true,
      [styles.box__dot__inactive]: currentDot === index,
    });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDot(currentDotValue => (currentDotValue + 1) % 3);
    }, 200);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return { currentDot, dots, containerClassName, dotClassName, fillColor };
};
