export function bloodPressureAnswerValidator(
  systolic: number,
  diastolic: number
): string {
  const systolicAnswered = !!systolic;
  const diastolicAnswered = !!diastolic;
  if (
    (systolicAnswered && !diastolicAnswered) ||
    (!systolicAnswered && diastolicAnswered)
  )
    return 'bloodPressureOptions.systolicAndDiastolicRequired';
  if (systolic <= diastolic && systolic !== 0 && diastolic !== 0)
    return 'bloodPressureOptions.systolicShouldHigherMessage';
  return '';
}
