import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import { CaretaskComponent } from '../../../../../../types/Caretask';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { SortableObject } from './AnsweringRanking.type';
import { AnsweringComponentsProps } from '../AnsweringTypes';
import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';

export default function useAnsweringRankingService({
  handleOnChange,
}: AnsweringComponentsProps) {
  const { t } = useTranslation();
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );

  const items = currentComponent.optionJson?.options || [];

  const getInitialState = (): SortableObject => {
    return {
      index: items.length > 0 ? Array.from(Array(items.length).keys()) : [],
      isActive: false,
    };
  };

  const defaultValue: SortableObject =
    currentAnswer?.answer || getInitialState();

  const getCurrentOrder = (state, newOrder: SortableObject) => {
    if (newOrder === null || newOrder === undefined) return getInitialState();
    if (newOrder.isActive) {
      return newOrder;
    }
    return {
      ...state,
      ...newOrder,
    };
  };

  const [sortableListState, setSortableListState] = useReducer(
    getCurrentOrder,
    getInitialState()
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSortableListState({
      index: arrayMove(sortableListState.index, oldIndex, newIndex),
      isActive: true,
    });
  };

  useEffect(() => {
    handleOnChange({
      answer: sortableListState,
      isSkipped: !sortableListState.isActive,
    });
  }, [sortableListState, handleOnChange]);

  useEffect(() => {
    setSortableListState(defaultValue);
  }, [currentComponent]);

  return {
    t,
    items,
    sortableListState,
    setSortableListState,
    onSortEnd,
  };
}
