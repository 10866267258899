/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { ref, getDownloadURL } from 'firebase/storage';

import { Box, Flex } from '@ui-common-files/components';
import BubbleContent from '../Bubbles/BubbleContent';

import { PrimaryColor } from '@ui-common-files/utils';

import { isDataImage, getFileExtension } from '../../../Utils/Chat/chatUtility';
import {
  isPreviewInViewer,
  convertLinkToBlob,
} from '../../../Utils/Asset/assetHelper';

const Bubble = ({
  note,
  name,
  profilePicture,
  time,
  date,
  fileRef,
  noteTimestamp,
  directLink,
  thumbnail,
  isFileData,
  isOwnNote,
}) => {
  const [blobURL, setBlobURL] = useState(null);
  const [thumbnailBlobURL, setThumbnailBlobURL] = useState(null);
  const [data, setData] = useReducer((_state, url) => url, null);
  const [isLoading, setIsLoading] = useState(false);

  const isImage = isFileData && isDataImage(note);

  useEffect(() => {
    if (directLink) {
      let link = directLink.split('?v=')[0];
      const lastIndex = link.lastIndexOf(' (');
      if (lastIndex !== -1) link = link.split(' (')[0];
      if (isPreviewInViewer(getFileExtension(link).toUpperCase())) {
        convertLinkToBlob(directLink).then(convertedUrl => {
          setBlobURL(convertedUrl);
        });
      } else {
        setBlobURL(directLink);
      }
    }
  }, [directLink]);

  useEffect(() => {
    if (thumbnail) {
      convertLinkToBlob(thumbnail).then(convertedUrl => {
        setThumbnailBlobURL(convertedUrl);
      });
    }
  }, [thumbnail]);

  useEffect(() => {
    setIsLoading(true);
    if (fileRef && !data && !directLink) {
      const filesStorageRef = ref(
        fileRef,
        `${moment(noteTimestamp).format('x')}_${note}`
      );
      getDownloadURL(filesStorageRef)
        .then(url => {
          setIsLoading(false);
          setData(url);
        })
        .catch(error => {
          setIsLoading(false);
          throw new Error(error);
        });
    }
    setIsLoading(false);
  }, [fileRef]);
  return (
    <Box className="note-container">
      <Box className={`note-bubble${isOwnNote ? '__self' : ''}`}>
        {isLoading && isImage && (
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        )}

        <BubbleContent
          note={note}
          isFileData={isFileData}
          data={data}
          blobURL={blobURL}
          thumbnailBlobURL={thumbnailBlobURL}
          isOwnNote={isOwnNote}
        />

        <Box className="note-bubble__details">
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <Box
                className="user-note__profile-pic"
                otherStyles={{
                  backgroundImage: `url(${profilePicture.directLink
                    ? profilePicture.directLink
                    : `data:image;base64,${profilePicture}`
                    })`,
                }}
              />
              <p className="note-bubble__details__name">{name}</p>
            </Flex>
            <p className="note-bubble__details__time">{`${date} ${time}`}</p>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

Bubble.propTypes = {
  note: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profilePicture: PropTypes.oneOf([PropTypes.string, PropTypes.object])
    .isRequired,
  time: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  fileRef: PropTypes.object,
  noteTimestamp: PropTypes.string,
  directLink: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  isFileData: PropTypes.bool.isRequired,
  isOwnNote: PropTypes.bool.isRequired,
};

Bubble.defaultProps = {
  fileRef: undefined,
  noteTimestamp: undefined,
};

export default React.memo(Bubble);
