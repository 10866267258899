import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Box from '../../../../../../../caro-ui-commonfiles/components/Box/Box';
import RadioButton from '../../../../../../../caro-ui-commonfiles/components/RadioButton/RadioButton';
import CheckboxRadio from '../../../../../../../caro-ui-commonfiles/utils/CheckboxRadio';

import { CaretaskComponent } from '../../../../../../types/Caretask';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { AnsweringComponentsProps } from '../AnsweringTypes';

import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';

const DEFAULT_UN_SELECTED: number = -1;

export default function AnsweringSingleChoice({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);
  const defaultValue: number =
    currentAnswer?.answer !== null
      ? currentAnswer?.answer
      : DEFAULT_UN_SELECTED;

  const [checkedIndex, setCheckedIndex] = useState<number>(defaultValue);

  useEffect(() => {
    const jumpTo: number =
      currentComponent.logicJumpPaths[checkedIndex] || null;

    handleOnChange({
      answer: checkedIndex === DEFAULT_UN_SELECTED ? null : checkedIndex,
      isSkipped: checkedIndex === DEFAULT_UN_SELECTED,
      jumpTo,
    });
  }, [checkedIndex, handleOnChange]);

  useEffect(() => {
    setCheckedIndex(defaultValue);
  }, [currentComponent]);

  return (
    <Box>
      {currentComponent.optionJson?.options.map((option, i) => {
        return (
          <Box key={`container-${option}`} otherStyles={{ padding: '5px 0px' }}>
            <RadioButton
              id={`option-${i}`}
              key={`radio-key-${option}`}
              name={option}
              variant={CheckboxRadio.Default}
              checked={checkedIndex === i}
              handleOnChange={() => setCheckedIndex(i)}
              label={option}
            />
          </Box>
        );
      })}
    </Box>
  );
}
