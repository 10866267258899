import React, { useContext } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';

import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import { ButtonType, ButtonVariant} from '../../../caro-ui-commonfiles/utils/button';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import UsFilterTopWarning from '../../../caro-ui-commonfiles/components/UsFilterWarnings/UsFilterTopWarning';

import {
  Actions,
  SubmissionTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

const ModalFooter = ({ componentType, actionType, hasCarePathways }) => {
  const {
    dispatchFormValidator,
    currentStep,
    setCurrentStep,
    dispatchFormSubmittor,
    hasOneCareplanExpired,
    setHasOneCareplanExpired,
  } = useContext(PatientOverviewContext);

  const renderIntermediateStep = () => {
    return (
      <>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          otherStyles={{ width: '100%' }}
        >
          <Button
            label={I18n.t('common_buttons.back')}
            onClick={() => {
              setHasOneCareplanExpired({});
              setCurrentStep(currentStep - 1);
            }}
            variant={ButtonVariant.OUTLINED}
          />

          {Object.values(hasOneCareplanExpired).includes(true) && (
            <Flex
              alignItems="center"
              justifyContent="center"
              otherStyles={{ width: '90%', display: 'flex', margin: '0 24px' }}
            >
              <UsFilterTopWarning
                message={I18n.t(
                  'patients_view.us-market-warning__patient-appointment-update'
                )}
                showUsWarningTopPage
                showEditIcon={false}
                showFilter={false}
              />
            </Flex>
          )}

          <Button
            label={I18n.t('common_buttons.next')}
            onClick={() => {
              dispatchFormValidator({
                component: componentType,
                action: actionType,
                shouldValidate: true,
              });
            }}
          />
        </Flex>
      </>
    );
  };

  const renderFinalStep = () => {
    return (
      <>
        <Button
          label={I18n.t('common_buttons.back')}
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
          variant={ButtonVariant.OUTLINED}
        />
        <Flex justifyContent="flex-end" otherStyles={{ flex: 1 }}>
          <Box margin="0 16px 0 0">
            <Button
              label={I18n.t('common_buttons.reschedule')}
              type={ButtonType.SUBMIT}
              onClick={() => {
                dispatchFormSubmittor({
                  component: componentType,
                  action: actionType,
                  submissionType: SubmissionTypes.ASSIGN_ONLY,
                });
              }}
              variant={ButtonVariant.OUTLINED}
            />
          </Box>
          <Button
            label={I18n.t('assignTemplate_view.send_immediately')}
            type={ButtonType.SUBMIT}
            onClick={() => {
              dispatchFormSubmittor({
                component: componentType,
                action: actionType,
                submissionType: SubmissionTypes.ASSIGN_AND_SEND,
              });
            }}
          />
        </Flex>
      </>
    );
  };
  return (
    <Flex justifyContent="flex-end" otherStyles={{ flex: 1 }}>
      {currentStep === 1 && (
        <Button
          label={I18n.t('common_buttons.next')}
          type={ButtonType.BUTTON}
          onClick={() => {
            dispatchFormValidator({
              component: componentType,
              action: actionType,
              shouldValidate: true,
            });
          }}
        />
      )}
      {currentStep === 2 && actionType == Actions.Create && renderFinalStep()}
      {currentStep === 2 &&
        actionType == Actions.Update &&
        hasCarePathways &&
        renderIntermediateStep()}
      {currentStep === 2 &&
        actionType == Actions.Update &&
        !hasCarePathways &&
        renderFinalStep()}
      {currentStep === 3 && renderFinalStep()}
    </Flex>
  );
};

ModalFooter.propTypes = {
  componentType: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
};

export default ModalFooter;
