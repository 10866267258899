import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import usePatientsListService from './PatientsList.service';
import { useDelaySearch } from '@global/Hooks';
import usePatientsQueryEffect from './hooks/usePatientsQueryEffect';
import usePatientDataEffect from './hooks/usePatientDataEffect';

import {
  Box,
  FlashMessage,
  FloatButton,
  UsFilterBottomWarning,
  Breadcrumb,
} from '@ui-common-files/components';
import ShowPatientModal from './ShowPatientModal';
import PatientListEditFilter from './PatientListEditFIlter';
import OncoBoxModals from './OncoBoxModals';
import PatientDeleteModal from './PatientDeleteModal';
import PatientsListHeader from './PatientsListHeader';
import FilterSection from './FilterSection';
import { BreadcrumbPortal } from '../AppLayout';
import { FlashMessageTypes } from '@ui-common-files/types';

import { HideFlashMessage, useAnalytics } from '@global/Services';
import PatientListView from '../Datatables/PatientsTables/IndexPatient';
import { PatientFormModal } from '../Patients/PatientFormModal';
import PatientTableFilter from '../Patients/Filter/FilterContainer/FilterContainer';
import { PatientFilterContextProvider } from '../Patients/Filter/PatientFilterContext/PatientFilterContext';
import {
  getPatientsByQueryRequest,
  getPatientsByCustomFilter,
} from '../../../actions/patients';
import { onFilterSelection } from '../../../actions/Datatables/patientFilter';
import {
  onSearchAction,
  resetPaginationPatientTable,
} from '../../../actions/Datatables/patients';
import '../../css/patient.css';
import {
  getCustomFilteredPatients,
  getExistingFilterPatients,
  patientListFetchData,
} from './PatientList.api';

import styles from './Patient.module.scss';

function Patients() {
  const {
    customFilterData,
    deleteOperation,
    displayEditFilter,
    displayFlashMsg,
    displayModal,
    exportModalToggle,
    importModalToggle,
    floatItems,
    forceRender,
    isModalLoading,
    isPatientDemographicData,
    loading,
    modalData,
    onCreatePatientFormModal,
    onDeletePatienListView,
    onEditPatientListView,
    onPatientFormModalClose,
    onShowPatientListView,
    onUpdatePatientFormModal,
    openResultsModal,
    operation,
    patients,
    selectedFilter,
    services,
    setDeleteOperation,
    setDisplayFlashMsg,
    setExportModalToggle,
    setFlashMessagePatientListView,
    setImportModalToggle,
    setLoading,
    setPatients,
    setShowAppliedFilterWarning,
    setShowPatient,
    showAppliedFilterWarning,
    showFilterSettings,
    showPatient,
    tableHandler,
    user,
    activeTab,
    onClickTabItem,
    oldPatientsCounter,
    newPatientsCounter,
    searchTerm,
    handlePatientSearch,
    setOpenResultsModal,
    setShowFilterSettings,
    selectAdvancedFilter,
    isNewPatient,
    doFetch,
    reduxDispatcher,
    actionState,
    checkPatientsReadPermission,
    setFilteredPatientsForCsvExport,
    setIsFilterSelected,
    getPatientsCounters,
    setForceRender,
    setSearchTerm,
    setIsPatientDemographicData,
    setModalData,
    setDisplayModal,
    setOperation,
    setDoFetch,
    setIsModalLoading,
    filteredPatientsForCsvExport
  } = usePatientsListService();
  const { t } = useTranslation();
  const history = useHistory();
  const { trackEventTrigger } = useAnalytics();

  const defaultFilter = selectedFilter.savedFilter.index;
  const NoPermissionflashMessage = history.location.state
    ? (history.location.state as { NoPermissionflashMessage: {} })
        .NoPermissionflashMessage
    : null;
  useEffect(() => {
    if (
      openResultsModal &&
      selectedFilter.savedFilter.id &&
      selectedFilter.savedFilter.id !== 1
    ) {
      getExistingFilterPatients({
        openResultsModal,
        selectedFilter,
        actionState,
        patients,
        isNewPatient,
        setFilteredPatientsForCsvExport,
        setDisplayFlashMsg,
        t,
      });
    }
    if (
      !selectedFilter.customFilter &&
      (!selectedFilter.savedFilter.id || selectedFilter.savedFilter.id === 1)
    )
      setFilteredPatientsForCsvExport(null);
  }, [selectedFilter, openResultsModal, patients]);
  useEffect(() => {
    setIsFilterSelected(
      !!(
        (actionState.customFilter &&
          (actionState.customFilter.caretaskFilter.criteria.length > 0 ||
            actionState.customFilter.patientFilter.criteria.length > 0)) ||
        (selectedFilter.savedFilter.id && selectedFilter.savedFilter.id !== 1)
      )
    );
  }, [actionState.customFilter, actionState.sqlQuery]);
  useEffect(() => {
    if (openResultsModal && selectedFilter.customFilter) {
      getCustomFilteredPatients({
        openResultsModal,
        selectedFilter,
        actionState,
        patients,
        isNewPatient,
        setDisplayFlashMsg,
        setFilteredPatientsForCsvExport,
        t,
      });
    }
    if (
      !selectedFilter.customFilter &&
      (!selectedFilter.savedFilter.id || selectedFilter.savedFilter.id === 1)
    )
      setFilteredPatientsForCsvExport(null);
  }, [actionState.customFilter, openResultsModal, patients, isNewPatient]);

  usePatientsQueryEffect({
    reduxDispatcher,
    actionState,
    doFetch,
    setLoading,
    setPatients,
    setDisplayFlashMsg,
    t,
    checkPatientsReadPermission,
    selectedFilter,
    isNewPatient,
    getPatientsByQueryRequest,
    getPatientsByCustomFilter,
  });
  usePatientDataEffect({
    setPatients,
    setIsPatientDemographicData,
    setLoading,
    setModalData,
    setDisplayModal,
    setOperation,
    setDisplayFlashMsg,
    trackEventTrigger,
    user,
    patients,
    actionState,
    doFetch,
    setDoFetch,
    activeTab,
    getPatientsCounters,
    setIsModalLoading,
    displayModal,
    isModalLoading,
    setShowPatient,
  });
  useEffect(() => {
    if (NoPermissionflashMessage) setDisplayFlashMsg(NoPermissionflashMessage);
  }, [NoPermissionflashMessage]);

  useEffect(() => {
    if (actionState.search.length > 1) {
      setSearchTerm(actionState.search);
    }
  }, [actionState.search]);

  useEffect(() => {
    reduxDispatcher(resetPaginationPatientTable());
  }, [isNewPatient]);
  useEffect(() => {
    if (
      'patientservice.myoncare.care' in services &&
      user &&
      displayFlashMsg.content === t('flash.accountDeactivated')
    )
      setDisplayFlashMsg({
        display: false,
        content: '',
        type: FlashMessageTypes.Warning,
      });
  }, [displayFlashMsg.content, user, services]);
  useLayoutEffect(() => {
    if (
      !selectedFilter.savedFilter.id &&
      !selectedFilter.savedFilter.rule &&
      selectedFilter.customFilter === null
    ) {
      reduxDispatcher(
        onFilterSelection({
          allFilters: selectedFilter?.allFilters,
          id: selectedFilter.allFilters[defaultFilter]?.filterId,
          index: defaultFilter,
          name: selectedFilter.allFilters[defaultFilter]?.key,
          rule:
            typeof selectedFilter.allFilters[defaultFilter]?.rule === 'string'
              ? JSON.parse(selectedFilter.allFilters[defaultFilter]?.rule)
              : selectedFilter.allFilters[defaultFilter]?.rule,
        })
      );
    }
    setShowFilterSettings(selectedFilter.customFilter !== null);
  }, []);
  useEffect(() => {
    patientListFetchData({ setDisplayFlashMsg, setLoading, setPatients, t });
  }, []);

  useEffect(() => {
    getPatientsCounters();
  }, []);

  useEffect(() => {
    if (!forceRender) setForceRender(true);
  }, [forceRender]);
  useDelaySearch({
    input: searchTerm,
    callback: () => {
      reduxDispatcher(onSearchAction(searchTerm));
    },
  });
  if ('patientservice.myoncare.care' in services) {
    if (user && displayFlashMsg.content === t('flash.accountDeactivated')) {
      setDisplayFlashMsg({
        display: false,
        message: '',
        content: '',
        type: FlashMessageTypes.Empty,
        autoHide: false,
        hideLeftIcon: false,
      });
    }

    return (
      <>
        <BreadcrumbPortal>
          <Breadcrumb>
            <span aria-current="page"> {t('nav.patients')}</span>
          </Breadcrumb>
        </BreadcrumbPortal>
        {displayFlashMsg.display && (
          <FlashMessage
            message={t(displayFlashMsg.content)}
            type={displayFlashMsg.type}
            onClick={() => {
              setDisplayFlashMsg({
                display: false,
                message: '',
                content: '',
                type: FlashMessageTypes.Empty,
                autoHide: false,
                hideLeftIcon: false,
              });
            }}
            autoHide={displayFlashMsg.autoHide}
            hideLeftIcon={displayFlashMsg.hideLeftIcon}
          />
        )}

        <Box className="container-padding">
          <PatientsListHeader
            activeTab={activeTab}
            onClickTabItem={onClickTabItem}
            oldPatientsCounter={oldPatientsCounter}
            newPatientsCounter={newPatientsCounter}
            searchTerm={searchTerm}
            handlePatientSearch={handlePatientSearch}
            setOpenResultsModal={setOpenResultsModal}
            showFilterSettings={showFilterSettings}
            setShowFilterSettings={setShowFilterSettings}
            selectedFilter={selectedFilter}
            setDisplayFlashMsg={setDisplayFlashMsg}
            selectAdvancedFilter={selectAdvancedFilter}
          />
          <FilterSection
            showAppliedFilterWarning={showAppliedFilterWarning}
            selectedFilter={selectedFilter}
            forceRender={forceRender}
            customFilterData={customFilterData}
            selectAdvancedFilter={selectAdvancedFilter}
          />
          <PatientFilterContextProvider
            setPatients={setPatients}
            setLoading={setLoading}
            setShowAppliedFilterWarning={setShowAppliedFilterWarning}
          >
            <PatientTableFilter showFilterSettings={showFilterSettings} />
          </PatientFilterContextProvider>
          <PatientListView
            data={patients}
            loading={loading}
            onChange={tableHandler}
            onDelete={onDeletePatienListView}
            onEditPatient={onEditPatientListView}
            onShowPatient={onShowPatientListView}
            setFlashMessage={setFlashMessagePatientListView}
          />
        </Box>

        {displayModal === true && (
          <PatientFormModal
            open={displayModal}
            onClose={onPatientFormModalClose}
            onCreate={onCreatePatientFormModal}
            onUpdate={onUpdatePatientFormModal}
            data={modalData}
            operation={operation}
            loading={isModalLoading}
          />
        )}

        {showPatient.show && (
          <ShowPatientModal
            show={showPatient.show}
            data={showPatient.data}
            healthScore={showPatient.healthScore}
            scoringSchemeCategory={showPatient.scoringSchemeCategory}
            setShowPatient={setShowPatient}
          />
        )}

        {deleteOperation.display && (
          <PatientDeleteModal
            deleteOperation={deleteOperation}
            setDeleteOperation={setDeleteOperation}
          />
        )}

        {displayEditFilter && selectedFilter.savedFilter.id && (
          <PatientListEditFilter selectedFilter={selectedFilter} />
        )}
        <OncoBoxModals
          exportModal={exportModalToggle}
          setImportModalToggle={setImportModalToggle}
          setExportModalToggle={setExportModalToggle}
          importModal={importModalToggle}
          HideFlashMessage={HideFlashMessage}
          openResultsModal={openResultsModal}
          setOpenResultsModal={setOpenResultsModal}
          filteredPatientsForCsvExport={filteredPatientsForCsvExport}
        />
        {!isPatientDemographicData && <FloatButton menuItems={floatItems} />}

        <Box className="patient-index__us-warning-container">
          <Box className={styles['us-filter-bottom-warning']}>
            <UsFilterBottomWarning
              message={t(
                'patients_view.us-market-warning__patient-index-bottom'
              )}
            />
          </Box>
        </Box>
      </>
    );
  }
}

export default withTranslation()(Patients);
