import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import I18n from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  Box,
  Flex,
  TextInput,
  Modal,
  Button,
  Icon,
  Tooltip,
  ModalSize,
} from '@ui-common-files/components';

import {
  ButtonSize,
  ButtonVariant,
  inputField,
  MaxCharLimits,
} from '@ui-common-files/utils';

import { ApiClient, HideFlashMessage } from '@global/Services';

import ModalFooter from '../common/Layout/Modal/ModalFooter';
import AssetListView from './AssetListView';
import ShowAssetModalContent from '../Files/ShowAssetModalContent';
import EditAssetModalContent from '../Files/EditAssetModalContent';

import useWindowWidthListener from '../../Utils/hooks/useWindowWidthListener';
import {
  assetUploadPromise,
  validateFileSize,
  validateFileType,
  validateFileLimit,
  validateFileNameLimit,
  clearPendingAssets,
  convertLinkToBlob,
  isPreviewInViewer,
} from '../../Utils/Asset/assetHelper';
import Actions from '../../Utils/actions';

import serviceConfig from '../../../serviceConfig.json';
import { getMessageObject } from '../../Utils/Chat/chatUtility';
import { useUserPermission } from '../../Utils/hooks/getUserPermission';
import ASSET_TYPES from '../../Utils/Asset/assetTypes';
import { AssetOrigins } from '../../Utils/Asset/AssetOrigins.enum';

import ShareButtonDropdown from '../../../caro-ui-commonfiles/components/DropdownMenu/ShareButtonDropdown';

import '../../css/sites.css';

function Assets({
  hasPagesize,
  reloadTable,
  setReloadTable,
  setFlashMessage,
  activeTab,
  isAttachFile,
  setAttachedFile,
  setSelectedFile,
  disableBtn,
  setDisableBtn,
  displayShareBtn,
  isAttachFiles,
  patientInfo,
  setReloadChat,
  isChatOpened,
  isPrivacyPolicy,
  comesFromPatientOverview,
}) {
  const [assetAssociations, setAssetAssociations] = useState(null);
  const [blobArray, setBlobArray] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const hasAssetCreatePermission = useUserPermission(
    I18n.t('types.componentType.assets'),
    I18n.t('actions.create'),
    true
  );
  const { user } = useSelector(state => state.session);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [typingTimeOut, setTypingTimeOut] = useState(0);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    value: '',
    start: 0,
    length: 10,
    dir: 'desc',
    column: 'updatedAt',
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageState, setPageState] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [sort, setSort] = useState({
    dir: 'desc',
    column: isPrivacyPolicy ? 'createdAt' : 'updatedAt',
  });
  const [modalTitle, setModalTitle] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [currUser, setCurrUser] = useState(false);
  const [action, setAction] = useState('');
  const [assetDataToShow, setAssetDataToShow] = useState(null);
  const [allAssetData, setAllAssetData] = useState([]);
  const [allSitesToShare, setAllSitesToShare] = useState([]);
  const responsive = useWindowWidthListener();
  const { innerWidth: width } = window;

  const abortController = new AbortController();
  useEffect(() => {
    const fetchData = async () => {
      ApiClient.POST({
        url: serviceConfig.brokerService.getAllSitesForModals.uri,
        payload: {},
      })
        .then(allSites => {
          const sites = allSites.data.allSites;
          const institutions = sites.map(({ id, name }) => ({
            key: id,
            site: name,
            isChecked: false,
          }));
          setAllSitesToShare(institutions);
        })
        .catch(error => {
          setFlashMessage({
            content: error.response.data.content,
            type: error.response.data.type,
          });
        })
        .finally(() => setLoading(false));
    };

    fetchData();
  }, []);

  const onSiteSelectedChange = (id, shouldBeChecked) => {
    const newAllSitesToShare = [...allSitesToShare];
    const siteForId = newAllSitesToShare.find(site => site.key === id);
    siteForId.isChecked = shouldBeChecked;
    setAllSitesToShare(newAllSitesToShare);
  };

  const getAssetsRequest = (typeId, query) => {
    return ApiClient.GET({
      url: serviceConfig.brokerService.getAssets.uri,
      payload: {
        assetTypeId: isPrivacyPolicy ? ASSET_TYPES.DOCUMENT : typeId,
        query,
        patientId: patientInfo?.id,
        isPrivacyPolicy,
      },
      signal: abortController.signal,
      timeout: 60000,
    });
  };

  useEffect(() => {
    setFetchedData([]);
  }, [activeTab]);

  useEffect(() => {
    if (!isSelectAll) {
      setAllAssetData([]);
    }
  }, [isSelectAll]);

  useEffect(() => {
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    const blobThumbnails = [];
    let assetTypeLink;
    const promises = fetchedData.map(asset => {
      if (asset.assetTypeId == ASSET_TYPES.IMAGE) {
        assetTypeLink = asset.directLink;
      } else {
        assetTypeLink = asset.thumbnailURL;
      }
      if (assetTypeLink) {
        return convertLinkToBlob(assetTypeLink)
          .then(convertedUrl => {
            if (convertedUrl) {
              blobThumbnails.push({
                id: asset.id,
                blobThumbnailURL: convertedUrl,
              });
              return blobThumbnails;
            }
          })
          .catch(() => {
            return blobThumbnails;
          });
      }
    });
    Promise.all(promises).then(() => {
      setBlobArray(blobThumbnails);
    });
  }, [fetchedData]);

  let inputRef;

  const getAssetsByQuery = async (typeId, shouldReloadTable) => {
    setBlobArray([]);
    if (shouldReloadTable) setLoading(true);
    try {
      return await getAssetsRequest(typeId, query)
        .then(response => {
          setLoading(false);
          setFetchedData(response.data.data);
          setRecordsTotal(response.data.recordsTotal);
        })
        .catch(error => {
          setLoading(false);
          setFlashMessage({
            content: error.response.data.content,
            type: error.response.data.type,
          });
        });
    } catch (error) {
      setLoading(false);
      setFlashMessage({
        content: error.response.data.content,
        type: error.response.data.type,
      });
    }
  };

  const deletePatientAssetAssociationRequest = assetId => {
    return axios.post(
      serviceConfig.brokerService.deletePatientAssetAssociation.uri,
      {
        assetId,
        patientId: patientInfo.id,
        shouldSkipAssetInstitutionsCheck: true,
      }
    );
  };

  const deleteAssetRequest = (asset, showDeleteFlash = true) => {
    try {
      return axios
        .post(serviceConfig.brokerService.deleteAsset.uri, {
          id: asset.id,
          resourceSpaceAssetId: asset.assetId,
          shouldSkipAssetInstitutionsCheck: true,
          patientId: patientInfo?.id,
        })
        .then(response => {
          if (showDeleteFlash) {
            setReloadTable(activeTab || 3);
            setFlashMessage({
              content: I18n.t(response.data.content),
              type: response.data.type,
            });
            const removeIndex = allAssetData.findIndex(
              elem => elem.id === asset.id
            );
            allAssetData.splice(removeIndex, 1);
          }
        })
        .catch(error => {
          if (showDeleteFlash)
            setFlashMessage({
              content: I18n.t(error.response.data.content),
              type: error.response.data.type,
            });
        });
    } catch (error) {
      if (showDeleteFlash)
        setFlashMessage({
          content: I18n.t(error.response.data.content),
          type: error.response.data.type,
        });
    }
  };

  const createAndSendNewMsg = assetName => {
    const message = `user:${user.username} key:chatMessages.chatDeleted file:${assetName}`;
    const timestamp = moment().toISOString();
    const messageObj = getMessageObject(
      timestamp,
      message,
      false,
      patientInfo,
      user
    );
    return axios
      .post(serviceConfig.chatService.createChatMessage.uri, {
        data: messageObj,
        isTranslatable: true,
      })
      .then(() => {
        return axios.post(serviceConfig.chatService.sendChatMessage.uri, {
          data: messageObj,
          userId: user.id,
          isTranslatable: true,
        });
      })
      .then(() => {
        setReloadTable(3);
        setFlashMessage({
          content: I18n.t('flash.assetDeleted'),
          type: 'success',
        });
      });
  };

  const deleteAssetFromPatientOverview = asset => {
    try {
      return axios
        .get(
          `${serviceConfig.brokerService.getAssetChatMessages.uri}${asset.id}/${patientInfo.id}`
        )
        .then(response => {
          if (
            asset.isShared.isAssetAssociatedWithPatients?.assetOrigin ===
              AssetOrigins.Careplan ||
            asset.isShared.isAssetAssociatedWithPatients?.assetOrigin ===
              AssetOrigins.PdfReport ||
            response.data.length === 0
          ) {
            deletePatientAssetAssociationRequest(asset.id).then(() => {
              createAndSendNewMsg(asset.name);
            });
          } else if (response.data.length > 0) {
            response.data.map(chatMessage => {
              return axios
                .get(
                  `${serviceConfig.chatService.deleteChatMessage.uri}${chatMessage.id}/${patientInfo.id}`
                )
                .then(() => {
                  if (
                    !asset.isShared.isAssetAssociatedWithInsitutions ||
                    chatMessage.isPatientInitiated
                  ) {
                    deleteAssetRequest(asset).then(() => {
                      createAndSendNewMsg(asset.name);
                    });
                  } else {
                    deletePatientAssetAssociationRequest(asset.id).then(() => {
                      createAndSendNewMsg(asset.name);
                    });
                  }
                })
                .then(() => {
                  setReloadChat(isChatOpened);
                });
            });
          }
        })
        .catch(error => {
          setFlashMessage({
            content: I18n.t(error.response.data.content),
            type: error.response.data.type,
          });
        });
    } catch (error) {
      setLoading(false);
      setFlashMessage({
        content: I18n.t(error.response.data.content),
        type: error.response.data.type,
      });
    }
  };

  useEffect(() => {
    const getAllAssets = async (typeId, shouldReloadTable) => {
      if (isSelectAll) {
        if (shouldReloadTable) setLoading(true);
        try {
          return await getAssetsRequest(typeId, {
            value: query.value,
            start: 0,
            length: recordsTotal,
            dir: query.dir,
            column: query.column,
          })
            .then(response => {
              setLoading(false);
              setAllAssetData(response.data.data);
              setRecordsTotal(response.data.recordsTotal);
            })
            .catch(error => {
              setLoading(false);
              setFlashMessage({
                content: I18n.t(error.response.data.content),
                type: error.response.data.type,
              });
            });
        } catch (error) {
          setLoading(false);
          setFlashMessage({
            content: I18n.t(error.response.data.content),
            type: error.response.data.type,
          });
        }
      }
    };
    getAllAssets(activeTab, true);
  }, [isSelectAll]);

  useEffect(() => {
    if (typingTimeOut > 0) clearTimeout(typingTimeOut);
    if (query.value.length === 0) {
      getAssetsByQuery(activeTab, true);
    } else if (query.value.length > 1) {
      const timeOut = setTimeout(() => {
        getAssetsByQuery(activeTab, true);
      }, 500);
      setTypingTimeOut(timeOut);
    }
    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [activeTab, query, reloadTable]);

  const closeModal = () => {
    setDisplayModal(false);
    setCurrUser(false);
  };

  useEffect(() => {
    setQuery({
      start: currentPage * pageState,
      length: pageState,
      dir: sort.dir,
      column: sort.column,
      value: query.value,
    });
  }, [currentPage, pageState, sort]);

  const readAndUpdateAsset = (assetUploadResponseCollection, assetTypeId) => {
    try {
      return axios
        .post(
          `${serviceConfig.brokerService.updateAssetDetails.uri}`,
          {
            assetUploadResponseCollection,
          },
          { timeout: 30000 }
        )
        .then(response => {
          setFlashMessage({
            content: I18n.t(response.data.content),
            type: response.data.type,
          });
          getAssetsByQuery(assetTypeId, false);
          setDisableBtn(false);
        })
        .catch(error => {
          setFlashMessage({
            content: I18n.t(error.response.data.content),
            type: error.response.data.type,
          });
        });
    } catch (error) {
      setFlashMessage({
        content: I18n.t('flash.errorAssetService'),
        type: 'error',
      });
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (allAssetData.length === 0) setIsSelectAll(false);
  }, [allAssetData]);

  const handleImageUpload = e => {
    e.preventDefault();
    const fileList = e.target.files;
    let errorMessage = '';
    inputRef = null;
    const isFileLimitCorrect = validateFileLimit(fileList);
    const isFileTypeCorrect = validateFileType(fileList);
    const isFileSizeCorrect = validateFileSize(fileList);
    const isFileNameLimitCorrect = validateFileNameLimit(fileList);

    if (
      isFileLimitCorrect &&
      isFileSizeCorrect &&
      isFileTypeCorrect &&
      isFileNameLimitCorrect
    ) {
      setLoading(true);
      setDisableBtn(true);
      const formData = new FormData();
      for (let i = 0; i < fileList.length; i++) {
        formData.append('filedata', fileList[i]);
      }

      return ApiClient.POST({
        url: serviceConfig.brokerService.createAsset.uri,
        payload: formData,
        timeout: 3000000000,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'form-data',
        },
      })
        .then(response => {
          const { assetTypeId } = response.data;
          setLoading(false);
          setReloadTable(assetTypeId);
          setFlashMessage({
            content: I18n.t(response.data.content),
            type: response.data.type,
          });
          const promises = [];
          for (let i = 0; i < fileList.length; i++) {
            promises.push(
              Promise.resolve(
                assetUploadPromise(response, fileList[i], setFlashMessage)
                  .then(() => {
                    getAssetsByQuery(assetTypeId, true);
                    setDisableBtn(false);
                  })
                  .catch(error => {
                    setFlashMessage({
                      content: I18n.t(error.response.data.content),
                      type: error.response.data.type,
                    });
                  })
              )
            );
          }
          Promise.all(promises);
        })
        .catch(error => {
          setLoading(false);
          setDisableBtn(false);
          clearPendingAssets().catch(() => {});
          setFlashMessage({
            content: error.response.data.content,
            type: error.response.data.type,
          });
        });
    }

    if (!isFileLimitCorrect) {
      errorMessage = 'flash.maxNumberOfFilesUpload';
    } else if (!isFileSizeCorrect) {
      errorMessage = 'flash.maxFileSize';
    } else if (!isFileTypeCorrect) {
      errorMessage = 'flash.incorrectFileType';
    } else if (!isFileNameLimitCorrect) {
      errorMessage = 'flash.fileNameLimitExceed';
    }
    setFlashMessage({
      status: 403,
      content: errorMessage,
      type: 'warning',
    });
  };
  const renderModalFooter = () => {
    switch (action) {
      case Actions.Edit:
        return (
          <ModalFooter
            close={closeModal}
            labelCTA={I18n.t('common_buttons.update')}
            form="update-user-form"
          />
        );
      default:
        return null;
    }
  };

  const isPreviewable = useMemo(
    () =>
      assetDataToShow
        ? isPreviewInViewer(assetDataToShow.assetData.extension.toUpperCase())
        : false,
    [assetDataToShow]
  );

  const getModalSize = useCallback(() => {
    if (isPreviewable && action === Actions.Show) return ModalSize.Large;
    return ModalSize.Default;
  }, [isPreviewable, action]);

  const renderModalContent = () => {
    switch (action) {
      case Actions.Show:
        return (
          <ShowAssetModalContent
            assetDataToShow={assetDataToShow}
            shouldShowSites={activeTab && !isPrivacyPolicy}
            activeTab={activeTab}
            assetAssociations={assetAssociations}
          />
        );
      case Actions.Edit:
        return (
          <EditAssetModalContent
            assetDataToEdit={assetDataToShow}
            sites={allSitesToShare}
            setReloadTable={setReloadTable}
            activeTab={activeTab}
            setFlashMessage={setFlashMessage}
            closeModal={closeModal}
          />
        );
    }
  };

  const shareAssetToInstitutions = async () => {
    const sitesToSend = allSitesToShare
      .filter(site => site.isChecked === true)
      .map(site => site.key);
    const assetsIds = allAssetData.map(asset => asset.id);
    const newAllSitesToShare = [...allSitesToShare];
    newAllSitesToShare.forEach(site => {
      return (site.isChecked = false);
    });

    setAllSitesToShare(newAllSitesToShare);

    try {
      const data = await axios.post(
        serviceConfig.brokerService.shareAssets.uri,
        {
          institutionIds: sitesToSend,
          assetIds: assetsIds,
        }
      );

      return setFlashMessage({
        content: I18n.t(data.data.content),
        type: data.data.type,
      });
    } catch ({ response: { data } }) {
      setFlashMessage({
        content: I18n.t(data.content),
        type: data.type,
      });
    }
  };

  return (
    <Flex flexDirection="column">
      {responsive ? (
        <Flex
          alignItems="center"
          otherStyles={{ position: 'absolute', right: 0, top: 0 }}
        >
          {activeTab && !isAttachFile && !isAttachFiles && (
            <>
              <input
                id="image-upload"
                type="file"
                onChange={event => {
                  if (event.target.files[0]) handleImageUpload(event);
                }}
                ref={input => (inputRef = input)}
                onClick={e => (e.target.value = '')}
                hidden
                multiple
              />
              <div
                style={
                  responsive
                    ? {
                        display: 'flex',
                        columnGap: '2em',
                        rowGap: '10px',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                      }
                    : ''
                }
              >
                <div
                  style={{
                    width: 250,
                    marginTop: activeTab ? 0 : null,
                    marginRight: activeTab ? 5 : null,
                    marginLeft: activeTab ? 21 : null,
                    position: activeTab ? null : 'absolute',
                    top: activeTab ? null : 12,
                    right: activeTab ? null : 20,
                  }}
                >
                  <TextInput
                    id="specialId"
                    name="Name"
                    placeholder={I18n.t('common_labels.label_search')}
                    variant={inputField.variant.CHAR_COUNT}
                    maxChars={MaxCharLimits.searchInputs.searchKeyword}
                    value={query.value}
                    handleOnChange={e => {
                      setQuery({
                        value: e.target.value,
                        start: query.start,
                        length: query.length,
                        dir: sort.dir,
                        column: sort.column,
                      });
                      setCurrentPage(0);
                      setPageState(10);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: innerWidth == 1280 ? '25px' : '35px',
                    marginLeft: '7px',
                    columnGap: '0em',
                    rowGap: '10px',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box margin="0 -13px 0 0 ">
                    {!displayShareBtn ? (
                      <Tooltip
                        content={I18n.t(
                          'asset_view.tooltip_label_notAllowedToShare'
                        )}
                      >
                        <ShareButtonDropdown
                          buttonLabel={I18n.t('actions.share')}
                          labelHeader={I18n.t('asset_view.shareAssets')}
                          variant={ButtonVariant.OUTLINED}
                          handleSiteSelectedChange={onSiteSelectedChange}
                          items={allSitesToShare}
                          isDisabled={
                            (!allAssetData.length &&
                              displayShareBtn === false) ||
                            (displayShareBtn === true && !allAssetData.length)
                          }
                          onClick={shareAssetToInstitutions}
                          clearCheckedSites={() => {
                            const itemsCopy = [...allSitesToShare];
                            itemsCopy.map(item => {
                              item.isChecked = false;
                              return item;
                            });
                            setAllSitesToShare(itemsCopy);
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <ShareButtonDropdown
                        buttonLabel={I18n.t('actions.share')}
                        labelHeader={I18n.t('asset_view.shareAssets')}
                        variant={ButtonVariant.OUTLINED}
                        handleSiteSelectedChange={onSiteSelectedChange}
                        items={allSitesToShare}
                        isDisabled={
                          (!allAssetData.length && displayShareBtn === false) ||
                          (displayShareBtn === true && !allAssetData.length)
                        }
                        onClick={shareAssetToInstitutions}
                        clearCheckedSites={() => {
                          const itemsCopy = [...allSitesToShare];
                          itemsCopy.map(item => {
                            item.isChecked = false;
                            return item;
                          });
                          setAllSitesToShare(itemsCopy);
                        }}
                      />
                    )}
                  </Box>
                  <Box margin={responsive ? '0 0 0 27px' : ''}>
                    <Button
                      label={I18n.t('common_labels.label_Upload')}
                      disabled={disableBtn || isPrivacyPolicy}
                      onClick={() => {
                        if (hasAssetCreatePermission) {
                          inputRef.click();
                        } else {
                          setFlashMessage({
                            content: I18n.t('common_labels.permissionDenied'),
                            type: 'warning',
                          });
                        }
                      }}
                      size={ButtonSize.MD}
                    >
                      <Icon name="add" size={18} />
                    </Button>
                  </Box>
                </div>
              </div>
            </>
          )}
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          otherStyles={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          {activeTab && !isAttachFile && !isAttachFiles && (
            <>
              <input
                id="image-upload"
                type="file"
                onChange={event => {
                  if (event.target.files[0]) handleImageUpload(event);
                }}
                ref={input => (inputRef = input)}
                onClick={e => (e.target.value = '')}
                hidden
                multiple
              />
              {!isPrivacyPolicy && (
                <Box margin="0 16px 0 0 ">
                  {!displayShareBtn ? (
                    <Tooltip
                      content={I18n.t(
                        'asset_view.tooltip_label_notAllowedToShare'
                      )}
                    >
                      <ShareButtonDropdown
                        buttonLabel={I18n.t('actions.share')}
                        labelHeader={I18n.t('asset_view.shareAssets')}
                        variant={ButtonVariant.OUTLINED}
                        handleSiteSelectedChange={onSiteSelectedChange}
                        items={allSitesToShare}
                        isDisabled={
                          (!allAssetData.length && displayShareBtn === false) ||
                          (displayShareBtn === true && !allAssetData.length)
                        }
                        onClick={shareAssetToInstitutions}
                        clearCheckedSites={() => {
                          const itemsCopy = [...allSitesToShare];
                          itemsCopy.map(item => {
                            item.isChecked = false;
                            return item;
                          });
                          setAllSitesToShare(itemsCopy);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <ShareButtonDropdown
                      buttonLabel={I18n.t('actions.share')}
                      labelHeader={I18n.t('asset_view.shareAssets')}
                      variant={ButtonVariant.OUTLINED}
                      handleSiteSelectedChange={onSiteSelectedChange}
                      items={allSitesToShare}
                      isDisabled={
                        (!allAssetData.length && displayShareBtn === false) ||
                        (displayShareBtn === true && !allAssetData.length)
                      }
                      onClick={shareAssetToInstitutions}
                      clearCheckedSites={() => {
                        const itemsCopy = [...allSitesToShare];
                        itemsCopy.map(item => {
                          item.isChecked = false;
                          return item;
                        });
                        setAllSitesToShare(itemsCopy);
                      }}
                    />
                  )}
                </Box>
              )}
              {!isPrivacyPolicy && (
                <Button
                  label={I18n.t('common_labels.label_Upload')}
                  disabled={disableBtn || isPrivacyPolicy}
                  onClick={() => {
                    if (hasAssetCreatePermission) {
                      inputRef.click();
                    } else {
                      setFlashMessage({
                        content: I18n.t('common_labels.permissionDenied'),
                        type: 'warning',
                      });
                    }
                  }}
                  size={ButtonSize.MD}
                >
                  <Icon name="add" size={18} />
                </Button>
              )}
            </>
          )}
          <div
            style={{
              width: 250,
              marginTop: activeTab ? 0 : null,
              marginRight: activeTab ? 5 : null,
              marginLeft: activeTab ? 22 : null,
              position: activeTab ? null : 'absolute',
              right: 0,
            }}
          >
            <TextInput
              id="specialId"
              name="Name"
              placeholder={I18n.t('common_labels.label_search')}
              maxChars={MaxCharLimits.searchInputs.searchKeyword}
              variant={inputField.variant.CHAR_COUNT}
              value={query.value}
              handleOnChange={e => {
                setQuery({
                  value: e.target.value,
                  start: query.start,
                  length: query.length,
                  dir: sort.dir,
                  column: sort.column,
                });
                setCurrentPage(0);
                setPageState(10);
              }}
            />
          </div>
        </Flex>
      )}
      <Box otherStyles={{ marginTop: comesFromPatientOverview ? 35 : 0 }}>
        <AssetListView
          fetchedData={fetchedData}
          loading={loading}
          hasPagesize={hasPagesize}
          recordsTotal={recordsTotal}
          activeTab={activeTab}
          reloadTable={reloadTable}
          deleteAssetRequest={deleteAssetRequest}
          query={query}
          setQuery={setQuery}
          isAttachFile={isAttachFile}
          setAttachedFile={setAttachedFile}
          setSelectedFile={setSelectedFile}
          setFlashMessage={setFlashMessage}
          setAssetDataToShow={setAssetDataToShow}
          setOpenModal={setDisplayModal}
          setAction={setAction}
          setModalTitle={setModalTitle}
          isSelectAll={isSelectAll}
          setIsSelectAll={setIsSelectAll}
          allAssetData={allAssetData}
          setAllAssetData={setAllAssetData}
          readAndUpdateAsset={readAndUpdateAsset}
          isAttachFiles={isAttachFiles}
          deleteAssetFromPatientOverview={deleteAssetFromPatientOverview}
          blobArray={blobArray}
          setAssetAssociations={setAssetAssociations}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setPageState={setPageState}
          sort={sort}
          setSort={setSort}
          patientId={patientInfo?.id}
          isPrivacyPolicy={isPrivacyPolicy}
        />
      </Box>
      {assetDataToShow && (
        <Modal
          title={modalTitle}
          contentComponent={renderModalContent()}
          footerComponent={renderModalFooter()}
          openModal={displayModal}
          onClose={closeModal}
          modalHeightSize={getModalSize()}
          modalWidthSize={getModalSize()}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </Flex>
  );
}
export default Assets;
