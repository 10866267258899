/* eslint-disable */
import React, { useState, useEffect } from 'react';
import '../../styles/main.scss';
import PropTypes from 'prop-types';
import DropdownMenu from './DropdownMenu';
import Icon from '../Icon/Icon';

import { Greys, PrimaryColor } from '../../utils/colors';

const LanguageDropdown = ({ onClick, items, active }) => {
  const [current, setCurrent] = useState(active>0 ? active : 0);
  const [isActive, setIsActive] = useState(false);

  const buildItems = () => {
    return items.map((item, index) => {
      return (
        <li key={item.value} onClick={e => handleChange(item, index)}>
          <span className="language-dropdown__item">{item.key}</span>
        </li>
      );
    });
  };

  const handleChange = (item, index) => {
    setCurrent(index);
    onClick(item);
  };
  const disableActive = () => {
    setIsActive(false);
  };

  useEffect(() => {
    if (isActive === true) {
      document.addEventListener('click', disableActive);
    }
    return () => {
      document.removeEventListener('click', disableActive);
    };
  }, [isActive]);

  return (
    <div
      className="language-dropdown"
      onClick={e => {
        e.preventDefault();
        setIsActive(true);
      }}
    >
      <div className="language-dropdown__select">
        <DropdownMenu items={buildItems()} variant="language-dropdown__menu">
          <div className="language-dropdown__main">
            <span
              className="language-dropdown__main-content"
              style={{ color: isActive ? PrimaryColor.MAIN_COLOR : Greys.DARK_GREY }}
            >
              {items[current].key}
            </span>
            <Icon
              name={'language'}
              size={24}
              fill={isActive ? PrimaryColor.MAIN_COLOR : Greys.DARK_GREY }
            />
          </div>
        </DropdownMenu>
      </div>
    </div>
  );
};

LanguageDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  active: PropTypes.number
};

export default LanguageDropdown;
