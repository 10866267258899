import React from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import  { ButtonVariant}from '../../../caro-ui-commonfiles/utils/button';
import { PrimaryColor, Greys } from '../../../caro-ui-commonfiles/utils/colors';

const CustomLoader = ({ variant }) => (
  <Button variant={variant || ButtonVariant.CONTAINED} fullWidth size="md">
    <Loader
      className="loader"
      type="Oval"
      color={variant ? PrimaryColor.MAIN_COLOR : Greys.WHITE}
      width={20}
      height={20}
    />
  </Button>
);

CustomLoader.defaultProps = {
  variant: null,
};

CustomLoader.propTypes = {
  variant: PropTypes.string,
};

export default CustomLoader;
