export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}
export enum ButtonVariant {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  NO_BORDER = 'no-border',
  LINK = 'link',
}
export enum ButtonColor {
  MAIN_COLOR = 'main-color',
  RED = 'red',
}
export enum ButtonSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
}
