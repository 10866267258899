import React from 'react';
import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';
import ReportGeneratedDate from '../ReportGeneratedDate/ReportGeneratedDate';
import { CareplanReportPageHeaderProps } from './CareplanReportPageHeader.type';
import './CareplanReportPageHeader.scss';
import { formatTimestamp } from '../../../../../Global/Date';

const CareplanReportPageHeader = React.forwardRef<
  HTMLDivElement,
  CareplanReportPageHeaderProps
>(({ title, allOccurrences }, ref): JSX.Element => {
  return (
    <Box className="page-header" ref={ref}>
      <Box otherStyles={{ height: 40 }} />
      <Box className="page-header_title">{title}</Box>
      <Box otherStyles={{ height: 10 }} />
      <Box className="page-header_date">
        <ReportGeneratedDate />
      </Box>
      <Box otherStyles={{ height: 12 }} />
      <Box className="page-header_columns">
        {allOccurrences.map((occurrence, index) => (
          <Box className="page-header_columns-column" key={`page-col-${index}`}>
            {formatTimestamp(occurrence.unixEpochs)}
          </Box>
        ))}
      </Box>
    </Box>
  );
});

export default CareplanReportPageHeader;
