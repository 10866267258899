import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Flex, Box } from '@ui-common-files/components';

import { ActionColors } from '@ui-common-files/utils';

import { WithingsDeviceListProps } from './WithingsDevice.type';

function WithingsDeviceList({
  withingsDevices,
  onDelete,
}: WithingsDeviceListProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    withingsDevices.length > 0 && (
      <Box margin="40px 0 0 20px">
        <Flex className="withings-device__list-header">
          <Box flex="0.5">
            {t('patientOverview.withings_device.list.row.device_type')}
          </Box>
          <Box flex="1">
            {t('patientOverview.withings_device.common.mac_address')}
          </Box>
          <Box flex="0.5" />
        </Flex>
        <Flex flexDirection="column" className="withings-device__list-rows">
          {withingsDevices.map(item => {
            return (
              <Flex
                key={item.deviceid}
                alignItems="center"
                className="withings-device__list-row"
              >
                <Box flex="0.5">{item.type}</Box>

                <Box flex="1">{item.mac_address}</Box>
                <Flex
                  justifyContent="flex-end"
                  className="withings-device__list-row__delete"
                >
                  <IconButton
                    onClick={() => onDelete(item.deviceid, item.mac_address)}
                    name="delete"
                    size={30}
                    fill={ActionColors.DELETE}
                    tooltipText={t('toolTipsText.delete')}
                  />
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Box>
    )
  );
}

export default WithingsDeviceList;
