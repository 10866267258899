/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import I18n from 'i18next';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import { Title, Footer } from './CardComponents';
import MedicationUpdateModalContent from '../Medications/MedicationUpdateModalContent';
import MedicationShowModalContent from '../Medications/MedicationShowModalContent';
import PatientOverviewModalFooter from '../common/PatientOverviewModalFooter';
import { PatientOverviewModalHeader } from '../common/PatientOverviewModalHeader';
import ShowModalFooter from '../common/ShowModalFooter';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import CardInfoContent from '../common/CardInfoContent';
import { MEDIA_QUERIES } from '@ui-common-files/utils/layout';
import serviceConfig from '../../../serviceConfig.json';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import {
  ComponentTypes,
  Actions,
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { ComponentColors } from '../../../caro-ui-commonfiles/utils/colors';
import { ApiClient, ShowFlashMessage } from '@global/Services';

const MedicationCard = ({ data, dosage, setCardCount }) => {
  const {
    setModalContent,
    setMessagePrompt,
    setLandingTab,
    closeMessagePrompt,
    closeModal,
    checkUserCredential,
    landingTab,
    patientInfo,
  } = useContext(PatientOverviewContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const fetchCount = async () => {
    const response = await ApiClient.GET({
      url: serviceConfig.brokerService.getAllMedicationsForPatient.uri,
      payload: {
        patientId: patientInfo.id,
      },
    });
    if (response) {
      setCardCount(prevCount => {
        const indexOfCardCount = prevCount.findIndex(
          item => item.component === ComponentTypes.MEDICATION
        );
        const tempCount = [...prevCount];
        tempCount[indexOfCardCount].total = response.data.length;
        return tempCount;
      });
    }
  };

  useEffect(() => {
    if (landingTab) fetchCount();
  }, [landingTab]);

  const sendMedication = async () => {
    setLandingTab(false);
    return await ApiClient.POST({
      url: serviceConfig.brokerService.sendMedication.uri,
      payload: {
        reminderId: data.reminderElement.reminderId,
      },
    }).then(response => {
      if (response) setLandingTab(ComponentTypes.MEDICATION);
    });
  };

  const unassignMedication = async () => {
    setLandingTab(false);
    return await ApiClient.GET({
      url: serviceConfig.brokerService.unassignMedication.uri,
      payload: {
        reminderId: data.reminderElement.reminderId,
      },
    }).then(response => {
      if (response) setLandingTab(ComponentTypes.MEDICATION);
    });
  };

  const deleteMedication = async () => {
    setLandingTab(false);
    return await ApiClient.GET({
      url: serviceConfig.brokerService.deleteMedication.uri,
      payload: {
        reminderId: data.reminderElement.reminderId,
      },
    }).then(response => {
      if (response) {
        setLandingTab(ComponentTypes.MEDICATION);
        ShowFlashMessage({
          type: response.data.type,
          messageKey: response.data.content,
        });
      }
    });
  };

  const sendHandler = data.reminderElement.firebaseKey ? null : sendMedication;
  const unassignHandler = sendHandler ? null : unassignMedication;
  const resultHandler = null;

  const renderEditModalContent = async () => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.MEDICATION,
      ActionTranslationKeys.UPDATE
    );

    if (userHasCredential) {
      setModalContent({
        title: `${I18n.t('medication.editMedication')}: ${data.name}`,
        content: <MedicationUpdateModalContent data={data} />,
        footer: (
          <PatientOverviewModalFooter
            componentType={ComponentTypes.MEDICATION}
            actionType={Actions.Update}
          />
        ),
        header: (
          <PatientOverviewModalHeader
            settingsStepTranslation={I18n.t('steps.medicationSettings')}
          />
        ),
      });
    }
  };

  const renderDeleteDialog = async user => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.MEDICATION,
      ActionTranslationKeys.DELETE
    );
    if (userHasCredential) {
      setMessagePrompt({
        title: `${I18n.t(
          'common_labels.msg_confirm_medication_delete_headline'
        )}: ${data.name}`,
        content: (
          <PromptContent
            message={I18n.t('common_labels.msg_confirm_medication_delete')}
          />
        ),
        footer: (
          <PromptFooter
            close={closeMessagePrompt}
            confirmHandler={() => deleteMedication(user)}
          />
        ),
      });
    }
  };

  const renderShowModalContent = async () => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.MEDICATION,
      ActionTranslationKeys.SHOW
    );
    if (userHasCredential) {
      setModalContent({
        title: `${I18n.t('medication.showMedication')}: ${data.name}`,
        content: <MedicationShowModalContent data={data} />,
        footer: <ShowModalFooter close={closeModal} />,
      });
    }
  };

  const renderDosageInfo = dosagesList =>
    dosagesList.map(dosageObj => (
      <Flex
        key={`dosage-${dosageObj.duration}`}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div className="assignment-card__medication__duration">
          {dosageObj.duration}
        </div>
        <div className="assignment-card__medication__dosage">
          {dosageObj.dosage}
        </div>
      </Flex>
    ));

  const renderContent = () => (
    <Flex
      flexDirection={windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'}
      otherStyles={{ paddingLeft: '60px', marginTop: '10px' }}
    >
      <CardInfoContent
        data={data}
        variant="medication"
        dosage={renderDosageInfo(dosage)}
      />

      <div className="empty-container" />
    </Flex>
  );

  return (
    <div className="assignment-card">
      <Title
        iconName={ComponentTypes.MEDICATION}
        color={ComponentColors.MEDICATION}
        data={data}
        onEditIconClick={renderEditModalContent}
        onShowIconClick={renderShowModalContent}
        onDeleteClick={renderDeleteDialog}
        isMedication={true}
      />
      {renderContent()}
      <Footer
        sendHandler={sendHandler}
        unassignHandler={unassignHandler}
        resultHandler={resultHandler}
        componentType={ComponentTypes.MEDICATION}
        firebaseKey={data.reminderElement.firebaseKey}
      />
    </div>
  );
};

MedicationCard.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  dosage: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MedicationCard;
