import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import inputField from '../../utils/inputField';
import { Greys, ActionColors } from '../../utils/colors';

import '../../styles/main.scss';
import {
  TextInputAutoComplete,
  TextInputProps,
  TextInputType,
} from './TextInput.type';

const TextInput = ({
  type = TextInputType.TEXT,
  id,
  name,
  placeholder,
  isDisabled = false,
  autoComplete = TextInputAutoComplete.OFF,
  hasError = false,
  hasSuccess = false,
  validationMessage,
  variant = inputField.variant.DEFAULT,
  isLeftIcon = true,
  iconName,
  isLinkWithIcon = false,
  maxChars,
  rightHelperText,
  value,
  handleOnChange,
  ...rest
}: TextInputProps) => {
  const charsCount = value.length;

  const handleInput = e => {
    const inputText = e.target.value;
    const newCharsCount = inputText.length;

    if (variant === inputField.variant.CHAR_COUNT && newCharsCount > maxChars) {
      e.preventDefault();
      e.stopPropagation();

      e.target.value = inputText.slice(0, maxChars);
    }
    handleOnChange(e);
  };

  /* Classes
   ========================================================================== */

  const inputWrapperClasses = classNames({
    input__text__wrapper: true,
    disabled: isDisabled,
    'input--error':
      (variant === inputField.variant.DEFAULT ||
        variant === inputField.variant.CHAR_COUNT ||
        variant === inputField.variant.VALUE_RIGHT) &&
      hasError &&
      !hasSuccess &&
      !isDisabled,
    'input--success':
      variant === inputField.variant.DEFAULT &&
      hasSuccess &&
      !hasError &&
      !isDisabled,
    'inline-input': variant === inputField.variant.INLINE_INPUT,
  });

  const inputClasses = classNames({
    input__text: true,
  });

  const iconClasses = classNames({
    input__text__icon: true,
    'left-icon': isLeftIcon,
    'right-icon': !isLeftIcon,
  });

  /* Validation
   ========================================================================== */

  let validationHelperText;
  let validationIcon;

  if (
    validationMessage &&
    ((hasSuccess && !hasError) || (hasError && !hasSuccess))
  ) {
    validationHelperText = <span>{validationMessage}</span>;
  }

  if (hasSuccess && !hasError) {
    validationIcon = (
      <Icon
        name="success"
        fill={ActionColors.SUCCESS}
        className="input__text__icon right-icon"
      />
    );
  }

  if (hasError && !hasSuccess) {
    validationIcon = (
      <Icon
        name="error"
        fill={ActionColors.ERROR}
        className="input__text__icon right-icon"
      />
    );
  }

  /* Icon (left) + Input text + Link (right)
   ========================================================================== */

  const linkWithIcon =
    variant === inputField.variant.WITH_ICON && isLinkWithIcon && isLeftIcon;

  return (
    <div className="input__wrapper">
      <div className={inputWrapperClasses}>
        {variant === inputField.variant.WITH_ICON && (
          <Icon
            name={iconName}
            fill={Greys.LIGHT_GREY}
            className={iconClasses}
          />
        )}

        {validationIcon}

        <input
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={handleInput}
          placeholder={placeholder}
          className={inputClasses}
          disabled={isDisabled}
          autoComplete={autoComplete}
          {...rest}
        />

        {linkWithIcon && (
          <a href="" target="_blank" className="input__text__link">
            {rightHelperText}
          </a>
        )}
        {variant === inputField.variant.VALUE_RIGHT && (
          <span className="helperText">{rightHelperText}</span>
        )}
        {variant === inputField.variant.CHAR_COUNT && (
          <span className="helperText">
            {charsCount}/{maxChars}
          </span>
        )}
      </div>
      {(variant === inputField.variant.DEFAULT ||
        variant === inputField.variant.VALUE_RIGHT ||
        variant === inputField.variant.CHAR_COUNT) &&
        validationHelperText}
    </div>
  );
};

export default TextInput;
