import React from 'react';
import PropTypes from 'prop-types';

const ToastChat = ({ unreadMessages, content }) => (
  <div className="toast">
    {`${unreadMessages} ${content}`}
  </div>
);

ToastChat.propTypes = {
  unreadMessages: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
};

export default ToastChat;
