import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import I18n from 'i18next';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

import Button from '../../caro-ui-commonfiles/components/Button/Button';
import Box from '../../caro-ui-commonfiles/components/Box/Box';
import CheckBox from '../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import CheckBoxRadioVariants from '../../caro-ui-commonfiles/utils/CheckboxRadio';
import FlashMessage from '../../caro-ui-commonfiles/components/FlashMessage/FlashMessage';
import NavigationJSON from '../Utils/navigation.json';
import serviceConfig from '../../serviceConfig.json';
import { FirebaseContext } from '../Utils/Firebase/firebase';
import IdleTimer from '../Utils/TimerTemplate';
import logOut from '../Utils/logOut';
import '../css/login.scss';
import checkUserPinAndOptins from '../Utils/Administration/checkUserPinAndOptins';
import LoginHeader from './Login/LoginHeader';
import LoginFooter from './Login/LoginFooter';
import CustomLoader from './Login/CustomLoader';
import appendRoute from '../Global/Strings/AppendRoute/AppendRoute';

const Optins = () => {
  const [privacyPermission, setPrivacyPermission] = useState({
    privacy: false,
    term: false,
    consent: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [flashMessage, setFlashMessage] = useState({});
  const {
    setIsLoggedIn,
    setUserPinAndOptinsInfo,
    userPinAndOptinsInfo,
  } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.session);
  const history = useHistory();

  const language = I18n.language.trim();
  let termsAndConditions_PR_url =
    'https://www.myoncare.com/pt/terms-and-conditions';
  let privacyPolicy_PR_url = 'https://www.myoncare.com/pt/privacy-policy';
  let termsAndConditions_DE_url =
    'https://www.myoncare.com/de/terms-and-conditions';
  let privacyPolicy_DE_url = 'https://www.myoncare.com/de/privacy-policy';
  let termsAndConditions_TH_url =
    'https://www.myoncare.com/th/terms-and-conditions';
  let privacyPolicy_TH_url = 'https://www.myoncare.com/th/privacy-policy';
  let termsAndConditions_FR_url =
    'https://www.myoncare.com/fr/terms-and-conditions';
  let privacyPolicy_FR_url = 'https://www.myoncare.com/fr/privacy-policy';
  let termsAndConditions_IT_url =
    'https://www.myoncare.com/it/terms-and-conditions';
  let privacyPolicy_IT_url = 'https://www.myoncare.com/it/privacy-policy';
  let termsAndConditions_AR_url =
    'https://www.myoncare.com/ar/terms-and-conditions';
  let privacyPolicy_AR_url = 'https://www.myoncare.com/ar/privacy-policy';
  let termsAndConditions_default_url =
    'https://www.myoncare.com/terms-and-conditions';
  let privacyPolicy_default_url = 'https://www.myoncare.com/privacy-policy';

  let currentLanguage = language.split('-')[0];
  switch (currentLanguage) {
    case 'it':
      termsAndConditions_default_url = termsAndConditions_IT_url;
      privacyPolicy_default_url = privacyPolicy_IT_url;
      break;
    case 'th':
      termsAndConditions_default_url = termsAndConditions_TH_url;
      privacyPolicy_default_url = privacyPolicy_TH_url;
      break;
    case 'pt':
      termsAndConditions_default_url = termsAndConditions_PR_url;
      privacyPolicy_default_url = privacyPolicy_PR_url;
      break;
    case 'de':
      termsAndConditions_default_url = termsAndConditions_DE_url;
      privacyPolicy_default_url = privacyPolicy_DE_url;
      break;
    case 'fr':
      termsAndConditions_default_url = termsAndConditions_FR_url;
      privacyPolicy_default_url = privacyPolicy_FR_url;
      break;
    case 'ar':
      termsAndConditions_default_url = termsAndConditions_AR_url;
      privacyPolicy_default_url = privacyPolicy_AR_url;
      break;
    default:
      break;
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await checkUserPinAndOptins(user.id);
      setUserPinAndOptinsInfo(response);
    };
    if (user) {
      fetchData();
    }
  }, []);

  if (user && userPinAndOptinsInfo) {
    if (
      userPinAndOptinsInfo.isTermsAndConditions &&
      userPinAndOptinsInfo.isPrivacyPolicy &&
      userPinAndOptinsInfo.isConsentForTracking
    ) {
      if (
        userPinAndOptinsInfo.isTwoFactorAuthentication &&
        !userPinAndOptinsInfo.isPinVerified
      ) {
        history.push(`/${NavigationJSON.VERIFICATION}`);
        return null;
      }
      history.push(`/${NavigationJSON.PATIENT}`);
      return null;
    }
  }

  const handleLogOut = () => logOut(setIsLoggedIn, history, user);

  const hideFlashMessage = () => setFlashMessage({});

  const submitQuery = async () => {
    setIsLoading(true);

    const userObject = {
      userId: user.id,
      isTermsAndConditions: privacyPermission.term,
      isPrivacyPolicy: privacyPermission.privacy,
      isConsentForTracking: privacyPermission.consent,
    };

    try {
      const userResponse = (
        await axios.post(serviceConfig.brokerService.saveOptIns.uri, userObject)
      ).data;
      let temp = { ...userPinAndOptinsInfo };
      temp.isTermsAndConditions = userResponse.isTermsAndConditions;
      temp.isPrivacyPolicy = userResponse.isPrivacyPolicy;
      temp.isConsentForTracking = userResponse.isConsentForTracking;
      temp.isTwoFactorAuthentication = userResponse.isTwoFactorAuthentication;
      temp.isActive = userResponse.isActive;
      setUserPinAndOptinsInfo({ ...temp });

      if (
        userPinAndOptinsInfo.isTwoFactorAuthentication &&
        !userPinAndOptinsInfo.isPinVerified
      ) {
        const body = {
          userId: user.id,
          userLanguage: I18n.language,
        };
        await axios.post(serviceConfig.brokerService.resendPin.uri, body);
        history.push(`/${NavigationJSON.VERIFICATION}`);
      } else {
        history.push(`/${NavigationJSON.PATIENT}`);
      }
    } catch (err) {
      setIsLoading(false);
      setFlashMessage({
        type: 'error',
        content: 'flash.unexpectedError',
      });
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    submitQuery();
  };

  return (
    <>
      <IdleTimer logOut={handleLogOut} />
      <Box width="100%" height="100%">
        <div className="login-container">
          <div>
            {flashMessage.content && (
              <div className="flash-message-style">
                <FlashMessage
                  message={I18n.t(flashMessage.content)}
                  type={flashMessage.type}
                  onClick={hideFlashMessage}
                />
              </div>
            )}
          </div>

          <LoginHeader handleGoHome={handleLogOut} />

          <div className="login-content-container">
            <div className="credential-container">
              <form
                className="login__widget-panel__container"
                onSubmit={handleFormSubmit}
              >
                <div className="login-header__wrapper">
                  <span className="login-header">
                    {I18n.t('common_labels.label_termsAndConditions')}
                  </span>
                </div>

                <div className="login-info-container optins__text-rows-spacing">
                  <span>
                    <CheckBox
                      id="login-privacyPolicy"
                      name="login-privacyPolicy"
                      variant={CheckBoxRadioVariants.DEFAULT}
                      checked={privacyPermission.privacy}
                      handleOnChange={e => {
                        setPrivacyPermission({
                          ...privacyPermission,
                          privacy: e.target.checked,
                        });
                      }}
                    />
                  </span>
                  <span className="privacy-policy-text">
                    {ReactHtmlParser(
                      I18n.t('common_labels.privacyPolicy_label', {
                        name: 'privacy-policy__link',
                        target: '__blank',
                        href: privacyPolicy_default_url,
                      })
                    )}
                  </span>
                </div>
                <div className="login-info-container optins__text-rows-spacing">
                  <span>
                    <CheckBox
                      id="login-term-cond"
                      name="login-term-cond"
                      variant={CheckBoxRadioVariants.DEFAULT}
                      checked={privacyPermission.term}
                      handleOnChange={e => {
                        setPrivacyPermission({
                          ...privacyPermission,
                          term: e.target.checked,
                        });
                      }}
                    />
                  </span>
                  <span className="privacy-policy-text">
                    {ReactHtmlParser(
                      I18n.t('common_labels.term_cond_label', {
                        name: 'privacy-policy__link',
                        target: '__blank',
                        href: termsAndConditions_default_url,
                      })
                    )}
                  </span>
                </div>
                <div className="login-info-container">
                  <span>
                    <CheckBox
                      id="login-term-cond"
                      name="login-term-cond"
                      variant={CheckBoxRadioVariants.DEFAULT}
                      checked={privacyPermission.consent}
                      handleOnChange={e => {
                        setPrivacyPermission({
                          ...privacyPermission,
                          consent: e.target.checked,
                        });
                      }}
                    />
                  </span>
                  <span className="privacy-policy-text">
                    {ReactHtmlParser(
                      I18n.t('common_labels.consent_label', {
                        name: 'privacy-policy__link',
                        target: '__blank',
                        href: appendRoute('privacy-policy'),
                      })
                    )}
                  </span>
                </div>

                <div className="button-container">
                  {isLoading ? (
                    <CustomLoader />
                  ) : (
                    <Button
                      label={I18n.t('common_buttons.consent')}
                      type="submit"
                      fullWidth
                      disabled={
                        !privacyPermission.privacy || !privacyPermission.term
                      }
                      size="md"
                    />
                  )}
                </div>
              </form>

              <LoginFooter />
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default withTranslation()(Optins);
