import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import BodyChart from '../../../../../caro-ui-commonfiles/components/BodyChart/BodyChart';
import AnswerHeaderComponent from '../AnswerHeaderComponent';

import BodyPosition from '../../../../../caro-ui-commonfiles/components/BodyChart/bodyPosition';
import { BodyChartPoint } from '../../../../../caro-ui-commonfiles/components/BodyChart/BodyChart.type';
import { GeneralAnswerType } from '../GeneralAnswer.type';
import {
  CareplanComponentAnswer,
  CarePlanReportOccurrence,
} from '../../../../types/Careplan';

import { getIsThreshold } from '../../../ReportGenerator/Careplan/CareplanReport.utils';
import { formatTimestamp } from '../../../../Global/Date';
import {
  occurrenceHasAnswer,
  getComponentOccurrencesAnswer,
  getThresholdClass,
  getAnswersOccurrenceById,
} from '../AnswerResult.services';

import '../AnswerResultCommon.scss';

function PainLocationChartAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const { t } = useTranslation();
  const BODY_CHART_SIZE = { width: 71, height: 175 };
  const HALF_SIZE_OF_POINTER = 5;

  const renderComponentAnswers = useCallback(
    (
      occurrenceAnswers: CareplanComponentAnswer[],
      index: number,
      answerDate: number
    ) => {
      const isThreshold: boolean = getIsThreshold(
        componentData,
        occurrenceAnswers[0]
      );

      const thresholdClass = getThresholdClass(isThreshold);

      let answerContent = '-';
      if (occurrenceHasAnswer(occurrenceAnswers)) {
        answerContent = t(`bodyChartImage.${occurrenceAnswers[0].bodychart}`);
        if (isThreshold) answerContent += '*';
      }

      return (
        <Flex
          className="answer-component_row-section-item"
          key={`col-header-${componentIndex}-${index}`}
          alignItems="center"
        >
          <span className="answer-component_row-section-item-date">
            {formatTimestamp(answerDate)}
          </span>
          <span className="answer-component_row-section-item-bar">|</span>
          <span
            className={`answer-component_row-section-item-content ${thresholdClass}`}
          >
            {answerContent}
          </span>
        </Flex>
      );
    },
    []
  );

  const getPainLocationPoints = (isBack: boolean): BodyChartPoint[] => {
    const points: BodyChartPoint[] = [];
    allOccurrences.forEach((occurrence: CarePlanReportOccurrence) => {
      const occurrenceAnswers: CareplanComponentAnswer[] =
        getAnswersOccurrenceById(componentData.answers, occurrence.id);

      occurrenceAnswers
        .filter(answer => answer.back === isBack)
        .forEach(answer => {
          if (answer.answer_x && answer.answer_y)
            points.push({
              marginTop:
                parseFloat(answer.answer_y) * BODY_CHART_SIZE.height -
                HALF_SIZE_OF_POINTER,
              marginLeft:
                parseFloat(answer.answer_x) * BODY_CHART_SIZE.width -
                HALF_SIZE_OF_POINTER,
              isThreshold: getIsThreshold(componentData, answer),
            });
        });
    });

    return points;
  };

  return (
    <Box className="answer-component">
      <Flex>
        <Box flex={1}>
          <AnswerHeaderComponent
            componentIndex={componentIndex}
            iconName="PainLocationChart"
            title={componentData.name}
          />
          <Box className="answer-component_row-section">
            {getComponentOccurrencesAnswer(
              allOccurrences,
              componentData.answers,
              renderComponentAnswers
            )}
          </Box>
        </Box>
        <Flex otherStyles={{ padding: '5px 0' }}>
          <Box otherStyles={{ marginLeft: 28 }}>
            <BodyChart
              circlePos={getPainLocationPoints(false)}
              type={BodyPosition.front}
              size={{ ...BODY_CHART_SIZE }}
              showAsReport={true}
            />
          </Box>
          <Box otherStyles={{ marginLeft: 28 }}>
            <BodyChart
              circlePos={getPainLocationPoints(true)}
              type={BodyPosition.back}
              size={{ ...BODY_CHART_SIZE }}
              showAsReport={true}
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
export default PainLocationChartAnswer;
