import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Box from '../../../../../../../caro-ui-commonfiles/components/Box/Box';
import RadioButton from '../../../../../../../caro-ui-commonfiles/components/RadioButton/RadioButton';
import CheckboxRadio from '../../../../../../../caro-ui-commonfiles/utils/CheckboxRadio';

import { CaretaskComponent } from '../../../../../../types/Caretask';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import {
  AnsweringYesNoSectionProps,
  YesNoOptions,
} from './AnsweringYesNoSection.type';

import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';

export default function AnsweringYesNoSection({
  handleOnChange,
  YesNo,
}: AnsweringYesNoSectionProps): JSX.Element {
  const { t } = useTranslation();
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);
  const defaultValue: number =
    currentAnswer?.answer !== null
      ? currentAnswer?.answer
      : YesNoOptions.NotSpecified;

  const [selected, setSelected] = useState<YesNoOptions>(defaultValue);

  useEffect(() => {
    handleOnChange({
      answer: selected === YesNoOptions.NotSpecified ? null : selected,
      isSkipped: selected === YesNoOptions.NotSpecified,
      jumpTo:
        YesNoOptions.NotSpecified === selected
          ? currentComponent.logicJumpPaths[0]
          : currentComponent.logicJumpPaths[selected],
    });
  }, [selected, handleOnChange]);

  useEffect(() => {
    setSelected(defaultValue);
  }, [currentComponent]);

  return (
    <Box>
      <Box otherStyles={{ padding: '5px 0px' }}>
        <RadioButton
          name="yes-option"
          variant={CheckboxRadio.Default}
          checked={selected === YesNoOptions.Yes}
          handleOnChange={() => setSelected(YesNoOptions.Yes)}
          label={t(YesNo ? 'common_labels.yes' : 'common_labels.enter')}
        />
      </Box>
      <Box otherStyles={{ padding: '5px 0px' }}>
        <RadioButton
          name="no-option"
          variant={CheckboxRadio.Default}
          checked={selected === YesNoOptions.No}
          handleOnChange={() => setSelected(YesNoOptions.No)}
          label={t(YesNo ? 'common_labels.no' : 'common_labels.skip')}
        />
      </Box>
    </Box>
  );
}
