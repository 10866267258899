import React, { useRef } from 'react';

import { Box, Flex, Button, Tag } from '@ui-common-files/components';

import { ActionColors, PrimaryColor } from '@ui-common-files/utils';

import assetUploadCheck from './AssetUploadCheck.service';

const AssetUploadCheck = () => {
  const { uploadResponse, handleImageUpload } = assetUploadCheck();
  let inputRef = useRef(null);
  return (
    <Flex>
      <Box
        margin="35px 0 15px 0"
        className="custom-label"
        otherStyles={{ color: PrimaryColor.MAIN_COLOR }}
      >
        Scanner service:
      </Box>
      <Flex otherStyles={{ margin: '27px 15px 0' }}>
        <Box>
          <input
            id="image-upload"
            type="file"
            onChange={event => {
              if (event.target.files[0]) handleImageUpload(event);
            }}
            ref={input => (inputRef = input)}
            onClick={e => (e.target.value = '')}
            hidden
            multiple
          />
          <Button
            label="Upload"
            onClick={() => {
              inputRef.click();
            }}
          />
        </Box>
        <Box margin="6px">
          {uploadResponse && (
            <Tag
              text={uploadResponse}
              hasMark
              fill={
                uploadResponse.substring(0, 2) === 'OK'
                  ? ActionColors.SUCCESS
                  : ActionColors.ERROR
              }
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
export default AssetUploadCheck;
