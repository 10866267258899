function EnumToArray(
  Enum: any,
  KeyName: string = 'key',
  ValueName: string = 'value'
) {
  const array: any = [];
  Object.keys(Enum).forEach(key => {
    const obj: any = {};
    obj[KeyName] = key;
    obj[ValueName] = Enum[key];
    array.push(obj);
  });
  return array;
}

export default EnumToArray;
