import axios from 'axios';
import I18n from 'i18next';
import serviceConfig from '../../../../serviceConfig.json';
import {
  convertBase64UrlToFile,
  convertLinkToBlob,
} from '../../Asset/assetHelper';
import avatarTypes from '../../Asset/assetAvatarTypes.json';
import {
  createAvatarAsset,
  createAvatarResource,
  updateAvatarAsset,
  deleteAvatarResource,
} from '../../Asset/assetAvatarHelper';

let patientsByQueryController;
export const getPatientsByQueryController = () => patientsByQueryController;

const getNewPatientsByQueryController = () => {
  patientsByQueryController = new AbortController();
  return patientsByQueryController.signal;
};

export const ERR_CANCELED = 'ERR_CANCELED';

export async function getPatientsByQuery(requestData) {
  let resp;
  return axios
    .get(serviceConfig.brokerService.getPatientsByQuery.uri, {
      params: requestData,
      timeout: 30000,
      signal: getNewPatientsByQueryController(),
    })
    .then(response => {
      if (response === null) throw new Error(ERR_CANCELED);
      resp = response.data;
      const patients = resp.data;
      return Promise.all(
        patients.map(async patient => {
          if (patient.profilePicture.directLink) {
            patient.profilePicture = await convertLinkToBlob(
              patient.profilePicture.directLink
            );
          } else {
            patient.profilePicture = `data:image;base64,${patient.profilePicture}`;
          }
          return patient;
        })
      );
    })
    .then(data => ({ ...resp, data }))
    .catch(error => {
      if (error.message.includes(ERR_CANCELED)) {
        throw new Error(ERR_CANCELED);
      }
      return Promise.reject(error);
    });
}

export async function getPatientsByCustomFilter(requestData) {
  let resp;
  return axios
    .post(serviceConfig.brokerService.applyCustomFilter.uri, requestData, {
      timeout: 10000,
    })
    .then(response => {
      resp = response.data;
      const patients = resp.data;
      return Promise.all(
        patients.map(async patient => {
          if (patient.profilePicture.directLink) {
            patient.profilePicture = await convertLinkToBlob(
              patient.profilePicture.directLink
            );
          } else {
            patient.profilePicture = `data:image;base64,${patient.profilePicture}`;
          }
          return patient;
        })
      );
    })
    .then(data => ({ ...resp, data }))
    .catch(error => Promise.reject(error));
}

export async function addPatientApiCall() {
  return axios
    .get(serviceConfig.patientService.addPatient.uri)
    .then(dataInfo => {
      let institutions = [];
      if (dataInfo.data.sites) {
        institutions = dataInfo.data.sites.map(org => ({
          value: org.id,
          label: org.name,
          site: org,
        }));
      }
      return {
        sites: institutions,
        icdsMaxLength: dataInfo.data.maxNumOfSelections,
        patient: {
          firstName: '',
          lastName: '',
          medicalRecord: '',
          pseudonymCode: '',
          birthDate: null,
          email: '',
          mobile: '',
          gender: null,
          room: '',
          ward: '',
          bed: '',
          inHousePatient: false,
          currentSite: null,
          profilePicture: convertBase64UrlToFile(
            'default_profile-picture.png',
            `data:image;base64,${dataInfo.data.profilePicture}`
          ),
          address: '',
          city: '',
          postalCode: '',
          country: '',
          InsuranceType: '',
          InsuranceCompany: '',
          InsuranceNumber: '',
          caseNumber: '',
          insuranceTypes: dataInfo.data.insuranceTypes,
        },
      };
    });
}

export async function createPatient(body) {
  let asset;
  let newPatient;
  return createAvatarAsset(
    body.profilePicture,
    body.medicalRecord,
    avatarTypes.PATIENTS
  )
    .then(resourceSpaceData => {
      const { collectionId, assetId } = resourceSpaceData.data;
      asset = assetId;
      body.collectionId = collectionId;
      return axios
        .post(serviceConfig.patientService.createPatient.uri, body, {
          timeout: 30000,
        })
        .catch(error => {
          return Promise.reject(error);
        });
    })
    .then(resp => {
      const { newPatientId } = resp.data;
      newPatient = resp.data;
      return createAvatarResource(newPatientId, avatarTypes.PATIENTS, asset);
    })
    .then(() => newPatient)
    .catch(error => {
      return Promise.reject(error);
    });
}

export async function editPatient(id) {
  return axios
    .get(`${serviceConfig.patientService.editPatient.uri}/${id}`)
    .then(async response => {
      if (response?.data) {
        let institutions = [];
        if (response.data.patient.institutions) {
          institutions = response.data.patient.institutions.map(site => ({
            value: site.id,
            label: site.name,
            site,
          }));
        }
        let allSites = [];
        if (response.data.allSites) {
          allSites = response.data.allSites.map(site => ({
            value: site.id,
            label: site.name,
            site,
          }));
        }
        let careTeam = [];
        if (response.data.careTeam) {
          careTeam = response.data.careTeam.map(member => ({
            value: member.id,
            label: `${member.firstname} ${member.lastname}`,
          }));
        }

        let allCareTeam = [];
        if (response.data.users) {
          allCareTeam = response.data.users.map(member => ({
            value: member.id,
            label: `${member.firstname} ${member.lastname}`,
          }));
        }
        let profilePicture;
        if (
          !response.data.patient.isPatientDemographicData &&
          response.data.patient.profilePicture.directLink
        ) {
          await convertLinkToBlob(response.data.patient.profilePicture.directLink)
            .then(blob => {
              profilePicture = blob;
            })
            .catch(() => {
              profilePicture = response.data.patient.profilePicture.directLink;
            });
        } else {
          profilePicture = `data:image;base64,${response.data.patient.profilePicture}`;
        }

        return {
          patientId: id,
          sites: institutions,
          icdsMaxLength: response.data.maxNumOfSelections,
          patientIcds: response.data.patientIcds,
          patientOps: response.data.patientOps,
          careTeam,
          allCareTeam,
          patient: {
            firstName: !response.data.patient.isPatientDemographicData
              ? response.data.patient.firstname
              : '',
            lastName: !response.data.patient.isPatientDemographicData
              ? response.data.patient.lastname
              : '',
            medicalRecord: response.data.patient.medicalRecord,
            birthDate: !response.data.patient.isPatientDemographicData
              ? new Date(response.data.patient.birthdate)
              : response.data.patient.age,
            email: response.data.patient.email || '',
            mobile: response.data.patient.mobileNumber || '',
            gender:
              // eslint-disable-next-line no-nested-ternary
              response.data.patient.gender === 'Female'
                ? {
                    value: 'Female',
                    label: I18n.t('patients_view.gender_option2'),
                  }
                : response.data.patient.gender === 'Male'
                ? {
                    value: 'Male',
                    label: I18n.t('patients_view.gender_option1'),
                  }
                : {},
            room: response.data.patient.room,
            ward: response.data.patient.ward,
            bed: response.data.patient.bed,
            inHousePatient: response.data.patient.isInHouse,
            currentSite: institutions,
            profilePicture,
            collectionId: response.data.patient.collectionId,
            address: response.data.patient.address || '',
            city: response.data.patient.city || '',
            postalCode: response.data.patient.postalCode || '',
            country: response.data.patient.country || '',
            InsuranceType: response.data.patient.InsuranceType || '',
            InsuranceCompany: response.data.patient.InsuranceCompany || '',
            InsuranceNumber: response.data.patient.InsuranceNumber || '',
            caseNumber: response.data.patient.caseNumber || '',
            pseudonymCode: response.data.patient.pseudonymCode || '',
            isPatientDemographicData:
              response.data.patient.isPatientDemographicData,
            isBirthdayHardcoded: response.data.patient.isBirthdayHardcoded,
            insuranceTypes: response.data.insuranceTypes
          },
        };
      }
    });
}

export async function updatePatient(patientId, body) {
  return updateAvatarAsset(
    patientId,
    body.profilePicture,
    body.collectionId,
    body.medicalRecord,
    avatarTypes.PATIENTS
  )
    .then(resource => {
      const { collection, profilePicture } = resource;
      body.collectionId = collection;
      body.profilePicture = profilePicture;
      return axios.post(
        `${serviceConfig.patientService.updatePatient.uri}/${patientId}`,
        body,
        { timeout: 30000 }
      );
    })
    .then(response => response.data)
    .catch(error => {
      return Promise.reject(error);
    });
}

export async function deletePatient(req) {
  let deleteResponse;
  return axios
    .get(`${serviceConfig.patientService.deletePatient.uri}/${req.id}`)
    .then(response => {
      deleteResponse = response?.data;
      if (req.collectionId) {
        return deleteAvatarResource(req.id, avatarTypes.PATIENTS);
      }
      return Promise.resolve();
    })
    .then(() => deleteResponse)
    .catch(error => Promise.reject(error));
}

export async function showPatient(id, healthScore, scoringSchemeCategory) {
  return axios
    .get(`${serviceConfig.patientService.showPatient.uri}/${id}`)
    .then(async response => {
      if (response.data.patient.profilePicture.directLink) {
        await convertLinkToBlob(
          response.data.patient.profilePicture.directLink
        ).then(blob => {
          response.data.patient.profilePicture = blob;
        });
      } else {
        response.data.patient.profilePicture = `data:image;base64,${response.data.patient.profilePicture}`;
      }

      return {
        data: response.data,
        healthScore,
        scoringSchemeCategory,
      };
    });
}
