/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Motion, spring } from 'react-motion';

import PropTypes from 'prop-types';

import { PrimaryColor } from '../../utils/colors'

import '../../styles/main.scss';
import IconButton from '../IconButton/IconButton';

// CONSTANTS

const degToRad = 0.0174533;
const stiffness = 320;
const damping = 25;
const rotation = -46;
const flyOutRadius = 150;

// UTILITY FUNCTIONS

const toRadians = degrees => {
  return degrees * degToRad;
};

const MenuButton = ({ menuItems, rightOffset, tooltipText }) => {
  const [isOpen, setIsOpen] = useState();
  const [toggleClass, setToggleClass] = useState('');


  const toggleAction = () => {
    setIsOpen(!isOpen);

    setToggleClass(
      !isOpen ? ' floating-button--opened' : ' floating-button--closed'
    );
  };

  const close = () => {
    setIsOpen(false);
    setToggleClass(' floating-button--closed');
  };

  const getInitalChildButtonStyle = () => {
    return {
      width: 45,
      height: 45,
      zIndex: -1,
      top: spring(65 / 2 - 50 / 2, {
        stiffness,
        damping,
      }),
      left: spring(65 / 2 - 50 / 2, {
        stiffness,
        damping,
      }),
    };
  };

  const getFinalChildButtonStyle = index => {
    const { deltaX, deltaY } = getFinalDeltaPositions(index);
    return {
      width: 45,
      height: 45,
      zIndex: spring(0),
      top: spring(60 / 2 + deltaX, { stiffness, damping }),
      left: spring(60 / 2 - deltaY, { stiffness, damping }),
    };
  };

  const getFinalDeltaPositions = index => {
    const itemsLenth = menuItems.length;
    const separationAngle =
      itemsLenth === 5
        ? 26
        : itemsLenth === 4
          ? 35
          : itemsLenth === 3
            ? 50
            : 100;
    const fanAngle = (itemsLenth - 1) * separationAngle;
    const baseAngle = (180 - fanAngle) / 2 + 90 + rotation;
    const targetAngle = baseAngle + index * separationAngle;

    return {
      deltaX: flyOutRadius * Math.cos(toRadians(targetAngle)) - 50 / 2,
      deltaY: flyOutRadius * Math.sin(toRadians(targetAngle)) + 50 / 2,
    };
  };

  const getCProps = () => {
    return {
      mainButtonProps: () => ({
        className: `floating-button${toggleClass}`,
        onClick: toggleAction,
      }),

      childButtonProps: (style, onClick) => ({
        className: 'floating-button__child',
        style,
        onClick,
      }),

      childButtonMotionProps: (index, isOpen) => ({
        key: index,
        style: isOpen
          ? getFinalChildButtonStyle(index)
          : getInitalChildButtonStyle(),
      }),
    };
  };

  const renderChildButton = (item, index) => {
    const cp = getCProps();
    return (
      <Motion {...cp.childButtonMotionProps(index, isOpen)}>
        {style => (
          <div
            {...cp.childButtonProps(style)}
            onClick={() => item.onClick(close)}
          >
            <IconButton name={item.icon} fill={item.fill} size={28} tooltipText={item.tooltipText} isPlusButton={isOpen} />
          </div>
        )}
      </Motion>
    );
  };

  const renderButton = () => {
    const cp = getCProps();
    const elements = menuItems;
    return (
      <div
        className={isOpen ? 'floating-button__overlay' : null}
        onClick={event => {
          if (event.target.className === 'floating-button__overlay') close();
        }}
      >
        <div
          className="floating-button__container"
          style={{ right: rightOffset }}
        >
          {elements.map((item, i) => renderChildButton(item, i))}

          <div {...cp.mainButtonProps()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
            >
              <g fill="none" fillRule="evenodd">
                <path
                  fill="#FFF"
                  d="M29.011 29.011V17.143h1.978V29.01h11.868v1.978H30.99v11.868h-1.978V30.99H17.143v-1.978H29.01z"
                />
                <circle cx="30" cy="30" r="30" fill="#FFF" fillOpacity=".8" />
                <circle id="circle-hover" cx="30" cy="30" r="25" fill={PrimaryColor.MAIN_COLOR} />
                <path
                  fill="#FFF"
                  d="M29.176 29.176v-9.89h1.648v9.89h9.89v1.648h-9.89v9.89h-1.648v-9.89h-9.89v-1.648h9.89z"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  };

  return renderButton();
};

MenuButton.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  rightOffset: PropTypes.number,
  tooltipText: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MenuButton.defaultProps = {
  rightOffset: 20,
};

export default MenuButton;
