import { SVGFactory } from 'react';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  /**
   * To define the name of the icon
   */
  name: string;
  /**
   * To define the color of the icon
   */
  fill?: string;
  /**
   * To define the size of the icon
   */
  size?: string | number;
}

enum SVGTypes {
  Rect = 'rect',
  Circle = 'circle',
  Path = 'path',
  Defs = 'defs',
  Group = 'g',
  ClipPath = 'clipPath',
  LinearGradient = 'linearGradient',
}

interface SvgElemt extends React.SVGProps<SVGDefsElement> {
  type: SVGTypes;
  stops?: Array<{ stopColor: string; offset?: string | number }>;
}

type IconDefinition = {
  title: string;
  viewBox?: string;
  svgElements: Array<SvgElemt>;
  fillRule?: FillRule;
  fill?: string;
  clipRule?: string;
};

enum FillRule {
  Inherit = 'inherit',
  Nonzero = 'nonzero',
  Evenodd = 'evenodd',
}

export { IconProps, IconDefinition, FillRule, SVGTypes };
