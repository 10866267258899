import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Flex, Box, Button, Icon } from '@ui-common-files/components';

import { ButtonType, ButtonVariant, Greys } from '@ui-common-files/utils';

import { Solution } from './TroubleshootSection.enum';

import { handleReAssign } from './TroubleshootSection.api';

import useTroubleshootSolutionsListService from './TroubleshootSolutionsList.service';

import { PatientOverviewContext } from '../../PatientOverview';

import styles from './TroubleshootSection.module.scss';

const TroubleshootSolutionsList = ({ patientInfo }) => {
  const { t } = useTranslation();
  const {
    solutionsList,
    setIsDisabled,
    disableButtonsConditions,
    shouldResetAppear,
  } = useTroubleshootSolutionsListService(patientInfo);

  const { openSolutionPrompt } = useContext(PatientOverviewContext);

  return (
    <Box>
      <ol className={styles['troubleshoot__solutions-list']} type="1">
        {solutionsList.map(el => {
          const isFirstElement = el === Solution.Reassign;

          return (
            <li
              key={`troubleshoot-solution_${el}`}
              className={cx(
                styles['troubleshoot__heading-and-title'],
                styles['troubleshoot__heading-and-title__item-spacing']
              )}
            >
              <Box
                className={
                  styles['troubleshoot__heading-and-title__text-spacing']
                }
              >
                {t(`patient.troubleshoot.solution_${el}.title`)}
              </Box>

              <Box
                className={cx(
                  styles['troubleshoot__info'],
                  styles['solution-type'],
                  styles['troubleshoot__info__text-spacing']
                )}
              >
                {t(`patient.troubleshoot.solution_${el}.info`)}
              </Box>

              <Flex otherStyles={{ marginTop: '10px' }}>
                <Box margin="0 16px 0 0">
                  <Button
                    label={t(`patient.troubleshoot.solution_${el}.cta_btn`)}
                    type={ButtonType.BUTTON}
                    variant={
                      isFirstElement
                        ? ButtonVariant.CONTAINED
                        : ButtonVariant.OUTLINED
                    }
                    onClick={() => {
                      isFirstElement
                        ? handleReAssign(
                            patientInfo.firebaseId,
                            patientInfo.id,
                            setIsDisabled
                          )
                        : openSolutionPrompt(el - 1);
                    }}
                    disabled={disableButtonsConditions[el - 1]}
                  />
                </Box>
              </Flex>
            </li>
          );
        })}
      </ol>
      {!shouldResetAppear && (
        <Flex className={styles['icon-text-container']}>
          <Icon name="info" size={32} fill={Greys.LIGHT_GREY_75} />
          <Box otherStyles={{ marginLeft: '16px' }}>
            {t('patient.troubleshoot.solution_3.multi_site_tooltip')}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default TroubleshootSolutionsList;
