import React from 'react';
import PropTypes from 'prop-types';

const HistorialLayout = ({ children }) => {
  return <div className="historial__layout">{children}</div>;
};

HistorialLayout.propTypes = {
  children: PropTypes.any
};

export default HistorialLayout;
