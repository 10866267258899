/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';

import I18n from 'i18next';
import ICDSelect from '../../../caro-ui-commonfiles/components/ICDDropdownSelect/ICDSelect';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import MedicationModalContentForm from './MedicationModalContentForm';
import MedicationModalContentConfirmationView from './MedicationModalContentConfirmationView';
import RecurrenceComponent from '../../../caro-ui-commonfiles/components/molecules/Recurrence/Recurrence';
import {
  dropdownMedicationPhaseTypes,
  dropdownMedicationAdministrationType,
} from '../../Utils/dropdownTypes';
import { updateDosageAndSelectedTime } from './updateDosageAndSelectedTime';
import {
  ComponentTypes,
  Actions,
  SubmissionTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

import serviceConfig from '../../../serviceConfig.json';

const MedicationUpdateModalContent = ({ data }) => {
  const dropdownMedicationAdministrationTypeObj =
    dropdownMedicationAdministrationType();
  const dropdownMedicationPhaseTypesObj = dropdownMedicationPhaseTypes();

  const {
    timeOfDay,
    lifespanUnits,
    rruleFreqToRepeatTypeIndex,
    generateWeekdayArray,
  } = require('../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils');
  const lifespanUnitsObj = lifespanUnits();
  const repeatTypesArray = rruleFreqToRepeatTypeIndex();

  const { user } = useSelector(state => state.session);

  const {
    currentStep,
    setCurrentStep,
    shouldValidateForm,
    dispatchFormValidator,
    shouldSubmitForm,
    dispatchFormSubmittor,
    setFlashMessage,
    closeModal,
    setLandingTab,
  } = useContext(PatientOverviewContext);

  const [icds, setIcds] = useState(data.icdRecords ? data.icdRecords : []);
  const [validateRecurrence, setValidateRecurrence] = useState(false);
  const [values, setValues] = useState({
    name: data.name,
    sober: data.sober,
    medicationAdministrationType: data.medAdminType.id
      ? dropdownMedicationAdministrationTypeObj[data.medAdminType.id - 1]
      : '',
    phaseType: data.medPhaseType
      ? dropdownMedicationPhaseTypesObj[data.medPhaseType - 1]
      : '',
    startDate: moment(data.reminderElement.startsAt).toDate(),
    morning: false,
    dosageInMorning: '',
    midday: false,
    dosageInAfternoon: '',
    evening: false,
    dosageInEvening: '',
    night: false,
    dosageAtNight: '',
    customTimeChecked: false,
    dosageSpecificTime: '',
    customTime: { hour: 9 },
    date: '',
    lifespan: data.reminderElement.allOccurrences[0].lifespan,
    lifespanUnit: lifespanUnitsObj[0],
    repeatType: repeatTypesArray[3],
    repeatInterval: { value: 1, label: 1 },
    occurrenceCount: 1,
    endDate: new Date(),
    weeklyRecurrence: new Array(7).fill(false),
    occurrenceCountCheck: true,
    unit: '',
  });
  const [hasErrors, setHasErrors] = useState({
    name: false,
    sober: false,
    medicationAdministrationType: false,
    phaseType: false,
    dosageInMorning: false,
    dosageInAfternoon: false,
    dosageInEvening: false,
    dosageAtNight: false,
    dosageSpecificTime: false,
    customTime: false,
    date: false,
    unit: false,
  });

  const [recurrenceErrors, setRecurrenceErrors] = useState({
    lifespan: false,
    recurrence: false,
    recurrenceRepeat: false,
    startDate: false,
    endDate: false,
    occurrenceCount: false,
  });

  const [startingArrays, setStartingArrays] = useState({
    startingDays: [],
    startingTimes: [],
  });

  const onValueChange = (value, key) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const resetDosage = (isTimeOfDayChecked, timeOfDayCheckbox, dosage) => {
    if (isTimeOfDayChecked === false) {
      setValues({
        ...values,
        [timeOfDayCheckbox]: false,
        [dosage]: '',
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          serviceConfig.brokerService.editMedication.uri,
          {
            params: {
              reminderId: data.reminderElement.reminderId,
            },
          }
        );

        const responseData = response.data;

        if (responseData) {
          const { rruleRecurrence } = responseData;
          const { endsAt } = responseData.reminder;
          const additionalAttrObj = JSON.parse(
            responseData.additionalAttribute
          );
          const firstNotEmptyDosage = Object.keys(additionalAttrObj).find(
            key => additionalAttrObj[key] !== ''
          );
          const dosageUnit = additionalAttrObj[firstNotEmptyDosage].substr(
            additionalAttrObj[firstNotEmptyDosage].indexOf(' ') + 1
          );

          Object.keys(additionalAttrObj).forEach(key => {
            if (additionalAttrObj[key] !== '')
              additionalAttrObj[key] = additionalAttrObj[key].split(' ')[0];
          });
          setValues({
            ...values,
            morning:
              responseData.timeOfDayRecurrence.indexOf(timeOfDay.MORNING) > -1,
            dosageInMorning: additionalAttrObj.morningDosage,
            midday:
              responseData.timeOfDayRecurrence.indexOf(timeOfDay.MIDDAY) > -1,
            dosageInAfternoon: additionalAttrObj.middayDosage,
            evening:
              responseData.timeOfDayRecurrence.indexOf(timeOfDay.EVENING) > -1,
            dosageInEvening: additionalAttrObj.eveningDosage,
            night:
              responseData.timeOfDayRecurrence.indexOf(timeOfDay.NIGHT) > -1,
            dosageAtNight: additionalAttrObj.nightDosage,
            customTimeChecked: responseData.specificTime > -1,
            dosageSpecificTime: additionalAttrObj.specificTimeDosage,
            customTime:
              responseData.specificTime > -1
                ? { hour: responseData.specificTime }
                : values.customTime,
            unit: dosageUnit,
            repeatType:
              rruleRecurrence.origOptions.freq > -1
                ? repeatTypesArray[rruleRecurrence.origOptions.freq]
                : values.repeatType,
            repeatInterval: rruleRecurrence.origOptions.interval
              ? {
                  value: rruleRecurrence.origOptions.interval,
                  label: rruleRecurrence.origOptions.interval,
                }
              : values.repeatInterval,
            occurrenceCount:
              rruleRecurrence.origOptions.count || values.occurrenceCount,
            endDate: endsAt ? moment(endsAt).toDate() : null,
            weeklyRecurrence: rruleRecurrence.options.byweekday
              ? generateWeekdayArray(rruleRecurrence.options.byweekday)
              : values.weeklyRecurrence,
            occurrenceCountCheck: !rruleRecurrence.origOptions.until,
          });
        }
      } catch (error) {
        closeModal();
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (
      shouldValidateForm.component === ComponentTypes.MEDICATION &&
      shouldValidateForm.action === Actions.Update
    ) {
      setValidateRecurrence(shouldValidateForm.shouldValidate);
    }
  }, [shouldValidateForm]);

  const validateForm = () => {
    const isNameValid = values.name.trim().length > 5;
    const hasDosageInMorning =
      !Number.isNaN(Number(values.dosageInMorning)) &&
      values.dosageInMorning.trim().length > 0;
    const hasDosageInAfternoon =
      !Number.isNaN(Number(values.dosageInAfternoon)) &&
      values.dosageInAfternoon.trim().length > 0;
    const hasDosageInEvening =
      !Number.isNaN(Number(values.dosageInEvening)) &&
      values.dosageInEvening.trim().length > 0;
    const hasDosageAtNight =
      !Number.isNaN(Number(values.dosageAtNight)) &&
      values.dosageAtNight.trim().length > 0;
    const hasDosageSpecificTime =
      !Number.isNaN(Number(values.dosageSpecificTime)) &&
      values.dosageSpecificTime.trim().length > 0;

    const isDosageInMorningValid =
      values.morning === false || (hasDosageInMorning && values.morning);
    const isDosageInAfternoonValid =
      values.midday === false || (hasDosageInAfternoon && values.midday);
    const isDosageInEveningValid =
      values.evening === false || (hasDosageInEvening && values.evening);
    const isDosageAtNightValid =
      values.night === false || (hasDosageAtNight && values.night);
    const isDosageSpecificTimeValid =
      values.customTimeChecked === false ||
      (hasDosageSpecificTime && values.customTimeChecked);
    const isUnitValid = values.unit.trim().length > 0;
    setHasErrors({
      ...hasErrors,
      name: !isNameValid,
      dosageInMorning: !isDosageInMorningValid,
      dosageInAfternoon: !isDosageInAfternoonValid,
      dosageInEvening: !isDosageInEveningValid,
      dosageAtNight: !isDosageAtNightValid,
      dosageSpecificTime: !isDosageSpecificTimeValid,
      unit: !isUnitValid,
    });
    return (
      isNameValid &&
      isDosageInMorningValid &&
      isDosageInAfternoonValid &&
      isDosageInEveningValid &&
      isDosageAtNightValid &&
      isDosageSpecificTimeValid &&
      isUnitValid
    );
  };

  useEffect(() => {
    const checkRecurrenceError = () =>
      Object.keys(recurrenceErrors).find(key => recurrenceErrors[key]);
    if (shouldValidateForm.shouldValidate) {
      const isFormValid = validateForm();
      const isRecurrenceValid = checkRecurrenceError();

      if (validateRecurrence && !isRecurrenceValid && isFormValid) {
        setCurrentStep(currentStep + 1);
        dispatchFormValidator({
          component: '',
          action: '',
          shouldValidate: false,
        });
      }
    }

    if (validateRecurrence) setValidateRecurrence(false);
  }, [recurrenceErrors]);

  useEffect(() => {
    const updateMedication = async () => {
      closeModal();
      let message = '';
      const { endDate } = values;
      updateDosageAndSelectedTime(values);
      setLandingTab(false);
      try {
        const response = await axios.post(
          serviceConfig.brokerService.updateMedication.uri,
          {
            reminderId: data.reminderElement.reminderId,
            countICDs: icds,
            ...values,
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: endDate
              ? moment(endDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
          }
        );
        if (response) {
          if (data.reminderElement.firebaseKey) {
            await axios.get(
              serviceConfig.brokerService.unassignMedication.uri,
              {
                params: {
                  reminderId: data.reminderElement.reminderId,
                },
              }
            );
          }
          let sendResponse = response;
          message = `${I18n.t(response.data.content)}`;
          const isSendOutImmediately =
            shouldSubmitForm.submissionType === SubmissionTypes.ASSIGN_AND_SEND;
          if (isSendOutImmediately) {
            sendResponse = await axios.post(
              serviceConfig.brokerService.sendMedication.uri,
              {
                reminderId: data.reminderElement.reminderId,
              }
            );
          }
          if (sendResponse) {
            setLandingTab(ComponentTypes.MEDICATION);
            setFlashMessage({
              type: sendResponse.data.type,
              content: isSendOutImmediately
                ? `${message} ${I18n.t(sendResponse.data.content)}`
                : message,
            });
          }
        }
      } catch (error) {
        setFlashMessage({
          type: error.response.data.type,
          content: `${I18n.t(error.response.data.content)}${message}`,
        });
      }
    };

    if (
      shouldSubmitForm.component === ComponentTypes.MEDICATION &&
      shouldSubmitForm.action === Actions.Update
    ) {
      updateMedication();
      return function cleanup() {
        dispatchFormSubmittor({
          component: '',
          action: '',
          submissionType: false,
        });
      };
    }
  }, [shouldSubmitForm]);

  const handleFormSubmit = event => {
    event.preventDefault();
    return false;
  };

  const fetchIcdData = term => {
    return axios(serviceConfig.getIcds.uri, {
      params: {
        userId: user.id,
        searchTerm: term,
      },
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  return (
    <>
      <ModalContent>
        <ModalForm
          formId="edit-medication-wizard-form"
          handleFormSubmit={handleFormSubmit}
        >
          {currentStep === 1 && (
            <MedicationModalContentForm
              values={values}
              recurrenceComponent={
                <RecurrenceComponent
                  componentType={ComponentTypes.MEDICATION}
                  onValueChange={onValueChange}
                  values={values}
                  validateRecurrence={validateRecurrence}
                  hasErrors={recurrenceErrors}
                  setHasErrors={setRecurrenceErrors}
                  setStartingArrays={setStartingArrays}
                />
              }
              icdSelectorComponent={
                <ICDSelect
                  maxLengthItem={1}
                  onSelect={selected => {
                    setIcds(selected);
                  }}
                  icdSelected={icds}
                  fetchData={fetchIcdData}
                  placeholder={I18n.t(
                    'newCareplan_view.placeholderICDReference'
                  )}
                />
              }
              onValueChange={onValueChange}
              hasErrors={hasErrors}
              setRecurrenceErrors={setRecurrenceErrors}
              recurrenceErrors={recurrenceErrors}
              isUpdate
              resetDosage={resetDosage}
            />
          )}
          {currentStep === 2 && (
            <MedicationModalContentConfirmationView
              values={values}
              startingArrays={startingArrays}
              icds={icds}
            />
          )}
        </ModalForm>
      </ModalContent>
    </>
  );
};

MedicationUpdateModalContent.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default MedicationUpdateModalContent;
