export interface FilterDropdownContainerProps {
    showFilterSettings: boolean,
    setShowFilterSettings: React.Dispatch<React.SetStateAction<boolean>>,
    advancedFilterList: Array<any>,
    selectAdvancedFilter: Function,
    setDisplayFlashMsg: Function
}

export enum showDropdownTypes {
    Static = 'static',
    Visible = 'visible',
    Hidden = 'hidden'
}