import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Box, NumberInput } from '@ui-common-files/components';

export default function BloodAndBMIRowComponent({
  unit,
  value,
  name,
  onChange,
  hasError,
  isFloatingNumber,
}) {
  const { t } = useTranslation();
  return (
    <Flex alignItems="center" otherStyles={{ marginBottom: 20 }}>
      <Box otherStyles={{ width: 80, marginRight: 10 }}>
        <NumberInput
          value={value}
          name={name}
          min={0}
          max={999}
          step={1}
          placeholder="0"
          isFloatingNumber={isFloatingNumber}
          changeValue={onChange}
          hasError={hasError}
        />
      </Box>
      <Box>{t(unit)}</Box>
    </Flex>
  );
}
