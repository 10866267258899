import { ApiClient, ShowFlashMessage } from '@global/Services';

import { FlashMessageTypes } from '@ui-common-files/types';

import {
  MedicationOverviewRequestDataParams,
  OverviewRequestDataParams,
} from './Overview.type';
import serviceConfig from '../../../../serviceConfig.json';
import { PAGE_SIZE } from './OverviewDataChart/OverviewDataPointsSelection/CaretasksData/CaretasksData.constant';

export const getGraphData = (requestParams: OverviewRequestDataParams) =>
  ApiClient.GET({
    url: serviceConfig.brokerService.getGraphData.uri,
    payload: {
      ...requestParams,
    },
  }).catch(error => {
    ShowFlashMessage({
      type: FlashMessageTypes.Error,
      messageKey: 'flash.unexpectedError',
    });
  });

export const getMedicationData = (
  requestParams: MedicationOverviewRequestDataParams
) =>
  ApiClient.GET({
    url: `${serviceConfig.brokerService.getMedicationOccurences.uri}/${requestParams.patientId}`,
    payload: {
      ...requestParams,
    },
  })

export const getTemplatesForGraph = (
  signal: AbortSignal,
  patientId: string,
  page: number,
  keyword: string,
  size = PAGE_SIZE
) =>
  ApiClient.GET({
    url: `${serviceConfig.brokerService.getTemplatesForGraph.uri}/${patientId}`,
    payload: {
      searchValue: keyword,
      page,
      size,
    },
    signal,
  });
