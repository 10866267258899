import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Icon } from '@ui-common-files/components';

import { ComponentColors } from '@ui-common-files/utils';

import './CustomTooltip.styles.scss';

const CustomTooltip = ({ active, payload }) => {
  const { t } = useTranslation();
  if (!active || !payload[0]?.payload || !payload[0]?.payload.isTrimmed)
    return null;
  return (
    <Flex flexDirection="row" className="overview-medication-chart-custom-tooltip">
      <Icon
        className="overview-custom-tooltip_icon"
        name={'medication'}
        size={30}
        fill={ComponentColors.MEDICATION}
      />
      <Flex flexDirection="column">
        <Flex flexDirection="column" className="overview-custom-tooltip_name">
          {payload?.[0]?.payload.name}
        </Flex>
        <Flex
          flexDirection="row"
          className="overview-custom-tooltip_recurrence"
        >
          <Box className="overview-custom-tooltip_recurrence-title">
            {t('medication.period')}:&nbsp;
          </Box>
          <Box>{payload?.[0]?.payload.recurrence}</Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CustomTooltip;
