/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const TimerTemplate = ({ logOut }) => {
  const [minCounter, setMinCounter] = useState(5);
  const [isInactive, setIsInactive] = useState(false);
  const [timer, setTimer] = useState(null);
  useEffect(() => {
    let interval = null;
    if (isInactive) {
      interval = setInterval(() => {
        setMinCounter(minCounter => minCounter + 1);
      }, 60000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isInactive]);

  const resetTimer = () => {
    if (timer !== null) {
      clearTimeout(timer);
      setTimer(null);
    }
    const newTimer = setTimeout(function () {
      setIsInactive(true);
    }, 300000);
    setTimer(newTimer);
  };
  const onIdle = () => {
    logOut();
  };
  const onAction = () => {
    setMinCounter(5);
    setIsInactive(false);
    resetTimer();
  };

  useEffect(() => {
    resetTimer();
  }, []);
  const idleTimer = useIdleTimer({ onIdle, onAction, timeout: 15000 * 60 });

  return (
    <>
      <div>
        <div />
      </div>
      {isInactive ? (
        <span className="template_counter">
          {`User has been inactive: ${minCounter}m`}
        </span>
      ) : null}
    </>
  );
};

export default TimerTemplate;
