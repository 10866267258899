import { CareplanComponentThreshold } from '../../../../types/Careplan';

export const isNumericThresholdCrossed = (
  numericRangeThresholds: CareplanComponentThreshold[],
  patientAnswer: number
) => {
  const thresholdIsCrossed = numericRangeThresholds.map(threshold => {
    if (patientAnswer !== null && threshold) {
      switch (threshold.comparison) {
        case '>':
          return patientAnswer > threshold.numericValue;
        case '>=':
          return patientAnswer >= threshold.numericValue;
        case '<':
          return patientAnswer < threshold.numericValue;
        case '<=':
          return patientAnswer <= threshold.numericValue;
        case '==':
          return patientAnswer == threshold.numericValue;
        case '!=':
          return patientAnswer != threshold.numericValue;
        default:
          return false;
      }
    }
  });
  return thresholdIsCrossed.includes(true);
};
