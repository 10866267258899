import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import RadioButton from '../../../../caro-ui-commonfiles/components/RadioButton/RadioButton';
import Checkbox from '../../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import Slider from '../../../../caro-ui-commonfiles/components/Slider/Slider';
import BodyChart from '../../../../caro-ui-commonfiles/components/BodyChart/BodyChart';
import { PainLocationChartDropdown } from '../../Careplans/AnswerComponents/AnswerElements';
import componentTypeOrder from '../../Careplans/careplanComponentTypeOrder';
import Tag from '../../../../caro-ui-commonfiles/components/Tags/Tags';
import {
  ActionColors,
  PrimaryColor,
  TagsType,
  CheckBoxRadioVariants,
  slider,
  toLocalizeDate,
} from '@ui-common-files/utils';
import { nonMedical } from '../../../../config';
import axios from 'axios';
import serviceConfig from '../../../../serviceConfig.json';
import ASSET_TYPES from '../../../Utils/Asset/assetTypes';
import {
  Box,
  Label,
  Flex,
  Icon,
  TextInput
} from '@ui-common-files/components';
export const SingleChoice = ({ component }) => {
  const optionsList =
    component.careplanComponentTypeId === componentTypeOrder.INFORMATION
      ? ['Yes', 'No']
      : component.options;
  const { answer } = component;
  let parsedOptionWeights = [];
  const { weight } = component;
  const { optionWeights } = component;
  if (weight != null && optionWeights != 'null') {
    parsedOptionWeights = JSON.parse(optionWeights).weights;
  }
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  return optionsList.map((option, i) => {
    const isChecked = option == answer;
    let showWeight = true;
    let optionWeight = parsedOptionWeights[i];
    if (
      (weight == 0 || weight == null) &&
      (optionWeights == 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && !optionWeight) {
      optionWeight = 0;
    }
    return (
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name={option}
            id={option}
            label={option}
            inputWidth={30}
            checked={isChecked}
            handleOnChange={() => {}}
            valueInput=""
            handleInputOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />

          {showWeight ? (
            <div className="sign-off-sheet-weight__label">
              <div className="threshold-component__name">
                <Tag
                  text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                  fill={PrimaryColor.MAIN_COLOR}
                  type={TagsType.SQUARED}
                />
              </div>
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const Freetext = ({ component }) => {
  const answer = component.answer_text;
  if (answer && answer != 'skipped' && answer != 'überspringen') {
    return (
      <TextInput
        value={answer}
        id="freetextAnswer"
        isDisabled
        name="freetextAnswer"
        placeholder=""
        handleOnChange={() => {}}
      />
    );
  }
  return null;
};

export const MultipleChoice = ({ component }) => {
  const { answer } = component;
  let parsedOptionWeights = [];
  const { weight } = component;
  if (weight != null && component.optionWeights != 'null') {
    parsedOptionWeights = JSON.parse(component.optionWeights).weights;
  }
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  return component.options.map((option, i) => {
    const isChecked = answer.includes(option);
    let showWeight = true;
    let optionWeight = parsedOptionWeights[i];
    if (
      (weight == 0 || weight == null) &&
      (component.optionWeights == 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && (optionWeight == null || optionWeight == 'null')) {
      optionWeight = 0;
    }
    return (
      <Box margin="5px 0">
        <Flex>
          <Checkbox
            id={option}
            label={option}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
            checked={isChecked}
            handleOnChange={() => {}}
          />
          {showWeight ? (
            <div className="sign-off-sheet-weight__label">
              <div className="threshold-component__name">
                <Tag
                  text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                  fill={PrimaryColor.MAIN_COLOR}
                  type={TagsType.SQUARED}
                />
              </div>
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const Sortable = ({ component }) => {
  const optionsList = component.options;
  const { answer } = component;
  let parsedOptionWeights = [];
  const { weight } = component;
  if (weight != null && component.optionWeights != 'null') {
    parsedOptionWeights = JSON.parse(component.optionWeights).weights;
  }
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  const sortedList = answer
    ? answer.map(function (singleElem) {
        return (
          <Box margin="5px 0">
            <Flex>
              <Checkbox
                id={singleElem}
                label={singleElem}
                variant={CheckBoxRadioVariants.NON_EDITABLE}
                checked={false}
                handleOnChange={() => {}}
              />
            </Flex>
          </Box>
        );
      })
    : null;

  const originList = optionsList.map((option, i) => {
    let showWeight = true;
    let optionWeight = parsedOptionWeights[i];
    if (
      (weight == 0 || weight == null) &&
      (component.optionWeights == 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && (optionWeight == null || optionWeight == 'null')) {
      optionWeight = 0;
    }
    return (
      <Box margin="5px 0">
        <Flex>
          <Checkbox
            id={option}
            label={option}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
            checked={false}
            handleOnChange={() => {}}
          />
          {showWeight ? (
            <div className="sign-off-sheet-weight__label">
              <div className="threshold-component__name">
                <Tag
                  text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                  fill={PrimaryColor.MAIN_COLOR}
                  type={TagsType.SQUARED}
                />
              </div>
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
  return (
    <Flex>
      <Box margin="5px 0"> {originList} </Box>
      <Box margin="5px 0 0 30px"> {sortedList} </Box>
    </Flex>
  );
};

export const NumericComponent = ({ component }) => {
  const { careplanComponent } = component;
  const answer = component.answer_numeric;
  const isFloatingNumber = careplanComponent.decimal;
  const { careplanComponentTypeId } = component;
  if (answer) {
    return (
      <>
        {careplanComponentTypeId === componentTypeOrder.NUMERIC_RANGE && (
          <div style={{ height: '120px' }}>
            <Slider
              id="sliderId"
              name="slider"
              value={answer}
              min={careplanComponent.minimumValue}
              max={careplanComponent.maximumValue}
              minLabel={careplanComponent.minLabel}
              maxLabel={careplanComponent.maxLabel}
              onInput={() => {}}
              onChange={() => {}}
              variant={slider.variant.NON_EDITABLE}
            />
          </div>
        )}
        {isFloatingNumber && (
          <div style={{ height: '20px' }}>
            <span className="floatingTextSpan">
              {I18n.t('previewCareplanComponent.floatingPointNumber')}
            </span>
          </div>
        )}
      </>
    );
  }
  return null;
};

export const YesNoComponent = ({ component }) => {
  const answer = component.answer_boolean;

  return (
    <>
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name="Yes"
            id="Yes"
            label={I18n.t('common_labels.yes')}
            inputWidth={30}
            checked={answer == 1}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
        </Flex>
      </Box>
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name="No"
            id="No"
            label={I18n.t('common_labels.no')}
            inputWidth={30}
            checked={answer == 0}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
        </Flex>
      </Box>
    </>
  );
};

export const PainLocationChart = ({ component }) => {
  const body = component.bodyChart;
  const answerX = component.answer_x;
  const answerY = component.answer_y;
  const circlePos = [
    {
      marginTop: (333 * answerY) / 100 - 22.5,
      marginLeft: (121 * answerX) / 100 - 17.5,
    },
  ];

  if (
    body &&
    body.toLowerCase() != 'skipped' &&
    body.toLowerCase() != 'überspringen' &&
    (nonMedical == false || nonMedical == 'false')
  ) {
    return (
      <>
        <BodyChart
          circlePos={circlePos}
          type={component.front ? 'front' : 'back'}
          onClick={() => {}}
        />
        <div style={{ marginTop: '21px' }}>
          <Flex alignItems="center">
            <Box margin="5px 0 0 0" width="100%">
              <PainLocationChartDropdown
                placeholder=""
                isDisabled
                onChange={() => {}}
                option={body}
              />
            </Box>
          </Flex>
        </div>
      </>
    );
  }
  return null;
};

export const GroupDescriptionComponent = ({ component }) => {
  const answer = component.answer_boolean;

  return (
    <>
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name="Yes"
            id="Yes"
            label={I18n.t('common_labels.enter')}
            inputWidth={30}
            checked={answer == 1}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
        </Flex>
      </Box>
      <Box margin="5px 0">
        <Flex>
          <RadioButton
            name="No"
            id="No"
            label={I18n.t('common_labels.skip')}
            inputWidth={30}
            checked={answer == 0}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
        </Flex>
      </Box>
    </>
  );
};

export const UploadFile = ({ component }) => {
  const [fileUrl, setFileUrl] = useState('');
  const [assetTypeId, setAssetTypeId] = useState(null);
  const [fileName, setFileName] = useState('');
  useEffect(() => {
    if (component.answer) {
      axios
        .get(
          `${serviceConfig.brokerService.getAssetById.uri}${component.answer}`
        )
        .then(response => {
          const file = response.data.assetData;
          setFileName(file.name);
          setFileUrl(file.directLink);
          setAssetTypeId(file.assetTypeId);
        })
        .catch(error => {
          setFlashMessage({
            content: I18n.t(error.response.data.content),
            type: error.response.data.type,
          });
        });
    }
  }, []);

  const showFile = () => {
    let fileType;
    if (component.answer) {
      switch (fileUrl) {
        case assetTypeId === ASSET_TYPES.VIDEO && fileUrl:
          fileType = I18n.t('newQuestion_view.careplanComponentMediaVideo');
          break;

        case assetTypeId === ASSET_TYPES.IMAGE && fileUrl:
          fileType = I18n.t('newQuestion_view.careplanComponentMediaImage');
          break;

        case assetTypeId === ASSET_TYPES.DOCUMENT && fileUrl:
          fileType = I18n.t('newQuestion_view.careplanComponentMediaDocument');
          break;

        case assetTypeId === ASSET_TYPES.AUDIO && fileUrl:
          fileType = I18n.t('newQuestion_view.audio');
          break;
      }
      return (
        <>
          <Flex>
            <Box className="file-infos file-infos-title">
              {I18n.t('asset_view.file_type')}:
            </Box>
            <Box className="file-infos">{fileType} </Box>
          </Flex>
          <Flex>
            <Box className="file-infos file-infos-title">
              {I18n.t('asset_view.fileName')}:
            </Box>
            <Box className="file-infos">{fileName} </Box>
          </Flex>
        </>
      );
    }
    return null;
  };
  return showFile();
};

export const Datepicker = ({ component }) => {
  const answer = component.answer_date;
  const inputContent = answer !== null ? toLocalizeDate(answer) : '--/--/----';
  const thresholdCrossed = component.thresholdCrossed;

  return (
    <Flex alignItems="center" otherStyles={{ margin: '0 0 10px 0' }}>
      {thresholdCrossed && (
        <Box margin="0 5px 0 0">
          <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
        </Box>
      )}
      <Box width={140}>
        <TextInput
          value={inputContent}
          id="datepickerAnswer"
          isDisabled
          name="datepickerAnswer"
          placeholder=""
          handleOnChange={() => {}}
        />
      </Box>
    </Flex>
  );
};

export const BMI = ({ component }) => {
  const answerHeight = component.answer_bmi_height || 0;
  const answerWeight = component.answer_bmi_weight || 0;
  const answerBMI =
    answerHeight !== 0 && answerWeight !== 0
      ? (answerWeight / ((answerHeight * answerHeight) / 10000)).toFixed(2)
      : 0;
  const isThreshold =
    (component.thresholds.includes('underweight') &&
      answerBMI > 0 &&
      answerBMI < 18.5) ||
    (component.thresholds.includes('normal') &&
      answerBMI >= 18.5 &&
      answerBMI < 25) ||
    (component.thresholds.includes('overweight') &&
      answerBMI >= 25 &&
      answerBMI < 30) ||
    (component.thresholds.includes('obesity') && answerBMI >= 30);
  return (
    <Box>
      <Flex
        justifyContent="space-between"
        otherStyles={{ maxWidth: '550px' }}
      >
        {isThreshold && (
          <Flex
            otherStyles={{
              alignItems: 'center',
              minHeight: '36px',
              marginRight: '10px',
            }}
          >
            <Flex>
              <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
            </Flex>
          </Flex>
        )}
        <Flex>
          <Label text={`${I18n.t('bmiFields.height')}:`} />
          <Flex justifyContent="end">
            <Box otherStyles={{ width: '85px', marginRight: '5px' }}>
              <TextInput
                value={answerHeight}
                name="height"
                placeholder="0"
                handleOnChange={() => {}}
              />
            </Box>
            <Label text={`${I18n.t('bmiFields.cm')}`} />
          </Flex>
        </Flex>
        <Flex>
          <Label text={`${I18n.t('bmiFields.weight')}:`} />
          <Flex justifyContent="end">
            <Box otherStyles={{ width: '85px', marginRight: '5px' }}>
              <TextInput
                value={answerWeight}
                name="weight"
                placeholder="0"
                handleOnChange={() => {}}
              />
            </Box>
            <Label text={`${I18n.t('bmiFields.kg')}`} />
          </Flex>
        </Flex>
        <Flex>
          <Label text="BMI:" />
          <Flex justifyContent="end">
            <Box otherStyles={{ width: '85px' }}>
              <TextInput
                value={answerBMI}
                name="bmi"
                placeholder="0"
                handleOnChange={() => {}}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};