import React, { useReducer, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import {
  Flex,
  Box,
  Action,
  Icon,
  Tooltip,
  ICDViewer,
  Button,
  ModalContent,
} from '@ui-common-files/components';
import TroubleshootSection from '../TroubleshootSection/TroubleshootSection';

import { ButtonVariant, PrimaryColor } from '@ui-common-files/utils';
import userRoles from '@utils/roles.json';

import { enableOps } from '../../../../../config';

import './PatientDetailsModal.styles.scss';

const AccountInfo = ({ patientInfo }) => {
  const { t } = useTranslation();

  return (
    <Box className="section-container">
      <Box className="section-title-container">
        {t('patientOverview.account')}
      </Box>

      <Box className="content-text">
        {patientInfo.uuid
          ? t('types.carePathwayStatus.active')
          : t('types.carePathwayStatus.inactive')}
      </Box>
    </Box>
  );
};

const PatientDetailsFooter = ({ closeModal }) => {
  const { t } = useTranslation();
  return (
    <Flex
      otherStyles={{
        width: '100%',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        label={t('common_buttons.close')}
        type="button"
        onClick={closeModal}
        variant={ButtonVariant.CONTAINED}
      />
    </Flex>
  );
};

const PatientDetailsModal = ({ patientInfo }) => {
  const { user } = useSelector(state => state.session);
  const { t } = useTranslation();

  const isSiteAdmin = user.role.name === userRoles.SITE_ADMIN;
  const shouldDisplayResetContent = isSiteAdmin && patientInfo.uuid;

  const [isLoading, setIsloading] = useReducer(
    (_state, loading) => loading,
    true
  );

  const renderIcdOrOpsInfo = ({
    processIcd = false,
    processOps = false,
  } = {}) => {
    const icds = patientInfo.icds;
    if (processIcd && icds?.length > 0) {
      return <ICDViewer icds={icds} shouldShowAll />;
    }
    if (processOps) {
      return patientInfo.ops.map(opsCode => {
        if (opsCode) {
          return (
            <Flex key={opsCode.code} flexDirection="row">
              <Tooltip position="right" content={opsCode.description}>
                <Icon name="info" size={24} />
              </Tooltip>
              <span className="icd-code-style">{opsCode.code}</span>
            </Flex>
          );
        }
        return null;
      });
    }
  };

  const renderInstitutions = useCallback(() => {
    if (patientInfo.institutions.length) {
      return patientInfo.institutions.map(institution => (
        <div
          key={institution.id}
          style={{
            marginBottom: 5,
            wordBreak: 'break-word',
            minWidth: '305px',
          }}
        >
          {institution.name}
        </div>
      ));
    }
  }, [patientInfo]);

  useEffect(() => {
    if (patientInfo.id) {
      setIsloading(false);
    }
  }, [patientInfo.id]);

  const renderPatientInfoSection = () => {
    const { gender } = patientInfo;
    let genderDescription = t('patientOverview.notProvided');
    if (gender === 'Male') {
      genderDescription = t(`patients_view.gender_option1`);
    } else if (gender === 'Female') {
      genderDescription = t(`patients_view.gender_option2`);
    }

    return (
      <Flex flexDirection="column">
        {patientInfo.gender && (
          <Box className="section-container">
            <Box className="section-title-container">
              {t('patientOverview.labelGender')}
            </Box>
            <Box className="content-text">{genderDescription}</Box>
          </Box>
        )}
        {patientInfo.email && (
          <Box className="section-container ">
            <Box className="section-title-container">
              {t('patientOverview.labelEmail')}
            </Box>
            <Box className="content-text">
              {patientInfo.email ? (
                <Action href={`mailto:${patientInfo.email}`}>
                  {patientInfo.email}
                </Action>
              ) : (
                t('patientOverview.notProvided')
              )}
            </Box>
          </Box>
        )}
        {patientInfo.mobileNumber && (
          <Box className="section-container ">
            <Box className="section-title-container">
              {t('patients_view.label_mobile_number')}
            </Box>
            <Box className="content-text">
              {patientInfo.mobileNumber
                ? patientInfo.mobileNumber
                : t('patientOverview.notProvided')}
            </Box>
          </Box>
        )}
        {patientInfo?.icds?.length > 0 && (
          <Box className="section-container">
            <Box className="section-title-container">
              {t('common_labels.referenced_icd_code')}
            </Box>
            <Box className="content-text content-text-container">
              {renderIcdOrOpsInfo({ processIcd: true })}
            </Box>
          </Box>
        )}
        {(enableOps == true || enableOps == 'true') && (
          <Box>
            {patientInfo?.ops?.length > 0 && (
              <Box className="section-container">
                <Box className="section-title-container">
                  {t('common_labels.referenced_ops_code')}
                </Box>
                <Box className="content-text content-text-container">
                  {renderIcdOrOpsInfo({ processOps: true })}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Flex>
    );
  };

  const renderAdditionalDetailsSection = () => {
    return (
      <Flex flexDirection="row">
        {patientInfo.institutions.length > 0 && (
          <Flex flexDirection="row" justifyContent="space-between">
            <Box className="section-container">
              <Box className="section-title-container">
                {t('patientOverview.institutions')}
              </Box>
              <Box className="content-text">{renderInstitutions()}</Box>
            </Box>
          </Flex>
        )}
      </Flex>
    );
  };
  const renderRoomInfo = () => {
    let info =
      patientInfo.ward && patientInfo.ward !== 'n/a'
        ? `${t('patients_view.label_Ward')}: ${patientInfo.ward} | `
        : '';
    info =
      patientInfo.room && patientInfo.room !== 'n/a'
        ? `${info}${t('patients_view.label_room')}: ${patientInfo.room} | `
        : info;
    info =
      patientInfo.bed && patientInfo.bed !== 'n/a'
        ? `${info}${t('patients_view.label_Bed')}: ${patientInfo.bed} | `
        : info;
    info =
      patientInfo.DaysInTreatment !== 0 && patientInfo.DaysInTreatment != null
        ? `${info}${t('patients_view.label_DaysInTreatment')}: ${
            patientInfo.DaysInTreatment
          }`
        : info;

    return info.replace(/\| $/, '');
  };

  return (
    <ModalContent>
      {isLoading ? (
        <Box className="pdf-loader-container">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Box>
      ) : (
        <Box className="patient-details" otherStyles={{ padding: 16 }}>
          <Flex flexDirection="row" justifyContent="space-between">
            <Box className="patient-details-container">
              <span className="patient-info">{renderRoomInfo()}</span>
              {renderPatientInfoSection()}
            </Box>
            <Box className="patient-details-container">
              {renderAdditionalDetailsSection()}
            </Box>
          </Flex>

          <Box className="patient-details-container">
            <AccountInfo patientInfo={patientInfo} />
          </Box>

          {shouldDisplayResetContent && (
            <Box otherStyles={{ marginTop: 10 }}>
              <TroubleshootSection patientInfo={patientInfo} />
            </Box>
          )}
        </Box>
      )}
    </ModalContent>
  );
};

export { PatientDetailsModal, PatientDetailsFooter };
