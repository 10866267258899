export const getBloodPressureScoreFromObject = (data, bloodPressureValue) => {
    const { isScoreEnabled, optionJson, optionWeights } = data;

    if (!isScoreEnabled) {
        return null;
    }
    const optionWeight = JSON.parse(optionWeights);
    const index = optionJson.options.indexOf(bloodPressureValue);

    return index === -1 ? null : parseInt(optionWeight.weights[index]);
};