/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/main.scss';
import Icon from '../Icon/Icon';
import Flex from '../Flex/Flex';
import RecordPage from '../DropdownMenu/RecordDropdown';
import { Greys } from '../../utils/colors';

const Pagination = ({
  page,
  pages,
  onPageChange,
  pageSize,
  previousText,
  nextText,
  pageText,
  ofText,
  rowsText,
  getProps,
  rowsSelectorText,
}) => {
  const { pageSizeOptions, active } = getProps();

  const changePage = newPage => {
    const activePage = page + 1;
    if (newPage !== activePage) {
      onPageChange(newPage - 1);
    }
  };

  const getVisiblePages = () => {
    if (pages < 7) {
      return [1, 2, 3, 4, 5, 6].filter(newPages => newPages <= pages);
    }
    if (page % 5 >= 0 && page > 4 && page + 2 < pages) {
      return [1, page - 1, page, page + 1, pages];
    }
    if (page % 5 >= 0 && page > 4 && page + 2 >= pages) {
      return [1, pages - 3, pages - 2, pages - 1, pages];
    }
    return [1, 2, 3, 4, 5, pages];
  };

  const renderPaginator = () => {
    const visible = getVisiblePages();
    const activePage = page + 1;
    const start = getProps().totalrecord === 0 ? 0 : page * pageSize + 1;
    const limit = page * pageSize + pageSize;

    return (
      <div className="data-table__pagination--wrapper">
        <div className="data-table__pagination">
          <div>
            <button
              className="data-table__page-button"
              onClick={() => {
                if (activePage !== 1) changePage(activePage - 1);
              }}
              disabled={activePage === 1}
              type="button"
            >
              <span className="data-table__navigation">
                <Icon name="arrowLeft" size={20} />
                {previousText}
              </span>
            </button>
          </div>
          <div className="data-table__visible-pages-wrapper">
            {visible.map((newPage, index, array) => {
              return (
                <button
                  key={`page__${index}`}
                  className={
                    activePage === newPage
                      ? 'data-table__page-button data-table__page-button--active'
                      : 'data-table__page-button'
                  }
                  onClick={() => changePage(newPage)}
                  type="button"
                >
                  {array[index - 1] + 1 < newPage ? `...${newPage}` : newPage}
                </button>
              );
            })}
          </div>
          <div>
            <button
              className="data-table__page-button"
              onClick={() => {
                if (activePage !== pages) changePage(activePage + 1);
              }}
              disabled={activePage === pages}
              type="button"
            >
              <span className="data-table__navigation">
                {nextText}
                <Icon name="arrowRight" size={20} />
              </span>
            </button>
          </div>
        </div>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {pageSize && pageSizeOptions.length > 0 ? (
            <RecordPage
              iconColor={Greys.DARK_GREY}
              text={rowsSelectorText}
              onClick={(e, item) =>
                getProps().setTableSize(parseInt(item.value))
              }
              items={pageSizeOptions}
              active={active}
            />
          ) : null}
          <span className="showing-enries__span">
            {`${pageText} ${start} - ${
              limit > getProps().totalrecord ? getProps().totalrecord : limit
            } ${ofText} ${getProps().totalrecord} ${rowsText}`}
          </span>
        </Flex>
      </div>
    );
  };
  return renderPaginator();
};

Pagination.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  onPageChange: PropTypes.func,
  pageSize: PropTypes.number,
  previousText: PropTypes.string,
  nextText: PropTypes.string,
  pageText: PropTypes.string,
  ofText: PropTypes.string,
  rowsText: PropTypes.string,
  getProps: PropTypes.func,
};

export default Pagination;
