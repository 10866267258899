import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import inputField from '../../utils/inputField';
import { Greys, ActionColors } from '../../utils/colors';

import '../../styles/main.scss';

const TextArea = ({
  id,
  name,
  placeholder,
  isDisabled,
  hasError,
  hasSuccess,
  validationMessage,
  variant,
  isLeftIcon,
  iconName,
  isLinkWithIcon,
  value,
  handleOnChange,
  maxChars,
  ...rest
}) => {
  const charsCount = value.length;

  const handleKeyPress = e => {
    if (variant === inputField.variant.CHAR_COUNT && charsCount === maxChars) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  /* Classes
   ========================================================================== */

  const inputWrapperClasses = classNames({
    input__textarea__wrapper: true,
    disabled: isDisabled,
    'input--error':
      variant === inputField.variant.DEFAULT &&
      (hasError && !hasSuccess && !isDisabled),
    'input--success':
      variant === inputField.variant.DEFAULT &&
      (hasSuccess && !hasError && !isDisabled),
    'inline-input': variant === inputField.variant.INLINE_INPUT,
  });

  const inputClasses = classNames({
    textarea__input: true,
  });

  const iconClasses = classNames({
    input__text__icon: true,
    'left-icon': isLeftIcon,
    'right-icon': !isLeftIcon
  });

  /* Validation
   ========================================================================== */

  let validationHelperText;
  let validationIcon;

  if ((hasSuccess && !hasError) || (hasError && !hasSuccess)) {
    validationHelperText = <span>{validationMessage}</span>;
  }

  if (hasSuccess && !hasError) {
    validationIcon = (
      <Icon
        name="success"
        fill={ActionColors.SUCCESS}
        className="input__text__icon right-icon"
      />
    );
  }

  if (hasError && !hasSuccess) {
    validationIcon = (
      <Icon
        name="error"
        fill={ActionColors.ERROR}
        className="input__text__icon right-icon"
      />
    );
  }

  /* Icon (left) + Input text + Link (right)
   ========================================================================== */

  const linkWithIcon =
    variant === inputField.variant.WITH_ICON && (isLinkWithIcon && isLeftIcon);

  return (
    <div className="input__wrapper">
      <div className={inputWrapperClasses}>
        {variant === inputField.variant.WITH_ICON && (
          <Icon name={iconName} fill={Greys.LIGHT_GREY} className={iconClasses} />
        )}

        {validationIcon}

        <textarea
          id={id}
          name={name}
          value={value}
          onChange={handleOnChange}
          placeholder={placeholder}
          className={inputClasses}
          disabled={isDisabled}
          onKeyPress={e => {
            if (value.length === maxChars) {
              e.preventDefault();
            }
          }}
          {...rest}
        />

        {linkWithIcon && (
          <a href="" target="_blank" className="input__text__link">
            {rightHelperText}
          </a>
        )}
        {variant === inputField.variant.VALUE_RIGHT && (
          <span className="helperText">{rightHelperText}</span>
        )}
        {variant === inputField.variant.CHAR_COUNT && (
          <span className="maxchars__span">
            {charsCount}/{maxChars}
          </span>
        )}
      </div>
      {variant === inputField.variant.DEFAULT && validationHelperText}
    </div>
  );
};


TextArea.defaultProps = {
  type: inputField.type.TEXT,
  isDisabled: false,
  hasError: false,
  hasSuccess: false,
  variant: inputField.variant.DEFAULT
};

export default TextArea;
