import React from 'react';
import I18n from 'i18next';
import { ActionColors } from '../../../caro-ui-commonfiles/utils/colors';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import ThresholdCardComponent from './AssignComponents/ThresholdCardComponent';
import {
  getJumpData,
  getScoringCategoryTranslation,
} from './CareplanHelperUtility';

const alphapet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const renderAdditionalComponents = (
  careplanScoringScheme,
  dataThreshold,
  careplanLogicJumpData,
  isEvaluating: boolean,
  setDataThreshold = null,
  thresholdView = null,
  emptyThresholds = null
) => {
  return (
    <>
      <div className="additional_component_title">
        {I18n.t('newCareplan_view.additional_component_based_on_score')}
      </div>
      {careplanScoringScheme.map(item => {
        if (item.min === -1 && item.max === -1) return null;

        return (
          <>
            <Flex
              alignItems="center"
              otherStyles={{
                backgroundColor: item.bgColor,
                width: 259,
                margin: '13px 0',
              }}
            >
              <Flex alignItems="center" otherStyles={{ padding: '6px 10px' }}>
                <Icon name={item.icon} size={24} fill={item.color} />
                <Flex
                  otherStyles={{
                    margin: '0 10px',
                    fontFamily: 'Open sans',
                    fontSize: 16,
                    fontWeight: 600,
                    color: item.color,
                  }}
                >
                  {getScoringCategoryTranslation(item.id)}:{' '}
                  {I18n.t('newQuestion_view.score')} {item.min}{' '}
                  {I18n.t('newCareplan_view.to')} {item.max}
                </Flex>
              </Flex>
            </Flex>
            {renderComponents(
              dataThreshold.filter(component =>
                item.componentIds.includes(component[0].id)
              ),
              careplanLogicJumpData,
              isEvaluating,
              false,
              setDataThreshold,
              thresholdView,
              emptyThresholds
            )}
          </>
        );
      })}
    </>
  );
};

function isParentConponent(
  currentComponent,
  currentComponentIndex,
  componentsLength,
  nextComponent
) {
  const currentComponentDepth =
    currentComponent.careplanComponentsTemplateMapper
      ? currentComponent.careplanComponentsTemplateMapper.depth
      : currentComponent.careplanCareplanComponentMapper.depth;
  const nextComponentDepth =
    nextComponent === null
      ? null
      : nextComponent.careplanComponentsTemplateMapper
      ? nextComponent?.careplanComponentsTemplateMapper.depth
      : nextComponent?.careplanCareplanComponentMapper.depth;

  return (
    currentComponentDepth === 0 &&
    currentComponentIndex + 1 < componentsLength &&
    nextComponentDepth === 1
  );
}

function isLastChildConponent(
  currentComponent,
  currentComponentIndex,
  componentsLength,
  nextComponent
) {
  const currentComponentDepth =
    currentComponent.careplanComponentsTemplateMapper
      ? currentComponent.careplanComponentsTemplateMapper.depth
      : currentComponent.careplanCareplanComponentMapper.depth;
  const nextComponentDepth =
    nextComponent === null
      ? null
      : nextComponent.careplanComponentsTemplateMapper
      ? nextComponent.careplanComponentsTemplateMapper.depth
      : nextComponent.careplanCareplanComponentMapper.depth;

  return (
    currentComponentDepth === 1 &&
    (currentComponentIndex + 1 === componentsLength ||
      (currentComponentIndex + 1 < componentsLength &&
        nextComponentDepth === 0))
  );
}

function isChildComponent(currentComponent) {
  const currentComponentDepth =
    currentComponent.careplanComponentsTemplateMapper
      ? currentComponent.careplanComponentsTemplateMapper.depth
      : currentComponent.careplanCareplanComponentMapper.depth;

  return currentComponentDepth === 1;
}

function renderComponents(
  components,
  careplanLogicJumpData,
  isEvaluating: boolean,
  isMainComponent: boolean,
  setDataThreshold = null,
  thresholdView = null,
  emptyThresholds = null
) {
  if (!isMainComponent && components.length === 0)
    return (
      <Flex
        otherStyles={{
          alignItems: 'center',
          marginLeft: 20,
        }}
      >
        <Icon name="info" size={24} fill={ActionColors.INFO} />
        {I18n.t('newCareplan_view.noAdditionalComponent')}
      </Flex>
    );

  return components.map((threshold, index) => {
    let variant = '';
    if (isMainComponent) {
      if (
        isParentConponent(
          threshold[0],
          index,
          components.length,
          index + 1 < components.length ? components[index + 1][0] : null
        )
      ) {
        variant = ' is-parent';
      } else if (
        isLastChildConponent(
          threshold[0],
          index,
          components.length,
          index + 1 < components.length ? components[index + 1][0] : null
        )
      ) {
        variant = ' is-last-child';
      } else if (isChildComponent(threshold[0])) {
        variant = ' is-child';
      }
    }

    let LogicJumpData = { isJumpSet: false, condition: [] };
    if (
      careplanLogicJumpData &&
      Object.keys(careplanLogicJumpData).length > 0
    ) {
      LogicJumpData = getJumpData(threshold[0], careplanLogicJumpData);
    }
    const logicJumpDataForTags = {
      isJumpSet: LogicJumpData.isJumpSet,
      collection: { key: threshold[0].careplanComponentTypeId },
      logicJumpCollection: LogicJumpData.condition,
    };

    return (
      <ThresholdCardComponent
        threshold={threshold}
        edit={thresholdView ? thresholdView.individual : false}
        onChange={value => {
          if (thresholdView && thresholdView.individual && setDataThreshold) {
            setDataThreshold(prevData =>
              prevData.map(prev => {
                return prev[0].careplanComponentsTemplateMapper.id === value[0].careplanComponentsTemplateMapper.id ? value : prev;
              })
            );
          }
        }}
        emptyThresholds={emptyThresholds}
        variant={isMainComponent ? variant : ''}
        componentIndex={isMainComponent ? index + 1 : alphapet[index]}
        LogicJumpDataforTags={logicJumpDataForTags}
        isEvaluating={isEvaluating}
        isAdditional={!isMainComponent}
      />
    );
  });
}

export { renderAdditionalComponents, renderComponents, alphapet };
