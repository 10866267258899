import React, { useContext, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  AuxiliaryColors,
  PrimaryColor,
  WearablesDataTypes,
  lifespanEnum,
} from '@ui-common-files/utils';
import {
  ComponentTranslationKeys,
  OverviewWearableUnitTranslations,
} from '@ui-common-files/utils/componentTypes';
import { wearablesDataTypesId } from '@ui-common-files/global';

import { RawDataTypes } from '@type/WearableActivityData';

import { useOverviewStore } from '../../../Overview.store';
import { PatientOverviewContext } from '../../../../../PatientOverview/PatientOverview';
import DataPointType from '../OverviewDataPointsSelection.enum';

const useHealthDataService = (activityKey: string) => {
  const { devicesQuery, setDevicesQuery } = useContext(PatientOverviewContext);
  const { overviewActivityStatus, overviewDataPoints, setOverviewDataPoints } =
    useOverviewStore(
      useShallow(state => ({
        overviewActivityStatus: state.overviewActivityStatus,
        overviewDataPoints: state.overviewDataPoints,
        setOverviewDataPoints: state.setOverviewDataPoints,
      }))
    );

  const healthDataActivities = devicesQuery.activities || [];
  const activeComp = healthDataActivities?.find(
    item => item.key === activityKey
  );
  const borderColor = activeComp?.color || '';
  const currentFilter = devicesQuery.filter;
  const availableColors = [PrimaryColor.MAIN_COLOR, AuxiliaryColors.GREEN];
  const [invalidSelectionWarning, setInvalidSelectionWarning] = useState(null);

  const onSelect = activities => {
    setDevicesQuery({
      ...devicesQuery,
      activities,
    });
  };

  const isHealthDataButtonDisabled = key => {
    const isSelected = overviewDataPoints?.selectedDataPoints?.some(
      dataPoint => dataPoint.healthDataKey === key
    );
    const isDataTypeDisabled = overviewActivityStatus?.activities?.some(
      activity => activity.key === key && !activity.value
    );
    const isActiveAndKeyNotIncluded =
      healthDataActivities?.length === 2 &&
      !healthDataActivities?.some(item => item.key === key);
    const isRawDataTypeActive = healthDataActivities?.some(item =>
      RawDataTypes.includes(item)
    );

    return (
      isDataTypeDisabled ||
      isActiveAndKeyNotIncluded ||
      isRawDataTypeActive ||
      (overviewDataPoints?.selectedDataPoints?.length === 2 && !isSelected)
    );
  };

  const invalidSelection = key => {
    if (currentFilter === lifespanEnum.DAY) {
      const checkStepsValidSelection =
        healthDataActivities?.some(
          item => item.key === WearablesDataTypes.STEPS
        ) && key !== WearablesDataTypes.STEPS;
      const checkBPMValidSelection =
        healthDataActivities?.some(
          item => item.key === WearablesDataTypes.HEART_RATE
        ) && key !== WearablesDataTypes.HEART_RATE;

      if (checkStepsValidSelection || checkBPMValidSelection) {
        setInvalidSelectionWarning(
          'patients_view.wearableInvalidDailySelection'
        );
        return true;
      }
    }
    return false;
  };

  const getDataPointUnit = key =>
    OverviewWearableUnitTranslations[wearablesDataTypesId[key]];
  const getDataPointName = key =>
    ComponentTranslationKeys[WearablesDataTypes[key]];

  const checkActivity = activity => {
    let clonedActivity = [...healthDataActivities];
    let currentColor;
    currentColor =
      !overviewDataPoints?.selectedDataPoints ||
      overviewDataPoints?.selectedDataPoints?.length < 1
        ? availableColors[0]
        : availableColors[1];
    clonedActivity.push({
      key: activity,
      color: currentColor,
    });
    onSelect(clonedActivity);
    const selectedItem = clonedActivity[1] || clonedActivity[0];
    let selectedActivity = {
      selectedDataPoints: [
        {
          dataPointType: DataPointType.HEALTH_DATA,
          healthDataKey: selectedItem.key,
          color: currentColor,
          dataPointName: getDataPointName(selectedItem.key),
          dataPointUnit: getDataPointUnit(selectedItem.key),
          dataPointDescription:
            'patients_view.overview.modal_select_data.health_container.title',
        },
      ],
    };
    if (overviewDataPoints === undefined) {
      setOverviewDataPoints(selectedActivity);
    } else {
      if (overviewDataPoints?.selectedDataPoints?.length < 2) {
        setOverviewDataPoints({
          ...overviewDataPoints,
          selectedDataPoints: [
            ...(overviewDataPoints.selectedDataPoints || []),
            ...selectedActivity.selectedDataPoints,
          ],
        });
      }
    }
  };

  const uncheckActivity = (activity, indexOfActive) => {
    const clonedActivity = [...healthDataActivities];
    const clonedColors = [...availableColors];

    clonedColors.push(clonedActivity[indexOfActive].color);
    clonedActivity.splice(indexOfActive, 1);
    onSelect(clonedActivity);
    setOverviewDataPoints({
      ...overviewDataPoints,
      selectedDataPoints: overviewDataPoints.selectedDataPoints?.filter(
        dataPoint => dataPoint.healthDataKey !== activity
      ),
    });
  };

  const activityButtonClickHandler = (e, key) => {
    e.preventDefault();
    if (!isHealthDataButtonDisabled(key)) {
      const indexOfActive = healthDataActivities?.findIndex(
        item => item.key === key
      );
      if (indexOfActive > -1) {
        uncheckActivity(key, indexOfActive);
      } else if (healthDataActivities?.length < 2 && !invalidSelection(key)) {
        checkActivity(key);
      }
    }
  };

  const preselectButtonColors = () => {
    healthDataActivities?.forEach(item => {
      const colorIndex = availableColors.findIndex(
        color => item.color === color
      );
      if (colorIndex > -1) {
        const clonedColors = [...availableColors];
        clonedColors.splice(colorIndex, 1);
      }
    });
  };

  useEffect(() => {
    if (healthDataActivities?.length > 0) {
      preselectButtonColors();
    }
  }, []);

  return {
    isHealthDataButtonDisabled,
    invalidSelectionWarning,
    activityButtonClickHandler,
    borderColor,
  };
};

export default useHealthDataService;
