import { arrayMoveImmutable } from 'array-move';
import { validateOptions } from '../../Views/Careplans/CareplanHelperUtility';
import { getSumOfArray } from '../../Global/Arrays';
import { MaxCharLimits } from '@ui-common-files/utils';

const addSingleChoices = (
  item,
  index,
  componentCollection,
  setComponentCollection,
  setBlurr
) => {
  const collection = item.choiceCollection.choices;
  const { optionWeights } = item.choiceCollection;
  setBlurr(true);
  if (item.choiceCollection.addChoice) {
    if (collection.length < MaxCharLimits.carePlan.componentOptionsLimit) {
      const obj = {
        name: item.choiceCollection.addChoice.trim(),
        isChecked: false,
      };
      collection.push(obj);
      optionWeights.weights.push(0);

      const sum =
        optionWeights.weights.length > 0
          ? getSumOfArray(optionWeights.weights)
          : 0;
      optionWeights.total = sum;
    }
  }
  const itemTemp = { ...item };
  itemTemp.choiceCollection.addChoice = '';
  const collectionTemp = [...componentCollection];
  collectionTemp[index] = itemTemp;
  setComponentCollection(collectionTemp);
};

const addMultipleChoices = (
  item,
  componentCollection,
  setComponentCollection,
  setBlurr
) => {
  const collection = item.choiceCollection.choices;
  const { optionWeights } = item.choiceCollection;
  setBlurr(true);
  if (item.choiceCollection.addChoice) {
    if (collection.length < MaxCharLimits.carePlan.componentOptionsLimit) {
      const obj = {
        name: item.choiceCollection.addChoice.trim(),
        isChecked: false,
      };
      collection.push(obj);
      optionWeights.weights.push(0);

      const sum =
        optionWeights.weights.length > 0
          ? getSumOfArray(optionWeights.weights)
          : 0;
      optionWeights.total = sum;
    }
  }
  item.choiceCollection.addChoice = '';
  setComponentCollection([...componentCollection]);
};

const removeSortables = (
  item,
  index,
  componentCollection,
  setComponentCollection,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring
) => {
  item.choiceCollection.choices.splice(index, 1);
  item.logicJumpCollection.splice(index, 1);
  item.choiceCollection.optionWeights.weights.splice(index, 1);
  let total = 0;
  for (
    let index = 0;
    index < item.choiceCollection.optionWeights.weights.length;
    index++
  ) {
    total += parseInt(item.choiceCollection.optionWeights.weights[index], 10);
  }
  item.choiceCollection.optionWeights.total = total;
  const temp = [...componentCollection];
  setComponentCollection(temp);

  if (
    item.choiceCollection.optionWeights.total > 0 &&
    item.choiceCollection.optionWeights.weights[index] > 0
  ) {
    showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent = true;
    setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
  }

  const validationTemp = validationErrorTemplate;
  hasValidationErrorTemplate({ ...validationTemp });
  validateOptions(
    item,
    item.choiceCollection.choices,
    validationErrorTemplate,
    hasValidationErrorTemplate
  );
};

const onChangeOptionName = (
  event,
  item,
  i,
  componentCollection,
  setComponentCollection,
  componentIndex = null
) => {
  const updatedOption = event.target.value;
  const matchedPosition =
    componentIndex !== null
      ? componentIndex
      : componentCollection.findIndex(elem => {
          return elem.id === item.id;
        });
  const collectionTemp = [...componentCollection];
  collectionTemp[matchedPosition].choiceCollection.choices[i] = {
    name: updatedOption,
    isChecked:
      collectionTemp[matchedPosition].choiceCollection.choices[i].isChecked,
  };
  if (
    collectionTemp[matchedPosition].choiceCollection.selectedChoice.index === i
  ) {
    collectionTemp[
      matchedPosition
    ].choiceCollection.selectedChoice.name = updatedOption;
  }
  setComponentCollection(collectionTemp);
};

const onSortEnd = (
  oldIndex,
  newIndex,
  setLogicJumpValueForComponent,
  component
) => {
  const updatedComponent = { ...component };
  let { choices } = updatedComponent.choiceCollection;
  let { logicJumpCollection } = updatedComponent;
  choices = arrayMoveImmutable(choices, oldIndex, newIndex);
  if (logicJumpCollection && !logicJumpCollection[newIndex]) {
    logicJumpCollection[newIndex] = logicJumpCollection[oldIndex];
    logicJumpCollection[oldIndex] = undefined;
  } else if (logicJumpCollection) {
    logicJumpCollection = arrayMoveImmutable(
      logicJumpCollection,
      oldIndex,
      newIndex
    );
  }
  updatedComponent.logicJumpCollection = logicJumpCollection;
  updatedComponent.choiceCollection.choices = choices;
  setLogicJumpValueForComponent(null, updatedComponent);
};

export {
  addSingleChoices,
  addMultipleChoices,
  removeSortables,
  onChangeOptionName,
  onSortEnd,
};
