enum ComponentTranslationKeys {
  PATIENT = 'types.componentType.patients',
  REMINDER = 'types.componentType.reminders',
  MEDICATION = 'types.componentType.medications',
  TASK = 'types.componentType.tasks',
  QUESTIONNAIRE = 'types.componentType.questionnaires',
  QUESTIONCATEGORY = 'types.componentType.questionCategory',
  QUESTIONTEMPLATE = 'types.componentType.questionnaireTemplates',
  TASKTEMPLATE = 'types.componentType.taskTemplates',
  QUESTION = 'types.componentType.questions',
  QR = 'types.componentType.qr',
  USER = 'types.componentType.users',
  USERROLE = 'types.componentType.userRoles',
  ROLE = 'types.componentType.roles',
  BEDPLANNING = 'types.componentType.bedPlanning',
  APPOINTMENT = 'types.componentType.appointments',
  CUSTOM_FILTER = 'types.componentType.customFilters',
  TASKTEMPLATECATEGORY = 'types.componentType.taskTemplateCategory',
  VIDEOLOG = 'types.componentType.videolog',
  SITE = 'types.componentType.sites',
  TELEMEDICINE = 'types.componentType.telemedicine',
  CAREPLAN = 'types.componentType.careplans',
  CAREPLAN_COMPONENT = 'types.componentType.careplanComponents',
  CAREPLAN_CATEGORY = 'types.componentType.careplanCategories',
  CAREPLAN_TEMPLATE = 'types.componentType.careplanTemplates',
  CAREPATHWAY_TEMPLATE = 'types.componentType.carepathwayTemplates',
  CAREPATHWAY = 'types.componentType.carepathways',
  DEVICE = 'types.componentType.devices',
  OVERVIEW = 'types.componentType.overview',
  STEPS = 'types.wearableDataType.steps',
  CALORIES = 'types.wearableDataType.calories',
  WEIGHT = 'types.wearableDataType.weight',
  HEIGHT = 'types.wearableDataType.height',
  HEART_RATE = 'types.wearableDataType.heartRate',
  SLEEP = 'types.wearableDataType.sleep',
  DISTANCE = 'types.wearableDataType.distance',
  BLOOD_PREASURE = 'types.wearableDataType.bloodPreasure',
  GOOGLE = 'types.wearableDataSource.google',
  APPLE = 'types.wearableDataSource.apple',
  WITHINGS = 'types.wearableDataSource.withings',
  TODOS = 'types.componentType.todos',
  SATELLITE = 'types.componentType.satellites',
  ASSETS = 'nav.assets',
  CALENDAR = 'patientOverview.calendar',
  ONCOBOX_IMPORTED_DATA = 'nav.importedOncoboxData',
  STANDARD_DATA_MAPPING = 'types.componentType.standardDataMapping'
}

enum WearableUnitTranslations {
  STEPS = 'types.wearableGraphUnits.steps',
  CALORIES = 'types.wearableGraphUnits.calories',
  WEIGHT = 'types.wearableGraphUnits.weight',
  HEIGHT = 'types.wearableGraphUnits.height',
  HEART_RATE = 'types.wearableGraphUnits.heartRate',
  SLEEP = 'types.wearableGraphUnits.sleep',
  DISTANCE = 'types.wearableGraphUnits.distance',
  BLOOD_PREASURE = 'types.wearableDataType.bloodPreasure',
}

enum OverviewWearableUnitTranslations {
  CALORIES = 'types.overviewWearableUnits.calories',
  WEIGHT = 'types.overviewWearableUnits.weight',
  HEIGHT = 'types.overviewWearableUnits.height',
  SLEEP = 'types.overviewWearableUnits.sleep',
  DISTANCE = 'types.overviewWearableUnits.distance',
  BLOOD_PREASURE = 'types.overviewWearableUnits.bloodPreasure',
}

const enum ComponentTypes {
  MEDICATION = 'medication',
  TASK = 'task',
  APPOINTMENT = 'appointment',
  CAREPLAN = 'careplan',
  REMINDER = 'reminder',
  CAREPLANTEMPLATE = 'careplanTemplate',
  ASSESSMENT = 'assessment',
  CAREPATHWAYS = 'carepathway',
  TODOS = 'todos',
  MEDICATIONPLAN = 'medicationPlan',
  CALENDAR = 'calendar',
  ASSETS = 'assets',
  ONCOBOX_IMPORTED_DATA = 'oncoboxImportedData',
  OVERVIEW = 'overview',
  DASHBOARD = 'dashboard',
}

const enum SubmissionTypes {
  ASSIGN_AND_SEND = 'assignAndSend',
  ASSIGN_ONLY = 'assignOnly',
  DEACTIVATE = 'DEACTIVATE',
  CONFIRM = 'CONFIRM',
  ACKNOWLEDGE_EXPORT = 'acknowledgeExport',
  PROCEED = 'proceed',
}

const enum Actions {
  Update = 'update',
  Create = 'create',
  Show = 'show',
}

enum WearablesDataTypes {
  WEIGHT = 1,
  HEIGHT = 2,
  STEPS = 3,
  CALORIES = 4,
  HEART_RATE = 5,
  SLEEP = 6,
  DISTANCE = 7,
  BLOOD_PREASURE = 8,
}

enum ChartDataKeys {
  LINE1 = 'line1',
  LINE2 = 'line2',
  LINE3 = 'line3',
}

enum WearablesSourceTypes {
  GOOGLE = 1,
  APPLE = 2,
  WITHINGS = 3,
  CARETASK = 4,
}

const enum ActionTranslationKeys {
  READ = 'actions.read',
  DELETE = 'actions.delete',
  UPDATE = 'actions.update',
  ASSIGN = 'actions.assign',
  CREATE = 'actions.create',
  SEND = 'actions.send',
  REVOKE = 'actions.revoke',
  SHOW = 'actions.show',
  BULKASSIGN = 'actions.bulkAssign',
  BULKASSIGNSEND = 'actions.bulkAssign&Send',
  GENERATEQR = 'actions.qrGeneration',
  UNASSIGN = 'actions.revoke',
  RELEASE = 'actions.release',
  SHARE = 'actions.share',
  ANSWERED_BY = 'actions.answered',
  SKIPPED_BY = 'actions.skipped',
  EXPORT = 'actions.export',
  IMPORT = 'actions.import',
}

enum EventTypes {
  CAREPLAN,
  MEDICATION,
  APPOINTMENT,
}

export {
  ComponentTranslationKeys,
  ComponentTypes,
  Actions,
  SubmissionTypes,
  ActionTranslationKeys,
  EventTypes,
  WearablesDataTypes,
  WearablesSourceTypes,
  WearableUnitTranslations,
  OverviewWearableUnitTranslations,
  ChartDataKeys,
};
