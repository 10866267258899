/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext } from 'react';
import I18n from 'i18next';

import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';

import { Greys } from '../../../caro-ui-commonfiles/utils/colors';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

const PatientContent = ({ careplanName }) => {
  const { patientInfo } = useContext(PatientOverviewContext);
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      otherStyles={{
        padding: '10px 0',
        backgroundColor: Greys.LIGHT_GREY_10,
        fontSize: 14,
        color: Greys.LIGHT_GREY,
      }}
    >
      <Flex
        otherStyles={{
          width: 'max-content',
          padding: '0 15px',
          borderRight: '1px solid',
          borderColor: Greys.LIGHT_GREY_20,
        }}
      >
        {`${I18n.t('analytic_view.patient_name')} ${patientInfo &&
          `${patientInfo.firstname} ${patientInfo.lastname}`}`}
      </Flex>
      <Flex otherStyles={{ width: 'max-content', padding: '0 15px', wordBreak: 'break-all' }}>
        {`${I18n.t('common_labels.careplan')}: ${careplanName}`}
      </Flex>
    </Flex>
  );
};

export default PatientContent;
