import I18n from 'i18next';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { Checkbox, Flex, Icon, Label } from '@ui-common-files/components';

import { ActionColors, CheckBoxRadioVariants } from '@ui-common-files/utils';
import { addMultipleChoices } from '../../../Utils/Careplan/careplanComponentUtilities';

import { isThresholdSet } from '../CareplanHelperUtility';
import componentTypeOrder from '../careplanComponentTypeOrder';
import BloodPressureTable from './bloodPressureTable';

const handleSortable = (
  event,
  item,
  index,
  componentCollection,
  setComponentCollection
) => {
  item.choiceCollection.choices[index].name = event.target.value;
  item.choiceCollection.choices[index].isChecked = event.target.checked;
  setComponentCollection([...componentCollection]);
};

const SortableItem = SortableElement(
  ({
    id,
    item,
    value,
    thresholdToggle,
    componentCollection,
    setComponentCollection,
    i,
  }) => {
    return (
      <li style={{ listStyleType: 'none' }}>
        <Flex flexDirection="row" className="singleChoices">
          <Flex flexDirection="row">
            <Flex alignItems="center" otherStyles={{ width: '100%' }}>
              {thresholdToggle && (
                <Checkbox
                  id={id}
                  name={id}
                  value={value}
                  label={I18n.t(`bmiOptions.${value}`)}
                  variant={
                    thresholdToggle
                      ? CheckBoxRadioVariants.Threshold
                      : CheckBoxRadioVariants.Default
                  }
                  hasInlineInput={false}
                  isDisabled={false}
                  inputWidth="100%"
                  valueInput={value}
                  checked={
                    thresholdToggle
                      ? item.choiceCollection.choices[i].isChecked
                      : false
                  }
                  handleOnChange={e =>
                    thresholdToggle
                      ? handleSortable(
                          e,
                          item,
                          i,
                          componentCollection,
                          setComponentCollection
                        )
                      : ''
                  }
                  handleInputOnChange={() => {}}
                />
              )}
              {!thresholdToggle && (
                <Flex flexDirection="row" className="bmiChoicesLabel">
                  <Label text={I18n.t(`bmiOptions.${value}`)} />
                </Flex>
              )}
              {thresholdToggle && item.choiceCollection.choices[i].isChecked && (
                <div className="thresholdIconContainer">
                  <Icon
                    name="threshold"
                    size={24}
                    fill={ActionColors.THRESHOLD}
                  />
                </div>
              )}
            </Flex>
          </Flex>
        </Flex>
      </li>
    );
  }
);

const SortableList = SortableContainer(
  ({
    item,
    index,
    componentCollection,
    setComponentCollection,
    searchTermComponents,
    setBlurr,
    type,
  }) => {
    let id;
    const thresholdToggle = isThresholdSet(
      index,
      componentCollection,
      searchTermComponents
    );
    return (
      <div
        onBlur={() =>
          addMultipleChoices(
            item,
            componentCollection,
            setComponentCollection,
            setBlurr
          )
        }
      >
        {type === componentTypeOrder.BLOOD_PRESSURE ? (
          <BloodPressureTable
            index
            componentCollection={componentCollection}
            setComponentCollection={setComponentCollection}
            item={item}
            thresholdToggle={thresholdToggle}
            handleSortable={handleSortable}
          />
        ) : (
          item.choiceCollection.choices.map((elem, i) => {
            const value = `${elem.name}`;
            id = `multipleChoice_${i}_${index}`;
            return (
              <React.Fragment key={`multipleChoiceElement_${i}_${index}`}>
                <SortableItem
                  id={id}
                  item={item}
                  value={value}
                  thresholdToggle={thresholdToggle}
                  componentCollection={componentCollection}
                  setComponentCollection={setComponentCollection}
                  i={i}
                  disabled
                  type={type}
                />
              </React.Fragment>
            );
          })
        )}
      </div>
    );
  }
);

const renderBMI = (
  item,
  componentCollection,
  searchTermComponents,
  setComponentCollection,
  setBlurr,
  type
) => {
  const index = componentCollection.findIndex(comp => comp.id === item.id);
  return (
    <SortableList
      item={item}
      index={index}
      componentCollection={componentCollection}
      setComponentCollection={setComponentCollection}
      searchTermComponents={searchTermComponents}
      setBlurr={setBlurr}
      type={type}
    />
  );
};

export default renderBMI;
