import { useEffect, useState } from 'react';
import { checkLogicJumpsThresholdsAndScore } from '@global/Careplan/Components/Utils';
import {
  PathWayReviewModalState,
  UsWarningCheckbox,
} from './PathWayReviewModal.types';
import { getPathwayTemplateDetails } from './PathWayReviewModal.api';
import { useCarePathWayBuilderStore } from '../../CarePathWayBuilder.store';

const usePathWayReviewModalService = (selectedCareTasks: any[]) => {
  const [componentState, setComponentState] = useState<PathWayReviewModalState>(
    {
      usWarningCheckboxChecked: {
        awareCheckbox: false,
        confirmCheckbox: false,
      },
      consentChecked: false,
      currentStep: 1,
    }
  );

  const shouldDisplayComponentConfiguration =
    checkLogicJumpsThresholdsAndScore(selectedCareTasks);
  const { usWarningCheckboxChecked, consentChecked, currentStep } =
    componentState;

  useEffect(() => {
    const isDisable =
      currentStep === 1 &&
      (!usWarningCheckboxChecked.awareCheckbox ||
        !usWarningCheckboxChecked.confirmCheckbox);
    setComponentState(prevState => ({
      ...prevState,
      consentChecked: isDisable,
    }));
  }, [currentStep, usWarningCheckboxChecked]);

  useEffect(() => {
    const newCurrentStep =
      selectedCareTasks.length > 0 && !shouldDisplayComponentConfiguration
        ? 2
        : 1;
    setComponentState(prevState => ({
      ...prevState,
      currentStep: newCurrentStep,
    }));
  }, [
    shouldDisplayComponentConfiguration,
    selectedCareTasks,
    usWarningCheckboxChecked,
  ]);

  const setUsWarningCheckboxChecked = (checkboxValues: UsWarningCheckbox) => {
    setComponentState(prevState => ({
      ...prevState,
      usWarningCheckboxChecked: checkboxValues,
    }));
  };

  const setCurrentStep = (step: number) => {
    setComponentState(prevState => ({ ...prevState, currentStep: step }));
  };

  return {
    usWarningCheckboxChecked,
    consentChecked,
    currentStep,
    setUsWarningCheckboxChecked,
    setCurrentStep,
    checkLogicJumpsThresholdsAndScore,
    shouldDisplayComponentConfiguration,
  };
};

export default usePathWayReviewModalService;

export const usePathWayReviewData = (nodes: any[]) => {
  const [selectedCareTasks, setSelectedCareTasks] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const pathWayReview = useCarePathWayBuilderStore(
    state => state.pathWayReview
  );

  useEffect(() => {
    const fetchData = async () => {
      if (pathWayReview.isOpen) {
        const careTaskIds = nodes.map(obj => obj.data.careTaskData.id);
        const templateIdsSet = new Set(careTaskIds);
        const templateIds = Array.from(templateIdsSet);

        setSelectedCareTasks([]);
        const response = await getPathwayTemplateDetails(
          templateIds,
          setIsLoading
        );

        if (response && response.data) {
          const { fetchedTemplateListWithThreshold } = response.data;
          setSelectedCareTasks(fetchedTemplateListWithThreshold);
        }
      }
    };

    fetchData();
  }, [pathWayReview.isOpen]);

  return {
    selectedCareTasks,
    isLoading,
  };
};
