import React, { useState, useEffect, useCallback, useContext } from 'react';

import {
  createWithingsAccount,
  getAttachedDevices,
} from './WithingsDevice.api';

import { PatientOverviewContext } from '../../PatientOverview/PatientOverview';

import {
  checkPatientWithingsAccountStatus,
  unLinkWithingsDeviceToPatient,
} from './WithingsDevice.helper';

import {
  DeviceInfo,
  GetPatientAttachedDevicesResponse,
  WithingsDevice,
} from './WithingsDevice.type';

export function useWithingsDevice() {
  const { patientInfo } = useContext(PatientOverviewContext);

  const [values, setValues] = useState<DeviceInfo>({
    macAddress: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [withingsDevices, setWithingsDevices] = useState<WithingsDevice[]>([]);

  const onValueChange = (value: string, key: keyof DeviceInfo): void => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const createPatientWithingsAccount = async (): Promise<void> => {
    setIsConnecting(true);
    setIsLoading(true);

    const macAddressesArray = values.macAddress.split(',');

    const response = await createWithingsAccount(
      patientInfo.id,
      macAddressesArray
    ).finally(() => {
      setIsConnecting(false);
      setIsLoading(false);
      setValues({ macAddress: '' });
    });

    if (response?.data) {
      const { devices } = response.data;

      setWithingsDevices(prevList => {
        const existingMacAddresses = new Set(
          prevList.map(device => device.mac_address)
        );

        const newDevices = devices.filter(
          device => !existingMacAddresses.has(device.mac_address)
        );
        return [...prevList, ...newDevices];
      });
    }
  };

  const getPatientAttachedDevices = async (): Promise<
    GetPatientAttachedDevicesResponse
  > => {
    setIsLoading(true);

    const response = await getAttachedDevices(patientInfo.id).finally(() => {
      setIsLoading(false);
    });

    if (response?.data) {
      const {
        body: { devices },
      } = response.data;

      setWithingsDevices(devices);
      return response;
    }
  };

  const initializeWithings = async (): Promise<void> => {
    setIsLoading(true);

    const patientExists = await checkPatientWithingsAccountStatus(
      patientInfo.id
    ).finally(() => {
      setIsLoading(false);
    });

    if (patientExists) {
      await getPatientAttachedDevices();
    }
  };

  useEffect(() => {
    initializeWithings();
  }, []);

  const onDelete = useCallback(
    async (deviceId: string, macAddress: string): Promise<void> => {
      setIsLoading(true);

      const canUnlink = await unLinkWithingsDeviceToPatient(
        patientInfo.id,
        macAddress
      ).finally(() => {
        setIsLoading(false);
      });

      if (canUnlink) {
        setWithingsDevices(prevList =>
          prevList.filter(item => item.deviceid !== deviceId)
        );
      }
    },
    []
  );

  return {
    values,
    onValueChange,
    isLoading,
    isConnecting,
    withingsDevices,
    createPatientWithingsAccount,
    onDelete,
  };
}
