import React from 'react';

import { Box, DataTable } from '@ui-common-files/components';

import { PrimaryColor } from '@ui-common-files/utils';

import getFirebaseInformations from './FirebaseInformations.service';

const FirebaseInformations = ({}) => {
  const { fetchedData, loading, columns } = getFirebaseInformations();

  return (
    <>
      <Box
        className="custom-label"
        otherStyles={{ color: PrimaryColor.MAIN_COLOR, marginTop: 35 }}
      >
        Firebase informations
      </Box>
      <DataTable
        data={fetchedData}
        columns={columns}
        isLoading={loading}
        onTableChange={() => {}}
      />
    </>
  );
};
export default FirebaseInformations;
