import React, { useEffect } from 'react';
import Dropdown from '../../../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import { showOperator } from '../../../../../Utils/PatientFilter/PatientFilter';
import { FilterActions, OperatorTypes } from '../../../../../Utils/PatientFilter/PatientFilter.enum';
import i18next from 'i18next';
import { FilterCriteriaOperatorProps, SelectedFieldOperatorsType } from './FilterCriteriaOperator.type';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';

const FilterCriteriaOperator = ({ criteria, operators, hide } : FilterCriteriaOperatorProps) => {
  const { updateCriterias } = usePatientFilterContext();

  const operatorsFormatter = (selectedFieldOperators : SelectedFieldOperatorsType) =>
    selectedFieldOperators.map(operator => ({
      ...operator,
      value: operator.id,
      label: i18next.t(operator.labelLocation),
    }));

  const checkBetweenField = (check : OperatorTypes) =>
    [OperatorTypes.Between, OperatorTypes.NotBetween].includes(check);

  const operatorsShown = showOperator(criteria.field.type) && !hide;

  const check = {...criteria.check, label: i18next.t(criteria.check.labelLocation)};

  if(!operatorsShown)
    return null;

  if(criteria.field.operators.length === 1)
    return (
      <Flex className="filters__criteria-check__label">
          {check.label}
      </Flex>
    );

  return (
        <div className="filters__criteria-check">
          <Dropdown
            value={check}
            options={operatorsFormatter(operators || criteria.field.operators)}
            placeholder="Check"
            handleOnChange={check => {
              const value = checkBetweenField(check.id) ? ['', ''] : [''];
              updateCriterias({action: FilterActions.Update, criteria: {values: { check, value }, position: criteria.position}});
            }}
          />
        </div>
      )
};

export default FilterCriteriaOperator;
