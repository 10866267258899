import { lastIndexOfObjectParams } from './LastIndexOfObject.type';

/**
 * Find the latest index of an object in an Array
 * @param array You need to specify the array in which you want to search.
 * @param key Search by the key you want
 * @param value Look for the value of the key
 * @param start default is 0. You want to start looking in this index
 */
const lastIndexOfObject = ({
  array,
  key,
  value,
  start = 0,
}: lastIndexOfObjectParams): number => {
  if (array.length === 0) return 0;

  if (start < 0 || start > array.length - 1)
    throw new Error(
      'The start value should be greater or equal to 0 and smaller or equal to Array.length'
    );

  for (let i = array.length - 1; i >= start; i--) {
    if (array[i][key] === value) return i;
  }
  return -1;
};

export default lastIndexOfObject;
