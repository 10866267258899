import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ModalContent,
  ComponentWithLabel,
  TextInput,
  MultipleSelection,
  TextArea,
} from '@ui-common-files/components';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';

import {
  inputField,
  MaxCharLimits,
  ActionTranslationKeys,
  ComponentTranslationKeys,
} from '@ui-common-files/utils';

import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';
import { renderShowAssetFirstColumn} from '../../Utils/Asset/assetHelper';
import ASSET_TYPES from '../../Utils/Asset/assetTypes';
import { useUserPermission } from '@utils';

import serviceConfig from '../../../serviceConfig.json';

import '../../css/assets.css';

const EditAssetModalContent = ({
  assetDataToEdit,
  sites,
  closeModal,
  setFlashMessage,
  setReloadTable,
  activeTab,
}) => {
  const { assetData } = assetDataToEdit;
  const { assetTypeId, resourceSpaceAssetId, id } = assetData;
  const assetInstitutions = assetData.institutions.map(inst => ({
    value: inst.id,
    label: inst.name,
  }));
  const allsites = sites.map(insti => ({
    value: insti.key,
    label: insti.site,
    site: insti,
  }));
  const [values, setValues] = useState({
    name: assetData.name,
    description: assetData.description ? assetData.description : '',
    institutions: assetInstitutions,
  });
  const { t } = useTranslation();

  const onValueChange = (value, key) => {
    if (key === 'description' && value.length > 500) return;
    setValues({
      ...values,
      [key]: value,
    });
  };

  const [hasErrors, setHasErrors] = useState({
    name: false,
    description: false,
    institutions: false,
  });
  const validateForm = () => {
    const formErrors = {
      ...hasErrors,
      name: values.name.trim().length < 1,
      institutions: values.institutions.length < 1,
    };
    setHasErrors(formErrors);
    return !Object.keys(formErrors).some(k => formErrors[k]);
  };

  const updateAsset = async () => {
    closeModal();
    try {
      const response = await axios.post(
        `${serviceConfig.brokerService.updateAsset.uri}${assetData.id}`,
        {
          name: values.name,
          description: values.description,
          institutionIds: values.institutions.map(insti => insti.value),
          assetTypeId,
          resourceSpaceAssetId: resourceSpaceAssetId,
          assetId: id,
        }
      );

      if (response) {
        if (response.status === 200) {
          setReloadTable(activeTab);
        }
        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    if (validateForm()) {
      updateAsset();
    }
    return false;
  };

  const hasReadSitesPermission = useUserPermission(
    t(ComponentTranslationKeys.SITE),
    t(ActionTranslationKeys.READ),
    true
  );

  return (
    <ModalContent>
      <ModalForm formId="update-user-form" handleFormSubmit={handleFormSubmit}>
        <ModalSection variant={MODAL_VARIANT.COL_W40} hasOverflow={false}>
          {renderShowAssetFirstColumn(assetDataToEdit)}
        </ModalSection>

        <ModalSection variant={MODAL_VARIANT.COL_W60}>
          <Box margin="21px 0 0 -24px">
            <ComponentWithLabel
              id="name"
              htmlFor="name"
              labelText={`* ${t('asset_view.fileName')}`}
            >
              <TextInput
                value={values.name}
                hasError={hasErrors.name}
                id="name"
                name="name"
                placeholder={t('asset_view.fileName')}
                variant={inputField.variant.CHAR_COUNT}
                maxLength={MaxCharLimits.files.fileNameMaxCharLimit}
                maxChars={MaxCharLimits.files.fileNameMaxCharLimit}
                validationMessage={t('parsley.requiredField')}
                handleOnChange={event => {
                  onValueChange(event.target.value, 'name');
                }}
              />
            </ComponentWithLabel>

            <ComponentWithLabel
              id="description"
              htmlFor="description"
              labelText={` ${t('asset_view.description')}`}
            >
              <TextArea
                type="text"
                value={values.description}
                handleOnChange={event => {
                  onValueChange(event.target.value, 'description');
                }}
                placeholder={t('asset_view.description_placeholder')}
                wrap="soft"
                style={{ height: '160px' }}
                variant={inputField.variant.CHAR_COUNT}
                maxChars={MaxCharLimits.files.fileDescMaxCharLimit}
              />
            </ComponentWithLabel>
            {activeTab !== ASSET_TYPES.PRIVACY_POLICY &&
              hasReadSitesPermission && (
                <ComponentWithLabel
                  id="institutions"
                  htmlFor="institutions"
                  labelText={`* ${t('asset_view.availableSite')}`}
                >
                  <MultipleSelection
                    value={values.institutions}
                    defaultOptions={allsites}
                    maxChars={allsites.length}
                    isDisabled={false}
                    placeholder={t('site_view.add_site')}
                    tagVariant="contained"
                    hasMark={false}
                    isClearable
                    clearIndicatorText={t('common_buttons.clear')}
                    isSelectAll
                    hasError={hasErrors.institutions}
                    selectAllIndicatorText={t('common_buttons.selectAll')}
                    noOptionsMessage={t('appointment.noResultsFound')}
                    changeValue={event => {
                      onValueChange(event, 'institutions');
                    }}
                  />
                  {hasErrors.institutions && (
                    <span className="asset-institutions__edit">
                      {' '}
                      {t('parsley.requiredField')}
                    </span>
                  )}
                </ComponentWithLabel>
              )}
          </Box>
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
};

export default EditAssetModalContent;
