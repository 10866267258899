import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import { getAllTodoAssessments } from '../Todos/Todos.api';
import { setTodos } from './Notification.reducer';
import { GetAllTodosType, TodosResponse } from '../Todos/Todos.types';

const useAllTodoAssessments = () => {
  const dispatch = useDispatch();
  const allTodoAssessmentsController = new AbortController();
  let assessmentsResult: AxiosResponse<TodosResponse, any>;
  const getAllTodos = useCallback(
    async (callback?: GetAllTodosType['callback']) => {
      assessmentsResult = await getAllTodoAssessments(
        allTodoAssessmentsController,
        { limit: 3 }
      ).finally(() => {
        if (callback) {
          callback(assessmentsResult);
        }
      });
      dispatch(
        setTodos({
          count: assessmentsResult?.data?.totalCount,
          items: assessmentsResult?.data?.assessments,
        })
      );
    },
    []
  );

  return { getAllTodos, allTodoAssessmentsController };
};

export default useAllTodoAssessments;
