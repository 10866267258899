export const onSatelliteChange = (
  value,
  setHasErrors,
  hasErrors,
  setSatellite
) => {
  setHasErrors({
    ...hasErrors,
    isSatelliteEmpty: false,
  });
  setSatellite(value);
};
