import { put, call, takeEvery } from 'redux-saga/effects';
import patientsActions from '../src/Utils/ReduxUtilities/Actions/patients.json';
import {
  addPatientApiCall,
  createPatient,
  editPatient,
  updatePatient,
  getPatientsByQuery,
  deletePatient,
  showPatient,
  getPatientsByCustomFilter,
  ERR_CANCELED,
} from '../src/Utils/ReduxUtilities/APICalls/patients';

function* addPatientRequest() {
  try {
    const results = yield call(addPatientApiCall);
    yield put({
      type: patientsActions.ADD_PATIENT_RESPONSE,
      addPatientResponse: results,
    });
  } catch (error) {
    yield put({
      type: patientsActions.PATIENT_ERROR,
      patientRequestError: error.response.data,
    });
  }
}
function* createPatientRequest(data) {
  try {
    const results = yield call(() => createPatient(data.payload));
    yield put({
      type: patientsActions.CREATE_PATIENT_RESPONSE,
      createPatientResponse: results,
    });
  } catch (error) {
    yield put({
      type: patientsActions.PATIENT_ERROR,
      patientRequestError: error.response.data,
    });
  }
}

function* editPatientRequest(data) {
  try {
    const results = yield call(() => editPatient(data.payload));
    yield put({
      type: patientsActions.EDIT_PATIENT_RESPONSE,
      editPatientResponse: results,
    });
  } catch (error) {
    yield put({
      type: patientsActions.PATIENT_ERROR,
      patientRequestError: error.response.data,
    });
  }
}

function* updatePatientRequest(data) {
  const { patientId, patientData } = data.payload;
  try {
    const results = yield call(() => updatePatient(patientId, patientData));
    yield put({
      type: patientsActions.UPDATE_PATIENT_RESPONSE,
      updatePatientResponse: results,
    });
  } catch (error) {
    yield put({
      type: patientsActions.PATIENT_ERROR,
      patientRequestError: error.response.data,
    });
  }
}

function* getPatientsByQueryRequest(data) {
  try {
    const results = yield call(() => getPatientsByQuery(data.payload));
    yield put({
      type: patientsActions.GET_PATIENTS_BY_QUERY_RESPONSE,
      getPatientsByQueryResponse: {
        data: results,
      },
    });
  } catch (error) {
    if (error.message.includes(ERR_CANCELED)) {
      yield put({
        type: patientsActions.ABORTED_REQUEST,
        abortedRequest: true,
      });
    } else {
      yield put({
        type: patientsActions.PATIENT_ERROR,
        patientRequestError: error.response.data,
        getPatientsByQueryResponse: true,
      });
    }
  }
}

function* getPatientsByCustomFilterRequest(data) {
  try {
    const results = yield call(() => getPatientsByCustomFilter(data.payload));
    yield put({
      type: patientsActions.GET_PATIENTS_BY_CUSTOM_FILTER_RESPONSE,
      getPatientsByQueryResponse: {
        data: results,
      },
    });
  } catch (error) {
    yield put({
      type: patientsActions.PATIENT_ERROR,
      patientRequestError: error.response.data,
      getPatientsByQueryResponse: true,
    });
  }
}

function* deletePatientsRequest(data) {
  try {
    const results = yield call(() => deletePatient(data.payload));
    yield put({
      type: patientsActions.DELETE_PATIENT_RESPONSE,
      deletePatientResponse: results,
    });
  } catch (error) {
    yield put({
      type: patientsActions.PATIENT_ERROR,
      patientRequestError: error.response.data,
    });
  }
}

function* showPatientsRequest(data) {
  const { patientId, healthScore, scoringSchemeCategory } = data.payload;
  try {
    const results = yield call(() =>
      showPatient(patientId, healthScore, scoringSchemeCategory)
    );
    yield put({
      type: patientsActions.SHOW_PATIENT_RESPONSE,
      showPatientResponse: results,
    });
  } catch (error) {
    yield put({
      type: patientsActions.PATIENT_ERROR,
      patientRequestError: error.response.data,
    });
  }
}

function* actionWatcher() {
  yield takeEvery(patientsActions.ADD_PATIENT_REQUEST, addPatientRequest);
  yield takeEvery(patientsActions.CREATE_PATIENT_REQUEST, createPatientRequest);
  yield takeEvery(patientsActions.EDIT_PATIENT_REQUEST, editPatientRequest);
  yield takeEvery(patientsActions.UPDATE_PATIENT_REQUEST, updatePatientRequest);
  yield takeEvery(
    patientsActions.GET_PATIENTS_BY_QUERY_REQUEST,
    getPatientsByQueryRequest
  );
  yield takeEvery(
    patientsActions.GET_PATIENTS_BY_CUSTOM_FILTER_REQUEST,
    getPatientsByCustomFilterRequest
  );
  yield takeEvery(
    patientsActions.DELETE_PATIENT_REQUEST,
    deletePatientsRequest
  );
  yield takeEvery(patientsActions.SHOW_PATIENT_REQUEST, showPatientsRequest);
}

export const patients = actionWatcher();
