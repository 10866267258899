import React, { useMemo } from 'react';
import I18n from 'i18next';

import { useHistory } from 'react-router-dom';
import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import '../../css/qrcode.css';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import IconButtonDropdown from '../../../caro-ui-commonfiles/components/DropdownMenu/IconButtonDropdown';

import {
  ActionColors,
  Greys,
  PrimaryColor,
} from '../../../caro-ui-commonfiles/utils/colors';
import checkUserCredential from '../../Utils/checkUserCredential';
import {
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import ICDViewer from '../../../caro-ui-commonfiles/components/ICD/ICDViewer';
import NavigationJSON from '../../Utils/navigation.json';
import TagsCell from './CellElements/TagsCell';
import useWindowWidthListener from '../../Utils/hooks/useWindowWidthListener';

const CareplanIndexTable = ({
  fetchedData,
  loading,
  onChange,
  currentPage,
  recordsTotal,
  pages,
  onShow,
  onEdit,
  onAssign,
  onExport,
  onDelete,
  sort,
  setSort,
  setFlashMessage,
  length,
  onQrCode,
  borderId,
  isReviewAssessment = false,
}) => {
  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];
  const history = useHistory();
  const responsive = useWindowWidthListener();
  const tableProps = {
    defaultSorted: [
      {
        id: 'name',
        desc: false,
      },
    ],
    onSortedChange: (newSorted, column, shiftKey) => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
    getTdProps: (state, row, col) => ({
      onClick: async () => {
        if (col.id !== 'buttons') {
          const hasUpdateCredential = await checkUserCredential(
            ComponentTranslationKeys.CAREPLAN_TEMPLATE,
            ActionTranslationKeys.UPDATE
          );
          if (hasUpdateCredential.type) {
            setFlashMessage({
              type: hasUpdateCredential.type,
              content: hasUpdateCredential.content,
            });
          } else if (hasUpdateCredential) {
            history.push(
              `/${NavigationJSON.EDIT_CAREPLAN_TEMPLATE.replace(
                ':id',
                row.original.id
              )}`
            );
          }
        }
      },
    }),
    getTrProps: (state, rowInfo) => {
      if (rowInfo) {
        return {
          style: {
            cursor: 'pointer',
            border:
              borderId == rowInfo.original.id ? 'solid 1px #3ebce8' : '0px',
          },
        };
      }
      return {};
    },
  };

  const getActiveOption = () => {
    const index = items.findIndex(opt => parseInt(opt.value) === length);
    if (index > -1) {
      return index;
    }
    return 0;
  };

  const getButtonOptions = (isAssessment: boolean) => {
    const actions = [
      { value: I18n.t('actions.show') },
      { value: I18n.t('actions.edit') },
      { value: I18n.t('actions.export') },
      { value: I18n.t('actions.delete') },
    ];

    if (!isAssessment)
      actions.splice(3, 0, { value: I18n.t('actions.qrCode') });
    if (!isReviewAssessment)
      actions.splice(3, 0, { value: I18n.t('actions.assign') });

    return actions;
  };

  const columns = useMemo(() => {
    return [
      {
        filterable: false,
        maxWidth: 60,
        style: {
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        },
        headerStyle: {
          marginRight: -20,
        },
        Cell: ({ original }) => {
          const { isAssessment } = original;
          return (
            <Box width={30}>
              <Icon
                name={isAssessment ? 'assessment' : 'careplan'}
                size={30}
                fill={
                  isReviewAssessment ? PrimaryColor.MAIN_COLOR : Greys.DARK_GREY
                }
              />
            </Box>
          );
        },
      },
      {
        Header: ({ column }) => {
          const { id } = column;
          return (
            <>
              {sort.column === id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{I18n.t('newCareplan_view.title')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'name',
        filterable: false,
        minWidth: 200,
        style: {
          fontWeight: 600,
          wordBreak: 'break-word',
        },
        headerStyle: {
          marginRight: 20,
        },
      },
      {
        Header: ({ column }) => {
          const { id } = column;
          return (
            <>
              {sort.column === id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{I18n.t('newCareplan_view.internalTitle')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'alias',
        filterable: false,
        minWidth: 200,
        style: { wordBreak: 'break-word' },
      },
      {
        Header: () => {
          return <span>{I18n.t('newCareplan_view.conducted_by')}</span>;
        },
        accessor: 'roles',
        sortable: false,
        filterable: false,
        style: { overflow: 'visible' },
        minWidth: 150,
        Cell: ({ row }) => {
          const { isAssessment, roles } = row._original;
          const rolePatients = [];
          if (!isAssessment) {
            rolePatients.push({ name: I18n.t('role_view.label_patients') });
          }
          return (
            <TagsCell tagList={!isAssessment ? rolePatients : roles} roles />
          );
        },
      },
      {
        Header: () => {
          return <span>{I18n.t('newCareplan_view.icd_code')}</span>;
        },
        accessor: 'icds',
        sortable: false,
        filterable: false,
        style: { overflow: 'visible' },
        minWidth: 180,
        Cell: ({ row }) => {
          return <ICDViewer icds={row.icds} />;
        },
      },
      {
        Header: '',
        accessor: 'buttons',
        sortable: false,
        filterable: false,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent:
            responsive || isReviewAssessment ? 'flex-end' : 'space-between',
          marginRight: responsive ? '6px' : 0,
        },
        minWidth: responsive ? 100 : 256,
        maxWidth: responsive ? 100 : 256,
        Cell: ({ original }) => {
          const {
            id,
            icds,
            tags,
            questions,
            isEvaluating,
            templateUUID,
            isAssessment,
          } = original;
          const title = original.name;
          const nbrComponents = questions;
          return (
            <>
              {!responsive ? (
                <>
                  <span style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="view"
                      size={30}
                      onClick={() => {
                        onShow(id, title);
                      }}
                      tooltipText={I18n.t('actions.show')}
                    />
                  </span>
                  <span style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="edit"
                      size={30}
                      onClick={() => {
                        onEdit(id);
                      }}
                      tooltipText={I18n.t('actions.edit')}
                    />
                  </span>
                  {!isReviewAssessment && (
                    <>
                      <span style={{ marginRight: 10 }}>
                        <IconButton
                          type="button"
                          name="qrCode"
                          disabled={!!isAssessment}
                          size={30}
                          onClick={() => {
                            onQrCode(id, title, templateUUID);
                          }}
                          tooltipText={
                            !isAssessment
                              ? I18n.t('actions.qrCode')
                              : I18n.t('actions.qrCodeDisable')
                          }
                        />
                      </span>
                      <span style={{ marginRight: 10 }}>
                        <IconButton
                          type="button"
                          name="assign"
                          size={30}
                          onClick={() => {
                            onAssign(
                              id,
                              title,
                              isEvaluating,
                              icds,
                              tags,
                              nbrComponents
                            );
                          }}
                          tooltipText={I18n.t('actions.assign')}
                        />
                      </span>
                    </>
                  )}
                  <span style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="export"
                      size={30}
                      onClick={() => {
                        onExport(id);
                      }}
                      tooltipText={I18n.t('actions.export')}
                    />
                  </span>
                  <span style={{ marginRight: isReviewAssessment ? 0 : 70 }}>
                    <IconButton
                      type="button"
                      name="delete"
                      size={30}
                      fill={ActionColors.DELETE}
                      onClick={async () => {
                        onDelete(id, title);
                      }}
                      tooltipText={I18n.t('actions.delete')}
                    />
                  </span>
                </>
              ) : (
                <div>
                  <IconButtonDropdown
                    items={getButtonOptions(isAssessment)}
                    onClick={async (e, action) => {
                      if (action.value === I18n.t('actions.show')) {
                        onShow(id, title);
                      } else if (action.value === I18n.t('actions.delete')) {
                        onDelete(id, title);
                      } else if (action.value === I18n.t('actions.export')) {
                        onExport(id);
                      } else if (action.value === I18n.t('actions.assign')) {
                        onAssign(
                          id,
                          title,
                          isEvaluating,
                          icds,
                          tags,
                          nbrComponents
                        );
                      } else if (action.value === I18n.t('actions.edit')) {
                        onEdit(id);
                      } else if (action.value === I18n.t('actions.qrCode')) {
                        onQrCode(id, title, templateUUID);
                      }
                    }}
                  />
                </div>
              )}
            </>
          );
        },
      },
    ];
  }, [responsive, sort.column, sort.dir]);

  return (
    <DataTable
      data={fetchedData}
      columns={columns}
      pageSize
      pageSizeOptions={items}
      pagination
      isLoading={loading}
      tableProps={tableProps}
      onTableChange={onChange}
      page={currentPage}
      pages={pages}
      totalRecord={recordsTotal}
      pageSizeActiveOption={getActiveOption()}
    />
  );
};

export default CareplanIndexTable;
