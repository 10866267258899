import i18next from "i18next";
import moment from 'moment';

function processThresholdTable(
  answer,
  careplanComponent,
  threshold,
  columnType,
  yesLabel,
  noLabel,
  enterLabel,
  skipLabel
) {
  switch (careplanComponent.careplanComponentTypeId) {
    case 2:
      let singleChoiceResult = '';
      if (columnType === 'answerColumn') {
        return `${
          answer.careplanComponent.optionJson.options[
            answer.answer_json.optionIndex
          ]
        }`;
      }
      singleChoiceResult = '==' ;
      if (Array.isArray(threshold.optionJson.optionIndex)){
        threshold.optionJson.optionIndex.forEach(index => {
          singleChoiceResult += `${answer.careplanComponent.optionJson.options[index]}, `;
        })
        
        return singleChoiceResult.slice(0, -2);
      }

    case 3:
    case 8:
      let result = '';
      if (columnType === 'answerColumn') {
        Object.keys(answer.answer_json).forEach((key) => {
          if (answer.answer_json[key]) {
            result += `${answer.careplanComponent.optionJson.options[key]}, `;
          }
        });
        return result.slice(0, -2);
      }
      result = '== '
      let optionIndex  = threshold.optionJson.optionIndex;
      if(!Array.isArray(optionIndex)){
        threshold.optionJson = {optionIndex :[optionIndex]};
      }
      threshold.optionJson.optionIndex.map(index => {
        result += `${answer.careplanComponent.optionJson.options[index]}, `;
      });
      return result.slice(0, -2);

    case 4:
    case 5:
      if (columnType === 'answerColumn') {
        return answer.answer_numeric;
      }
      return `${threshold.comparison} ${threshold.numericValue}`;

    case 6:
    case 9:
      if (columnType === 'answerColumn') {
        if (answer.answer_boolean === true) {
          return yesLabel;
        }
        return noLabel;
      }
      if (answer.answer_boolean === true) {
        return `${threshold.comparison} ${yesLabel}`;
      }
      return `${threshold.comparison} ${noLabel}`;

    case 7:
      const text = i18next.t(`bodyChartImage.${threshold.bodyChart}`);
      if (columnType === 'answerColumn') {
        return text;
      }
      return `== ${text}`;
    case 10:
      if (columnType === 'answerColumn') {
        if (answer.answer_boolean === true) {
          return enterLabel;
        }
        return skipLabel;
      }
      if (answer.answer_boolean === true) {
        return `${threshold.comparison} ${enterLabel}`;
      }
      return `${threshold.comparison} ${skipLabel}`;

    case 12:
      if (columnType === 'answerColumn') {
        return answer.answer_date;
      }
      return [threshold.comparison,threshold.numericValue,threshold.unit];

    case 13:
      let bmiResult = '';
      if (columnType === 'answerColumn') {
        const answerHeight = answer.answer_bmi_height || 0;
        const answerWeight = answer.answer_bmi_weight || 0;
        const answerBMI =
          Math.round(
            (answerWeight / ((answerHeight * answerHeight) / 10000)) * 10
          ) / 10;
        return bmiResult + answerBMI;
      }
      if (Array.isArray(threshold.optionJson.optionIndex)){
      threshold.optionJson.optionIndex.forEach(index => {
        bmiResult += `${i18next.t(
          `bmiThresholdsOptions.${answer.careplanComponent.optionJson.options[index]}`
        )}, `;
      });
      return bmiResult.slice(0, -2);
    }

    default:
      break;
  }
}

export default processThresholdTable;
