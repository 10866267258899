/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import { HideFlashMessage } from '@global/Services';

import Assets from '../Datatables/Assets';
import ASSET_TYPES from '../../Utils/Asset/assetTypes';
import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';

import Tabs from '../../../caro-ui-commonfiles/components/Tabs/Tabs';
import '../../css/chat.scss';
import ModalSection from '../common/Layout/Modal/ModalSection';
import ModalForm from '../common/Layout/Modal/ModalForm';
import tabPosition from '../../Utils/Administration/tabPosition';
import ModalFooter from '../common/Layout/Modal/ModalFooter';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import { TelemedicineContext } from './TelemedicineSidebar';

require('image-compressor');

const FileModal = ({ openModal, setOpenModal }) => {
  const [fetchedData, setFetchedData] = useState([]);
  const { setFlashMessage } = useContext(PatientOverviewContext);
  const {
    attachedFile,
    setAttachedFile,
    setAttachBtnClicked,
    fileActiveTab,
    setFileActiveTab,
  } = useContext(TelemedicineContext);

  const onClickTabItem = tab => {
    setFileActiveTab(tab);
  };
  const [reloadTable, setReloadTable] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
    setFileActiveTab(1);
  };

  const setActiveTabAndReload = tabId => {
    setReloadTable(!reloadTable);
    setFileActiveTab(tabId);
  };

  const renderModalContent = () => {
    return (
      <ModalContent>
        <ModalForm>
          <ModalSection hasPadding>
            <div style={{ position: 'relative' }}>
              <Tabs activeTab={fileActiveTab} onClickTabItem={onClickTabItem}>
                <div
                  label={I18n.t('asset_view.documents')}
                  tabId={tabPosition.FIRST}
                >
                  <Assets
                    hasPagesize
                    reloadTable={reloadTable}
                    setReloadTable={setActiveTabAndReload}
                    setFlashMessage={setFlashMessage}
                    activeTab={ASSET_TYPES.DOCUMENT}
                    setAttachedFile={setAttachedFile}
                    fetchedData={fetchedData}
                    setFetchedData={setFetchedData}
                    isAttachFiles
                    isPrivacyPolicy={false}
                  />
                </div>
                <div
                  label={I18n.t('asset_view.images')}
                  tabId={tabPosition.SECOND}
                >
                  <Assets
                    hasPagesize
                    reloadTable={reloadTable}
                    setReloadTable={setActiveTabAndReload}
                    setFlashMessage={setFlashMessage}
                    activeTab={ASSET_TYPES.IMAGE}
                    setAttachedFile={setAttachedFile}
                    fetchedData={fetchedData}
                    setFetchedData={setFetchedData}
                    isAttachFiles
                    isPrivacyPolicy={false}
                  />
                </div>
                <div
                  label={I18n.t('asset_view.videos')}
                  tabId={tabPosition.THIRD}
                >
                  <Assets
                    hasPagesize
                    reloadTable={reloadTable}
                    setReloadTable={setActiveTabAndReload}
                    setFlashMessage={setFlashMessage}
                    activeTab={ASSET_TYPES.VIDEO}
                    setAttachedFile={setAttachedFile}
                    fetchedData={fetchedData}
                    setFetchedData={setFetchedData}
                    isAttachFiles
                    isPrivacyPolicy={false}
                  />
                </div>

                <div
                  label={I18n.t('asset_view.audios')}
                  tabId={tabPosition.FOURTH}
                >
                  <Assets
                    hasPagesize
                    reloadTable={reloadTable}
                    setReloadTable={setActiveTabAndReload}
                    setFlashMessage={setFlashMessage}
                    activeTab={ASSET_TYPES.AUDIO}
                    setAttachedFile={setAttachedFile}
                    fetchedData={fetchedData}
                    setFetchedData={setFetchedData}
                    isAttachFiles
                    isPrivacyPolicy={false}
                  />
                </div>
              </Tabs>
            </div>
          </ModalSection>
        </ModalForm>
      </ModalContent>
    );
  };

  return (
    <Modal
      title={I18n.t('asset_view.attachFiles')}
      contentComponent={renderModalContent()}
      footerComponent={
        <ModalFooter
          close={closeModal}
          labelCTA={I18n.t('actions.attach')}
          onClickCTA={() => {
            if (attachedFile.length) {
              setAttachBtnClicked(true);
            }
            closeModal();
          }}
        />
      }
      openModal={openModal}
      onClose={closeModal}
      hideFlashMessage={HideFlashMessage}
    />
  );
};

FileModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};
export default FileModal;
