/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import I18n from 'i18next';
import PropTypes from "prop-types";

import { TextInput } from '@ui-common-files/components';
import { inputField, MaxCharLimits } from '@ui-common-files/utils';

const SearchBar = ({ handleNoteSearch }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearchInput = (event) => {
    setSearchText(event.target.value);
    handleNoteSearch(event.target.value);
  };

  return (
    <div className="note-searchbar-container">
      <TextInput
        handleOnChange={handleSearchInput}
        value={searchText}
        placeholder={I18n.t("common_labels.label_search")}
        variant={inputField.variant.CHAR_COUNT}
        maxChars={MaxCharLimits.searchInputs.searchKeyword}
      />
    </div>
  );
};

SearchBar.propTypes = {
  handleNoteSearch: PropTypes.func.isRequired,
};

export default SearchBar;
