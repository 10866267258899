import React from 'react';
import PropTypes from 'prop-types';

const Step = ({ isCurrent, number, text }) => {
  return (
    <li className="stepper__step">
      <span
        className={
          isCurrent ? 'stepper__step__number current' : 'stepper__step__number'
        }
      >
        {number}
      </span>
      <span
        className={
          isCurrent ? 'stepper__step__text current' : 'stepper__step__text'
        }
      >
        {text}
      </span>
    </li>
  );
};

Step.propTypes = {
  number: PropTypes.number,
  text: PropTypes.string,
  isCurrent: PropTypes.bool
};

export default Step;
