/**
 * Remove invalid symbols
 * @param fileName represents the name of file in which the invalid symbols will be removed.
 */

 const removeInvalidSymbolsFromFileName = (
    fileName: string
) : string => {
    if(fileName){
        return fileName.replace(/[\\/:*?"<>|]/g,'').replace(/\s+/g, '');
    }else{
        throw new Error('FileName is invalid.');
    }
};

export default removeInvalidSymbolsFromFileName;
