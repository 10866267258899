import React, { useEffect } from 'react';
import Loader from 'react-loader-spinner';

import { Box, Flex, CheckboxWarning } from '@ui-common-files/components';
import { PrimaryColor } from '@ui-common-files/utils';


import { nonMedical } from '../../../../../../../config';

import { PathWayReviewModalProps } from '../PathWayReviewModal.types';
import { CareTaskInfoRender } from '../../CareTasksInfoRender/CareTaskInfoRender';

const PathWayReviewStepComponents: React.FC = ({
  selectedCareTasks,
  isLoading,
  usWarningCheckboxChecked,
  setUsWarningCheckboxChecked,
}: PathWayReviewModalProps) => {
  useEffect(() => {
    setUsWarningCheckboxChecked({
      awareCheckbox: false,
      confirmCheckbox: false,
    });
  }, [selectedCareTasks]);

  return (
    <Box>
      {selectedCareTasks.length === 0 && isLoading && (
        <Flex
          className="modal_content"
          justifyContent="center"
          alignItems="center"
          otherStyles={{ flex: 1 }}
        >
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Flex>
      )}
      {selectedCareTasks?.length > 0 && !isLoading && (
        <Box className="carepathway_modal-layout">
          <CareTaskInfoRender selectedCareTasks={selectedCareTasks} />
          {selectedCareTasks.length > 0 && (
            <Box>
              <CheckboxWarning
                usWarningCheckboxChecked={usWarningCheckboxChecked}
                nonMedical={nonMedical}
                changeAwareCheckbox={event => {
                  setUsWarningCheckboxChecked({
                    ...usWarningCheckboxChecked,
                    awareCheckbox: event.target.checked,
                  });
                }}
                changeConfirmCheckbox={event => {
                  setUsWarningCheckboxChecked({
                    ...usWarningCheckboxChecked,
                    confirmCheckbox: event.target.checked,
                  });
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default PathWayReviewStepComponents;
