import React, { useRef } from "react";
import "../../styles/main.scss";
import PropTypes from "prop-types";
import modificationType from '../../utils/modification.json';
import I18n from 'i18next';


const ICDDropMenu = ({ items, selectOnClick }) => {
  const ul = useRef(null);

  /* ----------- COMPONENT RENDER ------------ */

  const renderItemsHighlighted = () => {
    if (items[0].empty) {
      return (
        <li className="icd-select__items__li">
          <div className="icd-select__item icd-select__item--no-options">
            <span>{items[0].modification == modificationType.OPS ? I18n.t('task.OPSNotFound') : I18n.t('task.ICDNotFound')}</span>
          </div>
        </li>
      );
    }
    return items.map(function(item) {
      return (
        <li
          id={item.id}
          key={item.id}
          onClick={e => selectOnClick(item.id)}
          tabIndex="1"
          className="icd-select__items__li"
        >
          <div className="icd-select__item">
            <span className="icd-select__item__content">
              <span className="icd-select__item__revision">{item.modification == modificationType.OPS ? 'OPS:' : 'ICD-10:'}</span>
              {item.code}
            </span>
            <span className="icd-select__item__separator" />
            {item.description}
          </div>
        </li>
      );
    });
  };

  return (
    <ul className={"icd-select__items"} tabIndex="0" ref={ul}>
      {renderItemsHighlighted()}
    </ul>
  );
};

ICDDropMenu.propTypes = {
  items: PropTypes.array.isRequired,
  selectOnClick: PropTypes.func.isRequired
};

export default ICDDropMenu;
