import React, { useEffect, useCallback, useMemo } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import moment from 'moment';
import I18n from 'i18next';

import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';
import th from 'date-fns/locale/th';
import pt from 'date-fns/locale/pt';
import ar from 'date-fns/locale/ar';
import fr from 'date-fns/locale/fr';

import Icon from '../Icon/Icon';
import { ActionColors } from '../../utils/colors';

import { DatepickerProps } from './DatePicker.type';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/main.scss';

setDefaultLocale(I18n.language);

const Datepicker: React.FC<DatepickerProps> = ({
  handleDate,
  placeholder = '',
  validationMessage = '',
  date = new Date(),
  hasError = false,
  disabled = false,
  minDate = moment().subtract(120, 'years').toDate(),
  maxDate = moment().add(50, 'years').toDate(),
}: DatepickerProps) => {
  const yearDropdownItemNumber = useMemo(() => {
    const current = date ? moment(date) : moment(new Date());
    const pastDifference = moment(current).diff(moment(minDate), 'years');
    const futureDifference = moment(maxDate).diff(moment(current), 'years');
    return Math.max(pastDifference, futureDifference);
  }, [date, minDate, maxDate]);

  const isInRange = useCallback(date => {
    const selected = moment(date);
    return (
      selected.endOf('day').isAfter(moment(minDate)) &&
      selected.startOf('day').isBefore(moment(maxDate))
    );
  }, []);

  const handleChange = pickedDate => {
    if (isInRange(pickedDate)) {
      handleDate(pickedDate);
    }
  };

  useEffect(() => {
    const languageCode = I18n.language.trim();
    setDefaultLocale(languageCode);
    switch (languageCode) {
      case 'de-DE':
        registerLocale(languageCode, de);
        break;
      case 'it-IT':
        registerLocale(languageCode, it);
        break;
      case 'th-TH':
        registerLocale(languageCode, th);
        break;
      case 'pt-BR':
        registerLocale(languageCode, pt);
        break;
      case 'ar-AR':
        registerLocale(languageCode, ar);
        break;
      case 'fr-FR':
        registerLocale(languageCode, fr);
        break;
    }
  }, [I18n.language]);

  return (
    <>
      <div className="datepicker-wrapper">
        <DatePicker
          dateFormat="P"
          placeholderText={placeholder}
          selected={date}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          className={`datepicker-input error${hasError && ' error'}`}
          calendarClassName="custom-datepicker"
          todayButton={I18n.t('recurrence.today')}
          disabled={disabled}
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={yearDropdownItemNumber}
          scrollableYearDropdown
        />
        {hasError && (
          <div className="datepicker-error-icon">
            <Icon
              name="error"
              fill={ActionColors.ERROR}
              className="input__text__icon right-icon"
            />
          </div>
        )}
      </div>
      {hasError && validationMessage && (
        <span className="react-datepicker__error">{validationMessage}</span>
      )}
    </>
  );
};

export default React.memo(Datepicker);
