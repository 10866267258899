/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo, useContext } from 'react';
import I18n from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import { FirebaseContext } from '../../Utils/Firebase/firebase';
import '../../css/careplans.scss';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import ICDViewer from '../../../caro-ui-commonfiles/components/ICD/ICDViewer';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';

const AssignTemplateStep4 = ({
  careplanData,
  selectedPatientsData,
  careplanNumberOfComponents,
}) => {
  const { hidePatientDemographics } = useContext(FirebaseContext);
  const { icds } = careplanData;
  const timeFormat = 'DD MMM YYYY HH:mm';
  const { t } = useTranslation();
  const { customTime } = careplanData;
  const startDate = useMemo(() => {
    return !careplanData.startNow
      ? moment(careplanData.startDate)
          .hour(customTime.hour)
          .minute(customTime.minute)
          .locale(I18n.language)
          .format(timeFormat)
      : moment().locale(I18n.language).format(timeFormat);
  }, [careplanData]);

  const recurrenceString = useMemo(() => {
    if (careplanData.startNow) {
      return t('recurrence.once');
    }
    let recurrence = t('recurrence.every') + ' ';
    const occurrenceCountOrUntil = careplanData.occurrenceCountCheck
      ? ` ${t('recurrence.for')} ${careplanData.occurrenceCount} ${t(
          'recurrence.times'
        )}`
      : ` ${t('recurrence.until').toLowerCase()} ${moment(careplanData.endDate)
          .locale(I18n.language)
          .format('DD MMM YYYY')}`;
    switch (careplanData.repeatType.value) {
      case 1:
        recurrence += `${careplanData.repeatInterval.value} ${t(
          'recurrence.everyday'
        )} ${occurrenceCountOrUntil}`;
        break;
      case 2:
        recurrence += `${careplanData.repeatInterval.value} ${t(
          'lifespan.WEEK'
        )} ${t('recurrence.on')} `;
        {
          careplanData.weeklyRecurrence.map(function(booleanValue, index) {
            if (booleanValue == true) {
              recurrence += `${t(`recurrence.weekDays.${index}`)}`;
              if (index < careplanData.weeklyRecurrence.lastIndexOf(true)) {
                recurrence += `, `;
              }
            }
          });
        }
        recurrence += occurrenceCountOrUntil;
        break;
      case 3:
        recurrence += `${careplanData.repeatInterval.value} ${t(
          'recurrence.month'
        )} ${occurrenceCountOrUntil}`;
        break;
      case 4:
        recurrence += `${careplanData.repeatInterval.value} ${t(
          'recurrence.year'
        )} ${occurrenceCountOrUntil}`;
        break;

      default:
        break;
    }
    return recurrence;
  }, [careplanData]);

  const selectedPatients = [];

  selectedPatientsData.map(function (selectedPatient) {
    selectedPatients.push(
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '5px 5px 10px 40px',
        }}
      >
        <Box width="40px" height="40px">
          <div
            style={{
              backgroundImage: `url(${selectedPatient.profilePicture})`,
            }}
            className="notification__table-profile-pic"
          />
        </Box>
        <div className="assign-template__patient-name">
          {hidePatientDemographics
            ? ''
            : `${selectedPatient.firstname} ${selectedPatient.lastname}`}
        </div>
      </div>
    );
  });

  return (
    <ModalForm>
      <ModalSection variant={MODAL_VARIANT.COL_W30}>
        <h3 className="assigned-to">
          {t('assignTemplate_view.assignedTo')}
        </h3>
        {selectedPatients}
      </ModalSection>
      <ModalSection variant={MODAL_VARIANT.COL_W70}>
        <div className="title-step-4">
          <Label
            className="title-step-4"
            text={t('newCareplan_view.title')}
          />
        </div>
        <h3 className="link">{careplanData.title}</h3>

        <div className="title-step-4">
          <Label text={t('common_labels.numberOfQuestions')} />
        </div>
        <h3 className="link">{careplanNumberOfComponents}</h3>
        {icds.length > 0 && (
          <>
            <div className="title-step-4">
              <Label text={t('task.ICDReference')} />
            </div>
            <ICDViewer icds={icds} shouldShowAll />
          </>
        )}
        <div className="title-step-4">
          <Label text={t('task.phaseType')} />
        </div>
        <h3 className="link">{t(careplanData.phaseType.label)}</h3>
        <div className="title-step-4">
          <Label text={t('recurrence.startsFrom')} />
        </div>
        <h3 className="link">{startDate}</h3>
        <div className="title-step-4">
          <Label text={t('medication.period')} />
        </div>
        <h3 className="link">{recurrenceString}</h3>
        <div className="title-step-4">
          <Label text={t('recurrence.lifespan')} />
        </div>
        <h3 className="link">
          {`${careplanData.lifespan} ${careplanData.lifespanUnit.label}`}
        </h3>
      </ModalSection>
    </ModalForm>
  );
};

export default AssignTemplateStep4;
