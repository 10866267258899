import { componentHealthCategories } from '../../Views/Careplans/CareplanHelperUtility';
import { ComplexFormula } from '../../Views/Careplans/ComplexScoreSetting/ComplexScoring.type';
import { getOverallScore } from '../../Views/Careplans/ComplexScoreSetting/ComplexScoring.utils';
import { errorType } from '../../Views/Careplans/ComplexScoreSetting/ComplexScoringValidations';

const getComplexScoreValidation = (
  validationErrorTemplate,
  setTemplateSettingsPanelError,
  componentCollection,
  isEvaluatingToggle,
  setScoringFormulas,
  scoringFormulas,
  isValid
) => {
  let isValidTemp = isValid;
  const validationErrorTemplateTemp = { ...validationErrorTemplate };

  let hasAnyError: boolean = false;

  const scoringFormulasTemp = scoringFormulas.map((formula: ComplexFormula) => {
    const newFormula: ComplexFormula = { ...formula };
    if (newFormula.equationHasError || newFormula.descriptionHasError) {
      hasAnyError = true;
    }
    if (newFormula.equation === '') {
      hasAnyError = true;
      newFormula.equationHasError = true;
      newFormula.equationErrorMessage = errorType.emptyFormula;
    }
    if (newFormula.description === '') {
      hasAnyError = true;
      newFormula.descriptionHasError = true;
      newFormula.descriptionErrorMessage = errorType.emptyDescription;
    }
    return newFormula;
  });

  if (hasAnyError) {
    isValidTemp = false;
    validationErrorTemplateTemp.templateSettingsValidation.equationError = true;
    setTemplateSettingsPanelError(true);
    setScoringFormulas(scoringFormulasTemp);
  }

  const overallScore = getOverallScore(scoringFormulasTemp);
  const hasOverallScore = overallScore && !overallScore.equationHasError;
  const additionalComponentFound = componentCollection.some(ele => {
    return ele.healthCategory !== componentHealthCategories.MAIN_QUESTION;
  });

  if (hasOverallScore && isEvaluatingToggle && additionalComponentFound) {
    isValidTemp = false;
    validationErrorTemplateTemp.templateSettingsValidation.additionalComponentsWithComplexScoreError =
      true;
    setTemplateSettingsPanelError(true);
  }

  if (!isEvaluatingToggle && hasOverallScore) {
    isValidTemp = false;
    validationErrorTemplateTemp.templateSettingsValidation.healthIndicatorInactiveWithTotalScoreError =
      true;
  } else {
    validationErrorTemplateTemp.templateSettingsValidation.healthIndicatorInactiveWithTotalScoreError =
      false;
  }

  return { isValidTemp, validationErrorTemplateTemp };
};

export default getComplexScoreValidation;
