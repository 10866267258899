import { OperatorTypes } from '../../../../../Utils/PatientFilter/PatientFilter.enum';
import {
  CriteriaType,
  FieldTypes,
  FilterTypes,
} from '../../PatientFilterContext/PatientFilter.type';

export interface FilterCriteriaFieldSelectorProps {
  criteria: CriteriaType;
  type: FilterTypes;
}

interface OperatorType {
  id: OperatorTypes;
  labelLocation: string;
}

export enum FieldIdType {
  FirstName = 'patient.firstname',
  LastName = 'patient.lastname',
  MedicalRecord = 'patient.medicalRecord',
  CaseNumber = 'patient.caseNumber',
  Birthdate = 'patient.birthdate',
  Gender = 'patient.gender',
  InsuranceType = 'patient.insuranceType',
  HealthStatus = 'patient.healthStatus',
  ICD = 'icd',
  OPS = 'ops',
  CareplanName = 'careplans.name',
  AnswerDate = 'careplans.answerDate',
  Score = 'careplans.score',
  IsAnswered = 'careplans.isAnswered',
  ComponentName = 'careplanComponents.name',
  CareplanComponentTypeId = 'careplanComponents.careplanComponentTypeId',
  TypeAndName = 'careplanComponents.typeAndName',
  CareplanComponentAnswer = 'careplanComponents.careplanComponentAnswer',
  Age = 'TIMESTAMPDIFF(YEAR,patient.birthdate, NOW())',
}

export type FieldType = {
  id: FieldIdType;
  label: string;
  labelLocation: string;
  type: FieldTypes;
  operators: OperatorType[];
  value: string;
  optgroup: string;
  validation: {
    callback(value: unknown): string | true;
    format?: unknown;
    messages?: unknown;
  };
  plugin?: unknown;
  plugin_config?: unknown;
  values?: unknown;
};

export type CheckType = {
  id: OperatorTypes;
  labelLocation: string;
  type: FieldTypes;
  operators: {
    id: OperatorTypes;
    labelLocation: string;
  }[];
  optgroup: string;
  validation: {
    callback(value: unknown): string | true;
    format?: unknown;
    messages?: unknown;
  };
  plugin?: unknown;
  plugin_config?: unknown;
  values?: unknown;
  label: string;
  name?: string;
};
