import axios from 'axios';
import serviceConfig from '../../../serviceConfig.json';
import { ApiClient } from '../../Global/Services';

async function getUserInfoWithSessionHistory(currenUser) {
  try {
    const response = await ApiClient.GET({
      url:
        serviceConfig.brokerService.getUserInfoWithSessionHistory.uri +
        currenUser,
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    return {
      content: error.response.data.content,
      type: error.response.data.type,
    };
  }
}

export default getUserInfoWithSessionHistory;