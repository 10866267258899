import React from 'react';

const documentIcon = (size: number) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 1.5C7.00736 1.5 6 2.50736 6 3.75V32.25C6 33.4926 7.00736 34.5 8.25 34.5H27.75C28.9926 34.5 30 33.4926 30 32.25V10.5L21 1.5H8.25Z"
      fill="#4E8DF5"
    />
    <path
      d="M8.25 34.5C7.00736 34.5 6 33.4926 6 32.25V31.875C6 33.1176 7.00736 34.125 8.25 34.125H27.75C28.9926 34.125 30 33.1176 30 31.875V32.25C30 33.4926 28.9926 34.5 27.75 34.5H8.25Z"
      fill="#417EE9"
    />
    <path
      d="M8.25 1.5C7.00736 1.5 6 2.50736 6 3.75V4.125C6 2.88236 7.00736 1.875 8.25 1.875H21.375L21 1.5H8.25Z"
      fill="#639AF6"
    />
    <path
      d="M29.9996 18.1875V10.5L28.6871 9.1875H22.3576C21.8564 9.1875 21.6055 9.79339 21.9598 10.1477L29.9996 18.1875Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M21 1.5L30 10.5H23.25C22.0074 10.5 21 9.49264 21 8.25V1.5Z"
      fill="#A6C5FA"
    />
    <path d="M24 18H12V19.5H24V18Z" fill="white" />
    <path d="M24 21H12V22.5H24V21Z" fill="white" />
    <path d="M12 24H24V25.5H12V24Z" fill="white" />
    <path d="M21 27H12V28.5H21V27Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="25.4996"
        y1="18.1875"
        x2="25.4996"
        y2="9"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8DF5" />
        <stop offset="1" stopColor="#3D6ACD" />
      </linearGradient>
    </defs>
  </svg>
);

const imageIcon = (size: number) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M36 32.4141H0V3.58594H36V32.4141Z" fill="#E6F9FF" />
    <path d="M36 32.4141H18V3.58594H36V32.4141Z" fill="#CCEFFF" />
    <path
      d="M33.8906 5.69531H2.10938V26.7891H33.8906V5.69531Z"
      fill="#ACE3FC"
    />
    <path d="M33.8906 5.69531H18V26.7891H33.8906V5.69531Z" fill="#86D6F2" />
    <path
      d="M8.4375 15.1875C10.185 15.1875 11.6016 13.7709 11.6016 12.0234C11.6016 10.276 10.185 8.85938 8.4375 8.85938C6.69004 8.85938 5.27344 10.276 5.27344 12.0234C5.27344 13.7709 6.69004 15.1875 8.4375 15.1875Z"
      fill="#FFDC40"
    />
    <path
      d="M33.891 26.789V15.8053L28.1253 10.0397L17.8245 20.3405L13.9574 16.4733L3.6416 26.789H33.891Z"
      fill="#66E26F"
    />
    <path
      d="M28.125 10.0397L18 20.1647V26.789H33.8906V15.8053L28.125 10.0397Z"
      fill="#48CC4E"
    />
  </svg>
);

const videoIcon = (size: number) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.648 16.0787C33.4309 15.9624 33.1674 15.9752 32.9627 16.112L26 20.7547V20.6667C26 18.0893 23.9107 16 21.3333 16H6.66669C4.08931 16 2 18.0893 2 20.6667V28.6667C2 31.244 4.08931 33.3334 6.66669 33.3334H21.3334C23.9107 33.3334 26.0001 31.2441 26.0001 28.6667V28.5787L32.9641 33.2214C33.2706 33.4254 33.6844 33.3423 33.8884 33.0357C33.9611 32.9264 33.9999 32.798 34 32.6667V16.6667C34.0001 16.4207 33.8648 16.1947 33.648 16.0787Z"
      fill="#607D8B"
    />
    <path
      d="M20 14.6668C23.3137 14.6668 26 11.9805 26 8.66675C26 5.35304 23.3137 2.66675 20 2.66675C16.6863 2.66675 14 5.35304 14 8.66675C14 11.9805 16.6863 14.6668 20 14.6668Z"
      fill="#455A64"
    />
    <path
      d="M6.66669 14.6667C9.24403 14.6667 11.3334 12.5774 11.3334 10.0001C11.3334 7.42272 9.24403 5.33337 6.66669 5.33337C4.08935 5.33337 2 7.42272 2 10.0001C2 12.5774 4.08935 14.6667 6.66669 14.6667Z"
      fill="#455A64"
    />
  </svg>
);

const audioIcon = (size: number) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M18 36C13.192 36 8.67185 34.1276 5.27203 30.728C1.87235 27.3281 0 22.808 0 18C0 13.192 1.87235 8.67185 5.27203 5.27203C8.67185 1.87235 13.192 0 18 0C22.808 0 27.3281 1.87235 30.728 5.27203C34.1276 8.67185 36 13.192 36 18C36 22.808 34.1276 27.3281 30.728 30.728C27.3281 34.1276 22.808 36 18 36Z"
        fill="#E6E7FA"
      />
      <path
        d="M18 27.5625C17.068 27.5625 16.3125 26.807 16.3125 25.875V10.125C16.3125 9.19301 17.068 8.4375 18 8.4375C18.932 8.4375 19.6875 9.19301 19.6875 10.125V25.875C19.6875 26.807 18.932 27.5625 18 27.5625Z"
        fill="#232A85"
      />
      <path
        d="M12.375 24.1875C11.443 24.1875 10.6875 23.432 10.6875 22.5V13.5C10.6875 12.568 11.443 11.8125 12.375 11.8125C13.307 11.8125 14.0625 12.568 14.0625 13.5V22.5C14.0625 23.432 13.307 24.1875 12.375 24.1875Z"
        fill="#232A85"
      />
      <path
        d="M29.25 21.375C28.318 21.375 27.5625 20.6195 27.5625 19.6875V16.3125C27.5625 15.3805 28.318 14.625 29.25 14.625C30.182 14.625 30.9375 15.3805 30.9375 16.3125V19.6875C30.9375 20.6195 30.182 21.375 29.25 21.375Z"
        fill="#232A85"
      />
      <path
        d="M23.625 24.1875C22.693 24.1875 21.9375 23.432 21.9375 22.5V13.5C21.9375 12.568 22.693 11.8125 23.625 11.8125C24.557 11.8125 25.3125 12.568 25.3125 13.5V22.5C25.3125 23.432 24.557 24.1875 23.625 24.1875Z"
        fill="#232A85"
      />
      <path
        d="M6.75 21.375C5.81801 21.375 5.0625 20.6195 5.0625 19.6875V16.3125C5.0625 15.3805 5.81801 14.625 6.75 14.625C7.68199 14.625 8.4375 15.3805 8.4375 16.3125V19.6875C8.4375 20.6195 7.68199 21.375 6.75 21.375Z"
        fill="#232A85"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { documentIcon, imageIcon, videoIcon, audioIcon };
