import React, {
  useMemo,
  useCallback,
  useReducer,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Flex,
  Box,
  WYSIWYGEditor,
  IconButton,
  ToggleButton,
  NumberInput,
  Label,
  Tooltip,
} from '@ui-common-files/components';
import ComponentSettingsWarning from './ComponentSettingsWarning';
import MediaSettingsComponent from '../componentSettingsAssets/MediaSettingsComponent';
import OptionWeightsComponent from '../componentSettingsAssets/OptionWeightsComponent';
import NumericValueComponent from '../settingsForComponentTypes/NumericValueComponent';
import NumericRangeComponent from '../settingsForComponentTypes/NumericRangeComponent';
import AnalyticsDataSettings from '../AnalyticsDataSettings/AnalyticsDataSettings';

import {
  sanitizeInput,
  Position,
  PrimaryColor,
  Greys,
  MaxCharLimits,
  ActionTranslationKeys,
  ComponentTranslationKeys,
} from '@ui-common-files/utils';
import { useUserPermission } from '@utils';
import componentTypes from '../componentSettingsAssets/componentTypes.json';
import defaultEnums from '../defaultEnums';
import {
  getNewlyCreatedComponentOptionsAndWeightsForSortables,
  isMainQuestion,
} from '../CareplanHelperUtility';
import LogicJumpToggle from '../LogicJump/LogicJumpToggle';
import componentTypeOrder from '../careplanComponentTypeOrder';
import {
  useInitialEditableComponent,
  useIfEditable,
} from '../../../Utils/hooks/setIfEditable';
import { nonMedical } from '../../../../config';
import { containsNegativeValue } from './ComponentSettings.util';

import '../../../css/role.css';
import '../../../css/componentSettings.css';

const ComponentSettings = ({
  setComponentCollection,
  searchTermComponents,
  setSettings,
  componentCollection,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  shouldShowSettings,
  isEditable,
  setIsEditable,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring,
  isCareplanEvaluatingToggle,
  setFileReviewToggle,
  isEditMode
}) => {
  const { t } = useTranslation();
  const [currComponent, setCurrComponent] = useReducer((_state, comp) => comp, {
    component: searchTermComponents,
    componentTypeId: -1,
    index: -1,
  });
  const [componentDescription, setComponentDescription] = useState(
    currComponent.component.descriptionEditor
  );
  const [componentName, setComponentName] = useState(
    currComponent.component.nameHtml ? currComponent.component.nameHtml : ''
  );

  const currentPosition = useMemo(
    () =>
      componentCollection.findIndex(
        elem => elem.id === searchTermComponents.id
      ),
    [componentCollection, searchTermComponents.id]
  );

  const containsNegatives = useMemo(() => {
    const component = componentCollection[currentPosition];
    const isNumeric = [
      componentTypeOrder.NUMERIC_RANGE,
      componentTypeOrder.NUMERIC_VALUE,
    ].includes(currComponent.componentTypeId);
    return (
      isNumeric &&
      containsNegativeValue([
        component.searchTerm?.minimumValue,
        component.searchTerm?.maximumValue,
        component.minimumValue,
        component.maximumValue,
      ])
    );
  }, [componentCollection, currComponent]);

  const isMainComponentWithNegativeValues =
    containsNegatives && isMainQuestion(currComponent.component.healthCategory);

  const onChangeRichInput = (
    property,
    richInputText,
    index,
    setRichInputText,
    setComponentAndComponentCollection,
    currentComponentObject
  ) => {
    if (index > -1) {
      setRichInputText(richInputText);
      const searchTerm = { ...currentComponentObject.searchTerm };
      let item = { ...currentComponentObject };
      let state = {};

      switch (property) {
        case 'title':
          state = {
            ...searchTerm,
            nameHtml: richInputText,
            value: sanitizeInput(richInputText),
            label: sanitizeInput(richInputText),
          };
          item = {
            ...item,
            searchTerm: state,
            nameHtml: richInputText,
            name: sanitizeInput(richInputText),
            richInputText,
          };
          break;

        case 'description':
          state = {
            ...searchTerm,
            description: richInputText,
          };
          item = {
            ...item,
            searchTerm: state,
            richInputText,
          };
          break;

        default:
          break;
      }
      setComponentAndComponentCollection(item);
    }
  };

  const initialEditableComponent = useInitialEditableComponent(
    currComponent.component || {
      id: -1,
      searchTerm: -1,
    },
    isEditable
  );

  const [editableComponent, setEditableComponent] = useReducer(
    (_state, component) => component,
    initialEditableComponent
  );

  const setComponentAndComponentCollection = updatedComponent => {
    if (
      currComponent.component &&
      updatedComponent.id === currComponent.component.id &&
      currComponent.index > -1
    ) {
      const updatedComponentCollection = [...componentCollection];
      const { index } = currComponent;
      updatedComponentCollection[index] = updatedComponent;
      setCurrComponent({
        ...currComponent,
        component: updatedComponent,
      });
      setComponentCollection(updatedComponentCollection);
    }
  };

  const hasAnalyticsDataCreateOrUpdatePermission = useUserPermission(
    t(ComponentTranslationKeys.STANDARD_DATA_MAPPING),
    isEditMode
      ? t(ActionTranslationKeys.UPDATE)
      : t(ActionTranslationKeys.CREATE),
    true
  );

  const setIfEditable = useIfEditable(
    isEditable,
    setIsEditable,
    editableComponent,
    currComponent.component
  );

  const handleComponentWeightOnChange = value => {
    const updatedCurrComponent = { ...currComponent.component };
    if (updatedCurrComponent.searchTerm.careplanComponentId) {
      updatedCurrComponent.searchTerm.weight = value;
    } else {
      updatedCurrComponent.weight = value;
    }
    setComponentAndComponentCollection(updatedCurrComponent);
    const updatedPrompt = { ...showPromptForAdjustScoring };
    updatedPrompt.checkUserDeleteAddOrChangeScoreOfComponent = true;
    setShowPromptForAdjustScoring(updatedPrompt);
  };

  const handleSettingsToggleAndPreFilling = type => {
    const updatedCurrComponent = { ...currComponent.component };
    switch (type) {
      case 'handleDescriptionToggle':
        if (
          updatedCurrComponent.searchTerm &&
          updatedCurrComponent.searchTerm.careplanComponentId
        ) {
          updatedCurrComponent.searchTerm.shouldDescriptionToggle =
            !updatedCurrComponent.searchTerm.shouldDescriptionToggle;
        } else {
          updatedCurrComponent.shouldDescriptionToggle =
            !updatedCurrComponent.shouldDescriptionToggle;
        }
        break;
      case 'handleThresholdToggle':
        if (
          updatedCurrComponent.searchTerm &&
          updatedCurrComponent.searchTerm.careplanComponentId
        ) {
          updatedCurrComponent.searchTerm.shouldThresholdToggle =
            !updatedCurrComponent.searchTerm.shouldThresholdToggle;
        } else {
          updatedCurrComponent.shouldThresholdToggle =
            !updatedCurrComponent.shouldThresholdToggle;
        }
        break;
      case 'handleMediaSettingsToggle':
        if (
          updatedCurrComponent.searchTerm &&
          updatedCurrComponent.searchTerm.careplanComponentId
        ) {
          updatedCurrComponent.searchTerm.shouldMediaSettingsToggle =
            !updatedCurrComponent.searchTerm.shouldMediaSettingsToggle;
        } else {
          updatedCurrComponent.shouldMediaSettingsToggle =
            !updatedCurrComponent.shouldMediaSettingsToggle;
          if (!updatedCurrComponent.searchTerm.shouldMediaSettingsToggle)
            updatedCurrComponent.asset = null;
        }
        break;
      case 'handleWeightToggle':
        const updatedPrompt = { ...showPromptForAdjustScoring };
        if (
          updatedCurrComponent.searchTerm &&
          updatedCurrComponent.searchTerm.careplanComponentId
        ) {
          updatedCurrComponent.searchTerm.shouldWeightToggle =
            !updatedCurrComponent.searchTerm.shouldWeightToggle;

          if (updatedCurrComponent.searchTerm.weight > 0) {
            updatedPrompt.checkUserDeleteAddOrChangeScoreOfComponent = true;
            setShowPromptForAdjustScoring(updatedPrompt);
          }

          if (
            (currComponent.componentTypeId ===
              componentTypeOrder.SINGLE_CHOICE ||
              currComponent.componentTypeId ===
                componentTypeOrder.MULTIPLE_CHOICE ||
              currComponent.componentTypeId === componentTypeOrder.SORTABLE ||
              currComponent.componentTypeId === componentTypeOrder.BMI ||
              currComponent.componentTypeId ===
                componentTypeOrder.BLOOD_PRESSURE) &&
            updatedCurrComponent.searchTerm.optionWeights !== null &&
            updatedCurrComponent.searchTerm.optionWeights.total > 0
          ) {
            updatedPrompt.checkUserDeleteAddOrChangeScoreOfComponent = true;
            setShowPromptForAdjustScoring(updatedPrompt);
          }
        } else {
          updatedCurrComponent.shouldWeightToggle =
            !updatedCurrComponent.shouldWeightToggle;

          if (updatedCurrComponent.weight > 0) {
            updatedPrompt.checkUserDeleteAddOrChangeScoreOfComponent = true;
            setShowPromptForAdjustScoring(updatedPrompt);
          }

          if (
            (currComponent.componentTypeId ===
              componentTypeOrder.SINGLE_CHOICE ||
              currComponent.componentTypeId ===
                componentTypeOrder.MULTIPLE_CHOICE ||
              currComponent.componentTypeId === componentTypeOrder.BMI ||
              currComponent.componentTypeId ===
                componentTypeOrder.BLOOD_PRESSURE) &&
            updatedCurrComponent.choiceCollection.optionWeights !== null &&
            updatedCurrComponent.choiceCollection.optionWeights.total > 0
          ) {
            updatedPrompt.checkUserDeleteAddOrChangeScoreOfComponent = true;
            setShowPromptForAdjustScoring(updatedPrompt);
          }

          if (
            currComponent.componentTypeId === componentTypeOrder.SORTABLE &&
            updatedCurrComponent.sortableCollection.optionWeights !== null &&
            updatedCurrComponent.sortableCollection.optionWeights.total > 0
          ) {
            updatedPrompt.checkUserDeleteAddOrChangeScoreOfComponent = true;
            setShowPromptForAdjustScoring(updatedPrompt);
          }
        }
        break;
      case 'handleIsVerticalSliderToggle':
        if (
          updatedCurrComponent.searchTerm &&
          updatedCurrComponent.searchTerm.careplanComponentId
        ) {
          updatedCurrComponent.searchTerm.isVerticalSlider =
            !updatedCurrComponent.searchTerm.isVerticalSlider;
        } else {
          updatedCurrComponent.isVerticalSlider =
            !updatedCurrComponent.isVerticalSlider;
        }
        break;
      case 'handleIsMandatoryToggle':
        if (
          updatedCurrComponent.searchTerm &&
          updatedCurrComponent.searchTerm.careplanComponentId
        ) {
          updatedCurrComponent.searchTerm.shouldIsMandatoryToggle =
            !updatedCurrComponent.searchTerm.shouldIsMandatoryToggle;
        } else {
          updatedCurrComponent.shouldIsMandatoryToggle =
            !updatedCurrComponent.shouldIsMandatoryToggle;
        }
        break;

      case 'handleAnalyticDataToggle':
        if (
          updatedCurrComponent.searchTerm &&
          updatedCurrComponent.searchTerm.careplanComponentId
        ) {
          updatedCurrComponent.searchTerm.shouldAnalyticsDataToggle =
            !updatedCurrComponent.searchTerm.shouldAnalyticsDataToggle;
        } else {
          updatedCurrComponent.shouldAnalyticsDataToggle =
            !updatedCurrComponent.shouldAnalyticsDataToggle;
        }
        break;
    }
    setComponentAndComponentCollection(updatedCurrComponent);
  };

  const handleToggleButtonChanged = target => {
    const toggleButton = target.id;
    switch (toggleButton) {
      case t('newQuestion_view.description'):
        handleSettingsToggleAndPreFilling('handleDescriptionToggle');
        break;
      case t('threshold.thresholds'):
        handleSettingsToggleAndPreFilling('handleThresholdToggle');
        setIfEditable('thresholds', target.checked || -1);
        break;
      case t('asset_view.attachFile'):
        handleSettingsToggleAndPreFilling('handleMediaSettingsToggle');
        break;
      case t('newQuestion_view.score'):
        handleSettingsToggleAndPreFilling('handleWeightToggle');
        break;
      case t('newQuestion_view.isVerticalSlider'):
        handleSettingsToggleAndPreFilling('handleIsVerticalSliderToggle');
        break;
      case t('newQuestion_view.isMandatory'):
        handleSettingsToggleAndPreFilling('handleIsMandatoryToggle');
        break;
      case t('newQuestion_view.analytics_data'):
        handleSettingsToggleAndPreFilling('handleAnalyticDataToggle');
        break;
    }
  };

  const getComponentSettingsElementValue = useCallback(
    key => {
      let value = false;
      const { component } = currComponent;
      if (component) {
        const { careplanComponentId } = component.searchTerm;
        switch (key) {
          case 'getDescriptionToggle':
            value = careplanComponentId
              ? component.searchTerm.shouldDescriptionToggle
              : component.shouldDescriptionToggle;
            break;
          case 'getThresholdToggle':
            value = careplanComponentId
              ? component.searchTerm.shouldThresholdToggle
              : component.shouldThresholdToggle;
            break;
          case 'getComponentDescription':
            value = careplanComponentId
              ? component.searchTerm.description
              : component.description;
            break;
          case 'getMediaSettingsToggle':
            value = careplanComponentId
              ? component.searchTerm.shouldMediaSettingsToggle
              : component.shouldMediaSettingsToggle;
            break;
          case 'getWeightToggle':
            value = careplanComponentId
              ? component.searchTerm.shouldWeightToggle
              : component.shouldWeightToggle;
            break;
          case 'getComponentWeight':
            value = careplanComponentId
              ? component.searchTerm.weight
              : component.weight;
            break;
          case 'getIsVerticalSliderToggle':
            value = careplanComponentId
              ? component.searchTerm.isVerticalSlider
              : component.isVerticalSlider;
            break;
          case 'getIsMandatoryToggle':
            value = careplanComponentId
              ? !!component.searchTerm.shouldIsMandatoryToggle
              : !!component.shouldIsMandatoryToggle;
            break;
          case 'analyticsData':
            value = careplanComponentId
              ? component.searchTerm?.shouldAnalyticsDataToggle
              : component.shouldAnalyticsDataToggle;
            break;
        }
      }
      return value;
    },
    [currComponent]
  );

  const manipulateDescriptionToggleButton = labelText => {
    return (
      <ToggleButton
        id={labelText}
        checked={getComponentSettingsElementValue('getDescriptionToggle')}
        handleOnChange={e => handleToggleButtonChanged(e.target)}
      />
    );
  };

  const renderComponentDescription = () => {
    const { component, index } = currComponent;
    return (
      <Flex className="toggleAndTextContainer">
        <Box
          className="rich-input-editor"
          key={`description-${component.id}`}
          style={{ width: '100%', maxWidth: '335px' }}
        >
          <WYSIWYGEditor
            maxChars={MaxCharLimits.carePlan.careplanDescMaxCharLimit}
            onChange={value => {
              onChangeRichInput(
                'description',
                value,
                index,
                setComponentDescription,
                setComponentAndComponentCollection,
                component
              );
            }}
            placeholder={t('newQuestion_view.addDescription')}
            value={componentDescription}
            hasLink
          />
        </Box>
      </Flex>
    );
  };

  const renderComponentName = () => {
    const { component, index } = currComponent;
    return (
      <Flex className="toggleAndTextContainer">
        <Box className="rich-input-editor" key={`name-${component.id}`}>
          <WYSIWYGEditor
            maxChars={MaxCharLimits.carePlan.componentTitleMaxCharLimit}
            onChange={value => {
              onChangeRichInput(
                'title',
                value,
                index,
                setComponentName,
                setComponentAndComponentCollection,
                component
              );
            }}
            value={componentName}
          />
        </Box>
      </Flex>
    );
  };

  const manipulateThresholdToggleButton = labelText => {
    return (
      <ToggleButton
        id={labelText}
        checked={getComponentSettingsElementValue('getThresholdToggle')}
        handleOnChange={e => handleToggleButtonChanged(e.target)}
      />
    );
  };

  const manipulateIsMandatoryToggleButton = labelText => {
    return (
      <ToggleButton
        id={labelText}
        checked={getComponentSettingsElementValue('getIsMandatoryToggle')}
        handleOnChange={e => handleToggleButtonChanged(e.target)}
      />
    );
  };
  const manipulateAnalyticDataToggleButton = labelText => {
    return (
      <ToggleButton
        id={labelText}
        isDisabled={hasAnalyticsDataCreateOrUpdatePermission !== true}
        checked={getComponentSettingsElementValue('analyticsData')}
        handleOnChange={e => handleToggleButtonChanged(e.target)}
      />
    );
  };

  const manipulateMediaToggleButton = labelText => {
    return (
      <ToggleButton
        id={labelText}
        checked={getComponentSettingsElementValue('getMediaSettingsToggle')}
        handleOnChange={e => handleToggleButtonChanged(e.target)}
      />
    );
  };

  const manipulateIsVerticalSliderToggleButton = labelText => {
    return (
      <ToggleButton
        id={labelText}
        checked={getComponentSettingsElementValue('getIsVerticalSliderToggle')}
        handleOnChange={e => handleToggleButtonChanged(e.target)}
      />
    );
  };

  const manipulateWeightToggleButton = (labelText: string, icon: string) => {
    let isDisabled = false;
    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = ({
      target,
    }) => handleToggleButtonChanged(target);
    const checked = getComponentSettingsElementValue('getWeightToggle');
    if (isMainComponentWithNegativeValues) {
      isDisabled = true;
      if (checked)
        handleToggleButtonChanged({ id: t('newQuestion_view.score') });
    }
    return (
      <>
        {icon && (
          <div>
            <ToggleButton
              isDisabled={isDisabled}
              id={labelText}
              checked={checked}
              handleOnChange={handleOnChange}
            />
          </div>
        )}
        {!icon && (
          <div style={{ width: '100px' }}>
            <NumberInput
              value={getComponentSettingsElementValue('getComponentWeight')}
              name="componentWeight"
              min={defaultEnums.COMPONENT_WEIGHT_MIN_VALUE}
              max={defaultEnums.COMPONENT_WEIGHT_MAX_VALUE}
              step={1}
              placeholder="0"
              changeValue={e => handleComponentWeightOnChange(e)}
              isNegativeNumber
            />
          </div>
        )}
      </>
    );
  };

  const manipulateJumpToggleButton = useCallback(() => {
    const { component } = currComponent;
    const setLogicJumpToggle = isToggleOn => {
      const updatedCurrComponent = { ...component };
      updatedCurrComponent.shouldLogicJumpToggle = isToggleOn;
      setComponentAndComponentCollection(updatedCurrComponent);
    };
    return (
      <LogicJumpToggle
        key={`logic-jump-toggle-${component.id}`}
        currComponent={component}
        setLogicJumpToggle={setLogicJumpToggle}
        setIsEditable={setIfEditable}
      />
    );
  }, [setIfEditable, currComponent]);

  const renderLabelWithToggleButton = buttonObj => {
    const { type, labelText } = buttonObj;
    const color =
      editableComponent[type] === true
        ? PrimaryColor.MAIN_COLOR
        : Greys.LIGHT_GREY;
    const borderLeft =
      editableComponent[type] === true ? `solid 6px ${color}` : null;
    return (
      <>
        {(type === 'weight' || type === 'weightInput') &&
          !buttonObj.icon &&
          !isCareplanEvaluatingToggle && (
            <div
              className="error-container"
              style={{
                padding: 'inherit',
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
            >
              <span className="templateRequired">
                {t('warningMessage.careplanScoreWarning')}
              </span>
            </div>
          )}
        <Flex className="toggleAndTextContainer" otherStyles={{ borderLeft }}>
          <div
            role="button"
            onClick={() => {
              if (editableComponent[type] > -1) setIfEditable(type, true);
            }}
            style={{ flex: 1 }}
          >
            <Label text={labelText} textColor={color} />
          </div>
          <Flex flexDirection="row" alignItems="center">
            <div>
              {type === 'description' &&
                manipulateDescriptionToggleButton(labelText)}
              {type === 'media' && manipulateMediaToggleButton(labelText)}
              {type === 'thresholds' &&
                manipulateThresholdToggleButton(labelText)}
              {type === 'jump' && manipulateJumpToggleButton(labelText)}
              {(type === 'weight' || type === 'weightInput') &&
                manipulateWeightToggleButton(labelText, buttonObj.icon)}
              {type === 'isVerticalSlider' &&
                manipulateIsVerticalSliderToggleButton(labelText)}
              {type === 'isMandatory' &&
                manipulateIsMandatoryToggleButton(labelText)}
              {type === 'analytics_data' &&
                manipulateAnalyticDataToggleButton(labelText)}
            </div>
          </Flex>
        </Flex>
      </>
    );
  };

  const renderMediaSettings = () => {
    return (
      <MediaSettingsComponent
        componentCollection={componentCollection}
        setComponentCollection={setComponentCollection}
        searchTermComponents={currComponent.component}
        validationErrorTemplate={validationErrorTemplate}
        hasValidationErrorTemplate={hasValidationErrorTemplate}
        setFileReviewToggle={setFileReviewToggle}
      />
    );
  };
  const renderAnalyticsSetting = () => {
    return (
      <AnalyticsDataSettings
        setComponentCollection={setComponentCollection}
        componentCollection={componentCollection}
        currComponent={currComponent.component}
        hasAnalyticsDataCreateOrUpdatePermission={
          hasAnalyticsDataCreateOrUpdatePermission
        }
      />
    );
  };
  const renderComponentOptionWeights = () => {
    return (
      <>
        {!isCareplanEvaluatingToggle && (
          <div
            className="error-container"
            style={{ padding: 'inherit', paddingTop: '0px' }}
          >
            <span className="templateRequired">
              {t('warningMessage.careplanScoreWarning')}
            </span>
          </div>
        )}
        <OptionWeightsComponent
          searchTermComponents={currComponent.component}
          componentCollection={componentCollection}
          setComponentCollection={setComponentCollection}
          componentTypeId={currComponent.componentTypeId}
          setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
          showPromptForAdjustScoring={showPromptForAdjustScoring}
        />
      </>
    );
  };

  const renderNumericValueComponent = () => {
    return (
      <NumericValueComponent
        isNegativeWarningVisible={isMainComponentWithNegativeValues}
        searchTermComponents={currComponent.component}
        componentCollection={componentCollection}
        setComponentCollection={setComponentCollection}
        validationErrorTemplate={validationErrorTemplate}
        hasValidationErrorTemplate={hasValidationErrorTemplate}
        setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
      />
    );
  };

  const renderNumericRangeComponent = () => {
    return (
      <NumericRangeComponent
        isNegativeWarningVisible={isMainComponentWithNegativeValues}
        searchTermComponents={currComponent.component}
        componentCollection={componentCollection}
        setComponentCollection={setComponentCollection}
        validationErrorTemplate={validationErrorTemplate}
        hasValidationErrorTemplate={hasValidationErrorTemplate}
        setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
      />
    );
  };

  const checkIfComponentHaveOptions = componentTypeId => {
    const { component } = currComponent;
    if (component) {
      const searchTermOptions = component.searchTerm.optionWeights;
      const newlyCreatedOptions =
        componentTypeId === componentTypes.SORTABLE
          ? component.sortableCollection &&
            component.sortableCollection.sortables
          : component.choiceCollection && component.choiceCollection.choices;
      return searchTermOptions || newlyCreatedOptions;
    }
    return false;
  };

  const handleEvaluationLineChange = updatedEvaluationLine => {
    const { component, index } = currComponent;
    if (
      component &&
      component.searchTerm &&
      component.searchTerm.careplanComponentId
    ) {
      component.searchTerm.additional_configuration.evaluationLine = Number(
        updatedEvaluationLine
      );
    } else {
      component.additional_configuration.evaluationLine = Number(
        updatedEvaluationLine
      );
    }
    const options = getNewlyCreatedComponentOptionsAndWeightsForSortables(
      componentCollection,
      searchTermComponents
    );
    const temp = validationErrorTemplate;
    let filteredElement;
    if (updatedEvaluationLine <= options.length) {
      if (temp.templateSettingsValidation.noComponentsError.length > 0) {
        filteredElement =
          temp.templateSettingsValidation.evaluationLineErrorForSortable.find(
            elem => {
              return elem.componentId === index;
            }
          );
        if (filteredElement) {
          filteredElement.evaluationLineError = false;
        }
      }
    } else {
      filteredElement =
        temp.templateSettingsValidation.evaluationLineErrorForSortable.find(
          elem => {
            return elem.componentId === index;
          }
        );
      if (filteredElement) {
        filteredElement.evaluationLineError = true;
      } else {
        temp.templateSettingsValidation.evaluationLineErrorForSortable.push({
          componentId: index,
          evaluationLineError: true,
        });
      }
    }
    hasValidationErrorTemplate({ ...temp });
  };

  const getEvaluationLineValue = () => {
    const { component } = currComponent;
    let value;
    if (
      component &&
      component.searchTerm &&
      component.searchTerm.careplanComponentId
    ) {
      value = component.searchTerm.additional_configuration.evaluationLine;
    } else {
      value = component.additional_configuration.evaluationLine;
    }
    return value || defaultEnums.EVALUATION_LINE;
  };

  const componentSettingsContainer = useMemo(() => {
    let optionWeights;
    let options;
    const { componentTypeId, component } = currComponent;
    if (
      componentTypeId === componentTypes.SINGLE_CHOICE ||
      componentTypeId === componentTypes.MULTIPLE_CHOICE ||
      componentTypeId === componentTypes.SORTABLE ||
      componentTypeId === componentTypes.BMI ||
      componentTypeId === componentTypes.BLOOD_PRESSURE
    ) {
      optionWeights = checkIfComponentHaveOptions(componentTypeId);
    }
    if (componentTypeId === componentTypes.SORTABLE) {
      options = getNewlyCreatedComponentOptionsAndWeightsForSortables(
        componentCollection,
        component
      );
    }
    const weightToggle =
      component.shouldWeightToggle || component.searchTerm.shouldWeightToggle;
    return (
      <div className="componentSettingsContainer">
        <div className="close-btn-container-template">
          <Tooltip position={Position.left} content={t('toolTipsText.close')}>
            <IconButton
              onClick={() => setSettings(false)}
              name="close"
              size={24}
            />
          </Tooltip>
        </div>
        <div className="componentSettingsHeader">
          <div className="settingsIconText">
            <Label text={t('common_labels.label_careplantemplate_settings')} />
          </div>
        </div>
        <div className="componentTypeSettingsText">
          <Label text={t('newQuestion_view.componentTitle')} />
        </div>
        {renderComponentName()}
        {componentTypeId === componentTypes.NUMERIC_RANGE &&
          renderNumericRangeComponent()}
        {componentTypeId === componentTypes.NUMERIC_VALUE &&
          renderNumericValueComponent()}
        {component.searchTerm && (
          <span className="lineBetweenToggleContainers" />
        )}
        <span className="lineBetweenToggleContainers" />
        {renderLabelWithToggleButton({
          labelText: t('newQuestion_view.description'),
          type: 'description',
        })}
        {getComponentSettingsElementValue('getDescriptionToggle') &&
          renderComponentDescription()}
        <span className="lineBetweenToggleContainers" />
        {renderLabelWithToggleButton({
          labelText: t('asset_view.attachFile'),
          type: 'media',
        })}
        {getComponentSettingsElementValue('getMediaSettingsToggle') &&
          renderMediaSettings()}
        {(nonMedical == false || nonMedical == 'false') &&
          componentTypeId !== componentTypes.FREE_TEXT &&
          componentTypeId !== componentTypes.SORTABLE && (
            <span className="lineBetweenToggleContainers" />
          )}
        {(nonMedical == false || nonMedical == 'false') &&
          componentTypeId !== componentTypes.FREE_TEXT &&
          componentTypeId !== componentTypes.SORTABLE &&
          componentTypeId !== componentTypes.UPLOAD_FILE &&
          componentTypeId !== componentTypes.TO_DO &&
          renderLabelWithToggleButton({
            labelText: t('threshold.thresholds'),
            type: 'thresholds',
          })}
        {componentTypeId !== componentTypeOrder.DATEPICKER &&
          componentTypeId !== componentTypeOrder.UPLOAD_FILE && (
            <span className="lineBetweenToggleContainers" />
          )}
        {(componentTypeId === componentTypeOrder.SINGLE_CHOICE ||
          componentTypeId === componentTypeOrder.NUMERIC_VALUE ||
          componentTypeId === componentTypeOrder.NUMERIC_RANGE ||
          componentTypeId === componentTypeOrder.BLOOD_PRESSURE ||
          componentTypeId === componentTypeOrder.INFORMATION) &&
          isMainQuestion(component.healthCategory) && (
            <>
              {renderLabelWithToggleButton({
                labelText: t('newQuestion_view.logicalJump'),
                type: 'jump',
              })}
              <span className="lineBetweenToggleContainers" />
            </>
          )}
        {(componentTypeId == componentTypeOrder.MULTIPLE_CHOICE ||
          componentTypeId == componentTypeOrder.SORTABLE) && (
          <span className="lineBetweenToggleContainers" />
        )}
        {componentTypeId === componentTypes.NUMERIC_RANGE && (
          <>
            {renderLabelWithToggleButton({
              labelText: t('newQuestion_view.isVerticalSlider'),
              type: 'isVerticalSlider',
              icon: 'info',
            })}
            <span className="lineBetweenToggleContainers" />
          </>
        )}

        {componentTypeId !== componentTypes.UPLOAD_FILE &&
          componentTypeId !== componentTypes.DATEPICKER &&
          componentTypeId != componentTypes.TO_DO &&
          isMainQuestion(component.healthCategory) && (
            <Flex flexDirection="column">
              {renderLabelWithToggleButton({
                labelText: t('newQuestion_view.score'),
                type: 'weight',
                icon: 'info',
              })}
              {containsNegatives && <ComponentSettingsWarning />}
            </Flex>
          )}
        {(componentTypeId === componentTypes.NUMERIC_RANGE ||
          componentTypeId === componentTypes.NUMERIC_VALUE) &&
          !isCareplanEvaluatingToggle &&
          weightToggle && (
            <div
              className="error-container"
              style={{
                padding: 'inherit',
                paddingTop: '0px',
              }}
            >
              <span className="templateRequired">
                {t('warningMessage.careplanScoreWarning')}
              </span>
            </div>
          )}

        {getComponentSettingsElementValue('getWeightToggle') &&
          componentTypeId !== componentTypeOrder.SINGLE_CHOICE &&
          componentTypeId !== componentTypeOrder.MULTIPLE_CHOICE &&
          componentTypeId !== componentTypeOrder.SORTABLE &&
          componentTypeId !== componentTypeOrder.BMI &&
          componentTypeId !== componentTypeOrder.BLOOD_PRESSURE &&
          componentTypeId !== componentTypes.NUMERIC_RANGE &&
          componentTypeId !== componentTypes.NUMERIC_VALUE &&
          renderLabelWithToggleButton({
            labelText: t('newQuestion_view.component_score'),
            type: 'weightInput',
          })}
        {getComponentSettingsElementValue('getWeightToggle') &&
          optionWeights &&
          (componentTypeId === componentTypes.SINGLE_CHOICE ||
            componentTypeId === componentTypes.MULTIPLE_CHOICE ||
            componentTypeId === componentTypes.SORTABLE ||
            componentTypeId === componentTypes.BMI ||
            componentTypeId === componentTypes.BLOOD_PRESSURE) &&
          renderComponentOptionWeights()}
        {getComponentSettingsElementValue('getWeightToggle') &&
          componentTypeId === componentTypes.SORTABLE &&
          optionWeights &&
          options.length >= 2 && (
            <div className="weightAndLabelContainer">
              <div>
                <Label text={t('newQuestion_view.evaluationLine')} />
              </div>
              <div style={{ width: '100px' }}>
                <NumberInput
                  value={getEvaluationLineValue()}
                  name="Name"
                  min={defaultEnums.EVALUATION_LINE_MIN_VALUE}
                  max={defaultEnums.EVALUATION_LINE_MAX_VALUE}
                  step={1}
                  placeholder=""
                  changeValue={e => handleEvaluationLineChange(e)}
                />
              </div>
            </div>
          )}

        {componentTypeId !== componentTypeOrder.INFORMATION &&
          componentTypeId !== componentTypeOrder.GROUP_DESCRIPTION && (
            <>
              <span className="lineBetweenToggleContainers" />
              {renderLabelWithToggleButton({
                labelText: t('newQuestion_view.isMandatory'),
                type: 'isMandatory',
              })}
            </>
          )}
        {componentTypeId !== componentTypeOrder.INFORMATION &&
          componentTypeId !== componentTypeOrder.GROUP_DESCRIPTION &&
          componentTypeId !== componentTypeOrder.UPLOAD_FILE &&
          componentTypeId !== componentTypeOrder.BMI &&
          componentTypeId !== componentTypeOrder.BLOOD_PRESSURE &&
          componentTypeId !== componentTypeOrder.SORTABLE &&
          renderLabelWithToggleButton({
            labelText: t('newQuestion_view.analytics_data'),
            type: 'analytics_data',
          })}
        {getComponentSettingsElementValue('analyticsData') &&
          renderAnalyticsSetting()}
      </div>
    );
  }, [
    currComponent.component,
    currComponent.index,
    editableComponent,
    renderLabelWithToggleButton,
  ]);

  useEffect(() => {
    const { component } = currComponent;
    if (component) {
      setEditableComponent(initialEditableComponent);
    }
  }, [currComponent.index, isEditable, componentCollection, currComponent]);

  useEffect(() => {
    const getComponentIndex = () => {
      return componentCollection.findIndex(
        component => component.id === searchTermComponents.id
      );
    };
    if (searchTermComponents && searchTermComponents.id > -1) {
      const index = getComponentIndex();
      if (index > -1) {
        setCurrComponent({
          component: searchTermComponents,
          index,
          componentTypeId: searchTermComponents.collection.key,
        });
        setComponentDescription(
          searchTermComponents.searchTerm.description || ''
        );
        setComponentName(
          searchTermComponents.searchTerm.nameHtml
            ? searchTermComponents.searchTerm.nameHtml
            : searchTermComponents.nameHtml
            ? searchTermComponents.nameHtml
            : ''
        );
      }
    }
  }, [searchTermComponents, searchTermComponents.id]);

  return shouldShowSettings &&
    currComponent.component &&
    currComponent.component.collection &&
    componentCollection.length > 0
    ? componentSettingsContainer
    : '';
};

export default ComponentSettings;
