/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import I18n from 'i18next';
import { useSelector } from 'react-redux';

import IconButton from '../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import ToggleButton from '../../../../caro-ui-commonfiles/components/ToggleButton/ToggleButton';
import serviceConfig from '../../../../serviceConfig.json';
import IconButtonDropdown from '../../../../caro-ui-commonfiles/components/DropdownMenu/IconButtonDropdown';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Animation from '../CellElements/Animationcomponent';

import useWindowWidthListener from '../../../Utils/hooks/useWindowWidthListener';
import { convertLinkToBlob } from '../../../Utils/Asset/assetHelper';
import userRoles from '../../../Utils/roles.json';

import '../../../css/sites.css';
import styles from './Sites.module.scss';
import {
  activateSiteRequest,
  deactivateSiteRequest,
  editSiteRequest,
  getSite,
} from './Sites.api';

const useSites = props => {
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    value: '',
    start: 0,
    length: 10,
    dir: 'asc',
    column: 'id',
  });
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'id',
  });
  const [pageState, setPageState] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [name, setName] = useState('');
  const [logo, setLogo] = useState(null);
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [serviceProviderId, setServiceProviderId] = useState('');
  const [collectionId, setCollectionId] = useState(-1);
  const [displayModal, setDisplayModal] = useState(false);
  const [typingTimeOut, setTypingTimeOut] = useState(0);
  const [privacyPolicyFile, setPrivacyPolicyFile] = useState(null);
  const responsive = useWindowWidthListener();
  const [privacyPolicyData, setPrivacyPolicyData] = useState({
    additionalConsents: [],
  });

  const { user } = useSelector(state => state.session);

  const openEditModal = (
    name,
    address,
    email,
    phone,
    id,
    logo,
    collectionId,
    privacyPolicy,
    serviceProviderId
  ) => {
    const addressObj = { value: address, label: address };
    setTitle(name);
    setName(name);
    setEmail(email);
    setAddress(addressObj);
    setEmail(email);
    setServiceProviderId(serviceProviderId);
    setPhone(phone);
    setId(id);
    setLogo(logo);
    setCollectionId(collectionId);
    if (privacyPolicy) {
      convertLinkToBlob(privacyPolicy.directLink).then(blobUrl => {
        setLoading(false);
        setDisplayModal(true);
        setPrivacyPolicyData({
          url: blobUrl,
          name: privacyPolicy.name,
          size: `(${parseFloat(privacyPolicy.size)}MB)`,
          additionalConsents: privacyPolicy.additionalConsents
            ? JSON.parse(privacyPolicy.additionalConsents)
            : [],
        });
      });
    } else {
      setLoading(false);
      setDisplayModal(true);
    }
  };

  const closeModal = () => {
    setDisplayModal(false);
    setPrivacyPolicyData({});
    setPrivacyPolicyFile(null);
  };

  const onChangeNameText = event => {
    setName(event.target.value);
  };

  const onChangeEmailText = event => {
    setEmail(event.target.value);
  };

  const onChangeAddressText = event => {
    setAddress(event);
  };

  const onChangeServiceProviderIdText = event => {
    setServiceProviderId(event.target.value);
  };

  const onChangePhoneText = value => {
    setPhone(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let urlToGetSite = serviceConfig.brokerService.sites.uri;
      if (!props.sitePermission)
        urlToGetSite = serviceConfig.brokerService.getSiteByUserId.uri;

      const response = await getSite(urlToGetSite, query);

      if (response) {
        setFetchedData(await defineRowsData(response.data.data));
        setRecordsTotal(response.data.recordsTotal);
      }
    } catch (error) {
      props.showFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (typingTimeOut > 0) clearTimeout(typingTimeOut);
    if (query.value.length === 0) {
      fetchData();
    } else if (query.value.length > 1) {
      const timeOut = setTimeout(() => {
        fetchData();
      }, 500);
      setTypingTimeOut(timeOut);
    }
    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [query, props.reloadTable]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];

  const tableProps = {
    defaultSorted: [
      {
        id: 'record',
        desc: false,
      },
    ],
    onSortedChange: (newSorted, column, shiftKey) => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
  };

  const defineRowsData = async fetchedData => {
    const rows = [];
    if (fetchedData) {
      for (let index = 0; index < fetchedData.length; index++) {
        if (fetchedData[index].name != null) {
          let profiPic;
          if (fetchedData[index].logo.directLink) {
            await convertLinkToBlob(fetchedData[index].logo.directLink)
              .then(blob => {
                profiPic = blob;
              })
              .catch(() => {
                profiPic = fetchedData[index].logo.directLink;
              });
          } else {
            profiPic = `data:image;base64,${fetchedData[index].logo}`;
          }
          rows.push({
            logo: (
              <Animation isOn delay={index * 50}>
                <Box width="40px" height="40px">
                  <div
                    style={{
                      backgroundImage: `url("${profiPic}")`,
                    }}
                    className="notification__table-profile-pic"
                  />
                </Box>
              </Animation>
            ),
            profiPic,
            name: fetchedData[index].name,
            phone: fetchedData[index].contactNumber,
            email: fetchedData[index].email,
            address: fetchedData[index].place,
            id: fetchedData[index].id,
            isActive: fetchedData[index].isActive,
            collectionId: fetchedData[index].collectionId,
            privacyPolicy:
              fetchedData[index].assets[fetchedData[index].assets.length - 1],
            serviceProviderId: fetchedData[index].serviceProviderId || '',
          });
        }
      }
    }
    return rows;
  };

  const activateRow = useCallback(async id => {
    try {
      const response = await activateSiteRequest(id);

      if (response) {
        props.showFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
      }
      return response;
    } catch (error) {
      props.showFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      return error.response;
    }
  }, []);

  const deactivateRow = useCallback(async id => {
    try {
      const response = await deactivateSiteRequest(id);

      if (response) {
        props.showFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
      }
      return response;
    } catch (error) {
      props.showFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      return error.response;
    }
  }, []);

  const handleOnCheckToggle = useCallback(
    (id, isChecked) => {
      const defineIsSiteActive = (id, shouldBeActive) => {
        const newAllSites = [...fetchedData];
        const foundActiveSite = newAllSites.find(dep => dep.id === id);
        foundActiveSite.isActive = shouldBeActive;
        setFetchedData(newAllSites);
      };
      if (isChecked) {
        defineIsSiteActive(id, true);
        activateRow(id).then(res => {
          if (res.status == 500 || res.status == 403) {
            defineIsSiteActive(id, false);
          }
        });
      } else {
        defineIsSiteActive(id, false);
        deactivateRow(id).then(res => {
          if (res.status == 500 || res.status == 403) {
            defineIsSiteActive(id, true);
          }
        });
      }
    },
    [activateRow, deactivateRow, fetchedData]
  );

  useEffect(() => {
    setQuery({
      start: currentPage * pageState,
      length: pageState,
      dir: sort.dir,
      column: sort.column,
      value: query.value,
    });
  }, [currentPage, pageState, sort, query.value]);

  const columns = useMemo(() => {
    const checkEditSiteCredential = async id => {
      const response = await editSiteRequest(id);
      if (response) return response;
    };

    return [
      {
        Header: '',
        accessor: 'logo',
        sortable: false,
        filterable: false,
        width: 50,
      },
      {
        Header: props => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{I18n.t('site_view.label_siteName')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'name',
        filterable: false,
      },
      {
        Header: () => {
          return <span>{I18n.t('site_view.label_phone')}</span>;
        },
        accessor: 'phone',
        sortable: false,
        filterable: false,
      },
      {
        Header: props => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{I18n.t('site_view.label_email')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'email',
        filterable: false,
      },
      {
        Header: () => {
          return <span>{I18n.t('site_view.label_address')}</span>;
        },
        accessor: 'address',
        sortable: false,
        filterable: false,
      },
      {
        Header: '',
        accessor: 'buttons',
        sortable: false,
        filterable: false,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'space-around',
        },
        width: 150,
        Cell: props => {
          const { isActive } = props.original;
          const [active, setActive] = useState(isActive);
          const { id } = props.original;

          return (
            <>
              {responsive ? (
                <div className={styles.moreIconStyle}>
                  <IconButtonDropdown
                    items={
                      props.original.id != 1
                        ? [
                            { value: I18n.t('common_labels.label_edit') },
                            {
                              value: active
                                ? I18n.t('common_labels.deactivateBtn')
                                : I18n.t('common_labels.activateBtn'),
                            },
                          ]
                        : [{ value: I18n.t('common_labels.label_edit') }]
                    }
                    onClick={(e, action) => {
                      if (action.value == I18n.t('common_labels.label_edit')) {
                        setLoading(true);
                        checkEditSiteCredential(id).then(res => {
                          if (res) {
                            openEditModal(
                              props.original.name,
                              props.original.address,
                              props.original.email,
                              props.original.phone,
                              id,
                              props.original.profiPic,
                              props.original.collectionId,
                              props.original.privacyPolicy,
                              props.original.serviceProviderId,
                            );
                          } else {
                            setLoading(false);
                          }
                        });
                      } else {
                        setActive(!active);
                        handleOnCheckToggle(id, !isActive);
                      }
                    }}
                  />
                </div>
              ) : (
                <>
                  <IconButton
                    name="edit"
                    size={30}
                    onClick={() => {
                      setLoading(true);
                      checkEditSiteCredential(id).then(res => {
                        if (res) {
                          openEditModal(
                            props.original.name,
                            props.original.address,
                            props.original.email,
                            props.original.phone,
                            id,
                            props.original.profiPic,
                            props.original.collectionId,
                            props.original.privacyPolicy,
                            props.original.serviceProviderId,
                          );
                        } else {
                          setLoading(false);
                        }
                      });
                    }}
                    tooltipText={I18n.t('common_labels.label_edit')}
                  />

                  <ToggleButton
                    checked={props.original.isActive}
                    handleOnChange={e =>
                      handleOnCheckToggle(props.original.id, e.target.checked)
                    }
                    isDisabled={props.original.id == 1}
                  />
                </>
              )}
            </>
          );
        },
      },
    ];
  }, [handleOnCheckToggle, responsive, sort.column, sort.dir]);

  return {
    fetchedData,
    setFetchedData,
    loading,
    setLoading,
    query,
    setQuery,
    sort,
    setSort,
    pageState,
    setPageState,
    currentPage,
    setCurrentPage,
    recordsTotal,
    setRecordsTotal,
    name,
    setName,
    logo,
    setLogo,
    id,
    setId,
    title,
    setTitle,
    address,
    setAddress,
    email,
    setEmail,
    onChangeServiceProviderIdText,
    serviceProviderId,
    phone,
    setPhone,
    collectionId,
    setCollectionId,
    displayModal,
    setDisplayModal,
    typingTimeOut,
    setTypingTimeOut,
    privacyPolicyFile,
    setPrivacyPolicyFile,
    privacyPolicyData,
    setPrivacyPolicyData,
    responsive,
    user,
    openEditModal,
    closeModal,
    onChangeNameText,
    onChangeEmailText,
    onChangeAddressText,
    onChangePhoneText,
    fetchData,
    items,
    tableProps,
    defineRowsData,
    deactivateRow,
    handleOnCheckToggle,
    columns,
  };
};

export default useSites;
