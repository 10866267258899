import React, { useCallback } from 'react';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import AnswerHeaderComponent from '../AnswerHeaderComponent';

import { GeneralAnswerType } from '../GeneralAnswer.type';
import { CareplanComponentAnswer } from '../../../../types/Careplan';
import { renderComponentOptions } from '../SingleChoiceAnswer/SingleChoiceAnswer.service';
import {
  getComponentOccurrencesAnswer,
  occurrenceHasAnswer,
} from '../AnswerResult.services';

import '../AnswerResultCommon.scss';

function RankingAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const renderComponentAnswers = useCallback(
    (occurrenceAnswers: CareplanComponentAnswer[], index: number) => {
      const answerContent = occurrenceHasAnswer(occurrenceAnswers) ? '' : '-';
      return (
        <Flex
          key={`col-header-${componentIndex}-${index}`}
          className="answer-component_header-columns-column"
          alignItems="center"
          justifyContent="center"
        >
          <span>{answerContent}</span>
        </Flex>
      );
    },
    []
  );

  return (
    <Box className="answer-component">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName="Ranking"
        title={componentData.name}
        columnOccurrencesRenderer={() => {
          return getComponentOccurrencesAnswer(
            allOccurrences,
            componentData.answers,
            renderComponentAnswers
          );
        }}
      />
      <Box className="answer-component_options">
        {renderComponentOptions(allOccurrences, componentData, componentIndex)}
      </Box>
    </Box>
  );
}
export default RankingAnswer;