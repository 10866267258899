/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import Pagination from './Pagination';
import Flex from '../Flex/Flex';
import 'react-table/react-table.css';
import '../../styles/main.scss';
import Loader from 'react-loader-spinner';
import { PrimaryColor } from '../../utils/colors';

const DataTable = ({
  pagination,
  pageSize,
  pageSizeOptions,
  pageSizeActiveOption,
  tableProps,
  isLoading,
  data,
  columns,
  onTableChange,
  page,
  pages,
  totalRecord,
  TheadComponent,
  customClassName = '',
}) => {
  const [tableSize, setTableSize] = useState(
    pageSize ? parseInt(pageSizeOptions[pageSizeActiveOption || 0].value) : 10
  );
  const [pageIndex, setPageIndex] = useState(page);
  const customProps = {
    totalrecord: totalRecord,
    pageSizeOptions,
    setTableSize,
    active: pageSizeActiveOption,
  };
  useEffect(() => {
    onTableChange(pageIndex, tableSize);
  }, [pageIndex, tableSize]);

  useEffect(() => {
    setPageIndex(page);
  }, [page]);

  columns.map((column, index) => {
    if (
      index !== columns.length - 1 &&
      column.accessor !== 'site' &&
      column.accessor !== 'buttons'
    ) {
      column.style = {
        ...column.style,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      };
    } else if (column.accessor == 'alias') {
      column.style = {
        ...column.style,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
      };
    }
  });

  const paginate = () => {
    return pagination
      ? {
          PaginationComponent: Pagination,
          showPaginationBottom: true,
        }
      : { showPaginationBottom: false };
  };

  const loader = () => {
    return (
      <div className="-loading -active">
        <div className="data-table__loader">
          <Flex
            justifyContent="center"
            alignItems="center"
            otherStyles={{
              top: '50%',
              left: '50%',
              position: 'fixed',
            }}
          >
            <Loader
              type="Oval"
              color={PrimaryColor.MAIN_COLOR}
              height={60}
              width={60}
            />
          </Flex>
        </div>
      </div>
    );
  };

  const onLoad = () => {
    return isLoading
      ? {
          LoadingComponent: loader,
          loading: true,
        }
      : {
          loading: false,
        };
  };

  return (
    <ReactTable
      className={`data-tables ${customClassName}`}
      pageSize={tableSize}
      onPageChange={index => {
        setPageIndex(index);
      }}
      page={page}
      pages={pages}
      manual
      minRows={0}
      getProps={() => customProps}
      getPaginationProps={() => customProps}
      resizable={false}
      {...paginate()}
      {...onLoad()}
      data={data}
      columns={columns}
      {...tableProps}
      TheadComponent={TheadComponent}
    />
  );
};

DataTable.propTypes = {
  pagination: PropTypes.bool,
  pageSize: PropTypes.bool,
  isLoading: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
  tableProps: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number,
  pages: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  totalRecord: PropTypes.number,
};

DataTable.defaultProps = {
  pageSizeActiveOption: 0,
};

export default DataTable;
