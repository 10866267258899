import patientTableActions from '../../src/Utils/ReduxUtilities/Actions/Datatables/patients.json';

const initialState = {
  currPage: 0,
  start: 0,
  limit: 10,
  dir: 'asc',
  column: 'healthIndicatorId',
  search: '',
  sqlQuery: null,
  customFilter: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case patientTableActions.ON_TABLE_CHANGE: {
      const nextState = {
        ...state,
        start: action.payload.page * action.payload.pageSize,
        limit: action.payload.pageSize,
        dir: action.payload.dir,
        column: action.payload.column,
        currPage: action.payload.page,
      };
      if (JSON.stringify(state) !== JSON.stringify(nextState)) {
        return nextState;
      }
      return state;
    }

    case patientTableActions.ON_FILTER_CHANGE: {
      const nextState = {
        ...state,
        sqlQuery: action.payload,
        customFilter: null,
      };
      if (JSON.stringify(state) !== JSON.stringify(nextState)) {
        return nextState;
      }
      return state;
    }

    case patientTableActions.ON_SEARCH: {
      const nextState = {
        ...state,
        start: 0,
        currPage: 0,
        search: action.payload,
      };
      if (JSON.stringify(state) !== JSON.stringify(nextState)) {
        return nextState;
      }
      return state;
    }

    case patientTableActions.ON_CUSTOM_FILTER_CHANGE: {
      const nextState = {
        ...state,
        start: 0,
        currPage: 0,
        customFilter: action.payload,
        sqlQuery:
          !action.payload || Object.keys(action.payload).length === 0
            ? {
                params: [],
                sql: 'patients.firstname IS NOT NULL',
              }
            : null,
      };
      if (JSON.stringify(state) !== JSON.stringify(nextState)) {
        return nextState;
      }
      return state;
    }

    case patientTableActions.RESET_PATIENT_TABLE: {
      return {
        currPage: 0,
        start: 0,
        limit: 10,
        dir: 'asc',
        column: 'healthIndicatorId',
        search: '',
        sqlQuery: null,
        customFilter: null,
      };
    }

    case patientTableActions.RESET_PAGINATION_PATIENT_TABLE: {
      return { ...state, currPage: 0, start: 0 };
    }

    default:
      return state;
  }
};

export default reducer;
