/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import axios from 'axios';

import I18n from 'i18next';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import MedicationModalContentForm from './MedicationModalContentForm';
import MedicationModalContentConfirmationView from './MedicationModalContentConfirmationView';
import RecurrenceComponent from '../../../caro-ui-commonfiles/components/molecules/Recurrence/Recurrence';
import ICDSelect from '../../../caro-ui-commonfiles/components/ICDDropdownSelect/ICDSelect';
import {
  dropdownMedicationPhaseTypes,
  dropdownMedicationAdministrationType,
} from '../../Utils/dropdownTypes';
import icdThresholds from '../../Utils/icdThresholds.json';

import {
  ComponentTypes,
  Actions,
  SubmissionTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { updateDosageAndSelectedTime } from './updateDosageAndSelectedTime';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

import serviceConfig from '../../../serviceConfig.json';

const MedicationCreateModalContent = () => {
  const dropdownMedicationAdministrationTypeObj =
    dropdownMedicationAdministrationType();
  const dropdownMedicationPhaseTypesObj = dropdownMedicationPhaseTypes();

  const {
    lifespanUnits,
    rruleFreqToRepeatTypeIndex,
  } = require('../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils');
  const lifespanUnitsObj = lifespanUnits();
  const repeatTypesArray = rruleFreqToRepeatTypeIndex();

  const {
    currentStep,
    setCurrentStep,
    shouldValidateForm,
    dispatchFormValidator,
    shouldSubmitForm,
    dispatchFormSubmittor,
    setFlashMessage,
    closeModal,
    setLandingTab,
    patientInfo,
    setRefreshCalendar,
    setDoFetch,
  } = useContext(PatientOverviewContext);

  const [icds, setIcds] = useState([]);
  const [validateRecurrence, setValidateRecurrence] = useState(false);
  const [values, setValues] = useState({
    name: '',
    sober: false,
    medicationAdministrationType: dropdownMedicationAdministrationTypeObj[1],
    phaseType: dropdownMedicationPhaseTypesObj[1],
    startDate: moment().toDate(),
    morning: true,
    dosageInMorning: '',
    midday: true,
    dosageInAfternoon: '',
    evening: true,
    dosageInEvening: '',
    night: false,
    dosageAtNight: '',
    customTimeChecked: true,
    dosageSpecificTime: '',
    customTime: { hour: 9 },
    date: '',
    lifespan: 1,
    lifespanUnit: lifespanUnitsObj[0],
    repeatType: repeatTypesArray[3],
    repeatInterval: { value: 1, label: 1 },
    occurrenceCount: 1,
    endDate: new Date(),
    weeklyRecurrence: [],
    occurrenceCountCheck: true,
    unit: '',
  });
  const [hasErrors, setHasErrors] = useState({
    name: false,
    sober: false,
    medicationAdministrationType: false,
    phaseType: false,
    dosageInMorning: false,
    dosageInAfternoon: false,
    dosageInEvening: false,
    dosageAtNight: false,
    dosageSpecificTime: false,
    customTime: false,
    date: false,
    unit: false,
  });

  const [recurrenceErrors, setRecurrenceErrors] = useState({
    lifespan: false,
    recurrence: false,
    recurrenceRepeat: false,
    startDate: false,
    endDate: false,
    occurrenceCount: false,
  });

  const [startingArrays, setStartingArrays] = useState({
    startingDays: [],
    startingTimes: [],
  });

  const onValueChange = (value, key) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const resetDosage = (isTimeOfDayChecked, timeOfDayCheckbox, dosage) => {
    if (isTimeOfDayChecked === false) {
      setValues({
        ...values,
        [timeOfDayCheckbox]: false,
        [dosage]: '',
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          serviceConfig.brokerService.addMedication.uri + patientInfo.id
        );
      } catch (error) {
        closeModal();
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (
      shouldValidateForm.component === ComponentTypes.MEDICATION &&
      shouldValidateForm.action === Actions.Create
    ) {
      setValidateRecurrence(shouldValidateForm.shouldValidate);
    }
  }, [shouldValidateForm]);

  useEffect(() => {
    const checkRecurrenceError = () =>
      Object.keys(recurrenceErrors).find(key => recurrenceErrors[key]);

    if (shouldValidateForm.shouldValidate) {
      const isFormValid = validateForm();
      const isRecurrenceValid = checkRecurrenceError();

      if (validateRecurrence && !isRecurrenceValid && isFormValid) {
        setCurrentStep(currentStep + 1);
        dispatchFormValidator({
          component: '',
          action: '',
          shouldValidate: false,
        });
      }
    }

    if (validateRecurrence) setValidateRecurrence(false);
  }, [recurrenceErrors]);

  const validateForm = () => {
    const isNameValid = values.name.trim().length > 5;
    const hasDosageInMorning =
      !Number.isNaN(Number(values.dosageInMorning)) &&
      values.dosageInMorning.trim().length > 0;
    const hasDosageInAfternoon =
      !Number.isNaN(Number(values.dosageInAfternoon)) &&
      values.dosageInAfternoon.trim().length > 0;
    const hasDosageInEvening =
      !Number.isNaN(Number(values.dosageInEvening)) &&
      values.dosageInEvening.trim().length > 0;
    const hasDosageAtNight =
      !Number.isNaN(Number(values.dosageAtNight)) &&
      values.dosageAtNight.trim().length > 0;
    const hasDosageSpecificTime =
      !Number.isNaN(Number(values.dosageSpecificTime)) &&
      values.dosageSpecificTime.trim().length > 0;
    const isDosageInMorningValid =
      values.morning === false || (hasDosageInMorning && values.morning);
    const isDosageInAfternoonValid =
      values.midday === false || (hasDosageInAfternoon && values.midday);
    const isDosageInEveningValid =
      values.evening === false || (hasDosageInEvening && values.evening);
    const isDosageAtNightValid =
      values.night === false || (hasDosageAtNight && values.night);
    const isDosageSpecificTimeValid =
      values.customTimeChecked === false ||
      (hasDosageSpecificTime && values.customTimeChecked);
    const isUnitValid = values.unit.trim().length > 0;

    setHasErrors({
      ...hasErrors,
      name: !isNameValid,
      dosageInMorning: !isDosageInMorningValid,
      dosageInAfternoon: !isDosageInAfternoonValid,
      dosageInEvening: !isDosageInEveningValid,
      dosageAtNight: !isDosageAtNightValid,
      dosageSpecificTime: !isDosageSpecificTimeValid,
      unit: !isUnitValid,
    });

    return (
      isNameValid &&
      isDosageInMorningValid &&
      isDosageInAfternoonValid &&
      isDosageInEveningValid &&
      isDosageAtNightValid &&
      isDosageSpecificTimeValid &&
      isUnitValid
    );
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    return false;
  };

  useEffect(() => {
    const createMedication = async () => {
      closeModal();
      setLandingTab(false);
      let message = '';
      const { endDate } = values;
      updateDosageAndSelectedTime(values);

      try {
        const response = await axios.post(
          serviceConfig.brokerService.createMedication.uri,
          {
            patientId: patientInfo.id,
            countICDs: icds,
            ...values,
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: endDate
              ? moment(endDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
          }
        );

        if (response) {
          let sendResponse = response;
          message = `${I18n.t(response.data.content)}`;
          const isSendOutImmediately =
            shouldSubmitForm.submissionType === SubmissionTypes.ASSIGN_AND_SEND;
          if (isSendOutImmediately) {
            sendResponse = await axios.post(
              serviceConfig.brokerService.sendMedication.uri,
              {
                reminderId: response.data.reminderId,
              }
            );
          }
          setFlashMessage({
            type: sendResponse.data.type,
            content: isSendOutImmediately
              ? `${message} ${I18n.t(sendResponse.data.content)}`
              : message,
          });
          setDoFetch(prevState => !prevState);
        }
      } catch (error) {
        setFlashMessage({
          type: error.response.data.type,
          content: `${I18n.t(error.response.data.content)}${message}`,
        });
      }
      setLandingTab(ComponentTypes.MEDICATION);
      setRefreshCalendar(true);
    };

    if (
      shouldSubmitForm.component === ComponentTypes.MEDICATION &&
      shouldSubmitForm.action === Actions.Create
    ) {
      createMedication();
      return () => {
        dispatchFormSubmittor({
          component: '',
          action: '',
          submissionType: false,
        });
      };
    }
  }, [shouldSubmitForm]);

  const fetchIcdData = term => {
    return axios(serviceConfig.getIcds.uri, {
      params: {
        searchTerm: term,
      },
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  return (
    <>
      <ModalContent>
        <ModalForm
          formId="add-medication-wizard-form"
          handleFormSubmit={handleFormSubmit}
        >
          {currentStep === 1 && (
            <MedicationModalContentForm
              values={values}
              recurrenceComponent={
                <RecurrenceComponent
                  componentType={ComponentTypes.MEDICATION}
                  onValueChange={onValueChange}
                  values={values}
                  validateRecurrence={validateRecurrence}
                  hasErrors={recurrenceErrors}
                  setHasErrors={setRecurrenceErrors}
                  setStartingArrays={setStartingArrays}
                />
              }
              icdSelectorComponent={
                <ICDSelect
                  maxLengthItem={icdThresholds.MEDICATION}
                  onSelect={selected => {
                    setIcds(selected);
                  }}
                  icdSelected={icds}
                  fetchData={fetchIcdData}
                  placeholder={I18n.t(
                    'newCareplan_view.placeholderICDReference'
                  )}
                />
              }
              onValueChange={onValueChange}
              hasErrors={hasErrors}
              setRecurrenceErrors={setRecurrenceErrors}
              recurrenceErrors={recurrenceErrors}
              resetDosage={resetDosage}
            />
          )}
          {currentStep === 2 && (
            <MedicationModalContentConfirmationView
              values={values}
              startingArrays={startingArrays}
              icds={icds}
            />
          )}
        </ModalForm>
      </ModalContent>
    </>
  );
};

export default MedicationCreateModalContent;
