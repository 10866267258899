import moment, { Moment } from 'moment';

import {
  AuxiliaryColors,
  lifespanEnum,
  PrimaryColor,
} from '@ui-common-files/utils';
import { formatDate } from '@global/Date';
import { DateFormat } from '@ui-common-files/utils/dateUtils/dateUtil.type';

import { OVERALL_SCORE_COLOR } from '../OverviewDataChart/OverviewDataPointsSelection/CaretasksData/CaretasksData.constant';
import DataPointType from '../OverviewDataChart/OverviewDataPointsSelection/OverviewDataPointsSelection.enum';
import { ComplexScoringType } from '../../../Careplans/ComplexScoreSetting/ComplexScoring.type';
import { FilterOption } from '../Overview.type';

const calculateStartDate = (
  endDate: Moment,
  daysToSubtract: number
): Moment => {
  return endDate
    .clone()
    .subtract(daysToSubtract, 'days')
    .startOf('day')
    .add(1, 'days');
};

export const setDateRangeOnViewChange = (
  filter: FilterOption,
  date: string = null
) => {
  let endDate = date
    ? moment(date, DateFormat.DATE_HOUR_MINUTE_SECOND)
    : moment();
  let startDate;

  switch (filter) {
    case lifespanEnum.DAY:
      startDate = endDate.clone().startOf('day');
      endDate.endOf('day');
      break;

    case lifespanEnum.WEEK:
      startDate = calculateStartDate(endDate, 7);
      endDate.endOf('day');
      break;

    case lifespanEnum.MONTH:
      startDate = calculateStartDate(endDate, 30);
      endDate.endOf('day');
      break;

    case lifespanEnum.YEAR:
      startDate = calculateStartDate(endDate, 365);
      endDate.endOf('day');
      break;

    case lifespanEnum.YTD:
      endDate = moment();
      startDate = endDate.clone().startOf('year');
      break;

    default:
      startDate = endDate.startOf('day');
      endDate.startOf('day');
      break;
  }

  return {
    start: formatDate(startDate.toDate(), DateFormat.DATE_HOUR_MINUTE_SECOND),
    end: formatDate(endDate.toDate(), DateFormat.DATE_HOUR_MINUTE_SECOND),
  };
};

export const selectors = [
  lifespanEnum.TODAY,
  lifespanEnum.DAY,
  lifespanEnum.WEEK,
  lifespanEnum.MONTH,
  lifespanEnum.YEAR,
  lifespanEnum.YTD,
];

export const getIsMedicalLabel = (isMedical: boolean) =>
  isMedical
    ? 'patients_view.wearable_medical'
    : 'patients_view.wearable_non_medical';

export const getKeyLabel = (key, devicesQuery) => {
  switch (devicesQuery.filter) {
    case lifespanEnum.DAY:
      return moment.utc(key).format('HH:mm');
    case lifespanEnum.WEEK:
      return moment.utc(key).format('ddd-DD');
    case lifespanEnum.MONTH:
      return moment.utc(key).format('DD-MMM');
    case lifespanEnum.YEAR:
      return moment.utc(key).format('MMM-YYYY');
    default:
      return moment.utc(key).format('MMM-YYYY');
  }
};

export const getColor = (item, index) => {
  if (
    item.dataPointType === DataPointType.CARETASK &&
    item.scoreTypeId === ComplexScoringType.OverallScore
  ) {
    return OVERALL_SCORE_COLOR;
  }

  return index === 0 ? PrimaryColor.MAIN_COLOR : AuxiliaryColors.GREEN;
};
