import React, { useState, useEffect, useMemo } from 'react';
import I18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { MaxCharLimits } from '@ui-common-files/utils';
import { ApiClient, HideFlashMessage } from '@global/Services';

import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import Input from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import serviceConfig from '../../../serviceConfig.json';
import EditRole from '../roles/editRole';
import IconButtonDropdown from '../../../caro-ui-commonfiles/components/DropdownMenu/IconButtonDropdown';
import inputField from '../../../caro-ui-commonfiles/utils/inputField';
import '../../css/role.css';
import RoleHelperUtility from '../../Utils/RoleHelperUtility.js';
import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';

import { Greys, ActionColors } from '../../../caro-ui-commonfiles/utils/colors';
import ShowModalFooter from '../common/ShowModalFooter';
import { calcCurrentPage } from '../../Utils/DataTableUtilities';
import useWindowWidthListener from '../../Utils/hooks/useWindowWidthListener';
import userRoles from '../../Utils/roles.json';

const Roles = ({
  flashMessage,
  setFlashMessage,
  hasPagesize,
  hasPagination,
  fetchedData,
  setFetchedData,
  rolePermissions,
  setRolePermissions,
  reloadTable,
  setReloadTable,
  user,
}) => {
  const [descSort, setDescSort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({ value: '', start: 0, length: 10 });
  const [pageState, setPageState] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [openModalForEdit, setOpenModalForEdit] = useState(false);
  const [roleId, setRoleId] = useState();
  const [shouldSpin, setSpinner] = useState(false);
  const [promptMessage, setPromptMessage] = useState({
    display: false,
    id: 0,
    title: '',
    message: '',
  });
  const [roleUpdated, setRoleUpdated] = useState({
    display: false,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [typingTimeOut, setTypingTimeOut] = useState(0);
  const { t } = useTranslation();

  const responsive = useWindowWidthListener();

  const renderEditRoleModal = async (e, roleObject) => {
    try {
      const rolePermissions = await RoleHelperUtility.getRolePermissions(user);
      const editPermission = rolePermissions.find(role => {
        return role.action == I18n.t('actions.update');
      });
      if (editPermission.allowed) {
        setOpenModalForEdit(true);
        setRoleId(roleObject.roleId);
        setSpinner(true);
      } else {
        const permissionDeniedObject = {
          content: I18n.t('common_labels.msg_noPermission'),
          type: 'warning',
        };
        setFlashMessage(permissionDeniedObject);
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  useEffect(() => {
    const Rows = data => {
      const retrievedData = [...data];
      const rows = [];
      if (retrievedData) {
        for (let index = 0; index < retrievedData.length; index++) {
          rows.push({
            roleId: retrievedData[index].id,
            roleName: retrievedData[index].name,
            isAssigned: retrievedData[index].isAssigned,
          });
        }
      }
      return rows;
    };
    if (typingTimeOut > 0) clearTimeout(typingTimeOut);
    const fetchData = () => {
      setLoading(true);
      ApiClient.GET({
        url: serviceConfig.brokerService.getPrefilledRoles.uri,
        payload: { query },
        timeout: 30000,
      })
        .then(result => {
          if (result) {
            const data = Rows(result.data.rolesList.rows);
            setFetchedData(data);
            setRecordsTotal(result.data.rolesList.count);
          }
        })
        .catch(error => {
          setFlashMessage({
            type: error.response.data.type,
            content: error.response.data.content,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (query.value.length === 0) {
      fetchData();
    } else if (query.value.length > 1) {
      const timeOut = setTimeout(() => {
        fetchData();
      }, 500);
      setTypingTimeOut(timeOut);
    }
    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [query, reloadTable]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];
  const tableProps = {
    defaultSorted: [
      {
        id: 'record',
        desc: false,
      },
    ],
    onSortedChange: (newSorted, column, shiftKey) => {
      newSorted[0].desc ? setDescSort(true) : setDescSort(false);
    },
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
  };

  const fillIconColor = roleObject => {
    return roleObject.isAssigned ? Greys.LIGHT_GREY : ActionColors.DELETE;
  };

  const shouldDisable = roleObject => {
    const hasNoAccessToUpdateRole = hasAccessToActions(roleObject);
    return !!roleObject.isAssigned || hasNoAccessToUpdateRole;
  };

  const hasAccessToActions = roleObject => {
    if (
      user.role.name === userRoles.SITE_ADMIN &&
      roleObject.roleName === userRoles.SUPER_ADMIN
    )
      return true;

    if (
      user.role.name === userRoles.SITE_ADMIN &&
      roleObject.roleName === userRoles.SITE_ADMIN
    )
      return true;

    if (roleObject.roleName === userRoles.SUPER_ADMIN_HIDE_DEMOGRAPHICS)
      return true;

    return false;
  };

  const getToolTipText = roleObject => {
    return roleObject.isAssigned ? (
      <div className="tooltipRoles">
        {' '}
        {I18n.t('rolemanager_view.active_role')}
      </div>
    ) : (
      <div className="tooltipRoles">
        {' '}
        {I18n.t('rolemanager_view.delete_role')}{' '}
      </div>
    );
  };

  const getDropDownList = roleObject => {
    const items = [];

    const hasRoleAccessRights = hasAccessToActions(roleObject);

    if (!hasRoleAccessRights) {
      items.push({ value: I18n.t('common_labels.label_edit') });
    }

    if (!roleObject.isAssigned && !hasRoleAccessRights) {
      items.push({ value: I18n.t('common_labels.label_delete') });
    }
    return items;
  };

  const closeMessagePrompt = () => {
    setPromptMessage({
      display: false,
      id: 0,
      title: '',
      message: '',
    });

    setRoleUpdated({
      display: false,
    });
  };

  const columns = useMemo(() => {
    return [
      {
        Header: () => {
          return <span>{I18n.t('rolemanager_view.label_roleId')}</span>;
        },
        accessor: 'roleId',
        sortable: false,
        filterable: false,
      },
      {
        Header: () => {
          return <span>{I18n.t('rolemanager_view.label_roleName')}</span>;
        },
        accessor: 'roleName',
        sortable: false,
        filterable: false,
      },
      {
        Header: '',
        accessor: 'rowOptions',
        sortable: false,
        filterable: false,
        minWidth: 160,
        maxWidth: 160,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'space-around',
        },
        Cell: props => {
          const roleObject = props.original;
          const id = roleObject.roleId;

          const actionButtonItems = !hasAccessToActions(roleObject)
            ? [{ value: t('common_labels.label_edit') }]
            : [];

          return (
            <>
              {responsive ? (
                <div className="moreIconStyle">
                  {roleObject.roleId != 1 &&
                    roleObject.roleName !=
                      userRoles.SUPER_ADMIN_HIDE_DEMOGRAPHICS && (
                      <IconButtonDropdown
                        items={getDropDownList(roleObject)}
                        onClick={(e, action) => {
                          if (
                            action.value == I18n.t('common_labels.label_edit')
                          ) {
                            renderEditRoleModal(e, roleObject);
                          } else {
                            setPromptMessage({
                              display: true,
                              id,
                              title: `${I18n.t(
                                'common_labels.msg_confirm_role_delete_headline'
                              )}: ${roleObject.roleName}`,
                              message: I18n.t(
                                'common_labels.msg_confirm_role_delete'
                              ),
                            });
                          }
                        }}
                      />
                    )}

                  {roleObject.roleId === 1 &&
                    roleObject.roleName !=
                      userRoles.SUPER_ADMIN_HIDE_DEMOGRAPHICS && (
                      <IconButtonDropdown
                        items={actionButtonItems}
                        onClick={(e, action) => {
                          if (
                            action.value == I18n.t('common_labels.label_edit')
                          ) {
                            renderEditRoleModal(e, roleObject);
                          }
                        }}
                      />
                    )}
                </div>
              ) : (
                <>
                  <IconButton
                    type="button"
                    name="edit"
                    size={30}
                    disabled={hasAccessToActions(roleObject)}
                    onClick={(e, item) => {
                      renderEditRoleModal(e, roleObject);
                    }}
                    tooltipText={I18n.t('rolemanager_view.edit_role')}
                  />
                  <IconButton
                    type="button"
                    name="delete"
                    size={30}
                    tooltipText={getToolTipText(roleObject)}
                    fill={fillIconColor(roleObject)}
                    disabled={shouldDisable(roleObject)}
                    onClick={(e, item) => {
                      if (!roleObject.isAssigned) {
                        setPromptMessage({
                          display: true,
                          id,
                          title: `${I18n.t(
                            'common_labels.msg_confirm_role_delete_headline'
                          )}: ${roleObject.roleName}`,
                          message: I18n.t(
                            'common_labels.msg_confirm_role_delete'
                          ),
                        });
                      } else {
                        setFlashMessage({
                          type: 'warning',
                          content: I18n.t('rolemanager_view.active_role'),
                        });
                      }
                    }}
                  />
                </>
              )}
            </>
          );
        },
      },
    ];
  }, [responsive]);

  const handleFlashMessages = response => {
    if (response) {
      setFlashMessage(response.data.message);
    }
  };

  const deleteRole = async id => {
    try {
      const rolePermissions = await RoleHelperUtility.getRolePermissions(user);
      const deletePermission = rolePermissions.find(role => {
        return role.action == I18n.t('actions.delete');
      });
      if (deletePermission.allowed) {
        const response = await ApiClient.GET({
          url: serviceConfig.brokerService.deleteRole.uri + id,
          payload: {
            userLanguage: I18n.language,
          },
        });
        const updateFetchedData = fetchedData.filter(function (obj) {
          return obj.roleId !== id;
        });
        setFetchedData(updateFetchedData);
        handleFlashMessages(response);
        setPromptMessage({
          display: false,
          id: 0,
          title: '',
          message: '',
        });
        if (response) {
          const page = calcCurrentPage(
            recordsTotal - 1,
            pageState,
            currentPage
          );
          if (page !== currentPage) {
            setCurrentPage(page);
            setQuery({
              ...query,
              start: page * pageState,
            });
          } else {
            setReloadTable();
          }
        }
      } else {
        const permissionDeniedObject = {
          content: I18n.t('common_labels.msg_noPermission'),
          type: 'warning',
        };
        setFlashMessage(permissionDeniedObject);
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  return (
    <>
      <div style={{ width: 250, position: 'absolute', top: 0, right: 0 }}>
        <Input
          id="specialId"
          name="Name"
          placeholder={I18n.t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={query.value}
          handleOnChange={e => {
            setQuery({ value: e.target.value, start: 0, length: query.length });
            setCurrentPage(0);
            setPageState(query.length);
          }}
        />
      </div>
      <div>
        <DataTable
          data={fetchedData}
          columns={columns}
          pageSize={hasPagesize}
          pageSizeOptions={items}
          pagination={hasPagination}
          isLoading={loading}
          tableProps={tableProps}
          onTableChange={(index, size) => {
            setQuery({
              value: query.value,
              start:
                fetchedData && index * size > recordsTotal ? 0 : index * size,
              length: size,
            });
            setCurrentPage(
              fetchedData && index * size > recordsTotal ? 0 : index
            );
            setPageState(size);
          }}
          page={currentPage}
          pages={Math.ceil(recordsTotal / query.length)}
          totalRecord={recordsTotal}
        />
      </div>
      {roleId !== undefined && (
        <EditRole
          roleId={roleId}
          setRoleId={setRoleId}
          openModal={openModalForEdit}
          setOpenModal={setOpenModalForEdit}
          flashMessage={flashMessage}
          setFlashMessage={setFlashMessage}
          setFetchedData={setFetchedData}
          fetchedData={fetchedData}
          shouldSpin={shouldSpin}
          setSpinner={setSpinner}
          rolePermissions={rolePermissions}
          setRolePermissions={setRolePermissions}
          reloadTable={setReloadTable}
          setPromptMessage={setPromptMessage}
        />
      )}
      {promptMessage.display && (
        <Modal
          isConfirmationDialog
          title={promptMessage.title}
          contentComponent={<PromptContent message={promptMessage.message} />}
          footerComponent={
            promptMessage.id >= 0 ? (
              <PromptFooter
                close={closeMessagePrompt}
                confirmHandler={() => {
                  if (promptMessage.id > 0) {
                    deleteRole(promptMessage.id);
                  }
                }}
              />
            ) : (
              <ShowModalFooter close={closeMessagePrompt} />
            )
          }
          openModal={promptMessage.display}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </>
  );
};
export default Roles;
