import React, { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import { Button, Box } from '@ui-common-files/components';
import '../../../css/careplanComponent.scss';
import NumericConditionInput from '../settingsForComponentTypes/NumericConditionInput';
import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@ui-common-files/utils/button';

import { ActionColors } from '@ui-common-files/utils/colors';
import defaultEnums from '../defaultEnums';


const NumericValue = ({
  item,
  componentCollection,
  setLogicJumpValueForComponent,
  componentIndex,
  isLogicJumpOn,
  setLogicJumpError,
  setJumpContradictionError,
}) => {
  const [itemCollection, setItemCollection] = useState(
    item.logicJumpCollection && item.logicJumpCollection.length > 0
      ? item.logicJumpCollection
      : [{ name: '', value: '', destination: -1 }]
  );

  const isFloatingNumber = item.searchTerm.careplanComponentId
    ? item.searchTerm.decimal
    : item.decimal;

  const addJumpCondition = () => {
    const obj = { name: '', value: '', destination: -1 };
    const newItemCollection = [...itemCollection, obj];
    setItemCollection(newItemCollection);
  };

  const deleteJumpConditions = optionIndex => {
    const updateItemCollection = [...componentCollection];
    updateItemCollection[componentIndex].logicJumpCollection.splice(
      optionIndex,
      1
    );
    setLogicJumpValueForComponent(
      'logicJumpCollection',
      updateItemCollection[componentIndex].logicJumpCollection
    );
    setItemCollection(item.logicJumpCollection);
  };

  return (
    <div>
      {isFloatingNumber && (
        <div className="floatingTextContainer">
          <span className="floatingTextSpan">
            {I18n.t('previewCareplanComponent.floatingPointNumber')}
          </span>
        </div>
      )}
      {isLogicJumpOn && (
        <>
          {itemCollection.map((elem, i) => {
            return (
              <NumericConditionInput
                index={i}
                item={elem}
                parentComponentIndex={componentIndex}
                itemCollection={itemCollection}
                setLogicJumpValueForComponent={setLogicJumpValueForComponent}
                isFloatingNumber={isFloatingNumber}
                isThreshold={false}
                color={ActionColors.THRESHOLD}
                setLogicJumpError={setLogicJumpError}
                setJumpContradictionError={setJumpContradictionError}
                deleteJumpConditions={deleteJumpConditions}
                min={defaultEnums.NUMERIC_VALUE_MIN_VALUE}
                max={defaultEnums.NUMERIC_VALUE_MAX_VALUE}
              />
            );
          })}
          <Box margin="10px 0 0 50px">
            <Button
              label={`+ ${
                itemCollection.length > 0
                  ? I18n.t('threshold.addMoreThresholdLabel')
                  : I18n.t('threshold.addThresholdLabel')
              }`}
              variant={ButtonVariant.NO_BORDER}
              color={ButtonColor.RED}
              size={ButtonSize.MD}
              onClick={() => addJumpCondition()}
            />
          </Box>
        </>
      )}
    </div>
  );
};

NumericValue.propTypes = {
  item: PropTypes.object.isRequired,
  componentCollection: PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
  setLogicJumpValueForComponent: PropTypes.func.isRequired,
  isLogicJumpOn: PropTypes.bool.isRequired,
  setLogicJumpError: PropTypes.func.isRequired,
  setJumpContradictionError: PropTypes.func.isRequired,
};

export default NumericValue;
