import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import config from '../../../../config';

const InitLogger = () => {
  Sentry.init({
    dsn: config.SentryDSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `BrokerUI@${process.env.VERSION}`,
    environment: process.env.NODE_ENV,
    initialScope: {
      tags: {
        nonMedical: config.nonMedical,
        isBlockchainActive: config.isBlockchainActive,
        isRunningFhir: config.isRunningFhir,
        notForClinicalUse: config.not_for_clinical_use,
      },
    },
  });
};

export default InitLogger;
