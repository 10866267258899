import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';

import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import NumberInput from '../../../../caro-ui-commonfiles/components/NumberInput/NumberInput';
import Dropdown from '../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';

import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';

const DatepickerThresholdComponent = ({
  isDisabled,
  onChange,
  component,
  id,
}) => {
  const periodOfTimeOptions = [
    {
      value: 'more',
      label: I18n.t('common_labels.label_more'),
      key: 'common_labels.label_more',
    },
    {
      value: 'less',
      label: I18n.t('common_labels.label_less'),
      key: 'common_labels.label_less',
    },
  ];

  const timeUnitOptions = [
    { value: 'day(s)', label: I18n.t('lifespan.DAY'), key: 'lifespan.DAY' },
    { value: 'week(s)', label: I18n.t('lifespan.WEEK'), key: 'lifespan.WEEK' },
    {
      value: 'month(s)',
      label: I18n.t('lifespan.MONTH'),
      key: 'lifespan.MONTH',
    },
    { value: 'year(s)', label: I18n.t('lifespan.YEAR'), key: 'lifespan.YEAR' },
  ];

  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue({
      periodOfTimeOptions: component
        ? periodOfTimeOptions.find(val => {
            return val.value === component.comparison;
          })
        : null,
      timeUnitOptions: component
        ? timeUnitOptions.find(val => {
            return val.value === component.unit;
          })
        : null,
      number: component ? component.numericValue : '',
    });
  }, [component]);

  return (
    <Flex alignItems="start">
      <Box flex={0} margin="15px 15px 0 0">
        <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
      </Box>
      <Flex alignItems="center" flexWrap="wrap">
        <p className="link" style={{ margin: 0, marginTop: 8, marginRight: 8 }}>
          {I18n.t('newCareplan_view.thresholdDatePickerPart1')}
        </p>

        <Flex alignItems="center" otherStyles={{ marginTop: 8 }}>
          <Box margin="0 8px 0 0" width={90}>
            <Dropdown
              value={value && value.periodOfTimeOptions}
              options={periodOfTimeOptions}
              isDisabled={isDisabled}
              placeholder=""
              handleOnChange={val => {
                const newVal = {
                  ...value,
                  periodOfTimeOptions: val,
                };
                onChange(newVal);
                setValue({
                  ...value,
                  periodOfTimeOptions: val,
                });
              }}
              fontSize={14}
              heightControl={36}
            />
          </Box>

          <p className="link" style={{ margin: 0, marginTop: 8 }}>
            {I18n.t('threshold.than')}
          </p>

          <Box margin="0 8px" width={90}>
            <NumberInput
              value={value && value.number}
              name={id}
              id={id}
              min={1}
              max={100}
              step={1}
              isDisabled={isDisabled}
              changeValue={e => {
                const newVal = {
                  ...value,
                  number: e,
                };
                onChange(newVal);
                setValue({
                  ...value,
                  number: e,
                });
              }}
            />
          </Box>

          <Box margin="0 8px" width={90}>
            <Dropdown
              value={value && value.timeUnitOptions}
              options={timeUnitOptions}
              isDisabled={isDisabled}
              placeholder=""
              handleOnChange={val => {
                const newVal = {
                  ...value,
                  timeUnitOptions: val,
                };
                onChange(newVal);
                setValue({
                  ...value,
                  timeUnitOptions: val,
                });
              }}
              fontSize={14}
              heightControl={36}
            />
          </Box>
        </Flex>

        <p className="link" style={{ margin: 0, marginTop: 8 }}>
          {I18n.t('newCareplan_view.thresholdDatePickerPart2')}
        </p>
      </Flex>
    </Flex>
  );
};

DatepickerThresholdComponent.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  component: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  id: PropTypes.string.isRequired,
};

export default DatepickerThresholdComponent;
