import { put, call, takeEvery } from 'redux-saga/effects';
import wearablesActions from '../src/Utils/ReduxUtilities/Actions/wearables.json';
import {
  getWearablesForCareplans,
  getWearablesActivityStatus,
} from '../src/Utils/ReduxUtilities/APICalls/wearables';

function* getWearablesForCareplanRequest(data) {
  try {
    const results = yield call(() => getWearablesForCareplans(data.payload));
    yield put({
      type: wearablesActions.GET_WEARABLES_FOR_CAREPLANS_RESPONSE,
      payload: results.data,
    });
  } catch (error) {
    yield put({
      type: wearablesActions.WEARABLES_ERROR,
      payload: error.response.data,
    });
  }
}

function* getWearablesActivityStatusRequest(data) {
  try {
    const results = yield call(() => getWearablesActivityStatus(data.payload));
    yield put({
      type: wearablesActions.GET_WEARABLES_ACTIVITY_STATUS_RESPONSE,
      payload: results.data,
    });
  } catch (error) {
    yield put({
      type: wearablesActions.WEARABLES_ERROR,
      payload: error.response.data,
    });
  }
}

function* actionWatcher() {
  yield takeEvery(
    wearablesActions.GET_WEARABLES_FOR_CAREPLANS_REQUEST,
    getWearablesForCareplanRequest
  );
  yield takeEvery(
    wearablesActions.GET_WEARABLES_ACTIVITY_STATUS_REQUEST,
    getWearablesActivityStatusRequest
  );
}

export const wearables = actionWatcher();
