import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Slider from '../../../../caro-ui-commonfiles/components/Slider/Slider';
import defaultEnums from '../defaultEnums';
import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '../../../../caro-ui-commonfiles/utils/button';
import slider from '../../../../caro-ui-commonfiles/utils/slider';
import '../../../css/careplanComponent.scss';
import NumericConditionInput from '../settingsForComponentTypes/NumericConditionInput';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';
import { nonMedical } from '../../../../config';
import { setThresholdValueForComponent } from './thresholdHelper';

const isThresholdSet = (index, componentCollection, searchTermComponents) => {
  if (Object.keys(searchTermComponents).length > 0) {
    const shouldToggle =
      componentCollection[index].searchTerm.careplanComponentId &&
      (nonMedical == false || nonMedical == 'false')
        ? componentCollection[index].searchTerm.shouldThresholdToggle
        : componentCollection[index].shouldThresholdToggle;
    return shouldToggle;
  }
};

const NumericRange = ({
  item,
  componentCollection,
  searchTermComponents,
  setComponentCollection,
  thresholdDropdown,
  thresholdNumber,
}) => {
  const [itemCollection, setItemCollection] = useState([
    { name: thresholdDropdown, value: thresholdNumber },
  ]);
  const index = componentCollection.findIndex(comp => comp.id === item.id);

  useEffect(() => {
    const { thresholds } = componentCollection[index].thresholdCollection;
    const thresholdCollectionObject =
      thresholds.length > 0
        ? thresholds
        : [{ name: thresholdDropdown, value: thresholdNumber }];
    setItemCollection(thresholdCollectionObject);
  }, [componentCollection, index, thresholdDropdown, thresholdNumber]);

  const thresholdToggle = isThresholdSet(
    index,
    componentCollection,
    searchTermComponents
  );
  const minLabel = componentCollection[index].searchTerm.careplanComponentId
    ? componentCollection[index].searchTerm.minLabel
    : componentCollection[index].minLabel;
  const maxLabel = componentCollection[index].searchTerm.careplanComponentId
    ? componentCollection[index].searchTerm.maxLabel
    : componentCollection[index].maxLabel;
  const minValue = componentCollection[index].searchTerm.careplanComponentId
    ? componentCollection[index].searchTerm.minimumValue
    : componentCollection[index].minimumValue;
  const maxValue = componentCollection[index].searchTerm.careplanComponentId
    ? componentCollection[index].searchTerm.maximumValue
    : componentCollection[index].maximumValue;
  const addThreshold = () => {
    const obj = { name: thresholdDropdown, value: thresholdNumber };
    const newItemCollection = [...itemCollection, obj];
    setItemCollection(newItemCollection);
  };

  const setValueForComponent = (key, optionIndex, value) => {
    const updatedComponentCollection = setThresholdValueForComponent(
      key,
      index,
      optionIndex,
      value,
      componentCollection
    );
    setComponentCollection(updatedComponentCollection);
  };

  return (
    <>
      <Slider
        id="specialId"
        name="Name"
        value={Math.ceil((parseInt(minValue) + parseInt(maxValue)) / 2)}
        min={minValue.toString()}
        max={maxValue.toString()}
        minLabel={minLabel}
        maxLabel={maxLabel}
        onInput={() => {}}
        onChange={() => {}}
        variant={slider.variant.NON_EDITABLE}
      />
      {thresholdToggle && (
        <>
          {itemCollection.map((elem, i) => {
            return (
              <NumericConditionInput
                key={`numeric-condition-input-${index}-${i}`}
                index={i}
                item={elem}
                min={defaultEnums.NUMERIC_RANGE_MIN_VALUE}
                max={defaultEnums.NUMERIC_RANGE_MAX_VALUE}
                parentComponentIndex={index}
                itemCollection={itemCollection}
                setValueForComponent={setValueForComponent}
                isFloatingNumber={false}
                isThreshold
                color={ActionColors.THRESHOLD}
              />
            );
          })}
          <Box margin="13px 0 0 0">
            <Button
              label={`+ ${
                item.thresholdCollection.thresholds.length > 0
                  ? I18n.t('threshold.addMoreThresholdLabel')
                  : I18n.t('threshold.addThresholdLabel')
              }`}
              variant={ButtonVariant.NO_BORDER}
              color={ButtonColor.RED}
              size={ButtonSize.MD}
              onClick={() =>
                addThreshold(
                  item,
                  thresholdDropdown,
                  thresholdNumber,
                  componentCollection,
                  setComponentCollection
                )
              }
            />
          </Box>
        </>
      )}
    </>
  );
};

NumericRange.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  componentCollection: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  searchTermComponents: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object])
  ).isRequired,
  setComponentCollection: PropTypes.func.isRequired,
  thresholdDropdown: PropTypes.string.isRequired,
  thresholdNumber: PropTypes.string.isRequired,
};

export default NumericRange;
