/* eslint-disable*/
import React, { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import moment from 'moment';
import '../../css/user.css';

import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import Historial from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialLayout';
import HistorialStep from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialStep';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import { ButtonType } from '../../../caro-ui-commonfiles/utils/button';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';
import Tabs from '../../../caro-ui-commonfiles/components/Tabs/Tabs';
import tabPosition from '../../Utils/Administration/tabPosition';
import {
  PrimaryColor,
  greysColorVars,
} from '../../../caro-ui-commonfiles/utils/colors';

const UserModalShowContent = ({ userInformation, userOptIns }) => {
  const changeTabPosition = () => {
    if (activeTab == 2) {
      return tabPosition.SECOND;
    }
    return tabPosition.FIRST;
  };

  const getActiveTab = (_state, data) => data;
  const [activeTab, setActiveTab] = useReducer(
    getActiveTab,
    changeTabPosition()
  );

  const onClickTabItem = tab => {
    setActiveTab(tab);
  };

  const { institutions, userSessions } = userInformation;

  const dateFormat = 'DD MMM YYYY';

  useEffect(() => {}, [activeTab]);

  const renderHistorialOptIns = () => {
    return userOptIns.map(userOptIn => (
      <Historial>
        <HistorialStep userSession={userOptIn}>
          <div className="show-patient-history">
            {userOptIn.action == 'action.consent' && (
              <React.Fragment>
                <span style={{ color: PrimaryColor.MAIN_COLOR }}>{`${I18n.t(
                  'admin_view.label_consent'
                )}`}</span>
              </React.Fragment>
            )}
            {userOptIn.action == 'action.revoked' && (
              <React.Fragment>
                <span style={{ color: greysColorVars.LIGHT_GREY }}>{`${I18n.t(
                  'admin_view.label_revoke'
                )}`}</span>
              </React.Fragment>
            )}
            <span>
              {`${moment(userOptIn.timeOfAction)
                .locale(I18n.language)
                .format(dateFormat)} - ${moment(userOptIn.timeOfAction).format(
                'HH:mm'
              )}`}
            </span>
          </div>
        </HistorialStep>
      </Historial>
    ));
  };

  const renderHistorialUser = () => {
    return userSessions.map(userSession => (
      <Historial>
      <HistorialStep userSession={userSession}>
        <div className="show-patient-history">
          {userSession.isActive && (
            <span>{I18n.t('admin_view.label_active_session')}</span>
          )}
          {`${I18n.t('admin_view.label_device')} ${
            userSession.sessionInformation.osName
          }`}
          <span>
            {`${I18n.t('admin_view.label_browser')} ${
              userSession.sessionInformation.browserName
            }`}
          </span>
          {userSession.isActive && (
            <span className="show-patient__history-date">
              {`${I18n.t('admin_view.label-login-time')} ${moment(
                userSession.createdAt
              )
                .locale(I18n.language)
                .format(dateFormat)} - ${moment(userSession.createdAt).format(
                'HH:mm'
              )}`}
            </span>
          )}
          {!userSession.isActive && (
            <>
              <span className="show-patient__history-date">
                {`${I18n.t('admin_view.label-login-time')} ${moment(
                  userSession.createdAt
                )
                  .locale(I18n.language)
                  .format(dateFormat)} - ${moment(userSession.createdAt).format(
                  'HH:mm'
                )}`}
              </span>
              <span className="show-patient__history-date">
                {`${I18n.t('admin_view.label-logout-time')} ${moment(
                  userSession.logoutTime
                )
                  .locale(I18n.language)
                  .format(dateFormat)} - ${moment(
                  userSession.logoutTime
                ).format('HH:mm')}`}
              </span>
            </>
          )}
        </div>
      </HistorialStep>
      </Historial>
    ));
  };

  return (
    <ModalContent>
      <ModalForm>
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <div className="container-padding">
            <div style={{ position: 'relative' }}>
              <Tabs activeTab={activeTab} onClickTabItem={onClickTabItem}>
                <div
                  className="show-patient__history-label"
                  tabId={1}
                  label={I18n.t('common_labels.history')}
                >
                  <Label text={I18n.t('common_labels.history')} />
                  <div className="show-patient__historial-wrapper">
                      {userSessions && renderHistorialUser()}
                  </div>
                </div>
                <div
                  className="show-patient__history-label"
                  tabId={2}
                  label={I18n.t('common_labels.opt_ins')}
                >
                  <Label text={I18n.t('common_labels.opt_ins')} />
                  <div className="show-patient__historial-wrapper">
                      {userOptIns && renderHistorialOptIns()}
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </ModalSection>

        <ModalSection variant={MODAL_VARIANT.COL_W70}>
          <Flex otherStyles={{ height: '100%' }}>
            <div className="show_view_profile">
              <Box width="40px" height="40px">
                <div
                  style={{
                    backgroundImage: `url("${userInformation.profilePicture}")`,
                  }}
                  className="notification__table-profile-pic"
                />
              </Box>
              <ComponentWithLabel
                labelText={I18n.t('admin_view.label_username')}
              >
                <span className="show-patient-label">
                  {userInformation.username}
                </span>
              </ComponentWithLabel>
            </div>

            <Flex
              otherStyles={{
                width: '100%',
                height: '100%',
                padding: '20px 20px 0',
              }}
            >
              <div className="ver-col2">
                <ComponentWithLabel
                  labelText={I18n.t('admin_view.label_firstNameUser')}
                >
                  {userInformation.firstname}
                </ComponentWithLabel>
                <ComponentWithLabel
                  labelText={I18n.t('admin_view.label_lastNameUser')}
                >
                  {userInformation.lastname}
                </ComponentWithLabel>

                <ComponentWithLabel
                  labelText={I18n.t('patients_view.label_email')}
                >
                  <span className="show-patient__email">
                    {userInformation.email}
                  </span>
                </ComponentWithLabel>

                <ComponentWithLabel
                  labelText={I18n.t('admin_view.label-two-factor')}
                >
                  {userInformation.isTwoFactorAuthentication
                    ? I18n.t('admin_view.label-enabled')
                    : I18n.t('admin_view.label-disabled')}
                </ComponentWithLabel>
              </div>

              <div className="ver-col2">
                <ComponentWithLabel
                  labelText={I18n.t('common_labels.Organisation')}
                >
                  <Flex flexDirection="column">
                    {institutions.map(institution => (
                      <Box
                        key={`${institution.id}-${institution.name}`}
                        margin="0 0 5px 0"
                      >
                        {institution.name}
                      </Box>
                    ))}
                  </Flex>
                </ComponentWithLabel>
              </div>
            </Flex>
          </Flex>
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
};

UserModalShowContent.propTypes = {
  close: PropTypes.func.isRequired,
};

const UserModalShowFooter = ({ close }) => {
  return (
    <div className="show-patient__modal-footer">
      <Button
        label={I18n.t('common_buttons.close')}
        type={ButtonType.BUTTON}
        onClick={() => {
          close();
        }}
        variant="contained"
      />
    </div>
  );
};

UserModalShowFooter.propTypes = {
  close: PropTypes.func.isRequired,
};

export { UserModalShowContent, UserModalShowFooter };
