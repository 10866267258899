import React from 'react';
import { ActionProps } from './Action.interface';

import '../../styles/main.scss';

/**
 * A UI component for user interaction. It can be used as a Link or a Button with a link style.
 * This feature is versatile and can function as either a hyperlink or a button with a link appearance.
 */
const Action = ({
  onClick = () => {},
  className = '',
  href = '',
  target = '_blank',
  children,
}: ActionProps): JSX.Element => {
  const classNames = [className, 'action-underlined'].filter(Boolean).join(' ');

  if (href === '') {
    return (
      <button className={classNames} onClick={onClick}>
        {children}
      </button>
    );
  }
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;

  return (
    <a className={classNames} href={href} target={target} rel={rel}>
      {children}
    </a>
  );
};

export default Action;
