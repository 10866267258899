import { useTranslation } from 'react-i18next';

import { BloodPressureJumpProps, LogicJump } from './BloodPressureJump.type';
import {
  BloodPressureJumpData as data,
  BloodPressureJumpColumns as columns,
} from './BloodPressureJump.utils';

export default function useBloodPressureJumpService({
  item: { choiceCollection, logicJumpCollection },
  componentIndex,
  setLogicJumpValueForComponent,
}: BloodPressureJumpProps) {
  const { t } = useTranslation();

  const tableData = data({
    choices: choiceCollection.choices,
    setLogicJumpValueForComponent,
    index: componentIndex,
    logicJumpCollection,
    t,
  });

  const errorMessage =
    logicJumpCollection?.some(
      (item: LogicJump) => item?.destination <= componentIndex + 1
    ) && t('warningMessage.jumpDestinationPosition');

  const renderedColumns = columns({
    systolicValues: t(`bloodPressureOptions.systolicValues.label`),
    diastolicValues: t(`bloodPressureOptions.diastolicValues.label`),
  });

  return {
    errorMessage,
    data: tableData,
    columns: renderedColumns,
  };
}
