/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';

import PatientsForAssign from '../PatientForAssign';
import '../../css/careplans.scss';
import AssignTemplateStep2 from './AssignTemplateStep2';
import AssignTemplateStep3 from './AssignTemplateStep3';
import AssignTemplateStep4 from './AssignTemplateStep4';
import moment from 'moment';
import { nonMedical } from '../../../config';
import { convertLinkToBlob } from '../../Utils/Asset/assetHelper';

const AssignCareplanTemplate = ({
  careplanScoringScheme,
  currentStep,
  setCurrentStep,
  isSelectAll,
  setIsSelectAll,
  nbrPatientsSelected,
  setNbrPatientsSelected,
  careplanData,
  setCareplanData,
  careplanDataThresholds,
  setCareplanDataThresholds,
  selectedPatientsData,
  setSelectedPatientsData,
  shouldValidateForm,
  dispatchFormValidator,
  usWarningCheckboxChecked,
  setUsWarningCheckboxChecked,
  setBottomPageReached,
  careplanLogicJumpData,
  defaultDataThresholds,
  setDisableNext,
}) => {
  const [thresholdView, setThresholdView] = useState({
    default: true,
    individual: false,
  });

  const checkPatientsOver18 = () => {
    let isUnder18 = false;
    if (selectedPatientsData.length) {
      selectedPatientsData.map(patient => {
        const age = moment().diff(patient.birthdate, 'years');
        if (age < 18 && (nonMedical == false || nonMedical == 'false')) {
          isUnder18 = true;
          if (currentStep === 3) {
            setCurrentStep(currentStep + 1);
          }
        }
        return false;
      });
    }
    return true;
  };

  useEffect(() => {
    const processSelectedPatientsAvatar = async () => {
      if (currentStep === 2) {
        const processedPatients = await Promise.all(
          selectedPatientsData.map(async patient => {
            if (patient.profilePicture.previewURL) {
              await convertLinkToBlob(patient.profilePicture.previewURL)
                .then(blob => {
                  patient.profilePicture = blob;
                })
                .catch(() => {
                  patient.profilePicture = patient.profilePicture.previewURL;
                });
            } else if (
              !patient.profilePicture.includes('blob:https://') &&
              !patient.profilePicture.includes('data:image;base64')
            ) {
              patient.profilePicture = `data:image;base64,${patient.profilePicture}`;
            }
            return patient;
          })
        );
        setSelectedPatientsData(processedPatients);
      }
    };
    processSelectedPatientsAvatar();
  }, [currentStep]);

  return (
    <>
      <ModalContent>
        {currentStep === 1 && (
          <ModalForm
            formId="assign-careplan-from-manager"
            handleFormSubmit={e => {
              e.preventDefault();
            }}
          >
            <PatientsForAssign
              isSelectAll={isSelectAll}
              setIsSelectAll={setIsSelectAll}
              nbrPatientsSelected={nbrPatientsSelected}
              setNbrPatientsSelected={setNbrPatientsSelected}
              selectedPatientsData={selectedPatientsData}
              setSelectedPatientsData={setSelectedPatientsData}
              setDisableNext={setDisableNext}
            />
          </ModalForm>
        )}
        {currentStep === 2 && (
          <AssignTemplateStep2
            careplanData={careplanData}
            setCareplanData={setCareplanData}
            shouldValidateForm={shouldValidateForm}
            dispatchFormValidator={dispatchFormValidator}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 3 && checkPatientsOver18() && (
          <AssignTemplateStep3
            careplanData={careplanData}
            careplanScoringScheme={careplanScoringScheme}
            careplanDataThresholds={careplanDataThresholds}
            setCareplanDataThresholds={setCareplanDataThresholds}
            onChange={() => {}}
            isEvaluating={careplanData.isEvaluating}
            thresholdView={thresholdView}
            setThresholdView={setThresholdView}
            setBottomPageReached={setBottomPageReached}
            usWarningCheckboxChecked={usWarningCheckboxChecked}
            setUsWarningCheckboxChecked={setUsWarningCheckboxChecked}
            careplanLogicJumpData={careplanLogicJumpData}
            defaultDataThresholds={defaultDataThresholds}
          />
        )}
        {currentStep === 4 && (
          <AssignTemplateStep4
            careplanData={careplanData}
            selectedPatientsData={selectedPatientsData}
            careplanNumberOfComponents={careplanDataThresholds.length}
          />
        )}
      </ModalContent>
    </>
  );
};

export default AssignCareplanTemplate;
