import { equationFunction } from './ComplexScoreSetting.service';

const isParenthesisBalancedAndNotEmpty = formula => {
  const emptyParanthesisRegexPattern = new RegExp(
    /^(?!.*\(\))(?!.*\( \))(?!.*\)\()(?!.*\)\()./
  );
  if (!emptyParanthesisRegexPattern.test(formula)) {
    return false;
  }
  let brackets = '()';
  let stack = [];
  for (let input of formula) {
    let bracketsIndex = brackets.indexOf(input);
    if (bracketsIndex === -1) {
      continue;
    }
    if (bracketsIndex % 2 === 0) {
      stack.push(bracketsIndex + 1);
    } else if (stack.pop() !== bracketsIndex) {
      return false;
    }
  }
  return stack.length === 0;
};

const isDecimalNumberValid = formula => {
  if (formula.includes('.')) {
    const decimalRegexPattern = new RegExp(/(\d\.\d)/g);
    const decimalInComponentRegexPattern = new RegExp(
      /(C\d\.\d)|(\d\)\.\d)|(\d\.\(\d)/g
    );
    if (
      !decimalRegexPattern.test(formula) ||
      decimalInComponentRegexPattern.test(formula)
    ) {
      return false;
    }
    const splitFormula = formula.split('.');
    let checkMultipleDecimal = true;
    const validNumberRegexPattern = new RegExp(/^\d+$/);
    for (let i = 0; i < splitFormula.length; i++) {
      const splitFormulaElement = splitFormula[i];
      if (
        validNumberRegexPattern.test(splitFormulaElement) &&
        formula.includes('.' + splitFormulaElement + '.')
      ) {
        checkMultipleDecimal = false;
        break;
      }
    }
    return checkMultipleDecimal;
  }
  return true;
};

export const validateFormula = formula => {
  const formulaRegexPattern = new RegExp(
    /^([(]?[C]?[0-9+]+)([+*\/()-]+[(]?[C]?[0)-9+]+)*$/g
  );
  const formatCRegexPattern = new RegExp(/C\D/);
  const specialCharactersRegexPattern = new RegExp(/^([C0-9+-/.()*])*$/);
  const completeFormulaRegexPattern = new RegExp(/[0-9)]$/);
  const numberWithBracketCombinationRegexPattern = new RegExp(/\d\(|\)\d|\)C/);
  const consecutiveOperatorsRegexPattern = new RegExp(/([+*\/.-][+*\/.-])/);
  const hasDivisionByZero = new RegExp(/\/\(C\d{1,2}\/?0\)(?!\.\d)|C\d{1,2}\/0(?!\.\d)|C\d{1,2}\/\(0\)/);
  const hasDivisionByCTimesZero = new RegExp(/\/\(C\d{1,2}\*0\)/);
  const numberFollowedByCDenominatorZero = new RegExp(/([0-9]+C.*)\/\([^)]*\*0\)|\/\(.*\*0\)|\/\(.*\*\([^)]*0\)\)/);

  const finalValidation =
    !hasDivisionByZero.test(formula) &&
    !hasDivisionByCTimesZero.test(formula) &&
    formulaRegexPattern.test(formula) &&
    !formatCRegexPattern.test(formula) &&
    specialCharactersRegexPattern &&
    isParenthesisBalancedAndNotEmpty(formula) &&
    isDecimalNumberValid(formula) &&
    completeFormulaRegexPattern.test(formula) &&
    !numberWithBracketCombinationRegexPattern.test(formula) &&
    !consecutiveOperatorsRegexPattern.test(formula) &&
    !numberFollowedByCDenominatorZero.test(formula);
  return finalValidation;
};

export const errorType = {
  emptyFormula: 'newCareplan_view.complex_score.validation.emptyFormula',
  invalidFormula: 'newCareplan_view.complex_score.validation.invalidFormula',
  componentSelected:
    'newCareplan_view.complex_score.validation.componentSelected',
  emptyDescription:
    'newCareplan_view.complex_score.validation.empty_formula_description',
  oneComponentIncluded:
    'newCareplan_view.complex_score.validation.oneComponentIncluded',
};

export const equationValidation = (
  formula,
  componentCollection,
  setWithinIntervals = null
) => {
  if (formula === '') {
    return {
      hasError: true,
      errorMessage: errorType.emptyFormula,
    };
  }

  if (!validateFormula(formula)) {
    return {
      hasError: true,
      errorMessage: errorType.invalidFormula,
    };
  }

  if (formula.toUpperCase().indexOf('C') === -1) {
    return {
      hasError: true,
      errorMessage: errorType.oneComponentIncluded,
    };
  }

  const calculate = equationFunction(
    formula,
    componentCollection,
    setWithinIntervals
  );

  if (Number.isNaN(calculate)) {
    return {
      hasError: true,
      errorMessage: errorType.componentSelected,
    };
  }

  return { hasError: false, errorMessage: '' };
};
