import React from 'react';
import classNames from 'classnames';
import TextInput from '../TextInput/TextInput';
import CheckboxRadio from '../../utils/CheckboxRadio';
import '../../styles/main.scss';
import inputField from '../../utils/inputField';
import maxCharLimit from '../../utils/maxcharlimits.json'
import { CheckboxProps } from './Checkbox.type';

const Checkbox = ({
  id,
  name,
  value,
  label,
  isDisabled,
  disableActionOnly,
  isIndeterminate,
  variant,
  inputWidth,
  checked,
  handleOnChange,
  valueInput,
  handleInputOnChange,
  hasInlineInput,
  placeholder,
}: CheckboxProps): JSX.Element => {
  /* Classes
  ========================================================================== */
  const labelStyles = classNames({
    'checkbox-radio__label': true,
    'checkbox-radio__label__non-editable':
      variant === CheckboxRadio.NonEditable,
    disabled: isDisabled,
  });

  const inputStyles = classNames({
    'checkbox-radio__input': true,
    'checkbox-radio__input__non-editable':
      variant === CheckboxRadio.NonEditable,
    disabled: isDisabled,
  });

  const svgStyles = classNames({
    'checkbox-radio__square-svg': true,
    'checkbox-radio__square-svg__threshold':
      variant === CheckboxRadio.Threshold,
    'checkbox-radio__square-svg__inline-input':
      variant === CheckboxRadio.InlineInput,
    'checkbox-radio__square-svg__non-editable':
      variant === CheckboxRadio.NonEditable,
  });

  return (
    <label
      className={labelStyles}
      htmlFor={`${id}`}
      style={{
        width:
          variant === CheckboxRadio.InlineInput ||
          (variant === CheckboxRadio.Threshold && hasInlineInput)
            ? '100%'
            : null,
      }}
    >
      <input
        type="checkbox"
        name={name}
        id={`${id}`}
        value={value}
        className={inputStyles}
        disabled={
          variant === CheckboxRadio.InlineInput
            ? true
            : isDisabled || disableActionOnly
        }
        onChange={handleOnChange}
        checked={
          variant === CheckboxRadio.InlineInput ? false : checked
        }
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={svgStyles}
      >
        <g fill="none" fillRule="evenodd">
          <path fill="#FFF" d="M0 0h24v24H0z" />
          <path
            fill="none"
            d={
              variant === CheckboxRadio.Default && isIndeterminate
                ? 'M0 0h24v24H0V0zm3.6 13.2v-2.4h16.8v2.4H3.6z'
                : 'M0 0h24v24H0V0zm18.625 4.8L20.4 5.96 10.639 19.2 3.6 12.199l1.57-1.4 5.21 5.183L18.625 4.8z'
            }
            stroke="none"
          />
        </g>
      </svg>
      {variant === CheckboxRadio.InlineInput || hasInlineInput ? (
        <div style={{ width: inputWidth }}>
          <TextInput
            id={id}
            name={name}
            value={valueInput}
            isDisabled={isDisabled}
            onChange={handleInputOnChange}
            placeholder={placeholder}
            variant={inputField.variant.CHAR_COUNT}
            maxLength={maxCharLimit.carePlan.multipleChoiceMaxCharLimit}
            maxChars={maxCharLimit.carePlan.multipleChoiceMaxCharLimit}
            autoComplete="off"
            handleOnChange={() => ''}
          />
        </div>
      ) : (
        label
      )}
    </label>
  );
};

Checkbox.defaultProps = {
  value: '',
  placeholder: '',
  isDisabled: false,
  isIndeterminate: false,
  variant: CheckboxRadio.Default,
  disableActionOnly: false,
  hasInlineInput: false,
};

export default Checkbox;
