import { ApiClient } from '@global/Services';
import { AxiosResponse } from 'axios';

import { FlashMessageTypes } from '@ui-common-files/types';
import { Patient } from '@type/Patient';
import serviceConfig from '../../../serviceConfig.json';
import { ExistingFilterPatient, PatientFetchData } from './PatientList.type';

export const getPatientsCountersRequest = () =>
  ApiClient.GET({
    url: serviceConfig.brokerService.getPatientsCounter.uri,
  });

export const getExistingFilterPatients = async ({
  openResultsModal,
  selectedFilter,
  actionState,
  patients,
  isNewPatient,
  setFilteredPatientsForCsvExport,
  setDisplayFlashMsg,
  t,
}: ExistingFilterPatient) => {
  if (!actionState || !actionState.sqlQuery) {
    return;
  }
  await ApiClient.GET({
    url: serviceConfig.brokerService.getPatientsByQuery.uri,
    payload: {
      openResultsModal,
      existingFilterSelected: selectedFilter.savedFilter.id,
      sql: actionState.sqlQuery.sql,
      params: JSON.stringify(actionState.sqlQuery.params),
      getRule: JSON.stringify(selectedFilter.savedFilter.rule),
      filterKey: {
        id: selectedFilter.savedFilter.id,
        name: selectedFilter.savedFilter.name,
      },
      query: JSON.stringify({
        start: 0,
        limit: patients.recordsTotal,
        dir: actionState.dir,
        column: actionState.column,
        search: actionState.search,
      }),
      isNewPatient,
    },
  })
    .then((response: AxiosResponse) => {
      if (response) {
        if (response.data?.data.length > 0) {
          setFilteredPatientsForCsvExport(
            response.data.data.map(
              (filteredPatient: Patient) => filteredPatient.id
            )
          );
        } else {
          setFilteredPatientsForCsvExport([]);
        }
      }
    })
    .catch(error => {
      setDisplayFlashMsg({
        display: true,
        content: t(error.response.data.content),
        type: error.response.data.type,
      });
    });
};

export const patientListFetchData = async ({
  setDisplayFlashMsg,
  setLoading,
  setPatients,
  t,
}: PatientFetchData) => {
  await ApiClient.GET({
    url: serviceConfig.patientService.index.uri,
    timeout: 30000,
  })
    .then((response: AxiosResponse) => {
      if (response?.data.permissionDeniedMessage) {
        setDisplayFlashMsg({
          display: true,
          content: t(response.data.permissionDeniedMessage),
          type: FlashMessageTypes.Warning,
        });
        setLoading(false);
        setPatients(null);
      }
    })
    .catch(error => {
      setLoading(false);
      setPatients(null);
      setDisplayFlashMsg({
        display: true,
        content: t(error.response.data.content),
        type: error.response.data.type,
      });
    });
};

export const getCustomFilteredPatients = async ({
  openResultsModal,
  selectedFilter,
  actionState,
  patients,
  isNewPatient,
  setDisplayFlashMsg,
  setFilteredPatientsForCsvExport,
  t,
}: ExistingFilterPatient) => {
  await ApiClient.POST({
    url: serviceConfig.brokerService.applyCustomFilter.uri,
    payload: {
      openResultsModal,
      customFilterSelected: selectedFilter.customFilter,
      customFilter: actionState.customFilter || {},
      query: JSON.stringify({
        start: 0,
        limit: patients.recordsTotal,
        dir: actionState.dir,
        column: actionState.column,
        search: actionState.search,
      }),
      isNewPatient,
    },
  })
    .then((response: AxiosResponse) => {
      if (response) {
        if (response.data.data.length > 0) {
          setFilteredPatientsForCsvExport(response.data.data);
        } else {
          setFilteredPatientsForCsvExport([]);
        }
      }
    })
    .catch(error => {
      setDisplayFlashMsg({
        display: true,
        content: t(error.response.data.content),
        type: error.response.data.type,
      });
    });
};
