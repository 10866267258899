import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import I18n from 'i18next';

import Tag from '../Tags/Tags';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';

import {
  inputContainerStyle,
  multiSelectStyles,
} from '../../utils/dropdownSelectStyles';
import { Position, ActionColors, TagsType, TagsVariant } from '../../utils';

const Control = ({ selectProps, children, ...innerProps }) => {
  const { isMaxCharsTextDisplayed, maxCharsText } = selectProps;
  return (
    <>
      <components.Control selectProps={selectProps} {...innerProps}>
        {children}
      </components.Control>
      {isMaxCharsTextDisplayed && (
        <div className="helperTextMultiselect">{maxCharsText}</div>
      )}
    </>
  );
};

const ValueContainer = ({ selectProps, children, ...innerProps }) => {
  return (
    <>
      <components.ValueContainer {...innerProps}>
        {children}
      </components.ValueContainer>
    </>
  );
};

/*
 * Replace the MultiValue default component from react-select
 */
const MultiValue = ({ data, removeProps, selectProps, ...innerProps }) => {
  const { tagVariant } = selectProps;
  const { hasMark } = selectProps;

  return (
    <components.MultiValue {...innerProps}>
      <Tag
        text={data.label}
        variant={tagVariant}
        hasMark={hasMark}
        fill={data.markColor}
        type={TagsType.ROUNDED}
        closeable
        onClose={removeProps.onClick}
      />
    </components.MultiValue>
  );
};

/*
 * Replace the Input default component from react-select
 */

/* The placeholder was addded in this replacement component
 * instead of using Placeholder component from react-select
 * so that the placeholder would be displayed also when the
 * Value Container holds at least one value.
 * On the other hand, the placeholder's color won't perfectly
 * match with the other Selects, even with the workaround of
 * changing the color of Input on customStyles Object.
 */
const Input = ({ isHidden, selectProps, ...innerProps }) => {
  if (isHidden) {
    return <components.Input {...innerProps} />;
  }

  const { maxChars } = selectProps;
  let charsCount = selectProps.value;
  if (charsCount === null) {
    charsCount = 0;
  } else {
    charsCount = selectProps.value.length;
  }

  return (
    <div style={inputContainerStyle}>
      <components.Input placeholder={selectProps.placeholder} {...innerProps} />
      {!selectProps.isSelectAll && (
        <span className="helperText">
          {charsCount} /{maxChars}
        </span>
      )}
    </div>
  );
};

/*
 * Replace the IndicatorsContainer default component from react-select
 */
const IndicatorsContainer = ({
  children,
  hasValue,
  selectProps,
  options,
  setValue,
  ...innerProps
}) => {
  const handleSelectAll = () => {
    setValue(options);
  };

  const { isLoading } = selectProps;
  const showSelectAll = !isLoading && hasValue && selectProps.isSelectAll;

  return (
    <components.IndicatorsContainer {...innerProps}>
      {showSelectAll && (
        <div
          className="borderButton"
          onClick={handleSelectAll}
          onPointerDown={handleSelectAll}
        >
          <IconButton
            type="button"
            name="files"
            size={20}
            onClick={() => {}}
            onPointerDown={() => {}}
            tooltipText={I18n.t('common_labels.label_selectAll_options')}
            tooltipPosition={Position.left}
          />
        </div>
      )}
      {children}
      {selectProps.hasError && !hasValue && (
        <Icon
          name="error"
          fill={ActionColors.ERROR}
          className="input__text__icon right-icon"
        />
      )}
    </components.IndicatorsContainer>
  );
};

/*
 * Replace the ClearIndicator default component from react-select
 */
const ClearIndicator = ({ selectProps, ...innerProps }) => {
  return (
    <components.ClearIndicator {...innerProps}>
      <div className="clearBorder">
        <IconButton
          type="button"
          name="clear"
          size={20}
          onClick={() => {}}
          onPointerDown={() => {}}
          tooltipText={I18n.t('common_labels.label_clear_selections')}
          tooltipPosition={Position.left}
        />
      </div>
    </components.ClearIndicator>
  );
};

const MultipleSelectTagSearchable = ({
  isDisabled,
  maxChars,
  placeholder,
  tagVariant,
  hasMark,
  isClearable,
  isSelectAll,
  defaultOptions,
  noOptionsMessage,
  value,
  changeValue,
  disableMenu,
  hasError,
}) => {
  const [menuOnClick, setMenuOnClick] = useState(disableMenu);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isMaxCharsTextDisplayed, setIsMaxCharsTextDisplayed] = useState(false);
  const maxCharsText = maxChars + '/' + maxChars;
  /* Handle opening of Menu on max selection of tags
  ========================================================================== */
  const disableOpenMenu = (tagsSelected, limit) => {
    if (tagsSelected >= limit) {
      setMenuOnClick(false);
      setIsSearchable(false);
      setIsMaxCharsTextDisplayed(true);
    } else {
      setMenuOnClick(true);
      setIsSearchable(true);
      setIsMaxCharsTextDisplayed(false);
    }
  };

  const handleOnChange = selected => {
    changeValue(selected);

    if (selected === null) {
      selected = [];
    }
    if (!isSelectAll) {
      disableOpenMenu(selected.length, maxChars);
    }
  };

  const filterOptions = inputValue => {
    return defaultOptions.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterOptions(inputValue));
      }, 1000);
    });

  return (
    <AsyncSelect
      defaultOptions={defaultOptions}
      loadOptions={promiseOptions}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      isMulti
      styles={multiSelectStyles}
      className="react-select-container"
      classNamePrefix="react-select"
      maxChars={maxChars}
      maxCharsText={maxCharsText}
      isMaxCharsTextDisplayed={isMaxCharsTextDisplayed}
      components={{
        Input,
        MultiValue,
        IndicatorsContainer,
        ClearIndicator,
        Control,
        ValueContainer,
      }}
      openMenuOnClick={menuOnClick}
      onChange={handleOnChange}
      placeholder={placeholder}
      tagVariant={tagVariant}
      hasMark={hasMark}
      isClearable={isClearable}
      isSelectAll={isSelectAll}
      noOptionsMessage={() => noOptionsMessage}
      value={value}
      hasError={hasError}
    />
  );
};

MultipleSelectTagSearchable.propTypes = {
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
    .isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSearchable: PropTypes.bool,
  maxChars: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  tagVariant: PropTypes.oneOf([TagsVariant.CONTAINED, TagsVariant.OUTLINED]),
  hasMark: PropTypes.bool.isRequired,
  isClearable: PropTypes.bool.isRequired,
  isSelectAll: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.string.isRequired,
  /** Can be null or an array */
  value: PropTypes.array,
  changeValue: PropTypes.func.isRequired,
  disableMenu: PropTypes.bool,
  hasError: PropTypes.bool,
};

MultipleSelectTagSearchable.defaultProps = {
  disableMenu: true,
  tagVariant: TagsVariant.CONTAINED,
  hasError: false,
};

export default MultipleSelectTagSearchable;
