import { useState, useEffect, DragEvent } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useDelaySearch } from '@global/Hooks';

import { useCarePathWayBuilderStore } from '../CarePathWayBuilder.store';
import { getCareTaskAPI } from './CareTaskList.api';

let abortController: AbortController;

const useCareTaskListService = () => {
  const { careTask, setCareTask, isAddingParent, setIsAddingParent } =
    useCarePathWayBuilderStore(
      useShallow(state => ({
        careTask: state.careTask,
        setCareTask: state.setCareTask,
        isAddingParent: state.isAddingParent,
        setIsAddingParent: state.setIsAddingParent,
      }))
    );

  const [keyword, setKeyword] = useState(careTask.keyword);

  useDelaySearch({
    input: careTask.keyword,
    callback: () => {
      setKeyword(careTask.keyword);
    },
  });

  useEffect(() => {
    if (careTask.items === null) {
      abortController = new AbortController();
      setCareTask({ ...careTask, isLoading: true });
      getCareTaskAPI(
        abortController.signal,
        careTask.keyword,
        careTask.page
      ).then(response => {
        if (response) {
          const { templates, templatesCount } = response.data;
          setCareTask({
            ...careTask,
            items: templates,
            totalRecords: templatesCount,
            isLoading: false,
          });
        }
      });
    }
    return () => abortController.abort();
  }, [keyword, careTask.page]);

  const onDragStart = (event: DragEvent<HTMLDivElement>, careTaskData) => {
    event.dataTransfer.setData(
      'application/reactflow',
      JSON.stringify(careTaskData)
    );
  };

  const onKeywordChange = e => {
    setCareTask({
      keyword: e.target.value,
      isLoading: true,
      totalRecords: 0,
      items: null,
      page: 1,
    });
    abortController.abort();
  };

  const hasNoResultsBySearch =
    careTask.keyword.length > 0 && careTask.items?.length === 0;
  const hasNoTemplates =
    careTask.keyword.length === 0 && careTask.items?.length === 0;

  const onPageChange = (page: number) =>
    setCareTask({
      ...careTask,
      items: null,
      page,
    });

  return {
    careTask,
    onDragStart,
    isAddingParent,
    onKeywordChange,
    setIsAddingParent,
    hasNoResultsBySearch,
    hasNoTemplates,
    onPageChange,
  };
};

export default useCareTaskListService;
