/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import '../../css/customFilters.css';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import ComplexSearch from '../../../caro-ui-commonfiles/components/ComplexSearch/DynamicBuilder';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import '../../css/modal-layout.css';
import queryOperators from '../../Utils/custonFiltersOperator.json';
import { ActionColors } from '../../../caro-ui-commonfiles/utils/colors';

const BuilderComponent = ({
  filters,
  rules,
  id,
  onChange,
  operator,
  onDelete,
}) => {
  const [queryCondition, setQueryCondition] = useState(operator);
  const [displayError, setDisplayError] = useState({
    display: false,
    message: '',
  });
  /** **** */
  const queryActions = {
    ON_SQL_CHANGE: 'on_sql_change',
    ON_RULE_CHANGE: 'on_union_change',
    ON_OPERATOR_CHANGE: 'on_operator_change',
    ON_ERROR_CHANGE: 'on_error_change',
  };

  const initialQueryValues = {
    rules: null,
    sql: null,
    operator: queryOperators.EXCEPT_OPERATOR,
    hasError: false,
  };

  const reducerQueryHandler = (state, action) => {
    switch (action.type) {
      case queryActions.ON_SQL_CHANGE:
        return {
          ...state,
          sql: action.payload,
        };
      case queryActions.ON_RULE_CHANGE:
        return {
          ...state,
          rules: action.payload,
        };
      case queryActions.ON_OPERATOR_CHANGE:
        return {
          ...state,
          operator: action.payload,
        };
      case queryActions.ON_ERROR_CHANGE:
        return {
          ...state,
          hasError: action.payload,
        };
    }
  };
  const [builderState, dispatchBuilderHandler] = useReducer(
    reducerQueryHandler,
    initialQueryValues
  );

  /** ********************************************************************************** */

  useEffect(() => {
    dispatchBuilderHandler({
      type: queryActions.ON_OPERATOR_CHANGE,
      payload: queryCondition,
    });
  }, [queryCondition]);

  useEffect(() => {
    onChange(builderState);
  }, [builderState]);

  const validation = ({ node, error }) => {
    if (node.__.filter === null) {
      setDisplayError({
        display: true,
        message: `Error : Empty filter`,
      });
    } else {
      setDisplayError({
        display: true,
        message: `${node.__.filter.label} : ${
          error[0] === 'string_empty'
            ? I18n.t('parsley.requiredField')
            : error[0]
        }`,
      });
    }
  };

  return (
    <>
      <div className="custom-filter__union-container">
        <div className="custom-filter__union-button">
          <div
            className={`custom-filter__intersect-button${
              queryCondition === queryOperators.EXCEPT_OPERATOR ? ' active' : ''
            }`}
            onClick={() => setQueryCondition(queryOperators.EXCEPT_OPERATOR)}
          >
            EXCEPT
          </div>
          <div
            className={`custom-filter__intersect-button${
              queryCondition === queryOperators.INTERSECT_OPERATOR
                ? ' active'
                : ''
            }`}
            onClick={() => setQueryCondition(queryOperators.INTERSECT_OPERATOR)}
          >
            INTERSECT
          </div>
        </div>
      </div>
      <div className="custom-filter__builder-container">
        <div className="custom-filter__button-container remove">
          {displayError.display && (
            <Flex
              alignItems="center"
              otherStyles={{ color: ActionColors.ERROR, fontSize: 12 }}
            >
              <Box margin="0 5px 5px 0" height={16}>
                <Icon name="error" size={20} fill={ActionColors.ERROR} />
              </Box>
              {displayError.message}
            </Flex>
          )}
          <button
            className="custom-filter__remove-query-button"
            onClick={onDelete}
            type="button"
          >
            <Box margin="0 10px 0 0" height={14}>
              <Icon name="minus" size={14} fill="#fff" />
            </Box>
            {I18n.t('customFilters_view.label_remove_customFilter')}
          </button>
        </div>
        <ComplexSearch
          filters={filters}
          rules={rules}
          getSql={sql => {
            dispatchBuilderHandler({
              type: queryActions.ON_SQL_CHANGE,
              payload: sql,
            });
          }}
          getRules={queryRules => {
            dispatchBuilderHandler({
              type: queryActions.ON_RULE_CHANGE,
              payload: queryRules,
            });
          }}
          hide={false}
          hasError={error => {
            dispatchBuilderHandler({
              type: queryActions.ON_ERROR_CHANGE,
              payload: error,
            });
            if (!error) {
              setDisplayError({
                display: false,
                message: '',
              });
            }
          }}
          id={id}
          onValidateError={validation}
        />
      </div>
    </>
  );
};

BuilderComponent.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.object).isRequired,
  rules: PropTypes.objectOf(PropTypes.object).isRequired,
  operator: PropTypes.oneOf([
    queryOperators.EXCEPT_OPERATOR,
    queryOperators.INTERSECT_OPERATOR,
  ]).isRequired,
  onDelete: PropTypes.func.isRequired,
};
export default BuilderComponent;
