import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/main.scss';

const SelectedFiles = ({ numberFiles, message }) => {

  return (
    <div className='selected-files'>
      <span className='number-files files-are-selected'>{numberFiles}</span>
      <span className='files-are-selected'>{message}</span>
    </div>
  );
};

SelectedFiles.propTypes = {
  numberFiles: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
};

export default SelectedFiles;
