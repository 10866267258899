export enum TimeOfDay {
  MORNING_START = 6,
  MORNING_END = 12,
  AFTERNOON_START = 12,
  AFTERNOON_END = 18,
  EVENING_START = 18,
  EVENING_END = 21,
  NIGHT_START = 21,
  NIGHT_END = 24,
}
