import { checkLogicJumpsThresholdsAndScore } from "@global/Careplan/Components/Utils";

export const checkIfLogicJumpsThresholdsAndScore = async (
  pathwayCaretasks,
  currentStep,
  setCurrentStep,
  setPathwayHasLogicJumpsThresholdsAndScore
) => {
  const hasLogicJumpsThresholdsAndScore = checkLogicJumpsThresholdsAndScore(
    pathwayCaretasks
  );
  if (!hasLogicJumpsThresholdsAndScore) {
    setCurrentStep(currentStep + 1);
  }
  setPathwayHasLogicJumpsThresholdsAndScore(hasLogicJumpsThresholdsAndScore);
  return hasLogicJumpsThresholdsAndScore;
};
