import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { selectMessages } from './FlashMessages.reducer';
import { FlashMessagesWrapperProps } from './FlashMessage.type';

import FlashMessage from './FlashMessage';

import './FlashMessagesWrapper.style.scss';

function FlashMessagesWrapper({
  isModalMessage = false,
  hideFlashMessage,
}: FlashMessagesWrapperProps) {
  const { t } = useTranslation();
  const messages = useSelector(selectMessages);
  const messagesBasedOnView = messages.filter(message => {
    return isModalMessage ? !!message.isModalMessage : !message.isModalMessage;
  });

  const cssClasses = classNames({
    'flash-messages-container': true,
    'flash-messages': messagesBasedOnView.length > 0,
  });

  return (
    <div className={cssClasses}>
      {messagesBasedOnView.map(message => (
        <FlashMessage
          key={message.id}
          id={message.id}
          message={message.messageKey ? t(message.messageKey) : message.message}
          type={message.type}
          onClick={message.onClick}
          autoHide={message.autoHide}
          hideLeftIcon={message.hideLeftIcon}
          timeout={message.timeout}
          hideCloseButton={message.hideCloseButton}
          hideFlashMessage={hideFlashMessage}
        />
      ))}
    </div>
  );
}

export default FlashMessagesWrapper;
