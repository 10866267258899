/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';

import Position from '../../utils/positions';

import { TooltipType } from './Tooltip.type';

import '../../styles/main.scss';

function Tooltip({
  position,
  content,
  children,
  show = true,
}: TooltipType): JSX.Element {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [isTouchDevice] = useState(
    localStorage.getItem('isTouchDevice') || false
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const bubbleRef = useRef<HTMLDivElement>(null);

  const [bubblePosition, setBubblePosition] = useState({
    top: 0,
    left: 0,
  });

  const positionToTop = () => {
    const bubbleHeight = bubbleRef.current.offsetHeight;
    const topPosition =
      wrapperRef.current.getBoundingClientRect().top - bubbleHeight - 5;
    const leftPosition =
      wrapperRef.current.getBoundingClientRect().left +
      wrapperRef.current.offsetWidth / 2;
    return {
      top: topPosition,
      left: leftPosition,
    };
  };

  const positionToBottom = () => {
    const topPosition =
      wrapperRef.current.getBoundingClientRect().top +
      wrapperRef.current.offsetHeight +
      5;
    const leftPosition =
      wrapperRef.current.getBoundingClientRect().left +
      wrapperRef.current.offsetWidth / 2;
    return {
      top: topPosition,
      left: leftPosition,
    };
  };

  const positionToRight = () => {
    const leftPosition =
      wrapperRef.current.getBoundingClientRect().left +
      wrapperRef.current.offsetWidth +
      5;
    const topPosition =
      wrapperRef.current.getBoundingClientRect().top +
      wrapperRef.current.offsetHeight / 2;
    return {
      top: topPosition,
      left: leftPosition,
    };
  };

  const positionToLeft = () => {
    const bubbleWidth = bubbleRef.current.offsetWidth;
    const leftPosition =
      wrapperRef.current.getBoundingClientRect().left - bubbleWidth - 5;
    const topPosition =
      wrapperRef.current.getBoundingClientRect().top +
      wrapperRef.current.offsetHeight / 2;
    return {
      top: topPosition,
      left: leftPosition,
    };
  };

  useEffect(() => {
    const addPosition = () => {
      if (displayTooltip) {
        switch (position) {
          case Position.top:
            setBubblePosition(positionToTop);
            break;
          case Position.right:
            setBubblePosition(positionToRight);
            break;
          case Position.left:
            setBubblePosition(positionToLeft);
            break;
          default:
            setBubblePosition(positionToBottom);
            break;
        }
      }
    };
    addPosition();
  }, [displayTooltip]);

  const hideTooltip = () => {
    setDisplayTooltip(false);
  };

  const showTooltip = () => {
    setDisplayTooltip(true);
  };

  const toggleTooltip = () => {
    setDisplayTooltip(!displayTooltip);
  };

  if (!show) return null;

  return (
    <div className="custom__tooltip">
      {displayTooltip && (
        <div
          className={`custom__tooltip__bubble custom__tooltip--${position}`}
          style={{
            top: bubblePosition.top,
            left: bubblePosition.left,
          }}
          ref={bubbleRef}
        >
          <div className="custom__tooltip__content">{content}</div>
        </div>
      )}
      {isTouchDevice && (
        <div
          className="custom__tooltip__trigger"
          onTouchStart={toggleTooltip}
          ref={wrapperRef}
        >
          {children}
        </div>
      )}
      {!isTouchDevice && (
        <div
          className="custom__tooltip__trigger"
          onPointerEnter={showTooltip}
          onPointerLeave={hideTooltip}
          ref={wrapperRef}
        >
          {children}
        </div>
      )}
    </div>
  );
}

Tooltip.defaultProps = {
  position: Position.bottom,
};

export default Tooltip;
