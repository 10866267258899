/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from 'recharts';

import I18n from 'i18next';
import { PrimaryColor, Greys } from '@ui-common-files/utils';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';

import { getHealthIndicatorColor } from '@utils/Careplan';

const CustomizedAxisTick = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

const CareplanGrapComponent = ({ data, history }) => {
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    setGraphData(data);
  }, [data]);

  const CareTaskGraphDotColor = props => {
    const { cx, cy, payload } = props;
    const color = getHealthIndicatorColor(payload.occurrenceHealthIndicator);

    return <circle cx={cx} cy={cy} r={5} stroke={color} fill={color} />;
  };

  return (
    <Box width="100%" height="200px">
      {graphData && (
        <ResponsiveContainer id={graphData.id}>
          <LineChart
            width={500}
            height={100}
            data={graphData.graphData}
            margin={{
              top: 15,
              right: 10,
              left: 10,
              bottom: 5,
            }}
          >
            {data.graphData[0] && (
              <CartesianGrid vertical={false} stroke={Greys.LIGHT_GREY_10} />
            )}
            <XAxis dataKey="unixEpochs" tick={<CustomizedAxisTick />} />
            {data.isEvaluating && data.Score === null && (
              <YAxis
                dataKey="score"
                type="number"
                yAxisId="1"
                hide
                label={{
                  value: I18n.t('analytic_view.score_graph_yAxis'),
                  angle: -90,
                  position: 'insideLeft',
                  offset: -5,
                }}
              />
            )}
            {data.isEvaluating && data.Score !== null && (
              <YAxis
                dataKey="score"
                type="number"
                yAxisId="1"
                label={{
                  value: I18n.t('analytic_view.score_graph_yAxis'),
                  angle: -90,
                  position: 'insideLeft',
                  offset: -5,
                }}
              />
            )}
            <Tooltip cursor={graphData.graphData.length > 0} />
            {data.isEvaluating && (
              <Line
                yAxisId="1"
                dataKey="score"
                stroke={Greys.LIGHT_GREY_75}
                animationDuration={300}
                strokeWidth={1}
                dot={<CareTaskGraphDotColor />}
              />
            )}
            {graphData.graphData && graphData.graphData.length > 0 && (
              <Brush
                dataKey="name"
                height={10}
                stroke={PrimaryColor.MAIN_COLOR}
                travellerWidth={30}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

CareplanGrapComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CareplanGrapComponent;
