import React from 'react';
import { Handle, Position } from 'reactflow';

import { Box } from '@ui-common-files/components';

import useCareTaskNodeSourceHandleService from './CareTaskNodeSourceHandle.service';
import { CareTaskNodeSourceHandleProps } from './CareTaskNodeSourceHandle.types';
import { MAX_INCOMING_DEPTH } from '../../CarePathWayBuilder.constant';

const CareTaskNodeSourceHandle: React.FC = ({
  schema,
  index,
}: CareTaskNodeSourceHandleProps) => {
  const { isHandleConnectable, incomingDepth } =
    useCareTaskNodeSourceHandleService(schema.id);

  return (
    <Handle
      id={schema.id}
      position={Position.Bottom}
      type="source"
      className="scoring-schema_handle"
      isConnectable={isHandleConnectable}
      style={{
        left: index * 50 + 49,
        bottom: -4,
        backgroundColor: schema.color,
        opacity: MAX_INCOMING_DEPTH > incomingDepth ? 1 : 0.5,
      }}
    >
      <Box
        className="scoring-schema_text"
        otherStyles={{
          backgroundColor: schema.bgColor,
          color: schema.color,
        }}
      >
        {schema.id}
      </Box>
    </Handle>
  );
};

export default CareTaskNodeSourceHandle;
