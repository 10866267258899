import { useTranslation } from 'react-i18next';

export const formatDistanceToNow = (previousDate: Date) => {
  const currentDate = new Date();

  const seconds = Math.floor(
    (currentDate.getTime() - previousDate.getTime()) / 1000
  );

  const { t } = useTranslation();

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return `${interval} ${t('timeAgo.years')}`;
  } else if (interval === 1) {
    return `${interval} ${t('timeAgo.year')}`;
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} ${t('timeAgo.months')}`;
  } else if (interval === 1) {
    return `${interval} ${t('timeAgo.month')}`;
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} ${t('timeAgo.days')}`;
  } else if (interval === 1) {
    return `${interval} ${t('timeAgo.day')}`;
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} ${t('timeAgo.hours')}`;
  } else if (interval === 1) {
    return `${interval} ${t('timeAgo.hour')}`;
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} ${t('timeAgo.minutes')}`;
  } else if (interval === 1) {
    return `${interval} ${t('timeAgo.minute')}`;
  }

  return `${Math.floor(seconds)} ${t('timeAgo.seconds')}`;
};
