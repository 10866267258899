import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import IconButton from '../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import TextInput from '../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import TagScore from './TagScore';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';
import { equationValidation } from './ComplexScoringValidations';
import { ComplexScoringType } from './ComplexScoring.type';
import { getOverallScore, getSectionScores } from './ComplexScoring.utils';

function OverallScore({
  setWithinIntervals,
  withinIntervals,
  componentCollection,
  scoringFormulas,
  setScoringFormulas,
  isEvaluatingToggle,
}): JSX.Element {
  const { t } = useTranslation();
  const overallScore = getOverallScore(scoringFormulas);

  const deleteFormula = () => {
    setScoringFormulas(getSectionScores(scoringFormulas));
  };

  const onChangeOverallScoreInput = value => {
    setScoringFormulas(prevFormulas =>
      prevFormulas.map(formula =>
        formula.scoringTypeId === ComplexScoringType.OverallScore
          ? {
              ...formula,
              equation: value,
            }
          : formula
      )
    );
  };

  const onBlurOverallScoreInput = value => {
    const validationResult = equationValidation(
      value,
      componentCollection,
      setWithinIntervals
    );

    setScoringFormulas(prevFormulas =>
      prevFormulas.map(formula =>
        formula.scoringTypeId === ComplexScoringType.OverallScore
          ? {
              ...formula,
              equation: value,
              equationHasError: validationResult.hasError,
              equationErrorMessage: validationResult.errorMessage,
            }
          : formula
      )
    );
  };

  return (
    <>
      <Flex otherStyles={{ flex: '1' }}>
        <TagScore isTotalScoreTag>
          {t('newCareplan_view.complex_score.total_score.text')}
        </TagScore>

        <Box width="70%">
          <TextInput
            value={overallScore.equation}
            name="totalScore"
            placeholder={t(
              'newCareplan_view.complex_score.total_score.placeholder'
            )}
            handleOnChange={e =>
              onChangeOverallScoreInput(e.target.value.toUpperCase())
            }
            onBlur={e => onBlurOverallScoreInput(e.target.value.toUpperCase())}
            hasError={overallScore.equationHasError}
            validationMessage={t(overallScore.equationErrorMessage)}
          />
        </Box>
        <Box className="thresholdIconDeleteScore">
          <IconButton
            onClick={deleteFormula}
            name="delete"
            size={30}
            fill={ActionColors.DELETE}
            tooltipText={t('toolTipsText.delete')}
          />
        </Box>
      </Flex>
      {!isEvaluatingToggle && (
        <Box className="error-container">
          <div
            style={{ margin: '5px 134px 0', width: '70%' }}
            className="templateRequired "
          >
            {t('newCareplan_view.complex_score.activate_health_indicator')}
          </div>
        </Box>
      )}
    </>
  );
}

export default OverallScore;
