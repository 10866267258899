import * as moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/ar';
import 'moment/locale/pt-br';
import 'moment/locale/it';
import 'moment/locale/en-gb';

import { LanguageItems } from '@type/Common';

export default function updateLocale(userLang: string) {
  switch (userLang) {
    case LanguageItems.Deutsch:
      moment.locale('de');
      break;
    case LanguageItems.Français:
      moment.locale('fr');
      break;
    case LanguageItems.Arabic:
      moment.locale('ar');
      const symbolMap = {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '0': '0',
      };
      const numberMap = {
        '١': '1',
        '٢': '2',
        '٣': '3',
        '٤': '4',
        '٥': '5',
        '٦': '6',
        '٧': '7',
        '٨': '8',
        '٩': '9',
        '٠': '0',
      };
      moment.updateLocale('ar', {
        preparse: function (string) {
          return string
            .replace(/\u200f/g, '')
            .replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match) {
              return numberMap[match];
            })
            .replace(/،/g, ',');
        },
        postformat: function (string) {
          return string
            .replace(/\d/g, function (match) {
              return symbolMap[match];
            })
            .replace(/,/g, '،');
        },
      });
      break;
    case LanguageItems.Portuguese:
      moment.locale('pt-br');
      break;
    case LanguageItems.English:
      moment.locale('en-gb');
      break;
    case LanguageItems.Italiano:
      moment.locale('it');
      break;
    default:
      moment.locale('en-gb');
      break;
  }
}
