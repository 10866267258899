import React from 'react';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import FilterCriteriaValue from '../FilterCriteriaValue/FilterCriteriaValue';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import { FilterActions } from '../../../../../Utils/PatientFilter/PatientFilter.enum';
import IconButton from '../../../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import FilterCriteriaMultipleOperator from '../FilterCriteriaMultipleOperator/FilterCriteriaMultipleOperator';
import FilterCriteriaFieldSelector from '../FilterCriteriaFieldSelector/FilterCriteriaFieldSelector';
import FilterCriteriaOperator from '../FilterCriteriaOperator/FilterCriteriaOperator';
import { FilterCriteriaProps } from './FilterCriteria.type';

const FilterCriteria = ({ criteria, type }: FilterCriteriaProps) => {
  const { updateCriterias } = usePatientFilterContext();

  const onClickRemoveHandler = () => {
    updateCriterias({
      action: FilterActions.Remove,
      criteria: { ...criteria, type },
    });
  };

  return (
    <Flex className="filters__criteria">
      <Flex className="filters__criteria-block">
        <FilterCriteriaMultipleOperator criteria={criteria} />
        <FilterCriteriaFieldSelector criteria={criteria} type={type} />
        <FilterCriteriaOperator criteria={criteria} />
        <FilterCriteriaValue criteria={criteria} />
      </Flex>
      <Flex className="filters__criteria-delete">
        <IconButton
          name="delete"
          size={30}
          fill="red"
          onClick={onClickRemoveHandler}
          disabled={!criteria.position}
        />
      </Flex>
    </Flex>
  );
};

export default FilterCriteria;
