import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import I18n from 'i18next';

import { Box, Flex, Icon, TextInput } from '@ui-common-files/components';

import {
  inputField,
  MaxCharLimits,
  Greys,
  PrimaryColor,
} from '@ui-common-files/utils';
import { FlashMessageTypes } from '@ui-common-files/types';

import { FilterDropdownContentProps } from './FilterDropdownContent.type';

const FilterDropdownContent = ({
  showDropdown,
  toggleDropdown,
  showFilterSettings,
  setShowFilterSettings,
  advancedFilterList,
  selectAdvancedFilter,
  setDisplayFlashMsg,
}: FilterDropdownContentProps) => {
  const selectedFilter = useSelector(state => state.patientFilters);
  const [advancedFilterSearch, setAdvancedFilterSearch] = useState('');
  const [searchedFilters, setSearchedFilters] = useState([]);

  useEffect(() => {
    const searchFilters = (searchText: String) => {
      const filterResults = advancedFilterList.filter(advancedFilter =>
        advancedFilter.key.toUpperCase().includes(searchText.toUpperCase())
      );
      setSearchedFilters(filterResults);
    };
    searchFilters(advancedFilterSearch);
  }, [advancedFilterSearch]);

  const showCustomFilterSettings = () => {
    if (!showFilterSettings) {
      setShowFilterSettings(true);
      toggleDropdown();
      selectAdvancedFilter(selectedFilter.allFilters[0], 0);
    }
  };

  const applySavedFilter = (filter: any, index: number) => {
    selectAdvancedFilter(filter, index);
    if (filter.filterId !== 1) {
      setDisplayFlashMsg({
        display: true,
        content: I18n.t('filter_dropdown_list.advanced_filter_flash_message'),
        type: FlashMessageTypes.Info,
      });
    }
    setShowFilterSettings(false);
    toggleDropdown();
  };

  const Header = (
    <Box className="filters__dropdown__list-header">
      <Flex className="filters__dropdown__list-header-title">
        {I18n.t('filter_dropdown_list.create_or_select')}
        <button
          type="button"
          onClick={() => toggleDropdown()}
          className="filters__dropdown__list-header-close"
        >
          <Icon name="close" fill={Greys.DARK_GREY} size={20} />
        </button>
      </Flex>
      <TextInput
        value={advancedFilterSearch}
        id="Text"
        name="Text"
        placeholder={I18n.t('common_labels.label_search')}
        variant={inputField.variant.CHAR_COUNT}
        maxChars={MaxCharLimits.searchInputs.searchKeyword}
        handleOnChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setAdvancedFilterSearch(event.target.value);
        }}
        isDisabled={false}
        autoComplete={inputField.autocomplete.OFF}
        hasError={false}
        hasSuccess={false}
        validationMessage={''}
        type={undefined}
      />
      <Box className="filters__dropdown__list-header-subtitle">
        {I18n.t('filter_dropdown_list.saved_filter')}
      </Box>
    </Box>
  );

  const Footer = (
    <div onClick={showCustomFilterSettings}>
      <Flex
        className={`filters__dropdown__list-footer${
          showFilterSettings ? '-disabled' : ''
        }`}
      >
        <>
          <Icon
            name="Add"
            size={16}
            fill={
              showFilterSettings ? Greys.LIGHT_GREY_50 : PrimaryColor.MAIN_COLOR
            }
          />
          {I18n.t('filter_dropdown_list.create_filter')}
        </>
      </Flex>
    </div>
  );

  const renderAdvancedFilterList = (filters: Array<any>) => {
    return filters.map((filter, index) => {
      return (
        <li
          className="filters__dropdown__list-content-saved-filter"
          onClick={e => applySavedFilter(filter, index)}
        >
          {filter.key}
        </li>
      );
    });
  };

  return (
    <Flex
      className={`filters__dropdown__list filters__dropdown__list__${showDropdown}`}
    >
      {Header}
      <Box className="filters__dropdown__list-content-container">
        <ul>
          {renderAdvancedFilterList(
            advancedFilterSearch === '' ? advancedFilterList : searchedFilters
          )}
        </ul>
      </Box>
      {Footer}
    </Flex>
  );
};

export default FilterDropdownContent;
