const fontsProperty = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: 16,
  lineHeight: 1.5,
};

export const colors = {
  mainColor: '#28B4E6',
  mainColorLight: '#E4F2F7',
  darkGrey: '#323232',
  lightGrey: '#787878',
  lightGrey20: '#ebebeb',
  lightGrey50: '#cdcdcd',
  white: '#ffffff',
  errorColor: '#e96464',
};

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
};

// ======================
// Default Styles
export const customStyles = {
  control: (base, { isDisabled, isFocused, selectProps }) => ({
    ...base,
    height: 34,
    minHeight: 34,
    padding: '0 0 0 7px',
    backgroundColor: isDisabled ? colors.lightGrey20 : colors.white,
    boxShadow: 'none',
    borderRadius: 2,
    borderColor: selectProps.hasError
      ? colors.errorColor
      : isFocused
      ? colors.mainColor
      : colors.lightGrey50,
    '&:hover': {
      borderColor: selectProps.hasError
        ? colors.errorColor
        : isFocused
        ? colors.mainColor
        : colors.lightGrey,
    },
    ...fontsProperty,
  }),
  placeholder: base => ({
    ...base,
    color: colors.lightGrey,
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 0,
  }),
  clearIndicator: base => ({
    ...base,
    display: 'none',
  }),
  valueContainer: base => ({
    ...base,
    padding: '2px 8px 2px 0',
  }),
  singleValue: base => ({
    ...base,
    color: colors.darkGrey,
  }),
  menu: base => ({
    ...base,
    boxShadow: 'none',
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: 0,
    marginTop: 0,
    padding: 0,
  }),
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  option: (base, { isSelected, data }) => ({
    ...base,
    color: data.isNone ? colors.lightGrey : colors.darkGrey,
    backgroundColor: isSelected ? colors.mainColorLight : colors.white,
    borderBottom: 0,
    padding: '5px 10px',
    '&:hover': {
      backgroundColor: colors.mainColorLight,
    },
    ...fontsProperty,
  }),
  noOptionsMessage: base => ({
    ...base,
    ...fontsProperty,
  }),
};
// ======================

// ======================
// Dropdown
export const dropdownStyles = (fontSize, heightControl) => ({
  ...customStyles,
  control: (base, { isFocused, isDisabled, selectProps }) => ({
    ...base,
    height: heightControl,
    minHeight: heightControl,
    padding: '0 6px 0 7px',
    backgroundColor: isDisabled ? colors.lightGrey20 : colors.white,
    boxShadow: 'none',
    borderRadius: 2,
    borderColor: selectProps.hasError
      ? colors.errorColor
      : isFocused
      ? colors.mainColor
      : colors.lightGrey50,
    '&:hover': {
      borderColor: selectProps.hasError
        ? colors.errorColor
        : isFocused
        ? colors.mainColor
        : colors.lightGrey,
    },
    ...fontsProperty,
    fontSize,
  }),
});
// ======================

// ======================
// SingleSelectLeftIcon
export const dataWithDotStyles = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#28B4E6',
  borderRadius: 10,
  marginRight: 10,
  height: 10,
  width: 10,
};
// ======================

// ======================
// SingleSelectLeftLabel
export const groupLabelStyles = {
  display: 'flex',
  alignItems: 'center',
  width: 64,
  height: 24,
  backgroundColor: '#E4F2F7',
  textAlign: 'center',
  border: '1px solid #ffffff',
  padding: '0 5px',
};

export const numberLabelStyles = {
  width: 40,
};

export const dataLabelStyles = {
  paddingLeft: 5,
};
// ======================

// ======================
// Default Searchable Styles
export const searchableStyles = {
  ...customStyles,
  dropdownIndicator: base => ({
    ...base,
    padding: 0,
    display: 'none',
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
  }),
  menu: (base, { isLoading }) => ({
    ...base,
    boxShadow: 'none',
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: 0,
    marginTop: 0,
    padding: 0,
    display: isLoading ? 'none' : 'block',
  }),
};
// ======================

// ======================
// SingleSelectQuestionSearchable
export const optionStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
// ======================

// ======================
// Default Careplan Styles
export const careplanStyles = {
  ...searchableStyles,
  option: (base, { isSelected }) => ({
    ...base,
    color: colors.darkGrey,
    backgroundColor: isSelected ? colors.mainColorLight : colors.white,
    borderBottom: 0,
    padding: '5px 10px',
    '&:hover': {
      backgroundColor: colors.mainColorLight,
    },
    fontFamily: 'Open Sans, sans-serif',
    fontSize: 16,
    lineHeight: 1.5,
    '.option__container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexFlow: 'column',
    },
    '.option__row': {
      display: 'flex',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      marginBottom: 5,
      marginTop: 5,
      ':nth-of-type(2)': {
        minHeight: 24,
        fontSize: 12,
        lineHeight: 1,
        color: colors.lightGrey,
        '.option__patients': {
          flex: 4,
        },
      },
    },
    '.option__column': {
      display: 'flex',
      alignItems: 'flex-end',
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1,
      '&--lg': {
        flex: 13,
      },
    },
    '.option__tag': {
      display: 'inline-block',
      marginRight: 5,
      marginBottom: 5,
    },
  }),
};
// ======================

// ======================
// MultiSelect elements style
export const inputContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '100%',
};

export const inputCounterStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: '100%',
};

export const selectAllStyle = {
  color: colors.mainColor,
  cursor: 'pointer',
  fontSize: 12,
  padding: 0,
  marginRight: 20,
};
// ======================

// ======================
// Default MultiSelect Styles
export const multiSelectStyles = {
  ...searchableStyles,
  container: base => ({
    ...base,
    minHeight: 1,
  }),
  control: (base, { isFocused, isDisabled, selectProps }) => ({
    ...base,
    maxHeight: 200,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '5px 5px 5px 10px',
    minHeight: 1,
    backgroundColor: isDisabled ? colors.lightGrey20 : colors.white,
    boxShadow: 'none',
    borderRadius: 2,
    borderColor:
      selectProps && selectProps.hasError
        ? colors.errorColor
        : isFocused
        ? colors.mainColor
        : colors.lightGrey50,
    '&:hover': {
      borderColor:
        selectProps && selectProps.hasError
          ? colors.errorColor
          : isFocused
          ? colors.mainColor
          : colors.lightGrey,
    },
    '&:hover': {
      borderColor:
        selectProps && selectProps.maxCharsText ? isFocused : colors.lightGrey,
    },

    alignItems: 'inherit',
    flexDirection: 'row',
    ...fontsProperty,
  }),
  placeholder: base => ({
    ...base,
    color: colors.mainColor,
    margin: 0,
    display: 'none',
    fontSize: 9,
  }),
  input: base => ({
    ...base,
    minHeight: 1,
    padding: 0,
    margin: 0,
    display: 'block',
    color: colors.darkGrey,
  }),
  indicatorsContainer: base => ({
    ...base,
    flex: 1,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    border: 'none',
    paddingRight: 5,
    alignSelf: 'center',
    padding: '0px 0px',
  }),
  dropdownIndicator: base => ({
    ...base,
    display: 'none',
  }),
  clearIndicator: base => ({
    ...base,
    minHeight: 1,
    color: `${colors.mainColor}`,
    cursor: 'pointer',
    fontSize: 12,
    padding: 0,
    '&:hover': {
      color: `${colors.mainColor}`,
    },
  }),
  valueContainer: base => ({
    ...base,
    flex: 13,
    minHeight: 1,
    padding: 0,
    display: 'flex',
    alignItems: 'flex-start',
    border: '1px solid transparent', // It makes this content visible instead of IndicatorsContainer content
  }),
  singleValue: base => ({
    ...base,
    minHeight: 1,
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: 'transparent',
    margin: 0,
  }),
  multiValueLabel: base => ({
    ...base,
    border: 'none',
    paddingRight: 5,
    paddingLeft: 0,
    whiteSpace: 'normal',
  }),
  multiValueRemove: base => ({
    ...base,
    display: 'none',
  }),
};
// ======================
