import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';
import { toLocalizeDate } from '../../../../../../caro-ui-commonfiles/utils/dateUtils';

import { formatDate } from '../../../../../Global/Date';

function ReportGeneratedDate(): JSX.Element {
  const { t } = useTranslation();

  const now: string = new Date().toString();
  const localeDate: string = toLocalizeDate(now);
  const localeTime: string = formatDate(now, 'HH:mm');

  return (
    <Box className="first-page_content-meta">
      {`${t('PDFReport.generatedOn')} ${localeDate} | ${localeTime}`}
    </Box>
  );
}

export default ReportGeneratedDate;
