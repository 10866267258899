import { AuxiliaryColors, PrimaryColor, TagsType, Greys } from '../../utils';

import { TagVariant } from './Tags.interface';

const variants: TagVariant = {
  [TagsType.SQUARED]: {
    lightBlue: {
      backgroundColor: PrimaryColor.MAIN_COLOR_10,
      color: PrimaryColor.MAIN_COLOR,
    },
    lightRed: {
      backgroundColor: AuxiliaryColors.RED_10,
      color: AuxiliaryColors.RED,
    },
  },
  [TagsType.ROUNDED]: {
    white: {
      backgroundColor: Greys.WHITE,
    },
  },
  [TagsType.ARROW]: {},
};

export default variants;
