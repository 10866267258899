import React from 'react';
import '../../css/role.css';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';
import Checkbox from '../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import { getActionIconName } from '../Careplans/CareplanHelperUtility';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';

const RoleMatrix = props => {
  const { actions } = props.value;
  const { actionsKeys } = props.value;
  const { componentTypes } = props.value;
  const { componentActions } = props.value;
  const { selectedActionCheckBoxes } = props.value;
  const { selectedComponentCheckBoxes } = props.value;

  const toggleCheckedProperty = (
    actionsForType,
    currentActionCheckBoxes,
    isChecked,
    isIndeterminate
  ) => {
    currentActionCheckBoxes.map((elem, index) => {
      const isMatched = actionsForType.some(
        arg => arg == elem.actionCheckboxId
      );
      if (isMatched) {
        elem.isChecked = isChecked;
        elem.isIndeterminate = isIndeterminate;
      }
      return elem;
    });
  };

  const handleComponentTypeCheckbox = e => {
    let currentActionCheckBoxes;
    const actionsForType = componentActions
      .filter(action => action.component.replace(/\s/g, '') == e.target.id)
      .map(elem => `${elem.component.replace(/\s/g, '')}_${elem.action}`);

    if (!e.target.checked) {
      currentActionCheckBoxes = selectedActionCheckBoxes;
      toggleCheckedProperty(
        actionsForType,
        currentActionCheckBoxes,
        false,
        false
      );
      props.setSelectedActionCheckBoxes(currentActionCheckBoxes);
    } else if (e.target.checked) {
      currentActionCheckBoxes = selectedActionCheckBoxes;
      toggleCheckedProperty(
        actionsForType,
        currentActionCheckBoxes,
        true,
        false
      );
      props.setSelectedActionCheckBoxes(currentActionCheckBoxes);
    }
  };

  const handleOnChange = e => {
    const checkedValue = e.target.checked;
    const { id } = e.target;
    handleComponentTypeCheckbox(e);
    if (e.target.name == 'actionCheckbox') {
      const updatedList = selectedActionCheckBoxes.map(elem => {
        if (elem.actionCheckboxId == id) {
          elem.isChecked = checkedValue;
        }
        return elem;
      });
      props.setSelectedActionCheckBoxes(updatedList);
      manipulateComponentCheckboxIndeterminateState(id, checkedValue);
    } else {
      const updatedList = selectedComponentCheckBoxes.map(elem => {
        if (elem.checkboxId == id) {
          elem.isChecked = checkedValue;
          elem.isIndeterminate = e.target.isIndeterminate;
        }
        return elem;
      });
      props.setSelectedComponentCheckBoxes(updatedList);
    }
  };

  const manipulateComponentCheckboxIndeterminateState = (id, isChecked) => {
    let updatedList;
    const componentId = id.split('_');
    const componentBasedActionCheckboxes = selectedActionCheckBoxes.filter(
      selId => selId.actionCheckboxId.includes(componentId[0])
    );
    const isOneUncheked = componentBasedActionCheckboxes.some(
      elem => elem.isChecked == false
    );
    const areAllUnchecked =
      componentBasedActionCheckboxes.filter(elem => elem.isChecked == false)
        .length == componentBasedActionCheckboxes.length;
    updatedList = selectedComponentCheckBoxes.map(elem => {
      if (elem.checkboxId == componentId[0]) {
        if (isOneUncheked) {
          elem.isIndeterminate = true;
          elem.isChecked = true;
        } else if (!isOneUncheked) {
          elem.isIndeterminate = false;
        }
        if (areAllUnchecked) {
          elem.isIndeterminate = false;
          elem.isChecked = false;
        }
      }
      return elem;
    });
    props.setSelectedComponentCheckBoxes(updatedList);
  };

  const renderIconWithAction = action => {
    const name = getActionIconName(action);
    return (
      <div className='roleMatrix-header-page-icon-all-actions'>
        <div>
          <Icon name={name} size={24} />
        </div>
        <div>{I18n.t(action)}</div>
      </div>
    );
  };

  const renderAction = () => {
    return actionsKeys.map(action => {
      if (!(action === 'actions.release')) {
        return renderIconWithAction(action);
      }
    });
  };

  const renderIndividualCheckbox = checkBoxObject => {
    return (
      <Flex
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        otherStyles={{ minWidth: 80, maxWidth: 80 }}
      >
        <div style={{ marginRight: -10 }}>
          {!checkBoxObject.isDisabled && (
            <Checkbox
              id={checkBoxObject.checkBoxId}
              name={checkBoxObject.name}
              label=''
              isIndeterminate={checkBoxObject.isIndeterminate}
              value={checkBoxObject.value}
              inputWidth={checkBoxObject.inputWidth}
              checked={checkBoxObject.checked}
              isDisabled={checkBoxObject.isDisabled}
              handleOnChange={handleOnChange}
              valueInput=''
              handleInputOnChange={() => true}
            />
          )}
        </div>
      </Flex>
    );
  };

  const renderComponentTypeCheckBoxes = componentTypeName => {
    const checkBoxId = componentTypeName;
    const shouldThisBeChecked = selectedComponentCheckBoxes.some(
      elem => elem.checkboxId == checkBoxId && elem.isChecked == true
    );
    const isIndeterminate = selectedComponentCheckBoxes.some(
      action =>
        action.checkboxId == componentTypeName && action.isIndeterminate == true
    );
    const checkBoxObject = {
      checkBoxId,
      name: 'componentCheckbox',
      isIndeterminate,
      value: shouldThisBeChecked,
      inputWidth: 200,
      checked: shouldThisBeChecked,
      isDisabled: false,
    };
    return renderIndividualCheckbox(checkBoxObject);
  };

  const renderActionCheckBoxes = componentType => {
    const actionsForType = componentActions.filter(action => {
      return action.component == I18n.t(componentType.name);
    });
    return actions.map((action, i) => {
      const actionCheckBoxIdCollection = [];
      const hasMatchingAction = actionsForType.some(
        elem => elem.action === action
      );
      const checkBoxId = `${I18n.t(componentType.name).replace(
        /\s/g,
        ''
      )}_${action}`;
      const shouldThisBeChecked = selectedActionCheckBoxes.some(
        elem => elem.actionCheckboxId === checkBoxId && elem.isChecked === true
      );
      actionCheckBoxIdCollection.push(checkBoxId);
      if (hasMatchingAction) {
        const checkBoxObject = {
          checkBoxId,
          name: 'actionCheckbox',
          isIndeterminate: false,
          value: shouldThisBeChecked,
          inputWidth: 200,
          checked: shouldThisBeChecked,
          isDisabled: false,
        };
        return renderIndividualCheckbox(checkBoxObject);
      }
      const checkBoxObject = {
        checkBoxId,
        name: 'actionCheckbox',
        isIndeterminate: false,
        value: '',
        inputWidth: 200,
        checked: false,
        isDisabled: true,
      };
      return renderIndividualCheckbox(checkBoxObject);
    });
  };

  const renderComponentWithCheckboxes = () => {
    return componentTypes.map(componentType => {
      return (
        <>
          <div className='roleMatrix-table-header-tr-height'>
            <div className='roleMatrix-header-enable-label'>
              {renderComponentTypeCheckBoxes(
                I18n.t(componentType.name).replace(/\s/g, '')
              )}
            </div>
            <div className='roleMatrix-header-page-label'>
              {I18n.t(componentType.name)}
            </div>
            {renderActionCheckBoxes(componentType)}
          </div>
        </>
      );
    });
  };

  const getMatrixTable = () => {
    return (
      <div className='role-matrix__wrapper'>
        <div id='roleMatrixTableHeader'>
          <div className='roleMatrix-header-enable-label'>
            {I18n.t('newRole_view.label_roleMatrixHeader_Enable')}
          </div>

          <div className='roleMatrix-header-page-label'>
            {I18n.t('newRole_view.label_roleMatrixHeader_Page')}
          </div>

          {renderAction()}
        </div>

        <div id='roleMatrix'>{renderComponentWithCheckboxes()}</div>
      </div>
    );
  };

  return getMatrixTable();
};

export default withTranslation()(RoleMatrix);
