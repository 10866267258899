import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { Box, FlashMessagesWrapper, Flex } from '@ui-common-files/components';
import SideBarLayout from './SideBar/SideBarLayout/SideBarLayout';
import SideBarMenu from './SideBar/SideBarMenu/SideBarMenu';
import TopBarWrapper from './TopBarWrapper';

import {
  resetAssessmentsTable,
  resetTabPosition,
  resetTemplateTable,
} from '../../../actions/Datatables/templates';
import { resetPatientTable } from '../../../actions/Datatables/patients';

import config from '../../../config';
import { NavigationJSON } from '@ui-common-files/utils';
import { ActionColors } from '@ui-common-files/utils/colors';
import { HideFlashMessage } from '@global/Services';

import '../../css/aux.css';
import '../../css/createCareplanTemplate.css';

const AppLayout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.session);

  const navigateToPatientIndex = () => {
    history.push(`/${NavigationJSON.PATIENT}`);
  };

  useLayoutEffect(() => {
    if (!location.pathname.includes('patient')) {
      dispatch(resetPatientTable());
    }
    if (
      location.pathname !== '/careplan' &&
      !location.pathname.includes('/caretask')
    ) {
      dispatch(resetTemplateTable());
      dispatch(resetAssessmentsTable());
      dispatch(resetTabPosition());
    }
  }, []);

  const renderAppLayout = () => {
    if (user) {
      return (
        <Flex>
          <SideBarLayout
            logoNavigation={navigateToPatientIndex}
            siteLogo={user.siteLogo}
          >
            {config.not_for_clinical_use && (
              <p
                style={{
                  color: ActionColors.ERROR,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                NOT FOR CLINICAL USE
              </p>
            )}
            <SideBarMenu />
          </SideBarLayout>

          <Flex
            flexDirection="column"
            otherStyles={{ flex: 1, overflow: 'hidden' }}
          >
            <TopBarWrapper />
            <Box className="content-container">
              <Box className="main-layout">
                <FlashMessagesWrapper hideFlashMessage={HideFlashMessage} />
                {children}
              </Box>
            </Box>
          </Flex>
        </Flex>
      );
    } else {
      return <Redirect to={'/'} />;
    }
  };

  return renderAppLayout();
};

export default AppLayout;
