import {
  NodeTypes,
  EdgeTypes,
  Connection,
  Edge,
  Node,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
} from 'reactflow';

import { Caretask } from '@type/Caretask';
import { Institution } from '@type/Institution';
import { StoreSetterFunctionType } from '@type/Store';
import IcdOps from '@type/Common/IcdOps.type';

import { CareTaskNode, CareTaskEdge } from './Components';

export type SidebarContentType =
  | ''
  | 'care-task'
  | 'configuration'
  | 'settings'
  | 'linkedCareTask'
  ;

export type CareTaskStore = {
  isLoading: boolean;
  keyword: string;
  items: Caretask[] | null;
  totalRecords: number;
  page: number;
};

export type ErrorObject = {
  id:
    | 'carePathwayName'
    | 'appointmentType'
    | 'icds'
    | 'careTaskLength'
    | 'careTaskNoConfiguration'
    | 'childNodeWithOutParent'
    | 'nodesLimitExceeded';
  type: 'node' | 'settings' | 'name';
  translationKey: string;
};

export type CarePathWayBuilderState = {
  sidebar: {
    isVisible: boolean;
    contentType: SidebarContentType;
    configurationNodeId: string;
  };
  isAddingParent: boolean;
  careTask: CareTaskStore;
  nodes: Node[];
  edges: Edge[];
  errors: ErrorObject[];
  settings: {
    carePathwayName: string;
    appointmentType: { value: number; label: string } | null;
    icds: IcdOps[];
  };
  pathWayReview: {
    isOpen: boolean;
    title: string | null;
    id: number | null;
    wizard?: React.ReactElement | null;
  };
};

export type CarePathWayBuilderActions = {
  updateSidebarVisibility: (
    state: boolean,
    contentType?: SidebarContentType,
    configurationNodeId?: string
  ) => void;
  setIsAddingParent: (isParent: boolean) => void;
  setCareTask: (careTaskObject: CareTaskStore) => void;
  addNode: (
    isRoot: boolean,
    nodeId: string | null,
    careTaskData: Caretask,
    x: number,
    y: number,
    removeNode: (node: Node) => void,
    toggleNodeType: (id: string) => void,
    updateNodeConfiguration: (id: string, configuration) => void,
    validateNodeConfiguration: (id: string, overlappedWith: string) => void
  ) => void;
  edgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
  removeEdge: (id: string) => void;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  addError: (error: ErrorObject) => void;
  removeAnError: (id: string) => void;
  resetErrors: () => void;
  hasErrorBasedOnId: (id: string) => boolean;
  setSettings: (key: string, value: string | Object | IcdOps[]) => void;
  setPathWayReview: (params: PathWayReviewParams) => void;
  transformPathWayObject: (institutions: Institution[]) => object;
  setPathWayTemplate: (pathWayTemplate: object) => void;
  resetBuilder: () => void;
};

export type SetType = StoreSetterFunctionType<
  CarePathWayBuilderState,
  CarePathWayBuilderActions
>;

export type GetType = () => CarePathWayBuilderState & CarePathWayBuilderActions;

export type lifespanUnit = {
  key: string;
  label: string;
  value: number;
}


export type PathWayReviewParams = {
  isOpen: boolean;
  title: string | null;
  id: number | null;
  shouldCloseBuilder?: boolean;
  wizard?: React.ReactElement | null;
};

export const nodeTypes: NodeTypes = {
  careTaskNode: CareTaskNode,
};

export const edgeTypes: EdgeTypes = {
  careTaskEdge: CareTaskEdge,
};


type CarePathwayTemplateAppointmentType = {
  lifespan: number;
  lifespanUnit: lifespanUnit;
};

export type TransformedNode = {
  id: string;
  parentId: string | null;
  templateId: number;
  metadata: object;
  carePathwayTemplateAppointmentType: CarePathwayTemplateAppointmentType;
};

export type TransformedEdge = {
  id: string;
  source: string;
  target: string;
  conditionTypeId: number;
  condition: string;
};
