import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/main.scss';

const ToggleButton = ({ id, value, isDisabled, checked, handleOnChange, tooltipText }) => {
  let labelStyles = 'toggleBtn__label';

  if (isDisabled) {
    labelStyles = `${labelStyles}__disabled`;
  }

  return (
    <label className={labelStyles} htmlFor={id}>
      <div className="toggleBtn__tooltip">
      <input
        type="checkbox"
        id={id}
        value={value}
        className="toggleBtn__input"
        disabled={isDisabled}
        checked={checked}
        onChange={handleOnChange}
      />
      <span className="toggleBtn__slider" />
      {tooltipText && (
      <span  className="toggleBtn__tooltiptext">{tooltipText}</span>
      )}
      </div>
    </label>
  );
};

ToggleButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  /**  Disables the Toggle Button */
  isDisabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired
};

ToggleButton.defaultProps = {
  isDisabled: false
};

export default ToggleButton;
