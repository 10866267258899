/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import I18n from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Action, Box, Flex } from '@ui-common-files/components';
import { ComponentTypes, MEDIA_QUERIES } from '@ui-common-files/utils';
import { categorizeDosage } from '@utils/Medication/categorizeDosage';
import { taskTypes } from '@ui-common-files/global';
import '../../css/cardInfoContent.scss';

const CardInfoContent = ({
  data,
  variant,
  dosage,
  setLandingTab,
  setForceScroll,
}) => {
  const categorizedDosage = categorizeDosage(data.dosage);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const services = useSelector(state => state.services);
  const { t } = useTranslation();
  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const redirectTab = rowInfo => {
    if (
      variant != ComponentTypes.DASHBOARD &&
      services.hasOwnProperty('appointmentservice.myoncare.care')
    ) {
      setLandingTab(false);
      setLandingTab(ComponentTypes.CAREPATHWAYS);
      setForceScroll({ scroll: true, scrollTo: rowInfo.id });
    }
  };

  return (
    <div
      className={
        windowWidth < MEDIA_QUERIES.SCREEN_MD
          ? 'card__content-container card__container--row'
          : 'card__content-container card__container--column'
      }
      style={
        variant === ComponentTypes.DASHBOARD &&
        windowWidth < MEDIA_QUERIES.SCREEN_MD
          ? { marginLeft: '62px' }
          : null
      }
    >
      {(variant === ComponentTypes.DASHBOARD ||
        variant === ComponentTypes.CAREPLAN ||
        variant === ComponentTypes.CAREPATHWAYS) && (
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={
            windowWidth < MEDIA_QUERIES.SCREEN_MD
              ? { flexBasis: '50%', marginBottom: '10px' }
              : { marginBottom: '10px' }
          }
        >
          <div className="card__content-container card__content-text__info-title">
            {(variant === ComponentTypes.DASHBOARD ||
              variant === ComponentTypes.CAREPLAN ||
              variant === ComponentTypes.CAREPATHWAYS) &&
              t('analytic_view.number_of_questions')}
          </div>

          <div className="card__content-text card__content-text__container">
            {data.noOfComponents}
            {data.questionNumber}
          </div>
        </Flex>
      )}

      {(variant === ComponentTypes.CAREPLAN ||
        variant === ComponentTypes.CAREPATHWAYS) && (
        <>
          <Flex
            flexDirection="row"
            alignItems="center"
            otherStyles={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? { flexBasis: '50%', marginBottom: '10px' }
                : { marginBottom: '10px' }
            }
          >
            <div className="card__content-container card__content-text__info-title">
              {(variant === ComponentTypes.DASHBOARD ||
                variant === ComponentTypes.CAREPLAN ||
                variant === ComponentTypes.CAREPATHWAYS) &&
                t('analytic_view.Score')}
            </div>

            <div className="card__content-text card__content-text__container">
              {data.Score === null ? 'N/A' : data.Score}
            </div>
          </Flex>

          <Flex
            flexDirection="row"
            alignItems="center"
            otherStyles={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? {
                    flexBasis: '50%',
                    marginBottom: '10px',
                  }
                : { marginBottom: '10px' }
            }
          >
            <div className="card__content-container card__content-text__info-title">
              {t('analytic_view.careplan_compliance')}
            </div>

            <div className="card__content-text card__content-text__container">
              {data.patientCompliance ? `${data.patientCompliance}%` : 'N/A'}
            </div>
          </Flex>
        </>
      )}

      {variant === ComponentTypes.CAREPLAN && (
        <>
          <Flex
            flexDirection="row"
            alignItems="center"
            otherStyles={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? { flexBasis: '50%', marginBottom: '10px' }
                : null
            }
          >
            <div className="card__content-text card__content-text__container">
              {data &&
                data.hasOwnProperty('carePathways') &&
                data.carePathways.length == 0 &&
                'N/A'}
              {data &&
                data.hasOwnProperty('carePathways') &&
                data.carePathways.length > 0 && (
                  <Action
                    onClick={() => {
                      redirectTab(data.carePathways[0]);
                    }}
                  >
                    <div className="care-pathway-name">
                      {' '}
                      {`${data.carePathways[0].name} `}
                    </div>
                  </Action>
                )}
            </div>
          </Flex>

          <Flex
            flexDirection="row"
            alignItems="center"
            otherStyles={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? {
                    flexBasis: '50%',
                    marginBottom: '10px',
                  }
                : { marginBottom: '10px' }
            }
          />
        </>
      )}

      {variant === ComponentTypes.TASK && (
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={
            windowWidth < MEDIA_QUERIES.SCREEN_MD
              ? { flexBasis: '50%', marginBottom: '10px' }
              : { marginBottom: '10px' }
          }
        >
          <div className="card__content-container card__content-text__info-title">
            {`${t('recurrence.lifespan')}:`}
          </div>

          <div className="card__content-text card__content-text__container">
            {`${data.lifespan} ${t(`lifespan.${data.lifespanUnit}`)}`}
          </div>
        </Flex>
      )}

      {variant === ComponentTypes.DASHBOARD && (
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={
            windowWidth < MEDIA_QUERIES.SCREEN_MD
              ? { flexBasis: '50%', marginBottom: '10px' }
              : { marginBottom: '10px' }
          }
        >
          <div className="card__content-container card__content-text__info-title">
            {t('analytic_view.duration')}
          </div>

          <div className="card__content-text card__content-text__container">
            {data.duration}
          </div>
        </Flex>
      )}

      {variant === ComponentTypes.APPOINTMENT && (
        <>
          <Flex
            flexDirection="row"
            alignItems="center"
            otherStyles={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? { flexBasis: '50%', marginBottom: '10px' }
                : { marginBottom: '10px' }
            }
          >
            <div className="card__content-container card__content-text__info-title">
              {`${t('appointment.dateAndTime')}:`}
            </div>

            <div className="card__content-text card__content-text__container">
              {moment(data.reminderElement.startsAt)
                .locale(I18n.language)
                .format('DD MMM YYYY HH:mm')}
            </div>
          </Flex>

          <Flex
            flexDirection="row"
            alignItems="center"
            otherStyles={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? { flexBasis: '50%', marginBottom: '10px' }
                : { marginBottom: '10px' }
            }
          >
            <div className="card__content-container card__content-text__info-title">
              {`${t('appointment.place')}:`}
            </div>

            <div className="card__content-text card__content-text__container">
              {data.place}
            </div>
          </Flex>
        </>
      )}

      {variant === 'medication' && (
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={
            windowWidth < MEDIA_QUERIES.SCREEN_MD
              ? { flexBasis: '50%', marginBottom: '10px', marginRight: '10px' }
              : { marginBottom: '10px' }
          }
        >
          <div className="card__content-container card__content-text__info-title">
            {`${t('medication.dosageSchema')}:`}
          </div>

          <div className="card__content-text">{dosage}</div>
        </Flex>
      )}
      {variant === ComponentTypes.MEDICATIONPLAN && (
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          otherStyles={{
            width: '100%',
          }}
        >
          <Box otherStyles={{ flex: 1, paddingRight: '40px' }}>
            <Flex
              className="card_content-title_seperator"
              flexDirection="column"
            >
              <Flex
                alignItems="flex-start"
                otherStyles={{ marginBottom: '20px' }}
              >
                <Box
                  className="card__content-container card__content-text__info-medication-plan-title"
                  otherStyles={{ width: '250px' }}
                >
                  {t('medication.dosageSchema')}
                </Box>
                <Box
                  className="card__content-text card__content-medication__container"
                  otherStyles={{ flex: 1 }}
                >
                  {categorizedDosage?.map((dose, index) => (
                    <Flex
                      key={index}
                      justifyContent="space-between"
                      otherStyles={{ width: '100%' }}
                    >
                      <Box
                        className="dosage-category"
                        otherStyles={{ width: '100px' }}
                      >
                        {t(`medication.${dose.category}`)}
                      </Box>
                      <Box otherStyles={{ flex: 1, textAlign: 'left' }}>
                        {dose.dosage}
                      </Box>
                    </Flex>
                  ))}
                  {!categorizedDosage?.length && (
                    <Box otherStyles={{ flex: 1, textAlign: 'left' }}>
                      {'-'}
                    </Box>
                  )}
                </Box>
              </Flex>

              <Flex
                alignItems="flex-start"
                otherStyles={{ marginBottom: '20px' }}
              >
                <Box className="card__content-container card__content-text__info-medication-plan-title">
                  {t('medication.form')}
                </Box>
                <Box
                  className="card__content-text card__content-medication__container"
                  otherStyles={{ flex: 1 }}
                >
                  {data.form || '-'}
                </Box>
              </Flex>
              <Flex alignItems="flex-start">
                <Box className="card__content-container card__content-text__info-medication-plan-title medication-card-note">
                  {t('medication.note')}
                </Box>
                <Box
                  className="card__content-text card__content-medication__container truncated-text"
                  otherStyles={{ flex: 1 }}
                >
                  {data.notes || '-'}
                </Box>
              </Flex>
            </Flex>
          </Box>

          <Box otherStyles={{ flex: 1, paddingLeft: '40px' }}>
            <Flex
              className="card_content-title_seperator"
              flexDirection="column"
            >
              <Flex
                alignItems="flex-start"
                otherStyles={{ marginBottom: '20px' }}
              >
                <Box className=" card__content-text__info-medication-plan-title">
                  {t('medication.reason')}
                </Box>
                <Box className="card__content-medication__container">
                  {data.reason || '-'}
                </Box>
              </Flex>

              <Flex alignItems="flex-start">
                <Box className="card__content-text__info-medication-plan-title">
                  {t('medication.activeIngredient')}
                </Box>
                <Box
                  className="card__content-medication__container"
                  otherStyles={{ flex: 1 }}
                >
                  {data.activeElements.length > 0 ? (
                    data.activeElements.map((element, index) => (
                      <Box
                        key={index}
                        otherStyles={{ display: 'block', textAlign: 'start' }}
                      >
                        {element.name} {element.awbmng}
                      </Box>
                    ))
                  ) : (
                    <Box otherStyles={{ textAlign: 'center' }}>-</Box>
                  )}
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      )}

      {variant !== ComponentTypes.MEDICATIONPLAN && (
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={
            windowWidth < MEDIA_QUERIES.SCREEN_MD
              ? { flexBasis: '61%', marginBottom: '10px' }
              : { marginBottom: '10px' }
          }
        >
          <Box className="card__content-container card__content-text__info-title">
            {(variant === ComponentTypes.DASHBOARD ||
              variant === ComponentTypes.CAREPLAN ||
              variant === ComponentTypes.CAREPATHWAYS) &&
              t('analytic_view.recurrence')}
            {variant === ComponentTypes.TASK && `${t('task.period')}:`}
            {variant === ComponentTypes.APPOINTMENT &&
              `${t('medication.period')}:`}
            {variant === 'medication' && `${t('medication.period')}:`}
          </Box>

          <Box className="card__content-text card__content-text__container">
            {data.recurrenceText}
          </Box>
        </Flex>
      )}
      {variant === ComponentTypes.APPOINTMENT && (
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={
            windowWidth < MEDIA_QUERIES.SCREEN_MD
              ? { flexBasis: '50%', marginBottom: '10px' }
              : { marginBottom: '10px' }
          }
        >
          <div className="card__content-container card__content-text__info-title">
            {`${t('appointment.type')}:`}
          </div>

          <div className="card__content-text card__content-text__container">
            {`${t(data.appointmentType.name)}`}
          </div>
        </Flex>
      )}

      {variant === ComponentTypes.TASK && (
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={
            windowWidth < MEDIA_QUERIES.SCREEN_MD
              ? { flexBasis: '50%', marginBottom: '10px' }
              : { marginBottom: '10px' }
          }
        >
          <div className="card__content-container card__content-text__info-title">
            {`${t('task.taskType')}:`}
          </div>

          <div className="card__content-text card__content-text__container">
            {data.taskURL && (
              <Action href={data.taskURL} target="_blank">
                {t(`task.${taskTypes[data.taskTypeId]}`)}
              </Action>
            )}
            {!data.taskURL && t(`task.${taskTypes[data.taskTypeId]}`)}
          </div>
        </Flex>
      )}

      {variant === ComponentTypes.DASHBOARD && (
        <>
          <Flex
            flexDirection="row"
            alignItems="center"
            otherStyles={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? { flexBasis: '50%', marginBottom: '10px' }
                : { marginBottom: '10px' }
            }
          >
            <div className="card__content-container card__content-text__info-title">
              {t('analytic_view.enrolled_patients')}
            </div>

            <div className="card__content-text card__content-text__container">
              {data.enrolledPatient}
            </div>
          </Flex>

          <Flex
            flexDirection="row"
            alignItems="center"
            otherStyles={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? {
                    flexBasis: '50%',
                    marginBottom: '10px',
                  }
                : { marginBottom: '10px' }
            }
          >
            <div className="card__content-container card__content-text__info-title">
              {t('analytic_view.careplan_compliance')}
            </div>

            <div className="card__content-text card__content-text__container card__content-text--blue-highlight">
              {data.compliance ? `${data.compliance}%` : 'N/A'}
            </div>
          </Flex>
        </>
      )}
      {variant === ComponentTypes.CAREPATHWAYS && (
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={
            windowWidth < MEDIA_QUERIES.SCREEN_MD
              ? { flexBasis: '50%', marginBottom: '10px' }
              : { marginBottom: '10px' }
          }
        >
          <div className="card__content-container card__content-text__info-title">
            {t('recurrence.nextOccurrence')}
          </div>

          <div className="card__content-text card__content-text__container">
            {data.nextOccurrence}
          </div>
        </Flex>
      )}
    </div>
  );
};

export default CardInfoContent;
