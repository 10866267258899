const toBoolean = require('./src/Global/Boolean/toBoolean');

const config = {};
config.firebase = {};
config.socketOptions = {
  transports: ['websocket'],
  secure: false,
  reconnect: false,
  rejectUnauthorized: false,
  forceNew: true,
};
config.enableOps = toBoolean(process.env.ENABLE_OPS);
config.enableOpsPatientView = toBoolean(process.env.ENABLE_OPS_PATIENT_VIEW);
config.not_for_clinical_use = toBoolean(process.env.NOT_FOR_CLINICAL_USE);
config.googleMapApiKey = process.env.GOOGLE_MAP_API_KEY;
config.firebase.apiKey = process.env.FIREBASE_API_KEY;
config.firebase.authDomain = process.env.FIREBASE_AUTH_DOMAIN;
config.firebase.databaseURL = process.env.FIREBASE_DATABASE_URL;
config.firebase.storageBucket = process.env.FIREBASE_STORAGE_BUCKET;
config.firebase.projectId = process.env.FIREBASE_PROJECT_ID;
config.jitsiUrl = process.env.JITSI_URL;
config.axios_timeout = process.env.AXIOS_TIMEOUT;
config.nonMedical = toBoolean(process.env.NON_MEDICAL);
config.isBlockchainActive = toBoolean(process.env.BLOCKCHAIN_MODE);
config.isRunningFhir = toBoolean(process.env.IS_RUNNING_FHIR);
config.SentryDSN = process.env.SENTRY_DSN;
config.isUsingOncobox = toBoolean(process.env.IS_USING_ONCOBOX);
config.accessTokenExpiration = process.env.ACCESS_TOKEN_EXPIRATION;
config.matomoURL = process.env.MATOMO_URL;
config.isBeta = toBoolean(process.env.IS_BETA);
config.isPatientDataEditable = toBoolean(process.env.ALLOW_EDIT_PATIENT_DATA);
config.supportUrl = process.env.SUPPORT_URL;
config.serviceEmail = process.env.SERVICE_EMAIL;
config.minPatientAge = process.env.MIN_PATIENT_AGE;
config.carepathwayMaxNodesLimit = Number(
  process.env.CAREPATHWAY_MAX_NODES_LIMIT
);
config.carepathwayMaxNodesThresholds = Number(
  process.env.CAREPATHWAY_MAX_NODES_THRESHOLDS
);
config.trainingResourcesUrl = process.env.TRAINING_RESOURCES_URL;
module.exports = config;
