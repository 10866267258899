import ASSET_TYPES from './assetTypes';
import { documentIcon, imageIcon, videoIcon, audioIcon } from './assetIcons';

const getAssetIcon = (activeTab, size) => {
  let assetIcon;
  switch (activeTab) {
    case ASSET_TYPES.DOCUMENT:
      assetIcon = documentIcon(size);
      break;
    case ASSET_TYPES.IMAGE:
      assetIcon = imageIcon(size);
      break;
    case ASSET_TYPES.VIDEO:
      assetIcon = videoIcon(size);
      break;
    case ASSET_TYPES.AUDIO:
      assetIcon = audioIcon(size);
      break;
    default:
      assetIcon = documentIcon(size);
  }
  return assetIcon;
};

export default getAssetIcon;
