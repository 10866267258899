import templateActions from '../../src/Utils/ReduxUtilities/Actions/Datatables/templates.json';

export const onSearchAction = query => ({
  type: templateActions.ON_SEARCH_TEMPLATES,
  payload: query,
});

export const onTableChangeAction = query => ({
  type: templateActions.ON_TEMPLATE_TABLE_CHANGE,
  payload: query,
});

export const resetTemplateTable = () => ({
  type: templateActions.RESET_TEMPLATE_TABLES,  
});

export const onSearchAssessmentsAction = query => ({
  type: templateActions.ON_SEARCH_ASSESSMENT,
  payload: query,
});

export const onAssessmentTableChangeAction = query => ({
  type: templateActions.ON_ASSESSMENT_TABLE_CHANGE,
  payload: query,
});

export const resetAssessmentsTable = () => ({
  type: templateActions.RESET_ASSESSMENT_TABLE,  
});

export const onTabChange = tab => ({
  type: templateActions.ON_TAB_CHANGE,
  payload: tab,
});

export const resetTabPosition = () => ({
  type: templateActions.RESET_TAB_POSITION,  
});