import React, { useState, useEffect } from 'react';
import axios from 'axios';
import I18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { MaxCharLimits } from '@ui-common-files/utils';

import { ActionColors } from '../../../caro-ui-commonfiles/utils/colors';
import Input from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import inputField from '../../../caro-ui-commonfiles/utils/inputField';
import serviceConfig from '../../../serviceConfig.json';
import NavigationJSON from '../../Utils/navigation.json';

const ChatMessages = ({ hasPagesize, hasPagination, totalChatMessages }) => {
  const { user } = useSelector(state => state.session);
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({ value: '', start: 0, length: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const history = useHistory();
  const Rows = data => {
    const rows = [];
    if (data) {
      for (let index = 0; index < data.length; index++) {
        const { message } = data[index];

        const processTimeString = (time = '') => {
          return time.includes(',')
            ? `${I18n.t(`chatMessages.${time.split(',')[0]}`)}, ${I18n.t(
                `chatMessages.${time.split(',')[1]}`
              )}`
            : I18n.t(`chatMessages.${time}`);
        };

        const processMessage = () => {
          return data[index].isTranslatable
            ? I18n.t(message.split('key:')[1].split(' file:')[0], {
                time: processTimeString(message.split('time:')[1]),
              })
            : message;
        };
        rows.push({
          medicalRecord: data[index].patient.medicalRecord,
          patient: `${data[index].patient.firstname} ${data[index].patient.lastname}`,
          message: processMessage(),
          timestamp: moment(data[index].timestamp)
            .locale(I18n.language)
            .format('DD MMM YYYY HH:mm'),
          status:
            data[index].users[0].userChatMessage.showInDropdown === true
              ? 'chatMessages.unseen'
              : 'chatMessages.seen',
          patientId: data[index].patient.id,
          profilePicture: data[index].patient.profilePicture,
        });
      }
    }

    return rows;
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(
        `${serviceConfig.chatService.getChatMessagesByQuery.uri}?userId=${user.id}`,
        {
          query,
          totalChatMessagesLength: totalChatMessages,
        }
      );

      const { data } = result.data;
      setLoading(false);
      setFetchedData(Rows(data));
      setRecordsTotal(result.data.recordsTotal);
    };

    fetchData();
  }, [query.value, query.start, query.length, totalChatMessages, user.id]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];
  const tableProps = {
    defaultSorted: [
      {
        id: 'record',
        desc: false,
      },
    ],
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
    getTdProps: (state, row, col) => ({
      onClick: () => {
        if (col.id !== 'rowOptions') {
          history.push(
            `/${NavigationJSON.PATIENT_OVERVIEW}/${row.original.patientId}`,
            { patientInfo: row.original, shouldOpenChat: true }
          );
        }
      },
    }),
    getTrProps: (state, row) => ({
      style: {
        cursor: 'pointer',
        color:
          row.original.status === 'chatMessages.unseen'
            ? ActionColors.THRESHOLD
            : null,
      },
    }),
  };

  const columns = [
    {
      Header: I18n.t('chatMessages.status'),
      accessor: 'status',
      sortable: false,
      filterable: false,
      Cell: ({ value }) => I18n.t(value),
    },
    {
      Header: I18n.t('patients_view.label_medicalRecord'),
      accessor: 'medicalRecord',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('patients_view.label_patient'),
      accessor: 'patient',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('chatMessages.message'),
      accessor: 'message',
      sortable: false,
      filterable: false,
    },
    {
      Header: I18n.t('common_labels.timestamp'),
      accessor: 'timestamp',
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <>
      <div style={{ width: 200, position: 'absolute', top: 0, right: 0 }}>
        <Input
          id="specialId"
          name="Name"
          placeholder={I18n.t('common_labels.label_search')}
          variant={inputField.variant.CHAR_COUNT}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          value={query.value}
          handleOnChange={e => {
            setQuery({ value: e.target.value, start: 0, length: 10 });
            setCurrentPage(0);
          }}
        />
      </div>
      <DataTable
        data={fetchedData}
        columns={columns}
        pageSize={hasPagesize}
        pageSizeOptions={items}
        pagination={hasPagination}
        isLoading={loading}
        tableProps={tableProps}
        onTableChange={(index, size) => {
          setQuery({
            value: query.value,
            start:
              fetchedData && index * size > recordsTotal ? 0 : index * size,
            length: size,
          });
          setCurrentPage(
            fetchedData && index * size > recordsTotal ? 0 : index
          );
        }}
        page={currentPage}
        pages={Math.ceil(recordsTotal / query.length)}
        totalRecord={recordsTotal}
      />
    </>
  );
};

export default ChatMessages;
