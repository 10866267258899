import { useState } from 'react';

import { Solution } from './TroubleshootSection.enum';

const useTroubleshootSolutionsListService = patientInfo => {
  const [isDisabled, setIsDisabled] = useState(false);
  const shouldResetAppear = patientInfo.isPrimary && !patientInfo.isMultiSite;
  const solutionsList = [
    Solution.Reassign,
    Solution.Cache,
    ...(shouldResetAppear ? [Solution.Reset] : []),
  ];

  const disableButtonsConditions = [
    isDisabled || !patientInfo.deviceToken,
    !patientInfo.deviceToken,
    !patientInfo.isPrimary,
  ];

  return {
    solutionsList,
    setIsDisabled,
    disableButtonsConditions,
    shouldResetAppear,
  };
};

export default useTroubleshootSolutionsListService;
