import I18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Loader from 'react-loader-spinner';

import { HideFlashMessage } from '@global/Services';

import useAllTodoAssessments from 'src/Views/Notifications/Notification.hooks';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import ModalContent from '../../../../caro-ui-commonfiles/components/Modals/ModalContent';
import Modal from '../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import Step from '../../../../caro-ui-commonfiles/components/Stepper/Step';
import Stepper from '../../../../caro-ui-commonfiles/components/Stepper/Stepper';
import {
  ButtonType,
  ButtonVariant,
} from '../../../../caro-ui-commonfiles/utils/button';
import UsFilterTopWarning from '../../../../caro-ui-commonfiles/components/UsFilterWarnings/UsFilterTopWarning';
import { ActionColors, PrimaryColor } from '@ui-common-files/utils/colors';
import {
  ActionTranslationKeys,
  ComponentTranslationKeys,
  SubmissionTypes,
} from '../../../../caro-ui-commonfiles/utils/componentTypes';
import '../../../css/carepathway.css';
import checkUserCredential from '../../../Utils/checkUserCredential';
import ScrollDownComponent from '../../../Utils/ScrollDownComponent';
import ShowModalFooter from '../../common/ShowModalFooter';
import { PromptContent, PromptFooter } from '../../common/ConfirmationPrompt';
import CarePathwayTemplatesForAssign from '../../Datatables/CarePathwayTemplatesForAssign';
import { PatientOverviewContext } from '../../PatientOverview/PatientOverview';
import { isAssessmentWithIsRoot } from '../CareplanHelperUtility';
import {
  assignCarePathway,
  assignCarePathwayToPatients,
  checkDuplicateCarePathway,
  getPathwayTemplateDetails,
  updateAdHocCarepathway,
} from './CarePathwayApiCalls';
import {
  AsignSendStep,
  ChooseAppointment,
  CarePathwayModalStep3,
} from './CarePathwayModalContentComponents';
import serviceConfig from '../../../../serviceConfig.json';
import { nonMedical } from '../../../../config';
import { checkIfLogicJumpsThresholdsAndScore } from './Carepathway.utils';

const CreateCarePathwayHeader = () => {
  const { currentStep } = useContext(PatientOverviewContext);
  return (
    <Box width="70%" height={60}>
      <Stepper currentStep={currentStep}>
        <Step text={('Step 1 text', I18n.t('steps.chooseCarePathway'))} />
        <Step
          text={
            ('Step 2 text',
            I18n.t('steps.carePathwayAssignment_appointmentSettings'))
          }
        />
        <Step text={('Step 3 text', I18n.t('steps.thresholdSettings'))} />
        <Step text={('Step 4 text', I18n.t('steps.assignAndSend'))} />
      </Stepper>
    </Box>
  );
};

const CreateCarePathwayContent = ({
  patientData,
  close,
  fromEditAssignment,
  carePathwayData,
  setItemIdToUpdate,
}) => {
  const {
    currentStep,
    selectedCarepathway,
    setSelectedCarepathway,
    setCurrentStep,
    setFlashMessage,
    usWarningCheckboxChecked,
    setUsWarningCheckboxChecked,
    setBottomPageReached,
    setLandingTab,
    shouldSubmitForm,
    setcarePathwayHasThresholds,
    carePathwayHasThresholds,
    handleSelectedCarePathway,
    carePathway,
    isAdhoc,
    setIsAdhoc,
    appointmentDate,
    setAppointmentDate,
    appointmentTime,
    setAppointmentTime,
    isUnspecified,
    setIsUnspecified,
    isFetchingModalContent,
    setIsFetchingModalContent,
  } = useContext(PatientOverviewContext);
  const [openPopupConfirmation, setOpenPopupConfirmation] = useState(false);
  const [isBeforeCurrentTime, setIsBeforeCurrentTime] = useState(false);
  const [selectedCareplans, setSelectedCareplans] = useState([]);
  const [isDuplicateCarePathway, setIsDuplicateCarePathway] = useState(false);
  const [carePathwayDataTemplate, setCarePathwayDataTemplate] = useState('');
  const [isLoadingCarepathway, setIsLoadingCarepathway] = useState(false);
  const [allFutureCareplans, setAllFutureCareplans] = useState(true);

  const [promptMessage, setPromptMessage] = useState({
    display: false,
    title: '',
    message: '',
    displayed: false,
  });
  const closeMessage = () => {
    setPromptMessage({
      display: false,
      title: '',
      message: '',
      displayed: true,
    });
    if (isDuplicateCarePathway) close();
  };
  useEffect(() => {
    if (fromEditAssignment && currentStep === 1) {
      setCurrentStep(2);
    }
  }, [currentStep]);
  useEffect(() => {
    const referenceDate = fromEditAssignment
      ? carePathwayData.referenceDate
      : '';
    if (fromEditAssignment) {
      setAppointmentDate(new Date(carePathwayData.referenceDate));
      setAppointmentTime({
        hour: new Date(carePathwayData.referenceDate).getHours(),
        minute: new Date(carePathwayData.referenceDate).getMinutes(),
      });
    }
    const getCarePathwayTemplate = async () => {
      try {
        const carePathwayId = carePathwayData.carePathwayTemplateId;
        const response = await axios.get(
          serviceConfig.brokerService.showCarePathwayTemplate.uri +
            carePathwayId
        );
        if (response && response.data) {
          setCarePathwayDataTemplate(
            response.data.carePathwayTemplateAssociatedTemplates
          );
          setCarePathwayDataTemplate(carePathwayDataTemplate => ({
            ...carePathwayDataTemplate,
            referenceDate,
          }));
          setIsLoadingCarepathway(true);
        }
      } catch (error) {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      }
    };
    if (fromEditAssignment) {
      getCarePathwayTemplate();
    } else {
      setIsLoadingCarepathway(true);
    }
  }, []);

  useEffect(() => {
    const initPathwayModal = () => {
      assignCarePathway(
        selectedCarepathway,
        handleSelectedCarePathway,
        setFlashMessage
      );
    };
    if (selectedCarepathway !== -1) initPathwayModal();
  }, [selectedCarepathway]);

  useEffect(() => {
    const abortController = new AbortController();
    const carePathwayTemplate = fromEditAssignment
      ? carePathwayDataTemplate
      : carePathway;
    if (currentStep === 3) {
      if (carePathwayTemplate && carePathwayTemplate.templates.length > 0) {
        const templatesIds = carePathwayTemplate.templates.map(
          template => template.id
        );
        setSelectedCareplans([]);
        fetchCarepathwayCaretasks(templatesIds, abortController.signal);
      }
    } else if (currentStep === 4) {
      const patientIds =
        patientData.length > 0 && patientData.map(patient => patient.id);
      const carePathwayTemplateId = fromEditAssignment
        ? carePathwayDataTemplate.id
        : carePathway.id;
      checkDuplicateCarePathway(
        patientIds,
        carePathwayTemplateId,
        setFlashMessage,
        setIsDuplicateCarePathway
      );
    }

    return () => abortController.abort();
  }, [currentStep, carePathway]);

  const fetchCarepathwayCaretasks = async (templatesIds, signal) => {
    const pathwayCaretasks = await getPathwayTemplateDetails(
      templatesIds,
      setIsFetchingModalContent,
      setSelectedCareplans,
      selectedCareplans,
      signal
    );
    if (pathwayCaretasks.length) {
      checkIfLogicJumpsThresholdsAndScore(
        pathwayCaretasks,
        currentStep,
        setCurrentStep,
        setcarePathwayHasThresholds
      );
    }
  };

  const checkpatientUnder18 = () => {
    if (
      patientData[0].age < 18 &&
      (nonMedical == false || nonMedical == 'false')
    ) {
      setBottomPageReached(true);
      return true;
    }
    return false;
  };
  const { getAllTodos, allTodoAssessmentsController } = useAllTodoAssessments();

  const onSubmit = e => {
    allTodoAssessmentsController.abort();
    const isPatientOverView = true;
    e.preventDefault();
    if (currentStep === 1 && selectedCarepathway !== -1) {
      setCurrentStep(currentStep + 1);
    }
    if (currentStep === 2) {
      if (allFutureCareplans && isBeforeCurrentTime) {
        setCurrentStep(currentStep + 1);
      } else {
        setOpenPopupConfirmation(true);
      }
    }
    if (currentStep === 3) {
      setCurrentStep(currentStep + 1);
    }
    if (currentStep === 4) {
      if (fromEditAssignment) {
        const referenceDate = moment(appointmentDate)
          .hour(appointmentTime.hour)
          .minute(appointmentTime.minute)
          .toISOString();
        updateAdHocCarepathway(
          patientData[0].id,
          carePathwayData.id,
          carePathwayData.name,
          referenceDate,
          close,
          setItemIdToUpdate
        );
      } else {
        const patientIds = patientData.map(patient => patient.id);
        if (isDuplicateCarePathway) {
          setPromptMessage({
            display: true,
            title: `${I18n.t('warningMessage.duplicateCarePathway_title')}`,
            message: I18n.t('warningMessage.duplicateCarePathway', {
              Carepathway: carePathway.name,
            }),
          });
        } else {
          close();
          setFlashMessage({
            type: 'info',
            content: `${I18n.t(
              'newCareplan_view.carepathwayAssignProgressStart'
            )} "${carePathway.name}" ${I18n.t(
              'newCareplan_view.carepathwayAssignProgress'
            )}`,
          });
          const referenceDate = isAdhoc
            ? moment(appointmentDate)
                .hour(appointmentTime.hour)
                .minute(appointmentTime.minute)
                .toISOString()
            : null;
          assignCarePathwayToPatients(
            selectedCarepathway,
            patientIds,
            setFlashMessage,
            shouldSubmitForm.submissionType === SubmissionTypes.ASSIGN_AND_SEND,
            selectedCareplans,
            carePathway,
            isPatientOverView,
            isAdhoc,
            referenceDate,
            setLandingTab
          ).finally(() => {
            const hasAssessment = selectedCareplans.some(
              careplan => careplan.template.isAssessment
            );
            if (hasAssessment) {
              getAllTodos();
            }
          });
        }
      }
    }
  };

  const renderContentByStep = () => {
    switch (currentStep) {
      case 1:
        return renderStepOne();
      case 2:
        return renderStepTwo();
      case 3:
        return renderStepThree();
      case 4:
        return renderStepFour();
    }
  };

  const renderStepOne = () => (
    <CarePathwayTemplatesForAssign
      selectedCarepathway={selectedCarepathway}
      setSelectedCarepathway={setSelectedCarepathway}
      setAllFutureCareplans={setAllFutureCareplans}
    />
  );

  const renderStepTwo = () => {
    if (!isLoadingCarepathway)
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{
            width: '100%',
            height: '100%',
            position: 'inherit',
          }}
        >
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Flex>
      );

    return (
      <ChooseAppointment
        carePathwayData={
          fromEditAssignment ? carePathwayDataTemplate : carePathway
        }
        isPatientOverView
        isAdhoc={isAdhoc}
        setIsAdhoc={setIsAdhoc}
        appointmentDate={appointmentDate}
        setAppointmentDate={setAppointmentDate}
        appointmentTime={appointmentTime}
        setAppointmentTime={setAppointmentTime}
        isUnspecified={isUnspecified}
        setIsUnspecified={setIsUnspecified}
        fromEditAssignment={fromEditAssignment}
        patient={patientData}
        setOpenPopupConfirmation={setOpenPopupConfirmation}
        openPopupConfirmation={openPopupConfirmation}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setIsBeforeCurrentTime={setIsBeforeCurrentTime}
      />
    );
  };

  const renderStepThree = () => {
    const isUnder18 = checkpatientUnder18();
    if (isUnder18 && carePathwayHasThresholds)
      return (
        <Box margin="auto 0" padding="0 60px">
          <UsFilterTopWarning
            message={I18n.t('newCareplan_view.under18')}
            showUsWarningTopPage
            showEditIcon={false}
            showFilter={false}
          />
        </Box>
      );
    return (
      <CarePathwayModalStep3
        careplanData={selectedCareplans}
        isLoading={isFetchingModalContent}
        setBottomPageReached={setBottomPageReached}
        usWarningCheckboxChecked={usWarningCheckboxChecked}
        setUsWarningCheckboxChecked={setUsWarningCheckboxChecked}
      />
    );
  };

  const renderStepFour = () => (
    <AsignSendStep
      carePathwayData={
        fromEditAssignment ? carePathwayDataTemplate : carePathway
      }
      selectedPatientsData={patientData}
    />
  );

  return (
    <ModalContent>
      <form
        id="form-create-carepathway"
        onSubmit={onSubmit}
        className="careplan-flex__ver-2col"
      >
        {renderContentByStep()}
        {promptMessage.display && (
          <Modal
            isConfirmationDialog
            title={promptMessage.title}
            contentComponent={<PromptContent message={promptMessage.message} />}
            footerComponent={<ShowModalFooter close={closeMessage} />}
            openModal={promptMessage.display}
            onClose={closeMessage}
            hideFlashMessage={HideFlashMessage}
          />
        )}
        {openPopupConfirmation && (
          <Modal
            isConfirmationDialog
            title={I18n.t('chooseAppointment_view.popupTitle')}
            contentComponent={
              <PromptContent
                message={I18n.t(
                  'chooseAppointment_view.popupConfirmationMessage'
                )}
              />
            }
            footerComponent={
              <PromptFooter
                close={() => setOpenPopupConfirmation(false)}
                confirmHandler={() => {
                  setCurrentStep(currentStep + 1);
                }}
                submissionType={SubmissionTypes.CONFIRM}
                btnLabelRight={I18n.t(
                  'chooseAppointment_view.acceptWarningButton'
                )}
              />
            }
            openModal={openPopupConfirmation}
            onClose={() => setOpenPopupConfirmation(false)}
            hideFlashMessage={HideFlashMessage}
          />
        )}
      </form>
    </ModalContent>
  );
};

const CreateCarePathwayFooter = ({
  componentType,
  actionType,
  fromEditAssignment,
}) => {
  const {
    currentStep,
    selectedCarepathway,
    setCurrentStep,
    showUnder18,
    setShowUnder18,
    usWarningCheckboxChecked,
    setUsWarningCheckboxChecked,
    bottomPageReached,
    setBottomPageReached,
    dispatchFormSubmittor,
    carePathwayHasThresholds,
    carePathway,
    isFetchingModalContent,
  } = useContext(PatientOverviewContext);
  const [showError, setShowError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [hasSendCredential, setHasSendCredential] = useState(false);

  const [buttonClicked, setButtonClicked] = useState(true);

  const hasOnlyAssessment = isAssessmentWithIsRoot(carePathway);

  useEffect(() => {
    setUsWarningCheckboxChecked({
      awareCheckbox: false,
      confirmCheckbox: false,
    });
    setBottomPageReached(false);
  }, [selectedCarepathway]);

  const formSubmittor = submissionType => {
    if (buttonClicked) {
      setButtonClicked(false);
      dispatchFormSubmittor({
        component: componentType,
        action: actionType,
        submissionType,
      });
    }
  };

  useEffect(() => {
    if (currentStep === 3) {
      const isDisable =
        !usWarningCheckboxChecked.awareCheckbox ||
        !usWarningCheckboxChecked.confirmCheckbox ||
        showUnder18;
      setDisableButton(isDisable);
    }
    if (currentStep === 1) {
      setDisableButton(false);
      setShowUnder18(false);
    }
    if (currentStep === 2 && showUnder18) {
      setDisableButton(true);
    }
  }, [currentStep, usWarningCheckboxChecked, showUnder18]);

  useEffect(() => {
    const checkSendCredential = async () => {
      const hasSendCarePathwayTemplateCredential = await checkUserCredential(
        ComponentTranslationKeys.CAREPATHWAY_TEMPLATE,
        ActionTranslationKeys.SEND
      );
      const hasSendCareplanTemplateCredential = await checkUserCredential(
        ComponentTranslationKeys.CAREPLAN_TEMPLATE,
        ActionTranslationKeys.SEND
      );
      if (
        hasSendCarePathwayTemplateCredential === true &&
        hasSendCareplanTemplateCredential === true
      ) {
        setHasSendCredential(true);
      }
    };
    checkSendCredential();
  }, []);
  return (
    <Flex justifyContent="space-between" otherStyles={{ width: '100%' }}>
      <Box>
        <>
          {currentStep === 1 && (
            <Flex flexDirection="row" alignItems="center">
              {showError && (
                <Flex
                  flexDirection="row"
                  justifyContent="flex-start"
                  otherStyles={{ flex: 1 }}
                >
                  <div style={{ marginTop: '15px' }}>
                    <Icon name="error" size={20} fill={ActionColors.ERROR} />
                  </div>
                  <h3 className="select-patient">
                    {I18n.t('warningMessage.oneCarePathwaySelection')}
                  </h3>
                </Flex>
              )}
            </Flex>
          )}
          {currentStep > 1 && (
            <Button
              label={I18n.t('common_buttons.back')}
              type={ButtonType.BUTTON}
              disabled={fromEditAssignment && currentStep === 2}
              onClick={() => {
                if (currentStep === 4 && !carePathwayHasThresholds) {
                  setCurrentStep(currentStep - 2);
                } else {
                  setCurrentStep(currentStep - 1);
                }
                setDisableButton(false);
              }}
              variant={ButtonVariant.OUTLINED}
            />
          )}
        </>
      </Box>
      <Flex alignItems="center">
        {currentStep === 3 && !bottomPageReached && <ScrollDownComponent />}
        {currentStep < 4 && (
          <Button
            label={I18n.t('common_buttons.next')}
            type={ButtonType.SUBMIT}
            form="form-create-carepathway"
            variant={ButtonVariant.CONTAINED}
            disabled={disableButton}
            onClick={() => {
              if (currentStep === 1 && selectedCarepathway === -1) {
                setShowError(true);
              } else {
                setShowError(false);
              }
            }}
          />
        )}
        {currentStep === 4 && (
          <Button
            label={
              fromEditAssignment
                ? I18n.t('common_buttons.save')
                : I18n.t('assignTemplate_view.addToAssignments')
            }
            type={ButtonType.SUBMIT}
            form="form-create-carepathway"
            variant={ButtonVariant.OUTLINED}
            onClick={() => formSubmittor(SubmissionTypes.ASSIGN_ONLY)}
          />
        )}
        {currentStep === 4 && hasSendCredential && !hasOnlyAssessment && (
          <Box margin="0 0 0 10px">
            <Button
              label={I18n.t('assignTemplate_view.send_immediately')}
              form="form-create-carepathway"
              type={ButtonType.SUBMIT}
              onClick={() => formSubmittor(SubmissionTypes.ASSIGN_AND_SEND)}
              variant={ButtonVariant.CONTAINED}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

CreateCarePathwayContent.propTypes = {
  patientData: PropTypes.objectOf(PropTypes.object).isRequired,
  sendToPatient: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

CreateCarePathwayFooter.propTypes = {
  setSendToPatient: PropTypes.func.isRequired,
};

export {
  CreateCarePathwayContent,
  CreateCarePathwayFooter,
  CreateCarePathwayHeader,
};
