import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import Flex from '../../Flex/Flex';
import Button from '../../Button/Button';
import  { ButtonType, ButtonVariant } from '../../../utils/button';

const ImageUploadComponent = ({
  blob,
  handleOnChange,
  styleProps,
  setHasError,
  hidePatientDemographics
}) => {
  const [previewStyle, setPreviewStyle] = useState({});
  const [hasErrors, _setHasErrors] = useState({
    typeError: false,
    sizeError: false,
  });
  const [imgSource, setImgSource] = useState('');
  const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const baseStyle = {
    ...styleProps,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    OBackgroundSize: 'cover',
  };
  const reader = new FileReader();
  reader.onloadend = () => {
    setImgSource(reader.result);
  };

  let inputRef;

  const _handleImageUpload = e => {
    const file = e.target.files[0];
    const isFileTypeCorrect = _validateFileType(file);
    const isFileSizeCorrect = _validateFileSize(file);
    _setHasErrors({
      typeError: !isFileTypeCorrect,
      sizeError: !isFileSizeCorrect,
    });
    setHasError(!(isFileTypeCorrect && isFileSizeCorrect));
    isFileTypeCorrect && isFileSizeCorrect && handleOnChange(file);
  };

  const _validateFileType = file => {
    return file && acceptedImageTypes.includes(file.type);
  };

  const _validateFileSize = file => {
    return file.size <= 4 * 1024 * 1024;
  };

  useEffect(() => {
    if (blob && typeof blob === 'object') {
      reader.readAsDataURL(blob);
    } else {
      setImgSource(blob);
    }
  }, [blob]);

  useEffect(() => {
    setPreviewStyle({
      ...baseStyle,
      backgroundImage: `url("${imgSource}")`,
    });
  }, [imgSource]);

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        <div className="image-preview" style={previewStyle} />
        <div id="button-container" className="image-upload__button-container">
          <input
            id="image-upload"
            type="file"
            onChange={event => _handleImageUpload(event)}
            ref={input => (inputRef = input)}
            hidden
          />
          {!hidePatientDemographics &&
          <Button
          label={I18n.t('common_labels.label_Upload')}
          type={ButtonType.BUTTON}
          variant={ButtonVariant.NO_BORDER}
          onClick={() => inputRef.click()}
        />
        }
        </div>
      </Flex>
      {hasErrors.typeError && (
        <span className="image-upload__val-msg">
          {I18n.t('patients_view.invalidImageNotification')}
        </span>
      )}
      {hasErrors.sizeError && (
        <span className="image-upload__val-msg">
          {I18n.t('patients_view.imageTooBig')}
        </span>
      )}
    </>
  );
};

ImageUploadComponent.propTypes = {
  blob: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  styleProps: PropTypes.object,
  setHasError: PropTypes.func.isRequired,
};

export default ImageUploadComponent;
