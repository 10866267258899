import React, { useContext } from 'react';
import { Box, Flex } from '@ui-common-files/components';
import { ComponentTypes, ComponentColors } from '@ui-common-files/utils';
import { usePlannedMedicationCardService } from './PlannedMedicationCard.service';
import { Dosage } from '../MedicationPlan.types';
import { MedicationCardTitle } from '../MedicationCardTitle/MedicationCardTitle';
import CardInfoContent from '../../../common/CardInfoContent';
import { PatientOverviewContext } from '../../../PatientOverview/PatientOverview';

const PlannedMedicationCard: React.FC = ({ data, dosage }) => {
  const { setLandingTab, setForceScroll } = useContext(PatientOverviewContext);

  const { flexDirection } = usePlannedMedicationCardService();

  const renderDosageInfo = (dosagesList?: Dosage[]) =>
    dosagesList &&
    dosagesList.map(dosageObj => (
      <Flex
        key={`dosage-${dosageObj.duration}`}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box className="assignment-card__medication__duration">
          {dosageObj.duration}
        </Box>
        <Box className="assignment-card__medication__dosage">
          {dosageObj.dosage}
        </Box>
      </Flex>
    ));

  const renderContent = () => (
    <Flex
      flexDirection={flexDirection}
      otherStyles={{ paddingLeft: '45px', marginTop: '10px' }}
    >
      <CardInfoContent
        data={data}
        variant={ComponentTypes.MEDICATIONPLAN}
        dosage={renderDosageInfo(dosage)}
        setLandingTab={setLandingTab}
        setForceScroll={setForceScroll}
      />
    </Flex>
  );

  return (
    <Flex className="medication-card medication-card-column">
      <MedicationCardTitle
        iconName={ComponentTypes.MEDICATION}
        color={ComponentColors.MEDICATION}
        data={data}
      />
      {renderContent()}
    </Flex>
  );
};

export default PlannedMedicationCard;
