import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  Flex,
  Icon,
  TextInput,
  DataTable,
} from '@ui-common-files/components';

import {
  Greys,
  ActionColors,
  inputField,
  MaxCharLimits,
} from '@ui-common-files/utils';

import { dataTablePageSize } from '@global/Utils';
import { useWindowWidthListener } from '@utils/hooks';
import { FirebaseContext } from '@utils';

import getTaggedUsers from './TaggedUsersList.service';
import './TaggedUsersList.scss';

const TaggedUsersList = ({ hasPagesize, hasPagination }) => {
  const { t } = useTranslation();

  const responsive = useWindowWidthListener();

  const {
    fetchedData,
    recordsTotal,
    loading,
    tableProps,
    query,
    currentPage,
    handleSearch,
    handleTableOnChange,
  } = getTaggedUsers();

  const columns = useMemo(() => {
    const { hidePatientDemographics } = useContext(FirebaseContext);
    return [
      {
        Header: t('taggedUsers.status'),
        accessor: 'status',
        sortable: false,
        filterable: false,
        width: 100,
        Cell: ({ value }) => (
          <>
            {value ? (
              <Flex>
                <Icon name="seen" size={20} fill={Greys.DARK_GREY} />
                <span style={{ marginLeft: '4px' }}>
                  {t('taggedUsers.seen')}
                </span>
              </Flex>
            ) : (
              <Flex>
                <Icon name="unSeen" size={20} fill={ActionColors.THRESHOLD} />
                <span style={{ marginLeft: '4px' }}>
                  {t('taggedUsers.unSeen')}
                </span>
              </Flex>
            )}
          </>
        ),
      },
      {
        Header: () => {
          return <span>{t('taggedUsers.hcpName')}</span>;
        },
        accessor: 'hcpName',
        sortable: false,
        filterable: false,
      },
      {
        Header: () => {
          return <span>{t('taggedUsers.hcpRole')}</span>;
        },
        accessor: 'hcpRole',
        sortable: false,
        filterable: false,
      },

      hidePatientDemographics
        ? {
            Header: () => {
              return <span>{t('patients_view.label_medicalRecord')}</span>;
            },
            accessor: 'medicalRecord',
            sortable: false,
            filterable: false,
          }
        : {
            Header: () => {
              return <span>{t('taggedUsers.patientName')}</span>;
            },
            accessor: 'patientName',
            sortable: false,
            filterable: false,
          },
      {
        Header: () => {
          return <span>{t('taggedUsers.message')}</span>;
        },
        accessor: 'message',
        sortable: false,
        filterable: false,
        minWidth: 300,
        Cell: ({ original }) => (
          <div className={original.status ? 'seen' : 'unseen'}>
            {ReactHtmlParser(original.message)}
          </div>
        ),
      },
      {
        Header: () => {
          return <span>{t('taggedUsers.timeStamp')}</span>;
        },
        accessor: 'timeStamp',
        sortable: false,
        filterable: false,
      },
    ];
  }, [responsive]);

  return (
    <Box>
      <Box className="inputField">
        <TextInput
          id="taggedUsersSearch"
          name="taggedUsersSearch"
          placeholder={t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={query.value}
          handleOnChange={e => handleSearch(e)}
        />
      </Box>
      <DataTable
        data={fetchedData}
        columns={columns}
        pageSize={hasPagesize}
        pageSizeOptions={dataTablePageSize}
        pagination={hasPagination}
        isLoading={loading}
        tableProps={tableProps}
        onTableChange={(index, size) => handleTableOnChange(index, size)}
        page={currentPage}
        pages={Math.ceil(recordsTotal / query.length)}
        totalRecord={recordsTotal}
      />
    </Box>
  );
};
export default TaggedUsersList;
