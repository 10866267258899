import { ApiClient } from '@global/Services';
import I18n from 'i18next';

import serviceConfig from '../../../../serviceConfig.json';
import {
  GetReviewAssessmentsQueryData,
  GetReviewAssessmentsReponse,
  GetReviewAssessmentsPayload,
  GetTemplateCaretasksPayload,
  GetTemplateCaretasksResponse,
  DeleteCaretaskResponse,
} from './ReviewAssessments.type';

export const getReviewAssessments = (
  queryData: GetReviewAssessmentsQueryData,
  signal: AbortSignal
) =>
  ApiClient.GET<GetReviewAssessmentsReponse, GetReviewAssessmentsPayload>({
    url: serviceConfig.brokerService.getAllCareplanTemplatesForDatatable.uri,
    payload: {
      queryData,
    },
    signal,
    timeout: 30000,
  });

export const getTemplateCaretasks = async (id: number) =>
  ApiClient.GET<GetTemplateCaretasksResponse, GetTemplateCaretasksPayload>({
    url: serviceConfig.brokerService.getTemplateCareplans.uri,
    payload: { userLanguage: I18n.language, templateId: id },
  }).then(({ data }) => data.count);

export const deleteCaretaskRequest = async (id: number) =>
  ApiClient.GET<DeleteCaretaskResponse, {}>({
    url: serviceConfig.brokerService.deleteCareplanTemplate.uri + id,
  });
