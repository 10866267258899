/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import axios from 'axios';
import PropTypes from 'prop-types';

import { HideFlashMessage } from '@global/Services';

import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ModalFooter from '../common/Layout/Modal/ModalFooter';
import serviceConfig from '../../../serviceConfig.json';

import { CustomFilterUpdateModalContent } from './update';
import { useUserPermission } from '../../Utils/hooks/getUserPermission';

const UpdateFilterFromIndex = ({
  setFlashMessage,
  currCustomFilterId,
  closeModal,
  reloadFilters,
}) => {
  const [unionQuery, setUnionQuery] = useState({
    isUnion: false,
    unionRules: '',
    unionSqlQuery: null,
  });
  const hasSiteReadPermission = useUserPermission(
    I18n.t('types.componentType.sites'),
    I18n.t('actions.read'),
    true
  );
  const [displayModal, setDisplayModal] = useState(false);
  const [filterRule, setFilterRule] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [sites, setSites] = useState([]);
  const [existingFilters, setExistingFilters] = useState([]);
  const [preset, setPreset] = useState([]);
  const [filterName, setFilterName] = useState('');

  useEffect(() => {
    const checkCustomFilterEdit = async customFilterId => {
      try {
        const filterToUpdate = await axios.post(
          serviceConfig.brokerService.editCustomFilter.uri,
          {
            id: customFilterId,
          }
        );
        const filterData = filterToUpdate.data;

        if (filterData) {
          setFilterRule(filterData.filterRule);
          const filterSites = filterData.filter.institutions
            ? filterData.filter.institutions.map(site => ({
              value: site.id,
              label: site.name,
            }))
            : [];
          setFilterName(filterData.filter.name);
          setSelectedSite(filterSites);
          setSites(filterData.sites);
          setExistingFilters(filterData.prefilledFilters);
          setPreset(filterData.presetNames);
          setUnionQuery({
            isUnion: filterData.filter.isUnion,
            unionRules: filterData.filter.unionRules,
            unionSqlQuery: filterData.filter.unionSqlQuery,
          });
          setDisplayModal(true);
          return filterData;
        }
      } catch (error) {
        setDisplayModal(false);
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
        throw new Error(error.response);
      }
    };
    checkCustomFilterEdit(currCustomFilterId);
  }, []);

  return (
    <Modal
      title={I18n.t('customFilters_view.label_edit_customFilters')}
      contentComponent={(
        <CustomFilterUpdateModalContent
          customFilterId={currCustomFilterId}
          customFilterName={filterName}
          closeModal={closeModal}
          reloadTable={reloadFilters}
          setFlashMessage={setFlashMessage}
          LoadedRule={filterRule}
          selectedSite={selectedSite}
          setSelectedSite={setSelectedSite}
          sites={sites}
          existingFilters={existingFilters}
          preset={preset}
          unionQuery={unionQuery}
          hasSiteReadPermission={hasSiteReadPermission}
        />
      )}
      footerComponent={
        <ModalFooter
          close={closeModal}
          labelCTA={I18n.t('common_buttons.update')}
          form="create-custom-filter-form"
          fromIndex
        />
      }
      openModal={displayModal}
      onClose={closeModal}
      hideFlashMessage={HideFlashMessage}
    />
  );
};
UpdateFilterFromIndex.propTypes = {
  setFlashMessage: PropTypes.func.isRequired,
  currCustomFilterId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  reloadFilters: PropTypes.func.isRequired,
};

export default UpdateFilterFromIndex;
