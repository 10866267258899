/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';

import '../../css/wearables.css';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import {
  ActionColors,
  PrimaryColor,
} from '../../../caro-ui-commonfiles/utils/colors';
import { WearablesDataTypes } from '../../../caro-ui-commonfiles/utils/componentTypes';

import { getActivityButtonComponents } from './WearablesUtility';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';

const ActivityButtons = ({ active, onSelect, dataTypes, responsive,
}) => {
  const checkDisabled = key => {
    return (
      dataTypes &&
      dataTypes.length > 0 &&
      dataTypes.some(activity => activity.key === key && !activity.value)
    );
  };
  const ActivityButtonComponent = key => {
    const { component, name } = getActivityButtonComponents(
      key,
      responsive ? 20 : 24
    );
    if (key !== WearablesDataTypes.BLOOD_PREASURE) {

      return (
        <button
          key={`activity-careplan-${key}`}
          className="activity_data_button--overview"
          onClick={e => {
            e.preventDefault();
            if (!checkDisabled(key) && active !== key) {
              onSelect(key);
            }
          }}
          type="button"
          style={{
            borderColor: active === key ? PrimaryColor.MAIN_COLOR_DARK : '',
          }}
        >
          {checkDisabled(key) && <div className="disabled" />}
          {component}
          <Box margin="4px 0 0 0">{name}</Box>
        </button>
      );
    }
    return null;
  };

  const renderActivityButtons = () => {
    const wearablesTypes = Object.values(WearablesDataTypes);
    const midl = Math.ceil(wearablesTypes.length / 2);
    const activityButtons = {
      topButtons: wearablesTypes.slice(0, midl),
      bottomButtons: wearablesTypes.slice(midl),
    };
    return (
      <>
        <Flex justifyContent="center">
          {activityButtons?.bottomButtons?.map(key =>
            ActivityButtonComponent(key)
          )}
        </Flex>
      </>
    );
  };

  return (
    <Flex
      otherStyles={{ padding: '20px 0' }}
      flexDirection="column"
      alignItems="center"
    >
      <div className="patient-index__us-warning_custom-modal">
        <Flex alignItems="center">
          <Icon
            name="warning"
            className="patient-index__us-warning_custom-icon"
            size={29}
            fill={ActionColors.ERROR}
          />
        </Flex>
        <Box margin="auto 0" padding="4px 0">
          <span className="patient-index__us-warning_custom-txt">
            {I18n.t('warningMessage.wearableWarning')}
          </span>
        </Box>
      </div>
      <span className="activity-data_warning-selection">
        {I18n.t('patients_view.wearables_selectionForCareplan')}
      </span>

      <Flex justifyContent="space-between" otherStyles={{ width: '60%' }}>
        {renderActivityButtons()}
      </Flex>
    </Flex>
  );
};

ActivityButtons.propTypes = {
  active: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  dataTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default ActivityButtons;
