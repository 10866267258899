import React, {useState} from 'react';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import PatientFilterSelectLabel from '../PatientFilterSelectLabel/PatientFilterSelectLabel';
import {PatientFilterSelectOverflowProps} from './PatientFilterSelectOverflow.type';

const PatientFilterSelectOverflow = ({filters, type} : PatientFilterSelectOverflowProps) => {
    const [showDropdown, setShowDropDown] = useState(false);
    const show = () => setShowDropDown(true);
    const hide = () => setShowDropDown(false);
    return (
        <div onMouseOver={show} onMouseLeave={hide} className="filters__dropdown-overflow">
            <div className='filters__dropdown-overflow-indicator'>⋯</div>
            {showDropdown && (
                <div onClick={e => e.stopPropagation()} className="filters__dropdown-overflow-content">
                    <div className="filters__dropdown-overflow-content-triangle" />
                    <Flex className="filters__dropdown-overflow-content-container">
                        {filters.map((filter, index) => <PatientFilterSelectLabel key={index} filter={filter} position={index + 3} type={type} />)}
                    </Flex>
                </div>
            )}
        </div>
  )};
  
  export default PatientFilterSelectOverflow;
  