import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@ui-common-files/components';

import { TabPosition } from '@type/TabPosition';
import { PatientsListTabsProps } from './PatientsListTabs.type';

const PatientsListTabs: React.FC<PatientsListTabsProps> = ({
  activeTab,
  onClickTabItem,
  oldPatientsCounter,
  newPatientsCounter,
}) => {
  const { t } = useTranslation();

  return (
    <Box className="tabs">
      <ol className="tab-list" role="tablist">
        <li
          role="tab"
          aria-label="tab"
          className={
            activeTab === TabPosition.FIRST
              ? 'tab-list__item tab-list__item--active'
              : 'tab-list__item'
          }
          onClick={() => onClickTabItem(TabPosition.FIRST)}
          onPointerDown={() => onClickTabItem(TabPosition.FIRST)}
          id={TabPosition.FIRST.toString()}
          onKeyDown={null}
        >
          {`${t('patients_view.oldPatientsTab')} (${oldPatientsCounter})`}
        </li>
        <li
          role="tab"
          aria-label="tab"
          className={
            activeTab === TabPosition.SECOND
              ? 'tab-list__item tab-list__item--active'
              : 'tab-list__item'
          }
          onClick={() => onClickTabItem(TabPosition.SECOND)}
          onPointerDown={() => onClickTabItem(TabPosition.SECOND)}
          id={TabPosition.SECOND.toString()}
          onKeyDown={null}
        >
          {`${t('patients_view.newPatientsTab')} (${newPatientsCounter})`}
        </li>
      </ol>
    </Box>
  );
};

export default PatientsListTabs;
