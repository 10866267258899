const analytics = (
  state = {},
  {
    type,
    careplanAnalyticData,
    patientCareplanData,
    allCustomFilters,
    peerGroupSelected,
    peerGroupOptions,
    peerGroupSelectedFromUI,
    templateId,
    selectedTemplates,
    allTemplates,    
  }
) => {
  switch (type) {    
    case 'CAREPLAN_PATIENT_DATA_SUCCESS':
      return {
        ...state,
        patientCareplanData,
        selectedTemplates: [patientCareplanData],
        peerGroupSelectedFromUI,
      };
    case 'CAREPLAN_PATIENT_DATA_FAILED':
      return { ...state, patientCareplanData: null };
    
    case 'CUSTOMFILTER_DATA_SUCCESS':
      return {
        ...state,
        allCustomFilters,
        peerGroupOptions,
      };
    case 'CUSTOMFILTER_DATA_FAILED':
      return { ...state, allCustomFilters: null };
    
    case 'CAREPLAN_ANALYTIC_DATA_SUCCESS':
      return {
        ...state,
        careplanAnalyticData: careplanAnalyticData.analyticResults,
        selectedTemplates: [...state.selectedTemplates].map((template, i) =>
          template.id == careplanAnalyticData.templateId
            ? {
                ...template,
                careplanAnalyticData: careplanAnalyticData.analyticResults,                
                peerGroups:template.peerGroups
              }
            : { ...template, peerGroups: template.peerGroups }
        ),
      };
    case 'CAREPLAN_ANALYTIC_DATA_FAILED':
      return { ...state, careplanAnalyticData: null };
    case 'UPDATE_SELECTED_PEERGROUP_REQUESTED':
      return {
        ...state,
        peerGroupSelectedFromUI,
        templateId,
        selectedTemplates: [...state.selectedTemplates].map((template, i) =>
          template.id == templateId
            ? { ...template, peerGroups: peerGroupSelectedFromUI }
            : { ...template, peerGroups: template.peerGroups }
        ),
      };
    case 'UPDATE_SELECTED_PEERGROUP_SUCCESS':
      return {
        ...state,
        peerGroupSelectedFromUI,
        selectedTemplates: [...state.selectedTemplates].map((template, i) =>
          template.id == templateId
            ? { ...template, peerGroups: peerGroupSelectedFromUI }
            : { ...template, peerGroups: template.peerGroups }
        ),
      };
    case 'UPDATE_SELECTED_PEERGROUP_FAILED':
      return { ...state };
    
    case 'UPDATE_CAREPLAN_PEERGROUP_SUCCESS':
      return {
        ...state,
        peerGroupUpdated: peerGroupSelectedFromUI,
        selectedTemplates: [...state.selectedTemplates].map((template, i) =>
          template.id == templateId
            ? { ...template, peerGroups: peerGroupSelected }
            : { ...template, peerGroups: template.peerGroups }
        ),
      };
    case 'UPDATE_CAREPLAN_PEERGROUP_FAILED':
      return { ...state };
    
    case 'GET_ALL_TEMPLATE_SUCCESS': {
      return { ...state, allTemplates };
    }
    case 'GET_TEMPLATE_DETAIL_SUCCESS': {
      let newTemplateSelection;
      if (state.selectedTemplates) {
        newTemplateSelection = selectedTemplates.map(template => {
          const foundTemplate = state.selectedTemplates.find(templ => templ.id === template.id);
          return foundTemplate || template;          
        });
        return {
          ...state,
          selectedTemplates: newTemplateSelection
        }
      }
      else {
        return { ...state, selectedTemplates };
      }
    }
    case 'CLEAR_PREVIEWED_PEERGROUP_RESPOPNSE': {      
      const copied = [...state.selectedTemplates];
      copied.splice(
        copied.findIndex(template => template.id === selectedTemplates.id),
        1,
        selectedTemplates
      );
      return {
        ...state,
        selectedTemplates: copied
      };
    }
    default:
      return state;
  }
};
export default analytics;
