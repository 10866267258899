import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import { Box, Flex } from '@ui-common-files/components';
import OncoBoxSectionCard from '../../OncoboxSectionCard/OncoBoxSectionCard';
import InfiniteScroll from '../../../../caro-ui-commonfiles/components/InfiniteScroll/InfiniteScroll';

import { PatientOverviewContext } from '../PatientOverview';

import {
  Case,
  PatientOverviewOncoboxImportedDataType,
} from '../oncobox.interface';

import { PrimaryColor, Greys } from '@ui-common-files/utils';

import useOncoboxImportedDataService from './OncoboxImportedData.service';
import OncoBoxCaseSectionCard from 'src/Views/OncoboxSectionCard/OncoBoxCaseSectionCard';

const OncoboxImportedData = ({
  patientId,
}: PatientOverviewOncoboxImportedDataType) => {
  const {
    isLoading,
    importedDataContainerRef,
    importedDataViewerSize,
    sectionNamesList,
    oncoboxData,
  } = useOncoboxImportedDataService(parseInt(patientId));

  const { isChatOpened } = useContext(PatientOverviewContext);
  const { t } = useTranslation();

  const loader = () => {
    return (
      <Box className="oncobox__wrapper__loader">
        <Box className="data-table__loader">
          <Flex justifyContent="center" alignItems="center">
            <Loader
              type="Oval"
              color={PrimaryColor.MAIN_COLOR}
              height={60}
              width={60}
            />
          </Flex>
        </Box>
      </Box>
    );
  };

  return (
    <div
      className="oncobox__wrapper"
      ref={importedDataContainerRef}
      style={{
        height: importedDataViewerSize.height,
        width: !isChatOpened ? '100%' : importedDataViewerSize.width - 410,
      }}
    >
      {isLoading && loader()}
      {!isLoading && (
        <InfiniteScroll
          onScrollUp={() => {}}
          onScrollDown={() => {}}
          loadingTop={false}
          loadingBottom={false}
          loadingComponent={loader}
        >
          <Box>
            {oncoboxData && oncoboxData.patientAttributes && (
              <OncoBoxSectionCard
                key={`oncoboxSection-masterData`}
                sectionName={'masterData'}
                sectionData={oncoboxData.patientAttributes}
              />
            )}
            {oncoboxData && oncoboxData.cases?.length > 0 ? (
              oncoboxData.cases.map((caseItem: Case) => {
                return (
                  <OncoBoxCaseSectionCard
                    key={`oncoboxSection-case`}
                    sectionName={`case`}
                    sectionData={caseItem}
                  />
                );
              })
            ) : (
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                otherStyles={{ minHeight: '100px', color: Greys.LIGHT_GREY }}
              >
                {t('patients_view.oncoBoxNoImportedData')}
              </Flex>
            )}
          </Box>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default OncoboxImportedData;
