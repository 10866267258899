/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import componentTypeOrder from '../careplanComponentTypeOrder';
import MultipleChoiceGroup from './MultipleChoice';
import SingleChoice from './SingleChoice';
import NumericValue from './NumericValue';
import NumericRange from './NumericRange';
import Information from './Information';

import {
  addSingleChoices,
  removeSortables,
  onChangeOptionName,
} from '../../../Utils/Careplan/careplanComponentUtilities';
import BloodPressureJump from './BloodPressure';

const LogicJumpContent = ({
  item,
  componentCollection,
  collectionIndex,
  setComponentCollection,
  validationErrorTemplate,
  hasValidationErrorTemplate,
}) => {
  const componentTypeId = item.collection.key;

  const setLogicJumpValueForComponent = useCallback(
    (key, value) => {
      const updatedComponentCollection = [...componentCollection];
      if (!key) {
        updatedComponentCollection[collectionIndex] = value;
      } else {
        updatedComponentCollection[collectionIndex][key] = value;
      }
      setComponentCollection(updatedComponentCollection);
    },
    [collectionIndex, componentCollection, setComponentCollection]
  );

  const setLogicJumpError = optionIndex => {
    const { logicJumpCollectionError } = validationErrorTemplate;
    const jumpErrors = { ...logicJumpCollectionError };
    const error = jumpErrors[item.id] === undefined ? {} : jumpErrors[item.id];
    if (optionIndex > -1) {
      if (typeof error === 'object') {
        error[optionIndex - 1] = true;
      }
      jumpErrors[item.id] = error;
    } else if (optionIndex < 0 && error[-optionIndex - 1] === true) {
      const actualOptionIndex = -optionIndex - 1;
      delete jumpErrors[item.id][actualOptionIndex];
      if (jumpErrors[item.id] && Object.keys(jumpErrors[item.id]).length < 1)
        delete jumpErrors[item.id];
    }
    hasValidationErrorTemplate({
      ...validationErrorTemplate,
      logicJumpCollectionError: jumpErrors,
    });
  };

  const setJumpContradictionError = optionIndex => {
    const { logicJumpContradictionError } = validationErrorTemplate;
    const jumpErrors = { ...logicJumpContradictionError };
    const error = jumpErrors[item.id] === undefined ? {} : jumpErrors[item.id];
    if (optionIndex > -1) {
      if (typeof error === 'object') {
        error[optionIndex - 1] = true;
      }
      jumpErrors[item.id] = error;
    } else if (optionIndex < 0 && error[-optionIndex - 1] === true) {
      const actualOptionIndex = -optionIndex - 1;
      delete jumpErrors[item.id][actualOptionIndex];
      if (jumpErrors[item.id] && Object.keys(jumpErrors[item.id]).length < 1)
        delete jumpErrors[item.id];
    }
    hasValidationErrorTemplate({
      ...validationErrorTemplate,
      logicJumpContradictionError: jumpErrors,
    });
  };

  const onOptionInputChange = (e, componentIndex, optionIndex) => {
    onChangeOptionName(
      e,
      componentCollection[componentIndex],
      optionIndex,
      componentCollection,
      setComponentCollection,
      componentIndex
    );
  };

  const removeOption = (componentIndex, optionIndex) => {
    removeSortables(
      componentCollection[componentIndex],
      optionIndex,
      componentCollection,
      setComponentCollection,
      validationErrorTemplate,
      hasValidationErrorTemplate
    );
  };

  const addOption = componentIndex => {
    addSingleChoices(
      componentCollection[componentIndex],
      componentIndex,
      componentCollection,
      setComponentCollection,
      () => {}
    );
  };

  const getJumpSelectionComponent = useCallback(() => {
    switch (componentTypeId) {
      case componentTypeOrder.MULTIPLE_CHOICE:
        return (
          <MultipleChoiceGroup
            component={item}
            componentIndex={collectionIndex}
          />
        );

      case componentTypeOrder.SINGLE_CHOICE:
        return (
          <SingleChoice
            component={item}
            setLogicJumpValueForComponent={setLogicJumpValueForComponent}
            componentIndex={collectionIndex}
            setLogicJumpError={setLogicJumpError}
            onOptionInputChange={onOptionInputChange}
            removeOption={removeOption}
            addOption={addOption}
          />
        );

      case componentTypeOrder.NUMERIC_VALUE:
        return (
          <NumericValue
            item={item}
            componentCollection={componentCollection}
            componentIndex={collectionIndex}
            setLogicJumpValueForComponent={setLogicJumpValueForComponent}
            isLogicJumpOn
            setLogicJumpError={setLogicJumpError}
            setJumpContradictionError={setJumpContradictionError}
          />
        );

      case componentTypeOrder.INFORMATION:
        return (
          <Information
            item={item}
            componentIndex={collectionIndex}
            componentCollection={componentCollection}
            setLogicJumpValueForComponent={setLogicJumpValueForComponent}
            isLogicJumpOn
            setLogicJumpError={setLogicJumpError}
            componentTypeId={componentTypeId}
          />
        );

      case componentTypeOrder.NUMERIC_RANGE:
        return (
          <NumericRange
            item={item}
            componentIndex={collectionIndex}
            componentCollection={componentCollection}
            setLogicJumpValueForComponent={setLogicJumpValueForComponent}
            isLogicJumpOn
            setLogicJumpError={setLogicJumpError}
            setJumpContradictionError={setJumpContradictionError}
          />
        );

      case componentTypeOrder.BLOOD_PRESSURE:
        return (
          <BloodPressureJump
            item={item}
            componentIndex={collectionIndex}
            setLogicJumpValueForComponent={setLogicJumpValueForComponent}
          />
        );
      default:
        break;
    }
  }, [collectionIndex, componentTypeId, item, setLogicJumpValueForComponent]);

  return <>{getJumpSelectionComponent()}</>;
};

LogicJumpContent.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
    ])
  ).isRequired,
  validationErrorTemplate: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool])
  ).isRequired,
  hasValidationErrorTemplate: PropTypes.func.isRequired,
  collectionIndex: PropTypes.number.isRequired,
  setComponentCollection: PropTypes.func.isRequired,
  componentCollection: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};
export default LogicJumpContent;
