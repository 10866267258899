import React, { useEffect, useMemo, useState } from 'react';

import { Tag } from '@ui-common-files/components';

import { ActionColors } from '@ui-common-files/utils';

import { getServices } from './ServicesList.api';
import serviceConfig from '../../../../serviceConfig.json';

const getAllServices = () => {
  const [fetchedData, setFetchedData] = useState([]);
  const [buildNumber, setBuildNumber] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const prepareDatatableRows = data => {
      const retrievedData = [...data];
      const rows = [];

      retrievedData.forEach(service => {
        rows.push({
          name: service[0],
          status: service[1].status ? 'OK' : 'N/A',
          version: service[1].version || 'N/A',
        });
      });
      return rows;
    };

    const fetchData = async () => {
      const url = serviceConfig.brokerService.getAllServices.uri;
      const response = await getServices(url);
      if (response?.data?.buildNumber) {
        setBuildNumber(response?.data?.buildNumber);
      }

      if (response?.data?.subscribedServices) {
        setFetchedData(
          prepareDatatableRows(
            Object.entries(response?.data?.subscribedServices)
          )
        );
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: () => {
          return <span>Service Name</span>;
        },
        accessor: 'name',
        headerClassName: 'data-table__column-1__header',
        sortable: true,
        filterable: false,
      },
      {
        Header: () => {
          return <span>Status</span>;
        },
        accessor: 'status',
        sortable: false,
        filterable: false,
        Cell: ({ row }) => {
          return (
            <Tag
              text={row.status}
              hasMark
              fill={
                row.status.substring(0, 2) === 'OK'
                  ? ActionColors.SUCCESS
                  : ActionColors.ERROR
              }
            />
          );
        },
      },
      {
        Header: () => {
          return <span>Service Version</span>;
        },
        accessor: 'version',
        headerClassName: 'data-table__column-1__header',
        sortable: false,
        filterable: false,
        Cell: ({ row }) => {
          return (
            <Tag
              text={row.version}
              hasMark
              fill={row.version ? ActionColors.SUCCESS : ActionColors.ERROR}
            />
          );
        },
      },
    ];
  }, []);

  return {
    fetchedData,
    loading,
    columns,
    buildNumber,
  };
};
export default getAllServices;
