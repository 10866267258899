import React from 'react';
import { PatientFilters } from './PatientListEditFilter.type';

import EditFilter from '../../CustomFilters/updateFromIndex';
import useEditFilterService from './PatientListEditFilter.service';

const PatientListEditFilter = ({ selectedFilter }: PatientFilters) => {
  const { handleSetFlashMessage, handleReloadFilters, onEditFilterClose } =
    useEditFilterService();

  return (
    <EditFilter
      setFlashMessage={handleSetFlashMessage}
      currCustomFilterId={selectedFilter.savedFilter.id}
      closeModal={onEditFilterClose}
      reloadFilters={handleReloadFilters}
    />
  );
};

export default PatientListEditFilter;
