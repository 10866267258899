import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Box, Icon, Button } from '@ui-common-files/components';
import { generateRandomId } from '@ui-common-files/utils';

import OverallScore from './OverallScore';
import SectionScore from './SectionScore';

import { ComplexScoringType } from './ComplexScoring.type';
import {
  hasOverallScore,
  getSectionScores,
  getOverallScore,
} from './ComplexScoring.utils';

import '../../../css/complexScore.scss';

function FirstRow({
  scoringFormulas,
  setScoringFormulas,
  renderLabelWithToggleButton,
  careplanTemplateOverallScoringToggle,
}): JSX.Element {
  const { t } = useTranslation();
  const hasOverallScoreSection = hasOverallScore(scoringFormulas);
  const sectionScores = getSectionScores(scoringFormulas);

  const items: { key: ComplexScoringType; value: string }[] = [];
  if (!hasOverallScoreSection) {
    items.unshift({
      key: ComplexScoringType.OverallScore,
      value: t('newCareplan_view.complex_score.total_score.text'),
    });
  }
  if (sectionScores.length < 10) {
    items.push({
      key: ComplexScoringType.SectionScore,
      value: t('newCareplan_view.complex_score.section_score.text'),
    });
  }

  //TODO: The several parts of code commented below are according to the current request found on INN-11186.
  // The decision is to maintain the code until stakeholder's decisions are made for the feature.

  const handleOnClick = (event, item) => {
    const id = generateRandomId();
    setScoringFormulas(prevState => {
      // if (item.key === ComplexScoringType.OverallScore) {
      //   return [
      //     {
      //       id,
      //       equation: '',
      //       scoringTypeId: ComplexScoringType.OverallScore,
      //       equationHasError: false,
      //       equationErrorMessage: '',
      //     },
      //     ...prevState,
      //   ];
      // }
      return [
        ...prevState,
        {
          id,
          equation: '',
          description: '',
          scoringTypeId: ComplexScoringType.SectionScore,
          equationHasError: false,
          equationErrorMessage: '',
          descriptionHasError: false,
          descriptionErrorMessage: '',
        },
      ];
    });
  };

  const shouldDisableDropdown =
    // sectionScores.length >= 11 && hasOverallScoreSection;
    sectionScores.length >= 10;

  //TODO: The Button component added should be replaced by the ButtonDropdown component once Overall Score is back
  return (
    <Flex
      alignItems="center"
      justifyContent={
        careplanTemplateOverallScoringToggle ? 'space-between' : null
      }
    >
      {renderLabelWithToggleButton(
        t('newCareplan_view.complex_score.toggle_title')
      )}
      {careplanTemplateOverallScoringToggle && (
        <Box width="max-content" margin="0 10px 0 0">
          {/* <ButtonDropdown
            isDisabled={shouldDisableDropdown}
            items={items}
            buttonLabel={t('newCareplan_view.complex_score.add_formula_button')}
            buttonType={ButtonType.BUTTON}
            variant={ButtonVariant.CONTAINED}
            onClick={(event, item) => handleOnClick(event, item)}
          /> */}
          <Button
            label={t(
              'newCareplan_view.complex_score.add_section_formula_button'
            )}
            onClick={(event, item) => handleOnClick(event, item)}
            disabled={shouldDisableDropdown}
          />
        </Box>
      )}
    </Flex>
  );
}

function SecondRow({
  setWithinIntervals,
  withinIntervals,
  componentCollection,
  isEvaluatingToggle,
  scoringFormulas,
  setScoringFormulas,
}): JSX.Element {
  const { t } = useTranslation();

  const overallScore = getOverallScore(scoringFormulas);

  return (
    <Flex flexDirection="column" className="complex-score__row__container">
      <Flex alignItems="center" className="complex-score__row__info-container">
        <Icon name="info" size={20} />
        <Box margin="0 0 0 5px">
          <p className="templateDescription" style={{ margin: '0' }}>
            {t('newCareplan_view.complex_score.information')}
          </p>
        </Box>
      </Flex>
      <Box>
        {overallScore && (
          <OverallScore
            setWithinIntervals={setWithinIntervals}
            withinIntervals={withinIntervals}
            componentCollection={componentCollection}
            scoringFormulas={scoringFormulas}
            setScoringFormulas={setScoringFormulas}
            isEvaluatingToggle={isEvaluatingToggle}
          />
        )}
      </Box>
    </Flex>
  );
}

function ThirdRow({
  withinIntervals,
  scoringFormulas,
  setScoringFormulas,
  componentCollection,
}): JSX.Element {
  return (
    <Flex className="complex-score__row__container">
      <Box flex={1}>
        {getSectionScores(scoringFormulas).map((sectionScore, index) => {
          return (
            <SectionScore
              key={sectionScore.id}
              index={index}
              sectionScore={sectionScore}
              setScoringFormulas={setScoringFormulas}
              withinIntervals={withinIntervals}
              componentCollection={componentCollection}
            />
          );
        })}
      </Box>
    </Flex>
  );
}

function ComplexScoreSetting({
  withinIntervals,
  scoringFormulas,
  isEvaluatingToggle,
  setScoringFormulas,
  setWithinIntervals,
  componentCollection,
  renderLabelWithToggleButton,
  careplanTemplateOverallScoringToggle,
}): JSX.Element {
  return (
    <Flex flexDirection="column">
      <FirstRow
        scoringFormulas={scoringFormulas}
        setScoringFormulas={setScoringFormulas}
        renderLabelWithToggleButton={renderLabelWithToggleButton}
        careplanTemplateOverallScoringToggle={
          careplanTemplateOverallScoringToggle
        }
      />
      {careplanTemplateOverallScoringToggle && (
        <>
          <SecondRow
            setWithinIntervals={setWithinIntervals}
            withinIntervals={withinIntervals}
            componentCollection={componentCollection}
            isEvaluatingToggle={isEvaluatingToggle}
            scoringFormulas={scoringFormulas}
            setScoringFormulas={setScoringFormulas}
          />
          <ThirdRow
            withinIntervals={withinIntervals}
            scoringFormulas={scoringFormulas}
            setScoringFormulas={setScoringFormulas}
            componentCollection={componentCollection}
          />
        </>
      )}
    </Flex>
  );
}

export default ComplexScoreSetting;
