import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton } from '@ui-common-files/components';
import { IconButtonVariant } from '@ui-common-files/utils';

import useCaretaskAnsweringContentAttachmentService from './CaretaskAnsweringContentAttachment.service';
import { ShowFileInRectangle } from './CaretaskAnsweringContentAttachment.utils';

import getAssetIcon from '../../../../../../../Utils/Asset/getAssetIcon';
import ASSET_TYPES from '../../../../../../../Utils/Asset/assetTypes';
import { navigateToExternalUrl } from '../../../../../../../Utils/Asset/assetHelper';

export default function CaretaskAnsweringContentAttachment() {
  const { t } = useTranslation();
  const { mediaURL, assetTypeId, isPreviewable, containerCSSClasses } =
    useCaretaskAnsweringContentAttachmentService();

  return (
    <Box className="answer-view__caretask-components-image">
      <Box className={containerCSSClasses}>
        {isPreviewable &&
          assetTypeId !== ASSET_TYPES.VIDEO &&
          assetTypeId !== ASSET_TYPES.AUDIO && (
            <Box className="answer-view__caretask-components-image__preview-icon">
              <IconButton
                type="button"
                name="previewInShow"
                size={36}
                variant={IconButtonVariant.PREVIEW}
                onClick={() => {
                  navigateToExternalUrl(mediaURL);
                }}
                tooltipText={t('asset_view.openInBrowser')}
              />
            </Box>
          )}
        <Box className="answer-view__caretask-components-image__preview-content">
          {isPreviewable ? (
            <ShowFileInRectangle
              assetTypeId={assetTypeId}
              mediaURL={mediaURL}
            />
          ) : (
            <Box className="no-preview">{getAssetIcon(assetTypeId, 80)}</Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
