import React from 'react';
import I18n from 'i18next';
import moment from 'moment';

import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Historial from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialLayout';
import HistorialStep from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialStep';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import { getAction, getComponent } from '../../Utils/History';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';
import CareplanDataPreview from './CareplanDataPreview';
import '../../css/careplans.scss';

const CareplanShowModalContent = ({ careplanDataForShow }) => {
  const { ownershipLogs } = careplanDataForShow;
  const renderCareplanHistory = () => {
    return ownershipLogs.map(function (log) {
      return (
        <Historial>
          <HistorialStep>
            <div className="show-template-history">
              <span>
                {I18n.t('history.assignment', {
                  user: log.user.username,
                  action: getAction(log.action).toLowerCase(),
                  component:
                    log.action === 'actions.seen'
                      ? `${getComponent(log.component)} ${I18n.t(
                          'threshold.thresholds'
                        )}`
                      : getComponent(log.component),
                  interpolation: { escapeValue: false },
                })}
              </span>

              <span className="show-template__history-date">
                {`${moment(log.timeOfAction)
                  .locale(I18n.language)
                  .format('DD MMM YYYY')} - ${moment(log.timeOfAction).format(
                  'HH:mm'
                )}`}
              </span>
            </div>
          </HistorialStep>
        </Historial>
      );
    });
  };

  return (
    <ModalContent>
      <ModalForm>
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <div className="history-label">
            <Label text={I18n.t('common_labels.history')} />
          </div>
          <div className="show-template__historial-wrapper">
            {ownershipLogs && renderCareplanHistory()}
          </div>
        </ModalSection>
        <ModalSection variant={MODAL_VARIANT.COL_W70}>
          <CareplanDataPreview careplanData={careplanDataForShow} />
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
};

export default CareplanShowModalContent;
