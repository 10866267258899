import React from 'react';
import I18n from 'i18next';

import serviceConfig from '../../../serviceConfig.json';
import { ApiClient } from '../../Global/Services';

import Occurrences from './Occurrences';
import { ViewCareplanModalFooter } from '../Careplans/ViewCareplan';

const renderReccuerenceModalContent = async (
  name,
  carePlanId,
  setModalContent,
  closeModal,
  deviceOffset
) => {
  setModalContent({
    title: `${I18n.t('recurrence.repetition')}: ${name}`,
    content: <Occurrences data={{ carePlanId, deviceOffset }} />,
    footer: <ViewCareplanModalFooter close={closeModal} />,
  });
};

export default renderReccuerenceModalContent;
