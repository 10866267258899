import React, { useEffect, useReducer, useState } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';

import { FlashMessageTypes } from '../../caro-ui-commonfiles/types/components';
import config from '../../config';
import { setCurrentUser } from '../../reducers/session';
import serviceConfig from '../../serviceConfig.json';
import Navigation from '../navigation/navigation';
import '../Utils/axios';
import { Firebase } from '../Utils/Firebase/firebase';
import { ApiClient } from '../Global/Services';
import useAnalytics from '../Global/Services/Analytics/UseAnalytics';
import useUpdateLocale from '@global/Hooks/useUpdateLocale';

import Box from '../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../caro-ui-commonfiles/components/Flex/Flex';
import ToastChat from '../../caro-ui-commonfiles/components/ToastNotification/ToastChat';
import ToastGeneric from '../../caro-ui-commonfiles/components/ToastNotification/ToastGeneric';
import toastHelper from '../../caro-ui-commonfiles/components/ToastNotification/toastHelper';
import ToastContainer from '../../caro-ui-commonfiles/components/ToastNotification/ToastNotification';
import ToastThreshold from '../../caro-ui-commonfiles/components/ToastNotification/ToastThreshold';
import storeInfo from '../../store';

import 'reactjs-popup/dist/index.css';
import '../css/reset/reset.css';

const App = ({}) => {
  const dispatch = useDispatch();
  useUpdateLocale();
  const { user } = useSelector(state => state.session);
  const services = useSelector(state => state.services);
  const [userPinAndOptinsInfo, setUserPinAndOptinsInfo] = useState({});
  const [hidePatientDemographics, setHidePatientDemographics] = useState(false);
  const { setUserInfo } = useAnalytics();
  const [isLoggedIn, setIsLoggedIn] = useReducer(
    (_state, loggedIn) => loggedIn,
    user?.hasOwnProperty('id')
  );
  const initSocketListeners = () => {
    const socket = io(`${window.location.host}`, config.socketOptions);
    if (
      services.thresholdNotification &&
      user.role &&
      user.role.isPatientDemographicData === false &&
      (config.nonMedical === false || config.nonMedical === 'false')
    ) {
      socket.on('thresholdNotification', data => {
        if (data.users.includes(user.id)) {
          toastHelper.info(
            <ToastThreshold
              thresholdTitle={i18n.t('threshold.thresholdNotification')}
              patientName={data.patientName}
              question={data.question}
              answer={data.answer}
            />
          );
        }
      });
    }
    if (services.chatNotification) {
      socket.on('chatMessageNotification', async data => {
        if (data.careTeamIds.includes(user.id)) {
          try {
            const response = await axios.get(
              serviceConfig.chatService.getMessageNotifications.uri
            );
            const responseData = response.data;
            if (responseData) {
              toastHelper.success(
                <ToastChat
                  unreadMessages={responseData.numChatMessages}
                  content={i18n.t('chatMessages.unreadMessages')}
                />
              );
            }
          } catch (error) {
            throw new Error(error);
          }
        }
      });
    }

    const toastGenericContent = (data, displayWithName = false) => (
      <Flex alignItems="center">
        <Box margin="0 10px 0 0"></Box>
        <Box>
          {data.type === FlashMessageTypes.Success
            ? `${i18n.t('newCareplan_view.carePathway')} "${
                data.carePathwayName
              }" ${i18n.t(data.content)}`
            : displayWithName
            ? `${i18n.t(data.content, { Carepathway: data.carePathwayName })}`
            : i18n.t(data.content)}
        </Box>
      </Flex>
    );

    socket.on(
      `hasNoValidApptForCarepathway/${user.id}/${user.sessionId}`,
      data => {
        if (data) {
          toastHelper.generic(
            <ToastGeneric content={toastGenericContent(data)} />,
            {
              type: data.type,
            }
          );
        }
      }
    );

    socket.on(`no_occurrences_created/${user.id}/${user.sessionId}`, data => {
      if (data) {
        const displayWithName = true;
        toastHelper.generic(
          <ToastGeneric content={toastGenericContent(data, displayWithName)} />,
          {
            type: data.type,
          }
        );
      }
    });

    socket.on(`carePathwayAssigned/${user.id}/${user.sessionId}`, data => {
      if (data) {
        const displayWithName = true;
        toastHelper.generic(
          <ToastGeneric content={toastGenericContent(data, displayWithName)} />,
          {
            type: data.type,
          }
        );
      }
    });

    socket.on(`caretaskAnswered/${user.id}/${user.sessionId}`, data => {
      if (data) {
        toastHelper.generic(
          <ToastGeneric
            content={i18n.t(data.content, {
              caretaskName: data.caretaskName,
            })}
          />,
          {
            type: data.type,
          }
        );
      }
    });

    socket.on(`carePathwaySent/${user.id}/${user.sessionId}`, data => {
      if (data) {
        toastHelper.generic(
          <ToastGeneric content={toastGenericContent(data)} />,
          {
            type: data.type,
          }
        );
      }
    });

    socket.on(`carePathwayDeleted/${user.id}/${user.sessionId}`, data => {
      if (data) {
        toastHelper.generic(
          <ToastGeneric content={toastGenericContent(data)} />,
          {
            type: data.type,
          }
        );
      }
    });

    socket.on(`carePathwayUnassigned/${user.id}/${user.sessionId}`, data => {
      if (data) {
        toastHelper.generic(
          <ToastGeneric content={toastGenericContent(data)} />,
          {
            type: data.type,
          }
        );
      }
    });

    socket.on(`assetUploaded/${user.id}/${user.sessionId}`, data => {
      if (data) {
        toastHelper.generic(<ToastGeneric content={i18n.t(data.content)} />, {
          type: data.type,
        });
      }
    });

    socket.on(`accessTokenUpdated/${user.id}`, data => {
      if (data) {
        const userForUpdateAccessToken =
          storeInfo.store.getState().session?.user;
        dispatch(
          setCurrentUser({
            ...userForUpdateAccessToken,
            accessToken: data.accessToken,
          })
        );
      }
    });

    return socket;
  };

  const refreshTokenApiCall = async () => {
    const userToRefresh = storeInfo.store.getState().session?.user;
    if (
      userToRefresh !== null &&
      userToRefresh !== 'null' &&
      userToRefresh !== undefined &&
      userToRefresh !== 'undefined'
    ) {
      const newAccessToken = await ApiClient.GET({
        url: serviceConfig.brokerService.refreshToken.uri,
      });
      if (newAccessToken && newAccessToken.data) {
        dispatch(
          setCurrentUser({ ...userToRefresh, accessToken: newAccessToken.data })
        );
      }
    }
  };

  useEffect(() => {
    if (
      user != null &&
      user != 'null' &&
      user != undefined &&
      user != 'undefined'
    ) {
      i18n.changeLanguage(user.languagePreference);
      const updatedUser = {
        ...user,
        expiryInterval: parseInt(config.accessTokenExpiration),
      };
      window.addEventListener('beforeunload', refreshTokenApiCall);
      const interval = setInterval(async () => {
        await refreshTokenApiCall();
      }, (updatedUser.expiryInterval - 2) * 60000);
      return async () => {
        window.removeEventListener('beforeunload', refreshTokenApiCall);
        clearInterval(interval);
      };
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      setUserInfo(`${user.id} - ${user.role.name}`);
      const notificationSocket = initSocketListeners();
      return function cleanup() {
        if (notificationSocket) {
          setUserInfo('loggedOutUser');
          notificationSocket.close();
        }
      };
    }
  }, [isLoggedIn]);

  return (
    <Firebase
      isLoggedIn={isLoggedIn}
      setIsLoggedIn={setIsLoggedIn}
      userPinAndOptinsInfo={userPinAndOptinsInfo}
      setUserPinAndOptinsInfo={setUserPinAndOptinsInfo}
      hidePatientDemographics={hidePatientDemographics}
      setHidePatientDemographics={setHidePatientDemographics}
    >
      <Box className="app-routes" otherStyles={{ height: '100%' }}>
        <ToastContainer />
        <Navigation isLoggedIn={isLoggedIn} />
      </Box>
    </Firebase>
  );
};

export default Sentry.withProfiler(App, { name: 'BrokerUI' });
