/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, Flex, Box } from '@ui-common-files/components';

import { Greys, useWindowWidth, MEDIA_QUERIES } from '@ui-common-files/utils';

const BubblePdf = ({ note, data, blobs }) => {
  const [blobPdfDirectLink, blobPdfThumbnail] = blobs;
  const [fileNameContainerWidth, setFileNameContainerWidth] = useState('');

  const windowWidth = useWindowWidth(window.innerWidth);

  useEffect(() => {
    if (windowWidth <= MEDIA_QUERIES.SCREEN_SM_IPAD)
      setFileNameContainerWidth('756px');
    else if (windowWidth <= MEDIA_QUERIES.SCREEN_MD)
      setFileNameContainerWidth('247px');
    else if (windowWidth <= MEDIA_QUERIES.SCREEN_L)
      setFileNameContainerWidth('287px');
    else setFileNameContainerWidth('485px');
  }, [windowWidth]);

  return (
    <a
      href={blobPdfDirectLink || data}
      target="_blank"
      rel="noopener noreferrer"
      className="file-pdf__container"
    >
      <Box className="file-pdf__thumbnail-container">
        {blobPdfThumbnail && (
          <img
            src={blobPdfThumbnail}
            alt={note}
            height="140px"
            width={
              windowWidth <= MEDIA_QUERIES.SCREEN_SM_IPAD ? '300px' : '100%'
            }
            className="file-pdf__thumbnail"
          />
        )}
      </Box>

      <Flex
        flexDirection="row"
        alignItems="center"
        otherStyles={{
          backgroundColor: Greys.LIGHT_GREY_10,
          padding: '8px 8px 8px 4px',
          borderRadius: '0 0 8px 8px',
        }}
      >
        <Box className="file-pdf__icon">
          <Icon name="pdf" size={24} />
        </Box>

        <Box
          margin="0 0 0 4px"
          className="note-bubble__file-text"
          otherStyles={{ width: fileNameContainerWidth }}
        >
          {note}
        </Box>
      </Flex>
    </a>
  );
};

BubblePdf.propTypes = {
  note: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  blobs: PropTypes.string.isRequired,
};

export default React.memo(BubblePdf);
