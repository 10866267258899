import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SingleSelectAddressSearchable from './SingleSelectAddressSearchable';

const AddressSearch = ({
  value,
  handleOnChange,
  hasError,
  placeholder,
  validationMessage,
}) => {
  const PlacesAPI = new window.google.maps.places.AutocompleteService();
  const AUTOCOMPLETE_OK = window.google.maps.places.PlacesServiceStatus.OK;
  const [address, setAddress] = useState(value.label);

  const handleInputChange = inputAddress => {
    setAddress(inputAddress || '');
  };

  const fetchPredictions = (inputValue, callback) => {
    const getSuggestions = suggestions => {
      return suggestions.map(suggestion => {
        return { value: suggestion.id, label: suggestion.description };
      });
    };

    const renderOptions = (predictions, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
      ) {
        callback([]);
      } else if (status === AUTOCOMPLETE_OK) {
        callback(getSuggestions(predictions));
      } else {
        throw new Error(status);
      }
    };
    if (inputValue && inputValue.length > 2) {
      PlacesAPI.getPlacePredictions(
        {
          input: inputValue,
        },
        renderOptions
      );
    } else {
      callback([]);
    }
  };

  return (
    <SingleSelectAddressSearchable
      value={value.label ? value : false}
      inputValue={address}
      onInputChange={handleInputChange}
      isDisabled={false}
      loadOptions={fetchPredictions}
      placeholder={placeholder}
      handleOnChange={handleOnChange}
      hasError={hasError}
      validationMessage={validationMessage}
    />
  );
};

AddressSearch.propTypes = {
  value: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  hasError: PropTypes.objectOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string.isRequired,
  validationMessage: PropTypes.string.isRequired,
};

export default AddressSearch;
