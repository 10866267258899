import React, { memo } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Flex,
  Icon,
  Tag,
  CircularProgressBar,
  DropdownMenu,
} from '@ui-common-files/components';

import { getScoreFraction } from '@utils/PatientOverview/patientOverviewHelper';

import { nonMedical } from '../../../../config';

import useBreadcrumbPatientInfoService from './BreadcrumbPatientInfo.service';

const BreadcrumbPatientInfo = ({
  patientInfo,
  patientStatus,
  language,
  isPatientDemographicData,
}) => {
  const scoringSchemeCategory = patientInfo.scoringSchemeCategory
    ? patientInfo.scoringSchemeCategory
    : { id: 'defaultCategory', min: '', max: '', color: '#D6D6D6' };
  const { t } = useTranslation();

  const { actionsDropdownItems } = useBreadcrumbPatientInfoService(
    isPatientDemographicData,
    patientInfo
  );
  const patientInfos = (
    <Flex otherStyles={{ cursor: 'pointer' }}>
      <CircularProgressBar
        value={
          nonMedical == false || nonMedical == 'false'
            ? getScoreFraction(scoringSchemeCategory.id)
            : 0
        }
        size={50}
        color={scoringSchemeCategory.color}
        imgSrc={patientInfo.profilePicture}
        hasIcon={false}
        isPatientDemographicData={isPatientDemographicData}
      />
      {patientInfo.medicalRecord && (
        <div className="patient-info-two-lines__wrapper">
          <Flex>
            {patientInfo.firstname && patientInfo.lastname && (
              <span className="patient-name">
                {`${patientInfo.firstname} ${patientInfo.lastname} | `}
              </span>
            )}

            <span className="patient-info">
              {`#${patientInfo.medicalRecord} ${
                !patientInfo.isBirthdayHardcoded ? '| ' : ''
              }`}
            </span>
            {patientInfo.pseudonymCode && (
              <span className="patient-info">
                {` #${patientInfo.pseudonymCode} | `}
              </span>
            )}
            <span className="patient-info">
              {!patientInfo.isBirthdayHardcoded
                ? patientInfo.isPatientDemographicData === true
                  ? t('patientOverview.labelAge') +
                    ' : ' +
                    moment().diff(patientInfo.birthdate, 'years')
                  : t('patientOverview.labelBirthdate', {
                      date: moment(patientInfo.birthdate)
                        .locale(I18n.language)
                        .format('DD MMM YYYY'),
                      age: moment().diff(patientInfo.birthdate, 'years'),
                    })
                : null}
            </span>
            <Box className="profile-button__arrow">
              <Icon name="expanded" size={25} />
            </Box>
          </Flex>

          <Tag
            text={t(`patientOverview.${patientStatus.translationKey}`)}
            variant="contained"
            type="squared"
            fill={patientStatus.color}
          />
        </div>
      )}
    </Flex>
  );

  const buildDropdownItems = () => {
    const result = actionsDropdownItems.map(function (item) {
      return (
        <li
          key={item.key}
          onClick={item.action}
          style={{ justifyContent: 'flex-start' }}
        >
          <Icon name={item.icon} size={30} />
          <span
            className="dropdown__item"
            style={{ fontSize: 16, alignContent: 'center' }}
          >
            {t(item.value)}
          </span>
        </li>
      );
    });
    return result;
  };

  return (
    <DropdownMenu items={buildDropdownItems()}>{patientInfos}</DropdownMenu>
  );
};

BreadcrumbPatientInfo.propTypes = {
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  patientStatus: PropTypes.objectOf(PropTypes.string).isRequired,
  language: PropTypes.string.isRequired,
};

export default memo(BreadcrumbPatientInfo);
