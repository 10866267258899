import { MEDIA_QUERIES } from '@ui-common-files/utils';
import { useWindowDimensions } from '@global/Hooks';

export const usePlannedMedicationCardService = () => {
  const { width: windowWidth } = useWindowDimensions();
  const flexDirection: 'column' | 'row' =
    windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row';

  return {
    flexDirection,
  };
};
