import { ApiClient } from '../../../Global/Services';
import { GetSitesAPI } from './Sites.type';
import serviceConfig from '../../../../serviceConfig.json';

export const getSite: GetSitesAPI = (url, query) =>
  ApiClient.POST({
    url,
    payload: { query },
  });

export const activateSiteRequest = (id: string) =>
  ApiClient.GET({
    url: serviceConfig.brokerService.activateSite.uri + id,
  });

export const deactivateSiteRequest = (id: string) =>
  ApiClient.GET({
    url: serviceConfig.brokerService.deactivateSite.uri + id,
  });

export const editSiteRequest = (id: string) =>
  ApiClient.GET({
    url: serviceConfig.brokerService.editSite.uri + id + id,
  });
