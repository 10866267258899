import React from 'react';
import I18n from 'i18next';
import componentTypeOrder from '../careplanComponentTypeOrder';
import {
  mapThresholdConditionals,
  getJumpData,
} from '../CareplanHelperUtility';
import Tag from '../../../../caro-ui-commonfiles/components/Tags/Tags';
import { TagsType, TagsVariant } from '@ui-common-files/utils';
import {
  PrimaryColor,
  Greys,
} from '../../../../caro-ui-commonfiles/utils/colors';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import NumberInput from '../../../../caro-ui-commonfiles/components/NumberInput/NumberInput';
import DropdownInputComponent from '../AssignComponents/DropdownInputComponent';

const renderTags = (choice, destination, key, componentTypeId) => {
  return (
    <div className="logic-jump__tag-container" key={key}>
      <Tag
        text={
          componentTypeId === componentTypeOrder.INFORMATION
            ? I18n.t('newQuestion_view.jumpToTag')
            : choice
        }
        variant={TagsVariant.CONTAINED}
        type={TagsType.SQUARED}
        fill={PrimaryColor.MAIN_COLOR}
        shouldWidthBeMaxContent={false}
      />
      <Tag
        text={I18n.t('newQuestion_view.jumpTo', {
          number: destination,
        })}
        variant={TagsVariant.OUTLINED}
        type={TagsType.ARROW}
        fill={Greys.LIGHT_GREY_75}
      />
    </div>
  );
};

const displayJumps = item => {
  let choice = '';
  let jumpsCollection = [];
  const componentTypeId = item.collection.key;
  if (
    componentTypeId === componentTypeOrder.NUMERIC_RANGE ||
    componentTypeId === componentTypeOrder.NUMERIC_VALUE ||
    componentTypeId === componentTypeOrder.INFORMATION
  ) {
    jumpsCollection =
      item.logicJumpCollection &&
      item.logicJumpCollection.filter(elem => {
        return (
          (elem.name !== '' && elem.value !== '' && elem.destination > -1) ||
          elem.destination
        );
      });
    if (jumpsCollection && jumpsCollection.length) {
      return jumpsCollection.map(elem => {
        if (componentTypeId != componentTypeOrder.INFORMATION) {
          choice = `${mapThresholdConditionals(elem.name.value)} ${elem.value}`;
        } else if (componentTypeId !== componentTypeOrder.BLOOD_PRESSURE) {
          choice = `${elem.name}`;
        } else {
          choice = '';
        }
        return renderTags(
          choice,
          elem.destination,
          `logic-jump-tag-${item.id}-${elem.value}`,
          componentTypeId
        );
      });
    }
  }

  if (
    (componentTypeId === componentTypeOrder.SINGLE_CHOICE ||
      componentTypeId === componentTypeOrder.MULTIPLE_CHOICE ||
      componentTypeId === componentTypeOrder.BLOOD_PRESSURE) &&
    item.logicJumpCollection
  ) {
    jumpsCollection = item.logicJumpCollection.filter(elem => {
      return (
        elem && elem.options && elem.options.length > 0 && elem.destination > -1
      );
    });
    return jumpsCollection.map(elem => {
      const [name] = elem.options;
      if (componentTypeId === componentTypeOrder.BLOOD_PRESSURE)
        choice = I18n.t(`bloodPressureOptions.labels.${name}`);
      else choice = name;
      return renderTags(
        choice,
        elem.destination,
        `logic-jump-tag-${item.id}-${choice}`,
        componentTypeId
      );
    });
  }
};

const renderFormattedJumpData = component => {
  const logicJumps = component.logicJumpCollection;
  let LogicJumpData = { isJumpSet: false, condition: [] };
  if (logicJumps && Object.keys(logicJumps).length > 0) {
    LogicJumpData = getJumpData(component, logicJumps);
  }

  const logicJumpDataForTags = {
    isJumpSet: LogicJumpData.isJumpSet,
    collection: { key: component.careplanComponentTypeId },
    logicJumpCollection: LogicJumpData.condition,
  };
  return logicJumpDataForTags;
};

const renderTagsForNumericCompononet = jumps => {
  return jumps.logicJumpCollection.map((jump, index) => {
    const choice = `${mapThresholdConditionals(jump.name.value)}`;
    const component = {};
    component.comparison = choice;
    component.numericValue = jump.value;
    return (
      <Box className="jump-collection-container jumpsTag">
        <Box className="condition-container jumpsTag">
          <Box margin="0 5px 0 0">
            <Icon
              name="logicJump"
              size={24}
              fill={PrimaryColor.MAIN_COLOR}
              style={{ borderRadius: '50%' }}
            />
          </Box>
          <DropdownInputComponent
            isDisabled
            onChange={() => {}}
            component={component}
            id={`default-${index}`}
            extendNumberInputWidth
          />
        </Box>
        <Box className="destination-container jumpsTag">
          <Box margin="0 0 6px 10px">
            <Tag
              text={I18n.t('newQuestion_view.jumpToTag')}
              variant={TagsVariant.CONTAINED}
              type={TagsType.SQUARED}
              fill={PrimaryColor.MAIN_COLOR}
            />
          </Box>
          <Box margin="0 0 10px 10px" width="80px">
            <NumberInput
              value={jump.destination}
              name="jump-selection"
              id={index}
              min={0}
              max={1000000}
              step={1}
              isDisabled
              changeValue={() => {}}
            />
          </Box>
        </Box>
      </Box>
    );
  });
};

const renderTagsForChoices = (jumps, index) => {
  return (
    <Box className="jumpsTag">
      <Box margin="0 0 0 10px" className="jumpsTag">
        <Tag
          text={I18n.t('newQuestion_view.jumpToTag')}
          variant={TagsVariant.CONTAINED}
          type={TagsType.SQUARED}
          fill={PrimaryColor.MAIN_COLOR}
        />
      </Box>
      <Box margin="0 0 0 10px" width="100px">
        <NumberInput
          value={jumps.logicJumpCollection[index].destination}
          name="jump-selection"
          id={index}
          min={0}
          max={1000000}
          step={1}
          isDisabled
          changeValue={() => {}}
        />
      </Box>
    </Box>
  );
};

export {
  displayJumps,
  renderFormattedJumpData,
  renderTagsForNumericCompononet,
  renderTagsForChoices,
};
