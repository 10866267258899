import patientTableActions from '../../src/Utils/ReduxUtilities/Actions/Datatables/patients.json';

export const onSearchAction = query => ({
  type: patientTableActions.ON_SEARCH,
  payload: query,
});

export const onTableChangeAction = query => ({
  type: patientTableActions.ON_TABLE_CHANGE,
  payload: query,
});

export const onFilterChangeAction = query => ({
  type: patientTableActions.ON_FILTER_CHANGE,
  payload: query,
});

export const onCustomFilterChangeAction = query => ({
  type: patientTableActions.ON_CUSTOM_FILTER_CHANGE,
  payload: query,
});

export const resetPatientTable = () => ({
  type: patientTableActions.RESET_PATIENT_TABLE,
});

export const resetPaginationPatientTable = () => ({
  type: patientTableActions.RESET_PAGINATION_PATIENT_TABLE,
});
