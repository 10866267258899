import React from 'react';
import i18next from 'i18next';
import Dropdown from '../../../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import getCriteria from '../../../../../Utils/PatientFilter/PatientFilter';
import {FilterActions} from '../../../../../Utils/PatientFilter/PatientFilter.enum';
import { FieldType, FilterCriteriaFieldSelectorProps } from './FilterCriteriaFieldSelector.type';
import { useSelector } from 'react-redux';

const FilterCriteriaFieldSelector = ({ criteria, type } : FilterCriteriaFieldSelectorProps) => {
  const { updateCriterias } = usePatientFilterContext();
  const { user } = useSelector(state => state.session);
  
  const fields = getCriteria(user.role.isPatientDemographicData)[type].map(option => ({
    ...option,
    value: option.id,
    label: i18next.t(option.labelLocation),
  }));

  const onCriteriaFieldChange = (field : FieldType) => {
    updateCriterias({
      action: FilterActions.Update,
      criteria: {
        values: {
          field: {
            ...field,
            label: i18next.t(field.labelLocation),
          },
          check: {
            ...field.operators[0],
            label: i18next.t(field.operators[0].labelLocation),
          },
          value: [''],
        },
        position: criteria.position
      }
    });
  }

  const field = {...criteria.field, label : i18next.t(criteria.field.labelLocation)};

  return (
    <div className="filters__criteria-select">
    <Dropdown
        value={field}
        options={fields}
        placeholder="Criteria"
        handleOnChange={onCriteriaFieldChange}
    />
    </div>
  );
};

export default FilterCriteriaFieldSelector;
