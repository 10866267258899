/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  TextInput
} from '@ui-common-files/components';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import IcdSelector from '../../../caro-ui-commonfiles/components/ICDDropdownSelect/ICDSelect';
import icdThreshold from '../../Utils/icdThresholds';
import serviceConfig from '../../../serviceConfig.json';
import Dropdown from '../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import RadioButton from '../../../caro-ui-commonfiles/components/RadioButton/RadioButton';
import CheckboxRadio from '../../../caro-ui-commonfiles/utils/CheckboxRadio';
import '../../css/careplans.scss';
import RecurrenceComponent from '../../../caro-ui-commonfiles/components/molecules/Recurrence/Recurrence';
import { checkRecurrenceError } from '../Careplans/CareplanHelperUtility';
import {
  ComponentTypes,
  Actions,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { dropdownPhaseTypes } from '../../Utils/dropdownTypes';
import inputField from '../../../caro-ui-commonfiles/utils/inputField';
import maxCharLimit from '../../../caro-ui-commonfiles/utils/maxcharlimits.json';

const AssignTemplateStep2 = ({
  careplanData,
  setCareplanData,
  dispatchFormValidator,
  shouldValidateForm,
  currentStep,
  setCurrentStep,
  fromPatientOverview,
}) => {
  const { icds, title } = careplanData;
  const [validateRecurrence, setValidateRecurrence] = useState(false);
  const [startNow, setStartNow] = useState(
    careplanData.startNow ? careplanData.startNow : false
  );
  const { t } = useTranslation();

  const [recurrenceErrors, setRecurrenceErrors] = useState({
    lifespan: false,
    recurrence: false,
    recurrenceRepeat: false,
    startDate: false,
    endDate: false,
    occurrenceCount: false,
  });

  const { user } = useSelector(state => state.session);

  const continueToNextStep = () => {
    setCurrentStep(currentStep + 1);
    dispatchFormValidator({
      component: '',
      action: '',
      shouldValidate: false,
    });
  };

  useEffect(() => {
    if (
      (shouldValidateForm.component === ComponentTypes.CAREPLAN &&
        shouldValidateForm.action === Actions.Create) ||
      shouldValidateForm.action === Actions.Update
    ) {
      if (!careplanData.startNow) {
        setValidateRecurrence(shouldValidateForm.shouldValidate);
      } else if (careplanData?.title?.trim().length > 0) {
        continueToNextStep();
      }
    }
  }, [shouldValidateForm]);

  useEffect(() => {
    if (
      validateRecurrence &&
      !checkRecurrenceError(recurrenceErrors) &&
      title?.trim()
    ) {
      continueToNextStep();
    } else {
      setValidateRecurrence(false);
    }
  }, [recurrenceErrors]);

  const onValueChange = (value, key) => {
    setCareplanData({
      ...careplanData,
      [key]: value,
    });
  };
  const [startingArrays, setStartingArrays] = useState({
    startingDays: [],
    startingTimes: [],
  });

  return (
    <>
      <ModalForm formId="assign-careplan-form" handleFormSubmit={() => {}}>
        <ModalSection>
          {fromPatientOverview &&
          careplanData.shouldUserAnswerCareplan &&
          careplanData.isAssessment ? (
            <Box padding="10px 20px">
              <Flex flexDirection="row" otherStyles={{ margin: '0 0 10px' }}>
                <RadioButton
                  id="assignToPatient"
                  name="assignToPatient"
                  isDisabled={false}
                  variant={CheckboxRadio.Default}
                  checked={!startNow}
                  handleOnChange={event => {
                    setStartNow(!event.target.checked);
                    onValueChange(!event.target.checked, 'startNow');
                  }}
                  label={t('newCareplan_view.assignToPatient')}
                />
              </Flex>
              <Flex flexDirection="row" otherStyles={{ margin: '10px 0 10px' }}>
                <RadioButton
                  id="startNow"
                  name="startNow"
                  isDisabled={false}
                  variant={CheckboxRadio.Default}
                  checked={startNow}
                  handleOnChange={event => {
                    setStartNow(event.target.checked);
                    onValueChange(event.target.checked, 'startNow');
                  }}
                  label={t('newCareplan_view.answerCareplanNow')}
                />
              </Flex>
              <Box
                otherStyles={{ margin: '0 0 0 35px' }}
                className="lineBetweenToggleContainers__or">
                {t('newCareplan_view.theOccurenceWillBeForOneTime')}
              </Box>
            </Box>
          ) : (
            <></>
          )}
          <ComponentWithLabel
            labelText={`* ${t('newCareplan_view.title')}`}
            tooltipText={t('newCareplan_view.title')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <TextInput
              value={title || ''}
              name="title"
              placeholder={t('appointment.add_title')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.editCarePlan.editCarePlanMaxCharLimit}
              maxChars={maxCharLimit.editCarePlan.editCarePlanMaxCharLimit}
              handleOnChange={event =>
                onValueChange(event.target.value, 'title')
              }
              hasError={shouldValidateForm.shouldValidate && !title?.trim()}
              validationMessage={t('parsley.requiredField')}
            />
          </ComponentWithLabel>

          <ComponentWithLabel labelText={t('task.phaseType')}>
            <Dropdown
              value={careplanData.phaseType}
              options={dropdownPhaseTypes()}
              placeholder={t('task.phaseType')}
              handleOnChange={event => {
                onValueChange(event, 'phaseType');
              }}
            />
          </ComponentWithLabel>
          <ComponentWithLabel labelText={t('task.ICDReference')}>
            <IcdSelector
              maxLengthItem={icdThreshold.CAREPLAN}
              onSelect={selected => {
                onValueChange(selected, 'icds');
              }}
              icdSelected={icds}
              fetchData={term => {
                return axios(serviceConfig.getIcds.uri, {
                  params: {
                    searchTerm: term,
                  },
                }).then(function (response) {
                  return response.data;
                });
              }}
              placeholder={t('newCareplan_view.placeholderICDReference')}
            />
          </ComponentWithLabel>
        </ModalSection>
        <ModalSection>
          <div style={{ opacity: startNow ? 0.35 : 1 }}>
            <RecurrenceComponent
              isDisabled={startNow}
              componentType="careplan"
              onValueChange={onValueChange}
              values={careplanData}
              recurrenceRule={careplanData.rruleRecurrence}
              validateRecurrence={validateRecurrence}
              hasErrors={recurrenceErrors}
              setHasErrors={setRecurrenceErrors}
              setStartingArrays={setStartingArrays}
            />
          </div>
        </ModalSection>
      </ModalForm>
    </>
  );
};

export default AssignTemplateStep2;
