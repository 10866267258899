import React from 'react';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@ui-common-files/components';
import TodoAssessmentCard from './TodoAssessmentCard';

import { PrimaryColor } from '@ui-common-files/utils';
import useTodoCardsService from './TodoCards.service';
import { TodoCardsProps } from './TodoCards.type';

import styles from './TodoCards.module.scss';

export default function TodoCards({
  patientId,
  searchValue,
  updateCardCount,
}: TodoCardsProps) {
  const { t } = useTranslation();
  const { todos, loading } = useTodoCardsService({
    patientId,
    updateCardCount,
    searchValue,
  });

  if (loading)
    return (
      <Box className={styles.loader}>
        <Loader
          type="Oval"
          color={PrimaryColor.MAIN_COLOR}
          height={60}
          width={60}
        />
      </Box>
    );

  if (todos.length === 0)
    return (
      <Box className={styles.empty}>{t('common_labels.no_todos_assigned')}</Box>
    );

  return (
    <Flex className={styles.container}>
      {todos.map(assessment => (
        <TodoAssessmentCard key={assessment.id} assessment={assessment} />
      ))}
    </Flex>
  );
}
