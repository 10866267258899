import React from 'react';

const HearRateActivity = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 18.7891C9.8623 18.7891 9.72461 18.7407 9.61414 18.6441C8.73547 17.8752 7.91031 17.1736 7.1123 16.495C2.82945 12.8529 0 10.4468 0 6.93418C0 5.41652 0.5225 3.9948 1.47129 2.93086C2.46031 1.82176 3.82449 1.21094 5.3125 1.21094C6.84535 1.21094 8.2377 1.84371 9.33902 3.04078C9.60586 3.33086 9.82516 3.62117 10 3.88191C10.1748 3.62117 10.3941 3.33086 10.661 3.04078C11.7623 1.84371 13.1546 1.21094 14.6875 1.21094C16.1755 1.21094 17.5397 1.82176 18.5287 2.93086C19.4775 3.9948 20 5.41656 20 6.93418C20 10.4468 17.1705 12.8529 12.8877 16.4949C12.0897 17.1735 11.2645 17.8752 10.3859 18.6441C10.2754 18.7407 10.1377 18.7891 10 18.7891V18.7891Z"
      fill="#FF6C6C"
    />
    <path
      d="M12.8877 16.495C17.1705 12.8529 20 10.4468 20 6.93418C20 5.41652 19.4775 3.9948 18.5287 2.93086C17.5397 1.82176 16.1755 1.21094 14.6875 1.21094C13.1546 1.21094 11.7623 1.84371 10.661 3.04078C10.3941 3.33086 10.1748 3.62117 10 3.88191V18.7891C10.1377 18.7891 10.2754 18.7407 10.3859 18.6441C11.2645 17.8752 12.0897 17.1736 12.8877 16.495V16.495Z"
      fill="#E63950"
    />
    <path
      d="M8.8282 12.9297C8.82523 12.9297 8.82227 12.9297 8.81926 12.9296C8.56383 12.9257 8.34031 12.7568 8.26691 12.5121L7.00859 8.31771L5.86113 11.3776C5.77539 11.6063 5.55676 11.7578 5.3125 11.7578H0.585938C0.262344 11.7578 0 11.4954 0 11.1718C0 10.8483 0.262344 10.5859 0.585938 10.5859H4.90645L6.52168 6.27861C6.61 6.04318 6.83922 5.89084 7.09004 5.89873C7.34141 5.90721 7.5593 6.0751 7.63152 6.316L8.85754 10.4026L9.44414 8.64283C9.5191 8.41798 9.72207 8.26044 9.95848 8.24365C10.1959 8.22681 10.4181 8.35412 10.5241 8.56607L11.1719 9.86165L12.4056 7.3942C12.5048 7.19568 12.7077 7.07029 12.9297 7.07029C13.1516 7.07029 13.3545 7.19568 13.4537 7.3942L15.0496 10.5859H19.4141C19.7377 10.5859 20 10.8483 20 11.1718C20 11.4954 19.7377 11.7578 19.4141 11.7578H14.6875C14.4655 11.7578 14.2627 11.6324 14.1634 11.4339L12.9297 8.96642L11.6959 11.4339C11.5967 11.6324 11.3938 11.7578 11.1719 11.7578C10.9499 11.7578 10.747 11.6324 10.6478 11.4339L10.1085 10.3554L9.38398 12.529C9.30418 12.7685 9.08 12.9297 8.8282 12.9297V12.9297Z"
      fill="#E6FBFF"
    />
    <path
      d="M19.4141 10.5859H15.0496L13.4538 7.39422C13.3545 7.1957 13.1516 7.07031 12.9297 7.07031C12.7077 7.07031 12.5048 7.1957 12.4056 7.39422L11.1719 9.86168L10.5241 8.56609C10.4243 8.36656 10.2215 8.24215 10 8.24227V10.681L10.1085 10.3554L10.6478 11.4339C10.747 11.6324 10.9499 11.7578 11.1719 11.7578C11.3938 11.7578 11.5967 11.6324 11.6959 11.4339L12.9297 8.96645L14.1634 11.4339C14.2627 11.6324 14.4655 11.7578 14.6875 11.7578H19.4141C19.7377 11.7578 20 11.4955 20 11.1719C20 10.8483 19.7377 10.5859 19.4141 10.5859V10.5859Z"
      fill="#CCEEFF"
    />
  </svg>
);

const SleepActivity = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#7584F2"
    />
    <path
      d="M20 9.99981C20 9.89528 19.9984 9.7911 19.9952 9.68735L15.5848 5.27692L13.745 6.75192L10.08 3.08691L8.2602 4.99223L6.94914 3.68117L3.00781 8.36247L4.69238 15.5807L9.06867 19.957C9.37531 19.9853 9.68598 19.9998 10 19.9998C15.5229 19.9998 20 15.5227 20 9.99981Z"
      fill="#4253CE"
    />
    <path
      d="M11.8058 14.0289C8.31844 14.0289 5.49137 11.2019 5.49137 7.71453C5.49137 6.18113 6.03812 4.77555 6.94707 3.68164C4.33609 4.78547 2.50391 7.37055 2.50391 10.3836C2.50395 14.4003 5.76016 17.6565 9.77691 17.6565C13.4765 17.6565 16.5309 14.8941 16.9901 11.3195C15.8493 12.957 13.9529 14.0289 11.8058 14.0289Z"
      fill="#FFCE00"
    />
    <path
      d="M11.8091 14.0288C10.0987 14.0288 8.54746 13.3484 7.41035 12.244L4.43359 15.3133C5.7625 16.7538 7.66582 17.6564 9.78023 17.6564C13.4798 17.6564 16.5342 14.894 16.9934 11.3193C15.8526 12.9569 13.9562 14.0288 11.8091 14.0288Z"
      fill="#FDBA12"
    />
    <path
      d="M9.06066 5.47485C9.84061 5.47485 10.4729 4.84257 10.4729 4.06262C10.4729 3.28267 9.84061 2.65039 9.06066 2.65039C8.28071 2.65039 7.64844 3.28267 7.64844 4.06262C7.64844 4.84257 8.28071 5.47485 9.06066 5.47485Z"
      fill="white"
    />
    <path
      d="M10.4708 4.06254C10.4708 3.28391 9.84063 2.65254 9.0625 2.65039V5.47465C9.84067 5.47258 10.4708 4.84122 10.4708 4.06254Z"
      fill="#E9EDF5"
    />
    <path
      d="M11.7121 11.1694C12.2284 11.1694 12.647 10.7509 12.647 10.2346C12.647 9.71833 12.2284 9.2998 11.7121 9.2998C11.1959 9.2998 10.7773 9.71833 10.7773 10.2346C10.7773 10.7509 11.1959 11.1694 11.7121 11.1694Z"
      fill="white"
    />
    <path
      d="M12.6477 10.2344C12.6477 9.72137 12.2344 9.30508 11.7227 9.2998V11.169C12.2344 11.1637 12.6477 10.7474 12.6477 10.2344Z"
      fill="#E9EDF5"
    />
    <path
      d="M14.5568 7.65747C15.3367 7.65747 15.969 7.02519 15.969 6.24524C15.969 5.46528 15.3367 4.83301 14.5568 4.83301C13.7768 4.83301 13.1445 5.46528 13.1445 6.24524C13.1445 7.02519 13.7768 7.65747 14.5568 7.65747Z"
      fill="white"
    />
    <path
      d="M14.5578 4.83301C14.5541 4.83301 14.5505 4.83324 14.5469 4.83328V7.65719C14.5505 7.65723 14.5541 7.65747 14.5578 7.65747C15.3378 7.65747 15.97 7.0252 15.97 6.24524C15.97 5.46528 15.3378 4.83301 14.5578 4.83301Z"
      fill="#E9EDF5"
    />
  </svg>
);

const HeightActivity = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#22E0E0"
      d="M8.831 5.078c-1.615 0-2.929 1.314-2.929 2.929v3.907c0 .324.263.586.586.586h.036l.334 5.352h3.944l.334-5.352h.038c.324 0 .586-.262.586-.586V8.007c0-1.615-1.314-2.929-2.929-2.929z"
    />
    <path
      fill="#22C7E0"
      d="M8.832 5.078v12.774h1.97l.334-5.352h.038c.323 0 .586-.262.586-.586V8.007c0-1.623-1.323-2.929-2.928-2.929z"
    />
    <path
      fill="#F4425C"
      d="M14.102 9.375v1.172h.783c.323 0 .586.262.586.586 0 .323-.263.586-.586.586h-.783v1.172h1.564c.323 0 .586.262.586.586 0 .323-.262.585-.586.585h-1.564v1.172h.783c.323 0 .586.263.586.586 0 .324-.263.586-.586.586h-.783v1.446H12.93V.586c0-.323.262-.586.586-.586.323 0 .586.263.586.586v.586h.783c.323 0 .586.262.586.586 0 .323-.263.586-.586.586h-.783v1.172h1.564c.323 0 .586.262.586.586 0 .323-.262.585-.586.585h-1.564V5.86h.783c.323 0 .586.263.586.586 0 .324-.263.586-.586.586h-.783v1.172h1.564c.323 0 .586.263.586.586 0 .324-.262.586-.586.586h-1.564z"
    />
    <path
      fill="#E6FAFF"
      d="M15.86 17.656H4.14c-.323 0-.585.263-.585.586v1.172c0 .324.262.586.586.586h11.718c.324 0 .586-.262.586-.586v-1.172c0-.323-.262-.586-.586-.586z"
    />
    <path
      fill="#CCF8FF"
      d="M16.445 18.242v1.172c0 .323-.262.586-.586.586H8.832v-2.344h7.027c.324 0 .586.263.586.586z"
    />
    <path
      fill="#FBCDBE"
      d="M8.832 5.469c.97 0 1.758-.787 1.758-1.758 0-.97-.787-1.758-1.758-1.758-.97 0-1.758.787-1.758 1.758 0 .97.787 1.758 1.758 1.758z"
    />
    <path
      fill="#FDBBAB"
      d="M10.59 3.71c0 .975-.793 1.759-1.758 1.759V1.953c.97 0 1.758.789 1.758 1.758z"
    />
  </svg>
);

const DistanceActivity = ({ size }) => (
  <svg
    width={size}
    height={size + 1}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="#575F64"
        d="M17.617 15.227c0-.324-.262-.586-.586-.586H2.97c-.324 0-.586.262-.586.586v1.757l1.172.586-1.172.586v1.758c0 .324.262.586.586.586H17.03c.324 0 .586-.262.586-.586v-1.758l-1.172-.586 1.172-.586v-1.757z"
      />
      <path
        fill="#474F54"
        d="M17.617 19.914v-1.758l-1.172-.586 1.172-.586v-1.757c0-.324-.262-.586-.586-.586H10V20.5h7.031c.324 0 .586-.262.586-.586z"
      />
      <path
        fill="#C7CFE1"
        d="M14.688 16.984c-.324 0-.586.263-.586.586 0 .324.262.586.585.586h2.93v-1.172h-2.93z"
      />
      <path
        fill="#DFE7F4"
        d="M12.344 16.984H7.656c-.324 0-.586.262-.586.586 0 .324.262.586.586.586h4.688c.324 0 .586-.262.586-.586 0-.324-.262-.586-.586-.586z"
      />
      <path
        fill="#C7CFE1"
        d="M12.93 17.57c0-.324-.262-.586-.586-.586H10v1.172h2.344c.324 0 .586-.262.586-.586z"
      />
      <path
        fill="#FE8205"
        d="M10 .5C6.446.5 3.555 3.391 3.555 6.945c0 2.794 1.44 4.16 3.262 5.887.353.334.726.766 1.112 1.152.957.958 1.485 2.231 1.485 3.586 0 .324.262.586.586.586.324 0 .586-.262.586-.586 0-1.355.527-2.628 1.485-3.586.39-.39.767-.824 1.125-1.162 1.815-1.715 3.25-3.07 3.25-5.877C16.445 3.391 13.553.5 10 .5zm0 10.547c-2.261 0-4.102-1.84-4.102-4.102 0-2.261 1.84-4.101 4.102-4.101 2.261 0 4.102 1.84 4.102 4.101 0 2.262-1.84 4.102-4.102 4.102z"
      />
      <path
        fill="#FA5D0F"
        d="M10.586 17.57c0-1.355.527-2.628 1.485-3.586.39-.39.767-.824 1.125-1.162 1.815-1.715 3.25-3.07 3.25-5.877C16.445 3.391 13.553.5 10 .5v2.344c2.261 0 4.102 1.84 4.102 4.101 0 2.262-1.84 4.102-4.102 4.102v7.11c.324 0 .586-.263.586-.587z"
      />
      <path
        fill="#DFE7F4"
        d="M5.898 17.57c0-.323-.262-.586-.585-.586h-2.93v1.172h2.93c.323 0 .585-.262.585-.586z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H20V20H0z" transform="translate(0 .5)" />
      </clipPath>
    </defs>
  </svg>
);

const BloodPreasureActivity = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#D8553A"
      d="M9.168 20c-4.135 0-7.5-3.39-7.5-7.555 0-5.09 6.768-11.97 7.057-12.26.234-.237.652-.237.886 0 .289.29 7.057 7.17 7.057 12.26 0 4.165-3.365 7.554-7.5 7.554z"
    />
    <path
      fill="#FF6C6C"
      d="M9.168.007c-.163 0-.327.059-.443.177-.289.29-7.057 7.171-7.057 12.261 0 4.165 3.365 7.554 7.5 7.554.282 0 .56-.015.833-.046v-6.369l-1.279-1.918h-1.22c-.46 0-.834-.373-.834-.833 0-.46.373-.834.833-.834 1.705 0 1.945-.251 2.5.581V.59C9.786.36 9.648.22 9.611.183c-.116-.118-.28-.177-.443-.177z"
    />
    <path
      fill="#D9E9FF"
      d="M10.835 14.167c-.278 0-.539-.138-.694-.37l-1.419-2.13h-1.22c-.46 0-.834-.373-.834-.833 0-.46.373-.834.833-.834h1.667c.278 0 .538.14.693.371l.87 1.305L12.59 7.96c.136-.271.409-.448.713-.46.32-.008.59.144.747.404l2.256 3.763h1.195c.46 0 .834.373.834.833 0 .46-.374.834-.834.834h-1.666c-.293 0-.565-.154-.715-.404l-1.719-2.865-1.82 3.642c-.134.266-.398.44-.694.46h-.052z"
    />
    <path
      fill="#D9E9FF"
      d="M13.328 7.501h-.026c-.304.012-.576.188-.713.46l-1.859 3.716-.87-1.305c-.154-.233-.414-.372-.692-.372H7.501c-.46 0-.833.373-.833.834 0 .46.373.833.833.833h1.221l1.42 2.129c.154.232.415.37.693.37h.051c.297-.02.561-.194.694-.46l1.82-3.643v-2.56l-.072-.002z"
    />
  </svg>
);

const StepsActivity = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#ED694A"
      d="M15.428 6.456c-3.044-.892-5.528 1.828-4.248 4.546 0 0 1.183 2.16-.164 4.908-.739 1.626.058 3.537 1.653 4.005 1.43.419 3.12-.761 3.457-2.37l2.117-6.663c.463-1.853-.797-3.835-2.815-4.426z"
    />
    <path
      fill="#D8553A"
      d="M13.184 15.91c1.347-2.747.164-4.907.164-4.907-.942-2.002.156-4.004 2.016-4.565-3.014-.834-5.453 1.866-4.182 4.565 0 0 1.182 2.16-.164 4.908-1.054 2.32 1.049 4.935 3.37 3.824-1.288-.664-1.869-2.36-1.204-3.824z"
    />
    <path
      fill="#ED694A"
      d="M12.323 5.385c.767 0 1.389-.622 1.389-1.39 0-.767-.622-1.389-1.39-1.389-.766 0-1.388.622-1.388 1.39 0 .767.622 1.389 1.389 1.389z"
    />
    <path
      fill="#D8553A"
      d="M12.324 2.606c-1.361.001-1.912 1.768-.784 2.537.659.45 1.575.258 1.986-.453-1.058 0-1.74-1.153-1.202-2.084z"
    />
    <path
      fill="#ED694A"
      d="M15.455 5.38c.543 0 .983-.44.983-.983 0-.543-.44-.983-.983-.983-.542 0-.982.44-.982.983 0 .543.44.983.982.983z"
    />
    <path
      fill="#D8553A"
      d="M15.402 3.416c-.939.05-1.273 1.267-.501 1.793.451.308 1.073.19 1.376-.276-.791.043-1.317-.838-.875-1.517z"
    />
    <path
      fill="#ED694A"
      d="M17.72 6.329c.436 0 .79-.354.79-.79 0-.436-.354-.79-.79-.79-.437 0-.79.354-.79.79 0 .436.353.79.79.79z"
    />
    <path
      fill="#D8553A"
      d="M18.114 4.856c-.671-.388-1.443.327-1.107 1.025.196.407.7.575 1.106.341-.522-.301-.527-1.062.002-1.366z"
    />
    <path
      fill="#ED694A"
      d="M19.08 7.824c.35 0 .635-.284.635-.634 0-.351-.284-.635-.635-.635-.35 0-.635.284-.635.635 0 .35.285.634.635.634z"
    />
    <path
      fill="#D8553A"
      d="M19.397 6.64c-.54-.311-1.16.264-.89.825.152.316.531.45.847.297.015-.007.028-.015.042-.023-.42-.242-.424-.854 0-1.098zM19.581 9.338c.23 0 .418-.187.418-.418 0-.23-.187-.417-.418-.417-.23 0-.417.187-.417.417 0 .23.187.418.417.418z"
    />
    <path
      fill="#ED694A"
      d="M4.571 3.85C7.615 2.957 10.1 5.677 8.82 8.396c0 0-1.183 2.16.164 4.908.739 1.626-.058 3.536-1.653 4.004-1.43.42-3.12-.76-3.457-2.369 0-.004-2.116-6.66-2.117-6.663-.463-1.853.797-3.835 2.815-4.426z"
    />
    <path
      fill="#D8553A"
      d="M5.886 14.376L3.768 7.712c-.388-1.552.434-3.194 1.897-4.034-.352.007-.719.062-1.094.172-2.018.591-3.278 2.573-2.815 4.426l2.117 6.663c.338 1.608 2.026 2.788 3.457 2.37.362-.107.683-.289.955-.524-1.11-.215-2.143-1.189-2.4-2.41z"
    />
    <path
      fill="#ED694A"
      d="M7.674 2.778c.768 0 1.39-.622 1.39-1.389S8.442 0 7.674 0c-.767 0-1.389.622-1.389 1.39 0 .766.622 1.388 1.39 1.388z"
    />
    <path
      fill="#D8553A"
      d="M7.493.8C7.47.51 7.539.234 7.674 0c-.034 0-.069 0-.104.003-.765.058-1.338.725-1.28 1.49.102 1.361 1.907 1.769 2.588.59-.72 0-1.33-.555-1.385-1.285z"
    />
    <path
      fill="#ED694A"
      d="M4.545 2.774c.543 0 .983-.44.983-.983 0-.542-.44-.982-.983-.982-.542 0-.982.44-.982.982 0 .543.44.983.982.983z"
    />
    <path
      fill="#D8553A"
      d="M4.545.809c-.024 0-.049 0-.074.002-.54.04-.946.512-.906 1.054.073.962 1.349 1.251 1.831.417-.76 0-1.225-.826-.85-1.473z"
    />
    <path
      fill="#ED694A"
      d="M2.278 3.722c.436 0 .79-.353.79-.79 0-.436-.354-.79-.79-.79-.436 0-.79.354-.79.79 0 .437.354.79.79.79z"
    />
    <path
      fill="#D8553A"
      d="M2.676 2.25c-.406-.235-.91-.067-1.106.34-.337.699.436 1.414 1.107 1.026-.355-.204-.523-1.065-.001-1.367z"
    />
    <path
      fill="#ED694A"
      d="M.92 5.219c.35 0 .635-.284.635-.635 0-.35-.284-.635-.635-.635-.35 0-.635.284-.635.635 0 .35.284.635.635.635z"
    />
    <path
      fill="#D8553A"
      d="M1.236 4.035c-.326-.188-.731-.054-.89.274-.27.562.351 1.136.89.825-.424-.245-.42-.857 0-1.1zM.417 6.731c.23 0 .418-.187.418-.417 0-.23-.187-.418-.418-.418-.23 0-.417.187-.417.418 0 .23.187.417.417.417z"
    />
  </svg>
);

const CaloriesActivity = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M2.1191 10.2539C2.1191 10.2539 2.90926 11.4147 4.56535 12.3973C4.56535 12.3973 3.36594 2.26051 11.1809 0.5C9.17867 7.84848 13.7278 9.92086 15.386 6.03852C18.1553 9.73582 16.0527 12.9615 16.0527 12.9615C17.1882 13.1251 18.142 11.8846 18.142 11.8846C18.1505 12.0374 18.1553 12.1913 18.1553 12.3462C18.1553 16.8494 14.5047 20.5 10.0015 20.5C5.49824 20.5 1.84766 16.8494 1.84766 12.3462C1.84766 11.6228 1.94234 10.9216 2.1191 10.2539Z"
        fill="#FF6536"
      />
      <path
        d="M18.1405 11.8846C18.1405 11.8846 17.1867 13.1251 16.0512 12.9615C16.0512 12.9615 18.1538 9.73582 15.3846 6.03852C13.7263 9.92086 9.17723 7.84848 11.1795 0.5C10.7609 0.594297 10.3684 0.712773 10 0.852266V20.5C14.5032 20.5 18.1538 16.8494 18.1538 12.3462C18.1538 12.1912 18.1491 12.0374 18.1405 11.8846Z"
        fill="#FF421D"
      />
      <path
        d="M6.42578 16.9242C6.42578 18.8992 8.0268 20.5002 10.0017 20.5002C11.9766 20.5002 13.5777 18.8992 13.5777 16.9242C13.5777 15.8672 13.119 14.9172 12.3898 14.2626C11.0073 16.1409 9.03703 13.2916 10.5725 11.2109C10.5725 11.2109 6.42578 11.7306 6.42578 16.9242Z"
        fill="#FBBF00"
      />
      <path
        d="M13.5759 16.9242C13.5759 15.8672 13.1173 14.9172 12.3881 14.2626C11.0055 16.1409 9.03531 13.2916 10.5708 11.2109C10.5708 11.2109 10.3455 11.2393 10 11.3483V20.5002C11.9749 20.5002 13.5759 18.8992 13.5759 16.9242Z"
        fill="#FFA900"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
const WeightActivity = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fill="#A0CAFE"
        d="M3.957.5h12.086C18.228.5 20 2.272 20 4.457v12.086c0 2.185-1.772 3.957-3.957 3.957H3.957C1.772 20.5 0 18.728 0 16.543V4.457C0 2.272 1.772.5 3.957.5z"
      />
      <path
        fill="#E6F1FF"
        d="M15.685 8.943c-1.23-.317-2.513.366-2.938 1.563-.166.465-.183.945-.079 1.39.372 1.584.316 3.238-.173 4.79-.275.874.21 1.806 1.084 2.081.874.275 1.805-.21 2.08-1.084l1.767-5.608c.423-1.342-.36-2.776-1.741-3.132zM4.317 8.943c1.23-.317 2.513.366 2.938 1.563.165.465.183.945.079 1.39-.372 1.584-.316 3.238.173 4.79.275.874-.21 1.806-1.084 2.081-.874.275-1.806-.21-2.081-1.084l-1.766-5.608c-.423-1.342.36-2.776 1.74-3.132zM15.496 4.148l-1.58 2.872c-.178.324-.575.463-.914.314-.86-.38-1.775-.589-2.695-.626-.204-.008-.407-.008-.61 0-.92.037-1.835.246-2.696.625-.34.15-.736.011-.915-.313L4.507 4.148c-.199-.362-.054-.82.32-.996 3.276-1.543 7.073-1.543 10.35 0 .373.177.518.634.319.996z"
      />
      <path
        fill="#E7387F"
        d="M10.306 3.624v3.085c-.204-.009-.407-.009-.61 0V3.624c0-.169.136-.306.305-.306.168 0 .305.137.305.306z"
      />
      <path
        fill="#D9E9FF"
        d="M7.799 7.035c-.27.084-.536.183-.798.299-.34.149-.736.01-.915-.314L4.507 4.148c-.199-.362-.054-.82.32-.996.26-.122.524-.236.791-.339 0 .164.04.33.125.483L7.8 7.036zM14.79 18.683c-.22.105-.463.161-.712.161-.165 0-.333-.025-.5-.077-.707-.223-1.16-.877-1.16-1.582 0-.165.025-.333.077-.499.489-1.552.545-3.206.173-4.79-.104-.445-.087-.925.079-1.39.425-1.197 1.708-1.88 2.938-1.563l.101.028c-.729.221-1.345.775-1.615 1.535-.166.465-.183.945-.079 1.39.372 1.584.316 3.238-.173 4.79-.052.166-.077.334-.077.499 0 .631.363 1.222.947 1.498zM6.636 18.683c-.068.033-.14.06-.213.084-.166.053-.334.077-.5.077-.704 0-1.358-.453-1.581-1.16l-1.766-5.609c-.423-1.342.36-2.776 1.74-3.132.45-.116.905-.099 1.323.028-1.317.4-2.051 1.795-1.64 3.104l1.767 5.608c.144.458.469.809.87 1z"
      />
      <path
        fill="#81B8FD"
        d="M19.446 18.564c-.69 1.16-1.956 1.936-3.403 1.936H3.957C1.772 20.5 0 18.73 0 16.543V4.458C0 2.993.796 1.714 1.978 1.03c-.352.592-.554 1.282-.554 2.02v12.086c0 2.186 1.772 3.957 3.957 3.957h12.086c.721 0 1.397-.192 1.979-.53z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H20V20H0z" transform="translate(0 .5)" />
      </clipPath>
    </defs>
  </svg>
);

export {
  HearRateActivity,
  SleepActivity,
  HeightActivity,
  DistanceActivity,
  BloodPreasureActivity,
  StepsActivity,
  CaloriesActivity,
  WeightActivity,
};
