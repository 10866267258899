import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { io } from 'socket.io-client';

import { ShowFlashMessage } from '@global/Services';

import { navigation } from '@utils';
import { Caretask, CaretaskComponent } from '@type/Caretask';
import {
  CaretaskAnsweringNavigator,
  ComponentAnswer,
} from '../../CaretaskAnswering.type';

import {
  submitCaretaskAnswer,
  calculateScoreOfAnswers,
} from './CaretaskAnsweringFooter.api';
import {
  selectCaretask,
  selectCaretaskAnswers,
  selectIsLastComponent,
  selectNavigator,
  selectHasError,
  resetAnswering,
  selectCurrentAnswer,
  selectCurrentComponent,
  setAdditionalComponents,
  createAnswersObject,
  selectIsAnsweringMain,
  selectMainComponentsCount,
  nextComponent,
  nextToAdditionalComponents,
} from '../../CaretaskAnswering.reducer';
import config from '../../../../../../../config';

import componentTypeOrder from '../../../../../Careplans/careplanComponentTypeOrder';
import useAllTodoAssessments from 'src/Views/Notifications/Notification.hooks';
import { PatientOverviewContext } from 'src/Views/PatientOverview/PatientOverview';

import './CaretaskAnsweringFooter.style.scss';
import storeInfo from '../../../../../../../store';

export default function useCaretaskAnsweringFooterService(
  setCaretaskId: React.Dispatch<React.SetStateAction<number>>,
  setAnsweredCaretask: React.Dispatch<React.SetStateAction<boolean>>,
  occurrenceId: number
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedInUser = storeInfo.store.getState().session.user;
  const history = useHistory();

  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const caretaskData: Caretask = useSelector(selectCaretask);
  const navigator: CaretaskAnsweringNavigator = useSelector(selectNavigator);
  const caretaskAnswers: ComponentAnswer[] = useSelector(selectCaretaskAnswers);
  const isLastComponent: boolean = useSelector(selectIsLastComponent);
  const isAnsweringMain: boolean = useSelector(selectIsAnsweringMain);
  const hasError: boolean = useSelector(selectHasError);
  const answerObject: Object = useSelector(selectCurrentAnswer);
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const mainComponentsCount: number = useSelector(selectMainComponentsCount);

  const {
    refreshCaretaskItemById,
    setRefreshCaretaskItemById,
    assetId,
    isReviewReport,
  } = useContext(PatientOverviewContext);

  const redirectToReviewReportList = () => {
    if (isReviewReport) {
      history.push({ pathname: `/${navigation.REVIEW_CARETASKS}` });
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const cancelHandler = () => {
    setOpenModal(true);
  };

  const confirmHandler = () => {
    setOpenModal(false);
    setCaretaskId(null);
    dispatch(resetAnswering());
    redirectToReviewReportList();
  };
  const { getAllTodos, allTodoAssessmentsController } = useAllTodoAssessments();

  const data = {
    answerData: {
      occurrenceId,
      careplanId: caretaskData.id,
      careplanName: caretaskData.name,
      patientId: caretaskData.patientId,
      isEvaluating: caretaskData.isEvaluating,
      isAssesment: caretaskData.isAssessment,
      timeZoneOffset: moment().utcOffset(),
      timestamp: new Date(),
      answerCollection: caretaskAnswers,
      answeredBy: loggedInUser.uuid,
      userSessionId: loggedInUser.sessionId,
      reportReviewId: assetId,
    },
  };

  const handleApiError = error => {
    const message = {
      type: '',
      messageKey: '',
    };

    if (error.response?.data) {
      message.type = error.response.data.type;
      message.messageKey = error.response.data.content;
    } else {
      message.type = 'error';
      message.messageKey = 'common_labels.broker_service_error';
    }

    ShowFlashMessage(message);
  };

  const submitAnswer = () => {
    if (allTodoAssessmentsController) allTodoAssessmentsController.abort();

    setSubmitIsLoading(true);

    submitCaretaskAnswer(data, caretaskData.id).catch(handleApiError);
  };

  const calculateMainComponents = () => {
    setSubmitIsLoading(true);

    calculateScoreOfAnswers(data)
      .then(response => {
        if (response?.data) {
          const { additionalComponents } = response.data;

          if (additionalComponents.length > 0) {
            dispatch(setAdditionalComponents(additionalComponents));
            dispatch(createAnswersObject(additionalComponents));
            setSubmitIsLoading(false);
            dispatch(nextComponent());
          } else {
            submitAnswer();
          }
        }
      })
      .catch(handleApiError);
  };

  useEffect(() => {
    const socket = io(`${window.location.host}`, config.socketOptions);
    const handleCaretaskAnswered = data => {
      if (data) {
        setAnsweredCaretask(true);
        setRefreshCaretaskItemById(caretaskData.id);
        setCaretaskId(null);
        getAllTodos(() => {});
        if (typeof refreshCaretaskItemById === 'function') {
          refreshCaretaskItemById();
        }
        redirectToReviewReportList();
        setSubmitIsLoading(false);
      }
    };
    socket.on(
      `caretaskAnswered/${loggedInUser.id}/${loggedInUser.sessionId}`,
      handleCaretaskAnswered
    );

    return () => {
      socket.off(
        `caretaskAnswered/${loggedInUser.id}/${loggedInUser.sessionId}`,
        handleCaretaskAnswered
      );
    };
  }, [
    caretaskData.id,
    loggedInUser.id,
    loggedInUser.sessionId,
    refreshCaretaskItemById,
  ]);

  const disablePreviousButton = () => {
    const currentComponentType = currentComponent?.careplanComponentTypeId;
    return hasError && currentComponentType === componentTypeOrder.UPLOAD_FILE;
  };

  const disableNextFinishButton = () => {
    const isMandatoryComponent =
      currentComponent?.careplanCareplanComponentMapper.isMandatory;
    return hasError || (isMandatoryComponent && answerObject?.isSkipped);
  };

  const shouldDisplayPreviousButton: boolean =
    (isAnsweringMain && navigator.Current >= 0) ||
    (!isAnsweringMain && navigator.Current > 0);

  const requiresScoreCalculation: boolean =
    isAnsweringMain && caretaskData.isEvaluating;

  const isLastMainComponent: boolean =
    isAnsweringMain && navigator.Current === mainComponentsCount - 1;

  const getSubmitAPI = () => {
    return requiresScoreCalculation
      ? dispatch(nextToAdditionalComponents())
      : submitAnswer();
  };

  const submitButtonLabel: string = requiresScoreCalculation
    ? 'common_buttons.proceed'
    : 'common_buttons.finish';

  const nextButtonLabel: string = isLastMainComponent
    ? 'common_buttons.finish'
    : 'common_buttons.next';

  const handleNextButtonClick = () => {
    if (isLastMainComponent) {
      calculateMainComponents();
    } else {
      dispatch(nextComponent());
    }
  };

  return {
    t,
    submitIsLoading,
    dispatch,
    caretaskData,
    isLastComponent,
    hasError,
    openModal,
    closeModal,
    cancelHandler,
    confirmHandler,
    disablePreviousButton,
    disableNextFinishButton,
    shouldDisplayPreviousButton,
    getSubmitAPI,
    submitButtonLabel,
    nextButtonLabel,
    handleNextButtonClick,
  };
}
