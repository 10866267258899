/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Flex,
  Box,
  TextInput,
  FlashMessage,
  ComplexSearch,
} from '@ui-common-files/components';
import UsFilterTopWarning from '../../caro-ui-commonfiles/components/UsFilterWarnings/UsFilterTopWarning';
import { inputField, MaxCharLimits } from '@ui-common-files/utils';
import { FlashMessageTypes } from '../../caro-ui-commonfiles/types/components';

import serviceConfig from '../../serviceConfig.json';
import { useDelaySearch } from '@global/Hooks';
import { ApiClient } from '@global/Services';
import { deepEqual } from '@global/Objects';

import complexSearchData from '../Utils/complexSearchData';
import ModalSection from './common/Layout/Modal/ModalSection';
import PatientListViewForAssign from './Datatables/IndexPatientForAssign';
import { nonMedical } from '../../config';

const PatientsForAssign = ({
  isSelectAll,
  setIsSelectAll,
  nbrPatientsSelected,
  setNbrPatientsSelected,
  selectedPatientsData,
  setSelectedPatientsData,
  setDisableNext,
  isOncobox = false,
}) => {
  const services = useSelector(state => state.services);
  const { allFilters, savedFilter } = useSelector(
    state => state.patientFilters
  );

  const retrievedSelectedRule = savedFilter.rule;
  const defaultFilter = savedFilter.index;

  const checkPatientsReadPermission =
    allFilters !== null && retrievedSelectedRule !== null;

  const { filterData } = complexSearchData(services);
  /* **** States **** */

  const [hidePatientDemographics, setHidePatientDemographics] = useState(false);
  const [forceRender, setForceRender] = useState(true);
  const [selectedRule, setSelectedRule] = useState(retrievedSelectedRule);
  const [auxState, setAuxState] = useState(null);
  const [patients, setPatients] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [displayFlashMeg, setDisplayFlashMsg] = useState({
    display: false,
    message: '',
    type: FlashMessageTypes.Warning,
  });
  const [doFetch, setDoFetch] = useState(false);
  const [filterKey, setFilterKey] = useState({
    id: allFilters[defaultFilter].filterId,
    name: allFilters[defaultFilter].key,
  });
  const [showUsWarningTopPage, setShowUsWarningTopPage] = useState(
    allFilters[defaultFilter] && allFilters[defaultFilter].filterId != 1
  );

  /* **** Life Cicle Controls **** */
  const actionTypes = {
    ON_SEARCH: 'on_search',
    ON_TABLE_CHANGE: 'on_table_change',
    ON_FILTER_CHANGE: 'on_filter_change',
  };

  const initialState = {
    currPage: 0,
    start: 0,
    limit: 10,
    dir: 'asc',
    column: 'medicalRecord',
    search: '',
    sqlQuery: null,
  };

  const reducer = (prevState, action) => {
    switch (action.type) {
      case actionTypes.ON_TABLE_CHANGE: {
        const nextState = {
          ...prevState,
          start: action.payload.page * action.payload.pageSize,
          limit: action.payload.pageSize,
          dir: action.payload.sort.dir,
          column: action.payload.sort.column,
          currPage: action.payload.page,
        };

        return deepEqual(nextState, prevState) ? prevState : nextState;
      }
      case actionTypes.ON_FILTER_CHANGE: {
        const nextState = {
          ...prevState,
          start: 0,
          currPage: 0,
          sqlQuery: action.payload,
          filterKey,
        };

        return deepEqual(nextState, prevState) ? prevState : nextState;
      }
      case actionTypes.ON_SEARCH: {
        const nextState = {
          ...prevState,
          start: 0,
          currPage: 0,
          search: action.payload,
        };

        return deepEqual(nextState, prevState) ? prevState : nextState;
      }
      default:
        return prevState;
    }
  };

  const [actionState, dispatch] = useReducer(reducer, initialState);

  const abortController = new AbortController();

  const doPatientSearch = useCallback(() => {
    if (searchTerm.length > 1 || searchTerm.length === 0) {
      dispatch({
        type: actionTypes.ON_SEARCH,
        payload: searchTerm,
      });
    }
  }, [searchTerm]);

  useDelaySearch({ input: searchTerm, callback: doPatientSearch });

  /* -------------- Load  Patients filters and services ---------- */

  useEffect(() => {
    if (
      defaultFilter >= 0 &&
      allFilters.length > 0 &&
      allFilters[defaultFilter].rule
    ) {
      setSelectedRule(
        typeof allFilters[defaultFilter].rule == 'string'
          ? JSON.parse(allFilters[defaultFilter].rule)
          : allFilters[defaultFilter].rule
      );
      setFilterKey({
        id: allFilters[defaultFilter].filterId,
        name: allFilters[defaultFilter].key,
      });
    }
  }, [defaultFilter]);

  useEffect(() => {
    const handleChange = rule => {
      if (rule) setSelectedRule(rule);
      setForceRender(true);
    };
    handleChange(auxState);
  }, [forceRender]);

  function getPatientsRequest(start, limit, isBulkAssign) {
    return ApiClient.GET({
      url: serviceConfig.brokerService.getPatientsByQuery.uri,
      signal: abortController.signal,
      payload: {
        isBulkAssign: !!isBulkAssign,
        sql: actionState.sqlQuery.sql,
        params: JSON.stringify(actionState.sqlQuery.params),
        filterKey,
        isOncobox,
        getRule: JSON.stringify(selectedRule),
        query: JSON.stringify({
          start,
          limit,
          dir: actionState.dir,
          column: actionState.column,
          search: actionState.search,
        }),
      },
    });
  }

  useEffect(() => {
    const getPatientsByQuery = async () => {
      setLoading(true);
      if (checkPatientsReadPermission) {
        try {
          const { sqlQuery } = actionState;
          if (sqlQuery && sqlQuery.sql) {
            return await getPatientsRequest(
              actionState.start,
              actionState.limit
            ).then(filteredPatients => {
              if (filteredPatients && filteredPatients.data) {
                setPatients(filteredPatients.data);
                setHidePatientDemographics(
                  filteredPatients.data.isPatientDemographicData
                );
                setLoading(false);
                setDoFetch(false);
              }
            });
          }
        } catch (error) {
          setLoading(false);
          setPatients(null);
          setDoFetch(false);
          if (error.response && error.response.data) {
            setDisplayFlashMsg({
              display: true,
              content: I18n.t(error.response.data.content),
              type: error.response.data.type,
            });
          }
        }
      } else {
        setLoading(false);
        setPatients(null);
        setDoFetch(false);
        setDisplayFlashMsg({
          display: true,
          content: I18n.t('common_labels.msg_noPermission'),
          type: FlashMessageTypes.Warning,
        });
      }
    };
    getPatientsByQuery();
  }, [actionState, doFetch, hidePatientDemographics]);

  useEffect(() => {
    const getAllPatients = async () => {
      if (isSelectAll) {
        setLoading(true);
        setDisableNext(true);
        if (checkPatientsReadPermission) {
          try {
            const { sqlQuery } = actionState;
            if (sqlQuery && sqlQuery.sql) {
              return getPatientsRequest(0, patients.recordsTotal, true).then(
                filteredAllPatients => {
                  let selectedPatients;
                  let selectedPatientsCount;

                  if (!nonMedical) {
                    selectedPatients = filteredAllPatients.data.data.filter(
                      patient => patient.isInMedicalCountry
                    );
                    selectedPatientsCount = selectedPatients.length;
                  } else {
                    selectedPatients = filteredAllPatients.data.data;
                    selectedPatientsCount = patients.recordsTotal;
                  }
                  setLoading(false);
                  setDisableNext(false);
                  setSelectedPatientsData(selectedPatients);
                  setNbrPatientsSelected(selectedPatientsCount);
                }
              );
            }

            setDisableNext(false);
          } catch (error) {
            setLoading(false);
            setDisableNext(false);
            if (error.response && error.response.data) {
              setDisplayFlashMsg({
                display: true,
                content: I18n.t(error.response.data.content),
                type: error.response.data.type,
              });
            }
          }
        } else {
          setLoading(false);
          setDisableNext(false);
          setDisplayFlashMsg({
            display: true,
            content: I18n.t('common_labels.msg_noPermission'),
            type: FlashMessageTypes.Warning,
          });
        }
      }
    };
    getAllPatients();
  }, [isSelectAll]);

  useEffect(() => {
    return () => abortController.abort();
  }, []);

  /** **** Events Handlers ***** */

  const tableHandler = (page, pageSize, sort) => {
    dispatch({
      type: actionTypes.ON_TABLE_CHANGE,
      payload: {
        page,
        pageSize,
        sort,
      },
    });
  };

  function onSearchTermChange(e) {
    setSearchTerm(e.target.value);
  }

  /** **** */
  return (
    <>
      <ModalSection hasPadding>
        {displayFlashMeg.display && (
          <FlashMessage
            message={displayFlashMeg.content}
            type={displayFlashMeg.type}
            onClick={() => {
              setDisplayFlashMsg({
                display: false,
                message: '',
              });
            }}
          />
        )}
        <Box margin="0 0 10px 0">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              otherStyles={{ flex: 1, marginRight: '20px' }}
            >
              {allFilters.length && defaultFilter > -1 && !isOncobox ? (
                <UsFilterTopWarning
                  message={I18n.t(
                    'patients_view.us-market-warning__patient-index-top'
                  )}
                  items={allFilters}
                  showUsWarningTopPage={showUsWarningTopPage}
                  active={defaultFilter}
                  onClick={(e, item) => {
                    setFilterKey({
                      name: item.key,
                      id: item.filterId,
                    });
                    setAuxState(
                      typeof item.rule == 'string'
                        ? JSON.parse(item.rule)
                        : item.rule
                    );
                    setForceRender(false);
                    setShowUsWarningTopPage(item.filterId !== 1);
                  }}
                  showEditIcon={false}
                />
              ) : null}
              {forceRender && (
                <ComplexSearch
                  filters={filterData}
                  rules={selectedRule}
                  getSql={value => {
                    dispatch({
                      type: actionTypes.ON_FILTER_CHANGE,
                      payload: value,
                    });
                  }}
                  hide
                />
              )}
            </Flex>

            <Box width="250px">
              <TextInput
                value={searchTerm}
                placeholder={I18n.t('common_labels.label_search')}
                variant={inputField.variant.CHAR_COUNT}
                maxChars={MaxCharLimits.searchInputs.searchKeyword}
                handleOnChange={onSearchTermChange}
              />
            </Box>
          </Flex>
        </Box>

        <PatientListViewForAssign
          isOncobox={isOncobox}
          data={patients}
          loading={loading}
          onChange={tableHandler}
          page={actionState.currPage}
          isSelectAll={isSelectAll}
          setIsSelectAll={setIsSelectAll}
          nbrPatientsSelected={nbrPatientsSelected}
          setNbrPatientsSelected={setNbrPatientsSelected}
          selectedPatientsData={selectedPatientsData}
          setSelectedPatientsData={setSelectedPatientsData}
          hidePatientDemographics={hidePatientDemographics}
        />
      </ModalSection>
    </>
  );
};

export default withTranslation()(PatientsForAssign);
