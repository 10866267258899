/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import I18n from 'i18next';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import {filterHl7AttributeBasedOnAttributeName, renderPatientAttribute} from './PatientHelperUtility';


const PatientManufacturer = ({
  patientAdditionalInformation,
  isLoading
}) => {

  const renderPatientDetails = () => {
    return (
    <div className="overview__patient-activity-patient-details patient-details-bg-color">
      <Flex flexDirection="row" alignItems="flex-start">
       {patientAdditionalInformation.isManufacturerDetailsPresent &&  
      <div className="patient-name-title"> {I18n.t('hl7Attribute.sections.patientManufacturer')} </div>}
      </Flex>
      {renderPatientAttribute(I18n.t('hl7Attribute.manufacturerName'), filterHl7AttributeBasedOnAttributeName('hl7Attribute.manufacturerName',patientAdditionalInformation))}
      {renderPatientAttribute(I18n.t('hl7Attribute.manufacturerDescription'), filterHl7AttributeBasedOnAttributeName('hl7Attribute.manufacturerDescription', patientAdditionalInformation))}
      {renderPatientAttribute(I18n.t('hl7Attribute.manufacturerItemNumber'), filterHl7AttributeBasedOnAttributeName('hl7Attribute.manufacturerItemNumber', patientAdditionalInformation))}
      {renderPatientAttribute(I18n.t('hl7Attribute.eClassCategory'), filterHl7AttributeBasedOnAttributeName('hl7Attribute.eClassCategory', patientAdditionalInformation))}
    </div>
    );
  }

  return (
    <div>
      {isLoading && (
        <div className="pdf-loader-container">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="room-info-container">
            {renderPatientDetails()}
          </div>
        </>
      )}
    </div>
  );
};

PatientManufacturer.propTypes = {
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default PatientManufacturer;
