import React from 'react';
import { useSelector } from 'react-redux';

import { CaretaskComponent } from '@type/Caretask';
import { AnsweringComponentsProps } from '../AnsweringTypes';
import { InputOption } from '../AnsweringBloodPressureAndBMIBase/AnsweringBloodPressureAndBMIBase.type';

import AnsweringBloodPressureAndBMIBase from '../AnsweringBloodPressureAndBMIBase/AnsweringBloodPressureAndBMIBase';

import { bloodPressureAnswerValidator } from './AnsweringBloodPressure.validations';
import { calculateBloodPressureResult } from './AnsweringBloodPressure.utils';
import { selectCurrentComponent } from '../../CaretaskAnswering.reducer';

const inputOptionSystolic: InputOption = {
  label: 'bloodPressureOptions.systolic',
  unit: 'bloodPressureOptions.unit',
  name: 'systolic',
};

const inputOptionDiastolic: InputOption = {
  label: 'bloodPressureOptions.diastolic',
  unit: 'bloodPressureOptions.unit',
  name: 'diastolic',
};

export default function AnsweringBloodPressure({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );

  const getJumpTo = (systolic: number, diastolic: number) => {
    const result = calculateBloodPressureResult(systolic, diastolic);
    const jumpObject = currentComponent.logicJumpPaths[result.index];
    return jumpObject ? jumpObject.destination : null;
  };

  return (
    <AnsweringBloodPressureAndBMIBase
      handleOnChange={handleOnChange}
      inputOption1={inputOptionSystolic}
      inputOption2={inputOptionDiastolic}
      validator={bloodPressureAnswerValidator}
      isFloatingNumber={false}
      getJumpTo={getJumpTo}
    />
  );
}
