/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';

import Stepper from '../../../../caro-ui-commonfiles/components/Stepper/Stepper';
import Step from '../../../../caro-ui-commonfiles/components/Stepper/Step';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import FlexBox from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import ModalContent from '../../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../../common/Layout/Modal/ModalForm';
import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import { ButtonVariant, ButtonType } from '../../../../caro-ui-commonfiles/utils/button';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import { SubmissionTypes } from '../../../../caro-ui-commonfiles/utils/componentTypes';

import ListPatients from './ListPatientsAppointment';
import { CreateAppointmentContext } from './CreateAppointmentModal';
import AppointmentSettings from './AppointmentSetting';

const CreateAppointmentHeader = () => {
  const { currentStep } = useContext(CreateAppointmentContext);

  return (
    <Box width="70%">
      <Stepper currentStep={currentStep}>
        <Step text={('Step 1 text', I18n.t('steps.choosePatients'))} />
        <Step text={('Step 2 text', I18n.t('steps.appointment_settings'))} />
        <Step text={('Step 3 text', I18n.t('steps.assignAndSend'))} />
      </Stepper>
    </Box>
  );
};

const CreateAppointmentContent = ({ onClose }) => {
  const { currentStep } = useContext(CreateAppointmentContext);

  const firstStep = () => {
    return (
      <ModalForm
        formId="create-appointment-from-manager"
        handleFormSubmit={e => {
          e.preventDefault();
        }}
      >
        <ListPatients onSelect={() => {}} />
      </ModalForm>
    );
  };
  return (
    <ModalContent>
      {currentStep === 1 ? (
        firstStep()
      ) : (
        <AppointmentSettings onClose={onClose} />
      )}
    </ModalContent>
  );
};
CreateAppointmentContent.propTypes = {
  data: PropTypes.shape.isRequired,
  onClose: PropTypes.func.isRequired,
};

const CreateAppointmentFooter = () => {
  const {
    currentStep,
    setCurrentStep,
    existingPatient,
    setShouldValidateForm,
    dispatchFormSubmittor,
  } = useContext(CreateAppointmentContext);

  const [displayError, setDisplayError] = useState({
    show: false,
    message: '',
  });

  return (
    <FlexBox
      justifyContent={
        displayError.show || currentStep === 2 ? 'space-between' : 'flex-end'
      }
      otherStyles={{ width: '100%' }}
      alignItems="center"
    >
      {currentStep === 1 && (
        <>
          {displayError.show && (
            <FlexBox alignItems="center" otherStyles={{ width: 'max-content' }}>
              <Icon name="error" size={24} fill={ActionColors.ERROR} />
              <span className="appointment-manager__patient-error">
                {displayError.message}
              </span>
            </FlexBox>
          )}
          <Button
            label={I18n.t('common_buttons.next')}
            onClick={() => {
              if (!existingPatient.id) {
                setDisplayError({
                  show: true,
                  message: I18n.t('warningMessage.select_patient'),
                });
              } else {
                setCurrentStep(currentStep + 1);
              }
            }}
          />
        </>
      )}

      {currentStep === 2 && (
        <>
          <Button
            label={I18n.t('common_buttons.back')}
            onClick={() => {
              setCurrentStep(currentStep - 1);
              setDisplayError({
                show: false,
                message: '',
              });
            }}
            variant={ButtonVariant.OUTLINED}
          />
          <Button
            label={I18n.t('common_buttons.next')}
            onClick={() => {
              setShouldValidateForm(true);
            }}
          />
        </>
      )}
      {currentStep === 3 && (
        <>
          <Button
            label={I18n.t('common_buttons.back')}
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
            variant={ButtonVariant.OUTLINED}
          />
          <FlexBox justifyContent="flex-end" otherStyles={{ flex: 1 }}>
            <div style={{ marginRight: '10px' }}>
              <Button
                label={I18n.t('assignTemplate_view.addToAssignments')}
                type={ButtonType.SUBMIT}
                onClick={() => {
                  dispatchFormSubmittor({
                    submit: true,
                    submissionType: SubmissionTypes.ASSIGN_ONLY,
                  });
                }}
                variant={ButtonVariant.OUTLINED}
              />
            </div>
            <Button
              label={I18n.t('assignTemplate_view.send_immediately')}
              type={ButtonType.SUBMIT}
              onClick={() => {
                dispatchFormSubmittor({
                  submit: true,
                  submissionType: SubmissionTypes.ASSIGN_AND_SEND,
                });
              }}
            />
          </FlexBox>
        </>
      )}
    </FlexBox>
  );
};

export {
  CreateAppointmentHeader,
  CreateAppointmentContent,
  CreateAppointmentFooter,
};
