import React, { useRef, useState, useEffect } from "react";
import "../../styles/main.scss";
import I18n from 'i18next';
import axios, { AxiosResponse }from 'axios';

import { fhirLink, Resource, SelectProps } from './SingleSelectPatientDropMenu.types';
import serviceConfig  from '../../../serviceConfig.json';
import FhirLoader from "./FhirLoader";


const SingleSelectPatientDropMenu = ({ items, selectOnClick, paginationController, setPaginationController, setItemsHighlighted }: SelectProps) => {
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loadingDown, setLoadingDown] = useState(false);
  const [displayedData, setDisplayedData] = useState(items);
  const patientListul = useRef(null);
  const childListul = useRef(null);

  const handleScrollEvent = () => {
    if (
      childListul.current.getBoundingClientRect().bottom - 5 <=
      patientListul.current.getBoundingClientRect().bottom
    ) {
      scrollDown()
    }
  };

  useEffect(() => {
    patientListul.current.addEventListener("scroll", handleScrollEvent);
    return () =>
    patientListul.current.removeEventListener("scroll", handleScrollEvent);
  }, [paginationController, hasMoreData]);
  
  
  const scrollDown = async () : AxiosResponse => {
    try {
      const hostName = serviceConfig.brokerService.fhirPagination.uri
      const nextPageUrl = paginationController.filter(item => item.relation === "next")[0]?.url || ""
      const urlArray = nextPageUrl.split("/fhir")
      const urlString = urlArray.slice(2).join("")
      const nextPage = hostName + urlString
      let responseData;
      if(nextPageUrl && hasMoreData){
        setLoadingDown(true);
        setTimeout(() => setLoadingDown(false), 1000);
        const response = await axios.get(nextPage);
        responseData = response.data;
        if (responseData && responseData.entry && responseData.entry.length && responseData.link) {
          const newHighlighted = responseData.entry.map((item: Resource) => {
            return {
              ...item,
              name: item.resource.name && item.resource.name.length ? item.resource.name[0].given.join(" ") : "",
              id: item.resource.id,
              medicalRecord: item.resource.identifier ? item.resource.identifier[0].value : ""
            };
          });

          const newItem = displayedData.concat(newHighlighted);
          setDisplayedData(newItem);
          setItemsHighlighted(newItem);
          setLoadingDown(false);

          const next = responseData.link.filter((item: fhirLink) => item.relation === "next")[0]?.url || ""
          if(next){
            setHasMoreData(true)
            setPaginationController(responseData.link);
            return;
          }

          setHasMoreData(false)
        }
      }
    } catch (error) {
      setLoadingDown(false);
      setHasMoreData(false);
    }
  };
  const renderItemsHighlighted = () => {
    if (displayedData.length === 0) {
      return (
        [<li key="no-patients-found" className="fhir-select__items__li">
          <div className="fhir-select__item fhir-select__item--no-options">
            <span>{I18n.t('task.FhirPatientNotFound')}</span>
          </div>
        </li>]
      );
    }
    return displayedData.map((item : {id: string, medicalRecord: string, name: string}) => {
      return (
        <li
          id={item.id}
          key={item.id}
          onClick={e => selectOnClick(item.id)}
          tabIndex="1"
          className=""
        >
          <div className="fhir-select__item">
            <span className="fhir-select__item__content" >
              {item.medicalRecord} - {item.name}
            </span>
          </div>
        </li>
      );
    });
  };

  return (
    <ul ref={patientListul} className="fhir-select__items" tabIndex="0">
      <div ref={childListul}>
         {renderItemsHighlighted()}
         {loadingDown && <FhirLoader isBottomLoader={true}/>}
      </div>
    </ul>
  );
};


export default SingleSelectPatientDropMenu;
