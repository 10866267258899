import React from 'react';
import { useTranslation } from 'react-i18next';

import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import IconButton from '../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import TextInput from '../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import SectionScoreTag from './SectionScoreTag';

import { SectionScoreElements } from './ComplexScore.type';

import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';
import inputField from '../../../../caro-ui-commonfiles/utils/inputField';
import maxCharLimit from '../../../../caro-ui-commonfiles/utils/maxcharlimits.json';
import { errorType, equationValidation } from './ComplexScoringValidations';

function SectionScore({
  index,
  sectionScore,
  setScoringFormulas,
  withinIntervals,
  componentCollection,
}): JSX.Element {
  const { t } = useTranslation();

  const deleteFormula = id => {
    setScoringFormulas(prevFormulas =>
      prevFormulas.filter(formula => formula.id !== id)
    );
  };

  const onChangeSectionScoreInput = (
    key: SectionScoreElements,
    value: string
  ) => {
    setScoringFormulas(prevFormulas =>
      prevFormulas.map(formula =>
        formula.id === sectionScore.id
          ? {
              ...formula,
              [key]: value,
            }
          : formula
      )
    );
  };

  const onBlurSectionScoreInput = (
    key: SectionScoreElements,
    value: string
  ) => {
    let validationResult = { hasError: false, errorMessage: '' };
    if (key === SectionScoreElements.Description) {
      if (value.trim() === '') {
        validationResult = {
          hasError: true,
          errorMessage: errorType.emptyDescription,
        };
      }
    } else if (key === SectionScoreElements.Equation) {
      validationResult = equationValidation(
        value,
        componentCollection
      );
    }

    setScoringFormulas(prevFormulas =>
      prevFormulas.map(formula =>
        formula.id === sectionScore.id
          ? {
              ...formula,
              [key]: value,
              [`${key}HasError`]: validationResult.hasError,
              [`${key}ErrorMessage`]: validationResult.errorMessage,
            }
          : formula
      )
    );
  };

  return (
    <Flex>
      <SectionScoreTag index={index} />
      <Box flex={1} otherStyles={{ margin: '0 0 8px 0' }}>
        <Flex otherStyles={{ flex: '1' }}>
          <Box width="73%">
            <TextInput
              value={sectionScore.description}
              name="subScoreDescription"
              placeholder={t(
                'newCareplan_view.complex_score.section_score.description'
              )}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.carePlan.subScoreDescMaxCharLimit}
              maxChars={maxCharLimit.carePlan.subScoreDescMaxCharLimit}
              handleOnChange={e =>
                onChangeSectionScoreInput(
                  SectionScoreElements.Description,
                  e.target.value
                )
              }
              onBlur={e =>
                onBlurSectionScoreInput(
                  SectionScoreElements.Description,
                  e.target.value
                )
              }
              hasError={sectionScore.descriptionHasError}
              validationMessage={t(sectionScore.descriptionErrorMessage)}
            />
          </Box>
          <div className="thresholdIconDeleteScore">
            <IconButton
              onClick={() => {
                deleteFormula(sectionScore.id);
              }}
              name="delete"
              size={30}
              fill={ActionColors.DELETE}
              tooltipText={t('toolTipsText.delete')}
            />
          </div>
        </Flex>
        <span className="spaceBetweenFieldContainers" />
        <Flex otherStyles={{ flex: '1' }}>
          <Box width="73%">
            <TextInput
              value={sectionScore.equation}
              name="subScoreEquation"
              placeholder={t(
                'newCareplan_view.complex_score.section_score.placeholder'
              )}
              handleOnChange={e =>
                onChangeSectionScoreInput(
                  SectionScoreElements.Equation,
                  e.target.value.trim().toUpperCase()
                )
              }
              onBlur={e =>
                onBlurSectionScoreInput(
                  SectionScoreElements.Equation,
                  e.target.value.trim().toUpperCase()
                )
              }
              hasError={sectionScore.equationHasError}
              validationMessage={t(sectionScore.equationErrorMessage)}
            />
          </Box>
        </Flex>
        <span className="spaceBetweenFieldContainers" />
      </Box>
    </Flex>
  );
}

export default SectionScore;
