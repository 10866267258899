import React from 'react';

import { Box } from '@ui-common-files/components';
import {
  MedicationPlanReportPageHeader,
  MedicationPlanReportPage,
  MedicationPlanReportPageFooter,
} from './Components';
import { MedicationPage } from './MedicationPlanReport';
import useMedicationPlanReportTemplateService from './MedicationPlanReportTemplate.service';

import type { MedicationPlanReportTemplateProps } from './MedicationPlanReportTemplate.type';
import './MedicationPlanReportTemplate.style.scss';

export default function MedicationPlanReportTemplate({
  reportId,
  authenticationToken,
}: MedicationPlanReportTemplateProps): React.ReactElement {
  const { reportData, isReportRendered, errorMessage } =
    useMedicationPlanReportTemplateService(reportId, authenticationToken);

  if (!reportData?.pages?.length) {
    return <Box>{errorMessage}</Box>;
  }

  return (
    <Box className={`${isReportRendered && 'report-renders-done'}`}>
      {reportData.pages.map((page, index: number) => (
        <Box
          key={`report-page-index-${index}`}
          className="main-medication-plan-page"
        >
          <MedicationPlanReportPage
            headerComponent={
              <MedicationPlanReportPageHeader
                metaData={reportData.metaData}
                qrCode={page.qrCode}
                currentPageNumber={page.currentPage}
              />
            }
            footerComponent={<MedicationPlanReportPageFooter />}
          >
            <MedicationPage
              key={`component-${index}`}
              pageData={page.medicationGroupings}
            />
          </MedicationPlanReportPage>
        </Box>
      ))}
    </Box>
  );
}
