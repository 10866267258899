import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  Icon,
  IconButton,
  Flex,
  Button,
  IconButtonDropdown,
  Tooltip,
  Tag,
  Box,
  ICDViewer,
} from '@ui-common-files/components';

import {
  appointmentStatusIdToColor,
  appointmentStatusIdToTranslationKeys,
} from '@ui-common-files/global/constants';
import { formatDate } from '@global/Date';

import Position from '@ui-common-files/utils/positions';
import { ButtonType, ButtonVariant } from '@ui-common-files/utils/button';
import {
  Greys,
  ActionColors,
  ComponentColors,
} from '@ui-common-files/utils/colors';
import { ComponentTypes } from '@ui-common-files/utils/componentTypes';
import { userStatus, CAREPATHWAY_STATUS, roles } from '@utils';
import { getCarePlanIconColor, getHealthIndicatorColor } from '@utils/Careplan';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import { MedicationCardTitleTypes } from './MedicationPlan/MedicationPlan.types';
import '../../css/patientOverview.scss';

const HIDE_STATUS = true;
const apptTag = true;

const Title = ({
  iconName,
  color,
  data,
  onEditIconClick,
  onDeleteClick,
  onShowIconClick,
  onShowReccuerenceIconClick,
  isMedication,
}) => {
  const { user } = useSelector(state => state.session);

  const hasMoreThanOneIcd = data.icdRecords.length > 0;
  const { isAnswered } = data;
  const isCarePathwayCard = iconName === ComponentTypes.CAREPATHWAYS;
  const isCareplanAnswered =
    (iconName === ComponentTypes.CAREPLAN ||
      iconName === ComponentTypes.ASSESSMENT) &&
    isAnswered;

  const displayEditBtnConditions = !isCarePathwayCard && !isCareplanAnswered;
  return (
    <Flex flexDirection="row" justifyContent="space-between">
      <Flex flexDirection="row" alignItems="center" otherStyles={{ flex: 1 }}>
        <Icon
          name={iconName}
          size={40}
          fill={color}
          style={{ flexShrink: 0 }}
        />
        <div className="card-title-text">{data.name}</div>
        {iconName === ComponentTypes.CAREPATHWAYS && (
          <div className="margin-left-10">
            <Tag
              text={
                data.referenceDate
                  ? moment(data.referenceDate)
                      .locale(I18n.language)
                      .format('DD MMM YYYY HH:mm')
                  : ''
              }
              type="squared"
              fill={
                data.firebaseKey
                  ? ActionColors.SUCCESS
                  : userStatus.color.unpaired
              }
            />
          </div>
        )}
      </Flex>
      <Flex alignItems="center">
        {data.icdRecords && hasMoreThanOneIcd && data.icdRecords[0] && (
          <Flex otherStyles={{ marginRight: 12 }}>
            <ICDViewer icds={data.icdRecords} shouldShowAll />
          </Flex>
        )}
        {!data.isAssessment && !isMedication && (
          <Flex otherStyles={{ marginRight: 12 }}>
            <IconButton
              type="button"
              name="reccuerenceIcon"
              size={30}
              onClick={onShowReccuerenceIconClick}
              tooltipText={I18n.t('recurrence.repetition')}
            />
          </Flex>
        )}
        {displayEditBtnConditions && (
          <Flex otherStyles={{ marginRight: 12 }}>
            <IconButton
              type="button"
              name="edit"
              size={30}
              onClick={onEditIconClick}
              tooltipText={I18n.t('patientOverview.editAssignment')}
            />
          </Flex>
        )}
        <Flex otherStyles={{ marginRight: 12 }}>
          <IconButton
            type="button"
            name="view"
            size={30}
            onClick={onShowIconClick}
            tooltipText={I18n.t('actions.show')}
          />
        </Flex>

        <div>
          {iconName !== ComponentTypes.CAREPATHWAYS && (
            <Flex alignItems="center">
              <IconButton
                type="button"
                name="delete"
                size={30}
                fill={ActionColors.DELETE}
                onClick={() => onDeleteClick(user)}
                tooltipText={I18n.t('common_buttons.delete')}
              />
            </Flex>
          )}
          {iconName === ComponentTypes.CAREPATHWAYS && (
            <IconButton
              type="button"
              name="delete"
              size={30}
              fill={ActionColors.DELETE}
              onClick={onDeleteClick(user)}
              tooltipText={
                data.firebaseKey
                  ? I18n.t('patientOverview.carePathway_delete_disabled')
                  : I18n.t('common_buttons.delete')
              }
              disabled={!!data.firebaseKey}
            />
          )}
        </div>
      </Flex>
    </Flex>
  );
};

const MedicationCardTitle: React.FC<MedicationCardTitleTypes> = ({
  iconName,
  color,
  title,
}) => {
  const fillColor =
    color === ComponentColors.MEDICATION && ComponentColors.MEDICATION;

  return (
    <Flex flexDirection="row" justifyContent="space-between">
      <Flex flexDirection="row" alignItems="center" otherStyles={{ flex: 1 }}>
        <Icon
          name={iconName}
          size={30}
          fill={fillColor}
          style={{ flexShrink: 0 }}
        />
        <Box className="card-title-text">{title}</Box>
      </Flex>
    </Flex>
  );
};

const AppointmentTitle = ({
  iconName,
  color,
  data,
  onEditIconClick,
  onDeleteClick,
  onShowIconClick,
  apptSocket,
  setSendAndUnassignEnabled,
}) => {
  const { user } = useSelector(state => state.session);
  const [apptStatus, setApptStatus] = useState(data.appointmentStatusId);
  const appointmentStatusSocket = `appointmentStatusUpdate_${data.id}`;

  const updateAppointmentStatus = appointment => {
    setApptStatus(appointment.appointmentStatusId);
    setSendAndUnassignEnabled(appointment.appointmentStatusId !== 2);
  };

  const initAppointmentStatusListener = () => {
    apptSocket.on(appointmentStatusSocket, updateAppointmentStatus);
  };

  const unsubscribeToAppointmentStatusListener = () => {
    apptSocket.removeListener(appointmentStatusSocket, updateAppointmentStatus);
  };

  useEffect(() => {
    if (apptSocket && !HIDE_STATUS) initAppointmentStatusListener();
    return function cleanup() {
      if (apptSocket && !HIDE_STATUS) unsubscribeToAppointmentStatusListener();
    };
  }, [apptSocket]);

  useEffect(() => {
    if (!HIDE_STATUS) setApptStatus(data.appointmentStatusId);
  }, [data.appointmentStatusId]);

  return (
    <Flex flexDirection="row" justifyContent="space-between">
      <Flex flexDirection="row" alignItems="center" otherStyles={{ flex: 1 }}>
        <Icon
          name={iconName}
          size={40}
          fill={color}
          style={{ flexShrink: 0 }}
        />
        <div className="card-title-text">{data.name}</div>
        {!HIDE_STATUS && (
          <div className="margin-left-10">
            <Tag
              text={I18n.t(appointmentStatusIdToTranslationKeys[apptStatus])}
              variant={ButtonVariant.CONTAINED}
              type="squared"
              fill={appointmentStatusIdToColor[apptStatus]}
            />
          </div>
        )}
      </Flex>
      <Flex flexDirection="row" alignItems="center">
        {data.icdRecords &&
          data.icdRecords.length > 0 &&
          data.icdRecords[0] && (
            <ICDViewer icds={data.icdRecords} shouldShowAll />
          )}
        {apptStatus !== 2 && (
          <Flex otherStyles={{ marginRight: 12 }}>
            <IconButton
              type="button"
              name="edit"
              size={30}
              onClick={onEditIconClick}
              tooltipText={I18n.t('patientOverview.editAssignment')}
            />
          </Flex>
        )}
        <Flex otherStyles={{ marginRight: 12 }}>
          <IconButton
            type="button"
            name="view"
            size={30}
            onClick={onShowIconClick}
            tooltipText={I18n.t('actions.show')}
          />
        </Flex>
        <div>
          <Flex otherStyles={{ marginRight: 12 }}>
            <IconButton
              type="button"
              name="delete"
              size={30}
              onClick={() => onDeleteClick(user)}
              tooltipText={I18n.t('common_buttons.delete')}
              fill={ActionColors.DELETE}
            />
          </Flex>
        </div>
      </Flex>
    </Flex>
  );
};

const Footer = ({
  sendHandler,
  unassignHandler,
  resultHandler,
  componentType,
  firebaseKey,
  sendAndUnassignEnabled,
  answerButton,
  hasOnlyAssessment,
  carePathwayExpirationDate,
  isCareplanAnswered,
  isLastOccurrenceAnswered,
  isLastOccurrenceBeforeToday,
  careplanData,
  setItemIdToUpdate,
}) => {
  const { user } = useSelector(state => state.session);
  const { navigateToCareplanAnswerView } = useContext(PatientOverviewContext);

  const onSendClick = async () => {
    await sendHandler(user);
  };

  const onUnassignClick = async () => {
    await unassignHandler(user);
  };
  const isCarePathwayCard = componentType === ComponentTypes.CAREPATHWAYS;
  const isCareplanCard = componentType === ComponentTypes.CAREPLAN;
  const isAnswerable = careplanData?.isAnswerable;

  const carePathwaySendBtnCondition = moment(
    carePathwayExpirationDate
  ).isBefore(moment());

  let isDisabled;
  const careplanAnswerRoles = [roles.DOCTOR, roles.NURSE];

  if (isCarePathwayCard || isCareplanCard) {
    isDisabled = carePathwaySendBtnCondition;
  }

  let isButtonUnassignDisplayed;
  let isButtonSendDisplayed;

  if (firebaseKey && sendAndUnassignEnabled && !isLastOccurrenceAnswered) {
    isButtonUnassignDisplayed = true;
  }

  if (
    isCareplanCard &&
    !isDisabled &&
    (isLastOccurrenceAnswered || isLastOccurrenceBeforeToday)
  ) {
    isButtonUnassignDisplayed = false;
    isButtonSendDisplayed = false;
    if (!isCareplanAnswered && firebaseKey) {
      isButtonUnassignDisplayed = true;
    }
  }

  if (
    !firebaseKey &&
    sendAndUnassignEnabled &&
    !hasOnlyAssessment &&
    !isLastOccurrenceAnswered
  ) {
    isButtonSendDisplayed = true;
    if (isAnswerable && isCareplanCard) {
      isButtonSendDisplayed = true;
    }
    if (isCareplanCard && isCareplanAnswered && isLastOccurrenceBeforeToday) {
      isButtonSendDisplayed = false;
    }
  }
  return (
    <Flex flexDirection="row" justifyContent="flex-end">
      {isButtonUnassignDisplayed && (
        <Button
          label={I18n.t('role_view.label_unassign')}
          type={ButtonType.BUTTON}
          onClick={onUnassignClick}
          variant={ButtonVariant.OUTLINED}
          disabled={isDisabled}
        />
      )}
      {isButtonSendDisplayed && (
        <Button
          label={I18n.t('common_labels.label_send')}
          type={ButtonType.BUTTON}
          onClick={onSendClick}
          variant={ButtonVariant.CONTAINED}
          disabled={isDisabled}
        />
      )}
      {answerButton}
      {resultHandler && (
        <div className="margin-left-10">
          <Button
            label={I18n.t('common_labels.label_results')}
            type={ButtonType.BUTTON}
            onClick={resultHandler}
            variant={ButtonVariant.CONTAINED}
          />
        </div>
      )}
    </Flex>
  );
};

const CarePathwayTitle = ({
  data,
  onDeleteClick,
  onEditIconClick,
  onShowIconClick,
  sendHandler,
  unassignHandler,
  resultHandler,
  componentType,
  firebaseKey,
  sendAndUnassignEnabled,
  hasOnlyAssessment,
  carePathwayReferenceDate,
  carePathwayExpirationDate,
  appointmentStartDate,
  responsive,
  redirectTab,
  onExpand,
}) => {
  const { user } = useSelector(state => state.session);

  const getStatusColor = (
    statusId,
    expirationDate,
    hover = false,
    isApptTag = false
  ) => {
    let statusColor;
    const isExpirationDateBeforeToday = moment(expirationDate).isBefore(
      moment()
    );

    const chosenStatusId = isExpirationDateBeforeToday
      ? CAREPATHWAY_STATUS.INACTIVE
      : statusId;

    switch (chosenStatusId) {
      case CAREPATHWAY_STATUS.ACTIVE:
        if (hover) statusColor = ActionColors.ACTIVE_25;
        else
          statusColor = isApptTag
            ? ActionColors.ACTIVE_10
            : ActionColors.SUCCESS;
        break;
      case CAREPATHWAY_STATUS.PLANNED:
        if (hover) statusColor = ActionColors.PLANNED_25;
        else
          statusColor = isApptTag
            ? ActionColors.PLANNED_10
            : ActionColors.WARNING;
        break;
      default:
        if (hover) statusColor = ActionColors.INACTIVE_25;
        else
          statusColor = isApptTag ? ActionColors.INACTIVE_10 : Greys.LIGHT_GREY;
    }
    return statusColor;
  };
  const [linkStyle, setLinkStyle] = useState({});

  const menuItems = [
    {
      id: 1,
      value: I18n.t('actions.show'),
    },
    {
      id: 2,
      value: I18n.t('actions.edit'),
    },
    {
      id: 3,
      value: I18n.t('actions.delete'),
    },
  ];

  const toggleHover = hover => {
    if (hover) {
      setLinkStyle({
        backgroundColor: getStatusColor(
          data.carePathwayStatus.id,
          data.expirationDate,
          hover,
          false
        ),
      });
    } else {
      setLinkStyle({
        backgroundColor: getStatusColor(
          data.carePathwayStatus.id,
          data.expirationDate,
          false,
          apptTag
        ),
      });
    }
  };

  useEffect(() => {
    setLinkStyle({
      backgroundColor: getStatusColor(
        data.carePathwayStatus.id,
        data.expirationDate,
        false,
        apptTag
      ),
    });
  }, [data.carePathwayStatus.id]);

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      otherStyles={{ marginRight: 10 }}
    >
      <div onClick={onExpand} style={{ flex: 1, cursor: 'pointer' }}>
        <Flex flexDirection="row" alignItems="center" otherStyles={{ flex: 1 }}>
          <Flex
            flexDirection="column"
            alignItems="center"
            otherStyles={{ marginLeft: 21, width: 54, flexShrink: 0 }}
          >
            <Icon
              name={ComponentTypes.CAREPATHWAYS}
              size={40}
              fill={Greys.LIGHT_GREY}
            />
            <Flex
              otherStyles={{
                marginTop: 10,
                fontSize: 14,
                lineHeight: 1,
                fontWeight: 'bold',
                color: Greys.LIGHT_GREY,
              }}
            >
              {I18n.t(data.carePathwayStatus.name)}
            </Flex>
          </Flex>
          <div className="assignment-card__carepathway--title">{data.name}</div>
          {data.icdRecords &&
            data.icdRecords.length > 0 &&
            data.icdRecords[0] && (
              <Box margin="0 0 0 16px">
                <ICDViewer icds={data.icdRecords} />
              </Box>
            )}
          <Box className="pathway_appointment_container">
            <Button
              type="contained"
              className="pathway_appointment_btn"
              onClick={() => {
                redirectTab(ComponentTypes.APPOINTMENT, {
                  id: data.appointmentId,
                });
              }}
              style={
                data.appointmentTypeAttributes
                  ? {
                      color: getStatusColor(
                        data.carePathwayStatus.id,
                        data.expirationDate
                      ),
                      backgroundColor: linkStyle.backgroundColor,
                    }
                  : {}
              }
              onMouseEnter={() => toggleHover(true)}
              onMouseLeave={() => toggleHover(false)}
              label={
                data.appointmentTypeAttributes
                  ? `${I18n.t(data.appointmentTypeAttributes.name)},
                ${moment(data.referenceDate)
                  .locale(I18n.language)
                  .format('DD MMM YYYY HH:mm')}`
                  : `${moment(data.referenceDate)
                      .locale(I18n.language)
                      .format('DD MMM YYYY HH:mm')}`
              }
              disabled={!data.appointmentTypeAttributes}
            />
          </Box>
        </Flex>
      </div>
      <Box margin="0 36px 0 0">
        <Footer
          sendHandler={sendHandler}
          unassignHandler={unassignHandler}
          resultHandler={resultHandler}
          sendAndUnassignEnabled={sendAndUnassignEnabled}
          componentType={componentType}
          firebaseKey={firebaseKey}
          hasOnlyAssessment={hasOnlyAssessment}
          carePathwayReferenceDate={carePathwayReferenceDate}
          carePathwayExpirationDate={carePathwayExpirationDate}
          appointmentStartDate={appointmentStartDate}
        />
      </Box>
      <Flex
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        otherStyles={{
          flex: 0,
          border: `solid ${Greys.LIGHT_GREY_50}`,
          borderWidth: '0 1px',
          padding: '0 16px',
        }}
      >
        {responsive ? (
          <Box margin="0 8px">
            <IconButtonDropdown
              tooltipText={I18n.t('patientOverview.editAssignment')}
              items={menuItems}
              onClick={(e, action) => {
                switch (action.id) {
                  case 1:
                    onShowIconClick();
                    break;
                  case 2:
                    onEditIconClick();
                    break;
                  case 3:
                    onDeleteClick(user);
                    break;
                  default:
                    break;
                }
              }}
            />
          </Box>
        ) : (
          <>
            <Box margin="0 8px">
              <IconButton
                type="button"
                name="view"
                size={30}
                onClick={onShowIconClick}
                tooltipText={I18n.t('actions.show')}
              />
            </Box>
            <Box margin="0 8px">
              <IconButton
                type="button"
                name="edit"
                size={30}
                onClick={onEditIconClick}
                tooltipText={I18n.t('actions.edit')}
                disabled={data.appointmentTypeAttributes}
              />
            </Box>
            <Tooltip
              position={Position.bottom}
              content={
                data.firebaseKey
                  ? I18n.t('patientOverview.carePathway_delete_disabled')
                  : I18n.t('common_buttons.delete')
              }
            >
              <Box margin="0 8px">
                <IconButton
                  type="button"
                  name="delete"
                  size={30}
                  fill={ActionColors.DELETE}
                  onClick={() => onDeleteClick(user)}
                  disabled={!!data.firebaseKey}
                />
              </Box>
            </Tooltip>
          </>
        )}
      </Flex>
    </Flex>
  );
};

const CarePathwayTitleForAppointmentUpdate = ({
  data,
  appointmentData,
  onExpand,
}) => {
  return (
    <div onClick={onExpand} style={{ flex: 1, cursor: 'pointer' }}>
      <Flex flexDirection="row">
        <Flex flexDirection="row" alignItems="center" otherStyles={{ flex: 1 }}>
          <Flex flexDirection="row" otherStyles={{ marginLeft: 21 }}>
            <Icon name="carepathway" size={40} fill={Greys.DARK_GREY} />
          </Flex>
          <div className="assignment-card__carepathway--title">{data.name}</div>
          <Flex flexDirection="row" alignItems="center">
            {data.icdRecords &&
              data.icdRecords.length > 0 &&
              data.icdRecords[0] && (
                <Box margin="0 0 0 16px">
                  <ICDViewer icds={data.icdRecords} />
                </Box>
              )}
          </Flex>
          <Box className="pathway_appointment_container">
            <button
              type="button"
              className="pathway_appointment_btn"
              style={{
                color: Greys.DARK_GREY,
              }}
            >
              {appointmentData.startDate
                ? `${appointmentData.appointmentType.label},
                ${moment(appointmentData.startDate)
                  .locale(I18n.language)
                  .format('DD MMM YYYY')}`
                : ''}
            </button>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

const CareplanTitle = ({ data, onExpand, careplan }) => {
  let style = {};

  const getCareplanTitleIcon = (isAssessment, healthIndicator) => {
    let name;
    if (healthIndicator) {
      name = isAssessment ? 'linkedAssessment' : 'linkedCaretask';
    } else {
      name = isAssessment ? 'assessment' : 'careplan';
    }
    return name;
  };

  if (!careplan.healthIndicator) {
    style = { flex: 1, cursor: 'pointer' };
  }
  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      otherStyles={{ marginRight: 10 }}
    >
      <div onClick={onExpand} style={style}>
        <Flex flexDirection="row" alignItems="center" otherStyles={{ flex: 1 }}>
          <Flex
            flexDirection="column"
            alignItems="center"
            otherStyles={{ marginLeft: 21, width: 54, flexShrink: 0 }}
          >
            <Icon
              name={getCareplanTitleIcon(
                careplan.isAssessment,
                careplan.healthIndicator
              )}
              size={35}
              fill={
                careplan.healthIndicator
                  ? getHealthIndicatorColor(
                      careplan.healthIndicator ||
                        careplan.lastOccurrenceHealthIndicator
                    )
                  : getCarePlanIconColor(careplan)
              }
            />
          </Flex>
          <div
            style={{ display: 'flex' }}
            className="assignment-card__carepathway--title"
          >
            {careplan.name}
          </div>
          {!careplan.healthIndicator && data.icdRecords?.[0] && (
            <Box margin="0 0 0 16px">
              <ICDViewer icds={data.icdRecords} />
            </Box>
          )}
        </Flex>
        {careplan.healthIndicator ? (
          <Box className="linkedCaretaskAnsweredTime">
            {formatDate(
              careplan.answeredAt,
              'DD MMM YYYY HH:mm',
              I18n.language
            )}
          </Box>
        ) : (
          <Box
            otherStyles={{ marginLeft: '115px' }}
            className="assignment-card__congfiguration--text"
          >
            {`${careplan.careplanRecurrences?.length} ${I18n.t(
              'newCareplan_view.careplanConfig'
            )}`}
          </Box>
        )}
      </div>
    </Flex>
  );
};

Title.propTypes = {
  iconName: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  onEditIconClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onShowIconClick: PropTypes.func.isRequired,
};

Footer.propTypes = {
  componentType: PropTypes.string.isRequired,
  sendHandler: PropTypes.func,
  unassignHandler: PropTypes.func,
  resultHandler: PropTypes.func,
  firebaseKey: PropTypes.string,
  sendAndUnassignEnabled: PropTypes.bool,
  hasOnlyAssessment: PropTypes.bool,
};

Footer.defaultProps = {
  sendHandler: null,
  unassignHandler: null,
  resultHandler: null,
  firebaseKey: null,
  sendAndUnassignEnabled: true,
  hasOnlyAssessment: false,
};

AppointmentTitle.propTypes = {
  iconName: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  apptSocket: PropTypes.objectOf(PropTypes.object).isRequired,
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  onEditIconClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onShowIconClick: PropTypes.func.isRequired,
  setSendAndUnassignEnabled: PropTypes.func.isRequired,
};
CarePathwayTitle.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onShowIconClick: PropTypes.func.isRequired,
  componentType: PropTypes.string.isRequired,
  sendHandler: PropTypes.func,
  unassignHandler: PropTypes.func,
  resultHandler: PropTypes.func,
  firebaseKey: PropTypes.string,
  sendAndUnassignEnabled: PropTypes.bool,
  hasOnlyAssessment: PropTypes.bool,
};

CarePathwayTitleForAppointmentUpdate.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

CarePathwayTitle.defaultProps = {
  sendHandler: null,
  unassignHandler: null,
  resultHandler: null,
  firebaseKey: null,
  sendAndUnassignEnabled: true,
  hasOnlyAssessment: false,
};
export {
  Title,
  Footer,
  AppointmentTitle,
  CarePathwayTitle,
  CarePathwayTitleForAppointmentUpdate,
  CareplanTitle,
  MedicationCardTitle,
};
