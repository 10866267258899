import React, { useState } from 'react';
import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';
import Button from '../../../../../../caro-ui-commonfiles/components/Button/Button';
import Icon from '../../../../../../caro-ui-commonfiles/components/Icon/Icon';
import {
  ButtonType,
  ButtonVariant,
} from '../../../../../../caro-ui-commonfiles/utils/button';
import { useTranslation } from 'react-i18next';
import FilterDropdownContent from '../FilterDropdownContent/FilterDropdownContent';
import {
  FilterDropdownContainerProps,
  showDropdownTypes,
} from './FilterDropdownContainer.type';

const FilterDropdownContainer = ({
  showFilterSettings,
  setShowFilterSettings,
  advancedFilterList,
  selectAdvancedFilter,
  setDisplayFlashMsg,
}: FilterDropdownContainerProps): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(showDropdownTypes.Static);
  const { t } = useTranslation();

  const toggleDropdown = () => {
    const show =
      showDropdown === showDropdownTypes.Static
        ? showDropdownTypes.Visible
        : showDropdownTypes.Static;
    setShowDropdown(show);
  };
  return (
    <Box margin="10px 20px 20px 20px">
      <div>
        <Button
          label={t('patients_complexsearch.filter')}
          type={ButtonType.BUTTON}
          onClick={toggleDropdown}
          variant={ButtonVariant.OUTLINED}
        >
          <Icon name="filter" size={18} />
        </Button>
        <div className="filters__dropdown-container">
          <FilterDropdownContent
            showDropdown={showDropdown}
            toggleDropdown={toggleDropdown}
            showFilterSettings={showFilterSettings}
            setShowFilterSettings={setShowFilterSettings}
            advancedFilterList={advancedFilterList}
            selectAdvancedFilter={selectAdvancedFilter}
            setDisplayFlashMsg={setDisplayFlashMsg}
          />
        </div>
      </div>
    </Box>
  );
};

export default FilterDropdownContainer;
