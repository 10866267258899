import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Tag from '../../../../caro-ui-commonfiles/components/Tags/Tags';
import Tooltip from '../../../../caro-ui-commonfiles/components/Tooltip/Tooltip';

import { TagsVariant, PrimaryColor, Position } from '@ui-common-files/utils';

const TagsCell = ({ tagList, hasMark, variant, roles }) => {
  let tagComponent = [];

  if ((tagList.length < 3 && !roles) || (tagList.length < 2 && roles)) {
    tagComponent = tagList.map(tag => (
      <Flex otherStyles={{ marginRight: '5px' }}>
        <Tag
          text={tag.name}
          hasMark={hasMark}
          fill={PrimaryColor.MAIN_COLOR}
          variant={variant}
          shouldHaveEllipsis
        />
      </Flex>
    ));
  } else {
    if (roles) {
      tagComponent.push(
        <Flex otherStyles={{ marginRight: '5px' }}>
          <Tag
            text={tagList[0].name}
            hasMark={hasMark}
            fill={PrimaryColor.MAIN_COLOR}
            variant={variant}
            shouldHaveEllipsis
          />
        </Flex>
      );
    } else {
      tagComponent.push(
        <Flex otherStyles={{ marginRight: '5px' }}>
          <Tag
            text={tagList[0].name}
            hasMark={hasMark}
            fill={PrimaryColor.MAIN_COLOR}
            variant={variant}
            shouldHaveEllipsis
          />
        </Flex>
      );

      tagComponent.push(
        <Flex otherStyles={{ marginRight: '5px' }}>
          <Tag
            text={tagList[1].name}
            hasMark={hasMark}
            fill={PrimaryColor.MAIN_COLOR}
            variant={variant}
            shouldHaveEllipsis
          />
        </Flex>
      );
    }

    const listTag = roles ? tagList.slice(1, 5) : tagList.slice(2);

    const tagTooltip = listTag.map(singleTag => (
      <Box padding={5}>
        <Tag
          text={singleTag.name}
          hasMark={hasMark}
          fill={PrimaryColor.MAIN_COLOR}
          variant={variant}
        />
      </Box>
    ));

    const tooltipComponent = <Flex flexDirection="column">{tagTooltip}</Flex>;

    if (roles && tagList.length > 4) {
      tagTooltip.push(
        <Tooltip content={tooltipComponent} position={Position.top}>
          <div style={{ cursor: 'default' }}> ... </div>
        </Tooltip>
      );
    }

    if (tagTooltip.length > 0) {
      tagComponent.push(
        <Tooltip content={tooltipComponent} position={Position.top}>
          <div style={{ cursor: 'default' }}> ... </div>
        </Tooltip>
      );
    }
  }

  return <Flex alignItems="center">{tagComponent}</Flex>;
};

TagsCell.propTypes = {
  tagList: PropTypes.shape([]).isRequired,
  hasMark: PropTypes.bool,
  variant: PropTypes.oneOf([TagsVariant.CONTAINED, TagsVariant.OUTLINED]),
  roles: PropTypes.bool,
};

TagsCell.defaultProps = {
  hasMark: false,
  variant: TagsVariant.CONTAINED,
  roles: false,
};

export default TagsCell;
