import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import showCareplanAnswers from './showCareplanAnswers';

import { CareplanTitle } from './CardComponents';
import { Box, Button } from '@ui-common-files/components';
import { ButtonType, ButtonVariant } from '@ui-common-files/utils';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import { getCareplanAnswers } from '@utils/Careplan';

const LinkedCareplanCard = ({ careplan, index, data, status }) => {
  const { t } = useTranslation();
  const { setPatientSectionalModal } = useContext(PatientOverviewContext);

  const linkedCareplanResultsData = () => {
    return async () => {
      const results = await getCareplanAnswers(careplan.id).then(res => res);
      if (results)
        setPatientSectionalModal(
          showCareplanAnswers(
            careplan,
            results,
            t('common_labels.label_results')
          )
        );
    };
  };

  return (
    <Box className={`myCareplanTitle ${status} linkedCareplanResultButton`}>
      <Box>
        <CareplanTitle data={data} careplan={careplan} onExpand={() => {}} />
      </Box>
      <Button
        label={t('common_labels.label_results')}
        type={ButtonType.BUTTON}
        onClick={linkedCareplanResultsData()}
        variant={ButtonVariant.CONTAINED}
      />
    </Box>
  );
};

export default LinkedCareplanCard;
