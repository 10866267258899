import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Loader from 'react-loader-spinner';
import ReactFlow, { MiniMap, Controls, Background, Panel } from 'reactflow';
import {
  Flex,
  Box,
  Breadcrumb,
  Button,
  Modal,
} from '@ui-common-files/components';
import {
  NavigationJSON,
  ButtonType,
  ButtonVariant,
  PrimaryColor,
} from '@ui-common-files/utils';
import { HideFlashMessage } from '@global/Services';

import { BreadcrumbPortal } from '../../AppLayout';
import { PromptContent, PromptFooter } from '../../common/ConfirmationPrompt';

import useCarePathWayBuilderService from './CarePathWayBuilder.service';
import { isValidConnection } from './CarePathWayBuilder.validations';

import { nodeTypes, edgeTypes } from './CarePathWayBuilder.type';

import PanelHeader from './PanelHeader/PanelHeader';
import PanelSidebar from './PanelSidebar/PanelSidebar';

import { PathWayReviewModal } from './Components';

import './CarePathWayBuilder.styles.scss';
import 'reactflow/dist/base.css';

const CarePathWayBuilder: React.FC = () => {
  const {
    nodes,
    edges,
    onDrop,
    onConnect,
    isLoading,
    onDragOver,
    isEditMode,
    onEdgeUpdate,
    onNodesChange,
    onEdgesChange,
    onEdgeUpdateEnd,
    reactFlowWrapper,
    onEdgeUpdateStart,
    navigateToCarePathWayList,
    submitCarePathWay,
  } = useCarePathWayBuilderService();

  const { t } = useTranslation();

  const [triggerCancel, setTriggerCancel] = useState(false);

  const closeMessagePrompt = () => {
    setTriggerCancel(false);
  };

  const careTaskBuilderCancel = () => {
    setTriggerCancel(true);
  };

  return (
    <>
      <BreadcrumbPortal>
        <Breadcrumb>
          <Link to={`/${NavigationJSON.CAREPLAN}`}>{t('nav.careManager')}</Link>
          <Flex alignItems="center">
            <span>{t('breadcrumbs.carePathwayBuilder')}</span>
            <Box margin="0 0 0 20px">
              <Button
                label={t('common_buttons.saveAndClose')}
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.CONTAINED}
                onClick={() => {
                  submitCarePathWay(true);
                }}
              />
            </Box>
            <Box margin="0 0 0 10px">
              <Button
                label={t('common_buttons.save')}
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.CONTAINED}
                onClick={() => {
                  submitCarePathWay(false);
                }}
              />
            </Box>
            <Box margin="0 0 0 10px">
              <Button
                label={t('common_buttons.close')}
                type={ButtonType.RESET}
                variant={ButtonVariant.OUTLINED}
                onClick={() => {
                  careTaskBuilderCancel();
                }}
              />
            </Box>
          </Flex>
        </Breadcrumb>
      </BreadcrumbPortal>
      <Box className="pathway-builder_content" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onDrop={onDrop}
          onDragOver={onDragOver}
          nodesDraggable
          onConnect={onConnect}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateStart={onEdgeUpdateStart}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          isValidConnection={isValidConnection}
          fitView={isEditMode}
        >
          <Panel position="top-left" className="panel-header-container">
            <PanelHeader />
          </Panel>
          <Panel position="top-right" className="panel-sidebar-container">
            <PanelSidebar />
          </Panel>
          <MiniMap zoomable pannable />
          <Controls showInteractive={false} />
          <Background />
        </ReactFlow>
      </Box>
      <PathWayReviewModal />
      {triggerCancel && (
        <Modal
          isConfirmationDialog
          title={t('warningMessage.leave_carepathway_builder')}
          contentComponent={
            <PromptContent
              message={ReactHtmlParser(t('warningMessage.templateLoss'))}
            />
          }
          footerComponent={
            <PromptFooter
              close={closeMessagePrompt}
              btnLabelRight={t(
                'common_buttons.leave_without_saving_carepathway'
              )}
              confirmHandler={navigateToCarePathWayList}
            />
          }
          openModal={triggerCancel}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}
      {isLoading && (
        <Box className="roleOverlapSpinner">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Box>
      )}
    </>
  );
};

export default CarePathWayBuilder;
