import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import moment from 'moment';
import Loader from 'react-loader-spinner';

import { getUTCDateFromLocalDate } from '@ui-common-files/utils/dateUtils/dateUtil';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import DatePicker from '../../../caro-ui-commonfiles/components/DateTimePicker/Datepicker';
import MultipleSelection from '../../../caro-ui-commonfiles/components/MultipleSelectTagSearchable/MultipleSelectTagSearchable';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import FilterWarningMessage from '../../../caro-ui-commonfiles/components/FilterWarningMessage/FilterWarningMessage';

import { ApiClient, ShowFlashMessage } from '../../Global/Services';
import { FlashMessageTypes } from '../../../caro-ui-commonfiles/types/components';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import serviceConfig from '../../../serviceConfig.json';

import '../../css/careplans.scss';
import '../../css/answers.css';

function ExportAllPatientsAnswersModalContent({
  closeModal,
  setDataForExportAnswers,
  isLoading,
  isFilterSelected,
  filteredPatientsForCsvExport,
  isNewPatient,
}) {
  const [hasErrors, setHasErrors] = useState(false);
  const [careplanComponents, setCareplanComponents] = useState(null);
  const [careplanComponent, setCareplanComponent] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isFlashMessageShow, setIsFlashMessageShow] = useState(false);

  const getAllCareplanComponents = async () => {
    const response = await ApiClient.GET({
      url: serviceConfig.careplanService.getAllTemplatesWithIdAndName.uri,
      timeout: 60000,
    }).catch(error => {
      closeModal();
      ShowFlashMessage({
        type: error.response.data.type,
        messageKey: error.response.data.content,
        isModalMessage: false,
      });
    });
    if (response) {
      setCareplanComponents(response.data.content.templateResponse);
    }
  };

  const validateInputs = () => {
    const errors = !careplanComponent || careplanComponent.length === 0;
    setHasErrors(errors);
    return errors;
  };

  const updateDataForExport = () => {
    let hasSubScoreFormula = false;
    const careplanComponentId =
      careplanComponent && careplanComponent.length
        ? careplanComponent.map(elem => {
          if (elem.hasSubScoreFormula) {
            hasSubScoreFormula = true;
          }
          return elem.value;
        })
        : null;
    setDataForExportAnswers({
      careplanComponentId,
      input1: startDate,
      input2: endDate,
      isNewPatient,
      hasSubScoreFormula,
    });
  };

  const validateAndExecute = () => {
    const errors = validateInputs();
    if (errors === false && hasErrors === false) {
      updateDataForExport();
    }
  };

  useEffect(() => {
    if (careplanComponent && careplanComponent.length) {
      validateAndExecute();
    }
  }, [careplanComponent, hasErrors, startDate, endDate]);

  const handleFormSubmit = event => {
    setIsFlashMessageShow(true);
    event.preventDefault();
    validateAndExecute();
    return false;
  };

  useEffect(() => {
    updateDataForExport();
  }, [careplanComponent]);

  useEffect(() => {
    getAllCareplanComponents();
  }, []);

  useEffect(() => {
    if (isFlashMessageShow && isLoading) {
      ShowFlashMessage({
        messageKey: 'flash.exportCsvFileInfo',
        type: FlashMessageTypes.Info,
        autoHide: false,
      });
    }
  }, [isFlashMessageShow, isLoading]);

  return (
    <ModalContent>
      <ModalForm
        formId="form__export-results-patients"
        handleFormSubmit={handleFormSubmit}
      >
        {isLoading ||
        (filteredPatientsForCsvExport === null && isFilterSelected) ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            otherStyles={{
              top: '47.3%',
              left: '48.4%',
              position: 'fixed',
            }}
          >
            <Loader
              type="Oval"
              color={PrimaryColor.MAIN_COLOR}
              height={60}
              width={60}
            />
          </Flex>
        ) : (
          <>
            <ModalSection>
              <div style={{ marginLeft: '30px', marginTop: '30px' }}>
                <Label text={I18n.t('patientOverview.note')} id="note" />
                <p className="latest-answer">
                  {I18n.t('newCareplan_view.note')}
                </p>
              </div>
              <div style={{ marginLeft: '30px', marginTop: '30px' }}>
                {isFilterSelected && (
                  <Flex>
                    <FilterWarningMessage
                      message={I18n.t('patients_complexsearch.filter_applied')}
                    />
                  </Flex>
                )}
              </div>
            </ModalSection>
            <ModalSection>
              <div style={{ marginLeft: '25px', marginTop: '26px' }}>
                <ComponentWithLabel
                  id="assignmentSelection"
                  htmlFor="assignmentSelection"
                  labelText={`* ${I18n.t(
                    'newCareplan_view.careplanComponentSelection'
                  )}`}
                >
                  <MultipleSelection
                    value={careplanComponent}
                    defaultOptions={careplanComponents}
                    maxChars={careplanComponents && careplanComponents.length}
                    isDisabled={false}
                    placeholder={I18n.t('patientOverview.selectOptions')}
                    tagVariant="contained"
                    hasMark={false}
                    isClearable
                    clearIndicatorText={I18n.t('common_buttons.clear')}
                    isSelectAll
                    hasError={hasErrors}
                    selectAllIndicatorText={I18n.t('common_buttons.selectAll')}
                    noOptionsMessage={I18n.t('appointment.noResultsFound')}
                    changeValue={event => {
                      setCareplanComponent(event);
                    }}
                  />
                  {hasErrors === true && (
                    <span className="patients__phone-field error">
                      {I18n.t('parsley.requiredField')}
                    </span>
                  )}
                </ComponentWithLabel>
              </div>
              <div
                style={{
                  marginLeft: '25px',
                  marginTop: '22px',
                  whiteSpace: 'nowrap',
                }}
              >
                <ComponentWithLabel
                  id="dateRange"
                  htmlFor="dateRange"
                  labelText={I18n.t('patientOverview.dateRange')}
                >
                  <Flex flexDirection="row">
                    <h3 className="from-label">
                      {I18n.t('common_labels.from')}
                    </h3>
                    <div style={{ width: '149px' }}>
                      <DatePicker
                        date={startDate}
                        placeholder=""
                        handleDate={date => {
                          setStartDate(getUTCDateFromLocalDate(date));
                        }}
                        minDate={moment().subtract(190, 'years').toDate()}
                        maxDate={endDate || new Date()}
                      />
                    </div>
                    <h3 className="to-label">{I18n.t('common_labels.to')}</h3>
                    <div style={{ width: '149px' }}>
                      <DatePicker
                        date={endDate}
                        placeholder=""
                        handleDate={date => {
                          setEndDate(getUTCDateFromLocalDate(date));
                        }}
                        minDate={
                          startDate || moment().subtract(190, 'years').toDate()
                        }
                        maxDate={new Date()}
                      />
                    </div>
                  </Flex>
                </ComponentWithLabel>
              </div>
            </ModalSection>
          </>
        )}
      </ModalForm>
    </ModalContent>
  );
}

export default ExportAllPatientsAnswersModalContent;
