import I18n from 'i18next';

function getAppointmentsLabel(filter) {
  if (filter.appointmentTypeOrder) {
    switch (filter.appointmentTypeOrder) {
      case 1:
        return I18n.t('types.appointmentType.attending');
      case 2:
        return I18n.t('types.appointmentType.consultation');
      case 3:
        return I18n.t('types.appointmentType.discharge');
      case 4:
        return I18n.t('types.appointmentType.followup');
      case 5:
        return I18n.t('types.appointmentType.test');
      case 6:
        return I18n.t('types.appointmentType.transportation');
      case 7:
        return I18n.t('types.appointmentType.treatment');
      case 8:
        return I18n.t('types.appointmentType.surgeryDate');
      case 9:
        return I18n.t('types.appointmentType.others');
      case 10:
        return I18n.t('types.appointmentType.videoCall');
      default:
        return '';
    }
  }
  if (filter.appointmentStatusOrder) {
    switch (filter.appointmentStatusOrder) {
      case 1:
        return I18n.t('types.appointmentStatus.accepted');
      case 2:
        return I18n.t('types.appointmentStatus.reschedule');
      case 3:
        return I18n.t('types.appointmentStatus.response');
      default:
        return '';
    }
  }
}

export default getAppointmentsLabel;
