import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@ui-common-files/components';
import {
  CaretaskAnsweringContent,
  CaretaskAnsweringFooter,
  CaretaskAnsweringProgressBar,
} from './Components';

import {
  CaretaskAnsweringNavigator,
  CaretaskAnsweringProps,
} from './CaretaskAnswering.type';
import { Caretask } from '../../../../types/Caretask';
import { PrimaryColor } from '@ui-common-files/utils';

import { getCaretaskData } from './CaretaskAnswering.api';
import {
  selectCaretask,
  selectNavigator,
  selectIsAnsweringMain,
  selectMainComponentsCount,
  setCaretask,
  resetAnswering,
  createAnswersObject,
} from './CaretaskAnswering.reducer';

import './CaretaskAnswering.style.scss';

function CaretaskAnswering({
  occurrenceId,
  caretaskId,
  setCaretaskId,
  setAnsweredCaretask,
  setFlashMessage,
}: CaretaskAnsweringProps): JSX.Element {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const caretaskData: Caretask = useSelector(selectCaretask);
  const navigator: CaretaskAnsweringNavigator = useSelector(selectNavigator);
  const mainComponentsCount: number = useSelector(selectMainComponentsCount);

  const isAnsweringMain: boolean = useSelector(selectIsAnsweringMain);
  const dispatch = useDispatch();

  const shouldDisplayProgressbar =
    navigator.Current < mainComponentsCount || !isAnsweringMain;

  useEffect(() => {
    async function fetchCaretaskData() {
      setIsLoading(true);
      getCaretaskData(caretaskId, occurrenceId)
        .then(response => {
          if (response) {
            dispatch(setCaretask(response.data));
            dispatch(createAnswersObject(response.data.careplanComponents));
          }
        })
        .catch(error => {
          if (error?.response?.data) {
            setFlashMessage({
              content: t(error?.response?.data?.content),
              type: error?.response?.data?.type,
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (caretaskId && caretaskId !== caretaskData?.id) {
      dispatch(resetAnswering());
      fetchCaretaskData();
    }
  }, [caretaskId]);

  return (
    <Box className="answer-view">
      <Box className="answer-view__container">
        {!caretaskId && (
          <Box className="answer-view__container-no-caretask">
            {t('common_labels.no_caretask_selected')}
          </Box>
        )}
        {caretaskId && (
          <>
            {isLoading && (
              <Box className="answer-view__container-loading">
                <Loader
                  type="Oval"
                  color={PrimaryColor.MAIN_COLOR}
                  height={40}
                  width={40}
                />
              </Box>
            )}
            {!isLoading && caretaskData !== null && (
              <>
                {shouldDisplayProgressbar && <CaretaskAnsweringProgressBar />}
                <Box className="answer-view__container-content">
                  <Box className="answer-view__container-content-inner">
                    <CaretaskAnsweringContent
                      setFlashMessage={setFlashMessage}
                    />
                    <CaretaskAnsweringFooter
                      setCaretaskId={setCaretaskId}
                      occurrenceId={occurrenceId}
                      setAnsweredCaretask={setAnsweredCaretask}
                    />
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default CaretaskAnswering;
