import axios from 'axios';
import serviceConfig from '../serviceConfig.json';

export async function getPatientCareplanDataApiCall(data) {
  const response = await axios.get(
    serviceConfig.careplanService.getAnalyticDataForPatientOverview.uri,
    {
      params: {
        careplanId: data.careplanId,
        patientId: data.patientId,
        fromDashboard: data.fromDashboard,
      },
      timeout: 60000,
    }
  );
  return response;
}

export async function getCareplanAnalyticDataApiCall(data) {
  const response = await axios.get(
    serviceConfig.careplanService.getPeerGroupAnalyticDataById.uri,
    {
      params: {
        data,
      },
      timeout: 60000,
    }
  );
  return response;
}

export async function getAnalyticDataForDashboardApiCall(data) {
  const response = await axios.get(
    serviceConfig.careplanService.getAnalyticDataForDashboard.uri,
    {
      params: {
        data,
      },
      timeout: 60000,
    }
  );
  return response;
}

export async function getAllCustomFiltersApiCall(query) {
  const response = await axios.get(
    serviceConfig.brokerService.getAllFilters.uri,
    {
      params: {
        query: query.query,
      },
      timeout: 60000,
    }
  );
  return response;
}

export async function updateCareplanPeerGroupApiCall(
  templateId,
  peerGroupSelected
) {
  const response = await axios.get(
    serviceConfig.careplanService.updateCareplanPeerGroup.uri,
    {
      params: {
        peerGroupArray: peerGroupSelected,
        templateId,
      },
      timeout: 60000,
    }
  );
  return response;
}

export async function getAllCareplanTemplatesApiCall(data) {
  const templateList = data.templateArray.map(function (template) {
    return template.id;
  });
  const response = await axios.get(
    serviceConfig.careplanService.getAllTemplates.uri,
    {
      params: {
        templateList,
      },
      timeout: 60000,
    }
  );
  return response;
}

export async function getAllTemplatesApiCall() {
  const response = await axios.get(
    serviceConfig.careplanService.getAllTemplatesWithIdAndName.uri,
    { timeout: 60000 }
  );
  return response;
}
