import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { dropdownAppointmentTypes } from '@utils/dropdownTypes';

import { useCarePathWayBuilderStore } from '../CarePathWayBuilder.store';

const useCarePathWaySettingsService = () => {
  const { settings, setSettings, removeAnError, hasErrorBasedOnId } =
    useCarePathWayBuilderStore(
      useShallow(state => ({
        settings: state.settings,
        setSettings: state.setSettings,
        removeAnError: state.removeAnError,
        hasErrorBasedOnId: state.hasErrorBasedOnId,
      }))
    );

  const { i18n } = useTranslation();

  const [allAppointmentTypes, setAllAppointmentTypes] = useState([]);

  useEffect(() => {
    const updatedOptions = dropdownAppointmentTypes();
    setAllAppointmentTypes(updatedOptions);
  }, [i18n.language]);

  const onValueChange = useCallback(
    (value, key) => {
      setSettings(key, value);
      removeAnError(key);
    },
    [removeAnError, setSettings]
  );

  useEffect(() => {
    const hasMatchingValue = option =>
      option.value === settings.appointmentType?.value;
    const hasSettingsStored = allAppointmentTypes.some(hasMatchingValue);

    if (hasSettingsStored) {
      const index = allAppointmentTypes.findIndex(hasMatchingValue);
      onValueChange(allAppointmentTypes[index], 'appointmentType');
    }
  }, [allAppointmentTypes, settings.appointmentType, onValueChange]);

  const appointmentTypeHasError = hasErrorBasedOnId('appointmentType');
  const icdsHasError = hasErrorBasedOnId('icds');

  return {
    settings,
    icdsHasError,
    onValueChange,
    allAppointmentTypes,
    appointmentTypeHasError,
  };
};

export default useCarePathWaySettingsService;
