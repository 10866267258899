import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Flex, Box } from '@ui-common-files/components';

import type {
  MedicationPlanMetaData,
  MedicationPlanPage,
} from '../../MedicationPlanReport.type';
import './MedicationPlanReportPageHeader.scss';

const QRCodeComponent = ({ qrCode }: { qrCode: string }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className="medication-plan-report-header_container_qrcode"
    >
      <img src={qrCode} />
    </Flex>
  );
};

const AllergiesComponent = ({
  allergiesString,
}: {
  allergiesString?: string;
}) => {
  const { t } = useTranslation();
  const allergiesArray = allergiesString ? allergiesString.split(',') : [];
  const hasMore = allergiesArray.length > 2;
  const allergies = allergiesArray.slice(0, 2);
  return (
    <Box className="medication-plan-report-header_container_table_col_spaced-col">
      {t('medicationPdfReport.printout.allergies')}:
      <Box>
        {allergies.map((allergy, index) => (
          <Box key={index}>
            {allergy}
            {index < allergies.length - 1 && ','}
            {index === allergies.length - 1 && hasMore && '...'}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const HeaderComponent = ({
  metaData,
  currentPageNumber,
}: {
  metaData: MedicationPlanMetaData;
  currentPageNumber: number;
}) => {
  const { t } = useTranslation();
  const {
    printOut: { name, street, zipCode, city, email, telephone, date },
    bmp,
    patient,
    parameters,
  } = metaData;

  return (
    <Flex
      className={classNames(
        'medication-plan-report-header_container_table',
        'medication-plan-report-header_container_table_row'
      )}
    >
      <Flex
        flexDirection="column"
        className="medication-plan-report-header_container_table_col"
      >
        <Box className="medication-plan-report-header_container_table_title">
          {t('medicationPdfReport.medicationPlan')}
        </Box>
        <Box>
          {t('medicationPdfReport.printout.page')} {currentPageNumber}{' '}
          {t('medicationPdfReport.printout.of')} {bmp.totalPagesCount}
        </Box>
      </Flex>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        className="medication-plan-report-header_container_table_col medication-plan-report-header_container_table_col_full"
      >
        <Flex flexDirection="column">
          <Box>
            {t('medicationPdfReport.printout.for')}: {patient.firstname}{' '}
            {patient.surname}
          </Box>
          <Box>
            {t('medicationPdfReport.printout.printedBy')}: {name}
          </Box>
          <Box>
            {street && street} {street && (zipCode || city) ? ', ' : ''}{' '}
            {zipCode && zipCode} {city && city}
          </Box>
          {telephone && (
            <Box>
              {t('medicationPdfReport.printout.telephone')}: {telephone}
            </Box>
          )}
          {email && (
            <Box>
              {t('medicationPdfReport.printout.email')}: {email}
            </Box>
          )}
        </Flex>
        <Flex flexDirection="column" alignItems="flex-end">
          {patient.birthdate && (
            <Box className="medication-plan-report-header_container_table_col_spaced-col">
              {t('medicationPdfReport.printout.bornOn')}:{' '}
              <b>{patient.birthdate}</b>
            </Box>
          )}
          {parameters.weight && (
            <Box>
              {t('medicationPdfReport.printout.weight')}: {parameters.weight}
            </Box>
          )}
          {parameters.height && (
            <Box>
              {t('medicationPdfReport.printout.height')}: {parameters.height}
            </Box>
          )}
          {patient.gender && (
            <Box className="medication-plan-report-header_container_table_col_spaced-col">
              {t('medicationPdfReport.printout.gender')}: {patient.gender}
            </Box>
          )}
          {parameters.allergiesIntolerances && (
            <AllergiesComponent
              allergiesString={parameters.allergiesIntolerances}
            />
          )}
          <Box>
            {t('medicationPdfReport.printout.printedOut')}:<Box>{date}</Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

const MedicationPlanReportPageHeader = ({
  metaData,
  qrCode,
  currentPageNumber,
}: {
  qrCode: MedicationPlanPage['qrCode'];
  metaData: MedicationPlanMetaData;
  currentPageNumber: number;
}): JSX.Element => {
  return (
    <Box className="medication-plan-report-header">
      <Flex
        flexDirection="row"
        className="medication-plan-report-header_container"
      >
        <HeaderComponent
          metaData={metaData}
          currentPageNumber={currentPageNumber}
        />
        <QRCodeComponent qrCode={qrCode} />
      </Flex>
    </Box>
  );
};

export default MedicationPlanReportPageHeader;
