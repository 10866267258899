import React from 'react';
import PropTypes from 'prop-types';
import Flex from '../Flex/Flex';
import Tab from './Tab';
import '../../styles/main.scss';

const Tabs = ({
  additionalElem,
  children,
  activeTab,
  onClickTabItem,
  showVerticalScrollbar,
}) => {
  return (
    <Flex className="tabs" flexDirection="column">
      <Flex flexDirection="row" className="tabs__header">
        <ol className="tab-list" role="tablist">
          {children.map(child => {
            if (child && child.props) {
              return (
                <Tab
                  key={child.props.label}
                  activeTab={activeTab}
                  label={child.props.label}
                  onClick={onClickTabItem}
                  tabId={child.props.tabId}
                  hidden={child.props.hidden}
                />
              );
            }
          })}
        </ol>
        {additionalElem}
      </Flex>
      <div
        className={showVerticalScrollbar ? 'tab-content' : null}
        role="tabpanel"
      >
        {children.map(child => {
          if (child && child.props) {
            if (child.props.tabId !== activeTab) return undefined;
            return child.props.children;
          }
        })}
      </div>
    </Flex>
  );
};

Tabs.propTypes = {
  additionalElem: PropTypes.element,
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onClickTabItem: PropTypes.func,
  showVerticalScrollbar: PropTypes.bool,
};

Tabs.defaultProps = {
  showVerticalScrollbar: false,
};

export default Tabs;
