import axios from 'axios';
import serviceConfig from '../../serviceConfig.json';
import { ApiClient } from '../Global/Services';

function checkUserCredential(component, action) {
  return ApiClient.GET({
    url: serviceConfig.brokerService.checkUserCredential.uri,
    payload: {
      component,
      action,
    },
  })
    .then(response => {
      if (response?.data.hasCredential) return response.data.hasCredential;
      return false;
    })
    .catch(error => {
      return {
        type: error.response.data.type,
        content: error.response.data.content,
      };
    });
}

export default checkUserCredential;
