/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DatePill from './DatePill';
import Bubble from './Bubble';

import bubbleType from '../../../Utils/Chat/bubbleType';
import i18next from 'i18next';

const MessageListSection = ({
  listOfMessages,
  numberOfMessages,
  patientInfo,
  fileRef,
  date,
  isPatientUsingNewVersion
}) => {
  const patientName = `${patientInfo.firstname} ${patientInfo.lastname}`;
  let messageId;
  const renderFileBubble = (
    message,
    users,
    isPatientInitiated,
    bubbleTimestamp,
    id,
    timestamp,
    asset,
    directLink,
    thumbnailURL,
    isFileData
  ) => {
    if (
      !isPatientInitiated &&
      users.length > 0 &&
      (!isPatientUsingNewVersion || asset || directLink)
    ) {
      const careTeamUsername = `${users[0].firstname} ${users[0].lastname}`;
      return (
        <React.Fragment key={`userBubble_${id}`}>
          <Bubble
            key={`userBubble_${id}`}
            message={message}
            name={careTeamUsername}
            time={bubbleTimestamp}
            fileRef={fileRef}
            messageTimestamp={timestamp}
            variant={bubbleType.USER}
            directLink={(asset && asset.directLink) || directLink}
            thumbnail={(asset && asset.thumbnailURL) || thumbnailURL}
            isFileData={isFileData}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={`patientBubble_${id}`}>
        <Bubble
          key={`patientBubble_${id}`}
          message={message}
          name={patientName}
          time={bubbleTimestamp}
          fileRef={fileRef}
          messageTimestamp={timestamp}
          variant={bubbleType.PATIENT}
          directLink={(asset && asset.directLink) || directLink}
          thumbnail={(asset && asset.thumbnailURL) || thumbnailURL}
          isFileData={isFileData}
        />
      </React.Fragment>
    );
  };

  const renderDatePillContent = useCallback(() => {
    return listOfMessages.map(singleMessage => {
      const {
        id,
        isPatientInitiated,
        timestamp,
        users,
        asset,
        assetDirectLink,
        thumbnailURL,
        isFileData,
      } = singleMessage;
      let { message } = singleMessage;

      const processTimeString = (time = '') =>
        time.includes(',')
          ? `${i18next.t(`chatMessages.${time.split(',')[0]}`)}, ${i18next.t(
            `chatMessages.${time.split(',')[1]}`
          )}`
          : i18next.t(`chatMessages.${time}`);
      message = singleMessage.isTranslatable
        ? i18next.t(message.split('key:')[1].split(' file:')[0], {
          user: message.split('user:')[1].split(' key:')[0],
          file: message.split('file:')[1].split(' time:')[0],
          time: processTimeString(message.split('time:')[1]),
        })
        : message;
      messageId = id;
      const bubbleTimestamp = moment(timestamp).format('HH:mm');

      if (singleMessage.isFileData) {
        return renderFileBubble(
          message,
          users,
          isPatientInitiated,
          bubbleTimestamp,
          id,
          timestamp,
          asset,
          assetDirectLink,
          thumbnailURL,
          isFileData
        );
      }
      if (!isPatientInitiated && users.length > 0) {
        const careTeamUsername = `${users[0].firstname} ${users[0].lastname}`;
        return (
          <React.Fragment key={`userBubble_${id}`}>
            <Bubble
              key={`userBubble_${id}`}
              message={message}
              name={careTeamUsername}
              time={bubbleTimestamp}
              variant={bubbleType.USER}
            />
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={`patientBubble_${id}`}>
          <Bubble
            key={`patientBubble_${id}`}
            message={message}
            name={patientName}
            time={bubbleTimestamp}
            variant={bubbleType.PATIENT}
          />
        </React.Fragment>
      );
    });
  }, [listOfMessages, numberOfMessages]);

  return (
    <React.Fragment key={messageId}>
      <DatePill key={messageId} date={date} />
      {renderDatePillContent()}
    </React.Fragment>
  );
};

MessageListSection.propTypes = {
  listOfMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
  numberOfMessages: PropTypes.number.isRequired,
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ])
  ).isRequired,
  fileRef: PropTypes.objectOf(PropTypes.object).isRequired,
  date: PropTypes.string.isRequired,
};

export default React.memo(MessageListSection);
