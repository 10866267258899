/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

const BubbleVideo = ({ blobURL }) => {
  return (
    <video
      playsinline
      controls="true"
      width="100%"
      height="100%"
      className="img-obj__fit"
      frameBorder="0"
      src={blobURL}
      type="video/mp4"
    >
      <track kind="captions" />
    </video>
  );
};

BubbleVideo.propTypes = {
  blobURL: PropTypes.string.isRequired,
};

export default React.memo(BubbleVideo);
