import React from 'react';

import { Flex, Box, Icon } from '@ui-common-files/components';

import { Greys } from '@ui-common-files/utils';
import { TopBarIconProps } from './TopBarIcon.type';
import useTopBarIconService from './TopBarIcon.service';
import { ReportNotificationIcon } from './Icons';

import styles from './TopBarIcon.module.scss';

export default function TopBarIcon({
  onClick,
  name,
  size = 24,
  fill = Greys.LIGHT_GREY,
  variant,
  count,
}: TopBarIconProps) {
  const { buttonClass, countNumber } = useTopBarIconService({ variant, count });
  const renderIcon = () => {
    if (name === 'Reports') {
      return <ReportNotificationIcon />;
    }
    return <Icon name={name} size={size} fill={fill} />;
  };
  return (
    <Flex className={styles.button}>
      <Box className={styles.button__icon} onClick={onClick}>
        {renderIcon()}
      </Box>
      {countNumber && <Box className={buttonClass}>{countNumber}</Box>}
    </Flex>
  );
}
