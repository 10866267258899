import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../Box/Box';
import Flex from '../../Flex/Flex';
import Label from '../../Label/Label';
import ToggleButton from '../../ToggleButton/ToggleButton';
import Position from '../../../utils/positions';

import { Greys } from '../../../utils/colors';

const ComponentWithLabel = ({
  id,
  htmlFor,
  labelText,
  hasIcon,
  icon,
  fill,
  tooltipText,
  tooltipPosition,
  hasToggleButton,
  idToggleButton,
  valueToggleButton,
  isDisabledToggleButton,
  checked,
  handleOnChangeToggle,
  children,
  labelDescription
}) => {
  return (
    <>
      <Box padding="10px 20px">
        <Box margin="0 0 10px">
          <Flex justifyContent="space-between">
            <Label
              id={id}
              htmlFor={htmlFor}
              text={labelText}
              hasIcon={hasIcon}
              icon={icon}
              fill={fill}
              tooltip={tooltipText}
              tooltipPosition={tooltipPosition}
              labelDescription={labelDescription}
            />
            {hasToggleButton ? (
              <ToggleButton
                id={idToggleButton}
                value={valueToggleButton}
                isDisabled={isDisabledToggleButton}
                checked={checked}
                handleOnChange={handleOnChangeToggle}
              />
            ) : null}
          </Flex>
        </Box>
        {children}
      </Box>
    </>
  );
};

ComponentWithLabel.propTypes = {
  id: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  hasIcon: PropTypes.bool,
  icon: PropTypes.string,
  fill: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.oneOf([
    Position.top,
    Position.bottom,
    Position.right,
    Position.left,
  ]),
  hasToggleButton: PropTypes.bool,
  idToggleButton: PropTypes.string,
  valueToggleButton: PropTypes.string,
  isDisabledToggleButton: PropTypes.bool,
  checked: PropTypes.bool,
  handleOnChangeToggle: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ComponentWithLabel.defaultProps = {
  id: null,
  labelText: '',
  hasIcon: false,
  icon: 'info',
  fill: Greys.DARK_GREY,
  tooltipText: '',
  tooltipPosition: Position.bottom,
  hasToggleButton: false,
  idToggleButton: '',
  valueToggleButton: '',
  isDisabledToggleButton: false,
  checked: false,
};

export default ComponentWithLabel;
