import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import moment from 'moment';
import Loader from 'react-loader-spinner';

import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import Historial from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialLayout';
import HistorialStep from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialStep';
import CareTaskSequence from '../../../caro-ui-commonfiles/components/CareTaskSequence/CareTaskSequence';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';
import { getAction, getComponent } from '../../Utils/History';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import { ShowCaretaskData } from '../Careplans/CaretaskData';

import { ApiClient } from '../../Global/Services';
import serviceConfig from '../../../serviceConfig.json';

import { isMainQuestion } from '../Careplans/CareplanHelperUtility';
import { PrimaryColor, Greys } from '../../../caro-ui-commonfiles/utils/colors';
import {
  getDurationTextForOverview,
  sortCarepathwayCareplanByStartingTime,
} from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import {
  renderAdditionalComponents,
  renderComponents,
} from '../Careplans/CareplanAdditionalComponentUtils';

import '../../css/careplans.scss';

export default function ShowCarePathwayView({
  id,
}: {
  id: number;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [carePathwayDetails, setCarePathwayDetails] = useState(null);

  useEffect(() => {
    ApiClient.GET({
      url: serviceConfig.brokerService.getCarePathwayDetails.uri,
      payload: { carePathwayId: id },
    })
      .then(response => setCarePathwayDetails(response?.data))
      .finally(() => setIsLoading(false));
  }, []);

  const renderCareplanTemplateHistory = () => {
    return (
      carePathwayDetails &&
      carePathwayDetails.carePathwayLogs.map(log => (
        <Historial>
          <HistorialStep key="showTemplateHistorial">
            <div className="show-template-history">
              <span>
                {I18n.t('history.assignment', {
                  user: log.user.username,
                  action: getAction(log.action).toLowerCase(),
                  component: getComponent(log.component),
                  interpolation: { escapeValue: false },
                })}
              </span>
              <span className="show-template__history-date">
                {`${moment(log.timeOfAction)
                  .locale(I18n.language)
                  .format('DD MMM YYYY')} - ${moment(log.timeOfAction).format(
                  'HH:mm'
                )}`}
              </span>
            </div>
          </HistorialStep>
        </Historial>
      ))
    );
  };

  const renderCareplansDuration = carePathwayTemplate => {
    const sortedCarePathwayCareplan = carePathwayTemplate.careplans.sort(
      sortCarepathwayCareplanByStartingTime
    );

    return (
      <Flex flexDirection="column" otherStyles={{ width: '100%' }}>
        {sortedCarePathwayCareplan
          .filter(
            careplan => careplan.carePathwayCareplanAppointmentType.isRoot
          )
          .map(careplan => {
            const { startText, occurrence, endText } =
              getDurationTextForOverview(
                careplan.carePathwayCareplanAppointmentType,
                carePathwayTemplate.carePathwayConnectedAppointment.label
              );
            const caretask = careplan.carePathwayCareplanAppointmentType;
            return (
              <ShowCaretaskData
                caretaskName={careplan.name}
                caretask={caretask}
                allCareplans={sortedCarePathwayCareplan}
                startText={startText}
                occurrence={occurrence}
                endText={endText}
              />
            );
          })}
      </Flex>
    );
  };

  const CarePathwayTemplateInfo = carePathwayTemplate => {
    const nbOfRootCaretasks = carePathwayTemplate.careplans.filter(
      careplan => careplan.carePathwayCareplanAppointmentType.isRoot
    ).length;

    return (
      <div className="carepathway_modal-layout-for-carePathway-template">
        <ComponentWithLabel
          id="pathwayName"
          htmlFor="pathwayName"
          labelText={I18n.t('newCareplan_view.carePathway')}
        >
          {carePathwayTemplate.name}
        </ComponentWithLabel>
        <ComponentWithLabel
          id="pathwayCareplans"
          htmlFor="pathwayCareplans"
          labelText={I18n.t('nav.caretasks')}
        >
          {nbOfRootCaretasks}
        </ComponentWithLabel>
        <ComponentWithLabel
          id="pathway"
          htmlFor="pathway"
          labelText={I18n.t(
            'newCareplan_view.view_carePathwayTemplateSequence'
          )}
        >
          {renderCareplansDuration(carePathwayTemplate)}
        </ComponentWithLabel>
      </div>
    );
  };

  const checkCarePathwayHaveThresholdsOrNot =
    careplanComponentsWithThresholds => {
      if (careplanComponentsWithThresholds.length > 0) {
        for (let i = 0; i < careplanComponentsWithThresholds.length; i++) {
          if (careplanComponentsWithThresholds[i][1].length > 0) {
            return true;
          }
        }
      }
      return false;
    };

  const checkCarePathwaysHaveLogicJumpsOrNot =
    careplanTemplateWithLogicJumps => {
      if (Object.keys(careplanTemplateWithLogicJumps).length > 0) {
        return true;
      }
      return false;
    };

  const renderCareplanComponentsWithThreshold = () => {
    if (carePathwayDetails && carePathwayDetails.carePathwayList.length > 0) {
      return carePathwayDetails.carePathwayList.map(careplans => {
        return careplans
          .sort((a, b) => a.qc.name.localeCompare(b.qc.name))
          .map(careplan => {
            const {
              questionsWithThresholds,
              careplanLogicJumps,
              isEvaluating,
            } = careplan;
            const { scoringScheme } = careplan.qc;
            const isThresholds = checkCarePathwayHaveThresholdsOrNot(
              questionsWithThresholds
            );
            const isLogicJumps =
              checkCarePathwaysHaveLogicJumpsOrNot(careplanLogicJumps);
            let hasScores = false;

            if (
              isEvaluating &&
              scoringScheme != null &&
              scoringScheme.length > 0
            ) {
              for (let i = 0; i < scoringScheme.length; i++) {
                if (scoringScheme[i].max > 0) {
                  hasScores = true;
                }
              }
            }

            return (
              <>
                {(isThresholds || isLogicJumps || hasScores) && (
                  <>
                    <Flex
                      otherStyles={{
                        margin: '20px 0px',
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      {careplan.qc.name}
                    </Flex>
                    {renderComponents(
                      questionsWithThresholds.filter(component =>
                        isMainQuestion(component[0].healthCategory)
                      ),
                      careplanLogicJumps,
                      isEvaluating,
                      true
                    )}
                    {isEvaluating &&
                      renderAdditionalComponents(
                        scoringScheme,
                        questionsWithThresholds,
                        careplanLogicJumps,
                        isEvaluating
                      )}
                  </>
                )}
              </>
            );
          });
      });
    }
  };

  return (
    <ModalContent>
      {!isLoading && carePathwayDetails && (
        <ModalForm>
          <ModalSection variant={MODAL_VARIANT.COL_W30}>
            <div className="history-label">
              <Label text={I18n.t('common_labels.history')} />
            </div>
            <div className="show-template__historial-wrapper">
              {carePathwayDetails &&
                carePathwayDetails.carePathwayLogs &&
                renderCareplanTemplateHistory()}
            </div>
          </ModalSection>
          <ModalSection variant={MODAL_VARIANT.COL_W70}>
            <Box margin="0 0 0 -20px">
              {carePathwayDetails &&
                CarePathwayTemplateInfo(carePathwayDetails.carePathwayDetails)}
            </Box>
            <div>
              {carePathwayDetails && renderCareplanComponentsWithThreshold()}
            </div>
          </ModalSection>
        </ModalForm>
      )}
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{
            width: '100%',
            height: '100%',
            position: 'inherit',
          }}
        >
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Flex>
      )}
    </ModalContent>
  );
}
