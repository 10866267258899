import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import { WYSIWYGEditor, Flex, IconButton, IconButtonDropdown } from '@ui-common-files/components';
import { sanitizeInput, Greys } from '@ui-common-files/utils';

import { PatientOverviewContext } from '../../PatientOverview/PatientOverview';
import FileModal from '../FileModal';
import serviceConfig from '../../../../serviceConfig.json';
import { ApiClient } from '@global/Services';
import { useUserPermission } from '@utils';
import { MentionListCall } from '@ui-common-files/components/WYSIWYGEditor/WYSIWYGEditor.type';

const SendMessageForm = ({
  sendNote,
  handleFileUpload,
  fileName,
  handleAttachment,
}) => {
  const imageUploader = useRef(null);
  const hasAssetSharePermission = useUserPermission(
    I18n.t('types.componentType.assets'),
    I18n.t('actions.share'),
    true
  );

  const hasAssetCreatePermission = useUserPermission(
    I18n.t('types.componentType.assets'),
    I18n.t('actions.create'),
    true
  );

  const [message, setMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const chatFooter = useRef({ current: null });

  const setOpen = val => {
    setOpenModal(val);
  };

  const { setFlashMessage, patientInfo } = useContext(PatientOverviewContext);

  useEffect(() => {
    setMessage(fileName);
  }, [fileName]);

  const handleChange = (newValue) => {
    if (sanitizeInput(newValue).length <= 500) {
      setMessage(newValue);
    }
  };

  const handleKeyPress = e => {
    const isEnterKey = e.which === 13;
    if (sanitizeInput(message).length >= 500) {
      e.preventDefault();
    }
    if (isEnterKey && !isInputEmpty && message.length <= 500) {
      handleSubmit(e);
    }
  };

  const handleAttachmentButtonClick = (e, action) => {
    if (action.value === I18n.t('asset_view.uploadedFiles')) {
      if (hasAssetSharePermission) {
        setOpen(true);
      } else {
        setFlashMessage({
          content: I18n.t('common_labels.permissionDenied'),
          type: 'warning',
        });
      }
    } else if (hasAssetCreatePermission) {
      imageUploader.current.click();
    } else {
      setFlashMessage({
        content: I18n.t('common_labels.permissionDenied'),
        type: 'warning',
      });
    }
  };

  const isInputEmpty = message.trim() === '';
  const handleSubmit = e => {
    e.preventDefault();
    if (!isInputEmpty && sanitizeInput(message).length <= 500) {
      if (fileName.length) {
        handleFileUpload();
      } else {
        sendNote(sanitizeInput(message, true), fileName.length > 0);
      }
    }
    if (sanitizeInput(message).length <= 500) {
      setMessage('');
    }
  };

  const clearInputValueOnClick = e => {
    e.target.value = '';
  };

  const getUsersAndRoles: MentionListCall = async (
    query,
    signal,
  ) => {
    return await ApiClient.GET<TResponse, { query: string, patientId: number }>({
      url: serviceConfig.brokerService.getUsersAndRolesForTag.uri,
      payload: {
        query,
        patientId: patientInfo.id,
      },
      signal,
    });
  };

  const handleOnchange = e => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();

      reader.onload = ev => {
        handleAttachment({ data: ev.target.result, file });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="chat__input-box" ref={chatFooter}>
        <form className="chat__form" onSubmit={handleSubmit} style={{ padding: "3px" }}>

          <WYSIWYGEditor
            onChange={handleChange}
            value={message}
            hasMention={true}
            placeholder={I18n.t('notes.textarea_placeholder')}
            mentionListCall={getUsersAndRoles}
            hideToolbar={true}
            hideWordCount={true}
            className={'mention_editor'}
            textBoxClassName={'mention-textBox'}
          />
          <div className="chat__input__counter" style={{ padding: "20px" }}>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              otherStyles={{ flex: 1 }}
            >
              <input
                type="file"
                id="fileUpload"
                onChange={handleOnchange}
                onClick={clearInputValueOnClick}
                ref={imageUploader}
                hidden
              />
              <IconButtonDropdown
                iconName="attachment"
                onClick={handleAttachmentButtonClick}
                items={[
                  { value: I18n.t('asset_view.uploadedFiles') },
                  { value: I18n.t('asset_view.browsePc') },
                ]}
                isAttachAsset
                variant="chat__file__dropdown"
              />
              <div style={{ marginBottom: '20px', marginLeft: '16px' }}>
                <IconButton
                  type="submit"
                  name="addNote"
                  size={17}
                  disabled={isInputEmpty}
                  fill={Greys.WHITE}
                  tooltipText={I18n.t('common_buttons.save')}
                />
              </div>
            </Flex>
            <span className="icd-select__input__counter">
              {`${sanitizeInput(message).length}/500`}
            </span>
          </div>
        </form>
      </div>
      <FileModal openModal={openModal} setOpenModal={setOpen} />
    </>
  );
};

SendMessageForm.propTypes = {
  sendNote: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  handleAttachment: PropTypes.func.isRequired,
};

export default SendMessageForm;
