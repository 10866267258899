/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../styles/main.scss';
import PropTypes from 'prop-types';
import DropdownMenu from './DropdownMenu';
import Icon from '../Icon/Icon';
import { Greys, PrimaryColor } from '../../utils/colors';
import Flex from '../Flex/Flex';
import I18n from 'i18next';

const FilterDropdown = ({ iconName, iconColor, onClick, items, active }) => {
  const [current, setCurrent] = useState(active && active >= 0 ? active : 0);

  const buildItems = () => {
    return items.map((item, index) => {
      return (
        <li
          accessKey={item.key}
          key={
            item.filterId !== 1
              ? item.value
              : I18n.t('customFilters_view.allPatients')
          }
          onClick={e => handleChange(e, index, item)}
        >
          <span className="filter-container__item">
            {item.filterId !== 1
              ? item.value
              : I18n.t('customFilters_view.allPatients')}
          </span>
          {index === current ? (
            <Icon
              name="multipleChoices"
              size={24}
              fill={PrimaryColor.MAIN_COLOR}
            />
          ) : null}
        </li>
      );
    });
  };

  useEffect(() => {
    active && active >= 0 && setCurrent(active);
  }, [active]);

  const handleChange = (e, index, item) => {
    setCurrent(index);
    onClick(e, item, index);
  };

  return (
    <div className="filter-container">
      <DropdownMenu items={buildItems()} variant="filter-container__options">
        <Flex otherStyles={{ cursor: 'pointer' }}>
          <Flex justifyContent="center" otherStyles={{ marginRight: 5 }}>
            <Icon name={iconName} size={30} fill={iconColor} />
          </Flex>
          <span className="filter-container__header">
            {items[current].filterId !== 1
              ? items[current].value
              : I18n.t('customFilters_view.allPatients')}
          </span>
        </Flex>
      </DropdownMenu>
    </div>
  );
};

FilterDropdown.propTypes = {
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  active: PropTypes.number,
};
FilterDropdown.defaultProps = {
  iconName: 'category',
  iconColor: Greys.DARK_GREY,
};

export default FilterDropdown;
