import { Box, Flex } from '@ui-common-files/components';
import { Greys } from '@ui-common-files/utils';
import React from 'react';

function RankingTitleComponent({ title }) {
    return (
        <Flex>
            <Box
                otherStyles={{
                    color: Greys.LIGHT_GREY,
                    margin: "10px 0px 10px 0px",
                    fontWeight: 600,
                }}
            >
                {title}
            </Box>
        </Flex>
    );
}

export default RankingTitleComponent;
