/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, createContext } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';

import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import  { ButtonType } from '../../../caro-ui-commonfiles/utils/button';

import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import FilterLayout from './FiltersLayoutModal';

const FiltersModalContext = createContext(null);

const FiltersModalContent = ({
  types,
  statuses,
  getFilters,
  currentFilters,
}) => {
  const [statusesCheckList, setStatusesCheckList] = useState({});
  const [typesCheckList, setTypesCheckList] = useState({});
  const hideAppointmentStatuses = true;
  useEffect(() => {
    if (types) {
      const typesObject = { ...typesCheckList };
      types.map(type => {
        typesObject[type.id] = currentFilters.types.some(
          filter => parseInt(filter) === type.id
        );
      });
      setTypesCheckList(typesObject);
    }
  }, [types]);

  useEffect(() => {
    if (statuses) {
      const statusesObject = { ...statusesCheckList };
      statuses.map(status => {
        statusesObject[status.id] = true;
      });
      setStatusesCheckList(statusesObject);
    }
  }, [statuses]);

  useEffect(() => {
    const typesIds = [];
    const statusesIds = [];
    for (var key in typesCheckList) {
      if (typesCheckList[key]) {
        typesIds.push(key);
      }
    }
    for (var key in statusesCheckList) {
      if (statusesCheckList[key]) {
        statusesIds.push(key);
      }
    }

    getFilters({
      statusesIds,
      typesIds,
    });
  }, [typesCheckList]);
  return (
    <FiltersModalContext.Provider
      value={{
        statusesCheckList,
        setStatusesCheckList,
        typesCheckList,
        setTypesCheckList,
      }}
    >
      <ModalContent>
        <div>
          <FilterLayout
            filters={types}
            label={I18n.t('appointment.filterByType')}
            type="types"
          />
          {!hideAppointmentStatuses && (
            <div className="ver-col2">
              <FilterLayout
                filters={statuses}
                label={I18n.t('appointment.filterByStatus')}
                type="statuses"
              />
            </div>
          )}
        </div>
      </ModalContent>
    </FiltersModalContext.Provider>
  );
};

FiltersModalContent.propTypes = {
  types: PropTypes.arrayOf(PropTypes.object).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFilters: PropTypes.func.isRequired,
};

const FiltersModalFooter = ({ close, onApply }) => (
  <Flex
    justifyContent="flex-end"
    otherStyles={{
      flex: 1,
    }}
  >
    <Box margin="0 16px 0 0">
      <Button
        label={I18n.t('common_buttons.cancel')}
        type={ButtonType.BUTTON}
        onClick={close}
        variant="outlined"
      />
    </Box>
    <Button
      label={I18n.t('common_buttons.apply')}
      type={ButtonType.BUTTON}
      onClick={onApply}
      variant="contained"
    />
  </Flex>
);

FiltersModalFooter.propTypes = {
  close: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

export { FiltersModalContent, FiltersModalFooter, FiltersModalContext };
