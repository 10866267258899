import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useContext } from 'react';
import { FirebaseContext } from '../../../Utils/Firebase/firebase';

const useAnalytics = () => {
  const { trackEvent, trackPageView, pushInstruction } = useMatomo();
  const userFirebaseContext = useContext(FirebaseContext);

  const setUserInfo = (currentUser: string) => {
      pushInstruction('setUserId', currentUser);
  };

  const trackEventTrigger = (params: { category: string; action: string }) => {
    if (userFirebaseContext?.userPinAndOptinsInfo?.isConsentForTracking) {
      trackEvent({ category: params.category, action: params.action });
    }
  };

  const trackPageViewTrigger = () => {
    if (userFirebaseContext?.userPinAndOptinsInfo?.isConsentForTracking) {
        trackPageView();
      }
  };

  return { setUserInfo, trackEventTrigger, trackPageViewTrigger };
};

export default useAnalytics;
