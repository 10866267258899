import { AnsweringComponentsProps } from '../AnsweringTypes';

export interface AnsweringYesNoSectionProps extends AnsweringComponentsProps {
  YesNo: boolean;
}

export enum YesNoOptions {
  NotSpecified = -1,
  No = 0,
  Yes = 1,
}
