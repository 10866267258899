import React from 'react';
import i18next from 'i18next';
import FilterCriteria from '../FilterCriteria/FilterCriteria';
import FilterCriteriaComponentAnswer from '../FilterCriteriaComponentAnswer/FilterCriteriaComponentAnswer';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import Icon from '../../../../../../caro-ui-commonfiles/components/Icon/Icon';
import { colors } from '../../../../../../caro-ui-commonfiles/utils/dropdownSelectStyles';
import Button from '../../../../../../caro-ui-commonfiles/components/Button/Button';
import { ButtonVariant } from '../../../../../../caro-ui-commonfiles/utils/button';
import { FilterActions } from '../../../../../Utils/PatientFilter/PatientFilter.enum';
import { FilterCriteriaListProps } from './FilterCriteriaList.type';
import { FieldTypes } from '../../PatientFilterContext/PatientFilter.type';

const FilterCriteriaList = ({ type }: FilterCriteriaListProps) => {
  const { criteriaList, updateCriterias } = usePatientFilterContext();
  const addNewCriteria = () => {
    updateCriterias({ action: FilterActions.Push });
  };
  const isComponentAnswer = (fieldType: FieldTypes) =>
    fieldType === FieldTypes.ComponentAnswer;
  return (
    <div>
      <div className="filters__criteria-container">
        {criteriaList[type].map((criteria, position) =>
          isComponentAnswer(criteria.field.type) ? (
            <FilterCriteriaComponentAnswer
              key={position}
              criteria={{ ...criteria, position }}
              type={type}
            />
          ) : (
            <FilterCriteria
              key={position}
              criteria={{ ...criteria, position }}
              type={type}
            />
          )
        )}
      </div>
      <Button
        label={i18next.t('patients_complexsearch.addFilter')}
        variant={ButtonVariant.NO_BORDER}
        onClick={addNewCriteria}
        disabled={criteriaList[type].length >= 8}
      >
        <Icon name="plus" size={12} fill={colors.mainColor} />
      </Button>
    </div>
  );
};

export default FilterCriteriaList;
