export enum ComplexScoringType {
  OverallScore = 1,
  SectionScore = 2,
}

export type ComplexFormula = {
  id: string;
  scoringTypeId: ComplexScoringType;
  equation: string;
  equationHasError: boolean;
  equationErrorMessage: string;
  description?: string;
  descriptionHasError?: boolean;
  descriptionErrorMessage?: string;
};
