import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { CaretaskComponent } from '@type/Caretask';

import { selectCurrentComponent } from '../../../CaretaskAnswering.reducer';
import ASSET_TYPES from '../../../../../../../Utils/Asset/assetTypes';
import {
  isPreviewInViewer,
  getAssetTypeId,
} from '../../../../../../../Utils/Asset/assetHelper';

export default function useCaretaskAnsweringContentAttachmentService() {
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );

  const { mediaURL } = JSON.parse(currentComponent.additional_configuration);

  const extension = currentComponent?.asset
    ? currentComponent.asset.extension.toUpperCase()
    : mediaURL.substr(mediaURL.lastIndexOf('.') + 1).toUpperCase();
  const isPreviewable = isPreviewInViewer(extension);
  const assetTypeId = getAssetTypeId(extension);

  const isImage = assetTypeId === ASSET_TYPES.IMAGE;
  const containerCSSClasses = classNames({
    'answer-view__caretask-components-image__preview': true,
    image: isImage,
    'no-image': !isImage,
  });

  return {
    mediaURL,
    assetTypeId,
    isPreviewable,
    containerCSSClasses,
  };
}
