/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import I18n from 'i18next';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import '../../css/careplans.scss';
import AssignTemplateStep2 from './AssignTemplateStep2';
import AssignTemplateStep3 from './AssignTemplateStep3';
import AssignTemplateStep4 from './AssignTemplateStep4';
import useCareplanModalHeaderFooter from '../../Utils/hooks/useCareplanModalHeaderFooter';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import serviceConfig from '../../../serviceConfig.json';
import ShowCareplanRequest from '../../Utils/ShowCareplanRequest';
import {
  ComponentTypes,
  Actions,
  SubmissionTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import IsEmptyObject from '../../../caro-ui-commonfiles/global/IsEmptyObject/IsEmptyObject.ts';
import useAllTodoAssessments from '../Notifications/Notification.hooks';

const EditCareplanModalContent = ({
  careplan,
  data,
  isComingFromCarePathway = false,
  setItemIdToUpdate,
  setIsConfigurationsLoading,
}) => {
  const {
    lifespanUnits,
    rruleFreqToRepeatTypeIndex,
    generateWeekdayArray,
  } = require('../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils');
  const {
    currentStep,
    setCurrentStep,
    shouldValidateForm,
    dispatchFormValidator,
    shouldSubmitForm,
    dispatchFormSubmittor,
    setFlashMessage,
    closeModal,
    setLandingTab,
    setCareplanHeaderFooter,
    navigateToCareplanAnswerView,
    usWarningCheckboxChecked,
    setUsWarningCheckboxChecked,
    showUnder18Warning,
    setBottomPageReached,
    setIsCaretaskDataLoading,
  } = useContext(PatientOverviewContext);
  const lifespanUnitsObj = lifespanUnits();
  const repeatTypesArray = rruleFreqToRepeatTypeIndex();
  const [careplanData, setCareplanData] = useState({});
  const [careplanDataThresholds, setCareplanDataThresholds] = useState({});
  const [defaultDataThresholds, setDefaultDataThresholds] = useState({});
  const [careplanScoringScheme, setCareplanScoringScheme] = useState([]);
  const timeOfDayRecurrence = careplan.rruleRecurrence.options.byhour;
  const { careplanLogicJumps } = careplan;

  useEffect(() => {
    ShowCareplanRequest(careplan.qc.id, setFlashMessage).then(response => {
      const dataThresholds = response.questionsWithThresholds;
      if (dataThresholds.length > 0) {
        dataThresholds.map(careplanComponent => {
          careplanComponent[0].careplanComponentsTemplateMapper =
            careplanComponent[0].careplanCareplanComponentMapper;
        });
      }
      if (response.qc.scoringScheme) {
        setCareplanScoringScheme(response.qc.scoringScheme);
      } else {
        setCareplanScoringScheme([]);
      }

      setCareplanDataThresholds(dataThresholds);
      setDefaultDataThresholds(dataThresholds);
    });
  }, [careplan]);

  useEffect(() => {
    if (!IsEmptyObject(careplanDataThresholds)) {
      setIsCaretaskDataLoading(false);
    }
  }, [careplanDataThresholds]);

  const recurrenceRule = careplan.rruleRecurrence;
  const selectedPatient = careplan.patient;
  const startTime = careplan.startTime.split(':');
  const [thresholdView, setThresholdView] = useState({
    default: true,
    individual: false,
  });

  useEffect(() => {
    setCareplanData({
      isAssessment: careplan.qc.isAssessment,
      isEvaluating: careplan.qc.isEvaluating,
      lifespan: careplan.lifespan,
      carePathways:
        careplan.selectedCarePathways &&
        careplan.selectedCarePathways.map(speciality => ({
          label: speciality.name,
          value: speciality.id,
        })),
      phaseTypeId: careplan.phaseTypeObject.id,
      phaseType: careplan.phaseType,
      morning: false,
      midday: false,
      evening: false,
      night: false,
      customTime:
        timeOfDayRecurrence[0] > -1
          ? { hour: parseInt(startTime[0]), minute: parseInt(startTime[1]) }
          : { hour: 9, minute: 0 },
      customTimeChecked: timeOfDayRecurrence[0] > -1 || false,
      lifespanUnit: lifespanUnitsObj[careplan.lifespanUnit],

      rruleRecurrence: careplan.rruleRecurrence,
      repeatType: repeatTypesArray[careplan.rruleRecurrence.origOptions.freq],
      repeatInterval: {
        value: careplan.rruleRecurrence.origOptions.interval,
        label: careplan.rruleRecurrence.origOptions.interval,
      },

      occurrenceCount: recurrenceRule.origOptions.count || 1,
      weeklyRecurrence: recurrenceRule.options.byweekday
        ? generateWeekdayArray(recurrenceRule.options.byweekday)
        : new Array(7).fill(false),
      occurrenceCountCheck: !recurrenceRule.origOptions.until,
      startDate: moment(careplan.startsAt).toDate(),
      endDate: moment(careplan.endsAt).toDate(),
      title: careplan.title,
      selectedPatientsData: [
        {
          profilePicture: selectedPatient.profilePicture,
          firstname: selectedPatient.firstname,
          lastname: selectedPatient.lastname,
          birthdate: selectedPatient.birthdate,
        },
      ],
      icds: data.icdRecords,
      nbrComponents: careplan.questiontypes.length,
      isReportEnabled: careplan.isReportEnabled,
      reportOccurenceNumber: careplan.careplan,
      reportLanguage: careplan.reportLanguage,
    });
  }, [careplan]);

  const { getAllTodos, allTodoAssessmentsController } = useAllTodoAssessments();

  useEffect(() => {
    const updateCareplan = async () => {
      closeModal();
      let message = '';
      try {
        let dataObject = careplanData;
        if (careplanData.startNow) {
          const now = new Date();
          dataObject = {
            ...dataObject,
            lifespan: 1,
            selectedLifespanUnit: lifespanUnitsObj[1].label,
            selectedRecurrence: '1',
            occurrenceCount: 1,
            occurrenceCountCheck: true,
            repeatType: repeatTypesArray[3],
            repeatInterval: { value: 1, label: 1 },
            customTime: { hour: now.getHours(), minute: 0 },
            startDate: now,
            startNow: true,
          };
        }
        const response = await axios.post(
          serviceConfig.brokerService.updateCareplan.uri + data.id,
          {
            careplanDataThresholds,
            ...dataObject,
          }
        );
        if (response) {
          message = `${I18n.t(response.data.content)}`;
          setFlashMessage({
            type: response.data.type,
            content: message,
          });
          const { occurrenceId } = response.data;
          if (careplanData.startNow && occurrenceId) {
            navigateToCareplanAnswerView({
              id: data.id,
              isAssessment: careplanData.isAssessment,
              occurrenceId,
            });
          }
          let sendResponse = true;
          if (data.firebaseKey) {
            await axios.get(
              serviceConfig.brokerService.unassignCareplan.uri + data.id
            );
          }
          if (
            shouldSubmitForm.submissionType === SubmissionTypes.ASSIGN_AND_SEND
          ) {
            sendResponse = await axios.get(
              serviceConfig.brokerService.sendCareplan.uri + data.id
            );
          }
          if (sendResponse) {
            const itemIdToUpdate = isComingFromCarePathway
              ? careplanData.carePathways[0].value
              : data.id;

            setItemIdToUpdate && setItemIdToUpdate(itemIdToUpdate);
            setIsConfigurationsLoading && setIsConfigurationsLoading(true);

            if (careplanData.isAssessment) {
              getAllTodos();
            }
            if (sendResponse.data)
              setFlashMessage({
                type: sendResponse.data.type,
                content: `${message} ${I18n.t(sendResponse.data.content)}`,
              });
          }
        }
      } catch (error) {
        setFlashMessage({
          type: error.response.data.type,
          content: `${I18n.t(error.response.data.content)}${message}`,
        });
      }
    };

    if (
      shouldSubmitForm.component === ComponentTypes.CAREPLAN &&
      shouldSubmitForm.action === Actions.Update
    ) {
      updateCareplan();
      return function cleanup() {
        allTodoAssessmentsController.abort();
        dispatchFormSubmittor({
          component: '',
          action: '',
          submissionType: false,
        });
      };
    }
  }, [shouldSubmitForm]);

  useCareplanModalHeaderFooter(careplanData, setCareplanHeaderFooter);

  const checkPatientOver18 = () => {
    if (showUnder18Warning && currentStep === 3) {
      setCurrentStep(currentStep - 1);
      return false;
    }
    if (showUnder18Warning && currentStep === 2) {
      setCurrentStep(currentStep + 1);
      return false;
    }
    return true;
  };

  return (
    <>
      <ModalContent>
        {currentStep === 1 && (
          <AssignTemplateStep2
            careplanData={careplanData}
            setCareplanData={setCareplanData}
            dispatchFormValidator={dispatchFormValidator}
            shouldValidateForm={shouldValidateForm}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            fromPatientOverview
          />
        )}
        {currentStep === 2 && checkPatientOver18() && (
          <AssignTemplateStep3
            careplanData={careplanData}
            careplanScoringScheme={careplanScoringScheme}
            careplanDataThresholds={careplanDataThresholds}
            setCareplanDataThresholds={setCareplanDataThresholds}
            onChange={() => {}}
            isEvaluating={careplanData.isEvaluating}
            thresholdView={thresholdView}
            setThresholdView={setThresholdView}
            setBottomPageReached={setBottomPageReached}
            usWarningCheckboxChecked={usWarningCheckboxChecked}
            setUsWarningCheckboxChecked={setUsWarningCheckboxChecked}
            careplanLogicJumpData={careplanLogicJumps}
            defaultDataThresholds={defaultDataThresholds}
          />
        )}
        {currentStep === 3 && (
          <AssignTemplateStep4
            careplanData={careplanData}
            selectedPatientsData={careplanData.selectedPatientsData}
            careplanNumberOfComponents={careplanDataThresholds.length}
          />
        )}
      </ModalContent>
    </>
  );
};

export default EditCareplanModalContent;
