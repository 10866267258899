import { useCallback } from 'react';

const useCalendarToolbarService = (devicesQuery, setDevicesQuery) => {
  const handleClick = useCallback(
    (selectedView, onView) => {
      setDevicesQuery({
        ...devicesQuery,
        isTodayActive: false,
      });
      onView(selectedView);
    },
    [setDevicesQuery]
  );

  const handleTodayandLastDataChange = (isTodayActive, isLastDataActive) => {
    setDevicesQuery({
      ...devicesQuery,
      isTodayActive,
      isLastDataActive,
    });
  };

  return {
    handleClick,
    handleTodayandLastDataChange,
  };
};

export default useCalendarToolbarService;
