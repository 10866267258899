import React from 'react';
import I18n from 'i18next';
import moment from 'moment';

import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import Historial from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialLayout';
import HistorialStep from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialStep';

import { getAction, getComponent } from '../../Utils/History';
import { ButtonVariant } from '../../../caro-ui-commonfiles/utils/button';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';

import '../../css/careplans.scss';
import { isMainQuestion } from './CareplanHelperUtility';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import {
  renderAdditionalComponents,
  renderComponents,
} from './CareplanAdditionalComponentUtils';

const ViewCareplan = props => {
  const {
    careplanData,
    careplanLogicJumpData,
    isEvaluating,
    ownershipLogs,
    careplanScoringScheme,
  } = props;
  
  const renderCareplanTemplateHistory = () => {
    return ownershipLogs.map(log => (
      <Historial>
        <HistorialStep key="showTemplateHistorial">
          <div className="show-template-history">
            <span>
              {I18n.t('history.assignment', {
                user: log.user != undefined ? log.user.username : log.user,
                action: getAction(log.action).toLowerCase(),
                component: getComponent(log.component),
                interpolation: { escapeValue: false },
              })}
            </span>
            <span className="show-template__history-date">
              {`${moment(log.timeOfAction)
                .locale(I18n.language)
                .format('DD MMM YYYY')} - ${moment(log.timeOfAction).format(
                'HH:mm'
              )}`}
            </span>
          </div>
        </HistorialStep>
      </Historial>
    ));
  };

  return (
    <ModalContent>
      <ModalForm>
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <div className="history-label">
            <Label text={I18n.t('common_labels.history')} />
          </div>
          <div className="show-template__historial-wrapper">
              {ownershipLogs && renderCareplanTemplateHistory()}
          </div>
        </ModalSection>

        <ModalSection variant={MODAL_VARIANT.COL_W70}>
          <h3 className="careplan-preview">
            {I18n.t('newCareplan_view.careplanPreview')}
          </h3>
          <Box padding="20px 0 0">
            {renderComponents(
              careplanData.filter(component =>
                isMainQuestion(component[0].healthCategory)
              ),
              careplanLogicJumpData,
              isEvaluating,
              true
            )}
            {isEvaluating &&
              renderAdditionalComponents(
                careplanScoringScheme,
                careplanData,
                careplanLogicJumpData,
                isEvaluating
              )}
          </Box>
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
};

const ViewCareplanModalFooter = ({ close }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        label={I18n.t('common_buttons.close')}
        type="button"
        onClick={close}
        variant={ButtonVariant.CONTAINED}
      />
    </div>
  );
};

export { ViewCareplan, ViewCareplanModalFooter };
