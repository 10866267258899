import { useMemo } from 'react';
import {
  getConnectedEdges,
  useNodeId,
  useStore,
  ReactFlowState,
} from 'reactflow';

import { getIncomingDepth } from '../../CarePathWayBuilder.utils';
import { MAX_INCOMING_DEPTH } from '../../CarePathWayBuilder.constant';

const selector = (s: ReactFlowState) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const useCareTaskNodeSourceHandleService = (schemaId: string) => {
  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();

  const node = useMemo(
    () => nodeInternals.get(nodeId),
    [nodeId, nodeInternals]
  );

  const incomingDepth = useMemo(
    () => getIncomingDepth(node, Array.from(nodeInternals.values()), edges),
    [nodeInternals, edges, node]
  );

  const isHandleConnectable = useMemo(() => {
    if (incomingDepth >= MAX_INCOMING_DEPTH) return false;

    const connectedEdges = getConnectedEdges([node], edges);
    const hasAnyConnection = connectedEdges.some(
      edge => edge.source === nodeId && edge.sourceHandle === schemaId
    );
    return !hasAnyConnection;
  }, [edges, node, nodeId, schemaId, incomingDepth]);

  return { isHandleConnectable, incomingDepth };
};

export default useCareTaskNodeSourceHandleService;
