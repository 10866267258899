export enum TagsType {
  ROUNDED = 'rounded',
  SQUARED = 'squared',
  ARROW = 'arrow',
}

export enum TagsVariant {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}
