import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "../Box/Box";

const InfiniteScroll = ({
  children,
  onScrollUp,
  onScrollDown,
  loadingComponent,
  loadingTop,
  loadingBottom,
}) => {
  const parentRef = useRef(null);
  const childRef = useRef(null);
  const handleScrollEvent = () => {
    if (
      childRef.current.getBoundingClientRect().top ===
      parentRef.current.getBoundingClientRect().top
    ) {
      onScrollUp();
    }
    if (
      childRef.current.getBoundingClientRect().bottom - 5 <=
      parentRef.current.getBoundingClientRect().bottom
    ) {
      onScrollDown();
    }
  };

  useEffect(() => {
    parentRef.current.addEventListener("scroll", handleScrollEvent);
    return () =>
      parentRef.current && parentRef.current.removeEventListener("scroll", handleScrollEvent);
  });

  return (
    <Box otherStyles={{ height: "100%", overflowY: "auto" }}>
      {loadingTop && (
        <Box className="infinite-scroll__loader--top">
          {loadingComponent()}
        </Box>
      )}
      <div ref={parentRef} className="infinite-scroll__wrapper">
        <div ref={childRef} className="infinite-scroll__infinite-layout">
          {children}
        </div>
      </div>
      {loadingBottom && (
        <Box className="infinite-scroll__loader--bottom">
          {loadingComponent()}
        </Box>
      )}
    </Box>
  );
};
InfiniteScroll.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  onScrollUp: PropTypes.func,
  onScrollDown: PropTypes.func,
  loadingComponent: PropTypes.func,
  loadingTop: PropTypes.bool,
  loadingBottom: PropTypes.bool,
};
InfiniteScroll.defaultProps = {
  onScrollUp: () => { },
  onScrollDown: () => { },
  loadingComponent: () => { },
  loadingTop: false,
  loadingBottom: false,
};
export default InfiniteScroll;
