import {
  Greys,
  HealthIndicatorColors,
  ComponentColors,
} from '@ui-common-files/utils';

const getCarePlanIconColor = data => {
  const { isAnswered, graphData, lastOccurrenceHealthIndicator } = data;
  if (!isAnswered) {
    return Greys.LIGHT_GREY;
  }
  if (lastOccurrenceHealthIndicator) {
    return HealthIndicatorColors[lastOccurrenceHealthIndicator];
  }

  if (graphData?.length) {
    const lastOccurrenceHealthIndicator =
      graphData[graphData.length - 1].occurrenceHealthIndicator;
    const color =
      HealthIndicatorColors[lastOccurrenceHealthIndicator] ||
      ComponentColors.CAREPLAN;
    return color;
  }

  return ComponentColors.CAREPLAN;
};

const getHealthIndicatorColor = occurrenceHealthIndicator =>
  HealthIndicatorColors[occurrenceHealthIndicator];

export { getCarePlanIconColor, getHealthIndicatorColor };
