import React from 'react';

import { Box } from '@ui-common-files/components';

import OverviewDataChart from '../OverviewDataChart';
import OverviewMedicationChart from '../OverviewMedicationChart';
import OverviewDataCardHeader from './OverviewDataCardHeader';

import useOverviewDataCardService from './OverviewDataCard.service';
import './OverviewDataCard.styles.scss';

const OverviewDataCard = () => {
  const { graphData, devicesQuery, setDevicesQuery, isChatOpened, isLoading } =
    useOverviewDataCardService();
  return (
    <Box className="overview-data_card">
      <OverviewDataCardHeader
        selector
        selectorValue={devicesQuery.filter}
        dateRange={devicesQuery.date}
        onSelectorChange={filter => {
          setDevicesQuery({
            ...devicesQuery,
            ...filter,
          });
        }}
        onDateChange={date => {
          setDevicesQuery({
            ...devicesQuery,
            isTodayActive: false,
            isLastDataActive: false,
            date,
          });
        }}
      />
      <OverviewDataChart
        data={graphData}
        isChatOpened={isChatOpened}
        isLoading={isLoading}
      />
      {graphData.numberOfLines > 0 && <OverviewMedicationChart />}
    </Box>
  );
};

export default OverviewDataCard;
