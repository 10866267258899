import React from 'react';

const TodoNotificationIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.46798 4.40343C7.26244 4.40343 6.28516 5.38072 6.28516 6.58626V24.8172C6.28516 26.0227 7.26244 27 8.46798 27H21.2775C22.483 27 23.4603 26.0227 23.4603 24.8172V6.58626C23.4603 5.38072 22.483 4.40343 21.2775 4.40343H8.46798ZM8.82031 5.3826C7.9592 5.3826 7.26114 6.08066 7.26114 6.94176V24.5369C7.26114 25.3981 7.9592 26.0961 8.8203 26.0961H20.9254C21.7865 26.0961 22.4846 25.3981 22.4846 24.5369V6.94176C22.4846 6.08066 21.7865 5.3826 20.9254 5.3826H8.82031Z"
      fill="#323232"
    />
    <path
      d="M16.7843 4.25282C16.6035 3.3933 15.8159 2.74636 14.8716 2.74636C13.9274 2.74636 13.1398 3.3933 12.959 4.25282H10.9939C10.7643 4.25282 10.5781 4.43897 10.5781 4.66859V6.0967C10.5781 6.32632 10.7643 6.51247 10.9939 6.51247H18.7499C18.9796 6.51247 19.1657 6.32632 19.1657 6.0967V4.66859C19.1657 4.43897 18.9796 4.25282 18.7499 4.25282H16.7843Z"
      fill="#323232"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.8563 15.9832C20.0337 16.1466 20.045 16.4227 19.8817 16.6001L14.3314 22.6258C14.1733 22.7975 13.9081 22.8144 13.7294 22.6643L10.1926 19.6923C10.008 19.5372 9.9841 19.2618 10.1392 19.0773C10.2943 18.8927 10.5697 18.8688 10.7543 19.0239L13.9718 21.7275L19.2394 16.0085C19.4028 15.8312 19.679 15.8199 19.8563 15.9832Z"
      fill="#323232"
    />
    <rect
      x="9.40796"
      y="9.52532"
      width="0.780689"
      height="0.753219"
      fill="#323232"
    />
    <rect
      x="10.9688"
      y="9.52532"
      width="1.56138"
      height="0.753219"
      fill="#323232"
    />
    <rect
      x="13.3103"
      y="9.52532"
      width="3.12276"
      height="0.753219"
      fill="#323232"
    />
    <rect
      x="17.2141"
      y="9.52532"
      width="1.56138"
      height="0.753219"
      fill="#323232"
    />
    <rect
      x="19.5566"
      y="9.52532"
      width="0.780688"
      height="0.753219"
      fill="#323232"
    />
    <rect
      x="9.40796"
      y="11.785"
      width="0.780689"
      height="0.753219"
      fill="#323232"
    />
    <rect
      x="10.9688"
      y="11.785"
      width="1.56138"
      height="0.753219"
      fill="#323232"
    />
    <rect
      x="13.3103"
      y="11.785"
      width="3.12276"
      height="0.753219"
      fill="#323232"
    />
    <rect
      x="17.2141"
      y="11.785"
      width="1.56138"
      height="0.753219"
      fill="#323232"
    />
    <rect
      x="19.5566"
      y="11.785"
      width="0.780688"
      height="0.753219"
      fill="#323232"
    />
  </svg>
);

export default TodoNotificationIcon;
