import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Box,
  Button,
  TimePicker,
  Dropdown,
  NumberInput,
  WeeklyRecurrence,
  RadioButton,
  Label,
  TextInput,
  Icon,
  Tooltip,
} from '@ui-common-files/components';

import {
  CheckBoxRadioVariants,
  MaxCharLimits,
  Position,
  generateRepeatInterval,
  inputField,
} from '@ui-common-files/utils';
import { checkDateInput, getDurationText } from './CareTaskConfiguration.utils';
import useCareTaskConfigurationService from './CareTaskConfiguration.service';

import './CareTaskConfiguration.styles.scss';

const CareTaskConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const {
    lifespanUnitsObjSmall,
    carePathwayCareplanSequence,
    nodeData,
    configuration,
    repeatTypesArray,
    onConfigurationChange,
    availableLifespanUnits,
    saveConfiguration,
    hasErrors,
    getTranslatedLabel,
  } = useCareTaskConfigurationService();
  const durationText = getDurationText(configuration);

  const availableLifespans = availableLifespanUnits?.map(option => ({
    ...option,
    label: t(option.key),
  }));

  return (
    <>
      <Flex className="care-task-list_options">
        <Box className="care-task-list_options-title">
          {t('common_labels.add_configuration')}
        </Box>
        <Flex className="care-task-list_content-title">
          <Box>{nodeData?.label}</Box>
        </Flex>
      </Flex>
      <Flex
        otherStyles={{
          flex: 1,
        }}
        flexDirection="column"
      >
        <Box className="caretask-configuration">
          <Box>
            <Box className="caretask-configuration-title">
              {t('common_labels.start')}
            </Box>
            <Flex flexDirection="row" otherStyles={{ marginBottom: '24px' }}>
              {configuration.startDateUnit.value !== 0 && (
                <Box margin="0 4px 0 0" maxWidth={70}>
                  <TimePicker
                    value={configuration.startTime}
                    isMinuteDisabled={false}
                    minuteIncrement={15}
                    getTimeStamp={time => {
                      onConfigurationChange(time, 'startTime');
                    }}
                  />
                </Box>
              )}
              <Box className="number-input">
                <NumberInput
                  id="startDateValue"
                  name="startDateValue"
                  min={configuration.startDateUnit.value > 1 ? 1 : 0}
                  max={99}
                  step={1}
                  placeholder=""
                  value={configuration.startDateValue}
                  changeValue={value => {
                    onConfigurationChange(
                      checkDateInput(value, configuration.startDateUnit.value),
                      'startDateValue'
                    );
                  }}
                />
              </Box>
              <Box flex="1" margin="0 3px 0 0" maxWidth={130}>
                <Dropdown
                  value={getTranslatedLabel('startDateUnit')}
                  options={lifespanUnitsObjSmall}
                  placeholder="Start Date Unit"
                  handleOnChange={event => {
                    onConfigurationChange(event, 'startDateUnit');
                  }}
                />
              </Box>
              <Box flex="1" maxWidth={100}>
                <Dropdown
                  isDisabled={configuration.startDateValue === 0}
                  value={getTranslatedLabel('startDateSequence')}
                  options={carePathwayCareplanSequence}
                  placeholder="Start Date Sequence"
                  handleOnChange={event => {
                    onConfigurationChange(event, 'startDateSequence');
                  }}
                />
              </Box>
            </Flex>
            <Box otherStyles={{ marginBottom: '24px' }}>
              <Box className="caretask-configuration-title">
                {t('recurrence.repetition')}
              </Box>
              <Flex flexDirection="row" justifyContent="space-between">
                <Box minWidth={118}>
                  <Dropdown
                    isDisabled={
                      configuration.startDateValue === 0 &&
                      configuration.endDateValue === 0 &&
                      !configuration.occurrenceCountCheck
                    }
                    value={getTranslatedLabel('repeatType')}
                    options={repeatTypesArray}
                    placeholder="Repeat"
                    handleOnChange={event => {
                      onConfigurationChange(event, 'repeatType');
                    }}
                  />
                </Box>
                <Flex alignItems="center">
                  <Box margin="0 7px" className="recurrence-text">
                    {t('recurrence.every')}
                  </Box>
                  <Box minWidth={90} maxWidth={90}>
                    <Dropdown
                      value={configuration.repeatInterval}
                      options={generateRepeatInterval()}
                      isDisabled={false}
                      placeholder="Interval"
                      handleOnChange={event => {
                        onConfigurationChange(event, 'repeatInterval');
                      }}
                    />
                  </Box>
                  <Box margin="0 7px" className="recurrence-text">
                    {configuration.repeatType
                      ? lifespanUnitsObjSmall[configuration.repeatType.value]
                          .label
                      : lifespanUnitsObjSmall[0].label}
                  </Box>
                </Flex>
              </Flex>
              {configuration.repeatType &&
                configuration.repeatType.value === 2 && (
                  <Box className="weekly-reccurrence">
                    <WeeklyRecurrence
                      isDisabled={
                        configuration.startDateValue === 0 &&
                        configuration.endDateValue === 0 &&
                        !configuration.occurrenceCountCheck
                      }
                      startDateWeekday={1}
                      weeklyRecurrence={configuration.weeklyRecurrence}
                      hasErrors={hasErrors.recurrenceRepeat}
                      handleOnChange={event =>
                        onConfigurationChange(event, 'weeklyRecurrence')
                      }
                    />
                  </Box>
                )}
              <Box flex="1" margin="24px 0px 24px">
                <Box className="caretask-configuration-title">
                  <Flex flexDirection="row">
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      otherStyles={{ marginRight: 24 }}
                    >
                      <RadioButton
                        id="endDateCheck"
                        name="endDateCheck"
                        variant={CheckBoxRadioVariants.Default}
                        checked={!configuration.occurrenceCountCheck}
                        handleOnChange={event => {
                          onConfigurationChange(
                            !event.target.checked,
                            'occurrenceCountCheck'
                          );
                        }}
                      />
                      {t('common_labels.end')}
                    </Flex>
                    <Flex flexDirection="row" alignItems="center">
                      <Box>
                        <RadioButton
                          id="occurrenceCountCheck"
                          name="occurrenceCountCheck"
                          variant={CheckBoxRadioVariants.Default}
                          checked={configuration.occurrenceCountCheck}
                          handleOnChange={event => {
                            onConfigurationChange(
                              event.target.checked,
                              'occurrenceCountCheck'
                            );
                          }}
                        />
                      </Box>
                      {t('recurrence.occurences')}
                    </Flex>
                  </Flex>
                </Box>
              </Box>
              <Box flex="1">
                {configuration.occurrenceCountCheck && (
                  <Flex flexDirection="row" alignItems="center">
                    <Box flex="1" margin="0 10px 0 0" maxWidth={128}>
                      <Dropdown
                        value={configuration.occurrenceCount}
                        options={generateRepeatInterval()}
                        placeholder="Times"
                        handleOnChange={event => {
                          onConfigurationChange(event, 'occurrenceCount');
                        }}
                      />
                    </Box>
                    <Box className="text-container">
                      {t('recurrence.times')}
                    </Box>
                  </Flex>
                )}
                {!configuration.occurrenceCountCheck && (
                  <>
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      otherStyles={{ marginTop: 12 }}
                    >
                      <Box className="number-input">
                        <NumberInput
                          id="endDateValue"
                          name="endDateValue"
                          isDisabled={configuration.occurrenceCountCheck}
                          min={configuration.endDateUnit.value > 1 ? 1 : 0}
                          max={99}
                          step={1}
                          placeholder=""
                          value={configuration.endDateValue}
                          hasError={hasErrors.endDate}
                          changeValue={value => {
                            onConfigurationChange(
                              checkDateInput(
                                value,
                                configuration.endDateUnit.value
                              ),
                              'endDateValue'
                            );
                          }}
                        />
                      </Box>
                      <Box flex="1" margin="0 10px 0 10px">
                        <Dropdown
                          value={getTranslatedLabel('endDateUnit')}
                          options={lifespanUnitsObjSmall}
                          isDisabled={configuration.occurrenceCountCheck}
                          placeholder="End Date Unit"
                          handleOnChange={event => {
                            onConfigurationChange(event, 'endDateUnit');
                          }}
                        />
                      </Box>
                      <Box flex="1">
                        <Dropdown
                          value={getTranslatedLabel('endDateSequence')}
                          options={carePathwayCareplanSequence}
                          isDisabled={
                            configuration.occurrenceCountCheck ||
                            configuration.endDateValue === 0
                          }
                          placeholder="End Date Sequence"
                          handleOnChange={event => {
                            onConfigurationChange(event, 'endDateSequence');
                          }}
                        />
                      </Box>
                    </Flex>
                    {hasErrors.endDate && (
                      <Box className="recurrence-error-message">
                        {t('recurrence.endDateErrorNoRequired')}
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className="caretask-configuration-title">
              <Flex flexDirection="row">
                {t('recurrence.lifespan')}
                <Label
                  id="lifespanInfo"
                  icon="info"
                  hasIcon
                  tooltip={
                    <Box className="tooltip-content-wrapper">
                      {t('recurrence.lifespanInfo')}
                    </Box>
                  }
                />
              </Flex>
            </Box>
            <Flex
              flexDirection="row"
              alignItems={hasErrors.lifespan ? null : 'center'}
              otherStyles={{ width: '80%' }}
            >
              <Box flex="1" margin="0 10px 0 0" maxWidth={100}>
                <NumberInput
                  id="lifespanDuration"
                  name="lifespanDuration"
                  min={1}
                  max={99}
                  step={1}
                  placeholder=""
                  value={configuration.lifespan}
                  changeValue={value => {
                    onConfigurationChange(value, 'lifespan');
                  }}
                  hasError={hasErrors.lifespan}
                />
              </Box>
              <Box flex="1" maxWidth={128} margin="0 10px 0 0">
                <Dropdown
                  value={getTranslatedLabel('lifespanUnit')}
                  options={availableLifespans}
                  placeholder="Lifespan"
                  handleOnChange={event => {
                    onConfigurationChange(event, 'lifespanUnit');
                  }}
                />
              </Box>
            </Flex>
            {hasErrors.lifespan && (
              <Box className="recurrence-error-message">
                {t(hasErrors.lifespan)}
              </Box>
            )}
          </Box>
          <Box otherStyles={{ marginBottom: 24, marginTop: 24 }}>
            <Flex alignItems="center">
              <Box className="caretask-configuration-title">
                {t('newCareplan_view.carepathwaySettingsTag')}
              </Box>
              <Flex otherStyles={{ margin: '0 0 8px 12px' }}>
                <Tooltip
                  position={Position.bottom}
                  content={t('newCareplan_view.carepathwaySettingsTagInfo')}
                >
                  <Icon name="info" size={20} />
                </Tooltip>
              </Flex>
            </Flex>
            <TextInput
              value={configuration.recurrenceTag}
              hasError={hasErrors.recurrenceTag}
              id="recurrenceTag"
              name="recurrenceTag"
              placeholder="#"
              variant={inputField.variant.CHAR_COUNT}
              maxLength={MaxCharLimits.carePathway.carePathwayTemplateSettings}
              maxChars={MaxCharLimits.carePathway.carePathwayTemplateSettings}
              handleOnChange={event => {
                onConfigurationChange(event.target.value, 'recurrenceTag');
              }}
            />
            {hasErrors.recurrenceTag && (
              <Box className="recurrence-error-message">
                {t('newCareplan_view.carePathwaySettingsTagError')}
              </Box>
            )}
          </Box>
          <Box otherStyles={{ marginBottom: 24 }}>
            <Box className="caretask-configuration-title">
              {t('steps.duraionLabelPathwayTemplate')}
            </Box>
            <Box className="duration-container">
              <Flex
                otherStyles={{
                  marginBottom: 8,
                }}
              >
                <Box className="duration-container-title">
                  {`${t('common_labels.start')}:`}
                </Box>
                <Box className="duration-container-content">
                  {durationText ? durationText.startText : ''}
                </Box>
              </Flex>
              <Flex otherStyles={{ marginBottom: 8 }}>
                <Box className="duration-container-title">
                  {`${t('common_labels.end')}:`}
                </Box>
                <Box className="duration-container-content">
                  {durationText ? durationText.endText : ''}
                </Box>
              </Flex>
              <Flex otherStyles={{ marginBottom: 8 }}>
                <Box className="duration-container-title">
                  {`${t('recurrence.repetition')}:`}
                </Box>
                <Box className="duration-container-content">
                  {durationText ? durationText.occurrence : ''}
                </Box>
              </Flex>
              <Flex>
                <Box className="duration-container-title">
                  {`${t('recurrence.lifespan')}:`}
                </Box>
                <Box className="duration-container-content">
                  {durationText ? durationText.lifeSpan : ''}
                </Box>
              </Flex>
            </Box>
          </Box>
          {hasErrors.overlapping && (
            <Box className="recurrence-overlapping">
              {t('newCareplan_view.careplanOverlappinReccurrenceError')}
            </Box>
          )}
        </Box>
        <Flex className="save-button" justifyContent="flex-end" >
          <Button
            label={t(
              nodeData.duration?.durationText
                ? 'common_buttons.update'
                : 'common_buttons.save'
            )}
            onClick={saveConfiguration}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default CareTaskConfiguration;
