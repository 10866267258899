import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '../../../../caro-ui-commonfiles/utils/button';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';
import '../../../css/careplanComponent.scss';
import NumericConditionInput from '../settingsForComponentTypes/NumericConditionInput';
import { nonMedical } from '../../../../config';
import { setThresholdValueForComponent } from './thresholdHelper';
import defaultEnums from '../defaultEnums';

const isThresholdSet = (index, componentCollection, searchTermComponents) => {
  if (Object.keys(searchTermComponents).length > 0) {
    const shouldToggle =
      componentCollection[index].searchTerm.careplanComponentId &&
      (nonMedical == false || nonMedical == 'false')
        ? componentCollection[index].searchTerm.shouldThresholdToggle
        : componentCollection[index].shouldThresholdToggle;
    return shouldToggle;
  }
};

const NumericValue = ({
  item,
  componentCollection,
  searchTermComponents,
  setComponentCollection,
  thresholdDropdown,
  thresholdNumber,
}) => {
  const [itemCollection, setItemCollection] = useState([
    { name: thresholdDropdown, value: thresholdNumber },
  ]);
  const index = componentCollection.findIndex(comp => comp.id === item.id);

  useEffect(() => {
    const { thresholds } = componentCollection[index].thresholdCollection;
    const thresholdCollectionObject =
      thresholds.length > 0
        ? thresholds
        : [{ name: thresholdDropdown, value: thresholdNumber }];
    setItemCollection(thresholdCollectionObject);
  }, [componentCollection, index, thresholdDropdown, thresholdNumber]);

  const thresholdToggle = isThresholdSet(
    index,
    componentCollection,
    searchTermComponents
  );
  const isFloatingNumber = componentCollection[index].searchTerm
    .careplanComponentId
    ? componentCollection[index].searchTerm.decimal
    : componentCollection[index].decimal;

  const addThreshold = () => {
    const obj = { name: thresholdDropdown, value: thresholdNumber };
    const newItemCollection = [...itemCollection, obj];
    setItemCollection(newItemCollection);
  };

  const setValueForComponent = (key, optionIndex, value) => {
    const updatedComponentCollection = setThresholdValueForComponent(
      key,
      index,
      optionIndex,
      value,
      componentCollection
    );
    setComponentCollection(updatedComponentCollection);
  };

  return (
    <div>
      {isFloatingNumber && (
        <div className="floatingTextContainer">
          <span className="floatingTextSpan">
            {I18n.t('previewCareplanComponent.floatingPointNumber')}
          </span>
        </div>
      )}
      {thresholdToggle && (
        <>
          {itemCollection.map((elem, i) => {
            return (
              <NumericConditionInput
                key={`numeric-condition-input-${index}-${i}`}
                index={i}
                item={elem}
                parentComponentIndex={index}
                itemCollection={itemCollection}
                setValueForComponent={setValueForComponent}
                isFloatingNumber={isFloatingNumber}
                isThreshold
                max={defaultEnums.NUMERIC_VALUE_MAX_VALUE}
                min={defaultEnums.NUMERIC_VALUE_MIN_VALUE}
                color={ActionColors.THRESHOLD}
              />
            );
          })}
          <Box margin="13px 0 0 0">
            <Button
              label={`+ ${
                item.thresholdCollection.thresholds.length > 0
                  ? I18n.t('threshold.addMoreThresholdLabel')
                  : I18n.t('threshold.addThresholdLabel')
              }`}
              variant={ButtonVariant.NO_BORDER}
              color={ButtonColor.RED}
              size={ButtonSize.MD}
              onClick={() => addThreshold()}
            />
          </Box>
        </>
      )}
    </div>
  );
};

NumericValue.propTypes = {
  item: PropTypes.object.isRequired,
  componentCollection: PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
  searchTermComponents: PropTypes.object.isRequired,
  setComponentCollection: PropTypes.func.isRequired,
  thresholdDropdown: PropTypes.string.isRequired,
  thresholdNumber: PropTypes.string.isRequired,
};

export default NumericValue;
