import React, { memo } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import ModalSection from '../common/Layout/Modal/ModalSection';
import AtomWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import TextInput from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Dropdown from '../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import Checkbox from '../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';

import MedicationDailyOccurrence from '../../../caro-ui-commonfiles/components/molecules/Recurrence/MedicationDailyOccurrence';
import maxCharLimit from '../../../caro-ui-commonfiles/utils/maxcharlimits.json';
import inputField from '../../../caro-ui-commonfiles/utils/inputField';

import {
  dropdownMedicationPhaseTypes,
  dropdownMedicationAdministrationType,
} from '../../Utils/dropdownTypes';

const MedicationCreateModalContentForm = ({
  values,
  recurrenceComponent,
  icdSelectorComponent,
  onValueChange,
  hasErrors,
  recurrenceErrors,
  setRecurrenceErrors,
  isUpdate,
  resetDosage,
}) => {
  const dropdownMedicationAdministrationTypeObj = dropdownMedicationAdministrationType();
  const dropdownMedicationPhaseTypesObj = dropdownMedicationPhaseTypes();

  return (
    <>
      <ModalSection>
        <AtomWithLabel
          id="name"
          htmlFor="name"
          labelText={`* ${I18n.t('medication.name')}`}
        >
          <TextInput
            value={values.name}
            id="name"
            name="name"
            placeholder={I18n.t('medication.add_medicationName')}
            variant={inputField.variant.CHAR_COUNT}
            maxLength={maxCharLimit.medication.medicationNameMaxCharLimit}
            maxChars={maxCharLimit.medication.medicationNameMaxCharLimit}
            handleOnChange={event =>{ 
              onValueChange(event.target.value, 'name')
            }}
            hasError={hasErrors.name}
            validationMessage={I18n.t('medication.atLeast6Characters')}
          />
        </AtomWithLabel>

        <Box margin="0 0 10px 20px">
          <Checkbox
            id="sober"
            name="sober"
            label={I18n.t('medication.sober')}
            checked={values.sober}
            handleOnChange={e => onValueChange(e.target.checked, 'sober')}
          />
        </Box>
        <AtomWithLabel
          id="unit"
          htmlFor="unit"
          labelText={`* ${I18n.t('medication.unit')}`}
        >
          <TextInput
            value={values.unit}
            id="unit"
            name="unit"
            placeholder={I18n.t('medication.add_unit')}
            variant={inputField.variant.CHAR_COUNT}
            maxLength={maxCharLimit.medication.medicationUnitMaxCharLimit}
            maxChars={maxCharLimit.medication.medicationUnitMaxCharLimit}
            handleOnChange={event => onValueChange(event.target.value, 'unit')}
            hasError={hasErrors.unit}
            validationMessage={I18n.t('parsley.requiredField')}
          />
        </AtomWithLabel>
        <AtomWithLabel
          id="medicationAdministrationType"
          htmlFor="medicationAdministrationType"
          labelText={I18n.t('medication.administrationType')}
        >
          <Dropdown
            value={values.medicationAdministrationType}
            options={dropdownMedicationAdministrationTypeObj}
            placeholder={I18n.t('medication.administrationType')}
            handleOnChange={event =>
              onValueChange(event, 'medicationAdministrationType')
            }
          />
        </AtomWithLabel>

        <AtomWithLabel
          id="dosage"
          htmlFor="dosage"
          labelText={`* ${I18n.t('medication.dosage')}`}
        >
          <MedicationDailyOccurrence
            onValueChange={onValueChange}
            values={values}
            hasErrors={hasErrors}
            recurrenceErrors={recurrenceErrors}
            setRecurrenceErrors={setRecurrenceErrors}
            resetDosage={resetDosage}
            unit={values.unit}
          />
          {recurrenceErrors.recurrence && (
            <div className="recurrence__validation-message">
              {I18n.t('recurrence.warningNoTimeOfDaySelected')}
            </div>
          )}
        </AtomWithLabel>
      </ModalSection>

      <ModalSection>
        <AtomWithLabel
          id="phaseType"
          htmlFor="phaseType"
          labelText={I18n.t('medication.phaseType')}
        >
          <Dropdown
            value={values.phaseType}
            options={dropdownMedicationPhaseTypesObj}
            placeholder={I18n.t('medication.phaseType')}
            handleOnChange={event => onValueChange(event, 'phaseType')}
            isDisabled={isUpdate}
          />
        </AtomWithLabel>
        {recurrenceComponent}
        <AtomWithLabel
          id="icds"
          htmlFor="icds"
          labelText={I18n.t('task.ICDReference')}
        >
          {icdSelectorComponent}
        </AtomWithLabel>
      </ModalSection>
    </>
  );
};

MedicationCreateModalContentForm.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  recurrenceComponent: PropTypes.element.isRequired,
  icdSelectorComponent: PropTypes.element.isRequired,
  onValueChange: PropTypes.func.isRequired,
  hasErrors: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default memo(MedicationCreateModalContentForm);
