import React from 'react';

import ComponentWithLabel from '../../../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import TextInput from '../../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import inputField from '../../../../../caro-ui-commonfiles/utils/inputField';
import Button from '../../../../../caro-ui-commonfiles/components/Button/Button';
import UpdatePasswordValidation from './UpdatePasswordValidation';
import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import CheckCurrentPasswordBox from './CheckCurrentPasswordBox';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';

import maxCharLimit from '../../../../../caro-ui-commonfiles/utils/maxcharlimits.json';
import useUpdatePasswordComponentService from './UpdatePasswordComponent.service';
import { updateUserPasswordValidation as passwordValidations } from './UpdatePasswordComponent.util';
import {
  ButtonType,
  ButtonVariant,
} from '../../../../../caro-ui-commonfiles/utils/button';
import { UpdatePasswordComponentProps } from './UpdatePasswordComponent.type';

import styles from './UpdatePasswordComponent.module.scss';
import '../../../../css/user.css';

function UpdatePasswordComponent({
  showEditPassword,
  showHidePassword,
  values,
  hasErrors,
  setHasErrors,
  onValueChange,
  setValues,
}: UpdatePasswordComponentProps) {
  const {
    previousEmail,
    validatePassword,
    validateRepeatPassword,
    handleCurrentPasswordChange,
    buttonLabel,
    validateEmail,
    currentPasswordValid,
    handleOnClickCheck,
    buttonState,
    t,
  } = useUpdatePasswordComponentService({
    setHasErrors,
    showEditPassword,
    values,
    setValues,
    onValueChange,
  });
  return (
    <Box>
      <Box className={styles.container__button}>
        <Button
          label={buttonLabel}
          type={ButtonType.BUTTON}
          onClick={showHidePassword}
          variant={ButtonVariant.OUTLINED}
        />
      </Box>
      {!currentPasswordValid && (
        <Box className={styles.container__email}>{previousEmail}</Box>
      )}
      {showEditPassword && (
        <div id="password-section">
          <ComponentWithLabel
            id="currentPassword"
            htmlFor="currentPassword"
            hasIcon={false}
            labelText={t('common_labels.label_currentPassword')}
            tooltipText={t('common_labels.label_currentPassword')}
            hasToggleButton={false}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
            isDisabledToggleButton={false}
            checked={false}
          >
            <Flex className={styles.container__currentPassword}>
              <Flex className={styles.container__currentPasswordInput}>
                <TextInput
                  value={values.currentPassword}
                  hasError={hasErrors.currentPassword}
                  isDisabled={currentPasswordValid}
                  id="currentPassword"
                  name="currentPassword"
                  placeholder={t('common_labels.label_currentPassword')}
                  handleOnChange={handleCurrentPasswordChange}
                  validationMessage={hasErrors.currentPassword}
                  type={inputField.type.PASSWORD}
                  autoComplete="off"
                />
              </Flex>
              <CheckCurrentPasswordBox
                onClick={handleOnClickCheck}
                state={buttonState}
              />
            </Flex>
          </ComponentWithLabel>
          {currentPasswordValid && (
            <>
              <ComponentWithLabel
                id="email"
                htmlFor="email"
                hasIcon={false}
                labelText={`* ${t('admin_view.label_email_user')}`}
                tooltipText={t('admin_view.label_email_user')}
                hasToggleButton={false}
                idToggleButton="toggleBtn"
                valueToggleButton="toggleBtn"
                isDisabledToggleButton={false}
                checked={false}
              >
                <TextInput
                  value={values.email}
                  hasError={hasErrors.email}
                  id="email"
                  name="email"
                  placeholder={t('patients_view.add_emailAddress')}
                  variant={inputField.variant.CHAR_COUNT}
                  maxLength={maxCharLimit.user.emailMaxCharLimit}
                  maxChars={maxCharLimit.user.emailMaxCharLimit}
                  handleOnChange={event =>
                    onValueChange(event.target.value, 'email')
                  }
                  onBlur={validateEmail}
                  validationMessage={hasErrors.email}
                />
              </ComponentWithLabel>
              <ComponentWithLabel
                id="password"
                htmlFor="password"
                hasIcon={false}
                labelText={t('common_labels.label_newPassword')}
                hasToggleButton={false}
                idToggleButton="toggleBtn"
                valueToggleButton="toggleBtn"
                isDisabledToggleButton={false}
                checked={false}
              >
                <TextInput
                  value={values.password}
                  hasError={hasErrors.password}
                  id="password"
                  name="password"
                  onBlur={validatePassword}
                  placeholder={t('common_labels.add_newPassword')}
                  handleOnChange={event =>
                    onValueChange(event.target.value, 'password')
                  }
                  validationMessage={hasErrors.password}
                  type={inputField.type.PASSWORD}
                />
                <Flex className={styles.container__validations}>
                  {passwordValidations.map(validation => (
                    <UpdatePasswordValidation
                      key={validation.message}
                      validation={validation.validation}
                      text={values.password}
                      message={validation.message}
                    />
                  ))}
                </Flex>
              </ComponentWithLabel>
              <ComponentWithLabel
                id="repeatPassword"
                htmlFor="repeatPassword"
                hasIcon={false}
                labelText={t('admin_view.label_retypePassword')}
                tooltipText={t('admin_view.label_retypePassword')}
                hasToggleButton={false}
                idToggleButton="toggleBtn"
                valueToggleButton="toggleBtn"
                isDisabledToggleButton={false}
                checked={false}
              >
                <TextInput
                  value={values.repeatPassword}
                  hasError={hasErrors.repeatPassword}
                  onBlur={validateRepeatPassword}
                  id="repeatPassword"
                  name="repeatPassword"
                  placeholder={t('admin_view.label_retypePassword')}
                  handleOnChange={event =>
                    onValueChange(event.target.value, 'repeatPassword')
                  }
                  validationMessage={hasErrors.repeatPassword}
                  type={inputField.type.PASSWORD}
                />
              </ComponentWithLabel>
            </>
          )}
        </div>
      )}
    </Box>
  );
}

export default UpdatePasswordComponent;
