import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Step, Stepper } from '@ui-common-files/components';
const CarePathwayHeader: React.FC = (
  currentStep,
  isLoading,
  shouldDisplayComponentConfiguration
) => {
  const { t } = useTranslation();

  return (
    <Box width="50%">
      {shouldDisplayComponentConfiguration && (
        <Stepper currentStep={isLoading ? 1 : currentStep}>
          <Step text={('Step 1 text', t('steps.thresholdSettings'))} />
          <Step text={('Step 2 text', t('common_buttons.preview'))} />
        </Stepper>
      )}
    </Box>
  );
};
export default CarePathwayHeader;
