export const isDatePickerThresholdCrossed = (answer, threshold) => {
  const unitInSeconds = {
    'day(s)': 86400,
    'week(s)': 604800,
    'month(s)': 2628000,
    'year(s)': 31560000,
  };
  if (answer && answer.answer_date !== null && threshold) {
    const answerValueInSeconds = new Date(answer.answer_date).getTime() / 1000;
    const answerStartDateInSeconds =
      new Date(new Date(answer.timestamp).setHours(0, 0, 0, 0)).getTime() /
      1000;
    const answerEndDateInSeconds =
      new Date(new Date(answer.timestamp).setHours(23, 59, 59)).getTime() /
      1000;
    switch (threshold.comparison) {
      case 'more':
        const answerDateMinusDurationInSecondsForMore =
          answerStartDateInSeconds -
          parseInt(threshold.numericValue) * unitInSeconds[threshold.unit];
        const answerDatePlusDurationInSecondsForMore =
          answerEndDateInSeconds +
          parseInt(threshold.numericValue) * unitInSeconds[threshold.unit];
        if (
          answerValueInSeconds < answerDateMinusDurationInSecondsForMore ||
          answerValueInSeconds > answerDatePlusDurationInSecondsForMore
        ) {
          return true;
        }
        return false;
      case 'less':
        const answerDateMinusDurationInSecondsForLess =
          answerEndDateInSeconds -
          parseInt(threshold.numericValue) * unitInSeconds[threshold.unit];
        const answerDatePlusDurationInSecondsForLess =
          answerStartDateInSeconds +
          parseInt(threshold.numericValue) * unitInSeconds[threshold.unit];
        if (
          answerValueInSeconds < answerDateMinusDurationInSecondsForLess ||
          answerValueInSeconds > answerDatePlusDurationInSecondsForLess
        ) {
          return true;
        }
        return false;
    }
    return false;
  }
};
