import React, { useMemo } from 'react';
import i18next from 'i18next';

import { LanguageItems } from '@type/Common';
import { USER_GUIDE_LINKS } from '@utils/GuideLinks/UserGuideLinks.constant';

const useImprintService = () => {
  const getEIFULink = useMemo(() => {
    const currentLanguage = i18next.language;
    let eIFULink = '';
    switch (currentLanguage) {
      case LanguageItems.English:
        eIFULink = USER_GUIDE_LINKS.EN;
        break;
      case LanguageItems.Deutsch:
        eIFULink = USER_GUIDE_LINKS.DE;
        break;
      case LanguageItems.Italiano:
        eIFULink = USER_GUIDE_LINKS.IT;
        break;
      case LanguageItems.Portuguese:
        eIFULink = USER_GUIDE_LINKS.PT;
        break;
      case LanguageItems.Français:
        eIFULink = USER_GUIDE_LINKS.FR;
        break;
      default:
        eIFULink = USER_GUIDE_LINKS.EN;
        break;
    }
    return eIFULink;
  }, [i18next.language]);

  return {
    getEIFULink,
  };
};

export default useImprintService;
