import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FlexBox from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';

import { useResponsiveScreen } from '../../utils/hooks';
import { isScreenSmIpad } from '../../global/responsiveUtils/responsiveValidation.js';

const Collapsible = ({
  headerComponent,
  actionsComponent,
  bodyComponent,
  separatorComponent,
  isExpanded = false,
  isCollapsible = true
}) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const screenSize = useResponsiveScreen();
  
  return <>
    <FlexBox 
      justifyContent="space-between" 
      alignItems="center"   
      otherStyles={{...(isScreenSmIpad(screenSize) ? {display: 'block'} : {})}}
    >
      {headerComponent()}
      <FlexBox 
        alignItems="center" 
        otherStyles={{...(isScreenSmIpad(screenSize) ? {justifyContent: 'space-between'} : {})}}
      >
        {actionsComponent()}
        {isCollapsible &&
          <Box otherStyles={{ marginLeft: 34 }}>
            <IconButton
              name={expanded ? "arrowUp" : "arrowDown"}
              size={20}
              onClick={() => setExpanded(!expanded)}
            />
          </Box>
        }
      </FlexBox>
    </FlexBox>
    {expanded &&
      <FlexBox>
        {bodyComponent()}
      </FlexBox>
    }
    {separatorComponent()}
  </>;
}

Collapsible.propTypes = {
  headerComponent: PropTypes.func.isRequired,
  actionsComponent: PropTypes.func,
  bodyComponent: PropTypes.func.isRequired,
  separatorComponent: PropTypes.func,
  isExpanded: PropTypes.bool,
  isCollapsible: PropTypes.bool,
};

export default Collapsible;