import React from 'react';
import { Route } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Box, Icon } from '@ui-common-files/components';

import { Greys, PrimaryColor } from '@ui-common-files/utils';
import { useSideBarMenuService } from './SideBarMenu.service';

const SideBarMenu = () => {
  const { mapServices, determinateActiveLink, handleClick } =
    useSideBarMenuService();

  const renderServices = () => {
    const mappedServices = mapServices();
    return mappedServices.map((service, index) => {
      return (
        <Route
          key={service.icon}
          path={service.url}
          children={({ match, location }) => {
            const active = determinateActiveLink(
              match,
              location.pathname,
              service.url
            );
            return (
              <NavLink
                to={service.url}
                className="side-bar__item "
                activeClassName="side-bar__item--active"
                onClick={e => handleClick(e, index)}
                isActive={() => active}
              >
                <span>
                  <Icon
                    name={service.icon}
                    size={30}
                    fill={active ? PrimaryColor.MAIN_COLOR : Greys.DARK_GREY}
                  />
                </span>
                <span className="side-bar__item__text">{service.label}</span>
              </NavLink>
            );
          }}
        />
      );
    });
  };

  return <Box className="side-bar__menu">{renderServices()}</Box>;
};

export default SideBarMenu;
