import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { ScoringTypes } from '@type/Careplan';

import { getNodeByParentId } from './CarepathwayTriggeredResult.util';
import {
  getCaretaskAnswersDate,
  getCaretaskAnswerByDate,
} from './CarepathwayTriggeredResult.api';

export const useTriggeredCaretaskService = ({
  node,
  nodes,
  edges,
  condition,
  caretaskIdTriggeredThreshold,
}) => {
  const [isToggle, setIsToggle] = useState(
    caretaskIdTriggeredThreshold === node.careplanId
  );
  const childNode = getNodeByParentId(nodes, node.id);
  const edge = edges.find(
    edge => edge.source === node.id && edge.target === childNode.id
  );

  const contentToggle = () => {
    setIsToggle(prevToggle => !prevToggle);
  };

  const conditionArrowCSSClasses = classNames({
    'triggered-item_content-header_condition-arrow': true,
    well: condition === ScoringTypes.Well,
    fair: condition === ScoringTypes.Fair,
    poor: condition === ScoringTypes.Poor,
  });

  const headerInnerCSSClasses = classNames({
    'triggered-item_content-header-inner': true,
    inactive: !node.isAnswered,
  });

  const contentBodyCSSClasses = classNames({
    'triggered-item_content-body': true,
    'has-condition': !!condition,
  });

  return {
    edge,
    isToggle,
    childNode,
    contentToggle,
    contentBodyCSSClasses,
    headerInnerCSSClasses,
    conditionArrowCSSClasses,
  };
};

export const useCarepathwayTriggeredResultService = (
  caretaskId,
  answerTimestamp
) => {
  const [datesOfAnswers, setDatesOfAnswers] = useState(null);
  const [datesOfAnswersLoading, setDatesOfAnswersLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(answerTimestamp);
  const [caretasks, setCaretasks] = useState(null);
  const [caretasksLoading, setCaretasksLoading] = useState(true);

  let abortController: AbortController;

  useEffect(() => {
    abortController = new AbortController();
    getCaretaskAnswersDate(caretaskId, abortController)
      .then(response => {
        if (response) {
          setDatesOfAnswers(response.data);
          if (!selectedDate) {
            setSelectedDate(response.data.dates[0].savedAt);
          }
        }
      })
      .finally(() => {
        setDatesOfAnswersLoading(false);
      });

    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      abortController = new AbortController();
      setCaretasksLoading(true);
      getCaretaskAnswerByDate(caretaskId, selectedDate, abortController)
        .then(response => {
          if (response) {
            setCaretasks(response.data);
          }
        })
        .finally(() => {
          setCaretasksLoading(false);
        });
    }

    return () => abortController.abort();
  }, [selectedDate]);

  return {
    caretasks,
    selectedDate,
    datesOfAnswers,
    setSelectedDate,
    caretasksLoading,
    datesOfAnswersLoading,
  };
};
