import React from 'react';

import { Box, Button, Dropdown, Flex } from '@ui-common-files/components';

import { formatDate } from '@global/Date';
import { documentIcon } from '@utils/Asset/assetIcons';
import {
  AssessmentTableColumns,
  ReviewPatientAsset,
  ReviewRow,
} from './ReviewAssessments.type';

import styles from './ReviewAssessments.module.scss';

export const AssessmentsTableColumns = ({
  assessments,
  onDropdownSelection,
  t,
  onReviewReportButtonClick,
  width,
}: AssessmentTableColumns) => [
  {
    Header: t('reviewAssessments.reportName'),
    accessor: 'report',
    width: width * 0.3,
    Cell: ({ value }: { value: string }) => (
      <Flex className={styles.container__table__report}>
        <Box className={styles.container__table__reportIcon}>
          {documentIcon(32)}
        </Box>
        <Box className={styles.container__table__reportName}>{value}</Box>
      </Flex>
    ),
  },
  {
    width: width * 0.1,
    Header: t('reviewAssessments.patientName'),
    accessor: 'patient',
  },
  {
    width: width * 0.15,
    Header: t('reviewAssessments.dateGenerated'),
    accessor: 'date',
  },
  {
    width: width * 0.3,
    accessor: 'template',
    Cell: ({
      value: { value, index, label, patientId, assetId },
    }: {
      value: {
        index: number;
        value: number;
        label: string;
        patientId: number;
        assetId: number;
        reviewAssessmentTemplateId: number;
      };
    }) => (
      <Flex className={styles.container__table__review}>
        <Box className={styles.container__table__dropdown}>
          <Dropdown
            isSearchable
            handleOnChange={onDropdownSelection(index, patientId, assetId)}
            options={assessments}
            placeholder={t('reviewAssessments.chooseAssessment')}
            value={value && { value, label }}
          />
        </Box>
        <Button
          disabled={value === undefined}
          label={t('reviewAssessments.reviewReport')}
          onClick={() => onReviewReportButtonClick(patientId, assetId, value)}
        />
      </Flex>
    ),
  },
];

export const pageSizeOptions = [
  { value: '10' },
  { value: '20' },
  { value: '30' },
];

export const convertResponseToRows = (patientAssets: ReviewPatientAsset[]) => {
  const rows: ReviewRow[] = patientAssets.map((asset, index) => ({
    date: formatDate(asset.createdAt, 'DD MMM YYYY'),
    patient: `${asset.patient.firstname} ${asset.patient.lastname}`,
    report: asset.asset.name,
    template: {
      value: asset.template?.id,
      label: asset.template?.name,
      index,
      patientId: asset.patientId,
      assetId: asset.assetId,
      reviewAssessmentTemplateId: asset.reviewAssessmentTemplateId,
    },
  }));
  return rows;
};
