import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Icon, Box } from '@ui-common-files/components';

import { greysColorVars } from '@ui-common-files/utils';

import './ComponentSettingsWarning.style.scss';

export default function ComponentSettingsWarning() {
  const { t } = useTranslation();
  return (
    <Flex className="componentWarning">
      <Box className="componentWarning__icon">
        <Icon fill={greysColorVars.LIGHT_GREY} name="info" />
      </Box>
      <Box>{t('warningMessage.careplanScoreNegativeWarning')}</Box>
    </Flex>
  );
}
