import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  FilterWarningMessage,
  Flex,
  Box,
  Icon,
  ComplexSearch,
} from '@ui-common-files/components';

import { Greys } from '@ui-common-files/utils';
import { FilterSectionProps } from './FilterSection.type';

import { onFilterChangeAction } from '../../../../actions/Datatables/patients';

import styles from '../Patient.module.scss';

const FilterSection = ({
  showAppliedFilterWarning,
  selectedFilter,
  forceRender,
  customFilterData,
  selectAdvancedFilter,
}: FilterSectionProps) => {
  const { t } = useTranslation();
  const reduxDispatcher = useDispatch();
  return (
    <Flex
      flexDirection="row"
      justifyContent="flex-start"
      className={styles['patient-filter-applied']}
    >
      {showAppliedFilterWarning && (
        <Flex>
          <FilterWarningMessage
            message={t('patients_complexsearch.filter_applied')}
          />
          {selectedFilter.customFilter === null && (
            <Box className={styles['patient-index__us-warning-filter-wrapper']}>
              <Flex
                justifyContent="flex-start"
                className={styles['patient-index__us-warning-filter-name']}
              >
                <Flex alignItems="center">
                  <Flex className={styles['patient-index__us-warning-shrink']}>
                    <Icon name="filter" size={30} />
                  </Flex>
                  <Box margin="0 0 0 12px">
                    <span className={styles['patient-index__us-warning-txt']}>
                      {selectedFilter.savedFilter.name}
                    </span>
                  </Box>
                  <Box margin="4px 0 0 12px">
                    <button
                      onClick={() =>
                        selectAdvancedFilter(selectedFilter.allFilters[0], 0)
                      }
                    >
                      <Icon name="close" fill={Greys.DARK_GREY} size={20} />
                    </button>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          )}
        </Flex>
      )}

      {forceRender && customFilterData !== null && (
        <ComplexSearch
          filters={customFilterData.filterData}
          rules={selectedFilter.savedFilter.rule}
          getSql={value => {
            reduxDispatcher(onFilterChangeAction(value));
          }}
          hide
        />
      )}
    </Flex>
  );
};

export default FilterSection;
