import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryColor } from '@ui-common-files/utils';
import { Box, Flex, Icon, Tooltip } from '@ui-common-files/components';
import { StartTimeType } from './CareTaskNode.type';
import { getReferenceDatePreposition, formatTime } from './CareTaskNode.utils';

const ReferenceDateIcons = ({
  iconName,
  tooltipText,
}: {
  iconName: string;
  tooltipText: string;
}) => {
  return (
    <Box className="node-content_start-time-icon">
      <Tooltip position="top" content={tooltipText}>
        <Icon name={iconName} size={14} fill={PrimaryColor.MAIN_COLOR} />
      </Tooltip>
    </Box>
  );
};

const CareTaskNodeReferenceDate = ({
  startText,
  startTime,
}: {
  startText: string;
  startTime: StartTimeType;
}) => {
  const { t } = useTranslation();
  const { isAfterReferenceDate, isBeforeReferenceDate } = useMemo(
    () => getReferenceDatePreposition(startText),
    [startText]
  );

  const formattedTime = useMemo(() => formatTime(startTime), [startTime]);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className="node-content_start-time"
    >
      {isAfterReferenceDate && (
        <>
          <ReferenceDateIcons
            iconName="afterReferenceDate"
            tooltipText={t('carePathway_builder.tooltips.after_reference_date')}
          />
          {formattedTime}
        </>
      )}
      {!isAfterReferenceDate && !isBeforeReferenceDate && (
        <Box className="node-content_start-time-icon">
          <Tooltip
            position="top"
            content={t('carePathway_builder.tooltips.on_reference_date')}
          >
            {formattedTime}
          </Tooltip>
        </Box>
      )}
      {isBeforeReferenceDate && (
        <>
          {formattedTime}
          <ReferenceDateIcons
            iconName="beforeReferenceDate"
            tooltipText={t(
              'carePathway_builder.tooltips.before_reference_date'
            )}
          />
        </>
      )}
    </Flex>
  );
};

export default CareTaskNodeReferenceDate;
