import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CurrentPasswordButtonState,
  UpdatePasswordComponentServiceProps,
} from './UpdatePasswordComponent.type';
import { emailRegex, passwordRegex } from '../../../../Global/Utils';
import { checkCurrentPassword } from './UpdatePasswordComponent.api';

const useUpdatePasswordComponentService = ({
  setHasErrors,
  showEditPassword,
  values,
  setValues,
  onValueChange,
}: UpdatePasswordComponentServiceProps) => {
  const { t } = useTranslation();
  const [previousEmail] = useState(values.email);
  const [buttonState, setButtonState] = useState<CurrentPasswordButtonState>(
    CurrentPasswordButtonState.Inactive
  );
  const [currentPasswordValid, setCurrentPasswordValid] = useState(false);

  const validateEmail = () => {
    const isEmailValid = emailRegex.test(String(values.email).toLowerCase());
    if (values.email.length === 0)
      setHasErrors(prevHasErrors => ({
        ...prevHasErrors,
        email: t('parsley.requiredField'),
      }));
    else if (!isEmailValid)
      setHasErrors(prevHasErrors => ({
        ...prevHasErrors,
        email: t('common_labels.invalid_email'),
      }));
    else
      setHasErrors(prevHasErrors => {
        const newHasErrors = { ...prevHasErrors };
        delete newHasErrors.email;
        return newHasErrors;
      });
  };

  const validatePassword = () => {
    let password: string;
    if (values.password && !passwordRegex.test(values.password))
      password = t('admin_view.password_not_strong');
    setHasErrors(prevHasErrors => ({
      ...prevHasErrors,
      password,
    }));
  };

  const validateRepeatPassword = () => {
    let repeatPassword: string;
    if (values.password && !values.repeatPassword)
      repeatPassword = t('admin_view.password_retypePattern');
    else if (values.password !== values.repeatPassword)
      repeatPassword = t('admin_view.password_no_match');
    setHasErrors(prevHasErrors => ({
      ...prevHasErrors,
      repeatPassword,
    }));
  };

  const buttonLabel = !showEditPassword
    ? t('admin_view.label_editEmailPassword')
    : t('common_buttons.close');

  const validateCurrentPasswordForEmail = () => {
    if (values.email === values.currentEmail && !showEditPassword)
      setValues(prevValues => ({
        ...prevValues,
        currentPassword: '',
      }));
  };

  const handleCurrentPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const text = event.target.value;
    if (text) setButtonState(CurrentPasswordButtonState.Active);
    else setButtonState(CurrentPasswordButtonState.Inactive);
    onValueChange(text, 'currentPassword');
  };

  const handleOnClickCheck = () => {
    if (buttonState !== CurrentPasswordButtonState.Active) return;
    setButtonState(CurrentPasswordButtonState.Validating);
    if (values.currentPassword) {
      checkCurrentPassword(values.currentPassword).then(({ data }) => {
        let currentPassword: string;
        if (!data) currentPassword = t('admin_view.password_incorrect');
        setHasErrors(prevHasErrors => ({
          ...prevHasErrors,
          currentPassword,
        }));
        setCurrentPasswordValid(data);
        setButtonState(CurrentPasswordButtonState.Inactive);
      });
    } else {
      setCurrentPasswordValid(false);
      setButtonState(CurrentPasswordButtonState.Inactive);
    }
  };

  useEffect(() => {
    if (!showEditPassword) setCurrentPasswordValid(false);
  }, [showEditPassword]);

  return {
    previousEmail,
    validatePassword,
    validateRepeatPassword,
    buttonLabel,
    validateEmail,
    validateCurrentPasswordForEmail,
    handleCurrentPasswordChange,
    currentPasswordValid,
    handleOnClickCheck,
    buttonState,
    t,
  };
};

export default useUpdatePasswordComponentService;
