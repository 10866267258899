import React, { useContext, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useDelaySearch } from '@global/Hooks';

import { PatientOverviewContext } from '../../../../../PatientOverview/PatientOverview';
import { getTemplatesForGraph } from '../../../Overview.api';
import { useOverviewStore } from '../../../Overview.store';

import DataPointType from '../OverviewDataPointsSelection.enum';

let abortController: AbortController;

const useOverviewDataModalHeaderService = () => {
  const { patientInfo, isModalOpen } = useContext(PatientOverviewContext);
  const {
    setOverviewActivityStatus,
    setOverviewCaretasks,
    overviewCaretasks,
    overviewDataPoints,
    setOverviewDataPoints,
  } = useOverviewStore(
    useShallow(state => ({
      setOverviewActivityStatus: state.setOverviewActivityStatus,
      overviewCaretasks: state.overviewCaretasks,
      setOverviewCaretasks: state.setOverviewCaretasks,
      overviewDataPoints: state.overviewDataPoints,
      setOverviewDataPoints: state.setOverviewDataPoints,
    }))
  );
  const [keyword, setKeyword] = useState(overviewCaretasks.keyword);

  useDelaySearch({
    input: overviewCaretasks.keyword,
    callback: () => {
      setKeyword(overviewCaretasks.keyword);
    },
  });

  const onKeywordChange = e => {
    setOverviewCaretasks({
      keyword: e.target.value,
      isLoading: true,
      totalRecords: 0,
      items: null,
      page: 1,
    });
    abortController.abort();
  };

  const handleClose = (
    dataPointType: number,
    dataPointKey: number,
    dataPointScoreId: number
  ) => {
    const filteredDataPoints = overviewDataPoints?.selectedDataPoints?.filter(
      dataPoint =>
        dataPointType === DataPointType.HEALTH_DATA
          ? dataPoint.healthDataKey !== dataPointKey
          : !(
              dataPoint.scoreId === dataPointScoreId &&
              dataPoint.dataPointType === dataPointType
            )
    );
    setOverviewDataPoints({
      ...overviewDataPoints,
      selectedDataPoints: filteredDataPoints,
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      abortController = new AbortController();

      setOverviewCaretasks({
        ...overviewCaretasks,
        isLoading: true,
      });
      getTemplatesForGraph(
        abortController.signal,
        patientInfo.id,
        overviewCaretasks.page,
        overviewCaretasks.keyword
      ).then(response => {
        if (response?.data) {
          const { templates, templatesCount, wearableStatus } = response.data;
          setOverviewCaretasks({
            ...overviewCaretasks,
            isLoading: false,
            items: templates,
            totalRecords: templatesCount,
          });
          setOverviewActivityStatus(wearableStatus);
        }
      });
    }

    return () => abortController.abort();
  }, [isModalOpen, overviewCaretasks.page, keyword]);

  return {
    onKeywordChange,
    handleClose,
  };
};

export default useOverviewDataModalHeaderService;
