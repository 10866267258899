import { useState } from 'react';

import usePatientsListService from '../PatientsList.service';

import { UseOncoBoxServicesProps } from './OncoBoxModals.type';

export default function useOncoBoxServices({
  setImportModalToggle,
  setExportModalToggle,
}: UseOncoBoxServicesProps) {
  const [oncoBoxErrors, setOncoboxErrors] = useState(null);
  const [oncoBoxSubmitQuery, setOncoboxSubmitQuery] = useState(false);
  const [nbrPatientsSelected, setNbrPatientsSelected] = useState(0);
  const [selectedPatientsData, setSelectedPatientsData] = useState([]);
  const [disableNext, setDisableNext] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [oncoboxFile, setOncoboxFile] = useState({
    name: '',
    data: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { setOpenResultsModal, setDataForExportAnswers } =
    usePatientsListService();

  const closeOncoBoxImportModal = (hasBackgroundProcess: boolean) => {
    if (!hasBackgroundProcess) {
      setImportModalToggle(false);
      setOncoboxErrors(null);
      setOncoboxSubmitQuery(false);
      setOncoboxFile({
        name: '',
        data: null,
      });
      setIsLoading(false);
    }
  };

  const closeExportModal = () => {
    setOpenResultsModal(false);
    setDataForExportAnswers(null);
    setIsLoading(false);
  };
  const closeOncoBoxExportModal = () => {
    setExportModalToggle(false);
    setIsSelectAll(false);
    setSelectedPatientsData([]);
    setNbrPatientsSelected(0);
    setDisableNext(false);
    setOpenResultsModal(false);
  };

  return {
    setNbrPatientsSelected,
    setDisableNext,
    setIsSelectAll,
    closeExportModal,
    isLoading,
    oncoBoxErrors,
    oncoBoxSubmitQuery,
    nbrPatientsSelected,
    disableNext,
    isSelectAll,
    oncoboxFile,
    selectedPatientsData,
    setIsLoading,
    closeOncoBoxExportModal,
    closeOncoBoxImportModal,
    setSelectedPatientsData,
    setOncoboxFile,
    setOncoboxErrors,
    setOncoboxSubmitQuery,
  };
}
