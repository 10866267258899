/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'i18next';

import Bubble from './Bubble';
import { useSelector } from 'react-redux';

const NoteListSection = ({
  listOfNotes,
  numberOfNotes,
  fileRef,
  noteRef,
  prevNote,
}) => {
  const currentUser = useSelector(state => state.session).user;
  const [userPronoun, setUserPronoun] = useState('');

  I18n.on('languageChanged', () => {
    setUserPronoun(I18n.t('notes.user_pronoun'));
  });

  useEffect(() => {
    setUserPronoun(I18n.t('notes.user_pronoun'));
  });

  let noteId;
  const renderFileBubble = (
    text,
    bubbleTimestamp,
    bubbleDate,
    id,
    careTeamUsername,
    careTeamProfilePicture,
    timestamp,
    asset,
    directLink,
    isFileData,
    isCurrentUser
  ) => {
    return (
      <Bubble
        key={`userBubble_${id}`}
        note={text}
        name={careTeamUsername}
        profilePicture={careTeamProfilePicture}
        time={bubbleTimestamp}
        date={bubbleDate}
        fileRef={fileRef}
        noteTimestamp={timestamp}
        directLink={(asset && asset.directLink) || directLink}
        thumbnail={asset && asset.thumbnailURL}
        isFileData={isFileData}
        isOwnNote={isCurrentUser}
      />
    );
  };

  const renderNoteList = useCallback(() => {
    return listOfNotes.map((note, index) => {
      const {
        id,
        user,
        createdAt,
        asset,
        assetDirectLink,
        isFileData,
        text,
        userId,
      } = note;
      const isCurrentUser = currentUser.id === userId;
      const careTeamUsername =
        user != null
          ? isCurrentUser
            ? userPronoun
            : `${user.firstname} ${user.lastname}`
          : '';
      const careTeamProfilePicture = user.profilePicture;

      noteId = id;
      const bubbleTimestamp = moment(createdAt).format('HH:mm');
      const bubbleDate = moment(createdAt)
        .locale(I18n.language.trim())
        .format('DD MMM. YYYY');

      return (
        <React.Fragment key={`userBubble_${id}`}>
          {prevNote > 0 && index === prevNote && (
            <div ref={noteRef} className="notes-list__end" />
          )}
          {note.isFileData ? (
            renderFileBubble(
              text,
              bubbleTimestamp,
              bubbleDate,
              id,
              careTeamUsername,
              careTeamProfilePicture,
              createdAt,
              asset,
              assetDirectLink,
              isFileData,
              isCurrentUser
            )
          ) : (
            <Bubble
              key={`userBubble_${id}`}
              note={text}
              name={careTeamUsername}
              profilePicture={careTeamProfilePicture}
              time={bubbleTimestamp}
              date={bubbleDate}
              isOwnNote={isCurrentUser}
            />
          )}
        </React.Fragment>
      );
    });
  }, [listOfNotes, numberOfNotes, userPronoun]);

  return <React.Fragment key={noteId}>{renderNoteList()}</React.Fragment>;
};

NoteListSection.propTypes = {
  listOfNotes: PropTypes.array.isRequired,
  numberOfNotes: PropTypes.number.isRequired,
  fileRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default React.memo(NoteListSection);
