import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import I18n from 'i18next';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import {
  ButtonType,
  ButtonVariant,
} from '../../../caro-ui-commonfiles/utils/button';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import {
  SubmissionTypes,
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { ActionColors } from '../../../caro-ui-commonfiles/utils/colors';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import serviceConfig from '../../../serviceConfig.json';
import ScrollDownComponent from '../../Utils/ScrollDownComponent';
import UsFilterTopWarning from '../../../caro-ui-commonfiles/components/UsFilterWarnings/UsFilterTopWarning';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import { nonMedical } from '../../../config';

const AddCareplanModalFooter = ({ componentType, actionType }) => {
  const {
    currentStep,
    setCurrentStep,
    dispatchFormSubmittor,
    dispatchFormValidator,
    isTemplateSelected,
    showUnder18,
    setShowUnder18,
    careplanHeaderFooter,
    usWarningCheckboxChecked,
    bottomPageReached,
    showUnder18Warning,
    setUsWarningCheckboxChecked,
    isCaretaskDataLoading,
  } = useContext(PatientOverviewContext);

  const disableButton =
    !showUnder18Warning &&
    (!usWarningCheckboxChecked.awareCheckbox ||
      !usWarningCheckboxChecked.confirmCheckbox);
  const [nextClicked, setNextClicked] = useState(false);
  const [showSendButton, setShowSendButton] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(true);

  const formSubmittor = submissionType => {
    if (buttonClicked) {
      setButtonClicked(false);
      dispatchFormSubmittor({
        component: componentType,
        action: actionType,
        submissionType,
      });
    }
  };
  useEffect(() => {
    setUsWarningCheckboxChecked({
      awareCheckbox: false,
      confirmCheckbox: false,
    });
  }, [isTemplateSelected]);

  useEffect(() => {
    const checkUserCredential = async (component, action) => {
      return axios(serviceConfig.brokerService.checkUserCredential.uri, {
        params: {
          component,
          action,
        },
      })
        .then(response => {
          if (response.data.hasCredential) return response.data.hasCredential;
          return false;
        })
        .catch(() => {
          setShowSendButton(false);
        });
    };
    checkUserCredential(
      ComponentTranslationKeys.CAREPLAN_TEMPLATE,
      ActionTranslationKeys.SEND
    );
  }, []);

  if (currentStep === 1) {
    return (
      <>
        {isTemplateSelected === -1 && (
          <Flex justifyContent="flex-start" otherStyles={{ flex: 1 }}>
            <div style={{ marginTop: '15px' }}>
              <Icon name="error" size={20} fill={ActionColors.ERROR} />
            </div>
            <h3 className="select-patient">
              {I18n.t('warningMessage.oneTemplateSelection')}
            </h3>
          </Flex>
        )}
        {showUnder18 && (
          <Flex alignItems="center" otherStyles={{ flex: 4 }}>
            <UsFilterTopWarning
              message={I18n.t('newCareplan_view.under18')}
              showUsWarningTopPage
              showEditIcon={false}
              showFilter={false}
            />
          </Flex>
        )}
        <Flex justifyContent="flex-end" otherStyles={{ flex: 1 }}>
          <Button
            label={I18n.t('common_buttons.next')}
            type={ButtonType.SUBMIT}
            onClick={() => {
              setNextClicked(true);
              if (!showUnder18 && isTemplateSelected > -1) {
                setCurrentStep(currentStep + 1);
              }
            }}
            disabled={showUnder18 || !!isCaretaskDataLoading}
          />
        </Flex>
      </>
    );
  }
  if (currentStep !== 4) {
    return (
      <Flex justifyContent="space-between" otherStyles={{ flex: 1 }}>
        <Button
          label={I18n.t('common_buttons.back')}
          onClick={() => {
            setCurrentStep(currentStep - 1);
            setShowUnder18(false);
            dispatchFormValidator({
              component: '',
              action: '',
              shouldValidate: false,
            });
          }}
          variant={ButtonVariant.OUTLINED}
        />
        <Flex flexDirection="row" justifyContent="flex-end">
          {currentStep === 2 && (
            <Button
              label={I18n.t('common_buttons.next')}
              type={ButtonType.SUBMIT}
              onClick={() => {
                dispatchFormValidator({
                  component: componentType,
                  action: actionType,
                  shouldValidate: true,
                });
              }}
            />
          )}
          {currentStep === 3 && !bottomPageReached && <ScrollDownComponent />}
          {currentStep !== 2 && (
            <Button
              label={I18n.t('common_buttons.next')}
              type={ButtonType.SUBMIT}
              onClick={() => {
                setCurrentStep(currentStep + 1);
              }}
              disabled={currentStep === 3 && disableButton}
            />
          )}
        </Flex>
      </Flex>
    );
  }
  return (
    <>
      <Button
        label={I18n.t('common_buttons.back')}
        onClick={() => {
          if (
            showUnder18Warning &&
            (nonMedical == false || nonMedical == 'false')
          ) {
            setCurrentStep(currentStep - 2);
          } else {
            setCurrentStep(currentStep - 1);
          }
        }}
        variant={ButtonVariant.OUTLINED}
      />
      <Flex justifyContent="flex-end" otherStyles={{ flex: 1 }}>
        {!careplanHeaderFooter.startNow && (
          <>
            <div style={{ marginRight: '10px' }}>
              <Button
                label={I18n.t('common_buttons.save')}
                type={ButtonType.SUBMIT}
                onClick={() => formSubmittor(SubmissionTypes.ASSIGN_ONLY)}
                variant={ButtonVariant.OUTLINED}
              />
            </div>
            {showSendButton && !careplanHeaderFooter.isAssessment && (
              <Button
                label={I18n.t('assignTemplate_view.send_immediately')}
                type={ButtonType.SUBMIT}
                onClick={() => formSubmittor(SubmissionTypes.ASSIGN_AND_SEND)}
              />
            )}
          </>
        )}
        {careplanHeaderFooter.startNow && (
          <Button
            label={
              careplanHeaderFooter.isAssessment
                ? I18n.t('newCareplan_view.startNow')
                : I18n.t('newCareplan_view.answerNow')
            }
            type={ButtonType.SUBMIT}
            onClick={() => formSubmittor(SubmissionTypes.ASSIGN_ONLY)}
          />
        )}
      </Flex>
    </>
  );
};

export default AddCareplanModalFooter;
