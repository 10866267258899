import React from 'react';
import ASSET_TYPES from '../../../../../../../Utils/Asset/assetTypes';

export function ShowFileInRectangle({ assetTypeId, mediaURL }) {
  switch (assetTypeId) {
    case ASSET_TYPES.VIDEO:
      return (
        <video
          playsInline
          controls={true}
          className="media-obj"
          frameBorder="0"
          src={mediaURL}
          type="video/mp4"
        />
      );
    case ASSET_TYPES.IMAGE:
      return <img src={mediaURL} className="media-obj" frameBorder="0" />;
    case ASSET_TYPES.DOCUMENT:
      return <iframe src={mediaURL} className="media-obj" frameBorder="0" />;
    case ASSET_TYPES.AUDIO:
      return (
        <audio
          playsInline
          controls={true}
          className="media-audio"
          frameBorder="0"
          src={mediaURL}
          type="audio/mp3"
        />
      );
  }
}
