import React from 'react';

import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';
import Icon from '../../../../../../caro-ui-commonfiles/components/Icon/Icon';

import { useCheckCurrentPasswordBoxService } from './CheckCurrentPasswordBox.service';
import { CurrentPasswordButtonState } from '../UpdatePasswordComponent.type';
import { CheckCurrentPasswordBoxProps } from './CheckCurrentPasswordBox.type';

import styles from './CheckCurrentPasswordBox.module.scss';

function CheckCurrentPasswordBox({
  state,
  onClick,
}: CheckCurrentPasswordBoxProps) {
  const { dots, containerClassName, dotClassName, fillColor } =
    useCheckCurrentPasswordBoxService({
      state,
    });
  return (
    <div onClick={onClick} className={containerClassName}>
      {state === CurrentPasswordButtonState.Validating ? (
        dots.map(index => <Box key={index} className={dotClassName(index)} />)
      ) : (
        <Icon
          className={styles.box__icon}
          name="Tick"
          size={15}
          fill={fillColor}
        />
      )}
    </div>
  );
}

export default CheckCurrentPasswordBox;
