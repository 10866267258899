import { useState, useEffect } from 'react';

import { MEDIA_QUERIES } from '@ui-common-files/utils';

const useAssignmentCardViewerService = (
  tabsList,
  isChatOpened,
  windowWidth
) => {
  const [startIndex, setStartIndex] = useState(0);
  const [maxItemsNumber, setMaxItemsNumber] = useState(0);
  const [navigationItems, setNavigationItems] = useState(tabsList);
  const [displayNavigationArrow, setDisplayNavigationArrow] = useState(true);
  const [navigationBarWidth, setNavigationBarWidth] = useState(100);

  useEffect(() => {
    if (windowWidth >= MEDIA_QUERIES.SCREEN_XL) {
      setNavigationBarWidth(isChatOpened ? 100 : 80);
    } else if (windowWidth >= MEDIA_QUERIES.SCREEN_L || windowWidth >= MEDIA_QUERIES.SCREEN_MD) {
      setNavigationBarWidth(100);
    } else if (windowWidth > MEDIA_QUERIES.SCREEN_SM_IPAD) {
      setNavigationBarWidth(isChatOpened ? 65 : 100);
    } else {
      setNavigationBarWidth(isChatOpened ? 55 : 100);
    }
  }, [windowWidth, isChatOpened]);

  useEffect(() => {
    let maxItemsPerNavigation = 0;

    const getMaxItems = () => {
      if (windowWidth > MEDIA_QUERIES.SCREEN_XL) {
        return isChatOpened ? 8 : 9;
      }
      if (windowWidth === MEDIA_QUERIES.SCREEN_XL) {
        return isChatOpened ? 7 : 9;
      }
      if (windowWidth >= MEDIA_QUERIES.SCREEN_L) {
        return isChatOpened ? 5 : 7;
      }
      if (
        windowWidth > MEDIA_QUERIES.SCREEN_SM_IPAD &&
        windowWidth < MEDIA_QUERIES.SCREEN_L
      ) {
        return isChatOpened ? 4 : 6;
      }
      return isChatOpened ? 2 : 4;
    };

    maxItemsPerNavigation = getMaxItems();

    const calculateNewNavigationItems = () => {
      let endIndex = Math.min(
        startIndex + maxItemsPerNavigation,
        tabsList.length
      );

      if (endIndex - startIndex < maxItemsPerNavigation) {
        setStartIndex(Math.max(0, endIndex - maxItemsPerNavigation));
        endIndex = startIndex + maxItemsPerNavigation;
      }
      return tabsList.slice(startIndex, endIndex);
    };

    const newNavigationItems = calculateNewNavigationItems();
    setNavigationItems(newNavigationItems);
    setMaxItemsNumber(maxItemsPerNavigation);
    setDisplayNavigationArrow(newNavigationItems.length < tabsList.length);
  }, [windowWidth, tabsList, isChatOpened, startIndex, maxItemsNumber]);

  const handleNext = () => {
    setStartIndex(index => {
      const nextIndex = index + 1;
      const endIndex = nextIndex + maxItemsNumber;
      if (endIndex <= tabsList.length) {
        return nextIndex;
      }
      return index;
    });
  };

  const handlePrev = () => {
    setStartIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  return {
    navigationItems,
    displayNavigationArrow,
    handleNext,
    handlePrev,
    navigationBarWidth,
  };
};
export default useAssignmentCardViewerService;
