import { ComponentMinMax } from '../FilterCriteria/FilterCriteriaComponentAnswer/FilterCriteriaComponentAnswer.enum';
import {
  CheckType,
  FieldType,
} from '../FilterCriteria/FilterCriteriaFieldSelector/FilterCriteriaFieldSelector.type';

export enum FieldTypes {
  String = 'string',
  Record = 'record',
  Date = 'date',
  Integer = 'integer',
  Select = 'select',
  ICD = 'icd',
  OPS = 'ops',
  CareplanName = 'careplans.name',
  ComponentTypeAndName = 'component.type_name',
  ComponentAnswer = 'component.answer',
}

export enum FilterTypes {
  Patient = 'patient',
  Caretask = 'caretask',
}

export type PatientFilterSelectLabelValue =
  | {
      value?: string | Array<any>;
      labelLocation?: string;
      label?: string;
      id?: string;
    }
  | Date
  | boolean
  | string;

export interface CriteriaType {
  length: number;
  field: FieldType;
  check: CheckType;
  value: PatientFilterSelectLabelValue[];
  position?: number;
  id: string;
  careplanComponentId?: number;
  careplanComponentTypeId?: number;
  careplanComponentMinMax?: ComponentMinMax;
}

export interface CriteriaList {
  [FilterTypes.Patient]: CriteriaType[];
  [FilterTypes.Caretask]: CriteriaType[];
}

export type PatientFilterErrorType = {
  label: string;
  options: {
    [key: string]: string;
  };
};
