import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { NavigationJSON } from '@ui-common-files/utils';
import Actions from '../../../Utils/actions';
import { resetPatientData } from '../../../../actions/patients';
import { onTableChangeAction } from '../../../../actions/Datatables/patients';

import { PatientResponse } from '../PatientListResponse.type';
import { calcCurrentPage } from '../../../Utils/DataTableUtilities';
import { PatientDataEffectProps } from './hooks.types';

const usePatientDataEffect = ({
  setPatients,
  setIsPatientDemographicData,
  setLoading,
  setModalData,
  setDisplayModal,
  setOperation,
  setDisplayFlashMsg,
  trackEventTrigger,
  user,
  patients,
  actionState,
  doFetch,
  setDoFetch,
  activeTab,
  getPatientsCounters,
  setIsModalLoading,
  displayModal,
  isModalLoading,
  setShowPatient,
}: PatientDataEffectProps) => {
  const patientReduxState = useSelector(
    (state: { patients: PatientResponse }) => state.patients
  );
  const reduxDispatcher = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      patientReduxState.getPatientsByQueryResponse &&
      patientReduxState.getPatientsByQueryResponse.data
    ) {
      const { data } = patientReduxState.getPatientsByQueryResponse;
      setPatients(data);
      setIsPatientDemographicData(
        patientReduxState.getPatientsByQueryResponse.data
          .isPatientDemographicData
      );
      setLoading(false);
    }
    if (patientReduxState.addPatientResponse) {
      const primaryInstitution = user.institutions.filter(
        inst => inst.userInstitution.isPrimary
      );
      setModalData({
        ...patientReduxState.addPatientResponse,
        sites: primaryInstitution.map(inst => ({
          value: inst.id,
          label: inst.name,
          site: { ...inst },
        })),
      });
      setDisplayModal(true);
      setOperation(Actions.Create);
    }
    if (patientReduxState.createPatientResponse) {
      trackEventTrigger({ category: 'patient', action: 'Create-Successful' });
      setDisplayModal(false);
      history.push(
        `/${NavigationJSON.PATIENT_OVERVIEW}/${patientReduxState.createPatientResponse.newPatientId}`,
        {
          isPatientCreatedFromWeb: true,
          ...(patientReduxState.createPatientResponse.isUnderage
            ? null
            : {
                message: {
                  type: patientReduxState.createPatientResponse.type,
                  content: patientReduxState.createPatientResponse.content,
                },
              }),
        }
      );

      reduxDispatcher(resetPatientData());
    }

    if (patientReduxState.editPatientResponse) {
      trackEventTrigger({ category: 'patient', action: 'Open-Edit-Modal' });
      setModalData(patientReduxState.editPatientResponse);
      setDisplayModal(true);
      setOperation(Actions.Edit);
      reduxDispatcher(resetPatientData());
    }
    if (patientReduxState.updatePatientResponse) {
      trackEventTrigger({ category: 'patient', action: 'Update-Successful' });
      setDisplayModal(false);
      setDoFetch(!doFetch);
      setIsModalLoading(false);
      setDisplayFlashMsg({
        display: true,
        content: t(patientReduxState.updatePatientResponse.content),
        type: patientReduxState.updatePatientResponse.type,
      });
      reduxDispatcher(resetPatientData());
    }
    if (patientReduxState.deletePatientResponse) {
      trackEventTrigger({ category: 'patient', action: 'Delete-Successful' });
      getPatientsCounters();
      const page = calcCurrentPage(
        patients.recordsTotal - 1,
        actionState.limit,
        actionState.currPage
      );
      if (page !== actionState.currPage) {
        reduxDispatcher(
          onTableChangeAction({
            dir: actionState.dir,
            column: actionState.column,
            page,
            pageSize: actionState.limit,
          })
        );
      } else {
        setDoFetch(!doFetch);
      }
      setDisplayFlashMsg({
        display: true,
        content: t(patientReduxState.deletePatientResponse.content),
        type: patientReduxState.deletePatientResponse.type,
      });
      reduxDispatcher(resetPatientData());
    }
    if (patientReduxState.patientRequestError) {
      if (displayModal) setDisplayModal(false);
      if (isModalLoading) setIsModalLoading(false);
      setDisplayFlashMsg({
        display: true,
        content: t(patientReduxState.patientRequestError.content),
        type: patientReduxState.patientRequestError.type,
      });
      if (patientReduxState.getPatientsByQueryResponse) {
        setLoading(false);
        setPatients(null);
      }
      reduxDispatcher(resetPatientData());
    }
    if (patientReduxState.showPatientResponse) {
      const { data, healthScore, scoringSchemeCategory } =
        patientReduxState.showPatientResponse;
      setShowPatient({
        show: true,
        data,
        healthScore,
        scoringSchemeCategory,
      });
      reduxDispatcher(resetPatientData());
    }
    if (patientReduxState.abortedRequest) {
      setLoading(false);
    }
  }, [patientReduxState, activeTab]);
};

export default usePatientDataEffect;
