import { useMemo, useCallback, useContext } from 'react';
import moment from 'moment';

import { lifespanEnum, SCREEN_CATEGORIES } from '@ui-common-files/utils';
import { Opacity } from '@ui-common-files/utils/colors';
import { useResponsiveScreen } from '@ui-common-files/utils/hooks';

import { GraphData } from '../Overview.type';

import { PatientOverviewContext } from '../../../PatientOverview/PatientOverview';

const useOverViewDataChartService = () => {
  const {
    filter,
    date: { start: startDate, end: endDate },
  } = useContext(PatientOverviewContext).devicesQuery;
  const screenSize = useResponsiveScreen();

  const getFormat = () => {
    switch (filter) {
      case lifespanEnum.DAY:
        return 'HH:00';
      case lifespanEnum.WEEK:
        return 'ddd DD';
      case lifespanEnum.MONTH:
      case lifespanEnum.YEAR:
        return 'DD MMM';
      case lifespanEnum.YTD:
        return 'MMM';
      default:
        return '';
    }
  };

  const generatePeriods = useCallback(
    (amount: number, unit: moment.unitOfTime.DurationConstructor): number[] => {
      const start = moment(startDate);
      const periods = Array.from({ length: amount + 1 }, (_, i) => {
        return start.clone().add(i, unit).valueOf();
      });
      return periods;
    },
    [startDate]
  );

  const getPeriods = useCallback((): number[] => {
    let endDateMonthNumber = 1;
    switch (filter) {
      case lifespanEnum.DAY:
        return generatePeriods(24, 'hours');
      case lifespanEnum.WEEK:
        return generatePeriods(7, 'days');
      case lifespanEnum.MONTH:
        return generatePeriods(30, 'days');
      case lifespanEnum.YEAR:
        return generatePeriods(12, 'months');
      case lifespanEnum.YTD:
        endDateMonthNumber = moment(endDate).month() + 1;
        return generatePeriods(endDateMonthNumber, 'months');
      default:
        return [];
    }
  }, [filter, generatePeriods, endDate]);

  const getTicks = useMemo(() => {
    return getPeriods();
  }, [getPeriods]);

  const getYAxisOrientation = (index: number, data: GraphData) => {
    const { isBloodPressureSelected } = data;
    if (!isBloodPressureSelected) {
      return index === 0 ? 'left' : 'right';
    }

    const { bloodPressureLineIndex } = data;
    if (bloodPressureLineIndex === 0) {
      return index <= 1 ? 'left' : 'right';
    }
    if (bloodPressureLineIndex === 1) {
      return index === 1 || index === 2 ? 'right' : 'left';
    }
    return 'right';
  };

  const getStrokeColor = (index: number, data: GraphData) => {
    const color = data.colorMap[`line${index + 1}`];
    return index === 0 ? color : `${color}${Opacity.OPACITY_60_HEX_DECIMAL}`;
  };

  const tickFormatter = (tick: number) => {
    return moment(tick).format(getFormat());
  };

  const xAxisIntervalRenderer = (isChatOpened: boolean) => {
    const isMonth = filter === lifespanEnum.MONTH;
    const isDay = filter === lifespanEnum.DAY;

    const isSmallScreen = screenSize !== SCREEN_CATEGORIES.SCREEN_XL;
    const interval =
      (isMonth && isChatOpened) ||
      (isMonth && !isChatOpened && isSmallScreen) ||
      (isDay && isSmallScreen)
        ? 1
        : 0;

    return interval;
  };

  return {
    getTicks,
    getYAxisOrientation,
    getStrokeColor,
    tickFormatter,
    xAxisIntervalRenderer,
  };
};

export default useOverViewDataChartService;
