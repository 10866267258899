/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import moment from 'moment';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import { NotificationType } from './Notification.type';

const NotificationRecord = ({ rowData, type, maxWidth, redirect }) => {
  const profilePic = rowData.patient.profilePicture;
  const processTimestamp = () => {
    const dataTimestamp = moment(rowData.timestamp);
    return moment().calendar(dataTimestamp, {
      sameDay: now => {
        const diff = moment().unix() - now.unix();
        if (diff >= 3600)
          return `[${Math.floor(diff / 3600)}] [${i18n.t(
            'chatMessages.hour'
          )}]`;
        if (diff >= 7200)
          return `[${Math.floor(diff / 3600)}] [${i18n.t(
            'chatMessages.hours'
          )}]`;
        if (diff < 120) return `[${i18n.t('chatMessages.now')}]`;
        return `[${Math.floor(diff / 60)}] [${i18n.t('chatMessages.minutes')}]`;
      },
      nextWeek: `[${i18n.t('chatMessages.last')} ${i18n.t(
        `recurrence.weekDays.${dataTimestamp.day() - 1}`
      )}]`,
      nextDay: `[${i18n.t('chatMessages.yesterday')}]`,
      sameElse: `[${dataTimestamp
        .locale(i18n.language)
        .format('DD MMM YYYY')}]`,
    });
  };

  const processTimeString = (time = '') =>
    time.includes(',')
      ? `${i18n.t(`chatMessages.${time.split(',')[0]}`)}, ${i18n.t(
          `chatMessages.${time.split(',')[1]}`
        )}`
      : i18n.t(`chatMessages.${time}`);

  const processMessage = () => {
    const { message } = rowData;
    return rowData.isTranslatable
      ? i18n.t(message.split('key:')[1].split(' file:')[0], {
          time: processTimeString(message.split('time:')[1]),
        })
      : message;
  };

  const renderChatMessageRowContent = useCallback(
    () => (
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        otherStyles={{ flex: 1, marginLeft: 10 }}
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          otherStyles={{ width: '100%' }}
        >
          <div className="notification__table-patient-name">
            {rowData.patient.firstname} {rowData.patient.lastname}
          </div>
          <div className="notification__row-timestamp">
            {processTimestamp()}
          </div>
        </Flex>
        <div
          className="notification__row-text"
          style={{ maxWidth: maxWidth - 110 }}
        >
          {processMessage()}
        </div>
      </Flex>
    ),
    [maxWidth, rowData]
  );

  const renderThresholdNotificationRowContent = useCallback(() => {
    return (
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        otherStyles={{ flex: 1, marginLeft: 10, wordBreak: 'break-word' }}
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          otherStyles={{ width: '100%' }}
        >
          <div className="notification__table-threshold-title">
            <span className="notification__table-patient-name">
              {rowData.patient.firstname} {rowData.patient.lastname}{' '}
            </span>
            <span>
              {i18n.t('chatMessages.careplanSubmission', {
                careplanName: rowData.careplanName,
              })}
            </span>
          </div>
          <div className="notification__row-timestamp">
            {processTimestamp()}
          </div>
        </Flex>
        <div className="notification__row-answer">
          <Box flex={1}>
            {i18n.t('threshold.rule')}: {rowData.rule}
          </Box>
          <Box flex={1}>
            {i18n.t('common_labels.answer')}: {rowData.answer}
          </Box>
        </div>
      </Flex>
    );
  }, [maxWidth, rowData]);

  const handleRowClick = () => {
    if (type === NotificationType.Chat) {
      redirect(rowData.patient.id, rowData.id);
    } else {
      redirect(rowData.patient.id, {
        careplanId: rowData.careplanId,
        careplanName: rowData.careplanName,
        answerTimestamp: rowData.timestamp,
        isCarepathway: rowData.isCarepathway,
        seen: rowData.seen,
        isAssessment: rowData.isAssessment,
        carePathwayId: rowData.carePathwayId,
        carePathwayName: rowData.carePathwayName,
        caretaskParentId: rowData.caretaskParentId,
        nodeId: rowData.nodeId,
      });
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      key={`${type}-${rowData.id}`}
      className="notification__row-content"
      onClick={handleRowClick}
      onKeyDown={handleRowClick}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <div
          className="notification__table-profile-pic"
          style={{
            backgroundImage: `url(${profilePic})`,
          }}
        />
        {type === NotificationType.Chat && renderChatMessageRowContent()}
        {type === NotificationType.Threshold &&
          renderThresholdNotificationRowContent()}
      </Flex>
    </div>
  );
};

NotificationRecord.propTypes = {
  rowData: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.string,
      PropTypes.array,
    ])
  ).isRequired,
  maxWidth: PropTypes.number.isRequired,
  redirect: PropTypes.func.isRequired,
  type: PropTypes.string,
};

NotificationRecord.defaultProps = {
  type: NotificationType.Chat,
};

export default React.memo(NotificationRecord);
