/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { HideFlashMessage } from '@global/Services';

import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import TextInput from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Dropdown from '../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import { PromptContent } from '../common/ConfirmationPrompt';
import ShowModalFooter from '../common/ShowModalFooter';
import Checkbox from '../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import RoleMatrix from './roleMatrix.js';

import serviceConfig from '../../../serviceConfig.json';
import { ButtonVariant } from '../../../caro-ui-commonfiles/utils/button';
import CheckBoxRadioVariants from '../../../caro-ui-commonfiles/utils/CheckboxRadio';
import tabPosition from '../../Utils/Administration/tabPosition';
import {
  MODAL_VARIANT,
  MODAL_FORM_DIRECTION,
} from '../../Utils/Layout/modalSection';

import '../../css/role.css';

let response;

const AddRole = ({
  openModal,
  setOpenModal,
  fetchedData,
  setFetchedData,
  setFlashMessage,
  reloadTable,
}) => {
  const services = useSelector(state => state.services);

  const [userRole, setUserRole] = useState('admin');
  const [roleName, setRoleName] = useState('');
  const [allowAllSites, setAllowAllSites] = useState(false);
  const [isPatientDemographicData, setIsPatientDemographicData] =
    useState(false);
  const [actions, setActions] = useState([]);
  const [actionsKeys, setActionsKeys] = useState([]);
  const [componentTypes, setComponentTypes] = useState([]);
  const [componentActions, setComponentActions] = useState([]);
  const [selectedActionCheckBoxes, setSelectedActionCheckBoxes] = useState([]);
  const [selectedComponentCheckBoxes, setSelectedComponentCheckBoxes] =
    useState([]);
  const [prefilledRoles, setPrefilledRoles] = useState([]);
  const [validationError, hasValidationError] = useState(false);
  const [roleUpdated, setRoleUpdated] = useState({
    display: false,
  });

  const setActionCheckBoxesOnChange = (
    prefilledComponentActions,
    calledOnChange
  ) => {
    const actionCheckboxesIdsCollection = [];
    response.data.componentTypes.map(type => {
      prefilledComponentActions.filter(action => {
        if (!calledOnChange) {
          action.allowed = true;
        }
        if (action.component == I18n.t(type.name)) {
          let componentName;
          let actionCheckboxId;
          componentName = I18n.t(type.name).replace(/\s/g, '');
          actionCheckboxId = {
            actionCheckboxId: `${componentName}_${action.action}`,
            isIndeterminate: false,
            isChecked: action.allowed == true,
          };
          actionCheckboxesIdsCollection.push(actionCheckboxId);
        }
      });
    });
    setSelectedActionCheckBoxes(actionCheckboxesIdsCollection);
  };

  const setComponentCheckBoxesOnChange = prefilledComponentActions => {
    const componentCheckBoxes = componentTypes.map(elem => {
      let updatedObject;
      const getComponentActions = prefilledComponentActions.filter(action => {
        return action.component == I18n.t(elem.name);
      });

      const isAnyComponentActionUnchecked = getComponentActions.some(
        elem => !elem.allowed
      );
      const areAllComponentActionsUnchecked =
        getComponentActions.filter(elem => !elem.allowed).length ==
        getComponentActions.length;

      if (isAnyComponentActionUnchecked) {
        updatedObject = {
          ...{},
          isIndeterminate: true,
          isChecked: true,
          checkboxId: I18n.t(elem.name).replace(/\s/g, ''),
        };
      } else if (!isAnyComponentActionUnchecked) {
        updatedObject = {
          ...{},
          isChecked: true,
          isIndeterminate: false,
          checkboxId: I18n.t(elem.name).replace(/\s/g, ''),
        };
      }
      if (areAllComponentActionsUnchecked) {
        updatedObject = {
          ...{},
          isIndeterminate: false,
          isChecked: false,
          checkboxId: I18n.t(elem.name).replace(/\s/g, ''),
        };
      }
      return updatedObject;
    });
    setSelectedComponentCheckBoxes(componentCheckBoxes);
  };

  const changeRole = async event => {
    setUserRole(event.name);
    const response = await axios.get(
      serviceConfig.brokerService.prefillComponentActions.uri + event.id,
      {
        params: {
          userLanguage: I18n.language,
        },
      }
    );
    const { prefilledComponentActions } = response.data;
    setComponentActions(prefilledComponentActions);
    setActionCheckBoxesOnChange(prefilledComponentActions, true);
    setComponentCheckBoxesOnChange(prefilledComponentActions);
  };

  const onChangeRoleNameText = event => {
    if (event.target.value) {
      hasValidationError(false);
    } else {
      hasValidationError(true);
    }
    setRoleName(event.target.value);
  };

  const getRoleMatrixData = () => {
    const roleMatrixData = [];
    const { permissions } = response.data.componentActions;
    componentTypes.forEach(componentType => {
      const componentTypeId = componentType.id;
      let binaryString = 0;
      const actionsForType = selectedActionCheckBoxes.filter(action => {
        const componentName = action.actionCheckboxId.split('_');
        return (
          componentName[0] == I18n.t(componentType.name).replace(/\s/g, '')
        );
      });
      actionsForType.forEach(elem => {
        if (elem.isChecked) {
          const matchedPermission = permissions.find(
            permission =>
              permission.action === elem.actionCheckboxId.split('_')[1]
          );
          binaryString += parseInt(matchedPermission.permissionBitValue);
        }
      });
      roleMatrixData.push({ binaryString, componentTypeId });
    });
    return roleMatrixData;
  };

  const saveRoleMatrixData = async close => {
    if (roleName) {
      const matrixObject = getRoleMatrixData();
      const response = await axios.post(
        serviceConfig.brokerService.createRole.uri,
        {
          matrixObject,
          roleName,
          userLanguage: I18n.language,
          allowAllSites,
          isPatientDemographicData,
        }
      );
      validateCreatedRoleResponse(response);
      handleFlashMessages(response);

      reloadTable(tabPosition.FOURTH);
      close();
    } else {
      hasValidationError(true);
    }
  };

  const closeMessagePrompt = () => {
    setRoleUpdated({
      display: false,
    });
  };

  const validateCreatedRoleResponse = response => {
    if (response.status == 200 && response.data.message.type == 'success') {
      fetchedData.push({
        roleId: response.data.message.createdRole.id,
        roleName,
      });
      setFetchedData(fetchedData);
      setRoleUpdated({
        display: true,
      });
    }
  };

  const cancelAddRole = close => {
    hasValidationError(false);
    setOpenModal(false);
    close();
  };

  const handleFlashMessages = response => {
    if (response) {
      setFlashMessage(response.data.message);
    }
  };

  const onValueChange = value => {
    setAllowAllSites(value);
  };

  const onPatientDemographicsValueChange = value => {
    setIsPatientDemographicData(value);
  };

  const HeaderComponent = () => {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        otherStyles={{ width: '100%' }}
      >
        <Flex>
          <Box width="50%">
            <ComponentWithLabel
              id="roleName"
              htmlFor="roleName"
              labelText={`* ${I18n.t('rolemanager_view.label_roleName')}`}
            >
              <TextInput
                value={roleName}
                id="roleName"
                name="roleName"
                hasError={validationError}
                validationMessage={
                  roleName ? '' : I18n.t('parsley.requiredField')
                }
                placeholder={I18n.t('rolemanager_view.add_roleName')}
                handleOnChange={onChangeRoleNameText}
              />
            </ComponentWithLabel>
          </Box>

          <Box width="50%">
            <ComponentWithLabel
              id="rolePreset"
              htmlFor="rolePreset"
              labelText={I18n.t('rolemanager_view.label_presetRole')}
            >
              <Dropdown
                value={{ value: userRole, label: userRole }}
                options={prefilledRoles}
                placeholder=""
                handleOnChange={changeRole}
              />
            </ComponentWithLabel>
          </Box>
        </Flex>
        <Box className="allow_all_sites_checkbox">
          <Checkbox
            id="allowAllSites"
            label={I18n.t('rolemanager_view.label_hideAllSites')}
            variant={CheckBoxRadioVariants.DEFAULT}
            checked={allowAllSites}
            handleOnChange={e => onValueChange(e.target.checked)}
          />
        </Box>
        <Box className="allow_all_sites_checkbox">
          <Checkbox
            id="isPatientDemographicData"
            label={I18n.t('rolemanager_view.label_allowPatientDemographics')}
            variant={CheckBoxRadioVariants.DEFAULT}
            checked={isPatientDemographicData}
            handleOnChange={e =>
              onPatientDemographicsValueChange(e.target.checked)
            }
          />
        </Box>
      </Flex>
    );
  };

  const Content = () => (
    <ModalContent>
      <ModalForm
        formId="create-role-form"
        handleFormSubmit={() => {}}
        direction={MODAL_FORM_DIRECTION.COLUMN}
      >
        <ModalSection hasColumnDirection variant={MODAL_VARIANT.TOP}>
          {HeaderComponent()}
        </ModalSection>

        <ModalSection
          hasOverflow
          hasColumnDirection
          variant={MODAL_VARIANT.BOTTOM}
        >
          <RoleMatrix
            value={{
              response,
              actions,
              actionsKeys,
              componentTypes,
              selectedActionCheckBoxes,
              componentActions,
              selectedComponentCheckBoxes,
            }}
            setSelectedActionCheckBoxes={setSelectedActionCheckBoxes}
            setActionCheckBoxesOnChange={setActionCheckBoxesOnChange}
            setSelectedComponentCheckBoxes={setSelectedComponentCheckBoxes}
            setComponentCheckBoxesOnChange={setComponentCheckBoxesOnChange}
          />
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );

  const Footer = ({ close }) => {
    return (
      <Flex
        justifyContent="flex-end"
        otherStyles={{
          flex: 1,
        }}
      >
        <Box margin="0 16px 0 0">
          <Button
            label={I18n.t('common_buttons.cancel')}
            onClick={() => cancelAddRole(close)}
            variant={ButtonVariant.OUTLINED}
          />
        </Box>

        <Button
          label={I18n.t('common_buttons.save')}
          onClick={() => saveRoleMatrixData(close)}
        />
      </Flex>
    );
  };

  useEffect(() => {
    let actionList = [];
    let actionKeyList = [];
    const fetchUserRolesData = async () => {
      response = await axios.get(serviceConfig.brokerService.addRole.uri, {
        params: {
          userLanguage: I18n.language,
        },
      });
      if (!response.data.message) {
        setPrefilledRoles(response.data.prefilledRoles.rows);
        actionList = [
          ...new Set(
            response.data.componentActions.allComponentActions.map(
              item => item.action
            )
          ),
        ];
        actionKeyList = [
          ...new Set(
            response.data.componentActions.allComponentActions.map(
              item => item.actionKey
            )
          ),
        ];
        setActions(actionList);
        setActionsKeys(actionKeyList);
        setComponentTypes(response.data.componentTypes);
        setComponentActions(response.data.componentActions.allComponentActions);
        const componentsCheckboxes = response.data.componentTypes.map(type => {
          return {
            checkboxId: I18n.t(type.name).replace(/\s/g, ''),
            isIndeterminate: false,
            isChecked: true,
          };
        });
        setSelectedComponentCheckBoxes(componentsCheckboxes);
        setActionCheckBoxesOnChange(
          response.data.componentActions.allComponentActions,
          false
        );
        setRoleName('');
        setUserRole('admin');
        setAllowAllSites(false);
        setIsPatientDemographicData(false);
      }
    };
    fetchUserRolesData();
  }, [openModal]);
  return (
    <div>
      <Modal
        title={I18n.t('newRole_view.label_addUserRole')}
        contentComponent={Content()}
        footerComponent={<Footer close={() => setOpenModal(false)} />}
        openModal={openModal}
        onClose={() => cancelAddRole(close)}
        hideFlashMessage={HideFlashMessage}
      />
      {roleUpdated.display && (
        <Modal
          isConfirmationDialog
          title={I18n.t('common_labels.msg_role_update_headline')}
          contentComponent={
            <PromptContent message={I18n.t('common_labels.msg_role_update')} />
          }
          footerComponent={<ShowModalFooter close={closeMessagePrompt} />}
          openModal={roleUpdated.display}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </div>
  );
};

export default withTranslation()(AddRole);
