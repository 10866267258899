import React, { useState, useEffect } from 'react';

import { useDelaySearch } from '@global/Hooks';

let controller: AbortController;

const useInteractiveTable = (fetchData, reloadTable, recordsTotal) => {
  const [query, setQuery] = useState({
    value: '',
    start: 0,
    length: 10,
    dir: 'asc',
    column: 'name',
  });
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'name',
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageState, setPageState] = useState(10);
  const [keyword, setKeyword] = useState(query.value);

  useEffect(() => {
    setQuery(prevQuery => ({
      ...prevQuery,
      dir: sort.dir,
      column: sort.column,
    }));
  }, [sort.dir, sort.column]);

  useEffect(() => {
    controller = new AbortController();
    fetchData(controller);

    return () => controller.abort();
  }, [reloadTable, keyword, currentPage, pageState, query.dir, query.column]);

  const handleSearch = e => {
    setQuery(prevQuery => ({
      ...prevQuery,
      value: e.target.value,
    }));
    setCurrentPage(0);
    setPageState(10);
    controller.abort();
  };

  const handleTableOnChange = (index, size) => {
    const newStart = index * size > recordsTotal ? 0 : index * size;

    setQuery(prevQuery => ({
      ...prevQuery,
      start: newStart,
      length: size,
    }));
    setCurrentPage(newStart === 0 ? 0 : index);
    setPageState(size);
  };

  useDelaySearch({
    input: query.value,
    callback: () => {
      setKeyword(query.value);
    },
  });

  return {
    sort,
    setSort,
    query,
    currentPage,
    handleSearch,
    handleTableOnChange,
  };
};

export default useInteractiveTable;
