import { ApiClient } from '@global/Services';

import serviceConfig from '../../../../serviceConfig.json';

import './CarepathwayTriggeredResult.styles.scss';

export const getCaretaskAnswersDate = (caretaskId, abortController) => {
  return ApiClient.GET({
    signal: abortController.signal,
    url: serviceConfig.brokerService.getCareplanAnswers.uri + caretaskId,
  });
};

export const getCaretaskAnswerByDate = (
  caretaskId,
  selectedDate,
  abortController
) => {
  return ApiClient.GET({
    signal: abortController.signal,
    url: serviceConfig.brokerService.getLinkedCareplanAnswersBySpecificDate.uri
      .replace(':caretaskId', caretaskId)
      .replace(':date', selectedDate),
  });
};
