import axios from 'axios';
import {
  requestConfigHandler,
  requestErrorHandler,
  responseDataHandler,
  responseErrorHandler,
} from '../Global/Services/API/Interceptors.service';

axios.interceptors.request.use(requestConfigHandler, requestErrorHandler);

axios.interceptors.response.use(responseDataHandler, responseErrorHandler);
