import React from 'react';

import { bmiCategories } from '@global/Careplan/Components/Utils';

import AnswerBloodPressureAndBMIBase from './AnswerBloodPressureAndBMIBase';
import { AnswerInputOption } from './AnswerBloodPressureAndBMI.type';

export default function AnswerBMI({ component, isEvaluating, hasSubScores }) {
  const answerHeight = component.answers[0].answer_bmi_height || 0;
  const answerWeight = component.answers[0].answer_bmi_weight || 0;
  const answerBMI =
    answerHeight !== 0 && answerWeight !== 0
      ? parseFloat(
          (answerWeight / ((answerHeight * answerHeight) / 10000)).toFixed(2)
        )
      : 0;

  const inputOptionHeight: AnswerInputOption = {
    label: 'bmiFields.height',
    unit: 'bmiFields.cm',
    name: 'height',
    value: answerHeight,
  };

  const inputOptionWeight: AnswerInputOption = {
    label: 'bmiFields.weight',
    unit: 'bmiFields.kg',
    name: 'weight',
    value: answerWeight,
  };

  const getIsChecked = option => {
    return (
      (option === bmiCategories[0] && answerBMI > 0 && answerBMI < 18.5) ||
      (option === bmiCategories[1] && answerBMI >= 18.5 && answerBMI < 25) ||
      (option === bmiCategories[2] && answerBMI >= 25 && answerBMI < 30) ||
      (option === bmiCategories[3] && answerBMI >= 30)
    );
  };

  return (
    <AnswerBloodPressureAndBMIBase
      component={component}
      isEvaluating={isEvaluating}
      hasSubScores={hasSubScores}
      inputOption1={inputOptionHeight}
      inputOption2={inputOptionWeight}
      optionTranslation="bmiOptions"
      getIsChecked={getIsChecked}
      answerBMI={answerBMI}
      isBMI
    />
  );
}
