/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import moment from 'moment';
import '../../css/user.css';

import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import FormGroupTitle from '../common/FormGroupTitle/FormGroupTitle';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import Historial from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialLayout';
import HistorialStep from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialStep';
import ImageProgressBar from '../../../caro-ui-commonfiles/components/Progressbar/CircularProgressBar';
import Tag from '../../../caro-ui-commonfiles/components/Tags/Tags';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import ICDViewer from '../../../caro-ui-commonfiles/components/ICD/ICDViewer';

import {
  ButtonType,
  ButtonVariant,
} from '../../../caro-ui-commonfiles/utils/button';

import { getComponent, getAction } from '../../Utils/History';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';
import { getScoreFraction } from '../../Utils/PatientOverview/patientOverviewHelper';
import { enableOps, nonMedical } from '../../../config';

const PatientModalShowContent = ({
  patient,
  logs,
  scoringSchemeCategory,
  isPatientDemographicData,
}) => {
  const dateFormat = 'DD-MM-YYYY';
  const renderHistorialPatient = () =>
    logs.map(log => (
      <Historial>
        <HistorialStep>
          <div className="show-patient-history">
            {log.action === 'actions.consent' ||
            log.action === 'actions.revoke' ? (
              <>
                <span>
                  {isPatientDemographicData
                    ? patient.medicalRecord
                    : `${patient.firstName} ${patient.lastName} ${getAction(
                        log.action
                      )}`}
                </span>
                <span>{I18n.t('admin_view.label-privacy-policy')}</span>
              </>
            ) : (
              <>
                <span>{`${getAction(log.action)} ${
                  isPatientDemographicData
                    ? patient.medicalRecord
                    : log.description
                }`}</span>
                <span>
                  {`${I18n.t('history.on')} ${getComponent(
                    log.component
                  )} ${I18n.t('history.by')} ${
                    log.user != null ? log.user.username : log.user
                  }`}
                </span>
              </>
            )}
            <span className="show-patient__history-date">
              {`${moment(log.timeOfAction)
                .locale(I18n.language)
                .format(dateFormat)} - ${moment(log.timeOfAction).format(
                'HH:mm'
              )}`}
            </span>
          </div>
        </HistorialStep>
      </Historial>
    ));

  return (
    <ModalContent>
      <ModalForm>
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <Box margin="40px 0 0 0">
            <Flex alignItems="center" flexDirection="column">
              <ImageProgressBar
                value={
                  nonMedical == false || nonMedical == 'false'
                    ? getScoreFraction(scoringSchemeCategory.id)
                    : 0
                }
                size={85}
                color={scoringSchemeCategory.color}
                imgSrc={patient.profilePicture}
                strokeWidth={3}
              />

              <ComponentWithLabel
                labelText={I18n.t('patients_view.label_medicalRecord')}
              >
                <span className="show-patient-label">
                  {patient.medicalRecord}
                </span>
              </ComponentWithLabel>
            </Flex>

            <div className="show-patient__history-label">
              <Label text={I18n.t('common_labels.history')} />
            </div>

            <div className="show-patient__historial-wrapper">
              {logs && renderHistorialPatient()}
            </div>
          </Box>
        </ModalSection>

        <ModalSection variant={MODAL_VARIANT.COL_W70}>
          {isPatientDemographicData && (
            <>
              <Flex
                className="form-group__divider"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <p className="form-group__title">
                  {I18n.t('patients_view.field-group_title_personal-data')}
                </p>
                <p className="form-group__warning">
                  {I18n.t('patients_view.field-group_demographic-data-warning')}
                </p>
              </Flex>

              <Flex flexDirection="row" justifyContent="space-between">
                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patientOverview.labelAge')}
                  >
                    {!patient.isBirthdayHardcoded
                      ? `${patient.age} ${I18n.t('lifespan.YEAR')}`
                      : null}
                  </ComponentWithLabel>
                </Box>

                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_gender')}
                  >
                    {patient.gender}
                  </ComponentWithLabel>
                </Box>
              </Flex>
            </>
          )}
          {!isPatientDemographicData && (
            <>
              <FormGroupTitle>
                {I18n.t('patients_view.field-group_title_personal-data')}
              </FormGroupTitle>

              <Flex flexDirection="row" justifyContent="space-between">
                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_firstName')}
                  >
                    {patient.firstName}
                  </ComponentWithLabel>
                </Box>

                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_lastName')}
                  >
                    {patient.lastName}
                  </ComponentWithLabel>
                </Box>
              </Flex>

              <Flex flexDirection="row" justifyContent="space-between">
                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_dateOfBirth')}
                  >
                    {!patient.isBirthdayHardcoded
                      ? `${moment(patient.birthDate)
                          .locale(I18n.language)
                          .format(dateFormat)} (${I18n.t(
                          'patientOverview.labelAge'
                        )}: ${moment().diff(patient.birthDate, 'years')} )`
                      : null}
                  </ComponentWithLabel>
                </Box>

                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_gender')}
                  >
                    {patient.gender}
                  </ComponentWithLabel>
                </Box>
              </Flex>

              <FormGroupTitle>
                {I18n.t('patients_view.field-group_title_contact-information')}
              </FormGroupTitle>

              <Flex flexDirection="row" justifyContent="space-between">
                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_email')}
                  >
                    <span className="show-patient__email">{patient.email}</span>
                  </ComponentWithLabel>
                </Box>

                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_mobile_number')}
                  >
                    {patient.mobile}
                  </ComponentWithLabel>
                </Box>
              </Flex>

              <Flex flexDirection="row" justifyContent="space-between">
                <Box flex="1">
                  <ComponentWithLabel labelText={I18n.t('appointment.place')}>
                    <span className="show-patient__email">
                      {patient.address}
                    </span>
                  </ComponentWithLabel>
                </Box>

                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_city')}
                  >
                    {patient.city}
                  </ComponentWithLabel>
                </Box>
              </Flex>

              <Flex flexDirection="row" justifyContent="space-between">
                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_postal-code')}
                  >
                    <span className="show-patient__email">
                      {patient.postalCode}
                    </span>
                  </ComponentWithLabel>
                </Box>

                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_country')}
                  >
                    {patient.country}
                  </ComponentWithLabel>
                </Box>
              </Flex>

              <Flex flexDirection="row" justifyContent="space-between">
                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_insurance-type')}
                  >
                    <span className="show-patient__email">
                      {patient.InsuranceType}
                    </span>
                  </ComponentWithLabel>
                </Box>

                <Box flex="1">
                  <ComponentWithLabel
                    labelText={I18n.t('patients_view.label_insurance-company')}
                  >
                    {patient.InsuranceCompany}
                  </ComponentWithLabel>
                </Box>
              </Flex>

              <ComponentWithLabel
                labelText={I18n.t('patients_view.label_insurance-number')}
              >
                {patient.InsuranceNumber}
              </ComponentWithLabel>
            </>
          )}

          <FormGroupTitle>
            {I18n.t('patients_view.field-group_title_medical-information')}
          </FormGroupTitle>

          <Flex flexDirection="row" justifyContent="space-between">
            <Box flex="1">
              <ComponentWithLabel
                labelText={I18n.t('patients_view.label_medicalRecord')}
              >
                {patient.medicalRecord}
              </ComponentWithLabel>
            </Box>

            <Box flex="1">
              <ComponentWithLabel
                labelText={I18n.t('patients_view.label_case-number')}
              >
                {patient.caseNumber}
              </ComponentWithLabel>
            </Box>
          </Flex>

          <Box flex="1">
            <ComponentWithLabel
              labelText={I18n.t('patients_view.label_pseudonymCode')}
            >
              {patient.pseudonymCode}
            </ComponentWithLabel>
          </Box>

          {(enableOps == true || enableOps == 'true') && (
            <>
              {patient.ops.length > 0 && (
                <ComponentWithLabel labelText={I18n.t('task.OPSReference')}>
                  <ICDViewer icds={patient.ops} shouldShowAll />
                </ComponentWithLabel>
              )}
            </>
          )}

          {patient.icds.length > 0 && (
            <ComponentWithLabel labelText={I18n.t('task.ICDReference')}>
              <ICDViewer icds={patient.icds} shouldShowAll />
            </ComponentWithLabel>
          )}
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
};

PatientModalShowContent.propTypes = {
  data: PropTypes.shape.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const PatientModalShowFooter = ({ close }) => {
  return (
    <div className="show-patient__modal-footer">
      <Button
        label={I18n.t('common_buttons.close')}
        type={ButtonType.BUTTON}
        onClick={() => {
          close();
        }}
        variant={ButtonVariant.CONTAINED}
      />
    </div>
  );
};

PatientModalShowFooter.propTypes = {
  close: PropTypes.func.isRequired,
};

export { PatientModalShowContent, PatientModalShowFooter };
