import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionColors } from '../../utils/colors';
import '../../styles/main.scss';
import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import Flex from '../Flex/Flex';

const UsFilterBottomWarning = ({ message }) => {
  const [showUsWarningBottomPage, setShowUsWarningBottomPage] = useState(true);
  if (showUsWarningBottomPage) {
    return (
      <div className="patient-index__us-warning">
        <Flex alignItems="center" otherStyles={{ padding: 20 }}>
          <Icon name="WarningFilter" size={39} fill={ActionColors.ERROR} />
        </Flex>
        <Box margin="auto" padding="4px 0">
          <span className="patient-index__us-warning-txt">{message}</span>
        </Box>
        <Flex
          alignItems="flex-start"
          otherStyles={{ paddingTop: 8, paddingRight: 8, paddingLeft: 20, }}
        >
          <button
            type="button"
            onClick={() => {
              setShowUsWarningBottomPage(false);
            }}
          >
            <Icon name="close" size={24} />
          </button>
        </Flex>
      </div>
    );
  }
  return null;
};

UsFilterBottomWarning.propTypes = {
  message: PropTypes.string.isRequired,
};

export default UsFilterBottomWarning;
