import React, { useEffect, useMemo, useState } from 'react';
import { getRabitMQStatus } from './QueueCheck.api';
import serviceConfig from '../../../../serviceConfig.json';

import { Tag } from '@ui-common-files/components';

import { ActionColors } from '@ui-common-files/utils';

const getQueueStatus = () => {
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const prepareDatatableRows = data => {
      const retrievedData = [...data];
      const rows = [];
      retrievedData.forEach(info => {
        let key = info[0].charAt(0).toUpperCase() + info[0].substring(1);
        rows.push({
          key: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
          value: info[1] === 'true' ? 'OK' : 'Not Available',
        });
      });
      return rows;
    };

    const fetchData = async () => {
      const url = serviceConfig.brokerService.getRabbitMQStatus.uri;
      const response = await getRabitMQStatus(url);

      if (response?.data) {
        setFetchedData(prepareDatatableRows(Object.entries(response.data)));
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: () => {
          return;
        },
        accessor: 'key',
        headerClassName: '',
        sortable: true,
        filterable: false,
      },
      {
        Header: () => {
          return '';
        },
        accessor: 'value',
        headerClassName: '',
        sortable: true,
        filterable: false,
        Cell: ({ row }) => {
          return (
            <Tag
              text={row.value}
              hasMark
              fill={
                row.value === 'OK' ? ActionColors.SUCCESS : ActionColors.ERROR
              }
            />
          );
        },
      },
    ];
  }, []);
  return {
    fetchedData,
    loading,
    columns,
  };
};
export default getQueueStatus;
