import React from 'react';
import PropTypes from 'prop-types';

const DatePill = ({ date }) => {
  return (
    <div className="chat__date-pill">
      <p className="chat__date-pill__text">{date}</p>
    </div>
  );
};

DatePill.propTypes = {};

export default DatePill;
