import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import tabPosition from '../../Utils/Administration/tabPosition';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';

const TelemedicineHeader = ({
  isEnabled,
  execVideoCall,
  activeTab,
  isChatServiceActive = true,
  isReviewReport,
}) => {
  const { t } = useTranslation();

  const tabLabels = [
    t('patientOverview.chat'),
    t('notes.tab_label'),
    t('common_labels.answer_caretask'),
  ];

  return (
    <div className="chat__header">
      <Flex flexDirection="column" otherStyles={{ flex: 1 }}>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          otherStyles={{ flex: 1, margin: '0 20px 16px 16px' }}
        >
          <Box
            className=" tab-list__item tab-list__item--active"
            otherStyles={{ textAlign: 'left' }}
          >
            {isReviewReport && activeTab === tabPosition.THIRD
              ? t('common_labels.answer_review_caretask')
              : tabLabels[activeTab - 1]}
          </Box>
          {isChatServiceActive && (
            <Button
              label={t('common_buttons.videoCall')}
              onClick={execVideoCall}
              disabled={!isEnabled || activeTab == tabPosition.THIRD}
            >
              <Icon name="call" size={20} fill="white" />
            </Button>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

TelemedicineHeader.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  execVideoCall: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
};

export default TelemedicineHeader;
