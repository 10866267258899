import React from 'react';

import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Icon from '../../../../../caro-ui-commonfiles/components/Icon/Icon';

import { getIsThreshold } from '../../../ReportGenerator/Careplan/CareplanReport.utils';
import { alphapet } from '../../CareplanAdditionalComponentUtils';
import {
  ActionColors,
  PrimaryColor,
} from '../../../../../caro-ui-commonfiles/utils/colors';
import {
  CarePlanReportOccurrence,
  CareplanComponent,
  CareplanComponentAnswer,
} from '../../../../types/Careplan';
import componentTypeOrder from '../../careplanComponentTypeOrder';

export const renderComponentOptions = (
  allOccurrences: CarePlanReportOccurrence[],
  componentData: CareplanComponent,
  componentIndex: number
): JSX.Element[] => {
  return componentData.optionJson.options.map(
    (option: string, index: number) => (
      <Flex
        key={`col-option-${componentIndex}-${index}`}
        className="answer-component_options-item"
      >
        <Box flex={1} className="answer-component_options-item-title">
          <Flex alignItems="center">
            <Box className="answer-component_options-item-title-index">
              {alphapet[index]}
            </Box>
            <Box flex={1}>{option}</Box>
          </Flex>
        </Box>
        <Flex className="answer-component_options-item-columns-section">
          {renderComponentOptionContent(
            allOccurrences,
            componentData,
            componentIndex,
            option,
            index
          )}
        </Flex>
      </Flex>
    )
  );
};

const renderComponentOptionContent = (
  allOccurrences: CarePlanReportOccurrence[],
  componentData: CareplanComponent,
  componentIndex: number,
  option: string,
  optionIndex: number
): JSX.Element[] => {
  return allOccurrences.map(
    (occurrence: CarePlanReportOccurrence, indexOccurrence: number) => {
      let optionContents: JSX.Element[];

      if (
        componentData.careplanComponentTypeId === componentTypeOrder.SORTABLE
      ) {
        const answers = componentData.answers.filter(
          (answer: CareplanComponentAnswer) =>
            answer.allOccurrenceId === occurrence.id
        );
        optionContents = answers.map(
          (answer: CareplanComponentAnswer, index: number) => {
            let componentContent: string | number;
            if (answer.isSkipped) {
              componentContent = '-';
            } else {
              componentContent = answer.answer_json.order[optionIndex] + 1;
            }

            return (
              <span
                key={`component_options-header-answer-${componentIndex}-${index}`}
                style={
                  componentContent.toString() === '1'
                    ? { color: PrimaryColor.MAIN_COLOR }
                    : {}
                }
              >
                {componentContent}
              </span>
            );
          }
        );
      } else if (
        componentData.careplanComponentTypeId ===
          componentTypeOrder.SINGLE_CHOICE ||
        componentData.careplanComponentTypeId ===
          componentTypeOrder.MULTIPLE_CHOICE
      ) {
        const answers = componentData.answers.filter(
          (answer: CareplanComponentAnswer) => {
            if (answer.allOccurrenceId !== occurrence.id) return false;
            if (answer.answer_json) {
              if (answer.answer_json.optionIndex !== undefined) {
                return answer.answer_json.optionIndex === optionIndex;
              } else {
                return answer.answer_json[optionIndex];
              }
            }
            return false;
          }
        );

        optionContents = answers.map(
          (answer: CareplanComponentAnswer, index: number) => {
            return (
              <span
                key={`component_options-header-answer-${componentIndex}-${index}`}
              >
                <Icon
                  name="Tick"
                  size={14}
                  fill={
                    getIsThreshold(
                      componentData,
                      answer,
                      optionIndex.toString()
                    )
                      ? ActionColors.ERROR
                      : PrimaryColor.MAIN_COLOR
                  }
                />
              </span>
            );
          }
        );
      }

      return (
        <Flex
          key={`component_options-answer-${componentIndex}-${indexOccurrence}`}
          className="answer-component_options-item-columns-section-column"
          alignItems="center"
          justifyContent="center"
        >
          {optionContents}
        </Flex>
      );
    }
  );
};
