import { useMemo } from 'react';
import { formatDate } from '@global/Date';

const useShowAssetModalContentService = assetDataToShow => {
  const { assetData, fetchedPrivacyPolicyData } = assetDataToShow;

  const consentData = useMemo(
    () =>
      fetchedPrivacyPolicyData?.find(e => e.action === 'actions.consent') || {},
    [fetchedPrivacyPolicyData]
  );
  const revokeData = useMemo(
    () =>
      fetchedPrivacyPolicyData?.find(e => e.action === 'actions.revoke') || {},
    [fetchedPrivacyPolicyData]
  );

  const revokeTime = useMemo(
    () =>
      revokeData.timeOfAction > consentData.timeOfAction
        ? formatDate(revokeData.timeOfAction)
        : '-',
    [revokeData.timeOfAction, consentData.timeOfAction]
  );

  const additionalConsents = useMemo(
    () =>
      assetData.additionalConsents
        ? JSON.parse(assetData.additionalConsents)
        : [],
    [assetData.additionalConsents]
  );

  return {
    assetData,
    fetchedPrivacyPolicyData,
    consentData,
    revokeData,
    revokeTime,
    additionalConsents,
  };
};

export default useShowAssetModalContentService;
