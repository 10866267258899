import React, { useCallback } from 'react';
import I18n from 'i18next';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import AnswerHeaderComponent from '../AnswerHeaderComponent';

import { CareplanComponentAnswer } from '../../../../types/Careplan';
import { GeneralAnswerType } from '../GeneralAnswer.type';
import {
  occurrenceHasAnswer,
  getComponentOccurrencesAnswer,
} from '../AnswerResult.services';

import '../AnswerResultCommon.scss';

function FileRequestAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const renderComponentAnswers = useCallback(
    (occurrenceAnswers: CareplanComponentAnswer[], index: number) => {
      let answerContent = '-';
      if (occurrenceHasAnswer(occurrenceAnswers)) {
        answerContent = I18n.t('common_labels.Attached');
      }
      return (
        <Flex
          key={`col-header-${componentIndex}-${index}`}
          className="answer-component_header-columns-column"
          alignItems="center"
          justifyContent="center"
        >
          <span>{answerContent}</span>
        </Flex>
      );
    },
    []
  );

  return (
    <Box className="answer-component answer-component_upload_file">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName="UploadFile"
        title={componentData.name}
        columnOccurrencesRenderer={() => {
          return getComponentOccurrencesAnswer(
            allOccurrences,
            componentData.answers,
            renderComponentAnswers
          );
        }}
      />
    </Box>
  );
}
export default FileRequestAnswer;
