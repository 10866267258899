import React from 'react';
import { useTranslation } from 'react-i18next';

import Flex from '../Flex/Flex';

import useDatePickerService from './DatePicker.service';
import { DatePickerProps } from './DatePicker.type';
import DatePickerInput from './DatePickerInput';

import styles from './DatePicker.module.scss';

function DatePicker({
  date,
  handleDate,
  error,
  disabled = false,
}: DatePickerProps) {
  const { t } = useTranslation();
  const { inputList, onInput, errorList, errorMessage, refs } =
    useDatePickerService({
      date,
      handleDate,
      error,
    });
  return (
    <Flex className={styles.datePicker}>
      <Flex className={styles.datePicker__inputsContainer}>
        {inputList.map(inputType => (
          <DatePickerInput
            key={inputType}
            type={inputType}
            value={date[inputType]}
            onInput={onInput}
            error={errorList.includes(inputType)}
            refs={refs}
            disabled={disabled}
          />
        ))}
      </Flex>
      {!disabled && (
        <Flex className={styles.datePicker__error}>{t(errorMessage)}</Flex>
      )}
    </Flex>
  );
}

export default DatePicker;
