import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import TaskInformationOverview from './TaskInformationOverview';
import serviceConfig from '../../../serviceConfig.json';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import { lifespanUnits } from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import { dropdownTaskTypes } from '../../Utils/dropdownTypes';

const TaskShowModalContent = ({ data }) => {
  const lifespanUnitsObj = lifespanUnits();
  const { setFlashMessage, closeModal } = useContext(PatientOverviewContext);

  const [icds, setIcds] = useState(data.icdRecords ? data.icdRecords : []);

  const [startingArrays, setStartingArrays] = useState({
    startingDays: [],
    startingTimes: [],
  });
  const [values, setValues] = useState({
    name: data.name,
    taskType: data.taskTypeId ? dropdownTaskTypes()[data.taskTypeId - 1] : '',
    phaseType: '',
    taskPriority: '',
    startDate: moment(data.reminderElement.startsAt).toDate(),
    URL: data.taskURL ? data.taskURL : '',
    date: '',
    role: '',
    lifespan: 1,
    lifespanUnit: {},
    recurrenceText: '',
    ownershipLogs: [],
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          serviceConfig.brokerService.showTask.uri,
          {
            params: {
              
              reminderId: data.reminderElement.reminderId,
            },
          }
        );
        const responseData = response.data;
        if (responseData) {
          setStartingArrays({
            ...startingArrays,
            startingTimes: responseData.startingTimes,
          });
          setValues({
            ...values,
            phaseType: responseData.phaseType,
            taskPriority: responseData.taskPriority,
            role: responseData.role,
            lifespan: responseData.lifespan,
            lifespanUnit: lifespanUnitsObj[responseData.lifespanUnit],
            recurrenceText: responseData.recurrenceText,
            ownershipLogs: responseData.ownershipLogs,
          });
        }
      } catch (error) {
        closeModal();
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      }
    };
    getData();
  }, []);

  return (
    <ModalContent>
      <TaskInformationOverview
        values={values}
        startingArrays={startingArrays}
        icds={icds}
        showAssignee={false}
      />
    </ModalContent>
  );
};

TaskShowModalContent.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default TaskShowModalContent;
