import React from 'react';

import CareplanResultsModalFooter from 'src/Views/Careplans/CareplanResultsModalFooter';
import CareplanResultsModalContent from 'src/Views/Careplans/CareplanResultsModalContent';
import { Caretask } from '@type/Caretask';

const showCareplanAnswers = (
  careplan: Caretask,
  careplanData: any,
  label: string
) => {
  const careplanDataCopy = Array.isArray(careplanData)
    ? [...careplanData]
    : [careplanData];
  if (careplanDataCopy.length) {
    careplanDataCopy[0].template = {
      hasSubScoreFormula: careplan.hasSubScoreFormula,
    };
  }
  return {
    isOpen: true,
    title: `${label}: ${careplan.name}`,
    content: <CareplanResultsModalContent careplanData={careplanDataCopy} />,
    footer: <CareplanResultsModalFooter careplanData={careplanDataCopy} />,
  };
};
export default showCareplanAnswers;
