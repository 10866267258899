import React from 'react';

export enum TextInputType {
  EMAIL = 'email',
  PASSWORD = 'password',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
}

export enum TextInputAutoComplete {
  ON = 'on',
  OFF = 'off',
}

enum TextInputVariant {
  DEFAULT = 'default',
  WITH_ICON = 'with-icon',
  VALUE_RIGHT = 'value-right',
  CHAR_COUNT = 'char-count',
  INLINE_INPUT = 'inline-input',
}

export interface TextInputProps {
  type?: TextInputType;
  id?: string | number;
  name?: string;
  placeholder: string;
  isDisabled?: boolean;
  autoComplete: TextInputAutoComplete;
  hasError?: boolean;
  hasSuccess?: boolean;
  validationMessage?: string;
  variant?: TextInputVariant | string;
  isLeftIcon?: boolean;
  iconName?: string;
  isLinkWithIcon?: boolean;
  maxChars?: number;
  rightHelperText?: string;
  value?: string | number | null;
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
