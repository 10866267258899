import { ApiClient } from '@global/Services';

import serviceConfig from '../../../../../../../serviceConfig.json';

export const submitCarePathWayData = (
  isEditMode: boolean,
  data: object,
  id: number | null = null
) => {
  return ApiClient.POST({
    url: isEditMode
      ? serviceConfig.brokerService.updateCarePathway.uri + id
      : serviceConfig.brokerService.createCarePathway.uri,
    payload: data,
  });
};
