import React, {
  useState,
  useMemo,
  useReducer,
  useCallback,
  useEffect,
} from 'react';
import I18n from 'i18next';
import axios from 'axios';
import { findDescendants } from 'react-sortly';
import { useTranslation } from 'react-i18next';

import {
  bloodPressureCategories,
  bmiCategories,
} from '@global/Careplan/Components/Utils';
import { useResponsiveScreen } from '@ui-common-files/utils/hooks';
import { isScreenSmIpad } from '@ui-common-files/global/responsiveUtils/responsiveValidation.js';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import SingleSelectQuestionSearchable from '../../../caro-ui-commonfiles/components/SingleSelectQuestionSearchable/SingleSelectQuestionSearchable';
import { DnDView } from '../../../caro-ui-commonfiles/components/DnDComponent/DnDComponents';
import TextInput from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import renderYesNo from './typesRender/yesNo';
import renderFreeText from './typesRender/freeText';
import renderSingleChoice from './typesRender/singleChoice';
import renderMultipleChoice from './typesRender/multipleChoice';
import NumericValue from './typesRender/numericValue';
import NumericRange from './typesRender/numericRange';
import renderPainLocationChart from './typesRender/painLocationChart';
import renderSortable from './typesRender/sortable';
import renderGroupDescription from './typesRender/groupDescription';
import renderDatepicker from './typesRender/datepicker';
import renderUploadFile from './typesRender/uploadFile';
import renderBMI from './typesRender/bmi';
import LogicJumpContent from './LogicJump/LogicJumpContent';
import {
  displayThreshold,
  removeCareplanComponent,
  parseComponentName,
  getComponentsByHealthCategory,
  componentHealthCategories,
  isMainQuestion,
} from './CareplanHelperUtility';
import { shouldShowOrHideContentButton } from '../../Global/Careplan/Components/Utils';
import { displayJumps } from './LogicJump/logicJumpTags';
import componentTypeOrder from './careplanComponentTypeOrder';
import serviceConfig from '../../../serviceConfig.json';
import { ButtonVariant } from '../../../caro-ui-commonfiles/utils/button';
import {
  BackgroundColors,
  ActionColors,
} from '../../../caro-ui-commonfiles/utils/colors';

import '../../css/careplanComponent.scss';
import '../../css/role.css';
import '../../css/componentSettings.css';
import '../../css/logicJump.css';

import {
  useInitialEditableComponent,
  useIsLogicJumpSet,
} from '../../Utils/hooks/setIfEditable';
import { nonMedical } from '../../../config';

import maxCharLimit from '../../../caro-ui-commonfiles/utils/maxcharlimits.json';
import { FlashMessageTypes } from '../../../caro-ui-commonfiles/types/components';
import useDebounce from '../../Global/Hooks/UseDebounce/UseDebounce';
import { descriptionValidator } from '../Caretask/CaretaskBuilder/CaretaskBuilder.utils';

const ComponentBanner = ({
  additionalComponent,
  item,
  index,
  depth,
  hasDescendants,
  componentCollection,
  fetchData,
  getValidationErrors,
  promiseOptions,
  updateSearchTerm,
  isSearchTermComponent,
  isEditable,
  setIsEditable,
  getSearchableComponentValue,
  generateInnerComponentContent,
  setComponentCollection,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  removeComponent,
  careplanComponents,
}) => {
  const { t } = useTranslation();
  const editableComponent = useInitialEditableComponent(
    item || {
      id: -1,
      searchTerm: -1,
    },
    isEditable
  );

  const [showContent, setShowContent] = useReducer(
    (_state, show) => show,
    false
  );

  const logicJumpToggle = useIsLogicJumpSet(item, editableComponent);

  useEffect(() => {
    if (!showContent) setShowContent(editableComponent.showContent);
  }, [editableComponent]);

  const onChangeComponentNameText = event => {
    const matchedPosition = componentCollection.findIndex(elem => {
      return elem.id === parseInt(event.target.id);
    });
    const temp = validationErrorTemplate;
    const componentError =
      temp.templateSettingsValidation.noComponentsError[event.target.id];
    if (event.target.value && componentError) {
      delete temp.templateSettingsValidation.noComponentsError[event.target.id];
      hasValidationErrorTemplate({ ...temp });
    }
    const updatedCollection = [...componentCollection];
    if (typeof updatedCollection[matchedPosition].searchTerm === 'string')
      updatedCollection[matchedPosition].searchTerm = {};
    updatedCollection[matchedPosition].searchTerm.label = event.target.value;
    updatedCollection[matchedPosition].searchTerm.value = event.target.value;
    setComponentCollection(updatedCollection);
  };

  const updatEditableSections = () => {
    const actions = { ...isEditable };
    actions[item.id] = editableComponent;
    setIsEditable(actions);
  };

  const handleShowHideContainer = () => {
    updatEditableSections();
    setShowContent(!showContent);
    fetchData(item);
  };

  const isThresholdSet = useMemo(() => {
    if (item) {
      const shouldToggle = item.searchTerm.careplanComponentId
        ? item.searchTerm.shouldThresholdToggle
        : item.shouldThresholdToggle;
      return shouldToggle;
    }
    return false;
  }, [item.id, index, showContent, editableComponent]);

  const content = item.collection;
  const contentMessage = !showContent
    ? t('newCareplan_view.showContent')
    : t('newCareplan_view.hideContent');
  const thresholdToggle = isThresholdSet;

  const generateComponentIcon = useCallback(() => {
    const currIndex = `${index + 1}`;
    let variantClass = '';
    if (depth === 1) {
      variantClass = ' is-child';
    }
    if (hasDescendants === true) {
      variantClass = ' is-parent';
    }
    return (
      <div
        className={`componentIconParent${variantClass}`}
        key={`componentIconParent-${item.id}`}
      >
        <div className="componentIconContainer">
          <Icon name={item.collection.icon} size={24} />
        </div>
        <div className="componentCounterContainer">
          <span className="componentCounter">{currIndex}</span>
        </div>
      </div>
    );
  }, [depth, hasDescendants, index, item.collection.icon, item.id]);

  const {
    isItemWithNoComponentName,
    isItemWithTwoOptionsRequired,
    isItemWithEmptyOptionsRequired,
    isItemWithDuplicateOptions,
    isItemWithEvaluationLineRequired,
    isItemWithComponentSettingsError,
    logicJumpExceedingLimitError,
    logicJumpCollectionError,
    logicJumpContradictionError,
    isItemWithInvalidDescription,
  } = getValidationErrors(item);

  const screenSize = useResponsiveScreen();
  return (
    <div
      key={`component-banner-${item.id}-${index}`}
      className={`${
        isSearchTermComponent ? 'activeComponent' : 'inactiveComponent'
      } 
        ${
          !isScreenSmIpad(screenSize) && !additionalComponent
            ? 'componentTypeMainContainer componentContainer'
            : 'componentTypeMainContainerAdditionalComponent componentContainer'
        }`}
      style={{
        backgroundColor: BackgroundColors.BG_COLOR_WHITE,
      }}
    >
      <div
        className={`${
          !isScreenSmIpad(screenSize) && !additionalComponent
            ? 'componentTypeParent'
            : 'componentTypeParentAdditionalComponent'
        }`}
      >
        {generateComponentIcon()}
        <div
          className="selectComponentTypeContainer"
          onClick={() => {
            updatEditableSections();
            fetchData(item);
          }}
        >
          {item.collection.associatedWithCareplanTemplate === true && (
            <TextInput
              key={`component-title-${item.id}-${index}`}
              id={item.id}
              value={parseComponentName(item.searchTerm.label)}
              name="componentName"
              placeholder=""
              isDisabled
              style={{ pointerEvents: 'none' }}
              handleOnChange={event => onChangeComponentNameText(event)}
            />
          )}
          {item.collection.associatedWithCareplanTemplate === false &&
            item.healthCategory === componentHealthCategories.MAIN_QUESTION && (
              <SingleSelectQuestionSearchable
                key={`single-select-searchable-${item.id}`}
                value={getSearchableComponentValue(item)}
                defaultOptions={careplanComponents}
                loadOptions={promiseOptions}
                isDisabled={false}
                placeholder="Careplan Components"
                handleOnChange={state => updateSearchTerm(state, item)}
                limitSingle={maxCharLimit.carePlan.componentTitleMaxCharLimit}
              />
            )}
          {item.collection.associatedWithCareplanTemplate === false &&
            item.healthCategory !== componentHealthCategories.MAIN_QUESTION && (
              <TextInput
                key={`component-title-${item.id}-${index}`}
                id={item.id}
                value={
                  item.name !== undefined
                    ? parseComponentName(item.searchTerm.label)
                    : ''
                }
                placeholder=""
                isDisabled={item.searchTerm.careplanComponentId !== undefined}
                name="componentName"
                handleOnChange={event =>
                  updateSearchTerm(
                    {
                      label: event.target.value,
                      nameHtml: event.target.value,
                      value: event.target.value,
                    },
                    item
                  )
                }
                hasError={
                  validationErrorTemplate.templateSettingsValidation
                    .additionalComponentsWithComplexScoreError
                }
              />
            )}
        </div>
        <div className="componentTypeTextContainer">
          <span className="labelComponentType">
            {`- ${
              content.value.indexOf('.') !== -1
                ? t(content.value)
                : content.value
            } -`}
          </span>
        </div>
        <div className="thresholdIconDelete">
          <IconButton
            key={`component-remove-${item.id}-${index}`}
            onClick={() => {
              removeComponent(item);
              const newSettings = { ...isEditable };
              newSettings[item.id] = null;
              setIsEditable(newSettings);
            }}
            name="delete"
            size={30}
            fill={ActionColors.DELETE}
            tooltipText={t('toolTipsText.delete')}
          />
        </div>
      </div>
      <div
        className="component-content-container"
        onClick={() => fetchData(item)}
      >
        {isItemWithNoComponentName && (
          <div className="error-container">
            <span className="templateRequired">
              {t('parsley.requiredField')}
            </span>
          </div>
        )}

        {isItemWithTwoOptionsRequired && !isItemWithNoComponentName && (
          <div className="error-container">
            <span className="templateRequired">
              {t('newQuestion_view.twoOptionsRequiredValidationMessage')}
            </span>
          </div>
        )}

        {isItemWithEmptyOptionsRequired && !isItemWithNoComponentName && (
          <div className="error-container">
            <span className="templateRequired">
              {t('newQuestion_view.emptyOptionError')}
            </span>
          </div>
        )}

        {isItemWithDuplicateOptions && (
          <div className="error-container">
            <span className="templateRequired">
              {t('common_labels.warning_duplicate_options')}
            </span>
          </div>
        )}

        {isItemWithEvaluationLineRequired && (
          <div className="error-container">
            <span className="templateRequired">
              {t('warningMessage.careplanComponentEvaluationLine')}
            </span>
          </div>
        )}

        {isItemWithComponentSettingsError && (
          <div className="error-container">
            <span className="templateRequired">
              {t('newQuestion_view.componentSettingsValidationMessage')}
            </span>
          </div>
        )}

        {logicJumpExceedingLimitError && (
          <div className="error-container">
            <span className="templateRequired">
              {t('warningMessage.jumpDestinationLimit')}
            </span>
          </div>
        )}

        {logicJumpContradictionError && (
          <div className="error-container">
            <span className="templateRequired">
              {t('warningMessage.jumpContradiction')}
            </span>
          </div>
        )}

        {isItemWithInvalidDescription && (
          <Box className="error-container">
            <span className="templateRequired">
              {t('warningMessage.invalidDescription')}
            </span>
          </Box>
        )}

        {logicJumpCollectionError && !showContent && (
          <div className="error-container">
            <span className="templateRequired">
              {t('warningMessage.jumpDestinationPosition')}
            </span>
          </div>
        )}
        {showContent && (
          <div>
            {(item.collection.key === componentTypeOrder.SINGLE_CHOICE ||
              item.collection.key === componentTypeOrder.MULTIPLE_CHOICE ||
              item.collection.key === componentTypeOrder.SORTABLE) && (
              <div className="error-container">
                <span className="templateRequired">
                  {t('warningMessage.maxChoicesAllowed')}
                </span>
              </div>
            )}
            {isEditable[item.id] && isEditable[item.id].jump === true && (
              <LogicJumpContent
                key={`component-logic-jump-content-${item.id}-${index}`}
                item={item}
                componentCollection={componentCollection}
                collectionIndex={index}
                setComponentCollection={setComponentCollection}
                validationErrorTemplate={validationErrorTemplate}
                hasValidationErrorTemplate={hasValidationErrorTemplate}
              />
            )}
            {(!isEditable[item.id] || isEditable[item.id].jump !== true) &&
              generateInnerComponentContent(item)}
            <div className="showHideParent">
              <div className="showHideContainer" style={{ paddingTop: 10 }}>
                {thresholdToggle &&
                  (nonMedical == false || nonMedical == 'false') &&
                  isEditable[item.id] &&
                  isEditable[item.id].thresholds === true &&
                  (item.collection.key === componentTypeOrder.SINGLE_CHOICE ||
                    item.collection.key ===
                      componentTypeOrder.MULTIPLE_CHOICE ||
                    item.collection.key === componentTypeOrder.NUMERIC_RANGE ||
                    item.collection.key === componentTypeOrder.NUMERIC_VALUE ||
                    item.collection.key ===
                      componentTypeOrder.PAIN_LOCATION_CHART ||
                    item.collection.key === componentTypeOrder.BMI ||
                    item.collection.key === componentTypeOrder.BLOOD_PRESSURE ||
                    item.collection.key === componentTypeOrder.YESNO ||
                    item.collection.key === componentTypeOrder.DATEPICKER ||
                    item.collection.key ===
                      componentTypeOrder.GROUP_DESCRIPTION) && (
                    <div className="thresholdTextContainer">
                      <span className="set-threshold-text">
                        {(item.choiceCollection?.choices ||
                          item.thresholdCollection?.thresholds) &&
                        item.collection.key !== componentTypeOrder.YESNO &&
                        item.collection.key !==
                          componentTypeOrder.GROUP_DESCRIPTION &&
                        item.collection.key !== componentTypeOrder.DATEPICKER
                          ? t('newCareplan_view.thresholdLabelMultipleChoice')
                          : t('newCareplan_view.thresholdLabelAddOptions')}
                      </span>
                    </div>
                  )}
                {thresholdToggle &&
                  (nonMedical == false || nonMedical == 'false') &&
                  item.collection.key === componentTypeOrder.SORTABLE && (
                    <div className="thresholdTextContainer">
                      <span className="set-threshold-text">
                        {item.sortableCollection.sortables.length > 0
                          ? t('newCareplan_view.thresholdLabelMultipleChoice')
                          : t('newCareplan_view.thresholdLabelAddOptions')}
                      </span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
        <div className="showHideContainer">
          {shouldShowOrHideContentButton(item.collection.key) && (
            <Box margin={`${showContent ? '15px' : 0} 0 0 0`}>
              <Button
                key={`component-show-hide-${item.id}-${index}`}
                variant={ButtonVariant.NO_BORDER}
                onClick={() => handleShowHideContainer()}
                label={contentMessage}
              />
            </Box>
          )}
          {!showContent && thresholdToggle && displayThreshold(item)}
          {!showContent && logicJumpToggle && displayJumps(item)}
        </div>
      </div>
    </div>
  );
};

const CareplanComponents = ({
  additionalComponent,
  completeCollection,
  updateSelectedComponent,
  componentCollection,
  setComponentCollection,
  setSettings,
  searchTermComponents,
  setSearchTermComponents,
  setCounter,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  setOpenPrompt,
  setTemplateNames,
  isEditTemplate,
  isCreatTemplate,
  setOpenPromptForParentComponentDeletion,
  setParentId,
  isEditable,
  setIsEditable,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring,
  healthCategory,
  setDisplayFlashMsg,
}) => {
  const [careplanComponents, setCareplanComponents] = useState([]);
  const [isBlurred, setBlurr] = useState(false);
  const [thresholdDropdown, setThresholdDropdown] = useState('');
  const [thresholdNumber, setThresholdNumber] = useState('');

  const findGlobalIndex = useCallback(() => {
    return componentCollection.findIndex(
      component => component.id === searchTermComponents.id
    );
  }, [componentCollection, searchTermComponents.id]);
  const [globalIndexOfSelectedComponent, setGlobalIndexOfSelectedComponent] =
    useReducer((_state, index) => index, findGlobalIndex());

  const setSelectedPayLoad = item => {
    setSearchTermComponents({ ...item });
  };

  const validateIfComponentAssociatedWithTemplate = async careplanComponent => {
    if (careplanComponent.careplanComponentId != undefined) {
      const response = await axios.get(
        serviceConfig.brokerService.validateRequestedComponentExistence.uri +
          careplanComponent.careplanComponentId,
        {
          params: { userLanguage: I18n.language },
        }
      );
      if (response.status === 200 && response.data.length > 0) {
        setOpenPrompt(true);
        setTemplateNames([response.data]);
      }
    }
  };

  const updateSearchTerm = (state, item) => {
    const checkIsEditTemplatePropExistence = isEditTemplate || true;
    const indexToSelectComponent = componentCollection.findIndex(
      component => component.id === searchTermComponents.id
    );
    const selectedComponent = componentCollection[indexToSelectComponent];
    const temp = validationErrorTemplate;
    const componentError =
      temp.templateSettingsValidation.noComponentsError[
        searchTermComponents.id
      ];
    if (state.label && componentError) {
      delete temp.templateSettingsValidation.noComponentsError[
        searchTermComponents.id
      ];
      hasValidationErrorTemplate({ ...temp });
    }

    if (isEditTemplate || isCreatTemplate) {
      validateIfComponentAssociatedWithTemplate(state);
    }
    if (checkIsEditTemplatePropExistence) {
      validateIfComponentAssociatedWithTemplate(state);
    }
    if (checkIsEditTemplatePropExistence) {
      let prefilledOptions;
      const matchedPosition = componentCollection.findIndex(elem => {
        return elem.id === item.id;
      });
      const component = componentCollection[matchedPosition];
      const componentTypeId = component.collection.key;
      component.searchTerm = state;
      component.nameHtml = state.value;
      component.name = state.value;
      component.descriptionEditor = state.value;
      if (component.searchTerm.optionJson) {
        const optionJson =
          typeof component.searchTerm.optionJson === 'string'
            ? JSON.parse(component.searchTerm.optionJson)
            : component.searchTerm.optionJson;
        prefilledOptions = optionJson.options.map(elem => {
          return {
            name: elem,
            isChecked: false,
          };
        });
      }

      const customLabels = (categories: string[]) =>
        categories.map(category => ({
          isChecked: false,
          name: category,
        }));
      if (
        componentTypeId === componentTypeOrder.SINGLE_CHOICE ||
        componentTypeId === componentTypeOrder.MULTIPLE_CHOICE ||
        componentTypeId === componentTypeOrder.BMI ||
        componentTypeId === componentTypeOrder.BLOOD_PRESSURE
      ) {
        component.choiceCollection.choices =
          componentTypeId === componentTypeOrder.BMI
            ? customLabels(bmiCategories)
            : componentTypeId === componentTypeOrder.BLOOD_PRESSURE
            ? customLabels(bloodPressureCategories)
            : prefilledOptions || [];
        component.choiceCollection.optionWeights.total = component.searchTerm
          .careplanComponentId
          ? component.searchTerm.optionWeights
            ? component.searchTerm.optionWeights.total
            : 0
          : 0;
        component.choiceCollection.optionWeights.weights = component.searchTerm
          .careplanComponentId
          ? component.searchTerm.optionWeights
            ? component.searchTerm.optionWeights.weights
            : []
          : [];

        if (
          component.searchTerm.careplanComponentId &&
          component.searchTerm.optionWeights &&
          component.choiceCollection.optionWeights.total > 0
        ) {
          component.searchTerm.shouldWeightToggle = true;
        }
      }

      if (componentTypeId == componentTypeOrder.SORTABLE) {
        component.sortableCollection.sortables = prefilledOptions || [];
        component.sortableCollection.optionWeights.total = component.searchTerm
          .careplanComponentId
          ? component.searchTerm.optionWeights
            ? component.searchTerm.optionWeights.total
            : 0
          : 0;
        component.sortableCollection.optionWeights.weights = component
          .searchTerm.careplanComponentId
          ? component.searchTerm.optionWeights
            ? component.searchTerm.optionWeights.weights
            : []
          : [];
        if (
          component.searchTerm.careplanComponentId &&
          component.searchTerm.optionWeights &&
          component.sortableCollection.optionWeights.total > 0
        ) {
          component.searchTerm.shouldWeightToggle = true;
        }
      }

      updateSelectedComponent(
        searchTermComponents.searchTerm.careplanComponentId
      );
      setComponentCollection([...componentCollection]);
      setSelectedPayLoad(selectedComponent || {});
      setSettings(true);
      hasValidationErrorTemplate({ ...temp });
    }
  };

  const filterOptions = inputValue => {
    if (Object.keys(careplanComponents).length > 0)
      return careplanComponents.filter(i =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterOptions(inputValue));
      }, 1000);
    });

  const generateInnerComponentContent = item => {
    switch (item.collection.key) {
      case componentTypeOrder.FREE_TEXT:
        return renderFreeText();
      case componentTypeOrder.SINGLE_CHOICE:
        return renderSingleChoice(
          item,
          componentCollection,
          searchTermComponents,
          setComponentCollection,
          setBlurr,
          validationErrorTemplate,
          hasValidationErrorTemplate,
          setShowPromptForAdjustScoring,
          showPromptForAdjustScoring
        );
      case componentTypeOrder.MULTIPLE_CHOICE:
        return renderMultipleChoice(
          item,
          componentCollection,
          searchTermComponents,
          setComponentCollection,
          setBlurr,
          validationErrorTemplate,
          hasValidationErrorTemplate,
          setShowPromptForAdjustScoring,
          showPromptForAdjustScoring
        );
      case componentTypeOrder.NUMERIC_RANGE:
        return (
          <NumericRange
            item={item}
            componentCollection={componentCollection}
            searchTermComponents={searchTermComponents}
            setComponentCollection={setComponentCollection}
            thresholdDropdown={thresholdDropdown}
            thresholdNumber={thresholdNumber}
          />
        );
      case componentTypeOrder.NUMERIC_VALUE:
        return (
          <NumericValue
            item={item}
            componentCollection={componentCollection}
            searchTermComponents={searchTermComponents}
            setComponentCollection={setComponentCollection}
            thresholdDropdown={thresholdDropdown}
            thresholdNumber={thresholdNumber}
          />
        );
      case componentTypeOrder.YESNO:
        return renderYesNo(item, componentCollection, setComponentCollection);

      case componentTypeOrder.PAIN_LOCATION_CHART:
        return renderPainLocationChart(
          item,
          componentCollection,
          searchTermComponents,
          setComponentCollection,
          thresholdDropdown,
          setThresholdDropdown,
          thresholdNumber,
          setThresholdNumber
        );

      case componentTypeOrder.SORTABLE:
        return renderSortable(
          item,
          componentCollection,
          searchTermComponents,
          setComponentCollection,
          setBlurr,
          validationErrorTemplate,
          hasValidationErrorTemplate,
          setShowPromptForAdjustScoring,
          showPromptForAdjustScoring
        );

      case componentTypeOrder.GROUP_DESCRIPTION:
        return renderGroupDescription(
          item,
          componentCollection,
          setComponentCollection
        );

      case componentTypeOrder.DATEPICKER:
        return renderDatepicker(
          item,
          componentCollection,
          setComponentCollection,
          thresholdDropdown,
          setThresholdDropdown,
          thresholdNumber,
          setThresholdNumber
        );

      case componentTypeOrder.UPLOAD_FILE:
        return renderUploadFile();

      case componentTypeOrder.BMI:
        return renderBMI(
          item,
          componentCollection,
          searchTermComponents,
          setComponentCollection,
          setBlurr,
          item.collection.key
        );
      case componentTypeOrder.BLOOD_PRESSURE:
        return renderBMI(
          item,
          componentCollection,
          searchTermComponents,
          setComponentCollection,
          setBlurr,
          item.collection.key
        );

      default:
        break;
    }
  };

  const getSearchableComponentValue = item => {
    const globalIndex = componentCollection.findIndex(
      comp => comp.id === item.id
    );
    const filteredComponent = componentCollection[globalIndex];
    if (filteredComponent && filteredComponent.searchTerm) {
      const { searchTerm } = filteredComponent;
      searchTerm.label = searchTerm.label != undefined ? searchTerm.label : '';
      return {
        value: parseComponentName(searchTerm.value),
        label: parseComponentName(searchTerm.label),
        icon: searchTerm.icon,
      };
    }
    return {
      value: '',
      label: '',
      icon: '',
    };
  };

  const itemWithDuplicateOptions = item => {
    let filteredElement;
    if (
      validationErrorTemplate.templateSettingsValidation
        .itemsWithDuplicateOptionsError.length > 0
    ) {
      filteredElement =
        validationErrorTemplate.templateSettingsValidation.itemsWithDuplicateOptionsError.find(
          elem => {
            return elem.componentId === item.id;
          }
        );
      if (filteredElement) {
        return filteredElement.isDuplicate;
      }
    }
    return false;
  };

  const itemWithEvaluationLineRequired = item => {
    let filteredElement;
    if (
      validationErrorTemplate.templateSettingsValidation
        .evaluationLineErrorForSortable.length > 0
    ) {
      filteredElement =
        validationErrorTemplate.templateSettingsValidation.evaluationLineErrorForSortable.find(
          elem => {
            return elem.componentId === item.id;
          }
        );
      if (filteredElement) {
        return filteredElement.evaluationLineError;
      }
    }
    return false;
  };

  const getValidationErrors = item => {
    const key = item.id;
    const isItemWithNoComponentName = validationErrorTemplate
      .templateSettingsValidation.noComponentsError
      ? validationErrorTemplate.templateSettingsValidation.noComponentsError[
          key
        ]
      : false;

    const isItemWithInvalidDescription = !descriptionValidator(
      item.searchTerm.description
    );

    const isItemWithTwoOptionsRequired = validationErrorTemplate
      .templateSettingsValidation.twoOptionsRequiredError
      ? validationErrorTemplate.templateSettingsValidation
          .twoOptionsRequiredError[key]
      : false;

    const isItemWithEmptyOptionsRequired = validationErrorTemplate
      .templateSettingsValidation.emptyOptionError
      ? validationErrorTemplate.templateSettingsValidation.emptyOptionError[key]
      : false;

    const isItemWithDuplicateOptions = itemWithDuplicateOptions(item);

    const isItemWithEvaluationLineRequired =
      itemWithEvaluationLineRequired(item);

    const isItemWithComponentSettingsError =
      validationErrorTemplate.templateSettingsValidation.componentSettingsError
        .length > 0
        ? validationErrorTemplate.templateSettingsValidation.componentSettingsError.filter(
            elem => {
              return elem.componentId == key;
            }
          ).length > 0
        : false;

    const logicJumpExceedingLimitError =
      validationErrorTemplate.itemsWithJumpExceedingLimitError
        ? validationErrorTemplate.itemsWithJumpExceedingLimitError[key]
        : false;

    const logicJumpCollectionError =
      validationErrorTemplate.logicJumpCollectionError
        ? validationErrorTemplate.logicJumpCollectionError[key]
        : false;

    const logicJumpContradictionError =
      validationErrorTemplate.logicJumpContradictionError
        ? validationErrorTemplate.logicJumpContradictionError[key]
        : false;

    return {
      isItemWithNoComponentName,
      isItemWithTwoOptionsRequired,
      isItemWithEmptyOptionsRequired,
      isItemWithDuplicateOptions,
      isItemWithEvaluationLineRequired,
      isItemWithComponentSettingsError,
      logicJumpExceedingLimitError,
      logicJumpCollectionError,
      logicJumpContradictionError,
      isItemWithInvalidDescription,
    };
  };

  const fetchData = item => {
    setSettings(true);
    setSelectedPayLoad(item || {});

    if (!isMainQuestion(item.healthCategory)) return;

    const filteredComponentsByType = completeCollection.find(elem => {
      return elem.componentTypeId === item.collection.key;
    });
    setCareplanComponents(
      filteredComponentsByType ? filteredComponentsByType.components : {}
    );
  };

  const removeComponent = item => {
    removeCareplanComponent(
      componentCollection,
      setComponentCollection,
      setCounter,
      setSearchTermComponents,
      setSettings,
      validationErrorTemplate,
      hasValidationErrorTemplate,
      item,
      setOpenPromptForParentComponentDeletion,
      setParentId,
      setShowPromptForAdjustScoring,
      showPromptForAdjustScoring,
      setDisplayFlashMsg
    );
  };

  useEffect(() => {
    const matchedPosition = findGlobalIndex();
    if (globalIndexOfSelectedComponent !== matchedPosition) {
      setGlobalIndexOfSelectedComponent(matchedPosition);
    }
  }, [findGlobalIndex, searchTermComponents.id]);

  function getHealthCategoryQuestions(
    newList: Array<object>,
    additionalComponentsType: object[],
    healthCategoryType: number
  ) {
    let questions = additionalComponentsType;
    if (newList[0].healthCategory === healthCategoryType) {
      questions = newList;
    }
    return questions;
  }

  const handleChange = newList => {
    let isValid = true;
    for (let index = 0; index < newList.length; index++) {
      // Defines which components can be nested === allow parent/children components
      const hasNestingOnMain =
        newList[index].healthCategory ===
          componentHealthCategories.MAIN_QUESTION &&
        newList[index].collection.key !== componentTypeOrder.YESNO &&
        newList[index].collection.key !== componentTypeOrder.GROUP_DESCRIPTION;

      const hasNoNestingInAdditional =
        newList[index].healthCategory !==
          componentHealthCategories.MAIN_QUESTION &&
        (newList[index].collection.key !== componentTypeOrder.YESNO ||
          newList[index].collection.key !==
            componentTypeOrder.GROUP_DESCRIPTION);

      if (
        (findDescendants(newList, index).length > 0 && hasNestingOnMain) ||
        (findDescendants(newList, index).length > 0 && hasNoNestingInAdditional)
      ) {
        isValid = false;
        break;
      }
    }
    if (isValid === true) {
      const allAdditionalComponents = componentCollection.filter(
        component =>
          component.healthCategory !== componentHealthCategories.MAIN_QUESTION
      );
      let componentCollectionCopy = [...newList, ...allAdditionalComponents];

      if (
        newList[0].healthCategory !== componentHealthCategories.MAIN_QUESTION
      ) {
        const mainComponents = getComponentsByHealthCategory(
          componentCollection,
          componentHealthCategories.MAIN_QUESTION
        );

        const categories = [
          componentHealthCategories.WELL_QUESTION,
          componentHealthCategories.FAIR_QUESTION,
          componentHealthCategories.POOR_QUESTION,
        ];

        const questionSets = categories.map(category => {
          const additionalComponentsPerType = getComponentsByHealthCategory(
            componentCollection,
            category
          );
          return getHealthCategoryQuestions(
            newList,
            additionalComponentsPerType,
            category
          );
        });

        // Flattens question's set to a single array of the questions above
        componentCollectionCopy = [...mainComponents].concat(...questionSets);
      }

      setComponentCollection([...componentCollectionCopy]);
      const updatedSearchTermComponentIndex = findGlobalIndex();
      if (updatedSearchTermComponentIndex > -1)
        setSearchTermComponents({
          ...componentCollectionCopy[updatedSearchTermComponentIndex],
        });

      const componentsWithJump = componentCollectionCopy.filter(
        component =>
          component.shouldLogicJumpToggle &&
          component.logicJumpCollection &&
          component.logicJumpCollection.length > 0
      );
      if (componentsWithJump.length > 0 && setDisplayFlashMsg)
        setDisplayFlashMsg({
          display: true,
          message: 'warningMessage.jumpMessageWhenComponentsReordered',
          type: FlashMessageTypes.Warning,
        });
    }
  };
  return (
    <DnDView
      items={getComponentsByHealthCategory(componentCollection, healthCategory)}
      component={({
        data,
        depth,
        hasDescendants,
        index,
        additionalComponent,
      }) => (
        <ComponentBanner
          additionalComponent={additionalComponent}
          key={`component-${data.id}-${depth}-${index}`}
          item={data}
          index={index}
          depth={depth}
          hasDescendants={hasDescendants}
          componentCollection={componentCollection}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          fetchData={fetchData}
          isSearchTermComponent={data.id === searchTermComponents.id}
          getValidationErrors={getValidationErrors}
          promiseOptions={promiseOptions}
          updateSearchTerm={updateSearchTerm}
          getSearchableComponentValue={getSearchableComponentValue}
          generateInnerComponentContent={generateInnerComponentContent}
          setComponentCollection={setComponentCollection}
          validationErrorTemplate={validationErrorTemplate}
          hasValidationErrorTemplate={hasValidationErrorTemplate}
          setCareplanComponents={setCareplanComponents}
          removeComponent={removeComponent}
          careplanComponents={careplanComponents}
        />
      )}
      onChange={useDebounce(handleChange, 100)}
    />
  );
};

export default CareplanComponents;
