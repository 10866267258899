import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Icon } from '@ui-common-files/components';
import { MedicationCardTitleTypes } from '../MedicationPlan.types';
import { useMedicationCardTitleService } from './MedicationCardTitle.service';

export const MedicationCardTitle: React.FC<MedicationCardTitleTypes> = ({
  iconName,
  color,
  data,
}) => {
  const { scanningDate, lastUpdated, title, fillColor } =
    useMedicationCardTitleService(data, color);

  const { t } = useTranslation();

  return (
    <Flex flexDirection="row" justifyContent="space-between">
      <Flex flexDirection="row" alignItems="center" otherStyles={{ flex: 1 }}>
        <Icon
          name={iconName}
          size={30}
          fill={fillColor}
          style={{ flexShrink: 0 }}
        />
        <Box>
          <Box className="card-title-text card-title-text--medication__card">
            {title}
          </Box>
          {scanningDate && (
            <Flex className="last-updated">
              <span className="green-dot"></span>
              {t('asset_view.lastUpdated')} {lastUpdated}
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
