import React from 'react';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import FilterSeparator from '../../FilterSeparator/FilterSeparator';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import PatientFilterSelectContent from '../PatientFilterSelectContent/PatientFilterSelectContent';
import { PatientFilterSelectContainerProps } from './PatientFilterSelectContainer.type';

function PatientFilterSelectContainer({
  type,
}: PatientFilterSelectContainerProps) {
  const { currentFilter, setCurrentFilter, clearCurrentFilter } =
    usePatientFilterContext();

  const onClickHandler = () => {
    if (currentFilter === type) clearCurrentFilter();
    else setCurrentFilter(type);
  };

  return (
    <div onClick={onClickHandler}>
      <Flex className="filters__dropdown">
        <PatientFilterSelectContent type={type} />
        <FilterSeparator />
      </Flex>
    </div>
  );
}

export default PatientFilterSelectContainer;
