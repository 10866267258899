import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Flex } from '@ui-common-files/components';
import ModalFooter from '../../../common/Layout/Modal/ModalFooter';
import {
  OverviewDataModalContent,
  OverviewDataModalHeader,
} from 'src/Views/AssignmentCards/Overview/OverviewDataChart/OverviewDataPointsSelection/OverviewDataModal';

import { PatientOverviewContext } from 'src/Views/PatientOverview/PatientOverview';
import { useOverviewStore } from '../Overview.store';

const useOpenModal = () => {
  const { t } = useTranslation();
  const { setModalContent, closeModal, setShouldGetGraphViewData } = useContext(
    PatientOverviewContext
  );
  const { resetOverviewCaretasks } = useOverviewStore(
    useShallow(state => ({
      resetOverviewCaretasks: state.resetOverviewCaretasks,
    }))
  );
  const openModal = () => {
    setShouldGetGraphViewData(false);
    setModalContent({
      title: t('patients_view.overview.modal_select_data.title'),
      content: (
        <Flex
          flexDirection="column"
          otherStyles={{ overflow: 'auto', height: '100%' }}
        >
          <OverviewDataModalHeader />
          <OverviewDataModalContent />
        </Flex>
      ),
      footer: (
        <ModalFooter
          close={() => {
            closeModal();
            resetOverviewCaretasks();
          }}
          labelCTA={t('common_buttons.add')}
          onClickCTA={() => {
            setShouldGetGraphViewData(true);
            closeModal();
            resetOverviewCaretasks();
          }}
        />
      ),
    });
  };

  return {
    openModal,
  };
};

export default useOpenModal;
