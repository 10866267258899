import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Flex from '../../Flex/Flex';
import Box from '../../Box/Box';
import Checkbox from '../../Checkbox/Checkbox';

import CheckboxRadio from '../../../utils/CheckboxRadio';
import { ComponentTypes } from '../../../utils/componentTypes';

const WeeklyRecurrence = ({
  isDisabled,
  weeklyRecurrence,
  handleOnChange,
  hasErrors,
  selectedWeekDaysError,
  componentType,
}) => {
  const { weekdays } = require('../../../utils/Recurrence/recurrenceUtils');
  const { t } = useTranslation();
  const updateWeekdaySelection = (index, checked) => {
    weeklyRecurrence[index] = checked;
    handleOnChange(weeklyRecurrence);
  };

  const renderWeekdayCheckboxes = () =>
    weekdays().map((weekday, i) => (
      <Box
        key={`weekdayCheckbox-${weekday.day}`}
        width="80px"
        margin="12px 0 0 0"
      >
        <Checkbox
          id={weekday.day}
          name={weekday.day}
          isDisabled={isDisabled}
          label={weekday.translation}
          variant={CheckboxRadio.Default}
          checked={weeklyRecurrence[i]}
          handleOnChange={e => updateWeekdaySelection(i, e.target.checked)}
        />
      </Box>
    ));

  return (
    <>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        otherStyles={{
          marginLeft:
            componentType === ComponentTypes.CAREPLANTEMPLATE ? null : '20px',
        }}
      >
        {renderWeekdayCheckboxes()}
      </Flex>
      {hasErrors && (
        <Box
          className="datetimepicker__error"
          otherStyles={{ marginLeft: '19px' }}
        >
          {t('recurrence.noWeekdaysSelectedError')}
        </Box>
      )}
      {selectedWeekDaysError && (
        <Box className="datetimepicker__error">
          {t('recurrence.selectedWeekDaysError')}
        </Box>
      )}
    </>
  );
};

WeeklyRecurrence.propTypes = {
  weeklyRecurrence: PropTypes.arrayOf(PropTypes.bool).isRequired,
  handleOnChange: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
};

export default WeeklyRecurrence;
