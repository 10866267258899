const defaultEnums = {
  EVALUATION_LINE: 2,
  EVALUATION_LINE_MIN_VALUE: 2,
  EVALUATION_LINE_MAX_VALUE: 10,
  COMPONENT_WEIGHT_MIN_VALUE: 0,
  COMPONENT_WEIGHT_MAX_VALUE: 100,
  OPTION_WEIGHT_MIN_VALUE: 0,
  OPTION_WEIGHT_MAX_VALUE: 100,
  MEDIA_URL_MAX_CHARACTERS: 150,
  NUMERIC_RANGE_MIN_VALUE: -100,
  NUMERIC_RANGE_MAX_VALUE: 100,
  DEFAULT_NUMERIC_RANGE_MIN_VALUE: 0,
  DEFAULT_NUMERIC_RANGE_MAX_VALUE: 10,
  NUMERIC_VALUE_MIN_VALUE: -999999.99,
  NUMERIC_VALUE_MAX_VALUE: 999999.99,
  DEFAULT_NUMERIC_VALUE_MIN_VALUE: 1,
  DEFAULT_NUMERIC_VALUE_MAX_VALUE: 100,
  MIN_OPTIONS: 2,
  MAX_OPTIONS: 10,
  DEFAULT_NUMBER_INPUT_MIN_VALUE: 0,
  DEFAULT_NUMBER_INPUT_MAX_VALUE: 100000,
};

export default defaultEnums;
