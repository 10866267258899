/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Box, TextInput } from '@ui-common-files/components';
import ReviewAssessmentsModal from './ReviewAssessmentsModal';
import CareplanIndexTable from '../../Datatables/CareplanIndex';

import { MaxCharLimits } from '@ui-common-files/utils';
import inputField from '../../../../caro-ui-commonfiles/utils/inputField';
import useReviewAssessmentsService from './ReviewAssessments.service';
import { ReviewAssessmentsProps } from './ReviewAssessments.type';

import styles from './ReviewAssessment.module.scss';
import '../../../css/qrcode.css';

const ReviewAssessments = ({
  reloadTable,
  setReloadTable,
  onEdit,
  onAssign,
  onExport,
  onShow,
  setFlashMessage,
  setServices,
  borderId,
}: ReviewAssessmentsProps) => {
  const {
    onTableChange,
    query,
    value,
    caretaskTemplatesData,
    closeMessagePrompt,
    deleteCaretask,
    deleteOperation,
    loading,
    onDelete,
    recordsTotal,
    onSearch,
    t,
  } = useReviewAssessmentsService({
    setServices,
    reloadTable,
    setReloadTable,
  });

  return (
    <>
      <Box className={styles.searchbar}>
        <TextInput
          id="careplanTemplateSearch"
          placeholder={t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={value}
          handleOnChange={onSearch}
        />
      </Box>
      <CareplanIndexTable
        isReviewAssessment
        fetchedData={caretaskTemplatesData}
        loading={loading}
        onChange={onTableChange}
        currentPage={query.currentPage}
        recordsTotal={caretaskTemplatesData ? recordsTotal : 0}
        pages={
          caretaskTemplatesData ? Math.ceil(recordsTotal / query.length) : 1
        }
        sort={{}}
        length={query.length}
        onEdit={onEdit}
        onDelete={onDelete}
        onAssign={onAssign}
        onExport={onExport}
        onShow={onShow}
        setFlashMessage={setFlashMessage}
        borderId={borderId}
      />
      <ReviewAssessmentsModal
        active={deleteOperation.display}
        deleteOperation={deleteOperation}
        closeMessagePrompt={closeMessagePrompt}
        deleteCaretask={deleteCaretask}
      />
    </>
  );
};
export default ReviewAssessments;
