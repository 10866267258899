import React from 'react';

import { Flex, Box, DataTable, TextInput } from '@ui-common-files/components';

import useReviewAssessmentsService from './ReviewAssessments.service';
import { pageSizeOptions } from './ReviewAssessments.util';

import styles from './ReviewAssessments.module.scss';

export default function ReviewAssessments() {
  const {
    isLoading,
    pagination,
    rows,
    fetchData,
    onInputChange,
    search,
    columns,
    tableProps,
    t,
  } = useReviewAssessmentsService();
  return (
    <Flex className={styles.container}>
      <Flex className={styles.container__header}>
        <Box className={styles.container__headerText}>
          {t('reviewAssessments.readyToReviewReports')}
        </Box>
        <Box className={styles.container__headerInput}>
          <TextInput
            placeholder={t('common_labels.label_search')}
            value={search}
            handleOnChange={onInputChange}
          />
        </Box>
      </Flex>
      <DataTable
        isLoading={isLoading}
        columns={columns}
        data={rows}
        pageSize
        pageSizeOptions={pageSizeOptions}
        pagination
        onTableChange={fetchData}
        page={pagination.currentPage}
        pages={pagination.pages}
        totalRecord={pagination.totalRecord}
        tableProps={tableProps}
      />
    </Flex>
  );
}
