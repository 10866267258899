import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import NumberInput from '../../../../caro-ui-commonfiles/components/NumberInput/NumberInput';
import Dropdown from '../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import defaultEnums from '../defaultEnums';

const DropdownInputComponent = ({
  isDisabled,
  onChange,
  component,
  id,
  isNegativeNumber = false,
  values = null,
  isFloatingNumber = false,
  extendNumberInputWidth = false
}) => {
  const options = [
    { value: '>', label: I18n.t('threshold.greaterThan') },
    { value: '<', label: I18n.t('threshold.lessThan') },
    { value: '>=', label: I18n.t('threshold.greaterThanOrEqualTo') },
    { value: '<=', label: I18n.t('threshold.lessThanOrEqualTo') },
    { value: '==', label: I18n.t('threshold.equalTo') },
    { value: '!=', label: I18n.t('threshold.notEqual') },
  ];

  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue({
      option: component
        ? options.find(val => {
          return val.value === component.comparison;
        })
        : null,
      number: component ? component.numericValue : '',
    });
  }, [component]);

  return (
    <>
      <Box margin="0 5px 10px 0" width="230px">
        <Dropdown
          value={value && value.option}
          options={options}
          isDisabled={isDisabled}
          handleOnChange={val => {
            const newVal = {
              ...value,
              option: val,
            };
            onChange(newVal);
            setValue({
              ...value,
              option: val,
            });
          }}
          heightControl={36}
        />
      </Box>
      <Box
        margin="0 0 10px 0"
        width={extendNumberInputWidth ? '140px' : '100px'}
      >
        <NumberInput
          value={value && value.number}
          name={id}
          id={id}
          min={values ? values.min : defaultEnums.DEFAULT_NUMBER_INPUT_MIN_VALUE}
          max={values ? values.max : defaultEnums.DEFAULT_NUMBER_INPUT_MAX_VALUE}
          step={1}
          isFloatingNumber={isFloatingNumber}
          isNegativeNumber={isNegativeNumber}
          isDisabled={isDisabled}
          changeValue={e => {
            const newVal = {
              ...value,
              number: e,
            };
            onChange(newVal);
            setValue({
              ...value,
              number: e,
            });
          }}
        />
      </Box>
    </>
  );
};

DropdownInputComponent.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  component: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  id: PropTypes.string.isRequired,
};

export default DropdownInputComponent;
