import React, { useContext } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import { ButtonType, ButtonVariant} from '../../../caro-ui-commonfiles/utils/button';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import { SubmissionTypes } from '../../../caro-ui-commonfiles/utils/componentTypes';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

const ModalFooter = ({ componentType, actionType }) => {
  const {
    dispatchFormValidator,
    currentStep,
    setCurrentStep,
    dispatchFormSubmittor,
  } = useContext(PatientOverviewContext);
  return (
    <Flex justifyContent="flex-end" otherStyles={{ flex: 1 }}>
      {currentStep === 1 && (
        <Button
          label={I18n.t('common_buttons.next')}
          onClick={() => {
            dispatchFormValidator({
              component: componentType,
              action: actionType,
              shouldValidate: true,
            });
          }}
        />
      )}
      {currentStep === 2 && (
        <>
          <Button
            label={I18n.t('common_buttons.back')}
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
            variant={ButtonVariant.OUTLINED}
          />

          <Flex justifyContent="flex-end" otherStyles={{ flex: 1 }}>
            <Box margin="0 16px 0 0">
              <Button
                label={I18n.t('assignTemplate_view.addToAssignments')}
                type={ButtonType.SUBMIT}
                onClick={() => {
                  dispatchFormSubmittor({
                    component: componentType,
                    action: actionType,
                    submissionType: SubmissionTypes.ASSIGN_ONLY,
                  });
                }}
                variant={ButtonVariant.OUTLINED}
              />
            </Box>
            <Button
              label={I18n.t('assignTemplate_view.send_immediately')}
              type={ButtonType.SUBMIT}
              onClick={() => {
                dispatchFormSubmittor({
                  component: componentType,
                  action: actionType,
                  submissionType: SubmissionTypes.ASSIGN_AND_SEND,
                });
              }}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};

ModalFooter.propTypes = {
  componentType: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
};

export default ModalFooter;
