import storeInfo from '../../../../store';

import { addMessage } from '../../../../caro-ui-commonfiles/components/FlashMessage/FlashMessages.reducer';
import { FlashMessageItem } from '../../../../caro-ui-commonfiles/components/FlashMessage/FlashMessage.type';

function ShowFlashMessage(message: FlashMessageItem): number {
  const id: number = Date.now() + Math.ceil(Math.random() * 10000);
  const messageExist = storeInfo.store
    .getState()
    .flashMessages.messages.some((msg: FlashMessageItem) => {
      return (
        (msg.message === message.message ||
          msg.messageKey === message.messageKey) &&
        msg.type === message.type
      );
    });
  if (!messageExist) {
    const isModalOpen: boolean =
      storeInfo.store.getState().flashMessages.isModalOpen;
    storeInfo.store.dispatch(
      addMessage({
        id,
        isModalMessage:
          message.isModalMessage !== undefined
            ? message.isModalMessage
            : isModalOpen,
        ...message,
      })
    );
    return id;
  }
  return null;
}

export { ShowFlashMessage };
