import React from 'react';
import { Box } from '@ui-common-files/components';

import User from '@type/Administration/Users/User.interface';

function ReportGeneratedAnsweredBy({
  answeredByRole,
  answeredByName,
  answerDate,
}: {
  answeredByRole: User['role']['name'];
  answeredByName: User['fullName'];
  answerDate: string;
}): JSX.Element {
  return (
    <Box className="first-page_content-answeredby">
      {`${answeredByRole} - ${answeredByName} - ${answerDate}`}
    </Box>
  );
}

export default ReportGeneratedAnsweredBy;
