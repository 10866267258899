/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import PropTypes from 'prop-types';

import Box from '../Box/Box';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

import { getUTCDatesFromRanges } from './Calendar.util';
import {
  setEndOfDayForMaxData,
  getUTCDateFromLocalDate,
} from '../../utils/dateUtils/dateUtil';
import { useResponsiveScreen } from '../../utils/hooks';
import { SCREEN_CATEGORIES } from '../../utils/layout';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const DnDCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const TaskCalendar = ({
  events,
  onEventChange,
  onDoubleClickEvent,
  locale,
  filterAction,
  hidePatientDemographics,
  onFilter,
  filterActive,
  getRange,
  customTooltipComponent,
  isChatOpened,
  currLang,
  eventPropGetter,
}) => {
  const HIDE_STATUS = true;
  const [listEvents, setListEvents] = useState([...events]);
  const [calendarViewerSize, setCalendarViewerSize] = useState({
    height: 0,
    width: 0,
  });
  const calendarContainerRef = useRef(null);
  const responsiveScreen = useResponsiveScreen();

  useEffect(() => {
    const rbcTimeHeader = document.getElementsByClassName('rbc-time-header');
    if (rbcTimeHeader.length > 0) {
      const hasVerticalScrollbar =
        rbcTimeHeader[0].scrollHeight > rbcTimeHeader[0].clientHeight;
      if (hasVerticalScrollbar) {
        rbcTimeHeader[0].style.marginRight = 0;
      }
    }
  }, [listEvents]);

  useEffect(() => {
    setListEvents([...events]);
  }, [events]);

  useEffect(() => {
    const changeLang = async () => {
      moment.locale(currLang.trim());
    };
    changeLang();
  }, []);

  const onEventDnD = ({ event, start, end }) => {
    const cloneEvents = [...listEvents];
    const index = cloneEvents.indexOf(event);
    const currentDate = new Date();

    if (
      start >= currentDate &&
      end >= currentDate &&
      start.getDate() === end.getDate() &&
      event.data.statusId === 1
    ) {
      const updatedEvent = {
        ...event,
        start,
        end,
      };
      cloneEvents.splice(index, 1, updatedEvent);
      onEventChange(updatedEvent);
      setListEvents(cloneEvents);
    }
  };

  useEffect(() => {
    const filteredEvents = events.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
    }));
    setListEvents(filteredEvents);
  }, [events]);

  const customDayPropGetter = date => {
    if (date.getDay() === 0 || date.getDay() === 6)
      return {
        className: 'rbc-calendar__day-weekend',
      };
    return {};
  };

  const ToolTipComponent = ({ event }) => {
    let statusClass = 'response';
    if (event.data.statusId === 1) {
      statusClass = 'accepted';
    }
    if (event.data.statusId === 2) {
      statusClass = 'reschedule';
    }
    return (
      <Flex
        flexDirection="column"
        justifyContent="space-around"
        otherStyles={{ whiteSpace: 'normal' }}
      >
        <Box margin="0 0 10px 0">
          <Flex flexDirection="column">
            {hidePatientDemographics ? null : (
              <span>
                <strong>{`${locale.patient}: `}</strong>
                {event.data.patient}
              </span>
            )}
            <span>
              <strong>{`${locale.record}: `}</strong>
              {event.data.medicalId}
            </span>
          </Flex>
        </Box>
        <Box>
          <Flex flexDirection="column">
            <span>{event.data.text}</span>
            <span>
              {`${moment(event.start).format('HH:mm')} - ${moment(
                event.end
              ).format('HH:mm')}`}
            </span>
          </Flex>
        </Box>
        <Flex flexDirection="column">
          {!HIDE_STATUS && (
            <span>
              <strong>{`${locale.status}: `}</strong>
              <span className={`rbc-calendar__tooltip__status ${statusClass}`}>
                {event.data.status}
              </span>
            </span>
          )}
          <span>
            <strong>{`${locale.type}: `}</strong>
            {event.data.type}
          </span>
          <span className="rbc-calendar__tooltip__locations">
            <strong>{`${locale.location}: `}</strong>
            {event.data.location}
          </span>
        </Flex>
      </Flex>
    );
  };

  const CustomEvent = ({ event }) => {
    const toolTipComponent = customTooltipComponent ? (
      customTooltipComponent(event)
    ) : (
      <ToolTipComponent event={event} />
    );
    return (
      <Tooltip position="bottom" content={toolTipComponent}>
        <div className="rbc-calendar__custom-event">
          <span className="assignment-title__span">{event.title}</span>
          <span>
            {`${moment(event.start).format('HH:mm')} - ${moment(
              event.end
            ).format('HH:mm')}`}
          </span>
        </div>
      </Tooltip>
    );
  };

  const MonthEvent = ({ event }) => {
    const toolTipComponent = customTooltipComponent ? (
      customTooltipComponent(event)
    ) : (
      <ToolTipComponent event={event} />
    );
    return (
      <Tooltip position="top" content={toolTipComponent}>
        <span className="rbc-calendar__month-event">
          <strong>{moment(event.start).format('HH:mm')}</strong>
          {` ${event.title}`}
        </span>
      </Tooltip>
    );
  };

  const CustomToolbar = ({ date, label, onNavigate, onView, view }) => {
    useEffect(() => {
      onNavigate('current');
    }, []);

    return (
      <div className="rbc-custom-toolbar">
        <div
          className={`rbc-view-group${filterAction ? ' filter-active' : ''}`}
        >
          <button
            className={`rbc-view--button${view === 'day' ? ' active' : ''}`}
            onClick={() => {
              onView('day');
            }}
          >
            {locale.day}
          </button>
          <button
            className={`rbc-view--button${view === 'week' ? ' active' : ''}`}
            onClick={() => {
              onView('week');
            }}
          >
            {locale.week}
          </button>
          <button
            className={`rbc-view--button${view === 'month' ? ' active' : ''}`}
            onClick={() => {
              onView('month');
            }}
          >
            {locale.month}
          </button>
        </div>

        <div>{label}</div>
        <div className="rbc-actions-group">
          <button
            className="rbc-actions--button"
            onClick={() => {
              const now = new Date();
              date.setMonth(now.getMonth());
              date.setYear(now.getFullYear());
              date.setDate(now.getDate());
              onNavigate('current');
            }}
          >
            {locale.today}
          </button>
          <div className="rbc-actions-group--buttons">
            <button
              className="rbc-actions--button"
              onClick={() => {
                view === 'month' && date.setMonth(date.getMonth() - 1);
                view === 'day' && date.setDate(date.getDate() - 1);
                view === 'week' && date.setDate(date.getDate() - 7);
                onNavigate('prev');
              }}
            >
              <Icon name="arrowLeft" size={15} />
            </button>
            <button
              className="rbc-actions--button"
              onClick={() => {
                view === 'month' && date.setMonth(date.getMonth() + 1);
                view === 'day' && date.setDate(date.getDate() + 1);
                view === 'week' && date.setDate(date.getDate() + 7);
                onNavigate('next');
              }}
            >
              <Icon name="arrowRight" size={15} />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const screenResize = () => {
    setCalendarViewerSize({
      height:
        window.innerHeight -
        calendarContainerRef.current.getBoundingClientRect().top -
        40,
      width:
        window.innerWidth -
        calendarContainerRef.current.getBoundingClientRect().left -
        65,
    });
  };

  useEffect(() => {
    screenResize();
    window.addEventListener('resize', screenResize);
    return () => {
      window.removeEventListener('resize', screenResize);
    };
  }, [filterActive]);

  return (
    <div
      className="rbc-calendar__rbc-wrapper"
      ref={calendarContainerRef}
      style={{
        height: calendarViewerSize.height,
        width:
          responsiveScreen === SCREEN_CATEGORIES.SCREEN_MD &&
          calendarViewerSize.width,
      }}
    >
      {filterAction && (
        <button
          className={`rbc-calendar__filter-button ${
            filterActive ? 'active' : ''
          }`}
          onClick={() => {
            onFilter();
          }}
        >
          {locale.filter}
        </button>
      )}
      <DnDCalendar
        localizer={localizer}
        events={listEvents}
        style={{ height: 'inherit' }}
        onEventDrop={onEventDnD}
        defaultView={Views.WEEK}
        resizableAccessor={() => false}
        tooltipAccessor={() => false}
        onDoubleClickEvent={event => onDoubleClickEvent(event)}
        messages={{
          ...locale,
          showMore: total => `+${total} ${locale.showMore}`,
        }}
        components={{
          toolbar: CustomToolbar,
          week: {
            event: CustomEvent,
          },
          day: {
            event: CustomEvent,
          },
          month: {
            event: MonthEvent,
          },
        }}
        dayPropGetter={customDayPropGetter}
        eventPropGetter={eventPropGetter}
        onRangeChange={range => {
          if (range.length === 1) {
            const utcRange = getUTCDatesFromRanges(range);
            const endDate = setEndOfDayForMaxData(utcRange[0]);
            getRange({
              minDate: utcRange[0],
              maxDate: endDate,
            });
          } else if (range.length === 7) {
            const utcRange = getUTCDatesFromRanges(range);
            const endDate = setEndOfDayForMaxData(utcRange[6]);
            getRange({
              minDate: utcRange[0],
              maxDate: endDate,
            });
          } else {
            const endDate = setEndOfDayForMaxData(
              getUTCDateFromLocalDate(range.end)
            );
            getRange({
              minDate: getUTCDateFromLocalDate(range.start),
              maxDate: endDate,
            });
          }
        }}
      />
    </div>
  );
};

TaskCalendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEventChange: PropTypes.func.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  onDoubleClickEvent: PropTypes.func,
  filterAction: PropTypes.bool,
  clickFilter: PropTypes.func,
  locale: PropTypes.shape({
    month: PropTypes.string,
    day: PropTypes.string,
    week: PropTypes.string,
    today: PropTypes.string,
    showMore: PropTypes.string,
    filter: PropTypes.string,
  }),
  filterActive: PropTypes.bool,
  customTooltipComponent: PropTypes.func,
  isChatOpened: PropTypes.bool,
  eventPropGetter: PropTypes.func,
};

TaskCalendar.defaultProps = {
  locale: {
    month: 'Month',
    day: 'Day',
    week: 'Week',
    today: 'Today',
    showMore: 'More',
    filter: 'filters',
    patient: 'Patient',
    record: 'Medical Record',
    start: 'Start time',
    end: 'End time',
    status: 'Status',
    type: 'Type',
    location: 'Location',
  },
  filterAction: false,
  filterActive: false,
  isChatOpened: false,
};

export default TaskCalendar;
