import { combineReducers } from 'redux';

import analytics from './analytics';
import patients from './patients';
import wearables from './wearables';
import patientTable from './Datatables/patients';
import patientFilters from './Datatables/patientFilters';
import templates from './Datatables/templates';
import assessment from './Datatables/assessments';
import careManager from './Datatables/careManager';
import services from './services';
import session from './session';
import caretaskAnswering from '../src/Views/Patients/Caretask/Answering/CaretaskAnswering.reducer';
import notifications from '../src/Views/Notifications/Notification.reducer';
import flashMessages from '../caro-ui-commonfiles/components/FlashMessage/FlashMessages.reducer';

export default combineReducers({
  analytics,
  patients,
  wearables,
  patientTable,
  patientFilters,
  templates,
  assessment,
  careManager,
  services,
  session,
  caretaskAnswering,
  flashMessages,
  notifications,
});
