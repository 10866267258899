/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import I18n from 'i18next';

import { inputField, MaxCharLimits } from '@ui-common-files/utils';

import DataTable from '../../caro-ui-commonfiles/components/DataTable/DataTable';
import Input from '../../caro-ui-commonfiles/components/TextInput/TextInput';
import serviceConfig from '../../serviceConfig.json';
import Icon from '../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../caro-ui-commonfiles/components/Flex/Flex';
import ModalSection from './common/Layout/Modal/ModalSection';
import { PrimaryColor } from '../../caro-ui-commonfiles/utils/colors';
import AssignCareplanRequest from '../Utils/AssignCareplanRequest';
import { PatientOverviewContext } from './PatientOverview/PatientOverview';
import ShowCareplanTemplateRequest from '../Utils/ShowCareplanTemplateRequest';
import FlashMessage from '../../caro-ui-commonfiles/components/FlashMessage/FlashMessage';
import ICDViewer from '../../caro-ui-commonfiles/components/ICD/ICDViewer';
import { prepareRowsForDatatables } from './Careplans/CareplanHelperUtility';
import TagsCell from './Datatables/CellElements/TagsCell';

const CareplanTemplateForAssign = ({
  setCareplanData,
  setCareplanDataThresholds,
  isTemplateSelected,
  setIsTemplateSelected,
  setIsEvaluating,
  setCareplanDataLogicJumps,
  setCareplanScoringScheme,
  setIsCaretaskDataLoading,
}) => {
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'name',
  });
  const [fetchedData, setFetchedData] = useState([]);
  const [services, setServices] = useState({});
  const [loading, setLoading] = useState(false);
  const [flashMessageDatatable, setFlashMessageDatatable] = useState({});
  const { setFlashMessage } = useContext(PatientOverviewContext);
  const [query, setQuery] = useState({
    value: '',
    start: 0,
    length: 10,
    dir: 'asc',
    column: 'name',
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageState, setPageState] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [typingTimeOut, setTypingTimeOut] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      const searchTermLength = query.value.length;
      if (searchTermLength > 1 || searchTermLength === 0) {
        return await axios
          .get(
            serviceConfig.brokerService.getAllCareplanTemplatesForDatatable.uri,
            {
              params: {
                queryData: query,
              },
              timeout: 30000,
            }
          )
          .then(function (result) {
            setServices(result.data.services);
            setLoading(false);
            setFetchedData(prepareRowsForDatatables(result.data.data));
            setRecordsTotal(result.data.recordsTotal);
          });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFlashMessageDatatable({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  useEffect(() => {
    if (typingTimeOut > 0) clearTimeout(typingTimeOut);
    if (query.value.length === 0) {
      fetchData();
    } else {
      const timeOut = setTimeout(() => {
        fetchData();
      }, 1000);
      setTypingTimeOut(timeOut);
    }
    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [query]);

  useEffect(() => {
    setQuery({
      start: currentPage * pageState,
      length: pageState,
      dir: sort.dir,
      column: sort.column,
      value: query.value,
    });
  }, [currentPage, pageState, sort]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];
  const tableProps = {
    defaultSorted: [
      {
        id: 'name',
        desc: false,
      },
    ],
    onSortedChange: (newSorted, column, shiftKey) => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
    getTdProps: (state, rowInfo, column, instance) => ({
      onClick: (e, handleOriginal) => {
        if (column.id !== 'rowOptions') {
          const { row } = rowInfo;
          const { id } = row._original;
          const { icds } = row._original;
          const { tags, isEvaluating, roles, isAssessment } = row._original;
          const nbrComponents = row._original.questions;
          setIsTemplateSelected(rowInfo.index);
          setIsCaretaskDataLoading(true);
          if (handleOriginal) {
            handleOriginal();
          }

          ShowCareplanTemplateRequest(
            id,
            setCareplanDataThresholds,
            () => {},
            setCareplanDataLogicJumps,
            setCareplanScoringScheme
          );
          setIsEvaluating(isEvaluating);
          return AssignCareplanRequest(
            id,
            setFlashMessage,
            setCareplanData,
            setCareplanScoringScheme,
            icds,
            tags,
            nbrComponents
          );
        }
      },
      style: {
        background:
          rowInfo.index === isTemplateSelected
            ? PrimaryColor.MAIN_COLOR_LIGHT
            : null,
      },
    }),
    getTrProps: () => ({
      style: {
        cursor: 'pointer',
      },
    }),
  };

  const columns = [
    {
      filterable: false,
      maxWidth: 60,
      style: {
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },
      headerStyle: {
        marginRight: -20,
      },
      Cell: ({ original }) => {
        const { isAssessment } = original;
        return (
          <Box width={30}>
            <Icon name={isAssessment ? 'assessment' : 'careplan'} size={30} />
          </Box>
        );
      },
    },
    {
      Header: ({ column }) => {
        const { id } = column;
        return (
          <>
            {sort.column === id && (
              <Box width={20}>
                <Icon
                  name="sortArrow"
                  size={22}
                  className={
                    sort.column === id && sort.dir === 'desc' ? 'desc-sort' : ''
                  }
                />
              </Box>
            )}
            <span>{I18n.t('newCareplan_view.title')}</span>
          </>
        );
      },
      headerClassName: 'data-table__column-1__header',
      accessor: 'name',
      filterable: false,
      minWidth: 200,
      style: {
        fontWeight: 600,
        wordBreak: 'break-word',
      },
      headerStyle: {
        marginRight: 20,
      },
    },
    {
      Header: () => {
        return <span>{I18n.t('newCareplan_view.conducted_by')}</span>;
      },
      headerClassName: 'data-table__column-1__header',
      accessor: 'roles',
      filterable: false,
      sortable: false,
      Cell: ({ row }) => {
        const { isAssessment, roles } = row._original;
        const rolePatients = [];
        if (!isAssessment) {
          rolePatients.push({ name: I18n.t('role_view.label_patients') });
        }
        return (
          <TagsCell tagList={!isAssessment ? rolePatients : roles} roles />
        );
      },
    },
    {
      Header: () => {
        return <span>{I18n.t('newCareplan_view.icd10')}</span>;
      },
      headerClassName: 'data-table__column-1__header',
      accessor: 'icds',
      sortable: false,
      filterable: false,
      style: {
        overflow: 'visible',
      },
      width: 180,
      Cell: ({ row }) => {
        return <ICDViewer icds={row.icds} fromAssignModal />;
      },
    },
    {
      Header: () => {
        return <span>{I18n.t('newCareplan_view.internalTitle')}</span>;
      },
      headerClassName: 'data-table__column-1__header',
      accessor: 'alias',
      sortable: false,
      filterable: false,
      minWidth: 90,
    },
  ];

  return (
    <>
      <ModalSection hasPadding>
        {flashMessageDatatable.content && (
          <FlashMessage
            message={I18n.t(flashMessageDatatable.content)}
            type={flashMessageDatatable.type}
            onClick={() => {
              setFlashMessageDatatable({});
            }}
          />
        )}
        <Flex
          justifyContent="flex-end"
          otherStyles={{
            width: '100%',
          }}
        >
          <Box>
            <Input
              id="specialId2"
              placeholder={I18n.t('common_labels.label_search')}
              variant={inputField.variant.CHAR_COUNT}
              maxChars={MaxCharLimits.searchInputs.searchKeyword}
              value={query.value}
              handleOnChange={e => {
                setQuery({
                  value: e.target.value,
                  start: 0,
                  length: 10,
                  dir: sort.dir,
                  column: sort.column,
                });
                setCurrentPage(0);
                setPageState(10);
              }}
            />
          </Box>
        </Flex>

        <DataTable
          data={fetchedData}
          columns={columns}
          pageSize={false}
          pageSizeOptions={items}
          pagination
          isLoading={loading}
          tableProps={tableProps}
          onTableChange={(index, size) => {
            setCurrentPage(
              fetchedData && index * size > recordsTotal ? 0 : index
            );
            setPageState(size);
          }}
          page={currentPage}
          pages={Math.ceil(recordsTotal / query.length)}
          totalRecord={recordsTotal}
        />
      </ModalSection>
    </>
  );
};

export default withTranslation()(CareplanTemplateForAssign);
