import React from 'react';
const TagScore = ({ children, isTotalScoreTag }) => {
  return (
    <div className={`tag__score--${isTotalScoreTag ? 'total' : 'subScore'}`}>
      <span className="tag__score__label">{children}</span>
    </div>
  );
};

export default TagScore;
