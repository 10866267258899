const breadcrumbAdministrationObject = [
  { value: 1, label: 'types.componentType.users' },
  { value: 2, label: 'types.componentType.sites' },
  { value: 3, label: 'types.componentType.customFilters' },
  { value: 4, label: 'rolemanager_view.label_role_title' },
  { value: 5, label: 'diagnostics' },
  { value: 6, label: 'patientLogs' },
  { value: 7, label: 'types.componentType.satellites' },
];

const breadcrumbNotificationObject = [
  { value: 1, label: 'threshold.allThresholds' },
  { value: 2, label: 'chatMessages.messageNotifications' },
  { value: 3, label: 'todos.allTodos' },
];

const breadcrumbCareplanObject = [
  { value: 1, label: 'breadcrumbs.carepathway' },
  { value: 2, label: 'breadcrumbs.careplan' },
  { value: 3, label: 'breadcrumbs.reviewCaretasks' },
];

const breadcrumbFilesObject = [
  { value: 1, label: 'asset_view.documents' },
  { value: 2, label: 'asset_view.images' },
  { value: 3, label: 'asset_view.videos' },
  { value: 4, label: 'asset_view.audios' },
  { value: 5, label: 'admin_view.label-privacy-policy' },
];

export {
  breadcrumbAdministrationObject,
  breadcrumbNotificationObject,
  breadcrumbCareplanObject,
  breadcrumbFilesObject,
};
