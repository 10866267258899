import React from 'react';

import { Flex, Box } from '@ui-common-files/components';

import { WearablesDataTypes } from '@ui-common-files/utils';

import { getActivityButtonComponents } from '../../../../../Wearables/WearablesUtility';

import useHealthDataService from './HealthData.service';

import '../HealthData/HealthData.styles.scss';

const HealthDataButtonLabel = ({ name }) => {
  return <Flex className="health_data_button_label">{name}</Flex>;
};

const HealthDataButton = ({ activityKey }) => {
  const {
    isHealthDataButtonDisabled,
    activityButtonClickHandler,
    borderColor,
  } = useHealthDataService(activityKey);

  const { component, name } = getActivityButtonComponents(activityKey, 18);
  return (
    <button
      key={`health-${activityKey}`}
      className="health_data_button"
      onClick={e => activityButtonClickHandler(e, activityKey)}
      type="button"
      style={{ borderColor }}
    >
      {isHealthDataButtonDisabled(activityKey) && <Box className="disabled" />}
      {component}
      <HealthDataButtonLabel name={name} />
    </button>
  );
};

export const HealthDataButtons = () => {
  const wearablesTypes = Object.entries(WearablesDataTypes)
    .filter(([, value]) => isNaN(parseInt(value, 10)))
    .map(([key]) => parseInt(key, 10));

  return (
    <Flex>
      {wearablesTypes.map(key => (
        <HealthDataButton key={key} activityKey={key} />
      ))}
    </Flex>
  );
};
