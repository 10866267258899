import { useState, useEffect } from 'react';

function useWindowWidth(currentWidth) {
  const [width, setWidth] = useState(currentWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return width;
}

export default useWindowWidth;
