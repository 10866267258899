import { useDispatch } from 'react-redux';

import { useAnalytics } from '@global/Services';

import { resetPatientData } from '../../../../actions/patients';

export default function usePatientShowModalService(setShowPatient: Function) {
  const reduxDispatcher = useDispatch();
  const { trackEventTrigger } = useAnalytics();
  const onCloseShowPatientModal = () => {
    trackEventTrigger({
      category: 'patient',
      action: 'Close-Show-Modal',
    });
    setShowPatient({
      show: false,
      data: {},
    });
    reduxDispatcher(resetPatientData());
  };
  return {
    onCloseShowPatientModal,
  };
}
