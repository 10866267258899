const ScanQrCode = ({}) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.replace("https://play.google.com/store/apps/details?id=com.gmbh.oncare.myoncare&hl=en&gl=US");
    }

    else {
      window.location.replace("https://apps.apple.com/de/app/myoncare/id1487210171");        
    }

  return ;
};

export default ScanQrCode;