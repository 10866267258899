import { toast } from 'react-toastify';

export default {
  info(msg, options = {}) {
    return toast.info(msg, { ...options, className: 'toast__threshold' });
  },
  success(msg, options = {}) {
    return toast.success(msg, { ...options, className: 'toast__chat' });
  },
  generic(msg, options) {
    return toast.warning(msg, {
      ...options,
      className: `toast__generic ${options.type}`,
      autoClose:
        options.type === 'warning' && !options.forceAutoClose ? false : 5000,
    });
  },
};
