import React from 'react';

import { Box, Flex, Icon } from '@ui-common-files/components';

const CaretaskItem = ({ template }) => {
  const shouldRenderInternalTitle = !!template.alias;
  const iconName = template.isAssessment ? 'Assessment' : 'Careplan';

  return (
    <Flex alignItems="center">
      <Icon name={iconName} size={28} />
      <Box otherStyles={{ marginLeft: '7px' }}>
        {shouldRenderInternalTitle && (
          <Box className="care-task-list_content-item-alias">
            {template.alias}
          </Box>
        )}
        <Box className="care-task-list_content-item-title">{template.name}</Box>
      </Box>
    </Flex>
  );
};

export default CaretaskItem;
