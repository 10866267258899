import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import Button from '../../../../../caro-ui-commonfiles/components/Button/Button';
import UsFilterTopWarning from '../../../../../caro-ui-commonfiles/components/UsFilterWarnings/UsFilterTopWarning';

import { ButtonType, ButtonVariant} from '../../../../../caro-ui-commonfiles/utils/button';

const ModalFooter = ({
  close,
  form,
  labelCTA,
  onClickCTA,
  fromIndex,
  additionalFunctionality,
  disabled,
  isOncoBox
}) => {
  return (
    <Flex
      justifyContent={isOncoBox ? "space-between" : "flex-end"}
      alignItems={fromIndex ? 'center' : null}
      otherStyles={{
        flex: 1,
      }}
    >
      <Box margin={fromIndex ? null : '0 16px 0 0'}>
        <Button
          label={I18n.t('common_buttons.cancel')}
          onClick={close}
          variant={ButtonVariant.OUTLINED}
        />
      </Box>

      {additionalFunctionality}

      {fromIndex && (
        <Flex
          alignItems="center"
          justifyContent="center"
          otherStyles={{ flex: 1 }}
        >
          <UsFilterTopWarning
            message={I18n.t('customFilters_view.label_update_index')}
            showUsWarningTopPage
            showEditIcon={false}
            showFilter={false}
          />
        </Flex>
      )}

      <Button
        label={labelCTA}
        type={ButtonType.SUBMIT}
        form={form}
        onClick={onClickCTA}
        disabled={disabled}
      />
    </Flex>
  );
};

ModalFooter.propTypes = {
  close: PropTypes.func.isRequired,
  form: PropTypes.string,
  labelCTA: PropTypes.string.isRequired,
  fromIndex: PropTypes.bool,
  onClickCTA: PropTypes.func,
  additionalFunctionality: PropTypes.node,
  disabled: PropTypes.bool,
  isOncoBox: PropTypes.bool
  
};

ModalFooter.defaultProps = {
  fromIndex: false,
  additionalFunctionality: null,
  disabled: false,
  isOncoBox: false
};

export default ModalFooter;
