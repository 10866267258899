import React from 'react';
import I18n from 'i18next';

import {
  handleDropDownState,
  addThreshold,
  removeThreshold,
  isThresholdSet,
  setPrefilledThresholdFromDropdown,
} from '../CareplanHelperUtility';
import IconCheckbox from '../../../../caro-ui-commonfiles/components/IconCheckbox/IconCheckbox';
import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Dropdown from '../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';

import iconCheckbox from '../../../../caro-ui-commonfiles/utils/iconCheckbox';

import  { ButtonColor, ButtonSize, ButtonVariant } from '../../../../caro-ui-commonfiles/utils/button';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';

const getThresholdOptions = () => {
  return [
    {
      value: 'back',
      label: I18n.t('bodyChartImage.back'),
      key: 'bodyChartImage.back',
    },
    {
      value: 'head',
      label: I18n.t('bodyChartImage.head'),
      key: 'bodyChartImage.head',
    },
    {
      value: 'occiput',
      label: I18n.t('bodyChartImage.occiput'),
      key: 'bodyChartImage.occiput',
    },
    {
      value: 'shoulder',
      label: I18n.t('bodyChartImage.shoulder'),
      key: 'bodyChartImage.shoulder',
    },
    {
      value: 'leftChest',
      label: I18n.t('bodyChartImage.leftChest'),
      key: 'bodyChartImage.leftChest',
    },
    {
      value: 'rightChest',
      label: I18n.t('bodyChartImage.rightChest'),
      key: 'bodyChartImage.rightChest',
    },
    {
      value: 'stomach',
      label: I18n.t('bodyChartImage.stomach'),
      key: 'bodyChartImage.stomach',
    },
    {
      value: 'alvus',
      label: I18n.t('bodyChartImage.alvus'),
      key: 'bodyChartImage.alvus',
    },
    {
      value: 'fundament',
      label: I18n.t('bodyChartImage.fundament'),
      key: 'bodyChartImage.fundament',
    },
    {
      value: 'leftUpperArm',
      label: I18n.t('bodyChartImage.leftUpperArm'),
      key: 'bodyChartImage.leftUpperArm',
    },
    {
      value: 'leftLowerArm',
      label: I18n.t('bodyChartImage.leftLowerArm'),
      key: 'bodyChartImage.leftLowerArm',
    },
    {
      value: 'rightUpperArm',
      label: I18n.t('bodyChartImage.rightUpperArm'),
      key: 'bodyChartImage.rightUpperArm',
    },
    {
      value: 'rightLowerArm',
      label: I18n.t('bodyChartImage.rightLowerArm'),
      key: 'bodyChartImage.rightLowerArm',
    },
    {
      value: 'leftLeg',
      label: I18n.t('bodyChartImage.leftLeg'),
      key: 'bodyChartImage.leftLeg',
    },
    {
      value: 'rightLeg',
      label: I18n.t('bodyChartImage.rightLeg'),
      key: 'bodyChartImage.rightLeg',
    },
    {
      value: 'neck',
      label: I18n.t('bodyChartImage.neck'),
      key: 'bodyChartImage.neck',
    },
    {
      value: 'throat',
      label: I18n.t('bodyChartImage.throat'),
      key: 'bodyChartImage.throat',
    },
    {
      value: 'genital',
      label: I18n.t('bodyChartImage.genital'),
      key: 'bodyChartImage.genital',
    },
  ];
};

const renderThresholdFilters = (
  item,
  index,
  thresholdDropdown,
  setThresholdDropdown,
  thresholdNumber,
  setThresholdNumber,
  componentCollection,
  setComponentCollection,
  searchTermComponents
) => {
  return item.thresholdCollection.thresholds.map((elem, i) => {
    return (
      <div
        key={`unique-threshold-plc-${i}`}
        className="numericValueThresholdParent"
        style={{ paddingTop: 10 }}
      >
        <div className="thresholdIconDelete">
          <IconCheckbox
            id={`plc-threshold-checkbox-${index}`}
            iconName="threshold"
            name={`threshold-plc-${index}-${i}`}
            variant={iconCheckbox.variant.CIRCLE}
            checked
            handleOnChange={() =>
              removeThreshold(
                item,
                i,
                componentCollection,
                setComponentCollection
              )
            }
            multiSelectEnabled
            size={24}
            fill={ActionColors.THRESHOLD}
          />
        </div>
        <div className="thresholdDropdownContainer">
          <div style={{ width: '100%' }}>
            <Dropdown
              value={setPrefilledThresholdFromDropdown(elem)}
              options={getThresholdOptions()}
              isDisabled={false}
              isSearchable={false}
              placeholder=""
              handleOnChange={state =>
                handleDropDownState(
                  item,
                  i,
                  state,
                  setThresholdDropdown,
                  componentCollection,
                  setComponentCollection
                )
              }
              fontSize={14}
              heightControl={36}
            />
          </div>
        </div>
      </div>
    );
  });
};

const renderPainLocationChart = (
  item,
  componentCollection,
  searchTermComponents,
  setComponentCollection,
  thresholdDropdown,
  setThresholdDropdown,
  thresholdNumber,
  setThresholdNumber
) => {
  const index = componentCollection.findIndex(comp => comp.id === item.id);
  const thresholdToggle = isThresholdSet(
    index,
    componentCollection,
    searchTermComponents
  );

  return (
    <div className="painLocationParent">
      {thresholdToggle && (
        <>
          {renderThresholdFilters(
            item,
            index,
            thresholdDropdown,
            setThresholdDropdown,
            thresholdNumber,
            setThresholdNumber,
            componentCollection,
            setComponentCollection,
            searchTermComponents
          )}
          <Box margin="10px 0 0 50px">
            <Button
              label={`+ ${
                item.thresholdCollection.thresholds.length > 0
                  ? I18n.t('threshold.addMoreThresholdLabel')
                  : I18n.t('threshold.addThresholdLabel')
              }`}
              variant={ButtonVariant.NO_BORDER}
              color={ButtonColor.RED}
              size={ButtonSize.MD}
              onClick={() =>
                addThreshold(
                  item,
                  thresholdDropdown,
                  thresholdNumber,
                  componentCollection,
                  setComponentCollection
                )}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default renderPainLocationChart;
