import React, { useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import MentionItem from '../MentionItem/MentionItem';
import Flex from '../Flex/Flex';
import Box from '../Box/Box';
import generateRandomId from '../../utils/GenerateRandomId/GenerateRandomId';
import { useWYSIWYGEditor } from './WYSIWYGEditor.service';
import { WYSIWYGEditorProps } from './WYSIWYGEditor.type';

import './WYSIWYGEditor.styles.scss';

const BASE_URL = `${window.location.protocol}//${window.location.host}`;

const WYSIWYGEditor: React.FC<WYSIWYGEditorProps> = ({
  onChange,
  placeholder,
  value,
  maxChars,
  hasLink = false,
  hasMention = false,
  mentionListCall = null,
  hideToolbar = false,
  hideWordCount = false,
  className = '',
  textBoxClassName = '',
}) => {
  const {
    wordCount,
    handleEditorChange,
    handleInit,
    init,
    onKeyPress,
    userList,
    popupVisible,
    rolesList,
    satellitesList,
    insertMention,
  } = useWYSIWYGEditor({
    maxChars,
    onChange,
    hasLink,
    placeholder,
    hasMention,
    mentionListCall,
    hideToolbar,
    hideWordCount,
  });
  const editorKey = useMemo(generateRandomId, [placeholder]);

  return (
    <Box className={className}>
      <Flex justifyContent="center">
        <Box className={textBoxClassName || 'editor-Wrapper'}>
          <Editor
            key={editorKey}
            tinymceScriptSrc={`${BASE_URL}/tinymce/tinymce.min.js`}
            onInit={handleInit}
            value={value}
            onEditorChange={handleEditorChange}
            onKeyPress={onKeyPress}
            init={init}
          />
        </Box>
        {maxChars && (
          <Box className="wordCount">
            {wordCount}/{maxChars}
          </Box>
        )}
      </Flex>
      {popupVisible && (
        <ul id="mention-popup" className="mention-popup">
          {userList.map(user => (
            <MentionItem
              key={user.id}
              mention={{ ...user, type: 'user' }}
              insertMention={insertMention}
            />
          ))}
          {rolesList.map(role => (
            <MentionItem
              key={role.id}
              mention={{ ...role, type: 'role' }}
              insertMention={insertMention}
            />
          ))}
          {satellitesList.map(satellite => (
            <MentionItem
              key={satellite.id}
              mention={{ ...satellite, type: 'satellite' }}
              insertMention={insertMention}
            />
          ))}
        </ul>
      )}
    </Box>
  );
};

export default WYSIWYGEditor;
