import React from 'react'
import Flex from '../Flex/Flex'
import I18n from 'i18next';

function CareTaskSequence({startText,endText,occurrence}) {
  return (
    <Flex flexDirection="column" otherStyles={{margin:'10px 20px 10px',width:'100%'}} >
    <Flex otherStyles={{ marginBottom: 8 }}>
                    <div className="duration-container__title">
                      {`${I18n.t('common_labels.start')}:`}
                    </div>
                    <div className="duration-container__content">
                      {startText}
                    </div>
                  </Flex>
                  <Flex otherStyles={{ marginBottom: 8 }}>
                    <div className="duration-container__title">
                      {`${I18n.t('common_labels.end')}:`}
                    </div>
                    <div className="duration-container__content">{endText}</div>
                  </Flex>
                  <Flex>
                    <div className="duration-container__title">
                      {`${I18n.t('recurrence.repetition')}: `}
                    </div>
                    <div className="duration-container__content">
                      {occurrence}
                    </div>
                  </Flex>
</Flex>
  )
}
export default CareTaskSequence