import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Box,
  ModalContent,
  Label,
  RadioButton,
  ContentLoader,
} from '@ui-common-files/components';

import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';

import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';

import CareplanResultsModalContentAnswers from './CareplanResultsModalContentAnswers';
import { getRadioButtonVariant } from './CareplanHelperUtility';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

import serviceConfig from '../../../serviceConfig.json';

import '../../css/careplans.scss';
import '../../css/answers.css';

function CareplanResultsModalContent({ careplanData, chosenDate }) {
  const { t, i18n } = useTranslation();

  const { setFlashMessage, closeModal } = useContext(PatientOverviewContext);

  const firstDate = chosenDate || careplanData[0].dates[0].savedAt;

  const [careplanDataWithAnswers, setCareplanDataWithAnswers] = useState({});
  const [currentChoice, setCurrentChoice] = useState(firstDate);
  const [isLoading, setIsLoading] = useState(false);

  const dates = careplanData.reduce((result, careplan) => {
    const datesInfo = careplan.dates
      ? careplan.dates.map(date => {
          const savedDate = date.savedAt;
          const answeredOccurrence = careplan.qc.allOccurrences.find(
            occurrence => occurrence.id === date.allOccurrenceId
          );
          const formattedDate = moment(savedDate)
            .locale(i18n.language)
            .format('DD MMM YYYY HH:mm');

          return (
            <div style={{ marginTop: '10px' }}>
              <RadioButton
                id={savedDate}
                name={savedDate}
                label={formattedDate}
                checked={savedDate === currentChoice}
                variant={getRadioButtonVariant(
                  answeredOccurrence.healthIndicatorId
                )}
                handleOnChange={e => {
                  if (e.target.checked) {
                    setCurrentChoice(savedDate);
                    getCareplanAnswersWithSpecificDate(
                      savedDate,
                      careplan.qc.id
                    );
                  }
                }}
              />
              {answeredOccurrence && answeredOccurrence.user && (
                <Box className="answered-by">
                  {answeredOccurrence.user.fullName}
                </Box>
              )}
            </div>
          );
        })
      : null;
    if (datesInfo) {
      result.push(...datesInfo);
    }
    return result;
  }, []);

  const getCareplanAnswersWithSpecificDate = async (
    date,
    currentCareplanId
  ) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${
          serviceConfig.brokerService.getCareplanAnswersWithSpecificDate.uri +
          currentCareplanId
        }/${date}`
      );
      if (response) {
        setCareplanDataWithAnswers(response.data);
        setCurrentChoice(date);
      }
    } catch (error) {
      closeModal();
      if (error.response) {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      }
      throw new Error(error.response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCareplanAnswersWithSpecificDate(firstDate, careplanData[0].qc.id);
  }, [careplanData]);

  return (
    <ModalContent>
      <ModalForm>
        <ModalSection variant={MODAL_VARIANT.COL_W20}>
          <Box margin="10px 0 0 20px">
            <div className="overview-label">
              <Label text={t('patientOverview.overview')} />
            </div>
            <div className="overview-label">
              <Label text={t('QuestionCatalog_view.timeOfAnswer')} />
            </div>
            <Box>{dates}</Box>
          </Box>
        </ModalSection>
        <ModalSection variant={MODAL_VARIANT.COL_W80}>
          {isLoading ? (
            <ContentLoader />
          ) : (
            <CareplanResultsModalContentAnswers
              questionWithThresholdsAndJumps={
                careplanDataWithAnswers.questionWithThresholdsAndJumps
              }
              careplanData={careplanData[0].qc}
              scores={careplanDataWithAnswers.scores}
              overallScore={careplanDataWithAnswers.overallScore}
            />
          )}
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
}

export default CareplanResultsModalContent;
