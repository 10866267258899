/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import NotificationRecord from './NotificationRecord';
import NavigationJSON from '../../Utils/navigation.json';
import serviceConfig from '../../../serviceConfig.json';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import { convertLinkToBlob } from '../../Utils/Asset/assetHelper';
import { NotificationType } from './Notification.type';
import { NotificationTypes } from '@type/Notifications';

const ChatNotificationDropdown = ({ offsetRight }) => {
  const services = useSelector(state => state.services);
  const history = useHistory();
  const calcDropdownWidth = () => {
    return Math.min((offsetRight - 40) * 2, 320);
  };

  const [dropdownWidth, setDropdownWidth] = useReducer(
    (_state, width) => width,
    calcDropdownWidth()
  );
  const [notifications, setNotifications] = useReducer(
    (_state, record) => record,
    {
      threshold: null,
      chat: null,
    }
  );
  const [isLoading, setIsLoading] = useReducer(
    (_state, loading) => loading,
    true
  );

  const redirectToPatientOverview = (patientId, recordId) => {
    history.push(`/${NavigationJSON.PATIENT_OVERVIEW}/${patientId}?tab=0`, {
      shouldOpenChat: true,
      chat: true,
      recordId,
    });
  };

  const renderChatTable = () => {
    if (notifications.chat && notifications.chat.length) {
      return notifications.chat.map(record => (
        <NotificationRecord
          key={`chat-${record.id}`}
          type={NotificationType.Chat}
          rowData={record}
          maxWidth={dropdownWidth}
          redirect={redirectToPatientOverview}
        />
      ));
    }
  };
  useEffect(() => {
    const getNotifications = async () => {
      let thresholdResponse = {};
      let chatResponse = {};
      try {
        if (services.thresholdNotification) {
          thresholdResponse = await axios
            .get(serviceConfig.brokerService.thresholdNotifications.uri)
            .then(async response => {
              const thresholdNotifications = response.data;
              const processedNotifications = await Promise.all(
                thresholdNotifications.newNotifications.map(
                  async notificationObj => {
                    if (notificationObj.patient.profilePicture.directLink) {
                      await convertLinkToBlob(
                        notificationObj.patient.profilePicture.directLink
                      )
                        .then(blob => {
                          notificationObj.patient.profilePicture = blob;
                        })
                        .catch(() => {
                          notificationObj.patient.profilePicture =
                            notificationObj.patient.profilePicture.directLink;
                        });
                    } else {
                      notificationObj.patient.profilePicture = `data:image;base64,${notificationObj.patient.profilePicture}`;
                    }
                    return notificationObj;
                  }
                )
              );
              thresholdNotifications.newNotifications = processedNotifications;
              return thresholdNotifications;
            });
        }
        if (services.chatNotification) {
          chatResponse = await axios
            .get(serviceConfig.chatService.getMessageNotifications.uri)
            .then(async response => {
              const chatNotifications = response.data;
              const processedNotifications = await Promise.all(
                chatNotifications.chatMessages.map(async notificationObj => {
                  if (notificationObj.patient.profilePicture.directLink) {
                    await convertLinkToBlob(
                      notificationObj.patient.profilePicture.directLink
                    )
                      .then(blob => {
                        notificationObj.patient.profilePicture = blob;
                      })
                      .catch(() => {
                        notificationObj.patient.profilePicture =
                          notificationObj.patient.profilePicture.directLink;
                      });
                  } else {
                    notificationObj.patient.profilePicture = `data:image;base64,${notificationObj.patient.profilePicture}`;
                  }
                  return notificationObj;
                })
              );
              chatNotifications.chatMessages = processedNotifications;
              return chatNotifications;
            });
        }
        if (
          Object.keys(thresholdResponse).length > 0 ||
          Object.keys(chatResponse).length > 0
        ) {
          const chatData = chatResponse ? chatResponse : {};

          setNotifications({
            chat:
              chatData.numChatMessages > 0
                ? chatData.chatMessages.splice(
                    0,
                    Math.min(3, chatData.numChatMessages)
                  )
                : false,
          });
          setIsLoading(false);
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    getNotifications();
  }, []);

  useEffect(() => {
    setDropdownWidth(calcDropdownWidth());
  }, [offsetRight]);

  return (
    <div
      className="notification__table-container"
      style={{ width: dropdownWidth, right: offsetRight - dropdownWidth / 2 }}
    >
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{ height: '80px' }}
        >
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={40}
            width={40}
            marginTop="25px"
          />
        </Flex>
      )}
      {!isLoading && (
        <Flex flexDirection="column">
          {!notifications.chat && (
            <div className="notification__no-messages">
              {i18n.t('chatMessages.noMessages')}
            </div>
          )}
          {notifications.chat && (
            <Box>
              <div className="notification__table-header">
                {i18n.t('chatMessages.messages')}
              </div>
              <div className="notification__table-content">
                {renderChatTable()}
              </div>
            </Box>
          )}
          <div
            role="button"
            className="notification__see-all"
            tabIndex={0}
            onClick={() => {
              history.push(`/${NavigationJSON.NOTIFICATION_CENTER}?notificationType=${NotificationTypes.CHAT}`);
            }}
          >
            {i18n.t('chatMessages.seeAll')}
          </div>
        </Flex>
      )}
    </div>
  );
};

ChatNotificationDropdown.propTypes = {
  offsetRight: PropTypes.number.isRequired,
  setChatShowNotificationDropdown: PropTypes.func.isRequired,
  showChatNotificationDropdown: PropTypes.bool.isRequired,
};

export default React.memo(ChatNotificationDropdown);
