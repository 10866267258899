import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { User } from '@type/User';
import { MyOnCareServices } from '@type/MyOnCareServices/';
import { TabPosition } from '@type/TabPosition';
import { useAnalytics } from '@global/Services';
import {
  FlashMessageData,
  OnUpdatePatientProps,
  PatientTable,
} from './PatientList.type';
import { ShowPatientData } from './ShowPatientModal/ShowPatientModal.type';
import {
  FilterItem,
  SelectedFilter,
} from './PatientListEditFIlter/PatientListEditFilter.type';
import { Patients } from './PatientListResponse.type';
import { DeleteOperation } from './PatientDeleteModal/PatientDeleteModal.type';

import {
  resetPatientData,
  createPatientRequest,
  updatePatientRequest,
  editPatientRequest,
  showPatientRequest,
} from '../../../actions/patients';
import { getPatientsCountersRequest } from './PatientList.api';
import useFloatItems from './hooks/useFloatItems';
import useShowAppliedFilterWarning from './hooks/useShowAppliedFilterWarning';
import complexSearchData from '../../Utils/complexSearchData';
import { calcCurrentPage } from '../../Utils/DataTableUtilities';
import { onFilterSelection } from '../../../actions/Datatables/patientFilter';
import { getPatientsByQueryController } from '../../Utils/ReduxUtilities/APICalls/patients';
import { onTableChangeAction } from '../../../actions/Datatables/patients';
import config from '../../../config';

import '../../css/patient.css';

export default function usePatientsListService() {
  const { user } = useSelector(
    (state: { session: { user: User } }) => state.session
  );
  const actionState = useSelector(
    (state: { patientTable: PatientTable }) => state.patientTable
  );
  const selectedFilter = useSelector(
    (state: { patientFilters: SelectedFilter }) => state.patientFilters
  );
  const reduxDispatcher = useDispatch();
  const { trackEventTrigger } = useAnalytics();
  const history = useHistory();
  const services = useSelector(
    (state: { services: MyOnCareServices }) => state.services
  );
  const customFilterData = complexSearchData(services);
  const { t } = useTranslation();

  const checkPatientsReadPermission =
    selectedFilter.allFilters !== null &&
    selectedFilter.savedFilter.rule !== null;

  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [deleteOperation, setDeleteOperation] = useState<DeleteOperation>({
    display: false,
    id: 0,
    title: '',
    collectionId: null,
  });
  const [forceRender, setForceRender] = useState<boolean>(true);
  const [patients, setPatients] = useState<Patients>(null);
  const [filteredPatientsForCsvExport, setFilteredPatientsForCsvExport] =
    useState<[]>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [doFetch, setDoFetch] = useState<boolean>(false);
  const [modalData, setModalData] = useState(null);
  const [operation, setOperation] = useState<string>('');
  const [showPatient, setShowPatient] = useState<ShowPatientData>({
    show: false,
    id: 0,
    data: {},
    healthScore: 0,
    scoringSchemeCategory: '',
  });
  const [displayEditFilter, setDisplayEditFilter] = useState<boolean>(false);

  const { showAppliedFilterWarning, setShowAppliedFilterWarning } =
    useShowAppliedFilterWarning(selectedFilter);

  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [openResultsModal, setOpenResultsModal] = useState<boolean>(false);
  const [showFilterSettings, setShowFilterSettings] = useState<boolean>(false);
  const [dataForExportAnswers, setDataForExportAnswers] = useState(null);
  const [isPatientDemographicData, setIsPatientDemographicData] =
    useState<boolean>(false);
  const [isFilterSelected, setIsFilterSelected] = useState<boolean>(false);
  const [isNewPatient, setIsNewPatient] = useState<boolean>(false);
  const [oldPatientsCounter, setOldPatientsCounter] = useState<number>(0);
  const [newPatientsCounter, setNewPatientsCounter] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<TabPosition>(TabPosition.FIRST);

  const {
    floatItems,
    exportModalToggle,
    setExportModalToggle,
    importModalToggle,
    setImportModalToggle,
    displayFlashMsg,
    setDisplayFlashMsg,
  } = useFloatItems(config);

  const onPatientFormModalClose = () => {
    trackEventTrigger({
      category: 'patient',
      action: `Close-${operation}-Modal`,
    });
    setDisplayModal(false);
    setModalData(null);
    reduxDispatcher(resetPatientData());
  };
  const onCreatePatientFormModal = (body: {}) => {
    setIsModalLoading(true);
    reduxDispatcher(createPatientRequest(body));
  };
  const onUpdatePatientFormModal = ({
    patientId,
    body,
  }: OnUpdatePatientProps) => {
    setIsModalLoading(true);
    reduxDispatcher(updatePatientRequest(patientId, body));
  };
  const onDeletePatienListView = (
    id: number,
    firstName: string,
    lastName: string,
    collectionId: number
  ) => {
    trackEventTrigger({
      category: 'patient',
      action: 'Open-Delete-Modal',
    });
    setDeleteOperation({
      display: true,
      id,
      title: `${t(
        'common_labels.msg_confirm_patient_delete_headline'
      )}: ${firstName} ${lastName}`,
      collectionId,
    });
  };
  const onEditPatientListView = (id: number) =>
    reduxDispatcher(editPatientRequest(id));
  const onShowPatientListView = (
    id: number,
    healthScore: number,
    scoringSchemeCategory: string
  ) => {
    trackEventTrigger({
      category: 'patient',
      action: 'Open-Show-Modal',
    });
    reduxDispatcher(showPatientRequest(id, healthScore, scoringSchemeCategory));
  };
  const setFlashMessagePatientListView = (msg: FlashMessageData) => {
    setDisplayFlashMsg({
      display: true,
      content: t(msg.content),
      type: msg.type,
    });
  };
  const handlePatientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const patientsByQueryController = getPatientsByQueryController();
    if (patientsByQueryController) {
      patientsByQueryController.abort();
    }
    setSearchTerm(e.target.value);
  };

  const tableHandler = (page: number, pageSize: number) => {
    const pageI = patients
      ? calcCurrentPage(patients.recordsTotal, pageSize, page)
      : page;
    reduxDispatcher(
      onTableChangeAction({
        dir: actionState.dir,
        column: actionState.column,
        page: pageI,
        pageSize,
      })
    );
  };

  const closeMessagePrompt = () => {
    trackEventTrigger({ category: 'patient', action: 'Close-Delete-Modal' });
    setDeleteOperation({
      display: false,
      id: 0,
      title: '',
      collectionId: null,
    });
  };

  const onClickTabItem = (tab: number) => {
    setActiveTab(tab);
    setIsNewPatient(tab !== TabPosition.FIRST);
  };

  const selectAdvancedFilter = (item: FilterItem, index: number) => {
    reduxDispatcher(
      onTableChangeAction({
        dir: actionState.dir,
        column: actionState.column,
        page: 0,
        pageSize: actionState.limit,
      })
    );

    reduxDispatcher(
      onFilterSelection({
        allFilters: selectedFilter.allFilters,
        id: item?.filterId,
        index,
        name: item?.key,
        rule: typeof item.rule === 'string' ? JSON.parse(item.rule) : item.rule,
      })
    );
    setShowAppliedFilterWarning(item?.filterId !== 1);
    setForceRender(false);
  };

  const getPatientsCounters = () => {
    getPatientsCountersRequest().then(response => {
      if (response?.data) {
        setOldPatientsCounter(
          (response.data as { patientCount: number }).patientCount
        );
        setNewPatientsCounter(
          (response.data as { newPatientCount: number }).newPatientCount
        );
      }
    });
  };

  return {
    actionState,
    closeMessagePrompt,
    customFilterData,
    dataForExportAnswers,
    deleteOperation,
    displayEditFilter,
    displayFlashMsg,
    displayModal,
    exportModalToggle,
    importModalToggle,
    filteredPatientsForCsvExport,
    floatItems,
    forceRender,
    handlePatientSearch,
    history,
    isFilterSelected,
    isModalLoading,
    isNewPatient,
    isPatientDemographicData,
    loading,
    modalData,
    onCreatePatientFormModal,
    onDeletePatienListView,
    onEditPatientListView,
    onPatientFormModalClose,
    onShowPatientListView,
    onUpdatePatientFormModal,
    openResultsModal,
    operation,
    patients,
    reduxDispatcher,
    searchTerm,
    selectAdvancedFilter,
    selectedFilter,
    services,
    setDataForExportAnswers,
    setDeleteOperation,
    setDisplayEditFilter,
    setDisplayFlashMsg,
    setDisplayModal,
    setExportModalToggle,
    setFlashMessagePatientListView,
    setForceRender,
    setImportModalToggle,
    setIsModalLoading,
    setLoading,
    setModalData,
    setOpenResultsModal,
    setPatients,
    setShowAppliedFilterWarning,
    setShowFilterSettings,
    setShowPatient,
    showAppliedFilterWarning,
    showFilterSettings,
    showPatient,
    tableHandler,
    trackEventTrigger,
    user,
    activeTab,
    onClickTabItem,
    oldPatientsCounter,
    newPatientsCounter,
    doFetch,
    checkPatientsReadPermission,
    setFilteredPatientsForCsvExport,
    setIsFilterSelected,
    getPatientsCounters,
    setSearchTerm,
    setIsPatientDemographicData,
    setOperation,
    setDoFetch,
  };
}
