import { useEffect } from 'react';

const useScrollToElem = (elRef, scrollComponent, componentId) => {
  useEffect(() => {
    if (scrollComponent && elRef.current && elRef.current[componentId]) {
      elRef.current[componentId].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [scrollComponent, componentId]);
};

export default useScrollToElem;
