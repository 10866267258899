import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import I18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import { Prompt, Link } from 'react-router-dom';
import { useAbortController } from '@global/Hooks';

import {
  Box,
  Breadcrumb,
  Button,
  DnDLayoutProvider,
  FlashMessage,
  Flex,
  Modal,
  SingleSelectCareplanSearchable,
  TextInput,
} from '@ui-common-files/components';

import {
  PrimaryColor,
  SubmissionTypes,
  inputField,
  generateRandomId,
  ButtonType,
  ButtonVariant,
} from '@ui-common-files/utils';
import maxCharLimits from '@ui-common-files/utils/maxcharlimits.json';
import { FlashMessageTypes } from '@ui-common-files/types';

import {
  ValidateTemplate,
  getComplexScoreValidation,
  CaretaskDefaultValidationErrorTemplate,
} from '@utils/CareplanTemplateValidatorFactory';
import { getCareplanComponentTypes } from '@utils/Careplan';
import NavigationJSON from '@utils/navigation.json';
import { DropDownPhaseTypes } from '@utils/DropDownType';

import {
  ApiClient,
  useAnalytics,
  HideFlashMessage,
  ShowFlashMessage,
} from '@global/Services';

import { deepClone } from '@global/Objects';
import { EnumToArray } from '@global/Arrays';
import { hasThresholds } from '@global/Careplan';
import { LanguageItems } from '@type/Common';

import BreadcrumbPortal from 'src/Views/AppLayout/BreadcrumbPortal/BreadcrumbPortal';
import {
  getPrefilledTemplatePhaseType,
  renderIcds,
  renderValidationComponent,
  renderComponentValidation,
  fetchAssessments,
  descriptionValidator,
} from './CaretaskBuilder.utils';

import {
  addComponentHandler,
  checkRecurrenceError,
  componentHealthCategories,
  fillCareplanTemplateComponents,
  getCareplanComponents,
  getComponentsByHealthCategory,
  getDefaultRecurrenceData,
  getEndDate,
  getIcds,
  getPhaseType,
  getPrefilledRecurrenceData,
  getStartDate,
  getStartTime,
  getTemplateName,
  getTotalScoringSum,
  isMainQuestion,
  prepareSearchTerm,
  removeParentAndTheirChildren,
} from '../../Careplans/CareplanHelperUtility';

import {
  ComplexFormula,
  ComplexScoringType,
} from '../../Careplans/ComplexScoreSetting/ComplexScoring.type';

import {
  getOverallScore,
  getSectionScores,
  hasOverallScore,
  processOverallScoreToBeSentToBackend,
  processSectionScoreToBeSentToBackend,
} from '../../Careplans/ComplexScoreSetting/ComplexScoring.utils';

import {
  PromptContent,
  PromptFooter,
  PromptFooterForCareplan,
} from '../../common/ConfirmationPrompt';

import BuilderNavigator from '../../Careplans/BuilderElements';
import CareplanComponents from '../../Careplans/CareplanComponents';
import CareplanTemplateSettings from '../../Careplans/CareplanTemplateSettings';
import { equationFunction } from '../../Careplans/ComplexScoreSetting/ComplexScoreSetting.service';
import ComponentSettings from '../../Careplans/ComponentSettings';
import { scoringState } from '../../Careplans/TemplateSettingScoringComponent';
import { nonMedical } from '../../../../config';
import serviceConfig from '../../../../serviceConfig.json';

import '../../../css/createCareplanTemplate.css';
import '../../../css/role.css';

const languagesArray = EnumToArray(LanguageItems);

function CaretaskBuilder({ history }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const isEditMode: boolean = !!id;
  const { user } = useSelector(state => state.session);
  const [spinner, setSpinner] = useState(isEditMode);
  const { trackEventTrigger } = useAnalytics();
  const query = useSelector(state => state.templates);
  const { getAbortController } = useAbortController();
  const { signal } = getAbortController();
  const [careplanComponentTypes, setCareplanComponentTypes] = useState(
    getCareplanComponentTypes()
  );
  const [searchTerm, setSearchTerm] = useState({});
  const [templateDescription, setTemplateDescription] = useState('');
  const [fileReviewToggle, setFileReviewToggle] = useState(false);
  const [shareOverAllScore, setShareOverAllScore] = useState(false);
  const [shouldRenderComponent, renderCareplanComponent] = useState(false);
  const [componentCollection, setComponentCollection] = useState([]);
  const [shouldShowSettings, setSettings] = useState(false);
  const [shouldShowTemplateSettings, setTemplateSettings] = useState(
    !isEditMode
  );
  const [customRecurrenceToggle, setCustomRecurrenceToggle] = useState(false);
  const [searchTermComponents, setSearchTermComponents] = useState({});
  const [selectedComponent, updateSelectedComponent] = useState();
  const [completeCollection, setCompleteCollection] = useState([]);
  const [counter, setCounter] = useState(0);
  const [parentId, setParentId] = useState('');
  const [
    careplanTemplateDescriptionToggle,
    setCareplanTemplateDescriptionToggle,
  ] = useState(false);
  const [
    careplanTemplateOverallScoringToggle,
    setCareplanTemplateOverallScoringToggle,
  ] = useState(false);
  const [withinIntervals, setWithinIntervals] = useState('');
  const [careTaskNameToggle, setCareTaskNameToggleToggle] = useState(false);
  const [careTaskInternalNameToggle, setCareTaskInternalNameToggle] =
    useState(false);
  const [careTaskReviewAssessmentToggle, setCareTaskReviewAssessmentToggle] =
    useState(false);
  const [reportReviewToggle, setReportReviewToggle] = useState(false);
  const [allIcds, setAllIcds] = useState([]);
  const [icdReferenceToggle, setIcdReferenceToggle] = useState(false);
  const [isEvaluatingToggle, setIsEvaluatingToggle] = useState(false);
  const [isReportEnabled, setIsReportEnabled] = useState(false);
  const [occurrences, setOccurrences] = useState(1);
  const [isCareplanOrAssessment, setIsCareplanOrAssessment] = useState(null);
  const [phaseTypes, setPhaseTypes] = useState(DropDownPhaseTypes());
  const [selectedPhaseType, setPhaseType] = useState('');
  const [triggerSaveAndExit, setTriggerSaveAndExit] = useState(false);
  const [triggerCancel, setTriggerCancel] = useState(false);
  const [triggerSave, setTriggerSave] = useState(false);
  const isEditAllowedRef = useRef(true);
  const [validateRecurrence, setValidateRecurrence] = useState(false);
  const [updatedTemplateResponse, setUpdatedTemplateResponse] = useState({});
  const [templateNames, setTemplateNames] = useState([]);
  const [
    openPromptForParentComponentDeletion,
    setOpenPromptForParentComponentDeletion,
  ] = useState(false);
  const [totalScoringValue, setTotalScoringValue] = useState(0);
  const [scoringSchemeValue, setScoringSchemeValue] = useState(scoringState);
  const [isValidScoring, setIsValidScoring] = useState(true);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [showComponentSettingsPanelError, setShowComponentSettingsPanelError] =
    useState(false);
  const [showTemplateSettingsPanelError, setTemplateSettingsPanelError] =
    useState(false);
  const [selectedMedical, setSelectedMedical] = useState([]);
  const [scoringFormulas, setScoringFormulas] = useState<ComplexFormula[]>([]);
  const [hasThreshold, setHasThreshold] = useState(false);
  const [displayFlashMsg, setDisplayFlashMsg] = useState({
    display: false,
    message: '',
    type: FlashMessageTypes.Error,
  });
  const [validationErrorTemplate, hasValidationErrorTemplate] = useState(
    deepClone(CaretaskDefaultValidationErrorTemplate)
  );
  const [recurrenceErrors, setRecurrenceErrors] = useState({
    lifespan: false,
    recurrence: false,
    recurrenceRepeat: false,
    startDate: false,
    endDate: false,
    occurrenceCount: false,
  });
  const defaultRecurrenceData = getDefaultRecurrenceData(selectedPhaseType);
  const [recurrenceData, setRecurrenceData] = useState(defaultRecurrenceData);

  const [repeatTypeValue, setRepeatTypeValue] = useState(
    defaultRecurrenceData.repeatType
  );

  const [isNameDuplicated, setIsNameDuplicated] = useState({
    hasError: false,
    message: '',
  });

  const [isEditable, setIsEditable] = useReducer((_state, edit) => edit, {});
  const [showPromptForAdjustScoring, setShowPromptForAdjustScoring] = useState({
    checkIsEvaluationEnable: false,
    checkUserDeleteAddOrChangeScoreOfComponent: false,
  });
  const [showScorePrompt, setShowScorePrompt] = useState(false);
  const [templateAlias, setTemplateAlias] = useState('');
  const [openReportWarningModal, setOpenReportWarningModal] = useState(false);
  const [focusScoreSelection, setFocusScoreSelection] = useState(false);
  const [templateResponse, setTemplateResponse] = useState({});
  const [careplanTemplates, setCareplanTemplates] = useState([]);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [messageId, setMessageId] = useState(null);

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!triggerCancel && isEditAllowedRef.current) {
      setTriggerCancel(true);
      setNextLocation(nextLocation);
      setConfirmedNavigation(true);
      return false;
    }
    return true;
  };

  const confirmHandler = () => {
    HideFlashMessage(messageId);
    if (nextLocation && confirmedNavigation) {
      history.push(nextLocation.pathname);
    } else {
      history.push(`/${NavigationJSON.CAREPLAN}`);
    }
    setTriggerCancel(false);
  };

  const caretaskBuilderSaveAndExit = () => {
    setValidateRecurrence(true);
    setTriggerSaveAndExit(true);
  };

  const caretaskBuilderSave = () => {
    setSettings(false);
    setValidateRecurrence(true);
    setTriggerSave(true);
  };

  const closeMessagePrompt = () => {
    setTriggerCancel(false);
    setConfirmedNavigation(false);
  };

  const caretaskBuilderCancel = () => {
    setTriggerCancel(true);
    setConfirmedNavigation(false);
  };

  const [
    hasSelectedMedicalValidationError,
    setHasSelectedMedicalValidationError,
  ] = useState(false);

  const [reportLanguage, setReportLanguage] = useState({
    value: user.languagePreference,
    label: languagesArray.filter(
      lng => lng.value === user.languagePreference
    )[0].key,
  });
  const [reviewAssessments, setReviewAssessments] = useState([]);
  const [reportReviewAssessment, setReportReviewAssessment] = useState(null);
  const [isCaretaskNameEditable, setIsCaretaskNameEditable] = useState(false);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);

  useEffect(() => {
    if (!isNameFocused && !isDescriptionFocused) {
      setIsCaretaskNameEditable(false);
    }
  }, [isNameFocused, isDescriptionFocused]);

  useEffect(() => {
    if (!reportReviewToggle) {
      setReportReviewAssessment(null);
    }
  }, [reportReviewToggle]);

  const fetchCaretaskData = useCallback(async () => {
    await ApiClient.GET({
      url: serviceConfig.brokerService.editCareplanTemplate.uri + id,
      payload: { userLanguage: I18n.language },
      errorCallback: error => {
        if (
          error.response.status === 404 ||
          error.response.status === 500 ||
          error.response.status === 403
        ) {
          isEditAllowedRef.current = false;
          history.push(`/${NavigationJSON.CAREPLAN}`, {
            redirectToCaretaskList: true,
          });
        }
      },
    })
      .then(async response => {
        if (response) {
          const {
            data,
            data: {
              template,
              templateTriggersOtherTemplates,
              userDoesNotHaveStandardCaretaskRights,
            },
          } = response;
          setTemplateResponse(data);
          if (templateTriggersOtherTemplates)
            setDisplayFlashMsg({
              display: true,
              message: 'warningMessage.templateTriggersOtherTemplates',
              type: FlashMessageTypes.Warning,
              hideCloseButton: true,
            });
          if (userDoesNotHaveStandardCaretaskRights)
            setMessageId(
              ShowFlashMessage({
                type: FlashMessageTypes.Warning,
                messageKey: 'warningMessage.templateHasStandardComponents',
                autoHide: false,
                hideCloseButton: false,
              })
            );

          if (template.description) {
            setCareplanTemplateDescriptionToggle(true);
            setTemplateDescription(template.description || '');
          }
          if (data.reviewAssessments) {
            setReviewAssessments(data.reviewAssessments);
          }
          setReportReviewToggle(template.isReportReview);
          if (template.reviewAssessmentId) {
            setReportReviewAssessment(
              data.reviewAssessments.find(
                item => item.id === template.reviewAssessmentId
              )
            );
          } else {
            setReportReviewAssessment(null);
          }
          if (template.hasCustomOverAllFormula || template.hasSubScoreFormula) {
            const newScoringFormula = [];
            if (template.hasCustomOverAllFormula) {
              newScoringFormula.push({
                id: template.overallFormula.id,
                equation: template.overallFormula.equation,
                scoringTypeId: ComplexScoringType.OverallScore,
                equationHasError: false,
                equationErrorMessage: '',
              });
            }

            if (template.hasSubScoreFormula) {
              template.subScoreFormulaList.forEach(formula => {
                newScoringFormula.push({
                  id: formula.id,
                  equation: formula.equation,
                  description: formula.description,
                  scoringTypeId: ComplexScoringType.SectionScore,
                  equationHasError: false,
                  equationErrorMessage: '',
                  descriptionHasError: false,
                  descriptionErrorMessage: '',
                });
              });
            }

            setScoringFormulas(newScoringFormula);
            setCareplanTemplateOverallScoringToggle(true);
          }
          setShareOverAllScore(template.shareOverAllScore);

          if (template.reviewFiles) {
            setFileReviewToggle(true);
          } else if (!template.reviewFiles) {
            setFileReviewToggle(false);
          }
          if (template.alias) {
            setCareTaskInternalNameToggle(true);
            setTemplateAlias(template.alias || '');
          }
          setCareTaskReviewAssessmentToggle(template.isReviewAssessment);
          if (template.isEvaluating) {
            setIsEvaluatingToggle(true);
            setScoringSchemeValue(template.scoringScheme);
            showPromptForAdjustScoring.checkIsEvaluationEnable = true;
            setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
          }
          if (template.isAssessment) {
            setIsCareplanOrAssessment(true);
          } else if (!template.isAssessment) {
            setIsCareplanOrAssessment(false);
          }
          if (template.icds.length > 0) {
            setIcdReferenceToggle(true);
            setAllIcds(template.icds);
          }

          setCustomRecurrenceToggle(template.isCustomRecurrenceEnabled);

          if (data.phaseTypes) {
            setPhaseTypes(data.phaseTypes);
            const phaseTypesTemp = getPrefilledTemplatePhaseType(
              data.phaseTypes,
              template.phaseTypeId
            );
            setPhaseType(phaseTypesTemp[0]);
          }

          setRecurrenceData(getPrefilledRecurrenceData(recurrenceData, data));

          setSearchTerm(prepareSearchTerm(data));

          if (template.isReportEnabled) {
            const reportLan = languagesArray.filter(
              lang => lang.value === template.reportLanguage
            )[0].key;
            setIsReportEnabled(true);
            setOccurrences(template.reportOccurenceNumber);
            setReportLanguage({
              value: template.reportLanguage,
              label: reportLan,
            });
          } else {
            setReportLanguage({
              value: user.languagePreference,
              label: languagesArray.filter(
                lng => lng.value === user.languagePreference
              )[0].key,
            });
          }

          const templateData = fillCareplanTemplateComponents(template);
          setComponentCollection(templateData);
          setCounter(templateData.length);
          renderCareplanComponent(true);
        }
      })
      .catch(error => {
        history.push(`/${NavigationJSON.CAREPLAN}`, {
          NoPermissionflashMessage: {
            display: true,
            type: error.response.data.type,
            content: t(error.response.data.content),
          },
        });
      })
      .finally(() => {
        setSpinner(false);
      });
  }, [id]);

  useEffect(() => {
    if (id) fetchCaretaskData();
  }, [id]);

  const fetchPrefilledRoles = () => {
    ApiClient.GET({
      url: serviceConfig.brokerService.getPrefilledRoles.uri,
      payload: { isCareTask: true },
    }).then(result => {
      if (result) {
        const medicalRoles = result.data.rolesList.rows.filter(
          role => role.name !== 'Super Admin' && role.name !== 'Site Admin'
        );
        setSelectedMedical(
          medicalRoles.map(({ id, name }) => ({
            id,
            name,
            isChecked: isEditMode
              ? templateResponse.template.roles &&
                !!templateResponse.template.roles.find(elem => elem === id)
              : false,
          }))
        );
      }
    });
  };

  useEffect(() => {
    ApiClient.GET({
      url: serviceConfig.brokerService.getAllCareplanComponentTypes.uri,
      payload: {
        userLanguage: I18n.language,
      },
    }).then(careplanComponentTypes => {
      if (careplanComponentTypes) {
        setCareplanComponentTypes(careplanComponentTypes.data);
      }
    });

    ApiClient.GET({
      url: serviceConfig.brokerService.getCareplanComponentCollectionOnTypes
        .uri,
    }).then(components => {
      if (components) {
        setCompleteCollection(components.data);
      }
    });
    ApiClient.GET({
      url: serviceConfig.careplanService.getAllTemplatesWithIdAndName.uri,
      timeout: 60000,
    }).then(careplanTemplates => {
      if (careplanTemplates) {
        const templateResponse =
          careplanTemplates?.data?.content?.templateResponse;
        if (!isEditMode) {
          fetchPrefilledRoles();
          setCareplanTemplates(templateResponse);
        }
        const filteredReviews = templateResponse?.filter(
          item => item.isReviewAssessment === true
        );
        const reviewCaretaskArray = filteredReviews?.map(({ label, id }) => ({
          name: label,
          id,
        }));
        setReviewAssessments(reviewCaretaskArray);
      }
    });
  }, []);

  useEffect(() => {
    if (isEditMode && Object.keys(templateResponse)?.length > 0) {
      fetchPrefilledRoles();
    }
  }, [templateResponse]);

  const getUserInstitutions = async () => {
    const response = await ApiClient.GET({
      url:
        serviceConfig.brokerService.getUserInfoWithSessionHistory.uri + user.id,
    });
    if (response) {
      const responseInstitutions = response.data.institutions;
      return responseInstitutions.map(institution => institution.id);
    }
  };

  const validateTemplateForm = () => {
    setShowComponentSettingsPanelError(false);
    setTemplateSettingsPanelError(false);
    let isValid = true;
    const temp = { ...validationErrorTemplate };
    const [
      hasComponentSettingsError,
      noComponentsError,
      componentSettingsError,
      twoOptionsRequiredError,
      emptyOptionError,
      evaluationLineErrorForSortable,
    ] = ValidateTemplate.hasComponentSettingsError(
      componentCollection,
      t('warningMessage.careplanComponentMediaValidUrl')
    );

    const logicJumpCollectionError =
      ValidateTemplate.checkLogicJumps(componentCollection);
    if (Object.keys(logicJumpCollectionError).length > 0) {
      setShowComponentSettingsPanelError(true);
    }
    isValid = !logicJumpCollectionError;
    temp.logicJumpCollectionError = logicJumpCollectionError || {};

    const itemsWithJumpExceedingLimitError =
      ValidateTemplate.checkJumpLimit(componentCollection);
    if (Object.keys(itemsWithJumpExceedingLimitError).length > 0) {
      setShowComponentSettingsPanelError(true);
    }
    temp.itemsWithJumpExceedingLimitError =
      itemsWithJumpExceedingLimitError || {};
    if (itemsWithJumpExceedingLimitError) isValid = false;

    const itemsWithJumpContradictionError =
      ValidateTemplate.checkJumpContradictions(componentCollection);
    if (Object.keys(itemsWithJumpContradictionError).length > 0) {
      setShowComponentSettingsPanelError(true);
    }
    temp.logicJumpContradictionError = itemsWithJumpContradictionError;
    if (itemsWithJumpContradictionError) isValid = false;

    const itemsWithDuplicateOptionsError =
      ValidateTemplate.checkComponentDuplicateOptions(componentCollection);
    if (itemsWithDuplicateOptionsError.length > 0) {
      isValid = false;
      temp.templateSettingsValidation.itemsWithDuplicateOptionsError =
        itemsWithDuplicateOptionsError;
      setShowComponentSettingsPanelError(true);
    }

    if (
      !ValidateTemplate.hasTemplateName(
        !isEditMode ? searchTerm : templateResponse.template
      )
    ) {
      if (!isEditMode) {
        searchTerm.name = '';
        searchTerm.label = '';
        setSearchTerm({ ...searchTerm });
      }
      isValid = false;
      temp.templateSettingsValidation.templateNameError = true;
    }
    if (!hasComponentSettingsError) {
      isValid = false;
      temp.templateSettingsValidation.noComponentsError = noComponentsError;
      temp.templateSettingsValidation.componentSettingsError =
        componentSettingsError;
      temp.templateSettingsValidation.twoOptionsRequiredError =
        twoOptionsRequiredError;
      temp.templateSettingsValidation.emptyOptionError = emptyOptionError;
      temp.templateSettingsValidation.evaluationLineErrorForSortable =
        evaluationLineErrorForSortable;
      setShowComponentSettingsPanelError(true);
    }

    if (
      isEvaluatingToggle &&
      (!isValidScoring ||
        JSON.stringify(scoringSchemeValue) === JSON.stringify(scoringState)) &&
      (nonMedical === false || nonMedical === 'false')
    ) {
      isValid = false;
      temp.templateSettingsValidation.scoringSchemeError = true;
    } else {
      temp.templateSettingsValidation.scoringSchemeError = false;
    }

    if (ValidateTemplate.isComponentCollectionEmpty(componentCollection)) {
      isValid = false;
      temp.templateSettingsValidation.isComponentCollectionEmpty = true;
      setShowComponentSettingsPanelError(true);
      setTemplateSettingsPanelError(true);
    }

    if (
      !ValidateTemplate.hasTemplateType(isCareplanOrAssessment, selectedMedical)
    ) {
      isValid = false;
      temp.templateSettingsValidation.noTemplateType = true;
      setHasSelectedMedicalValidationError(true);
      setTemplateSettings(true);
      setTemplateSettingsPanelError(true);
    }

    const hasError = checkRecurrenceError(recurrenceErrors);
    if (hasError) {
      isValid = false;
      setTemplateSettingsPanelError(true);
    }

    if (
      !getTemplateName(!isEditMode ? searchTerm : templateResponse.template)
    ) {
      setTemplateSettingsPanelError(true);
    }

    if (isReportEnabled && occurrences === 2 && !hasThreshold) {
      setOpenReportWarningModal(true);
      isValid = false;
    }

    const invalidDescription = componentCollection.some(
      component => !descriptionValidator(component.searchTerm.description)
    );
    if (invalidDescription) {
      setTemplateSettingsPanelError(true);
      isValid = false;
    }

    const { isValidTemp, validationErrorTemplateTemp } =
      getComplexScoreValidation(
        temp,
        setTemplateSettingsPanelError,
        componentCollection,
        isEvaluatingToggle,
        setScoringFormulas,
        scoringFormulas,
        isValid
      );

    hasValidationErrorTemplate({ ...validationErrorTemplateTemp });
    return isValidTemp;
  };

  const processDataToBeSentToBackend = async () => {
    const icds = icdReferenceToggle ? getIcds(allIcds) : [];
    const userInstitutions = await getUserInstitutions();
    const recurrenceDataAfterValidation = customRecurrenceToggle
      ? recurrenceData
      : defaultRecurrenceData;
    const planningPhaseTypeIndex = 1;
    return {
      careplanTemplateName: getTemplateName(
        isEditMode ? templateResponse.template : searchTerm
      ),
      selectSpecificOrg: userInstitutions,
      componentURL: isEditMode
        ? '/careplanTemplates/editCareplanTemplate'
        : '/careplanTemplates/createCareplanTemplate',
      careplanComponentURL: isEditMode
        ? '/careplanComponents/editCareplanComponent'
        : '/careplanComponents/createCareplanComponent',
      careplanComponentSelected: getCareplanComponents(
        isEvaluatingToggle
          ? componentCollection
          : componentCollection.filter(item =>
              isMainQuestion(item.healthCategory)
            )
      ),
      choosePhaseType: customRecurrenceToggle
        ? getPhaseType(selectedPhaseType)
        : planningPhaseTypeIndex,
      startsAt: getStartDate(recurrenceDataAfterValidation),
      careplanStartTime: getStartTime(recurrenceDataAfterValidation),
      isAssessment: isCareplanOrAssessment,
      isEvaluating: isEvaluatingToggle,
      isReportReview: reportReviewToggle,
      isReviewAssessment: careTaskReviewAssessmentToggle,
      selectedICDs: icds,
      countICDs: icdReferenceToggle ? icds.length : 0,
      startDate: getStartDate(recurrenceDataAfterValidation),
      endsAt: getEndDate(recurrenceDataAfterValidation),
      occurrenceCountCheck: isEditMode
        ? recurrenceDataAfterValidation.occurrenceCountCheck
        : true,
      reviewAssessmentId:
        isReportEnabled && reportReviewAssessment && reportReviewToggle
          ? reportReviewAssessment.id
          : null,
      occurrenceCount: recurrenceDataAfterValidation.occurrenceCount,
      repeatInterval: recurrenceDataAfterValidation.repeatInterval,
      repeatType: recurrenceDataAfterValidation.repeatType,
      customTimeChecked: recurrenceDataAfterValidation.customTimeChecked,
      customTime: recurrenceDataAfterValidation.customTime,
      hasCustomOverAllFormula: hasOverallScore(scoringFormulas),
      hasSubScoreFormula: getSectionScores(scoringFormulas).length > 0,
      weeklyRecurrence: recurrenceDataAfterValidation.weeklyRecurrence,
      description: careplanTemplateDescriptionToggle ? templateDescription : '',
      subScoringFormulaList: processSectionScoreToBeSentToBackend(
        careplanTemplateOverallScoringToggle,
        scoringFormulas
      ),
      overAllFormula: processOverallScoreToBeSentToBackend(
        careplanTemplateOverallScoringToggle,
        scoringFormulas
      ),
      ...(isEvaluatingToggle && {
        scoringScheme: scoringSchemeValue,
      }),
      lifespan: recurrenceDataAfterValidation.lifespan,
      lifespanUnit: recurrenceDataAfterValidation.lifespanUnit,
      alias: careTaskInternalNameToggle ? templateAlias : '',
      reviewFiles: fileReviewToggle,
      shareOverAllScore: isEvaluatingToggle && shareOverAllScore,
      selectedRoles: selectedMedical
        .filter(role => role.isChecked)
        .map(obj => obj.id),
      isReportEnabled,
      isCustomRecurrenceEnabled: customRecurrenceToggle,
      reportOccurenceNumber: isReportEnabled ? occurrences : 0,
      reportLanguage: isReportEnabled ? reportLanguage.value : '',
      totalScore: totalScoringValue,
    };
  };

  const resetTriggerSaveAndValidateState = () => {
    setTriggerSaveAndExit(false);
    setTriggerSave(false);
    setValidateRecurrence(false);
  };

  const validateTemplateResponse = (response, operation) => {
    if (response !== null && operation === 'saveAndClose') {
      return history.push(`/${NavigationJSON.CAREPLAN}`, {
        redirectToCaretaskList: !careTaskReviewAssessmentToggle,
        isAssessment: careTaskReviewAssessmentToggle,
        templateFlashMsg: {
          type: response.data.message.type,
          content: t(response.data.message.content),
        },
      });
    }
    resetTriggerSaveAndValidateState();
    setDisplayFlashMsg({
      display: true,
      message: response.data.message.content,
      type: response.data.message.type,
    });
  };

  useEffect(() => {
    const saveTemplateData = async () => {
      setSettings(false);
      setSpinner(true);
      trackEventTrigger({
        category: 'caretask',
        action: isEditMode ? 'Click-Update-Button' : 'Click-Save-Button',
      });
      if (validateTemplateForm()) {
        if (
          showPromptForAdjustScoring.checkIsEvaluationEnable === true &&
          showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent ===
            true &&
          (nonMedical === false || nonMedical === 'false')
        ) {
          setTemplateSettings(true);
          setShowScorePrompt(true);
          resetTriggerSaveAndValidateState();
        } else {
          const processedData = await processDataToBeSentToBackend();
          try {
            const response = await ApiClient.POST({
              url: isEditMode
                ? serviceConfig.brokerService.updateCareplanTemplate.uri + id
                : serviceConfig.brokerService.createCareplanTemplate.uri,
              payload: {
                userLanguage: I18n.language,
                ...processedData,
              },
            });
            if (response) {
              setUpdatedTemplateResponse(response);
              validateTemplateResponse(
                response,
                triggerSaveAndExit ? 'saveAndClose' : 'update'
              );
              resetTriggerSaveAndValidateState();
              trackEventTrigger({
                category: 'caretask',
                action: isEditMode ? 'Update-Successful' : 'Save-Successful',
              });
            } else {
              resetTriggerSaveAndValidateState();
            }
          } catch (error) {
            if (error.response && error.response.data.isNameDuplicate) {
              setIsNameDuplicated({
                hasError: true,
                message: t(error.response.data.content),
              });
            } else {
              setDisplayFlashMsg({
                display: true,
                message: error.response.data.content,
                type: error.response.data.type,
              });
              trackEventTrigger({
                category: 'caretask',
                action: isEditMode ? 'Update-Failed' : 'Save-Failed',
              });
            }
            resetTriggerSaveAndValidateState();
          }
        }
      } else {
        resetTriggerSaveAndValidateState();
      }
      setSpinner(false);
    };

    if (triggerSave || triggerSaveAndExit) {
      saveTemplateData();
    }
  }, [triggerSave, triggerSaveAndExit]);

  useEffect(() => {
    triggerCancel &&
      trackEventTrigger({
        category: 'caretask',
        action: 'Click-Cancel-Button',
      });
  }, [triggerCancel]);

  useEffect(() => {
    const temp = { ...validationErrorTemplate };

    if (!careplanTemplateOverallScoringToggle) {
      setScoringFormulas([]);
      temp.templateSettingsValidation.additionalComponentsWithComplexScoreError =
        false;
      hasValidationErrorTemplate({ ...temp });
    }
  }, [careplanTemplateOverallScoringToggle]);

  useEffect(() => {
    if (componentCollection.length > 0) {
      const overallScore = getOverallScore(scoringFormulas);
      const totalWeightOfComponent = overallScore
        ? equationFunction(
            overallScore.equation,
            componentCollection,
            setWithinIntervals
          )
        : getTotalScoringSum(componentCollection);
      if (totalWeightOfComponent === 0) {
        setScoringSchemeValue(scoringState);
        setShareOverAllScore(false);
      }
      setTotalScoringValue(totalWeightOfComponent);
    } else {
      setTotalScoringValue(0);
      setShareOverAllScore(false);
      setScoringSchemeValue(scoringState);
    }
  }, [
    componentCollection,
    shouldShowSettings,
    careplanTemplateOverallScoringToggle,
    scoringFormulas,
  ]);

  const filterOptions = inputValue => {
    return careplanTemplates.filter(item => {
      const shouldLabelAndValueMatch = item.label
        .toLowerCase()
        .includes(inputValue.toLowerCase());

      return shouldLabelAndValueMatch;
    });
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterOptions(inputValue));
      }, 1000);
    });

  const clearSearchTermComponents = (shouldRunFunction: boolean = true) => {
    if (!shouldRunFunction) return;
    setSearchTermComponents({});
  };

  const handleSearchTerm = async state => {
    let response;
    if (state.id) {
      setSpinner(true);
      response = await ApiClient.GET({
        url: serviceConfig.brokerService.editCareplanTemplate.uri + state.id,
        payload: { userLanguage: I18n.language },
      });
      if (response) {
        const careplanTemplate = response.data.template;
        const careplanTemplatePhaseTypes = response.data.phaseTypes;
        if (careplanTemplatePhaseTypes) {
          const phaseTypesTemp = careplanTemplatePhaseTypes.filter(
            item => item.id === careplanTemplate.phaseTypeId
          );
          setPhaseType(phaseTypesTemp[0]);
        }
        state.careplanComponentsWithThresholds =
          careplanTemplate.careplanComponentsWithThresholds;
        state.careplanCareplanComponentsWithLogicJumps =
          careplanTemplate.careplanCareplanComponentsWithLogicJumps;
        state.organizations = careplanTemplate.organizations;
        state.icds = careplanTemplate.icds;
        state.isAssessment = careplanTemplate.isAssessment;
        state.description = careplanTemplate.description;
        state.hasCustomOverAllFormula =
          careplanTemplate.hasCustomOverAllFormula;
        state.hasSubScoreFormula = careplanTemplate.hasSubScoreFormula;
        state.overallFormula = careplanTemplate.overallFormula;
        state.subScoreFormulaList = careplanTemplate.subScoreFormulaList;
        state.alias = careplanTemplate.alias;
        state.isEvaluating = careplanTemplate.isEvaluating;
        state.scoringScheme = careplanTemplate.scoringScheme;
        state.careplanComponents = careplanTemplate.careplanComponents;
        state.isReportEnabled = careplanTemplate.isReportEnabled;
        state.reportOccurenceNumber = careplanTemplate.reportOccurenceNumber;
        state.reportLanguage = careplanTemplate.reportLanguage;
        state.shareOverAllScore = careplanTemplate.shareOverAllScore;
        const data = fillCareplanTemplateComponents(careplanTemplate);
        if (data) {
          setSpinner(false);
        }
        setComponentCollection([...data]);
        setCounter(data.length);
        renderCareplanComponent(true);
        setTemplateSettings(false);
        clearSearchTermComponents();
      }
    } else if (!state.__isNew__) {
      setComponentCollection([]);
      setCounter(0);
      renderCareplanComponent(false);
      clearSearchTermComponents();
    }
    if (!state.__isNew__) {
      setRecurrenceData(
        getPrefilledRecurrenceData(recurrenceData, response.data)
      );
      setIsReportEnabled(state.isReportEnabled);
      setOccurrences(state.reportOccurenceNumber);

      if (state.hasCustomOverAllFormula || state.hasSubScoreFormula) {
        const newScoringFormula = [];
        if (state.hasCustomOverAllFormula) {
          newScoringFormula.push({
            id: generateRandomId(),
            equation: state.overallFormula.equation,
            scoringTypeId: ComplexScoringType.OverallScore,
            equationHasError: false,
            equationErrorMessage: '',
          });
        }
        if (state.hasSubScoreFormula) {
          state.subScoreFormulaList.forEach(formula => {
            newScoringFormula.push({
              id: generateRandomId(),
              equation: formula.equation,
              description: formula.description,
              scoringTypeId: ComplexScoringType.SectionScore,
              equationHasError: false,
              equationErrorMessage: '',
              descriptionHasError: false,
              descriptionErrorMessage: '',
            });
          });
        }

        setScoringFormulas(newScoringFormula);
        setCareplanTemplateOverallScoringToggle(true);
      }
      setShareOverAllScore(state.shareOverAllScore);

      if (state.isReportEnabled) {
        const reportLanguage = languagesArray.filter(
          lang => lang.value === state.reportLanguage
        )[0].key;
        setReportLanguage({
          value: state.reportLanguage,
          label: reportLanguage,
        });
      }
      setCareTaskInternalNameToggle(!!state.alias);
      setCareTaskReviewAssessmentToggle(!!state.isReviewAssessment);
      setReportReviewToggle(!!state.reviewAssessmentId);
      setCareplanTemplateDescriptionToggle(!!state.description);
      setTemplateAlias(state.alias || '');
      setTemplateDescription(state.description || '');
      if (state.icds && state.icds.length > 0) {
        setIcdReferenceToggle(true);
        setAllIcds(state.icds);
      } else {
        setIcdReferenceToggle(false);
      }

      setIsEvaluatingToggle(!!state.isEvaluating);
    }

    setSearchTerm({ ...state });
    if (state.isAssessment && state.id > 0) {
      setIsCareplanOrAssessment(true);
    } else if (!state.isAssessment && state.id > 0) {
      setIsCareplanOrAssessment(false);
    }
    if (state.isEvaluating) {
      showPromptForAdjustScoring.checkIsEvaluationEnable = true;
      setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
    }
    hasValidationErrorTemplate(CaretaskDefaultValidationErrorTemplate);
    setIsEditable({});
    if (state.scoringScheme) {
      setScoringSchemeValue(state.scoringScheme);
    }
    const additionalComponents =
      state.careplanComponents &&
      state.careplanComponents.filter(
        component => !isMainQuestion(component.healthCategory)
      );
    if (additionalComponents && additionalComponents.length > 0)
      setDisplayFlashMsg({
        display: true,
        message: 'warningMessage.refill_caretask_contain_healthindicator',
        type: FlashMessageTypes.Info,
      });
  };

  useEffect(() => {
    let thresholds = [];
    thresholds = getCareplanComponents(componentCollection).filter(
      comp => hasThresholds(comp) && comp.shouldThresholdToggle
    );
    setHasThreshold(thresholds.length !== 0);
  }, [componentCollection]);

  const closeWarningModal = () => {
    setOpenReportWarningModal(false);
  };

  const proceedWithoutGeneratingReport = () => {
    setIsReportEnabled(false);
    setOpenReportWarningModal(false);
    setTriggerSaveAndExit(true);
    setValidateRecurrence(true);
    setTriggerSave(true);
  };

  const showReportWarningModal = () => {
    return (
      <Modal
        isConfirmationDialog
        title={t('warningMessage.reportCantBeGeneratedTitle')}
        contentComponent={
          <PromptContent
            message={t('warningMessage.reportCantBeGeneratedContent')}
          />
        }
        footerComponent={
          <PromptFooter
            close={closeWarningModal}
            confirmHandler={proceedWithoutGeneratingReport}
            submissionType={SubmissionTypes.PROCEED}
            btnLabelLeft={t('common_buttons.changeSettings')}
          />
        }
        openModal={openReportWarningModal}
        onClose={closeWarningModal}
        hideFlashMessage={HideFlashMessage}
      />
    );
  };

  const handleEditTemplateClick = () => {
    setSettings(false);
    clearSearchTermComponents();
  };

  const closeMessagePromptForCancelXButton = () => {
    setTriggerCancel(false);
  };

  const closeMessagePromptForCancel = e => {
    trackEventTrigger({
      category: 'caretask',
      action: 'Cancel-Confirm-Cancel-Button',
    });
    closeMessagePromptForCancelXButton();
  };

  const handleFocusOnElements = (e: any, isFocused: boolean) => {
    const componentName: string = e.target.name;
    const elementsFocusSetter = () => {
      if (componentName === 'componentName') {
        setIsNameFocused(isFocused);
      } else {
        setIsDescriptionFocused(isFocused);
      }
    };

    if (!isFocused) {
      setTimeout(elementsFocusSetter, 20);
    } else {
      elementsFocusSetter();
    }
  };

  const onChangeTemplateNameText = (
    event,
    validationErrorTemplate,
    hasValidationErrorTemplate
  ) => {
    const temp = { ...validationErrorTemplate };
    if (event.target.value?.length <= 255)
      templateResponse.template.name = event?.target?.value;
    if (event?.target?.value?.trim()?.length > 0) {
      temp.templateSettingsValidation.templateNameError = false;
    } else {
      temp.templateSettingsValidation.templateNameError = true;
    }
    hasValidationErrorTemplate({ ...temp });
    setTemplateResponse({ ...templateResponse });
    setIsNameDuplicated({ hasError: false, message: '' });
  };

  const renderCareplanTemplateHeader = () => {
    const mySearchTerm =
      Object.keys(searchTerm).length === 0 ? null : searchTerm;

    return (
      <div>
        {!isEditMode && (
          <SingleSelectCareplanSearchable
            value={mySearchTerm}
            defaultOptions={careplanTemplates}
            loadOptions={promiseOptions}
            isDisabled={false}
            placeholder={t('newCareplan_view.placeholder_enterCareplanName')}
            noTagsMessage={t('common_labels.label_noTags')}
            handleOnChange={state => handleSearchTerm(state)}
            hasError={isNameDuplicated.hasError}
            validationMessage={isNameDuplicated.message}
            inputLimit={50}
            noOptionsMessage={t('common_labels.label_noOptions_Available')}
          />
        )}
        {isEditMode && isCaretaskNameEditable && (
          <TextInput
            id={templateResponse.template.id}
            value={templateResponse.template.name}
            variant={inputField.variant.CHAR_COUNT}
            maxLength={
              maxCharLimits.carePlan.careplanExternalTitlecMaxCharLimit
            }
            maxChars={maxCharLimits.carePlan.careplanExternalTitlecMaxCharLimit}
            name="componentName"
            hasError={
              isNameDuplicated.hasError ||
              validationErrorTemplate.templateSettingsValidation
                .templateNameError == true
            }
            validationMessage={
              isNameDuplicated.hasError
                ? isNameDuplicated.message
                : validationErrorTemplate.templateSettingsValidation
                    .templateNameError == true
                ? t('parsley.requiredField')
                : ''
            }
            placeholder={t('newCareplan_view.placeholder_enterCareplanName')}
            handleOnChange={() =>
              onChangeTemplateNameText(
                event,
                validationErrorTemplate,
                hasValidationErrorTemplate
              )
            }
            onBlur={(e: any) => handleFocusOnElements(e, false)}
            onFocus={(e: any) => handleFocusOnElements(e, true)}
          />
        )}

        {careplanTemplateDescriptionToggle && (
          <TextInput
            value={templateDescription || ''}
            id="careplanTemplateDescription"
            name="careplanTemplateDescription"
            disabled={false}
            placeholder={t('newTemplate_view.label_descriptionTemplate')}
            handleOnChange={event => setTemplateDescription(event.target.value)}
            onBlur={(e: any) => handleFocusOnElements(e, false)}
            onFocus={(e: any) => handleFocusOnElements(e, true)}
          />
        )}
        {icdReferenceToggle && (allIcds.length || searchTerm?.icds?.length) && (
          <div>
            <div className="labelIcdsParentContainer">
              {renderIcds(allIcds, searchTerm.icds)}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTitleLabel = () => {
    return (
      <div
        className="titleOnBlurOuterContainer"
        onClick={() => setIsCaretaskNameEditable(true)}
      >
        <label className="labelCareplanTemplateTitleFirst">
          {!isEditMode
            ? searchTerm.label
            : templateResponse.template && templateResponse.template.name}
        </label>
        {careplanTemplateDescriptionToggle && (
          <div className="careplanTemplateDescription">
            <label className="labelCareplanTemplateTitleSecond">
              {templateDescription}
            </label>
          </div>
        )}
        {icdReferenceToggle &&
          (allIcds.length || templateResponse.template.icds.length) && (
            <div>
              <div className="labelIcdsParentContainer">
                {renderIcds(allIcds, templateResponse.template.icds)}
              </div>
            </div>
          )}
      </div>
    );
  };

  const TitleBanner = useCallback(() => {
    return (
      <div
        className={`createCareplanTitleOuterContainer componentContainer ${
          searchTermComponents.id === undefined
            ? 'activeComponent'
            : 'inactiveComponent'
        }`}
        onClick={() => handleEditTemplateClick()}
        onBlur={() => clearSearchTermComponents(!isEditMode)}
      >
        <div>
          {isEditMode && searchTerm.label && !isCaretaskNameEditable
            ? renderTitleLabel()
            : renderCareplanTemplateHeader()}
          {!isEditMode && !searchTerm.label && !searchTerm.name && (
            <>
              {validationErrorTemplate.templateSettingsValidation
                .templateNameError &&
                renderValidationComponent('parsley.requiredField')}
            </>
          )}
          {validationErrorTemplate.templateSettingsValidation
            .scoringSchemeError &&
            renderValidationComponent('newCareplan_view.scoring_Error')}
          {validationErrorTemplate.templateSettingsValidation
            .healthIndicatorInactiveWithTotalScoreError &&
            renderValidationComponent(
              'newCareplan_view.complex_score.activate_health_indicator'
            )}
        </div>
      </div>
    );
  }, [
    searchTermComponents,
    searchTerm,
    careplanTemplates.length,
    isNameDuplicated,
  ]);

  function ContentBanner() {
    return (
      <>
        <BuilderNavigator
          shouldShowTemplateSettings={shouldShowTemplateSettings}
          careplanComponentTypes={careplanComponentTypes}
          setTemplateSettings={setTemplateSettings}
          addComponentHandler={(event, item) =>
            addComponentHandler(
              event,
              item,
              componentHealthCategories.MAIN_QUESTION,
              showPromptForAdjustScoring,
              setShowPromptForAdjustScoring,
              counter,
              setCounter,
              componentCollection,
              setComponentCollection,
              setSearchTermComponents,
              renderCareplanComponent
            )
          }
          setSettings={setSettings}
        />

        <div id="componentsContainer" className="components__container">
          {showComponentSettingsPanelError && (
            <>
              {renderComponentValidation(
                'newQuestion_view.componentLevelInfoMissing'
              )}
            </>
          )}
          {getComponentsByHealthCategory(
            componentCollection,
            componentHealthCategories.MAIN_QUESTION
          ).length === 0 &&
            validationErrorTemplate.templateSettingsValidation
              .isComponentCollectionEmpty && (
              <>
                {renderValidationComponent(
                  'newQuestion_view.noComponentCollection'
                )}
              </>
            )}
          {shouldRenderComponent && (
            <DnDLayoutProvider>
              <CareplanComponents
                componentCollection={componentCollection}
                setComponentCollection={setComponentCollection}
                setSettings={setSettings}
                searchTermComponents={searchTermComponents}
                setSearchTermComponents={setSearchTermComponents}
                updateSelectedComponent={updateSelectedComponent}
                completeCollection={completeCollection}
                shouldShowSettings={shouldShowSettings}
                setCounter={setCounter}
                setOpenPrompt={setOpenPrompt}
                setTemplateNames={setTemplateNames}
                isEditTemplate={isEditMode}
                isCreatTemplate={!isEditMode}
                validationErrorTemplate={validationErrorTemplate}
                hasValidationErrorTemplate={hasValidationErrorTemplate}
                setOpenPromptForParentComponentDeletion={
                  setOpenPromptForParentComponentDeletion
                }
                setParentId={setParentId}
                setTemplateNames={setTemplateNames}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                setOpenPrompt={setOpenPrompt}
                setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
                showPromptForAdjustScoring={showPromptForAdjustScoring}
                healthCategory={componentHealthCategories.MAIN_QUESTION}
                setDisplayFlashMsg={setDisplayFlashMsg}
              />
            </DnDLayoutProvider>
          )}
          <div>
            {shouldShowTemplateSettings && (
              <CareplanTemplateSettings
                setWithinIntervals={setWithinIntervals}
                withinIntervals={withinIntervals}
                scoringFormulas={scoringFormulas}
                setScoringFormulas={setScoringFormulas}
                setPhaseType={setPhaseType}
                selectedPhaseType={selectedPhaseType}
                customRecurrenceToggle={customRecurrenceToggle}
                setCustomRecurrenceToggle={setCustomRecurrenceToggle}
                searchTerm={searchTerm}
                careTaskNameToggle={careTaskNameToggle}
                careTaskReviewAssessmentToggle={careTaskReviewAssessmentToggle}
                setCareTaskReviewAssessmentToggle={
                  setCareTaskReviewAssessmentToggle
                }
                templateResponse={templateResponse}
                reviewAssessments={reviewAssessments}
                reportReviewToggle={reportReviewToggle}
                setReportReviewToggle={setReportReviewToggle}
                setCareTaskNameToggleToggle={setCareTaskNameToggleToggle}
                careTaskInternalNameToggle={careTaskInternalNameToggle}
                setCareTaskInternalNameToggle={setCareTaskInternalNameToggle}
                templateDescription={templateDescription}
                setTemplateDescription={setTemplateDescription}
                setTemplateAlias={setTemplateAlias}
                alias={templateAlias}
                careplanTemplateDescriptionToggle={
                  careplanTemplateDescriptionToggle
                }
                careplanTemplateOverallScoringToggle={
                  careplanTemplateOverallScoringToggle
                }
                setCareplanTemplateDescriptionToggle={
                  setCareplanTemplateDescriptionToggle
                }
                setCareplanTemplateOverallScoringToggle={
                  setCareplanTemplateOverallScoringToggle
                }
                fileReviewToggle={fileReviewToggle}
                shareOverAllScore={shareOverAllScore}
                setFileReviewToggle={setFileReviewToggle}
                setShareOverAllScore={setShareOverAllScore}
                allIcds={allIcds}
                setAllIcds={setAllIcds}
                icdReferenceToggle={icdReferenceToggle}
                setIcdReferenceToggle={setIcdReferenceToggle}
                phaseTypes={phaseTypes}
                selectedPhaseType={selectedPhaseType}
                setPhaseType={setPhaseType}
                recurrenceErrors={recurrenceErrors}
                setRecurrenceErrors={setRecurrenceErrors}
                recurrenceData={recurrenceData}
                setRecurrenceData={setRecurrenceData}
                repeatTypeValue={repeatTypeValue}
                setRepeatTypeValue={setRepeatTypeValue}
                validateRecurrence={validateRecurrence}
                triggerSave={triggerSaveAndExit || triggerSave}
                shouldShowTemplateSettings={shouldShowTemplateSettings}
                setIsEvaluatingToggle={setIsEvaluatingToggle}
                isEvaluatingToggle={isEvaluatingToggle}
                isCareplanOrAssessment={isCareplanOrAssessment}
                setIsCareplanOrAssessment={setIsCareplanOrAssessment}
                isValidScoringScheme={valid => {
                  setIsValidScoring(valid);
                }}
                scoringScheme={scoringSchemeValue}
                setScoringScheme={setScoringSchemeValue}
                totalScore={totalScoringValue}
                setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
                showPromptForAdjustScoring={showPromptForAdjustScoring}
                comesFromEditTemplate={isEditMode}
                focusScoreSelection={focusScoreSelection}
                onValidateScoring={
                  validationErrorTemplate.templateSettingsValidation
                    .scoringSchemeError
                }
                reportReviewAssessment={reportReviewAssessment}
                setReportReviewAssessment={setReportReviewAssessment}
                selectedMedical={selectedMedical}
                setSelectedMedical={setSelectedMedical}
                careplanComponentTypes={careplanComponentTypes}
                componentCollection={componentCollection}
                setComponentCollection={setComponentCollection}
                counter={counter}
                setCounter={setCounter}
                setSettings={setSettings}
                searchTermComponents={searchTermComponents}
                setSearchTermComponents={setSearchTermComponents}
                updateSelectedComponent={updateSelectedComponent}
                setOpenPrompt={setOpenPrompt}
                setOpenPromptForParentComponentDeletion={
                  setOpenPromptForParentComponentDeletion
                }
                setTemplateNames={setTemplateNames}
                isEditTemplate={isEditMode}
                setParentId={setParentId}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                setTemplateSettings={setTemplateSettings}
                validationErrorTemplate={validationErrorTemplate}
                hasValidationErrorTemplate={hasValidationErrorTemplate}
                isReportEnabled={isReportEnabled}
                setIsReportEnabled={setIsReportEnabled}
                occurrences={occurrences}
                setOccurrences={setOccurrences}
                reportLanguage={reportLanguage}
                setReportLanguage={setReportLanguage}
                hasSelectedMedicalValidationError={
                  hasSelectedMedicalValidationError
                }
                hasThreshold={hasThreshold}
              />
            )}
          </div>
        </div>
      </>
    );
  }

  const renderComponentSettings = () => {
    return (
      <ComponentSettings
        searchTermComponents={searchTermComponents}
        selectedComponent={selectedComponent}
        counter={counter}
        setSettings={setSettings}
        componentCollection={componentCollection}
        setComponentCollection={setComponentCollection}
        setSearchTermComponents={setSearchTermComponents}
        validationErrorTemplate={validationErrorTemplate}
        hasValidationErrorTemplate={hasValidationErrorTemplate}
        shouldShowSettings={shouldShowSettings}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
        showPromptForAdjustScoring={showPromptForAdjustScoring}
        isCareplanEvaluatingToggle={isEvaluatingToggle}
        setFileReviewToggle={setFileReviewToggle}
        isEditMode={isEditMode}
      />
    );
  };

  const hideFlashMessage = () => {
    setDisplayFlashMsg({
      display: false,
      message: '',
    });
  };

  const closeMessagePromptMainIfUserTryToDeleteParent = () => {
    setOpenPromptForParentComponentDeletion(false);
  };

  const closeMessagePromptIfScoreChange = () => {
    setShowScorePrompt(false);
    showPromptForAdjustScoring.checkIsEvaluationEnable = false;
    showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent =
      false;
    setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
  };

  const confirmHandlerIfUserTryToDeleteParent = () => {
    setOpenPromptForParentComponentDeletion(false);
    removeParentAndTheirChildren(
      parentId,
      componentCollection,
      setComponentCollection,
      setCounter,
      validationErrorTemplate,
      hasValidationErrorTemplate,
      setShowPromptForAdjustScoring,
      showPromptForAdjustScoring
    );
  };

  const closeMessagePromptIfUserTryToDeleteParent = () => {
    setOpenPromptForParentComponentDeletion(false);
  };

  const closeMessagePromptForChangeScore = event => {
    if (event) {
      if (event.target.innerHTML === t('common_buttons.cancel')) {
        showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent =
          false;
        setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
      }
    }
    setShowScorePrompt(false);
  };

  const confirmHandlerIfSaveCareplanPressed = () => {
    setFocusScoreSelection(true);
    clearSearchTermComponents();
    showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent =
      false;
    setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
  };

  const openPromptForAdjustScoring = () => {
    return (
      <Modal
        isConfirmationDialog
        title={t('warningMessage.msg_score_headline')}
        contentComponent={
          <PromptContent message={t('warningMessage.msg_score_check')} />
        }
        footerComponent={
          <PromptFooterForCareplan
            hasOneBtn
            close={closeMessagePromptForChangeScore}
            confirmHandler={confirmHandlerIfSaveCareplanPressed}
          />
        }
        openModal={showScorePrompt}
        onClose={closeMessagePromptIfScoreChange}
        hideFlashMessage={HideFlashMessage}
      />
    );
  };

  const openPromptIfUserTryToDeleteParentComponent = () => {
    return (
      <Modal
        isConfirmationDialog
        title={t('warningMessage.msg_component_parent_delete_headline')}
        contentComponent={
          <PromptContent
            message={t('warningMessage.msg_component_parent_delete')}
          />
        }
        footerComponent={
          <PromptFooter
            close={closeMessagePromptIfUserTryToDeleteParent}
            confirmHandler={confirmHandlerIfUserTryToDeleteParent}
          />
        }
        openModal={openPromptForParentComponentDeletion}
        onClose={closeMessagePromptMainIfUserTryToDeleteParent}
        hideFlashMessage={HideFlashMessage}
      />
    );
  };

  useEffect(() => {
    if (focusScoreSelection) {
      setTimeout(() => setFocusScoreSelection(false), 5000);
    }
  }, [focusScoreSelection]);

  useEffect(() => {
    if (shouldShowTemplateSettings) {
      renderCareplanComponent(false);
    } else {
      renderCareplanComponent(true);
    }
  }, [shouldShowTemplateSettings]);

  return (
    <>
      <BreadcrumbPortal>
        <Breadcrumb>
          <Link to={`/${NavigationJSON.CAREPLAN}`}>{t('nav.careManager')}</Link>
          <Flex alignItems="center">
            <span>
              {isEditMode
                ? t('breadcrumbs.updateTemplate')
                : t('breadcrumbs.createTemplate')}
            </span>
            <Box margin="0 0 0 20px">
              <Button
                label={t('common_buttons.saveAndClose')}
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.CONTAINED}
                onClick={() => caretaskBuilderSaveAndExit()}
                disabled={
                  templateResponse.templateTriggersOtherTemplates ||
                  templateResponse.userDoesNotHaveStandardCaretaskRights
                }
              />
            </Box>
            {isEditMode && (
              <Box margin="0 0 0 10px">
                <Button
                  label={t('common_buttons.save')}
                  type={ButtonType.SUBMIT}
                  variant={ButtonVariant.CONTAINED}
                  onClick={() => caretaskBuilderSave()}
                  disabled={
                    templateResponse.templateTriggersOtherTemplates ||
                    templateResponse.userDoesNotHaveStandardCaretaskRights
                  }
                />
              </Box>
            )}
            <Box margin="0 0 0 10px">
              <Button
                label={t('common_buttons.close')}
                type={ButtonType.RESET}
                variant={ButtonVariant.OUTLINED}
                onClick={() => caretaskBuilderCancel()}
              />
            </Box>
          </Flex>
        </Breadcrumb>
      </BreadcrumbPortal>
      <Prompt
        when={!triggerSaveAndExit && !triggerSave}
        message={handleBlockedNavigation}
      />
      {triggerCancel && (
        <Modal
          isConfirmationDialog
          title={t('warningMessage.leave_careplan_template')}
          contentComponent={
            <PromptContent
              message={ReactHtmlParser(t('warningMessage.templateLoss'))}
            />
          }
          footerComponent={
            <PromptFooter
              close={closeMessagePrompt}
              confirmHandler={confirmHandler}
              btnLabelRight={t('common_buttons.leave_without_saving')}
            />
          }
          openModal={triggerCancel}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}
      <div>
        {displayFlashMsg.display && (
          <FlashMessage
            message={t(displayFlashMsg.message)}
            type={displayFlashMsg.type || FlashMessageTypes.Success}
            onClick={hideFlashMessage}
            hideCloseButton={displayFlashMsg.hideCloseButton}
          />
        )}
      </div>
      <div className="mainContainer">
        {spinner && (
          <div className="roleOverlapSpinner">
            <Loader
              type="Oval"
              visible={spinner}
              color={PrimaryColor.MAIN_COLOR}
              height={60}
              width={60}
            />
          </div>
        )}
        <div className="createCareplanTitleParentContainer">
          {TitleBanner()}
          {showTemplateSettingsPanelError && (
            <>
              {renderComponentValidation(
                'newQuestion_view.templateLevelInfoMissing'
              )}
            </>
          )}
          {ContentBanner()}
        </div>
        <div>
          {shouldShowSettings && renderComponentSettings()}
          {openPromptForParentComponentDeletion &&
            openPromptIfUserTryToDeleteParentComponent()}
        </div>
        {showScorePrompt && openPromptForAdjustScoring()}
        {openReportWarningModal && showReportWarningModal()}
      </div>
    </>
  );
}

export default CaretaskBuilder;
