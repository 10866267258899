import React from 'react';
import Loader from 'react-loader-spinner';

import { Box } from '@ui-common-files/components';
import HealthData from '../HealthData';
import CaretasksData from '../CaretasksData';

import { PrimaryColor } from '@ui-common-files/utils';

import useOverviewDataModalContentService from './OverviewDataModalContent.service';

const OverviewDataModalContent = () => {
  const { isLoading } = useOverviewDataModalContentService();
  return (
    <Box>
      {isLoading ? (
        <Box className="roleOverlapSpinner">
          <Loader
            className="loader"
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            width={40}
            height={40}
          />
        </Box>
      ) : (
        <Box otherStyles={{ padding: '0 20px', height: '100%' }}>
          <HealthData />
          <CaretasksData />
        </Box>
      )}
    </Box>
  );
};

export default OverviewDataModalContent;
