import { AxiosResponse } from 'axios';
import serviceConfig from '../../../../../serviceConfig.json';

import { GetCaretaskAPI } from './CaretaskAnswering.type';

import { ApiClient } from '../../../../Global/Services';
import { Caretask } from '../../../../types/Caretask';

export const getCaretaskData: GetCaretaskAPI = async (
  caretaskId: number,
  occurrenceId: number
): Promise<AxiosResponse<Caretask>> =>
  await ApiClient.GET<Caretask, null>({
    url: `${serviceConfig.brokerService.careplanAnswerView.uri}${caretaskId}/${occurrenceId}`,
  });
