import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActionColors,
} from '@ui-common-files/utils';
import { Box, TextInput, Flex, Icon, Label } from '@ui-common-files/components';

export const BloodPressure = ({ component, data }) => {
    const { t } = useTranslation();

    const diastolic = data.answers?.[0].answer_blood_pressure_diastolic ?? 0;
    const systolic = data.answers?.[0].answer_blood_pressure_systolic ?? 0;
    const bloodPressureValue = data.answers?.[0].answer_blood_pressure_value ?? 0;

    const bloodPressureLabel = `${t(`bloodPressureOptions.labels.${bloodPressureValue}`)}: ${t(
        `bloodPressureOptions.systolicValues.${bloodPressureValue}`
    )} / ${t(`bloodPressureOptions.diastolicValues.${bloodPressureValue}`)}`;

    const isThreshold = component.thresholds?.length > 0;
    return (
        <Box>
            <Flex
                justifyContent="space-between"
                otherStyles={{ maxWidth: '550px' }}
            >
                {isThreshold && (
                    <Flex
                        otherStyles={{
                            alignItems: 'center',
                            minHeight: '36px',
                            marginRight: '10px',
                        }}
                    >
                        <Flex>
                            <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
                        </Flex>
                    </Flex>
                )}
                <Flex>      

                    <Label text={`${t('bloodPressureOptions.systolic')}:`} />
                    <Flex justifyContent="end">
                        <Box otherStyles={{ width: '85px', marginRight: '5px' }}>
                            <TextInput
                                value={systolic}
                                name="systolic"
                                placeholder="0"
                                handleOnChange={() => { }}
                            />
                        </Box>
                        <Label text={`${t('bloodPressureOptions.unit')}`} />
                    </Flex>
                </Flex>
                <Flex>
                    <Label text={`${t('bloodPressureOptions.diastolic')}:`} />
                    <Flex justifyContent="end">
                        <Box otherStyles={{ width: '85px', marginRight: '5px' }}>
                            <TextInput
                                value={diastolic}
                                name="diastolic"
                                placeholder="0"
                                handleOnChange={() => { }}
                            />
                        </Box>
                        <Label text={`${t('bloodPressureOptions.unit')}`} />
                    </Flex>
                </Flex>
                <Flex>
                    <Label isLongText={true} text={bloodPressureLabel} />
                </Flex>
            </Flex>
        </Box>
    );
};