import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../styles/main.scss';

function DropdownMenu(props) {
  const [displayMenu, setDisplayMenu] = useState(false);
  const ParentRef = useRef(null);

  const dropdownClasses = classNames({
    dropdown__items: true,
    'width--content': !props.shouldOptionsBeFullWidth,
    'width--full': props.shouldOptionsBeFullWidth,
    'right--null': props.isAttachAsset,
  });

  const showDropdownMenu = () => {
    setDisplayMenu(!displayMenu);
  };

  const hideDropdownMenu = e => {
    if (
      ParentRef.current &&
      displayMenu &&
      !ParentRef.current.contains(e.target)
    ) {
      setTimeout(() => {
        setDisplayMenu(false);
      }, 50);
    }
  };

  useEffect(() => {
    const option = { capture: true };
    if (displayMenu) {
      document.addEventListener('click', hideDropdownMenu, option);
    }
    return () => {
      document.removeEventListener('click', hideDropdownMenu, option);
    };
  }, [displayMenu]);

  return (
    <div className="dropdown">
      <div
        ref={ParentRef}
        className="dropdown__container"
        onClick={e => showDropdownMenu(e)}
      >
        {props.children}
      </div>
      {displayMenu ? (
        <ul className={`${dropdownClasses} ${props.variant} `}>
          {props.items}
        </ul>
      ) : null}
    </div>
  );
}

DropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  variant: PropTypes.string,
  children: PropTypes.any.isRequired,
  isAttachAsset: PropTypes.bool,
  shouldOptionsBeFullWidth: PropTypes.bool,
};
DropdownMenu.defaultProps = {
  variant: '',
  shouldOptionsBeFullWidth: false,
};

export default DropdownMenu;
