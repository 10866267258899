import I18n from 'i18next';
import RRule from 'rrule';
import {
  getNlpToken,
  getNlpTokenItalian,
  getNlpTokenThai,
  getNlpTokenPortuguese,
  getNlpTokenArabic,
  getNlpTokenFrench,
  NLP_GERMAN,
  NLP_ITALIAN,
  NLP_THAI,
  NLP_PORTUGUESE,
  NLP_ARABIC,
  NLP_FRENCH
} from './nlp';

const getRecurrenceText = recurrence => {
  switch (I18n.language.trim()) {
    case 'de-DE':
      return RRule.fromString(recurrence).toText(getNlpToken, NLP_GERMAN);
    case 'it-IT':
      return RRule.fromString(recurrence).toText(
        getNlpTokenItalian,
        NLP_ITALIAN
      );
    case 'th-TH':
      return RRule.fromString(recurrence).toText(getNlpTokenThai, NLP_THAI);
    case 'pt-BR':
      return RRule.fromString(recurrence).toText(getNlpTokenPortuguese, NLP_PORTUGUESE);
    case 'ar-AR':
      return RRule.fromString(recurrence).toText(getNlpTokenArabic, NLP_ARABIC);
    case 'fr-FR':
      return RRule.fromString(recurrence).toText(getNlpTokenFrench, NLP_FRENCH);
    default:
      return RRule.fromString(recurrence).toText();
  }
};

export default getRecurrenceText;
