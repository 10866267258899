import React from 'react';
import { useTranslation } from 'react-i18next';

import { Action, Box } from '@ui-common-files/components';

import useImprintService from './Imprint.service';

import config from '../../../../config';

import './Imprint.styles.scss';

const Imprint = () => {
  const { t } = useTranslation();
  const { getEIFULink } = useImprintService();

  return (
    <Box className="imprint_content">
      <Box>
        {t('imprint.imprintInformationPart1')}
        <Action href={getEIFULink} className="email">
          {t('imprint.eIFU')}
        </Action>
        {'. '}
        {t('imprint.imprintInformationPart2')}
        <Action href={`mailto:${config.serviceEmail}`} className="email">
          {config.serviceEmail}
        </Action>
        {'.'}
      </Box>
      <Box>{t('imprint.imprintInformationPart3')}</Box>
      <Box className="udi_content">{t('imprint.basicUDI')}</Box>
      <Box>{t('imprint.manufacturing_address')}</Box>
    </Box>
  );
};

export default Imprint;
