/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../styles/main.scss';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import SingleSelectPatientDropMenu from './SingleSelectPatientDropMenu';
import { ActionColors } from '../../utils/colors';
import { contactType, Item, Resource, SelectPatientSearchable } from './SingleSelectPatientDropMenu.types';
import FhirLoader from './FhirLoader';
import IsEmptyObject from '../../global/IsEmptyObject/IsEmptyObject';

const SingleSelectPatientSearchable = ({
  paginationController,
  setPaginationController,
  patientFormValues,
  setPatientFormValues,
  fetchData,
  placeholder,
  hasError,
  isDisabled,
} : SelectPatientSearchable) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [itemFiltered, setItemFiltered] = useState([]);
  const [itemsHighlighted, setItemsHighlighted] = useState([]);
  const [itemSelected, setItemSelected] = useState({});
  const [term, setTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [timer, setTimer] = useState(null);

 

  let termInput:{value: number | string} = {value:null}

  useEffect(() => {
    const fetchFhirPatients = async () : AxiosResponse => {
      try {
        const response = await fetchData(term);
        if (response) {
          setItemFiltered(response);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setItemFiltered([]);
      }
    };
    if (!isTyping && term.length > 1) {
      fetchFhirPatients();
    } else {
      setItemFiltered([]);
    }
  }, [term, isTyping]);

  const hideDropdownMenu = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setDisplayMenu(false);
    setItemFiltered([]);
    setItemsHighlighted([]);
    document.removeEventListener('click', hideDropdownMenu);
  };

  const startFetch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setTerm('');
    setItemSelected({})
    setDisplayMenu(false);

    const searchTerm = e.currentTarget.value;
    if (
      searchTerm &&
      searchTerm.length > 1
    ) {
      setIsLoading(true);
      setIsTyping(true);
      setTerm(searchTerm);
      if (timer !== null) {
        clearTimeout(timer);
        setTimer(null);
      }
      const currTimer = setTimeout(function () {
        setIsTyping(false);
      }, 1000);
      setTimer(currTimer);
    } else {
      setTerm('');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const highlightTerm = () => {
      if (itemFiltered.length === 0 && !isTyping) {
        setItemsHighlighted([]);
        setDisplayMenu(true);
      } else {
        const highlighted = itemFiltered.map((item: Resource) => {
          return {
            name: item.resource.name && item.resource.name.length ? item.resource.name[0].given.join(" ") : "",
            id: item.resource.id,
            medicalRecord: item.resource.identifier ? item.resource.identifier[0].value : "",
            ...item,
          };
        });
        if (highlighted.length > 0) {
          setItemsHighlighted(highlighted);
          setDisplayMenu(true);
          document.addEventListener('click', hideDropdownMenu);
        } else {
          setDisplayMenu(false);
        }
      }
    };
    highlightTerm();
  }, [itemFiltered]);

  const findItemPosition = (id: number, list: Item[]) => {
    let pos = -1;
    list.forEach((item, index) => {
      if (item.id === id) {
        pos = index;
        return;
      }
    });
    return pos;
  };

  const selectPatient = (id:number) => {
      const item: Resource = itemsHighlighted[findItemPosition(id, itemsHighlighted)];
      const resourceItem = item.resource
      const medicalRecord = item.resource.identifier[0].value
      let email = ""
      let mobile = ""
      setItemSelected(resourceItem);
      resourceItem.telecom.forEach((contact:contactType) => {
        if(contact.system === "email"){
          if(contact.value) email = contact.value
        }
        else if( contact.system === "phone"){
          if(contact.value) mobile = contact.value
        }
      });
      setPatientFormValues({
        ...patientFormValues,
        firstName: resourceItem.name && resourceItem.name.length ? resourceItem.name[0].given.join(" ") : "",
        lastName:  resourceItem.name && resourceItem.name.length ? resourceItem.name[0].family : "",
        birthDate: new Date(resourceItem.birthDate),
        gender: {value: resourceItem.gender, label: resourceItem.gender},
        email: email.toString(),
        mobile: mobile.toString(),
        address: resourceItem.address && resourceItem.address.length && resourceItem.address[0].line && resourceItem.address[0].line.length ? resourceItem.address[0].line.join(" ") : "",
        city: resourceItem.address && resourceItem.address.length && resourceItem.address[0] && resourceItem.address[0].city ? resourceItem.address[0].city : "",
        postalCode: resourceItem.address && resourceItem.address.length && resourceItem.address[0] && resourceItem.address[0].postalCode ? resourceItem.address[0].postalCode : "",
        country: resourceItem.address && resourceItem.address.length && resourceItem.address[0] && resourceItem.address[0].state ? resourceItem.address[0].state : "",
				medicalRecord: resourceItem.identifier && resourceItem.identifier.length ? resourceItem.identifier[0].value : ""
      })
      setTerm(id);
      termInput.value = medicalRecord;
      return true;
  };

  const inputFhirWrapperClasses = classNames({
    'fhir-select__input__wrapper': true,
    disabled: isDisabled,
    has_error: hasError && IsEmptyObject(itemSelected),
  });
  const input = () => (
    <div className={inputFhirWrapperClasses}>
        <i className="searchTop">
          <Icon name= "searchIcon" size={16}/>
        </i>
        <input
          type="text"
          autoComplete="on"
          className="fhir-select__input"
          onKeyUp={startFetch}
          ref={elem => (termInput = elem)}
          placeholder={placeholder}
          disabled={isDisabled}
        />
     
    
      {isLoading && (
        <FhirLoader />
      )}
      {IsEmptyObject(itemSelected) &&
      <span className="fhir-select__input__counter">
        {`${itemsHighlighted && itemsHighlighted[0] && itemsHighlighted[0].empty ? 0 : itemsHighlighted.length}`}
      </span>}
      {hasError && (
        <Icon
          name="error"
          fill={ActionColors.ERROR}
          className="input__text__icon right-icon"
        />
      )}
    </div>
  );
  return (
    <>
      <div className="fhir-select">
        {input()}
        {displayMenu &&
        term &&
        itemsHighlighted &&
        IsEmptyObject(itemSelected) ? (
            <SingleSelectPatientDropMenu
              items={itemsHighlighted}
              selectOnClick={(id: number) => {
                if (selectPatient(id)) setDisplayMenu(false);
              }}
              paginationController={paginationController}
              setPaginationController={setPaginationController}
              setItemsHighlighted={setItemsHighlighted}
            />
          ) : null}
      </div>
    </>
  );
};

export default SingleSelectPatientSearchable;
