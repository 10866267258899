import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlashMessagesReducer, FlashMessageItem } from './FlashMessage.type';

const initialState: FlashMessagesReducer = {
  messages: [],
  isModalOpen: false,
};

const flashMessagesSlice = createSlice({
  name: 'flashMessages',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<FlashMessageItem>) => {
      return {
        ...state,
        messages: [{ ...action.payload }, ...state.messages],
      };
    },
    removeMessage: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        messages: state.messages.filter(
          (message: FlashMessageItem) => message.id !== action.payload
        ),
      };
    },
    updateModalStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isModalOpen: action.payload,
      };
    },
  },
});

export const selectMessages = state => state.flashMessages.messages;
export const selectModalStatus = state => state.flashMessages.isModalOpen;

export const { addMessage, removeMessage, updateModalStatus } =
  flashMessagesSlice.actions;

export default flashMessagesSlice.reducer;
