import React, { useState, useEffect, useMemo } from 'react';
import I18n from 'i18next';
import axios from 'axios';

import { MaxCharLimits } from '@ui-common-files/utils';

import ModalSection from '../common/Layout/Modal/ModalSection';
import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import FlashMessage from '../../../caro-ui-commonfiles/components/FlashMessage/FlashMessage';
import Input from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import inputField from '../../../caro-ui-commonfiles/utils/inputField';
import { Greys, PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import '../../css/aux.css';
import serviceConfig from '../../../serviceConfig.json';
import getAppointmentsLabel from '../../Utils/appointmentManagerUtilities';
import { getDurations } from '../Careplans/CareplanHelperUtility';
import {
  lifespanUnits,
  lifespanUnitToValue,
} from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import ICDViewer from '../../../caro-ui-commonfiles/components/ICD/ICDViewer';
import TagsCell from './CellElements/TagsCell';
import Loader from 'react-loader-spinner';

const CarePathwayIndex = ({
  selectedCarepathway,
  setSelectedCarepathway,
  setAllFutureCareplans,
}) => {
  const [expanded, setExpanded] = useState({});
  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'name',
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [carePathwayTemplates, setCarePathwayTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [pageState, setPageState] = useState(10);
  const [query, setQuery] = useState({
    value: '',
    start: 0,
    length: 10,
    dir: 'asc',
    column: 'name',
  });
  const [pathwayData, setPathwayData] = useState([]);
  const [flashMessage, setFlashMessage] = useState({});
  const [typingTimeOut, setTypingTimeOut] = useState(0);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);

  const onChange = (index, size) => {
    setPageState(size);
    setCurrentPage(
      carePathwayTemplates && index * size > recordsTotal ? 0 : index
    );
  };

  const fetchTemplates = async id => {
    try {
      setIsTemplatesLoading(true);
      const response = await axios.get(
        `${serviceConfig.brokerService.getTemplatesByCarePathwayId.uri + id}`
      );
      setIsTemplatesLoading(false);
      return response.data.data.templates;
    } catch (error) {
      setIsTemplatesLoading(false);
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  const getCarePathwayTemplates = (templates, id) => {
    const allAssociatedTemplates = [];
    for (let i = 0; i < templates.length; i++) {
      const carePathwayTemplateInfo =
        templates[i].carePathwayTemplateAppointmentType;
      for (let index = 0; index < carePathwayTemplateInfo.length; index++) {
        const isRoot = carePathwayTemplateInfo[index].isRoot;
        const recurrenceInfo = carePathwayTemplateInfo[index];
        const appointmentType = getAppointmentsLabel({
          appointmentTypeOrder: recurrenceInfo.appointmentTypeId,
        });
        const unit = lifespanUnits().find(
          lifespanUnit =>
            lifespanUnit.value ===
            lifespanUnitToValue[recurrenceInfo.durationUnit]
        );
        allAssociatedTemplates.push({
          carePathwayTemplateId: id,
          id: templates[i].id,
          name: templates[i].name,
          reference: getDurations(
            appointmentType,
            recurrenceInfo.duration,
            unit.label
          ),
          appointmentType,
          futureCareplans: recurrenceInfo.duration >= 0,
          roles: templates[i].roles,
          isAssessment: templates[i].isAssessment,
          isRoot: isRoot,
        });
      }
    }

    return allAssociatedTemplates;
  };

  const fetchCarePathwayWithTemplates = async () => {
    try {
      setLoading(true);
      query.view = 'getCarepathwayTemplates';
      const searchTermLength = query.value.length;
      if (searchTermLength > 1 || searchTermLength === 0) {
        return await axios
          .get(serviceConfig.brokerService.getAllCarePathwayTemplates.uri, {
            params: {
              queryData: query,
            },
          })
          .then(function (result) {
            const dataCarepathway = [];
            const carepathwayTemplatesLength = result.data.data.length;
            const carepathwayTemplates = result.data.data;
            for (let index = 0; index < carepathwayTemplatesLength; index++) {
              const { id, icds, uuid, careplanTemplates } =
                carepathwayTemplates[index];
              const pack = {
                id,
                name: `${carepathwayTemplates[index].name}`,
                icds,
                carepathwayTemplateUUID: uuid,
                careplanTemplates,
              };
              dataCarepathway.push(pack);
            }
            setCarePathwayTemplates(dataCarepathway);
            setLoading(false);
            setRecordsTotal(result.data.recordsTotal);
          });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setFlashMessage({
        type: error.response.data.type,
        content: I18n.t(error.response.data.content),
      });
    }
  };

  useEffect(() => {
    if (typingTimeOut > 0) clearTimeout(typingTimeOut);
    if (query.value.length === 0) {
      fetchCarePathwayWithTemplates();
    } else {
      const timeOut = setTimeout(() => {
        fetchCarePathwayWithTemplates();
      }, 1000);
      setTypingTimeOut(timeOut);
    }
    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [query]);

  useEffect(() => {
    setQuery({
      start: currentPage * pageState,
      length: pageState,
      dir: sort.dir,
      column: sort.column,
      value: query.value,
    });
  }, [currentPage, pageState, sort]);

  const careplanSubComponent = ({ name, reference, roles, isAssessment }) => {
    const rolePatients = [];
    if (!isAssessment) {
      rolePatients.push({ name: I18n.t('role_view.label_patients') });
    }
    return (
      <Flex
        alignItems="center"
        otherStyles={{
          width: '100%',
          border: '0.2px solid',
          borderColor: Greys.LIGHT_GREY_20,
        }}
      >
        <div
          className="rt-td"
          style={{
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            maxWidth: 60,
            flex: '60 0 auto',
            width: 60,
          }}
          role="gridcell"
        >
          <Box width={28}>
            <Icon name={isAssessment ? 'assessment' : 'careplan'} size={28} />
          </Box>
        </div>
        <div
          className="rt-td"
          style={{ flex: '330 0 auto', width: 280, maxWidth: 300 }}
          role="gridcell"
        >
          {name}
        </div>
        <div
          className="rt-td"
          style={{
            flex: '100 0 auto',
            width: 100,
            maxWidth: 200,
            whiteSpace: 'normal',
          }}
          role="gridcell"
        >
          {reference}
        </div>
        <div className="rt-td expander-data-table" role="gridcell">
          <span>
            <TagsCell tagList={!isAssessment ? rolePatients : roles} roles />
          </span>
        </div>
      </Flex>
    );
  };

  const isExpandedRow = (index, test) => {
    const expandedKey = Object.keys(test);
    if (expandedKey.length > 0) {
      const indexFound = expandedKey.find(item => item == index);
      return indexFound > -1 && test[`${indexFound}`];
    }
    return false;
  };

  const subComponent = ({ original }) => {
    if (isTemplatesLoading === true) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{
            left: '50%',
          }}
        >
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Flex>
      );
    } else {
      const { templates } = original;

      if (templates && templates.length > 0) {
        return templates.map(careplan =>
          careplanSubComponent(careplan, templates.length === 1)
        );
      }
      return null;
    }
  };

  const tableProps = useMemo(() => {
    return {
      defaultSorted: [
        {
          id: 'name',
          desc: false,
        },
      ],
      onSortedChange: newSorted => {
        setSort({
          column: newSorted[0].id,
          dir: newSorted[0].desc ? 'desc' : 'asc',
        });
      },
      SubComponent: props => subComponent(props),
      getTrProps: (state, rowInfo) => {
        if (rowInfo) {
          return {
            onClick: () => {
              const { id } = rowInfo.original;
              setSelectedCarepathway(id);
            },
            style: {
              boxShadow: isExpandedRow(rowInfo.index, expanded)
                ? 'inset 0 1px 10px 0 rgba(205, 205, 205, 0.5)'
                : '0 1px 5px 0 rgba(205, 205, 205, 0.5)',
              borderRadius: '0px',
              background:
                rowInfo.original.id === selectedCarepathway
                  ? PrimaryColor.MAIN_COLOR_LIGHT
                  : null,
              cursor: 'pointer',
            },
          };
        }
        return {};
      },
      getTrGroupProps: (state, rowInfo) => {
        if (rowInfo) {
          return {
            className: 'care-pathway__rt-tr-group',
          };
        }
        return {};
      },
      expanded,
      onExpandedChange: (newExpanded, index, event) => {
        setExpanded(newExpanded);
        if (isExpandedRow(index, newExpanded) && pathwayData.length) {
          const { id } = pathwayData[index];
          let pathwayDataCopy = [...pathwayData];
          return Promise.all(
            pathwayDataCopy.map(async elem => {
              if (elem.id == id) {
                elem.templates = await fetchTemplates(id);
                elem.templates =
                  elem.templates.length > 0
                    ? await getCarePathwayTemplates(elem.templates, elem.id)
                    : [];
                elem.templates =
                  elem.templates &&
                  elem.templates.filter(careplan => careplan.isRoot);
              }
              return elem;
            })
          ).then(() => {
            setPathwayData(pathwayDataCopy);
          });
        }
      },
      previousText: I18n.t('datatable.previous'),
      nextText: I18n.t('datatable.next'),
      noDataText: I18n.t('common_labels.label_noData'),
      pageText: I18n.t('datatable.showing'),
      ofText: I18n.t('datatable.of'),
      rowsText: I18n.t('datatable.entries'),
      rowsSelectorText: I18n.t('datatable.recordsPerPage'),
    };
  });

  useEffect(() => {
    if (carePathwayTemplates) {
      setPathwayData(carePathwayTemplates);
    }
  }, [carePathwayTemplates]);

  const columns = useMemo(() => {
    return [
      {
        accessor: 'carePathwayIndicator',
        filterable: false,
        maxWidth: 60,
        style: {
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        },
        headerStyle: {
          marginRight: -20,
        },
        Cell: props => {
          return (
            <Box width={30}>
              <Icon name="carepathway" size={30} />
            </Box>
          );
        },
      },
      {
        Header: ({ column }) => {
          const { id } = column;
          return (
            <>
              {sort.column === id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{I18n.t('newCareplan_view.title')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'name',
        filterable: false,
        minWidth: 300,
        maxWidth: 350,
        style: {
          fontWeight: 600,
        },
        headerStyle: {
          marginRight: 20,
        },
      },
      {
        Header: I18n.t('common_labels.label_reference'),
        headerClassName: 'data-table__column-1__header',
        accessor: 'reference',
        sortable: false,
        filterable: false,
        minWidth: 200,
        maxWidth: 215,
      },

      {
        Header: I18n.t('newCareplan_view.conducted_by'),
        headerClassName: 'data-table__column-1__header',
        accessor: 'roles',
        sortable: false,
        filterable: false,
        minWidth: 200,
        maxWidth: 215,
      },

      {
        Header: () => {
          return <span>{I18n.t('newCareplan_view.icd_code')}</span>;
        },
        accessor: 'icds',
        sortable: false,
        filterable: false,
        style: { overflow: 'visible' },
        minWidth: 180,
        Cell: ({ row }) => {
          return <ICDViewer icds={row.icds} fromAssignModal />;
        },
      },
      {
        expander: true,
        Expander: props => {
          return (
            <Icon
              name={props.isExpanded ? 'arrowDown' : 'arrowRight'}
              size={22}
            />
          );
        },
      },
    ];
  }, [sort.column, sort.dir]);

  return (
    <ModalSection hasPadding>
      {flashMessage.content && (
        <FlashMessage
          message={I18n.t(flashMessage.content)}
          type={flashMessage.type}
          onClick={() => {
            setFlashMessage({});
          }}
        />
      )}
      <Flex justifyContent="flex-end" otherStyles={{ width: '100%' }}>
        <Box>
          <Input
            id="serachCarepathway"
            placeholder={I18n.t('common_labels.label_search')}
            variant={inputField.variant.CHAR_COUNT}
            maxChars={MaxCharLimits.searchInputs.searchKeyword}
            value={query.value}
            handleOnChange={e => {
              setQuery({
                value: e.target.value,
                start: 0,
                length: 10,
                dir: sort.dir,
                column: sort.column,
              });
              setCurrentPage(0);
              setPageState(10);
            }}
          />
        </Box>
      </Flex>
      <DataTable
        data={pathwayData}
        columns={columns}
        pageSize={false}
        pageSizeOptions={items}
        pagination
        isLoading={loading}
        tableProps={tableProps}
        onTableChange={onChange}
        page={currentPage}
        pages={
          carePathwayTemplates ? Math.ceil(recordsTotal / query.length) : 1
        }
        totalRecord={recordsTotal}
      />
    </ModalSection>
  );
};

export default CarePathwayIndex;
