import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import {
  Box,
  Flex,
  RadioButton,
  Icon,
  Button,
} from '@ui-common-files/components';
import {
  ButtonType,
  ButtonVariant,
  CheckBoxRadioVariants,
  Greys,
  PrimaryColor,
  ScoringSchemaColors,
} from '@ui-common-files/utils';

import { formatDate } from '@global/Date';

import CareplanResultsModalContentAnswers from '../../Careplans/CareplanResultsModalContentAnswers';

import {
  useCarepathwayTriggeredResultService,
  useTriggeredCaretaskService,
} from './CarepathwayTriggeredResult.service';
import { getNodeByParentId } from './CarepathwayTriggeredResult.util';
import { PatientOverviewContext } from 'src/Views/PatientOverview/PatientOverview';

import './CarepathwayTriggeredResult.styles.scss';

const TriggeredCaretask: React.FC = ({
  node,
  nodes,
  edges,
  condition,
  caretaskIdTriggeredThreshold,
  shouldShowAnswerTime,
}) => {
  const { t } = useTranslation();
  const {
    edge,
    isToggle,
    childNode,
    contentToggle,
    contentBodyCSSClasses,
    headerInnerCSSClasses,
    conditionArrowCSSClasses,
  } = useTriggeredCaretaskService({
    node,
    nodes,
    edges,
    condition,
    caretaskIdTriggeredThreshold,
  });

  const { navigateToCareplanAnswerView, closeModal } = useContext(
    PatientOverviewContext
  );

  const {
    id,
    isAssessment,
    firebaseKey,
    isAnswered,
    shouldUserAnswerCareplan,
    isAnswerable,
    occurrenceId,
  } = node.careplan;

  const navigateToAnswer = ({
    id,
    isAssessment,
    occurrenceId,
  }: {
    id: number;
    isAssessment: boolean;
    occurrenceId: number;
  }) => {
    navigateToCareplanAnswerView({
      id,
      isAssessment,
      occurrenceId,
      setItemIdToUpdate: () => {},
    });
    closeModal();
  };
  const isActivatedCaretaskOrAssessment = firebaseKey || isAssessment;
  const shouldShowAnswerOnBehalfButton =
    isAnswerable && shouldUserAnswerCareplan && isActivatedCaretaskOrAssessment;
  const isChildOccurrenceValid = node.isLifespanValid;
  const timeOfResponse =
    node.careplan.careplanComponents[0].answers[0]?.savedAt;
  return (
    <Box className="triggered-item">
      <Flex>
        <Box className="triggered-item_content">
          <Flex
            className="triggered-item_content-header"
            onClick={contentToggle}
          >
            {condition && (
              <Flex className="triggered-item_content-header_condition">
                <Box className={conditionArrowCSSClasses}>
                  <Box className="triggered-item_content-header_condition-arrow-content" />
                </Box>
                <Box className="triggered-item_content-header_condition-icon">
                  <Icon
                    name={`score${condition}`}
                    fill={ScoringSchemaColors[condition].color}
                    size={16}
                  />
                </Box>
              </Flex>
            )}
            <Flex className={headerInnerCSSClasses} alignItems="center">
              <Icon
                name={node.careplan.isAssessment ? 'assessment' : 'careplan'}
                fill={PrimaryColor.MAIN_COLOR}
              />
              <Flex className="triggered-item_content-header-inner-title">
                {node.careplan.name}
                {shouldShowAnswerTime && timeOfResponse && (
                  <span>{`${t(
                    'QuestionCatalog_view.timeOfAnswer'
                  )}: ${formatDate(
                    timeOfResponse,
                    'DD MMM YYYY HH:mm'
                  )}`}</span>
                )}
              </Flex>
              {node.isAnswered && (
                <Icon
                  name={isToggle ? 'arrowUp' : 'arrowDown'}
                  fill={Greys.LIGHT_GREY}
                />
              )}
              <Box className="margin-left-10">
                {shouldShowAnswerOnBehalfButton &&
                  !isAnswered &&
                  isChildOccurrenceValid && (
                    <Button
                      label={
                        isAssessment
                          ? t('newCareplan_view.startNow')
                          : t('newCareplan_view.answerOnBehalf.text')
                      }
                      type={ButtonType.BUTTON}
                      onClick={() => {
                        navigateToAnswer({ id, isAssessment, occurrenceId });
                      }}
                      variant={ButtonVariant.CONTAINED}
                    />
                  )}
              </Box>
              {!node.isAnswered && !isChildOccurrenceValid && (
                <Box className="triggered-item_content-header-inner-info">
                  {t('newCareplan_view.lifespanTerminated')}
                </Box>
              )}
            </Flex>
          </Flex>
          {node.isAnswered && isToggle && (
            <Box className={contentBodyCSSClasses}>
              <CareplanResultsModalContentAnswers
                questionWithThresholdsAndJumps={
                  node.careplan.careplanComponents
                }
                careplanData={node.careplan}
                scores={node.careplan.scores}
                shouldHaveAnswerTitle={false}
                overallScore={node.careplan.overallScore}
              />
            </Box>
          )}
        </Box>
      </Flex>
      {edge && (
        <TriggeredCaretask
          node={childNode}
          nodes={nodes}
          edges={edges}
          condition={edge.condition}
          caretaskIdTriggeredThreshold={caretaskIdTriggeredThreshold}
          shouldShowAnswerTime={true}
        />
      )}
    </Box>
  );
};

const CarepathwayTriggeredResult: React.FC = ({
  caretaskId,
  caretaskIdTriggeredThreshold = null,
  answerTimestamp = null,
}) => {
  const { t } = useTranslation();
  const {
    caretasks,
    selectedDate,
    datesOfAnswers,
    setSelectedDate,
    caretasksLoading,
    datesOfAnswersLoading,
  } = useCarepathwayTriggeredResultService(caretaskId, answerTimestamp);

  return (
    <Flex>
      <Box className="answers-list">
        <Box className="answers-list_title">
          {t('QuestionCatalog_view.timeOfAnswer')}
        </Box>
        <Box className="answers-list_content">
          {datesOfAnswersLoading && (
            <Flex justifyContent="center">
              <Loader
                type="Oval"
                color={PrimaryColor.MAIN_COLOR}
                height={24}
                width={24}
              />
            </Flex>
          )}
          {datesOfAnswers?.dates.map(date => (
            <Box>
              <RadioButton
                key={date.savedAt}
                id={date.savedAt}
                name={date.savedAt}
                label={formatDate(date.savedAt, 'DD MMM YYYY HH:mm')}
                checked={date.savedAt === selectedDate}
                variant={CheckBoxRadioVariants.Default}
                handleOnChange={e => {
                  if (e.target.checked) {
                    setSelectedDate(date.savedAt);
                  }
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box className="triggered-container">
        {selectedDate && caretasksLoading && (
          <Flex justifyContent="center" alignItems="center">
            <Loader
              type="Oval"
              color={PrimaryColor.MAIN_COLOR}
              height={30}
              width={30}
            />
          </Flex>
        )}
        {selectedDate && caretasks && (
          <TriggeredCaretask
            node={getNodeByParentId(caretasks.nodes, null)}
            nodes={caretasks.nodes}
            edges={caretasks.edges}
            caretaskIdTriggeredThreshold={caretaskIdTriggeredThreshold}
            shouldShowAnswerTime={false}
          />
        )}
      </Box>
    </Flex>
  );
};

export default CarepathwayTriggeredResult;
