import i18n from 'i18next';
import React, { useState } from 'react';
import { isISODate, Greys, PrimaryColor } from '@ui-common-files/utils';

import moment from 'moment';
import {
  CriteriaType,
  FieldTypes,
} from '../../PatientFilterContext/PatientFilter.type';
import { PatientFilterLabelRemove } from './PatientFilterSelectLabel.type';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import { CareplanComponentTypes } from '../../../../../types/Careplan';

export const usePatientFilterSelectLabelService = () => {
  const { removeAppliedFilter } = usePatientFilterContext();
  const [fill, setFill] = useState<string>(Greys.WHITE);
  const setBrightFill = () => setFill(PrimaryColor.MAIN_COLOR_LIGHT);
  const setDarkFill = () => setFill(PrimaryColor.MAIN_COLOR);
  const renderDate = (value: Date) => {
    const date = new Date(value);
    return moment(date).locale(i18n.language).format('L');
  };

  const getLabel = (filter: CriteriaType) => {
    const value = filter.value.reduce((prev, curr, index) => {
      let value = curr;
      if (curr instanceof Date || isISODate(curr)) value = renderDate(curr);
      else if (curr instanceof Object) {
        if (curr.labelLocation) value = i18n.t(curr.labelLocation).toString();
        else if (
          filter.careplanComponentTypeId ===
            CareplanComponentTypes.SingleChoice ||
          filter.careplanComponentTypeId ===
            CareplanComponentTypes.MultipleChoice
        )
          value = curr.label;
        else if (curr.value) value = curr.value as string;
        else if (
          Array.isArray(curr) &&
          curr.length &&
          (filter.field.type === FieldTypes.ICD ||
            filter.field.type === FieldTypes.OPS)
        )
          value = curr[0].code;
      }
      return `${prev}${index ? ' - ' : ''}${value}`;
    }, '');
    return `${i18n.t(filter.field.labelLocation)}: ${i18n.t(
      filter.check.label
    )} ${value}`;
  };

  const removeLabel: PatientFilterLabelRemove = (position, type) => e => {
    e.stopPropagation();
    removeAppliedFilter(type, position);
  };

  return {
    fill,
    setBrightFill,
    setDarkFill,
    getLabel,
    removeLabel,
  };
};
