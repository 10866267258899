import { WearablesDataTypes } from '@ui-common-files/utils';
import DataPointType from '../OverviewDataPointsSelection.enum';

const useSelectedDataPointsService = () => {
  const getTitle = (dataPointType, dataPointName, dataPointDescription) => {
    if (dataPointType === DataPointType.HEALTH_DATA) {
      return dataPointName;
    }
    return dataPointDescription?.length > 50
      ? `${dataPointDescription.substring(0, 50)} ...`
      : dataPointDescription;
  };

  const getKey = (templateId: number, healthDataKey: WearablesDataTypes) => {
    return templateId? `point_${templateId}`: `point_${healthDataKey}`
  };

  return {
    getTitle,
    getKey
  };
};

export default useSelectedDataPointsService;
