import I18n from 'i18next';

type ComponentTypeObject = {
  questionType: string;
  iconName: string;
};

function ComponentType(id: number) {
  let componentTypeObj: ComponentTypeObject | null;
  switch (id) {
    case 1:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.FREETEXT'),
        iconName: 'freetext',
      };
      break;
    case 2:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.CHOICE'),
        iconName: 'singleChoices',
      };
      break;
    case 3:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.CHOICE'),
        iconName: 'multipleChoices',
      };
      break;
    case 4:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.NUMERIC_RANGE'),
        iconName: 'numericRange',
      };
      break;
    case 5:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.NUMERIC_VALUE'),
        iconName: 'numericValue',
      };
      break;
    case 6:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.YES_NO'),
        iconName: 'yesOrNo',
      };
      break;
    case 7:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.PAIN_LOCATION_CHART'),
        iconName: 'painLocationChart',
      };
      break;
    case 8:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.SORTABLE'),
        iconName: 'ranking',
      };
      break;
    case 9:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.INFORMATION'),
        iconName: 'todos',
      };
      break;
    case 10:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.GROUP_DESCRIPTION'),
        iconName: 'group',
      };
      break;
    case 11:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.UPLOAD_FILE'),
        iconName: 'uploadFile',
      };
      break;
    case 12:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.DATEPICKER'),
        iconName: 'datepicker',
      };
      break;
    case 13:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.BMI'),
        iconName: 'bmi',
      };
      break;
    case 14:
      componentTypeObj = {
        questionType: I18n.t('careplanComponentType.BLOOD_PRESSURE'),
        iconName: 'BloodPressure',
      };
      break;
  }

  return componentTypeObj;
}

export default ComponentType;
