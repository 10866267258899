import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import FilterDropdown from '../DropdownMenu/FilterDropdown';
import FlexBox from '../Flex/Flex';
import IconButton from '../IconButton/IconButton';
import { ActionColors } from '../../utils/colors';

import '../../styles/main.scss';

const UsFilterTopWarning = ({
  message,
  items,
  showUsWarningTopPage,
  active,
  onClick,
  showEditIcon,
  tooltip,
  onEditFilterClick,
  showFilter,
}) => {
  if (showUsWarningTopPage) {
    return (
      <div
        className="patient-index__us-warning-filter-wrapper"
        style={{
          minHeight: showFilter ? 50 : 'auto',
          marginBottom: showFilter ? 10 : 0,
          padding: showFilter ? '0 8px' : '0 13px 0 8px',
        }}
      >
        {showFilter && (
          <FlexBox alignItems="center">
            <FilterDropdown
              iconName="filter"
              onClick={onClick}
              items={items}
              active={active}
            />
            {showEditIcon && (
              <Box margin="0 0 0 10px">
                <IconButton
                  type="button"
                  name="edit"
                  size={24}
                  onClick={onEditFilterClick}
                  tooltip={tooltip}
                />
              </Box>
            )}
          </FlexBox>
        )}
        <FlexBox
          justifyContent={showFilter ? 'center' : 'flex-start'}
          otherStyles={{
            flex: '1 1 0%',
            margin: showFilter ? '0px 0px 0px 20px' : 0,
            padding: showFilter ? '0' : '8px 0',
          }}
        >
          <FlexBox alignItems="center">
            <FlexBox otherStyles={{ flexShrink: 0 }}>
              <Icon name="warning" size={39} fill={ActionColors.ERROR} />
            </FlexBox>
            <Box margin="0 0 0 8px">
              <span className="patient-index__us-warning-txt">{message}</span>
            </Box>
          </FlexBox>
        </FlexBox>
      </div>
    );
  }
  return (
    <div>
      <FlexBox alignItems="center">
        <FilterDropdown
          iconName="filter"
          onClick={onClick}
          items={items}
          active={active}
        />
        {showEditIcon && (
          <Box margin="0 0 0 10px">
            <IconButton
              type="button"
              name="edit"
              size={24}
              onClick={onEditFilterClick}
              tooltip={tooltip}
            />
          </Box>
        )}
      </FlexBox>
    </div>
  );
};

UsFilterTopWarning.propTypes = {
  message: PropTypes.string.isRequired,
  items: PropTypes.array,
  active: PropTypes.number,
  onClick: PropTypes.func,
  showEditIcon: PropTypes.bool,
  tooltip: PropTypes.string,
  onEditFilterClick: PropTypes.func,
  showFilter: PropTypes.bool,
  showUsWarningTopPage: PropTypes.bool,
};

UsFilterTopWarning.defaultProps = {
  showFilter: true,
};

export default UsFilterTopWarning;
