import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex } from '@ui-common-files/components';

import { ButtonVariant } from '@ui-common-files/utils';

import useCustomLegendService from './CustomLegend.service';
import SelectedDataPoints from '../OverviewDataPointsSelection/SelectedDataPoints/SelectedDataPoints';
import { SelectedPoint } from '../../Overview.type';

import './CustomLegend.styles.scss';

const CustomLegend = () => {
  const { t } = useTranslation();

  const {
    selectedDataPoints,
    openModal,
    shouldGetGraphViewData,
  } = useCustomLegendService();

  return (
    <Flex justifyContent="space-between" alignItems="center">
      {selectedDataPoints.map((selectedPoint: SelectedPoint, index: number) => (
        <SelectedDataPoints
          className="custom-legend-selected-point"
          dataPoint={selectedPoint}
          index={index}
          shouldGetGraphViewData={shouldGetGraphViewData}
        />
      ))}

      {selectedDataPoints.length === 1 && (
        <Button
          variant={ButtonVariant.OUTLINED}
          label={t('recurrence.compare')}
          onClick={openModal}
        />
      )}
    </Flex>
  );
};

export default CustomLegend;
