import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import {
  getCareplanSequence,
  repeatTypes,
  useAvailableLifespan,
} from '@ui-common-files/utils';
import { useCommonCareTaskConfiguration } from '../useCommonCareTaskConfiguration';

import { useCarePathWayBuilderStore } from '../../CarePathWayBuilder.store';

const useCareTaskConfigurationService = () => {
  const { t } = useTranslation();
  const { nodeData } = useCarePathWayBuilderStore(
    useShallow(state => ({
      nodeData: state.nodes.find(
        node => node.id === state.sidebar.configurationNodeId
      )?.data,
    }))
  );

  const {
    lifespanUnitsObjSmall,
    configuration,
    onConfigurationChange,
    saveConfiguration,
    getTranslatedLabel,
    hasErrors,
  } = useCommonCareTaskConfiguration();

  const carePathwayCareplanSequence = useMemo(() => getCareplanSequence(), [t]);
  const repeatTypesArray = useMemo(() => repeatTypes(), [t]);
  const availableLifespanUnits = useAvailableLifespan(
    configuration.repeatType,
    0,
    configuration.lifespanUnit,
    () => {}
  );

  return {
    lifespanUnitsObjSmall,
    carePathwayCareplanSequence,
    nodeData,
    configuration,
    repeatTypesArray,
    onConfigurationChange,
    availableLifespanUnits,
    saveConfiguration,
    hasErrors,
    getTranslatedLabel,
  };
};

export default useCareTaskConfigurationService;
