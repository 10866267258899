/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';

import DropdownMenu from '../../../../caro-ui-commonfiles/components/DropdownMenu/DropdownMenu';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';

import Actions from '../../../Utils/actions';

const CustomCountrySelect = props => {
  const { options, iconComponent } = props;
  const result = options.map(item => (
    <li
      key={item.value ? item.value : 'international'}
      onClick={() => props.onChange(item.value)}
    >
      <span className="dropdown__item">{item.label}</span>
    </li>
  ));
  const flagImage = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${props.value}.svg`;
  return (
    <div style={{ display: 'flex' }}>
      {props.action !== Actions.Edit && (
        <DropdownMenu items={result} variant="mobile-input__dropdown">
          <span className="mobile-input__flag">
            {props.value ? (
              <div className="react-phone-number-input__icon">
                <img src={flagImage} />
              </div>
            ) : (
              iconComponent(props)
            )}
            <Icon
              style={{ flex: '1', width: '3em' }}
              name="expanded"
              size={25}
            />
          </span>
        </DropdownMenu>
      )}
    </div>
  );
};

CustomCountrySelect.propTypes = {
  options: PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default CustomCountrySelect;
