import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';

import {
  Flex,
  Box,
  ModalContent,
  ComponentWithLabel,
  TextInput,
  Button,
  Icon,
  Dropdown,
  MultipleSelection,
} from '@ui-common-files/components';
import ModalSection from '../common/Layout/Modal/ModalSection';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ComplexSearch from '../../../caro-ui-commonfiles/components/ComplexSearch/DynamicBuilder';
import QueryComponent from './BuilderComponent';

import {
  ComponentTranslationKeys,
  ActionTranslationKeys,
  ActionColors,
  inputField,
  MaxCharLimits,
} from '@ui-common-files/utils';
import {
  checkUserCredential,
  useUserPermission,
  complexSearchData,
  queryOperators,
} from '@utils';

import {
  MODAL_VARIANT,
  MODAL_FORM_DIRECTION,
} from '../../Utils/Layout/modalSection';

import { updateCustomFilter } from '../../../actions/Datatables/patientFilter';

import serviceConfig from '../../../serviceConfig.json';

import '../../css/customFilters.css';

const CustomFilterUpdateModalContent = ({
  customFilterId,
  customFilterName,
  closeModal,
  reloadTable,
  setFlashMessage,
  LoadedRule,
  selectedSite,
  setSelectedSite,
  sites,
  existingFilters,
  preset,
  unionQuery,
}) => {
  const services = useSelector(state => state.services);
  const { allFilters, savedFilter } = useSelector(
    state => state.patientFilters
  );
  const reduxDispatcher = useDispatch();

  const { careplanFilterData, filterData } = complexSearchData(services);
  const retrievedDefaultFilter = savedFilter.index;

  const [name, setName] = useState(customFilterName);
  const [filterRule, setFilterRule] = useState(null);
  const [sqlQuery, setSqlQuery] = useState(null);
  const [displayError, setDisplayError] = useState({
    display: false,
    message: '',
  });
  const [hasError, setHasError] = useState(false);
  const [siteEmptyError, setSiteEmptyError] = useState(false);
  const [filterNameError, setFilterNameError] = useState(false);
  const [careplanPermission, setCareplanPermission] = useState(true);
  const [onPreset, setOnPreset] = useState(true);
  const [initialRule, setInitialRule] = useState(LoadedRule);
  const [currPreset, setCurrPreset] = useState(null);
  const { t } = useTranslation();

  const IdGenerator = queryProps => {
    const currID = Math.floor(1000 + Math.random() * 9999);
    if (queryProps.length === 0) {
      return currID;
    }
    const found = queryProps.findIndex(query => query.id === currID) !== -1;
    if (found) {
      IdGenerator(queryProps);
    }
    return currID;
  };

  const prefillFromPreset = (state, query) => {
    const code = IdGenerator(state);
    const genId = `secondary-builder-${code}`;
    return {
      id: genId,
      rules: null,
      sql: null,
      operator: query.operator,
      hasError: false,
      initialRule: query.rule,
    };
  };

  const actionTypes = {
    ON_SITE_CHANGE: 'on_site_change',
    ON_USER_CHANGE: 'on_user_change',
    ON_RESET_USER: 'on_reset_user',
    ON_RESET_SITE: 'on_reset_site',
  };

  const initialState = {
    siteNameTextBox: null,
    userNameTextBox: null,
    componentNameTextBox: null,
  };

  const reducer = (state, action) => {
    let newState = {
      ...state,
    };
    switch (action.type) {
      case actionTypes.ON_SITE_CHANGE:
        if (state.userNameTextBox && action.payload === state.userNameTextBox) {
          newState = {
            ...state,
            siteNameTextBox: action.payload,
            userNameTextBox: null,
          };
        } else if (
          state.componentNameTextBox &&
          action.payload === state.componentNameTextBox
        ) {
          newState = {
            ...state,
            siteNameTextBox: action.payload,
            componentNameTextBox: null,
          };
        } else {
          newState = {
            ...state,
            siteNameTextBox: action.payload,
          };
        }
        break;
      case actionTypes.ON_USER_CHANGE:
        if (state.siteNameTextBox && state.siteNameTextBox === action.payload) {
          newState = {
            ...state,
            userNameTextBox: action.payload,
            siteNameTextBox: null,
          };
        } else if (
          state.componentNameTextBox &&
          action.payload === state.componentNameTextBox
        ) {
          newState = {
            ...state,
            userNameTextBox: action.payload,
            componentNameTextBox: null,
          };
        } else {
          newState = {
            ...state,
            userNameTextBox: action.payload,
          };
        }
        break;
      case actionTypes.ON_RESET_SITE:
        newState = {
          ...state,
          siteNameTextBox: null,
        };
        break;
      case actionTypes.ON_RESET_USER:
        newState = {
          ...state,
          userNameTextBox: null,
        };
        break;
    }
    return newState;
  };

  const [actionState, dispatch] = useReducer(reducer, initialState);

  const builderActions = {
    CREATE_NEW_QUERY: 'create_new_query',
    SET_QUERY: 'set_query',
    REMOVE_QUERY: 'remove_query',
    PRESET_QUERY: 'preset_query',
    RESET_QUERY: 'reset_query',
  };

  const queryReducer = (state, action) => {
    const clonedProps = [...state];
    switch (action.type) {
      case builderActions.CREATE_NEW_QUERY: {
        const code = IdGenerator(state);
        const genId = `secondary-builder-${code}`;
        clonedProps.push({
          id: genId,
          rules: null,
          sql: null,
          operator: queryOperators.EXCEPT_OPERATOR,
          hasError: false,
          initialRule: null,
        });
        return clonedProps;
      }
      case builderActions.SET_QUERY: {
        const newProps = {
          ...clonedProps[action.payload.index],
          ...action.payload.value,
        };
        clonedProps.splice(action.payload.index, 1, newProps);
        return clonedProps;
      }
      case builderActions.REMOVE_QUERY: {
        clonedProps.splice(action.payload, 1);
        return clonedProps.map(query =>
          prefillFromPreset(clonedProps, {
            operator: query.operator,
            rule: query.rules ? query.rules : null,
          })
        );
      }
      case builderActions.PRESET_QUERY:
        return action.payload;
      case builderActions.RESET_QUERY:
        return [];
    }
  };

  const [builderState, dispatchBuilderHandler] = useReducer(queryReducer, []);

  const validateAttachedBuilders = () => {
    let errorFound = false;
    for (let index = 0; index < builderState.length; index++) {
      if (builderState[index].hasError) {
        errorFound = true;
        break;
      }
    }
    return errorFound;
  };

  const onChangeNameText = event => {
    setName(event.target.value);
  };

  const onSiteSelectionChange = event => {
    setSelectedSite(event);
  };

  const onPresetSelectionChange = event => {
    if (event.value > -1) {
      if (
        existingFilters[event.value].unionRules &&
        existingFilters[event.value].unionRules.rules &&
        existingFilters[event.value].unionRules.rules.length > 0
      ) {
        const clonedFromPreset = existingFilters[event.value].unionRules.rules;
        const parsedFromPreset = clonedFromPreset.map(query => {
          return prefillFromPreset(builderState, query);
        });
        dispatchBuilderHandler({
          type: builderActions.PRESET_QUERY,
          payload: parsedFromPreset,
        });
      } else {
        dispatchBuilderHandler({
          type: builderActions.RESET_QUERY,
        });
      }
      setInitialRule(existingFilters[event.value].rule);
      setOnPreset(false);
      setCurrPreset(preset[event.value + 1]);
    } else {
      setInitialRule(null);
      setOnPreset(false);
      dispatchBuilderHandler({
        type: builderActions.RESET_QUERY,
      });
      setCurrPreset(null);
    }
  };

  const hasReadSitesPermission = useUserPermission(
    t(ComponentTranslationKeys.SITE),
    t(ActionTranslationKeys.READ),
    true
  );

  useEffect(() => {
    if (!onPreset) {
      setOnPreset(true);
    }
  }, [onPreset]);

  const validateForm = () => {
    setFilterNameError(!name || name.trim().length < 1);
    setSiteEmptyError(!selectedSite || selectedSite.length < 1);
    if (
      name &&
      name.trim().length > 0 &&
      selectedSite &&
      selectedSite.length > 0 &&
      !hasError &&
      !validateAttachedBuilders()
    ) {
      return true;
    }
    return false;
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    if (validateForm()) updateCustomFilterHandler();
    return false;
  };
  const updateCustomFilterHandler = async () => {
    let filterOption;
    closeModal();
    const unionRules = builderState.map(query => {
      return {
        rule: query.rules,
        operator: query.operator,
      };
    });
    const unionSqlQuery = builderState.map(query => query.sql);
    if (selectedSite) {
      filterOption = selectedSite.map(site => site.value);
    }

    try {
      const response = await axios.post(
        serviceConfig.brokerService.updateCustomFilter.uri,
        {
          id: customFilterId,
          name,
          sql: sqlQuery,
          params: sqlQuery.params,
          filterRule: JSON.stringify(filterRule),
          selectSpecificOrg: filterOption,
          isUnion: builderState.length > 0,
          ...(builderState.length > 0 && {
            unionRules,
            unionSqlQuery,
          }),
        }
      );

      if (response) {
        if (response.status === 200) {
          reduxDispatcher(
            updateCustomFilter({
              allFilters: {
                key: name,
                value: name,
                rule: filterRule,
                filterId: customFilterId,
              },
              ...savedFilter,
            })
          );
        }
        reloadTable();
        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  const validation = ({ node, error }) => {
    const errorType = error[0];

    const isSelectedFieldEmpty =
      errorType === 'string_empty' ||
      errorType === 'datetime_empty' ||
      errorType === 'number_nan';

    const validationMessage = isSelectedFieldEmpty
      ? t('parsley.requiredField')
      : errorType;

    if (node.__.filter) {
      setDisplayError({
        display: true,
        message: `${node.__.filter.label} : ${validationMessage}`,
      });
    } else {
      setDisplayError({
        display: true,
        message: `Error : Empty filter`,
      });
    }
  };

  /* ************************************************************************* */
  useEffect(() => {
    if (
      unionQuery.unionRules &&
      unionQuery.unionRules.rules &&
      unionQuery.unionRules.rules.length > 0
    ) {
      const clonedFromUpdate = unionQuery.unionRules.rules;
      const parsedFromUpdate = clonedFromUpdate.map(query => {
        return prefillFromPreset(builderState, query);
      });
      dispatchBuilderHandler({
        type: builderActions.PRESET_QUERY,
        payload: parsedFromUpdate,
      });
    } else {
      dispatchBuilderHandler({
        type: builderActions.RESET_QUERY,
      });
    }
  }, []);

  useEffect(() => {
    const checkCareplanReadCredential = async () => {
      const hasCredential = await checkUserCredential(
        ComponentTranslationKeys.CAREPLAN,
        ActionTranslationKeys.READ
      );
      if (hasCredential === true) {
        setCareplanPermission(true);
      } else {
        setCareplanPermission(false);
      }
    };
    checkCareplanReadCredential();
  }, []);

  useEffect(() => {
    const addCustomDropdown = () => {
      $('#primary-builder').on(
        'afterCreateRuleInput.queryBuilder',
        function (e, rule) {
          updateCustomDropdown(rule);
        }
      );
    };
    addCustomDropdown();
  }, []);

  useEffect(() => {
    function setSiteSelected() {
      $(`[name="${actionState.siteNameTextBox}"]`).val(
        $('#editSiteList option:selected').text()
      );
      $(`[name="${actionState.siteNameTextBox}"]`).text(
        $('#editSiteList option:selected').text()
      );
      const evt = document.createEvent('Events');
      evt.initEvent('change', true, true);
      document
        .getElementsByName(actionState.siteNameTextBox)[0]
        .dispatchEvent(evt);
    }
    function setUserSelected() {
      $(`[name="${actionState.userNameTextBox}"]`).val(
        $('#editUserList option:selected').text()
      );
      $(`[name="${actionState.userNameTextBox}"]`).text(
        $('#editUserList option:selected').text()
      );
      const evt = document.createEvent('Events');
      evt.initEvent('change', true, true);
      document
        .getElementsByName(actionState.userNameTextBox)[0]
        .dispatchEvent(evt);
    }
    if (actionState.siteNameTextBox !== null) {
      const siteListDropdown = document.getElementById('editSiteList');
      if (siteListDropdown) {
        siteListDropdown.addEventListener('change', setSiteSelected);
      }
      setSiteSelected();
    }
    if (actionState.userNameTextBox !== null) {
      const userListDropdown = document.getElementById('editUserList');
      if (userListDropdown) {
        userListDropdown.addEventListener('change', setUserSelected);
      }
      setUserSelected();
    }
  }, [actionState]);
  useEffect(() => {
    $('#primary-builder').on(
      'beforeDeleteRule.queryBuilder',
      function (e, rule) {
        if (rule.filter.id === 'institutions.name') {
          dispatch({
            type: actionTypes.ON_RESET_SITE,
          });
        }
        if (rule.filter.id === 'users.username') {
          dispatch({
            type: actionTypes.ON_RESET_USER,
          });
        }
      }
    );
  }, []);

  const updateCustomDropdown = rule => {
    let siteListDropdown = null;
    let allSites;
    let userListDropdown = null;
    let allUsers;
    if (rule.filter.id === 'institutions.name') {
      axios
        .post(serviceConfig.brokerService.getAllSitesForModals.uri)
        .then(response => {
          allSites = response.data.allSites;
          rule.$el.find('#editSiteList').remove();
          siteListDropdown = $(
            '<select id = "editSiteList" class="form-control " padding="10" />'
          );
          for (const val in allSites) {
            $(`<option />`, {
              value: allSites[val].id,
              text: allSites[val].name,
              selected: rule.value === allSites[val].name,
            }).appendTo(siteListDropdown);
          }
          siteListDropdown.appendTo(rule.$el.find('.rule-value-container '));
          dispatch({
            type: actionTypes.ON_SITE_CHANGE,
            payload: rule.$el.find('.rule-value-container input').attr('name'),
          });
        });
    }
    if (rule.filter.id === 'users.username') {
      axios
        .get(serviceConfig.brokerService.getAllUsersData.uri)
        .then(response => {
          allUsers = response.data.data;
          rule.$el.find('#editUserList').remove();
          userListDropdown = $(
            '<select id = "editUserList" class="form-control " padding="10" />'
          );
          for (const val in allUsers) {
            $('<option />', {
              value: allUsers[val].id,
              text: allUsers[val].username,
              selected: rule.value === allUsers[val].username,
            }).appendTo(userListDropdown);
          }
          userListDropdown.appendTo(rule.$el.find('.rule-value-container '));
          dispatch({
            type: actionTypes.ON_USER_CHANGE,
            payload: rule.$el.find('.rule-value-container input').attr('name'),
          });
        });
    }
  };

  /** ********************************************************************** */
  const renderAttachedBuilders = () => {
    return builderState.map((query, index) => {
      return (
        <QueryComponent
          rules={query.initialRule}
          filters={careplanFilterData}
          id={query.id}
          onChange={queryObject => {
            dispatchBuilderHandler({
              type: builderActions.SET_QUERY,
              payload: {
                index,
                value: queryObject,
              },
            });
          }}
          operator={query.operator}
          onDelete={() => {
            dispatchBuilderHandler({
              type: builderActions.REMOVE_QUERY,
              payload: index,
            });
            setInitialRule(filterRule);
            setOnPreset(false);
          }}
        />
      );
    });
  };

  return (
    <ModalContent>
      <ModalForm
        formId="create-custom-filter-form"
        handleFormSubmit={handleFormSubmit}
        direction={MODAL_FORM_DIRECTION.COLUMN}
      >
        <Flex>
          <ModalSection hasOverflow={false}>
            <ComponentWithLabel
              id="filterName"
              htmlFor="filterName"
              hasIcon={false}
              labelText={`* ${t('customFilters_view.label_customFilterName')}`}
              tooltipText={t('customFilters_view.label_customFilterName')}
              hasToggleButton={false}
              idToggleButton="toggleBtn"
              valueToggleButton="toggleBtn"
              isDisabledToggleButton={false}
              checked={false}
            >
              <TextInput
                value={name}
                hasError={filterNameError}
                id="filterName"
                name="filterName"
                placeholder={t('customFilters_view.add_customFilterName')}
                variant={inputField.variant.CHAR_COUNT}
                maxLength={MaxCharLimits.filter.filterNameMaxCharLimit}
                maxChars={MaxCharLimits.filter.filterNameMaxCharLimit}
                handleOnChange={onChangeNameText}
                validationMessage={t('parsley.requiredField')}
              />
            </ComponentWithLabel>
          </ModalSection>
          <ModalSection hasOverflow={false}>
            <ComponentWithLabel
              id="preset"
              htmlFor="preset"
              hasIcon={false}
              labelText={t('customFilters_view.label_preset')}
              tooltipText={t('customFilters_view.label_preset')}
              hasToggleButton={false}
              idToggleButton="toggleBtn"
              valueToggleButton="toggleBtn"
              isDisabledToggleButton={false}
              checked={false}
            >
              <Dropdown
                value={currPreset}
                options={preset}
                placeholder={t('customFilters_view.label_select_preset')}
                handleOnChange={onPresetSelectionChange}
              />
            </ComponentWithLabel>
          </ModalSection>
        </Flex>
        {hasReadSitesPermission && (
          <ModalSection hasOverflow={false}>
            <Box otherStyles={{ width: '50%' }}>
              <ComponentWithLabel
                id="filterType"
                htmlFor="filterType"
                labelText={`* ${t('customFilters_view.label_default_filter')}`}
                tooltipText={t('customFilters_view.label_default_filter')}
                hasToggleButton={false}
                idToggleButton="toggleBtn"
                valueToggleButton="toggleBtn"
                isDisabledToggleButton={false}
                checked={false}
                tooltipText={
                  <Box className="tooltip-content-wrapper">
                    {t('newTemplate_view.site_info')}
                  </Box>
                }
                hasIcon
                icon="info"
              >
                <MultipleSelection
                  value={selectedSite}
                  defaultOptions={sites}
                  isDisabled={false}
                  maxChars={4}
                  placeholder={t('site_view.add_site')}
                  hasMark={false}
                  isClearable
                  clearIndicatorText={t('common_labels.label_clear_selections')}
                  isSelectAll
                  selectAllIndicatorText={t(
                    'common_labels.label_selectAll_options'
                  )}
                  noOptionsMessage={t(
                    'common_labels.label_noOptions_Available'
                  )}
                  changeValue={onSiteSelectionChange}
                  hasError={siteEmptyError}
                />
                {siteEmptyError && (
                  <span className="templateRequired">
                    {t('parsley.requiredField')}
                  </span>
                )}
              </ComponentWithLabel>
            </Box>
          </ModalSection>
        )}
        <ModalSection
          hasOverflow={false}
          hasColumnDirection
          variant={MODAL_VARIANT.BOTTOM}
        >
          <Flex
            justifyContent="space-between"
            otherStyles={{ padding: '5px 0' }}
          >
            {careplanPermission ? (
              <Button
                label={t('customFilters_view.label_add_new_filter')}
                onClick={() => {
                  dispatchBuilderHandler({
                    type: builderActions.CREATE_NEW_QUERY,
                  });
                }}
                disabled={builderState.length === 4}
              >
                <Icon name="plus" size={14} />
              </Button>
            ) : (
              <div />
            )}
            {displayError.display && (
              <Flex
                alignItems="center"
                otherStyles={{ color: ActionColors.ERROR, fontSize: 12 }}
              >
                <Box margin="0 5px 5px 0" height={16}>
                  <Icon name="error" size={20} fill={ActionColors.ERROR} />
                </Box>
                {displayError.message}
              </Flex>
            )}
          </Flex>
          {onPreset && (
            <>
              <div className="custom-filter__builder-container">
                <ComplexSearch
                  filters={filterData}
                  rules={initialRule}
                  getSql={value => {
                    setSqlQuery(value);
                  }}
                  getRules={rules => {
                    setFilterRule(rules);
                  }}
                  hide={false}
                  hasError={error => {
                    if (!error) {
                      setDisplayError({
                        display: false,
                        message: '',
                      });
                    }
                    setHasError(error);
                  }}
                  onRuleChange={updateCustomDropdown}
                  id="primary-builder"
                  onValidateError={validation}
                />
              </div>
              {builderState.length > 0 ? renderAttachedBuilders() : null}
            </>
          )}
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );
};

CustomFilterUpdateModalContent.propTypes = {
  customFilterId: PropTypes.number.isRequired,
  customFilterName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  reloadTable: PropTypes.func.isRequired,
  setFlashMessage: PropTypes.func.isRequired,
};

export { CustomFilterUpdateModalContent };
