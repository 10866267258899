import React, { useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import I18n from 'i18next';
import { useSelector } from 'react-redux';

import { LanguageItems } from '@type/Common/';
import { EnumToArray } from '@global/Arrays';

import {
  Flex,
  Box,
  Modal,
  Label,
  Dropdown,
  RecurrenceComponent,
  TextInput,
  ToggleButton,
  Checkbox,
  RadioButton,
  Icon,
  Tooltip,
} from '@ui-common-files/components';
import {
  inputField,
  CheckBoxRadioVariants,
  SubmissionTypes,
  Greys,
  repeatTypes,
  lifespanUnits,
  MaxCharLimits,
  Position,
} from '@ui-common-files/utils';

import IcdsComponent from './componentSettingsAssets/IcdsComponent';
import { ScoringSchemeComponent } from './TemplateSettingScoringComponent';
import { ComplexScoreSetting } from './ComplexScoreSetting';

import { phaseTypeMapper } from './CareplanHelperUtility';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';

import ReportSettingsComponent from './componentSettingsAssets/ReportSettingsComponent';

import '../../css/role.css';
import '../../css/componentSettings.css';
import '../../css/careplanTemplateSettings.scss';

function CareplanTemplateSettings({
  setWithinIntervals,
  withinIntervals,
  scoringFormulas,
  setScoringFormulas,
  customRecurrenceToggle,
  setCustomRecurrenceToggle,
  templateDescription,
  setTemplateDescription,
  careplanTemplateDescriptionToggle,
  careplanTemplateOverallScoringToggle,
  setCareplanTemplateDescriptionToggle,
  setCareplanTemplateOverallScoringToggle,
  careTaskInternalNameToggle,
  setCareTaskInternalNameToggle,
  fileReviewToggle,
  shareOverAllScore,
  setFileReviewToggle,
  setShareOverAllScore,
  allIcds,
  setAllIcds,
  icdReferenceToggle,
  setIcdReferenceToggle,
  phaseTypes,
  selectedPhaseType,
  setPhaseType,
  recurrenceErrors,
  setRecurrenceErrors,
  recurrenceData,
  setRecurrenceData,
  repeatTypeValue,
  setRepeatTypeValue,
  validateRecurrence,
  saveTrigger,
  isEvaluatingToggle,
  setIsEvaluatingToggle,
  isCareplanOrAssessment,
  setIsCareplanOrAssessment,
  totalScore,
  scoringScheme,
  setScoringScheme,
  isValidScoringScheme,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring,
  focusScoreSelection,
  onValidateScoring,
  alias,
  setTemplateAlias,
  selectedMedical,
  setSelectedMedical,
  careplanComponentTypes,
  componentCollection,
  setComponentCollection,
  counter,
  setCounter,
  setSettings,
  searchTermComponents,
  setSearchTermComponents,
  updateSelectedComponent,
  setOpenPrompt,
  setOpenPromptForParentComponentDeletion,
  setTemplateNames,
  isEditTemplate,
  setParentId,
  isEditable,
  setIsEditable,
  setTemplateSettings,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  isReportEnabled,
  setIsReportEnabled,
  occurrences,
  setOccurrences,
  reportLanguage,
  setReportLanguage,
  hasSelectedMedicalValidationError,
  hasThreshold,
  careTaskReviewAssessmentToggle,
  setCareTaskReviewAssessmentToggle,
  reportReviewToggle,
  setReportReviewToggle,
  reviewAssessments,
  reportReviewAssessment,
  setReportReviewAssessment,
  templateResponse,
}) {
  const subscribedServices = useSelector(state => state.services);
  const [startingArrays, setStartingArrays] = useState({
    startingDays: [],
    startingTimes: [],
  });
  const languagesArray = EnumToArray(LanguageItems);
  const { t } = useTranslation();
  useEffect(() => {
    if (!isCareplanOrAssessment) {
      const rolesCopy = [...selectedMedical];
      rolesCopy.map(role => {
        role.isChecked = false;
      });
      setSelectedMedical(rolesCopy);
    }
  }, [isCareplanOrAssessment]);

  useEffect(() => {
    if (selectedPhaseType) {
      setPhaseType(selectedPhaseType);
    } else {
      setPhaseType({ id: 1, value: 1, label: 'types.phaseType.planning' });
    }
  }, [selectedPhaseType]);

  const onLanguageChanged = userLanguage => {
    const repeatTypesAll = repeatTypes();
    const lifespanUnitsObj = lifespanUnits();
    setRecurrenceData({
      ...recurrenceData,
      repeatType: repeatTypesAll[repeatTypeValue.value - 1],
      lifespanUnit: lifespanUnitsObj[repeatTypeValue.value],
    });
    if (!isReportEnabled) {
      setReportLanguage({
        value: userLanguage,
        label: languagesArray.filter(lang => lang.value === userLanguage)[0]
          .key,
      });
    }
  };

  useEffect(() => {
    I18n.on('languageChanged', onLanguageChanged);

    return () => {
      I18n.off('languageChanged', onLanguageChanged);
    };
  }, [isReportEnabled]);

  const onValueChange = (value, key) => {
    setRecurrenceData({
      ...recurrenceData,
      [key]: value,
    });

    if (key === 'repeatType') {
      setRepeatTypeValue(value);
    }
  };

  const handleToggleButtonChanged = (labelText, checked) => {
    switch (labelText) {
      case t('newQuestion_view.templateDescription'):
        setCareplanTemplateDescriptionToggle(checked);
        break;
      case t('newCareplan_view.complex_score.toggle_title'):
        setCareplanTemplateOverallScoringToggle(checked);
        break;
      case t('newCareplan_view.reviewCaretask'):
        setCareTaskReviewAssessmentToggle(checked);
        break;
      case t('newCareplan_view.custom_recurrence.label'):
        setCustomRecurrenceToggle(checked);
        break;
      case t('newCareplan_view.internalTitle'):
        setCareTaskInternalNameToggle(checked);
        break;
      case t('common_labels.review_careplan_filesharing'):
        setFileReviewToggle(checked);
        break;
      case t('common_labels.shareOverallScoreWithPatient'):
        setShareOverAllScore(checked);
        break;
      case t('newCareplan_view.reportReview'):
        setReportReviewToggle(checked);
        break;
      case t('task.ICDReference'):
        setIcdReferenceToggle(checked);
        break;

      case t('common_labels.label_isEvaluating'):
        showPromptForAdjustScoring.checkIsEvaluationEnable = checked;
        setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
        setIsEvaluatingToggle(checked);
        break;
      case t('newCareplan_view.createReport'):
        setIsReportEnabled(checked);
        !isReportEnabled && setReportReviewToggle(false);
        break;

      default:
        break;
    }
  };

  const renderToggleButton = labelText => {
    let checked;
    let isDisabled = false;
    switch (labelText) {
      case t('newQuestion_view.templateDescription'):
        checked = careplanTemplateDescriptionToggle;
        break;
      case t('newCareplan_view.complex_score.toggle_title'):
        checked = careplanTemplateOverallScoringToggle;
        isDisabled = careTaskReviewAssessmentToggle;
        break;
      case t('newCareplan_view.reviewCaretask'):
        checked = careTaskReviewAssessmentToggle;
        break;
      case t('newCareplan_view.reportReview'):
        checked = reportReviewToggle;
        break;
      case t('newCareplan_view.internalTitle'):
        checked = careTaskInternalNameToggle;
        break;
      case t('common_labels.review_careplan_filesharing'):
        checked = fileReviewToggle;
        isDisabled = careTaskReviewAssessmentToggle;
        break;
      case t('common_labels.shareOverallScoreWithPatient'):
        checked = shareOverAllScore;
        break;
      case t('newCareplan_view.custom_recurrence.label'):
        checked = customRecurrenceToggle;
        isDisabled = careTaskReviewAssessmentToggle;
        break;
      case t('task.ICDReference'):
        checked = icdReferenceToggle;
        break;
      case t('common_labels.label_isEvaluating'):
        checked = isEvaluatingToggle;
        isDisabled = careTaskReviewAssessmentToggle;
        break;
      case t('newCareplan_view.createReport'):
        checked = isReportEnabled;
        isDisabled = careTaskReviewAssessmentToggle;
        break;
    }
    return (
      <ToggleButton
        id={labelText}
        isDisabled={isDisabled}
        checked={checked}
        handleOnChange={e =>
          handleToggleButtonChanged(labelText, e.target.checked)
        }
      />
    );
  };

  const renderIcdsComponent = () => {
    return (
      <IcdsComponent
        allIcds={allIcds}
        setAllIcds={setAllIcds}
        isRenderedOnCaretaskBuilder
      />
    );
  };

  const renderCareplanTemplateAliasContainer = () => {
    return (
      <Flex className="toggleAndTextContainer">
        <div style={{ width: '100%' }}>
          <TextInput
            value={alias}
            name="alias"
            placeholder={t('newCareplan_view.internalTitle')}
            handleOnChange={e => onChangeCareplanTemplateAlias(e)}
            maxChars={MaxCharLimits.carePlan.careplanInternalTitlecMaxCharLimit}
            variant={inputField.variant.CHAR_COUNT}
          />
        </div>
      </Flex>
    );
  };
  const reviewAssesmentToggleActivated = () => {
    setCareplanTemplateOverallScoringToggle(false);
    setCustomRecurrenceToggle(false);
    setFileReviewToggle(false);
    setIsReportEnabled(false);
    setIsEvaluatingToggle(false);
  };
  const onChangeCareplanTemplateDescription = event => {
    setTemplateDescription(event.target.value);
  };

  const onChangeCareplanTemplateAlias = event => {
    if (event.target.value.length > 75) return;
    setTemplateAlias(event.target.value);
  };

  const renderCareplanTemplateDescription = () => {
    return (
      <Flex className="toggleAndTextContainer">
        <div style={{ width: '100%' }}>
          <TextInput
            value={templateDescription}
            name="componentDescription"
            placeholder={t('newQuestion_view.addTemplateDescription')}
            variant={inputField.variant.CHAR_COUNT}
            maxLength={MaxCharLimits.carePlan.careplanDescMaxCharLimit}
            maxChars={MaxCharLimits.carePlan.careplanDescMaxCharLimit}
            handleOnChange={e => {
              onChangeCareplanTemplateDescription(e);
            }}
          />
        </div>
      </Flex>
    );
  };

  const renderLabelWithToggleButton = labelText => {
    return (
      <Flex
        className="toggleAndTextContainer"
        alignItems={labelText === t('task.ICDReference') ? null : 'flex-end'}
        otherStyles={{
          paddingBottom:
            labelText === t('newCareplan_view.complex_score.toggle_title')
              ? '8px'
              : null,
        }}
      >
        <div>
          {labelText !== t('task.phaseType') && renderToggleButton(labelText)}
        </div>
        <div>
          <Label
            text={labelText}
            isLongText={
              labelText === t('common_labels.review_careplan_filesharing') ||
              labelText === t('newCareplan_view.custom_recurrence.label') ||
              labelText === t('common_labels.label_isEvaluating') ||
              labelText === t('newCareplan_view.createReport') ||
              labelText === t('common_labels.shareOverallScoreWithPatient')
            }
          />
        </div>
      </Flex>
    );
  };

  const renderPhaseType = () => {
    const selectedPhaseTypeTemp = { ...selectedPhaseType };
    selectedPhaseTypeTemp.label = t(selectedPhaseTypeTemp.label);
    let tempPhaseTypes = [...phaseTypes];
    tempPhaseTypes = tempPhaseTypes.map(elem => {
      return {
        id: elem.id ? elem.id : elem.value,
        value: elem.value,
        label: t(elem.label),
      };
    });

    return (
      <div className="phaseStyle">
        <Dropdown
          value={selectedPhaseTypeTemp}
          options={tempPhaseTypes}
          handleOnChange={state => {
            state.label = phaseTypeMapper(state.id);
            setPhaseType(state);
          }}
          fontSize={16}
          heightControl={36}
          isDisabled={careTaskReviewAssessmentToggle}
        />
      </div>
    );
  };

  const handleCheckRadio = label => {
    if (label === t('newTemplate_view.patients')) {
      setIsCareplanOrAssessment(false);
      setCareTaskReviewAssessmentToggle(false);
    } else {
      setIsCareplanOrAssessment(true);
    }
  };

  const renderRadioButton = label => {
    const value = `${label}_${label}`;
    const id = `${value}_${label}`;
    let isChecked = false;
    const isDisabled = false;
    if (
      label === t('newTemplate_view.patients') &&
      isCareplanOrAssessment != null &&
      !isCareplanOrAssessment
    ) {
      isChecked = true;
    }
    if (
      label === t('newTemplate_view.medical') &&
      isCareplanOrAssessment != null &&
      isCareplanOrAssessment
    ) {
      isChecked = true;
    }
    return (
      <div style={{ marginBottom: '15px' }}>
        <RadioButton
          id={id}
          name={id}
          value={value}
          label={label}
          variant={CheckBoxRadioVariants.Default}
          isDisabled={isDisabled}
          inputWidth={200}
          checked={isChecked}
          handleOnChange={() => handleCheckRadio(label)}
        />
      </div>
    );
  };

  const handleRoleSelectedChange = (id, shouldBeChecked) => {
    if (selectedMedical.length) {
      const rolesCopy = [...selectedMedical];
      const roleId = rolesCopy.find(role => role.id === id);
      roleId.isChecked = shouldBeChecked;
      setSelectedMedical(rolesCopy);
    }
  };

  return (
    <div className="careplanTemplateSettingsContainer">
      <Box padding="20px">
        <Label text={t('newTemplate_view.defineAnswerCareplan')} isLongText />
      </Box>
      <Box padding="0 20px">
        {renderRadioButton(t('newTemplate_view.patients'))}
        {renderRadioButton(t('newTemplate_view.medical'))}
        <Flex
          alignItems="center"
          flexWrap="wrap"
          otherStyles={{
            margin: '24px 0px 25px 33px',
          }}
        >
          {selectedMedical.map(({ id, name, isChecked }) => (
            <Box margin="16px 0 0 1%" width="32%" key={`medical-${id}`}>
              <Checkbox
                id={id}
                name={id.toString()}
                value={name}
                label={name}
                handleOnChange={e => {
                  if (isCareplanOrAssessment === true) {
                    handleRoleSelectedChange(id, e.target.checked);
                  }
                }}
                checked={isCareplanOrAssessment === true && isChecked}
              />
            </Box>
          ))}
        </Flex>
        {hasSelectedMedicalValidationError && (
          <Flex otherStyles={{ paddingBottom: '8px' }}>
            <Box>
              <span className="templateRequired">
                {t('parsley.requiredField')}
              </span>
            </Box>
          </Flex>
        )}
      </Box>
      <span className="lineBetweenToggleContainers" />
      {isCareplanOrAssessment && (
        <>
          <Flex>
            {renderLabelWithToggleButton(t('newCareplan_view.reviewCaretask'))}
            {careTaskReviewAssessmentToggle && reviewAssesmentToggleActivated()}
          </Flex>
          <span className="lineBetweenToggleContainers" />
        </>
      )}
      <Flex>
        {renderLabelWithToggleButton(t('newCareplan_view.internalTitle'))}
        <div style={{ width: '100%' }}>
          {careTaskInternalNameToggle && renderCareplanTemplateAliasContainer()}
        </div>
      </Flex>
      <span className="lineBetweenToggleContainers" />
      <Flex>
        {renderLabelWithToggleButton(t('newQuestion_view.templateDescription'))}
        <div style={{ width: '100%' }}>
          {careplanTemplateDescriptionToggle &&
            renderCareplanTemplateDescription()}
        </div>
      </Flex>
      <span className="lineBetweenToggleContainers" />
      {renderLabelWithToggleButton(
        t('common_labels.review_careplan_filesharing')
      )}
      {subscribedServices.hasOwnProperty('icdhandlerservice.myoncare.care') && (
        <>
          <span className="lineBetweenToggleContainers" />
          <Flex>
            {renderLabelWithToggleButton(t('task.ICDReference'))}
            <div style={{ width: '100%' }}>
              {icdReferenceToggle && renderIcdsComponent()}
            </div>
          </Flex>
        </>
      )}
      <span className="lineBetweenToggleContainers" />
      <ComplexScoreSetting
        withinIntervals={withinIntervals}
        scoringFormulas={scoringFormulas}
        isEvaluatingToggle={isEvaluatingToggle}
        setScoringFormulas={setScoringFormulas}
        setWithinIntervals={setWithinIntervals}
        componentCollection={componentCollection}
        renderLabelWithToggleButton={renderLabelWithToggleButton}
        careplanTemplateOverallScoringToggle={
          careplanTemplateOverallScoringToggle
        }
      />

      {!focusScoreSelection && <span className="lineBetweenToggleContainers" />}
      <div className={focusScoreSelection ? 'focusComponent' : ''}>
        <Flex alignItems="center">
          {renderLabelWithToggleButton(t('common_labels.label_isEvaluating'))}
        </Flex>
        {isEvaluatingToggle && (
          <ScoringSchemeComponent
            isCareplanOrAssessment={isCareplanOrAssessment}
            renderLabelWithToggleButton={renderLabelWithToggleButton}
            scoringFormulas={scoringFormulas}
            careplanTemplateOverallScoringToggle={
              careplanTemplateOverallScoringToggle
            }
            scoringValues={scoringScheme}
            totalScoring={totalScore}
            onChange={setScoringScheme}
            isValid={isValidScoringScheme}
            onValidation={onValidateScoring}
            setShowPromptForAdjustScoring={setShowPromptForAdjustScoring}
            showPromptForAdjustScoring={showPromptForAdjustScoring}
            careplanComponentTypes={careplanComponentTypes}
            componentCollection={componentCollection}
            setComponentCollection={setComponentCollection}
            counter={counter}
            setCounter={setCounter}
            setSettings={setSettings}
            setTemplateSettings={setTemplateSettings}
            searchTermComponents={searchTermComponents}
            setSearchTermComponents={setSearchTermComponents}
            updateSelectedComponent={updateSelectedComponent}
            setOpenPrompt={setOpenPrompt}
            setOpenPromptForParentComponentDeletion={
              setOpenPromptForParentComponentDeletion
            }
            setTemplateNames={setTemplateNames}
            isEditTemplate={isEditTemplate}
            validationErrorTemplate={validationErrorTemplate}
            hasValidationErrorTemplate={hasValidationErrorTemplate}
            setParentId={setParentId}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
          />
        )}
      </div>
      <span className="lineBetweenToggleContainers" />

      <Flex alignItems="center">
        {renderLabelWithToggleButton(
          t('newCareplan_view.custom_recurrence.label')
        )}
        <Tooltip
          content={
            <Box width={200}>
              {t('newCareplan_view.custom_recurrence.tooltip')}
            </Box>
          }
          position={Position.bottom}
        >
          <Icon name="info" size={40} fill={Greys.DARK_GREY_70} />
        </Tooltip>
      </Flex>

      {customRecurrenceToggle && (
        <div className="recurrenceComp">
          <RecurrenceComponent
            componentType="careplanTemplate"
            onValueChange={onValueChange}
            values={recurrenceData}
            renderPhaseType={renderPhaseType}
            hasErrors={recurrenceErrors}
            setHasErrors={setRecurrenceErrors}
            comesFromTemplateSettings
            validateRecurrence={validateRecurrence}
            setStartingArrays={setStartingArrays}
            saveTrigger={saveTrigger}
          />
        </div>
      )}
      <span className="lineBetweenToggleContainers" />
      {renderLabelWithToggleButton(t('newCareplan_view.createReport'))}
      {isReportEnabled && (
        <ReportSettingsComponent
          renderLabelWithToggleButton={renderLabelWithToggleButton}
          isReportEnabled={isReportEnabled}
          occurrences={occurrences}
          setOccurrences={setOccurrences}
          reportLanguage={reportLanguage}
          setReportLanguage={setReportLanguage}
          hasThreshold={hasThreshold}
          reportReviewToggle={reportReviewToggle}
          reviewAssessments={reviewAssessments}
          reportReviewAssessment={reportReviewAssessment}
          setReportReviewAssessment={setReportReviewAssessment}
          templateResponse={templateResponse}
        />
      )}
    </div>
  );
}

export default withTranslation()(CareplanTemplateSettings);
