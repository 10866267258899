import React from 'react';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import { ButtonType } from '../../utils/button';
import { Greys } from '../../utils/colors';
import { IconButtonVariant } from '../../utils/iconButton';
import { IconButtonProps } from './IconButton.interface';

import '../../styles/main.scss';

function IconButton({
  type = ButtonType.BUTTON,
  onPointerDown,
  name,
  size = 24,
  fill = Greys.DARK_GREY,
  tooltipText,
  disabled = false,
  onClick,
  variant = IconButtonVariant.DEFAULT,
  isPlusButton = false,
  tooltipPosition = 'bottom',
}: IconButtonProps): JSX.Element {
  const tooltipCSSClass = classNames({
    'icon-btn__tooltiptext__text-plus-button': isPlusButton,
    'icon-btn__tooltiptext': !isPlusButton,
    [`icon-btn__tooltiptext-position-${tooltipPosition}`]: !isPlusButton,
  });

  const iconCSSClass = classNames({
    'icon-btn--disabled': disabled,
    'icon-btn-preview': !disabled && variant === IconButtonVariant.PREVIEW,
    'icon-btn':
      !disabled && variant !== IconButtonVariant.PREVIEW && !isPlusButton,
  });

  return (
    <button
      type={type}
      onPointerDown={onPointerDown}
      onClick={onClick}
      className="icon-btn__tooltip"
      disabled={disabled}
    >
      {tooltipText && <span className={tooltipCSSClass}>{tooltipText}</span>}
      <Icon
        name={name}
        size={size}
        fill={disabled ? Greys.LIGHT_GREY_75 : fill}
        className={iconCSSClass}
      />
    </button>
  );
}

export default IconButton;
