import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShowFlashMessage } from '@global/Services';

import { getOncoboxImportedData } from './OncoboxImportedData.api';
import { OncoboxData } from '../oncobox.interface';

const useOncoboxImportedDataService = (patientId: number) => {
  const { t } = useTranslation();
  const [oncoboxData, setOncoboxData] = useState<OncoboxData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sectionNames, setSectionNames] = useState<{ [key: string]: string }>(
    {}
  );

  const [importedDataViewerSize, setImportedDataViewerSize] = useState({
    height: 0,
    width: 0,
  });

  const importedDataContainerRef = useRef(null);
  const sectionNamesList = useMemo(
    () =>
      sectionNames &&
      typeof sectionNames !== 'undefined' &&
      Object.keys(sectionNames) &&
      Object.keys(sectionNames).length
        ? Object.keys(sectionNames)
        : [],
    [sectionNames]
  );

  useEffect(() => {
    setIsLoading(true);
    getOncoboxImportedData(patientId)
      .then(response => {
        if (response?.data) {
          setOncoboxData(response.data.response);
        }
      })
      .catch(error => {
        ShowFlashMessage({
          messageKey: error.response.data.content,
          type: error.response.data.type,
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (oncoboxData) {
      setIsLoading(false);
      let sections: { [key: string]: any };
      setSectionNames(sections);
    }
  }, [oncoboxData]);

  const screenResize = () => {
    setImportedDataViewerSize({
      height:
        window.innerHeight -
        importedDataContainerRef.current.getBoundingClientRect().top -
        40,
      width:
        window.innerWidth -
        importedDataContainerRef.current.getBoundingClientRect().left -
        importedDataContainerRef.current.getBoundingClientRect().right,
    });
  };

  useEffect(() => {
    screenResize();
    window.addEventListener('resize', screenResize);
    return () => {
      window.removeEventListener('resize', screenResize);
    };
  }, []);

  return {
    isLoading,
    importedDataContainerRef,
    importedDataViewerSize,
    sectionNamesList,
    oncoboxData,
  };
};
export default useOncoboxImportedDataService;
