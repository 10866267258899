export enum OperatorTypes {
  Equal = 'equal',
  NotEqual = 'not_equal',
  Contains = 'contains',
  DoesNotContain = 'does_not_contain',
  Less = 'less',
  LessOrEqual = 'less_or_equal',
  Between = 'between',
  NotBetween = 'not_between',
  Greater = 'greater',
  GreaterOrEqual = 'greater_or_equal',
  IsEmpty = 'is_empty',
  IsNotEmpty = 'is_not_empty',
}

export enum FilterActions {
  Push = 'push',
  Update = 'update',
  Remove = 'remove',
}
