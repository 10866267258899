import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';
import Button from '../../caro-ui-commonfiles/components/Button/Button';
import {
  ButtonVariant,
  ButtonSize,
  ButtonType,
} from '../../caro-ui-commonfiles/utils/button';
import TextInput from '../../caro-ui-commonfiles/components/TextInput/TextInput';
import Box from '../../caro-ui-commonfiles/components/Box/Box';
import FlashMessage from '../../caro-ui-commonfiles/components/FlashMessage/FlashMessage';
import NavigationJSON from '../Utils/navigation.json';
import { FirebaseContext } from '../Utils/Firebase/firebase';
import IdleTimer from '../Utils/TimerTemplate';
import logOut from '../Utils/logOut';
import checkUserPinAndOptins from '../Utils/Administration/checkUserPinAndOptins';
import '../css/login.scss';
import serviceConfig from '../../serviceConfig.json';
import LoginHeader from './Login/LoginHeader';
import LoginFooter from './Login/LoginFooter';
import CustomLoader from './Login/CustomLoader';
import { setCurrentUser } from '../../reducers/session';

const Verification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pin, setPin] = useState('');
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsgKey, setErrMsgKey] = useState('');
  const [flashMessage, setFlashMessage] = useState({});
  const {
    setIsLoggedIn,
    setUserPinAndOptinsInfo,
    userPinAndOptinsInfo,
  } = useContext(FirebaseContext);
  const { user } = useSelector(state => state.session);
  const pinFormat = /^[0-9]{6}$/;

  useEffect(() => {
    const fetchData = async () => {
      const response = await checkUserPinAndOptins(user.id);
      setUserPinAndOptinsInfo(response);
    };
    if (user) {
      fetchData();
    }
  }, []);

  if (user && userPinAndOptinsInfo) {
    if (
      userPinAndOptinsInfo.isTermsAndConditions &&
      userPinAndOptinsInfo.isPrivacyPolicy &&
      (!userPinAndOptinsInfo.isTwoFactorAuthentication ||
        (userPinAndOptinsInfo.isTwoFactorAuthentication &&
          userPinAndOptinsInfo.isPinVerified))
    ) {
      history.push(`/${NavigationJSON.PATIENT}`);
      return null;
    }
  }

  const hideFlashMessage = () => setFlashMessage({});

  const onChangePin = event => {
    setPin(event.target.value);
  };

  const handleLogOut = () => logOut(setIsLoggedIn, history, user);

  const resendPinQuery = async () => {
    setIsResendLoading(true);
    try {
      const body = {
        userId: user.id,
        userLanguage: I18n.language,
      };

      const response = await axios.post(
        serviceConfig.brokerService.resendPin.uri,
        body
      );

      if (response.status === 200 && response.data.pinResendSuccess === 'OK') {
        dispatch(setCurrentUser({ ...user, isUserRequestedResendPin: true }));

        setIsResendLoading(false);
        setFlashMessage({
          type: 'success',
          content: I18n.t('flash.pinSent'),
        });
      } else throw new Error();
    } catch (error) {
      setIsResendLoading(false);
      setFlashMessage({
        type: 'error',
        content: 'flash.unexpectedError',
      });
    }
  };

  const submitQuery = async () => {
    setIsLoading(true);
    const body = {
      userId: user.id,
      pin,
      userLanguage: I18n.language,
    };

    try {
      const pinResponse = (
        await axios.post(serviceConfig.brokerService.verifyPin.uri, body)
      ).data;
      const response = await checkUserPinAndOptins(user.id);
      setUserPinAndOptinsInfo(response);
      if (pinResponse.isBlocked) {
        await axios.get(serviceConfig.brokerService.logOut.uri);
        setIsLoggedIn(false);
        localStorage.clear();
        history.push(`/${NavigationJSON.DEACTIVATED}`);
      } else if (pinResponse.isExpired) {
        setIsLoading(false);
        setErrMsgKey('common_labels.expired_pin');
      } else if (!pinResponse.isVerified) {
        setIsLoading(false);
        setErrMsgKey('common_labels.incorrect_pin');
      } else {
        history.push(`/${NavigationJSON.PATIENT}`, {
          pinResponse: pinResponse,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setFlashMessage({
        type: 'error',
        content: 'flash.unexpectedError',
      });
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    setErrMsgKey('');
    if (!pin) {
      setErrMsgKey('common_labels.empty_verify_pin');
    } else if (!pinFormat.test(pin)) {
      setErrMsgKey('common_labels.incorrect_pin');
    } else {
      submitQuery();
    }
  };

  return (
    <>
      <IdleTimer logOut={handleLogOut} />
      <Box width="100%" height="100%">
        <div className="login-container">
          <div>
            {flashMessage.content && (
              <div className="flash-message-style">
                <FlashMessage
                  message={I18n.t(flashMessage.content)}
                  type={flashMessage.type}
                  onClick={hideFlashMessage}
                />
              </div>
            )}
          </div>

          <LoginHeader handleGoHome={handleLogOut} />

          <div className="login-content-container">
            <div className="credential-container">
              <form
                className="login__widget-panel__container"
                onSubmit={handleFormSubmit}
              >
                <div className="login-header__wrapper">
                  <span className="login-header">
                    {I18n.t('common_labels.label_verifyPin')}
                  </span>
                </div>

                <div className="login-info-container login__text-rows-spacing">
                  <span>{I18n.t('common_labels.temp_15_min_pin_msg')}</span>
                </div>

                <div className="login-info-container">
                  <span>{I18n.t('common_labels.pin_prompt_msg')}</span>
                </div>

                <div className="Text-inputContainer">
                  <TextInput
                    id="specialId"
                    name="Name"
                    placeholder={I18n.t('common_labels.placeholder_pin')}
                    hasError={!!errMsgKey}
                    rightHelperText="words"
                    iconName="message"
                    handleOnChange={onChangePin}
                    value={pin}
                    type="text"
                  />
                </div>

                {errMsgKey && (
                  <div className="err-msg">{I18n.t(errMsgKey)}</div>
                )}

                <div className="two-buttons-container">
                  {isResendLoading ? (
                    <CustomLoader variant={ButtonVariant.OUTLINED} />
                  ) : (
                    <Button
                      variant={ButtonVariant.OUTLINED}
                      label={I18n.t('common_buttons.sendNewCode')}
                      type={ButtonType.BUTTON}
                      onClick={resendPinQuery}
                      fullWidth
                      size={ButtonSize.MD}
                    />
                  )}
                  {isLoading ? (
                    <CustomLoader />
                  ) : (
                    <Button
                      label={I18n.t('common_buttons.verifyPin')}
                      type={ButtonType.SUBMIT}
                      fullWidth
                      size={ButtonSize.MD}
                    />
                  )}
                </div>
              </form>

              <LoginFooter />
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default withTranslation()(Verification);
