import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import Stepper from '../../../caro-ui-commonfiles/components/Stepper/Stepper';
import Step from '../../../caro-ui-commonfiles/components/Stepper/Step';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

const PatientOverviewModalHeader = ({ settingsStepTranslation }) => {
  const { currentStep } = useContext(PatientOverviewContext);
  return (
    <Box width="70%">
      <Stepper currentStep={currentStep}>
        <Step text={('Step 1 text', settingsStepTranslation)} />
        <Step text={('Step 2 text', I18n.t('steps.assignAndSend'))} />
      </Stepper>
    </Box>
  );
};

const PatientOverviewModalHeaderForAppointments = () => {
  const { currentStep } = useContext(PatientOverviewContext);
  return (
    <Box width="70%">
      <Stepper currentStep={currentStep}>
        <Step text={('Step 1 text', I18n.t('steps.appointment_settings'))} />
        <Step text={('Step 2 text', I18n.t('steps.associatedElements'))} />
        <Step text={('Step 3 text', I18n.t('steps.assignAndSend'))} />
      </Stepper>
    </Box>
  );
};

export {PatientOverviewModalHeader, PatientOverviewModalHeaderForAppointments};
