/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import axios from 'axios';
import moment from 'moment';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import FlexBox from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import {
  Freetext,
  NumericComponent,
  SingleChoice,
  MultipleChoice,
  Sortable,
  YesNoComponent,
  PainLocationChart,
  GroupDescriptionComponent,
  UploadFile,
  Datepicker,
  BMI,
} from './DataElements';
import serviceConfig from '../../../../serviceConfig.json';
import componentTypeOrder from '../../Careplans/careplanComponentTypeOrder';
import {
  SingleChoiceGraph,
  YesNoGraph,
  NumericGraph,
  MultipleChoiceGraph,
} from './DataGraphs';
import { PainLocationGraph } from '../../Careplans/AnswerComponents/AnswerGraphs';
import ComponentType from '../../../Utils/ComponentType';
import { parseComponentName } from '../../Careplans/CareplanHelperUtility';
import '../../../css/careplans.scss';
import { PrimaryColor, ActionColors, TagsType } from '@ui-common-files/utils';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Tag from '../../../../caro-ui-commonfiles/components/Tags/Tags';
import { nonMedical } from '../../../../config';
import { BloodPressure } from './BloodPressureDataElements';
import { getBloodPressureScoreFromObject } from '@utils/PatientOverview/getBloodPressureScoreFromObject';

const isAnswerSkipped = (componentTypeId, answers) => {
  switch (componentTypeId) {
    case componentTypeOrder.YESNO:
    case componentTypeOrder.GROUP_DESCRIPTION:
      return answers.answer_boolean === null || answers.answer_boolean === -1;
    case componentTypeOrder.INFORMATION:
      return answers.answer_boolean === null || answers.answer_boolean === -1;
    case componentTypeOrder.SINGLE_CHOICE:
      return answers.answer == null;
    case componentTypeOrder.MULTIPLE_CHOICE:
    case componentTypeOrder.SORTABLE:
      return answers.answer.length == 0;
    case componentTypeOrder.FREE_TEXT:
      if (answers.answer_text != null) {
        return (
          answers.answer_text.toLowerCase() === 'skipped' ||
          answers.answer_text.toLowerCase() === 'überspringen'
        );
      }
    case componentTypeOrder.NUMERIC_RANGE:
    case componentTypeOrder.NUMERIC_VALUE:
      return answers.answer_numeric == null;
    case componentTypeOrder.PAIN_LOCATION_CHART:
      return answers.isSkipped;
    case componentTypeOrder.UPLOAD_FILE:
      return answers.answer == null;
    case componentTypeOrder.DATEPICKER:
      return answers.answer_date == null;
    case componentTypeOrder.BMI:
      return answers.answer_bmi_height === 0 || answers.answer_bmi_weight === 0;
    default:
      return false;
  }
};

const DataCardComponent = ({ careplanComponent, index, careplanId }) => {
  const componentId = careplanComponent.careplanComponentId;
  const bloodPressurThresholdLabel = (threshold) => `${I18n.t(`bloodPressureOptions.labels.${threshold}`)}`


  const careplanCareplanComponentMapperId =
    careplanComponent.careplanCareplanComponentMapperId;
  const [dataForVisualisation, setDataForVisualisation] = useState({});
  const [openGraph, setOpenGraph] = useState(false);
  const componentTypeId = careplanComponent.careplanComponentTypeId;
  const DefaultContent = ({ careplanComponent }) => {
    return (
      <>
        <Flex flexDirection="column" otherStyles={{ marginRight: 10 }}>
          <div className="threshold-component__content" style={{}}>
            <h3 className="latest-answer">
              {I18n.t('sign_off_sheet.latestAnswer')}{' '}
              {moment(careplanComponent.timestamp)
                .locale(I18n.language)
                .format('DD MMM YYYY')}
            </h3>
            {(nonMedical == false || nonMedical == 'false') && (
              <Flex flexDirection="row" otherStyles={{ whiteSpace: "nowrap" }}>
                {careplanComponent.thresholds &&
                  careplanComponent.thresholds.length > 0 && (
                    <h3 className="latest-answer">
                      {I18n.t('threshold.thresholds')}
                    </h3>
                  )}
                {careplanComponent.thresholds &&
                  careplanComponent.thresholds.map(threshold => {
                    if (threshold) {
                      if (componentTypeId === componentTypeOrder.DATEPICKER) {
                        const thresholdValues = threshold.split(' ');
                        const unitTranslation = I18n.t(
                          `datePicker.${thresholdValues[2].split('(')[0]}`
                        );
                        threshold = `${I18n.t(
                          `common_labels.label_${thresholdValues[0]}`
                        )} ${thresholdValues[1]} ${unitTranslation}`;
                      }
                      return (
                        <Box margin="10px 0 0 10px">
                          <Tag
                            text={
                              componentTypeId === componentTypeOrder.BLOOD_PRESSURE
                                ? bloodPressurThresholdLabel(threshold) :
                              componentTypeId === componentTypeOrder.BMI
                                ? I18n.t(`bmiThresholdsOptions.${threshold}`)
                                : threshold
                            }
                            fill={ActionColors.THRESHOLD}
                            type={TagsType.SQUARED}
                          />
                        </Box>
                      );
                    }
                    return null;
                  })}
              </Flex>
            )}
            {getDefaultComponentCareplan(careplanComponent)}
          </div>
        </Flex>
        {isAnswerSkipped(componentTypeId, careplanComponent) ? (
          <h3 className="select-patient">
            {I18n.t('QuestionCatalog_view.patientSkippedQuestion')}
          </h3>
        ) : (
          <div className="threshold-component__content">
            {openGraph && renderVisualisation(careplanComponent)}
          </div>
        )}
      </>
    );
  };

  const getDefaultComponentCareplan = component => {
    switch (componentTypeId) {
      case componentTypeOrder.FREE_TEXT:
        return <Freetext component={component} />;
      case componentTypeOrder.SINGLE_CHOICE:
        return <SingleChoice component={component} />;
      case componentTypeOrder.MULTIPLE_CHOICE:
        return <MultipleChoice component={component} />;
      case componentTypeOrder.SORTABLE:
        return <Sortable component={component} />;
      case componentTypeOrder.NUMERIC_VALUE:
      case componentTypeOrder.NUMERIC_RANGE:
        return <NumericComponent component={component} />;
      case componentTypeOrder.YESNO:
        return <YesNoComponent component={component} />;
      case componentTypeOrder.PAIN_LOCATION_CHART:
        return <PainLocationChart component={component} />;
      case componentTypeOrder.GROUP_DESCRIPTION:
        return <GroupDescriptionComponent component={component} />;
      case componentTypeOrder.UPLOAD_FILE:
        return <UploadFile component={component} />;
      case componentTypeOrder.DATEPICKER:
        return <Datepicker component={component} />;
      case componentTypeOrder.BMI:
        return <BMI component={component} />;
      case componentTypeOrder.BLOOD_PRESSURE:
        return (
          <BloodPressure
            component={component}
            data={dataForVisualisation}
          />
        );
      default:
        return null;
    }
  };

  const getComponentAnswersAllDates = async (
    careplanId,
    componentId,
    careplanComponentsTemplateMapperId
  ) => {
    try {
      const response = await axios.get(
        `${serviceConfig.brokerService.getCareplanAnswersAllDates.uri +
        careplanId
        }/${componentId}/${careplanComponentsTemplateMapperId}`
      );
      if (response) {
        setDataForVisualisation(response.data);
        setOpenGraph(true);
      }
    } catch (error) {
      throw new Error(error.response);
    }
  };

  useEffect(() => {
    getComponentAnswersAllDates(
      careplanId,
      componentId,
      careplanCareplanComponentMapperId
    );
  }, [openGraph]);

  const renderVisualisation = component => {
    switch (componentTypeId) {
      case componentTypeOrder.SINGLE_CHOICE:
        return (
          <SingleChoiceGraph
            component={component}
            dataForVisualisation={dataForVisualisation}
          />
        );
      case componentTypeOrder.PAIN_LOCATION_CHART:
        return (
          <PainLocationGraph dataForVisualisation={dataForVisualisation} />
        );
      case componentTypeOrder.YESNO:
      case componentTypeOrder.GROUP_DESCRIPTION:
        return (
          <YesNoGraph
            component={component}
            dataForVisualisation={dataForVisualisation}
          />
        );
      case componentTypeOrder.NUMERIC_RANGE:
      case componentTypeOrder.NUMERIC_VALUE:
        return (
          <NumericGraph
            component={component}
            dataForVisualisation={dataForVisualisation}
          />
        );
      case componentTypeOrder.MULTIPLE_CHOICE:
      case componentTypeOrder.SORTABLE:
        return (
          <MultipleChoiceGraph
            component={component}
            dataForVisualisation={dataForVisualisation}
          />
        );
      default:
        return null;
    }
  };

  const { questionType, iconName } = ComponentType(componentTypeId);
  const { weight, careplanComponentTypeId } = dataForVisualisation;
  const hasWeights =
    dataForVisualisation.optionWeights != 'null' &&
    dataForVisualisation.optionWeights != null;
  const optionWeights =
    hasWeights && JSON.parse(dataForVisualisation.optionWeights).weights;
  const allZeroWeight = hasWeights && optionWeights.every(item => item == 0);
  let showWeight = !(
    careplanComponentTypeId === componentTypeOrder.SINGLE_CHOICE ||
    careplanComponentTypeId === componentTypeOrder.MULTIPLE_CHOICE ||
    careplanComponentTypeId === componentTypeOrder.SORTABLE ||
    careplanComponentTypeId === componentTypeOrder.BMI ||
    careplanComponentTypeId === componentTypeOrder.BLOOD_PRESSURE
  );
  let careplanComponentName =
    careplanComponentTypeId === componentTypeOrder.UPLOAD_FILE ||
    careplanComponentTypeId === componentTypeOrder.DATEPICKER ||
    careplanComponentTypeId === componentTypeOrder.BMI ||
    careplanComponentTypeId === componentTypeOrder.BLOOD_PRESSURE
      ? careplanComponent.careplanComponent.name
      : careplanComponent.careplanComponentName;
  if (careplanComponentTypeId === componentTypeOrder.UPLOAD_FILE) {
    careplanComponent.answer = dataForVisualisation.answers[0].assetId;
  }

  if (
    (weight == 0 || weight == null) &&
    (!hasWeights || (optionWeights.length && allZeroWeight))
  )
    showWeight = false;
  careplanComponent.weight = dataForVisualisation.weight;
  careplanComponent.optionWeights = dataForVisualisation.optionWeights;
  const isBloodPressureComponent =
    careplanComponentTypeId === componentTypeOrder.BLOOD_PRESSURE;
  const bloodPressureValue = dataForVisualisation.answers?.[0]?.answer_blood_pressure_value ?? 0;


  const score = isBloodPressureComponent
    ? getBloodPressureScoreFromObject(dataForVisualisation,bloodPressureValue)
    : null;
  let tagContent = null;
  if (showWeight && !isBloodPressureComponent && weight !== null) {
    tagContent = `${I18n.t("analytic_view.Score")} ${weight}`;
  } else if (isBloodPressureComponent && score !== null) {
    tagContent = `${I18n.t("analytic_view.Score")} ${score}`;
  }
  return (
    <div
      className="threshold-component"
      style={{ width: '800px', marginLeft: '20px', paddingTop: '50px' }}
    >
      <FlexBox justifyContent="space-between">
        <FlexBox>
          <div className="threshold-component__icon">
            <Icon name={iconName.toString()} size={24} />
            <span className="question-number">{index + 1}</span>
          </div>
          <div className="question-name" style={{ marginLeft: '20px' }}>
            {parseComponentName(careplanComponentName)}
          </div>
          {tagContent && (
            <div className="question-name">
              <div className="threshold-component__name">
                <Tag
                  text={tagContent}
                  fill={PrimaryColor.MAIN_COLOR}
                  type={TagsType.SQUARED}
                />
              </div>
            </div>
          )}
        </FlexBox>

        <div className="threshold-component__type">{`- ${questionType} -`}</div>
      </FlexBox>
      <DefaultContent careplanComponent={careplanComponent} />
    </div>
  );
};

export default DataCardComponent;
