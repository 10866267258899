/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18next';

import { HideFlashMessage } from '@global/Services';

import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ShowModalFooter from '../common/ShowModalFooter';
import PatientOverviewCareplanAnalyticModal from '../Analytics/PatientOverviewCareplanAnalyticModal';
import CareplanGraphcomponent from '../Analytics/CareplanGrapComponent';
 
import { MEDIA_QUERIES } from '@ui-common-files/utils/layout';

import '../../css/analytics.css';

const CareplanGraphCard = ({ data, show, onClose }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const noScoreGroph = () => (
    <div className="analytic-graph__no-data">
      <span className="analytic-graph__no-data-label">
        {data.isEvaluating === true && data.Score === null
          ? I18n.t('analytic_view.no_data')
          : I18n.t('analytic_view.no_evaluation_data')}
      </span>
    </div>
  );

  const renderContent = () => (
    <div className="graph-container-analytics" key={`graph${data.id}`}>
      {!data.showGraph && noScoreGroph()}
      <CareplanGraphcomponent data={data} />
    </div>
  );

  return (
    <div
      style={{ width: windowWidth < MEDIA_QUERIES.SCREEN_MD ? '99%' : '55%' }}
      key={`graph${data.id}`}
    >
      {show === true && (
        <Modal
          title={`${I18n.t('analytic_view.present_scores')}: ${data.name}`}
          contentComponent={
            <PatientOverviewCareplanAnalyticModal data={data} />
          }
          footerComponent={<ShowModalFooter close={onClose} />}
          openModal={show}
          onClose={onClose}
          hideFlashMessage={HideFlashMessage}
        />
      )}
      {renderContent()}
    </div>
  );
};

CareplanGraphCard.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

const mapDispatchToProps = {};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CareplanGraphCard);
