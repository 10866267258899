import React from 'react';

const PreviewFile = () => {
  if (window.isVideo) {
    return (
      <video
        muted
        playsinline
        controls="true"
        width="100%"
        height="100%"
        className="img-obj__fit"
        frameBorder="0"
        src={window.convertedUrl}
        type="video/mp4"
      />
    );
  } else {
    return (
      <div className="audio-style">
        <audio
          playsinline
          controls="true"
          frameBorder="0"
          src={window.convertedUrl}
          type="audio/mp3"
        />
      </div>
    );
  }
};

export default PreviewFile;
