import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Box, Icon } from '@ui-common-files/components';
import { Greys } from '@ui-common-files/utils';

import { EmptyStateProps } from './CareTaskList.type';

const EmptyState: React.FC = ({ iconName, textContent }: EmptyStateProps) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Icon name={iconName} fill={Greys.LIGHT_GREY_75} size={48} />
      <Box margin="16px 0 0 0">{t(textContent)}</Box>
    </Flex>
  );
};

export default EmptyState;
