const componentTypeOrder = {
  FREE_TEXT: 1,
  SINGLE_CHOICE: 2,
  MULTIPLE_CHOICE: 3,
  NUMERIC_RANGE: 4,
  NUMERIC_VALUE: 5,
  YESNO: 6,
  PAIN_LOCATION_CHART: 7,
  SORTABLE: 8,
  INFORMATION: 9,
  GROUP_DESCRIPTION: 10,
  UPLOAD_FILE: 11,
  DATEPICKER: 12,
  BMI: 13,
  BLOOD_PRESSURE: 14,
};

export default componentTypeOrder;
