import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  DataTable,
  Flex,
  Icon,
  TextInput,
} from '@ui-common-files/components';
import { Greys, inputField, MaxCharLimits } from '@ui-common-files/utils';

import { formatDate } from '@global/Date';
import { useDelaySearch } from '@global/Hooks';

import NavigationJSON from '../../Utils/navigation.json';
import { FirebaseContext } from '../../Utils/Firebase/firebase';
import { getTodos } from '../Todos/Todos.service';
import { SortConfig } from '@type/DataTable';

const Todos = ({
  hasPagesize,
  hasPagination,
  setRefreshCount,
}: {
  hasPagesize: boolean;
  hasPagination: boolean;
  setRefreshCount: () => void;
}) => {
  const { user } = useSelector(state => state.session);
  const [fetchedData, setFetchedData] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState({ value: '', offset: 0, limit: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [sort, setSort] = useState<SortConfig>({
    dir: 'asc',
    column: 'validUntil',
  });
  const { hidePatientDemographics } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const history = useHistory();
  const controller = new AbortController();

  useDelaySearch({
    input: query.value,
    callback: () => {
      if (!isInitialLoad) {
        getTodos({
          controller,
          query,
          setRecordsTotal,
          setFetchedData,
          setLoading,
          setRefreshCount,
          sort: sort,
        });
      }
    },
  });
  useEffect(() => {
    setIsInitialLoad(false);
    getTodos({
      controller,
      query,
      setRecordsTotal,
      setFetchedData,
      setLoading,
      setRefreshCount,
      sort: sort,
    });
    return () => controller.abort();
  }, [
    query.offset,
    query.limit,
    setRefreshCount,
    user.id,
    sort.column,
    sort.dir,
  ]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];

  const tableProps = {
    defaultSorted: [
      {
        id: 'record',
        desc: false,
      },
    ],
    onSortedChange: newSorted => {
      setSort(prevSort => {
        const newSortOption = newSorted[0];
        const isSameColumn = newSortOption.id === prevSort.column;
        const sortDirection = newSortOption.desc ? 'asc' : 'desc';
        const dir = isSameColumn ? sortDirection : 'asc';
        return {
          column: newSortOption.id,
          dir: dir,
        };
      });
    },
    previousText: t('datatable.previous'),
    nextText: t('datatable.next'),
    noDataText: t('common_labels.label_noData'),
    pageText: t('datatable.showing'),
    ofText: t('datatable.of'),
    rowsText: t('datatable.entries'),
    rowsSelectorText: t('datatable.recordsPerPage'),
    getTdProps: (_state, row, col) => ({
      onClick: async () => {
        if (col.id !== 'rowOptions') {
          history.push(
            `/${NavigationJSON.PATIENT_OVERVIEW}/${row.original.patientId}`,
            row.original.navigationData
          );
        }
      },
    }),
    getTrProps: () => ({
      style: {
        cursor: 'pointer',
      },
    }),
  };

  const renderSortableHeader = (columnName, columnText) => (
    <Flex>
      <Box width={20}>
        {sort.column === columnName ? (
          <Icon
            name="sortArrow"
            size={22}
            className={
              sort.column === columnName && sort.dir === 'desc'
                ? 'desc-sort'
                : ''
            }
          />
        ) : (
          <Icon name="sortArrowDefault" size={20} fill={Greys.LIGHT_GREY_50} />
        )}
      </Box>
      <Box>{columnText}</Box>
    </Flex>
  );
  const columns = [
    ...(hidePatientDemographics
      ? [
          {
            Header: t('patients_view.label_pseudonymCode'),
            accessor: 'pseudonymCode',
            sortable: false,
            filterable: false,
          },
          {
            Header: t('patients_view.label_medicalRecord'),
            accessor: 'medicalRecord',
            sortable: false,
            filterable: false,
          },
        ]
      : [
          {
            Header: t('patients_view.label_patient'),
            accessor: 'patientFullName',
            sortable: false,
            filterable: false,
          },
        ]),
    {
      Header: t('common_labels.careplan'),
      accessor: 'careplan',
      sortable: false,
      filterable: false,
    },
    {
      Header: renderSortableHeader(
        'unixEpochs',
        t('newCareplan_view.careplanStartTime')
      ),
      accessor: 'unixEpochs',
      sortable: true,
      filterable: false,
      Cell: ({ value }) => formatDate(value, 'DD MMM YYYY HH:mm'),
    },
    {
      Header: renderSortableHeader('validUntil', t('occurenceView.validUntil')),
      accessor: 'validUntil',
      sortable: true,
      filterable: false,
      Cell: ({ value }) => formatDate(value, 'D MMM YYYY HH:mm'),
    },
  ];

  return (
    <>
      <Box otherStyles={{ width: 200, position: 'absolute', top: 0, right: 0 }}>
        <TextInput
          id="specialId"
          name="Name"
          placeholder={t('common_labels.label_search')}
          variant={inputField.variant.CHAR_COUNT}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          value={query.value}
          handleOnChange={e => {
            setQuery({ value: e.target.value, offset: 0, limit: 10 });
            setCurrentPage(0);
          }}
        />
      </Box>
      <DataTable
        data={fetchedData}
        columns={columns}
        pageSize={hasPagesize}
        pageSizeOptions={items}
        pagination={hasPagination}
        isLoading={loading}
        tableProps={tableProps}
        onTableChange={(index, size) => {
          setQuery({
            value: query.value,
            offset:
              fetchedData && index * size > recordsTotal ? 0 : index * size,
            limit: size,
          });
          setCurrentPage(
            fetchedData && index * size > recordsTotal ? 0 : index
          );
        }}
        page={currentPage}
        pages={Math.ceil(recordsTotal / query.limit)}
        totalRecord={recordsTotal}
      />
    </>
  );
};
export default Todos;
