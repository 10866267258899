import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Link, useHistory } from 'react-router-dom';

import { Breadcrumb, Button } from '@ui-common-files/components';
import BreadcrumbPortal from '../AppLayout/BreadcrumbPortal/BreadcrumbPortal';

import { Greys } from '../../../caro-ui-commonfiles/utils/colors';
import serviceConfig from '../../../serviceConfig.json';
import axios from 'axios';
import ImportTemplateDropzone from './ImportTemplateDropzone';
import FlashMessage from '../../../caro-ui-commonfiles/components/FlashMessage/FlashMessage';
import NavigationJSON from '../../Utils/navigation.json';
import { removeInvalidCharacters } from '../../Global/Careplan';
import { isJson } from '../../Global/Objects/IsJson/IsJson';

import '../../css/importTemplate.css';

const ImportCareplanTemplate = () => {
  const [flashMessage, setFlashMessage] = useState({});
  const [responsive, setResponsive] = useState(false);
  const [file, setFile] = useState({
    name: '',
    data: null,
  });
  const history = useHistory();
  const [submitQuery, setSubmitQuery] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const screenSize = useCallback(() => {
    window.innerWidth <= 1366 ? setResponsive(true) : setResponsive(false);
  }, []);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  const importTemplate = async () => {
    setIsLoading(true);
    let importTemplateFlashMsg;
    if (file.data.length > 0) {
      file.data = removeInvalidCharacters(file.data);
      if (!isJson(file.data)) {
        setFlashMessage({
          type: 'error',
          content: t('newTemplate_view.invalidJson'),
        });
      } else {
        if (JSON.parse(file.data).carePathwayTemplateName) {
          try {
            const response = await axios.post(
              serviceConfig.brokerService.importCarePathwayTemplate.uri,
              {
                carePathwayTemplateData: file,
              }
            );
            if (response) {
              importTemplateFlashMsg = {
                type: response.data.type,
                content: response.data.content,
              };
            }
          } catch (error) {
            setFlashMessage({
              type: error.response.data.type,
              content: error.response.data.content,
            });
          }
          return history.push(`/${NavigationJSON.CAREPLAN}`, {
            importTemplateFlashMsg,
          });
        } else {
          try {
            const response = await axios.post(
              serviceConfig.brokerService.importCareplanTemplate.uri,
              {
                templateData: file,
              }
            );
            if (response) {
              importTemplateFlashMsg = {
                type: response.data.type,
                content: response.data.content,
              };
            }
          } catch (error) {
            setFlashMessage({
              type: error.response.data.type,
              content: error.response.data.content,
            });
          }
          return history.push(`/${NavigationJSON.CAREPLAN}`, {
            importTemplateFlashMsg,
          });
        }
      }
    } else {
      setFlashMessage({
        type: 'warning',
        content: t('newTemplate_view.emptyTemplateData'),
      });
    }
    setIsLoading(false);
  };

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setFile({ name: file.name, data: e.target.result });
      };
      reader.readAsText(file);
      return file;
    });
  }, []);

  const hideFlashMessage = () => {
    setFlashMessage({});
  };

  return (
    <>
      <BreadcrumbPortal>
        <Breadcrumb>
          <Link to={`/${NavigationJSON.CAREPLAN}`}>{t('nav.caretasks')}</Link>
          <span aria-current="page">
            {t('newTemplate_view.label_importTemplate')}
          </span>
        </Breadcrumb>
      </BreadcrumbPortal>
      {flashMessage.content && (
        <FlashMessage
          message={t(flashMessage.content)}
          type={flashMessage.type}
          onClick={hideFlashMessage}
        />
      )}
      <div className="import-template__wrapper">
        <h3 className="import-template__title">
          {t('newTemplate_view.label_importTemplate')}
        </h3>

        <div
          className={
            !file.name && submitQuery
              ? 'drag-and-drop__error'
              : 'drag-and-drop__outer'
          }
        >
          <ImportTemplateDropzone
            onDrop={onDrop}
            accept=".care"
            file={file}
            submitQuery={submitQuery}
          />
        </div>
        <h3 className="only-care-file__text">
          {t('newTemplate_view.onlyCareFile')}
        </h3>

        <span className="buttons-style">
          <Button
            label={t('common_buttons.cancel')}
            type="button"
            onClick={() => {
              history.push(`/${NavigationJSON.CAREPLAN}`);
            }}
            variant="outlined"
          />
          <Button
            label={!isLoading && t('common_buttons.import')}
            type="button"
            onClick={() => {
              if (!isLoading) {
                setSubmitQuery(true);
                file.name ? importTemplate() : null;
              }
            }}
            variant="contained"
          >
            {isLoading && (
              <Loader
                className="loader"
                type="Oval"
                color={Greys.WHITE}
                width={20}
                height={20}
              />
            )}
          </Button>
        </span>
      </div>
    </>
  );
};

export default ImportCareplanTemplate;
