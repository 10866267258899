import { formatTimestamp } from '../../../../../Global/Date';
import { ScoreChart } from './CareplanReportFirstPage.type';

export const getChartData = (
  occurrenceScores: OccurrenceScore[]
): ScoreChart[] =>
  occurrenceScores.map((occurrenc: OccurrenceScore) => {
    return {
      time: formatTimestamp(occurrenc.unixEpochs, 'MM/DD'),
      score: occurrenc.score,
    };
  });
