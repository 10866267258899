import React from 'react';

import AnsweringBloodPressureAndBMIBase from '../AnsweringBloodPressureAndBMIBase/AnsweringBloodPressureAndBMIBase';

import { AnsweringComponentsProps } from '../AnsweringTypes';
import { InputOption } from '../AnsweringBloodPressureAndBMIBase/AnsweringBloodPressureAndBMIBase.type';
import { bmiAnswerValidator } from './AnsweringBMI.validations';

const inputOptionHeight: InputOption = {
  label: 'bmiFields.height',
  unit: 'bmiFields.cm',
  name: 'height',
};

const inputOptionWeight: InputOption = {
  label: 'bmiFields.weight',
  unit: 'bmiFields.kg',
  name: 'weight',
};

export default function AnsweringBMI({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  return (
    <AnsweringBloodPressureAndBMIBase
      handleOnChange={handleOnChange}
      inputOption1={inputOptionHeight}
      inputOption2={inputOptionWeight}
      validator={bmiAnswerValidator}
    />
  );
}
