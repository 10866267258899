import React, { Children } from 'react';
import PropTypes from 'prop-types';
import BreadcrumbItem from './BreadcrumbItem';
import BreadcrumbSeparator from './BreadcrumbSeparator';
import '../../styles/main.scss';

const toBreadcrumbItem = (children, index) => {
  return (
    <BreadcrumbItem key={`breadcrumb__item${index}`}>{children}</BreadcrumbItem>
  );
};

const withSeparator = lastIndex => (acc, child, index) => {
  const notLast = index < lastIndex;

  if (notLast) {
    acc.push([child, <BreadcrumbSeparator key={`breadcrumb__sep${index}`} />]);
  } else {
    acc.push(child);
  }

  return acc;
};

const Breadcrumb = ({ children, ...props }) => {
  let allItems = Children.toArray(children);

  const totalItems = allItems.length;
  const lastIndex = totalItems - 1;

  allItems = allItems
    .map(toBreadcrumbItem)
    .reduce(withSeparator(lastIndex), []);

  return (
    <nav aria-label="Breadcrumbs">
      <ol className="breadcrumb-list" {...props}>
        {allItems}
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Breadcrumb;
