/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import I18n from 'i18next';
import { useSelector } from 'react-redux';

import { MaxCharLimits } from '@ui-common-files/utils';
import { HideFlashMessage } from '@global/Services';

import Input from '../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import inputField from '../../../../caro-ui-commonfiles/utils/inputField';
import Modal from '../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import checkUserCredential from '../../../Utils/checkUserCredential';
import serviceConfig from '../../../../serviceConfig.json';
import CarePathwayIndex from '../../Datatables/CarePathwayIndex';

import Actions from '../../../Utils/actions';
import { showCarePathwayRequest } from './CarePathwayApiCalls';
import { PromptContent, PromptFooter } from '../../common/ConfirmationPrompt';
import {
  ViewCarePathwayTemplate,
  ViewCarePathwayTemplateModalFooter,
} from '../ViewCarePathwayTemplate';
import { ViewCareplan } from '../ViewCareplan';
import ShowCareplanTemplateRequest from '../../../Utils/ShowCareplanTemplateRequest';
import {
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../../../caro-ui-commonfiles/utils/componentTypes';
import RoleHelperUtility from '../../../Utils/RoleHelperUtility.js';
import { calcCurrentPage } from '../../../Utils/DataTableUtilities';
import useAnalytics from '../../../Global/Services/Analytics/UseAnalytics';
import { ApiClient } from '../../../Global/Services';
import { FlashMessageTypes } from '../../../../caro-ui-commonfiles/types/components';

function CarePathway({
  reloadTable,
  setReloadTable,
  onEdit,
  onAssign,
  setFlashMessage,
  setShowQrCodePanel,
  borderId,
  setBorderId,
  onExport,
}) {
  const [query, setQuery] = useState({
    value: '',
    start: 0,
    length: 10,
    dir: 'asc',
    column: 'name',
  });
  const [carePathwayTemplates, setCarePathwayTemplates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageState, setPageState] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingForShow, setLoadingForShow] = useState(false);
  const [responsive, setResponsive] = useState(false);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'name',
  });
  const [deleteOperation, setDeleteOperation] = useState({
    display: false,
    id: 0,
    title: '',
  });
  const [
    deleteOperationForCareplanTemplate,
    setDeleteOperationForCareplanTemplate,
  ] = useState({
    display: false,
    carePathwayTemplateId: 0,
    templateId: 0,
    title: '',
  });

  const [action, setAction] = useState('');
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [ownershipLogs, setOwnershipLogs] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalForTemplate, setDisplayModalForTemplate] = useState(false);
  const [carePathwayTemplatesForShow, setCarePathwayTemplatesForShow] =
    useState({});
  const [
    carePathwayTemplatesWithThresholdsForShow,
    setCarePathwayTemplatesWithThresholdsForShow,
  ] = useState([]);
  const [careplanDataThresholds, setCareplanDataThresholds] = useState({});
  const [careplanDataLogicJumps, setCareplanDataLogicJumps] = useState({});
  const [careplanScoringScheme, setCareplanScoringScheme] = useState({});
  const [typingTimeOut, setTypingTimeOut] = useState(0);
  const [showData, setShowData] = useState({});
  const { trackEventTrigger } = useAnalytics();

  const triggerTrackingForResponse = (category: string, action: string) => {
    trackEventTrigger({
      category,
      action,
    });
  };
  const { user } = useSelector(state => state.session);

  const openShowModal = carePathwayId => {
    trackEventTrigger({ category: 'carepathway', action: 'Open-Show-Modal' });
    setId(carePathwayId);
    setDisplayModal(true);
  };

  const openShowModalForTemplate = templateId => {
    setId(templateId);
    setDisplayModalForTemplate(true);
  };

  const fetchCarePathwayWithTemplates = () => {
    setLoading(true);
    ApiClient.GET({
      url: serviceConfig.brokerService.getAllCarePathwayTemplates.uri,
      payload: {
        queryData: query,
      },
      timeout: 30000,
    })
      .then(result => {
        const dataCarepathway = [];
        const carepathwayTemplatesLength = result.data.data.length;
        const carepathwayTemplates = result.data.data;
        for (let index = 0; index < carepathwayTemplatesLength; index++) {
          const { id, icds, uuid, careplanTemplates } =
            carepathwayTemplates[index];
          const pack = {
            id,
            name: `${carepathwayTemplates[index].name}`,
            allFutureCareplans: carepathwayTemplates[index].allFutureCareplans,
            icds,
            carepathwayTemplateUUID: uuid,
            careplanTemplates,
          };
          dataCarepathway.push(pack);
        }
        setCarePathwayTemplates(dataCarepathway);
        setRecordsTotal(result.data.recordsTotal);
      })
      .catch(error => {
        setFlashMessage({
          type: error.response?.data.type,
          content: error.response?.data.content,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onShow = async (carePathwayId, carePathwayTitle) => {
    trackEventTrigger({
      category: 'carepathway',
      action: `Click-Show-Button`,
    });
    const hasShowCarePathwayCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPATHWAY_TEMPLATE,
      ActionTranslationKeys.SHOW
    );

    const hasShowCareplanCredential = await checkUserCredential(
      ComponentTranslationKeys.CAREPLAN_TEMPLATE,
      ActionTranslationKeys.SHOW
    );

    if (
      hasShowCarePathwayCredential === true &&
      hasShowCareplanCredential === true
    ) {
      setAction(Actions.Show);
      setId(carePathwayId);
      openShowModal(carePathwayId);
      showCarePathwayRequest(
        carePathwayId,
        setFlashMessage,
        setCarePathwayTemplatesForShow,
        setCarePathwayTemplatesWithThresholdsForShow,
        setOwnershipLogs,
        setLoadingForShow,
        closeModal,
        triggerTrackingForResponse
      );
      setTitle(
        `${I18n.t(
          'newCareplan_view.view_carePathwayTemplate'
        )} ${carePathwayTitle}`
      );
    } else if (hasShowCarePathwayCredential.type === FlashMessageTypes.Error) {
      setFlashMessage({
        type: hasShowCarePathwayCredential.type,
        content: hasShowCarePathwayCredential.content,
      });
    } else {
      setFlashMessage({
        type: 'warning',
        content: I18n.t('common_labels.permissionDenied'),
      });
    }
  };

  const onTemplateShow = (templateId, templateName) => {
    setAction(Actions.Show);
    setId(templateId);

    ShowCareplanTemplateRequest(
      templateId,
      setCareplanDataThresholds,
      setShowData,
      setCareplanDataLogicJumps,
      setCareplanScoringScheme,
      setOwnershipLogs,
      setIsEvaluating
    ).then(() => {
      openShowModalForTemplate(templateId);
    });
    setTitle(`${I18n.t('newCareplan_view.view')} ${templateName}`);
  };

  const checkUserReadPermissionForPathwayTemplate = async () => {
    return await RoleHelperUtility.getLoggedInUserRolePermissions(
      user,
      I18n.t(ComponentTranslationKeys.CAREPATHWAY_TEMPLATE),
      I18n.t(ActionTranslationKeys.DELETE)
    );
  };

  const onQrCode = async (
    carePathwayId,
    carePathwayTitle,
    carepathwayTemplateUUID,
    allFutureCareplans
  ) => {
    setBorderId(carePathwayId);
    trackEventTrigger({
      category: 'carepathway',
      action: 'Open-QrCode-Modal',
    });
    setShowQrCodePanel({
      id: carePathwayId,
      name: carePathwayTitle,
      carepathwayTemplateUUID,
      shouldShow: true,
      allFutureCareplans,
    });
  };

  const onDelete = async (carePathwayId, carePathwayTitle) => {
    trackEventTrigger({
      category: 'carepathway',
      action: 'Click-Delete-Button',
    });
    const checkPermission = await checkUserReadPermissionForPathwayTemplate();
    if (checkPermission.allowed) {
      trackEventTrigger({
        category: 'carepathway',
        action: 'Open-Delete-Modal',
      });
      setDeleteOperation({
        display: true,
        id: carePathwayId,
        title: `${I18n.t(
          'common_labels.msg_confirm_carePathwayTemplate_delete_headline'
        )}: ${carePathwayTitle}`,
      });
    } else {
      trackEventTrigger({
        category: 'carepathway',
        action: 'Delete-Load-Failed',
      });
      setFlashMessage({
        type: 'warning',
        content: I18n.t('common_labels.permissionDenied'),
      });
    }
  };

  const onDeleteCareplanTemplate = async (
    carePathwayTemplateId,
    templateId,
    templateName,
    occurrenceId
  ) => {
    trackEventTrigger({
      category: 'carepathway',
      action: 'Click-Confirm-Delete',
    });
    const checkPermission = await checkUserReadPermissionForPathwayTemplate();
    if (checkPermission.allowed) {
      setDeleteOperationForCareplanTemplate({
        display: true,
        carePathwayTemplateId,
        templateId,
        occurrenceId,
        title: `${I18n.t(
          'common_labels.msg_confirm_template_delete_headline'
        )}: ${templateName}`,
      });
      trackEventTrigger({
        category: 'carepathway',
        action: 'Delete-Successful',
      });
    } else {
      setFlashMessage({
        type: 'warning',
        content: I18n.t('common_labels.permissionDenied'),
      });
      trackEventTrigger({
        category: 'carepathway',
        action: 'Delete-Failed',
      });
    }
  };

  const screenSize = useCallback(() => {
    const isResponsive = window.innerWidth <= 1366;
    setResponsive(isResponsive);
  }, [responsive]);

  useEffect(() => {
    screenSize();
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  useEffect(() => {
    if (typingTimeOut > 0) clearTimeout(typingTimeOut);
    if (query.value.length === 0) {
      fetchCarePathwayWithTemplates();
    } else if (query.value.length > 1) {
      const timeOut = setTimeout(async () => {
        fetchCarePathwayWithTemplates();
      }, 500);
      setTypingTimeOut(timeOut);
    }
    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [reloadTable, query]);

  useEffect(() => {
    setQuery({
      start: currentPage * pageState,
      length: pageState,
      dir: sort.dir,
      column: sort.column,
      value: query.value,
    });
  }, [currentPage, pageState, sort]);

  I18n.on('languageChanged', () => {
    setReloadTable(!reloadTable);
  });

  const renderModalContent = () => {
    switch (action) {
      case Actions.Show:
        return (
          <ViewCarePathwayTemplate
            closeModal={closeModal}
            id={id}
            carePathwayTemplatesForShow={carePathwayTemplatesForShow}
            carePathwayTemplatesWithThresholdsForShow={
              carePathwayTemplatesWithThresholdsForShow
            }
            careplanDataLogicJumps={careplanDataLogicJumps}
            ownershipLogs={ownershipLogs}
            loading={loadingForShow}
          />
        );
      default:
        return <div />;
    }
  };

  const renderModalContentForTemplate = () => {
    switch (action) {
      case Actions.Show:
        return (
          <ViewCareplan
            closeModal={closeModal}
            id={id}
            showData={showData}
            isEvaluating={isEvaluating}
            careplanLogicJumpData={careplanDataLogicJumps}
            careplanData={careplanDataThresholds}
            ownershipLogs={ownershipLogs}
            careplanScoringScheme={careplanScoringScheme}
          />
        );
      default:
        return <div />;
    }
  };

  const renderModalFooter = () => {
    switch (action) {
      case Actions.Show:
        return <ViewCarePathwayTemplateModalFooter close={closeModal} />;
      default:
        return <div />;
    }
  };

  const closeModal = () => {
    trackEventTrigger({
      category: 'Modal',
      action: `Close-modal`,
    });
    setDisplayModal(false);
    setDisplayModalForTemplate(false);
    setIsEvaluating(false);
    setCarePathwayTemplatesForShow({});
  };

  const closeMessagePrompt = () => {
    trackEventTrigger({
      category: 'carepathway',
      action: 'Close-Delete-Modal',
    });
    setDeleteOperation({
      display: false,
      id: 0,
      title: '',
    });
    closeMessagePromptForCareplanTemplate();
  };

  const closeMessagePromptForCareplanTemplate = () => {
    setDeleteOperationForCareplanTemplate({
      display: false,
      carePathwayTemplateId: 0,
      templateId: 0,
      title: '',
    });
  };

  const deleteCarePathwayTemplate = id => {
    ApiClient.GET({
      url: serviceConfig.brokerService.deleteCarePathwayTemplate.uri + id,
    })
      .then(response => {
        const page = calcCurrentPage(recordsTotal - 1, pageState, currentPage);
        if (page !== currentPage) {
          setCurrentPage(page);
        } else {
          setReloadTable(!reloadTable);
        }
        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
        setDeleteOperation({
          display: false,
          id: 0,
          title: '',
        });
        trackEventTrigger({
          category: 'carepathway',
          action: 'Delete-Successful',
        });
      })
      .catch(error => {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });
  };

  const deleteCareplanTemplate = (
    carePathwayTemplateId,
    templateId,
    occurrenceId
  ) => {
    ApiClient.POST({
      url:
        serviceConfig.brokerService.deleteCarePathwayTemplateAssociation.uri +
        carePathwayTemplateId,
      payload: { templateId, occurrenceId },
    })
      .then(response => {
        setReloadTable(!reloadTable);
        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
        setDeleteOperationForCareplanTemplate({
          display: false,
          carePathwayTemplateId: 0,
          templateId: 0,
          title: '',
        });
      })
      .catch(error => {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });
  };

  return (
    <>
      <div style={{ width: 250, position: 'absolute', top: 0, right: 0 }}>
        <Input
          id="careplanTemplateSearch"
          placeholder={I18n.t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={query.value}
          handleOnChange={e => {
            setQuery({
              value: e.target.value,
              start: 0,
              length: 10,
              dir: sort.dir,
              column: sort.column,
            });
            setCurrentPage(0);
            setPageState(10);
          }}
        />
      </div>
      <CarePathwayIndex
        onChange={(index, size) => {
          setCurrentPage(
            carePathwayTemplates && index * size > recordsTotal ? 0 : index
          );
          setPageState(size);
        }}
        loading={loading}
        fetchedData={carePathwayTemplates}
        currentPage={currentPage}
        recordsTotal={carePathwayTemplates ? recordsTotal : 0}
        pages={
          carePathwayTemplates ? Math.ceil(recordsTotal / query.length) : 1
        }
        sort={sort}
        setSort={setSort}
        onShow={onShow}
        onDelete={onDelete}
        onDeleteCareplanTemplate={onDeleteCareplanTemplate}
        onTemplateShow={onTemplateShow}
        onEdit={onEdit}
        onAssign={onAssign}
        onQrCode={onQrCode}
        borderId={borderId}
        onExport={onExport}
        setFlashMessage={setFlashMessage}
      />
      <Modal
        title={title}
        contentComponent={renderModalContent()}
        footerComponent={renderModalFooter()}
        openModal={displayModal}
        onClose={closeModal}
        hideFlashMessage={HideFlashMessage}
      />

      <Modal
        title={title}
        contentComponent={renderModalContentForTemplate()}
        footerComponent={renderModalFooter()}
        openModal={displayModalForTemplate}
        onClose={closeModal}
        hideFlashMessage={HideFlashMessage}
      />
      {deleteOperation.display && (
        <Modal
          isConfirmationDialog
          title={deleteOperation.title}
          contentComponent={
            <PromptContent
              message={I18n.t(
                'common_labels.msg_confirm_carePathwayTemplate_delete'
              )}
            />
          }
          footerComponent={
            <PromptFooter
              close={closeMessagePrompt}
              confirmHandler={() => {
                if (deleteOperation.id > 0) {
                  deleteCarePathwayTemplate(deleteOperation.id);
                }
              }}
            />
          }
          openModal={deleteOperation.display}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}

      {deleteOperationForCareplanTemplate.display && (
        <Modal
          isConfirmationDialog
          title={deleteOperationForCareplanTemplate.title}
          contentComponent={
            <PromptContent
              message={I18n.t('common_labels.msg_confirm_template_delete')}
            />
          }
          footerComponent={
            <PromptFooter
              close={closeMessagePrompt}
              confirmHandler={() => {
                if (
                  deleteOperationForCareplanTemplate.carePathwayTemplateId >
                    0 &&
                  deleteOperationForCareplanTemplate.templateId > 0
                ) {
                  deleteCareplanTemplate(
                    deleteOperationForCareplanTemplate.carePathwayTemplateId,
                    deleteOperationForCareplanTemplate.templateId,
                    deleteOperationForCareplanTemplate.occurrenceId
                  );
                }
              }}
            />
          }
          openModal={deleteOperationForCareplanTemplate.display}
          onClose={closeMessagePromptForCareplanTemplate}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </>
  );
}
export default CarePathway;
