import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  CarePathWayBuilderState,
  CarePathWayBuilderActions,
} from './CarePathWayBuilder.type';
import { STORE_NAME } from './CarePathWayBuilder.constant';

import {
  addNode,
  addError,
  onConnect,
  edgeUpdate,
  removeEdge,
  resetErrors,
  setCareTask,
  removeAnError,
  onEdgesChange,
  onNodesChange,
  hasErrorBasedOnId,
  setIsAddingParent,
  updateSidebarVisibility,
  setSettings,
  setPathWayReview,
  transformPathWayObject,
  setPathWayTemplate,
  resetBuilder,
} from './CarePathWayBuilder.actions';

const initialState: CarePathWayBuilderState = {
  sidebar: {
    isVisible: false,
    contentType: '',
    configurationNodeId: '',
  },
  isAddingParent: true,
  careTask: {
    isLoading: true,
    keyword: '',
    items: null,
    totalRecords: 0,
    page: 1,
  },
  nodes: [],
  edges: [],
  settings: {
    carePathwayName: '',
    appointmentType: null,
    icds: [],
  },
  pathWayReview: {
    isOpen: false,
    title: null,
    id: null,
    wizard: null,
  },
  errors: [],
};

export const useCarePathWayBuilderStore = create<
  CarePathWayBuilderState & CarePathWayBuilderActions
>()(
  devtools(
    (set, get) => ({
      ...initialState,
      updateSidebarVisibility: updateSidebarVisibility(set, get),
      setIsAddingParent: setIsAddingParent(set),
      setCareTask: setCareTask(set),
      addNode: addNode(set, get),
      edgeUpdate: edgeUpdate(set, get),
      removeEdge: removeEdge(set, get),
      onNodesChange: onNodesChange(set, get),
      onEdgesChange: onEdgesChange(set, get),
      onConnect: onConnect(set, get),
      addError: addError(set, get),
      removeAnError: removeAnError(set, get),
      resetErrors: resetErrors(set),
      hasErrorBasedOnId: hasErrorBasedOnId(get),
      setSettings: setSettings(set, get),
      setPathWayReview: setPathWayReview(set),
      transformPathWayObject: transformPathWayObject(get),
      setPathWayTemplate: setPathWayTemplate(set),
      resetBuilder: resetBuilder(set, initialState),
    }),
    { name: STORE_NAME }
  )
);
