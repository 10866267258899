import React from 'react';
import { Box, Modal } from '@ui-common-files/components';
import { HideFlashMessage } from '@global/Services';
import ErrorList from './ErrorList';

const ShowPatientErrorLog: React.FC = ({ onClose, errorData }) => {
  const { carePlanName } = errorData;

  const sessionsError =
    errorData.errorData && errorData.errorData.sessionsError;

  return (
    <Modal
      title={carePlanName}
      contentComponent={
        <Box otherStyles={{ overflowX: 'auto', marginLeft: '10px' }}>
          <ErrorList sessionErrors={sessionsError} />
        </Box>
      }
      openModal={true}
      onClose={() => {
        onClose();
      }}
      hideFlashMessage={HideFlashMessage}
    />
  );
};
export default ShowPatientErrorLog;
