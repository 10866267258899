import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import CloseToastButton from './CloseToastButton';
import Position from '../../utils/positions';

import 'react-toastify/dist/ReactToastify.css';

// Component simulating page (root) implementation
// maybe a prototype that can be used on stories?
// reference: https://codesandbox.io/s/93w3z0p7y4?from-embed

const ToastNotification = ({
  position,
  autoClose,
  transition,
  newestOnTop,
  pauseOnHover,
  pauseOnFocusLoss,
  draggable,
  draggablePercent,
}) => {
  return (
    <ToastContainer
      closeButton={<CloseToastButton />}
      position={position}
      autoClose={autoClose}
      hideProgressBar={true}
      transition={transition}
      newestOnTop={newestOnTop}
      pauseOnHover={pauseOnHover}
      pauseOnFocusLoss={pauseOnFocusLoss}
      draggable={draggable}
      draggablePercent={draggablePercent}
      role="threshold"
      theme="colored"
    />
  );
};

ToastNotification.propTypes = {
  position: PropTypes.oneOf([
    Position.topRight,
    Position.topCenter,
    Position.topLeft,
    Position.bottomRight,
    Position.bottomCenter,
    Position.bottomLeft,
  ]),
  /** False or number. Delay in ms to close the toast. If set to false, the notification need to be closed manually */
  autoClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]), // get validation fn from library (falseOrDelay)
  /** Bounce is used by default but you can replace it by your own transition or by one from the library: Slide, Zoom, Flip or Bounce */
  transition: PropTypes.func,
  newestOnTop: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  /** Pause the timer when the window loses focus */
  pauseOnFocusLoss: PropTypes.bool,
  /** Allow toast to be draggable */
  draggable: PropTypes.bool,
  /** The percentage of the toast's width it takes for a drag to dismiss a toast */
  draggablePercent: PropTypes.number,
};

ToastNotification.defaultProps = {
  position: Position.topRight,
  autoClose: 5000,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
  draggablePercent: 80,
};

export default ToastNotification;
