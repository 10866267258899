import React, { useEffect, useCallback, useRef, useState } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

import { Box, InfiniteScroll } from '@ui-common-files/components';
import { PrimaryColor } from '@ui-common-files/utils';

import NoteListSection from './NoteListSection';

const NoteList = ({
  notes,
  isSearched,
  fileRef,
  isUploading,
  isLoading,
  getNotesHistory,
  numOfNotes,
}) => {
  const notesEnd = useRef();
  const notesLoad = useRef();
  const noteContainerRef = useRef();
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);

  const scrollToEnd = (ref = notesEnd) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const prevNotes = usePrevious(notes);

  const renderMessageBubble = useCallback(() => {
    return (
      <NoteListSection
        key="section_notes"
        numberOfNotes={notes.length}
        listOfNotes={notes}
        fileRef={fileRef}
        noteRef={notesLoad}
        prevNote={prevNotes !== undefined ? notes.length - prevNotes.length : 0}
      />
    );
  }, [notes, prevNotes]);

  const renderLoadingWheel = () => {
    return (
      <div style={{ textAlign: 'center', marginTop: 0 }}>
        <Loader
          type="Oval"
          color={PrimaryColor.MAIN_COLOR}
          height={40}
          width={40}
        />
      </div>
    );
  };

  const loadPreviousNotes = () => {
    if (numOfNotes > notes.length) {
      setIsLoadingPrevious(true);
      getNotesHistory(notes.length);
    }
  };

  useEffect(() => {
    if (prevNotes && notes.length > prevNotes.length && !isLoadingPrevious) {
      const resizeObserver = new ResizeObserver(() => {
        scrollToEnd(notesEnd);
      });

      if (noteContainerRef.current) {
        resizeObserver.observe(noteContainerRef.current);
      }

      return () => {
        if (noteContainerRef.current) {
          resizeObserver.disconnect();
        }
      };
    }
  }, [notes, prevNotes, isLoadingPrevious]);

  useEffect(() => {
    scrollToEnd(
      prevNotes === undefined ||
        (notes.length - prevNotes.length === 1 && notes.length < numOfNotes)
        ? notesEnd
        : notesLoad
    );
  }, [notes]);

  useEffect(() => {
    if (isLoadingPrevious && notes.length > prevNotes.length) {
      setIsLoadingPrevious(false);
    }
  }, [notes, prevNotes]);

  useEffect(() => {
    if (isUploading) {
      scrollToEnd(notesEnd);
    }
  }, [isUploading]);

  return (
    <InfiniteScroll
      onScrollUp={loadPreviousNotes}
      onScrollDown={() => {}}
      loadingTop={isLoading}
      loadingBottom={false}
      loadingComponent={() => {}}
    >
      <>
        {isLoading && renderLoadingWheel()}
        {isSearched && notes.length === 0 ? (
          <p className="note-searchbar__no-data">{I18n.t('notes.no_data')}</p>
        ) : (
          <Box ref={noteContainerRef}>{renderMessageBubble()}</Box>
        )}
        <div ref={notesEnd} className="notes-list__end" />
        {isUploading && (
          <div style={{ textAlign: 'center', margin: '19px 0 8px 0' }}>
            <Loader
              type="Oval"
              color={PrimaryColor.MAIN_COLOR}
              height={36}
              width={36}
            />
          </div>
        )}
      </>
    </InfiniteScroll>
  );
};

NoteList.propTypes = {
  notes: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
  isSearched: PropTypes.bool.isRequired,
  fileRef: PropTypes.objectOf(PropTypes.object).isRequired,
  isUploading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default React.memo(NoteList);
