/* eslint-disable import/no-mutable-exports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import {
  getWearablesForCareplanRequest,
  resetWearableData,
  getWearablesActivityStatusRequest,
} from '../../../actions/wearables';

import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';

import {
  WearableUnitTranslations,
  WearablesDataTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { wearablesDataTypesId } from '../../../caro-ui-commonfiles/global/constants';

import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';

import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import PatientContent from './PatientContent';
import ActivityButton from '../Wearables/ActivityButtonsCareplanModal';
import ActivityGraph from '../Wearables/ActivityDataChart';
import { getHeartRateValue } from '../Wearables/WearablesUtility';

const PatientOverviewCareplanAnalyticModal = ({ data }) => {
  const wearablesReduxState = useSelector(state => state.wearables);
  const reduxDispatcher = useDispatch();
  const { patientId } = data;

  const [graphData, setGraphData] = useState([]);
  const [activity, setActivity] = useState(0);
  moment.locale(I18n.language.trim());

  useLayoutEffect(() => {
    reduxDispatcher(
      getWearablesActivityStatusRequest({
        patientId,
      })
    );
    return () => reduxDispatcher(resetWearableData());
  }, []);

  useEffect(() => {
    const getActivityData = () => {
      if (activity > 0 && data.graphData.length > 0) {
        const endDate =
          moment().unix() <= data.graphData[data.graphData.length - 1].unix
            ? moment().format('YYYY-MM-DD HH:mm:ss')
            : moment
                .unix(data.graphData[data.graphData.length - 1].unix)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss');
        const startDate = moment
          .unix(data.graphData[0].unix)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
        reduxDispatcher(
          getWearablesForCareplanRequest({
            patientId,
            dataType: activity,
            startDate,
            endDate,
          })
        );
      } else {
        reduxDispatcher(resetWearableData());
        setGraphData([]);
      }
    };
    getActivityData();
  }, [activity]);

  useEffect(() => {
    const processGraphData = async () => {
      if (data.graphData.length > 0 && graphData.length === 0) {
        const activityData = wearablesReduxState.wearablesForCareplanResponse;
        const careplanScores = data.graphData.map(occurrence => {
          const graphItemData = {
            xLabel: moment.unix(occurrence.unix).format('MMM DD'),
            line1: occurrence.score,
          };
          if (activityData && activityData.fetchedData.length > 0) {
            const line2 = activityData.fetchedData.find(
              act =>
                moment(act.measurementDate).format('YYYY-MM-DD') ===
                moment.unix(occurrence.unix).format('YYYY-MM-DD')
            );
            if (line2) {
              if (activity === WearablesDataTypes.HEART_RATE) {
                const { line, error } = getHeartRateValue(
                  line2.measurementValue
                );
                graphItemData.line2 = line;
                graphItemData.error = error;
              } else {
                graphItemData.line2 = parseFloat(
                  parseFloat(line2.measurementValue).toFixed(2)
                );
              }
            }
          }
          return graphItemData;
        });
        setGraphData(careplanScores);
      }
    };
    processGraphData();
  }, [data, wearablesReduxState]);

  const renderGraphComparison = () => (
    <Box margin="0 24px">
      <ActivityGraph
        data={graphData}
        history={{
          line1: { text: data.name, color: PrimaryColor.MAIN_COLOR, type: 1 },
          line2:
            activity !== 0
              ? {
                  text: I18n.t(
                    WearableUnitTranslations[
                      `${wearablesDataTypesId[`${activity}`]}`
                    ]
                  ),
                  color: PrimaryColor.MAIN_COLOR_DARK,
                  type: activity,
                }
              : {},
        }}
      />
    </Box>
  );

  return (
    <ModalContent>
      <div
        className="careplan-graph-card"
        style={{ backgroundColor: '#ffffff' }}
      >
        <PatientContent careplanName={data.name} />
        <Flex flexDirection="column">
          <ActivityButton
            active={activity}
            onSelect={act => {
              setGraphData([]);
              setActivity(act);
            }}
            dataTypes={
              wearablesReduxState.wearablesActivityStatusResponse &&
              wearablesReduxState.wearablesActivityStatusResponse.activities
            }
          />
          {renderGraphComparison()}
        </Flex>
      </div>
    </ModalContent>
  );
};

PatientOverviewCareplanAnalyticModal.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default PatientOverviewCareplanAnalyticModal;
