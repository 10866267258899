const calcCurrentPage = (recordsTotal, pageSize, page) => {
  if (recordsTotal > 0) {
    const pageLength = Math.ceil(recordsTotal / pageSize);
    if (page + 1 > pageLength) {
      return pageLength - 1;
    }
    return page;
  }
  return 0;
};

export { calcCurrentPage };
