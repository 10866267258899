import serviceConfig from '../../../serviceConfig.json';
import { ApiClient } from '../../Global/Services';

async function retrieveOptIns(currenUser) {
  try {
    const response = await ApiClient.GET({
      url: serviceConfig.brokerService.retrieveOptIns.uri + currenUser,
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    return {
      content: error.response.data.content,
      type: error.response.data.type,
    };
  }
}

export default retrieveOptIns;