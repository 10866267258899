import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HistorialStep = ({ children, userSession, type = 'default' }) => {
  let className;
  className = classNames({
    historial__step__indicator: true,
    'historial__step__indicator--default': type === 'default',
    'historial__step__indicator--active': type === 'active',
    'historial__step__indicator--poor': type === 'poor',
    'historial__step__indicator--fair': type === 'fair',
    'historial__step__indicator--well': type === 'well',
  });
  if (
    userSession != undefined &&
    userSession != 'undefined' &&
    Object.keys(userSession).length > 0
  ) {
    className =
      userSession.isActive || userSession.action == 'action.consent'
        ? 'historial__step__indicator historial__step__indicator--default'
        : 'historial__step__indicator historial__step__indicator--active';
  }
  return (
    <div className="historial__step">
      <div className={className} />
      <div className="historial__step__content">{children}</div>
    </div>
  );
};

HistorialStep.propTypes = {
  children: PropTypes.any,
};

export default HistorialStep;
