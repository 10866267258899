import React, { useState, useEffect, useRef } from 'react';
import I18n from 'i18next';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import moment from 'moment';
import serviceConfig from '../../../serviceConfig.json';
import ModalFooter from '../common/Layout/Modal/ModalFooter';
import { ShowFlashMessage } from '@global/Services';
import { FlashMessageTypes } from '@ui-common-files/types';

const OncoboxExportModalFooter = ({
  closeModal,
  setNbrPatientsSelected,
  selectedPatientsData,
  setSelectedPatientsData,
  setIsLoading,
  isLoading,
}) => {
  const downloadTrigger = useRef(null);

  const [clicked, setClicked] = useState(false);
  const [exportedData, setExportedData] = useState([]);
  const patients = selectedPatientsData.map(
    (patient: { id: number }) => patient.id
  );
  const exportResults = async () => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        const response = await axios.post(
          serviceConfig.brokerService.downloadPatientOncoboxCSV.uri,
          {
            timeout: 180000,
            data: patients,
          }
        );
        if (response) {
          if (response.data.length) {
            setExportedData(response.data);
          }
          setIsLoading(false);
          closeModal();
          setClicked(false);
          setSelectedPatientsData([]);
          setNbrPatientsSelected(0);
          if (response.data.content) {
            let hideLeftIcon = false;
            if (response.data.type === 'warning') {
              hideLeftIcon = true;
            }
            ShowFlashMessage({
              type: response.data.type,
              message: I18n.t(response.data.content),
              autoHide: false,
              isModalMessage: false,
            });
          } else {
            ShowFlashMessage({
              type: FlashMessageTypes.Success,
              message: I18n.t('flash.exportCsvFileProgressFinished'),
              autoHide: false,
              isModalMessage: false,
            });
          }
        }
      }
    } catch (error) {
      closeModal();
      ShowFlashMessage({
        type: FlashMessageTypes.Error,
        message: I18n.t('common_labels.oncobox_service_error'),
        isModalMessage: false,
      });
    }
  };

  useEffect(() => {
    if (downloadTrigger.current && exportedData.length > 0)
      downloadTrigger.current.link.click();
  }, [exportedData.length]);

  return (
    <ModalFooter
      close={() => {
        closeModal();
      }}
      additionalFunctionality={
        <CSVLink
          ref={downloadTrigger}
          style={{ display: 'none' }}
          filename={`${moment().format('YYYY-MM-DD')}.csv`}
          data={exportedData}
        />
      }
      labelCTA={I18n.t('common_buttons.export')}
      form="form__export-results-patients"
      onClickCTA={exportResults}
      disabled={isLoading}
      isOncoBox={true}
    />
  );
};

export default OncoboxExportModalFooter;
