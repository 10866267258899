import React from 'react';

import { Flex } from '@ui-common-files/components';

import useDataPointItemService from './DataPointItem.service';

const DataPointItem = ({
  dataPointName,
  dataPointUnit,
  lineColor,
  value,
  isCircular = false,
}) => {
  const {
    containerClass,
    getIndicatorStyles,
    getLabel,
  } = useDataPointItemService(dataPointUnit, isCircular);

  if (value === undefined) return null;

  const indicatorStyles = getIndicatorStyles(lineColor);
  const label = getLabel(dataPointName, dataPointUnit);

  return (
    <Flex alignItems="baseline" className={containerClass}>
      <Flex otherStyles={indicatorStyles} />
      <Flex className="overview-custom-tooltip_data-points">
        {value}
        <span className="overview-custom-tooltip_data-points_label">
          {label}
        </span>
      </Flex>
    </Flex>
  );
};

export default DataPointItem;
