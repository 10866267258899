import axios from 'axios';
import serviceConfig from '../../serviceConfig.json';

function checkPermissions() {
  return axios.get( serviceConfig.brokerService.getReadPermissionsByRoles.uri)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return {
        type: error.response.data.type,
        content: error.response.data.content,
      };
    });
}

export default checkPermissions;
