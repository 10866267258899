import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useTranslation } from 'react-i18next';
import { colors, careplanStyles } from '../../utils/dropdownSelectStyles';
import { inputCounterStyle } from '../../utils/dropdownSelectStyles';
import maxCharLimits from '../../utils/maxcharlimits.json';

const Input = ({ isHidden, selectProps, ...innerProps }) => {
  let charCount = 0; 
  if(selectProps.inputValue.length > 0){
    charCount = selectProps.inputValue.length;
  }
  else if(selectProps.inputValue.length === 0 && selectProps.value){
    charCount = selectProps.value.label.length;
  }
  return (
    <div style={inputCounterStyle}>
      <components.Input {...innerProps} maxLength={selectProps.maxLength}/>
      <span className="helperText">
        {charCount}/{selectProps.maxLength}
      </span>
    </div>
  );
};

/*
 * Replace the single value selected
 */
const SingleValue = ({ data, selectProps, ...innerProps }) => {
  const colorBasedOnMenuState = selectProps.menuIsOpen
    ? colors.lightGrey
    : colors.darkGrey;

  return (
    <components.SingleValue {...innerProps}>
      <span style={{ color: `${colorBasedOnMenuState}` }}>{data.label}</span>
    </components.SingleValue>
  );
};

const SingleSelectCareplanSearchable = ({
  loadOptions,
  isDisabled,
  placeholder,
  noTagsMessage,
  defaultOptions,
  value,
  handleOnChange,
  hasError,
  validationMessage,
  inputLimit,
  noOptionsMessage,
}) => {
  inputLimit ? 75 : 50;
  const isValidNewOption = inputValue => inputValue && inputValue.length && inputValue.length <= inputLimit;
  const { t } = useTranslation();
  return (
    <>
      <AsyncCreatableSelect
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        isDisabled={isDisabled}
        isSearchable
        placeholder={placeholder}
        value={value}
        maxLength={maxCharLimits.carePlan.careplanExternalTitlecMaxCharLimit}
        onChange={handleOnChange}
        styles={careplanStyles}
        className="react-select-container"
        classNamePrefix="react-select"
        components={{ SingleValue, Input }}
        noTagsMessage={noTagsMessage}
        hasError={hasError}
        isValidNewOption={isValidNewOption}
        noOptionsMessage={() => noOptionsMessage}
        getOptionValue={defaultOptions => defaultOptions.id}
        formatCreateLabel={inputValue => `${t('role_view.label_create')} "${inputValue}"`}
      />
      {hasError && (
        <span className="searchable-select__error">{validationMessage}</span>
      )}
    </>
  );
};

SingleSelectCareplanSearchable.propTypes = {
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
    .isRequired,
  loadOptions: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  noTagsMessage: PropTypes.string.isRequired,
  /** Can be null or an object */
  value: PropTypes.object,
  handleOnChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  validationMessage: PropTypes.string,
  noOptionsMessage: PropTypes.string,
};

SingleSelectCareplanSearchable.defaultProps = {
  hasError: false,
  validationMessage: null,
};

export default SingleSelectCareplanSearchable;
