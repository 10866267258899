import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { Connection, Edge, Node, useReactFlow } from 'reactflow';
import { useHistory, useParams } from 'react-router';

import NavigationJSON from '@utils/navigation.json';
import { useAnalytics } from '@global/Services';
import { useAbortController } from '@global/Hooks';
import { getDurationObjectForCareplan } from '@ui-common-files/utils';

import { useCarePathWayBuilderStore } from './CarePathWayBuilder.store';
import {
  validateAppointmentType,
  validateCarePathwayName,
  validateIcds,
} from './CarePathWayBuilder.validations';
import {
  getLayoutedNodes,
  removeCSSClassName,
  addNewCSSClassToNode,
  getTransformedDurationObject,
  transformPathWayTemplateToSortedFlatList,
  clearDagreGraph,
} from './CarePathWayBuilder.utils';
import { getCarePathWayContentForEdit } from './CarePathWayBuilder.api';
import {
  NODE_X_DEDUCTION,
  NODE_Y_DEDUCTION,
} from './CarePathWayBuilder.constant';
import config from '../../../../config';

const useCarePathWayBuilderService = () => {
  const {
    nodes,
    edges,
    errors,
    sidebar,
    addNode,
    addError,
    settings,
    onConnect,
    removeEdge,
    edgeUpdate,
    resetErrors,
    resetBuilder,
    removeAnError,
    onNodesChange,
    onEdgesChange,
    isAddingParent,
    setPathWayReview,
    setPathWayTemplate,
    updateSidebarVisibility,
  } = useCarePathWayBuilderStore(
    useShallow(state => ({
      nodes: state.nodes,
      edges: state.edges,
      errors: state.errors,
      sidebar: state.sidebar,
      addNode: state.addNode,
      addError: state.addError,
      settings: state.settings,
      onConnect: state.onConnect,
      removeEdge: state.removeEdge,
      edgeUpdate: state.edgeUpdate,
      resetErrors: state.resetErrors,
      resetBuilder: state.resetBuilder,
      removeAnError: state.removeAnError,
      onNodesChange: state.onNodesChange,
      onEdgesChange: state.onEdgesChange,
      isAddingParent: state.isAddingParent,
      setPathWayReview: state.setPathWayReview,
      setPathWayTemplate: state.setPathWayTemplate,
      updateSidebarVisibility: state.updateSidebarVisibility,
    }))
  );
  const { id } = useParams();
  const history = useHistory();
  const isEditMode: boolean = !!id;
  const reactFlowWrapper = useRef(null);
  const edgeUpdateSuccessful = useRef(true);
  const reactFlowInstance = useReactFlow();
  const { trackEventTrigger } = useAnalytics();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getAbortController } = useAbortController();

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const removeNode = useCallback(
    (node: Node) => {
      updateSidebarVisibility(false);
      reactFlowInstance.deleteElements({ nodes: [node] });
    },
    [reactFlowInstance, updateSidebarVisibility]
  );

  const toggleNodeType = useCallback(
    (nodeId: string) => {
      updateSidebarVisibility(false);

      reactFlowInstance
        .getEdges()
        .filter((edge: Edge) => edge.target === nodeId)
        .forEach((edge: Edge) => {
          removeEdge(edge.id);
        });


      reactFlowInstance.setNodes((allNodes: Node[]) =>
        allNodes.map(node => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                isParent: !node.data.isParent,
                durationDefault: { ...node.data.durationDefault }
              },
            };
          }

          return node;
        })
      );
    },
    [removeEdge, reactFlowInstance, updateSidebarVisibility]
  );

  const validateNodeConfiguration = useCallback(
    (nodeId: string, overlappedWith: string) => {
      reactFlowInstance.setNodes((allNodes: Node[]) =>
        allNodes.map(node => ({
          ...node,
          className:
            overlappedWith && (node.id === nodeId || node.id === overlappedWith)
              ? addNewCSSClassToNode(node.className, 'invalid-configuration')
              : node.className,
        }))
      );
    },
    [reactFlowInstance]
  );

  useEffect(() => {
    if (nodes.length > 0 && errors.length > 0) {
      const hasNoConfiguration = errors.some(
        error => error.id === 'careTaskNoConfiguration'
      );
      if (hasNoConfiguration) {
        const withOutDuration = nodes.filter(
          node => !node.data.duration && node.data.isParent
        );
        if (withOutDuration.length === 0) {
          removeAnError('careTaskNoConfiguration');
        }
      }
    }
  }, [nodes, errors, removeAnError]);

  useEffect(() => {
    const nodesLimitExceeded = errors.some(
      error => error.id === 'nodesLimitExceeded'
    );
    if (nodes.length === config.carepathwayMaxNodesLimit) {
      updateSidebarVisibility(false);
      if (!nodesLimitExceeded) {
        addError({
          id: 'nodesLimitExceeded',
          type: 'node',
          translationKey: 'carePathway_builder.errors.nodes_limit_exceeded',
        });
      }
    } else if (nodesLimitExceeded) {
      removeAnError('nodesLimitExceeded');
    }
  }, [nodes.length, errors, removeAnError]);

  const validateCarePathWay = useCallback(() => {
    updateSidebarVisibility(false);
    resetErrors();

    reactFlowInstance.setNodes((allNodes: Node[]) =>
      allNodes.map(node => {
        let newClassNames = removeCSSClassName(
          node.className,
          'missing-configuration'
        );
        newClassNames = removeCSSClassName(
          newClassNames,
          'invalid-configuration'
        );
        newClassNames = removeCSSClassName(newClassNames, 'missing-parent');

        return {
          ...node,
          className: newClassNames,
        };
      })
    );

    if (validateCarePathwayName(settings.carePathwayName)) {
      addError({
        id: 'carePathwayName',
        type: 'name',
        translationKey: 'carePathway_builder.errors.pathway_name',
      });
      return false;
    }

    if (validateAppointmentType(settings.appointmentType)) {
      addError({
        id: 'appointmentType',
        type: 'settings',
        translationKey: 'carePathway_builder.errors.settings_required_fields',
      });
      return false;
    }

    if (validateIcds(settings.icds)) {
      addError({
        id: 'icds',
        type: 'settings',
        translationKey: 'carePathway_builder.errors.settings_required_fields',
      });
      return false;
    }

    if (nodes.length === 0) {
      addError({
        id: 'careTaskLength',
        type: 'name',
        translationKey: 'carePathway_builder.careTask_list.no_CareTask_added',
      });
      return false;
    }

    const nodeWithOutDuration = nodes.filter(
      node => !node.data.duration && node.data.isParent
    );
    if (nodeWithOutDuration.length > 0) {
      addError({
        id: 'careTaskNoConfiguration',
        type: 'name',
        translationKey: 'carePathway_builder.errors.node_without_configuration',
      });

      reactFlowInstance.setNodes((allNodes: Node[]) =>
        allNodes.map(node => ({
          ...node,
          className: nodeWithOutDuration.some(n => n.id === node.id)
            ? addNewCSSClassToNode(node.className, 'missing-configuration')
            : node.className,
        }))
      );
      return false;
    }

    const childNodeWithOutParent = nodes.filter(
      node =>
        !node.data.isParent && !edges.some(edge => edge.target === node.id)
    );
    if (childNodeWithOutParent.length > 0) {
      addError({
        id: 'childNodeWithOutParent',
        type: 'name',
        translationKey: 'carePathway_builder.errors.child_node_without_parent',
      });

      reactFlowInstance.setNodes((allNodes: Node[]) =>
        allNodes.map(node => ({
          ...node,
          className: childNodeWithOutParent.some(n => n.id === node.id)
            ? addNewCSSClassToNode(node.className, 'missing-parent')
            : node.className,
        }))
      );
      return false;
    }

    return true;
  }, [
    nodes,
    edges,
    addError,
    resetErrors,
    settings.icds,
    reactFlowInstance,
    updateSidebarVisibility,
    settings.carePathwayName,
    settings.appointmentType,
  ]);

  const updateNodeConfiguration = useCallback(
    (nodeId: string, configuration) => {
      reactFlowInstance.setNodes((allNodes: Node[]) =>
        allNodes.map(node => {
          if (node.id === nodeId) {
            return {
              ...node,
              className: removeCSSClassName(
                node.className,
                'missing-configuration'
              ),
              data: {
                ...node.data,
                duration: { ...configuration },
              },
            };
          }
          return { ...node };
        })
      );
    },
    [reactFlowInstance]
  );

  useEffect(() => {
    reactFlowInstance?.setNodes((allNodes: Node[]) =>
      allNodes.map(node => ({
        ...node,
        className: removeCSSClassName(node.className, 'invalid-configuration'),
      }))
    );
  }, [reactFlowInstance, sidebar.configurationNodeId, sidebar.isVisible]);

  const addNodeToBuilder = useCallback(
    (careTaskData, position, isRoot, nodeId = null) => {
      addNode(
        isRoot,
        nodeId,
        careTaskData,
        position.x - NODE_X_DEDUCTION,
        position.y - NODE_Y_DEDUCTION,
        removeNode,
        toggleNodeType,
        updateNodeConfiguration,
        validateNodeConfiguration
      );
    },
    [
      addNode,
      removeNode,
      toggleNodeType,
      updateNodeConfiguration,
      validateNodeConfiguration,
    ]
  );

  useEffect(() => {
    resetBuilder();
    if (id) {
      setIsLoading(true);
      clearDagreGraph();
      const { signal } = getAbortController();
      getCarePathWayContentForEdit(id, signal)
        .then(response => {
          if (response) {
            setPathWayTemplate(response.data);

            const flattedTemplates = transformPathWayTemplateToSortedFlatList(
              response.data.templates
            );

            flattedTemplates.forEach(template => {
              const isBuiltByBuilder =
                template.nodes.length > 0 && template.nodes[0].metadata;

              const layoutedNodes = isBuiltByBuilder
                ? template.nodes
                : getLayoutedNodes(template.nodes, template.edges);

              layoutedNodes.forEach(node => {
                const isRoot = node.parentId === null;
                const recurrenceObject = isRoot ? { ...template } : {
                  lifespan: node.carePathwayTemplateAppointmentType.lifespan,
                  lifespanUnit:
                    node.carePathwayTemplateAppointmentType.lifespanUnit
                };

                if (isRoot) {
                  delete recurrenceObject.nodes;
                  delete recurrenceObject.edges;
                }

                const nodePosition = isBuiltByBuilder
                  ? JSON.parse(node.metadata).position
                  : {
                      ...node.position,
                      x: node.position.x,
                    };

                addNodeToBuilder(
                  {
                    ...recurrenceObject,
                    id: node.template.id,
                    name: node.template.name,
                    isAssessment: node.template.isAssessment,
                    scoringScheme: node.template.scoringScheme,
                  },
                  nodePosition,
                  isRoot,
                  node.id
                );
              });

              template.edges.forEach(edge => {
                onConnect({
                  source: edge.source,
                  target: edge.target,
                  sourceHandle: edge.condition,
                  targetHandle: null,
                });
              });
            });

            trackEventTrigger({
              category: 'carepathway',
              action: 'Edit-load-Successful',
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const data = event.dataTransfer.getData('application/reactflow');

      if (data) {
        const careTaskData = JSON.parse(data);

        const position = reactFlowInstance.screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        removeAnError('careTaskLength');
        addNodeToBuilder(careTaskData, position, isAddingParent);
      }
    },
    [reactFlowInstance, addNodeToBuilder, removeAnError, isAddingParent]
  );

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      edgeUpdateSuccessful.current = true;
      edgeUpdate(oldEdge, newConnection);
    },
    [edgeUpdate]
  );

  const onEdgeUpdateEnd = useCallback(
    (event: MouseEvent | TouchEvent, edge: Edge) => {
      if (!edgeUpdateSuccessful.current) {
        removeEdge(edge.id);
      }
      edgeUpdateSuccessful.current = true;
    },
    [removeEdge]
  );

  const navigateToCarePathWayList = () => {
    history.push(`/${NavigationJSON.CAREPLAN}`);
  };

  const submitCarePathWay = (shouldCloseBuilder: boolean) => {
    if (validateCarePathWay()) {
      setPathWayReview({
        title: settings.carePathwayName,
        id: null,
        isOpen: true,
        shouldCloseBuilder,
      });
    }
  };

  return {
    nodes,
    edges,
    onDrop,
    onConnect,
    isLoading,
    onDragOver,
    isEditMode,
    onEdgeUpdate,
    onNodesChange,
    onEdgesChange,
    onEdgeUpdateEnd,
    reactFlowWrapper,
    submitCarePathWay,
    onEdgeUpdateStart,
    validateNodeConfiguration,
    navigateToCarePathWayList,
  };
};

export default useCarePathWayBuilderService;
