import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import inputField from '../../utils/inputField';
import { Greys } from '../../utils/colors';
import IconButton from '../IconButton/IconButton';

import '../../styles/main.scss';

const InputFieldWithCopy = ({
  iconName,
  buttonToolTip,
  type,
  id,
  placeholder,
  isDisabled,
  validationMessage,
  value,
  handleOnChange,
  ...rest
}) => {
  const inputClasses = classNames({
    input__text: true,
    disabled: isDisabled,
  });

  return (
    <div className="input__wrapper">
      <div className="input__text__wrapper">
        <input
          style={{ color: Greys.LIGHT_GREY }}
          type={inputField.type.TEXT}
          id={id}
          value={value}
          onChange={handleOnChange}
          placeholder={placeholder}
          className={inputClasses}
          disabled={isDisabled}
          {...rest}
        />
        <IconButton
          name={iconName}
          onClick={() => {
            navigator.clipboard.writeText(value);
          }}
          size={24}
          fill={Greys.LIGHT_GREY}
          tooltipText={buttonToolTip}
        />
      </div>
    </div>
  );
};

InputFieldWithCopy.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

InputFieldWithCopy.defaultProps = {
  type: inputField.type.TEXT,
  isDisabled: false,
};

export default InputFieldWithCopy;
