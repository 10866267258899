import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Icon,
  TextInput,
  IconButton,
  ToggleButton,
  IconButtonDropdown,
  DataTable,
} from '@ui-common-files/components';
import TagsCell from '../CellElements/TagsCell';

import {
  MaxCharLimits,
  inputField,
  ActionTranslationKeys,
  ComponentTranslationKeys,
} from '@ui-common-files/utils';

import { dataTablePageSize } from '@global/Utils';
import { ShowFlashMessage } from '@global/Services';
import { useWindowWidthListener } from '@utils/hooks';

import useSatellites from './Satellites.service';
import { getActionButtonItems, isSatelliteDisable } from './Satellites.util';

import { useUserPermission } from '@utils';
import { FlashMessageTypes } from '@ui-common-files/types';

const Satellites = ({
  hasPagesize,
  hasPagination,
  reloadTable,
  onEditSatellite,
}) => {
  const { t } = useTranslation();

  const { user } = useSelector(state => state.session);

  const responsive = useWindowWidthListener();

  const {
    fetchedData,
    recordsTotal,
    loading,
    tableProps,
    sort,
    query,
    currentPage,
    handleSearch,
    handleTableOnChange,
    handleOnCheckToggle,
  } = useSatellites(user.isActive, reloadTable);

  const hasSatelliteCredentials = useUserPermission(
    t(ComponentTranslationKeys.SATELLITE),
    t(ActionTranslationKeys.UPDATE),
    true
  );

  const handleSatelliteEdit = (id, name) => {
    if (hasSatelliteCredentials) {
      onEditSatellite(id, name);
    } else {
      ShowFlashMessage({
        type: FlashMessageTypes.Warning,
        messageKey: 'common_labels.permissionDenied',
      });
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: props => {
          return (
            <Box>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{t('admin_view.satellite.name')}</span>
            </Box>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'name',
      },
      {
        Header: () => {
          return <span>{t('site_view.label_phone')}</span>;
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'phoneNumber',
        sortable: false,
        filterable: false,
      },
      {
        Header: () => {
          return <span>{t('admin_view.label_email_user')}</span>;
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'email',
        sortable: false,
        filterable: false,
      },
      {
        Header: () => {
          return <span>{t('site_view.label_address')}</span>;
        },
        accessor: 'address',
        sortable: false,
        filterable: false,
      },
      {
        Header: () => {
          return <span>{t('admin_view.label_site')}</span>;
        },
        accessor: 'site',
        sortable: false,
        filterable: false,
        minWidth: 120,
        style: {
          overflow: 'visible',
        },
        Cell: ({ row }) => {
          const { site } = row;

          return <TagsCell tagList={site} />;
        },
      },
      {
        Header: '',
        accessor: 'rowOptions',
        sortable: false,
        filterable: false,
        minWidth: 160,
        maxWidth: 160,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'space-around',
        },
        Cell: props => {
          const { isActive, id, name } = props.original;
          const [active, setActive] = useState(isActive);

          return responsive ? (
            <Box className="moreIconStyle">
              <IconButtonDropdown
                items={[
                  { value: t('common_labels.label_edit') },
                  ...getActionButtonItems(active, () =>
                    isSatelliteDisable(props.original)
                  ).map(item => ({ value: t(item) })),
                ]}
                onClick={(e, action) => {
                  if (action.value === t('common_labels.label_edit')) {
                    handleSatelliteEdit(id, name);
                  } else {
                    setActive(!active);
                    handleOnCheckToggle(id, !isActive);
                  }
                }}
              />
            </Box>
          ) : (
            <Flex
              justifyContent="space-around"
              otherStyles={{ flex: '160 0 auto' }}
            >
              <Box style={{ marginRight: 10 }}>
                <IconButton
                  type="button"
                  name="edit"
                  size={30}
                  onClick={() => handleSatelliteEdit(id, name)}
                  tooltipText={t('common_labels.label_edit')}
                />
              </Box>
              <ToggleButton
                checked={isActive}
                handleOnChange={e => {
                  handleOnCheckToggle(id, e.target.checked);
                }}
                isDisabled={
                  isSatelliteDisable(props.original) || !hasSatelliteCredentials
                }
              />
            </Flex>
          );
        },
      },
    ];
  }, [
    handleOnCheckToggle,
    sort.column,
    sort.dir,
    user.id,
    responsive,
    hasSatelliteCredentials,
  ]);

  return (
    <Box>
      <Box otherStyles={{ width: 250, position: 'absolute', top: 0, right: 0 }}>
        <TextInput
          id="satellitesSearch"
          name="satellitesSearch"
          placeholder={t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={query.value}
          handleOnChange={e => handleSearch(e)}
        />
      </Box>
      <DataTable
        data={fetchedData}
        columns={columns}
        pageSize={hasPagesize}
        pageSizeOptions={dataTablePageSize}
        pagination={hasPagination}
        isLoading={loading}
        tableProps={tableProps}
        onTableChange={(index, size) => handleTableOnChange(index, size)}
        page={currentPage}
        pages={Math.ceil(recordsTotal / query.length)}
        totalRecord={recordsTotal}
      />
    </Box>
  );
};
export default Satellites;
