import React, { useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import formatDate from '../../../../Global/Date/FormatDate/FormatDate';
import AnswerHeaderComponent from '../AnswerHeaderComponent';
import { GeneralAnswerType } from '../GeneralAnswer.type';
import { CareplanComponentAnswer } from '../../../../types/Careplan';
import { isDatePickerThresholdCrossed } from './DateRequestAnswer.service';
import {
  getComponentOccurrencesAnswer,
  getThresholdClass,
  occurrenceHasAnswer,
} from '../AnswerResult.services';

import '../AnswerResultCommon.scss';

function DateRequestAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const renderComponentAnswers = useCallback(
    (occurrenceAnswers: CareplanComponentAnswer[], index: number) => {
      const isThreshold: boolean = isDatePickerThresholdCrossed(
        occurrenceAnswers[0],
        componentData.thresholds[0]
      );

      const thresholdClass = getThresholdClass(isThreshold);

      let answerContent = '-';
      if (occurrenceHasAnswer(occurrenceAnswers)) {
        answerContent = formatDate(occurrenceAnswers[0].answer_date, 'D MMM');
        answerContent += '<br>';
        answerContent += formatDate(occurrenceAnswers[0].answer_date, 'YYYY');
        if (isThreshold) answerContent += '*';
      }

      return (
        <Flex
          key={`col-header-${componentIndex}-${index}`}
          className="answer-component_header-columns-column"
          alignItems="center"
          justifyContent="center"
        >
          <span className={thresholdClass}>
            {ReactHtmlParser(answerContent)}
          </span>
        </Flex>
      );
    },
    []
  );

  return (
    <Box className="answer-component">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName="Datepicker"
        title={componentData.name}
        columnOccurrencesRenderer={() => {
          return getComponentOccurrencesAnswer(
            allOccurrences,
            componentData.answers,
            renderComponentAnswers
          );
        }}
      />
    </Box>
  );
}
export default DateRequestAnswer;
