import { useSelector } from 'react-redux';

import { CaretaskAnsweringNavigator } from '../../CaretaskAnswering.type';

import {
  selectNavigator,
  selectIsAnsweringMain,
  selectMainComponentsCount,
  selectAdditionalComponentsCount,
} from '../../CaretaskAnswering.reducer';

export default function useCaretaskAnsweringProgressBarService() {
  const navigator: CaretaskAnsweringNavigator = useSelector(selectNavigator);
  const isAnsweringMain: boolean = useSelector(selectIsAnsweringMain);
  const mainComponentsCount: number = useSelector(selectMainComponentsCount);
  const additionalComponentsCount: number = useSelector(
    selectAdditionalComponentsCount
  );
  const currentComponent = navigator.Current;

  const totalComponentsCount: number = isAnsweringMain
    ? mainComponentsCount
    : additionalComponentsCount;

  const displayMainComponentsCurrent = isAnsweringMain
    ? currentComponent
    : mainComponentsCount;

  return {
    isAnsweringMain,
    mainComponentsCount,
    additionalComponentsCount,
    currentComponent,
    totalComponentsCount,
    displayMainComponentsCurrent,
  };
}
