import I18n from 'i18next';
import ASSET_TYPES from '../Asset/assetTypes';

export const getFileExtension = messageObj => {
  const lastIndexDot = messageObj.lastIndexOf('.');
  const messageLength = messageObj.length;
  const fileExtension = messageObj.substring(lastIndexDot + 1, messageLength);
  return fileExtension;
};

export const getUserPrimaryInstitutionId = user => {
  const { institutions } = user;

  const foundPrimaryInstitution = institutions.find(
    institution => institution.userInstitution.isPrimary === true
  );

  return foundPrimaryInstitution.id;
};

export const isDataImage = messageObj => {
  return (
    !messageObj.includes(I18n.t('common_labels.deleted')) &&
    getFileExtension(messageObj)
      .toLowerCase()
      .match(/(jpeg|jpg|png|gif|bmp|svg|heic|heif|webp)$/i)
  );
};

export const isDataAudio = messageObj => {
  return (
    !messageObj.includes(I18n.t('common_labels.deleted')) &&
    getFileExtension(messageObj)
      .toLowerCase()
      .match(/(mp3)$/i)
  );
};

export const isDataPdf = messageObj => {
  return (
    !messageObj.includes(I18n.t('common_labels.deleted')) &&
    getFileExtension(messageObj)
      .toLowerCase()
      .match(/(pdf)$/i)
  );
};

export const isDataVideo = messageObj => {
  return (
    !messageObj.includes(I18n.t('common_labels.deleted')) &&
    getFileExtension(messageObj)
      .toLowerCase()
      .match(/(mp4|avi|mov|wmv|m4v)$/i)
  );
};
export const addProtocolToUrl = (url) => {
  if (!url) {
    return "";
  }
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "http://" + url;
  }
  return url;
};

export const isDataLink = messageObj => {
  if (!messageObj) {
    return false;
  }
  const urlRegex = /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z]{2,4})(?:\/|$)/;
  return urlRegex.test(messageObj);
};

export const getFileType = messageObj => {
  if (isDataImage(messageObj)) return ASSET_TYPES.IMAGE;
  if (isDataAudio(messageObj)) return ASSET_TYPES.AUDIO;
  if (isDataPdf(messageObj)) return ASSET_TYPES.DOCUMENT;
  if (isDataVideo(messageObj)) return ASSET_TYPES.VIDEO;
  return 0;
}

export const getMessageObject = (
  timestamp,
  data,
  isFileObject,
  patientInfo,
  user
) => {
  const institutionId = getUserPrimaryInstitutionId(user);
  const { id, firebaseId } = patientInfo;

  if (isFileObject) {
    return {
      patientId: id,
      firebasePatientId: firebaseId,
      userId: user.id,
      message: data.fileName,
      isFileData: true,
      timestamp,
      isPatientInitiated: false,
      institutionId,
      assetId: data.assetId,
      filePath: data.directLink || data.assetDirectLink,
    };
  }
  return {
    patientId: id,
    firebasePatientId: firebaseId,
    userId: user.id,
    message: data,
    isFileData: false,
    timestamp,
    isPatientInitiated: false,
    institutionId,
  };
};
