/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';

import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import MedicationModalContentConfirmationView from './MedicationModalContentConfirmationView';

import {
  dropdownMedicationPhaseTypes,
  dropdownMedicationAdministrationType,
} from '../../Utils/dropdownTypes';

import serviceConfig from '../../../serviceConfig.json';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

const MedicationShowModalContent = ({ data }) => {
  const { setFlashMessage, closeModal } = useContext(PatientOverviewContext);

  const dropdownMedicationAdministrationTypeObj = dropdownMedicationAdministrationType();
  const dropdownMedicationPhaseTypesObj = dropdownMedicationPhaseTypes();

  const {
    timeOfDay,
    lifespanUnits,
  } = require('../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils');
  const lifespanUnitsObj = lifespanUnits();

  const [startingArrays, setStartingArrays] = useState({
    startingDays: [],
    startingTimes: [],
  });
  const [values, setValues] = useState({
    name: data.name,
    sober: data.sober,
    medicationAdministrationType: data.medAdminType.id
      ? dropdownMedicationAdministrationTypeObj[data.medAdminType.id - 1]
      : '',
    phaseType: data.medPhaseType
      ? dropdownMedicationPhaseTypesObj[data.medPhaseType - 1]
      : '',
    morning: false,
    dosageInMorning: '',
    midday: false,
    dosageInAfternoon: '',
    evening: false,
    dosageInEvening: '',
    night: false,
    dosageAtNight: '',
    customTimeChecked: false,
    dosageSpecificTime: '',
    customTime: { hour: 9 },
    startDate: moment(data.reminderElement.startsAt).toDate(),
    date: '',
    lifespan: 1,
    lifespanUnit: {},
    recurrenceText: data.recurrenceText,
    ownershipLogs: [],
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          serviceConfig.brokerService.showMedication.uri,
          {
            params: {
              
              reminderId: data.reminderElement.reminderId,
            },
          }
        );

        const responseData = response.data;
        if (responseData) {
          const additionalAttrObj = JSON.parse(
            responseData.additionalAttribute
          );
          setStartingArrays({
            ...startingArrays,
            startingTimes: responseData.startingTimes,
          });
          setValues({
            ...values,
            morning: responseData.startingTimes.indexOf(timeOfDay.MORNING) > -1,
            dosageInMorning: additionalAttrObj.morningDosage,
            midday: responseData.startingTimes.indexOf(timeOfDay.MIDDAY) > -1,
            dosageInAfternoon: additionalAttrObj.middayDosage,
            evening: responseData.startingTimes.indexOf(timeOfDay.EVENING) > -1,
            dosageInEvening: additionalAttrObj.eveningDosage,
            night: responseData.startingTimes.indexOf(timeOfDay.NIGHT) > -1,
            dosageAtNight: additionalAttrObj.nightDosage,
            customTimeChecked: responseData.specificTime > -1,
            dosageSpecificTime: additionalAttrObj.specificTimeDosage,
            customTime:
              responseData.specificTime > -1
                ? { hour: responseData.specificTime }
                : values.customTime,
            lifespan: responseData.lifespan,
            lifespanUnit: lifespanUnitsObj[responseData.lifespanUnit],
            recurrenceText: responseData.recurrenceText,
            ownershipLogs: responseData.ownershipLogs,
          });
        }
      } catch (error) {
        closeModal();
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      }
    };
    getData();
  }, []);

  return (
    <ModalContent>
      <MedicationModalContentConfirmationView
        values={values}
        startingArrays={startingArrays}
        icds={data.icdRecords? data.icdRecords:[]}
        showAssignee={false}
        isShowView={true}
      />
    </ModalContent>
  );
};

MedicationShowModalContent.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default MedicationShowModalContent;
