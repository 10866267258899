import React from 'react';
import I18n from 'i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Flex,
  ModalContent,
  Label,
  Icon,
  Action,
} from '@ui-common-files/components';
import { Greys } from '@ui-common-files/utils';
import NavigationJSON from '@utils/navigation.json';

import { renderShowAssetFirstColumn } from '../../Utils/Asset/assetHelper';

import '../../css/assets.css';

function ShowAssetModalContent({
  assetDataToShow,
  shouldShowSites,
  assetAssociations,
  activeTab,
}) {
  const { t } = useTranslation();
  const { assetData, fetchedPrivacyPolicyData } = assetDataToShow;
  let consentData = {},
    revokeData = {};
  if (fetchedPrivacyPolicyData) {
    consentData = fetchedPrivacyPolicyData.find(
      e => e.action === 'actions.consent'
    )
      ? fetchedPrivacyPolicyData.find(e => e.action === 'actions.consent')
      : {};
    revokeData = fetchedPrivacyPolicyData.find(
      e => e.action === 'actions.revoke'
    )
      ? fetchedPrivacyPolicyData.find(e => e.action === 'actions.revoke')
      : {};
  }
  let additionalConsents = assetData.additionalConsents
    ? JSON.parse(assetData.additionalConsents)
    : [];

  const history = useHistory();

  const revokeTime =
    revokeData.timeOfAction > consentData.timeOfAction
      ? moment(revokeData.timeOfAction)
          .locale(I18n.language)
          .format('DD MMM YYYY HH:mm')
      : '-';

  const assetInstitutions = assetData.institutions.map(inst => (
    <Box margin="12px 0 0">
      <Box className="asset-info__values">{inst.name}</Box>
    </Box>
  ));

  const assetCareplanTemplates =
    assetAssociations &&
    assetAssociations.assetCareplanTemplates.length &&
    assetAssociations.assetCareplanTemplates.map(assetCareplanTemplate => (
      <Box margin="12px 0 0">
        <Box className="asset-info__values">
          <Action
            onClick={() => {
              history.push(
                `/${NavigationJSON.EDIT_CAREPLAN_TEMPLATE.replace(
                  ':id',
                  assetCareplanTemplate.id
                )}`
              );
            }}
          >
            {assetCareplanTemplate.name}
          </Action>
        </Box>
      </Box>
    ));

  const assetPatients =
    assetAssociations &&
    assetAssociations.assetPatients.length &&
    assetAssociations.assetPatients.map(assetPatient => (
      <Box margin="12px 0 0">
        <Box className="asset-info__values">
          <Action
            onClick={() => {
              history.push(
                `/${NavigationJSON.PATIENT_OVERVIEW}/${assetPatient.patientId}`
              );
            }}
          >
            {assetPatient.patient && assetPatient.patient.firstname}{' '}
            {assetPatient.patient && assetPatient.patient.lastname}
          </Action>
        </Box>
      </Box>
    ));

  return (
    <ModalContent>
      <Flex otherStyles={{ width: '100%', minHeight: '100%' }}>
        <Box otherStyles={{ flex: 5 }}>
          {renderShowAssetFirstColumn(assetDataToShow, true)}
        </Box>
        <Box otherStyles={{ flex: 3 }}>
          <Box otherStyles={{ marginTop: 32, marginLeft: 20 }}>
            <Box className="asset-data__labels">{t('asset_view.fileName')}</Box>
            <Box margin="16px 0 0">
              <Box className="asset-info__values">{assetData.name}</Box>
            </Box>
            <Flex
              otherStyles={{ marginTop: 16, width: '100%', height: '100%' }}
            >
              <Flex flexDirection="column">
                <Flex flexDirection="row" alignItems="center">
                  <Box className="asset-info__labels">
                    {t('asset_view.fileSize')}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{ marginTop: '12px' }}
                >
                  <Box className="asset-info__labels">
                    {t('asset_view.dataAdded')}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{ marginTop: '12px' }}
                >
                  <Box className="asset-info__labels">
                    {t('asset_view.lastModified')}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{
                    marginTop: '12px',
                  }}
                >
                  <Box className="asset-info__labels">
                    {t('asset_view.createdBy')}
                  </Box>
                </Flex>
              </Flex>

              <Flex
                flexDirection="column"
                otherStyles={{ marginLeft: 40, flex: '1' }}
              >
                <Flex flexDirection="row" alignItems="center">
                  <Box className="asset-info__values">{`${assetData.size} MB`}</Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{ marginTop: '12px' }}
                >
                  <Box className="asset-info__values">
                    {moment(assetData.createdAt)
                      .locale(I18n.language)
                      .format('DD MMM YYYY')}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{ marginTop: '12px' }}
                >
                  <Box className="asset-info__values">
                    {moment(assetData.updatedAt)
                      .locale(I18n.language)
                      .format('DD MMM YYYY')}
                  </Box>
                </Flex>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  otherStyles={{
                    marginTop: '12px',
                  }}
                >
                  <Box className="asset-info__values">
                    {assetDataToShow.assetLogs[0]
                      ? assetDataToShow.assetLogs[0].user.username
                      : t('patients_view.label_patient')}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            {assetData.description && (
              <>
                <Box className="asset-data__labels">
                  {t('asset_view.description')}
                </Box>
                <Box margin="16px 0 0">
                  <Box className="asset-info__values">
                    {assetData.description}
                  </Box>
                </Box>
              </>
            )}
            {!activeTab && assetData.isPrivacyPolicy && consentData && (
              <>
                <Box className="asset-data__labels">
                  {t('asset_view.patientsConsent')}
                </Box>
                <Flex
                  otherStyles={{
                    marginTop: 10,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Flex flexDirection="column">
                    <Flex flexDirection="row" alignItems="center">
                      <Box className="asset-info__labels">
                        {t('asset_view.consentedOn')}
                      </Box>
                    </Flex>
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      otherStyles={{ marginTop: '12px' }}
                    >
                      <Box className="asset-info__labels">
                        {t('asset_view.revokedOn')}
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex
                    flexDirection="column"
                    otherStyles={{ marginLeft: 40, flex: '1' }}
                  >
                    <Flex flexDirection="row" alignItems="center">
                      <Box className="asset-info__values">
                        {moment(consentData.timeOfAction)
                          .locale(I18n.language)
                          .format('DD MMM YYYY HH:mm')}
                      </Box>
                    </Flex>
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      otherStyles={{ marginTop: '12px' }}
                    >
                      <Box className="asset-info__values">
                        {revokeData ? revokeTime : '-'}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
                <Box margin="24px 15px 12px 0">
                  <Label
                    text={t('asset_view.defaultConsentsDescription')}
                    isLongText={true}
                  />
                  <Box
                    className="asset-info__values"
                    style={{ marginTop: '12px' }}
                  >
                    {t('asset_view.defaultConsentsDescriptionText')}
                  </Box>
                </Box>
                {additionalConsents.length > 0 && (
                  <Box margin="24px 0 0 0">
                    <Flex flexDirection="row">
                      <Label
                        text={t('asset_view.additionalConsentsDescription')}
                        isLongText={true}
                      />
                      <Icon name="info" size={24} fill={Greys.DARK_GREY} />
                    </Flex>
                    <Box margin="0 15px 0 0">
                      {additionalConsents.map(additionalConsent => (
                        <Box
                          className="asset-info__values"
                          otherStyles={{ marginBottom: '24px' }}
                        >
                          {additionalConsent}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
                {assetDataToShow.fetchedPrivacyPolicyData.length > 2 && (
                  <>
                    <Box className="asset-data__labels">
                      {t('asset_view.patientsConsentHistory')}
                    </Box>
                    <Flex
                      otherStyles={{
                        marginTop: 10,
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <Flex flexDirection="column">
                        {assetDataToShow.fetchedPrivacyPolicyData.map(
                          (fetchedPrivacyPolicyData, i) => {
                            return fetchedPrivacyPolicyData.action ===
                              'actions.consent' ? (
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                otherStyles={{ marginTop: '12px' }}
                              >
                                <Box className="asset-info__labels">
                                  {t('asset_view.consentedOn')}
                                </Box>
                              </Flex>
                            ) : (
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                otherStyles={{ marginTop: '12px' }}
                              >
                                <Box className="asset-info__labels">
                                  {t('asset_view.revokedOn')}
                                </Box>
                              </Flex>
                            );
                          }
                        )}
                      </Flex>
                      <Flex
                        flexDirection="column"
                        otherStyles={{ marginLeft: 40, flex: '1' }}
                      >
                        {assetDataToShow.fetchedPrivacyPolicyData.map(
                          (fetchedPrivacyPolicyData, i) => {
                            return (
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                otherStyles={{ marginTop: '12px' }}
                              >
                                <Box className="asset-info__values">
                                  {moment(fetchedPrivacyPolicyData.timeOfAction)
                                    .locale(I18n.language)
                                    .format('DD MMM YYYY')}
                                </Box>
                              </Flex>
                            );
                          }
                        )}
                      </Flex>
                    </Flex>
                  </>
                )}
              </>
            )}
            {shouldShowSites && (
              <>
                <Box className="asset-data__labels">
                  {t('asset_view.availableSite')}
                </Box>
                <Box margin="16px 0 0">{assetInstitutions}</Box>
              </>
            )}
            {shouldShowSites &&
              assetAssociations &&
              assetAssociations.assetCareplanTemplates.length > 0 && (
                <>
                  <Box className="asset-data__labels">
                    {t('asset_view.attachedCareplans')}
                  </Box>

                  <Box margin="16px 0 0">{assetCareplanTemplates}</Box>
                </>
              )}
            {shouldShowSites &&
              assetAssociations &&
              assetAssociations.assetPatients.length > 0 && (
                <>
                  <Box className="asset-data__labels">
                    {t('asset_view.sharedPatients')}
                  </Box>
                  <Box margin="16px 0 20px">{assetPatients}</Box>
                </>
              )}
          </Box>
        </Box>
      </Flex>
    </ModalContent>
  );
}

export default ShowAssetModalContent;
