/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import { Flex,Icon } from '@ui-common-files/components';

import { PrimaryColor } from '@ui-common-files/utils';
import { addProtocolToUrl, isDataLink } from '../../../Utils/Chat/chatUtility';

const BubbleLink = ({ note, data, blobURL, isLink }) => {
  let renderText;

  const noteTextElements = note.split(' ');

  if (noteTextElements.length > 1) {
    renderText = noteTextElements.map(part => {
      return isDataLink(part) ? (
        <a href={addProtocolToUrl(part)} rel="noopener noreferrer" target="_blank">
          {`${part}`}
        </a>
      ) : (
        ` ${part} `
      );
    });
  } else {
    const linkURL = isLink ? addProtocolToUrl(note) : addProtocolToUrl(data || blobURL);
    renderText = (
      <a
        className="file-link__container"
        href={linkURL}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="file-link__text">{note}</div>
          <button
            type="button"
            onClick={() => {}}
            className="file-link__icon"
            style={{ justifyContent: 'center' }}
          >
            <Icon
              name="openInNewTab"
              size={16}
              fill={PrimaryColor.MAIN_COLOR}
            />
          </button>
        </Flex>
      </a>
    );
  }

  return <div className="note-bubble__text">{renderText}</div>;
};

BubbleLink.propTypes = {
  note: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  blobURL: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
};

BubbleLink.defaultProps = {
  isLink: false,
};

export default React.memo(BubbleLink);
