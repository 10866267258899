import React from 'react';
import '../../styles/main.scss';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import DropdownMenu from './DropdownMenu';
import Icon from '../Icon/Icon';
import { Greys } from '../../utils/colors';

const InfoButtonDropdown = ({ iconName, iconColor, onClick, items, isAttachAsset, isAttachmentDropdown }) => {
  const buildItems = () => {
    const result = items.map(function(item) {
      return (
        <li key={item.key} onClick={e => onClick(e, item)}>
          <span
            className={`dropdown__item${
              item.value === I18n.t('common_labels.label_delete') ||
              item.value === I18n.t('asset_view.detach')
                ? ' icon-dropdown__delete'
                : ''
            }`}
          >
            {item.value}
          </span>
        </li>
      );
    });
    return result;
  };

  return (
    <DropdownMenu items={buildItems()} isAttachAsset={isAttachAsset}>
      <div className="icon-dropdown">
        <div className="icon-dropdown__button">
          <Icon
            name={iconName}
            size={isAttachAsset ? 30 : 24}
            fill={iconColor}
          />
        </div>
      </div>
    </DropdownMenu>
  );
};

InfoButtonDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
};
InfoButtonDropdown.defaultProps = {
  iconName: 'information',
  iconColor: Greys.DARK_GREY,  
};

export default InfoButtonDropdown;
