import {
  checkPatientWithingsAccount,
  unLinkWithingsDevice,
} from './WithingsDevice.api';

export const checkPatientWithingsAccountStatus = async (
  patientId: string
): Promise<boolean> => {
  const response = await checkPatientWithingsAccount(patientId);
  return !!response?.data;
};

export const unLinkWithingsDeviceToPatient = async (
  patientId: string,
  macAddress: string
): Promise<boolean> => {
  const response = await unLinkWithingsDevice(patientId, macAddress);
  return !!response?.data;
};
