import { AxiosError } from 'axios';
import { FlashMessageTypes } from '../../../caro-ui-commonfiles/types/components';
import { FlashMessageError } from '../../types/Common/Errors.type';
import { SetFlashMessage } from './PatientOverview.type';

const defaultErrorFunction = (error: AxiosError<FlashMessageError>) => {
  return error;
};

const checkIfNoInternetAndSetFlashMessage =
  (setFlashMessage: SetFlashMessage, callback = defaultErrorFunction) =>
  (error: AxiosError<FlashMessageError>) => {
    if (error?.response?.data.type === FlashMessageTypes.Error)
      setFlashMessage({
        display: true,
        content: error.response.data.content,
        type: error.response.data.type,
      });
    else callback(error);
  };

export default checkIfNoInternetAndSetFlashMessage;
