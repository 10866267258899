/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import I18n from 'i18next';

import {
  ComponentTranslationKeys,
  WearablesDataTypes,
  WearablesSourceTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';

import '../../css/wearables.css';

import {
  HearRateActivity,
  SleepActivity,
  HeightActivity,
  DistanceActivity,
  StepsActivity,
  CaloriesActivity,
  WeightActivity,
  BloodPreasureActivity,
} from '../../Utils/WearablesActivityIcons';

const getActivityButtonComponents = (key, size) => {
  switch (key) {
    case 1:
      return {
        component: <WeightActivity size={size} />,
        name: I18n.t(ComponentTranslationKeys.WEIGHT),
      };
    case 2:
      return {
        component: <HeightActivity size={size} />,
        name: I18n.t(ComponentTranslationKeys.HEIGHT),
      };
    case 3:
      return {
        component: <StepsActivity size={size} />,
        name: I18n.t(ComponentTranslationKeys.STEPS),
      };
    case 4:
      return {
        component: <CaloriesActivity size={size} />,
        name: I18n.t(ComponentTranslationKeys.CALORIES),
      };
    case 5:
      return {
        component: <HearRateActivity size={size} />,
        name: I18n.t(ComponentTranslationKeys.HEART_RATE),
      };
    case 6:
      return {
        component: <SleepActivity size={size} />,
        name: I18n.t(ComponentTranslationKeys.SLEEP),
      };
    case 7:
      return {
        component: <DistanceActivity size={size} />,
        name: I18n.t(ComponentTranslationKeys.DISTANCE),
      };
    case 8:
      return {
        component: <BloodPreasureActivity size={size} />,
        name: I18n.t(ComponentTranslationKeys.BLOOD_PREASURE),
      };

    default:
      return {};
  }
};

const getHeartRateValue = value => {
  const { avg, min, max } = value;
  const line = parseFloat(parseFloat(avg).toFixed(2));
  const error = [
    parseInt(line, 10) - parseInt(min, 10),
    parseInt(max, 10) - parseInt(line, 10),
  ];
  return {
    line,
    error,
  };
};

const getTranslatedItemSource = (sourceId: WearablesSourceTypes): string => {
  let translatedSource: string;
  switch (sourceId) {
    case WearablesSourceTypes.APPLE:
      translatedSource = ComponentTranslationKeys.APPLE;
      break;
    case WearablesSourceTypes.GOOGLE:
      translatedSource = ComponentTranslationKeys.GOOGLE;
      break;
    case WearablesSourceTypes.WITHINGS:
      translatedSource = ComponentTranslationKeys.WITHINGS;
      break;
    case WearablesSourceTypes.CARETASK:
      translatedSource = ComponentTranslationKeys.CAREPLAN;
      break;
    default:
      translatedSource = null;
      break;
  }
  return translatedSource;
};

const checkBlookPressureActivitySelected = active =>
  active.some(item => item.key === WearablesDataTypes.BLOOD_PREASURE);

export {
  getActivityButtonComponents,
  getHeartRateValue,
  checkBlookPressureActivitySelected,
  getTranslatedItemSource,
};
