import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HideFlashMessage } from '@global/Services';
import { dataTablePageSize } from '@global/Utils';
import {
  ModalContent,
  Modal,
  Button,
  Icon,
  Box,
  Flex,
  DataTable,
  IconButton,
  TextInput,
} from '@ui-common-files/components';
import {
  ActionColors,
  inputField,
  MaxCharLimits,
  PrimaryColor,
  ActionTranslationKeys,
  ComponentTranslationKeys,
} from '@ui-common-files/utils';
import { useUserPermission } from '@utils/hooks/getUserPermission';

import ModalSection from '../../common/Layout/Modal/ModalSection';
import ModalForm from '../../common/Layout/Modal/ModalForm';
import ModalFooter from '../../common/Layout/Modal/ModalFooter';
import { useAnalyticsData } from './AnalyticsDataSettings.service';

import './AnalyticsDataSettings.styles.scss';

function AnalyticsDataSettings({
  setComponentCollection,
  currComponent,
  componentCollection,
  hasAnalyticsDataCreateOrUpdatePermission,
}) {
  const { t } = useTranslation();
  const {
    openModal,
    setOpenModal,
    fetchedData,
    standardDataValue,
    setStandardDataValue,
    setAttachBtnClicked,
    clearAttachedList,
    isLoading,
    query,
    handleSearch,
    handleTableOnChange,
    recordsTotal,
    currentPage,
    matchingComponent,
  } = useAnalyticsData(
    currComponent,
    componentCollection,
    setComponentCollection
  );

  const hasAnalyticsDataDeletePermission = useUserPermission(
    t(ComponentTranslationKeys.STANDARD_DATA_MAPPING),
    t(ActionTranslationKeys.DELETE),
    true
  );
  const hasStandardValue = !!(
    matchingComponent.searchTerm.standardValueId ||
    matchingComponent.standardValueId
  );
  const attachedListName =
    matchingComponent?.searchTerm?.standardValue?.name ||
    matchingComponent?.standardValue?.name;
  const tableProps = useMemo(
    () => ({
      getTrProps: (state, rowInfo) => {
        if (rowInfo) {
          return {
            onClick: () => {
              setStandardDataValue(rowInfo.original);
            },
            style: {
              background:
                rowInfo.original.id === standardDataValue?.id
                  ? PrimaryColor.MAIN_COLOR_LIGHT
                  : null,
              cursor: 'pointer',
            },
          };
        }
        return {};
      },
    }),
    [standardDataValue]
  );

  const columns = [
    {
      Header: () => <Box>{t('common_labels.label_name')}</Box>,
      accessor: 'name',
      width: 350,
    },
    {
      Header: () => <Box>{t('common_labels.id')}</Box>,
      accessor: 'uuid',
      width: 350,
    },
    {
      Header: () => <Box>{t('data_analytics.data_type')}</Box>,
      accessor: 'svdatatype.name',
      Cell: ({ value }) => <Box>{value}</Box>,
    },
  ];
  const renderSelectedList = () => {
    return (
      <Box>
        <Box className="attached-list__txt">
          {t('data_analytics.attachedList')}
        </Box>
        <Flex className="attached-list__div">
          <Box maxWidth={245}>
            <span
              className={
                hasAnalyticsDataDeletePermission
                  ? 'attached-list__name'
                  : 'attached-list__name-disabled'
              }
            >
              {attachedListName}
            </span>
          </Box>
          <Box className="attached-list__button">
            <IconButton
              name="remove"
              disabled={!hasAnalyticsDataDeletePermission}
              size={22}
              fill={ActionColors.DELETE}
              onClick={() => {
                clearAttachedList();
              }}
              tooltipText={t('toolTipsText.remove')}
            />
          </Box>
        </Flex>
      </Box>
    );
  };
  const renderAnalyticsComponentSettings = () => {
    return (
      <Flex className="toggleAndTextContainer label-toggle-content__component-settings">
        <Box className="list-settings__div">
          <Box>
            <Button
              label={t('data_analytics.selectFromList')}
              fullWidth
              disabled={
                hasStandardValue || !hasAnalyticsDataCreateOrUpdatePermission
              }
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <Icon name="Data" />
            </Button>
          </Box>
          {(matchingComponent.searchTerm.standardValueId ||
            matchingComponent.standardValueId) &&
            renderSelectedList()}
        </Box>
      </Flex>
    );
  };
  const renderModalFooter = () => {
    return (
      <ModalFooter
        close={() => {
          setOpenModal(false);
        }}
        labelCTA={t('actions.attach')}
        onClickCTA={() => {
          setAttachBtnClicked(true);
          setOpenModal(false);
        }}
      />
    );
  };

  const renderModalHeader = () => {
    return (
      <Box className="modal__search-bar">
        <TextInput
          id="searchDataPoints"
          value={query.value}
          placeholder={t('common_labels.label_search')}
          variant={inputField.variant.CHAR_COUNT}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          handleOnChange={e => handleSearch(e)}
        />
      </Box>
    );
  };

  const renderModalContent = () => {
    return (
      <ModalContent>
        <ModalForm>
          <ModalSection hasPadding>
            <Flex className="analytics__modal-header">
              {renderModalHeader()}
            </Flex>
            <DataTable
              isLoading={isLoading}
              data={fetchedData}
              pagination
              pageSize
              columns={columns}
              page={currentPage}
              pages={Math.ceil(recordsTotal / query.length)}
              totalRecord={recordsTotal}
              onTableChange={(index, size) => handleTableOnChange(index, size)}
              pageSizeOptions={dataTablePageSize}
              tableProps={tableProps}
            />
          </ModalSection>
        </ModalForm>
      </ModalContent>
    );
  };
  return (
    <Box>
      <Modal
        title={t('data_analytics.select_an_analytics_data')}
        contentComponent={renderModalContent()}
        footerComponent={renderModalFooter()}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
        hideFlashMessage={HideFlashMessage}
      />
      {renderAnalyticsComponentSettings()}
    </Box>
  );
}
export default AnalyticsDataSettings;
