import { useEffect } from 'react';

const useCareplanModalHeaderFooter = (
  careplanData,
  setCareplanHeaderFooter
) => {
  useEffect(() => {
    setCareplanHeaderFooter({
      header: 'newCareplan_view.assignCareplan',
      isAssessment: careplanData.isAssessment,
      startNow: careplanData.startNow,
    });
  }, [
    careplanData.isAssessment,
    careplanData.startNow,
    setCareplanHeaderFooter,
  ]);
};

export default useCareplanModalHeaderFooter;
