import React from 'react';

import { Tooltip, Flex, Box, Tag } from '..';

import { Position } from '../../utils';
import useSatellitesTableListService from './SatellitesTableList.service';

import { SatellitesTableListProps } from './SatellitesTableList.type';
import TagColorVariants from '../Tags/TagColorVariants.type';

import './SatellitesTableList.style.scss';

export default function SatellitesTableList({
  satellites,
}: SatellitesTableListProps) {
  const { showTooltip, initialSatellite, satellitesList } =
    useSatellitesTableListService({
      satellites,
    });
  return (
    <Flex flexDirection="row" className="satellitesList">
      <Tag
        text={initialSatellite.name}
        colorVariant={TagColorVariants.White}
        shouldHaveEllipsis
      />
      <Tooltip
        show={showTooltip}
        content={satellitesList}
        position={Position.bottom}
      >
        <Box className="satellitesList__ellipsis">...</Box>
      </Tooltip>
    </Flex>
  );
}
