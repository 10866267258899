import {
  SetType,
  OverviewHealthData,
  OverviewData,
  OverviewTemplates,
  OverviewDataPoints,
  MedicationData,
} from './Overview.type';

import { STORE_NAME, initialState } from './Overview.constant';

export const resetOverviewData = (set: SetType) => {
  return () =>
    set(
      {
        overviewDataResponse: null,
        overviewError: null,
      },
      false,
      `${STORE_NAME}-RESET_OVERVIEW_DATA`
    );
};

export const resetOverviewCaretasks = (set: SetType) => {
  return () =>
    set(
      {
        overviewCaretasks: {
          ...initialState.overviewCaretasks,
        },
      },
      false,
      `${STORE_NAME}-RESET_OVERVIEW_CARETASKS`
    );
};

//API CALL getWearablesActivityStatus and wearablesActions.GET_WEARABLES_ACTIVITY_STATUS_RESPONSE
export const setOverviewActivityStatus = (set: SetType) => {
  return (overviewActivityStatus: OverviewHealthData) =>
    set(
      {
        overviewActivityStatus: overviewActivityStatus,
      },
      false,
      `${STORE_NAME}-SET_OVERVIEW_ACTIVITY_STATUS`
    );
};

//API CALL getWearablesData and wearablesActions.GET_WEARABLES_DATA_RESPONSE
export const setOverviewData = (set: SetType) => {
  return (overviewData: OverviewData) =>
    set(
      {
        overviewDataResponse: overviewData,
      },
      false,
      `${STORE_NAME}-SET_OVERVIEW_DATA`
    );
};

export const setOverviewCaretasks = (set: SetType) => {
  return (caretasks: OverviewTemplates) =>
    set(
      {
        overviewCaretasks: {
          ...caretasks,
        },
      },
      false,
      `${STORE_NAME}-SET_OVERVIEW_CARETASKS`
    );
};

export const setOverviewDataPoints = (set: SetType) => {
  return (dataPoints: OverviewDataPoints) =>
    set(
      {
        overviewDataPoints: dataPoints,
      },
      false,
      `${STORE_NAME}-SET_OVERVIEW_DATA_POINTS`
    );
};

export const setMedicationData = (set: SetType) => {
  return (data: MedicationData) =>
    set(
      {
        medicationData: data || [],
      },
      false,
      `${STORE_NAME}-SET_MEDICATION_DATA`
    );
};

// STORES THE ERROR --> still to be checked if it's necessary
export const setOverviewError = (set: SetType) => {
  return error =>
    set(
      {
        overviewError: error,
      },
      false,
      `${STORE_NAME}-GET_OVERVIEW_ERROR`
    );
};
