/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import I18n from 'i18next';
import serviceConfig from '../../../../serviceConfig.json';
import NoteList from './NoteList';
import SendMessageForm from './SendMessageForm';
import {
  assetUploadPromise,
  validateFileSize,
  validateFileType,
  validateFileLimit,
} from '../../../Utils/Asset/assetHelper';
import { FlashMessageTypes } from '../../../../caro-ui-commonfiles/types/components';

import '../../../css/notes.css';
import { TelemedicineContext } from '../TelemedicineSidebar';
import SearchBar from './SearchBar';
import useDebounce from '../../../Global/Hooks/UseDebounce/UseDebounce';

require('image-compressor');

const Notes = ({
  patientInfo,
  setFlashMessage,
  reloadAssetTable,
  videoCall,
}) => {
  const {
    attachedFile,
    attachBtnClicked,
    setAttachBtnClicked,
    fileActiveTab,
  } = useContext(TelemedicineContext);

  const [notes, setNotes] = useReducer((_state, newNotes) => newNotes, []);
  const [numOfNotes, setNumOfNotes] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [numOfFilteredNotes, setNumOfFilteredNotes] = useState(0);

  const [attachment, setAttachment] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [memoizedFileRef, setMemoizedFileRef] = useState(null);

  const appendNote = note => {
    const updatesNotes = [...notes];
    updatesNotes.push(note);
    setNotes(updatesNotes);
  };

  const setNotesState = () => 
    isSearched ? setFilteredNotes : setNotes;
  

  const setNumOfNotesState = () =>
    isSearched ? setNumOfFilteredNotes : setNumOfNotes;
  
  const getNotesHistory = async (offset = 0) => {
    setIsLoading(true);
    try {
      const commentResponse = await axios.get(
        serviceConfig.brokerService.getNotesHistory.uri,
        {
          params: {
            searchText: isSearched ? searchText : '',
            patientId: patientInfo.id,
            offset
          },
        }
      );

      if (commentResponse) {
        const { data } = commentResponse;
        setNotesState()(data.notes.reverse().concat(isSearched ? filteredNotes : notes));
        setNumOfNotesState()(data.total);
      }
    } catch (error) {
      const { content, type } =
        error.response && error.response.data
          ? error.response.data
          : {
              content: 'flash.unexpectedError',
            type: FlashMessageTypes.Error,
          };
      setFlashMessage({
        content,
        type,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getNotesHistory();
  }, []);

  useEffect(() => {
    if (searchText !== '' && isSearched) {
      getNotesHistory(filteredNotes.length);
    }
  }, [searchText, isSearched]);

  const sendNote = async (text, isFileObject, assetId, assetDirectLink) => {
    const message = !isFileObject
      ? text
      : assetDirectLink
      ? { fileName: text, assetId, assetDirectLink }
      : { fileName: text, assetId };

    try {
      const response = await axios.post(
        serviceConfig.brokerService.createNote.uri,
        {
          patientId: patientInfo.id,
          noteContent: { message, isFileObject },
        }
      );
      if (response) {
        appendNote(response.data);
        setIsUploading(false);
        setAttachBtnClicked(false);
      }
    } catch (error) {
      const { content, type } =
        error.response && error.response.data
          ? error.response.data
          : {
            content: 'flash.unexpectedError',
              type: FlashMessageTypes.Error,
            };
      setFlashMessage({
        content,
        type,
      });
    }
  };

  const handleNoteSearch = async searchText => {
    setSearchText(searchText);
    setIsSearched(searchText !== '');
    setFilteredNotes([]);
    setNumOfFilteredNotes(0);
  };

  useEffect(() => {
    if (attachBtnClicked) {
      attachedFile.map(file => {
        sendNote(
          file.name,
          file.name.length > 0,
          file.id,
          file.directLink
        ).then(() => {
          reloadAssetTable();
        });
      });
    }
  }, [attachBtnClicked, attachedFile]);

  const handleFileUpload = () => {
    const fileList = [attachment.file];
    setAttachment(null);
    let errorMessage = '';

    const isFileLimitCorrect = validateFileLimit(fileList);
    const isFileTypeCorrect = validateFileType(fileList);
    const isFileSizeCorrect = validateFileSize(fileList);

    if (isFileLimitCorrect && isFileSizeCorrect && isFileTypeCorrect) {
      const formData = new FormData();
      for (let i = 0; i < fileList.length; i++) {
        formData.append('filedata', fileList[i]);
      }
      formData.append('isChat', true);
      return axios
        .post(serviceConfig.brokerService.createAsset.uri, formData, {
          timeout: 3000000000,
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': 'form-data',
          },
        })
        .then(response => {
          const { assetTypeId } = response.data;
          setIsUploading(true);
          return assetUploadPromise(
            response,
            fileList[0],
            setFlashMessage,
            true,
            patientInfo.collectionId
          )
            .then(results => {
              return axios
                .get(
                  `${serviceConfig.brokerService.getAssetById.uri}${results[0].id}`,
                  {
                    params: {
                      shouldSkipAssetInstitutionsCheck: !fileActiveTab,
                    },
                  }
                )
                .then(response => {
                  const {
                    data: { assetData },
                  } = response;

                  sendNote(
                    assetData.name,
                    assetData.name.length > 0,
                    assetData.id,
                    assetData.directLink
                  ).then(() => {
                    reloadAssetTable();
                  });
                })
                .catch(error => {
                  setIsUploading(false);
                  setFlashMessage({
                    content: I18n.t(error.response.data.content),
                    type: error.response.data.type,
                  });
                });
            })
            .catch(() => {
              setIsUploading(false);
              setAttachment(null);
            });
        })
        .catch(error => {
          setAttachment(null);
          setFlashMessage({
            content: error.response.data.content,
            type: error.response.data.type,
          });
        });
    }

    if (!isFileLimitCorrect) {
      errorMessage = 'flash.maxNumberOfFilesUpload';
    } else if (!isFileSizeCorrect) {
      errorMessage = 'flash.maxFileSize';
    } else if (!isFileTypeCorrect) {
      errorMessage = 'flash.incorrectFileType';
    }
    setFlashMessage({
      status: 403,
      content: errorMessage,
      type: 'warning',
    });
  };
  return (
    <>
      {!videoCall && (
        <SearchBar handleNoteSearch={useDebounce(handleNoteSearch,300)} />
      )}
      {!videoCall && (
        <NoteList
          notes={isSearched ? filteredNotes : notes}
          isSearched={isSearched}
          fileRef={memoizedFileRef}
          isUploading={isUploading}
          isLoading={isLoading}
          getNotesHistory={getNotesHistory}
          numOfNotes={isSearched ? numOfFilteredNotes : numOfNotes}
        />
      )}
      {!videoCall && (
        <SendMessageForm
          sendNote={sendNote}
          handleFileUpload={handleFileUpload}
          fileName={attachment ? attachment.file.name : ''}
          handleAttachment={attach => {
            setAttachment(attach);
          }}
        />
      )}
    </>
  );
};

Notes.propTypes = {
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ])
  ).isRequired,
  setFlashMessage: PropTypes.func.isRequired,
  reloadAssetTable: PropTypes.bool.isRequired,
  videoCall: PropTypes.bool.isRequired,
};
export default Notes;
