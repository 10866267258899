import React from 'react';

import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';

import { AnswerHeaderComponentProps } from './GeneralAnswer.type';

import './AnswerResultCommon.scss';

function AnswerHeaderComponent({
  componentIndex,
  iconName,
  title,
  columnOccurrencesRenderer,
}: AnswerHeaderComponentProps): JSX.Element {
  return (
    <>
      <Flex className="answer-component_header">
        <Flex alignItems="center" className="answer-component_header-title">
          <Box className="answer-component_header-title-index">
            {componentIndex}
          </Box>
          <Box className="answer-component_header-title-icon">
            <Icon name={iconName} size={12} />
          </Box>
          <Box className="answer-component_header-title-name">{title}</Box>
        </Flex>
        {columnOccurrencesRenderer && (
          <Flex className="answer-component_header-columns">
            {columnOccurrencesRenderer()}
          </Flex>
        )}
      </Flex>
      {iconName !== 'FreeText' && (
        <Box className="answer-component_separator" />
      )}
    </>
  );
}
export default AnswerHeaderComponent;