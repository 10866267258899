import React from 'react';
import Loader from 'react-loader-spinner';

import { LanguageItems } from '@type/Common';
import classNames from 'classnames';

import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import { PrimaryColor } from '../../../../caro-ui-commonfiles/utils/colors';
import useCarePlanReportTemplateService from './CareplanReportTemplate.service';
import { CareplanReportTemplateProps } from './CareplanReportTemplate.type';
import {
  CareplanReportFirstPage,
  CareplanReportPage,
  CareplanReportPageHeader,
  CareplanReportPageFooter,
} from './Components';

export default function CareplanReportTemplate({
  reportId,
  authenticationToken,
}: CareplanReportTemplateProps): JSX.Element {
  const {
    isLoading,
    reportData,
    firstPageRef,
    pages,
    headerRef,
    headerHeight,
    footerRef,
    footerHeight,
    isReportRendered,
    errorMessage,
  } = useCarePlanReportTemplateService(reportId, authenticationToken);

  if (isLoading)
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        otherStyles={{
          top: '50%',
          left: '50%',
          position: 'fixed',
        }}
      >
        <Loader
          type="Oval"
          color={PrimaryColor.MAIN_COLOR}
          height={60}
          width={60}
        />
      </Flex>
    );

  if (!reportData) {
    return <Box>{errorMessage}</Box>;
  }
  const mainPage = classNames({
    'main-page': true,
    'main-page-semitic': reportData.reportLanguage === LanguageItems.Arabic,
    'main-page-latin': reportData.reportLanguage !== LanguageItems.Arabic,
    'report-renders-done': isReportRendered,
  });

  return (
    <Box className={mainPage}>
      <CareplanReportFirstPage
        totalPages={pages.length + 1}
        ref={firstPageRef}
        reportData={reportData}
      />
      {pages.map((page, index: number) => (
        <Box
          className="sub-page"
          key={`report-page-index-${index}`}
          otherStyles={{
            paddingTop: headerHeight,
            paddingBottom: footerHeight,
          }}
        >
          <CareplanReportPage
            headerComponent={
              <CareplanReportPageHeader
                title={reportData.name}
                allOccurrences={reportData.allOccurrences}
                ref={headerRef}
              />
            }
            footerComponent={
              <CareplanReportPageFooter
                pageNumber={index + 2}
                totalPages={pages.length + 1}
                ref={footerRef}
              />
            }
          >
            {page.content}
          </CareplanReportPage>
        </Box>
      ))}
    </Box>
  );
}
