import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ComponentWithLabel,
  Flex,
  ICDViewer,
} from '@ui-common-files/components';
import { getRelativeDurationText, Greys } from '@ui-common-files/utils';
import { ShowCaretaskData } from '../../../../../Careplans/CaretaskData';
import { usePathWayReviewStepPreview } from './PathWayReviewStepPreview.service';

const PathWayReviewStepPreview: React.FC = ({ isLoading }) => {
  const { nodes, settings, sortedCaretasksByStartingTime } =
  usePathWayReviewStepPreview();

  const { t } = useTranslation();

  const RenderCareplansDuration = () => {
    return (
      <Flex flexDirection="column" otherStyles={{ width: '100%' }}>
        {sortedCaretasksByStartingTime.map(careplan => {
          const { startText, endText, occurrence } =
            careplan.duration.durationText ||
            getRelativeDurationText({
              ...careplan.data.duration,
              startDateSequence: careplan.duration.startDateSequence.label,
              endDateSequence: careplan.duration.endDateSequence.label,
            });
          return (
            <ShowCaretaskData
              caretaskName={careplan.label}
              caretask={careplan}
              allCareplans={nodes}
              startText={startText}
              occurrence={occurrence}
              endText={endText}
            />
          );
        })}
      </Flex>
    );
  };
  return (
    <Box width="100%">
      {!isLoading && (
        <Flex
          justifyContent="center"
          alignItems="baseline"
          otherStyles={{
            width: '100%',
            height: '100%',
            position: 'inherit',
          }}
        >
          <Flex
            flexDirection="column"
            otherStyles={{
              height: 'inherit',
              padding: '26px 0 0 40px',
              flexBasis: '30%',
            }}
          >
            <Flex
              flexDirection="column"
              otherStyles={{
                borderRight: `1px solid ${Greys.LIGHT_GREY_20}`,
                height: 'inherit',
                padding: '26px 0 0 40px',
              }}
            >
              <ComponentWithLabel
                id="pathwayName"
                htmlFor="pathwayName"
                labelText={t('newCareplan_view.carePathway')}
              >
                <span className="carepathway-name-label">
                  {settings.carePathwayName}
                </span>
              </ComponentWithLabel>
              <ComponentWithLabel
                id="pathwayIcds"
                htmlFor="pathwayIcds"
                labelText={t('task.ICDReference')}
              >
                <ICDViewer icds={settings.icds} />
              </ComponentWithLabel>
              <ComponentWithLabel
                id="pathwayCareplans"
                htmlFor="pathwayCareplans"
                labelText={t(
                  'carePathway_builder.careTask_list.parent_Caretask'
                )}
              >
                {sortedCaretasksByStartingTime.length}
              </ComponentWithLabel>
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            otherStyles={{
              flexBasis: '70%',
              padding: '26px 0 0 40px',
            }}
          >
            <div className="carepathway_historial-layout">
              <Flex
                otherStyles={{
                  fontWeight: 600,
                  fontSize: 14,
                  marginBottom: 16,
                }}
              >
                {t('newCareplan_view.view_carePathwayTemplateSequence')}
              </Flex>
              <RenderCareplansDuration />
            </div>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default PathWayReviewStepPreview;
