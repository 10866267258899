import React, { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Box } from '@ui-common-files/components';
import CustomBar from './ChartElements/CustomBar/CustomBar';
import CustomTooltip from './ChartElements/CustomTooltip/CustomTooltip';

import useOverViewDataChartService from '../OverviewDataChart/OverviewDataChart.service';
import useOverviewDataCardService from '../OverviewDataCard/OverviewDataCard.service';

import { getChartHeight } from './OverviewMedicationChart.utils';

import { useOverviewStore } from '../Overview.store';
import { BAR_HEIGHT } from './OverviewMedicationChart.constant';

import './OverviewMedicationChart.styles.scss';

const OverviewMedicationChart = () => {
  const {
    getTicks,
    tickFormatter,
    xAxisIntervalRenderer,
  } = useOverViewDataChartService();
  const { isChatOpened } = useOverviewDataCardService();

  const { medicationData } = useOverviewStore(
    useShallow(state => ({
      medicationData: state.medicationData || [],
    }))
  );
  const [activeIndex, setActiveIndex] = useState(null);

  const showTooltip = (data, index) => {
    setActiveIndex(index);
  };

  const chartHeight = getChartHeight(medicationData);

  return (
    <Box
      className="overview-medication_chart"
      otherStyles={{ overflowY: 'auto' }}
    >
      <ResponsiveContainer height={chartHeight}>
        <BarChart
          data={medicationData}
          layout="vertical"
          margin={{ top: 10, right: 5, left: 12 }}
        >
          <XAxis
            dataKey="date"
            interval={xAxisIntervalRenderer(isChatOpened)}
            type="number"
            domain={['auto', 'auto']}
            scale="time"
            ticks={getTicks}
            tickFormatter={tick => tickFormatter(tick)}
            hide={true}
          />
          <YAxis
            yAxisId={0}
            type="category"
            axisLine={false}
            tickLine={false}
            hide={true}
          />
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <Tooltip
            wrapperStyle={{
              visibility: activeIndex !== null ? 'visible' : 'hidden',
              position: 'absolute',
              pointerEvents: 'none',
            }}
            cursor={false}
            content={<CustomTooltip />}
            filterNull={false}
          />
          <Bar
            dataKey={'toDate'}
            barSize={BAR_HEIGHT}
            isAnimationActive={false}
            shape={CustomBar}
            onMouseOver={showTooltip}
            onMouseMove={showTooltip}
            onMouseOut={() => setActiveIndex(null)}
          >
            {medicationData.map((entry, index) => (
              <Cell key={`cell-${index}`} cursor="pointer" />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default OverviewMedicationChart;
