import { ApiClient } from '@global/Services';
import serviceConfig from '../../../../serviceConfig.json';

export const getStandardValues = (signal, query) => {
  return ApiClient.GET({
    signal,
    url: serviceConfig.careplanService.getStandardValues.uri,
    payload: query,
  });
};
