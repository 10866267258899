import { v4 as uuidv4 } from 'uuid';
import {
  Edge,
  Node,
  addEdge,
  Connection,
  EdgeChange,
  NodeChange,
  updateEdge,
  applyNodeChanges,
  applyEdgeChanges,
} from 'reactflow';

import { Caretask } from '@type/Caretask';
import { getDurationObjectForCareplan } from '@ui-common-files/utils';
import { Institution } from '@type/Institution';
import IcdOps from '@type/Common/IcdOps.type';
import {
  SetType,
  GetType,
  ErrorObject,
  CareTaskStore,
  TransformedEdge,
  TransformedNode,
  SidebarContentType,
  PathWayReviewParams,
  CarePathWayBuilderState,
} from './CarePathWayBuilder.type';

import {
  getChildNode,
  getEdgeColor,
  getEdgeMarkerEnd,
  getTransformedDurationObject,
} from './CarePathWayBuilder.utils';
import { formatTime } from './Components/CareTaskNode/CareTaskNode.utils';
import { getDurationText } from './PanelSidebar/CareTaskConfiguration/CareTaskConfiguration.utils';
import { isValidDepth } from './CarePathWayBuilder.validations';
import { STORE_NAME } from './CarePathWayBuilder.constant';

export const setCareTask = (set: SetType) => {
  return (careTaskObject: CareTaskStore) =>
    set(
      {
        careTask: {
          ...careTaskObject,
        },
      },
      false,
      `${STORE_NAME}-SET_CARE_TASK`
    );
};

export const updateSidebarVisibility = (set: SetType, get: GetType) => {
  return (
    state: boolean,
    contentType: SidebarContentType = '',
    configurationNodeId: string = ''
  ) =>
    set(
      {
        sidebar: {
          ...get().sidebar,
          isVisible: state,
          contentType,
          configurationNodeId,
        },
      },
      false,
      `${STORE_NAME}-UPDATE_SIDEBAR_VISIBILITY`
    );
};

export const setIsAddingParent = (set: SetType) => {
  return (isParent: boolean) =>
    set(
      {
        isAddingParent: isParent,
      },
      false,
      `${STORE_NAME}-SET_IS_ADDING_PARENT`
    );
};

export const addNode = (set: SetType, get: GetType) => {
  return (
    isRoot: boolean,
    nodeId: string | null,
    careTaskData: Caretask,
    x: number,
    y: number,
    removeNode: (node: Node) => void,
    toggleNodeType: (id: string) => void,
    updateNodeConfiguration: (id: string, configuration) => void,
    validateNodeConfiguration: (id: string, overlappedWith: string) => void
  ) => {
    const nodeUUID: string = nodeId || uuidv4();
    const durationDefault = getDurationObjectForCareplan(careTaskData);

    const durationObject =
      nodeId && isRoot ? getDurationObjectForCareplan(careTaskData) : null;
    let duration = null;
    if (durationObject) {
      const durationText = getDurationText(durationObject);
      duration = {
        ...durationObject,
        occurrence: durationText.occurrence,
        durationText,
      };
    }

    return set(
      {
        nodes: get().nodes.concat({
          id: nodeUUID,
          type: 'careTaskNode',
          position: { x, y },
          className: isRoot ? 'parent-node' : 'child-node',
          data: {
            label: careTaskData.name,
            isParent: isRoot,
            careTaskData,
            duration: duration ? getTransformedDurationObject(duration) : null,
            durationDefault: durationDefault
              ? getTransformedDurationObject(durationDefault)
              : null,
            openConfiguration: (sidebarType: SidebarContentType) => {
              get().updateSidebarVisibility(true, sidebarType, nodeUUID);
            },
            removeNode: (node: Node) => {
              removeNode(node);
            },
            toggleNodeType: (id: string) => {
              toggleNodeType(id);
            },
            updateNodeConfiguration: (id: string, configuration) => {
              updateNodeConfiguration(id, configuration);
            },
            validateNodeConfiguration: (id: string, overlappedWith: string) => {
              validateNodeConfiguration(id, overlappedWith);
            },
          },
        }),
      },
      false,
      `${STORE_NAME}-ADD_NODE`
    );
  };
};

export const removeEdge = (set: SetType, get: GetType) => {
  return (id: string) =>
    set(
      {
        edges: get().edges.filter(e => e.id !== id),
      },
      false,
      `${STORE_NAME}-REMOVE_EDGE`
    );
};

export const onNodesChange = (set: SetType, get: GetType) => {
  return (changes: NodeChange[]) =>
    set(
      {
        nodes: applyNodeChanges(changes, get().nodes),
      },
      false,
      `${STORE_NAME}-ON_NODES_CHANGE`
    );
};

export const onEdgesChange = (set: SetType, get: GetType) => {
  return (changes: EdgeChange[]) =>
    set(
      {
        edges: applyEdgeChanges(changes, get().edges),
      },
      false,
      `${STORE_NAME}-ON_EDGES_CHANGE`
    );
};

export const edgeUpdate = (set: SetType, get: GetType) => {
  return (oldEdge: Edge, newConnection: Connection) => {
    if (
      !isValidDepth(
        newConnection.source,
        newConnection.target,
        get().nodes,
        get().edges,
        newConnection
      )
    )
      return false;

    const edgeColor = getEdgeColor(newConnection.sourceHandle);

    return set(
      {
        edges: updateEdge(
          {
            ...oldEdge,
            style: { stroke: edgeColor },
            markerEnd: getEdgeMarkerEnd(edgeColor),
          },
          newConnection,
          get().edges,
          { shouldReplaceId: false }
        ),
      },
      false,
      `${STORE_NAME}-EDGE_UPDATE`
    );
  };
};

export const onConnect = (set: SetType, get: GetType) => {
  return (connection: Connection) => {
    if (
      !isValidDepth(
        connection.source,
        connection.target,
        get().nodes,
        get().edges,
        connection
      )
    )
      return false;

    const edgeColor = getEdgeColor(connection.sourceHandle);

    return set(
      {
        edges: addEdge(
          {
            ...connection,
            id: uuidv4(),
            type: 'careTaskEdge',
            style: { stroke: edgeColor },
            markerEnd: getEdgeMarkerEnd(edgeColor),
            data: {
              removeEdge: get().removeEdge,
            },
          },
          get().edges
        ),
      },
      false,
      `${STORE_NAME}-ON_CONNECT`
    );
  };
};

export const setSettings = (set: SetType, get: GetType) => {
  return (key: string, value: string | Object | IcdOps[]) =>
    set(
      {
        settings: {
          ...get().settings,
          [key]: value,
        },
      },
      false,
      `${STORE_NAME}-SET_SETTINGS`
    );
};

export const setPathWayReview = (set: SetType) => {
  return (params: PathWayReviewParams) => {
    return set(
      {
        pathWayReview: {
          ...params,
        },
      },
      false,
      `${STORE_NAME}-SET_PATHWAY_REVIEW_MODAL`
    );
  };
};

export const addError = (set: SetType, get: GetType) => {
  return (error: ErrorObject) => {
    const { errors } = get();
    const isMessageExist: boolean = errors.some(err => err.id === error.id);

    return set(
      {
        errors: isMessageExist ? errors : errors.concat(error),
      },
      false,
      `${STORE_NAME}-ADD_ERROR`
    );
  };
};

export const removeAnError = (set: SetType, get: GetType) => {
  return (id: string) =>
    set(
      {
        errors: get().errors.filter(error => error.id !== id),
      },
      false,
      `${STORE_NAME}-REMOVE_AN_ERROR`
    );
};

export const resetErrors = (set: SetType) => {
  return () =>
    set(
      {
        errors: [],
      },
      false,
      `${STORE_NAME}-RESET_ERRORS`
    );
};

export const hasErrorBasedOnId = (get: GetType) => {
  return (id: string) => get().errors.some(error => error.id === id);
};

export const transformPathWayObject = (get: GetType) => {
  return (institutions: Institution[]) => {
    const { settings, nodes, edges } = get();
    return {
      carePathwayTemplateName: settings.carePathwayName,
      expirationDate: null,
      institutionIds: institutions.map(institution => institution.id),
      icdIds: settings.icds.map(icd => icd.id),
      templateList: nodes
        .filter(node => node.data.isParent)
        .map(node => {
          const transformedNodes: TransformedNode[] = getChildNode(
            edges,
            nodes,
            node.id
          );

          const transformedEdges: TransformedEdge[] = [];
          const isLinkable = transformedNodes.length !== 0;
          transformedNodes.unshift({
            id: node.id,
            parentId: null,
            templateId: node.data.careTaskData.id,
            metadata: { position: node.position },
          });

          if (transformedNodes.length > 0) {
            transformedNodes.forEach(n => {
              edges
                .filter(e => e.source === n.id)
                .forEach(edge =>
                  transformedEdges.push({
                    id: edge.id,
                    source: edge.source,
                    target: edge.target,
                    conditionTypeId: 1,
                    condition: edge.sourceHandle,
                  })
                );
            });
          }

          return {
            id: node.data.careTaskData.id,
            appointmentTypeId: settings.appointmentType.value,
            duration: {
              ...node.data.duration,
              startTime: formatTime(node.data.duration.startTime),
              occurrenceCount: node.data.duration.occurrenceCount.value,
              referenceType: { ...settings.appointmentType },
            },
            nodes: transformedNodes,
            edges: transformedEdges,
            isLinkable,
            label: node.data.label,
          };
        }),
    };
  };
};

export const setPathWayTemplate = (set: SetType) => {
  return (pathWayTemplate: object) => {
    return set(
      {
        settings: {
          carePathwayName: pathWayTemplate.name,
          appointmentType: {
            value:
              pathWayTemplate.templates[0].carePathwayTemplateAppointmentType[0]
                .appointmentTypeId,
          },
          icds: pathWayTemplate.icds,
        },
      },
      false,
      `${STORE_NAME}-SET_PATHWAY_TEMPLATE`
    );
  };
};

export const resetBuilder = (
  set: SetType,
  initialState: CarePathWayBuilderState
) => {
  return () => set(initialState, false, `${STORE_NAME}-RESET_PATHWAY_TEMPLATE`);
};
