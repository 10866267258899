import React, { useRef, useState, useEffect } from 'react';
import I18n from 'i18next';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { CSVLink } from 'react-csv';
import serviceConfig from '../../../serviceConfig.json';
import ModalFooter from '../common/Layout/Modal/ModalFooter';
import { ShowFlashMessage } from '@global/Services';
import { FlashMessageTypes } from '@ui-common-files/types';

const OncoboxImportModalFooter = ({
	closeModal,
	setIsLoading,
	isLoading,
	oncoBoxFile,
	oncoBoxErrors
}) => {

	const downloadTrigger = useRef(null);
	const [importedData, setImportedData] = useState([]);
	const importOncoboxData = () => {
		try {
			if (!oncoBoxErrors || !Object.keys(oncoBoxErrors).length) {
				if (oncoBoxFile.name && !isLoading) {
					if (oncoBoxFile.data) {
						setIsLoading(true);
						axios.post(
							serviceConfig.brokerService.importOncoboxData.uri,
							{
								data: oncoBoxFile.data
							}
						).then((response: AxiosResponse) => {
							if (response) {
								if (response.data.content.failedPatients > 0) {
									let arrayOfPatients: [string[]] = [['Patient Id']];
									response.data.data.forEach((patient: string) => {
										arrayOfPatients.push([`${patient} \r`])
									})
									setImportedData(arrayOfPatients);
								}
								setIsLoading(false);
								closeModal(false)
								let hideLeftIcon = false
								if (response.data.type === 'warning') {
								  hideLeftIcon = true
								}
								if (typeof response.data.content === 'object') {
									ShowFlashMessage({
										type: response.data.type,
										message: I18n.t('patients_view.oncoBoxImportSuccess', { result: response.data.content }),
										isModalMessage: false,
										autoHide: false,
									});
								} else {
									ShowFlashMessage({
										type: response.data.type,
										message: I18n.t(response.data.content),
										isModalMessage: false,
										autoHide: false,
									});
								}
							}

						}).catch((error: Error | AxiosError) => {
							setIsLoading(false);
							closeModal(false)
							ShowFlashMessage({
								type: FlashMessageTypes.Error,
								message: I18n.t(error?.response?.data?.content?.message || error?.response?.data?.content || 'patients_view.oncoBoxImportFail'),
								isModalMessage: false,
							});
						})
					} else {
						ShowFlashMessage({
							type: FlashMessageTypes.Error,
							message: I18n.t('patients_view.xmlEmpty'),
							isModalMessage: false,
						});
					}
				}
			}
		} catch (error) {
			setIsLoading(false);
			closeModal(false)
			ShowFlashMessage({
				type: FlashMessageTypes.Error,
				message: I18n.t('patients_view.oncoBoxImportFail'),
				isModalMessage: false,
			});
		}
	};

	useEffect(() => {
		if (downloadTrigger.current && importedData.length > 0)
			downloadTrigger.current.link.click();
	}, [importedData.length]);

	return (
		<ModalFooter
			close={() => {
				closeModal(isLoading);
			}}
			additionalFunctionality={
				<CSVLink
					ref={downloadTrigger}
					style={{ display: 'none' }}
					filename={`failedPatients.csv`}
					data={importedData}
				/>
			}
			labelCTA={I18n.t('common_buttons.import')}
			form="form__export-results-patients"
			onClickCTA={() => {

				importOncoboxData();
			}}
			disabled={!oncoBoxFile.name || isLoading || (oncoBoxErrors && Object.keys(oncoBoxErrors).length)}
			isOncoBox={true}
		/>
	);
};

export default OncoboxImportModalFooter;
