/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import moment from 'moment';

import { HideFlashMessage } from '@global/Services';

import '../../css/user.css';

import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';

import {
  PatientModalShowContent,
  PatientModalShowFooter,
} from './ShowPatienComponents';

const ShowPatient = ({
  data,
  show,
  onClose,
  healthScore,
  scoringSchemeCategory,
}) => {
  const [patient, setPatient] = useState({
    firstName: '',
    lastName: '',
    medicalRecord: '',
    pseudonymCode: '',
    birthDate: '',
    email: '',
    mobile: '',
    gender: '',
    room: '',
    ward: '',
    bed: '',
    inHousePatient: '',
    sites: [],
    icds: [],
    ops: [],
    profilePicture: '',
    address: '',
    city: '',
    postalCode: '',
    country: '',
    InsuranceType: '',
    InsuranceCompany: '',
    InsuranceNumber: '',
    caseNumber: '',
  });

  useEffect(() => {
    const InitModal = () => {
      const patientObj = data.patient;
      setPatient({
        firstName: patientObj.firstname,
        lastName: patientObj.lastname,
        medicalRecord: patientObj.medicalRecord,
        pseudonymCode: patientObj.pseudonymCode
          ? patientObj.pseudonymCode
          : I18n.t('patients_view.na'),
        birthDate: moment(patientObj.birthdate).format('YYYY-MM-DD'),
        age: patientObj.age || I18n.t('patients_view.na'),
        email: patientObj.email || I18n.t('patients_view.na'),
        mobile: patientObj.mobileNumber || I18n.t('patients_view.na'),
        gender:
          patientObj.gender === 'Female'
            ? I18n.t('patients_view.gender_option2')
            : patientObj.gender === 'Male'
            ? I18n.t('patients_view.gender_option1')
            : I18n.t('patients_view.na'),
        room: patientObj.room,
        ward: patientObj.ward,
        bed: patientObj.bed,
        inHousePatient:
          patientObj.inHousePatient === true
            ? I18n.t('common_labels.yes')
            : I18n.t('common_labels.no'),
        sites: patientObj.institutions || [],
        icds: patientObj.icds || [],
        ops: patientObj.ops || [],
        profilePicture: patientObj.profilePicture,
        address: patientObj.address || I18n.t('patients_view.na'),
        city: patientObj.city || I18n.t('patients_view.na'),
        postalCode: patientObj.postalCode || I18n.t('patients_view.na'),
        country: patientObj.country || I18n.t('patients_view.na'),
        InsuranceType: patientObj.InsuranceType || I18n.t('patients_view.na'),
        InsuranceCompany:
          patientObj.InsuranceCompany || I18n.t('patients_view.na'),
        InsuranceNumber:
          patientObj.InsuranceNumber || I18n.t('patients_view.na'),
        caseNumber: patientObj.caseNumber || I18n.t('patients_view.na'),
        isBirthdayHardcoded: patientObj.isBirthdayHardcoded,
      });
    };
    if (show === true) InitModal();
  }, [show]);

  const modalTitle = () => {
    const headTitle = I18n.t('patients_view.label_showPatient');
    if (data.isPatientDemographicData) {
      return `${headTitle}: ${patient.medicalRecord}`;
    } else {
      return `${headTitle}: ${patient.firstName} ${patient.lastName}`;
    }
  };

  return (
    <Modal
      title={modalTitle()}
      contentComponent={
        <PatientModalShowContent
          patient={patient}
          logs={data.ownershipLogs}
          isPatientDemographicData={data.isPatientDemographicData}
          healthScore={healthScore}
          scoringSchemeCategory={scoringSchemeCategory}
        />
      }
      footerComponent={<PatientModalShowFooter close={onClose} />}
      openModal={show}
      onClose={() => {
        onClose();
      }}
      hideFlashMessage={HideFlashMessage}
    />
  );
};

ShowPatient.propTypes = {
  data: PropTypes.shape.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { ShowPatient };
