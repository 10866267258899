import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import Icon from '../Icon/Icon';
import Checkbox from '../Checkbox/Checkbox';
import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';
import Box from '../Box/Box';
import Flex from '../Flex/Flex';

import { ButtonSize, ButtonType ,ButtonVariant} from '../../utils/button';
import inputField from '../../utils/inputField';

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsComponentVisible(false);
      }
    }

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return { ref, isComponentVisible, setIsComponentVisible };
}

const ShareButtonDropdown = ({
  items,
  buttonLabel,
  buttonType,
  variant,
  labelHeader,
  onClick,
  isDisabled,
  handleSiteSelectedChange,
  clearCheckedSites,
}) => {
  const [searchTerm, setSearchTerm] = useState('');  

  const searchedSites =!searchTerm
    ? items
    : items.filter(({ site }) =>
        site.toLowerCase().includes(searchTerm.toLowerCase())
      );

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);


  useEffect(() => {
    if (!isComponentVisible) {
      clearCheckedSites();
      setSearchTerm('');
    }
  }, [isComponentVisible])

  const displayMenuByButtonClick = () =>
    setIsComponentVisible(!isComponentVisible);

  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="dropdown" ref={ref}>
      <div className="dropdown__container">
        <Button
          label={buttonLabel}
          type={buttonType}
          variant={variant}
          disabled={isDisabled}
          size={ButtonSize.MD}
          onClick={displayMenuByButtonClick}
        >
          <Icon name="share" size={20} />
        </Button>
        {isComponentVisible ? (
          <ul className="share-button-dropdown">
            <div style={{ padding: '20px 16px 0 16px' }}>
              <span className="share-button-dropdown__header-info">
                {labelHeader}
              </span>
              <Box margin="12px 0">
                <TextInput
                  id="searchTerm"
                  name="searchTerm"
                  placeholder="Search"
                  value={searchTerm}
                  variant={inputField.variant.WITH_ICON}
                  iconName="search"
                  isLeftIcon
                  handleOnChange={handleSearch}
                />
              </Box>
              <div
                style={{
                  maxHeight: '302px',
                  overflow: 'auto',
                  minHeight: '318px',
                  marginBottom: '16px',
                }}
              >
                {searchedSites
                  ? searchedSites.map(({ key, site, isChecked }) => (
                      <li key={key} className="share-button-dropdown__items">
                        <Checkbox
                          id={site}
                          value={site}
                          label=""
                          handleOnChange={e => {
                            handleSiteSelectedChange(key, e.target.checked);
                          }}
                          checked={isChecked}
                      />
                        <span
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '200px',
                            textOverflow: 'ellipsis',
                          }}
                          title={site}
                        >
                          {site}
                        </span>
                      </li>
                    ))
                  : null}
              </div>
            </div>
            <Flex
              justifyContent="center"
              alignItems="center"
              otherStyles={{
                padding: '12px 16px 16px',
                boxShadow: '1px 0px 8px rgba(50, 50, 50, 0.1)',
                background:'#fff'                
              }}
            >
              <Box margin="0 12px 0 0" width="50%">
                <Button
                  label={I18n.t('common_buttons.cancel')}
                  variant={ButtonVariant.OUTLINED}
                  onClick={() => {
                    setIsComponentVisible(false);
                  }}
                  fullWidth
                />
              </Box>
              <Box width="50%">
                <Button
                  label={I18n.t('actions.share')}
                  onClick={() => {
                    setIsComponentVisible(false);
                    onClick();
                  }}
                  fullWidth
                  disabled={searchedSites && !searchedSites.some(({isChecked})=>isChecked)}
                  />
                </Box>
            </Flex>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

ShareButtonDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      site: PropTypes.string,
      isChecked: PropTypes.bool,
    })
  ).isRequired,
  labelHeader: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonType: PropTypes.oneOf([
    ButtonType.BUTTON,
    ButtonType.SUBMIT,
    ButtonType.RESET,
  ]),
  variant: PropTypes.oneOf([ButtonVariant.CONTAINED, ButtonVariant.OUTLINED]),
  buttonLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  handleSiteSelectedChange: PropTypes.func.isRequired,
};

ShareButtonDropdown.defaultProps = {
  buttonType: ButtonType.BUTTON,
  variant: ButtonVariant.CONTAINED,
  isDisabled: false,
};

export default ShareButtonDropdown;
