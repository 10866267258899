import I18n from 'i18next';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  Dropdown,
  Icon,
  IconButton,
  Label,
  RadioButton,
  Slider,
  Tag,
  Flex,
} from '@ui-common-files/components';

import {
  ActionColors,
  PrimaryColor,
  slider,
  TagsType,
  CheckBoxRadioVariants,
} from '@ui-common-files/utils';

import { nonMedical } from '../../../../config';
import '../../../css/careplanComponent.scss';
import {
  renderTagsForChoices,
  renderTagsForNumericCompononet,
} from '../LogicJump/logicJumpTags';
import componentTypeOrder from '../careplanComponentTypeOrder';
import DatepickerThresholdComponent from './DatepickerThresholdComponent';
import DropdownInputComponent from './DropdownInputComponent';
import defaultEnums from '../defaultEnums';

export const NumericComponent = ({ component, emptyThresholds }) => {
  const { careplanComponentTypeId } = component[0];
  const tempComponent = component[1];
  const isFloatingNumber = component[0].decimal;
  const jumps = component[2];
  return (
    <>
      {careplanComponentTypeId === componentTypeOrder.NUMERIC_RANGE && (
        <div style={{ height: '140px', marginTop: 24 }}>
          <Slider
            id="slider"
            name="Name"
            value={Math.ceil(
              (parseFloat(component[0].minimumValue) +
                parseFloat(component[0].maximumValue)) /
                2
            )}
            min={component[0].minimumValue}
            max={component[0].maximumValue}
            minLabel={component[0].minLabel}
            maxLabel={component[0].maxLabel}
            onInput={() => {}}
            onChange={() => {}}
            variant={slider.variant.NON_EDITABLE}
          />
        </div>
      )}
      {isFloatingNumber && (
        <div style={{ height: '40px' }}>
          <span className="floatingTextSpan">
            {I18n.t('previewCareplanComponent.floatingPointNumber')}
          </span>
        </div>
      )}
      {!emptyThresholds &&
        (nonMedical == false || nonMedical == 'false') &&
        tempComponent.map((currValue, index) => (
          <Flex key={`numericComponent-${index}-${careplanComponentTypeId}`} alignItems="center">
            <Box margin="0 5px 0 0">
              <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
            </Box>
            <DropdownInputComponent
              isDisabled
              onChange={() => {}}
              component={currValue}
              id={`default-${index}`}
              extendNumberInputWidth
            />
          </Flex>
        ))}
      {jumps &&
        jumps.isJumpSet &&
        jumps.logicJumpCollection &&
        jumps.logicJumpCollection.length > 0 &&
        renderTagsForNumericCompononet(jumps)}
    </>
  );
};

export const EditNumericComponent = ({
  component,
  onChange,
  emptyThresholds,
}) => {

  const [values, setValues] = useState(null);

  useEffect(() => {
    const componentTypeMapping = {
      [componentTypeOrder.NUMERIC_RANGE]: {
        max: defaultEnums.NUMERIC_RANGE_MAX_VALUE,
        min: defaultEnums.NUMERIC_RANGE_MIN_VALUE
      },
      [componentTypeOrder.NUMERIC_VALUE]: {
        max: defaultEnums.NUMERIC_VALUE_MAX_VALUE,
        min: defaultEnums.NUMERIC_VALUE_MIN_VALUE
      }
    };

    const componentType = component[0]?.careplanComponentTypeId;
    const valuesForComponentType = componentTypeMapping[componentType];

    setValues(valuesForComponentType || null);
  }, [component]);

  const tempComponent = component[1];
  const newThresholds = [...component[1]];
  const threshold = [...component];
  const { careplanComponentTypeId } = component[0];
  const isFloatingNumber = component[0].decimal;
  const jumps = component[2];
  return (
    <>
      {careplanComponentTypeId === componentTypeOrder.NUMERIC_RANGE && (
        <div style={{ height: '140px', marginTop: 24 }}>
          <Slider
            id="specialId"
            name="Name"
            value={Math.ceil(
              (Number(component[0].minimumValue) +
                Number(component[0].maximumValue)) /
                2
            )}
            min={component[0].minimumValue}
            max={component[0].maximumValue}
            minLabel={component[0].minLabel}
            maxLabel={component[0].maxLabel}
            onInput={() => {}}
            onChange={() => {}}
            variant={slider.variant.NON_EDITABLE}
          />
        </div>
      )}
      {isFloatingNumber && (
        <div style={{ height: '40px' }}>
          <span className="floatingTextSpan">
            {I18n.t('previewCareplanComponent.floatingPointNumber')}
          </span>
        </div>
      )}
      {tempComponent.map((currValue, index) => {
        return (
          <Flex key={`multipleChoice-${index}-${careplanComponentTypeId}`} alignItems="center">
            <div className="threshold-icon">
              <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
            </div>
            <DropdownInputComponent
              values={values}
              isNegativeNumber={true}        
              isDisabled={false}
              isFloatingNumber={isFloatingNumber}
              onChange={value => {
                const newValue = {
                  comparison: value && value.option ? value.option.value : null,
                  numericValue: value && value.number,
                };
                newThresholds.splice(index, 1, newValue);
                threshold.splice(1, 1, newThresholds);
                onChange(threshold);
              }}
              component={currValue}
              id={`individual-${index}`}
            />
            <div className="delete-button">
              <IconButton
                id={`individual-${1}`}
                name="delete"
                size={24}
                fill={ActionColors.DELETE}
                onClick={() => {
                  tempComponent.splice(index, 1);
                  threshold.splice(1, 1, tempComponent);
                  onChange(threshold);
                }}
              />
            </div>
          </Flex>
        );
      })}
      {jumps &&
        jumps.isJumpSet &&
        jumps.logicJumpCollection &&
        jumps.logicJumpCollection.length > 0 &&
        renderTagsForNumericCompononet(jumps)}
    </>
  );
};

export const SingleChoice = ({ component, isEvaluating }) => {
  let parsedOptionWeights = [];
  const componentData = component[0];
  const { weight } = componentData;
  const { optionWeights } = componentData;
  const jumps = component[2];
  if (weight != null && optionWeights != 'null' && optionWeights != null) {
    parsedOptionWeights = JSON.parse(optionWeights).weights;
  }
  const optionsList =
    componentData.careplanComponentTypeId === componentTypeOrder.INFORMATION
      ? ['Yes', 'No']
      : componentData.optionJson.options;
  const thresholdList = component[1][0];
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  return optionsList.map((option, index) => {
    let showWeight = true;
    let optionWeight = parsedOptionWeights[index];
    if (
      (weight == 0 || weight == null) &&
      (optionWeights == 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && !optionWeight) {
      optionWeight = 0;
    }
    return (
      <Box key={`singleChoice-${index}-${componentData.careplanComponentTypeId}`} margin="5px 0" className="choiceTags">
        <Flex otherStyles={{ alignItems: 'center' }}>
          <RadioButton
            name={option}
            id={option}
            label={option}
            inputWidth={30}
            checked={false}
            handleOnChange={() => {}}
            valueInput=""
            handleInputOnChange={() => {}}
            variant={CheckBoxRadioVariants.NonEditable}
          />
          {thresholdList &&
          thresholdList.includes(option) &&
          (nonMedical == false || nonMedical == 'false') ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
          {jumps &&
            jumps.isJumpSet &&
            jumps.logicJumpCollection &&
            jumps.logicJumpCollection.length > 0 &&
            jumps.logicJumpCollection[index] &&
            renderTagsForChoices(jumps, index)}
          {showWeight && isEvaluating ? (
            <div className="threshold-component__name">
              <Tag
                text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const InformationComponent = ({ component, isEvaluating }) => {
  let parsedOptionWeights = [];
  const componentData = component[0];
  const { weight } = componentData;
  const { optionWeights } = componentData;
  const jumps = component[2];
  if (weight != null && optionWeights != 'null' && optionWeights != null) {
    parsedOptionWeights = JSON.parse(optionWeights).weights;
  }
  const optionsList =
    componentData.careplanComponentTypeId === componentTypeOrder.INFORMATION
      ? ['Yes', 'No']
      : componentData.optionJson.options;
  const thresholdList = component[1];
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  return optionsList.map((option, index) => {
    let showWeight = true;
    let optionWeight = parsedOptionWeights[index];
    if (
      (weight == 0 || weight == null) &&
      (optionWeights == 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && !optionWeight) {
      optionWeight = 0;
    }
    return (
      <Box key={`InformationComponent-${index}-${componentData.careplanComponentTypeId}`} margin="5px 0" className="choiceTags">
        <Flex otherStyles={{ alignItems: 'center' }}>
          {thresholdList.includes(option) &&
          (nonMedical == false || nonMedical == 'false') ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
          {jumps &&
            jumps.isJumpSet &&
            jumps.logicJumpCollection &&
            jumps.logicJumpCollection.length > 0 &&
            jumps.logicJumpCollection[index] &&
            renderTagsForChoices(jumps, index)}
          {showWeight && isEvaluating ? (
            <div className="threshold-component__name">
              <Tag
                text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const YesNoComponent = ({ component }) => {
  const thresholdList = component[1];
  return (
    <>
      <Box margin="5px 0">
        <Flex alignItems="center">
          <RadioButton
            name="Yes"
            id="Yes"
            label={I18n.t('common_labels.yes')}
            inputWidth={30}
            checked={false}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
          {thresholdList.includes(I18n.t('common_labels.yes')) &&
          (nonMedical == false || nonMedical == 'false') ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
      <Box margin="5px 0">
        <Flex alignItems="center">
          <RadioButton
            name="No"
            id="No"
            label={I18n.t('common_labels.no')}
            inputWidth={30}
            checked={false}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
          {thresholdList.includes(I18n.t('common_labels.no')) &&
          (nonMedical == false || nonMedical == 'false') ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
    </>
  );
};

export const EditYesNoComponent = ({
  component,
  onChange,
  emptyThresholds,
}) => {
  const componentData = component[0];
  const { id } = componentData;
  const thresholdList = component[1];
  const threshold = [...component];
  const [resp, setResp] = useState({
    yes: thresholdList.includes(I18n.t('common_labels.yes')),
    no: thresholdList.includes(I18n.t('common_labels.no')),
  });

  useEffect(() => {
    if (emptyThresholds) {
      setResp({
        yes: false,
        no: false,
      });
    }
  }, [emptyThresholds]);

  return (
    <>
      <Box margin="5px 0">
        <Flex alignItems="center">
          <RadioButton
            name="Yes"
            id={`yes_${id}`}
            label={I18n.t('common_labels.yes')}
            inputWidth={30}
            checked={resp.yes}
            variant={CheckBoxRadioVariants.THRESHOLD}
            handleOnChange={event => {
              setResp({
                yes: event.target.checked,
                no: !event.target.checked,
              });
              const newOption = [I18n.t('common_labels.yes')];
              threshold.splice(1, 1, newOption);
              onChange(threshold);
            }}
          />
          {resp.yes ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
      <Box margin="5px 0">
        <Flex alignItems="center">
          <RadioButton
            name="No"
            id={`no_${id}`}
            label={I18n.t('common_labels.no')}
            inputWidth={30}
            checked={resp.no}
            variant={CheckBoxRadioVariants.THRESHOLD}
            handleOnChange={event => {
              setResp({
                yes: !event.target.checked,
                no: event.target.checked,
              });
              const newOption = [I18n.t('common_labels.no')];
              threshold.splice(1, 1, newOption);
              onChange(threshold);
            }}
          />
          {resp.no ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
    </>
  );
};

export const EditSingleChoice = ({ component, onChange, emptyThresholds }) => {
  let parsedOptionWeights = [];
  const componentData = component[0];
  const { weight } = componentData;
  if (weight != null && componentData.optionWeights != 'null') {
    parsedOptionWeights = JSON.parse(componentData.optionWeights).weights;
  }
  const optionsList = componentData.optionJson.options;
  const { id } = componentData;
  const thresholdList = component[1].length > 0 ? component[1][0] : [];
  const threshold = [...component];
  const initialState = optionsList.map(val => {
    return thresholdList.length > 0 ? thresholdList.includes(val) : false;
  });
  const [check, setCheck] = useState(initialState);
  useEffect(() => {
    if (emptyThresholds == true) {
      const allFalse = optionsList.map(val => {
        return false;
      });
      setCheck(allFalse);
    }
  }, [emptyThresholds]);

  useEffect(() => {
    const newThresholds = optionsList.filter(
      (val, index) => check[index] === true
    );
    const updatedThreshold = [];
    updatedThreshold.push(newThresholds);
    threshold.splice(1, 1, updatedThreshold);
    onChange(threshold);
  }, [check]);
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  return optionsList.map((option, index) => {
    let showWeight = true;
    let optionWeight = parsedOptionWeights[index];
    if (
      (weight == 0 || weight == null) &&
      (componentData.optionWeights == 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && (optionWeight == null || optionWeight == 'null')) {
      optionWeight = 0;
    }
    return (
      <Box key={`editSingleChoice-${index}-${componentData.careplanComponentTypeId}`} margin="5px 0">
        <Flex alignItems="center">
          <RadioButton
            multiSelectEnabled={true}
            label={option}
            id={`singleChoice_${option}_${id}`}
            variant={CheckBoxRadioVariants.THRESHOLD}
            checked={check[index]}
            handleOnChange={event => {
              const newCheck = [...check];
              newCheck.splice(index, 1, event.target.checked);
              setCheck(newCheck);
            }}
          />
          {check[index] && !emptyThresholds ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
          {showWeight ? (
            <div className="threshold-component__name">
              <Tag
                text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const MultipleChoice = ({ component, isEvaluating }) => {
  let parsedOptionWeights = [];
  const componentData = component[0];
  const { weight } = componentData;
  if (weight != null && componentData.optionWeights != 'null') {
    parsedOptionWeights = JSON.parse(componentData.optionWeights).weights;
  }
  const optionsList = componentData.optionJson.options;
  const thresholdList = component[1][0];
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  return optionsList.map((option, index) => {
    let showWeight = true;
    let optionWeight = parsedOptionWeights[index];
    if (
      (weight == 0 || weight == null) &&
      (componentData.optionWeights == 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && (optionWeight == null || optionWeight == 'null')) {
      optionWeight = 0;
    }
    return (
      <Box key={`multipleChoice-${index}-${componentData.careplanComponentTypeId}`} margin="5px 0">
        <Flex alignItems="center">
          <Checkbox
            id={option}
            label={option}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
            checked={false}
            handleOnChange={() => {}}
          />
          {thresholdList &&
          thresholdList.includes(option) &&
          (nonMedical == false || nonMedical == 'false') ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
          {showWeight && isEvaluating ? (
            <div className="threshold-component__name">
              <Tag
                text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const EditMultipleChoice = ({
  component,
  onChange,
  emptyThresholds,
}) => {
  let parsedOptionWeights = [];
  const componentData = component[0];
  const { weight } = componentData;
  if (weight != null && componentData.optionWeights != 'null') {
    parsedOptionWeights = JSON.parse(componentData.optionWeights).weights;
  }
  const optionsList = componentData.optionJson.options;
  const { id } = componentData;
  const thresholdList = component[1].length > 0 ? component[1][0] : [];
  const threshold = [...component];
  const initialState = optionsList.map(val => {
    return thresholdList.length > 0 ? thresholdList.includes(val) : false;
  });
  const [check, setCheck] = useState(initialState);
  useEffect(() => {
    if (emptyThresholds == true) {
      const allFalse = optionsList.map(val => {
        return false;
      });
      setCheck(allFalse);
    }
  }, [emptyThresholds]);

  useEffect(() => {
    const newThresholds = optionsList.filter(
      (val, index) => check[index] === true
    );
    const updatedThreshold = [];
    updatedThreshold.push(newThresholds);
    threshold.splice(1, 1, updatedThreshold);
    onChange(threshold);
  }, [check]);
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  return optionsList.map((option, index) => {
    let showWeight = true;
    let optionWeight = parsedOptionWeights[index];
    if (
      (weight == 0 || weight == null) &&
      (componentData.optionWeights == 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && (optionWeight == null || optionWeight == 'null')) {
      optionWeight = 0;
    }
    return (
      <Box key={`editMultipleChoice-${index}-${componentData.careplanComponentTypeId}`} margin="5px 0">
        <Flex alignItems="center">
          <Checkbox
            label={option}
            id={`multipleChoice_${option}_${id}`}
            variant={CheckBoxRadioVariants.THRESHOLD}
            checked={check[index]}
            handleOnChange={event => {
              const newCheck = [...check];
              newCheck.splice(index, 1, event.target.checked);
              setCheck(newCheck);
            }}
          />
          {check[index] && !emptyThresholds ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
          {showWeight ? (
            <div className="threshold-component__name">
              <Tag
                text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </div>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const GroupDescriptionComponent = ({ component }) => {
  const thresholdList = component[1];
  return (
    <>
      <Box margin="5px 0">
        <Flex alignItems="center">
          <RadioButton
            name="Yes"
            id="Yes"
            label={I18n.t('common_labels.enter')}
            inputWidth={30}
            checked={false}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
          {thresholdList.includes(I18n.t('common_labels.enter')) &&
          (nonMedical == false || nonMedical == 'false') ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
      <Box margin="5px 0">
        <Flex alignItems="center">
          <RadioButton
            name="No"
            id="No"
            label={I18n.t('common_labels.skip')}
            inputWidth={30}
            checked={false}
            handleOnChange={() => {}}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
          />
          {thresholdList.includes(I18n.t('common_labels.skip')) &&
          (nonMedical == false || nonMedical == 'false') ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
    </>
  );
};

export const EditGroupDescriptionComponent = ({
  component,
  onChange,
  emptyThresholds,
}) => {
  const componentData = component[0];
  const { id } = componentData;
  const thresholdList = component[1];
  const threshold = [...component];
  const [resp, setResp] = useState({
    yes: thresholdList.includes(I18n.t('common_labels.enter')),
    no: thresholdList.includes(I18n.t('common_labels.skip')),
  });

  useEffect(() => {
    if (emptyThresholds) {
      setResp({
        yes: false,
        no: false,
      });
    }
  }, [emptyThresholds]);

  return (
    <>
      <Box margin="5px 0">
        <Flex alignItems="center">
          <RadioButton
            name="Yes"
            id={`yes_${id}`}
            label={I18n.t('common_labels.enter')}
            inputWidth={30}
            checked={resp.yes}
            variant={CheckBoxRadioVariants.THRESHOLD}
            handleOnChange={event => {
              setResp({
                yes: event.target.checked,
                no: !event.target.checked,
              });
              const newOption = [I18n.t('common_labels.enter')];
              threshold.splice(1, 1, newOption);
              onChange(threshold);
            }}
          />
          {resp.yes ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
      <Box margin="5px 0">
        <Flex alignItems="center">
          <RadioButton
            name="No"
            id={`no_${id}`}
            label={I18n.t('common_labels.skip')}
            inputWidth={30}
            checked={resp.no}
            variant={CheckBoxRadioVariants.THRESHOLD}
            handleOnChange={event => {
              setResp({
                yes: !event.target.checked,
                no: event.target.checked,
              });
              const newOption = [I18n.t('common_labels.skip')];
              threshold.splice(1, 1, newOption);
              onChange(threshold);
            }}
          />
          {resp.no ? (
            <Box margin="0 0 0 5px">
              <Tag
                text={I18n.t('threshold.threshold')}
                fill={ActionColors.THRESHOLD}
                type="squared"
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
    </>
  );
};

export const PainLocationChart = ({ component }) => {
  const tempComponent =
    nonMedical == false || nonMedical == 'false' ? component[1] : [];
  return tempComponent.map((currValue, index) => (
    <Flex key={`PainLocationChart-${index}-${component[0].careplanComponentTypeId}`} alignItems="center">
      <Box margin="0 5px 0 0">
        <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
      </Box>
      <Box margin="5px 0 0 0" width="100%">
        <PainLocationChartDropdown
          placeholder=""
          isDisabled
          onChange={() => {}}
          option={currValue.key}
        />
      </Box>
    </Flex>
  ));
};

export const EditPainLocationChart = ({ component, onChange }) => {
  const tempComponent = component[1];
  const newThresholds = [...component[1]];
  const threshold = [...component];

  return (
    <>
      {tempComponent.map((currValue, index) => (
        <Flex key="editPainLocationChart" alignItems="center">
          <div className="threshold-icon">
            <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
          </div>
          <div className="pain-location__dropdown">
            <PainLocationChartDropdown
              placeholder=""
              isDisabled={false}
              onChange={val => {
                newThresholds.splice(index, 1, {
                  key: val.value,
                  value: val.label,
                });
                threshold.splice(1, 1, newThresholds);
                onChange(threshold);
              }}
              option={currValue.key}
            />
          </div>
          <div className="delete-button">
            <IconButton
              id={`individual-${index}`}
              name="delete"
              size={24}
              fill={ActionColors.DELETE}
              onClick={() => {
                tempComponent.splice(index, 1);
                threshold.splice(1, 1, tempComponent);
                onChange(threshold);
              }}
            />
          </div>
        </Flex>
      ))}
    </>
  );
};

const PainLocationChartDropdown = ({
  placeholder,
  onChange,
  option,
  isDisabled,
}) => {
  const options = [
    { value: 'head', label: I18n.t('bodyChartImage.head') },
    { value: 'occiput', label: I18n.t('bodyChartImage.occiput') },
    { value: 'shoulder', label: I18n.t('bodyChartImage.shoulder') },
    { value: 'leftChest', label: I18n.t('bodyChartImage.leftChest') },
    { value: 'rightChest', label: I18n.t('bodyChartImage.rightChest') },
    { value: 'stomach', label: I18n.t('bodyChartImage.stomach') },
    { value: 'alvus', label: I18n.t('bodyChartImage.alvus') },
    { value: 'back', label: I18n.t('bodyChartImage.bodyBack') },
    { value: 'fundament', label: I18n.t('bodyChartImage.fundament') },
    { value: 'leftUpperArm', label: I18n.t('bodyChartImage.leftUpperArm') },
    { value: 'leftLowerArm', label: I18n.t('bodyChartImage.leftLowerArm') },
    { value: 'rightUpperArm', label: I18n.t('bodyChartImage.rightUpperArm') },
    { value: 'rightLowerArm', label: I18n.t('bodyChartImage.rightLowerArm') },
    { value: 'leftLeg', label: I18n.t('bodyChartImage.leftLeg') },
    { value: 'rightLeg', label: I18n.t('bodyChartImage.rightLeg') },
    { value: 'neck', label: I18n.t('bodyChartImage.neck') },
    { value: 'throat', label: I18n.t('bodyChartImage.throat') },
    { value: 'genital', label: I18n.t('bodyChartImage.genital') },
  ];
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(
      option
        ? options.find(val => {
            return val.value === option;
          })
        : null
    );
  }, [option]);

  return (
    <Flex alignItems="center">
      <Box margin="0 5px 10px 0" width="230px">
        <Dropdown
          value={value}
          options={options}
          isDisabled={isDisabled}
          placeholder={placeholder}
          handleOnChange={val => {
            setValue(val);
            onChange(val);
          }}
          heightControl={36}
        />
      </Box>
    </Flex>
  );
};

export const DatepickerComponent = ({ component, emptyThresholds }) => {
  const tempComponent = component[1];

  return (
    <>
      {!emptyThresholds &&
        (nonMedical == false || nonMedical == 'false') &&
        tempComponent.map((currValue, index) => (
          <DatepickerThresholdComponent
            key={`datepickerComponent-${index}`}
            isDisabled
            onChange={() => {}}
            component={currValue}
            id={`default-${index}`}
          />
        ))}
    </>
  );
};

export const EditDatepickerComponent = ({ component, onChange }) => {
  let newThresholds = [...component[1]];
  let tempComponent = component[1];
  const threshold = [...component];

  if (component[1].length === 0) {
    const thresholdDatepicker = component[1].concat({
      comparison: null,
      unit: null,
      numericValue: null,
    });
    threshold.splice(1, 1, thresholdDatepicker);

    newThresholds = [...threshold[1]];
    tempComponent = threshold[1];
  }

  return (
    <>
      {(nonMedical == false || nonMedical == 'false') &&
        tempComponent.map((currValue, index) => (
          <DatepickerThresholdComponent
            key={`datepickerComponent-${index}`}
            isDisabled={false}
            onChange={value => {
              const newValue = {
                comparison:
                  value && value.periodOfTimeOptions
                    ? value.periodOfTimeOptions.value
                    : null,
                unit:
                  value && value.timeUnitOptions
                    ? value.timeUnitOptions.value
                    : null,
                numericValue: value && value.number,
              };
              newThresholds.splice(index, 1, newValue);
              threshold.splice(1, 1, newThresholds);
              onChange(threshold);
            }}
            component={currValue}
            id={`individual-${index}`}
          />
        ))}
    </>
  );
};

export const BMI = ({ component, isEvaluating, componentTypeId }) => {
  let parsedOptionWeights = [];
  const componentData = component[0];
  const { weight } = componentData;
  if (weight != null && componentData.optionWeights !== 'null') {
    parsedOptionWeights = JSON.parse(componentData.optionWeights).weights;
  }
  const optionsList = componentData.optionJson.options;
  const thresholdList = component[1][0];
  const allZeroWeight = parsedOptionWeights.every(item => item === 0);
  const jumps = component[2];
  return optionsList.map((option, index) => {
    let showWeight = true;
    let optionWeight = parsedOptionWeights[index];
    if (
      (weight === 0 || weight == null) &&
      (componentData.optionWeights === 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && (optionWeight == null || optionWeight === 'null')) {
      optionWeight = 0;
    }

    const isBloodPressure =
      componentTypeId === componentTypeOrder.BLOOD_PRESSURE;
    const bloodPressureLable = `${I18n.t(
      `bloodPressureOptions.labels.${option}`
    )}: ${I18n.t(`bloodPressureOptions.systolicValues.${option}`)} / ${I18n.t(
      `bloodPressureOptions.diastolicValues.${option}`
    )}`;

    return (
      <Box key={`multipleChoice-${index}-${componentData.careplanComponentTypeId}`} margin="5px 0">
        <Flex alignItems="center">
          {isBloodPressure ? (
            <>
              <Label
                text={bloodPressureLable}
                htmlFor={`${option}`}
                isLongText={true}
              />
              {jumps &&
                jumps.isJumpSet &&
                jumps.logicJumpCollection &&
                jumps.logicJumpCollection.length > 0 &&
                jumps.logicJumpCollection[index] &&
                renderTagsForChoices(jumps, index)}
            </>
          ) : (
            <Checkbox
              id={option}
              label={I18n.t(`bmiOptions.${option}`)}
              variant={CheckBoxRadioVariants.NON_EDITABLE}
              checked={false}
              handleOnChange={() => {}}
            />
          )}
          {thresholdList &&
            thresholdList.includes(option) &&
            (nonMedical === false || nonMedical === 'false') && (
              <div className="thresholdIconContainer">
                <Icon
                  name="threshold"
                  size={24}
                  fill={ActionColors.THRESHOLD}
                />
              </div>
            )}
          {showWeight && isEvaluating ? (
            <Box className="threshold-component__name" margin="0 0 0 5px">
              <Tag
                text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
    );
  });
};

export const EditBMI = ({ component, onChange, emptyThresholds }) => {
  let parsedOptionWeights = [];
  const componentData = component[0];
  const { weight } = componentData;
  if (weight != null && componentData.optionWeights != 'null') {
    parsedOptionWeights = JSON.parse(componentData.optionWeights).weights;
  }
  const optionsList = componentData.optionJson.options;
  const { id } = componentData;
  const thresholdList = component[1].length > 0 ? component[1][0] : [];
  const threshold = [...component];
  const initialState = optionsList.map(val => {
    return thresholdList.length > 0 ? thresholdList.includes(val) : false;
  });
  const [check, setCheck] = useState(initialState);
  useEffect(() => {
    if (emptyThresholds == true) {
      const allFalse = optionsList.map(val => {
        return false;
      });
      setCheck(allFalse);
    }
  }, [emptyThresholds]);

  useEffect(() => {
    const newThresholds = optionsList.filter(
      (val, index) => check[index] === true
    );
    const updatedThreshold = [];
    updatedThreshold.push(newThresholds);
    threshold.splice(1, 1, updatedThreshold);
    onChange(threshold);
  }, [check]);
  const allZeroWeight = parsedOptionWeights.every(item => item == 0);
  return optionsList.map((option, index) => {
    let showWeight = true;
    let optionWeight = parsedOptionWeights[index];
    if (
      (weight == 0 || weight == null) &&
      (componentData.optionWeights == 'null' || allZeroWeight)
    ) {
      showWeight = false;
    }
    if (showWeight && (optionWeight == null || optionWeight == 'null')) {
      optionWeight = 0;
    }
    const isBloodPressure =
      component[0].careplanComponentTypeId === componentTypeOrder.BLOOD_PRESSURE;
    const bloodPressureLabel = `${I18n.t(
      `bloodPressureOptions.labels.${option}`
    )}: ${I18n.t(`bloodPressureOptions.systolicValues.${option}`)} / ${I18n.t(
      `bloodPressureOptions.diastolicValues.${option}`
    )}`;
    return (
      <Box key={`editBMI-${index}-${componentData.careplanComponentTypeId}`} margin="5px 0">
        <Flex alignItems="center">
          <Checkbox
            label={isBloodPressure ? bloodPressureLabel : I18n.t(`bmiOptions.${option}`)}
            id={`bmi${option}_${id}`}
            variant={CheckBoxRadioVariants.THRESHOLD}
            checked={check[index]}
            handleOnChange={event => {
              const newCheck = [...check];
              newCheck.splice(index, 1, event.target.checked);
              setCheck(newCheck);
            }}
          />
          {check[index] && !emptyThresholds && (
            <div className="thresholdIconContainer">
              <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
            </div>
          )}
          {showWeight && (
            <div className="threshold-component__name">
              <Tag
                text={`${I18n.t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </div>
          )}
        </Flex>
      </Box>
    );
  });
};
