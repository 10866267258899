import { ApiClient } from '@global/Services';

import serviceConfig from '../../serviceConfig.json';

const ShowCareplanTemplateRequest = async (
  id,
  setCareplanDataThresholds,
  setShowData,
  setCareplanDataLogicJumps,
  setCareplanScoringScheme,
  setOwnershipLogs,
  setIsEvaluating = null
) => {
  const response = await ApiClient.GET({
    url: serviceConfig.brokerService.showCareplanTemplate.uri + id,
  });
  if (response) {
    setCareplanDataThresholds(response.data.careplanComponentsWithThresholds);
    setCareplanDataLogicJumps(response.data.careplanTemplateWithLogicJumps);
    setShowData(response.data);
    if (response.data.template.scoringScheme) {
      setCareplanScoringScheme(response.data.template.scoringScheme);
    } else {
      setCareplanScoringScheme([]);
    }
    if (setOwnershipLogs) setOwnershipLogs(response.data.ownershipLogs);
    if (setIsEvaluating) setIsEvaluating(response.data.template.isEvaluating);
  }
  return response;
};

export default ShowCareplanTemplateRequest;
