/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import I18n from 'i18next';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import {renderIcdOrOpsInfo} from './PatientHelperUtility';


const PatientProcedures = ({
  patientAdditionalInformation,
  isLoading
}) => {

  const renderPatientDetails = () => {
    return (
    <div className="overview__patient-activity-patient-details patient-details-bg-color">
      <Flex flexDirection="row" alignItems="flex-start">
      <div className="patient-name-title"> {I18n.t('hl7Attribute.sections.patientProcedures')} </div>
      </Flex>
        <div className="section-container">
            <div className="section-title-container">
            {I18n.t('common_labels.referenced_ops_code')}
            </div>
            <div className="content-text content-text-container" style={{marginTop:20}}>
            {renderIcdOrOpsInfo({processOps : true, patientAdditionalInformation})}
            </div>
        </div>
     </div> 
    );
  }

  return (
    <div>
      {isLoading && (
        <div className="pdf-loader-container">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="room-info-container">
            {patientAdditionalInformation.icds && patientAdditionalInformation.icds.length > 0 && renderPatientDetails()}
          </div>
        </>
      )}
    </div>
  );
};

PatientProcedures.propTypes = {
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default PatientProcedures;
