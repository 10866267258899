/* eslint-disable react-hooks/exhaustive-deps */

import I18n from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';

import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import RadioButton from '../../../caro-ui-commonfiles/components/RadioButton/RadioButton';
import CheckboxWarning from '../../../caro-ui-commonfiles/components/UsFilterWarnings/CheckboxWarning';
import FlashMessage from '../../../caro-ui-commonfiles/components/FlashMessage/FlashMessage';

import {
  ButtonSize,
  ButtonVariant,
} from '../../../caro-ui-commonfiles/utils/button';
import { nonMedical } from '../../../config';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import { isMainQuestion } from './CareplanHelperUtility';
import {
  renderAdditionalComponents,
  renderComponents,
} from './CareplanAdditionalComponentUtils';
import { FlashMessageTypes } from '../../../caro-ui-commonfiles/types/components';

function AssignTemplateStep3({
  careplanData,
  careplanScoringScheme,
  careplanDataThresholds,
  setCareplanDataThresholds,
  isEvaluating,
  setBottomPageReached,
  usWarningCheckboxChecked,
  setUsWarningCheckboxChecked,
  careplanLogicJumpData,
  defaultDataThresholds,
}) {
  const careplanComponentsDivWrapper = useRef(null);
  const [thresholdView, setThresholdView] = useState({
    default: true,
    individual: false,
  });
  const [reloadThreholds, setReloadThresholds] = useState(true);
  const [dataThreshold, setDataThreshold] = useState([
    ...careplanDataThresholds,
  ]);
  const [emptyThresholds, setEmptyThresholds] = useState(false);
  const [displayFlashMessage, setDisplayFlashMsg] = useState({
    display: false,
    message: '',
    type: FlashMessageTypes.Warning,
  });

  useEffect(() => {
    const divWrapper = careplanComponentsDivWrapper.current;
    if (divWrapper) {
      setBottomPageReached(
        divWrapper.scrollHeight - divWrapper.scrollTop ===
          divWrapper.clientHeight
      );
    }
  }, []);

  useEffect(() => {
    setCareplanDataThresholds(dataThreshold);
    renderComponents(
      dataThreshold.filter(component =>
        isMainQuestion(component[0].healthCategory)
      ),
      careplanLogicJumpData,
      isEvaluating,
      true,
      setDataThreshold,
      thresholdView,
      emptyThresholds
    );
  }, [dataThreshold]);

  useEffect(() => {
    if (!reloadThreholds) {
      setReloadThresholds(true);
    }
  }, [reloadThreholds]);
  const hideFlashMessage = () => {
    setDisplayFlashMsg({
      display: false,
      message: '',
    });
  };
  useEffect(() => {
    if (thresholdView.individual && careplanData.reportOccurenceNumber === 2) {
      setDisplayFlashMsg({
        display: true,
        message: I18n.t('warningMessage.assignCaretaskWithThresholds'),
        type: FlashMessageTypes.Warning,
      });
    } else {
      hideFlashMessage();
    }
  }, [thresholdView]);

  const handleScroll = e => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setBottomPageReached(true);
    } else if (
      usWarningCheckboxChecked.confirmCheckbox === false ||
      usWarningCheckboxChecked.awareCheckbox === false
    ) {
      setBottomPageReached(false);
    }
  };

  return (
    <>
      <Box width="100%">
        <ModalSection variant={MODAL_VARIANT.TOP}>
          {displayFlashMessage.display && (
            <FlashMessage
              message={displayFlashMessage.message}
              type={
                displayFlashMessage.type
                  ? displayFlashMessage.type
                  : FlashMessageTypes.Success
              }
              onClick={hideFlashMessage}
            />
          )}
        </ModalSection>
      </Box>
      <ModalForm styles={{ height: '83%' }}>
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          {(nonMedical === false || nonMedical == 'false') && (
            <div className="thresholds-view">
              <ComponentWithLabel
                id="phaseType"
                htmlFor="phaseType"
                labelText={I18n.t('threshold.thresholdNotification')}
              >
                <Box margin="15px 0 0 0">
                  <RadioButton
                    id="default"
                    name="default"
                    label={I18n.t('threshold.default_thresholds')}
                    variant="default"
                    checked={thresholdView.default}
                    handleOnChange={e => {
                      setDataThreshold(defaultDataThresholds);
                      setEmptyThresholds(false);
                      setThresholdView({
                        individual: !e.target.checked,
                        default: e.target.checked,
                      });
                      setReloadThresholds(false);
                    }}
                  />
                </Box>
                <Box margin="15px 0 0 0">
                  <RadioButton
                    id="individual"
                    name="individual"
                    label={I18n.t('threshold.individual_thresholds')}
                    variant="default"
                    checked={thresholdView.individual}
                    handleOnChange={e => {
                      setThresholdView({
                        individual: e.target.checked,
                        default: !e.target.checked,
                      });
                    }}
                  />
                </Box>
                {thresholdView.individual && (
                  <Box margin="15px 0 0 0">
                    <Button
                      variant={ButtonVariant.NO_BORDER}
                      size={ButtonSize.MD}
                      onClick={() => {
                        if (dataThreshold.length > 0) {
                          setEmptyThresholds(true);
                          const newEmptyThresholds = dataThreshold.map(
                            careplan => {
                              const careplanObj = [...careplan];
                              careplanObj[1] = [];
                              return careplanObj;
                            }
                          );
                          setDataThreshold(newEmptyThresholds);
                          setReloadThresholds(false);
                        }
                      }}
                      label={I18n.t('threshold.remove_allThresholds')}
                    />
                  </Box>
                )}
              </ComponentWithLabel>
            </div>
          )}
        </ModalSection>
        {reloadThreholds && (
          <div
            className="modal-col-70__info"
            onScroll={handleScroll}
            ref={careplanComponentsDivWrapper}
          >
            <Box padding="20px 0 0">
              {renderComponents(
                dataThreshold.filter(component =>
                  isMainQuestion(component[0].healthCategory)
                ),
                careplanLogicJumpData,
                isEvaluating,
                true,
                setDataThreshold,
                thresholdView,
                emptyThresholds
              )}
              {isEvaluating &&
                renderAdditionalComponents(
                  careplanScoringScheme,
                  dataThreshold,
                  careplanLogicJumpData,
                  isEvaluating,
                  setDataThreshold,
                  thresholdView,
                  emptyThresholds
                )}
              <Box padding="20px 20px 20px 0">
                <CheckboxWarning
                  usWarningCheckboxChecked={usWarningCheckboxChecked}
                  nonMedical={nonMedical}
                  changeAwareCheckbox={event => {
                    setUsWarningCheckboxChecked({
                      ...usWarningCheckboxChecked,
                      awareCheckbox: event.target.checked,
                    });
                  }}
                  changeConfirmCheckbox={event => {
                    setUsWarningCheckboxChecked({
                      ...usWarningCheckboxChecked,
                      confirmCheckbox: event.target.checked,
                    });
                  }}
                />
              </Box>
            </Box>
          </div>
        )}
      </ModalForm>
    </>
  );
}

export default AssignTemplateStep3;
