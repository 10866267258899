import { FilterTypes } from '../PatientFilterContext/PatientFilter.type';

export interface FilterContentProps {
  type: FilterTypes;
}

export enum ShowFilterTypes {
  Static = 'static',
  Visible = 'visible',
  Hidden = 'hidden',
}
