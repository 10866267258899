import React from 'react';
import useResizeObserver from 'use-resize-observer';

import Box from '../../../../caro-ui-commonfiles/components/Box/Box';

import {
  BmiAnswer,
  DateRequestAnswer,
  FileRequestAnswer,
  FreeTextAnswer,
  InformationAnswer,
  NumericAnswer,
  PainLocationChartAnswer,
  SectionAnswer,
  SingleChoiceAnswer,
  YesNoAnswer,
  RankingAnswer,
  BloodPressureAnswer,
} from '../../Careplans/AnswerResult';
import componentTypeOrder from '../../Careplans/careplanComponentTypeOrder';
import {
  CareplanComponent,
  CareplanComponentAnswer,
  CareplanComponentThreshold,
} from '../../../types/Careplan';
import { RenderAnswerComponentProps } from './CareplanReport.type';
import { BMIThresholds } from '../../../Global/Careplan';

const RenderAnswerComponent = ({
  component,
  componentIndex,
  allOccurrences,
  setCurrentComponentHeight,
}: RenderAnswerComponentProps): JSX.Element => {
  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      setCurrentComponentHeight(height, componentIndex);
    },
  });

  function getComponentContent() {
    const componentNumber = componentIndex + 1;

    switch (component.careplanComponentTypeId) {
      case componentTypeOrder.FREE_TEXT:
        return (
          <FreeTextAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.SINGLE_CHOICE:
      case componentTypeOrder.MULTIPLE_CHOICE:
        return (
          <SingleChoiceAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.YESNO:
        return (
          <YesNoAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.UPLOAD_FILE:
        return (
          <FileRequestAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.INFORMATION:
        return (
          <InformationAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
          />
        );
      case componentTypeOrder.GROUP_DESCRIPTION:
        return (
          <SectionAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.DATEPICKER:
        return (
          <DateRequestAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.BMI:
        return (
          <BmiAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.NUMERIC_RANGE:
      case componentTypeOrder.NUMERIC_VALUE:
        return (
          <NumericAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.PAIN_LOCATION_CHART:
        return (
          <PainLocationChartAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.SORTABLE:
        return (
          <RankingAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
      case componentTypeOrder.BLOOD_PRESSURE:
        return (
          <BloodPressureAnswer
            key={`component-${component.id}`}
            componentData={component}
            componentIndex={componentNumber}
            allOccurrences={allOccurrences}
          />
        );
    }
  }

  return <Box ref={ref}>{getComponentContent()}</Box>;
};

function getIsThreshold(
  componentData: CareplanComponent,
  answer: CareplanComponentAnswer,
  multipleChoiceKey?: string
): boolean {
  const { thresholds } = componentData;
  if (thresholds.length === 0 || !answer) return false;
  switch (componentData.careplanComponentTypeId) {
    case componentTypeOrder.GROUP_DESCRIPTION:
    case componentTypeOrder.YESNO:
      return answer.answer_boolean === thresholds[0].booleanValue;

    case componentTypeOrder.SINGLE_CHOICE:
      if (typeof thresholds[0].optionJson.optionIndex === 'string') {
        return (
          thresholds[0].optionJson.optionIndex ===
          answer.answer_json.optionIndex.toString()
        );
      } else {
        const singleChoiceThresholds =
          thresholds[0].optionJson.optionIndex.filter(
            item =>
              item.toString() === answer.answer_json.optionIndex.toString()
          );
        return singleChoiceThresholds.length > 0;
      }

    case componentTypeOrder.BMI:
      const answerBMI = parseFloat(answer.answer_bmi);
      const optionsList = componentData.optionJson.options;
      const thresholdIndex = thresholds[0].optionJson.optionIndex;
      for (const option in optionsList) {
        if (
          ((option === BMIThresholds.Underweight &&
            answerBMI > 0 &&
            answerBMI < 18.5) ||
            (option === BMIThresholds.Normal &&
              answerBMI >= 18.5 &&
              answerBMI < 25) ||
            (option === BMIThresholds.Overweight &&
              answerBMI >= 25 &&
              answerBMI < 30) ||
            (option === BMIThresholds.Obesity && answerBMI >= 30)) &&
          thresholdIndex.includes(parseInt(option))
        ) {
          return true;
        }
      }

    case componentTypeOrder.BLOOD_PRESSURE:
      const answerIndexBloodPressure = componentData.optionJson.options.indexOf(
        answer.answer_blood_pressure_value
      );
      const thresholdIndexBloodPressure = thresholds[0].optionJson.optionIndex;
      return thresholdIndexBloodPressure.includes(answerIndexBloodPressure);

    case componentTypeOrder.MULTIPLE_CHOICE:
      const multipleChoiceThresholds =
        thresholds[0].optionJson.optionIndex.filter(
          item => item.toString() === multipleChoiceKey
        );

      return multipleChoiceThresholds.length > 0;

    case componentTypeOrder.PAIN_LOCATION_CHART:
      const painLocationThresholds = thresholds.filter(
        (threshold: CareplanComponentThreshold) =>
          threshold.bodyChart === answer.bodychart
      );
      return painLocationThresholds.length > 0;

    default:
      return false;
  }
}

export { RenderAnswerComponent, getIsThreshold };
