import { useState } from 'react';
import { formatDate } from '@global/Date';
import { useInteractiveTable } from '@global/Hooks';
import { getPatientLogsByUuid } from './PatientLogs.api';

export const usePatientLogsService = reloadTable => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showErrorLog, setShowErrorLog] = useState({
    isOpen: false,
    carePlanName: '',
    errorData: {},
    deviceOffset: '',
  });

  const fetchData = async controller => {
    setIsLoading(true);
    setSearchTerm(query.value);
    const response = await getPatientLogsByUuid({
      controller,
      uuid: query.value,
      page: currentPage,
      pageSize: query.length,
    });
    if (response?.data) {
      setSearchResult(response?.data);
    }
    setIsLoading(false);
  };
  const recordsTotal = searchResult?.totalRecords;

  const { query, currentPage, handleSearch, handleTableOnChange } =
    useInteractiveTable(fetchData, reloadTable, recordsTotal);

  const patientLogsData = searchResult?.patientLogs.map(item => {
    return {
      carePlanName: item.carePlanName,
      occurrenceDate: formatDate(item.occurrenceDate, 'D MMM YYYY HH:mm'),
      isAnswered: item.isAnswered,
      sessionId: item.sessionId,
      sessionCreatedAt: formatDate(item.sessionCreatedAt, 'D MMM YYYY HH:mm'),
      seenDate: formatDate(item.seenDate, 'D MMM YYYY HH:mm'),
      errorData: item.errorData,
      deviceOffset: item.deviceOffset,
      answeredBy: `${item.answeredByFirstName} ${item.answeredByLastName}`,
      answerAt: formatDate(item.answerAt, 'D MMM YYYY'),
    };
  });

  return {
    searchTerm,
    setSearchTerm,
    searchResult,
    setSearchResult,
    isLoading,
    setIsLoading,
    currentPage,
    query,
    showErrorLog,
    setShowErrorLog,
    handleSearch,
    patientLogsData,
    recordsTotal,
    handleTableOnChange,
  };
};
