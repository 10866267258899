import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';

import {
  Box,
  Button,
  TextInput,
  FlashMessagesWrapper,
} from '@ui-common-files/components';
import { FlashMessageTypes } from '@ui-common-files/types/components';
import { ShowFlashMessage, HideFlashMessage } from '@global/Services';

import serviceConfig from '../../serviceConfig.json';

import LoginHeader from './Login/LoginHeader';
import LoginFooter from './Login/LoginFooter';
import CustomLoader from './Login/CustomLoader';

import '../css/login.scss';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [errMsgKey, setErrMsgKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const history = useHistory();

  const onChangeEmailText = event => setEmail(event.target.value);

  const handleGoHome = () => history.push('/', { logout: true });

  const sendEmail = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        serviceConfig.brokerService.forgotPassword.uri,
        {
          email,
          userLanguage: I18n.language,
        }
      );
      if (response) {
        setIsLoading(false);
        ShowFlashMessage({
          type: response.data.type,
          messageKey: response.data.content,
        });
      } else throw new Error();
    } catch (error) {
      setIsLoading(false);
      ShowFlashMessage({
        type: FlashMessageTypes.Error,
        messageKey: 'flash.unexpectedError',
      });
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    setErrMsgKey('');
    if (!email) {
      setErrMsgKey('common_labels.empty_email');
    } else if (!emailFormat.test(email)) {
      setErrMsgKey('common_labels.invalid_email');
    } else {
      sendEmail();
    }
  };

  return (
    <Box width="100%" height="100%">
      <div className="login-container">
        <FlashMessagesWrapper hideFlashMessage={HideFlashMessage} />
        <LoginHeader handleGoHome={handleGoHome} />
        <div className="login-content-container">
          <div className="credential-container">
            <form
              className="login__widget-panel__container"
              onSubmit={handleFormSubmit}
            >
              <div className="login-header__wrapper">
                <span className="login-header">
                  {I18n.t('common_labels.label_forgotPassword')}
                </span>
              </div>

              <div>
                <span className="login-info-container">
                  {I18n.t('common_labels.forgot_pswd_not_worry')}
                </span>
              </div>

              <div className="Text-inputContainer">
                <TextInput
                  id="specialId"
                  name="Name"
                  placeholder={I18n.t('common_labels.placeholder_email')}
                  hasError={!!errMsgKey}
                  rightHelperText="words"
                  iconName="message"
                  handleOnChange={onChangeEmailText}
                  value={email}
                  type="email"
                />
              </div>

              {errMsgKey && <div className="err-msg">{I18n.t(errMsgKey)}</div>}

              <div className="button-container">
                {isLoading ? (
                  <CustomLoader />
                ) : (
                  <Button
                    label={I18n.t('common_buttons.recoverPassword')}
                    type="submit"
                    fullWidth
                    size="md"
                  />
                )}
              </div>
            </form>

            <LoginFooter />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default withTranslation()(Forgot);
