import React from 'react';
import { Flex, Box, IconButton } from '@ui-common-files/components';

import CareTaskConfiguration from './CareTaskConfiguration/CareTaskConfiguration';
import CareTaskList from './CareTaskList';
import CarePathWaySettings from './CarePathWaySettings';
import LinkedCareTaskConfiguration from './LinkedCareTaskConfiguration/LinkedCareTaskConfiguration';

import usePanelSidebarService from './PanelSidebar.service';

import './PanelSidebar.styles.scss';

const PanelSidebar: React.FC = () => {
  const {
    sidebar,
    contentRef,
    panelClasses,
    contentClasses,
    updateSidebarVisibility,
  } = usePanelSidebarService();

  return (
    <Flex className={panelClasses}>
      <Box className={contentClasses} ref={contentRef}>
        <Flex className="panel-sidebar_content-close">
          <IconButton
            name="clear"
            onClick={() => {
              updateSidebarVisibility(false);
            }}
          />
        </Flex>
        {sidebar.contentType === 'care-task' && <CareTaskList />}
        {sidebar.contentType === 'configuration' && <CareTaskConfiguration />}
        {sidebar.contentType === 'settings' && <CarePathWaySettings />}
        {sidebar.contentType === 'linkedCareTask' && (
          <LinkedCareTaskConfiguration />
        )}
      </Box>
    </Flex>
  );
};

export default PanelSidebar;
