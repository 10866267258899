import React from 'react';
import { ReactFlowProvider } from 'reactflow';

import { Flex } from '@ui-common-files/components';

import CarePathWayBuilder from './CarePathWayBuilder';

const CarePathWayBuilderProvider: React.FC = () => {
  return (
    <Flex id="pathway-builder" className="pathway-builder">
      <ReactFlowProvider>
        <CarePathWayBuilder />
      </ReactFlowProvider>
    </Flex>
  );
};

export default CarePathWayBuilderProvider;
