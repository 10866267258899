import moment from 'moment';
import { getAllTodoAssessments } from './Todos.api';
import { ComponentTypes } from '@ui-common-files/utils';
import { Caretask } from '@type/Caretask';
import { Occurrence } from '@type/Caretask/Occurrence.type';
import { Patient } from '@type/Patient';
import { SortConfig } from '@type/DataTable';

const Rows = (
  data?: (Caretask & { patient: Patient; allOccurrences: Occurrence[] })[]
) => {
  const rows = [];
  data?.forEach(item => {
    const occurrence =
      item.allOccurrences?.length > 0
        ? item.allOccurrences[0]
        : ({} as Occurrence);
    if (item) {
      rows.push({
        patientFullName: `${item.patient?.firstname} ${item.patient?.lastname}`,
        medicalRecord: item.patient?.medicalRecord,
        pseudonymCode: item.patient?.pseudonymCode,
        careplan: item.name,
        unixEpochs: moment.unix(occurrence.unixEpochs).utc(),
        validUntil: moment
          .unix(occurrence.unixEpochs)
          .utc()
          .add(
            occurrence.lifespan,
            occurrence.lifespanUnit as unknown as moment.DurationInputArg2
          ),
        patientId: item.patient.id,
        navigationData: {
          careplanId: item.id,
          isAssessment: item.isAssessment,
          careplan: true,
          deepLink: true,
          answer: true,
          shouldOpenChat: true,
          redirectTo: ComponentTypes.TODOS,
          occurrenceId: item.allOccurrences[0].id,
        },
      });
    }
  });

  return rows;
};

export const getTodos = ({
  controller,
  query,
  setRecordsTotal,
  setFetchedData,
  setLoading,
  setRefreshCount,
  sort,
}: {
  controller: AbortController;
  query: {
    value: string;
    offset: number;
    limit: number;
  };
  setRecordsTotal: React.Dispatch<React.SetStateAction<number>>;
  setFetchedData: React.Dispatch<React.SetStateAction<any[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshCount: () => void;
  sort: SortConfig;
}) => {
  setLoading(true);
  getAllTodoAssessments(controller, {
    ...query,
    sortColumn: sort.column,
    sortDirection: sort.dir,
  })
    .then(result => {
      setFetchedData(Rows(result?.data?.assessments));
      setRecordsTotal(result?.data?.totalCount);
    })
    .finally(() => {
      setLoading(false);
      setRefreshCount();
    });
};
