/* eslint-disable no-bitwise */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import ModalSection from '../common/Layout/Modal/ModalSection';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import TextInput from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Dropdown from '../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import AddressSearch from '../../../caro-ui-commonfiles/components/molecules/AddressSearch/AddressSearch';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import maxCharLimit from '../../../caro-ui-commonfiles/utils/maxcharlimits.json';
import inputField from '../../../caro-ui-commonfiles/utils/inputField';

const ApptModalContentForm = ({
  values,
  recurrenceComponent,
  icdSelectorComponent,
  onValueChange,
  hasErrors,
  isUpdate,
}) => {
  const { dropdownAppointmentTypes } = require('../../Utils/dropdownTypes');

  return (
    <>
      <ModalSection>
        <Box margin="20px 0">
          <ComponentWithLabel
            id="appointmentType"
            htmlFor="appointmentType"
            labelText={`* ${I18n.t('appointment.type')}`}
            tooltipText={I18n.t('appointment.type')}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
          >
            <div>
              <Dropdown
                value={values.appointmentType}
                options={dropdownAppointmentTypes()}
                isDisabled={isUpdate}
                placeholder={I18n.t('appointment.select_appointmentType')}
                handleOnChange={event =>
                  onValueChange(event, 'appointmentType')}
                hasError={hasErrors.appointmentType}
              />
              {hasErrors.appointmentType && (
                <span className="patients__phone-field error">
                  {I18n.t('parsley.requiredField')}
                </span>
              )}
            </div>
          </ComponentWithLabel>
          <ComponentWithLabel
            id="name"
            htmlFor="appointmentName"
            labelText={I18n.t('appointment.title')}
            labelText={`* ${I18n.t('appointment.title')}`}
            idToggleButton="toggleBtn"
            valueToggleButton="toggleBtn"
          >
            <TextInput
              value={values.name}
              id="name"
              name="name"
              placeholder={I18n.t('appointment.add_title')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.addAppointment.appointmentTitleMaxCharLimit}
              maxChars={maxCharLimit.addAppointment.appointmentTitleMaxCharLimit}
              handleOnChange={event =>{
                onValueChange(event.target.value, 'name')
              }}
              hasError={hasErrors.name}
              validationMessage={I18n.t('appointment.atLeast5Characters')}
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="icds"
            htmlFor="icds"
            labelText={I18n.t('task.ICDReference')}
          >
            {icdSelectorComponent}
          </ComponentWithLabel>
        </Box>
      </ModalSection>

      <ModalSection>
        <Box margin="20px 0">
          <ComponentWithLabel
            id="location"
            htmlFor="location"
            labelText={`* ${I18n.t('appointment.place')}`}
          >
            <AddressSearch
              value={values.location}
              handleOnChange={event => onValueChange(event, 'location')}
              labelText={`* ${I18n.t('appointment.place')}`}
              hasError={hasErrors.location}
              validationMessage={I18n.t('parsley.requiredField')}
              placeholder={I18n.t('appointment.select address')}
            />
          </ComponentWithLabel>
          <ComponentWithLabel
            id="notes"
            htmlFor="notes"
            labelText={I18n.t('patientOverview.details')}
          >
            <TextInput
              value={values.notes}
              id="notes"
              name="notes"
              placeholder={I18n.t('patientOverview.addDetails')}
              variant={inputField.variant.CHAR_COUNT}
              maxLength={maxCharLimit.addAppointment.appointmentDetailsMaxCharLimit}
              maxChars={maxCharLimit.addAppointment.appointmentDetailsMaxCharLimit}
              handleOnChange={event =>{
                onValueChange(event.target.value, 'notes') }}
            />
          </ComponentWithLabel>
          {recurrenceComponent}
        </Box>
      </ModalSection>
    </>
  );
};

ApptModalContentForm.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  recurrenceComponent: PropTypes.objectOf(PropTypes.object).isRequired,
  icdSelectorComponent: PropTypes.objectOf(PropTypes.object).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSmsEnabled: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired,
  hasErrors: PropTypes.objectOf(PropTypes.bool).isRequired,
  isUpdate: PropTypes.bool,
};

ApptModalContentForm.defaultProps = {
  isUpdate: false,
};

export default memo(ApptModalContentForm);
