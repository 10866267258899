import React from 'react';

import { Flex, Icon } from '@ui-common-files/components';

import { Greys, lifespanEnum } from '@ui-common-files/utils';
import { formatDate } from '@global/Date';

import useDateViewerService from './DateViewer.service';

import './DateViewer.styles.scss';

const DateViewer = ({ onChange, date, filter }) => {
  const { getDateFormat, handleNext, handlePrev, isEndDateBeforeToday } =
    useDateViewerService(onChange, date, filter);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <button
        onClick={handlePrev}
        type="button"
        disabled={filter === lifespanEnum.YTD}
        className="navigation-button"
      >
        <Icon
          size={20}
          name="arrowLeft"
          fill={
            filter === lifespanEnum.YTD ? Greys.LIGHT_GREY_20 : Greys.LIGHT_GREY
          }
        />
      </button>
      <Flex className="date-text">{getDateFormat(date, filter)}</Flex>
      {isEndDateBeforeToday && (
        <button
          onClick={handleNext}
          type="button"
          disabled={filter === lifespanEnum.YTD}
          className="navigation-button"
        >
          <Icon
            size={20}
            name="arrowRight"
            fill={
              filter === lifespanEnum.YTD
                ? Greys.LIGHT_GREY_20
                : Greys.LIGHT_GREY
            }
          />
        </button>
      )}
    </Flex>
  );
};

export default DateViewer;
