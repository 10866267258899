import React from 'react';
import FilterCriteriaValueField from './FilterCriteriaValueField';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import { showOperator } from '../../../../../Utils/PatientFilter/PatientFilter';
import {
  FilterActions,
  OperatorTypes,
} from '../../../../../Utils/PatientFilter/PatientFilter.enum';
import { FilterCriteriaValueProps } from './FilterCriteriaValue.type';
import { PatientFilterSelectLabelValue } from '../../PatientFilterContext/PatientFilter.type';

const FilterCriteriaValue = ({ criteria }: FilterCriteriaValueProps) => {
  const { updateCriterias, errors } = usePatientFilterContext();

  const checkNoValueField = (check: OperatorTypes) =>
    [OperatorTypes.IsEmpty, OperatorTypes.IsNotEmpty].includes(check);

  const onChangeField =
    (position: number) => (value: PatientFilterSelectLabelValue) => {
      const criteriaValues = criteria.value;
      criteriaValues[position] = value;
      updateCriterias({
        action: FilterActions.Update,
        criteria: {
          values: { value: criteriaValues },
          position: criteria.position,
        },
      });
    };

  const operatorsShown = showOperator(criteria.field.id);
  const error = (position: number) => {
    const error = errors[`${criteria.field.id}_${criteria.position}`];
    if (Array.isArray(error)) return error[position];
    return error;
  };

  const label = criteria.field.operators.length === 1;

  return (
    <Flex
      className={`filters__criteria-field-container ${
        !operatorsShown
          ? 'filters__criteria-field-container__icdOps'
          : label && 'filters__criteria-field-container__label'
      }`}
    >
      {criteria.check &&
        !checkNoValueField(criteria.check.id) &&
        criteria.value.map((value, position) => [
          position > 0 && <div className="filters__criteria-separator" />,
          <FilterCriteriaValueField
            field={criteria.field.id}
            type={criteria.field.type}
            value={value}
            onChange={onChangeField(position)}
            key={position}
            error={error(position)}
            options={criteria.field.values || []}
            multipleInputs={criteria.value.length > 1}
            check={criteria.check}
          />,
        ])}
    </Flex>
  );
};

export default FilterCriteriaValue;
