import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const BreadcrumbPortal = ({ children }) => {
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  return domReady
    ? ReactDOM.createPortal(
        <>{children}</>,
        document.getElementById('breadcrumb-wrapper')
      )
    : null;
};

export default BreadcrumbPortal;
