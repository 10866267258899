import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  SingleSelectQuestionSearchable,
  TextInput,
  Dropdown,
  DatePicker,
  IcdSelector,
} from '@ui-common-files/components';

import {
  inputField,
  MaxCharLimits,
  getUTCDateFromLocalDate,
  modification,
} from '@ui-common-files/utils';
import { fetchIcdsOrOpsData } from '../../../../../Utils/PatientOverview/patientOverviewHelper';
import { FieldTypes } from '../../PatientFilterContext/PatientFilter.type';
import {
  FilterCriteriaOptions,
  FilterCriteriaValueFieldProps,
} from './FilterCriteriaValue.type';
import { FieldIdType } from '../FilterCriteriaFieldSelector/FilterCriteriaFieldSelector.type';
import { getCareplanComponentsByType } from '../FilterCriteriaComponentAnswer/FilterCriteriaComponentAnswer.api';
import {
  getFilterCareplanNames,
  getFilterInsuranceTypes,
} from './FilterCriteriaValueField.api';

function FilterCriteriaValueField({
  type,
  value,
  onChange,
  options = [],
  multipleInputs,
  check,
  error,
  field,
}: FilterCriteriaValueFieldProps) {
  // Caretask name options
  const [caretasks, setCaretasks] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [componentNames, setComponentNames] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      getFilterCareplanNames().then(response => {
        if (!response?.data) return;
        const { data } = response;
        const careplans = data.content.careplans.map(careplan => {
          const careplanCopy = { ...careplan };
          careplanCopy.label = careplan.name;
          return careplanCopy;
        });
        setCaretasks(careplans);
      });

      getFilterInsuranceTypes().then(response => {
        if (!response?.data) return;
        const { data } = response;
        const types = data.map(({ label, value }) => ({
          value,
          label,
          labelLocation: label,
        }));
        setInsuranceTypes(types);
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (type === FieldTypes.ComponentTypeAndName) {
      const fetchComponents = async () => {
        getCareplanComponentsByType(check.id).then(({ data }) => {
          setComponentNames(data);
        });
      };
      fetchComponents();
    }
  }, [type, check]);

  const selectOptions = (optionList: FilterCriteriaOptions[]) =>
    optionList.map(option => ({
      ...option,
      label: t(option.labelLocation),
    }));

  const onCareplanNameSelection = (e: { name: string; id: number }) => {
    const { name, id } = e;
    onChange({
      value: name,
      label: t('patients_complexsearch.label_careplanName'),
      id,
    });
  };

  const searchCareplans = (input: string) => {
    const filteredCaretasks = caretasks.filter(careplan =>
      careplan.name.toLowerCase().includes(input.toLowerCase())
    );
    return Promise.resolve(filteredCaretasks);
  };

  const onComponentNameAndTypeSelection = (e: {
    label: String;
    value: Number;
    id: Number;
  }) => {
    onChange({ value: e.label, id: e.id, label: e.label });
  };

  const searchCareplansByType = (input: string) => {
    const filteredCaretasks = componentNames.filter(careplan =>
      careplan.label.toLowerCase().includes(input.toLowerCase())
    );
    return Promise.resolve(filteredCaretasks);
  };

  const renderValueField = () => {
    const fetchData = async (searchText: string, icd = true) => {
      return await fetchIcdsOrOpsData(searchText, icd);
    };
    const maxChars: { [key: string]: number } = {
      [FieldIdType.FirstName]: 25,
      [FieldIdType.LastName]: 25,
      [FieldIdType.MedicalRecord]: 25,
      [FieldIdType.CaseNumber]: 25,
      [FieldIdType.ComponentName]: 750,
      [FieldIdType.TypeAndName]: 50,
    };
    switch (type) {
      case FieldTypes.Date:
        return (
          <DatePicker
            date={value && !moment.isDate(value) ? new Date(value) : value}
            maxDate={new Date()}
            placeholder={t('common_field_placeholders.date')}
            handleDate={date => {
              onChange(getUTCDateFromLocalDate(date));
            }}
          />
        );
      case FieldTypes.Integer:
        return (
          <TextInput
            value={value || ''}
            id="Number"
            name="Number"
            placeholder={t('common_field_placeholders.number')}
            variant={inputField.variant.DEFAULT}
            handleOnChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(event.target.value);
            }}
          />
        );
      case FieldTypes.ICD:
        return (
          <IcdSelector
            icdSelected={(value as Object[]) || undefined}
            key="icdSelector"
            maxLengthItem={1}
            fetchData={term => fetchData(term, true)}
            placeholder="ICDs"
            onSelect={onChange}
          />
        );
      case FieldTypes.OPS:
        return (
          <IcdSelector
            icdSelected={(value as Object[]) || undefined}
            key="opsSelector"
            maxLengthItem={1}
            fetchData={term => fetchData(term, false)}
            placeholder="OPSs"
            onSelect={onChange}
            modification={modification.OPS}
          />
        );
      case FieldTypes.Select:
        const dropdownOptions =
          field === FieldIdType.InsuranceType
            ? insuranceTypes.map(insuranceType => ({
                ...insuranceType,
                label: t(insuranceType.label),
              }))
            : selectOptions(options);

        const dropDownvalue = {
          label: t(
            (value as { labelLocation: string }).labelLocation
          ).toString(),
        };
        return (
          <Dropdown
            value={dropDownvalue}
            options={dropdownOptions}
            placeholder={t('common_field_placeholders.criteria')}
            handleOnChange={onChange}
          />
        );
      case FieldTypes.CareplanName: {
        const componentValue =
          typeof value === 'object' ? { ...value, label: value.value } : null;
        return (
          <div style={{ height: '2rem' }}>
            <SingleSelectQuestionSearchable
              value={componentValue}
              defaultOptions={caretasks}
              loadOptions={searchCareplans}
              placeholder={t('patients_complexsearch.label_careplanName')}
              handleOnChange={onCareplanNameSelection}
              noOptionsMessage={t('appointment.noResultsFound')}
              maxLength={
                MaxCharLimits.carePlan.careplanExternalTitlecMaxCharLimit
              }
            />
          </div>
        );
      }
      case FieldTypes.ComponentTypeAndName: {
        const componentValue =
          typeof value === 'object' ? { ...value, label: value.value } : null;
        return (
          <SingleSelectQuestionSearchable
            value={componentValue}
            defaultOptions={componentNames}
            loadOptions={searchCareplansByType}
            placeholder={t('patients_complexsearch.componentName')}
            handleOnChange={onComponentNameAndTypeSelection}
          />
        );
      }
      default:
        return (
          <TextInput
            value={value || ''}
            id="Text"
            name="Text"
            placeholder={t('common_field_placeholders.text')}
            variant={
              maxChars[field]
                ? inputField.variant.CHAR_COUNT
                : inputField.variant.DEFAULT
            }
            maxChars={maxChars[field]}
            handleOnChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let text = event.target.value;
              if (maxChars[field] && text.length > maxChars[field])
                text = text.substring(0, maxChars[field]);
              onChange(text);
            }}
            isDisabled={false}
            autoComplete={inputField.autocomplete.OFF}
            hasSuccess={false}
            isLeftIcon={false}
          />
        );
    }
  };

  return (
    <div
      className={`filters__criteria-field__${multipleInputs ? 'half' : 'full'}`}
    >
      {renderValueField()}
      {error && (
        <div className="filters__answer-value-error">
          {t(
            `patients_complexsearch.validations_${error.label}`,
            error.options
          ).toString()}
        </div>
      )}
    </div>
  );
}

export default FilterCriteriaValueField;
