import React, { createContext, useContext, useState, useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import { generateRandomId } from '@ui-common-files/utils';
import { deepClone, deepEqual } from '@global/Objects';

import getCriteria from '../../../../Utils/PatientFilter/PatientFilter';
import { FilterActions } from '../../../../Utils/PatientFilter/PatientFilter.enum';
import { onCustomFilterChangeAction } from '../../../../../actions/Datatables/patients';
import { FilterTypes } from './PatientFilter.type';
import {
  extractValueByType,
  generateFilterObject,
  checkForErrors,
  initialCriteriaList,
} from './PatientFilterContext.util';
import { onCustomFilterSelection } from '../../../../../actions/Datatables/patientFilter';

import { PatientFilterContextProps } from './PatientFilterContext.type';

const PatientFilterContext = createContext({
  criteriaList: { caretask: [], patient: [] },
  updateCriterias: x => {},
  criteriaOperator: {
    patient: { value: '', label: '', labelLocation: '' },
    caretask: { value: '', label: '', labelLocation: '' },
  },
  updateCriteriaOperator: x => {},
  filterOperator: { value: '', label: '', labelLocation: '' },
  setFilterOperator: x => {},
  clearCurrentFilter: () => {},
  clearAllFilters: () => {},
  filter: x => {},
  currentFilter: null,
  setCurrentFilter: x => {},
  appliedFilters: { caretask: [], patient: [] },
  errors: {},
  removeAppliedFilter: (x, y) => {},
});

function PatientFilterContextProvider({
  children,
  setLoading,
  setShowAppliedFilterWarning,
}: PatientFilterContextProps) {
  const { user } = useSelector(state => state.session);
  const actionState = useSelector(state => state.patientTable);
  const { patient: patientFilters, caretask: caretaskFilters } = getCriteria(
    user.role.isPatientDemographicData
  );
  const reduxDispatcher = useDispatch();
  const selectedFilter = useSelector(state => state.patientFilters);

  // The current filter type (patient, caretask) displayed in the dropdown
  const [currentFilter, setCurrentFilter] = useState(null);
  // The currently applied filters, types are: 'patient' and 'caretask'
  const [appliedFilters, setAppliedFilters] = useState(
    selectedFilter.customFilter !== null
      ? {
          patient:
            !selectedFilter ||
            selectedFilter.customFilter.patient[0].value[0] === ''
              ? []
              : selectedFilter.customFilter.patient,
          caretask:
            !selectedFilter ||
            selectedFilter.customFilter.caretask[0].value[0] === ''
              ? []
              : selectedFilter.customFilter.caretask,
        }
      : {
          patient: [],
          caretask: [],
        }
  );
  // The currently applied filters, types are: 'patient' and 'caretask'
  const [errors, setErrors] = useState({});

  // Clears the current filter type
  const clearCurrentFilter = () => setCurrentFilter(null);
  // A helper function for updating the current criteria list
  const updateCriterias = ({ action, criteria }) => {
    const updatedCriteriaList = { ...criteriaList };
    const filter =
      currentFilter === FilterTypes.Patient ? patientFilters : caretaskFilters;
    if (currentFilter) {
      switch (action) {
        case FilterActions.Update:
          {
            updatedCriteriaList[currentFilter][criteria.position] = {
              ...updatedCriteriaList[currentFilter][criteria.position],
              ...criteria.values,
            };
          }
          break;
        case FilterActions.Push:
          {
            const id = generateRandomId();
            updatedCriteriaList[currentFilter].push({
              field: {
                ...filter[0],
                label: i18next.t(filter[0].labelLocation),
              },
              check: {
                ...filter[0].operators[0],
                label: i18next.t(filter[0].operators[0].labelLocation),
              },
              value: [''],
              id,
            });
          }
          break;
        case FilterActions.Remove:
          {
            updatedCriteriaList[criteria.type].splice(criteria.position, 1);
          }
          break;
      }
    }
    setCriteriaList(updatedCriteriaList);
  };

  useEffect(() => {
    const body = {
      query: JSON.stringify({
        start: actionState.start,
        limit: actionState.limit,
        dir: actionState.dir,
        column: actionState.column,
        search: actionState.search,
      }),
      customFilter: {
        patientFilter: {
          criteria:
            selectedFilter.customFilter === null
              ? []
              : getPatientFilter(FilterTypes.Patient),
          operator: criteriaOperator[FilterTypes.Patient].value,
        },
        caretaskFilter: {
          criteria:
            selectedFilter.customFilter === null
              ? []
              : getPatientFilter(FilterTypes.Caretask),
          operator: criteriaOperator[FilterTypes.Caretask].value,
        },
        filterOperator:
          filterOperator.value === '-'
            ? getPatientFilter(FilterTypes.Patient).length > 0 &&
              getPatientFilter(FilterTypes.Caretask).length > 0
              ? 'And'
              : null
            : filterOperator.value,
      },
    };
    if (!deepEqual(actionState.customFilter, body.customFilter)) {
      setLoading(true);
      reduxDispatcher(onCustomFilterChangeAction(body.customFilter));
    }
  }, [selectedFilter.customFilter]);

  useEffect(() => {
    if (selectedFilter !== null) {
      setLoading(true);
      reduxDispatcher(onCustomFilterChangeAction(actionState.customFilter));
      setShowAppliedFilterWarning(
        selectedFilter.savedFilter.id > 1 ||
          selectedFilter.customFilter !== null
      );
    }
  }, []);

  const initialList =
    selectedFilter.customFilter !== null
      ? selectedFilter.customFilter
      : initialCriteriaList(user.role.isPatientDemographicData);

  // The criteria list containing all the selected filter criteria
  const [criteriaList, setCriteriaList] = useState(initialList);

  useEffect(() => {
    if (!criteriaList.patient?.length || !criteriaList.caretask?.length) {
      const patient = !criteriaList.patient?.length
        ? initialCriteriaList(user.role.isPatientDemographicData).patient
        : criteriaList.patient;
      const caretask = !criteriaList.caretask?.length
        ? initialCriteriaList(user.role.isPatientDemographicData).caretask
        : criteriaList.caretask;
      setCriteriaList({
        patient,
        caretask,
      });
    }
  }, [criteriaList]);

  const updateCriteriaOperator = operator => {
    setCriteriaOperator({
      ...criteriaOperator,
      [currentFilter]: { ...operator },
    });
  };

  const [criteriaOperator, setCriteriaOperator] = useState({
    [FilterTypes.Patient]: {
      value: 'And',
      label: i18next.t('filter_operators.and'),
      labelLocation: 'filter_operators.and',
    },
    [FilterTypes.Caretask]: {
      value: 'And',
      label: i18next.t('filter_operators.and'),
      labelLocation: 'filter_operators.and',
    },
  });

  const [filterOperator, setFilterOperator] = useState({
    value: '-',
    label: i18next.t('filter_operators.dash'),
    labelLocation: 'filter_operators.dash',
  });

  const resetFilterOperator = () =>
    setFilterOperator({
      value: '-',
      label: i18next.t('filter_operators.dash'),
      labelLocation: 'filter_operators.dash',
    });

  useEffect(() => {
    setCriteriaOperator(criteriaOperator => {
      const newCriteriaOperator = { ...criteriaOperator };
      newCriteriaOperator.label = i18next.t(newCriteriaOperator.labelLocation);
      return newCriteriaOperator;
    });
  }, [i18next.language]);

  useEffect(() => {
    if (
      actionState.sqlQuery &&
      actionState.sqlQuery.sql &&
      actionState.customFilter === null
    ) {
      setAppliedFilters({ patient: [], caretask: [] });
    }
  }, [actionState]);

  const getPatientFilter = (filter, filterList) => {
    const forceFilter = !!filterList;
    if (!forceFilter) filterList = criteriaList;
    const list = {
      patient:
        forceFilter ||
        (currentFilter === FilterTypes.Patient &&
          filter === FilterTypes.Patient)
          ? filterList.patient
          : appliedFilters.patient,
      caretask:
        forceFilter ||
        (currentFilter === FilterTypes.Caretask &&
          filter === FilterTypes.Caretask)
          ? filterList.caretask
          : appliedFilters.caretask,
    };
    return list[filter].map(criteria => {
      const value = extractValueByType(criteria.value, criteria.field.type);
      return generateFilterObject(criteria, value);
    });
  };

  const filter = async () => {
    const errors = checkForErrors(criteriaList, currentFilter);
    if (errors) {
      setErrors(errors);
      return;
    }
    setErrors({});
    setCurrentFilter(null);
    const filters = deepClone(criteriaList);
    setAppliedFilters({
      patient:
        currentFilter !== FilterTypes.Patient
          ? appliedFilters.patient
          : filters.patient,
      caretask:
        currentFilter !== FilterTypes.Caretask
          ? appliedFilters.caretask
          : filters.caretask,
    });

    if (
      filterOperator.value === '-' &&
      getPatientFilter(FilterTypes.Patient).length > 0 &&
      getPatientFilter(FilterTypes.Caretask).length > 0
    ) {
      setFilterOperator({
        value: 'And',
        label: i18next.t('filter_operators.and'),
        labelLocation: 'filter_operators.and',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    applyFilter(criteriaList);
  };

  const applyFilter = () => {
    reduxDispatcher(onCustomFilterSelection(deepClone(criteriaList)));
  };

  const clearAllFilters = async () => {
    if (
      (!appliedFilters.patient.length && !appliedFilters.caretask.length) ||
      selectedFilter.customFilter === null
    )
      return;
    setLoading(true);
    setAppliedFilters({ patient: [], caretask: [] });
    setErrors({ patient: [], caretask: [] });
    resetFilterOperator();
    setShowAppliedFilterWarning(false);
    setCriteriaList(initialCriteriaList(user.role.isPatientDemographicData));
    reduxDispatcher(onCustomFilterSelection(null));
  };

  const removeAppliedFilter = (type, position) => {
    const filters = deepClone(appliedFilters);
    const updatedCriteriaList = deepClone(criteriaList);
    filters[type].splice(position, 1);
    updatedCriteriaList[type].splice(position, 1);
    const patientFilter = getPatientFilter(FilterTypes.Patient, filters);
    const caretaskFilter = getPatientFilter(FilterTypes.Caretask, filters);
    setAppliedFilters(filters);
    if (updatedCriteriaList[type].length === 0) {
      updatedCriteriaList[type] = deepClone(
        initialCriteriaList(user.role.isPatientDemographicData)[type]
      );
    }
    setCriteriaList(updatedCriteriaList);
    if (!patientFilter.length && !caretaskFilter.length) {
      clearAllFilters();
    } else {
      reduxDispatcher(onCustomFilterSelection(deepClone(updatedCriteriaList)));
      setShowAppliedFilterWarning(
        !!patientFilter.length || !!caretaskFilter.length
      );
    }
    if (!patientFilter.length || !caretaskFilter.length) resetFilterOperator();
  };

  return (
    <PatientFilterContext.Provider
      value={{
        criteriaList,
        criteriaOperator,
        updateCriteriaOperator,
        filterOperator,
        setFilterOperator,
        updateCriterias,
        filter,
        currentFilter,
        setCurrentFilter,
        clearCurrentFilter,
        appliedFilters,
        clearAllFilters,
        errors,
        removeAppliedFilter,
      }}
    >
      {children}
    </PatientFilterContext.Provider>
  );
}

const usePatientFilterContext = () => useContext(PatientFilterContext);

export { PatientFilterContextProvider, usePatientFilterContext };
