import { useTranslation } from 'react-i18next';
import { useOS } from '../../../Global/Hooks';
import links from './desktopDownloadLinks.json';

export const useDownloadDesktopAppButtonService = () => {
  const { t } = useTranslation();
  const os = useOS();
  const link = links[os];
  const isOnDesktop = window.navigator.userAgent.indexOf('Electron') !== -1;
  const hostName = window.location.hostname;
  const siteName = hostName.substring(0, hostName.indexOf('.myoncare.care'));
  const checkFileExistance = (url) => {
      var xhr = new XMLHttpRequest();
      xhr.open('HEAD', url, false);
      xhr.send();
      if (xhr.status != '200') {
        return false;
      } else {
        return true;
      }
  }
  return {
    link,
    isOnDesktop,
    t,
    siteName,
    checkFileExistance,
  };
};
