import React from 'react';

import { Box, Button, Flex, Icon } from '@ui-common-files/components';

import { PrimaryColor } from '@ui-common-files/utils';
import useTodoAssessmentCard from './TodoAssessmentCard.service';
import { TodoAssessmentCardProps } from './TodoAssessmentCard.type';

import styles from './TodoAssessmentCard.module.scss';

export default function TodoAssessmentCard({
  assessment,
}: TodoAssessmentCardProps) {
  const { t, onClick, isActive, expiryText } = useTodoAssessmentCard({
    assessment,
  });
  return (
    <Flex className={styles.card}>
      <Flex className={styles.card__header}>
        <Flex>
          <Icon size={45} name="Assessment" fill={PrimaryColor.MAIN_COLOR} />
          <Box className={styles.card__name}>{assessment.name}</Box>
        </Flex>
        <Flex>
          <Box className={styles.card__expiry}>{expiryText}</Box>
          <Box className={styles.card__button}>
            <Button
              disabled={!isActive}
              label={t('newCareplan_view.startNow')}
              onClick={onClick}
            />
          </Box>
        </Flex>
      </Flex>
      {Number.isFinite(assessment.carepathwayId) && (
        <Flex className={styles.card__carepathway}>
          <Box className={styles.card__carepathway__root}>
            {assessment.carePathwayName}
          </Box>
          {assessment.rootCareplanName && (
            <>
              <Box>&gt;</Box>
              <Box>{assessment.rootCareplanName}</Box>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
}
