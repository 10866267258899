import componentTypeOrder from "../../../../Views/Careplans/careplanComponentTypeOrder";

const hasThresholds = component => {
  const componentTypeId = component.careplanComponentTypeOrder;
  const thresholdsCollection = component.thresholdCollection;
  switch (componentTypeId) {
    case componentTypeOrder.FREE_TEXT:
    case componentTypeOrder.UPLOAD_FILE:
    case componentTypeOrder.INFORMATION:
      return false;

    case componentTypeOrder.SINGLE_CHOICE:
    case componentTypeOrder.MULTIPLE_CHOICE:
    case componentTypeOrder.BMI:
    case componentTypeOrder.BLOOD_PRESSURE:
      if (thresholdsCollection) {
        const thresholds = thresholdsCollection.filter(
          threshold => threshold.isChecked
        );
        return thresholds.length !== 0;
      }

    case componentTypeOrder.NUMERIC_RANGE:
    case componentTypeOrder.NUMERIC_VALUE:
    case componentTypeOrder.PAIN_LOCATION_CHART:
    case componentTypeOrder.DATEPICKER:
      return thresholdsCollection.length !== 0;

    case componentTypeOrder.YESNO:
    case componentTypeOrder.GROUP_DESCRIPTION:
      return thresholdsCollection.isChecked;
  }
};

export { hasThresholds };
