import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
  useCallback,
} from 'react';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';

import { Box, InfiniteScrolling } from '@ui-common-files/components';

import TaskCard from './TaskCard';
import MedicationCard from './MedicationCard';
import PlannedMedicationCard from './MedicationPlan/PlannedMedicationCard/PlannedMedicationCard';
import MedicationPlanCard from './MedicationPlan/MedicationPlanCard/MedicationPlanCard';
import CareplanCard from './CareplanCard';
import AppointmentCard from './AppointmentCard';
import AssessmentCard from './AssessmentCard';
import ActivityDataCard from './ActivityDataCard';

import { useDelaySearch } from '@global/Hooks';
import { ApiClient } from '@global/Services';
import { ComponentTypes } from '@ui-common-files/utils';

import useScrollToElem from '../../Utils/hooks/useScrollToElem';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

import config from '../../../config';
import serviceConfig from '../../../serviceConfig.json';

import '../../css/patientOverview.scss';

export const PatientOverviewCareTasks = createContext(null);
function EmptyCardViewer({ message }) {
  return <div className="empty-card-viewer">{message}</div>;
}

function getElementRefs(el, i, data, elRef) {
  const ref = elRef;
  ref.current[data[i].id] = el;
  return ref;
}

function CareplanCards({ patientId, searchValue, setCardCount }) {
  const [keyword, setKeyword] = useState(searchValue);
  const [itemIdToShowLoading, setItemIdToShowLoading] = useState(null);
  const [itemIdToUpdate, setItemIdToUpdate] = useState(null);
  const [restPagesToUpdate, setRestPagesToUpdate] = useState(null);
  const { landingTab, refreshCaretaskItemById } = useContext(
    PatientOverviewContext
  );
  const { t } = useTranslation();
  useEffect(() => {
    if (refreshCaretaskItemById && itemIdToUpdate === null) {
      setItemIdToUpdate(refreshCaretaskItemById);
    }
  }, [refreshCaretaskItemById]);
  const fetchData = useCallback(
    async (page, size, careplanId) => {
      return ApiClient.GET({
        url: serviceConfig.brokerService.getCareplanDetails.uri,
        payload: {
          searchValue: keyword,
          patientId,
          page,
          size,
          careplanId,
        },
      })
        .then(response => response?.data)
        .finally(() => {
          setItemIdToUpdate(null);
          setRestPagesToUpdate(null);
        });
    },
    [keyword, landingTab, patientId]
  );
  const fetchCount = async () => {
    const response = await ApiClient.GET({
      url: serviceConfig.brokerService.getCareplansCount.uri,
      payload: {
        patientId,
        careplanSearchValue: keyword,
      },
    });

    if (response) {
      setCardCount(prevCount => {
        const indexOfCardCount = prevCount.findIndex(
          item => item.component === ComponentTypes.CAREPLAN
        );
        const tempCount = [...prevCount];
        tempCount[indexOfCardCount].total = response.data.careplansCount;
        return tempCount;
      });
    }
  };
  useEffect(() => {
    const timeout = setTimeout(fetchCount, 800);
    return () => {
      clearTimeout(timeout);
    };
  }, [keyword, patientId, landingTab]);

  useEffect(() => {
    if (restPagesToUpdate === null) {
      fetchCount();
    }
  }, [restPagesToUpdate]);

  useDelaySearch({
    input: searchValue,
    callback: () => {
      setKeyword(searchValue);
    },
  });

  return (
    <PatientOverviewCareTasks.Provider
      value={{
        setItemIdToUpdate,
        setRestPagesToUpdate,
        setItemIdToShowLoading,
      }}
    >
      <InfiniteScrolling
        emptyItem={
          <EmptyCardViewer message={t('common_labels.no_careplans_assigned')} />
        }
        dataFetcher={fetchData}
        itemIdToUpdate={itemIdToUpdate}
        restPagesToUpdate={restPagesToUpdate}
        itemIdToShowLoading={itemIdToShowLoading}
        renderItem={itemData => <CareplanCard data={itemData} />}
      />
    </PatientOverviewCareTasks.Provider>
  );
}

const AssessmentCards = ({ data }) => {
  const assementTable = [];
  const elRef = useRef([]);
  const { forceScroll } = useContext(PatientOverviewContext);
  const { t } = useTranslation();
  useScrollToElem(elRef, forceScroll.assessment, forceScroll.assessmentId);

  if (data.length) {
    data.map(elem => {
      if (elem && elem.isAssessment == true) assementTable.push(elem);
    });

    return assementTable.map((assignment, i) => (
      <AssessmentCard
        key={`assessment-${assignment.id}`}
        data={assignment}
        elRefs={el => getElementRefs(el, i, data, elRef)}
      />
    ));
  }

  return <EmptyCardViewer message={t('common_labels.no_careplans_assigned')} />;
};
const AppointmentCards = ({ data, patientId }) => {
  const [socket, setSocket] = useState(null);
  const elRef = useRef([]);
  const { forceScroll } = useContext(PatientOverviewContext);
  const { t } = useTranslation();
  useScrollToElem(elRef, forceScroll.appointment, forceScroll.appointmentId);

  useEffect(() => {
    if (data.length)
      setSocket(io(`${window.location.host}`, config.socketOptions));
    return function cleanup() {
      if (socket) socket.close();
    };
  }, []);

  if (data.length) {
    return data.map((assignment, i) => (
      <AppointmentCard
        key={`appointment-${assignment.id}`}
        data={assignment}
        apptSocket={socket}
        patientId={patientId}
        elRefs={el => getElementRefs(el, i, data, elRef)}
      />
    ));
  }
  return (
    <EmptyCardViewer message={t('common_labels.no_appointments_assigned')} />
  );
};

const MedicationCards = ({ data, setCardCount }) => {
  const { medications, medicationPlan } = data;

  const { t } = useTranslation();
  if (!medications.length) {
    return (
      <EmptyCardViewer message={t('common_labels.no_medications_assigned')} />
    );
  }
  return (
    <Box>
      {medicationPlan.assetId && <MedicationPlanCard data={medicationPlan} />}
      {medications.map(medication => (
        <Box key={`assignment-${medication.id}`}>
          {medication.medicationPlanId ? (
            <PlannedMedicationCard
              data={medication}
              dosage={medication.dosage}
            />
          ) : (
            <MedicationCard
              data={medication}
              dosage={medication.dosage}
              setCardCount={setCardCount}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

const TaskCards = ({ data }) => {
  const { t } = useTranslation();
  if (data.length) {
    return data.map(assignment => (
      <TaskCard key={`task-${assignment.id}`} data={assignment} />
    ));
  }
  return <EmptyCardViewer message={t('common_labels.no_tasks_assigned')} />;
};

export {
  CareplanCards,
  MedicationCards,
  TaskCards,
  AppointmentCards,
  AssessmentCards,
};
