import React, { useState, useEffect, useRef } from 'react';
import I18n from 'i18next';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import ModalFooter from '../common/Layout/Modal/ModalFooter';

import { useAbortController } from '@global/Hooks';
import { ApiClient, ShowFlashMessage } from '@global/Services';
import { FlashMessageTypes } from '@ui-common-files/types';
import serviceConfig from '../../../serviceConfig.json';

function ExportAllPatientsAnswersModalFooter({
  closeModal,
  dataForExportAnswers,
  setIsLoading,
  isLoading,
  filteredPatientsForCsvExport,
}) {
  const downloadTrigger = useRef(null);

  const [clicked, setClicked] = useState(false);
  const [exportedData, setExportedData] = useState([]);

  const { getAbortController } = useAbortController();

  const exportResults = async () => {
    const abortController = getAbortController();
    try {
      if (
        dataForExportAnswers &&
        dataForExportAnswers.careplanComponentId &&
        !isLoading
      ) {
        setIsLoading(true);
        const response = await ApiClient.POST({
          url: serviceConfig.brokerService.exportAllPatientAnswers.uri,
          payload: {
            ...dataForExportAnswers,
            filteredPatientsForCsvExport,
          },
          signal: abortController.signal,
          timeout: 180000,
        });
        if (response) {
          if (response.data.length) {
            setExportedData(response.data);
          }
          setIsLoading(false);
          closeModal();
          setClicked(false);
          if (response.data.content) {
            ShowFlashMessage({
              type: response.data.type,
              messageKey: response.data.content,
              isModalMessage: false,
            });
          } else {
            if (response.data.length) {
              ShowFlashMessage({
                type:  FlashMessageTypes.Success,
                messageKey:'flash.exportCsvFileProgressFinished',
                isModalMessage: false,
              });
            }
          }
        }
      }
    } catch (error) {
      closeModal();
      ShowFlashMessage({
        type: error.data.type,
        message: error.data.content,
        isModalMessage: false,
      });
    }
  };

  useEffect(() => {
    if (clicked) {
      exportResults();
    }
  }, [clicked]);

  useEffect(() => {
    if (exportedData.length && downloadTrigger.current) {
      downloadTrigger.current.link.click();
    }
  }, [exportedData]);

  useEffect(() => {
    return () => setIsLoading(false);
  }, []);

  return (
    <ModalFooter
      close={() => {
        closeModal();
      }}
      additionalFunctionality={
        <CSVLink
          ref={downloadTrigger}
          style={{ display: 'none' }}
          filename={`${moment().format('YYYY-MM-DD')}.csv`}
          data={exportedData}
        />
      }
      labelCTA={I18n.t('common_buttons.exportAnswers')}
      form="form__export-results-patients"
      onClickCTA={() => {
        setClicked(
          dataForExportAnswers != null &&
            dataForExportAnswers.careplanComponentId
        );
      }}
      disabled={isLoading}
    />
  );
}

export default ExportAllPatientsAnswersModalFooter;
