export enum CareplanComponentTypes {
  FreeText = 1,
  SingleChoice = 2,
  MultipleChoice = 3,
  NumericRange = 4,
  NumericValue = 5,
  Yesno = 6,
  PainLocationChart = 7,
  Sortable = 8,
  Information = 9,
  GroupDescription = 10,
  UploadFile = 11,
  Datepicker = 12,
  Bmi = 13,
  BloodPressure = 14,
}

export default CareplanComponentTypes;
