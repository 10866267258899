/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import Box from '../../../../caro-ui-commonfiles/components/Box/Box';

const FormGroupTitle = ({ children }) => {
  return (
    <Box flex="1" className="form-group__divider">
      <p className="form-group__title">{children}</p>
    </Box>
  );
};

FormGroupTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormGroupTitle;
