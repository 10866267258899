import React from 'react';
import { Handle, Position } from 'reactflow';

import useCareTaskNodeTargetHandleService from './CareTaskNodeTargetHandle.service';

const CareTaskNodeTargetHandle = () => {
  const { isHandleConnectable } = useCareTaskNodeTargetHandleService();

  return (
    <Handle
      type="target"
      className="input-handle"
      position={Position.Top}
      isConnectable={isHandleConnectable}
    />
  );
};

export default CareTaskNodeTargetHandle;
