import { useTranslation } from 'react-i18next';

import { LanguageItems } from '@type/Common';

import { USER_GUIDE_LINKS } from '@utils/GuideLinks/UserGuideLinks.constant';
import { ProductLabelContentModalParams } from './ProductLabelContentModal.type';

export default function useProductLabelContentModalService({
  nonMedical,
}: ProductLabelContentModalParams) {
  const isMedical = nonMedical === false || nonMedical === 'false';
  const { i18n } = useTranslation();

  const getUserGuideLink = () => {
    const { language } = i18n;
    switch (language) {
      case LanguageItems.English:
        return USER_GUIDE_LINKS.EN;
      case LanguageItems.Deutsch:
        return USER_GUIDE_LINKS.DE;
      case LanguageItems.Français:
        return USER_GUIDE_LINKS.FR;
      case LanguageItems.Portuguese:
        return USER_GUIDE_LINKS.PT;
      case LanguageItems.Italiano:
        return USER_GUIDE_LINKS.IT;
      default:
        return USER_GUIDE_LINKS.EN;
    }
  };
  return {
    isMedical,
    getUserGuideLink,
  };
}
