import { useShallow } from 'zustand/react/shallow';

import { useCommonCareTaskConfiguration } from '../useCommonCareTaskConfiguration';

import { useCarePathWayBuilderStore } from '../../CarePathWayBuilder.store';

const useLinkedCareTaskConfigurationService = () => {
  const { nodeData } = useCarePathWayBuilderStore(
    useShallow(state => ({
      nodeData: state.nodes.find(
        node => node.id === state.sidebar.configurationNodeId
      )?.data,
    }))
  );

  const {
    lifespanUnitsObjSmall,
    configuration,
    onConfigurationChange,
    saveConfiguration,
    getTranslatedLabel,
  } = useCommonCareTaskConfiguration();

  return {
    lifespanUnitsObjSmall,
    nodeData,
    configuration,
    onConfigurationChange,
    saveConfiguration,
    getTranslatedLabel,
  };
};

export default useLinkedCareTaskConfigurationService;
