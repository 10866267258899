import moment from 'moment';

import Occurrence from '../../../types/Common/Occurrence.enum';
import formatDate from '../FormatDate/FormatDate';

export function getDateDifference(endDate: string | Date) {
  const date = moment(endDate);
  const today = moment();

  const minuteDifference = date.diff(today, `${Occurrence.Minute}s`);
  if (minuteDifference < 60)
    return { amount: minuteDifference, type: Occurrence.Minute };

  const hourDifference = date.diff(today, `${Occurrence.Hour}s`);
  if (hourDifference < 24)
    return { amount: hourDifference, type: Occurrence.Hour };

  const dayDifference = date.diff(today, `${Occurrence.Day}s`);
  if (dayDifference < 31)
    return { amount: dayDifference, type: Occurrence.Day };

  const monthDifference = date.diff(today, `${Occurrence.Month}s`);
  if (monthDifference < 12)
    return { amount: monthDifference, type: Occurrence.Month };

  const yearDifference = date.diff(today, `${Occurrence.Year}s`);
  return { amount: yearDifference, type: Occurrence.Year };
}

export function isDateInPast(date: string | Date) {
  return moment(formatDate(date, 'llll')).isBefore(moment());
}

export function isDateInFuture(timestamp: number): boolean {
  const dateToCompare = moment(timestamp);
  const today = moment();

  return dateToCompare.isAfter(today, 'day');
}

export const isToday = (dateToCheck: string | number | Date): boolean => {
  const today = moment();
  const toDate = moment(dateToCheck);

  return today.isSame(toDate, 'day');
};
