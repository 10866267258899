import config from '../../../../../../serviceConfig.json';
import { CareplanComponentTypes } from '../../../../../types/Careplan';
import { ApiClient } from '../../../../../Global/Services';
import {
  GetAllCareplansRequestType,
  GetAllCareplansResponseType,
} from '../FilterCriteriaValue/FilterCriteriaValue.type';

export const getCareplanComponentsByType = (id: CareplanComponentTypes) =>
  ApiClient.GET<GetAllCareplansResponseType, GetAllCareplansRequestType>({
    url: config.brokerService.getCareplanComponentsByType.uri,
    payload: {
      careplanComponentTypeId: id,
    },
  });
