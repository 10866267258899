import I18n from 'i18next';

const getComponent = component => {
  switch (component) {
    case 'types.componentType.questionnaires':
      return I18n.t('types.componentType.questionnaires');

    case 'types.componentType.medications':
      return I18n.t('types.componentType.medication');

    case 'types.componentType.tasks':
      return I18n.t('types.componentType.task');

    case 'types.componentType.questionnaireTemplates':
      return I18n.t('types.componentType.questionnaireTemplates');

    case 'types.componentType.appointments':
      return I18n.t('types.componentType.appointment');

    case 'types.componentType.users':
      return I18n.t('types.componentType.users');

    case 'types.componentType.patients':
      return I18n.t('types.componentType.patients');

    case 'types.componentType.taskTemplates':
      return I18n.t('types.componentType.taskTemplates');

    case 'types.componentType.qr':
      return I18n.t('types.common_labels.QRCode');

    case 'types.componentType.careplanTemplates':
      return I18n.t('types.componentType.careplanTemplates');

    case 'types.componentType.careplans':
      return I18n.t('types.componentType.careplan');

    case 'types.componentType.carepathwayTemplates':
      return I18n.t('types.componentType.carepathwayTemplates');

    case 'types.componentType.carepathways':
      return I18n.t('types.componentType.carepathway');

    default:
      return '';
  }
};

const getAction = action => {
  switch (action) {
    case 'actions.create':
      return I18n.t('history.CREATE');

    case 'actions.update':
      return I18n.t('history.UPDATE');

    case 'actions.send':
      return I18n.t('history.SEND');

    case 'actions.revoke':
      return I18n.t('history.REVOKE');

    case 'actions.assign':
      return I18n.t('history.ASSIGN');

    case 'actions.bulkAssign':
      return I18n.t('history.BULKASSIGN');

    case 'actions.bulkAssign&Send':
      return I18n.t('history.BULKASSIGNSEND');

    case 'actions.qrGeneration':
      return I18n.t('history.GENERATEQR');

    case 'actions.answered':
      return I18n.t('history.ANSWERED_BY');

    case 'actions.skipped':
      return I18n.t('history.SKIPPED_BY');

    case 'actions.seen':
      return I18n.t('history.SEEN');

    case 'actions.import':
      return I18n.t('history.import');

    case 'actions.consent':
      return I18n.t('privacyPolicy.consented');

    default:
      return '';
  }
};

export { getComponent, getAction };
