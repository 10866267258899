import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Icon } from '@ui-common-files/components';
import { HealthDataButtons } from './HealthDataButtons';

import { ActionColors } from '@ui-common-files/utils';

import '../HealthData/HealthData.styles.scss';

const HealthData = () => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      className="health_data_content"
    >
      <Box className="health_data_title">
        {t('patients_view.overview.modal_select_data.health_container.title')}
      </Box>

      <Flex alignItems="center">
        <Icon name="WarningFilter" size={28} fill={ActionColors.ERROR} />
        <p>{t('warningMessage.wearableWarning')}</p>
      </Flex>
      <Box>
        <HealthDataButtons />
      </Box>
    </Flex>
  );
};

export default HealthData;
