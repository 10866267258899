import I18n from 'i18next';
import React from 'react';

import {
  Box,
  Checkbox,
  DataTable,
  Flex,
  Icon,
} from '@ui-common-files/components';
import { ActionColors, CheckBoxRadioVariants } from '@ui-common-files/utils';

export default function BloodPressureTable({
  index,
  componentCollection,
  setComponentCollection,
  item,
  thresholdToggle,
  handleSortable,
}) {
  const columns = [
    {
      accessor: 'option',
      minWidth: 127,
      maxWidth: 300,
    },
    {
      Header: `${I18n.t(`bloodPressureOptions.systolicValues.label`)}`,
      accessor: 'systolicValue',
      style: {
        justifyContent: 'center',
      },
      maxWidth: 140,
    },
    {
      Header: `${I18n.t(`bloodPressureOptions.diastolicValues.label`)}`,
      accessor: 'diastolicValue',
      style: {
        justifyContent: 'center',
      },
      maxWidth: 140,
    },
  ];
  thresholdToggle &&
    columns.push({
      Header: '',
      accessor: 'icon',
      style: {
        justifyContent: 'center',
      },
      maxWidth: 50,
    });
  let data;
  if (thresholdToggle) {
    const type = item.collection.key;
    data = item.choiceCollection.choices.map((choice, i) => {
      const value = `${choice.name}`;
      const id = `multipleChoice_${i}_${index}`;
      return {
        option: (
          <Flex>
            <Checkbox
              id={id}
              name={id}
              value={value}
              variant={CheckBoxRadioVariants.Threshold}
              inputWidth="100%"
              valueInput={value}
              checked={item.choiceCollection.choices[i].isChecked}
              handleOnChange={e =>
                handleSortable(
                  e,
                  item,
                  i,
                  componentCollection,
                  setComponentCollection
                )
              }
              handleInputOnChange={() => {}}
            />
            <Box>{I18n.t(`bloodPressureOptions.labels.${choice.name}`)}</Box>
          </Flex>
        ),
        systolicValue: `${I18n.t(
          `bloodPressureOptions.systolicValues.${choice.name}`
        )}`,
        diastolicValue: `${I18n.t(
          `bloodPressureOptions.diastolicValues.${choice.name}`
        )}`,
        icon: thresholdToggle && item.choiceCollection.choices[i].isChecked && (
          <Box className="thresholdIconContainer">
            <Icon name="threshold" size={24} fill={ActionColors.THRESHOLD} />
          </Box>
        ),
      };
    });
  } else {
    data = item.choiceCollection.choices.map(choice => {
      return {
        option: `${I18n.t(`bloodPressureOptions.labels.${choice.name}`)}`,
        systolicValue: `${I18n.t(
          `bloodPressureOptions.systolicValues.${choice.name}`
        )}`,
        diastolicValue: `${I18n.t(
          `bloodPressureOptions.diastolicValues.${choice.name}`
        )}`,
      };
    });
  }

  return (
    <div className="blood-pressure-data-table">
      <DataTable
        customClassName={'customMargin'}
        onTableChange={() => {}}
        pagination={false}
        columns={columns}
        data={data}
      />
    </div>
  );
}
