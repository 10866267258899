import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ComponentTypes, useDebounce } from '@ui-common-files/utils';
import { getTodos } from './TodoCards.api';
import { TodoAssessment, TodoCardsProps } from './TodoCards.type';
import { selectTodos } from '../../Notifications/Notification.reducer';

export default function useTodoCardsService({
  patientId,
  searchValue,
  updateCardCount,
}: TodoCardsProps) {
  const [todos, setTodos] = useState<TodoAssessment[]>([]);
  const [loading, setLoading] = useState(true);
  const allTodos = useSelector(selectTodos);
  const debouncedSearch = useDebounce(searchValue, 500);

  useEffect(() => {
    setLoading(true);
    getTodos(patientId, debouncedSearch)
      .then(({ data: { assessments, totalCount } }) => {
        if (assessments) {
          updateCardCount(ComponentTypes.TODOS, totalCount);
          setTodos(assessments);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debouncedSearch, patientId, allTodos]);

  return { todos, loading };
}
