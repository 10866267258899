import classNames from 'classnames';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePickerKeys } from '../../../utils/dateUtils/dateUtil.type';
import { DatePickerInputServiceProps } from './DatePickerInput.type';
import { checkIfCorrectDateInput } from './DatePickerInput.util';

import styles from './DatePickerInput.module.scss';

export const useDatePickerInputService = ({
  type,
  onInput,
  value,
  refs,
  error,
}: DatePickerInputServiceProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  const { t } = useTranslation();

  const placeholder = t(`patients_view.placeholder_dateOfBirth${type}`);

  const onInputChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    const input = currentTarget.value;
    const correctDate = checkIfCorrectDateInput(input, type);
    if (correctDate) {
      setCurrentValue(input);
      switch (type) {
        case DatePickerKeys.Day:
          if (input.length === 2) refs[DatePickerKeys.Month].current.focus();
          onInput(input, type);
          break;
        case DatePickerKeys.Month:
          if (input.length === 2) refs[DatePickerKeys.Year].current.focus();
          onInput(input, type);
          break;
        default:
          onInput(input, type);
          break;
      }
    }
  };

  const inputClassName = classNames({
    [styles.inputContainer__input]: true,
    [styles.inputContainer__input__error]: error,
  });

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
    if (!currentValue && (key === 'Enter' || key === 'ArrowRight'))
      switch (type) {
        case DatePickerKeys.Day:
          refs[DatePickerKeys.Month].current.focus();
          break;
        case DatePickerKeys.Month:
          refs[DatePickerKeys.Year].current.focus();
          break;
        default:
          break;
      }
  };

  const onBlur = () => {
    if (
      (type === DatePickerKeys.Month || type === DatePickerKeys.Day) &&
      value.length === 1
    )
      onInput(`0${value}`, type);
  };

  useEffect(() => {
    setCurrentValue(value.toString());
  }, [value]);

  return {
    onInputChange,
    currentValue,
    inputClassName,
    onKeyDown,
    placeholder,
    onBlur,
  };
};
