/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import I18n from 'i18next';

import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Tooltip from '../../../caro-ui-commonfiles/components/Tooltip/Tooltip';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Checkbox from '../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import TagsCell from './CellElements/TagsCell';
import SelectedFiles from '../../../caro-ui-commonfiles/components/SelectedFiles/SelectedFiles';

import {
  ActionColors,
  PrimaryColor,
  Greys,
} from '../../../caro-ui-commonfiles/utils/colors';
import Position from '../../../caro-ui-commonfiles/utils/positions';
import { convertLinkToBlob } from '../../Utils/Asset/assetHelper';
import Animation from './CellElements/Animationcomponent';

import { nonMedical } from '../../../config';

const PatientListViewForAssign = ({
  isOncobox = false,
  data,
  loading,
  onChange,
  page,
  isSelectAll,
  setIsSelectAll,
  nbrPatientsSelected,
  setNbrPatientsSelected,
  selectedPatientsData,
  setSelectedPatientsData,
  hidePatientDemographics,
}) => {
  const [dataInfo, setDataInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [responsive, setResponsive] = useState(false);
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'medicalRecord',
  });

  const items = [
    { key: '10', value: '10' },
    { key: '25', value: '25' },
    { key: '50', value: '50' },
    { key: '100', value: '100' },
  ];
  const tableProps = {
    defaultSorted: [
      {
        id: 'medicalRecord',
        desc: false,
      },
    ],
    onSortedChange: (newSorted, column, shiftKey) => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
    getTdProps: (state, rowInfo, column, instance) => ({
      style: {
        background: selectedPatientsData.find(x => x.id === rowInfo.original.id)
          ? PrimaryColor.MAIN_COLOR_LIGHT
          : null,
      },
    }),
    getTrProps: (state, rowInfo, column, instance) => {
      const {
        original: { isInMedicalCountry },
      } = rowInfo;
      const shouldApplyGeofencingStyle = !nonMedical && !isInMedicalCountry;
      return {
        className: shouldApplyGeofencingStyle && 'rt-tr-disable',
      };
    },
  };

  const screenSize = useCallback(() => {
    window.innerWidth <= 1366 ? setResponsive(true) : setResponsive(false);
  }, [responsive]);

  useEffect(() => {
    screenSize();
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  useEffect(() => {
    const fetchData = () => {
      let nbr = 0;
      let newPatientList = selectedPatientsData;
      if (data) {
        const patients = data.data;
        const patientResult =
          patients &&
          patients.map(function (patient) {
            const { isInMedicalCountry } = patient;

            const filterTest = selectedPatientsData.filter(
              pat => pat.id == patient.id
            );

            let isChecked = false;
            if (selectedPatientsData.length > 0 && filterTest.length > 0) {
              isChecked = true;
            }

            if (selectedPatientsData.length > 0 && filterTest.length == 0) {
              isChecked = false;
            }

            if (selectedPatientsData.length == 0) {
              isChecked = false;
            }

            const shouldApplyGeofencingStyle =
              !nonMedical && !isInMedicalCountry;

            return {
              patientCheckbox: !shouldApplyGeofencingStyle ? (
                <Checkbox
                  id={patient.id}
                  label=""
                  checked={isChecked}
                  handleOnChange={e => {
                    isChecked = e.target.checked;
                    if (e.target.checked) {
                      nbr++;
                      isChecked = false;
                      newPatientList = selectedPatientsData.concat(patient);
                    } else {
                      nbr--;
                      isChecked = false;
                      const index = selectedPatientsData.findIndex(
                        pat => pat.id == patient.id
                      );

                      if (index > -1) {
                        newPatientList = [
                          ...newPatientList.slice(0, index),
                          ...newPatientList.slice(index + 1),
                        ];
                      }
                    }

                    setSelectedPatientsData(newPatientList);
                    setNbrPatientsSelected(nbrPatientsSelected + nbr);
                  }}
                />
              ) : null,
              id: patient.id,
              medicalRecord: patient.medicalRecord,
              pseudonymCode: patient.pseudonymCode,
              profilePicture: patient.profilePicture,
              firstname: patient.firstname,
              lastname: patient.lastname,
              birthdate: !hidePatientDemographics
                ? patient.birthdate
                  ? moment(patient.birthdate)
                      .locale(I18n.language)
                      .format('DD MMM YYYY')
                  : ''
                : `${patient.age} ${I18n.t('patients_view.years')}`,
              site: patient.institutions,
              isInMedicalCountry: patient.isInMedicalCountry,
              isBirthdayHardcoded: patient.isBirthdayHardcoded,
            };
          });
        return patientResult;
      }
      return [];
    };
    const result = fetchData();

    setDataInfo(result);
  }, [data, selectedPatientsData, isSelectAll, nbrPatientsSelected]);
  useEffect(() => {
    onChange(currentPage, pageSize, sort);
  }, [currentPage, pageSize, sort]);

  const ProfileCell = ({ profilePicture, index }) => {
    const [avatar, setAvatar] = useState('');
    useEffect(() => {
      const setProfile = async () => {
        if (profilePicture.directLink) {
          await convertLinkToBlob(profilePicture.directLink)
            .then(blob => {
              setAvatar(blob);
            })
            .catch(() => {
              setAvatar(profilePicture.directLink);
            });
        } else {
          setAvatar(`data:image;base64,${profilePicture}`);
        }
      };
      if (!isOncobox) {
        setProfile();
      }
    }, []);

    return (
      <Animation isOn={true} delay={50 * index}>
        <Box width="40px" height="40px">
          <div
            style={{
              backgroundImage: `url("${avatar}")`,
            }}
            className="notification__table-profile-pic"
          />
        </Box>
      </Animation>
    );
  };

  let columns: object[] = [
    {
      Header: () => {
        if (data) {
          const shouldBeIndeterminate =
            selectedPatientsData.length > 0 &&
            selectedPatientsData.length < data.recordsTotal;
          const isChecked = selectedPatientsData.length > 0;

          return (
            <Checkbox
              id="checkAll"
              isIndeterminate={shouldBeIndeterminate}
              checked={isChecked}
              handleOnChange={e => {
                setIsSelectAll(e.target.checked);
              }}
            />
          );
        }
        return null;
      },
      accessor: 'patientCheckbox',
      sortable: false,
      filterable: false,
      width: hidePatientDemographics ? 60 : 40,
    },
    {
      Header: props => {
        return (
          <>
            {sort.column === props.column.id && (
              <Box width={20}>
                <Icon
                  name="sortArrow"
                  size={22}
                  className={
                    sort.column === props.column.id && sort.dir === 'desc'
                      ? 'desc-sort'
                      : ''
                  }
                />
              </Box>
            )}
            <span>{I18n.t('patients_view.label_medicalRecord')}</span>
          </>
        );
      },
      headerClassName: 'data-table__column-1__header',
      className: 'data-table__column-1__body',
      accessor: 'medicalRecord',
      width: hidePatientDemographics ? 250 : 160,
      maxWidth: hidePatientDemographics ? 250 : 160,
    },
  ];

  if (hidePatientDemographics) {
    columns.push({
      Header: props => {
        return (
          <>
            {sort.column === props.column.id && (
              <Box width={20}>
                <Icon
                  name="sortArrow"
                  size={22}
                  className={
                    sort.column === props.column.id && sort.dir === 'desc'
                      ? 'desc-sort'
                      : ''
                  }
                />
              </Box>
            )}
            <span>{I18n.t('patients_view.label_pseudonymCode')}</span>
          </>
        );
      },
      headerClassName: 'data-table__column-1__header',
      accessor: 'pseudonymCode',
      filterable: false,
      minWidth: hidePatientDemographics ? 150 : 140,
    });
  } else {
    if (!isOncobox)
      columns.push({
        Header: '',
        accessor: 'profilePicture',
        sortable: false,
        filterable: false,
        minWidth: 110,
        maxWidth: 110,
        style: {
          padding: '0 0 0 20px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        },
        Cell: ({ value, index }) => (
          <ProfileCell profilePicture={value} index={index} />
        ),
      });

    columns.push(
      {
        Header: props => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{I18n.t('patients_view.label_firstName')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'firstname',
        filterable: false,
        minWidth: 140,
      },
      {
        Header: props => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}

              <span>{I18n.t('patients_view.label_lastName')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'lastname',
        filterable: false,
        minWidth: 140,
      }
    );
  }
  columns.push(
    {
      Header: props => {
        return (
          <>
            {sort.column === props.column.id && (
              <Box width={20}>
                <Icon
                  name="sortArrow"
                  size={22}
                  className={
                    sort.column === props.column.id && sort.dir === 'desc'
                      ? 'desc-sort'
                      : ''
                  }
                />
              </Box>
            )}
            <span>
              {!hidePatientDemographics
                ? I18n.t('patients_view.label_dateOfBirth')
                : I18n.t('patientOverview.labelAge')}
            </span>
          </>
        );
      },
      headerClassName: 'data-table__column-1__header',
      accessor: 'birthdate',
      filterable: false,
      minWidth: 150,
      Cell: ({ original, value }) => {
        const { isBirthdayHardcoded } = original;
        return !isBirthdayHardcoded ? value : null;
      },
    },
    {
      Header: I18n.t('nav.site'),
      accessor: 'site',
      sortable: false,
      filterable: false,
      style: { overflow: 'visible' },
      minWidth: 150,
      Cell: ({ value }) => {
        return <TagsCell tagList={value} />;
      },
    }
  );
  if (!nonMedical) {
    columns.push({
      Header: '',
      accessor: 'isInMedicalCountry',
      sortable: false,
      filterable: false,
      style: { overflow: 'visible' },
      minWidth: 150,
      Cell: ({ value }) => {
        return (
          <>
            {!value && (
              <Tooltip
                content={I18n.t('patients_view.nonMedicalToolTip')}
                position={Position.bottom}
              >
                <Icon name="info" size={24} />
              </Tooltip>
            )}
          </>
        );
      },
    });
  }

  return (
    <>
      {nbrPatientsSelected ? (
        <SelectedFiles
          numberFiles={nbrPatientsSelected}
          message={I18n.t('newCareplan_view.patients_selected')}
        />
      ) : null}
      <Box margin="20px 0">
        <DataTable
          data={dataInfo}
          columns={columns}
          pageSize={false}
          pageSizeOptions={items}
          pagination
          isLoading={loading}
          onTableChange={(index, size) => {
            setCurrentPage(
              data && index * size > data.recordsTotal ? 0 : index
            );
            setPageSize(size);
          }}
          page={page}
          pages={data ? Math.ceil(data.recordsTotal / pageSize) : 1}
          totalRecord={data ? data.recordsTotal : 0}
          tableProps={tableProps}
        />
      </Box>
    </>
  );
};

export default PatientListViewForAssign;
