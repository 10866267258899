import { MedicationData } from '../Overview.type';
import {
  BarWidthParams,
  XPositionParams,
} from './OverviewMedicationChart.type';
import { BAR_HEIGHT, PADDING } from './OverviewMedicationChart.constant';

export const getChartHeight = (medicationData: MedicationData[]) =>
  medicationData?.length ? medicationData.length * BAR_HEIGHT + 150 : 200;

export const checkOverflow = element => {
  return element.scrollWidth > element.clientWidth;
};

export const calculateXPosition = ({
  barStartDate,
  chartWidth,
  totalRange,
  startTimestamp,
}: XPositionParams) => {
  const fraction = (barStartDate - startTimestamp) / totalRange;
  return fraction * chartWidth;
};

export const calculateBarWidth = ({
  barStartDate,
  barEndDate,
  chartWidth,
  totalRange,
}: BarWidthParams) => {
  const duration = barEndDate - barStartDate;
  const fraction = duration / totalRange;
  return fraction * chartWidth;
};

export const foreignObjectXPosition = (xPosition: number) =>
  xPosition + PADDING * 2;

export const foreignObjectYPosition = (y: number) => y + PADDING;
