import React from 'react';
import I18n from 'i18next';

import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import ButtonDropdown from '../../../../caro-ui-commonfiles/components/DropdownMenu/ButtonDropdown';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';

import { ButtonVariant } from '../../../../caro-ui-commonfiles/utils/button';

const BuilderNavigator = ({
  shouldShowTemplateSettings,
  careplanComponentTypes,
  addComponentHandler,
  setTemplateSettings,
  setSettings,
}) => (
  <Flex
    className="careplanButtonDropDownInnerContainer"
    justifyContent="flex-end"
    alignItems="center"
  >
    <Box margin="0 auto 0 0">
      <p className="caretask-builder__navigator-header">
        {I18n.t(
          shouldShowTemplateSettings
            ? 'steps.settings'
            : 'common_labels.label_careplanTemplate_componentSettings'
        )}
      </p>
    </Box>
    {!shouldShowTemplateSettings && (
      <Box width="max-content" margin="0 10px 0 0">
        <ButtonDropdown
          items={careplanComponentTypes}
          buttonLabel={I18n.t('common_labels.label_addComponent')}
          buttonType="button"
          variant={ButtonVariant.OUTLINED}
          labelHeader={I18n.t('common_buttons.questionType_choose')}
          onClick={(event, item) => addComponentHandler(event, item)}
        />
      </Box>
    )}

    <Button
      label={I18n.t(
        shouldShowTemplateSettings
          ? 'common_labels.label_careplanTemplate_componentSettings'
          : 'steps.settings'
      )}
      onClick={() => {
        setTemplateSettings(!shouldShowTemplateSettings);
        setSettings(false);
      }}
    />
  </Flex>
);

export default BuilderNavigator;
