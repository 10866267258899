/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import I18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MaxCharLimits } from '@ui-common-files/utils';
import { ApiClient, HideFlashMessage } from '@global/Services';

import DataTable from '../../../caro-ui-commonfiles/components/DataTable/DataTable';
import Input from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import TagsCell from './CellElements/TagsCell';

import serviceConfig from '../../../serviceConfig.json';

import { CustomFilterUpdateModalContent } from '../CustomFilters/update';
import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ModalFooter from '../common/Layout/Modal/ModalFooter';
import inputField from '../../../caro-ui-commonfiles/utils/inputField';
import { ActionColors } from '../../../caro-ui-commonfiles/utils/colors';
import IconButtonDropdown from '../../../caro-ui-commonfiles/components/DropdownMenu/IconButtonDropdown';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import { useUserPermission } from '../../Utils/hooks/getUserPermission';
import { getUserPrimaryInstitutionId } from '../../Utils/Chat/chatUtility';
import { calcCurrentPage } from '../../Utils/DataTableUtilities';
import useWindowWidthListener from '../../Utils/hooks/useWindowWidthListener';
import {
  onFilterSelection,
  deleteCustomFilter,
} from '../../../actions/Datatables/patientFilter';

const CustomFilters = ({
  hasPagesize,
  hasPagination,
  reloadTable,
  setReloadTable,
  setFlashMessage,
}) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    value: '',
    start: 0,
    length: 10,
    dir: 'asc',
    column: 'id',
  });
  const [pageState, setPageState] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [currCustomFilter, setCurrCustomFilter] = useState(false);
  const [deleteOperation, setDeleteOperation] = useState({
    display: false,
    id: 0,
    title: '',
  });
  const responsive = useWindowWidthListener();
  const [filterRule, setFilterRule] = useState(null);

  const [selectedSite, setSelectedSite] = useState(null);
  const [sites, setSites] = useState([]);
  const [existingFilters, setExistingFilters] = useState([]);
  const [preset, setPreset] = useState([]);
  const [sort, setSort] = useState({
    dir: 'asc',
    column: 'id',
  });
  const [unionQuery, setUnionQuery] = useState({
    isUnion: false,
    unionRules: '',
    unionSqlQuery: null,
  });
  const hasSiteReadPermission = useUserPermission(
    I18n.t('types.componentType.sites'),
    I18n.t('actions.read'),
    true
  );
  const [typingTimeOut, setTypingTimeOut] = useState(0);
  const { user } = useSelector(state => state.session);
  const { allFilters, savedFilter } = useSelector(
    state => state.patientFilters
  );

  const reduxDispatcher = useDispatch();

  useEffect(() => {
    const Rows = data => {
      const retrievedData = [...data];
      const rows = [];
      if (retrievedData) {
        for (let index = 0; index < retrievedData.length; index++) {
          const { isDefault } = retrievedData[index];
          rows.push({
            id: retrievedData[index].id,
            name:
              retrievedData[index].id !== 1
                ? retrievedData[index].name
                : I18n.t('customFilters_view.allPatients'),
            site: retrievedData[index].institutions,
            isDefault,
          });
        }
      }
      return rows;
    };

    const fetchData = () => {
      setLoading(true);
      ApiClient.GET({
        url: serviceConfig.brokerService.getAllFilters.uri,
        payload: { query },
        timeout: 30000,
      })
        .then(result => {
          setFetchedData(Rows(result.data.filtersList));
          setRecordsTotal(result.data.recordsTotal);
        })
        .catch(error => {
          setFlashMessage({
            type: error.response.data.type,
            content: error.response.data.content,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (typingTimeOut > 0) clearTimeout(typingTimeOut);
    if (query.value.length === 0) {
      fetchData();
    } else if (query.value.length > 1) {
      const timeOut = setTimeout(() => {
        fetchData();
      }, 500);
      setTypingTimeOut(timeOut);
    }
    return () => {
      clearTimeout(typingTimeOut);
    };
  }, [query, reloadTable, I18n.language]);

  const items = [{ value: '10' }, { value: '20' }, { value: '30' }];
  const tableProps = {
    defaultSorted: [
      {
        id: 'record',
        desc: false,
      },
    ],
    onSortedChange: (newSorted, column, shiftKey) => {
      setSort({
        column: newSorted[0].id,
        dir: newSorted[0].desc ? 'desc' : 'asc',
      });
    },
    previousText: I18n.t('datatable.previous'),
    nextText: I18n.t('datatable.next'),
    noDataText: I18n.t('common_labels.label_noData'),
    pageText: I18n.t('datatable.showing'),
    ofText: I18n.t('datatable.of'),
    rowsText: I18n.t('datatable.entries'),
    rowsSelectorText: I18n.t('datatable.recordsPerPage'),
  };

  useEffect(() => {
    setQuery({
      start: currentPage * pageState,
      length: pageState,
      dir: sort.dir,
      column: sort.column,
      value: query.value,
    });
  }, [currentPage, pageState, sort]);

  const displayEditModal = (customFilterId, customFilterName) => {
    setCurrCustomFilter({ id: customFilterId, name: customFilterName });
    setDisplayModal(true);
  };

  const closeModal = () => {
    setDisplayModal(false);
    setCurrCustomFilter(false);
  };

  const deleteCustomFilterHandler = async customFilterId => {
    ApiClient.POST({
      url: serviceConfig.brokerService.deleteCustomFilter.uri,
      payload: {
        id: customFilterId,
      },
    })
      .then(response => {
        if (response) {
          reduxDispatcher(
            deleteCustomFilter({
              allFilters: customFilterId,
              ...savedFilter,
            })
          );
          const page = calcCurrentPage(
            recordsTotal - 1,
            pageState,
            currentPage
          );
          if (page !== currentPage) {
            setCurrentPage(page);
          } else {
            setReloadTable(!reloadTable);
          }
          setFlashMessage({
            type: response.data.type,
            content: response.data.content,
          });
          setDeleteOperation({
            display: false,
            id: 0,
            title: '',
          });
        }
      })
      .catch(error => {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      });
  };

  const closeMessagePrompt = () => {
    setDeleteOperation({
      display: false,
      id: 0,
      title: '',
    });
  };

  const columns = useMemo(() => {
    const updateFilterDefaultStatus = async (id, isDefault, institutionId) => {
      const userPrimaryInstitutionId = getUserPrimaryInstitutionId(user);
      ApiClient.POST({
        url: serviceConfig.brokerService.updateFilterDefaultStatus.uri + id,
        payload: {
          isDefault,
          institutionId,
        },
      })
        .then(response => {
          if (response) {
            const filterInstitutionIds = response.data.filter.institutions.map(
              institution => {
                return institution.id;
              }
            );
            if (filterInstitutionIds.includes(userPrimaryInstitutionId)) {
              let defaultFilterIndex = 0;
              for (let i = 0; i < allFilters.length; i++) {
                if (allFilters[i].filterId === id && isDefault) {
                  defaultFilterIndex = i;
                }
              }
              reduxDispatcher(
                onFilterSelection({
                  allFilters: allFilters,
                  id: allFilters[defaultFilterIndex].filterId,
                  index: defaultFilterIndex,
                  name: allFilters[defaultFilterIndex].key,
                  rule: allFilters[defaultFilterIndex].rule,
                })
              );
            }
            setFlashMessage({
              type: 'success',
              content: I18n.t('flash.customFilterUpdated'),
            });
            setReloadTable(!reloadTable);
          }
        })
        .catch(error => {
          setFlashMessage({
            type: error.response.data.type,
            content: error.response.data.content,
          });
        });
    };

    const checkCustomFilterEdit = async customFilterId => {
      try {
        const filterToUpdate = await ApiClient.POST({
          url: serviceConfig.brokerService.editCustomFilter.uri,
          payload: {
            id: customFilterId,
          },
        });
        if (filterToUpdate) {
          const filterData = filterToUpdate.data;
          if (filterData) {
            setFilterRule(filterData.filterRule);
            const filterSites = filterData.filter.institutions
              ? filterData.filter.institutions.map(site => ({
                  value: site.id,
                  label: site.name,
                }))
              : [];
            setSelectedSite(filterSites);
            setSites(filterData.sites);
            setExistingFilters(filterData.prefilledFilters);
            setPreset(filterData.presetNames);
            setUnionQuery({
              isUnion: filterData.filter.isUnion,
              unionRules: filterData.filter.unionRules,
              unionSqlQuery: filterData.filter.unionSqlQuery,
            });
            return filterData;
          }
        }
      } catch (error) {
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
        throw new Error(error.response);
      }
    };
    return [
      {
        Header: props => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{I18n.t('customFilters_view.label_customFilterId')}</span>
            </>
          );
        },
        headerClassName: 'data-table__column-1__header',
        accessor: 'id',
        sortable: true,
        filterable: false,
      },
      {
        Header: props => {
          return (
            <>
              {sort.column === props.column.id && (
                <Box width={20}>
                  <Icon
                    name="sortArrow"
                    size={22}
                    className={
                      sort.column === props.column.id && sort.dir === 'desc'
                        ? 'desc-sort'
                        : ''
                    }
                  />
                </Box>
              )}
              <span>{I18n.t('customFilters_view.label_customFilterName')}</span>
            </>
          );
        },
        accessor: 'name',
        headerClassName: 'data-table__column-1__header',
        sortable: true,
        filterable: false,
      },
      {
        Header: () => {
          return <span>{I18n.t('admin_view.label_site')}</span>;
        },
        accessor: 'site',
        sortable: false,
        filterable: false,
        style: {
          overflow: 'visible',
        },
        Cell: ({ row }) => {
          const { site, id } = row;

          return id !== 1 ? <TagsCell tagList={site} /> : null;
        },
      },
      {
        Header: '',
        accessor: 'rowOptions',
        sortable: false,
        filterable: false,
        minWidth: 160,
        maxWidth: 160,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'space-around',
        },
        Cell: props => {
          const { id, name } = props.row;
          const institutionId = props.original.site[0].id;
          const { isDefault } = props.original;
          return (
            <>
              {responsive ? (
                <div>
                  <IconButtonDropdown
                    items={
                      id != 1
                        ? [
                            {
                              value: isDefault
                                ? I18n.t('common_buttons.unsetDefault')
                                : I18n.t('common_buttons.setToDefault'),
                            },
                            { value: I18n.t('common_labels.label_edit') },
                            { value: I18n.t('common_labels.label_delete') },
                          ]
                        : [
                            {
                              value: isDefault
                                ? I18n.t('common_buttons.unsetDefault')
                                : I18n.t('common_buttons.setToDefault'),
                            },
                          ]
                    }
                    onClick={(e, action) => {
                      if (
                        action.value == I18n.t('common_buttons.unsetDefault') ||
                        action.value == I18n.t('common_buttons.setToDefault')
                      ) {
                        updateFilterDefaultStatus(
                          id,
                          !isDefault,
                          institutionId
                        );
                      } else if (
                        action.value == I18n.t('common_labels.label_edit')
                      ) {
                        checkCustomFilterEdit(id).then(response => {
                          if (response) displayEditModal(id, name);
                        });
                      } else {
                        setDeleteOperation({
                          display: true,
                          id,
                          title: `${I18n.t(
                            'common_labels.msg_confirm_filter_delete_headline'
                          )}: ${name}`,
                        });
                      }
                    }}
                  />
                </div>
              ) : (
                <>
                  <div style={{ marginRight: 10 }}>
                    <IconButton
                      id={id}
                      type="button"
                      name={isDefault ? 'unflag' : 'flag'}
                      size={30}
                      onClick={() => {
                        updateFilterDefaultStatus(
                          id,
                          !isDefault,
                          institutionId
                        );
                      }}
                      tooltipText={
                        isDefault
                          ? I18n.t('common_buttons.unsetDefault')
                          : I18n.t('common_buttons.setToDefault')
                      }
                    />
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <IconButton
                      type="button"
                      name="edit"
                      disabled={id == 1}
                      size={30}
                      onClick={() => {
                        checkCustomFilterEdit(id).then(response => {
                          if (response) displayEditModal(id, name);
                        });
                      }}
                      tooltipText={I18n.t(
                        'customFilters_view.label_edit_customFilters'
                      )}
                    />
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <IconButton
                      disabled={id == 1}
                      type="button"
                      name="delete"
                      size={30}
                      fill={ActionColors.DELETE}
                      onClick={() =>
                        setDeleteOperation({
                          display: true,
                          id,
                          title: `${I18n.t(
                            'common_labels.msg_confirm_filter_delete_headline'
                          )}: ${name}`,
                        })
                      }
                      tooltipText={I18n.t(
                        'customFilters_view.label_delete_customFilter'
                      )}
                    />
                  </div>
                </>
              )}
            </>
          );
        },
      },
    ];
  }, [responsive, reloadTable, setFlashMessage, sort.column, sort.dir]);

  return (
    <>
      <div style={{ width: 250, position: 'absolute', top: 0, right: 0 }}>
        <Input
          id="specialId"
          name="Name"
          placeholder={I18n.t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={query.value}
          handleOnChange={e => {
            setQuery({
              value: e.target.value,
              start: 0,
              length: query.length,
              dir: sort.dir,
              column: sort.column,
            });
            setCurrentPage(0);
            setPageState(query.length);
          }}
        />
      </div>
      <DataTable
        data={fetchedData}
        columns={columns}
        pageSize={hasPagesize}
        pageSizeOptions={items}
        pagination={hasPagination}
        isLoading={loading}
        tableProps={tableProps}
        onTableChange={(index, size) => {
          setQuery({
            value: query.value,
            start:
              fetchedData && index * size > recordsTotal ? 0 : index * size,
            length: size,
            dir: sort.dir,
            column: sort.column,
          });
          setCurrentPage(
            fetchedData && index * size > recordsTotal ? 0 : index
          );
          setPageState(size);
        }}
        page={currentPage}
        pages={Math.ceil(recordsTotal / query.length)}
        totalRecord={recordsTotal}
      />
      {currCustomFilter && displayModal && (
        <Modal
          title={`${I18n.t('customFilters_view.label_edit_customFilters')}: ${
            currCustomFilter.name
          }`}
          contentComponent={
            <CustomFilterUpdateModalContent
              customFilterId={currCustomFilter.id}
              customFilterName={currCustomFilter.name}
              closeModal={closeModal}
              reloadTable={() => setReloadTable(!reloadTable)}
              setFlashMessage={setFlashMessage}
              LoadedRule={filterRule}
              selectedSite={selectedSite}
              setSelectedSite={setSelectedSite}
              sites={sites}
              existingFilters={existingFilters}
              preset={preset}
              unionQuery={unionQuery}
              hasSiteReadPermission={hasSiteReadPermission}
            />
          }
          footerComponent={
            <ModalFooter
              close={closeModal}
              labelCTA={I18n.t('common_buttons.update')}
              form="create-custom-filter-form"
            />
          }
          openModal={displayModal}
          onClose={closeModal}
          hideFlashMessage={HideFlashMessage}
        />
      )}
      {deleteOperation.display && (
        <Modal
          isConfirmationDialog
          title={deleteOperation.title}
          contentComponent={
            <PromptContent
              message={I18n.t('common_labels.msg_confirm_filter_delete')}
            />
          }
          footerComponent={
            <PromptFooter
              close={closeMessagePrompt}
              confirmHandler={() => {
                if (deleteOperation.id > 0) {
                  deleteCustomFilterHandler(deleteOperation.id);
                }
              }}
            />
          }
          openModal={deleteOperation.display}
          onClose={closeMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </>
  );
};
export default CustomFilters;
