import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import Loader from 'react-loader-spinner';

import { Box, Flex, Icon } from '@ui-common-files/components';
import Pagination from 'src/Views/CarePathWay/Builder/PanelSidebar/Pagination';
import CaretaskItem from './CaretaskItem';

import { Greys, PrimaryColor } from '@ui-common-files/utils';

import { useOverviewStore } from '../../../Overview.store';

import useCaretasksDataService from './CaretasksData.service';

import { PAGE_SIZE } from './CaretasksData.constant';

import './CaretasksData.styles.scss';

const CaretasksData = () => {
  const { t } = useTranslation();
  const { overviewCaretasks } = useOverviewStore(
    useShallow(state => ({
      overviewCaretasks: state.overviewCaretasks,
    }))
  );
  const {
    hasNoResultsBySearch,
    hasNoTemplates,
    onPageChange,
    onSelect,
    getButtonClassName,
    getScoringItem,
  } = useCaretasksDataService();

  return (
    <Box>
      <Box className="caretasks_data_title">
        {t('types.componentType.careplans')}
      </Box>

      {overviewCaretasks.isLoading && (
        <Box className="roleOverlapSpinner">
          <Loader
            className="loader"
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            width={40}
            height={40}
          />
        </Box>
      )}

      {hasNoTemplates && (
        <Box className="caretasks_no-data">
          {t(
            'patients_view.overview.modal_select_data.caretask_container.no_data'
          )}
        </Box>
      )}

      {hasNoResultsBySearch && (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Icon name="SearchOff" fill={Greys.LIGHT_GREY_75} size={48} />
          <Box className="caretasks_no-data" otherStyles={{ marginTop: 16 }}>
            {t('carePathway_builder.careTask_list.no_CareTask_found')}
          </Box>
        </Flex>
      )}

      {overviewCaretasks.items?.length !== 0 &&
        overviewCaretasks.items?.map(template => {
          return (
            <Flex
              key={template.id}
              className="caretasks_data_separator"
              flexDirection="column"
            >
              <CaretaskItem template={template} />
              <Flex flexWrap="wrap" className="caretasks_score_section">
                {template.templateScorings.map(scoring => {
                  const {
                    shouldDisable,
                    scoreDescription,
                    selectedScoreIndex,
                  } = getScoringItem(scoring);

                  return (
                    <button
                      key={`scoring-${scoring.id}`}
                      className={getButtonClassName(
                        scoring,
                        selectedScoreIndex
                      )}
                      onClick={e =>
                        onSelect(
                          e,
                          scoring.id,
                          template.id,
                          scoring.scoringTypeId,
                          template.name,
                          scoreDescription,
                          template.totalScore
                        )
                      }
                      type="button"
                      disabled={shouldDisable && selectedScoreIndex === -1}
                    >
                      <span>{scoreDescription}</span>
                    </button>
                  );
                })}
              </Flex>
            </Flex>
          );
        })}
      {!overviewCaretasks.isLoading &&
        overviewCaretasks.items?.length !== 0 && (
          <Pagination
            currentPage={overviewCaretasks.page}
            totalCount={overviewCaretasks.totalRecords}
            pageSize={PAGE_SIZE}
            onPageChange={onPageChange}
          />
        )}
    </Box>
  );
};

export default CaretasksData;
