import patientFilterActions from '../../src/Utils/ReduxUtilities/Actions/patientFilter.json';

const initialState = {
  allFilters: [],
  savedFilter: {
    id: null,
    index: 0,
    name: null,
    rule: null,
  },
  customFilter: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case patientFilterActions.SELECT_FILTER:
      const { allFilters, ...savedFilter } = action.payload;
      return {
        allFilters: allFilters,
        savedFilter: savedFilter,
        customFilter: null,
      };
    case patientFilterActions.SELECT_CUSTOM_FILTER:
      return {
        allFilters: state.allFilters,
        savedFilter: state.savedFilter,
        customFilter: action.payload,
      };
    case patientFilterActions.RESET_PATIENTS_FILTER:
      return { ...initialState };

    case patientFilterActions.ADD_CUSTOM_FILTER:
      return {
        ...state,
        allFilters: [...state.allFilters, action.payload.allFilters],
      };

    case patientFilterActions.UPDATE_CUSTOM_FILTER:
      const updatedItems = state.allFilters.map(filter =>
        filter.filterId !== action.payload.allFilters.filterId
          ? filter
          : action.payload.allFilters
      );
      return {
        ...state,
        allFilters: updatedItems,
      };

    case patientFilterActions.DELETE_CUSTOM_FILTER:
      const filteredItems = state.allFilters.filter(
        item => item.filterId !== action.payload.allFilters
      );
      return {
        ...state,
        allFilters: filteredItems,
      };

    default:
      return state;
  }
};

export default reducer;
