import React from "react";
import PropTypes from "prop-types";
import I18n from "i18next";
import Flex from "../../../caro-ui-commonfiles/components/Flex/Flex";
import Button from "../../../caro-ui-commonfiles/components/Button/Button";
import  { ButtonType } from "../../../caro-ui-commonfiles/utils/button";
import UsFilterBottomWarning from "../../../caro-ui-commonfiles/components/UsFilterWarnings/UsFilterBottomWarning";

const ShowModalFooter = ({
  close,
  warningFlag = false,
  warningMessage = null,
}) => {
  return (
    <>
      {warningFlag && (
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={{ margin: "0 auto" }}
        >
          <div className="patient-index__us-warning-container">
            <div>
              <UsFilterBottomWarning message={I18n.t(warningMessage)} />
            </div>
          </div>
        </Flex>
      )}
      <Flex
        flexDirection="row"
        justifyContent="flex-end"
        otherStyles={warningFlag ? {} : { width: "100%" }}
      >
        <Button
          label={I18n.t("common_buttons.close")}
          type={ButtonType.BUTTON}
          onClick={close}
          variant="contained"
        />
      </Flex>
    </>
  );
};

ShowModalFooter.propTypes = {
  close: PropTypes.func.isRequired,
};

export default ShowModalFooter;
