import I18n from 'i18next';
import moment from 'moment';

const repeatTypes = () => [
  { value: 1, label: I18n.t('recurrence.daily'), key: 'recurrence.daily' },
  { value: 2, label: I18n.t('recurrence.weekly'), key: 'recurrence.weekly' },
  { value: 3, label: I18n.t('recurrence.monthly'), key: 'recurrence.monthly' },
  { value: 4, label: I18n.t('recurrence.yearly'), key: 'recurrence.yearly' },
];

const apptRepeatTypes = () => [
  { value: 0, label: I18n.t('recurrence.noRepeat') },
  { value: 1, label: I18n.t('recurrence.daily') },
  { value: 2, label: I18n.t('recurrence.weekly') },
  { value: 3, label: I18n.t('recurrence.monthly') },
  { value: 4, label: I18n.t('recurrence.yearly') },
];

const repeatTypesEnum = {
  HOURLY: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  YEARLY: 4,
};

const lifespanValueToUnit = {
  0: 'HOUR',
  1: 'DAY',
  2: 'WEEK',
  3: 'MONTH',
  4: 'YEAR',
};

const lifespanLabelMap = {
  HOUR: 'hour(s)',
  DAY: 'day(s)',
  WEEK: 'week(s)',
  MONTH: 'month(s)',
  YEAR: 'year(s)',
};

function getLifespanObject(lifespanKey) {
  const key = `lifespan.${lifespanKey.toUpperCase()}`;
  const label = lifespanLabelMap[lifespanKey];
  const value = lifespanUnitToValue[lifespanKey.toUpperCase()];

  return {
    key,
    label,
    value,
  };
}

const lifespanUnitToValue = {
  HOUR: 0,
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEAR: 4,
};

const lifespanEnum = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  YTD: 'ytd',
  LAST_DATA: 'lastData',
  TODAY: 'today',
};

const rruleFreqToRepeatTypeIndex = () => [
  { value: 4, label: I18n.t('recurrence.yearly'), key: 'recurrence.yearly' },
  { value: 3, label: I18n.t('recurrence.monthly'), key: 'recurrence.monthly' },
  { value: 2, label: I18n.t('recurrence.weekly'), key: 'recurrence.weekly' },
  { value: 1, label: I18n.t('recurrence.daily'), key: 'recurrence.daily' },
  {
    value: 0,
    label: I18n.t('recurrence.noRepeat'),
    key: 'recurrence.noRepeat',
  },
];

const lifespanUnits = () => [
  { value: 0, label: I18n.t('lifespan.HOUR'), key: 'lifespan.HOUR' },
  { value: 1, label: I18n.t('lifespan.DAY'), key: 'lifespan.DAY' },
  { value: 2, label: I18n.t('lifespan.WEEK'), key: 'lifespan.WEEK' },
  { value: 3, label: I18n.t('lifespan.MONTH'), key: 'lifespan.MONTH' },
  { value: 4, label: I18n.t('lifespan.YEAR'), key: 'lifespan.YEAR' },
];

const lifespanUnitsSmall = () => [
  { value: 0, label: I18n.t('lifespan.HOUR'), key: 'lifespan.HOUR' },
  { value: 1, label: I18n.t('lifespan.DAY'), key: 'lifespan.DAY' },
  { value: 2, label: I18n.t('lifespan.WEEK'), key: 'lifespan.WEEK' },
  { value: 3, label: I18n.t('lifespan.MONTH'), key: 'lifespan.MONTH' },
  { value: 4, label: I18n.t('lifespan.YEAR'), key: 'lifespan.YEAR' },
];

const weekdays = () => [
  { day: 'monday', translation: I18n.t('recurrence.daysOfWeek.monday') },
  { day: 'tuesday', translation: I18n.t('recurrence.daysOfWeek.tuesday') },
  { day: 'wednesday', translation: I18n.t('recurrence.daysOfWeek.wednesday') },
  { day: 'thursday', translation: I18n.t('recurrence.daysOfWeek.thursday') },
  { day: 'friday', translation: I18n.t('recurrence.daysOfWeek.friday') },
  { day: 'saturday', translation: I18n.t('recurrence.daysOfWeek.saturday') },
  { day: 'sunday', translation: I18n.t('recurrence.daysOfWeek.sunday') },
];

const timeOfDay = {
  MORNING: 8,
  MIDDAY: 16,
  EVENING: 20,
  NIGHT: 0,
};

const timeUnitInSeconds = {
  0: 3600,
  1: 86400,
  2: 604800,
  3: 2628000,
  4: 31536000,
};

const timeUnitStringInSeconds = {
  HOUR: 3600,
  DAY: 86400,
  WEEK: 604800,
  MONTH: 2628000,
  YEAR: 31536000,
};

const getMonths = () => {
  return [
    I18n.t('recurrence.monthsOfYear.january'),
    I18n.t('recurrence.monthsOfYear.febuary'),
    I18n.t('recurrence.monthsOfYear.march'),
    I18n.t('recurrence.monthsOfYear.april'),
    I18n.t('recurrence.monthsOfYear.may'),
    I18n.t('recurrence.monthsOfYear.june'),
    I18n.t('recurrence.monthsOfYear.july'),
    I18n.t('recurrence.monthsOfYear.august'),
    I18n.t('recurrence.monthsOfYear.september'),
    I18n.t('recurrence.monthsOfYear.october'),
    I18n.t('recurrence.monthsOfYear.november'),
    I18n.t('recurrence.monthsOfYear.december'),
  ];
};

const generateRepeatInterval = () => {
  const intervalRange = [];
  for (let i = 1; i <= 99; i++) {
    intervalRange.push({ value: i, label: i });
  }
  return intervalRange;
};

const generateWeekdayArray = weekdays => {
  const checkedWeekdays = new Array(7).fill(false);
  if (weekdays && weekdays.length) {
    weekdays.map(weekday => {
      checkedWeekdays[weekday] = true;
      return null;
    });
  }
  return checkedWeekdays;
};

const getWeeklyRecurrence = (recurrence, initWeeklyRecurrenceArray) => {
  if (recurrence && recurrence.freq === 2) {
    return generateWeekdayArray(
      recurrence.byweekday.map(weekday => weekday.weekday)
    );
  }
  return initWeeklyRecurrenceArray;
};

const getTimeStringFromObj = startTime => {
  return `${startTime.hour}:${
    startTime.minute === 0 ? '00' : startTime.minute
  }`;
};

const getEndTime = startTime => ({
  hour: startTime.hour + Math.floor(((startTime.minute % 60) + 30) / 60),
  minute: (startTime.minute + 30) % 60,
});

const getCareplanSequence = () => [
  {
    value: 1,
    label: I18n.t('common_labels.label_before'),
    key: 'common_labels.label_before',
  },
  {
    value: 2,
    label: I18n.t('common_labels.label_after'),
    key: 'common_labels.label_after',
  },
];

const careplanSequenceValueToSignMapper = {
  1: -1,
  2: 1,
};

const getRelativeDurationText = values => {
  const {
    startDateUnit,
    startDateValue,
    startDateSequence,
    startTime,
    occurrenceCount,
    occurrenceCountCheck,
    repeatType,
    repeatInterval,
    endDateValue,
    endDateUnit,
    endDateSequence,
    weeklyRecurrence,
    lifespan,
  } = values;
  let startText = '';
  let endText = '';
  let occurrence = '';
  const lifeSpan = `${lifespan} ${
    lifespanUnits().find(item => item.value === values.lifespanUnit.value).label
  }`;

  if (parseInt(startDateValue, 10) === 0) {
    startText = I18n.t(
      `recurrence.${
        startDateUnit.value === 0
          ? 'relativeDurationTextSameTime'
          : 'relativeDurationTextSameDay'
      }`
    );
  } else {
    const startTextTranslationKey =
      startDateUnit.value === 0
        ? 'recurrence.relativeDurationTextEnd'
        : 'recurrence.relativeDurationText';
    startText = I18n.t(startTextTranslationKey, {
      duration: startDateValue,
      durationUnit: I18n.t(startDateUnit.key),
      sequence: I18n.t(startDateSequence.key || startDateSequence),
    });
  }

  let repeatString = repeatType.label;
  if (repeatInterval.value === 1) {
    repeatString = I18n.t(repeatType.key);
  } else if (repeatType.value === 2) {
    const days = weekdays();
    let checkedWeekdays;
    weeklyRecurrence.map((checked, i) => {
      if (checked)
        checkedWeekdays = `${checkedWeekdays ? `${checkedWeekdays},` : ''} ${
          days[i].translation
        }`;
    });
    repeatString = I18n.t('recurrence.weekdayRepeatText', {
      interval: repeatInterval.value > 1 ? repeatInterval.value : '',
      days: checkedWeekdays,
    });
  } else if (repeatType.value === 1 && repeatInterval.value > 1) {
    repeatString = I18n.t('recurrence.dayIntervalText', {
      interval: repeatInterval.value,
    });
  } else if (repeatType.value === 3 && repeatInterval.value > 1) {
    repeatString = I18n.t('recurrence.monthIntervalText', {
      interval: repeatInterval.value,
    });
  } else if (repeatType.value === 4 && repeatInterval.value > 1) {
    repeatString = I18n.t('recurrence.yearIntervalText', {
      interval: repeatInterval.value,
    });
  }

  if (occurrenceCountCheck) {
    const occurrenceCountValue = occurrenceCount ? occurrenceCount : 1;
    endText = I18n.t('recurrence.relativeDurationEnd', {
      occurrenceCountValue,
    });
    occurrence = I18n.t('recurrence.durationTextRepeat', {
      repeatString,
      occurrenceCount,
      startTime,
    });
  } else {
    if (parseInt(endDateValue, 10) === 0) {
      endText = I18n.t(
        `recurrence.${
          endDateUnit.value === 0
            ? 'relativeDurationTextSameTime'
            : 'relativeDurationTextEndSameDay'
        }`
      );
    } else {
      endText = I18n.t('recurrence.relativeDurationTextEnd', {
        duration: endDateValue,
        durationUnit: I18n.t(endDateUnit.key),
        sequence: I18n.t(endDateSequence.key || endDateSequence),
      });
    }
    occurrence = I18n.t('recurrence.durationEndRepeat', {
      repeatString,
      startTime,
    });
  }

  return { startText, endText, occurrence, lifeSpan };
};

const initWeeklyRecurrenceArray = () => {
  const weeklyArray = new Array(7).fill(false);
  weeklyArray[new Date().getDay() - 1] = true;
  return weeklyArray;
};

const getDurationObjectForCareplan = careplan => {
  const careplanSequenceObj = getCareplanSequence();
  const lifespanUnitsObj = lifespanUnits();
  const lifespanUnitsObjSmall = lifespanUnitsSmall();
  const repeatTypesObj = rruleFreqToRepeatTypeIndex();

  const {
    recurrence,
    duration,
    durationUnit,
    endDuration,
    endDurationUnit,
    lifespan,
    lifespanUnit,
  } = careplan;
  const repeatType =
    recurrence && recurrence.freq != undefined
      ? repeatTypesObj[recurrence.freq]
      : repeatTypesObj[3];
  const repeatInterval =
    recurrence && recurrence.interval
      ? { value: recurrence.interval, label: recurrence.interval }
      : { value: 1, label: 1 };

  return {
    startDateUnit: durationUnit
      ? lifespanUnitsObjSmall[lifespanUnitToValue[durationUnit]]
      : lifespanUnitsObjSmall[1],
    startDateValue: duration ? Math.abs(duration) : 0,
    startDuration: duration || 0,
    startDateSequence: careplanSequenceObj[duration > 0 ? 1 : 0],
    startTime: careplan.startTime,
    occurrenceCount: recurrence && recurrence.count ? recurrence.count : 1,
    occurrenceCountCheck: recurrence && !recurrence.until,
    repeatType,
    repeatInterval,
    endDateValue: endDuration ? Math.abs(endDuration) : 0,
    endDateUnit: endDurationUnit
      ? lifespanUnitsObjSmall[lifespanUnitToValue[endDurationUnit]]
      : lifespanUnitsObjSmall[1],
    endDateSequence: careplanSequenceObj[endDuration > 0 ? 1 : 0],
    weeklyRecurrence: getWeeklyRecurrence(
      recurrence,
      initWeeklyRecurrenceArray()
    ),
    lifespan: lifespan || 1,
    lifespanUnit: lifespanUnit
      ? lifespanUnitsObj[lifespanUnitToValue[lifespanUnit]]
      : lifespanUnitsObj[1],
    recurrenceTag: careplan.templateTag || '',
  };
};

const getDurationTextForOverview = (
  careplan,
  appointmentTypeTranslationKey
) => {
  const durationObj = getDurationObjectForCareplan(careplan);
  return getRelativeDurationText({
    ...durationObj,
    startDateSequence: durationObj.startDateSequence.label,
    endDateSequence: durationObj.endDateSequence.label,
  });
};

const sortCareplansByStartingTime = (a, b) => {
  if (a.duration && !b.duration) {
    return -1;
  }
  if (!a.duration && b.duration) {
    return 1;
  }
  if (a.duration && b.duration) {
    return (
      a.duration.startDuration *
        timeUnitInSeconds[a.duration.startDateUnit.value] -
      b.duration.startDuration *
        timeUnitInSeconds[b.duration.startDateUnit.value]
    );
  }
  return 0;
};

const sortCarepathwayCareplanTemplateByStartingTime = (a, b) => {
  return (
    a.carePathwayTemplateAppointmentType.duration *
      timeUnitStringInSeconds[
        a.carePathwayTemplateAppointmentType.durationUnit
      ] -
    b.carePathwayTemplateAppointmentType.duration *
      timeUnitStringInSeconds[b.carePathwayTemplateAppointmentType.durationUnit]
  );
};

const sortCarepathwayCareplanByStartingTime = (a, b) => {
  return (
    a.carePathwayCareplanAppointmentType.duration *
      timeUnitStringInSeconds[
        a.carePathwayCareplanAppointmentType.durationUnit
      ] -
    b.carePathwayCareplanAppointmentType.duration *
      timeUnitStringInSeconds[b.carePathwayCareplanAppointmentType.durationUnit]
  );
};
function getNextDayOfWeek(date, dayOfWeek) {
  const resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));
  return resultDate;
}
const isReferenceDateToday = date => moment(date).isSame(moment(), 'day');

const getDateFromDuration = (date, dateUnit, duration) => {
  const resultDate = new Date(date.getTime());
  switch (dateUnit) {
    case lifespanUnitToValue.HOUR:
      resultDate.setHours(resultDate.getHours() + duration);
      break;
    case lifespanUnitToValue.DAY:
      resultDate.setDate(resultDate.getDate() + duration);
      break;
    case lifespanUnitToValue.WEEK:
      resultDate.setDate(resultDate.getDate() + duration * 7);
      break;
    case lifespanUnitToValue.MONTH:
      resultDate.setMonth(resultDate.getMonth() + duration);
      break;
    case lifespanUnitToValue.YEAR:
      resultDate.setFullYear(resultDate.getFullYear() + duration);
      break;
    default:
      break;
  }
  return resultDate;
};

const getOccurrencesEndDate = (
  startDate,
  occurrenceCount,
  repeatType,
  interval,
  weeklyRecurrence
) => {
  const date = new Date(startDate.getTime());
  switch (repeatType) {
    case 1:
      date.setDate(date.getDate() + occurrenceCount * interval);
      break;
    case 2: {
      const indexOfLastOccurrenceDay = weeklyRecurrence.lastIndexOf(true);
      if (indexOfLastOccurrenceDay === 6) {
        date.setDate(getNextDayOfWeek(date, 0).getDate());
      } else {
        date.setDate(
          getNextDayOfWeek(date, indexOfLastOccurrenceDay + 1).getDate()
        );
      }
      date.setDate(date.getDate() + occurrenceCount * interval * 7);
      break;
    }
    case 3:
      date.setMonth(date.getMonth() + occurrenceCount * interval);
      break;
    case 4:
      date.setFullYear(date.getFullYear() + occurrenceCount * interval);
      break;

    default:
      break;
  }
  return date;
};
const addLifespan = (date, lifeSpan, lifeSpanUnit) => {
  switch (lifeSpanUnit) {
    case lifespanUnitToValue.HOUR:
      date.setHours(date.getHours() + parseInt(lifeSpan));
      break;
    case lifespanUnitToValue.DAY:
      date.setDate(date.getDate() + parseInt(lifeSpan));
      break;
    case lifespanUnitToValue.WEEK:
      date.setDate(date.getDate() + parseInt(lifeSpan) * 7);
      break;
    case lifespanUnitToValue.MONTH:
      date.setMonth(date.getMonth() + parseInt(lifeSpan));
      break;
    case lifespanUnitToValue.YEAR:
      date.setFullYear(date.getFullYear() + parseInt(lifeSpan));
      break;
    default:
      break;
  }
  return date;
};
const calculateStartDuration = (startDateValue, startDateSequence) => {
  const mapperValue =
    careplanSequenceValueToSignMapper[startDateSequence.value];
  return startDateValue !== 0 ? startDateValue * mapperValue : startDateValue;
};

const getCareplanDuration = duration => {
  const todayDate = new Date();
  const {
    startTime,
    startDateUnit,
    occurrenceCountCheck,
    occurrenceCount,
    repeatType,
    repeatInterval,
    weeklyRecurrence,
    endDateValue,
    endDateSequence,
    endDateUnit,
    startDateValue,
    startDateSequence,
  } = duration;
  const hour =
    startTime.hour !== undefined ? startTime.hour : startTime.split(':')[0];
  const minute =
    startTime.minute !== undefined ? startTime.minute : startTime.split(':')[1];
  todayDate.setHours(parseInt(hour, 10), parseInt(minute, 10), 0);

  const startDate = getDateFromDuration(
    todayDate,
    startDateUnit.value,
    calculateStartDuration(startDateValue, startDateSequence)
  );
  let endDate;
  if (occurrenceCountCheck) {
    endDate = getOccurrencesEndDate(
      startDate,
      occurrenceCount.value ? occurrenceCount.value - 1 : occurrenceCount - 1,
      repeatType.value,
      repeatInterval.value,
      weeklyRecurrence
    );
  } else {
    const endDuration =
      endDateSequence.value === 1 ? endDateValue * -1 : endDateValue;
    endDate = getDateFromDuration(todayDate, endDateUnit.value, endDuration);
  }
  return {
    startDate,
    endDate: addLifespan(
      endDate,
      duration.lifespan,
      duration.lifespanUnit.value
    ),
  };
};

export {
  repeatTypes,
  apptRepeatTypes,
  rruleFreqToRepeatTypeIndex,
  lifespanUnits,
  lifespanUnitsSmall,
  timeOfDay,
  timeUnitInSeconds,
  generateRepeatInterval,
  weekdays,
  repeatTypesEnum,
  generateWeekdayArray,
  getEndTime,
  getMonths,
  lifespanValueToUnit,
  lifespanUnitToValue,
  getCareplanSequence,
  careplanSequenceValueToSignMapper,
  getTimeStringFromObj,
  getRelativeDurationText,
  getWeeklyRecurrence,
  getDurationObjectForCareplan,
  getDurationTextForOverview,
  sortCareplansByStartingTime,
  sortCarepathwayCareplanTemplateByStartingTime,
  sortCarepathwayCareplanByStartingTime,
  lifespanEnum,
  getCareplanDuration,
  calculateStartDuration,
  getLifespanObject,
  isReferenceDateToday,
};
