import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import AnswerHeaderComponent from '../AnswerHeaderComponent';

import {
  getComponentOccurrencesAnswer,
  getThresholdClass,
  occurrenceHasAnswer,
} from '../AnswerResult.services';
import { isNumericThresholdCrossed } from './NumericAnswer.service';
import { CareplanComponentAnswer } from '../../../../types/Careplan';
import componentTypeOrder from '../../careplanComponentTypeOrder';
import { GeneralAnswerType } from '../GeneralAnswer.type';

import '../AnswerResultCommon.scss';

function NumericAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const { t } = useTranslation();

  const renderComponentAnswers = useCallback(
    (occurrenceAnswers: CareplanComponentAnswer[], index: number) => {
      const isThreshold: boolean = isNumericThresholdCrossed(
        componentData.thresholds,
        occurrenceAnswers[0]?.answer_numeric
      );

      const thresholdClass = getThresholdClass(isThreshold);

      let answerContent = '-';
      if (occurrenceHasAnswer(occurrenceAnswers)) {
        answerContent = occurrenceAnswers[0].answer_numeric.toString();
        if (isThreshold) answerContent += '*';
      }

      return (
        <Flex
          key={`col-header-${componentIndex}-${index}`}
          className="answer-component_header-columns-column"
          alignItems="center"
          justifyContent="center"
        >
          <span className={thresholdClass}>{answerContent}</span>
        </Flex>
      );
    },
    []
  );

  const getTitleDescription = useCallback(() => {
    let descriptionText: string = componentData.minLabel
      ? componentData.minLabel
      : t('PDFReport.min');
    descriptionText += `. ${componentData.minimumValue} - `;
    descriptionText += componentData.maxLabel
      ? componentData.maxLabel
      : t('PDFReport.max');
    descriptionText += `. ${componentData.maximumValue}`;
    return descriptionText;
  }, [componentData]);

  return (
    <Box className="answer-component">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName={
          componentData.careplanComponentTypeId ===
          componentTypeOrder.NUMERIC_VALUE
            ? 'NumericValue'
            : 'NumericRange'
        }
        title={
          <>
            <Box className="answer-component_header-title-name-title">
              {componentData.name}
            </Box>
            <Box className="answer-component_header-title-name-description">
              {getTitleDescription()}
            </Box>
          </>
        }
        columnOccurrencesRenderer={() => {
          return getComponentOccurrencesAnswer(
            allOccurrences,
            componentData.answers,
            renderComponentAnswers
          );
        }}
      />
    </Box>
  );
}
export default NumericAnswer;
