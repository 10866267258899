import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { IApiClient, ApiClientRequestParameters } from './ApiClient.types';

import {
  requestConfigHandler,
  requestErrorHandler,
  responseDataHandler,
  responseErrorHandler,
} from './Interceptors.service';

class ApiClient implements IApiClient {
  private api: AxiosInstance;

  constructor() {
    const axiosInstance: AxiosInstance = Axios.create();

    axiosInstance.interceptors.request.use(
      requestConfigHandler,
      requestErrorHandler
    );

    axiosInstance.interceptors.response.use(
      responseDataHandler,
      responseErrorHandler
    );

    this.api = axiosInstance;
  }

  private send<TResponse>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<TResponse>> {
    return this.api(config);
  }

  async POST<TResponse, TPayload>(
    parameters: ApiClientRequestParameters<TPayload>
  ): Promise<AxiosResponse<TResponse>> {
    return this.send<TResponse>({
      url: parameters.url,
      method: 'post',
      data: parameters.payload,
      signal: parameters.signal,
      timeout: parameters.timeout,
      headers: parameters.headers,
      errorCallback: parameters.errorCallback,
    });
  }

  async GET<TResponse, TPayload>(
    parameters: ApiClientRequestParameters<TPayload>
  ): Promise<AxiosResponse<TResponse>> {
    return this.send<TResponse>({
      url: parameters.url,
      method: 'get',
      params: { ...parameters.payload },
      timeout: parameters.timeout,
      signal: parameters.signal,
      errorCallback: parameters.errorCallback,
    });
  }

  async PUT<TResponse, TPayload>(
    parameters: ApiClientRequestParameters<TPayload>
  ): Promise<AxiosResponse<TResponse>> {
    return this.send<TResponse>({
      url: parameters.url,
      method: 'put',
      data: { ...parameters.payload },
      signal: parameters.signal,
      errorCallback: parameters.errorCallback,
    });
  }

  async PATCH<TResponse, TPayload>(
    parameters: ApiClientRequestParameters<TPayload>
  ): Promise<AxiosResponse<TResponse>> {
    return this.send<TResponse>({
      url: parameters.url,
      method: 'patch',
      data: { ...parameters.payload },
      signal: parameters.signal,
      errorCallback: parameters.errorCallback,
    });
  }

  async DELETE<TResponse, TPayload>(
    parameters: ApiClientRequestParameters<TPayload>
  ): Promise<AxiosResponse<TResponse>> {
    return this.send<TResponse>({
      url: parameters.url + parameters.payload.toString(),
      method: 'delete',
      signal: parameters.signal,
      errorCallback: parameters.errorCallback,
    });
  }
}

export default new ApiClient();
