import React, { useCallback, useState } from 'react';
import I18n from 'i18next';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Loader from 'react-loader-spinner';
import ImportTemplateDropzone from '../Careplans/ImportTemplateDropzone';
import { validateFileLimit, validateFileNameLimit, validateFileSize } from '../../Utils/Asset/assetHelper';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import { XMLValidator } from 'fast-xml-parser';

const OncoboxImportModalContent = ({
  oncoBoxFile,
  setOncoboxFile,
  oncoBoxErrors,
  setOncoboxErrors,
  oncoBoxSubmitQuery,
  setOncoboxSubmitQuery,
  isLoading,
}) => {
  enum MIMETYPECODES {
    xmlCode = '3c3f786d',
  }
  const [isFileNameLong, setIsFileNameLong] = useState(false)
  const maximumFileSizeInMB = 25;
  const maximumFileSize = maximumFileSizeInMB * 1000000;

  const checkifContentsAreValidXML = (xmlData: string) => {
    const options = {
      ignoreAttributes: false,
      allowBooleanAttributes: true
    };

    return XMLValidator.validate(xmlData, options);
  }

  const checkMimeType = (file: File) => {
    return new Promise((resolve, reject) => {
      try {
        var fileReader = new FileReader();
        var isMimeTypeCorrect = false
        fileReader.onloadend = function (e) {
          var arr = (new Uint8Array(e.target.result)).subarray(0, 4);
          var mimeTypeinHeader = "";
          for (var i = 0; i < arr.length; i++) {
            mimeTypeinHeader += arr[i].toString(16);
          }
          if (mimeTypeinHeader === MIMETYPECODES.xmlCode) {
            isMimeTypeCorrect = true
          }
          resolve(isMimeTypeCorrect)
          // Check the file signature against known types
        };
        fileReader.readAsArrayBuffer(file);
      } catch (error) {
        resolve(isMimeTypeCorrect)
      }
    })
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    let errors = {}
    setOncoboxErrors(null)
    setIsFileNameLong(false)
    if (acceptedFiles && acceptedFiles.length) {
      checkMimeType(acceptedFiles[0]).then((hasMimeTypeInData: boolean) => {
        const isCorrectNameLength = validateFileNameLimit(acceptedFiles)
        const isCorrectFileSize = validateFileSize(acceptedFiles, maximumFileSize)
        const isOneFile = validateFileLimit(acceptedFiles, 1)
        var isCorrectMimeType = hasMimeTypeInData
        if (isCorrectFileSize && isOneFile) {
          if (!isCorrectNameLength) {
            setIsFileNameLong(true)
          }
          const reader = new FileReader();
          reader.onloadend = function (e) {
            setOncoboxFile({
              name: '',
              data: null,
            })
            if (checkifContentsAreValidXML(e.target.result as string) === true) {
              isCorrectMimeType = true
              setOncoboxFile({ name: acceptedFiles[0].name, data: e.target.result as string });
              return
            } else {
              errors = {
                ...errors,
                incorrectMimeType: true
              }
              setOncoboxErrors({
                ...oncoBoxErrors,
                incorrectMimeType: true,
              })
              setOncoboxSubmitQuery(true)

            }

          };
          reader.readAsText(acceptedFiles[0]);
          return;
        }
        if (!isOneFile) {
          errors = {
            multipleFiles: true,
          }
        }
        if (!isCorrectMimeType) {
          errors = {
            incorrectMimeType: true
          }
        }
        if (!isCorrectFileSize) {
          errors = {
            incorrectFileSize: true,
          }
        }
        setOncoboxErrors(errors)
        setOncoboxSubmitQuery(false)
        setOncoboxFile({
          name: '',
          data: null,
        })
      }).catch(error => {
        if (error) setOncoboxErrors({ incorrectMimeType: true });
      })
      return;
    } else {
      setOncoboxErrors({
        ...oncoBoxErrors,
        incorrectMimeType: true
      })
      setOncoboxSubmitQuery(true)
      setOncoboxFile({
        name: '',
        data: null,
      })
    }

  }, []);

  return (
    <>
      <ModalContent>
        {isLoading && (
          <div className="roleOverlapSpinner">
            <Loader
              type="Oval"
              visible
              color={PrimaryColor.MAIN_COLOR}
              height={60}
              width={60}
            />
          </div>
        )}
        <div className="oncobox-import-template__wrapper">
          <Flex
            className={
              !oncoBoxFile.name && oncoBoxSubmitQuery
                ? 'drag-and-drop__error'
                :
                'drag-and-drop__outer_oncobox'
            }
          >
            <ImportTemplateDropzone
              onDrop={onDrop}
              accept=".xml"
              file={oncoBoxFile}
              submitQuery={oncoBoxSubmitQuery}
              isOncoBox={true}
              errors={{...oncoBoxErrors, incorrectNameLength: isFileNameLong}}
            />

          </Flex>
          <h3 className="only-care-file__text">
            {I18n.t('patients_view.onlyXMLFile')}
          </h3>
        </div>
      </ModalContent>
    </>
  );
};

export default OncoboxImportModalContent;
