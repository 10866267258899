import React from 'react';

import { Flex, Box } from '@ui-common-files/components';
import DataPointItem from './DataPointItem';

import useCustomTooltipService from './CustomTooltip.service';

import './CustomTooltip.styles.scss';

const CustomTooltip = ({ active, payload }) => {
  const { formattedDate, dataPointEntries } = useCustomTooltipService(
    payload?.[0]?.payload
  );

  if (!active || !payload[0]?.payload) return null;

  return (
    <Flex flexDirection="column" className="overview-custom-tooltip">
      <Flex alignItems="center" className="overview-custom-tooltip_header">
        <Box className="overview-custom-tooltip_header_date">
          {formattedDate}
        </Box>
      </Flex>
      <Flex flexDirection="column">
        {dataPointEntries.map((line, index) => (
          <DataPointItem
            key={index}
            dataPointName={line.name}
            dataPointUnit={line.unit}
            lineColor={payload[index]?.stroke}
            value={payload[index]?.value}
            isCircular={line.circular}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default CustomTooltip;
