import axios from 'axios';
import serviceConfig from '../../../../serviceConfig.json';

export async function getWearablesForCareplans(requestData) {
  return await axios.get(
    serviceConfig.brokerService.getWearablesDataForCareplan.uri,
    {
      params: requestData,
      timeout: 30000,
    }
  );
}

export async function getWearablesActivityStatus(requestData) {
  return await axios.get(
    serviceConfig.brokerService.getWearablesActivityStatus.uri,
    {
      params: requestData,
      timeout: 30000,
    }
  );
}

export async function getWearablesStatus(requestData) {
  return await axios.get(serviceConfig.brokerService.getWearablesStatus.uri, {
    params: requestData,
    timeout: 30000,
  });
}

export async function getWearablesData(requestData) {
  return await axios.get(serviceConfig.brokerService.getWearablesData.uri, {
    params: requestData,
    timeout: 30000,
  });
}
