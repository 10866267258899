import { Node } from 'reactflow';
import moment from 'moment';
import {
  careplanSequenceValueToSignMapper,
  getCareplanDuration,
  getRelativeDurationText,
  getTimeStringFromObj,
  timeUnitInSeconds,
} from '@ui-common-files/utils';
import { CompareStrings } from '@global/Strings';

export const checkDateInput = (value: string, unitValue: number) => {
  if (value === '') return unitValue > 1 ? 1 : 0;
  return parseInt(value, 10);
};

export const getDurationText = configuration => {
  if (Object.keys(configuration).length !== 0) {
    return getRelativeDurationText({
      startDateUnit: configuration.startDateUnit,
      startDateValue: configuration.startDateValue,
      startDateSequence: configuration.startDateSequence,
      referenceType: configuration.referenceType?.label,
      startTime: getTimeStringFromObj(configuration?.startTime),
      occurrenceCount:
        configuration.occurrenceCount.value || configuration.occurrenceCount,
      occurrenceCountCheck: configuration.occurrenceCountCheck,
      repeatType: configuration.repeatType,
      repeatInterval: configuration.repeatInterval,
      endDateValue: configuration.endDateValue,
      endDateUnit: configuration.endDateUnit,
      endDateSequence: configuration.endDateSequence,
      weeklyRecurrence: configuration.weeklyRecurrence,
      lifespan: configuration.lifespan,
      lifespanUnit: configuration.lifespanUnit,
    });
  }
};
export const durationsOverlap = (durA, durB) => {
  const startFromDurationAisInDurationB =
    durA.startDate >= durB.startDate && durA.startDate <= durB.endDate;
  const endFromDurationAIsInDurationB =
    durA.endDate >= durB.startDate && durA.endDate <= durB.endDate;
  const durationBIsInsiDedurationA =
    durB.startDate >= durA.startDate && durB.startDate <= durA.endDate;
  return (
    startFromDurationAisInDurationB ||
    endFromDurationAIsInDurationB ||
    durationBIsInsiDedurationA
  );
};

export const checkLifespanOverlapping = (
  nodes: Node[],
  configuration,
  careTaskId: number,
  currentConfigNodeId: string
) => {
  let overlappedWithNodeId = '';

  nodes
    .filter(
      node =>
        node.data.careTaskData.id === careTaskId &&
        node.data.isParent &&
        node.id !== currentConfigNodeId &&
        node.data.duration
    )
    .forEach(node => {
      const durA = getCareplanDuration(node.data.duration);
      const durB = getCareplanDuration(configuration);

      if (
        node.data.duration.lifespanUnit.value === 0 ||
        configuration.lifespanUnit.value === 0
      )
        return false;
      if (durationsOverlap(durA, durB)) {
        overlappedWithNodeId = node.id;
      }
    });
  return overlappedWithNodeId;
};

export const validateCareplanRecurrenceTag = (
  nodes: Node[],
  nodeData,
  currentConfigurationId: string,
  tag: string
) =>
  nodes.some(
    node =>
      node.data.careTaskData.id === nodeData.careTaskData.id &&
      node.data.isParent &&
      node.id !== currentConfigurationId &&
      node.data.duration?.recurrenceTag &&
      !CompareStrings(node.data.duration.recurrenceTag, tag)
  );

export const calculateDate = (
  referenceUnixTimestamp,
  sequence,
  unit,
  value
) => {
  const timestamp =
    referenceUnixTimestamp +
    careplanSequenceValueToSignMapper[sequence] *
      timeUnitInSeconds[unit] *
      value;
  return moment(timestamp).toISOString();
};
