import { DatePickerKeys } from '../../../utils/dateUtils/dateUtil.type';

export const checkIfCorrectDateInput = (
  input: string,
  type: DatePickerKeys
) => {
  if (/[^0-9]+/.test(input)) return false;
  switch (type) {
    case DatePickerKeys.Day:
      return input.length <= 2 && +input <= 31;
    case DatePickerKeys.Month:
      return input.length <= 2 && +input <= 12;
    case DatePickerKeys.Year:
      return input.length <= 4;
    default:
      return false;
  }
};
