import { FlashMessageTypes } from '@ui-common-files/types';

import { ApiClient, ShowFlashMessage } from '@global/Services';
import serviceConfig from '../../../../../serviceConfig.json';

export const handleReAssign = async (firebaseId, patientId, setIsDisabled) => {
  setIsDisabled(true);

  const response = await ApiClient.POST({
    url: serviceConfig.patientService.sendReAssignRequest.uri,
    payload: {
      firebaseId,
      patientId,
    },
  });

  setIsDisabled(false);

  if (response?.data) {
    ShowFlashMessage({
      type: FlashMessageTypes.Success,
      messageKey: 'patient.troubleshoot.solution_1.success_flash-message',
      isModalMessage: true
    });
  }
};

export const handleClearCache = async (firebaseId, patientId) => {
  const response = await ApiClient.POST({
    url: serviceConfig.patientService.sendClearCacheRequest.uri,
    payload: {
      firebaseId,
      patientId,
    },
  });

  if (response?.data) {
    ShowFlashMessage({
      type: FlashMessageTypes.Success,
      messageKey: 'patient.troubleshoot.solution_2.success_flash-message',
      isModalMessage: true
    });
  }
};

export const handleResetAccount = async (firebaseId, uuid) => {
  const response = await ApiClient.POST({
    url: serviceConfig.patientService.resetPatientAccount.uri,
    payload: {
      firebaseId,
      uuid,
    },
  });

  if (response?.data) {
    ShowFlashMessage({
      type: FlashMessageTypes.Success,
      messageKey: 'patient.troubleshoot.solution_3.success_flash-message',
      isModalMessage: true
    });
  }
};
