import React, { useEffect } from 'react';
import classNames from 'classnames';

import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import { FlashMessageTypes } from '../../types/components';

import { FlashMessageProps } from './FlashMessage.type';

import './FlashMessage.style.scss';

function FlashMessage({
  message,
  type,
  onClick,
  autoHide = true,
  hideLeftIcon = false,
  hideCloseButton = false,
  id = null,
  timeout = 3000,
  hideFlashMessage,
}: FlashMessageProps) {
  const flashMsgClasses = classNames({
    'flash-msg_content': true,
    'flash-msg_content--success': type === FlashMessageTypes.Success,
    'flash-msg_content--info': type === FlashMessageTypes.Info,
    'flash-msg_content--warning': type === FlashMessageTypes.Warning,
    'flash-msg_content--error': type === FlashMessageTypes.Error,
  });

  const closeFlashMessage = () => {
    if (onClick) {
      onClick();
    }
    if (id && hideFlashMessage) {
      hideFlashMessage(id);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timer = null;
    if (
      autoHide &&
      (type === FlashMessageTypes.Success || type === FlashMessageTypes.Info)
    ) {
      interval = setInterval(() => {
        closeFlashMessage();
      }, timeout);
    }
    return () => clearInterval(interval);
  }, []);

  const iconFlashMessageType =
    type === FlashMessageTypes.Warning ? 'WarningRhombus' : type;

  return (
    <Box className="flash-msg">
      <Box className={flashMsgClasses}>
        {!hideLeftIcon && (
          <Box className="flash-msg_content_icon">
            <Icon name={iconFlashMessageType} fill="#ffffff" size={20} />
          </Box>
        )}
        <Box className="flash-msg_content_text">{message}</Box>
        {!hideCloseButton && (
          <Box className="flash-msg_content_close">
            <button
              type="button"
              onClick={closeFlashMessage}
              aria-label="close"
            >
              <Icon name="close" fill="#ffffff" />
            </button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FlashMessage;
