import React, { useEffect } from 'react';
import { getApps, initializeApp } from 'firebase/app';
import I18n from 'i18next';

import { useUserPermission } from '@utils';
import config from '../../../config';

const FirebaseContext = React.createContext(null);

const Firebase = ({
  isLoggedIn,
  setIsLoggedIn,
  userPinAndOptinsInfo,
  setUserPinAndOptinsInfo,
  hidePatientDemographics,
  setHidePatientDemographics,
  children,
}) => {
  const hasUserReadPermission = useUserPermission(
    I18n.t('types.componentType.users'),
    I18n.t('actions.read'),
    isLoggedIn
  );
  useEffect(() => {
    const initialize = () => {
      try {
        if (getApps().length < 1) {
          initializeApp(config.firebase);
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    if (isLoggedIn) initialize();
  }, [isLoggedIn]);

  return (
    <FirebaseContext.Provider
      value={{
        hasUserReadPermission,
        setIsLoggedIn,
        userPinAndOptinsInfo,
        setUserPinAndOptinsInfo,
        hidePatientDemographics,
        setHidePatientDemographics,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export { Firebase, FirebaseContext };
