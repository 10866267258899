import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Button, Modal, Icon } from '@ui-common-files/components';
import {
  ButtonType,
  ButtonVariant,
  ComponentTypes,
  Greys,
} from '@ui-common-files/utils';
import { HideFlashMessage } from '@global/Services';
import { useMedicationPlanCardService } from './MedicationPlanCard.service';
import { MedicationCardTitle } from '../MedicationCardTitle/MedicationCardTitle';
import ShowAssetModalContent from '../components/ShowAssetModalContent/ShowAssetModalContent';
import { MedicationPlanCardTypes } from '../MedicationPlan.types';
import '../MedicationPlanCard.scss';

const MedicationPlanCard: React.FC<MedicationPlanCardTypes> = ({ data }) => {
  const { t } = useTranslation();
  const {
    modalTitle,
    assetDataToShow,
    openModal,
    closeModal,
    getModalSize,
    handleViewMedicationPlanPDF,
  } = useMedicationPlanCardService(data);

  return (
    <Box className="medication-card">
      <Flex justifyContent="space-between">
        <MedicationCardTitle
          iconName={ComponentTypes.MEDICATIONPLAN}
          data={data}
        />

        <Button
          label={t('common_buttons.viewMedicationPlanPDF')}
          type={ButtonType.BUTTON}
          variant={ButtonVariant.OUTLINED}
          onClick={handleViewMedicationPlanPDF}
        />
        {assetDataToShow && (
          <Modal
            title={modalTitle}
            contentComponent={
              <ShowAssetModalContent assetDataToShow={assetDataToShow} />
            }
            openModal={openModal}
            onClose={closeModal}
            modalHeightSize={getModalSize()}
            modalWidthSize={getModalSize()}
            hideFlashMessage={HideFlashMessage}
          />
        )}
      </Flex>
      <Flex className="icon-text-container">
        <Icon name="info" size={24} fill={Greys.LIGHT_GREY_75} />
        <Box>{t('medicationPdfReport.infoText')}</Box>
      </Flex>
    </Box>
  );
};

export default MedicationPlanCard;
