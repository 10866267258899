import React from 'react';
import Loader from 'react-loader-spinner';

import { Flex } from '../index';

import { PrimaryColor } from '../../utils';

const ContentLoader: React.FC = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      otherStyles={{
        width: '100%',
        height: '100%',
        position: 'inherit',
      }}
    >
      <Loader
        type="Oval"
        color={PrimaryColor.MAIN_COLOR}
        height={60}
        width={60}
      />
    </Flex>
  );
};

export default ContentLoader;
