import wearablesActions from '../src/Utils/ReduxUtilities/Actions/wearables.json';

export const getWearablesForCareplanRequest = dataRequest => ({
  type: wearablesActions.GET_WEARABLES_FOR_CAREPLANS_REQUEST,
  payload: dataRequest,
});

export const getWearablesActivityStatusRequest = dataRequest => ({
  type: wearablesActions.GET_WEARABLES_ACTIVITY_STATUS_REQUEST,
  payload: dataRequest,
});

export const resetWearableData = () => ({
  type: wearablesActions.RESET_WEARABLES_DATA,
});

export const resetWearableStatus = () => ({
  type: wearablesActions.RESET_WEARABLE_STATUS,
});
