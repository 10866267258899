import React from 'react';
import Box from '../../caro-ui-commonfiles/components/Box/Box';
import { isMainQuestion } from '../Views/Careplans/CareplanHelperUtility';
import {
  renderAdditionalComponents,
  renderComponents,
} from '../Views/Careplans/CareplanAdditionalComponentUtils';

const ShowCarePathwayTemplateViewComponents = ({
  careplanData,
  careplans,
  careplanTemplateWithLogicJumps,
}) => {
  return (
    <Box padding="20px 0 0">
      {renderComponents(
        careplans.filter(component =>
          isMainQuestion(component[0].healthCategory)
        ),
        careplanTemplateWithLogicJumps,
        careplanData.template.isEvaluating,
        true
      )}
      {careplanData.template.isEvaluating &&
        renderAdditionalComponents(
          careplanData.template.scoringScheme,
          careplans,
          careplanTemplateWithLogicJumps,
          careplanData.template.isEvaluating
        )}
    </Box>
  );
};

export default ShowCarePathwayTemplateViewComponents;
