/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useReducer, useEffect } from 'react';
import I18n from 'i18next';
import PropTypes from 'prop-types';

import { HideFlashMessage } from '@global/Services';

import Modal from '../../../../caro-ui-commonfiles/components/Modals/ModalLayout';

import {
  CreateAppointmentHeader,
  CreateAppointmentContent,
  CreateAppointmentFooter,
} from './CreateAppointmentComponents';

const CreateAppointmentContext = createContext(null);

const CreateAppointmentModal = ({ open, onClose, setFlash, didUpdate }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [existingPatient, setExistingPatient] = useState({});
  const [flashMessage, setFlashMessage] = useState({
    display: false,
    content: '',
    type: '',
  });
  const [shouldValidateForm, setShouldValidateForm] = useState(false);

  const defaultFormSubmissionObj = {
    submit: false,
    submissionType: false,
  };

  const [shouldSubmitForm, dispatchFormSubmittor] = useReducer(
    (_state, action) => action,
    defaultFormSubmissionObj
  );

  useEffect(() => {
    setFlash(flashMessage);
  }, [flashMessage]);

  const cleanUp = () => {
    setCurrentStep(1);
    setExistingPatient({});
  };

  return (
    <CreateAppointmentContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        existingPatient,
        setExistingPatient,
        shouldValidateForm,
        setShouldValidateForm,
        shouldSubmitForm,
        dispatchFormSubmittor,
        flashMessage,
        setFlashMessage,
        didUpdate,
      }}
    >
      <Modal
        title={I18n.t('newAppointment_view.label_addAppointment')}
        headerComponent={<CreateAppointmentHeader />}
        contentComponent={<CreateAppointmentContent onClose={onClose} />}
        footerComponent={<CreateAppointmentFooter />}
        openModal={open}
        onClose={() => {
          cleanUp();
          onClose();
        }}
        hideFlashMessage={HideFlashMessage}
      />
    </CreateAppointmentContext.Provider>
  );
};

CreateAppointmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setFlash: PropTypes.func.isRequired,
};

export { CreateAppointmentModal, CreateAppointmentContext };
