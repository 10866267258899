/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Assets from './Datatables/Assets';
import Tabs from '../../caro-ui-commonfiles/components/Tabs/Tabs';
import FlashMessage from '../../caro-ui-commonfiles/components/FlashMessage/FlashMessage';
import tabPosition from '../Utils/Administration/tabPosition';
import ASSET_TYPES from '../Utils/Asset/assetTypes';
import { useUserPermission } from '../Utils/hooks/getUserPermission';
import BreadcrumbPortal from './AppLayout/BreadcrumbPortal/BreadcrumbPortal';
import { NavigationJSON } from '@ui-common-files/utils';
import { Breadcrumb } from '@ui-common-files/components';

const Files = ({ history }) => {
  const { breadcrumbFilesObject } = require('../Utils/breadcrumbObjects');
  const [reloadTable, setReloadTable] = useState(false);
  const services = useSelector(state => state.services);
  const [flashMessage, setFlashMessage] = useState({});
  const [fetchedData, setFetchedData] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [displayShareBtn, setDisplayShareBtn] = useState(false);
  const { t } = useTranslation();
  const hasSiteReadPermission = useUserPermission(
    t('types.componentType.sites'),
    t('actions.read'),
    true
  );

  const hasAssetSharePermission = useUserPermission(
    t('types.componentType.assets'),
    t('actions.share'),
    true
  );

  const shouldUserShareAssets = !!(
    hasSiteReadPermission && hasAssetSharePermission
  );

  useEffect(() => {
    shouldUserShareAssets
      ? setDisplayShareBtn(true)
      : setDisplayShareBtn(false);
  }, [shouldUserShareAssets]);

  const changeTabPosition = () => {
    return tabPosition.FIRST;
  };

  const getActiveTab = (_state, data) => data;
  const [activeTab, setActiveTab] = useReducer(
    getActiveTab,
    changeTabPosition()
  );

  const hideFlashMessage = () => {
    setFlashMessage({});
  };

  const onClickTabItem = tab => {
    setActiveTab(tab);
  };

  const renderTabs = () => (
    <div className="container-padding">
      <div style={{ position: 'relative' }}>
        <Tabs activeTab={activeTab} onClickTabItem={onClickTabItem}>
          {services.assetRead && (
            <div label={t('asset_view.documents')} tabId={tabPosition.FIRST}>
              <Assets
                hasPagesize
                reloadTable={reloadTable}
                setReloadTable={setActiveTabAndReload}
                setFlashMessage={setFlashMessage}
                activeTab={ASSET_TYPES.DOCUMENT}
                fetchedData={fetchedData}
                setFetchedData={setFetchedData}
                disableBtn={disableBtn}
                setDisableBtn={setDisableBtn}
                displayShareBtn={displayShareBtn}
                isPrivacyPolicy={false}
              />
            </div>
          )}
          {services.assetRead && (
            <div label={t('asset_view.images')} tabId={tabPosition.SECOND}>
              <Assets
                hasPagesize
                reloadTable={reloadTable}
                setReloadTable={setActiveTabAndReload}
                setFlashMessage={setFlashMessage}
                activeTab={ASSET_TYPES.IMAGE}
                fetchedData={fetchedData}
                setFetchedData={setFetchedData}
                disableBtn={disableBtn}
                setDisableBtn={setDisableBtn}
                displayShareBtn={displayShareBtn}
                isPrivacyPolicy={false}
              />
            </div>
          )}
          {services.assetRead && (
            <div label={t('asset_view.videos')} tabId={tabPosition.THIRD}>
              <Assets
                hasPagesize
                reloadTable={reloadTable}
                setReloadTable={setActiveTabAndReload}
                setFlashMessage={setFlashMessage}
                activeTab={ASSET_TYPES.VIDEO}
                fetchedData={fetchedData}
                setFetchedData={setFetchedData}
                disableBtn={disableBtn}
                setDisableBtn={setDisableBtn}
                displayShareBtn={displayShareBtn}
                isPrivacyPolicy={false}
              />
            </div>
          )}
          {services.assetRead && (
            <div label={t('asset_view.audios')} tabId={tabPosition.FOURTH}>
              <Assets
                hasPagesize
                reloadTable={reloadTable}
                setReloadTable={setActiveTabAndReload}
                setFlashMessage={setFlashMessage}
                activeTab={ASSET_TYPES.AUDIO}
                fetchedData={fetchedData}
                setFetchedData={setFetchedData}
                disableBtn={disableBtn}
                setDisableBtn={setDisableBtn}
                displayShareBtn={displayShareBtn}
                isPrivacyPolicy={false}
              />
            </div>
          )}
          {services.assetRead && (
            <div
              label={t('admin_view.label-privacy-policy')}
              tabId={tabPosition.FIFTH}
            >
              <Assets
                hasPagesize
                reloadTable={reloadTable}
                setReloadTable={setActiveTabAndReload}
                setFlashMessage={setFlashMessage}
                activeTab={ASSET_TYPES.PRIVACY_POLICY}
                fetchedData={fetchedData}
                setFetchedData={setFetchedData}
                disableBtn={disableBtn}
                setDisableBtn={setDisableBtn}
                displayShareBtn={displayShareBtn}
                isPrivacyPolicy
              />
            </div>
          )}
        </Tabs>
      </div>
    </div>
  );

  const setActiveTabAndReload = tabId => {
    setReloadTable(!reloadTable);
    setActiveTab(tabId);
  };

  return (
    <>
      <BreadcrumbPortal>
        <Breadcrumb>
          <Link to={`/${NavigationJSON.FILES}`}>{t('nav.assets')}</Link>
          <span aria-current="page">
            {t(breadcrumbFilesObject[activeTab - 1].label)}
          </span>
        </Breadcrumb>
      </BreadcrumbPortal>
      {flashMessage.content && (
        <FlashMessage
          message={t(flashMessage.content)}
          type={flashMessage.type}
          onClick={hideFlashMessage}
        />
      )}
      {renderTabs()}
    </>
  );
};

export default Files;
