import React from 'react';
import Icon from '../../../../../../caro-ui-commonfiles/components/Icon/Icon';
import { PatientFilterSelectLabelProps } from './PatientFilterSelectLabel.type';
import { usePatientFilterSelectLabelService } from './PatientFilterSelectLabel.service';

function PatientFilterSelectLabel({
  filter,
  position,
  type,
}: PatientFilterSelectLabelProps) {
  const { fill, getLabel, setBrightFill, setDarkFill, removeLabel } =
    usePatientFilterSelectLabelService();
  return (
    <div className="filters__dropdown-inner-criteria">
      <div>{getLabel(filter)}</div>
      <div
        onMouseOver={setDarkFill}
        onMouseLeave={setBrightFill}
        className="filters__dropdown-inner-criteria-remove"
        onClick={removeLabel(position, type)}
      >
        <Icon name="close" fill={fill} size={17} />
      </div>
    </div>
  );
}

export default PatientFilterSelectLabel;
