/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Motion, spring } from 'react-motion';

const Animator = ({ isOn, delay, children }) => {
  const [shouldRender, setShouldrender] = useState(false);

  useEffect(() => {
    if (isOn) {
      setTimeout(() => setShouldrender(true), delay);
    }
  }, [isOn]);

  return (
    shouldRender && (
      <Motion
        defaultStyle={{ opacity: 0 }}
        style={{
          opacity: spring(1, { stiffness: 80, damping: 70 }),
        }}
      >
        {style => <div style={style}>{children}</div>}
      </Motion>
    )
  );
};

export default Animator;
