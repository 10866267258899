import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TextInput from '../../../../../../../caro-ui-commonfiles/components/TextInput/TextInput';

import { CaretaskComponent } from '../../../../../../types/Caretask';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { AnsweringComponentsProps } from '../AnsweringTypes';

import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';
import { isValidNumber } from '../../../../../../../caro-ui-commonfiles/utils/validationUtils';
import inputField from '../../../../../../../caro-ui-commonfiles/utils/inputField';
import {
  jumpCalculationForNumericalComponent,
  valueIsGreaterThanReference,
} from '../../../../../Careplans/CareplanHelperUtility';

export default function AnsweringNumericValue({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  const { t } = useTranslation();
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);
  const { minimumValue, maximumValue, decimal } = currentComponent;

  const defaultValue: string = currentAnswer?.answer || '';

  const [errorMessage, setErrorMessage] = useState<string>(null);

  const updateValue = (_state, inputValue) => {
    if (inputValue === null || inputValue.length === 0) {
      setErrorMessage(null);
      return inputValue;
    }
    if (!decimal && inputValue.match(/^[0-9]+\./)) {
      setErrorMessage(t('common_labels.floating_point_error'));
      return inputValue;
    }
    const parts = inputValue.split('.');
    if (parts.length > 1 && parts[1].length > 3) {
      inputValue = parts[0] + '.' + parts[1].slice(0, 3);
    }

    if (!isValidNumber(inputValue)) {
      setErrorMessage(t('common_labels.valid_number_error'));
      return inputValue;
    }

    if (!valueIsGreaterThanReference(inputValue, minimumValue)) {
      setErrorMessage(
        t('common_labels.min_constraint_error', { minimum: minimumValue })
      );
      return inputValue;
    }

    if (!valueIsGreaterThanReference(maximumValue, inputValue)) {
      setErrorMessage(
        t('common_labels.max_constraint_error', { maximum: maximumValue })
      );
      return inputValue;
    }

    if (errorMessage !== null) setErrorMessage(null);
    return inputValue;
  };
  const [value, setValue] = useReducer(updateValue, null);
 
  useEffect(() => {
    let jumpTo: number = jumpCalculationForNumericalComponent(
      currentComponent,
      value
    );
    handleOnChange({
      answer: value,
      isSkipped: !value,
      jumpTo,
      hasError: errorMessage !== null,
    });
  }, [value, handleOnChange]);

  useEffect(() => {
    setValue(defaultValue);
  }, [currentComponent]);

  return (
    <TextInput
      name={`numeric-value-${currentAnswer.id}`}
      placeholder={t('common_labels.numeric_value_placeholder', {
        minimum: minimumValue,
        maximum: maximumValue,
        ifAllowed: decimal ? '' : t('common_labels.not'),
      })}
      value={value || ''}
      handleOnChange={e => setValue(e.target.value)}
      hasError={errorMessage !== null}
      validationMessage={errorMessage}
      variant={inputField.variant.DEFAULT}
    />
  );
}
