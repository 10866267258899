import { ApiClient } from '../../../../Global/Services';
import {
  CurrentPasswordCheckRequest,
  CurrentPasswordCheckResponse,
} from './UpdatePasswordComponent.type';

import serviceConfig from '../../../../../serviceConfig.json';

export const checkCurrentPassword = (currentPassword: string) =>
  ApiClient.POST<CurrentPasswordCheckResponse, CurrentPasswordCheckRequest>({
    url: serviceConfig.brokerService.checkUserCurrentPassword.uri,
    payload: {
      currentPassword,
    },
  });
