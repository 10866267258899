import React from 'react';
// Import the useDropzone hooks from react-dropzone
import { useDropzone } from 'react-dropzone';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import I18n from 'i18next';

const Dropzone = ({ onDrop, accept, logo, error }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    logo,
    error,
    multiple: false,
  });
  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */
  return (
    <div {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <Flex flexDirection="column" alignItems="center">
        {isDragActive ? (
          <p className="dropzone-content" />
        ) : (
          <>
            <Box height="150px">
              {error ? (
                <span className="image-site__val-msg">{error}</span>
              ) : (
                <img
                  src={logo.src}
                  height="150px"
                  style={{ maxWidth: '400px' }}
                />
              )}
            </Box>
            <Box margin="20px 0 0 0">
              <Button
                label={I18n.t('common_labels.label_Upload')}
                onClick={() => {}}
              />
            </Box>
          </>
        )}
      </Flex>
    </div>
  );
};

export default Dropzone;
