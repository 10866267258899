import { useEffect } from 'react';

import { FlashMessageTypes } from '@ui-common-files/types';
import { PatientsQueryEffectProps } from './hooks.types';

const usePatientsQueryEffect = ({
  reduxDispatcher,
  actionState,
  doFetch,
  setLoading,
  setPatients,
  setDisplayFlashMsg,
  t,
  checkPatientsReadPermission,
  selectedFilter,
  isNewPatient,
  getPatientsByQueryRequest,
  getPatientsByCustomFilter,
}: PatientsQueryEffectProps) => {
  useEffect(() => {
    const getPatientsByQuery = async () => {
      setLoading(true);
      if (checkPatientsReadPermission) {
        if (
          actionState.sqlQuery &&
          actionState.sqlQuery.sql &&
          actionState.customFilter === null
        ) {
          reduxDispatcher(
            getPatientsByQueryRequest({
              sql: actionState.sqlQuery.sql,
              params: JSON.stringify(actionState.sqlQuery.params),
              getRule: JSON.stringify(selectedFilter.savedFilter.rule),
              filterKey: {
                id: selectedFilter.savedFilter.id,
                name: selectedFilter.savedFilter.name,
              },
              query: JSON.stringify({
                start: actionState.start,
                limit: actionState.limit,
                dir: actionState.dir,
                column: actionState.column,
                search: actionState.search,
              }),
              isNewPatient,
            })
          );
        }
        if (actionState.customFilter && actionState.sqlQuery === null) {
          reduxDispatcher(
            getPatientsByCustomFilter({
              customFilter: actionState.customFilter || {},
              query: JSON.stringify({
                start: actionState.start,
                limit: actionState.limit,
                dir: actionState.dir,
                column: actionState.column,
                search: actionState.search,
              }),
              isNewPatient,
            })
          );
        }
      } else {
        setLoading(false);
        setPatients(null);
        setDisplayFlashMsg({
          display: true,
          content: t('common_labels.msg_noPermission'),
          type: FlashMessageTypes.Warning,
        });
      }
    };

    getPatientsByQuery();
  }, [actionState, doFetch]);
};

export default usePatientsQueryEffect;
