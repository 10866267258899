import React, { useEffect } from "react";
import Loader from "react-loader-spinner";

import { Flex, Box } from "@ui-common-files/components";
import { PrimaryColor } from "@ui-common-files/utils";

import PathWayReviewStepComponents from "./ComponentsInfoStep/PathWayReviewStepComponents";

import { PathWayReviewModalContentProps } from "./PathWayReviewModal.types";
import PathWayReviewStepPreview from "./ComponentsPreviewStep/PathWayReviewStepPreview";

const PathWayReviewModalContent: React.FC<PathWayReviewModalContentProps> = ({
  isLoading,
  currentStep,
  selectedCareTasks,
  usWarningCheckboxChecked,
  setUsWarningCheckboxChecked,
  consentChecked
}) => {
  if (selectedCareTasks.length === 0 && isLoading) {
    return (
      <Flex
        className="modal_content"
        justifyContent="center"
        alignItems="center"
        otherStyles={{ height: "60vh" }}
      >
        <Loader
          type="Oval"
          visible
          color={PrimaryColor.MAIN_COLOR}
          height={60}
          width={60}
        />
      </Flex>
    );
  }

  if (currentStep === 1 && !isLoading) {
    return (
      <Box className="modal__content">
        <PathWayReviewStepComponents
          selectedCareTasks={selectedCareTasks}
          usWarningCheckboxChecked={usWarningCheckboxChecked}
          setUsWarningCheckboxChecked={setUsWarningCheckboxChecked}
          isLoading={isLoading}
        />
      </Box>
    );
  }
  if (currentStep === 2 && !isLoading) {
    return (
      <Box className="modal__content">
        <PathWayReviewStepPreview isLoading={isLoading} />
      </Box>
    );
  }
};

export default PathWayReviewModalContent;
