/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import I18n from 'i18next';
import moment from 'moment';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import { CarePathwayTitleForAppointmentUpdate } from './CardComponents';
import { Greys } from '../../../caro-ui-commonfiles/utils/colors';
import { MEDIA_QUERIES } from '@ui-common-files/utils/layout';
import lifespanUnit from '../../Utils/lifespanUnit.json';

import '../../css/analytics.css';
import IconButton from '../../../caro-ui-commonfiles/components/IconButton/IconButton';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import CAREPATHWAY_STATUS from '../../Utils/carePathwayStatus';

import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

const CarePathwayCardForAppointment = ({ data, appointmentData }) => {
  const carePathwayId = `carepathway_${data.id}`;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showCareplansIcon, setShowCareplansIcon] = useState('arrowUp');

  const { setHasOneCareplanExpired, hasOneCareplanExpired } = useContext(
    PatientOverviewContext
  );

  const ToggleAttachedCareplans = () => {
    return showCareplansIcon == 'arrowUp'
      ? setShowCareplansIcon('arrowDown')
      : setShowCareplansIcon('arrowUp');
  };

  const ShowCareplanRequest = () => {
    ToggleAttachedCareplans();
  };

  const isCareplanStartDateInPast = startDate => {
    return moment(startDate).isBefore(moment().format('YYYY-MM-DD'));
  };

  const careplanSubComponent = (careplan, index) => {
    const startDate = moment(
      moment(appointmentData.startDate).add(
        careplan.duration,
        lifespanUnit.DURATION_UNITS_MOMENT_REFERENCE[careplan.durationUnit]
      )
    )
      .set({ hour: 0 })
      .locale(I18n.language)
      .format('DD MMM YYYY');

    if (isCareplanStartDateInPast(startDate)) {
      const temp = hasOneCareplanExpired;
      temp[careplan.id] = true;
      setHasOneCareplanExpired(temp);
    } else {
      const temp = hasOneCareplanExpired;
      temp[careplan.id] = false;
      setHasOneCareplanExpired(temp);
    }

    const IsCareplanStartingDateIsInPast = isCareplanStartDateInPast(startDate)
      ? 'rgba(233, 100, 100, 0.25)'
      : '';
    const evenStyles = {
      width: '100%',
      border: '0.2px solid',
      borderColor: Greys.LIGHT_GREY_20,
      height: '60px',
      alignItems: 'center',
      borderTop: 'none',
      backgroundColor: IsCareplanStartingDateIsInPast,
    };
    const oddStyles = {
      width: '100%',
      border: '0.2px solid',
      borderColor: Greys.LIGHT_GREY_20,
      height: '60px',
      alignItems: 'center',
      backgroundColor: IsCareplanStartingDateIsInPast,
      borderTop: index === 0 ? `0.2px solid ${Greys.LIGHT_GREY_20}` : 'none',
    };
    return (
      <Flex otherStyles={index % 2 == 0 && index >= 1 ? evenStyles : oddStyles}>
        <div
          className="rt-td"
          style={{
            flex: '100 0 auto',
            width: 100,
            maxWidth: 120,
            display: 'flex',
            justifyContent: 'center',
          }}
          role="gridcell"
        >
          <Icon
            name={careplan.isAssessment ? 'assessment' : 'careplan'}
            size={35}
            fill={Greys.DARK_GREY}
          />
        </div>
        <div className="rt-td expander-data-table__appointment" role="gridcell">
          {`${careplan.name} `}
        </div>
        <div
          className="rt-td"
          style={{ flex: '100 0 auto', width: 100, maxWidth: 200 }}
          role="gridcell"
        >
          {startDate}
        </div>
      </Flex>
    );
  };

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const getPathwayStatus = (statusId, expirationDate) => {
    const isExpirationDateBeforeToday = moment(expirationDate).isBefore(
      moment()
    );

    const chosenStatusId = isExpirationDateBeforeToday ? 3 : statusId;

    switch (chosenStatusId) {
      case CAREPATHWAY_STATUS.ACTIVE:
        return 'active';
      case CAREPATHWAY_STATUS.PLANNED:
        return 'planned';
      default:
        return 'inactive';
    }
  };

  return (
    <div className="assignment-card__carepathway natural" id={carePathwayId}>
      <Flex alignItems="center" otherStyles={{ padding: '32px 20px' }}>
        <Box width="100%">
          <CarePathwayTitleForAppointmentUpdate
            data={data}
            appointmentData={appointmentData}
            onExpand={() => ShowCareplanRequest()}
          />
        </Box>
        <Flex
          flexDirection={
            windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'
          }
          justifyContent="space-between"
          otherStyles={{ marginLeft: 20 }}
        >
          <div
            className={
              windowWidth < MEDIA_QUERIES.SCREEN_MD
                ? 'card__content-container card__container--row'
                : 'card__content-container card__container--column'
            }
          >
            <Flex alignItems="center">
              <IconButton
                name={showCareplansIcon}
                size={24}
                fill="#454B48"
                onClick={() => {
                  ShowCareplanRequest();
                }}
              />
            </Flex>
          </div>
        </Flex>
      </Flex>

      {showCareplansIcon === 'arrowUp' &&
        data &&
        data.careplans
          .reduce((recurrenceList, currentcareplan) => {
            recurrenceList.push(
              ...currentcareplan.careplanRecurrences.map(
                careplanRecurrence => careplanRecurrence
              )
            );
            return recurrenceList;
          }, [])
          .map((careplan, index) => careplanSubComponent(careplan, index))}
    </div>
  );
};

CarePathwayCardForAppointment.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default CarePathwayCardForAppointment;
