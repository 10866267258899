const NLP_GERMAN = {
  dayNames: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  monthNames: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  tokens: {
    SKIP: /^[ \r\n\t]+|^\.$/,
    number: /^[1-9][0-9]*/,
    numberAsText: /^(ein|zwei|drei)/i,
    every: 'jede/n/s',
    minute: 'Minute',
    minutes: 'Minutes',
    day: 'Tag',
    days: 'Tage',
    weekday: 'Wochentag',
    weekdays: 'Wochentage',
    week: 'Woche',
    weeks: 'Wochen',
    month: 'Monat',
    months: 'Monate',
    on: 'am',
    the: 'der',
    first: 'erste',
    second: 'zweite',
    third: 'dritte',
    nth: '.',
    last: 'letzte',
    for: 'für',
    time: 'Mal',
    times: 'Mal',
    until: 'bis',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
    comma: 'Komma',
    at: 'um',
    and: 'und',
  },
};

const NLP_ITALIAN = {
  dayNames: [
    'lunedì',
    'martedì',
    'mercoledì',
    'giovedì',
    'venerdì',
    'sabato',
    'domenica',
  ],
  monthNames: [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre',
  ],
  tokens: {
    SKIP: /^[ \r\n\t]+|^\.$/,
    number: /^[1-9][0-9]*/,
    numberAsText: /^(uno|due|tre)/i,
    every: 'ogni',
    minute: 'minuti',
    minutes: 'minuti',
    day: 'giorno',
    days: 'giorni',
    weekday: 'giorno della settimana',
    weekdays: 'giorni della settimana',
    week: 'settimana',
    weeks: 'settimane',
    month: 'mese',
    months: 'mesi',
    on: 'il',
    the: 'la',
    first: 'prima',
    second: 'seconda',
    third: 'terza',
    nth: '.',
    last: 'ultimo',
    for: 'per',
    time: 'volta',
    times: 'volte',
    until: 'fino a',
    monday: 'lunedi',
    tuesday: 'martedì',
    wednesday: 'mercoledì',
    thursday: 'giovedì',
    friday: 'venerdì',
    saturday: 'sabato',
    sunday: 'domenica',
    january: 'gennaio',
    february: 'febbraio',
    march: 'marzo',
    april: 'aprile',
    may: 'maggio',
    june: 'giugno',
    july: 'luglio',
    august: 'agosto',
    september: 'settembre',
    october: 'ottobre',
    november: 'novembre',
    december: 'dicembre',
    comma: 'virgola',
    at: 'alle',
    and: 'e',
  },
};

const NLP_THAI = {
  dayNames: [
    'จันทร์',
    'อังคาร',
    'พุธ',
    'พฤหัสบดี',
    'ศุกร์',
    'เสาร์',
    'อาทิตย์',
  ],
  monthNames: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ],
  tokens: {
    SKIP: /^[ \r\n\t]+|^\.$/,
    number: /^[1-9][0-9]*/,
    numberAsText: /^(xxxx|xxxx|xxxx)/i,
    every: 'ทุก',
    minute: 'นาที',
    minutes: 'นาที',
    day: 'วัน',
    days: 'xxxx',
    weekday: 'วันธรรมดา',
    weekdays: 'xxxx',
    week: 'สัปดาห์',
    weeks: 'xxxx',
    month: 'เดือน',
    months: 'xxxx',
    on: 'เมื่อ',
    the: 'xxxx',
    first: 'xxxx',
    second: 'xxxx',
    third: 'xxxx',
    nth: 'xxxx',
    last: 'ที่แล้ว',
    for: 'นาน',
    time: 'xxxx',
    times: 'ครั้ง',
    until: 'จนถึง',
    monday: 'จันทร์',
    tuesday: 'อังคาร',
    wednesday: 'พุธ',
    thursday: 'พฤหัสบดี',
    friday: 'ศุกร์',
    saturday: 'เสาร์',
    sunday: 'อาทิตย์',
    january: 'มกราคม',
    february: 'กุมภาพันธ์',
    march: 'มีนาคม',
    april: 'เมษายน',
    may: 'พฤษภาคม',
    june: 'มิถุนายน',
    july: 'กรกฎาคม',
    august: 'สิงหาคม',
    september: 'กันยายน',
    october: 'ตุลาคม',
    november: 'พฤศจิกายน',
    december: 'ธันวาคม',
    comma: 'xxxx',
    at: 'xxxx',
    and: 'xxxx',
  },
};

const NLP_PORTUGUESE = {
  dayNames: [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Jun',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  tokens: {
    SKIP: /^[ \r\n\t]+|^\.$/,
    number: /^[1-9][0-9]*/,
    numberAsText: /^(xxxx|xxxx|xxxx)/i,
    every: 'Cada',
    minute: 'Minuto',
    minutes: 'Minutos',
    day: 'Dia',
    days: 'Dias',
    weekday: 'Dia da semana',
    weekdays: 'Dias da semana',
    week: 'Semana',
    weeks: 'Semanas',
    month: 'Mês',
    months: 'Mêses',
    on: 'Em',
    the: 'xxxx',
    first: 'xxxx',
    second: 'xxxx',
    third: 'xxxx',
    nth: 'xxxx',
    last: 'Último',
    for: 'por',
    time: 'Vez',
    times: 'Vezes',
    until: 'Até',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado',
    sunday: 'Domingo',
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Jun',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro',
    comma: 'xxxx',
    at: 'xxxx',
    and: 'xxxx',
  },
};

const NLP_ARABIC = {
  dayNames: [
    'الأحد',
    'الإثنين',
    'الثلاثاء',
    'الأربعاء',
    'الخميس',
    'الجمعة',
    'السبت',
  ],
  monthNames: [
    'يناير',
    'فبراير',
    'مارس',
    'إبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ],
  tokens: {
    SKIP: /^[ \r\n\t]+|^\.$/,
    number: /^[1-9][0-9]*/,
    numberAsText: /^(xxxx|xxxx|xxxx)/i,
    every: 'في كل',
    minute: 'دقيقة (دقائق)',
    minutes: 'الدقائق',
    day: 'يوم',
    days: 'أيام',
    weekday: 'يوم من أيام الأسبوع',
    weekdays: 'أيام الأسبوع',
    week: 'أسبوع',
    weeks: 'أسابيع',
    month: 'شهر',
    months: 'أشهر',
    on: 'في',
    the: 'xxxx',
    first: 'xxxx',
    second: 'xxxx',
    third: 'xxxx',
    nth: 'xxxx',
    last: 'آخر',
    for: 'لـ',
    time: 'وقت',
    times: 'أوقات',
    until: 'حتى',
    monday: 'الإثنين',
    tuesday: 'الثلاثاء',
    wednesday: 'الأربعاء',
    thursday: 'الخميس',
    friday: 'الجمعة',
    saturday: 'السبت',
    sunday: 'الأحد',
    january: 'يناير',
    february: 'فبراير',
    march: 'مارس',
    april: 'إبريل',
    may: 'مايو',
    june: 'يونيو',
    july: 'يوليو',
    august: 'أغسطس',
    september: 'سبتمبر',
    october: 'أكتوبر',
    november: 'نوفمبر',
    december: 'ديسمبر',
    comma: 'xxxx',
    at: 'xxxx',
    and: 'و',
  },
};

const NLP_FRENCH = {
  dayNames: [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ],
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ],
  tokens: {
    SKIP: /^[ \r\n\t]+|^\.$/,
    number: /^[1-9][0-9]*/,
    numberAsText: /^(xxxx|xxxx|xxxx)/i,
    every: 'Toutes les',
    minute: 'Minute',
    minutes: 'Minutes',
    day: 'Jour',
    days: 'Jours',
    weekday: 'Jour de la semaine',
    weekdays: 'Jours de la semaine',
    week: 'Semaine',
    weeks: 'Semaines',
    month: 'Mois',
    months: 'Mois',
    on: 'Le',
    the: 'xxxx',
    first: 'xxxx',
    second: 'xxxx',
    third: 'xxxx',
    nth: 'xxxx',
    last: 'Dernière',
    for: 'pendant',
    time: 'heure',
    times: 'heures',
    until: 'Jusqu’à',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
    comma: 'xxxx',
    at: 'à',
    and: 'Et',
  },
};

const getNlpToken = function (id) {
  return NLP_GERMAN.tokens[id] || id;
};

const getNlpTokenItalian = function (id) {
  return NLP_ITALIAN.tokens[id] || id;
};

const getNlpTokenThai = id => {
  return NLP_THAI.tokens[id] || id;
};

const getNlpTokenPortuguese = id => {
  return NLP_PORTUGUESE.tokens[id] || id;
};

const getNlpTokenArabic = id => {
  return NLP_ARABIC.tokens[id] || id;
};

const getNlpTokenFrench = id => {
  return NLP_FRENCH.tokens[id] || id;
};

export {
  getNlpToken,
  getNlpTokenItalian,
  getNlpTokenThai,
  getNlpTokenPortuguese,
  getNlpTokenArabic,
  getNlpTokenFrench,
  NLP_GERMAN,
  NLP_ITALIAN,
  NLP_THAI,
  NLP_PORTUGUESE,
  NLP_ARABIC,
  NLP_FRENCH,
};
