import React, { Children } from 'react';
import PropTypes from 'prop-types';
import '../../styles/main.scss';

const BreadcrumbItem = ({ children, ...props }) => {
  const childrendWithClasses = Children.map(children, (child, index) => {
    return (
      <child.type
        {...child.props}
        key={index}
        className="breadcrumb-list__item__link"
      />
    );
  });

  return (
    <li className="breadcrumb-list__item" {...props}>
      {childrendWithClasses}
    </li>
  );
};

BreadcrumbItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BreadcrumbItem;
