import React, { useReducer, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import I18n from 'i18next';

import IconCheckbox from '../../../../caro-ui-commonfiles/components/IconCheckbox/IconCheckbox';
import RadioButton from '../../../../caro-ui-commonfiles/components/RadioButton/RadioButton';
import IconButton from '../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import iconCheckbox from '../../../../caro-ui-commonfiles/utils/iconCheckbox';
import CheckboxRadio from '../../../../caro-ui-commonfiles/utils/CheckboxRadio';
import JumpSelection from './JumpSelection';
import {
  useLogicJumpDestination,
  useLogicJumpCollection,
  getInitialState,
  useNewOptionText,
} from '../../../Utils/hooks/logicJumpHooks';
import { onSortEnd } from '../../../Utils/Careplan/careplanComponentUtilities';
import { PrimaryColor } from '../../../../caro-ui-commonfiles/utils/colors';

const SingleChoiceOption = SortableElement(
  ({
    option,
    setSingleChoiceJump,
    logicJumpCollectionItem,
    optionIndex,
    parentComponentIndex,
    onOptionInputChange,
    removeOption,
  }) => {
    const [isChecked, setIsChecked] = useReducer(
      (_state, ifChecked) => ifChecked,
      getInitialState(option, logicJumpCollectionItem)
    );

    const [optionInput, setOptionInput] = useReducer(
      (_state, text) => text,
      option
    );

    const { destination, setDestination } = useLogicJumpDestination(
      logicJumpCollectionItem,
      parentComponentIndex,
      isChecked,
      true
    );

    useEffect(() => {
      if (destination.jump > -1) {
        setSingleChoiceJump({
          options: isChecked ? [option] : null,
          index: optionIndex,
          isIntermediate:
            !destination.hasError &&
            destination.jump <= parentComponentIndex + 1,
          destination: isChecked ? destination.jump : -1,
        });
      }
    }, [isChecked, destination]);

    useEffect(() => {
      if (!isChecked) setDestination('');
    }, [isChecked, setDestination]);

    return (
      <div className="logic-jump__choice-parent-container">
        <div className="logic-jump__single-choice__container">
          <Flex flexDirection="row" otherStyles={{ flex: 1 }}>
            {option && (
              <Flex alignItems="center" otherStyles={{ width: '100%' }}>
                <IconCheckbox
                  id={`option-${parentComponentIndex}-${option}`}
                  iconName="logicJump"
                  name={`option-${parentComponentIndex}-${option}`}
                  variant={iconCheckbox.variant.CIRCLE}
                  checked={isChecked}
                  handleOnChange={() => setIsChecked(!isChecked)}
                  multiSelectEnabled
                  fill={PrimaryColor.MAIN_COLOR}
                  hasInlineInput
                  inputWidth="100%"
                  valueInput={optionInput}
                  onBlur={() => {
                    onOptionInputChange(
                      { target: { value: optionInput } },
                      parentComponentIndex,
                      optionIndex
                    );
                  }}
                  handleInputOnChange={e => {
                    setOptionInput(e.target.value);
                  }}
                />
              </Flex>
            )}
            {isChecked && (
              <JumpSelection
                parentComponentIndex={parentComponentIndex}
                destination={destination}
                setDestination={setDestination}
              />
            )}
          </Flex>
          <Flex justifyContent="flex-end" otherStyles={{ flexBasis: 40 }}>
            <IconButton
              name="close"
              size={20}
              tooltipText={I18n.t('toolTipsText.remove')}
              onClick={() => removeOption(parentComponentIndex, optionIndex)}
            />
          </Flex>
        </div>
        {destination.message && (
          <span className="validation-error">
            {I18n.t(destination.message)}
          </span>
        )}
      </div>
    );
  }
);

const SingleChoiceList = SortableContainer(
  ({ items, getSingleChoiceOption }) => {
    return (
      <div>{items.map((option, i) => getSingleChoiceOption(option, i))}</div>
    );
  }
);

const SingleChoice = ({
  component,
  setLogicJumpValueForComponent,
  componentIndex,
  setLogicJumpError,
  onOptionInputChange,
  removeOption,
  addOption,
}) => {
  const getSingleChoiceOptions = useCallback(() => {
    const { choices } = component.choiceCollection;
    if (choices && choices.length) {
      return choices.map(choice => choice.name);
    }
    return [];
  }, [component.choiceCollection]);

  const [options, setOptions] = useReducer(
    (_state, opts) => opts,
    getSingleChoiceOptions()
  );

  const setNewOption = optionToAdd => {
    const choices = { ...component.choiceCollection };
    choices.addChoice = optionToAdd;
    setLogicJumpValueForComponent('choiceCollection', choices);
    addOption(componentIndex);
  };

  const { text, setText } = useNewOptionText(setNewOption);

  const { logicJumpCollection, setLogicJumpCollection } =
    useLogicJumpCollection(
      setLogicJumpValueForComponent,
      component,
      setLogicJumpError
    );

  const getSingleChoiceOption = useCallback(
    (option, index) => {
      return (
        <SingleChoiceOption
          key={`sc-${componentIndex}-${option}`}
          option={option}
          setSingleChoiceJump={setLogicJumpCollection}
          logicJumpCollectionItem={
            logicJumpCollection.length > 0 ? logicJumpCollection[index] : {}
          }
          index={index}
          optionIndex={index}
          onOptionInputChange={onOptionInputChange}
          removeOption={removeOption}
          parentComponentIndex={componentIndex}
        />
      );
    },
    [componentIndex, logicJumpCollection, setLogicJumpCollection]
  );

  useEffect(() => {
    if (component.choiceCollection.choices)
      setOptions(getSingleChoiceOptions());
  }, [
    component.choiceCollection,
    JSON.stringify(component.choiceCollection.choices),
    component.choiceCollection.choices.length,
    getSingleChoiceOptions,
  ]);

  return (
    <div className="logic-jump__options-container">
      {options && options.length > 0 && (
        <SingleChoiceList
          items={options}
          lockAxis="y"
          pressDelay={200}
          getSingleChoiceOption={getSingleChoiceOption}
          onSortEnd={({ oldIndex, newIndex }) => {
            onSortEnd(
              oldIndex,
              newIndex,
              setLogicJumpValueForComponent,
              component
            );
          }}
        />
      )}
      <div className="addChoice" onBlur={() => setText('ADD_OPTION')}>
        <Flex otherStyles={{ flex: 1 }}>
          <RadioButton
            id="option_newSingleChoiceOption"
            iconName="logicJump"
            name="add_choice"
            placeholder={I18n.t('newCareplan_view.newOption')}
            iconOpacity={0.5}
            variant={CheckboxRadio.InlineInput}
            hasInlineInput
            checked={false}
            handleOnChange={() => { }}
            fill={PrimaryColor.MAIN_COLOR}
            inputWidth="100%"
            valueInput={text}
            handleInputOnChange={e => {
              setText(e.target.value);
            }}
          />
        </Flex>
        <div style={{ flexBasis: 40 }} />
      </div>
    </div>
  );
};

SingleChoiceOption.propTypes = {
  option: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  setSingleChoiceJump: PropTypes.func.isRequired,
  logicJumpCollectionItem: PropTypes.objectOf(
    PropTypes.oneOfType[(PropTypes.number, PropTypes.array)]
  ),
  index: PropTypes.number.isRequired,
  parentComponentIndex: PropTypes.number.isRequired,
  onOptionInputChange: PropTypes.func.isRequired,
};

SingleChoiceOption.defaultProps = {
  logicJumpCollectionItem: null,
};

SingleChoice.propTypes = {
  component: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  setLogicJumpValueForComponent: PropTypes.func.isRequired,
  componentIndex: PropTypes.number.isRequired,
  setLogicJumpError: PropTypes.func.isRequired,
  onOptionInputChange: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  addOption: PropTypes.func.isRequired,
};

export default SingleChoice;
