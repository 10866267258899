import React, { useState, useEffect } from 'react';
import I18n from 'i18next';
import moment from 'moment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import {
  ButtonType,
  ButtonVariant,
} from '../../../caro-ui-commonfiles/utils/button';
import { ActionColors } from '../../../caro-ui-commonfiles/utils/colors';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import UsFilterTopWarning from '../../../caro-ui-commonfiles/components/UsFilterWarnings/UsFilterTopWarning';

import AssignCareplanToPatientsRequest from '../../Utils/AssignCareplanToPatientsRequest';
import serviceConfig from '../../../serviceConfig.json';
import {
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import ScrollDownComponent from '../../Utils/ScrollDownComponent';
import { nonMedical } from '../../../config';
import useAllTodoAssessments from '../Notifications/Notification.hooks';

const AssignCareplanModalFooter = ({
  currentStep,
  setCurrentStep,
  nbrPatientsSelected,
  careplanData,
  closeModal,
  setFlashMessage,
  selectedPatientsData,
  careplanDataThresholds,
  dispatchFormValidator,
  isEvaluating,
  usWarningCheckboxChecked,
  bottomPageReached,
  careplanDataLogicJumps,
  showUnder18Warning,
  disableNext,
  triggerTrackAssigment,
}) => {
  const { user } = useSelector(state => state.session);
  const disableButton =
    !showUnder18Warning &&
    (!usWarningCheckboxChecked.awareCheckbox ||
      !usWarningCheckboxChecked.confirmCheckbox);
  const patientIds = selectedPatientsData
    ? selectedPatientsData.map(patient => patient.id)
    : null;
  const dataObject = {
    selectedLifespanUnit: careplanData.lifespanUnit,
    selectedRecurrence: careplanData.occurrenceCount.toString(),
    ...careplanData,
    selectedPatients: patientIds,
    user,
    careplanDataThresholds,
    careplanDataLogicJumps,
  };
  let hasThresholds = false;

  if (careplanDataThresholds.length) {
    careplanDataThresholds.map(careplan => {
      if (careplan[1].length > 0) {
        hasThresholds = true;
      }
    });
  }
  const [nextClicked, setNextClicked] = useState(false);
  const [showUnder18, setShowUnder18] = useState(false);
  const [showSendButton, setShowSendButton] = useState(
    !careplanData.isAssessment
  );
  const [buttonClicked, setButtonClicked] = useState(true);
  let isUnder18 = false;
  if (selectedPatientsData.length) {
    for (let p = 0; p < selectedPatientsData.length; p++) {
      const age = moment().diff(selectedPatientsData[p].birthdate, 'years');
      if (age < 18) {
        isUnder18 = true;
        break;
      }
    }
  }

  const checkPatientUnder18 = selectedPatientsData => {
    let isUnder18 = false;
    if (selectedPatientsData.length) {
      selectedPatientsData.map(patient => {
        const age =
          patient.isPatientDemographicData === false ||
          patient.isPatientDemographicData === undefined
            ? moment().diff(patient.birthdate, 'years')
            : patient.age;
        if (age < 18) {
          isUnder18 = true;
        }
      });
    }
    return isUnder18;
  };

  useEffect(() => {
    setShowUnder18(
      checkPatientUnder18(selectedPatientsData) &&
        (hasThresholds || isEvaluating) &&
        (nonMedical == false || nonMedical == 'false')
    );
  }, [hasThresholds, isEvaluating, selectedPatientsData]);

  const [showError, setShowError] = useState(false);
  const { getAllTodos, allTodoAssessmentsController } = useAllTodoAssessments();

  useEffect(() => {
    const checkUserCredential = async (component, action) => {
      return axios(serviceConfig.brokerService.checkUserCredential.uri, {
        params: {
          component,
          action,
        },
      })
        .then(response => {
          if (response.data.hasCredential) return response.data.hasCredential;
          return false;
        })
        .catch(error => {
          setShowSendButton(false);
        });
    };
    checkUserCredential(
      ComponentTranslationKeys.CAREPLAN_TEMPLATE,
      ActionTranslationKeys.SEND
    );
  }, []);

  const handleFetchingAssessments = () => {
    getAllTodos();
  };

  if (currentStep == 1) {
    return (
      <>
        <Flex flexDirection="row" alignItems="center">
          {showError && !nbrPatientsSelected ? (
            <>
              <Box margin="5px 5px 0 12px">
                <Icon name="error" size={20} fill={ActionColors.ERROR} />
              </Box>
              <Box>
                <span className="select-patient">
                  {I18n.t('warningMessage.notSelectedPatient')}
                </span>
              </Box>
            </>
          ) : null}
          {showUnder18 && nbrPatientsSelected ? (
            <Box margin="0 17px 0 0">
              <UsFilterTopWarning
                message={I18n.t('newCareplan_view.under18')}
                showUsWarningTopPage
                showEditIcon={false}
                showFilter={false}
              />
            </Box>
          ) : null}
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          otherStyles={{ flex: 1 }}
        >
          <Button
            label={I18n.t('common_buttons.next')}
            onClick={() => {
              setNextClicked(true);
              if (!nbrPatientsSelected) {
                setShowError(true);
              } else if (!showUnder18) {
                setCurrentStep(currentStep + 1);
              }
            }}
            disabled={showUnder18 || disableNext}
          />
        </Flex>
      </>
    );
  }
  if (currentStep !== 4) {
    return (
      <>
        <Button
          label={I18n.t('common_buttons.back')}
          onClick={() => {
            setCurrentStep(currentStep - 1);
            setShowUnder18(false);
            dispatchFormValidator({
              component: '',
              action: '',
              shouldValidate: false,
            });
          }}
          variant={ButtonVariant.OUTLINED}
        />

        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          otherStyles={{ flex: 3 }}
        >
          {currentStep === 3 && !bottomPageReached && <ScrollDownComponent />}
          <Button
            label={I18n.t('common_buttons.next')}
            type={ButtonType.SUBMIT}
            onClick={() => {
              if (currentStep === 2) {
                dispatchFormValidator({
                  component: 'careplan',
                  action: 'create',
                  shouldValidate: true,
                });
              } else {
                setCurrentStep(currentStep + 1);
              }
            }}
            disabled={currentStep === 3 && disableButton}
          />
        </Flex>
      </>
    );
  }
  return (
    <>
      <Button
        label={I18n.t('common_buttons.back')}
        onClick={() => {
          if (isUnder18 && (nonMedical == false || nonMedical == 'false')) {
            setCurrentStep(currentStep - 2);
          } else {
            setCurrentStep(currentStep - 1);
          }
        }}
        variant={ButtonVariant.OUTLINED}
      />
      <Flex
        flexDirection="row"
        justifyContent="flex-end"
        otherStyles={{ flex: 1 }}
      >
        <div style={{ marginRight: '10px' }}>
          <Button
            label={I18n.t('assignTemplate_view.addToAssignments')}
            type={ButtonType.SUBMIT}
            onClick={() => {
              triggerTrackAssigment('caretask');
              setButtonClicked(false);
              if (buttonClicked) {
                AssignCareplanToPatientsRequest(
                  careplanData.id,
                  closeModal,
                  setFlashMessage,
                  false,
                  dataObject,
                  true
                ).then(() => {
                  if (careplanData.isAssessment) {
                    if (allTodoAssessmentsController)
                      allTodoAssessmentsController.abort();
                    handleFetchingAssessments();
                  }
                });
              }
            }}
            variant={ButtonVariant.OUTLINED}
          />
        </div>
        {showSendButton ? (
          <Button
            label={I18n.t('assignTemplate_view.send_immediately')}
            type={ButtonType.SUBMIT}
            onClick={() => {
              triggerTrackAssigment('caretask');
              setButtonClicked(false);
              if (buttonClicked) {
                AssignCareplanToPatientsRequest(
                  careplanData.id,
                  closeModal,
                  setFlashMessage,
                  true,
                  dataObject,
                  true
                ).then(() => {
                  if (careplanData.isAssessment) {
                    if (allTodoAssessmentsController)
                      allTodoAssessmentsController.abort();
                    handleFetchingAssessments();
                  }
                });
              }
            }}
          />
        ) : null}
      </Flex>
    </>
  );
};

export default AssignCareplanModalFooter;
