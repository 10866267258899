const {
  timeOfDay,
} = require('../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils');

const updateDosageAndSelectedTime = values => {
  const specificTime = values.customTime.hour;
  values.dosageInMorning = values.dosageInMorning
    ? `${values.dosageInMorning.trim()} ${values.unit.trim()}`
    : values.dosageInMorning;
  values.dosageInAfternoon = values.dosageInAfternoon
    ? `${values.dosageInAfternoon.trim()} ${values.unit.trim()}`
    : values.dosageInAfternoon;
  values.dosageInEvening = values.dosageInEvening
    ? `${values.dosageInEvening.trim()} ${values.unit.trim()}`
    : values.dosageInEvening;
  values.dosageAtNight = values.dosageAtNight
    ? `${values.dosageAtNight.trim()} ${values.unit.trim()}`
    : values.dosageAtNight;
  values.dosageSpecificTime = values.dosageSpecificTime
    ? `${values.dosageSpecificTime.trim()} ${values.unit.trim()}`
    : values.dosageSpecificTime;
  const { dosageSpecificTime } = values;
  if (specificTime === timeOfDay.MORNING) {
    values.customTimeChecked = false;
    values.morning = true;
    values.dosageInMorning = values.dosageSpecificTime;
    values.dosageSpecificTime = '';
  }

  if (specificTime === timeOfDay.MIDDAY) {
    values.customTimeChecked = false;
    values.midday = true;
    values.dosageInAfternoon = values.dosageSpecificTime;
    values.dosageSpecificTime = '';
  }

  if (specificTime === timeOfDay.EVENING) {
    values.customTimeChecked = false;
    values.evening = true;
    values.dosageInEvening = values.dosageSpecificTime;
    values.dosageSpecificTime = '';
  }

  if (specificTime === timeOfDay.NIGHT) {
    values.customTimeChecked = false;
    values.night = true;
    values.dosageAtNight = values.dosageSpecificTime;
    values.dosageSpecificTime = '';
  }

  return values;
};

export { updateDosageAndSelectedTime };
