import { useEffect, useCallback, useState } from 'react';

const useWindowWidthListener = () => {
  const [responsive, setResponsive] = useState(window.innerWidth <= 1366);
  const screenSize = useCallback(() => {
    const isResponsive = window.innerWidth <= 1366;
    setResponsive(isResponsive);
  }, [setResponsive]);

  useEffect(() => {
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  return responsive;
};

export default useWindowWidthListener;
