import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationReducer, NotificationType } from './Notification.type';

const initialState: NotificationReducer = {
  todo: { count: 0, items: [] },
  chat: { count: 0, items: [] },
  threshold: { count: 0, items: [] },
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setThresholds: (
      state,
      action: PayloadAction<NotificationReducer[NotificationType.Threshold]>
    ) => {
      return {
        ...state,
        threshold: action.payload,
      };
    },
    setMessages: (
      state,
      action: PayloadAction<NotificationReducer[NotificationType.Chat]>
    ) => {
      return {
        ...state,
        chat: action.payload,
      };
    },
    setTodos: (state, action: PayloadAction<NotificationReducer[NotificationType.Todo]>) => {
      return {
        ...state,
        todo: action.payload,
      };
    },
  },
});

export const selectTodos = (state: { notifications: NotificationReducer }) =>
  state.notifications.todo;
export const selectMessage = (state: { notifications: NotificationReducer }) =>
  state.notifications.chat;
export const selectThreshold = (state: {
  notifications: NotificationReducer;
}) => state.notifications.threshold;

export const { setTodos, setThresholds, setMessages } =
  notificationSlice.actions;

export default notificationSlice.reducer;
