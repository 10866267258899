import { useReducer, useEffect } from 'react';

const useLogicJumpDestination = (
  logicJumpCollectionItem,
  parentComponentIndex,
  isChecked,
  isSingleChoice = false
) => {
  const checkDestination = (_state, newDestination) => {
    if (newDestination === '') return -1;
    const newJump = parseInt(newDestination.jump || newDestination, 10);

    const hasError =
      newDestination.jump <= parentComponentIndex + 1 ||
      newDestination <= parentComponentIndex + 1;

    if (isSingleChoice) {
      return {
        hasError,
        message: hasError ? 'warningMessage.jumpDestinationPosition' : '',
        jump: newJump,
      };
    } else {
      if (hasError) {
        return {
          hasError: true,
          message: 'warningMessage.jumpDestinationPosition',
          jump: newJump,
        };
      }
      return newJump;
    }
  };

  const [destination, setDestination] = useReducer(
    checkDestination,
    (logicJumpCollectionItem && logicJumpCollectionItem.destination) || -1
  );

  useEffect(() => {
    if (isChecked && destination > -1) {
      setDestination(destination);
    } else if (destination === -1) {
      setDestination(parentComponentIndex + 2);
    }
  }, [isChecked, parentComponentIndex]);

  useEffect(() => {
    if (isChecked && destination > -1)
      setDestination({ isBlurred: true, jump: destination });
  }, []);

  return { destination, setDestination };
};

const useLogicJumpCollection = (
  setLogicJumpValueForComponent,
  component,
  setLogicJumpError
) => {
  const updateLogicJumpObjects = (state, object) => {
    const { index, options, destination, isIntermediate } = object;
    const updatedState = [...state];
    const currObj = state.length > 0 ? state[index] : null;
    if (!isIntermediate) {
      setLogicJumpError(
        destination && destination.hasError ? index + 1 : -index - 1
      );
    }
    if (destination.hasError) return state;
    if (options && options.length && destination > -1 && destination !== '') {
      updatedState[index] = {
        options,
        destination,
      };
    } else if (currObj && currObj.destination > -1) {
      updatedState[index] = {
        options: null,
        destination: -1,
      };
    }
    setLogicJumpValueForComponent('logicJumpCollection', updatedState);
    return updatedState;
  };

  const [logicJumpCollection, setLogicJumpCollection] = useReducer(
    updateLogicJumpObjects,
    component.logicJumpCollection ? component.logicJumpCollection : []
  );

  return { logicJumpCollection, setLogicJumpCollection };
};

const useNewOptionText = setAddChoice => {
  const updateText = (state, newText) => {
    if (newText === 'ADD_OPTION') {
      setAddChoice(state);
      return '';
    }
    return newText;
  };

  const [text, setText] = useReducer(updateText, '');

  return { text, setText };
};

const getInitialState = (options, logicJumpCollectionItem) => {
  return (
    options &&
    logicJumpCollectionItem &&
    (logicJumpCollectionItem.options
      ? logicJumpCollectionItem.options &&
      logicJumpCollectionItem.options.length > 0
      : logicJumpCollectionItem.destination > -1)
  );
};

const useShowLogicJumpSelection = (options, logicJumpCollectionItem) => {
  const [showLogicJumpSelection, setShowLogicJumpSelection] = useReducer(
    (_state, show) => show,
    getInitialState(options, logicJumpCollectionItem)
  );

  const toggleHandler = state => {
    setShowLogicJumpSelection(state);
  };
  return { showLogicJumpSelection, toggleHandler };
};

export {
  useLogicJumpDestination,
  useLogicJumpCollection,
  getInitialState,
  useNewOptionText,
  useShowLogicJumpSelection,
};
