import I18n from 'i18next';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import ReactHtmlParser from 'react-html-parser';

import {
  Flex,
  IconButton,
  Modal,
  FloatButton,
  FlashMessage,
  UsFilterBottomWarning,
  SectionalModal,
  Breadcrumb,
  Box,
} from '@ui-common-files/components';
import {
  ComponentColors,
  Greys,
  Actions,
  ActionTranslationKeys,
  ComponentTranslationKeys,
  lifespanEnum,
} from '@ui-common-files/utils';
import { roles } from '@utils';

import {
  ApiClient,
  HideFlashMessage,
  ShowFlashMessage,
} from '@global/Services';
import { formatDate } from '@global/Date';

import { FlashMessageTypes } from '@ui-common-files/types';

import { MEDIA_QUERIES } from '@ui-common-files/utils/layout';
import { DateFormat } from '@ui-common-files/utils/dateUtils/dateUtil.type';
import {
  editPatientRequest,
  resetPatientData,
  updatePatientRequest,
} from '../../../actions/patients';
import {
  resetWearableData,
  resetWearableStatus,
} from '../../../actions/wearables';

import config, {
  enableOpsPatientView,
  isUsingOncobox,
  nonMedical,
} from '../../../config';

import tabPosition from '../../Utils/Administration/tabPosition';
import { convertLinkToBlob } from '../../Utils/Asset/assetHelper';
import modalTypes from '../../Utils/modalType';
import NavigationJSON from '../../Utils/navigation.json';
import getAllowedServicesAmong from '../../Utils/PatientOverview/getAllowedServicesAmong';
import userStatus from '../../Utils/userStatus';
import ApptCreateModalContent from '../Appointments/ApptCreateModalContent';
import AssignmentCardViewer from '../AssignmentCards/AssignmentCardViewer/AssignmentCardViewer';
import AddCareplanModalContent from '../Careplans/AddCareplanModalContent';
import AddCareplanModalFooter from '../Careplans/AddCareplanModalFooter';
import AddCareplanModalHeader from '../Careplans/AddCareplanModalHeader';
import {
  CreateCarePathwayContent,
  CreateCarePathwayFooter,
  CreateCarePathwayHeader,
} from '../Careplans/CarePathway/CreateCarePathwayModalContent';
import PatientOverviewModalFooter from '../common/PatientOverviewModalFooter';
import { PatientOverviewModalHeader } from '../common/PatientOverviewModalHeader';
import MedicationCreateModalContent from '../Medications/MedicationCreateModalContent';
import { PatientFormModal } from '../Patients/PatientFormModal';
import {
  QrCodeGenerator,
  QrCodeGeneratorFooter,
} from '../Patients/QrCodeGenerator';
import WithingsDevice from '../Patients/WithingsDevice';
import TaskCreateModalContent from '../Tasks/TaskCreateModalContent';
import { TelemedicineSidebar } from '../Telemedicine/TelemedicineSidebar';
import BreadcrumbPatientInfo from './BreadcrumbPatientInfo/BreadcrumbPatientInfo';
import ExportResultsModalContent from './ExportResultsModalContent';
import ExportResultsModalFooter from './ExportResultsModalFooter';
import {
  GetUserRolePayload,
  GetUserRoleResponse,
  CarePlanExportData,
} from './PatientOverview.type';
import checkIfNoInternetAndSetFlashMessage from './PatientOverview.util';
import PatientDetailsModalForUKF from './PatientDetails/PatientDetailsModal/PatientDetailsModalForUKF';
import {
  PatientDetailsModal,
  PatientDetailsFooter,
} from './PatientDetails/PatientDetailsModal/PatientDetailsModal';

import serviceConfig from '../../../serviceConfig.json';

import '../../css/patientOverview.scss';
import BreadcrumbPortal from '../AppLayout/BreadcrumbPortal/BreadcrumbPortal';

import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import {
  handleClearCache,
  handleResetAccount,
} from './PatientDetails/TroubleshootSection/TroubleshootSection.api';

import { useOverviewStore } from '../AssignmentCards/Overview/Overview.store';

const PatientOverviewContext = React.createContext(null);

const PATIENT_SECTIONAL_MODAL_DEFAULT = {
  isOpen: false,
  title: null,
  content: null,
  footer: null,
};

const PatientOverview = ({}) => {
  const [isPatientDemographicData, setPatientDemographicData] = useState(false);
  const [reportReviewFileURL, setReportReviewFileURL] = useState('');
  const { user } = useSelector(state => state.session);
  const services = useSelector(state => state.services);
  const CHAT_BOX_OFFSET =
    window.innerWidth < 2000 ? (window.innerWidth < 1366 ? 435 : 475) : 500;
  const [showUnder18, setShowUnder18] = useState(false);
  const [bottomPageReached, setBottomPageReached] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [disableDownloadInvitation, setDisableDownloadInvitation] =
    useState(true);
  const [answerCaretask, setAnswerCaretask] = useState(false);
  const [assetId, setAssetId] = useState();
  const [caretaskReviewName, setCaretaskReviewName] = useState('');
  const [isReviewReport, setIsReviewReport] = useState(false);
  const [shouldGetGraphViewData, setShouldGetGraphViewData] = useState(false);

  const [patientOncoboxData, setPatientOncoboxData] = useState(null);
  const [refreshCaretaskItemById, setRefreshCaretaskItemById] = useState(
    () => {}
  );
  const defaultFormValidationObj = {
    component: '',
    action: '',
    shouldValidate: false,
  };
  const defaultFormSubmissionObj = {
    component: '',
    action: '',
    submissionType: false,
  };
  const {
    ComponentTypes,
  } = require('../../../caro-ui-commonfiles/utils/componentTypes');
  const history = useHistory();
  const { patientId } = useParams();
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const tabQueryParam = searchParams.get('tab');
  const stateData = history.location.state ? history.location.state : {};
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [patientInfo, setPatientInfo] = useReducer(
    (_state, info) => info,
    stateData.patientInfo ? stateData.patientInfo : { DaysInTreatment: 0 }
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditModalLoading, setIsEditModalLoading] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const [flashMessage, setFlashMessage] = useState(
    stateData.message ? stateData.message : {}
  );
  const [qrPin, setQrPin] = useState(null);
  const [patientStatusSocket, setPatientStatusSocket] = useState(null);
  const [patientStatus, setPatientStatus] = useState({
    translationKey: userStatus.translationKey.unpaired,
    color: userStatus.color.unpaired,
  });
  const [careTeamPrimaryInstitutions, setCareTeamPrimaryInstitutions] =
    useState([]);
  const [modalContent, setModalContent] = useState({
    title: '',
    content: null,
    footer: null,
  });
  const [openPromptContent, setOpenPromptContent] = useState(false);
  const [usWarningCheckboxChecked, setUsWarningCheckboxChecked] = useState({
    awareCheckbox: false,
    confirmCheckbox: false,
  });
  const [showUnder18Warning, setShowUnder18Warning] = useState(false);
  const [forceScroll, setForceScroll] = useState({
    scroll: false,
    scrollTo: 0,
    careplan: false,
    careplanId: 0,
    careplanName: '',
    assessment: false,
    assessmentId: 0,
    appointment: false,
    appointmentId: 0,
  });
  const currMinute = Math.ceil(moment().minute() / 15) * 15;
  const startTimeObj = {
    hour: moment().hour() + Math.floor(currMinute / 60),
    minute: currMinute % 60,
  };
  const [isAdhoc, setIsAdhoc] = useState(true);
  const [appointmentDate, setAppointmentDate] = useState(moment().toDate());
  const [appointmentTime, setAppointmentTime] = useState(startTimeObj);
  const [isUnspecified, setIsUnspecified] = useState(false);
  const patientReduxState = useSelector(state => state.patients);
  const reduxDispatcher = useDispatch();
  const [allMessagesList, setAllMessagesList] = useState([]);
  const [reloadChat, setReloadChat] = useState(false);
  const [isPatientSyncNeeded, setIsPatientSyncNeeded] = useState(false);
  const [isPatientSyncRequestSent, setIsPatientSyncRequestSent] =
    useState(true);

  const [answeredCaretask, setAnsweredCaretask] = useState(false);
  const [isCaretaskDataLoading, setIsCaretaskDataLoading] = useState(true);
  const [isFetchingModalContent, setIsFetchingModalContent] = useState(false);
  const secondarySolutions = {
    1: {
      title: 'patient.troubleshoot.solution_2.prompt_title',
      buttonCTA: 'patient.troubleshoot.solution_2.cta_btn',
      prompt_message: 'patient.troubleshoot.solution_2.prompt_message',
      handler: () => {
        handleClearCache(patientInfo.firebaseId, patientInfo.id);
      },
    },
    2: {
      title: 'patient.troubleshoot.solution_3.prompt_title',
      buttonCTA: 'patient.troubleshoot.solution_3.cta_btn',
      prompt_message: 'patient.troubleshoot.solution_3.prompt_message',
      handler: () => {
        handleResetAccount(patientInfo.firebaseId, patientInfo.uuid);
      },
    },
  };

  const [triggerSolutionPrompt, setTriggerSolutionPrompt] = useState(false);
  const [promptElements, setPromptElements] = useState(secondarySolutions);
  const { t } = useTranslation();

  const { resetOverviewData, resetOverviewCaretasks } = useOverviewStore(
    useShallow(state => ({
      resetOverviewData: state.resetOverviewData,
      resetOverviewCaretasks: state.resetOverviewCaretasks,
    }))
  );

  const openSolutionPrompt = solutionChosen => {
    setIsModalOpen(false);
    setTriggerSolutionPrompt(true);
    setPromptElements(secondarySolutions[solutionChosen]);
  };
  const closePatientDetailsMessagePrompt = () => {
    setOpenPromptContent(false);
    showPatientDetailsModal();
    setPromptElements(secondarySolutions);
    setTriggerSolutionPrompt(false);
  };

  const getValidServiceComponents = (serviceObjects, data = false) => {
    const validComponents = [
      {
        total: 0,
        component: ComponentTypes.OVERVIEW,
      },
    ];

    validComponents.push({
      total: data?.todoTotal || 0,
      component: ComponentTypes.TODOS,
    });

    if (
      serviceObjects.hasOwnProperty('careplanservice.myoncare.care') &&
      serviceObjects.hasOwnProperty('appointmentservice.myoncare.care')
    ) {
      validComponents.push({
        total: data ? data.carePathwayTotal : 0,
        component: ComponentTypes.CAREPATHWAYS,
      });
    }

    if (serviceObjects.hasOwnProperty('careplanservice.myoncare.care')) {
      validComponents.push({
        total: data ? data.careplanTotal : 0,
        component: ComponentTypes.CAREPLAN,
      });
    }

    if (serviceObjects.hasOwnProperty('medicationservice.myoncare.care')) {
      validComponents.push({
        total: data ? data.medTotal : 0,
        component: ComponentTypes.MEDICATION,
      });
    }

    if (serviceObjects.hasOwnProperty('appointmentservice.myoncare.care')) {
      validComponents.push({
        total: data ? data.apptTotal : 0,
        component: ComponentTypes.APPOINTMENT,
      });
    }

    validComponents.push({
      total: 0,
      component: ComponentTypes.CALENDAR,
    });

    if (services.assetRead && !isPatientDemographicData) {
      validComponents.push({
        total: 0,
        component: ComponentTypes.ASSETS,
      });
    }
    if (isUsingOncobox) {
      validComponents.push({
        total: 0,
        component: ComponentTypes.ONCOBOX_IMPORTED_DATA,
      });
    }

    if (serviceObjects) {
      return validComponents;
    }
    return [];
  };
  const [componentDataCount, setComponentDataCount] = useState(
    getValidServiceComponents(services)
  );

  const [currentStep, setCurrentStep] = useReducer(
    (_state, stepNumber) => stepNumber,
    1
  );
  const [shouldValidateForm, dispatchFormValidator] = useReducer(
    (_state, action) => action,
    defaultFormValidationObj
  );
  const [shouldSubmitForm, dispatchFormSubmittor] = useReducer(
    (_state, action) => action,
    defaultFormSubmissionObj
  );
  const [landingTab, setLandingTab] = useState(
    componentDataCount && componentDataCount.length > 0
      ? componentDataCount[0].component
      : false
  );
  const [refreshCalendar, setRefreshCalendar] = useReducer(
    (_state, toRefresh) => toRefresh,
    false
  );

  const [hasOneCareplanExpired, setHasOneCareplanExpired] = useReducer(
    (_state, toShow) => toShow,
    {}
  );

  const [showCareplanResults, setShowCareplanResults] = useReducer(
    (_state, toShow) => toShow,
    false
  );
  const [userHasPermission, setUserHasPermission] = useReducer(
    (_state, permission) => permission,
    false
  );
  const [doFetch, setDoFetch] = useReducer((_state, fetch) => fetch, true);
  const [careplanHeaderFooter, setCareplanHeaderFooter] = useReducer(
    (_state, content) => content,
    {
      header: 'newCareplan_view.assignCareplan',
      startNow: false,
      isAssessment: false,
    }
  );

  const [devicesQuery, setDevicesQuery] = useReducer(
    (_state, content) => content,
    {
      activities: [],
      filter: lifespanEnum.MONTH,
      isLastDataActive: true,
      date: {
        start: formatDate(
          moment().subtract(30, 'days').startOf('day').toDate(),
          DateFormat.DATE_HOUR_MINUTE_SECOND
        ),
        end: formatDate(moment().toDate(), DateFormat.DATE_HOUR_MINUTE_SECOND),
      },
    }
  );

  const resetChatStateHistory = () => {
    history.replace(history.location.pathname, {
      ...history.location.state,
      shouldOpenChat: null,
      recordId: null,
    });
  };

  const showPatientDetailsModal = () => {
    if (enableOpsPatientView === 'true' || enableOpsPatientView == true) {
      setModalContent({
        title: t('patientOverview.patientDetails'),
        content: <PatientDetailsModal patientInfo={patientInfo} />,
        footer: <PatientDetailsFooter closeModal={closeModal} />,
      });
      setIsModalOpen(true);
    } else {
      setModalContent({
        title: t('patientOverview.patientDetails'),
        content: (
          <PatientDetailsModalForUKF
            patientInfo={patientInfo}
            setUserHasPermission={setUserHasPermission}
          />
        ),
        footer: <PatientDetailsFooter closeModal={closeModal} />,
      });
      setIsModalOpen(true);
    }
  };

  const [isChatOpened, setIsChatOpened] = useReducer((_state, isOpen) => {
    if (stateData.shouldOpenChat && (isChatOpened || isOpen)) {
      resetChatStateHistory();
    }
    return isOpen && services.hasOwnProperty('chatservice.myoncare.care');
  }, false);

  const getTabQueryString = () => {
    return tabQueryParam ? parseInt(tabQueryParam) : null;
  };

  const [activeTab, setActiveTab] = useState(getTabQueryString() || 0);
  const [isTemplateSelected, setIsTemplateSelected] = useState(-1);
  const [selectedCarepathway, setSelectedCarepathway] = useState(-1);
  const [sendOutInmidiat, setSendOutInmidiat] = useState(false);
  const [dataForExport, setDataForExport] = useState({});
  const [carePathwayHasThresholds, setcarePathwayHasThresholds] =
    useState(false);
  const [hasMedicationPlan, setHasMedicationPlan] = useState(false);
  const [occurrenceId, setOccurrenceId] = useState(null);
  const [careplanId, setCareplanId] = useState(null);
  const [carePathway, setCarePathway] = useState(null);
  const [chatNotified, setChatNotified] = useState(null);
  const [carePlanExportData, setCarePlanExportData] =
    useState<CarePlanExportData>(null);

  const getActiveTab = (_state, data) => data;
  const [telemedicineActiveTab, setTelemedicineActiveTab] = useReducer(
    getActiveTab,
    services['chatservice.myoncare.care']
      ? tabPosition.FIRST
      : tabPosition.SECOND
  );

  const [patientSectionalModal, setPatientSectionalModal] = useState(
    PATIENT_SECTIONAL_MODAL_DEFAULT
  );

  const closePatientSectionalModal = () => {
    setPatientSectionalModal(PATIENT_SECTIONAL_MODAL_DEFAULT);
  };

  useEffect(() => {
    if (
      !isChatOpened &&
      stateData.shouldOpenChat &&
      patientInfo.id === parseInt(patientId, 10)
    ) {
      setTelemedicineActiveTab(
        stateData?.answer ? tabPosition.THIRD : tabPosition.FIRST
      );
      setIsChatOpened(true);
    }
  }, [
    patientInfo.id,
    isChatOpened,
    patientId,
    answerCaretask,
    stateData?.chat,
  ]);

  const handleSelectedCarePathway = data => {
    setCarePathway(data);
  };

  const navigateToCareplanAnswerView = async params => {
    setOccurrenceId(params.occurrenceId);
    setCareplanId(params.id);
    setAnswerCaretask(true);
    setAnsweredCaretask(false);
    setRefreshCaretaskItemById(() => params.setItemIdToUpdate);
  };

  const closeModal = () => {
    resetOverviewCaretasks();
    setCurrentStep(1);
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    dispatchFormValidator(defaultFormValidationObj);
    setModalContent({
      title: '',
      content: null,
      footer: null,
    });
    setIsTemplateSelected(-1);
    setSelectedCarepathway(-1);
    setDataForExport([]);
    setUsWarningCheckboxChecked({
      awareCheckbox: false,
      confirmCheckbox: false,
    });
    setBottomPageReached(false);
    setSendOutInmidiat(false);
    setcarePathwayHasThresholds(false);
    setCarePathway(null);
    setDisableDownloadInvitation(true);
    setIsAdhoc(true);
    setAppointmentDate(moment().toDate());
    setAppointmentTime(startTimeObj);
    setIsUnspecified(false);
    setIsCaretaskDataLoading(true);
    closePatientSectionalModal();
  };

  const openModalAndSetModalContent = content => {
    setOpenPromptContent(false);
    setIsModalOpen(true);
    setModalContent(content);
  };

  const setMessagePrompt = content => {
    setIsModalOpen(false);
    setOpenPromptContent(true);
    setModalContent(content);
  };

  const closeMessagePrompt = () => {
    setOpenPromptContent(false);
  };

  const hideFlashMessages = () => {
    setFlashMessage({});
    if (stateData.message || stateData.isAssessment) {
      history.replace(history.location.pathname, {
        ...history.location.state,
        isAssessment: null,
        message: null,
      });
    }
  };

  const changePatientStatus = data => {
    setPatientStatus({
      translationKey: userStatus.translationKey[data.status],
      color: userStatus.color[data.status],
    });
  };

  useEffect(() => {
    const searchTab = getTabQueryString();
    if (searchTab !== null) setActiveTab(searchTab);
  }, [tabQueryParam]);

  useEffect(() => {
    const stringedActiveTab = activeTab.toString();
    const isTabDifferent =
      tabQueryParam !== null && tabQueryParam !== stringedActiveTab;

    if (isTabDifferent) {
      history.push({ search: `?tab=${stringedActiveTab}` });
    }
  }, [activeTab]);

  useEffect(() => {
    if (stateData.deepLink && !stateData.isAssessment) {
      setLandingTab(ComponentTypes.CAREPLAN);
      setForceScroll({
        careplan: true,
        careplanId: parseInt(stateData.careplanId, 10),
        careplanName: stateData.careplanName,
      });
    }
    if (stateData.answer) {
      navigateToCareplanAnswerView({
        id: stateData.careplanId,
        occurrenceId: stateData.occurrenceId,
      });
    }
  }, [
    stateData.answer,
    stateData.deepLink,
    stateData.careplanName,
    stateData.careplanId,
    stateData.shouldOpenChat,
  ]);

  useLayoutEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener('resize', updateWidth);
      reduxDispatcher(resetWearableData());
      reduxDispatcher(resetWearableStatus());
      resetOverviewData();
    };
  }, []);

  function getPatientOverview() {
    let firebasePatientId;
    ApiClient.GET({
      url: serviceConfig.brokerService.patientOverview.uri,
      payload: {
        patientId,
      },
    })
      .then(async response => {
        const responseData = response.data;
        if (responseData) {
          const responseDataPatient = responseData.patient;
          responseDataPatient.scoringSchemeCategory =
            responseData.scoringSchemeCategory;
          responseDataPatient.patientAppts = responseData.patientAppts;
          responseDataPatient.patientApptsIcds = responseData.patientApptsIcds;
          firebasePatientId = responseData.patient.firebaseId;
          setHasMedicationPlan(responseData.hasMedicationPlan);
          if (
            moment().diff(responseDataPatient.birthdate, 'years') < 18 &&
            (nonMedical == false || nonMedical == 'false')
          ) {
            setShowUnder18Warning(true);
          }

          if (responseDataPatient.profilePicture.directLink) {
            await convertLinkToBlob(
              responseDataPatient.profilePicture.directLink
            )
              .then(blob => {
                responseDataPatient.profilePicture = blob;
              })
              .catch(() => {
                responseDataPatient.profilePicture =
                  responseDataPatient.profilePicture.logo.directLink;
              });
          } else {
            responseDataPatient.profilePicture = `data:image;base64,${responseDataPatient.profilePicture}`;
          }
          setPatientInfo(responseDataPatient);
          setQrPin(responseData.patientkey);
          setCareTeamPrimaryInstitutions(
            responseData.careTeamPrimaryInstitutions
          );
          setComponentDataCount(
            getValidServiceComponents(services, responseData)
          );
          setIsPatientSyncNeeded(
            response.data.isPatientSyncNeeded
              ? response.data.isPatientSyncNeeded
              : false
          );
          setIsPatientSyncRequestSent(response.data.isPatientSyncRequestSent);
        }
        setDoFetch(false);
        setUserHasPermission(true);
        return ApiClient.POST({
          url: serviceConfig.brokerService.getPatientStatus.uri,
          payload: {
            firebaseId: firebasePatientId,
          },
        }).catch(checkIfNoInternetAndSetFlashMessage(setFlashMessage));
      })
      .then(initialPatientStatus => {
        changePatientStatus(initialPatientStatus.data);
        if (initialPatientStatus) {
          setPatientStatusSocket(`presenceUpdated_${firebasePatientId}`);
        }
      })
      .catch(
        checkIfNoInternetAndSetFlashMessage(setFlashMessage, error => {
          if (
            error.response &&
            (error.response.status == 403 || error.response.status == 404)
          ) {
            history.push(`/${NavigationJSON.PATIENT}`, {
              NoPermissionflashMessage: {
                display: true,
                type: error.response.data.type,
                content: t(error.response.data.content),
              },
            });
          } else {
            setUserHasPermission(false);
            if (error.response && error.response.data) {
              setFlashMessage({
                type: error.response.data.type,
                content: error.response.data.content,
              });
            }
          }
        })
      );
  }

  useEffect(() => {
    if (doFetch) getPatientOverview();
  }, [doFetch]);

  useEffect(() => {
    const getUserRole = () => {
      return ApiClient.POST<GetUserRoleResponse, GetUserRolePayload>({
        url: serviceConfig.brokerService.editUser.uri,
        payload: {
          id: user.id,
        },
      })
        .then(response => {
          if (response.data?.user?.role)
            setPatientDemographicData(
              response.data.user.role.isPatientDemographicData
            );
        })
        .catch(checkIfNoInternetAndSetFlashMessage(setFlashMessage));
    };
    getUserRole();
  }, []);

  const checkUserCredential = (component, action, chatIconClicked = false) => {
    return ApiClient.GET({
      url: serviceConfig.brokerService.checkUserCredential.uri,
      payload: {
        component,
        action,
      },
    })
      .then(response => {
        if (response.data.hasCredential) return response.data.hasCredential;
        return false;
      })
      .catch(
        checkIfNoInternetAndSetFlashMessage(setFlashMessage, error => {
          if (
            component !== ComponentTranslationKeys.TELEMEDICINE ||
            (services['chatservice.myoncare.care'] && chatIconClicked)
          ) {
            setFlashMessage({
              type: error.response.data.type,
              content: error.response.data.content,
            });
          }
        })
      );
  };

  const handleChatDisplay = async (shouldOpenChat = null) => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.TELEMEDICINE,
      ActionTranslationKeys.READ,
      !shouldOpenChat
    );
    if (userHasCredential) {
      setIsChatOpened(shouldOpenChat !== null ? shouldOpenChat : !isChatOpened);
    }
  };

  useEffect(() => {
    const retrieveCustomToken = async () => {
      ApiClient.POST({
        url: serviceConfig.chatService.retrieveCustomToken.uri,
        payload: {
          firebaseObject: {
            patientId: patientInfo.id,
          },
        },
      })
        .then(response => {
          const { customToken } = response.data;
          if (customToken) {
            const auth = getAuth();
            signInWithCustomToken(auth, customToken)
              .then(() => {
                if (
                  patientInfo.firebaseId &&
                  (windowWidth >= MEDIA_QUERIES.SCREEN_MD ||
                    stateData.shouldOpenChat)
                ) {
                  handleChatDisplay(true);
                }
              })
              .catch(error => {
                handleChatDisplay(false);
              });
          }
        })
        .catch(checkIfNoInternetAndSetFlashMessage(setFlashMessage));
    };
    if (patientInfo.firebaseId) retrieveCustomToken();
  }, [patientInfo.firebaseId]);

  useEffect(() => {
    const updateNewPatientToExistingPatient = async () => {
      await ApiClient.POST({
        url: serviceConfig.patientService.approve.uri.replace(
          ':patientId',
          patientId
        ),
      });
    };
    const userPrimaryInstitution =
      user.institutions &&
      user.institutions.find(
        institution => institution.userInstitution.isPrimary
      );
    const checkNewPatient =
      patientInfo &&
      patientInfo.institutions &&
      patientInfo.institutions.find(
        institution =>
          institution.patientInstitution.isNew &&
          institution.id == userPrimaryInstitution.id
      );
    const isPatientCreatedFromWeb =
      history.location.state?.isPatientCreatedFromWeb || null;
    (checkNewPatient || isPatientCreatedFromWeb) &&
      user.role.name !== roles.EXTERNAL_HCP &&
      updateNewPatientToExistingPatient();
  }, [patientInfo]);

  useEffect(() => {
    if (patientInfo.id && parseInt(patientId, 10) !== patientInfo.id) {
      setIsChatOpened(false);
      setDoFetch(true);
    } else if (patientInfo.id && stateData.shouldOpenChat) {
      if (isChatOpened && stateData.recordId) {
        setTelemedicineActiveTab(tabPosition.FIRST);
        ApiClient.POST({
          url: serviceConfig.chatService.setMessageNotificationsToRead.uri,
          payload: {
            chatMessageIds: [stateData.recordId],
            patientId,
          },
        }).catch(checkIfNoInternetAndSetFlashMessage(setFlashMessage));
        resetChatStateHistory();
      } else {
        handleChatDisplay(stateData.shouldOpenChat);
      }
    }
  }, [patientId, stateData.shouldOpenChat]);

  const initPatientStatusSocket = socket => {
    socket.on(patientStatusSocket, changePatientStatus);
  };

  const unsubscribeToPatientStatusSocket = socket => {
    socket.removeListener(patientStatusSocket, changePatientStatus);
  };

  const renderModalHeader = action => {
    switch (action) {
      case modalTypes.CAREPLAN:
        return <AddCareplanModalHeader currentStep={currentStep} />;

      case modalTypes.MEDICATION:
        return (
          <PatientOverviewModalHeader
            settingsStepTranslation={t('steps.medicationSettings')}
          />
        );
      case modalTypes.TASK:
        return (
          <PatientOverviewModalHeader
            settingsStepTranslation={t('steps.taskSettings')}
          />
        );

      case modalTypes.APPOINTMENT:
        return (
          <PatientOverviewModalHeader
            settingsStepTranslation={t('steps.appointment_settings')}
          />
        );

      default:
        return <PatientOverviewModalHeader currentStep={currentStep} />;
    }
  };

  const renderExportAnswersModal = () => {
    setModalContent({
      title: `${t('patientOverview.exportResults')}`,
      content: (
        <ExportResultsModalContent
          setCarePlanExportData={setCarePlanExportData}
          patientId={patientId}
        />
      ),
      footer: (
        <ExportResultsModalFooter carePlanExportData={carePlanExportData} />
      ),
    });
    setIsModalOpen(true);
  };

  const renderFloatingMenuBtnModals = async action => {
    let userHasCredential;
    switch (action) {
      case modalTypes.MEDICATION:
        userHasCredential = await checkUserCredential(
          ComponentTranslationKeys.MEDICATION,
          ActionTranslationKeys.CREATE
        );
        if (userHasCredential) {
          const medicalRecordHeadline = `${t('medication.assignMedication')} ${
            patientInfo.medicalRecord
          }`;

          const patientHeadline = `${t('medication.assignMedication')} ${
            patientInfo.firstname
          } ${patientInfo.lastname}`;

          const title: string = isPatientDemographicData
            ? medicalRecordHeadline
            : patientHeadline;
          setModalContent({
            title,
            content: <MedicationCreateModalContent />,
            footer: (
              <PatientOverviewModalFooter
                componentType={ComponentTypes.MEDICATION}
                actionType={Actions.Create}
              />
            ),
            header: renderModalHeader(action),
          });
        }
        break;
      case modalTypes.TASK:
        userHasCredential = await checkUserCredential(
          ComponentTranslationKeys.TASK,
          ActionTranslationKeys.CREATE
        );
        if (userHasCredential) {
          const medicalRecordHeadline = `${t('task.assignTask')} ${
            patientInfo.medicalRecord
          }`;

          const patientHeadline = `${t('task.assignTask')} ${
            patientInfo.firstname
          } ${patientInfo.lastname}`;

          const title: string = isPatientDemographicData
            ? medicalRecordHeadline
            : patientHeadline;
          setModalContent({
            title,
            content: <TaskCreateModalContent />,
            footer: (
              <PatientOverviewModalFooter
                componentType={ComponentTypes.TASK}
                actionType={Actions.Create}
              />
            ),
            header: renderModalHeader(action),
          });
        }
        break;
      case modalTypes.APPOINTMENT:
        userHasCredential = await checkUserCredential(
          ComponentTranslationKeys.APPOINTMENT,
          ActionTranslationKeys.CREATE
        );
        if (userHasCredential) {
          const medicalRecordHeadline = `${t(
            'appointment.assignAppointment'
          )} ${patientInfo.medicalRecord}`;

          const patientHeadline = `${t('appointment.assignAppointment')} ${
            patientInfo.firstname
          } ${patientInfo.lastname}`;

          const title: string = isPatientDemographicData
            ? medicalRecordHeadline
            : patientHeadline;
          setModalContent({
            title,
            content: <ApptCreateModalContent />,
            footer: (
              <PatientOverviewModalFooter
                componentType={ComponentTypes.APPOINTMENT}
                actionType={Actions.Create}
              />
            ),
            header: renderModalHeader(action),
          });
        }
        break;
      case modalTypes.CAREPLAN:
        const userHasCredentialCreate = await checkUserCredential(
          ComponentTranslationKeys.CAREPLAN,
          ActionTranslationKeys.CREATE
        );
        if (userHasCredentialCreate) {
          setIsModalOpen(true);
          const patient = [
            {
              id: patientInfo.id,
              firstname: patientInfo.firstname,
              lastname: patientInfo.lastname,
              profilePicture: patientInfo.profilePicture,
              birthdate: patientInfo.birthdate,
              age: moment().diff(patientInfo.birthdate, 'years'),
            },
          ];
          const medicalRecordHeadline = `${t(careplanHeaderFooter.header)} ${
            patientInfo.medicalRecord
          }`;

          const patientHeadline = `${t(careplanHeaderFooter.header)} ${
            patientInfo.firstname
          } ${patientInfo.lastname}`;

          const title: string = isPatientDemographicData
            ? medicalRecordHeadline
            : patientHeadline;
          setModalContent({
            title,
            content: (
              <AddCareplanModalContent
                selectedPatientsData={patient}
                fromPatientOverview
              />
            ),
            footer: (
              <AddCareplanModalFooter
                componentType={ComponentTypes.CAREPLAN}
                actionType={Actions.Create}
              />
            ),
            header: renderModalHeader(action),
          });
        }
        break;
      case modalTypes.CAREPATHWAY:
        const hasAssignCarePathwayCredential = await checkUserCredential(
          ComponentTranslationKeys.CAREPATHWAY,
          ActionTranslationKeys.CREATE
        );
        const hasAssignCareplanCredential = await checkUserCredential(
          ComponentTranslationKeys.CAREPLAN,
          ActionTranslationKeys.CREATE
        );
        userHasCredential =
          hasAssignCarePathwayCredential && hasAssignCareplanCredential;
        if (userHasCredential) {
          const medicalRecordHeadline = `${t(
            'common_labels.assign_carepathway'
          )} ${patientInfo.medicalRecord}`;

          const patientHeadline = `${t('common_labels.assign_carepathway')} ${
            patientInfo.firstname
          } ${patientInfo.lastname}`;

          const title: string = isPatientDemographicData
            ? medicalRecordHeadline
            : patientHeadline;
          const patient = [
            {
              id: patientInfo.id,
              firstname: patientInfo.firstname,
              lastname: patientInfo.lastname,
              profilePicture: patientInfo.profilePicture,
              birthdate: patientInfo.birthdate,
              age: moment().diff(patientInfo.birthdate, 'years'),
              patientAppts: patientInfo.patientAppts,
              patientApptsIcds: patientInfo.patientApptsIcds,
            },
          ];

          setModalContent({
            title,
            content: (
              <CreateCarePathwayContent
                patientData={patient}
                close={closeModal}
                fromEditAssignment={false}
                carePathwayData={carePathway}
              />
            ),
            footer: (
              <CreateCarePathwayFooter
                componentType={ComponentTypes.CAREPATHWAYS}
                actionType={Actions.Create}
                fromEditAssignment={false}
              />
            ),
            header: <CreateCarePathwayHeader />,
          });
        }
        break;
      default:
        break;
    }
    if (userHasCredential) setIsModalOpen(true);
  };

  useEffect(() => {
    setModalContent({
      ...modalContent,
      footer: (
        <ExportResultsModalFooter carePlanExportData={carePlanExportData} />
      ),
    });
  }, [carePlanExportData]);

  const showQrCodeModal = () => {
    const patientInstitutions = patientInfo.institutions.map(
      institution => institution.id
    );
    let hasPermission = false;
    user.institutions.map(institution => {
      if (patientInstitutions.includes(institution.id)) hasPermission = true;
    });
    if (hasPermission) {
      setModalContent({
        title: !isPatientDemographicData
          ? `${t('patientOverview.qrButton')} - ${patientInfo.firstname} ${
              patientInfo.lastname
            }`
          : `${t('patientOverview.qrButton')}`,
        content: (
          <QrCodeGenerator
            closeModal={closeModal}
            setFlashMessage={setFlashMessage}
            patientInfo={patientInfo}
            qrPin={qrPin}
            isPatientDemographicData={isPatientDemographicData}
          />
        ),
        footer: <QrCodeGeneratorFooter close={closeModal} />,
      });
      setIsModalOpen(true);
    } else {
      setFlashMessage({
        type: 'warning',
        content: 'common_labels.msg_noPermission',
      });
    }
  };

  const showWithingsDeviceModal = () => {
    setModalContent({
      title: t('patientOverview.withings_device.common.withings_device'),
      content: <WithingsDevice />,
      footer: <PatientDetailsFooter closeModal={closeModal} />,
    });
    setIsModalOpen(true);
  };

  useEffect(() => {
    const socket = io(`${window.location.host}`, config.socketOptions);
    if (patientStatusSocket && socket) initPatientStatusSocket(socket);
    return function cleanup() {
      if (patientStatusSocket && socket) {
        unsubscribeToPatientStatusSocket(socket);
        socket.close();
      }
    };
  }, [patientStatusSocket]);

  const onClickTabItem = tab => {
    setActiveTab(tab);
  };

  const onEditPatient = async () => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.PATIENT,
      ActionTranslationKeys.UPDATE
    );
    if (userHasCredential) {
      reduxDispatcher(editPatientRequest(patientInfo.id));
    }
  };

  useEffect(() => {
    if (patientReduxState.editPatientResponse) {
      setEditModalData(patientReduxState.editPatientResponse);
      setIsEditModalLoading(false);
      setIsEditModalOpen(true);
      reduxDispatcher(resetPatientData());
    }
    if (patientReduxState.updatePatientResponse) {
      closeModal();
      setFlashMessage({
        type: patientReduxState.updatePatientResponse.type,
        content: t(patientReduxState.updatePatientResponse.content),
      });
      reduxDispatcher(resetPatientData());
      setDoFetch(true);
    }
    if (patientReduxState.patientRequestError) {
      closeModal();
      setFlashMessage({
        content: patientReduxState.patientRequestError.content,
        type: patientReduxState.patientRequestError.type,
      });
      reduxDispatcher(resetPatientData());
    }
  }, [patientReduxState]);
  const { isInMedicalCountry } = patientInfo;
  const nonMedicalAndInMedicalCountry = isInMedicalCountry || nonMedical;

  const getFill = (isMedication: boolean) => {
    if (isMedication) {
      return hasMedicationPlan
        ? Greys.LIGHT_GREY_50
        : ComponentColors.MEDICATION;
    }
    return nonMedicalAndInMedicalCountry
      ? ComponentColors.CAREPLAN
      : Greys.LIGHT_GREY_50;
  };
  const getToolTip = (isCarePlan: boolean, isMedication: boolean) => {
    if (isMedication) {
      return hasMedicationPlan
        ? t('common_labels.permissionDeniedHasMedicationPlan')
        : t('medication.assignMedicationHover');
    }
    if (nonMedicalAndInMedicalCountry) {
      return isCarePlan
        ? t('newCareplan_view.assignCareplanHover')
        : t('common_labels.assign_carepathway_hover');
    }
    return t('common_labels.permissionDenied');
  };
  const onClickBasedOnAction = (action: string) => {
    if (nonMedicalAndInMedicalCountry) {
      if (action === modalTypes.MEDICATION && hasMedicationPlan) {
        ShowFlashMessage({
          type: FlashMessageTypes.Warning,
          messageKey: 'common_labels.permissionDeniedHasMedicationPlan',
        });
      } else {
        renderFloatingMenuBtnModals(action);
      }
    } else {
      ShowFlashMessage({
        type: FlashMessageTypes.Error,
        messageKey: 'common_labels.geoFencingPermissionDenied',
      });

      close();
    }
  };
  const menuList = [
    {
      icon: 'appointmentManager',
      fill: ComponentColors.APPOINTMENT,
      tooltipText: t('newAppointment_view.label_addAppointment'),
      onClick: close => {
        renderFloatingMenuBtnModals(modalTypes.APPOINTMENT);
        close();
      },
      service: ['appointmentservice.myoncare.care'],
    },
    {
      icon: 'medication',
      fill: getFill(true),
      tooltipText: getToolTip(false, true),
      onClick: close => {
        onClickBasedOnAction(modalTypes.MEDICATION);
        close();
      },
      service: ['medicationservice.myoncare.care'],
    },
    {
      icon: 'carepathway',
      fill: getFill(false),
      tooltipText: getToolTip(false, false),
      onClick: close => {
        onClickBasedOnAction(modalTypes.CAREPATHWAY);
        close();
      },
      service: [
        'careplanservice.myoncare.care',
        'appointmentservice.myoncare.care',
      ],
    },
    {
      icon: 'careplan',
      fill: getFill(false),
      tooltipText: getToolTip(true, false),
      onClick: close => {
        onClickBasedOnAction(modalTypes.CAREPLAN);
        close();
      },
      service: ['careplanservice.myoncare.care'],
    },
  ];

  const renderIconButtons = () => {
    return (
      <Box className="iconButtonContainers">
        {isPatientSyncNeeded && (
          <IconButton
            name="sync"
            size={35}
            onClick={() => {
              onSyncPatient();
            }}
            tooltipText={
              isPatientSyncRequestSent
                ? t('patientOverview.syncPatientRequestSent')
                : t('patientOverview.syncPatientDetails')
            }
            disabled={isPatientSyncRequestSent}
          />
        )}
      </Box>
    );
  };

  const setActiveTabAndReload = tabId => {
    setReloadTable(!reloadTable);
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (location.state?.isTaggedUser) {
      ApiClient.POST({
        url: serviceConfig.brokerService.setNoteNotificationToRead.uri,
        payload: {
          userTaggedNoteId: location.state?.userTaggedNoteId,
          patientId,
        },
      })
        .then(response => {
          if (response) {
            setTelemedicineActiveTab(tabPosition.SECOND);
          }
        })
        .catch(error => {
          ShowFlashMessage({
            type: FlashMessageTypes.Error,
            messageKey: error.response.data.content,
          });
        });
    }
  }, [location.state?.isTaggedUser]);

  useEffect(() => {
    if (location.state?.isReportReview) {
      ApiClient.GET({
        url: `${serviceConfig.brokerService.getAssetById.uri}${location.state.assetId}`,
      }).then(response => {
        if (response) {
          const { directLink } = response.data.assetData;
          setReportReviewFileURL(directLink);
        }
      });
      setIsChatOpened(true);
      setTelemedicineActiveTab(tabPosition.THIRD);
      setAssetId(location.state.assetId);
      setIsReviewReport(location.state.isReportReview);
      setCaretaskReviewName(location.state.caretaskReviewName);
      navigateToCareplanAnswerView({
        id: location.state.careplanId,
        occurrenceId: location.state.occurrenceId,
      });
    }
  }, [location.state?.isReportReview]);
  const renderPatientActivity = () => (
    <div
      className="overview__patient-activity-patient-details"
      style={{
        padding: '20px 20px 0 20px',
        boxSizing: 'border-box',
        position: 'relative',
      }}
    >
      {location.state?.isReportReview ? (
        <iframe
          style={{
            width: '100%',
            height: '100%',
            zIndex: 2,
          }}
          src={reportReviewFileURL}
          className="img-obj__fit"
          frameBorder="0"
        />
      ) : (
        <>
          {componentDataCount && componentDataCount.length > 0 && (
            <div label={t('patientOverview.assignments')} tabId={0}>
              <AssignmentCardViewer
                patientId={patientId}
                componentDataCount={componentDataCount}
                setFlashMessage={setFlashMessage}
                isChatOpened={isChatOpened}
                setShowCareplanResults={setShowCareplanResults}
                patientInfo={patientInfo}
                answeredCaretask={answeredCaretask}
                setAnsweredCaretask={setAnsweredCaretask}
              />
            </div>
          )}

          <FloatButton
            rightOffset={isChatOpened ? CHAT_BOX_OFFSET : 65}
            menuItems={getAllowedServicesAmong(services, menuList)}
          />
        </>
      )}
    </div>
  );

  const renderResponsiveLayouts = () => {
    if (!isChatOpened) {
      return <>{renderPatientActivity()}</>;
    }

    if (
      windowWidth <= MEDIA_QUERIES.SCREEN_L &&
      windowWidth >= MEDIA_QUERIES.SCREEN_SM_IPAD
    ) {
      if (isChatOpened) {
        return renderPatientActivity();
      }

      return <>{renderPatientActivity()}</>;
    }

    if (windowWidth > MEDIA_QUERIES.SCREEN_L) {
      return <>{renderPatientActivity()}</>;
    }
  };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [flashMessage]);

  useEffect(() => {
    setModalContent({
      ...modalContent,
      title: `${t(careplanHeaderFooter.header)} ${patientInfo.firstname} ${
        patientInfo.lastname
      }`,
    });
  }, [careplanHeaderFooter.header]);

  const filterChatFromPatient = chatMessages => {
    if (patientInfo.id && chatMessages.length > 0) {
      const patientsChat = chatMessages.filter(
        chat => chat.patientId === patientInfo.id
      );
      if (patientsChat.length > 0) setChatNotified(patientsChat);
    }
  };

  return (
    <PatientOverviewContext.Provider
      value={{
        setRefreshCaretaskItemById,
        refreshCaretaskItemById,
        patientInfo,
        dispatchFormValidator,
        shouldValidateForm,
        currentStep,
        setCurrentStep,
        hasOneCareplanExpired,
        setHasOneCareplanExpired,
        setFlashMessage,
        closeModal,
        isModalOpen,
        dispatchFormSubmittor,
        shouldSubmitForm,
        setModalContent: openModalAndSetModalContent,
        setLandingTab,
        setMessagePrompt,
        closeMessagePrompt,
        isChatOpened,
        AddCareplanModalFooter,
        isTemplateSelected,
        setIsTemplateSelected,
        checkUserCredential,
        refreshCalendar,
        setRefreshCalendar,
        dataForExport,
        setDataForExport,
        showCareplanResults,
        setShowCareplanResults,
        showUnder18,
        setShowUnder18,
        careplanHeaderFooter,
        setCareplanHeaderFooter,
        navigateToCareplanAnswerView,
        usWarningCheckboxChecked,
        setUsWarningCheckboxChecked,
        bottomPageReached,
        setBottomPageReached,
        showUnder18Warning,
        setShowUnder18Warning,
        selectedCarepathway,
        setSelectedCarepathway,
        sendOutInmidiat,
        setSendOutInmidiat,
        landingTab,
        carePathwayHasThresholds,
        setcarePathwayHasThresholds,
        handleSelectedCarePathway,
        setCarePathway,
        carePathway,
        forceScroll,
        setForceScroll,
        setDoFetch,
        doFetch,
        devicesQuery,
        setDevicesQuery,
        shouldGetGraphViewData,
        setShouldGetGraphViewData,
        disableDownloadInvitation,
        setDisableDownloadInvitation,
        isAdhoc,
        setIsAdhoc,
        appointmentDate,
        setAppointmentDate,
        appointmentTime,
        setAppointmentTime,
        isUnspecified,
        setIsUnspecified,
        answerCaretask,
        setAnswerCaretask,
        careplanId,
        occurrenceId,
        isCaretaskDataLoading,
        setIsCaretaskDataLoading,
        setPatientSectionalModal,
        assetId,
        isReviewReport,
        caretaskReviewName,
        isFetchingModalContent,
        setIsFetchingModalContent,
        showQrCodeModal,
        showWithingsDeviceModal,
        onEditPatient,
        showPatientDetailsModal,
        renderExportAnswersModal,
        openSolutionPrompt,
        isPatientSyncNeeded,
        setIsPatientSyncNeeded,
        setActiveTabAndReload,
        CHAT_BOX_OFFSET,
      }}
    >
      <BreadcrumbPortal>
        <Breadcrumb>
          <Link to={`/${NavigationJSON.PATIENT}`}>{t('nav.patients')}</Link>
          <BreadcrumbPatientInfo
            patientInfo={patientInfo}
            patientStatus={patientStatus}
            language={I18n.language}
            isPatientDemographicData={isPatientDemographicData}
          />
        </Breadcrumb>
      </BreadcrumbPortal>

      {flashMessage.content && (
        <FlashMessage
          message={t(flashMessage.content)}
          type={flashMessage.type}
          onClick={hideFlashMessages}
        />
      )}
      {userHasPermission && (
        <Flex flexDirection="row" otherStyles={{ height: '100%' }}>
          <Flex
            id="patient-overview-content"
            otherStyles={{ flex: 1, position: 'relative', height: '100%' }}
          >
            {renderResponsiveLayouts()}
          </Flex>
          <TelemedicineSidebar
            telemedicineActiveTab={telemedicineActiveTab}
            setTelemedicineActiveTab={setTelemedicineActiveTab}
            closeSidebar={() => setIsChatOpened(!isChatOpened)}
            patientInfo={patientInfo}
            setFlashMessage={setFlashMessage}
            careTeamPrimaryInstitutions={careTeamPrimaryInstitutions}
            isPatientLoggedIn={
              patientStatus.translationKey !==
              userStatus.translationKey.unpaired
            }
            isChatNotified={chatNotified}
            reloadAssetTable={() => setReloadTable(!reloadTable)}
            setAllMessagesList={setAllMessagesList}
            reloadChat={reloadChat}
            setReloadChat={setReloadChat}
            isChatOpened={isChatOpened}
            answerCaretask={answerCaretask}
            setAnswerCaretask={setAnswerCaretask}
            patientId={patientId}
            careplanId={careplanId}
            setCareplanId={setCareplanId}
            occurrenceId={occurrenceId}
            setAnsweredCaretask={setAnsweredCaretask}
          />
        </Flex>
      )}
      {showUnder18Warning && (
        <div className="patient-index__us-warning-container">
          <div style={{ position: 'absolute', bottom: '25px' }}>
            <UsFilterBottomWarning
              message={t('warningMessage.underageWarning')}
            />
          </div>
        </div>
      )}
      {triggerSolutionPrompt && (
        <Modal
          isConfirmationDialog={triggerSolutionPrompt}
          title={t(promptElements?.title)}
          contentComponent={
            <PromptContent
              message={ReactHtmlParser(t(promptElements?.prompt_message))}
            />
          }
          footerComponent={
            <PromptFooter
              close={closePatientDetailsMessagePrompt}
              btnLabelRight={t(promptElements?.buttonCTA)}
              confirmHandler={promptElements?.handler}
            />
          }
          openModal={triggerSolutionPrompt}
          onClose={closePatientDetailsMessagePrompt}
          hideFlashMessage={HideFlashMessage}
        />
      )}
      <Modal
        title={modalContent.title}
        contentComponent={modalContent.content}
        footerComponent={modalContent.footer}
        openModal={isModalOpen}
        onClose={closeModal}
        headerComponent={modalContent.header}
        hideFlashMessage={HideFlashMessage}
      />
      <Modal
        isConfirmationDialog
        title={modalContent.title}
        contentComponent={modalContent.content}
        footerComponent={modalContent.footer}
        openModal={openPromptContent}
        onClose={closeMessagePrompt}
        hideFlashMessage={HideFlashMessage}
      />
      <PatientFormModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onUpdate={({ patientId, body }) => {
          setIsEditModalLoading(true);
          reduxDispatcher(updatePatientRequest(patientId, body));
        }}
        data={editModalData}
        operation="Edit"
        loading={isEditModalLoading}
      />
      <SectionalModal
        sectionId="patient-overview-content"
        title={patientSectionalModal.title}
        isOpen={patientSectionalModal.isOpen}
        footer={patientSectionalModal.footer}
        onClose={closePatientSectionalModal}
      >
        {patientSectionalModal.content}
      </SectionalModal>
    </PatientOverviewContext.Provider>
  );
};

export { PatientOverviewContext, PatientOverview };
