import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';

import InitLogger from './Global/Services/Logger/InitLogger';
import initAnalyticsInstance from './Global/Services/Analytics/InitAnalytics';

import App from './Views/App';

import storeInfo from '../store';
import config from '../config';
import './i18n';

InitLogger();

const API_KEY = config.googleMapApiKey;

function AppWithCallbackAfterRender() {
  useEffect(() => {
    const googleScript = document.createElement('script');
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
    googleScript.async = true;
    document.head.appendChild(googleScript);
    const jitsiScript = document.createElement('script');
    jitsiScript.async = true;
    jitsiScript.src = `https://meet.jit.si/external_api.js`;
    document.head.appendChild(jitsiScript);
  }, []);

  return (
    <Provider store={storeInfo.store}>
      <MatomoProvider value={initAnalyticsInstance}>
        <PersistGate loading={null} persistor={storeInfo.persistor}>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </PersistGate>
      </MatomoProvider>
    </Provider>
  );
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);
