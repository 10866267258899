import I18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { findDescendants } from 'react-sortly';
import {
  bloodPressureCategories,
  bmiCategories,
} from '@global/Careplan/Components/Utils/index';
import IsEmptyObject from '@ui-common-files/global/IsEmptyObject/IsEmptyObject';
import {
  FlashMessageTypes,
  HealthIndicatorStatus,
} from '@ui-common-files/types/components';
import {
  generateWeekdayArray,
  lifespanUnits,
  rruleFreqToRepeatTypeIndex,
} from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import { sanitizeInput } from '@ui-common-files/utils/sanitizeInput';
import { nonMedical } from '../../../config';
import { lastIndexOfObject } from '../../Global/Arrays';
import { ScoringTypes } from '../../types/Careplan';
import RoleHelperUtility from '../../Utils/RoleHelperUtility';
import componentTypeOrder from './careplanComponentTypeOrder';
import defaultEnums from './defaultEnums';
import CheckBoxRadioVariants from '@ui-common-files/utils/CheckboxRadio';

const isThresholdSet = (index, componentCollection, searchTermComponents) => {
  if (Object.keys(searchTermComponents).length > 0) {
    const shouldToggle =
      componentCollection[index].searchTerm.careplanComponentId &&
      (nonMedical === false || nonMedical === 'false')
        ? componentCollection[index].searchTerm.shouldThresholdToggle
        : componentCollection[index].shouldThresholdToggle;
    return shouldToggle;
  }
};

const getThresholdOptions = () => {
  return [
    {
      value: 'greaterThanEqual',
      label: I18n.t('threshold.greaterThanOrEqualTo'),
      key: 'threshold.greaterThanOrEqualTo',
    },
    {
      value: 'greaterThan',
      label: I18n.t('threshold.greaterThan'),
      key: 'threshold.greaterThan',
    },
    {
      value: 'lessThanEqual',
      label: I18n.t('threshold.lessThanOrEqualTo'),
      key: 'threshold.lessThanOrEqualTo',
    },
    {
      value: 'lessThan',
      label: I18n.t('threshold.lessThan'),
      key: 'threshold.lessThan',
    },
    {
      value: 'equalTo',
      label: I18n.t('threshold.equalTo'),
      key: 'threshold.equalTo',
    },
    {
      value: 'notEqual',
      label: I18n.t('threshold.notEqual'),
      key: 'threshold.notEqual',
    },
  ];
};

const handleDropDownState = (
  item,
  index,
  state,
  setThresholdDropdown,
  componentCollection,
  setComponentCollection
) => {
  item.thresholdCollection.thresholds[index].name = state;
  setComponentCollection([...componentCollection]);
};

const handleThresholdInput = (
  item,
  index,
  state,
  setThresholdNumber,
  componentCollection,
  setComponentCollection
) => {
  item.thresholdCollection.thresholds[index].value = state;
  setComponentCollection([...componentCollection]);
};

const addThreshold = (
  item,
  thresholdDropdown,
  thresholdNumber,
  componentCollection,
  setComponentCollection
) => {
  const obj = { name: thresholdDropdown, value: thresholdNumber };
  item.thresholdCollection.thresholds.push(obj);
  setComponentCollection([...componentCollection]);
};

const removeThreshold = (
  item,
  index,
  componentCollection,
  setComponentCollection
) => {
  const deletedThresholdId =
    item.thresholdCollection.thresholds[index].name.thresholdId;
  if (deletedThresholdId) {
    item.thresholdCollection.deleteThresholdIds.push(deletedThresholdId);
  }
  item.thresholdCollection.thresholds.splice(index, 1);
  setComponentCollection([...componentCollection]);
};

const mapThresholdConditionals = label => {
  let conditional;
  switch (label) {
    case 'greaterThanEqual':
      conditional = '>=';
      break;

    case 'greaterThan':
      conditional = '>';
      break;

    case 'lessThanEqual':
      conditional = '<=';
      break;

    case 'lessThan':
      conditional = '<';
      break;

    case 'equalTo':
      conditional = '==';
      break;

    case 'notEqual':
      conditional = '!=';
      break;

    default:
      conditional = '';
      break;
  }
  return conditional;
};

const getActionIconName = action => {
  let actionIconName;
  if (action == 'actions.delete') {
    actionIconName = 'delete';
  } else if (action == 'actions.update') {
    actionIconName = 'edit';
  } else if (action == 'actions.read') {
    actionIconName = 'read';
  } else if (action == 'actions.create') {
    actionIconName = 'create';
  } else if (action == 'actions.show') {
    actionIconName = 'view';
  } else if (action == 'actions.release') {
    actionIconName = 'release';
  } else if (action == 'actions.send') {
    actionIconName = 'send';
  } else if (action == 'actions.revoke') {
    actionIconName = 'revoke';
  } else if (action == 'actions.share') {
    actionIconName = 'share';
  }
  return actionIconName;
};
const getRadioButtonVariant = (healthIndicatorId: HealthIndicatorStatus) => {
  let radioButtonVariant: CheckBoxRadioVariants;

  switch (healthIndicatorId) {
    case HealthIndicatorStatus.Well:
      radioButtonVariant = CheckBoxRadioVariants.SuccessView;
      break;
    case HealthIndicatorStatus.Fair:
      radioButtonVariant = CheckBoxRadioVariants.WarningView;
      break;
    case HealthIndicatorStatus.Poor:
      radioButtonVariant = CheckBoxRadioVariants.Threshold;
      break;
    default:
      radioButtonVariant = CheckBoxRadioVariants.Default;
      break;
  }
  return radioButtonVariant;
};
const displayChoices = (thresholds, componentTypeId) => {
  let choice = '';
  thresholds.forEach((element, index) => {
    switch (componentTypeId) {
      case componentTypeOrder.SORTABLE:
      case componentTypeOrder.SINGLE_CHOICE:
      case componentTypeOrder.MULTIPLE_CHOICE:
        if (index == thresholds.length - 1) {
          choice += `${element.name}`;
        } else {
          choice += `${element.name}, `;
        }
        break;

      case componentTypeOrder.NUMERIC_RANGE:
      case componentTypeOrder.NUMERIC_VALUE:
        if (index == thresholds.length - 1) {
          choice += `${mapThresholdConditionals(element.name.value)} ${
            element.value
          }`;
        } else {
          choice += `${mapThresholdConditionals(element.name.value)} ${
            element.value
          }, `;
        }
        break;

      case componentTypeOrder.PAIN_LOCATION_CHART:
        if (index == thresholds.length - 1) {
          choice += `${I18n.t(element.name.key)}`;
        } else {
          choice += `${I18n.t(element.name.key)}, `;
        }
        break;

      case componentTypeOrder.DATEPICKER:
        if (index === thresholds.length - 1) {
          choice += `${I18n.t(element.name.key)}`;
        } else if (index === 0) {
          choice += `${I18n.t(element.name.key)} ${element.value} `;
        }
        break;

      default:
        choice = '';
        break;
    }
  });
  return choice;
};

const displayThreshold = item => {
  let selectedThresholds;
  const componentTypeId = item.collection.key;
  switch (componentTypeId) {
    case componentTypeOrder.FREE_TEXT:
      return;

    case componentTypeOrder.SINGLE_CHOICE:
    case componentTypeOrder.MULTIPLE_CHOICE:
      const selectedChoices = item.choiceCollection.choices.filter(elem => {
        return elem.isChecked == true;
      });
      return selectedChoices.length > 0 &&
        (nonMedical == false || nonMedical == 'false') ? (
        <div className="displayThresholdParentContainer">
          <span className="thresholdSpan">
            {displayChoices(selectedChoices, componentTypeId)}
          </span>
        </div>
      ) : (
        ''
      );

    case componentTypeOrder.NUMERIC_RANGE:
    case componentTypeOrder.NUMERIC_VALUE:
      selectedThresholds = item.thresholdCollection.thresholds.filter(elem => {
        return elem.name !== '';
      });
      return selectedThresholds.length > 0 &&
        (nonMedical == false || nonMedical == 'false') ? (
        <div className="displayThresholdParentContainer">
          <span className="thresholdSpan">
            {displayChoices(selectedThresholds, componentTypeId)}
          </span>
        </div>
      ) : (
        ''
      );

    case componentTypeOrder.YESNO:
    case componentTypeOrder.INFORMATION:
    case componentTypeOrder.GROUP_DESCRIPTION:
      return item.radioCollection.name &&
        (nonMedical == false || nonMedical == 'false') ? (
        <div className="displayThresholdParentContainer">
          <span className="thresholdSpan">
            {item.radioCollection.name.split('_')[0]}
          </span>
        </div>
      ) : (
        ''
      );

    case componentTypeOrder.PAIN_LOCATION_CHART:
      selectedThresholds = item.thresholdCollection.thresholds.filter(elem => {
        return elem.name !== '';
      });
      return selectedThresholds.length > 0 &&
        (nonMedical == false || nonMedical == 'false') ? (
        <div className="displayThresholdParentContainer">
          <span className="thresholdSpan">
            {displayChoices(selectedThresholds, componentTypeId)}
          </span>
        </div>
      ) : (
        ''
      );

    case componentTypeOrder.SORTABLE:
      selectedThresholds = item.sortableCollection.sortables.filter(elem => {
        return elem.isChecked == true;
      });
      return selectedThresholds.length > 0 &&
        (nonMedical == false || nonMedical == 'false') ? (
        <div className="displayThresholdParentContainer">
          <span className="thresholdSpan">
            {displayChoices(selectedThresholds, componentTypeId)}
          </span>
        </div>
      ) : (
        ''
      );

    case componentTypeOrder.DATEPICKER:
      selectedThresholds = item.thresholdCollection.thresholds.filter(elem => {
        return elem.name !== '';
      });
      return selectedThresholds.length > 0 &&
        (nonMedical == false || nonMedical == 'false') ? (
        <div className="displayThresholdParentContainer">
          <span className="thresholdSpan">
            {displayChoices(selectedThresholds, componentTypeId)}
          </span>
        </div>
      ) : (
        ''
      );

    case componentTypeOrder.BMI:
    case componentTypeOrder.BLOOD_PRESSURE:
      const choices = item.choiceCollection.choices.filter(elem => {
        return elem.isChecked == true;
      });
      return choices.length > 0 &&
        (nonMedical == false || nonMedical == 'false')
        ? choices.map(choice => {
            return (
              <div className="displayThresholdParentContainer">
                <span className="thresholdSpan">
                  {componentTypeId === componentTypeOrder.BMI
                    ? I18n.t(`bmiThresholdsOptions.${choice.name}`)
                    : I18n.t(`bloodPressureOptions.labels.${choice.name}`)}
                </span>
              </div>
            );
          })
        : '';
    case componentTypeOrder.UPLOAD_FILE:

    default:
  }
};
const getStandardValueProperties = component => {
  const standardValueMapper =
    component.careplanComponentStandardValueMappers?.[0];
  const standardValue = standardValueMapper?.standardValue;

  return {
    standardValueId: standardValue?.id,
    standardValue,
    shouldAnalyticsDataToggle: !!standardValue,
  };
};

const getIsMedia = isMedia => {
  let shouldMediaSettingsToggle = false;
  if (typeof isMedia === 'string') {
    if (isMedia == 'true') {
      shouldMediaSettingsToggle = true;
    } else {
      shouldMediaSettingsToggle = false;
    }
  } else if (isMedia == true) {
    shouldMediaSettingsToggle = true;
  } else {
    shouldMediaSettingsToggle = false;
  }
  return shouldMediaSettingsToggle;
};

const makeBasicSearchTerm = (component, icon, shouldThresholdToggle) => {
  return {
    careplanComponentId: component.id,
    value: component.id,
    label: component.name,
    nameHtml: component.nameHtml,
    optionJson: null,
    minimumValue: null,
    maximumValue: null,
    minLabel: null,
    maxLabel: null,
    decimal: null,
    negative: null,
    additional_configuration: component.additional_configuration,
    optionWeights: null,
    description: component.description,
    weight: component.weight,
    icon,
    shouldThresholdToggle,
    standardValueId: null,
    standardValue: null,
    shouldDescriptionToggle: !!component.description,
    shouldMediaSettingsToggle: getIsMedia(
      component.additional_configuration.isMedia
    ),
    shouldWeightToggle: !!component.weight,
    shouldAnalyticsDataToggle:
      !!component.careplanComponentStandardValueMappers[0]?.standardValue,
    localeKey: component.careplanComponentType.name,
    shouldIsMandatoryToggle:
      component.careplanComponentsTemplateMapper.isMandatory,
    isSyncAttachment:
      component.careplanComponentsTemplateMapper.isSyncAttachment,
  };
};

const searchTermForSingleMultipleChoiceAndSortable = (
  component,
  icon,
  shouldThresholdToggle
) => {
  const standardValueProps = getStandardValueProperties(component);
  let shouldWeightToggle = false;
  if (component.weight > 0) {
    shouldWeightToggle = true;
  }
  if (component.optionWeights) {
    if (component.optionWeights.total > 0) {
      shouldWeightToggle = true;
    }
  }
  return {
    careplanComponentId: component.id,
    value: component.id,
    label: component.name,
    nameHtml: component.nameHtml,
    optionJson: component.optionJson,
    minimumValue: null,
    maximumValue: null,
    minLabel: null,
    maxLabel: null,
    decimal: null,
    negative: null,
    additional_configuration: component.additional_configuration,
    optionWeights: component.optionWeights,
    description: component.description,
    weight: component.weight,
    icon,
    shouldThresholdToggle,
    ...standardValueProps,
    shouldDescriptionToggle: !!component.description,
    shouldMediaSettingsToggle: getIsMedia(
      component.additional_configuration.isMedia
    ),
    shouldWeightToggle,
    localeKey: component.careplanComponentType.name,
    shouldIsMandatoryToggle:
      component.careplanComponentsTemplateMapper.isMandatory,
    isSyncAttachment:
      component.careplanComponentsTemplateMapper.isSyncAttachment,
  };
};

const fetchComponentThresholds = (component, careplanTemplateThresholds) => {
  const careplanComponentsTemplateMapperId =
    component.careplanComponentsTemplateMapper.id;
  const filteredThreshold = careplanTemplateThresholds.filter(
    (threshold, index) => {
      return (
        threshold[0].careplanComponentsTemplateMapperId ===
        careplanComponentsTemplateMapperId
      );
    }
  );
  return filteredThreshold;
};

const makeChoicesArrayForSingleMultipleChoiceAndSortable = (
  component,
  shouldThresholdToggle,
  careplanTemplateThresholds
) => {
  const thresholds = shouldThresholdToggle
    ? fetchComponentThresholds(component, careplanTemplateThresholds)
    : [];
  const options =
    typeof component.optionJson === 'string'
      ? JSON.parse(component.optionJson).options
      : component.optionJson.options;
  return options.map((option, index) => {
    const choice = { name: option, isChecked: false };
    switch (component.careplanComponentType.careplanComponentTypeOrder) {
      case componentTypeOrder.SINGLE_CHOICE:
      case componentTypeOrder.MULTIPLE_CHOICE:
      case componentTypeOrder.BMI:
      case componentTypeOrder.BLOOD_PRESSURE:
      case componentTypeOrder.SORTABLE:
        if (shouldThresholdToggle) {
          thresholds[0][1][0].includes(option)
            ? (choice.isChecked = true)
            : (choice.isChecked = false);
        }
        break;
      default:
        break;
    }
    return choice;
  });
};

const searchTermForNumericRangeAndNumericValue = (
  component,
  icon,
  shouldThresholdToggle
) => {
  const standardValueProps = getStandardValueProperties(component);
  return {
    careplanComponentId: component.id,
    value: component.id,
    label: component.name,
    nameHtml: component.nameHtml,
    optionJson: null,
    minimumValue: component.minimumValue,
    maximumValue: component.maximumValue,
    validationMessage: '',
    minLabel: icon == 'numericRange' ? component.minLabel : null,
    maxLabel: icon == 'numericRange' ? component.maxLabel : null,
    isVerticalSlider:
      icon == 'numericRange' ? component.isVerticalSlider : false,
    decimal: icon == 'numericRange' ? null : component.decimal,
    negative: icon == 'numericRange' ? null : component.negative,
    additional_configuration: component.additional_configuration,
    optionWeights: null,
    description: component.description,
    weight: component.weight,
    icon,
    shouldThresholdToggle,
    shouldDescriptionToggle: !!component.description,
    shouldMediaSettingsToggle: getIsMedia(
      component.additional_configuration.isMedia
    ),
    ...standardValueProps,
    shouldWeightToggle: component.isScoreEnabled,
    localeKey: component.careplanComponentType.name,
    shouldIsMandatoryToggle:
      component.careplanComponentsTemplateMapper.isMandatory,
    isSyncAttachment:
      component.careplanComponentsTemplateMapper.isSyncAttachment,
  };
};

const checkIfThresholdsExistOrNot = (
  careplanComponentsTemplateMapperId,
  careplanTemplateThresholds
) => {
  const filteredThreshold = careplanTemplateThresholds.filter(
    (threshold, index) => {
      return (
        threshold[0].careplanComponentsTemplateMapperId ===
        careplanComponentsTemplateMapperId
      );
    }
  );
  if (filteredThreshold[0][1].length > 0) {
    return true;
  }
  return false;
};

const getJumpData = (component, careplanTemplateJumps) => {
  const jumpCondition = [];
  const componentMapperId =
    component.careplanCareplanComponentMapper &&
    component.careplanCareplanComponentMapper.id;
  const templateMapperId =
    component.careplanComponentsTemplateMapper &&
    component.careplanComponentsTemplateMapper.id;
  const filteredJumps =
    careplanTemplateJumps[componentMapperId || templateMapperId];

  if (filteredJumps && filteredJumps.length > 0) {
    if (
      component.careplanComponentTypeId === componentTypeOrder.NUMERIC_VALUE ||
      component.careplanComponentTypeId === componentTypeOrder.NUMERIC_RANGE ||
      component.careplanComponentTypeId === componentTypeOrder.INFORMATION
    ) {
      filteredJumps.map(jumps => {
        const templateMapperIdFound = careplanComponentsTemplateMapperIdExist(
          component.careplanComponentsTemplateMapper,
          jumps.careplanComponentsTemplateMapperId
        );
        const componentMapperIdFound = careplanCareplanComponentMapperIdExist(
          component.careplanCareplanComponentMapper,
          jumps.careplanCareplanComponentMapperId
        );
        if (templateMapperIdFound || componentMapperIdFound) {
          jumpCondition.push({
            ...jumps.condition,
            destination: jumps.condition.destination + 1,
          });
        }
      });
      return {
        isJumpSet: true,
        condition: jumpCondition,
      };
    }

    if (
      component.careplanComponentTypeId === componentTypeOrder.SINGLE_CHOICE ||
      component.careplanComponentTypeId ===
        componentTypeOrder.MULTIPLE_CHOICE ||
      component.careplanComponentTypeId === componentTypeOrder.BLOOD_PRESSURE
    ) {
      filteredJumps.map((jumps, i) => {
        const templateMapperIdFound = careplanComponentsTemplateMapperIdExist(
          component.careplanComponentsTemplateMapper,
          jumps.careplanComponentsTemplateMapperId
        );
        const componentMapperIdFound = careplanCareplanComponentMapperIdExist(
          component.careplanCareplanComponentMapper,
          jumps.careplanCareplanComponentMapperId
        );
        const optionIndex = parseInt(filteredJumps[i].index);
        const jumpData = {
          options: [component.optionJson.options[jumps.index]],
          destination: jumps.destination + 1,
          index: jumps.index,
          condition: jumps.condition,
        };
        if (
          jumps.careplanComponentId === component.id &&
          (templateMapperIdFound || componentMapperIdFound)
        ) {
          jumpCondition[optionIndex] = jumpData;
        }
      });
      return {
        isJumpSet: true,
        condition: jumpCondition,
      };
    }
  }
  return { isJumpSet: false, condition: filteredJumps };
};

function careplanCareplanComponentMapperIdExist(
  careplanCareplanComponentMapper,
  jumpCareplanCareplanComponentMapperId
) {
  return (
    careplanCareplanComponentMapper &&
    jumpCareplanCareplanComponentMapperId === careplanCareplanComponentMapper.id
  );
}

function careplanComponentsTemplateMapperIdExist(
  careplanComponentsTemplateMapper,
  jumpCareplanComponentsTemplateMapperId
) {
  return (
    careplanComponentsTemplateMapper &&
    jumpCareplanComponentsTemplateMapperId ===
      careplanComponentsTemplateMapper.id
  );
}

const checkComponentScore = (component, isEvaluating) => {
  if (
    isEvaluating &&
    (component.careplanComponentTypeId === componentTypeOrder.SINGLE_CHOICE ||
      component.careplanComponentTypeId ===
        componentTypeOrder.MULTIPLE_CHOICE ||
      component.careplanComponentTypeId === componentTypeOrder.SORTABLE) &&
    JSON.parse(component.optionWeights) &&
    JSON.parse(component.optionWeights).total > 0
  ) {
    return true;
  }
  if (isEvaluating && component.weight > 0) {
    return true;
  }

  return false;
};

const getSelectedThresholdChoiceForSingleChoice = (
  component,
  careplanTemplateThresholds
) => {
  const careplanComponentsTemplateMapperId =
    component.careplanComponentsTemplateMapper.id;
  const { optionJson } = component;
  const filteredThreshold = careplanTemplateThresholds.filter(
    (threshold, index) => {
      return (
        threshold[0].careplanComponentsTemplateMapperId ===
        careplanComponentsTemplateMapperId
      );
    }
  );
  return {
    actualThreshold: filteredThreshold[0][1][0],
    index: optionJson.options.indexOf(filteredThreshold[0][1][0]),
  };
};

const prefillComponentThresholdsForSingleChoice = (
  component,
  careplanTemplateThresholds
) => {
  const selectedChoice = { name: '', isChecked: false, index: '' };
  const { careplanComponentTypeOrder } = component.careplanComponentType;
  switch (careplanComponentTypeOrder) {
    case componentTypeOrder.SINGLE_CHOICE:
      const thresholdObject = getSelectedThresholdChoiceForSingleChoice(
        component,
        careplanTemplateThresholds
      );
      selectedChoice.name = thresholdObject.actualThreshold;
      selectedChoice.isChecked = true;
      selectedChoice.index = thresholdObject.index;
      break;
    default:
      break;
  }
  return selectedChoice;
};

const getSelectedThresholdChoiceForYesNoToDoAndGroupDescription = (
  careplanComponentsTemplateMapperId,
  careplanTemplateThresholds,
  index
) => {
  const filteredThreshold = careplanTemplateThresholds.filter(
    (threshold, index) => {
      return (
        threshold[0].careplanComponentsTemplateMapperId ===
        careplanComponentsTemplateMapperId
      );
    }
  );
  return { name: `${filteredThreshold[0][1][0]}_${index}`, isChecked: true };
};

const prefillThresholdsForYesNoToDoAndGroupDescription = (
  component,
  careplanTemplateThresholds,
  index
) => {
  let radioCollection;
  const { careplanComponentTypeOrder } = component.careplanComponentType;
  switch (careplanComponentTypeOrder) {
    case componentTypeOrder.YESNO:
    case componentTypeOrder.INFORMATION:
    case componentTypeOrder.GROUP_DESCRIPTION:
      radioCollection =
        getSelectedThresholdChoiceForYesNoToDoAndGroupDescription(
          component.careplanComponentsTemplateMapper.id,
          careplanTemplateThresholds,
          index
        );
      break;
    default:
      break;
  }
  return radioCollection;
};

const getThresholdCollectionForNVAndNR = (
  careplanComponentsTemplateMapperId,
  careplanTemplateThresholds
) => {
  const thresholdCollection = {};
  const filteredThresholds = careplanTemplateThresholds.filter(
    (threshold, index) => {
      return (
        threshold[0].careplanComponentsTemplateMapperId ===
        careplanComponentsTemplateMapperId
      );
    }
  );

  const preparedThresholds = filteredThresholds.map((thresholds, index) => {
    return thresholds[1].map((threshold, index) => {
      let condition = {};
      const t = {};
      switch (threshold.comparison) {
        case '>=':
          condition = {
            value: 'greaterThanEqual',
            label: I18n.t('threshold.greaterThanOrEqualTo'),
            key: 'threshold.greaterThanOrEqualTo',
          };
          break;

        case '>':
          condition = {
            value: 'greaterThan',
            label: I18n.t('threshold.greaterThan'),
            key: 'threshold.greaterThan',
          };
          break;

        case '<=':
          condition = {
            value: 'lessThanEqual',
            label: I18n.t('threshold.lessThanOrEqualTo'),
            key: 'threshold.lessThanOrEqualTo',
          };
          break;

        case '<':
          condition = {
            value: 'lessThan',
            label: I18n.t('threshold.lessThan'),
            key: 'threshold.lessThan',
          };
          break;

        case '==':
          condition = {
            value: 'equalTo',
            label: I18n.t('threshold.equalTo'),
            key: 'threshold.equalTo',
          };
          break;

        case '!=':
          condition = {
            value: 'notEqual',
            label: I18n.t('threshold.notEqual'),
            key: 'threshold.notEqual',
          };
          break;
      }

      condition.thresholdId = threshold.thresholdId;
      t.name = condition;
      t.value = threshold.numericValue;
      return t;
    });
  });
  thresholdCollection.thresholds = preparedThresholds[0];
  thresholdCollection.currentThreshold = {
    name: '',
    value: '',
    thresholdId: '',
  };
  thresholdCollection.deleteThresholdIds = [];
  return thresholdCollection;
};

const prepareThresholdsForNVAndNR = (component, careplanTemplateThresholds) => {
  let thresholdCollection;
  const { careplanComponentTypeOrder } = component.careplanComponentType;
  switch (careplanComponentTypeOrder) {
    case componentTypeOrder.NUMERIC_RANGE:
    case componentTypeOrder.NUMERIC_VALUE:
      thresholdCollection = getThresholdCollectionForNVAndNR(
        component.careplanComponentsTemplateMapper.id,
        careplanTemplateThresholds
      );
      break;
    default:
      break;
  }
  return thresholdCollection;
};

const getThresholdCollectionForPainLocationChart = (
  careplanComponentsTemplateMapperId,
  careplanTemplateThresholds
) => {
  const thresholdCollection = {};
  const filteredThresholds = careplanTemplateThresholds.filter(
    (threshold, index) => {
      return (
        threshold[0].careplanComponentsTemplateMapperId ===
        careplanComponentsTemplateMapperId
      );
    }
  );
  const preparedThresholds = filteredThresholds.map((thresholds, index) => {
    return thresholds[1].map((threshold, index) => {
      let condition = {};
      const t = {};
      switch (threshold.key) {
        case 'back':
          condition = {
            value: 'back',
            label: I18n.t('bodyChartImage.back'),
            key: 'bodyChartImage.back',
          };
          break;

        case 'head':
          condition = {
            value: 'head',
            label: I18n.t('bodyChartImage.head'),
            key: 'bodyChartImage.head',
          };
          break;

        case 'occiput':
          condition = {
            value: 'occiput',
            label: I18n.t('bodyChartImage.occiput'),
            key: 'bodyChartImage.occiput',
          };
          break;

        case 'shoulder':
          condition = {
            value: 'shoulder',
            label: I18n.t('bodyChartImage.shoulder'),
            key: 'bodyChartImage.shoulder',
          };
          break;

        case 'leftChest':
          condition = {
            value: 'leftChest',
            label: I18n.t('bodyChartImage.leftChest'),
            key: 'bodyChartImage.leftChest',
          };
          break;

        case 'rightChest':
          condition = {
            value: 'rightChest',
            label: I18n.t('bodyChartImage.rightChest'),
            key: 'bodyChartImage.rightChest',
          };
          break;

        case 'stomach':
          condition = {
            value: 'stomach',
            label: I18n.t('bodyChartImage.stomach'),
            key: 'bodyChartImage.stomach',
          };
          break;

        case 'alvus':
          condition = {
            value: 'alvus',
            label: I18n.t('bodyChartImage.alvus'),
            key: 'bodyChartImage.alvus',
          };
          break;

        case 'fundament':
          condition = {
            value: 'fundament',
            label: I18n.t('bodyChartImage.fundament'),
            key: 'bodyChartImage.fundament',
          };
          break;

        case 'leftUpperArm':
          condition = {
            value: 'leftUpperArm',
            label: I18n.t('bodyChartImage.leftUpperArm'),
            key: 'bodyChartImage.leftUpperArm',
          };
          break;

        case 'leftLowerArm':
          condition = {
            value: 'leftLowerArm',
            label: I18n.t('bodyChartImage.leftLowerArm'),
            key: 'bodyChartImage.leftLowerArm',
          };
          break;

        case 'rightUpperArm':
          condition = {
            value: 'rightUpperArm',
            label: I18n.t('bodyChartImage.rightUpperArm'),
            key: 'bodyChartImage.rightUpperArm',
          };
          break;

        case 'rightLowerArm':
          condition = {
            value: 'rightLowerArm',
            label: I18n.t('bodyChartImage.rightLowerArm'),
            key: 'bodyChartImage.rightLowerArm',
          };
          break;

        case 'leftLeg':
          condition = {
            value: 'leftLeg',
            label: I18n.t('bodyChartImage.leftLeg'),
            key: 'bodyChartImage.leftLeg',
          };
          break;

        case 'rightLeg':
          condition = {
            value: 'rightLeg',
            label: I18n.t('bodyChartImage.rightLeg'),
            key: 'bodyChartImage.rightLeg',
          };
          break;

        case 'neck':
          condition = {
            value: 'neck',
            label: I18n.t('bodyChartImage.neck'),
            key: 'bodyChartImage.neck',
          };
          break;

        case 'throat':
          condition = {
            value: 'throat',
            label: I18n.t('bodyChartImage.throat'),
            key: 'bodyChartImage.throat',
          };
          break;

        case 'genital':
          condition = {
            value: 'genital',
            label: I18n.t('bodyChartImage.genital'),
            key: 'bodyChartImage.genital',
          };
          break;
      }
      condition.thresholdId = threshold.thresholdId;
      t.name = condition;
      return t;
    });
  });
  thresholdCollection.thresholds = preparedThresholds[0];
  thresholdCollection.currentThreshold = { name: '', value: '' };
  thresholdCollection.deleteThresholdIds = [];
  return thresholdCollection;
};

const prefillThresholdsForPainLocationChart = (
  component,
  careplanTemplateThresholds
) => {
  let thresholdCollection;
  const { careplanComponentTypeOrder } = component.careplanComponentType;
  switch (careplanComponentTypeOrder) {
    case componentTypeOrder.PAIN_LOCATION_CHART:
      thresholdCollection = getThresholdCollectionForPainLocationChart(
        component.careplanComponentsTemplateMapper.id,
        careplanTemplateThresholds
      );
      break;
    default:
      break;
  }
  return thresholdCollection;
};

const getThresholdCollectionForDatepicker = (
  careplanComponentsTemplateMapperId,
  careplanTemplateThresholds
) => {
  const thresholdCollection = {};
  const filteredThresholds = careplanTemplateThresholds.filter(
    (threshold, index) => {
      return (
        threshold[0].careplanComponentsTemplateMapperId ===
        careplanComponentsTemplateMapperId
      );
    }
  );

  const preparedThresholds = filteredThresholds.map((thresholds, index) => {
    return thresholds[1].map((threshold, index) => {
      let condition = {};
      const t = {};

      switch (threshold.comparison) {
        case 'more':
          condition = {
            value: 'more',
            label: I18n.t('common_labels.label_more'),
            key: 'common_labels.label_more',
          };
          break;

        case 'less':
          condition = {
            value: 'less',
            label: I18n.t('common_labels.label_less'),
            key: 'common_labels.label_less',
          };
          break;

        default:
          break;
      }

      condition.thresholdId = threshold.thresholdId;
      t.name = condition;
      t.value = threshold.numericValue;
      return t;
    });
  });

  const preparedThresholdsUnits = filteredThresholds.map(
    (thresholds, index) => {
      return thresholds[1].map((threshold, index) => {
        let unit = {};
        const t = {};

        switch (threshold.unit) {
          case 'day(s)':
            unit = {
              value: 'day(s)',
              label: I18n.t('lifespan.DAY'),
              key: 'lifespan.DAY',
            };
            break;

          case 'week(s)':
            unit = {
              value: 'week(s)',
              label: I18n.t('lifespan.WEEK'),
              key: 'lifespan.WEEK',
            };
            break;

          case 'month(s)':
            unit = {
              value: 'month(s)',
              label: I18n.t('lifespan.MONTH'),
              key: 'lifespan.MONTH',
            };
            break;

          case 'year(s)':
            unit = {
              value: 'year(s)',
              label: I18n.t('lifespan.YEAR'),
              key: 'lifespan.YEAR',
            };
            break;

          default:
            break;
        }

        t.name = unit;
        t.value = threshold.numericValue;
        return t;
      });
    }
  );

  thresholdCollection.thresholds = [
    ...preparedThresholds[0],
    ...preparedThresholdsUnits[0],
  ];
  thresholdCollection.currentThreshold = {
    name: '',
    value: '',
    thresholdId: '',
  };
  return thresholdCollection;
};

const prefillThresholdsForDatepicker = (
  component,
  careplanTemplateThresholds
) => {
  let thresholdCollection;
  const { careplanComponentTypeOrder } = component.careplanComponentType;
  switch (careplanComponentTypeOrder) {
    case componentTypeOrder.DATEPICKER:
      thresholdCollection = getThresholdCollectionForDatepicker(
        component.careplanComponentsTemplateMapper.id,
        careplanTemplateThresholds
      );
      break;
    default:
      break;
  }
  return thresholdCollection;
};

const getComponentIcon = careplanComponentTypeOrder => {
  switch (careplanComponentTypeOrder) {
    case componentTypeOrder.FREE_TEXT:
      return 'freetext';
    case componentTypeOrder.SINGLE_CHOICE:
      return 'singleChoices';
    case componentTypeOrder.MULTIPLE_CHOICE:
      return 'multipleChoices';
    case componentTypeOrder.BMI:
      return 'bmi';
    case componentTypeOrder.BLOOD_PRESSURE:
      return 'BloodPressure';
    case componentTypeOrder.NUMERIC_RANGE:
      return 'numericRange';
    case componentTypeOrder.NUMERIC_VALUE:
      return 'numericValue';
    case componentTypeOrder.YESNO:
      return 'yesOrNo';
    case componentTypeOrder.PAIN_LOCATION_CHART:
      return 'painLocationChart';
    case componentTypeOrder.SORTABLE:
      return 'ranking';
    case componentTypeOrder.INFORMATION:
      return 'todos';
    case componentTypeOrder.GROUP_DESCRIPTION:
      return 'group';
    case componentTypeOrder.DATEPICKER:
      return 'datepicker';
    case componentTypeOrder.UPLOAD_FILE:
      return 'uploadFile';
  }
};

const fillCareplanTemplateComponents = template => {
  const careplanTemplateComponents = template.careplanComponents;
  const careplanTemplateThresholds =
    template.careplanComponentsWithThresholds || [];
  const careplanTemplateJumps =
    template.careplanCareplanComponentsWithLogicJumps || [];
  let componentData;
  let thresholdId;
  const commonAttributes = {
    id: 0,
    collection: {},
    shouldShowContent: false,
    shouldThresholdToggle: false,
    shouldDescriptionToggle: false,
    shouldIsMandatoryToggle: false,
    description: '',
    shouldMediaSettingsToggle: false,
    additional_configuration: {
      isMedia: false,
      mediaType: '',
      mediaURL: '',
      evaluationLine: null,
    },
    shouldWeightToggle: false,
    shouldAnalyticsDataToggle: false,
    standardValueId: null,
    weight: 0,
    searchTerm: {},
    textValue: '',
    shouldLogicJumpToggle: false,
  };

  return careplanTemplateComponents.map((component, index) => {
    const componentDepth = component.careplanComponentsTemplateMapper.depth;
    const { asset } = component;
    let LogicJumpData = { isJumpSet: false, condition: [] };
    const standardValueProps = getStandardValueProperties(component);
    const { careplanComponentTypeOrder } = component.careplanComponentType;
    const collection = {
      key: careplanComponentTypeOrder,
      position: careplanComponentTypeOrder,
      name: component.careplanComponentType.name,
      label: component.careplanComponentType.name,
      value: component.careplanComponentType.name,
      associatedWithCareplanTemplate: true,
      icon: getComponentIcon(careplanComponentTypeOrder),
    };
    const shouldThresholdToggle = checkIfThresholdsExistOrNot(
      component.careplanComponentsTemplateMapper.id,
      careplanTemplateThresholds
    );

    if (shouldThresholdToggle) {
      const thresholdObject = fetchComponentThresholds(
        component,
        careplanTemplateThresholds
      );
      thresholdId = thresholdObject[0][0].thresholdId;
    }
    if (Object.keys(careplanTemplateJumps).length > 0) {
      LogicJumpData = getJumpData(component, careplanTemplateJumps);
    }
    commonAttributes.healthCategory =
      component.healthCategory || componentHealthCategories.MAIN_QUESTION;

    switch (careplanComponentTypeOrder) {
      case componentTypeOrder.FREE_TEXT:
        componentData = {
          ...commonAttributes,
          id: index,
          depth: componentDepth,
          collection,
          searchTerm: makeBasicSearchTerm(
            component,
            'freetext',
            shouldThresholdToggle
          ),
          nameHtml: component.nameHtml,
          description: component.description,
          ...standardValueProps,
          asset,
        };
        break;
      case componentTypeOrder.SINGLE_CHOICE:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: searchTermForSingleMultipleChoiceAndSortable(
            component,
            'choices',
            shouldThresholdToggle
          ),
          ...standardValueProps,
          nameHtml: component.nameHtml,
          description: component.description,
          choiceCollection: {
            addChoice: '',
            choices: makeChoicesArrayForSingleMultipleChoiceAndSortable(
              component,
              shouldThresholdToggle,
              careplanTemplateThresholds
            ),
            optionWeights: {
              total: component.optionWeights
                ? component.optionWeights.total
                : 0,
              weights: component.optionWeights
                ? component.optionWeights.weights
                : [],
            },
            selectedChoice: shouldThresholdToggle
              ? prefillComponentThresholdsForSingleChoice(
                  component,
                  careplanTemplateThresholds
                )
              : {
                  name: '',
                  isChecked: false,
                  index: '',
                },
          },
          thresholdId: shouldThresholdToggle ? thresholdId : '',
          shouldLogicJumpToggle: LogicJumpData.isJumpSet,
          logicJumpCollection: LogicJumpData.condition,
        };
        break;
      case componentTypeOrder.MULTIPLE_CHOICE:
      case componentTypeOrder.BMI:
      case componentTypeOrder.BLOOD_PRESSURE:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: searchTermForSingleMultipleChoiceAndSortable(
            component,
            'choices',
            shouldThresholdToggle
          ),
          ...standardValueProps,
          nameHtml: component.nameHtml,
          description: component.description,
          choiceCollection: {
            addChoice: '',
            choices: makeChoicesArrayForSingleMultipleChoiceAndSortable(
              component,
              shouldThresholdToggle,
              careplanTemplateThresholds
            ),
            optionWeights: {
              total: component.optionWeights
                ? component.optionWeights.total
                : 0,
              weights: component.optionWeights
                ? component.optionWeights.weights
                : [],
            },
            selectedChoice: shouldThresholdToggle
              ? prefillComponentThresholdsForSingleChoice(
                  component,
                  careplanTemplateThresholds
                )
              : {
                  name: '',
                  isChecked: false,
                  index: '',
                },
          },
          thresholdId: shouldThresholdToggle ? thresholdId : '',
          shouldLogicJumpToggle: LogicJumpData.isJumpSet,
          logicJumpCollection: LogicJumpData.condition,
        };
        break;
      case componentTypeOrder.NUMERIC_RANGE:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: searchTermForNumericRangeAndNumericValue(
            component,
            'numericRange',
            shouldThresholdToggle
          ),
          nameHtml: component.nameHtml,
          description: component.description,
          minimumValue: 1,
          maximumValue: 1,
          minLabel: '',
          maxLabel: '',
          ...standardValueProps,
          isVerticalSlider: false,
          thresholdCollection: shouldThresholdToggle
            ? prepareThresholdsForNVAndNR(component, careplanTemplateThresholds)
            : {
                thresholds: [],
                currentThreshold: {
                  name: '',
                  value: '',
                },
                deleteThresholdIds: [],
              },
          shouldLogicJumpToggle: LogicJumpData.isJumpSet,
          logicJumpCollection: LogicJumpData.condition,
        };
        break;
      case componentTypeOrder.NUMERIC_VALUE:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: searchTermForNumericRangeAndNumericValue(
            component,
            'numericValue',
            shouldThresholdToggle
          ),
          nameHtml: component.nameHtml,
          description: component.description,
          ...standardValueProps,
          minimumValue: 1,
          maximumValue: 1,
          decimal: false,
          negative: false,
          thresholdCollection: shouldThresholdToggle
            ? prepareThresholdsForNVAndNR(component, careplanTemplateThresholds)
            : {
                thresholds: [],
                currentThreshold: {
                  name: '',
                  value: '',
                },
                deleteThresholdIds: [],
              },
          shouldLogicJumpToggle: LogicJumpData.isJumpSet,
          logicJumpCollection: LogicJumpData.condition,
        };
        break;
      case componentTypeOrder.YESNO:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: makeBasicSearchTerm(
            component,
            'yesOrNo',
            shouldThresholdToggle
          ),
          ...standardValueProps,
          nameHtml: component.nameHtml,
          description: component.description,
          ...standardValueProps,
          radioCollection: shouldThresholdToggle
            ? prefillThresholdsForYesNoToDoAndGroupDescription(
                component,
                careplanTemplateThresholds,
                index
              )
            : {
                name: '',
                isChecked: false,
              },
          thresholdId: shouldThresholdToggle ? thresholdId : '',
        };
        break;
      case componentTypeOrder.PAIN_LOCATION_CHART:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: makeBasicSearchTerm(
            component,
            'painLocationChart',
            shouldThresholdToggle
          ),
          nameHtml: component.nameHtml,
          ...standardValueProps,
          description: component.description,
          thresholdCollection: shouldThresholdToggle
            ? prefillThresholdsForPainLocationChart(
                component,
                careplanTemplateThresholds
              )
            : {
                thresholds: [],
                currentThreshold: {
                  name: '',
                  value: '',
                },
                deleteThresholdIds: [],
              },
        };
        break;
      case componentTypeOrder.SORTABLE:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: searchTermForSingleMultipleChoiceAndSortable(
            component,
            'ranking',
            shouldThresholdToggle
          ),
          nameHtml: component.nameHtml,
          description: component.description,
          sortableCollection: {
            addSortable: '',
            sortables: makeChoicesArrayForSingleMultipleChoiceAndSortable(
              component,
              shouldThresholdToggle,
              careplanTemplateThresholds
            ),
            optionWeights: {
              total: component.optionWeights
                ? component.optionWeights.total
                : 0,
              weights: component.optionWeights
                ? component.optionWeights.weights
                : [],
            },
            selectedSortable: {
              name: '',
              isChecked: false,
            },
          },
          thresholdId: shouldThresholdToggle ? thresholdId : '',
        };
        break;
      case componentTypeOrder.INFORMATION:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: makeBasicSearchTerm(
            component,
            'todos',
            shouldThresholdToggle
          ),
          nameHtml: component.nameHtml,
          descriptionEditor: component.description,
          radioCollection: shouldThresholdToggle
            ? prefillThresholdsForYesNoToDoAndGroupDescription(
                component,
                careplanTemplateThresholds,
                index
              )
            : {
                name: '',
                isChecked: false,
              },
          thresholdId: shouldThresholdToggle ? thresholdId : '',
          shouldLogicJumpToggle: LogicJumpData.isJumpSet,
          logicJumpCollection: LogicJumpData.condition,
        };
        break;
      case componentTypeOrder.GROUP_DESCRIPTION:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: makeBasicSearchTerm(
            component,
            'group',
            shouldThresholdToggle
          ),
          nameHtml: component.nameHtml,
          description: component.description,
          radioCollection: shouldThresholdToggle
            ? prefillThresholdsForYesNoToDoAndGroupDescription(
                component,
                careplanTemplateThresholds,
                index
              )
            : {
                name: '',
                isChecked: false,
              },
          thresholdId: shouldThresholdToggle ? thresholdId : '',
        };
        break;

      case componentTypeOrder.DATEPICKER:
        componentData = {
          ...commonAttributes,
          id: index,
          asset,
          depth: componentDepth,
          collection,
          searchTerm: makeBasicSearchTerm(
            component,
            'datepicker',
            shouldThresholdToggle
          ),
          nameHtml: component.nameHtml,
          description: component.description,
          ...standardValueProps,
          thresholdId: shouldThresholdToggle ? thresholdId : '',
          thresholdCollection: shouldThresholdToggle
            ? prefillThresholdsForDatepicker(
                component,
                careplanTemplateThresholds
              )
            : {
                thresholds: [],
                currentThreshold: {
                  name: '',
                  value: '',
                },
              },
        };
        break;
      case componentTypeOrder.UPLOAD_FILE:
        componentData = {
          ...commonAttributes,
          id: index,
          depth: componentDepth,
          collection,
          searchTerm: makeBasicSearchTerm(
            component,
            'uploadfile',
            shouldThresholdToggle
          ),
          nameHtml: component.nameHtml,
          description: component.description,
          asset,
        };
        break;
    }

    return componentData;
  });
};

const prepareSearchTerm = careplanTemplateResponse => {
  const { template } = careplanTemplateResponse;
  return {
    id: template.id,
    name: template.name,
    value: template.id,
    label: template.name,
    tags: template.tags,
    questions: template.careplanComponents.length,
    patientsAssigned: 0,
    careplanComponents: template.careplanComponents,
    icds: template.icds,
    rruleRecurrence: careplanTemplateResponse.rruleRecurrence,
    carePathwayTemplates: template.tags,
    phaseType: template.phaseType,
    isReportEnabled: template.isReportEnabled,
    reportLanguage: template.reportLanguage,
    reportOccurenceNumber: template.reportOccurenceNumber,
  };
};

const getOptions = optionValues => {
  return optionValues.map(elem => {
    return elem.name;
  });
};

const getStartDate = recurrenceData => {
  return moment(recurrenceData.startDate).format('YYYY-MM-DD');
};

const getStartTime = recurrenceData => {
  let { minute } = recurrenceData.customTime;
  if (minute == 0) {
    minute += '0';
  }
  return `${recurrenceData.customTime.hour}:${minute}`;
};

const getEndDate = recurrenceData => {
  return moment(recurrenceData.endDate).format('YYYY-MM-DD');
};

const getTemplateName = searchTerm => {
  return searchTerm.label || searchTerm.name;
};

const getIcds = allIcds => {
  return allIcds.map(icd => {
    return icd.id;
  });
};

const getPhaseType = selectedPhaseType => {
  return selectedPhaseType.id;
};

const getCarepathwayTemplates = (searchTerm, carepathwayTemplates) => {
  const pathways = searchTerm.tags || carepathwayTemplates;
  return pathways.map(pathway => {
    return typeof pathway.value === 'number'
      ? { carePathwayId: pathway.value }
      : { carePathwayName: pathway.value };
  });
};

const getCareplanTemplateOrganization = (
  searchTerm,
  careplanTemplateOrganizations
) => {
  const organizations =
    searchTerm.organizations || careplanTemplateOrganizations;
  return organizations.map(organization => {
    return organization.value;
  });
};

const isAssessmentWithIsRoot = carePathway => {
  let hasOnlyAssessmentAndIsRoot = true;

  carePathway &&
    carePathway.templates.forEach(template => {
      template.carePathwayTemplateAppointmentType.forEach(appointmentType => {
        if (!template.isAssessment && appointmentType.isRoot) {
          hasOnlyAssessmentAndIsRoot = false;
        }
      });
    });

  return hasOnlyAssessmentAndIsRoot;
};

const processNumericThresholds = thresholds => {
  return thresholds
    .filter(threshold => threshold.name !== '' && threshold.value !== '')
    .map(threshold => {
      return {
        ...threshold,
        conditional: mapThresholdConditionals(threshold.name.value),
      };
    });
};

const processPainLocationThresholds = thresholds => {
  return thresholds.filter(threshold => threshold.name !== '');
};

const processDatepickerThresholds = thresholds => {
  return thresholds
    .filter(threshold => threshold.name !== '')
    .map(threshold => {
      return {
        ...threshold,
        value: threshold.value,
      };
    });
};

const getComponentDescription = elem => {
  let description;
  if (elem.searchTerm.careplanComponentId) {
    if (elem.searchTerm.shouldDescriptionToggle) {
      description = elem.searchTerm.description;
    } else {
      description = '';
    }
  } else if (elem.shouldDescriptionToggle) {
    description = elem.searchTerm.description;
  } else if (!elem.shouldDescriptionToggle) {
    description = '';
  }
  return encodeURIComponent(description);
};
const getStandardValueId = elem => {
  const { careplanComponentId, shouldAnalyticsDataToggle } =
    elem.searchTerm || {};
  let standardValueId = null;

  if (careplanComponentId && shouldAnalyticsDataToggle) {
    standardValueId = elem.standardValueId;
  } else if (!careplanComponentId && elem.shouldAnalyticsDataToggle) {
    standardValueId = elem.standardValueId;
  }
  return standardValueId;
};

const getMedia = (elem, careplanComponentTypeOrderId) => {
  let mediaObject = {};
  if (elem.searchTerm.careplanComponentId) {
    if (elem.searchTerm.shouldMediaSettingsToggle == true) {
      mediaObject = {
        isMedia: true,
        mediaType: elem.searchTerm.additional_configuration.mediaType,
        mediaURL: elem.searchTerm.additional_configuration.mediaURL,
      };
    } else {
      mediaObject = {
        isMedia: false,
        mediaType: '',
        mediaURL: '',
      };
    }
  } else if (elem.shouldMediaSettingsToggle == true) {
    mediaObject = {
      isMedia: true,
      mediaType: elem.additional_configuration.mediaType,
      mediaURL: elem.additional_configuration.mediaURL,
    };
  } else {
    mediaObject = {
      isMedia: false,
      mediaType: '',
      mediaURL: '',
    };
  }
  if (careplanComponentTypeOrderId === componentTypeOrder.SORTABLE) {
    if (elem.searchTerm.careplanComponentId) {
      mediaObject.evaluationLine =
        elem.searchTerm.additional_configuration.evaluationLine;
    } else {
      mediaObject.evaluationLine = elem.additional_configuration.evaluationLine;
    }
  }
  return mediaObject;
};

const prepareToBeCreatedComponents = elem => {
  let componentData;
  const careplanComponentTypeOrderId = elem.collection.key;
  const mediaObject = getMedia(elem, careplanComponentTypeOrderId);
  let weight;
  if (
    ![
      componentTypeOrder.NUMERIC_RANGE,
      componentTypeOrder.NUMERIC_VALUE,
    ].includes(careplanComponentTypeOrderId)
  )
    weight = elem.weight || elem.searchTerm.weight;
  const commonAttributes = {
    careplanComponentTypeOrder: careplanComponentTypeOrderId,
    healthCategory:
      elem.healthCategory || componentHealthCategories.MAIN_QUESTION,
    toAddWeights: elem.shouldWeightToggle || elem.searchTerm.shouldWeightToggle,
    evaluationLine: '',
    careplanComponentDescriptionHtml: getComponentDescription(elem),
    weight,
    shouldThresholdToggle: elem.searchTerm.careplanComponentId
      ? elem.searchTerm.shouldThresholdToggle
      : elem.shouldThresholdToggle,
    shouldLogicJumpToggle: elem.shouldLogicJumpToggle,
    thresholdId: elem.thresholdId,
    ...mediaObject,
    depth: elem.depth,
    isMandatory:
      elem.searchTerm.shouldIsMandatoryToggle || elem.shouldIsMandatoryToggle,
    isSyncAttachment: elem.searchTerm.isSyncAttachment || elem.isSyncAttachment,
    assetId: mediaObject.isMedia
      ? elem.assetId ||
        (elem.searchTerm.asset && elem.searchTerm.asset.id) ||
        (elem.asset && elem.asset.id)
      : null,
    mediaURL:
      mediaObject.isMedia && elem.asset
        ? elem.asset.directLink
        : mediaObject.mediaURL,
    standardValueId: getStandardValueId(elem),
  };

  switch (careplanComponentTypeOrderId) {
    case componentTypeOrder.SINGLE_CHOICE:
      componentData = {
        ...commonAttributes,
        optionWeights:
          careplanComponentTypeOrderId == componentTypeOrder.SORTABLE
            ? elem.sortableCollection.optionWeights
              ? elem.sortableCollection.optionWeights
              : ''
            : elem.choiceCollection.optionWeights
            ? elem.choiceCollection.optionWeights
            : '',
        option: getOptions(elem.choiceCollection.choices),
        minLabel: '',
        maxLabel: '',
        minimumValue: '',
        maximumValue: '',
        thresholdCollection:
          careplanComponentTypeOrderId == componentTypeOrder.SORTABLE
            ? elem.sortableCollection.sortables
            : elem.choiceCollection.choices,
        logicJumpCollection: elem.shouldLogicJumpToggle
          ? elem.logicJumpCollection
          : [],
      };
      break;
    case componentTypeOrder.MULTIPLE_CHOICE:
    case componentTypeOrder.SORTABLE:
    case componentTypeOrder.BMI:
      componentData = {
        ...commonAttributes,
        optionWeights:
          careplanComponentTypeOrderId == componentTypeOrder.SORTABLE
            ? elem.sortableCollection.optionWeights
              ? elem.sortableCollection.optionWeights
              : ''
            : elem.choiceCollection.optionWeights
            ? elem.choiceCollection.optionWeights
            : '',
        option:
          careplanComponentTypeOrderId == componentTypeOrder.SORTABLE
            ? getOptions(elem.sortableCollection.sortables)
            : getOptions(elem.choiceCollection.choices),
        minLabel: '',
        maxLabel: '',
        minimumValue: '',
        maximumValue: '',
        thresholdCollection:
          careplanComponentTypeOrderId == componentTypeOrder.SORTABLE
            ? elem.sortableCollection.sortables
            : elem.choiceCollection.choices,
      };
      break;

    case componentTypeOrder.BLOOD_PRESSURE:
      const jumpComponents = elem.logicJumpCollection?.map(item => {
        if (item && typeof item.destination === 'object')
          item.destination = +item.destination.jump;
        return item;
      });
      componentData = {
        ...commonAttributes,
        optionWeights: elem.choiceCollection.optionWeights
          ? elem.choiceCollection.optionWeights
          : '',
        option: getOptions(elem.choiceCollection.choices),
        minLabel: '',
        maxLabel: '',
        minimumValue: '',
        maximumValue: '',
        thresholdCollection: elem.choiceCollection.choices,
        logicJumpCollection: elem.shouldLogicJumpToggle ? jumpComponents : [],
      };
      break;

    case componentTypeOrder.NUMERIC_RANGE:
      componentData = {
        ...commonAttributes,
        optionWeights: '',
        option: '',
        minLabel: elem.searchTerm.careplanComponentId
          ? elem.searchTerm.minLabel
          : elem.minLabel,
        maxLabel: elem.searchTerm.careplanComponentId
          ? elem.searchTerm.maxLabel
          : elem.maxLabel,
        minimumValue: elem.searchTerm.careplanComponentId
          ? elem.searchTerm.minimumValue
          : elem.minimumValue,
        maximumValue: elem.searchTerm.careplanComponentId
          ? elem.searchTerm.maximumValue
          : elem.maximumValue,
        isVerticalSlider: elem.searchTerm.careplanComponentId
          ? elem.searchTerm.isVerticalSlider
          : elem.isVerticalSlider,
        thresholdCollection: processNumericThresholds(
          elem.thresholdCollection.thresholds
        ),
        deleteThresholdIds: elem.thresholdCollection.deleteThresholdIds,
        logicJumpCollection: elem.shouldLogicJumpToggle
          ? elem.logicJumpCollection
          : [],
      };
      break;

    case componentTypeOrder.NUMERIC_VALUE:
      componentData = {
        ...commonAttributes,
        optionWeights: '',
        option: '',
        negative: elem.searchTerm.careplanComponentId
          ? elem.searchTerm.negative
          : elem.negative,
        float: elem.searchTerm.careplanComponentId
          ? elem.searchTerm.decimal
          : elem.decimal,
        minimumValue: elem.searchTerm.careplanComponentId
          ? elem.searchTerm.minimumValue
          : elem.minimumValue,
        maximumValue: elem.searchTerm.careplanComponentId
          ? elem.searchTerm.maximumValue
          : elem.maximumValue,
        minLabel: '',
        maxLabel: '',
        thresholdCollection: processNumericThresholds(
          elem.thresholdCollection.thresholds
        ),
        deleteThresholdIds: elem.thresholdCollection.deleteThresholdIds,
        logicJumpCollection: elem.shouldLogicJumpToggle
          ? elem.logicJumpCollection
          : [],
      };
      break;

    case componentTypeOrder.PAIN_LOCATION_CHART:
      componentData = {
        ...commonAttributes,
        minLabel: '',
        maxLabel: '',
        minimumValue: '',
        maximumValue: '',
        optionWeights: '',
        option: '',
        thresholdCollection: processPainLocationThresholds(
          elem.thresholdCollection.thresholds
        ),
        deleteThresholdIds: elem.thresholdCollection.deleteThresholdIds,
      };
      break;

    case componentTypeOrder.FREE_TEXT:
    case componentTypeOrder.YESNO:

    case componentTypeOrder.GROUP_DESCRIPTION:
    case componentTypeOrder.UPLOAD_FILE:
      componentData = {
        ...commonAttributes,
        minLabel: '',
        maxLabel: '',
        minimumValue: '',
        maximumValue: '',
        optionWeights: '',
        option: '',
        thresholdCollection: elem.radioCollection,
      };

      break;

    case componentTypeOrder.DATEPICKER:
      componentData = {
        ...commonAttributes,
        minLabel: '',
        maxLabel: '',
        minimumValue: '',
        maximumValue: '',
        optionWeights: '',
        option: '',
        // hasStartAndEnd: elem.radioCollection.isChecked,
        thresholdCollection: processDatepickerThresholds(
          elem.thresholdCollection.thresholds
        ),
      };
      break;
    case componentTypeOrder.INFORMATION:
      componentData = {
        ...commonAttributes,
        minLabel: '',
        maxLabel: '',
        minimumValue: '',
        maximumValue: '',
        optionWeights: '',
        option: '',
        thresholdCollection: elem.radioCollection,
        logicJumpCollection: elem.shouldLogicJumpToggle
          ? elem.logicJumpCollection
          : [],
      };
      break;
  }
  return componentData;
};

const removeComponentValidationIfComponentIsDeleted = (
  deletedComponentId,
  templateSettingsValidation,
  hasValidationErrorTemplate,
  componentTypeId
) => {
  const temp = { ...templateSettingsValidation };

  if (!IsEmptyObject(temp.templateSettingsValidation.noComponentsError)) {
    delete temp.templateSettingsValidation.noComponentsError[
      deletedComponentId
    ];
  }

  if (
    componentTypeId === componentTypeOrder.SINGLE_CHOICE ||
    componentTypeId === componentTypeOrder.MULTIPLE_CHOICE ||
    componentTypeId === componentTypeOrder.SORTABLE
  ) {
    if (
      !IsEmptyObject(temp.templateSettingsValidation.twoOptionsRequiredError)
    ) {
      delete temp.templateSettingsValidation.twoOptionsRequiredError[
        deletedComponentId
      ];
    }
    if (!IsEmptyObject(temp.templateSettingsValidation.emptyOptionError)) {
      delete temp.templateSettingsValidation.emptyOptionError[
        deletedComponentId
      ];
    }
  }

  if (
    componentTypeId === componentTypeOrder.NUMERIC_RANGE ||
    componentTypeId === componentTypeOrder.NUMERIC_VALUE
  ) {
    if (!IsEmptyObject(temp.logicJumpContradictionError)) {
      delete temp.logicJumpContradictionError[deletedComponentId];
    }
  }

  if (
    componentTypeId === componentTypeOrder.SORTABLE &&
    temp.templateSettingsValidation.evaluationLineErrorForSortable.length > 0
  ) {
    const deletedIndex =
      temp.templateSettingsValidation.evaluationLineErrorForSortable.findIndex(
        elem => {
          return elem.componentId === deletedComponentId;
        }
      );
    temp.templateSettingsValidation.evaluationLineErrorForSortable.splice(
      deletedIndex,
      1
    );
  }
  hasValidationErrorTemplate({ ...temp });
};

const removeComponent = (
  deletedComponentId,
  componentCollection,
  setComponentCollection,
  setCounter
) => {
  const components = componentCollection.filter(
    item => item.id !== deletedComponentId
  );
  components.map((elem, positionIndex) => {
    elem.id = positionIndex;
    switch (elem.collection.key) {
      case componentTypeOrder.YESNO:
      case componentTypeOrder.INFORMATION:
      case componentTypeOrder.GROUP_DESCRIPTION:
        elem.radioCollection.name = updateRadiosIndexes(elem, positionIndex);
    }
  });
  setCounter(components.length);
  setComponentCollection([...components]);
};

const updateRadiosIndexes = (elem, index) => {
  const radioName = elem.radioCollection.name.split('_')[0];
  return `${radioName}_${index}`;
};

const validateCareplanRecurrenceTag = (carePathway, tag, valueId, templateId) =>
  carePathway.careplans.some(
    careplan =>
      careplan.data.templateId === templateId &&
      careplan.value !== valueId &&
      !careplan.parent &&
      careplan.recurrenceTag &&
      careplan.recurrenceTag === tag
  );

const removeParentAndTheirChildren = (
  deletedComponentIndex,
  componentCollection,
  setComponentCollection,
  setCounter,
  validationErrorTemplate,
  hasValidationErrorTemplate,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring
) => {
  const removeValidation = [];
  const componentChildren = findDescendants(
    componentCollection,
    deletedComponentIndex
  );
  const componentChildrenLength = componentChildren.length;
  if (componentChildrenLength > 0) {
    for (let i = 0; i < componentChildrenLength; i++) {
      const findChildIndex = componentCollection.findIndex(
        comp => comp.id === componentChildren[i].id
      );
      const component = componentCollection[findChildIndex];
      if (findChildIndex > -1) {
        removeValidation.push({
          componentTypeId: component.collection.key,
          componentId: component.id,
        });
        componentCollection.splice(findChildIndex, 1);
      }
    }
  }
  if (deletedComponentIndex > -1) {
    const component = componentCollection[deletedComponentIndex];
    removeValidation.push({
      componentTypeId: component.collection.key,
      componentId: component.id,
    });
    componentCollection.splice(deletedComponentIndex, 1);
    componentCollection = componentCollection.map((elem, index) => {
      elem.id = index;
      return elem;
    });
    setComponentCollection([...componentCollection]);
    setCounter(componentCollection.length);
  }
  if (removeValidation.length > 0) {
    removeComponentValidationIfParentComponentIsDeleted(
      removeValidation,
      validationErrorTemplate,
      hasValidationErrorTemplate
    );
  }
  showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent = true;
  setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
};

const removeComponentValidationIfParentComponentIsDeleted = (
  removeValidation,
  validationErrorTemplate,
  hasValidationErrorTemplate
) => {
  const removeValidationLength = removeValidation.length;
  const temp = validationErrorTemplate;
  const validation = temp.templateSettingsValidation;
  for (let i = 0; i < removeValidationLength; i++) {
    const { componentTypeId } = removeValidation[i];
    const { componentId } = removeValidation[i];
    if (validation.noComponentsError.length > 0) {
      if (componentId > -1) {
        let deletedIndex;
        deletedIndex = validation.noComponentsError.findIndex(elem => {
          return elem.componentId == componentId;
        });
        validation.noComponentsError.splice(deletedIndex, 1);
      }
    }

    if (
      (componentTypeId === componentTypeOrder.SINGLE_CHOICE ||
        componentTypeId === componentTypeOrder.MULTIPLE_CHOICE ||
        componentTypeId === componentTypeOrder.SORTABLE) &&
      validation.twoOptionsRequiredError.length > 0
    ) {
      if (componentId > -1) {
        let deletedIndex;
        deletedIndex = validation.twoOptionsRequiredError.findIndex(elem => {
          return elem.componentId == componentId;
        });
        validation.twoOptionsRequiredError.splice(deletedIndex, 1);
      }
    }

    if (
      (componentTypeId === componentTypeOrder.SINGLE_CHOICE ||
        componentTypeId === componentTypeOrder.MULTIPLE_CHOICE ||
        componentTypeId === componentTypeOrder.SORTABLE) &&
      validation.emptyOptionError.length > 0
    ) {
      if (componentId > -1) {
        let deletedIndex;
        deletedIndex = validation.emptyOptionError.findIndex(elem => {
          return elem.componentId == componentId;
        });
        validation.emptyOptionError.splice(deletedIndex, 1);
      }
    }

    if (
      componentTypeId === componentTypeOrder.SORTABLE &&
      validation.evaluationLineErrorForSortable.length > 0
    ) {
      if (componentId > -1) {
        let deletedIndex;
        deletedIndex = validation.evaluationLineErrorForSortable.findIndex(
          elem => {
            return elem.componentId == componentId;
          }
        );
        validation.evaluationLineErrorForSortable.splice(deletedIndex, 1);
      }
    }
  }
  hasValidationErrorTemplate({ ...temp });
};

const setSearchTermAfterRemovingComponent = (
  componentCollection,
  setSearchTermComponents,
  setSettings
) => {
  if (componentCollection.length > 0) {
    setSearchTermComponents({
      ...componentCollection[componentCollection.length - 1],
    });
  } else {
    setSearchTermComponents({});
    setSettings(false);
  }
};

const removeCareplanComponent = (
  componentCollection,
  setComponentCollection,
  setCounter,
  setSearchTermComponents,
  setSettings,
  templateSettingsValidation,
  hasValidationErrorTemplate,
  item,
  setOpenPromptForParentComponentDeletion,
  setParentId,
  setShowPromptForAdjustScoring,
  showPromptForAdjustScoring,
  setDisplayFlashMsg
) => {
  setSettings(false);
  const deletedComponentIndex = componentCollection.findIndex(
    comp => comp.id === item.id
  );
  const componentTypeId = item.collection.key;
  const componentChildren =
    componentTypeId === componentTypeOrder.YESNO ||
    componentTypeId === componentTypeOrder.GROUP_DESCRIPTION
      ? findDescendants(componentCollection, deletedComponentIndex)
      : [];
  if (
    (componentTypeId === componentTypeOrder.YESNO &&
      componentChildren.length > 0) ||
    (componentTypeId === componentTypeOrder.GROUP_DESCRIPTION &&
      componentChildren.length > 0)
  ) {
    setOpenPromptForParentComponentDeletion(true);
    setParentId(deletedComponentIndex);
  } else {
    removeComponent(
      item.id,
      componentCollection,
      setComponentCollection,
      setCounter
    );
    showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent =
      true;
    setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
    removeComponentValidationIfComponentIsDeleted(
      item.id,
      templateSettingsValidation,
      hasValidationErrorTemplate,
      componentTypeId
    );
    setSearchTermAfterRemovingComponent(
      componentCollection,
      setSearchTermComponents,
      setSettings
    );
    const componentsWithJump = componentCollection.filter(
      component =>
        component.shouldLogicJumpToggle &&
        component.logicJumpCollection &&
        component.logicJumpCollection.length > 0
    );
    if (setDisplayFlashMsg && componentsWithJump.length > 0)
      setDisplayFlashMsg({
        display: true,
        message: I18n.t('warningMessage.jumpMessageWhenComponentDeleted'),
        type: FlashMessageTypes.Warning,
      });
  }
};

const phaseTypeMapper = id => {
  let key;
  switch (id) {
    case 1:
      key = 'types.phaseType.planning';
      break;

    case 2:
      key = 'types.phaseType.treatment';
      break;

    case 3:
      key = 'types.phaseType.followUp';
      break;

    case 4:
      key = 'types.phaseType.diagnosis';
      break;

    case 5:
      key = 'types.phaseType.screening';

      break;
  }
  return key;
};

const processMinMaxValidationError = (
  min: string,
  max: string,
  matchingComponent
) => {
  if (min === '' || isNaN(+min) || max === '' || isNaN(+max)) {
    matchingComponent.searchTerm = {
      ...matchingComponent.searchTerm,
      minMaxValidationError: true,
      validationMessage: 'parsley.requiredField',
    };
    return;
  }

  const numericMin = parseFloat(min);
  const numericMax = parseFloat(max);

  if (numericMin >= numericMax) {
    matchingComponent.searchTerm.minMaxValidationError = true;
    matchingComponent.searchTerm.validationMessage =
      'newQuestion_view.maxMinValidation';
  } else {
    matchingComponent.minMaxValidationError = false;
    if (matchingComponent.searchTerm === '') matchingComponent.searchTerm = {};
    matchingComponent.searchTerm.minMaxValidationError = false;
    matchingComponent.searchTerm.validationMessage = '';
  }
};

const getDefaultRecurrenceData = selectedPhaseType => {
  const lifespanUnitsObj = lifespanUnits();
  const repeatTypesArray = rruleFreqToRepeatTypeIndex();
  return {
    phaseType: selectedPhaseType,
    startDate: moment().toDate(),
    customTimeChecked: true,
    customTime: { hour: 9, minute: 0 },
    date: '',
    repeatType: repeatTypesArray[3],
    repeatInterval: { value: 1, label: 1 },
    occurrenceCount: 1,
    endDate: null,
    lifespan: 1,
    lifespanUnit: lifespanUnitsObj[1],
    weeklyRecurrence: [],
    occurrenceCountCheck: true,
  };
};

const getPrefilledRecurrenceData = (recurrenceData, response) => {
  const lifespanUnitsObj = lifespanUnits();
  const repeatTypesArray = rruleFreqToRepeatTypeIndex();
  const recurrenceRule = response.rruleRecurrence;
  const responseData = response.template || response;
  const startTime = responseData.startTime.split(':');
  const customTime = {
    hour: parseInt(startTime[0]),
    minute: parseInt(startTime[1]),
  };
  return {
    ...recurrenceData,
    startDate: moment(responseData.startsAt).toDate(),
    customTime,
    repeatType:
      recurrenceRule.origOptions.freq > -1
        ? repeatTypesArray[recurrenceRule.origOptions.freq]
        : recurrenceData.repeatType,
    repeatInterval: recurrenceRule.origOptions.interval
      ? {
          value: recurrenceRule.origOptions.interval,
          label: recurrenceRule.origOptions.interval,
        }
      : recurrenceData.repeatInterval,
    occurrenceCount:
      recurrenceRule.origOptions.count || recurrenceData.occurrenceCount,
    endDate: moment(responseData.endsAt).toDate(),
    weeklyRecurrence: recurrenceRule.options.byweekday
      ? generateWeekdayArray(recurrenceRule.options.byweekday)
      : recurrenceData.weeklyRecurrence,
    occurrenceCountCheck: true,
    lifespan: responseData.lifespan,
    lifespanUnit: lifespanUnitsObj[response.lifespanUnitIndex],
  };
};

const checkRecurrenceError = recurrenceErrors => {
  return Object.keys(recurrenceErrors).find(key => {
    return recurrenceErrors[key];
  });
};

const parseComponentName = componentName => {
  const div = document.createElement('div');
  div.innerHTML =
    componentName &&
    typeof componentName === 'string' &&
    sanitizeInput(componentName);
  return div.innerText;
};

const validateOptions = (
  item,
  collection,
  validationErrorTemplate,
  hasValidationErrorTemplate
) => {
  const temp = validationErrorTemplate;
  let filteredElement;
  if (temp.templateSettingsValidation.twoOptionsRequiredError.length > 0) {
    filteredElement =
      temp.templateSettingsValidation.twoOptionsRequiredError.find(elem => {
        return elem.componentId == item.id;
      });
    if (filteredElement) {
      if (collection.length >= 2) {
        filteredElement.twoOptionsRequired = false;
      } else {
        filteredElement.twoOptionsRequired = true;
      }
    }
    hasValidationErrorTemplate({ ...temp });
  }

  if (temp.templateSettingsValidation.emptyOptionError.length > 0) {
    filteredElement = temp.templateSettingsValidation.emptyOptionError.find(
      elem => {
        return elem.componentId == item.id;
      }
    );
    if (filteredElement) {
      if (collection.length >= 2) {
        filteredElement.twoOptionsRequired = false;
      } else {
        filteredElement.twoOptionsRequired = true;
      }
    }
    hasValidationErrorTemplate({ ...temp });
  }
};

const getMatchedPosition = (componentCollection, searchTermComponents) => {
  return componentCollection.findIndex(elem => {
    return elem.id == searchTermComponents.id;
  });
};

const prepareOptionAndTheirWeights = (
  optionsArray,
  componentOptionWeights,
  addDefaultWeight
) => {
  let optionAndWeightObject;
  if (!addDefaultWeight) {
    optionAndWeightObject = optionsArray.map((option, index) => {
      return {
        index,
        option,
        weight:
          typeof componentOptionWeights === 'object'
            ? componentOptionWeights.weights[index]
            : JSON.parse(componentOptionWeights).weights[index],
        key: 'searchTerm',
      };
    });
  } else {
    optionAndWeightObject = optionsArray.map((option, index) => {
      return {
        index,
        option: option.name,
        weight: componentOptionWeights[index],
        key: 'newlyCreated',
      };
    });
  }
  return optionAndWeightObject;
};

const getNewlyCreatedComponentOptionsAndWeightsForSortables = (
  componentCollection,
  searchTermComponents
) => {
  let newlyCreatedOptions;
  let newlyCreatedComponentOptionsWeights;
  let newlyCreatedOptionsWithWeights;
  if (
    componentCollection[
      getMatchedPosition(componentCollection, searchTermComponents)
    ].sortableCollection.sortables.length > 0
  ) {
    newlyCreatedOptions =
      componentCollection[
        getMatchedPosition(componentCollection, searchTermComponents)
      ].sortableCollection.sortables;
    newlyCreatedComponentOptionsWeights =
      componentCollection[
        getMatchedPosition(componentCollection, searchTermComponents)
      ].sortableCollection.optionWeights.weights;
    newlyCreatedOptionsWithWeights = prepareOptionAndTheirWeights(
      newlyCreatedOptions,
      newlyCreatedComponentOptionsWeights,
      true
    );
  } else {
    newlyCreatedOptionsWithWeights = [];
  }
  return newlyCreatedOptionsWithWeights;
};

const setPrefilledThresholdFromDropdown = elem => {
  if (elem && elem.name) {
    const prefilledThreshold = elem.name;
    prefilledThreshold.label = I18n.t(prefilledThreshold.key);
    return prefilledThreshold;
  }
};

const scrollToEnd = () => {
  setTimeout(() => {
    const element = document.getElementById('componentsContainer');
    element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
  }, 500);
};

const replaceNullWithZero = optWeights => {
  const parseWeights = optWeights.map(weight => {
    return isNaN(weight) ||
      weight == '' ||
      weight == null ||
      weight == undefined ||
      weight == 'undefined'
      ? 0
      : weight;
  });
  return parseWeights;
};

const getComponentWeight = component => {
  let weight = 0;
  const optionWeights = component.choiceCollection?.optionWeights;
  const { key } = component.collection;
  const componentOptions = component.searchTerm.careplanComponentId
    ? component.searchTerm
    : component;
  if (componentOptions.shouldWeightToggle) {
    weight = componentOptions.weight;
  } else {
    weight = NaN;
  }

  if (
    key === componentTypeOrder.SINGLE_CHOICE &&
    componentOptions.shouldWeightToggle
  ) {
    weight = Math.max(...optionWeights.weights);
  }
  if (
    key === componentTypeOrder.MULTIPLE_CHOICE &&
    componentOptions.shouldWeightToggle
  ) {
    weight = optionWeights.total;
  }
  if (
    key === componentTypeOrder.SORTABLE &&
    componentOptions.shouldWeightToggle
  ) {
    const { evaluationLine } = componentOptions.additional_configuration;

    const optWeights =
      component.sortableCollection.optionWeights.weights.map(Number);
    const parseWeights = replaceNullWithZero(optWeights);
    weight =
      parseWeights.length > 0
        ? parseWeights
            .sort((total, val) => val - total)
            .slice(0, evaluationLine)
            .reduce((total, val) => total + val)
        : 0;
  }
  if (
    key === componentTypeOrder.BMI ||
    key === componentTypeOrder.BLOOD_PRESSURE
  ) {
    const optWeights = optionWeights.weights.map(Number);

    let parseWeights = replaceNullWithZero(optWeights);

    parseWeights = parseWeights.filter(elem => {
      return isNaN(elem) == false;
    });
    if (componentOptions.shouldWeightToggle) {
      weight = parseWeights.length > 0 ? Math.max(...parseWeights) : 0;
    }
  }

  return weight;
};

const getTotalScoringSum = componentCollection => {
  let weightsCounter = 0;
  componentCollection.map(component => {
    if (component.searchTerm.careplanComponentId) {
      if (component.searchTerm.shouldWeightToggle) {
        weightsCounter += parseInt(component.searchTerm.weight, 10);
      } else {
        weightsCounter += 0;
      }
    } else if (component.shouldWeightToggle) {
      weightsCounter += parseInt(component.weight, 10);
    } else {
      weightsCounter += 0;
    }
    if (component.collection.key === componentTypeOrder.SINGLE_CHOICE) {
      if (
        (component.searchTerm.careplanComponentId &&
          component.searchTerm.shouldWeightToggle) ||
        component.shouldWeightToggle
      ) {
        weightsCounter += Math.max(
          ...component.choiceCollection.optionWeights.weights
        );
      }
    }
    if (component.collection.key === componentTypeOrder.MULTIPLE_CHOICE) {
      if (
        (component.searchTerm.careplanComponentId &&
          component.searchTerm.shouldWeightToggle) ||
        component.shouldWeightToggle
      ) {
        weightsCounter += parseInt(
          component.choiceCollection.optionWeights.total,
          10
        );
      }
    }

    if (component.collection.key === componentTypeOrder.SORTABLE) {
      if (
        (component.searchTerm.careplanComponentId &&
          component.searchTerm.shouldWeightToggle) ||
        component.shouldWeightToggle
      ) {
        const evaluationLine = component.searchTerm.careplanComponentId
          ? component.searchTerm.additional_configuration.evaluationLine
          : component.additional_configuration.evaluationLine;
        const optWeights =
          component.sortableCollection.optionWeights.weights.map(Number);
        const parseWeights = replaceNullWithZero(optWeights);
        weightsCounter +=
          parseWeights.length > 0
            ? parseWeights
                .sort((total, val) => val - total)
                .slice(0, evaluationLine)
                .reduce((total, val) => total + val)
            : 0;
      }
    }

    if (
      component.collection.key === componentTypeOrder.BMI ||
      component.collection.key === componentTypeOrder.BLOOD_PRESSURE
    ) {
      const optWeights =
        component.choiceCollection.optionWeights.weights.map(Number);

      let parseWeights = replaceNullWithZero(optWeights);

      parseWeights = parseWeights.filter(elem => {
        return isNaN(elem) == false;
      });

      if (
        (component.searchTerm.careplanComponentId &&
          component.searchTerm.shouldWeightToggle) ||
        component.shouldWeightToggle
      ) {
        weightsCounter +=
          parseWeights.length > 0 ? Math.max(...parseWeights) : 0;
      }
    }
    if (
      (component.shouldWeightToggle ||
        component.searchTerm.shouldWeightToggle) &&
      (component.collection.key === componentTypeOrder.NUMERIC_VALUE ||
        component.collection.key === componentTypeOrder.NUMERIC_RANGE)
    ) {
      weightsCounter +=
        +component.searchTerm.maximumValue || +component.maximumValue;
    }
  });
  return Math.round(weightsCounter);
};

const getCarepathwayTemplateReadPermission = async user => {
  const userRolePermission =
    await RoleHelperUtility.getLoggedInUserRolePermissions(
      user,
      I18n.t('types.componentType.carepathwayTemplates')
    );
  if (userRolePermission.length > 0) {
    let readCarepathTemplatePermission;
    readCarepathTemplatePermission = userRolePermission.find(role => {
      return role.action == I18n.t('actions.read');
    });
    return readCarepathTemplatePermission.allowed;
  }
};

const getDurations = (appointmentType, time, unit) => {
  let duration = '';
  if (parseInt(time, 10) === 0) {
    duration = I18n.t('common_labels.label_atSameday');
  } else {
    duration = ` ${Math.abs(time)} ${unit} ${
      parseInt(time, 10) > 0
        ? I18n.t('common_labels.label_after')
        : I18n.t('common_labels.label_before')
    }`;
  }
  return `${duration} ${appointmentType}`;
};

const checkUnder18Patients = selectedPatientsData => {
  let isUnder18 = false;
  if (selectedPatientsData.length) {
    selectedPatientsData.map(patient => {
      const age =
        patient.isPatientDemographicData === false ||
        patient.isPatientDemographicData === undefined
          ? moment().diff(patient.birthdate, 'years')
          : patient.age;
      if (age < 18) {
        isUnder18 = true;
      }
    });
  }
  return isUnder18;
};

const templatesWithThresholds = pathwayTemplates => {
  let hasTresholds = false;
  if (pathwayTemplates.length > 0) {
    pathwayTemplates.map(template => {
      const { careplanComponentsWithThresholds } = template;
      if (careplanComponentsWithThresholds.length > 0) {
        careplanComponentsWithThresholds.map(threshold => {
          if (threshold[1].length > 0) hasTresholds = true;
        });
      }
    });
  }
  return hasTresholds;
};

const templatesWithLogicJumps = careTaskTemplates => {
  let hasLogicjumps = false;
  if (careTaskTemplates.length > 0) {
    careTaskTemplates.map(template => {
      const { careplanTemplateWithLogicJumps } = template;
      if (Object.keys(careplanTemplateWithLogicJumps).length > 0) {
        hasLogicjumps = true;
      }
    });
  }
  return hasLogicjumps;
};

const templatesComponentsHasScores = pathwayTemplates => {
  let hasScores = false;
  if (pathwayTemplates.length > 0) {
    pathwayTemplates.map(template => {
      const { scoringScheme, isEvaluating } = template.template;
      if (isEvaluating && scoringScheme != null && scoringScheme.length > 0) {
        for (let i = 0; i < scoringScheme.length; i++) {
          if (scoringScheme[i].max > 0) {
            hasScores = true;
          }
        }
      }
    });
  }
  return hasScores;
};

const prepareRowsForDatatables = careplanTemplatesData => {
  const rows = [];
  if (careplanTemplatesData) {
    for (let index = 0; index < careplanTemplatesData.length; index++) {
      if (careplanTemplatesData[index].name != null) {
        rows.push({
          id: careplanTemplatesData[index].id,
          name: careplanTemplatesData[index].name,
          questions: careplanTemplatesData[index].questions,
          icds: careplanTemplatesData[index].icds,
          isEvaluating: careplanTemplatesData[index].isEvaluating,
          alias: careplanTemplatesData[index].alias,
          templateUUID: careplanTemplatesData[index].uuid,
          isAssessment: careplanTemplatesData[index].isAssessment,
          roles: careplanTemplatesData[index].roles,
        });
      }
    }
  }
  return rows;
};

const isAnswerSkipped = (componentTypeId, answers) => {
  if (answers.isSkipped) {
    return true;
  }
  switch (componentTypeId) {
    case componentTypeOrder.YESNO:
    case componentTypeOrder.GROUP_DESCRIPTION:
      return answers.answer_boolean === null || answers.answer_boolean === -1;
    case componentTypeOrder.INFORMATION:
      return false;
    case componentTypeOrder.SINGLE_CHOICE:
      return (
        answers.answer_json != null && answers.answer_json.optionIndex == null
      );
    case componentTypeOrder.MULTIPLE_CHOICE:
      return answers.answer_json === null;
    case componentTypeOrder.SORTABLE:
      return (
        answers.answer_json === null ||
        (answers.answer_json &&
          answers.answer_json.order &&
          answers.answer_json.order.length === 0)
      );
    case componentTypeOrder.FREE_TEXT:
      return (
        (answers.answer_text !== null &&
          answers.answer_text.toLowerCase() === 'skipped') ||
        (answers.answer_text !== null &&
          answers.answer_text.toLowerCase() === 'überspringen') ||
        answers.isSkipped
      );
    case componentTypeOrder.NUMERIC_RANGE:
    case componentTypeOrder.NUMERIC_VALUE:
      return answers.answer_numeric == null;
    case componentTypeOrder.PAIN_LOCATION_CHART:
      return (
        answers.bodychart &&
        (answers.bodychart.toLowerCase() === 'skipped' ||
          answers.bodychart.toLowerCase() === 'überspringen')
      );
    case componentTypeOrder.UPLOAD_FILE:
      return answers.assetId === null;
    case componentTypeOrder.DATEPICKER:
      return answers.answer_date === null;
    case componentTypeOrder.BMI:
      return (
        answers.answer_bmi_height === null || answers.answer_bmi_weight === null
      );
    default:
      return false;
  }
};

const checkTemplateHaveThresholdsOrNot = careplanComponentsWithThresholds => {
  if (
    careplanComponentsWithThresholds &&
    careplanComponentsWithThresholds.length > 0
  ) {
    for (let i = 0; i < careplanComponentsWithThresholds.length; i++) {
      if (careplanComponentsWithThresholds[i][1].length > 0) {
        return true;
      }
    }
  }
  return false;
};

const checkTemplateHaveLogicJumpsOrNot = careplanTemplateWithLogicJumps => {
  if (
    careplanTemplateWithLogicJumps &&
    Object.keys(careplanTemplateWithLogicJumps).length > 0
  ) {
    return true;
  }
  return false;
};

const getRichTextTranslationMap = () => {
  return {
    clear: I18n.t('common_buttons.styleClear'),
    color: I18n.t('common_buttons.color'),
    bold: I18n.t('common_buttons.bold'),
    italic: I18n.t('common_buttons.italic'),
    underline: I18n.t('common_buttons.underline'),
  };
};

const addComponentHandler = async (
  event,
  item,
  healthCategory,
  showPromptForAdjustScoring,
  setShowPromptForAdjustScoring,
  counter,
  setCounter,
  componentCollection,
  setComponentCollection,
  setSearchTermComponents,
  renderCareplanComponent = null
) => {
  if (healthCategory === componentHealthCategories.MAIN_QUESTION) {
    showPromptForAdjustScoring.checkUserDeleteAddOrChangeScoreOfComponent =
      true;
    setShowPromptForAdjustScoring({ ...showPromptForAdjustScoring });
    scrollToEnd();
  }
  item.associatedWithCareplanTemplate = false;
  setCounter(count => count + 1);
  let baseObject = {
    id: counter,
    collection: item,
    healthCategory,
    shouldShowContent: false,
    shouldThresholdToggle: false,
    shouldIsMandatoryToggle: false,
    shouldDescriptionToggle: false,
    standardValueId: null,
    description: '',
    shouldMediaSettingsToggle: false,
    additional_configuration: {
      isMedia: false,
      mediaType: '',
      mediaURL: '',
      evaluationLine:
        item.key == componentTypeOrder.SORTABLE
          ? defaultEnums.EVALUATION_LINE
          : null,
    },
    shouldWeightToggle: false,
    shouldAnalyticsDataToggle: false,
    weight: 0,
    searchTerm: '',
    depth: 0,
  };

  const customLabels = (categories: string[]) =>
    categories.map(category => ({
      isChecked: false,
      name: category,
    }));
  switch (item.key) {
    case componentTypeOrder.FREE_TEXT:
      baseObject = { ...baseObject, textValue: '' };
      break;

    case componentTypeOrder.SINGLE_CHOICE:
    case componentTypeOrder.MULTIPLE_CHOICE:
      baseObject = {
        ...baseObject,
        choiceCollection: {
          addChoice: '',
          choices: [],
          optionWeights: { total: 0, weights: [] },
          selectedChoice: { name: '', isChecked: false, index: '' },
        },
      };
      break;

    case componentTypeOrder.NUMERIC_RANGE:
      baseObject = {
        ...baseObject,
        minimumValue: defaultEnums.DEFAULT_NUMERIC_RANGE_MIN_VALUE,
        maximumValue: defaultEnums.DEFAULT_NUMERIC_RANGE_MAX_VALUE,
        minLabel: '',
        maxLabel: '',
        thresholdCollection: {
          thresholds: [],
          currentThreshold: { name: '', value: '' },
        },
        logicJumpCollection: [],
      };
      break;

    case componentTypeOrder.NUMERIC_VALUE:
      baseObject = {
        ...baseObject,
        minimumValue: defaultEnums.DEFAULT_NUMERIC_VALUE_MIN_VALUE,
        maximumValue: defaultEnums.DEFAULT_NUMERIC_VALUE_MAX_VALUE,
        decimal: true,
        negative: false,
        thresholdCollection: {
          thresholds: [],
          currentThreshold: { name: '', value: '' },
        },
        logicJumpCollection: [],
      };
      break;

    case componentTypeOrder.YESNO:
    case componentTypeOrder.INFORMATION:
    case componentTypeOrder.GROUP_DESCRIPTION:
      baseObject = {
        ...baseObject,
        radioCollection: { name: '', isChecked: false },
      };
      break;

    case componentTypeOrder.PAIN_LOCATION_CHART:
      baseObject = {
        ...baseObject,
        thresholdCollection: {
          thresholds: [],
          currentThreshold: { name: '', value: '' },
        },
      };
      break;

    case componentTypeOrder.SORTABLE:
      baseObject = {
        ...baseObject,
        sortableCollection: {
          addSortable: '',
          sortables: [],
          optionWeights: { total: 0, weights: [] },
          selectedSortable: { name: '', isChecked: false },
        },
      };
      break;

    case componentTypeOrder.UPLOAD_FILE:
      baseObject = { ...baseObject, textValue: '' };
      break;

    case componentTypeOrder.DATEPICKER:
      baseObject = {
        ...baseObject,
        thresholdCollection: {
          thresholds: [],
          currentThreshold: { name: '', value: '' },
        },
      };
      break;

    case componentTypeOrder.BMI:
      baseObject = {
        ...baseObject,
        choiceCollection: {
          addChoice: '',
          choices: customLabels(bmiCategories),
          optionWeights: { total: 0, weights: [] },
          selectedChoice: { name: '', isChecked: false, index: '' },
        },
      };
      break;
    case componentTypeOrder.BLOOD_PRESSURE:
      baseObject = {
        ...baseObject,
        choiceCollection: {
          addChoice: '',
          choices: customLabels(bloodPressureCategories),
          optionWeights: { total: 0, weights: [] },
          selectedChoice: { name: '', isChecked: false, index: '' },
        },
      };
      break;

    default:
      baseObject;
      break;
  }
  let indexToAddNewComponent: number = componentCollection.length;
  if (baseObject.healthCategory === componentHealthCategories.MAIN_QUESTION) {
    indexToAddNewComponent =
      lastIndexOfObject({
        array: componentCollection,
        key: 'healthCategory',
        value: componentHealthCategories.MAIN_QUESTION,
      }) + 1;
  }
  componentCollection.splice(indexToAddNewComponent, 0, baseObject);
  setComponentCollection([...componentCollection]);
  setSearchTermComponents({
    ...componentCollection[indexToAddNewComponent],
  });

  if (renderCareplanComponent) renderCareplanComponent(true);
};

const componentHealthCategories = {
  MAIN_QUESTION: 0,
  POOR_QUESTION: 1,
  FAIR_QUESTION: 2,
  WELL_QUESTION: 3,
};

const getCareplanComponents = components => {
  return components.map(elem => {
    let nameHtml =
      elem.searchTerm.nameHtml == undefined
        ? elem.searchTerm.value
          ? elem.searchTerm.value
          : ''
        : elem.searchTerm.nameHtml;
    if (elem.searchTerm && !nameHtml.includes('<p>')) {
      nameHtml = `<p>${nameHtml}</p>`;
    }
    const commonObject = {
      careplanComponentText: nameHtml,
      ...prepareToBeCreatedComponents(elem),
    };
    return elem.searchTerm.careplanComponentId
      ? {
          id: elem.searchTerm.careplanComponentId,
          ...commonObject,
        }
      : {
          ...commonObject,
        };
  });
};

const getComponentsByHealthCategory = (components, healthCategory) => {
  const allowedCategory = [healthCategory];
  // * Checking if old questions have not field healthCategory so return them as main questions
  if (healthCategory === componentHealthCategories.MAIN_QUESTION)
    allowedCategory.push(undefined);
  return components.filter(
    x => allowedCategory.indexOf(x.healthCategory) != -1
  );
};

const isMainQuestion = healthCategory => {
  return (
    healthCategory === componentHealthCategories.MAIN_QUESTION ||
    healthCategory === undefined
  );
};

function getScoringCategoryTranslation(id: ScoringTypes): string {
  if (id === ScoringTypes.Well) return I18n.t('newCareplan_view.scoring_well');
  if (id === ScoringTypes.Fair) return I18n.t('newCareplan_view.scoring_fair');
  return I18n.t('newCareplan_view.scoring_poor');
}

const checkItemJumpListValidity = component => {
  return (
    component &&
    component.shouldLogicJumpToggle &&
    component.logicJumpCollection &&
    component.logicJumpCollection.length > 0
  );
};
const compare = (operatorKey, leftOperand, rightOperand) => {
  switch (operatorKey) {
    case 'lessThanEqual':
      return leftOperand <= rightOperand;

    case 'lessThan':
      return leftOperand < rightOperand;

    case 'greaterThanEqual':
      return leftOperand >= rightOperand;

    case 'greaterThan':
      return leftOperand > rightOperand;

    case 'equalTo':
      return leftOperand === rightOperand;

    case 'notEqual':
      return leftOperand !== rightOperand;

    default:
      return false;
  }
};
const jumpCalculationForNumericalComponent = (component, answer) => {
  const { logicJumpPaths } = component;
  const jumpElemKey = Object.keys(logicJumpPaths)[0];
  const jumpKeys = Object.keys(logicJumpPaths);
  for (let i = jumpElemKey; i < jumpKeys.length; i++) {
    const path = logicJumpPaths[i];
    const { condition } = path;
    if (compare(condition.name.value, Number(answer), Number(condition.value)))
      return path.destination;
  }
  return null;
};

const valueIsGreaterThanReference = (value: string, reference: string) => {
  return parseFloat(value) >= parseFloat(reference);
}

export {
  isThresholdSet,
  handleDropDownState,
  handleThresholdInput,
  addThreshold,
  removeThreshold,
  displayThreshold,
  getThresholdOptions,
  fillCareplanTemplateComponents,
  getOptions,
  getStartDate,
  getEndDate,
  getStartTime,
  getTemplateName,
  getIcds,
  getPhaseType,
  getCarepathwayTemplates,
  prepareToBeCreatedComponents,
  prepareSearchTerm,
  removeCareplanComponent,
  getCareplanTemplateOrganization,
  phaseTypeMapper,
  getActionIconName,
  getRadioButtonVariant,
  processMinMaxValidationError,
  getDefaultRecurrenceData,
  getPrefilledRecurrenceData,
  checkRecurrenceError,
  removeParentAndTheirChildren,
  parseComponentName,
  validateOptions,
  getNewlyCreatedComponentOptionsAndWeightsForSortables,
  prepareOptionAndTheirWeights,
  setPrefilledThresholdFromDropdown,
  getTotalScoringSum,
  getCarepathwayTemplateReadPermission,
  getDurations,
  mapThresholdConditionals,
  getJumpData,
  jumpCalculationForNumericalComponent,
  checkUnder18Patients,
  prepareRowsForDatatables,
  templatesWithThresholds,
  templatesWithLogicJumps,
  templatesComponentsHasScores,
  checkComponentScore,
  isAnswerSkipped,
  checkTemplateHaveThresholdsOrNot,
  checkTemplateHaveLogicJumpsOrNot,
  getRichTextTranslationMap,
  addComponentHandler,
  componentHealthCategories,
  getCareplanComponents,
  getComponentsByHealthCategory,
  isMainQuestion,
  getScoringCategoryTranslation,
  checkItemJumpListValidity,
  getComponentWeight,
  validateCareplanRecurrenceTag,
  isAssessmentWithIsRoot,
  valueIsGreaterThanReference
};
