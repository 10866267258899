import { useEffect } from 'react';
import i18next from 'i18next';

import { updateLocale } from '@global/Date';

export default function useUpdateLocale() {
  useEffect(() => {
    updateLocale(i18next.language);
  }, [i18next.language]);
}
