/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext, useCallback } from 'react';
import I18n from 'i18next';
import axios from 'axios';
import moment from 'moment';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import serviceConfig from '../../../serviceConfig.json';
import {
  ComponentTypes,
  Actions,
  SubmissionTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import '../../css/patient.css';
import TaskInformationOverview from './TaskInformationOverview';
import IcdSelector from '../../../caro-ui-commonfiles/components/ICDDropdownSelect/ICDSelect';

import TaskModalContentForm from './TaskModalContentForm';
import '../../css/user.css';
import '../../css/patientOverview.scss';
import '../../css/careplans.scss';
import RecurrenceComponent from '../../../caro-ui-commonfiles/components/molecules/Recurrence/Recurrence';
import icdThreshold from '../../Utils/icdThresholds';

const TaskCreateModalContent = () => {
  const {
    currentStep,
    setCurrentStep,
    shouldValidateForm,
    setFlashMessage,
    closeModal,
    dispatchFormValidator,
    shouldSubmitForm,
    dispatchFormSubmittor,
    patientInfo,
    setLandingTab,
    setRefreshCalendar,
  } = useContext(PatientOverviewContext);
  const [validateRecurrence, setValidateRecurrence] = useState(false);
  const [icds, setIcds] = useState([]);
  const {
    dropdownTaskTypes,
    dropdownTaskPriority,
    dropdownPhaseTypes,
  } = require('../../Utils/dropdownTypes');
  const {
    lifespanUnits,
    rruleFreqToRepeatTypeIndex,
  } = require('../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils');
  const lifespanUnitsObj = lifespanUnits();
  const repeatTypesArray = rruleFreqToRepeatTypeIndex();
  const [allRoles, setAllRoles] = useState([]);
  const [allTaskType, setAllTaskType] = useState([]);
  const [allTaskPriority, setAllPriority] = useState([]);
  const [allPhaseType, setAllPhaseType] = useState([]);
  const [values, setValues] = useState({
    name: '',
    taskType: dropdownTaskTypes()[2],
    phaseType: dropdownPhaseTypes()[1],
    taskPriority: dropdownTaskPriority()[1],
    startDate: moment().toDate(),
    morning: true,
    midday: true,
    evening: true,
    night: false,
    customTime: { hour: 9 },
    customTimeChecked: true,
    URL: '',
    date: '',
    role: '',
    lifespan: 1,
    lifespanUnit: lifespanUnitsObj[0],
    repeatType: repeatTypesArray[3],
    repeatInterval: { value: 1, label: 1 },
    occurrenceCount: 1,
    endDate: new Date(),
    weeklyRecurrence: new Array(7).fill(false),
    occurrenceCountCheck: true,
  });

  const [hasErrors, setHasErrors] = useState({
    name: false,
    taskType: false,
    phaseType: false,
    taskPriority: false,
    URL: false,
    date: false,
    role: false,
  });

  const [recurrenceErrors, setRecurrenceErrors] = useState({
    lifespan: false,
    recurrence: false,
    recurrenceRepeat: false,
    startDate: false,
    endDate: false,
    occurrenceCount: false,
  });

  const [startingArrays, setStartingArrays] = useState({
    startingDays: [],
    startingTimes: [],
  });

  const onValueChange = useCallback(
    (value, key) => {
      setValues({
        ...values,
        [key]: value,
      });
    },
    [values]
  );

  const getData = async () => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.addTask.uri + patientInfo.id,
        {
          params: {},
        }
      );
      let roles = [];
      let retrievedTaskTypes = [];
      let retrievedTaskPriorities = [];
      let retrievedPhaseTypes = [];

      if (response.data.userRoles) {
        roles = response.data.userRoles.rows.map(role => ({
          value: role.id,
          label: role.name,
        }));
        if (roles.length > 0) {
          setAllRoles(roles);
          onValueChange(roles[0], 'role');
        }
      }
      if (response.data.taskTypes) {
        retrievedTaskTypes = response.data.taskTypes.map(taskType => ({
          value: taskType.id,
          label: I18n.t(taskType.name),
        }));
        setAllTaskType(retrievedTaskTypes);
      }
      if (response.data.phaseTypes) {
        retrievedPhaseTypes = response.data.phaseTypes.map(phaseType => ({
          value: phaseType.id,
          label: I18n.t(phaseType.name),
        }));
        setAllPhaseType(retrievedPhaseTypes);
      }
      if (response.data.priorities) {
        retrievedTaskPriorities = response.data.priorities.map(
          taskPriority => ({
            value: taskPriority.id,
            label: I18n.t(taskPriority.name),
          })
        );
        setAllPriority(retrievedTaskPriorities);
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };

  useEffect(() => {
    const createTask = async () => {
      closeModal();
      const { endDate } = values;
      let message = '';
      try {
        const response = await axios.post(
          serviceConfig.brokerService.createTask.uri,
          {
            patientId: patientInfo.id,
            countICDs: icds,
            ...values,
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: endDate
              ? moment(endDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
          }
        );
        if (response) {
          let sendResponse = response;
          message = `${I18n.t(response.data.content)}`;
          const isSendOutImmediately =
            shouldSubmitForm.submissionType === SubmissionTypes.ASSIGN_AND_SEND;
          if (isSendOutImmediately) {
            sendResponse = await axios.post(
              serviceConfig.brokerService.sendTask.uri,
              {
                reminderId: response.data.reminderId,
              }
            );
          }
          if (sendResponse) {
            setFlashMessage({
              type: sendResponse.data.type,
              content: isSendOutImmediately
                ? `${message} ${I18n.t(sendResponse.data.content)}`
                : message,
            });
          }
        }
      } catch (error) {
        setFlashMessage({
          type: error.response.data.type,
          content: `${I18n.t(error.response.data.content)}${message}`,
        });
      }
      setLandingTab(false);
      setLandingTab(ComponentTypes.TASK);
      setRefreshCalendar(true);
    };

    if (
      shouldSubmitForm.component === ComponentTypes.TASK &&
      shouldSubmitForm.action === Actions.Create
    ) {
      createTask();
      return function cleanup() {
        dispatchFormSubmittor({
          component: '',
          action: '',
          submissionType: false,
        });
      };
    }
  }, [shouldSubmitForm]);

  const validateForm = () => {
    const isNameValid = values.name.trim().length > 5;
    const isUrlValid =
      values.taskType.value !== 1
        ? true
        : values.URL.match(
            /(https?|ftp):\/\/(-.)?([^s\/?.#-]+.?)+(\/[^s]*)?$/i
          );

    setHasErrors({
      name: !isNameValid,
      URL: !isUrlValid,
    });
    return isNameValid && isUrlValid;
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    return false;
  };

  const fetchIcdData = term => {
    return axios(serviceConfig.getIcds.uri, {
      params: {
        searchTerm: term,
      },
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    if (
      shouldValidateForm.component === ComponentTypes.TASK &&
      shouldValidateForm.action === Actions.Create
    ) {
      setValidateRecurrence(shouldValidateForm.shouldValidate);
    }
  }, [shouldValidateForm]);

  useEffect(() => {
    const checkRecurrenceError = () =>
      Object.keys(recurrenceErrors).find(key => recurrenceErrors[key]);
    if (shouldValidateForm.shouldValidate) {
      const isFormValid = validateForm();
      if (validateRecurrence && isFormValid && !checkRecurrenceError()) {
        setCurrentStep(currentStep + 1);
        dispatchFormValidator({
          component: '',
          action: '',
          shouldValidate: false,
        });
      }
    }
    if (validateRecurrence) setValidateRecurrence(false);
  }, [recurrenceErrors]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <ModalContent>
      {currentStep == 1 && (
        <ModalForm
          formId="create-user-form"
          handleFormSubmit={handleFormSubmit}
        >
          <TaskModalContentForm
            values={values}
            recurrenceComponent={
              <RecurrenceComponent
                componentType={ComponentTypes.TASK}
                onValueChange={onValueChange}
                values={values}
                validateRecurrence={validateRecurrence}
                hasErrors={recurrenceErrors}
                setHasErrors={setRecurrenceErrors}
                setStartingArrays={setStartingArrays}
              />
            }
            icdSelectorComponent={
              <IcdSelector
                maxLengthItem={icdThreshold.TASK}
                onSelect={setIcds}
                icdSelected={icds}
                fetchData={fetchIcdData}
                placeholder={I18n.t('newCareplan_view.placeholderICDReference')}
              />
            }
            onValueChange={onValueChange}
            hasErrors={hasErrors}
            allRoles={allRoles}
            allTaskType={allTaskType}
            allTaskPriority={allTaskPriority}
            allPhaseType={allPhaseType}
          />
        </ModalForm>
      )}

      {currentStep == 2 && (
        <TaskInformationOverview
          values={values}
          startingArrays={startingArrays}
          icds={icds}
        />
      )}
    </ModalContent>
  );
};

export default TaskCreateModalContent;
