import { Greys, ActionColors } from '../../caro-ui-commonfiles/utils/colors';

const status = {
  translationKey: {
    "online": "activeNow",
    "away": "away",
    "offline": "offline",
    "unpaired" : "unpaired",
    "unsynced": "unsynced"
  },
  color: {
    "online": ActionColors.SUCCESS,
    "away": ActionColors.WARNING,
    "offline": ActionColors.OFFLINE,
    "unpaired" : Greys.LIGHT_GREY_50,
    "unsynced": Greys.LIGHT_GREY_50,
  }
};
  
export default status;
  