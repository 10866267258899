import {
  WYSIWYGPlugins,
  WYSIWYGToolbar,
  WYSIWYGinitGetter,
} from './WYSIWYGEditor.type';

export const localizeLangToTinymce = (lang: string) =>
  ({
    'ar-AR': 'ar',
    'de-DE': 'de',
    'fr-FR': 'fr_FR',
    'it-IT': 'it',
    'pt-BR': 'pt_BR',
    'th-TH': 'th_TH',
  }[lang]);

export const getToolbar = ({ hasLink }: WYSIWYGinitGetter) => {
  const toolbar = [
    WYSIWYGToolbar.Bold,
    WYSIWYGToolbar.Italic,
    WYSIWYGToolbar.Underline,
    WYSIWYGToolbar.Forecolor,
  ];
  if (hasLink) toolbar.push(WYSIWYGToolbar.Link);
  return toolbar.join(' ');
};

export const getPlugins = ({ hasLink }: WYSIWYGinitGetter) => {
  const plugins = [WYSIWYGPlugins.Wordcount];
  if (hasLink) plugins.push(WYSIWYGPlugins.Link);
  return plugins.join(' ');
};

export const getInitExtraProperties = ({ hasLink }: { hasLink: boolean }) => {
  const init: { [key: string]: boolean } = {};
  if (hasLink) {
    init.link_title = false;
    init.target_list = false;
    init.link_target_list = false;
    init.convert_urls = false;
    init.anchor_bottom = false;
    init.anchor_top = false;
  }
  return init;
};
