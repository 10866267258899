import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import io from 'socket.io-client';

import { Box, Modal, Profile } from '@ui-common-files/components';
import ChatNotificationDropdown from '../../Notifications/NotificationDropdown';
import ThresholdsNotificationDropdown from '../../Notifications/ThresholdsNotificationDropdown';
import TodoTopBarIconButton from '../../Todos/TodoTopBarIconButton';
import ProductLabelContentModal from '../../ProductLabelContentModal';
import { UserUpdateModalContent } from '../../Users/update';
import ModalFooter from '../../../Views/common/Layout/Modal/ModalFooter';
import TopBarIcon from './TopBarIcon';

import { HideFlashMessage } from '@global/Services';
import { EnumToArray } from '@global/Arrays';
import { TimerTemplate, FirebaseContext } from '@utils';
import { NotificationTypes } from '@type/Notifications';
import config, { nonMedical } from '../../../../config';
import LanguageItems from '../../../types/Common/LanguageItems.enum';
import serviceConfig from '../../../../serviceConfig.json';
import { useTopBarWrapperService } from './TopBarWrapper.service';
import {
  ThresholdNotificationIcon,
  MessageNotificationIcon,
} from './TopBarIcon/Icons';
import TopBarDropDownIcon from './TopBarIcon/TopBarDropDownIcon/TopBarDropDownIcon';

const TopBarWrapper = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useSelector(state => state.session);
  const services = useSelector(state => state.services);
  const [profilePicture, setProfilePicture] = useState(user.profilePicture);
  const languagesArray = EnumToArray(LanguageItems);
  const {
    hidePatientDemographics,
    userPinAndOptinsInfo: { isConsentForTracking },
  } = useContext(FirebaseContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayRevoke, setDisplayRevoke] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const notificationRef = useRef();
  const dropDownRef = useRef(null);
  const dropDownThresholdRef = useRef(null);
  const [notificationOffset, setNotificationOffset] = useReducer(
    (_state, offset) => offset,
    270
  );
  const [
    showThresholdsNotificationDropdown,
    setShowThresholdsNotificationDropdown,
  ] = useReducer((_state, ifShow) => ifShow, false);
  const [numThresholdsNotifications, setNumThresholdsNotifications] = useState({
    display: false,
    numThresholdsNotifications: 0,
  });
  const [showChatNotificationDropdown, setChatShowNotificationDropdown] =
    useState(false);
  const [numChatNotifications, setNumChatNotifications] = useState({
    display: false,
    numChatNotifications: 0,
  });

  const {
    openInformation,
    closeModal,
    changeLanguage,
    displayEditModal,
    logOut,
    screenSize,
    hideThresholdNotificationDropdown,
    onThresholdsNotificationIconClick,
    hideChatNotificationDropdown,
    onChatNotificationIconClick,
    getNotificationCount,
    topBarIcons,
    onTodosNotificationIconClick,
  } = useTopBarWrapperService(
    setIsModalOpen,
    setDisplayModal,
    setDisplayRevoke,
    setUserToUpdate,
    setNumThresholdsNotifications,
    setNumChatNotifications,
    notificationRef,
    dropDownThresholdRef,
    dropDownRef,
    setNotificationOffset,
    showThresholdsNotificationDropdown,
    setShowThresholdsNotificationDropdown,
    showChatNotificationDropdown,
    setChatShowNotificationDropdown
  );
  const items = [
    { key: 'edit', value: t('admin_view.label_edit_profile') },
    { key: 'logout', value: t('nav.logout') },
  ];
  const informations = [
    { key: 'instruction', value: t('common_labels.instruction_for_use') },
    { key: 'imprint', value: t('common_labels.imprint') },
    { key: 'support', value: t('common_labels.support') },
  ];

  useEffect(() => {
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  useEffect(() => {
    if (showChatNotificationDropdown)
      document.addEventListener('click', hideChatNotificationDropdown);
    return () => {
      document.removeEventListener('click', hideChatNotificationDropdown);
    };
  }, [showChatNotificationDropdown]);

  useEffect(() => {
    getNotificationCount(NotificationTypes.ALLNOTIFICATIONS);
  }, []);

  useEffect(() => {
    if (showThresholdsNotificationDropdown)
      document.addEventListener('click', hideThresholdNotificationDropdown);
    return () => {
      document.removeEventListener('click', hideThresholdNotificationDropdown);
    };
  }, [showThresholdsNotificationDropdown]);

  useEffect(() => {
    const logOutOnRevokeOptIns = async () => {
      try {
        const retrievedUserOptIn = (
          await axios.get(serviceConfig.brokerService.getUserOptIn.uri, {
            params: {
              userId: user.id,
            },
          })
        ).data;
        if (
          retrievedUserOptIn.isPrivacyPolicy === false ||
          retrievedUserOptIn.isTermsAndConditions === false
        ) {
          logOut();
        }
      } catch (error) {}
    };
    logOutOnRevokeOptIns();
  }, []);

  useEffect(() => {
    const triggerLogOut = async () => {
      if (user.sessionId) {
        try {
          const isSessionValid = (
            await axios.post(
              serviceConfig.brokerService.getSessionValidity.uri,
              {
                sessionId: user.sessionId,
              }
            )
          ).data;
          if (isSessionValid === false) {
            logOut();
          }
        } catch (error) {}
      } else {
        logOut();
      }
    };
    triggerLogOut();
  }, [user.sessionId]);

  useEffect(() => {
    const socket = io(`${window.location.host}`, config.socketOptions);
    socket.on(`sessionExpired/${user.id}`, data => {
      if (
        data.message === 'sessionExpired' &&
        user.sessionId === data.sessionId &&
        data.userId === user.id
      ) {
        logOut();
        history.push('/', { logout: true });
      }
    });
    return function cleanup() {
      if (socket) socket.close();
    };
  }, []);

  useEffect(() => {
    if (notificationRef.current) {
      setNotificationOffset(
        window.innerWidth - notificationRef.current.offsetLeft - 30
      );
    }
  }, [notificationRef]);

  useEffect(() => {
    const socket = io(`${window.location.host}`, config.socketOptions);
    socket.on('updateNotificationIcon', data => {
      if (data.careTeamIds.includes(user.id))
        getNotificationCount(NotificationTypes.ALLNOTIFICATIONS);
    });
    return function cleanup() {
      if (socket) socket.close();
    };
  }, []);

  return (
    <Box className="top-bar__layout">
      <Box className="top-bar__content">
        <Box id="breadcrumb-wrapper" />
      </Box>
      <Box className="top-bar__menu">
        <TimerTemplate logOut={() => logOut()} />
        {!hidePatientDemographics &&
          topBarIcons.map(item => (
            <TopBarIcon
              key={item.name}
              name={item.name}
              onClick={item.onClick}
              variant={item.variant}
              count={item.count}
            />
          ))}
        {services['careplanservice.myoncare.care'] && (
          <TodoTopBarIconButton
            onTodosNotificationIconClick={onTodosNotificationIconClick}
          />
        )}
        {showThresholdsNotificationDropdown > 0 && (
          <ThresholdsNotificationDropdown
            offsetRight={notificationOffset}
            setShowThresholdsNotificationDropdown={
              setShowThresholdsNotificationDropdown
            }
            showThresholdsNotificationDropdown={
              showThresholdsNotificationDropdown
            }
            numThresholdsNotifications={
              numThresholdsNotifications.numThresholdsNotifications
            }
          />
        )}
        {services.thresholdNotification &&
          nonMedical === false &&
          hidePatientDemographics === false && (
            <Box
              className="top-bar__menu-item"
              ref={notificationRef}
              otherStyles={{ marginTop: '5px' }}
            >
              <button
                className="top-bar__menu-button"
                ref={dropDownThresholdRef}
                onClick={e => onThresholdsNotificationIconClick(e)}
              >
                <ThresholdNotificationIcon />
              </button>
              {numThresholdsNotifications.display && (
                <Box className="threshold-number">
                  {numThresholdsNotifications.numThresholdsNotifications}
                </Box>
              )}
            </Box>
          )}
        {showChatNotificationDropdown && (
          <ChatNotificationDropdown
            offsetRight={notificationOffset}
            setChatShowNotificationDropdown={setChatShowNotificationDropdown}
            showChatNotificationDropdown={showChatNotificationDropdown}
            numChatNotifications={numChatNotifications.numChatNotifications}
          />
        )}
        {!hidePatientDemographics && services.chatNotification && (
          <Box
            otherStyles={{ marginTop: '5px' }}
            className="top-bar__menu-item"
            ref={notificationRef}
          >
            <button
              className="top-bar__menu-button"
              ref={dropDownRef}
              onClick={e => onChatNotificationIconClick(e)}
            >
              <MessageNotificationIcon />
            </button>
            {numChatNotifications.display && (
              <Box className="threshold-number">
                {numChatNotifications.numChatNotifications}
              </Box>
            )}
          </Box>
        )}
        <Modal
          title={t('imprint.imprint')}
          headerComponent={null}
          contentComponent={
            <ProductLabelContentModal
              nonMedical={nonMedical}
              notForClinicalUse={config.not_for_clinical_use}
            />
          }
          footerComponent={null}
          openModal={isModalOpen}
          onClose={closeModal}
          hideFlashMessage={HideFlashMessage}
        />
        <Modal
          title={t('newUser_view.label_editUser')}
          contentComponent={
            <UserUpdateModalContent
              updateCurrentUser
              userData={userToUpdate}
              closeModal={closeModal}
              setProfilePicture={pic => {
                setProfilePicture(pic);
              }}
              comesFromAdministration={false}
              setDisplayRevoke={setDisplayRevoke}
              displayRevoke={displayRevoke}
              userToUpdateRole={false}
            />
          }
          footerComponent={
            <ModalFooter
              close={closeModal}
              labelCTA={t('common_buttons.update')}
              form="update-user-form"
            />
          }
          openModal={displayModal}
          onClose={closeModal}
          hideFlashMessage={HideFlashMessage}
        />
        <Box className="top-bar__menu-item">
          <Box className="top-bar__menu-button">
            <TopBarDropDownIcon
              iconName="information"
              onClick={(e, information) => {
                openInformation(information.value);
              }}
              items={informations}
            />
          </Box>
        </Box>
        <Box className="top-bar__menu-item">
          <Box className="top-bar__menu-button">
            <TopBarDropDownIcon
              iconName="language"
              onClick={(e, language) => {
                changeLanguage(language.value);
              }}
              items={languagesArray}
            />
          </Box>
        </Box>
        <Box className="top-bar__menu-profile">
          {user && (
            <Profile
              userName={`${user.firstname} ${user.lastname}`}
              role={user.role && user.role.name}
              imgSrc={user.profilePicture}
              onClick={(e, value) => {
                if (value.key === 'logout') {
                  logOut();
                } else {
                  displayEditModal(user.id);
                }
              }}
              items={items}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TopBarWrapper;
