import React from 'react';
import I18n from 'i18next';

import { toastHelper, ToastGeneric } from '@ui-common-files/components';

export const generateWarningToast = (translationKey: string) => {
  toastHelper.generic(<ToastGeneric content={I18n.t(translationKey)} />, {
    type: 'warning',
    forceAutoClose: true,
  });
};
