const deepClone = (obj: { [key: string]: unknown }) => {
  if (!obj) return obj;
  const clone: { [key: string]: any } = Array.isArray(obj) ? [] : {};
  let value;
  for (const key in obj) {
    value = obj[key];
    if (value instanceof Object && value instanceof Date === false) {
      clone[key] = deepClone(value as { [key: string]: unknown });
    } else {
      clone[key] = value;
    }
  }
  return clone;
};
export { deepClone };
