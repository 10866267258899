import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Label, 
  ToggleButton, 
  NumberInput, 
  Flex
} from '@ui-common-files/components';
import ComponentSettingsWarning from '../ComponentSettings/ComponentSettingsWarning';

import { processMinMaxValidationError } from '../CareplanHelperUtility';
import defaultEnums from '../defaultEnums';

import '../../../css/componentSettings.css';

const NumericValueComponent = ({
  searchTermComponents,
  componentCollection,
  setComponentCollection,
  isNegativeWarningVisible = false,
  setShowPromptForAdjustScoring,
}) => {
  const {t} = useTranslation();

  const getMatchedPosition = () => {
    return componentCollection.findIndex(elem => {
      return elem.id == searchTermComponents.id;
    });
  };

  const getCareplanComponentId = () => {
    return componentCollection[getMatchedPosition()].searchTerm
      .careplanComponentId;
  };

  const getComponentSettingsElementValue = key => {
    let value;
    switch (key) {
      case 'getDecimalToggle':
        value = getCareplanComponentId()
          ? !!componentCollection[getMatchedPosition()].searchTerm.decimal
          : !!componentCollection[getMatchedPosition()].decimal;
        break;
      case 'getMinimumValue':
        value = getCareplanComponentId()
          ? componentCollection[getMatchedPosition()].searchTerm.minimumValue
          : componentCollection[getMatchedPosition()].minimumValue;
        break;
      case 'getMaximumValue':
        value = getCareplanComponentId()
          ? componentCollection[getMatchedPosition()].searchTerm.maximumValue
          : componentCollection[getMatchedPosition()].maximumValue;
        break;
      default:
        break;
    }
    return value;
  };

  const [minValue,setMinValue] = useState(getComponentSettingsElementValue('getMinimumValue'));
  const [maxValue,setMaxValue] = useState(getComponentSettingsElementValue('getMaximumValue'));

  const handleMaxNumberOnChange = value => {
    if (value.split('.')[1] && value.split('.')[1].length > 3) return;
    const matchingComponent = componentCollection[getMatchedPosition()];
    if (
      componentCollection[getMatchedPosition()].searchTerm.careplanComponentId
    ) {
      processMinMaxValidationError(
        componentCollection[getMatchedPosition()].searchTerm.minimumValue,
        value,
        matchingComponent,
      );
      componentCollection[getMatchedPosition()].searchTerm.maximumValue = value;
      setMaxValue(value);
    } else {
      processMinMaxValidationError(
        componentCollection[getMatchedPosition()].minimumValue,
        value,
        matchingComponent,
      );
      componentCollection[getMatchedPosition()].maximumValue = value;
      setMaxValue(value);
    }
    setComponentCollection([...componentCollection]);
    setShowPromptForAdjustScoring(prevState => ({
      ...prevState,
      checkUserDeleteAddOrChangeScoreOfComponent: true,
    }));
  };

  const handleMinNumberOnChange = value => {
    if (value.split('.')[1] && value.split('.')[1].length > 3) return;
    const matchingComponent = componentCollection[getMatchedPosition()];
    if (
      componentCollection[getMatchedPosition()].searchTerm.careplanComponentId
    ) {
      processMinMaxValidationError(
        value,
        componentCollection[getMatchedPosition()].searchTerm.maximumValue,
        matchingComponent,
      );
      componentCollection[getMatchedPosition()].searchTerm.minimumValue = value;
      setMinValue(value);
    } else {
      processMinMaxValidationError(
        value,
        componentCollection[getMatchedPosition()].maximumValue,
        matchingComponent,
      );
      componentCollection[getMatchedPosition()].minimumValue = value;
      setMinValue(value);
    }
    setComponentCollection([...componentCollection]);
  };
  
  const handleNumericRangeToggleButtonsChanged = () => {
    if (
        componentCollection[getMatchedPosition()].searchTerm
        .careplanComponentId
        ) {
          componentCollection[getMatchedPosition()].searchTerm.decimal =
            !componentCollection[getMatchedPosition()].searchTerm.decimal;
        } else {
          componentCollection[getMatchedPosition()].decimal =
            !componentCollection[getMatchedPosition()].decimal;
        }
   
    const isDecimalToggleChecked =getComponentSettingsElementValue('getDecimalToggle');
    if (!isDecimalToggleChecked) {
      const defaultMinValue = defaultEnums.DEFAULT_NUMERIC_VALUE_MIN_VALUE;
      const defaultMaxValue = defaultEnums.DEFAULT_NUMERIC_VALUE_MAX_VALUE;

      if (
        componentCollection[getMatchedPosition()].searchTerm.careplanComponentId
      ) {
        componentCollection[getMatchedPosition()].searchTerm.minimumValue =
          defaultMinValue;
        componentCollection[getMatchedPosition()].searchTerm.maximumValue =
          defaultMaxValue;
      } else {
        componentCollection[getMatchedPosition()].minimumValue =
          defaultMinValue;
        componentCollection[getMatchedPosition()].maximumValue =
          defaultMaxValue;
      }
      setMaxValue(defaultMaxValue);
      setMinValue(defaultMinValue);
    }
    setComponentCollection([...componentCollection]);
  };

  const manipulateDecimalToggleButton = labelText => {
    return (
      <ToggleButton
        id={labelText}
        checked={getComponentSettingsElementValue('getDecimalToggle')}
        handleOnChange={e =>
          handleNumericRangeToggleButtonsChanged('handleDecimalToggle')
        }
      />
    );
  };

  const renderMinNumberNumberInput = () => {
    return (
      <Flex className='toggleAndTextContainer label-toggle-content__component-settings'>
        <div style={{ width: '100%' }}>
          <NumberInput
            value={getComponentSettingsElementValue('getMinimumValue')}
            name='minimumNumber'
            min={defaultEnums.NUMERIC_VALUE_MIN_VALUE}
            max={defaultEnums.NUMERIC_VALUE_MAX_VALUE}
            step={1}
            placeholder='0'
            isFloatingNumber={getComponentSettingsElementValue(
              'getDecimalToggle'
            )}
            isNegativeNumber
            changeValue={e => handleMinNumberOnChange(e)}
          />
        </div>
      </Flex>
    );
  };

  const renderMaxNumberNumberInput = () => {
    const matchingComponent = componentCollection[getMatchedPosition()];
    let hasMinMaxValidationError = matchingComponent.searchTerm.minMaxValidationError;
    return (
      <Flex className='toggleAndTextContainer label-toggle-content__component-settings'>
        <div style={{ width: '100%' }}>
          <NumberInput
            value={getComponentSettingsElementValue('getMaximumValue')}
            name='maximumNumber'
            min={defaultEnums.NUMERIC_VALUE_MIN_VALUE}
            max={defaultEnums.NUMERIC_VALUE_MAX_VALUE}
            step={1}
            placeholder='0'
            hasError={!!hasMinMaxValidationError}
            validationMessage={
              !!hasMinMaxValidationError
                ? t('newQuestion_view.maxMinValidation')
                : ''
            }
            isFloatingNumber={getComponentSettingsElementValue(
              'getDecimalToggle'
            )}
            isNegativeNumber
            changeValue={e => handleMaxNumberOnChange(e)}
          />
        </div>
      </Flex>
    );
  };

  const renderLabelWithToggleButton = labelText => {
    return (
      <>
        <Flex className='toggleAndTextContainer' justifyContent='space-between'>
          <div>
            <Label text={labelText} />
          </div>
          <div>
            {labelText === t('previewQuestion.floatingPointNumber') &&
              manipulateDecimalToggleButton(labelText)}
            {labelText === t('previewQuestion.minValue')}
            {labelText === t('previewQuestion.maxValue')}
          </div>
        </Flex>
      </>
    );
  };

  const renderNumericValueComponent = () => {
    return (
      <>
        <span className='lineBetweenToggleContainers' />
        {renderLabelWithToggleButton(
          t('previewQuestion.floatingPointNumber')
        )}
        <span className='lineBetweenToggleContainers' />
        {renderLabelWithToggleButton(t('previewQuestion.minValue'))}
        {renderMinNumberNumberInput()}
        <span className='lineBetweenToggleContainers' />
        {renderLabelWithToggleButton(t('previewQuestion.maxValue'))}
        {renderMaxNumberNumberInput()}
        {isNegativeWarningVisible && <ComponentSettingsWarning />}
      </>
    );
  };

  return renderNumericValueComponent();
};

export default NumericValueComponent;
