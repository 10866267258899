import React from 'react';
import PropTypes from 'prop-types';

const Progressbar = ({ percent, fill }) => {
  const progressPercent = percent > 100 ? 100 : percent < 0 ? 0 : percent;
  return (
    <div className="progressbar__wrapper">
      <div className="progressbar">
        <div
          className="progressbar__filler"
          style={{
            width: `${progressPercent}%`,
            backgroundColor: fill,
          }}
        />
      </div>
      <span
        className="progressbar__percent"
        style={{ color: fill }}
      >{`${progressPercent}%`}</span>
    </div>
  );
};

Progressbar.propTypes = {
  percent: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
};

export default Progressbar;
