/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@ui-common-files/utils';
import { useAbortController } from '@global/Hooks';
import { ShowFlashMessage } from '@global/Services';
import { prepareRowsForDatatables } from '../CareplanHelperUtility';
import { calcCurrentPage } from '../../../Utils/DataTableUtilities';
import {
  deleteCaretaskRequest,
  getReviewAssessments,
  getTemplateCaretasks,
} from './ReviewAssessments.api';
import { ReviewAssessmentServiceProps } from './ReviewAssessments.type';
import useAnalytics from '../../../Global/Services/Analytics/UseAnalytics';

export default function useReviewAssessmentsService({
  setServices,
  reloadTable,
  setReloadTable,
}: ReviewAssessmentServiceProps) {
  const [value, setValue] = useState('');
  const [query, setQuery] = useState({
    column: 'name',
    currentPage: 0,
    dir: 'asc',
    length: 10,
    isReviewAssessment: true,
  });
  const [deleteOperation, setDeleteOperation] = useState({
    display: false,
    id: 0,
    title: '',
  });
  const { trackEventTrigger } = useAnalytics();
  const [caretaskTemplatesData, setCaretaskTemplatesData] = useState([]);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [responsive, setResponsive] = useState(false);
  const { getAbortController } = useAbortController();
  const { t } = useTranslation();
  const debouncedValue = useDebounce(value);

  const fetchCaretaskAssessments = async () => {
    setLoading(true);
    const queryData = {
      ...query,
      start: query.currentPage * query.length,
      value,
    };
    const { signal } = getAbortController();
    getReviewAssessments(queryData, signal)
      .then(({ data }) => {
        setServices(data.services);
        setCaretaskTemplatesData(prepareRowsForDatatables(data.data));
        setRecordsTotal(data.recordsTotal);
      })
      .catch(err => console.log({ err }))
      .finally(() => setLoading(false));
  };

  const onDelete = async (templateId, templateTitle) => {
    trackEventTrigger({
      category: 'caretask',
      action: 'Open-Delete-Modal',
    });
    const caretaskCount = await getTemplateCaretasks(templateId);
    if (caretaskCount === 0) {
      setDeleteOperation({
        display: true,
        id: templateId,
        title: `${t(
          'common_labels.msg_confirm_template_delete_headline'
        )}: ${templateTitle}`,
      });
    } else if (caretaskCount !== undefined) {
      trackEventTrigger({
        category: 'caretask',
        action: 'Delete-Failed',
      });
      ShowFlashMessage({
        type: 'warning',
        messageKey: 'warningMessage.msg_template_associated_careplan',
      });
    }
  };

  const deleteCaretask = async (id: number) => {
    const response = await deleteCaretaskRequest(id);
    if (response) {
      const page = calcCurrentPage(
        recordsTotal - 1,
        query.length,
        query.currentPage
      );
      if (page !== query.currentPage) {
        setQuery({
          dir: query.dir,
          column: query.column,
          currentPage: page,
          length: query.length,
          isReviewAssessment: true,
        });
      } else {
        setReloadTable(!reloadTable);
      }
      ShowFlashMessage({
        type: response.data.type,
        messageKey: response.data.content,
      });
      setDeleteOperation({
        display: false,
        id: 0,
        title: '',
      });
      trackEventTrigger({
        category: 'caretask',
        action: 'Delete-Successful',
      });
    } else {
      trackEventTrigger({
        category: 'caretask',
        action: 'Delete-Failed',
      });
    }
  };
  const screenSize = useCallback(() => {
    const isResponsive = window.innerWidth <= 1366;
    setResponsive(isResponsive);
  }, [responsive]);

  const closeMessagePrompt = () => {
    trackEventTrigger({
      category: 'caretask',
      action: 'Close-Delete-Modal',
    });
    setDeleteOperation({
      display: false,
      id: 0,
      title: '',
    });
  };

  const onSearch: ChangeEventHandler<HTMLInputElement> = e =>
    setValue(e.target.value);

  const onTableChange = (page: number, length: number) => {
    const newPage =
      recordsTotal > 0 ? calcCurrentPage(recordsTotal, length, page) : page;
    setQuery(oldQuery => ({
      ...oldQuery,
      currentPage: newPage,
      length,
    }));
  };

  useEffect(() => {
    screenSize();
    window.addEventListener('resize', screenSize);
    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [screenSize]);

  useEffect(() => {
    fetchCaretaskAssessments();
  }, [query, reloadTable, debouncedValue]);

  return {
    query,
    value,
    caretaskTemplatesData,
    loading,
    recordsTotal,
    onDelete,
    deleteOperation,
    closeMessagePrompt,
    deleteCaretask,
    onSearch,
    onTableChange,
    t,
  };
}
