import React from 'react';
import { useTranslation } from 'react-i18next';

import { Action, Box, Flex, ModalContent } from '@ui-common-files/components';

import useProductLabelContentModalService from './ProductLabelContentModal.service';
import { productLabelIcons } from './ProductLabelContentModalService.util';
import { isBeta } from '../../../config';

import { ProductLabelContentModalParams } from './ProductLabelContentModal.type';

import styles from './ProductLabelContentModal.module.scss';

const ProductLabelContentModal = ({
  nonMedical,
  notForClinicalUse,
}: ProductLabelContentModalParams) => {
  const { t } = useTranslation();
  const { isMedical, getUserGuideLink } = useProductLabelContentModalService({
    nonMedical,
  });

  if (isBeta)
    return (
      <Flex className={styles.container}>
        {productLabelIcons.logo}
        <Box className="product-label__title__right-col">
          {t('imprint.beta_warning')}
        </Box>
      </Flex>
    );

  return (
    <ModalContent>
      <Flex className={styles.container}>
        {productLabelIcons.logo}
        <Box className={styles.container__title}>myoncare</Box>
        {isMedical && <Box>{t('imprint.MedicalDeviceDataSystem')}</Box>}
        <Flex alignItems="center">
          <Box className={styles.container__iconContainer}>
            {productLabelIcons.version}
          </Box>
          <Box>{t('imprint.product_version')}</Box>
        </Flex>
        <Flex alignItems="center">
          <Box className={styles.container__iconContainer}>
            {productLabelIcons.productNumber}
          </Box>
          <Box>
            {t(`imprint.product_number${!isMedical ? '_nonMedical' : ''}`)}
          </Box>
        </Flex>
        {isMedical && (
          <Flex alignItems="center">
            <Box className={styles.container__iconContainer}>
              {productLabelIcons.UDI}
            </Box>
            <Box>{t('imprint.UDI')}</Box>
          </Flex>
        )}

        <Flex alignItems="center">
          <Box className={styles.container__iconContainer}>
            {productLabelIcons.manufacturingDate}
          </Box>
          <p>{t('imprint.product_manufacutring_date')}</p>
        </Flex>

        <Flex>
          <Box className={styles.container__iconContainer}>
            {productLabelIcons.manufacturingAddress}
          </Box>
          <Box otherStyles={{ whiteSpace: 'pre-line' }}>
            {t('imprint.manufacturing_address')}
          </Box>
        </Flex>
        <Flex>
          <Box className={styles.container__iconContainer}>
            {productLabelIcons.info}
          </Box>
          <Box otherStyles={{ whiteSpace: 'pre-line' }}>
            <Action
              className={styles.container__link}
              href={getUserGuideLink()}
              target="_blank"
            >
              {t('imprint.info')}
            </Action>
          </Box>
        </Flex>
        <Flex alignItems="center">
          {isMedical && (
            <>
              <Box className={styles.container__iconContainer}>
                {productLabelIcons.MD}
              </Box>
              {!notForClinicalUse && (
                <Flex flexDirection="row">
                  <Box otherStyles={{ marginRight: '10px' }}>
                    {productLabelIcons.ceMarked}
                  </Box>
                  <Box className={styles.container__ceMarked}>
                    {!notForClinicalUse
                      ? t('imprint.ceMarked')
                      : `${t('imprint.ceMarked')} - NOT FOR CLINICAL USE`}
                  </Box>
                </Flex>
              )}
              <Box className={styles.container__iconContainer}>
                {productLabelIcons.rxOnly}
              </Box>
            </>
          )}
        </Flex>
        {isMedical && (
          <Flex alignItems="center">
            <Box className={styles.container__iconContainer__chrep}>
              {productLabelIcons.CHREP}
            </Box>
            <Box
              otherStyles={{
                whiteSpace: 'pre-line',
              }}
            >
              {t('imprint.CHREP')}
            </Box>
          </Flex>
        )}
      </Flex>
    </ModalContent>
  );
};

export default ProductLabelContentModal;
