import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import 'jQuery-QueryBuilder';
import $ from 'jquery';

import '../../styles/main.scss';
import '../../styles/bootstrap/bootstrap-datepicker-query-builder';
import '../../styles/bootstrap/bootstrap-datepicker3.min.css';

import moment from 'moment';

const ComplexSearch = ({
  filters,
  rules,
  getSql,
  getRules = null,
  hide,
  reset = null,
  hasError = null,
  validate = null,
  onRuleChange = null,
}) => {
  const modifyRulesForDateValue = rule => {
    const modifiedRules = rule;
    for (const i in modifiedRules) {
      const getRulesTemp = modifiedRules[i];
      for (const j in getRulesTemp) {
        if (getRulesTemp[j].rules) {
          modifyRulesForDateValue(getRulesTemp[j]);
        } else {
          if (getRulesTemp[j].id == 'reminders.startsAt') {
            modifiedRules[i][j].value = new moment().format('YYYY-MM-DD');
          }
          if (
            getRulesTemp[j].id ==
            'dateToday(FROM_UNIXTIME(allOccurrences.unixEpochs))'
          ) {
            modifiedRules[i][j].value = new moment().format('YYYY-MM-DD');
          }

          if (getRulesTemp[j].id == 'answers.answerForDateToday') {
            modifiedRules[i][j].value = new moment().format('YYYYMMDD');
          }
        }
      }
    }
    return modifiedRules;
  };

  useEffect(() => {
    if (onRuleChange) {
      $('#builder').on('afterCreateRuleInput.queryBuilder', function (e, rule) {
        onRuleChange(rule);
      });
    }
    $('#builder').queryBuilder({
      filters,
      plugins: ['unique-filter'],
    });
    if (hide) $('#builder').hide();
  }, []);

  useEffect(() => {
    if (rules)
      $('#builder').queryBuilder(
        'setRules',
        modifyRulesForDateValue(
          typeof rules == 'string' ? JSON.parse(rules) : rules
        )
      );
    if (reset) $('#builder').queryBuilder('reset');

    const sql = $('#builder').queryBuilder('getSQL', 'question_mark');

    getSql(sql);
  }, [rules, reset]);

  useEffect(() => {
    if (validate) {
      const isValid = $('#builder').queryBuilder('validate');
      hasError(!isValid);
      if (isValid) {
        const sql = $('#builder').queryBuilder('getSQL', 'question_mark');
        const rule = $('#builder').queryBuilder('getRules');

        getSql(sql);
        getRules(modifyRulesForDateValue(rule));
      } else {
        getSql(null);
        getRules(null);
      }
    }
  }, [validate]);

  return <div className="query-builder" id="builder" />;
};

ComplexSearch.propTypes = {
  /** Same Props as used in JQuery-QueryBuilder Library */
  filters: PropTypes.array,
  rules: PropTypes.object,
  getSql: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  reset: PropTypes.bool,
  hasError: PropTypes.func,
  validate: PropTypes.bool,
  onRuleChange: PropTypes.func,
};

export default ComplexSearch;
