import { ApiClient } from '@global/Services';
import serviceConfig from '../../../serviceConfig.json';
import {
  ReviewAssessmentsResponse,
  ReviewAssessmentPayload,
} from './ReviewAssessments.type';

export const getReviewAssessments = ({
  search: value,
  limit,
  offset,
  signal,
}: {
  search?: string;
  limit: number;
  offset: number;
  signal: AbortSignal;
}) =>
  ApiClient.GET<ReviewAssessmentsResponse, ReviewAssessmentPayload>({
    url: serviceConfig.brokerService.getReviewAssessments.uri,
    payload: {
      value,
      limit,
      offset,
    },
    signal,
  });

export const getCareplanReviewToAnswer = (
  assetId,
  patientId,
  reviewAssessmentTemplateId
) =>
  ApiClient.GET({
    url: `${serviceConfig.brokerService.assignReviewAssessment.uri}${assetId}/${patientId}/${reviewAssessmentTemplateId}`,
  });
