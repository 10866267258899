import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@ui-common-files/components';
import { greysColorVars } from '@ui-common-files/utils';

import { CustomBarProps } from '../../../Overview.type';
import { BAR_HEIGHT } from '../../OverviewMedicationChart.constant';
import useOverviewMedicationCustomBarService from './CustomBar.service';

import './CustomBar.styles.scss';
import {
  foreignObjectXPosition,
  foreignObjectYPosition,
} from '../../OverviewMedicationChart.utils';

const CustomBar = ({ payload, x, y, width, background }: CustomBarProps) => {
  const { t } = useTranslation();
  const {
    xPosition,
    barWidth,
    availableWidth,
    alignRight,
    foreignObjectRef,
  } = useOverviewMedicationCustomBarService({
    payload,
    x,
    y,
    width,
    background,
  });

  return (
    <g>
      <rect
        x={xPosition}
        y={y}
        width={barWidth}
        height={BAR_HEIGHT}
        fill={greysColorVars.WHITE}
      />
      <foreignObject
        ref={foreignObjectRef}
        x={foreignObjectXPosition(xPosition)}
        y={foreignObjectYPosition(y)}
        width={availableWidth}
        height={BAR_HEIGHT}
      >
        <Box
          className="medication-custombar"
          otherStyles={{
            maxWidth: `${availableWidth}px`,
            textAlign: alignRight ? 'right' : 'left',
          }}
        >
          <Box className="medication-bar-name-text">{payload.name}</Box>
          <Box className="medication-bar-recurrence-text">{`${t('medication.period')}: ${
            payload.recurrence
          }`}</Box>
        </Box>
      </foreignObject>
    </g>
  );
};

export default CustomBar;
