/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import '../../styles/main.scss';

const TimePicker = ({
  getTimeStamp,
  isDisabled,
  value,
  minuteIncrement,
  isMinuteDisabled,
  hasError,
  validationMessage,
}) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [hours, setHours] = useState(
    value ? value.hour : new Date().getHours()
  );
  const [minutes, setMinutes] = useState(
    value ? value.minute : new Date().getMinutes()
  );
  const [dropUp, setDropUp] = useState('');
  const inputRef = useRef();
  const timerRef = useRef();
  const [isTimeUpdated, setIsTimeUpdated] = useState(false);

  const timerClass = isMinuteDisabled
    ? 'timepicker__timer-hour-only'
    : 'timepicker__timer';

  const hideDropdownMenu = e => {
    if (
      !e.target ||
      (e.target && !e.target.className) ||
      (e.target &&
        e.target.className &&
        e.target.className.indexOf('timepicker') === -1)
    ) {
      setDisplayMenu(false);
    }
  };

  const retrieveTimeStamp = useCallback(() => {
    getTimeStamp({ hour: hours, minute: minutes });
  }, [hours, minutes]);

  useEffect(() => {
    retrieveTimeStamp();
  }, [retrieveTimeStamp]);

  useEffect(() => {
    if (value) {
      if (value.hour >= 0) setHours(value.hour);
      if (!isMinuteDisabled) setMinutes(value.minute ? value.minute : 0);
    }
  }, [value]);

  const increaseHours = () => {
    if (!isTimeUpdated) setIsTimeUpdated(true);
    if (hours < 23) {
      setHours(hours + 1);
    } else {
      setHours(0);
    }
  };

  const increaseMinutes = () => {
    if (!isTimeUpdated) setIsTimeUpdated(true);
    if (minutes < 60 - minuteIncrement) {
      setMinutes(minutes + minuteIncrement);
    } else {
      setMinutes(minutes + minuteIncrement - 60);
      increaseHours();
    }
  };

  const decreaseHours = () => {
    if (!isTimeUpdated) setIsTimeUpdated(true);
    if (hours > 0) {
      setHours(hours - 1);
    } else {
      setHours(23);
    }
  };

  const decreaseMinutes = () => {
    if (!isTimeUpdated) setIsTimeUpdated(true);
    if (minutes >= minuteIncrement) {
      setMinutes(minutes - minuteIncrement);
    } else {
      setMinutes(60 - minuteIncrement + minutes);
      decreaseHours();
    }
  };

  const timerDrowUp = () => {
    const timerBottom =
      inputRef.current.getBoundingClientRect().top +
      inputRef.current.clientHeight +
      timerRef.current.clientHeight +
      5;

    if (timerBottom >= window.innerHeight) {
      setDropUp(`${timerClass}__dropUp`);
    } else {
      setDropUp('');
    }
  };

  const showDropdownMenu = () => {
    if (displayMenu) {
      document.addEventListener('resize', timerDrowUp);
      document.addEventListener('scroll', timerDrowUp);
      document.addEventListener('mousedown', hideDropdownMenu);
    }
  };

  const onInpuClicked = () => {
    if (!isDisabled) {
      setDisplayMenu(!displayMenu);
    }
  };

  const getDisplayTime = () => {
    const time = `${hours < 10 ? '0' : ''}${hours}`;
    return isMinuteDisabled
      ? `${time} : 00`
      : `${time} : ${minutes < 10 ? '0' : ''}${minutes}`;
  };

  useEffect(() => {
    showDropdownMenu();
    return () => {
      document.removeEventListener('resize', timerDrowUp);
      document.removeEventListener('scroll', timerDrowUp);
      document.removeEventListener('mousedown', hideDropdownMenu);
    };
  }, [displayMenu]);

  const timer = () => (
    <div className={`${timerClass} ${dropUp}`} ref={timerRef}>
      <div className={`${timerClass}--column`}>
        <span
          className="timepicker__navigation--button"
          onClick={increaseHours}
          role="presentation"
        >
          <span className="timepicker__navigation timepicker__navigation--up" />
        </span>
        {`${hours < 10 ? '0' : ''}${hours}${isMinuteDisabled ? ' : 00' : ''}`}
        <span
          className="timepicker__navigation--button"
          onClick={decreaseHours}
          role="presentation"
        >
          <span className="timepicker__navigation timepicker__navigation--down" />
        </span>
      </div>
      {!isMinuteDisabled && (
        <>
          :
          <div className={`${timerClass}--column`}>
            <span
              className="timepicker__navigation--button"
              onClick={increaseMinutes}
              role="presentation"
            >
              <span className="timepicker__navigation timepicker__navigation--up" />
            </span>
            {`${minutes < 10 ? '0' : ''}${minutes}`}
            <span
              className="timepicker__navigation--button"
              onClick={decreaseMinutes}
              role="presentation"
            >
              <span className="timepicker__navigation timepicker__navigation--down" />
            </span>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="timepicker" ref={inputRef}>
      <div
        className="timepicker__dropdown"
        onClick={() => onInpuClicked()}
        role="presentation"
      >
        <input
          className={`timepicker__dropdown--input${hasError ? '--error' : ''} ${
            isDisabled ? 'disabled' : ''
          }`}
          type="text"
          value={getDisplayTime()}
          disabled={isDisabled}
          readOnly
        />
      </div>
      {displayMenu && !isDisabled ? timer() : null}
      {hasError && validationMessage && (
        <span className="react-datepicker__error">{validationMessage}</span>
      )}
    </div>
  );
};

TimePicker.propTypes = {
  getTimeStamp: PropTypes.func.isRequired,
  value: PropTypes.objectOf(PropTypes.number),
  minuteIncrement: PropTypes.number,
  isMinuteDisabled: PropTypes.bool,
};

TimePicker.defaultProps = {
  value: { hour: 9, minute: 0 },
  minuteIncrement: 1,
  isMinuteDisabled: false,
};

export default TimePicker;
