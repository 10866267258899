import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useInteractiveTable } from '@global/Hooks';
import { formatDate } from '@global/Date';
import { Greys, ActionColors } from '@ui-common-files/utils';
import { getAllTaggedUsers, setNoteNotificationToRead } from './TaggedUsersList.api';
import { navigation } from '@utils';

const getTaggedUsers = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [fetchedData, setFetchedData] = useState([]);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleRowClick = async (row) => {
    const { userTaggedNoteId, patientId } = row._original
    history.push({
      pathname: `/${navigation.PATIENT_OVERVIEW}/${patientId}`,
      state: {
        isTaggedUser: true,
        userTaggedNoteId,
      },
    });
  };

  const tableProps = {
    getTrProps: (state, rowInfo) => {
      if (rowInfo && rowInfo.row) {
        return {
          style: {
            cursor: 'pointer',
            color: rowInfo.row.status
              ? Greys.DARK_GREY
              : ActionColors.THRESHOLD,
            fontSize: '16px'
          },
          onClick: () => handleRowClick(rowInfo.row),
        };
      }
      return {};
    },

    previousText: t('datatable.previous'),
    nextText: t('datatable.next'),
    noDataText: t('common_labels.label_noData'),
    pageText: t('datatable.showing'),
    ofText: t('datatable.of'),
    rowsText: t('datatable.entries'),
    rowsSelectorText: t('datatable.recordsPerPage'),
  };

  const Rows = async (data: Array<object>) => {
    const rows: Array<object> = [];

    data?.forEach(item => {
      if (item) {
        const medicalRecord = item.note.patient.medicalRecord || '';
        const patientName = (item.note.patient.firstname && item.note.patient.lastname) ?
          `${item.note.patient.firstname} ${item.note.patient.lastname}` : '';
        rows.push({
          userTaggedNoteId: item.id,
          status: item.isSeen,
          hcpName: `${item.note.user.firstname} ${item.note.user.lastname}`,
          hcpRole: item.note.user.role.name,
          patientName,
          medicalRecord,
          message: item.note.text,
          timeStamp: formatDate(item.createdAt, 'DD MMM YYYY HH:mm'),
          patientId: item.note.patient.id
        });
      }
    });

    return rows;
  };

  const fetchData = async controller => {
    setLoading(true);
    const response = await getAllTaggedUsers(controller, {
      offset: query.start,
      limit: query.length,
      value: query.value
    });
    if (response?.data) {
      setFetchedData(await Rows(response.data.rows));
      setRecordsTotal(response.data.totalCount);
    }
    setLoading(false);
  };

  const {
    query,
    currentPage,
    handleSearch,
    handleTableOnChange,
  } = useInteractiveTable(fetchData, false, recordsTotal);

  return {
    fetchedData,
    recordsTotal,
    loading,
    tableProps,
    query,
    currentPage,
    handleSearch,
    handleTableOnChange,
  };
};

export default getTaggedUsers;
