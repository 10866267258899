import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../styles/main.scss';

const Tab = ({ activeTab, label, onClick, tabId, ...props }) => {
  const handleOnClick = () => {
    onClick(tabId);
  };

  const itemTabClass = classNames({
    'tab-list__item': true,
    'tab-list__item--active': activeTab === tabId,
  });

  return (
    <li
      aria-label="tab"
      className={itemTabClass}
      onClick={handleOnClick}
      onPointerDown={handleOnClick}
      id={tabId}
      {...props}
    >
      {label}
    </li>
  );
};

Tab.propTypes = {
  activeTab: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tabId: PropTypes.number.isRequired,
};

export default Tab;
