/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../css/role.css';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';

import { ApiClient, HideFlashMessage } from '../../Global/Services';

import Modal from '../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import TextInput from '../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Dropdown from '../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import RoleMatrix from './roleMatrix.js';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';

import NavigationJSON from '../../Utils/navigation.json';
import serviceConfig from '../../../serviceConfig.json';
import RoleHelperUtility from '../../Utils/RoleHelperUtility.js';

import { ButtonVariant } from '../../../caro-ui-commonfiles/utils/button';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import Checkbox from '../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import CheckBoxRadioVariants from '../../../caro-ui-commonfiles/utils/CheckboxRadio';
import {
  MODAL_VARIANT,
  MODAL_FORM_DIRECTION,
} from '../../Utils/Layout/modalSection';
import roles from '../../Utils/roles.json';
let response;

const EditRole = props => {
  const { user } = useSelector(state => state.session);
  const [prefilledRoles, setPrefilledRoles] = useState({});
  const [userRole, setUserRole] = useState('');
  const [roleName, setRoleName] = useState('');
  const [allowAllSites, setAllowAllSites] = useState(false);
  const [isPatientDemographicData, setIsPatientDemographicData] =
    useState(false);
  const [actions, setActions] = useState([]);
  const [actionsKeys, setActionsKeys] = useState([]);
  const [componentTypes, setComponentTypes] = useState([]);
  const [componentActions, setComponentActions] = useState([]);
  const [allActionCheckBoxes, setAllActionCheckBoxes] = useState([]);
  const [selectedActionCheckBoxes, setSelectedActionCheckBoxes] = useState([]);
  const [selectedComponentCheckBoxes, setSelectedComponentCheckBoxes] =
    useState([]);
  const [validationError, hasValidationError] = useState(false);
  const { roleId } = props;

  const HeaderComponent = () => {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        otherStyles={{ width: '100%' }}
      >
        <Flex>
          <Box width="50%">
            <ComponentWithLabel
              id="roleName"
              htmlFor="roleName"
              labelText={`* ${I18n.t('rolemanager_view.label_roleName')}`}
            >
              <TextInput
                value={roleName}
                id="roleName"
                name="roleName"
                placeholder={I18n.t('rolemanager_view.add_roleName')}
                hasError={validationError}
                validationMessage={
                  roleName.trim() ? '' : I18n.t('parsley.requiredField')
                }
                handleOnChange={onChangeRoleNameText}
                disabled={
                  roleName === roles.SUPER_ADMIN ||
                  roleName === roles.SITE_ADMIN ||
                  roleName === roles.DOCTOR ||
                  roleName === roles.NURSE
                }
              />
            </ComponentWithLabel>
          </Box>

          <Box width="50%">
            <ComponentWithLabel
              id="rolePreset"
              htmlFor="rolePreset"
              labelText={I18n.t('rolemanager_view.label_presetRole')}
            >
              <Dropdown
                value={{ value: userRole, label: userRole }}
                options={prefilledRoles}
                placeholder={false}
                handleOnChange={changeRole}
                isDisabled={user.role.name !== roles.SUPER_ADMIN}
              />
            </ComponentWithLabel>
          </Box>
        </Flex>

        <Box className="allow_all_sites_checkbox">
          {roleName !== roles.SUPER_ADMIN && (
            <Checkbox
              id="allowAllSites"
              label={I18n.t('rolemanager_view.label_hideAllSites')}
              variant={CheckBoxRadioVariants.DEFAULT}
              checked={allowAllSites}
              handleOnChange={e => onValueChange(e.target.checked)}
            />
          )}
        </Box>
        <Box className="allow_all_sites_checkbox">
          <Checkbox
            id="isPatientDemographicData"
            label={I18n.t('rolemanager_view.label_allowPatientDemographics')}
            variant={CheckBoxRadioVariants.DEFAULT}
            checked={isPatientDemographicData}
            handleOnChange={e =>
              onPatientDemographicsValueChange(e.target.checked)
            }
          />
        </Box>
      </Flex>
    );
  };

  const Content = () => (
    <ModalContent>
      <ModalForm
        formId="edit-role-form"
        handleFormSubmit={e => {
          e.preventDefault();
        }}
        direction={MODAL_FORM_DIRECTION.COLUMN}
      >
        {props.shouldSpin && (
          <div className="roleOverlapSpinner">
            <Loader
              type="Oval"
              visible={props.shouldSpin}
              color={PrimaryColor.MAIN_COLOR}
              height={60}
              width={60}
            />
          </div>
        )}

        <ModalSection hasColumnDirection variant={MODAL_VARIANT.TOP}>
          {HeaderComponent()}
        </ModalSection>

        <ModalSection
          hasOverflow
          hasColumnDirection
          variant={MODAL_VARIANT.BOTTOM}
        >
          <RoleMatrix
            value={{
              response,
              actions,
              actionsKeys,
              componentTypes,
              allActionCheckBoxes,
              selectedActionCheckBoxes,
              componentActions,
              selectedComponentCheckBoxes,
            }}
            setAllActionCheckBoxes={setAllActionCheckBoxes}
            setSelectedActionCheckBoxes={setSelectedActionCheckBoxes}
            setSelectedComponentCheckBoxes={setSelectedComponentCheckBoxes}
            setActionCheckBoxesOnChange={setActionCheckBoxesOnChange}
          />
        </ModalSection>
      </ModalForm>
    </ModalContent>
  );

  const getRoleMatrixData = () => {
    const roleMatrixData = [];
    const { permissions } = response.data.componentActions;
    componentTypes.forEach(componentType => {
      const componentTypeId = componentType.id;
      let binaryString = 0;
      const actionsForType = selectedActionCheckBoxes.filter(action => {
        const componentName = action.actionCheckboxId.split('_');
        return (
          componentName[0] == I18n.t(componentType.name).replace(/\s/g, '')
        );
      });
      actionsForType.forEach(elem => {
        if (elem.isChecked) {
          const matchedPermission = permissions.find(
            permission =>
              permission.action === elem.actionCheckboxId.split('_')[1]
          );
          binaryString += parseInt(matchedPermission.permissionBitValue);
        }
      });
      roleMatrixData.push({ binaryString, componentTypeId });
    });
    return roleMatrixData;
  };

  const handleFlashMessages = response => {
    if (response) {
      props.setFlashMessage(response.data);
    }
  };

  const validateUpdatedRoleResponse = response => {
    if (response.status == 200 && response.data.type == 'success') {
      const matchedPosition = props.fetchedData.findIndex(elem => {
        return elem.roleId == response.data.updatedRole.id;
      });
      props.fetchedData[matchedPosition].roleName =
        response.data.updatedRole.name;
      props.setFetchedData(props.fetchedData);
      props.setPromptMessage({
        display: true,
        id: -1,
        title: I18n.t('common_labels.msg_role_update_headline'),
        message: I18n.t('common_labels.msg_role_update'),
      });
    }
  };

  const updateRole = async close => {
    try {
      if (roleName.trim()) {
        const matrixObject = getRoleMatrixData();
        const response = await ApiClient.POST({
          url: serviceConfig.brokerService.updateRole.uri + roleId,
          payload: {
            matrixObject,
            roleName,

            userLanguage: I18n.language,
            allowAllSites,
            isPatientDemographicData,
          },
        });
        const rolePermissions = await RoleHelperUtility.getRolePermissions(
          user
        );
        props.setRolePermissions(rolePermissions);
        props.reloadTable();
        validateUpdatedRoleResponse(response);
        handleFlashMessages(response);
        clearStates();
      } else {
        hasValidationError(true);
      }
    } catch (error) {
      handleFlashMessages(error.response);
    }
  };

  const cancelUpdate = close => {
    clearStates();
    close();
  };

  const Footer = ({ close }) => {
    return (
      <Flex
        justifyContent="flex-end"
        otherStyles={{
          flex: 1,
        }}
      >
        <Box margin="0 16px 0 0">
          <Button
            label={I18n.t('common_buttons.cancel')}
            onClick={() => cancelUpdate(close)}
            variant={ButtonVariant.OUTLINED}
          />
        </Box>

        <Button
          label={I18n.t('common_buttons.update')}
          onClick={() => updateRole(close)}
        />
      </Flex>
    );
  };

  const setActionCheckBoxesOnChange = prefilledComponentActions => {
    const actionCheckboxesIdsCollection = [];
    response.data.componentTypes.map(type => {
      prefilledComponentActions.filter(action => {
        if (action.component == I18n.t(type.name)) {
          let componentName;
          let actionCheckboxId;
          componentName = I18n.t(type.name).replace(/\s/g, '');
          actionCheckboxId = {
            actionCheckboxId: `${componentName}_${action.action}`,
            isIndeterminate: false,
            isChecked: action.allowed == true,
          };
          actionCheckboxesIdsCollection.push(actionCheckboxId);
        }
      });
    });
    setSelectedActionCheckBoxes(actionCheckboxesIdsCollection);
  };

  const setComponentCheckBoxesOnChange = (
    prefilledComponentActions,
    componentTypes
  ) => {
    const componentCheckBoxes = componentTypes.map(elem => {
      let updatedObject;
      const getComponentActions = prefilledComponentActions.filter(action => {
        return action.component == I18n.t(elem.name);
      });
      const isAnyComponentActionUnchecked = getComponentActions.some(
        elem => !elem.allowed
      );
      const areAllComponentActionsUnchecked =
        getComponentActions.filter(elem => elem.allowed == false).length ==
        getComponentActions.length;

      if (isAnyComponentActionUnchecked) {
        updatedObject = {
          isIndeterminate: true,
          isChecked: true,
          checkboxId: I18n.t(elem.name).replace(/\s/g, ''),
        };
      } else if (!isAnyComponentActionUnchecked) {
        updatedObject = {
          isChecked: true,
          isIndeterminate: false,
          checkboxId: I18n.t(elem.name).replace(/\s/g, ''),
        };
      }
      if (areAllComponentActionsUnchecked) {
        updatedObject = {
          isIndeterminate: false,
          isChecked: false,
          checkboxId: I18n.t(elem.name).replace(/\s/g, ''),
        };
      }
      return updatedObject;
    });
    setSelectedComponentCheckBoxes(componentCheckBoxes);
  };

  const changeRole = async event => {
    try {
      setUserRole(event.name);
      const response = await ApiClient.GET({
        url: serviceConfig.brokerService.prefillComponentActions.uri + event.id,
        payload: {
          userLanguage: I18n.language,
        },
      });
      const { prefilledComponentActions } = response.data;
      setComponentActions(prefilledComponentActions);
      setActionCheckBoxesOnChange(prefilledComponentActions);
      setComponentCheckBoxesOnChange(prefilledComponentActions, componentTypes);
    } catch (error) {
      handleFlashMessages(error.response);
    }
  };

  const onChangeRoleNameText = event => {
    if (event.target.value) {
      hasValidationError(false);
    } else {
      hasValidationError(true);
    }
    setRoleName(event.target.value);
  };

  const onValueChange = value => {
    setAllowAllSites(value);
  };

  const onPatientDemographicsValueChange = value => {
    setIsPatientDemographicData(value);
  };

  const clearStates = () => {
    setPrefilledRoles({});
    setUserRole('');
    setRoleName('');
    setAllowAllSites(false);
    setIsPatientDemographicData(false);
    setActions([]);
    setActionsKeys([]);
    setComponentTypes([]);
    setComponentActions([]);
    setAllActionCheckBoxes([]);
    setSelectedActionCheckBoxes([]);
    setSelectedComponentCheckBoxes([]);
    hasValidationError(false);
    props.setOpenModal(false);
    props.setRoleId();
  };

  useEffect(() => {
    let actionList = [];
    let actionKeyList = [];
    const fetchUserRolesData = async () => {
      try {
        response = await ApiClient.GET({
          url: serviceConfig.brokerService.editRole.uri + roleId,
          payload: {
            userLanguage: I18n.language,
          },
        });
        if (!response.data.message) {
          setPrefilledRoles(response.data.prefilledRoles.rows);
          setRoleName(response.data.role ? response.data.role.name : '');
          setUserRole(response.data.role ? response.data.role.name : '');
          setAllowAllSites(
            response.data.role ? response.data.role.allowAllSites : false
          );
          setIsPatientDemographicData(
            response.data.role
              ? response.data.role.isPatientDemographicData
              : false
          );
          actionList = [
            ...new Set(
              response.data.componentActions.prefilledComponentActions.map(
                item => item.action
              )
            ),
          ];
          actionKeyList = [
            ...new Set(
              response.data.componentActions.prefilledComponentActions.map(
                item => item.actionKey
              )
            ),
          ];
          setComponentTypes(response.data.componentTypes);
          setActions(actionList);
          setActionsKeys(actionKeyList);
          setComponentActions(
            response.data.componentActions.prefilledComponentActions
          );
          setComponentCheckBoxesOnChange(
            response.data.componentActions.prefilledComponentActions,
            response.data.componentTypes
          );
          setActionCheckBoxesOnChange(
            response.data.componentActions.prefilledComponentActions
          );
          props.setSpinner(false);
        } else {
          props.setSpinner(true);
        }
      } catch (error) {
        handleFlashMessages(error.response);
      }
    };
    fetchUserRolesData();
  }, [props.roleId]);

  return (
    <div>
      <Modal
        title={I18n.t('newRole_view.label_editUserRole') + roleName}
        contentComponent={Content()}
        footerComponent={<Footer close={() => clearStates()} />}
        openModal={props.openModal}
        onClose={() => clearStates()}
        hideFlashMessage={HideFlashMessage}
      >
        <Button
          label="Open Modal"
          type="button"
          onClick={() => {}}
          variant="contained"
        />
      </Modal>
    </div>
  );
};

export default withTranslation()(EditRole);
