import React, { useReducer, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import Checkbox from '../../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import LogicJumpIcon from './LogicJumpIcon';

import { ButtonVariant } from '../../../../caro-ui-commonfiles/utils/button';
import CheckboxRadio from '../../../../caro-ui-commonfiles/utils/CheckboxRadio';


const MultipleChoice = ({
  options,
  index
}) => {

  const getCheckedOptions = (state, action) => {
    const updatedState = { ...state };
    updatedState[action.option] = action.isChecked;
    return updatedState;
  };

  const [checkedOptions, setCheckedOptions] = useReducer(
    getCheckedOptions,
    getSavedOptions()
  );


  const getLastCheckedOption = useCallback(() => {
    let lastOption = null;
    options.map(key => {
      if (checkedOptions[key]) lastOption = key;
    });
    return lastOption;
  }, [checkedOptions, options]);

  const compareOptionIndices = (state, optionObj) => {
    const { option, isChecked } = optionObj;
    const optionIndex = options.indexOf(option);
    const prevIndex = options.indexOf(state);
    if (isChecked) {
      if (optionIndex > prevIndex) return option;
      if (prevIndex > -1 && checkedOptions[state]) return state;
    }
    return getLastCheckedOption();
  };

  const [currLastCheckedOption, setCurrLastCheckedOption] = useReducer(
    compareOptionIndices,
    getLastCheckedOption(Object.keys(checkedOptions))
  );

  const renderCheckeBoxes = useCallback(
    () =>
      options.map((option, i) => (
        <div
          key={`container-${index}-${option}`}
          className="logic-jump__choice-container"
        >
          <div
            key={`option-container-${index}-${option}`}
            className="logic-jump__option-container"
          >
            <Checkbox
              id={`option-${index}-${i}`}
              key={`checkbox-key-${index}-${option}`}
              name={option}
              variant={CheckboxRadio.AnswerView}
              checked={checkedOptions[option] || false}
              handleOnChange={e => {
                const updatedOption = {
                  option,
                  isChecked: e.target.checked,
                };
                setCurrLastCheckedOption(updatedOption);
                setCheckedOptions(updatedOption);
              }}
            />
            <div
              className={
                checkedOptions[option]
                  ? 'logic-jump__checked-option-label'
                  : 'logic-jump__option-label'
              }
            >
              {option}
            </div>
            {option === currLastCheckedOption && (
              <LogicJumpIcon
                key={`label-key-${index}-${option}`}
                onPointerDownHandler={() =>
                  toggleHandler(!showLogicJumpSelection)
                }
                className={
                  checkedOptions[option]
                    ? 'answer-view__checked-option-label'
                    : null
                }
              />
            )}
          </div>
        </div>
      )),
    [
      checkedOptions,
      currLastCheckedOption,
      index,
      options
    ]
  );

  useEffect(() => {
    if (options && options.length && Object.keys(checkedOptions).length > 1) {
      setCurrLastCheckedOption(getLastCheckedOption());
    }
  }, [options]);

  return <>{options && renderCheckeBoxes()}</>;
};

function MultipleChoiceGroup({
  component,
  componentIndex,
}) {
  const getMultipleChoiceOptions = useCallback(() => {
    const { choices } = component.choiceCollection;
    if (choices && choices.length) {
      return choices.map(choice => choice.name);
    }
    return [];
  }, [component.choiceCollection]);

  return (
    <div className="logic-jump__options-container">
      {jumpSelectionGroup.length &&
        jumpSelectionGroup.map(object => (
          <div
            key={`subset-container-${object.destination}`}
            className="logic-jump__subset-container"
          >
            {object}
          </div>
        ))}
      <Button
        variant={ButtonVariant.NO_BORDER}
        onClick={addMultipleChoiceGroup}
        label={I18n.t('threshold.addThresholdLabel')}
      />
    </div>
  );
}

MultipleChoice.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  index: PropTypes.number.isRequired,
};

MultipleChoiceGroup.propTypes = {
  component: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  componentIndex: PropTypes.number.isRequired,
};

export default MultipleChoiceGroup;
