import React from 'react';

import Box from '../Box/Box';

import { FlexProps } from './Flex.type';

const Flex: React.FC<FlexProps> = ({
  id,
  onClick,
  children,
  className,
  otherStyles,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
}) => {
  const flexContainerStyle: React.CSSProperties = {
    flexWrap,
    alignItems,
    flexDirection,
    justifyContent,
    display: 'flex',
    ...otherStyles,
  };

  return (
    <Box
      id={id}
      className={className}
      otherStyles={flexContainerStyle}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default Flex;
