import patientsActions from '../src/Utils/ReduxUtilities/Actions/patients.json';

export const getPatientsByQueryRequest = patientsQuery => ({
  type: patientsActions.GET_PATIENTS_BY_QUERY_REQUEST,
  payload: patientsQuery,
});

export const getPatientsByCustomFilter = patientsQuery => ({
  type: patientsActions.GET_PATIENTS_BY_CUSTOM_FILTER_REQUEST,
  payload: patientsQuery,
});

export const addPatientRequest = () => {
  return {
    type: patientsActions.ADD_PATIENT_REQUEST,
  };
};

export const createPatientRequest = patientData => ({
  type: patientsActions.CREATE_PATIENT_REQUEST,
  payload: patientData,
});

export const editPatientRequest = patientId => ({
  type: patientsActions.EDIT_PATIENT_REQUEST,
  payload: patientId,
});

export const updatePatientRequest = (patientId, patientData) => ({
  type: patientsActions.UPDATE_PATIENT_REQUEST,
  payload: {
    patientId,
    patientData,
  },
});

export const deletePatientRequest = patientId => ({
  type: patientsActions.DELETE_PATIENT_REQUEST,
  payload: patientId,
});

export const showPatientRequest = (
  patientId,
  healthScore,
  scoringSchemeCategory
) => ({
  type: patientsActions.SHOW_PATIENT_REQUEST,
  payload: { patientId, healthScore, scoringSchemeCategory },
});

export const resetPatientData = () => {
  return {
    type: patientsActions.RESET_PATIENT_DATA,
  };
};
