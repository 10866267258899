/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'i18next';

import ModalForm from '../../common/Layout/Modal/ModalForm';
import ModalSection from '../../common/Layout/Modal/ModalSection';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import ICDViewer from '../../../../caro-ui-commonfiles/components/ICD/ICDViewer';
import { CreateAppointmentContext } from './CreateAppointmentModal';
import { MODAL_VARIANT } from '../../../Utils/Layout/modalSection';
import { convertLinkToBlob } from '../../../Utils/Asset/assetHelper';
import {
  repeatTypesEnum,
  weekdays,
} from '../../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';


const AppointmentSettingOverview = ({ values, icds }) => {
  const { existingPatient } = useContext(CreateAppointmentContext);

  const patientInfo = existingPatient;
  const dateFormat = 'DD MMM YYYY HH:mm';

  const [profilePicture, setProfilePicture] = useState('');

  const reader = new FileReader();
  reader.onloadend = () => {
    setProfilePicture(reader.result);
  };

  useEffect(() => {
    const getProfilePicture = async () => {
      if (patientInfo.profilePicture.previewURL) {
        await convertLinkToBlob(patientInfo.profilePicture.previewURL)
          .then(blob => {
            setProfilePicture(blob);
          })
          .catch(() => {
            setProfilePicture(patientInfo.profilePicture.previewURL);
          });
      } else {
        setProfilePicture(`data:image;base64,${patientInfo.profilePicture}`);
      }
    };
    getProfilePicture();
  }, []);

  const renderAssigneeInfoPane = () => {
    return (
      <div className="navigation-container">
        <div
          className="circular-picture-sm"
          style={{
            backgroundImage: `url(${profilePicture})`,
          }}
        />
        <div className="care-team-member-name">
          {`${patientInfo.firstname} ${patientInfo.lastname}`}
        </div>
      </div>
    );
  };
  const renderOccurrencInfo = () => {
    if (values.repeatType.value !== repeatTypesEnum.WEEKLY) {
      return <div className="content-text">{values.repeatType.label}</div>;
    }
    if (values.repeatType.value === repeatTypesEnum.WEEKLY) {
      const weekdaysTranslation = weekdays();
      const recurrenceDays = values.weeklyRecurrence.map((day, i) => {
        if (day) {
          return (
            <div key={`weekday-${i}`} className="content-text">
              {i !== 0 && ', '}
              {weekdaysTranslation[i].translation}
            </div>
          );
        }
      });

      return (
        <>
          {recurrenceDays && (
            <Flex flexDirection="row">
              <span style={{ marginRight: '5px' }}>
                {I18n.t('recurrence.on')}
              </span>
              {recurrenceDays}
            </Flex>
          )}
        </>
      );
    }
  };

  const renderApptInfoPane = () => {
    return (
      <Flex flexDirection="row">
        <div className="info-container">
          <div className="section-container">
            <div>{I18n.t('appointment.type')}</div>
            <div className="content-text">{values.appointmentType.label}</div>
          </div>
          <div className="section-container">
            <div>{I18n.t('appointment.title')}</div>
            <div className="content-text">{values.name}</div>
          </div>
          {icds.length > 0 && (
            <div className="section-container">
              <div>{I18n.t('common_labels.referenced_icd_code')}</div>
              <div className="content-text content-text-container">
                <ICDViewer icds={icds} shouldShowAll />
              </div>
            </div>
          )}
          {values.location && (
            <div className="section-container">
              <div>{I18n.t('appointment.place')}</div>
              <div className="content-text">
                {values.location.label
                  ? values.location.label
                  : values.location}
              </div>
            </div>
          )}
          {values.notes && (
            <div className="section-container">
              <div>{I18n.t('patientOverview.details')}</div>
              <div className="content-text content-text-container">
                {values.notes}
              </div>
            </div>
          )}
          <div className="section-container">
            <div>{I18n.t('recurrence.startsFrom')}</div>
            <div className="content-text content-text-container">
              {moment(values.startDate)
                .hour(values.startTime.hour)
                .minute(values.startTime.minute)
                .locale(I18n.language)
                .format(dateFormat)}
            </div>
          </div>
          <div className="section-container">
            <div>{I18n.t('recurrence.endsAt')}</div>
            <div className="content-text content-text-container">
              {moment(values.endDate)
                .hour(values.endTime.hour)
                .minute(values.endTime.minute)
                .locale(I18n.language)
                .format(dateFormat)}
            </div>
          </div>
          {values.recurrenceText && (
            <div className="section-container">
              <div>{I18n.t('recurrence.title')}</div>
              <div className="content-text content-text-container">
                {values.recurrenceText}
              </div>
            </div>
          )}
          {values.repeatType && (
            <div className="section-container">
              <div>{I18n.t('recurrence.title')}</div>
              <div className="content-text content-text-container">
                {renderOccurrencInfo()}
              </div>
            </div>
          )}
        </div>
      </Flex>
    );
  };

  return (
    <ModalForm>
      <ModalSection variant={MODAL_VARIANT.COL_W30}>
        <Box padding="30px 20px 0 40px">
          <div className="section-container-nav">
            <div>{I18n.t('patientOverview.assignedTo')}</div>
          </div>
          {renderAssigneeInfoPane()}
        </Box>
      </ModalSection>

      <ModalSection variant={MODAL_VARIANT.COL_W70}>
        {renderApptInfoPane()}
      </ModalSection>
    </ModalForm>
  );
};

AppointmentSettingOverview.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  icds: PropTypes.arrayOf(PropTypes.object),
};

AppointmentSettingOverview.defaultProps = {
  icds: [],
};

export default memo(AppointmentSettingOverview);
