export function bmiAnswerValidator(height: number, weight: number) {
  const heightAnswered = !!height;
  const weightAnswered = !!weight;
  if (
    (heightAnswered && !weightAnswered) ||
    (!heightAnswered && weightAnswered)
  )
    return 'newQuestion_view.bmiValidation';
  return '';
}
