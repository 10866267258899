import {
  TaggedUsersResponse,
  TaggedUsersListPayload,
} from './TaggedUsersList.type';
import serviceConfig from '../../../serviceConfig.json';
import { ApiClient } from '@global/Services';

export const getAllTaggedUsers = (
  controller: AbortController,
  query: TaggedUsersListPayload
) => {
  return ApiClient.GET<TaggedUsersResponse, TaggedUsersListPayload>({
    signal: controller.signal,
    url: serviceConfig.brokerService.getUserTaggedNotes.uri,
    payload: query,
  });
};
