import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Dropdown,
  Flex,
  Label,
  NumberInput,
} from '@ui-common-files/components';

import useLinkedCareTaskConfigurationService from './LinkedCareTaskConfiguration.service';


const LinkedCareTaskConfiguration: React.FC = () => {
  const {
    lifespanUnitsObjSmall,
    nodeData,
    configuration,
    onConfigurationChange,
    saveConfiguration,
    getTranslatedLabel,
  } = useLinkedCareTaskConfigurationService();

  const { t } = useTranslation();

  return (
    <>
      <Flex className="care-task-list_options">
        <Box className="care-task-list_options-title">
          {t('common_labels.add_configuration')}
        </Box>
        <Flex className="care-task-list_content-title">
          <Box>{nodeData.label}</Box>
        </Flex>
      </Flex>
      <Box className="caretask-configuration">
        <Box>
          <Box className="caretask-configuration-title">
            <Flex flexDirection="row">
              {t('recurrence.lifespan')}
              <Label
                id="lifespanInfo"
                icon="info"
                hasIcon
                tooltip={
                  <Box className="tooltip-content-wrapper">
                    {t('recurrence.lifespanInfo')}
                  </Box>
                }
              />
            </Flex>
          </Box>
          <Flex flexDirection="row">
            <Box flex="1" margin="0 10px 0 0" maxWidth={100}>
              <NumberInput
                id="lifespanDuration"
                name="lifespanDuration"
                min={1}
                max={99}
                step={1}
                placeholder=""
                value={configuration.lifespan}
                changeValue={value => {
                  onConfigurationChange(value, 'lifespan');
                }}
              />
            </Box>
            <Box flex="1" maxWidth={128} margin="0 10px 0 0">
              <Dropdown
                value={getTranslatedLabel('lifespanUnit')}
                options={lifespanUnitsObjSmall}
                placeholder={t('recurrence.lifespan')}
                handleOnChange={event => {
                  onConfigurationChange(event, 'lifespanUnit');
                }}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
      <Flex
        className='save-button'
        justifyContent="flex-end"
      >
        <Button
          label={t(
            nodeData.duration?.durationText
              ? 'common_buttons.update'
              : 'common_buttons.save'
          )}
          onClick={() => saveConfiguration()}
        />
      </Flex>
    </>
  );
};

export default LinkedCareTaskConfiguration;
