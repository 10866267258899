/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import moment from 'moment';
import axios from 'axios';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import serviceConfig from '../../../serviceConfig.json';
import IcdSelector from '../../../caro-ui-commonfiles/components/ICDDropdownSelect/ICDSelect';
import RecurrenceComponent from '../../../caro-ui-commonfiles/components/molecules/Recurrence/Recurrence';
import {
  ComponentTypes,
  Actions,
  SubmissionTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import TaskModalContentForm from './TaskModalContentForm';
import TaskInformationOverview from './TaskInformationOverview';
import { dropdownTaskTypes } from '../../Utils/dropdownTypes';
import {
  timeOfDay,
  lifespanUnits,
  rruleFreqToRepeatTypeIndex,
  generateWeekdayArray,
} from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';

import '../../css/user.css';
import '../../css/patient.css';

const TaskUpdateModalContent = ({ data }) => {
  const lifespanUnitsObj = lifespanUnits();
  const repeatTypesArray = rruleFreqToRepeatTypeIndex();

  const [icds, setIcds] = useState(data.icdRecords ? data.icdRecords : []);
  const [settings, setSettings] = useState({ maxIcdSelection: 1 });
  const {
    currentStep,
    setCurrentStep,
    shouldValidateForm,
    setFlashMessage,
    closeModal,
    dispatchFormValidator,
    shouldSubmitForm,
    dispatchFormSubmittor,
    setLandingTab,
  } = useContext(PatientOverviewContext);
  const [validateRecurrence, setValidateRecurrence] = useState(false);

  const [startingArrays, setStartingArrays] = useState({
    startingDays: [],
    startingTimes: [],
  });

  const [values, setValues] = useState({
    name: data.name,
    taskType: data.taskTypeId ? dropdownTaskTypes()[data.taskTypeId - 1] : '',
    phaseType: data.phaseType,
    taskPriority: data.taskPriority,
    startDate: moment(data.reminderElement.startsAt).toDate(),
    morning: false,
    midday: false,
    evening: false,
    night: false,
    customTime: { hour: 9 },
    customTimeChecked: false,
    URL: data.taskURL ? data.taskURL : '',
    date: '',
    role: data.role,
    lifespan: 1,
    lifespanUnit: lifespanUnitsObj[0],
    repeatType: repeatTypesArray[0],
    repeatInterval: { value: 1, label: 1 },
    occurrenceCount: 1,
    endDate: null,
    weeklyRecurrence: new Array(7).fill(false),
    occurrenceCountCheck: true,
  });

  const [hasErrors, setHasErrors] = useState({
    name: false,
    taskType: false,
    phaseType: false,
    taskPriority: false,
    URL: false,
    date: false,
    role: false,
  });

  const [recurrenceErrors, setRecurrenceErrors] = useState({
    lifespan: false,
    recurrence: false,
    recurrenceRepeat: false,
    startDate: false,
    endDate: false,
    occurrenceCount: false,
  });

  const onValueChange = useCallback(
    (value, key) => {
      setValues({
        ...values,
        [key]: value,
      });
    },
    [values]
  );

  const getData = async () => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.editTask.uri,
        {
          params: {
            reminderId: data.reminderElement.reminderId
          },
        }
      );
      const responseData = response.data;
      if (responseData) {
        const { endsAt } = responseData.reminder;
        const recurrenceRule = responseData.rruleRecurrence;
        setValues({
          ...values,
          phaseType: responseData.phaseType,
          taskPriority: responseData.taskPriority,
          role: responseData.role,
          morning:
            responseData.timeOfDayRecurrence.indexOf(timeOfDay.MORNING) > -1,
          midday:
            responseData.timeOfDayRecurrence.indexOf(timeOfDay.MIDDAY) > -1,
          evening:
            responseData.timeOfDayRecurrence.indexOf(timeOfDay.EVENING) > -1,
          night: responseData.timeOfDayRecurrence.indexOf(timeOfDay.NIGHT) > -1,
          customTime:
            responseData.specificTime > -1
              ? { hour: responseData.specificTime }
              : values.customTime,
          customTimeChecked: responseData.specificTime > -1 || false,
          lifespan: responseData.lifespan,
          lifespanUnit: lifespanUnitsObj[responseData.lifespanUnit],
          repeatType:
            recurrenceRule.origOptions.freq > -1
              ? repeatTypesArray[recurrenceRule.origOptions.freq]
              : values.repeatType,
          repeatInterval: recurrenceRule.origOptions.interval
            ? {
                value: recurrenceRule.origOptions.interval,
                label: recurrenceRule.origOptions.interval,
              }
            : values.repeatInterval,
          occurrenceCount:
            recurrenceRule.origOptions.count || values.occurrenceCount,
          endDate: endsAt ? moment(endsAt).toDate() : null,
          weeklyRecurrence: recurrenceRule.options.byweekday
            ? generateWeekdayArray(recurrenceRule.options.byweekday)
            : values.weeklyRecurrence,
          occurrenceCountCheck: !recurrenceRule.origOptions.until,
        });
        setSettings({ maxIcdSelection: responseData.maxNumOfSelections });
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: I18n.t(error.response.data.content),
      });
    }
  };

  useEffect(() => {
    const updateTask = async () => {
      let message = '';
      closeModal();
      const { endDate } = values;
      try {
        const response = await axios.post(
          serviceConfig.brokerService.updateTask.uri,
          {
            reminderId: data.reminderElement.reminderId,
            icds,
            ...values,
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: endDate
              ? moment(endDate).format('YYYY-MM-DD HH:mm:ss')
              : null,
          }
        );
        if (response) {
          let sendResponse = response;
          message = `${I18n.t(response.data.content)}`;
          const isSendOutImmediately =
            shouldSubmitForm.submissionType === SubmissionTypes.ASSIGN_AND_SEND;
          if (isSendOutImmediately) {
            sendResponse = await axios.post(
              serviceConfig.brokerService.sendTask.uri,
              {
                reminderId: data.reminderElement.reminderId,
              }
            );
          }
          if (sendResponse) {
            setLandingTab(false);
            setLandingTab(ComponentTypes.TASK);
            setFlashMessage({
              type: sendResponse.data.type,
              content: isSendOutImmediately
                ? `${message} ${I18n.t(sendResponse.data.content)}`
                : message,
            });
          }
        }
      } catch (error) {
        setFlashMessage({
          type: error.response.data.type,
          content: `${I18n.t(error.response.data.content)}${message}`,
        });
      }
    };

    if (
      shouldSubmitForm.component === ComponentTypes.TASK &&
      shouldSubmitForm.action === Actions.Update
    ) {
      updateTask();
      return function cleanup() {
        dispatchFormSubmittor({
          component: '',
          action: '',
          submissionType: false,
        });
      };
    }
  }, [shouldSubmitForm]);

  const validateForm = () => {
    const isNameValid = values.name.trim().length > 5;
    const isUrlValid =
      values.taskType.value !== 1
        ? true
        : values.URL.match(
            /(https?|ftp):\/\/(-.)?([^s\/?.#-]+.?)+(\/[^s]*)?$/i
          );

    setHasErrors({
      name: !isNameValid,
      URL: !isUrlValid,
    });
    return isNameValid && isUrlValid;
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    return false;
  };

  const fetchIcdData = term => {
    return axios(serviceConfig.getIcds.uri, {
      params: {
        searchTerm: term,
      },
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    if (
      shouldValidateForm.component === ComponentTypes.TASK &&
      shouldValidateForm.action === Actions.Update
    ) {
      setValidateRecurrence(shouldValidateForm.shouldValidate);
    }
  }, [shouldValidateForm]);

  useEffect(() => {
    const checkRecurrenceError = () =>
      Object.keys(recurrenceErrors).find(key => recurrenceErrors[key]);
    const isFormValid = validateForm();
    if (validateRecurrence && !checkRecurrenceError() && isFormValid) {
      setCurrentStep(currentStep + 1);
      dispatchFormValidator({
        component: '',
        action: '',
        shouldValidate: false,
      });
    }
    if (validateRecurrence) setValidateRecurrence(false);
  }, [recurrenceErrors]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <ModalContent>
      <ModalForm formId="task-form" handleFormSubmit={handleFormSubmit}>
        {currentStep === 1 && (
          <TaskModalContentForm
            values={values}
            recurrenceComponent={
              <RecurrenceComponent
                componentType={ComponentTypes.TASK}
                onValueChange={onValueChange}
                values={values}
                validateRecurrence={validateRecurrence}
                hasErrors={recurrenceErrors}
                setHasErrors={setRecurrenceErrors}
                setStartingArrays={setStartingArrays}
              />
            }
            icdSelectorComponent={
              <IcdSelector
                maxLengthItem={settings.maxIcdSelection}
                onSelect={setIcds}
                icdSelected={icds}
                fetchData={fetchIcdData}
                placeholder={I18n.t('newCareplan_view.placeholderICDReference')}
              />
            }
            onValueChange={onValueChange}
            hasErrors={hasErrors}
            isUpdate
          />
        )}
        {currentStep === 2 && (
          <TaskInformationOverview
            values={values}
            startingArrays={startingArrays}
            icds={icds}
          />
        )}
      </ModalForm>
    </ModalContent>
  );
};

TaskUpdateModalContent.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default TaskUpdateModalContent;
