import { createSlice } from '@reduxjs/toolkit';
import { getServices } from '../thunks/services';

const initialState = {
  "taskservice.myoncare.care": false,
  "appointmentservice.myoncare.care": false,
  "medicationservice.myoncare.care": false,
  "careplanservice.myoncare.care": false,
  "chatservice.myoncare.care": false,
  "templateservice.myoncare.care": false,
  "icdhandlerservice.myoncare.care": false,
  "assetservice.myoncare.care": false,
  "patientservice.myoncare.care": false,
  "administrationservice.myoncare.care": false,
  "chatNotification": false,
  "thresholdNotification": false,
  "rolesRead": false,
  "sitesRead": false,
  "usersRead": false,
  "filtersRead": false,
  "carePathwayRead": false,
  "assetRead": false,
  "satelliteRead": false,
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    resetServices: state => {
      state = { ...initialState };
      return state;
    },
  },
  extraReducers: {
    [getServices.fulfilled]: (state, action) => {
      state = { ...action.payload };
      return state;
    },
  },
});

export const { resetServices } = servicesSlice.actions;
export default servicesSlice.reducer;
