import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import TagScore from './TagScore';

function SectionScoreTag({ index }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box>
      <TagScore isTotalScoreTag={false}>
        {t('newCareplan_view.complex_score.section_score.tag')}
        <span className="componentCounter">{` ${index + 1}`}</span>
      </TagScore>
      <Box className="tag__score--spacer" />
    </Box>
  );
}
export default SectionScoreTag;
