import React from 'react';
import '../../../css/componentSettings.css';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import I18n from 'i18next';
import serviceConfig from '../../../../serviceConfig.json';
import IcdSelector from '../../../../caro-ui-commonfiles/components/ICDDropdownSelect/ICDSelect';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';

const IcdsComponent = ({
  allIcds,
  setAllIcds,
  isRenderedOnCaretaskBuilder,
}) => {
  const renderIcdsComponent = () => {
    return (
      <Flex className='toggleAndTextContainer'>
        <div style={{ width: '100%' }}>
          <IcdSelector
            onSelect={selected => {
              setAllIcds(selected);
            }}
            icdSelected={allIcds}
            fetchData={term => {
              return fetchIcds(term);
            }}
            placeholder={I18n.t('newCareplan_view.placeholderICDReference')}
            isRenderedOnCaretaskBuilder={isRenderedOnCaretaskBuilder}
          />
        </div>
      </Flex>
    );
  };

  const fetchIcds = term => {
    return axios(serviceConfig.getIcds.uri, {
      params: {
        searchTerm: term,
      },
    }).then(function (response) {
      return response.data;
    });
  };

  return renderIcdsComponent();
};

export default withTranslation()(IcdsComponent);
