/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { HideFlashMessage } from '@global/Services';

import Assets from '../../../../../Datatables/Assets';
import ASSET_TYPES from '../../../../../../Utils/Asset/assetTypes';
import Modal from '../../../../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ModalContent from '../../../../../../../caro-ui-commonfiles/components/Modals/ModalContent';
import Tabs from '../../../../../../../caro-ui-commonfiles/components/Tabs/Tabs';
import ModalSection from '../../../../../common/Layout/Modal/ModalSection';
import ModalForm from '../../../../../common/Layout/Modal/ModalForm';
import tabPosition from '../../../../../../Utils/Administration/tabPosition';
import ModalFooter from '../../../../../common/Layout/Modal/ModalFooter';
import { UploadFileContext } from './AnsweringFileRequest';

require('image-compressor');

export default function UploadFileModal({
  openModal,
  setOpenModal,
  setFlashMessage,
}): JSX.Element {
  const [fetchedData, setFetchedData] = useState([]);
  const { t } = useTranslation();
  const onClickTabItem = tab => {
    setFileActiveTab(tab);
  };
  const { selectedFile, setSelectedFile, fileActiveTab, setFileActiveTab } =
    useContext(UploadFileContext);

  const [reloadTable, setReloadTable] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
    setFileActiveTab(1);
  };
  const removeFileAttachmentWhenCancel = () => {
    closeModal();
    setSelectedFile(null);
  };

  const selectFileFromAsset = file => {
    setSelectedFile({ ...file, assetId: file.id });
  };

  const setActiveTabAndReload = tabId => {
    setReloadTable(!reloadTable);
    setFileActiveTab(tabId);
  };
  const renderModalContent = () => {
    return (
      <ModalContent>
        <ModalForm>
          <ModalSection hasPadding>
            <div style={{ position: 'relative' }}>
              <Tabs activeTab={fileActiveTab} onClickTabItem={onClickTabItem}>
                <div
                  label={t('asset_view.documents')}
                  tabId={tabPosition.FIRST}
                >
                  <Assets
                    hasPagesize
                    reloadTable={reloadTable}
                    setReloadTable={setActiveTabAndReload}
                    setFlashMessage={setFlashMessage}
                    activeTab={ASSET_TYPES.DOCUMENT}
                    isAttachFile
                    setAttachedFile={selectFileFromAsset}
                    fetchedData={fetchedData}
                    setFetchedData={setFetchedData}
                    isPrivacyPolicy={false}
                    setSelectedFile={selectFileFromAsset}
                  />
                </div>
                <div label={t('asset_view.images')} tabId={tabPosition.SECOND}>
                  <Assets
                    hasPagesize
                    reloadTable={reloadTable}
                    setReloadTable={setActiveTabAndReload}
                    setFlashMessage={setFlashMessage}
                    activeTab={ASSET_TYPES.IMAGE}
                    isAttachFile
                    setAttachedFile={selectFileFromAsset}
                    fetchedData={fetchedData}
                    setFetchedData={setFetchedData}
                    isPrivacyPolicy={false}
                    setSelectedFile={selectFileFromAsset}
                  />
                </div>
                <div label={t('asset_view.videos')} tabId={tabPosition.THIRD}>
                  <Assets
                    hasPagesize
                    reloadTable={reloadTable}
                    setReloadTable={setActiveTabAndReload}
                    setFlashMessage={setFlashMessage}
                    activeTab={ASSET_TYPES.VIDEO}
                    isAttachFile
                    setAttachedFile={selectFileFromAsset}
                    fetchedData={fetchedData}
                    setFetchedData={setFetchedData}
                    isPrivacyPolicy={false}
                    setSelectedFile={selectFileFromAsset}
                  />
                </div>

                <div label={t('asset_view.audios')} tabId={tabPosition.FOURTH}>
                  <Assets
                    hasPagesize
                    reloadTable={reloadTable}
                    setReloadTable={setActiveTabAndReload}
                    setFlashMessage={setFlashMessage}
                    activeTab={ASSET_TYPES.AUDIO}
                    isAttachFile
                    setAttachedFile={selectFileFromAsset}
                    fetchedData={fetchedData}
                    setFetchedData={setFetchedData}
                    isPrivacyPolicy={false}
                    setSelectedFile={selectFileFromAsset}
                  />
                </div>
              </Tabs>
            </div>
          </ModalSection>
        </ModalForm>
      </ModalContent>
    );
  };

  return (
    <Modal
      title={t('asset_view.attachFiles')}
      contentComponent={renderModalContent()}
      footerComponent={
        <ModalFooter
          close={removeFileAttachmentWhenCancel}
          labelCTA={t('actions.attach')}
          onClickCTA={() => {
            if (selectedFile) {
              setFlashMessage({});
            }
            closeModal();
          }}
        />
      }
      openModal={openModal}
      onClose={closeModal}
      closePressed={removeFileAttachmentWhenCancel}
      hideFlashMessage={HideFlashMessage}
    />
  );
}
