import { deepEqual } from '../../Global/Objects';
import componentTypeOrder from '../../Views/Careplans/careplanComponentTypeOrder';
import {
  checkItemJumpListValidity,
  componentHealthCategories,
} from '../../Views/Careplans/CareplanHelperUtility';

/**
 * @class ValidateTemplate
 * @name ValidateTemplate
 * @classdesc creates helper methods which validates template attributes
 */

class ValidateTemplate {
  constructor() {}

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#hasTemplateName
   * @param {Object} searchTerm - object containing attributes for careplan template
   * @returns {Boolean} This function returns boolean value which signals the fact if the careplan template name is entered or not
   */

  static hasTemplateName(searchTerm) {
    if (searchTerm.name || searchTerm.label) {
      if (searchTerm.name) {
        return !!(searchTerm.name.trim().length > 0);
      }
      return !!(searchTerm.label.trim().length > 0);
    }
    return !!(searchTerm.name || searchTerm.label);
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#hasTemplateType
   * @param {Boolean} isCareplanOrAssessment - flag that tells the template type either it is template or assessment
   * @returns {Boolean} This function returns boolean value that checks user's select one of the template type or not
   */

  static hasTemplateType(isCareplanOrAssessment, selectedMedical) {
    let isTemplateType = true;
    if (isCareplanOrAssessment == null) {
      isTemplateType = false;
    }
    if (
      isCareplanOrAssessment &&
      selectedMedical.filter(role => role.isChecked === true).length === 0
    ) {
      isTemplateType = false;
    }
    return isTemplateType;
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#checkDuplicateOptions
   * @param {Object} currentCareplanComponent - object containing attributes for careplan component
   * @returns {Boolean} This function returns boolean value which signals the fact if the options user entered are duplicate or not for single and multiple choice component
   */

  static checkDuplicateOptions(currentCareplanComponent) {
    let options;
    let isDuplicate = false;
    let optionsLength;
    optionsLength = currentCareplanComponent.choiceCollection.choices.length;
    if (optionsLength > 0) {
      options = currentCareplanComponent.choiceCollection.choices.map(function (
        option
      ) {
        return option.name;
      });
      isDuplicate = options.some(function (option, idx) {
        return options.indexOf(option) != idx;
      });
    }
    return isDuplicate;
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#checkDuplicateOptionsForSortable
   * @param {Object} currentCareplanComponent - object containing attributes for careplan component
   * @returns {Boolean} This function returns boolean value which signals the fact if the options user entered are duplicate or not for sortable component
   */

  static checkDuplicateOptionsForSortable(currentCareplanComponent) {
    let options;
    let isDuplicate = false;
    let optionsLength;
    optionsLength =
      currentCareplanComponent.sortableCollection.sortables.length;
    if (optionsLength > 0) {
      options = currentCareplanComponent.sortableCollection.sortables.map(
        function (option) {
          return option.name;
        }
      );
      isDuplicate = options.some(function (option, idx) {
        return options.indexOf(option) != idx;
      });
    }
    return isDuplicate;
  }

  static checkComponentDuplicateOptions(careplanComponents) {
    const itemsWithDuplicateOptionsError = [];
    const componentTypeOrder =
      require('../../Views/Careplans/careplanComponentTypeOrder').default;
    for (let i = 0, len = careplanComponents.length; i < len; i++) {
      const currentCareplanComponent = careplanComponents[i];
      const currentComponentType = currentCareplanComponent.collection.key;
      if (
        currentComponentType == componentTypeOrder.SINGLE_CHOICE ||
        currentComponentType == componentTypeOrder.MULTIPLE_CHOICE ||
        currentComponentType == componentTypeOrder.SORTABLE
      ) {
        if (
          currentComponentType == componentTypeOrder.SINGLE_CHOICE ||
          currentComponentType == componentTypeOrder.MULTIPLE_CHOICE
        ) {
          if (
            ValidateTemplate.checkDuplicateOptions(currentCareplanComponent)
          ) {
            itemsWithDuplicateOptionsError.push({
              componentId: currentCareplanComponent.id,
              isDuplicate: true,
            });
          }
        } else if (currentComponentType == componentTypeOrder.SORTABLE) {
          if (
            ValidateTemplate.checkDuplicateOptionsForSortable(
              currentCareplanComponent
            )
          ) {
            itemsWithDuplicateOptionsError.push({
              componentId: currentCareplanComponent.id,
              isDuplicate: true,
            });
          } else {
          }
        }
      }
    }
    return itemsWithDuplicateOptionsError;
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#hasComponentSettingsError
   * @param {Array} careplanComponents - careplan components for which settings are evaluated
   * @param {boolean} initErrorMessage - boolean flag for determining the validity
   * @returns {Object} This function returns an object with validation attributes
   */

  static hasComponentSettingsError(careplanComponents, initErrorMessage) {
    const [
      blankedComponentCollection,
      componentsWithSettingsError,
      twoOptionsRequiredError,
      emptyOptionError,
      evaluationLineErrorForSortable,
    ] = [{}, [], {}, {}, []];
    let minLabelRequired;
    let maxLabelRequired;
    let isValid = true;
    const shouldBeCollected = false;
    const componentTypeOrder =
      require('../../Views/Careplans/careplanComponentTypeOrder').default;
    const defaultEnums = require('../../Views/Careplans/defaultEnums').default;

    for (let i = 0, len = careplanComponents.length; i < len; i++) {
      const currentCareplanComponent = careplanComponents[i];
      const currentComponentType = currentCareplanComponent.collection.key;
      if (
        currentCareplanComponent.searchTerm.label == undefined ||
        currentCareplanComponent.searchTerm.label == ''
      ) {
        blankedComponentCollection[currentCareplanComponent.id] = {
          componentId: currentCareplanComponent.id,
          noComponentName: true,
        };
        isValid = false;
      }

      if (
        currentComponentType == componentTypeOrder.SINGLE_CHOICE ||
        currentComponentType == componentTypeOrder.MULTIPLE_CHOICE ||
        currentComponentType == componentTypeOrder.SORTABLE
      ) {
        if (
          currentComponentType == componentTypeOrder.SINGLE_CHOICE ||
          currentComponentType == componentTypeOrder.MULTIPLE_CHOICE
        ) {
          for (
            let j = 0,
              len = currentCareplanComponent.choiceCollection.choices.length;
            j < len;
            j++
          ) {
            if (
              currentCareplanComponent.choiceCollection.choices[j].name.trim()
                .length < 1
            ) {
              emptyOptionError[currentCareplanComponent.id] = true;
              isValid = false;
            }
          }
          if (
            currentCareplanComponent.choiceCollection.choices.length <
            defaultEnums.MIN_OPTIONS
          ) {
            twoOptionsRequiredError[currentCareplanComponent.id] = true;
            isValid = false;
          }
        } else if (currentComponentType == componentTypeOrder.SORTABLE) {
          for (
            let j = 0,
              len =
                currentCareplanComponent.sortableCollection.sortables.length;
            j < len;
            j++
          ) {
            if (
              currentCareplanComponent.sortableCollection.sortables[
                j
              ].name.trim().length < 1
            ) {
              emptyOptionError[currentCareplanComponent.id] = true;
              isValid = false;
            }
          }
          if (
            currentCareplanComponent.sortableCollection.sortables.length <
            defaultEnums.MIN_OPTIONS
          ) {
            twoOptionsRequiredError[currentCareplanComponent.id] = true;
            isValid = false;
          }
          let evaluationLine;
          let sortableOptionsLen;
          if (currentCareplanComponent.searchTerm.careplanComponentId) {
            evaluationLine =
              currentCareplanComponent.searchTerm.additional_configuration
                .evaluationLine;
          } else {
            evaluationLine =
              currentCareplanComponent.additional_configuration.evaluationLine;
          }
          sortableOptionsLen =
            currentCareplanComponent.sortableCollection.sortables.length;
          if (
            evaluationLine > sortableOptionsLen &&
            sortableOptionsLen > defaultEnums.MIN_OPTIONS
          ) {
            evaluationLineErrorForSortable.push({
              componentId: currentCareplanComponent.id,
              evaluationLineError: true,
            });
            isValid = false;
          }
        }
      }

      if (currentComponentType == componentTypeOrder.NUMERIC_RANGE) {
        const processedValues = ValidateTemplate.handleNumericRange(
          currentCareplanComponent,
          minLabelRequired,
          maxLabelRequired
        );
        minLabelRequired = processedValues[0];
        maxLabelRequired = processedValues[1];
        const hasMinMaxValidationError = processedValues[2];
        if (
          hasMinMaxValidationError ||
          shouldBeCollected ||
          minLabelRequired ||
          maxLabelRequired
        ) {
          componentsWithSettingsError.push({
            componentId: currentCareplanComponent.id,
            mediaUrlErrorMessage: initErrorMessage,
            isValidUrl: true,
            minLabelRequired,
            maxLabelRequired,
          });
          isValid = false;
        }
      } else if (shouldBeCollected == true) {
        componentsWithSettingsError.push({
          componentId: currentCareplanComponent.id,
          mediaUrlErrorMessage: initErrorMessage,
          isValidUrl: true,
        });
        isValid = false;
      }

      if (currentComponentType == componentTypeOrder.NUMERIC_VALUE) {
        const [hasMinMaxValidationError] = ValidateTemplate.handleNumericValue(
          currentCareplanComponent
        );
        if (hasMinMaxValidationError || shouldBeCollected) {
          componentsWithSettingsError.push({
            componentId: currentCareplanComponent.id,
            mediaUrlErrorMessage: initErrorMessage,
            isValidUrl: true,
            minLabelRequired,
            maxLabelRequired,
          });
          isValid = false;
        }
      } else if (shouldBeCollected == true) {
        componentsWithSettingsError.push({
          componentId: currentCareplanComponent.id,
          mediaUrlErrorMessage: initErrorMessage,
          isValidUrl: true,
        });
        isValid = false;
      }
    }
    return [
      isValid,
      blankedComponentCollection,
      componentsWithSettingsError,
      twoOptionsRequiredError,
      emptyOptionError,
      evaluationLineErrorForSortable,
    ];
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#checkMediaSettings
   * @param {Object} currentCareplanComponent - careplan component for which media setting is evaluated
   * @param {string} initErrorMessage - error message to be displayed in case validation fails
   * @param {boolean} isValid - boolean flag for determining the validity
   * @param {boolean} shouldBeCollected - boolean specifying if the component has to be pushed into list of invalidated components
   * @returns {Array} This function returns an array with specific validators to determine validity of media settings for a component
   */

  static checkMediaSettings(
    currentCareplanComponent,
    initErrorMessage,
    isValid,
    shouldBeCollected
  ) {
    if (currentCareplanComponent.searchTerm.careplanComponentId) {
      if (currentCareplanComponent.searchTerm.shouldMediaSettingsToggle) {
        if (
          currentCareplanComponent.searchTerm.additional_configuration
            .mediaURL == ''
        ) {
          mediaUrlErrorMessage = initErrorMessage;
          isValid = false;
          shouldBeCollected = true;
        }
        if (
          !ValidateTemplate.validURL(
            currentCareplanComponent.searchTerm.additional_configuration
              .mediaURL
          )
        ) {
          mediaUrlErrorMessage = initErrorMessage;
          isValid = false;
          shouldBeCollected = true;
        }
      }
    } else if (currentCareplanComponent.shouldMediaSettingsToggle) {
      if (currentCareplanComponent.additional_configuration.mediaURL == '') {
        mediaUrlErrorMessage = initErrorMessage;
        isValid = false;
        shouldBeCollected = true;
      }
      if (
        !ValidateTemplate.validURL(
          currentCareplanComponent.additional_configuration.mediaURL
        )
      ) {
        mediaUrlErrorMessage = initErrorMessage;
        isValid = false;
        shouldBeCollected = true;
      }
    }
    return [initErrorMessage, shouldBeCollected, isValid];
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#handleNumericRange
   * @param {Object} currentCareplanComponent - careplan component for which media setting is evaluated
   * @param {boolean} minLabelRequired - boolean to set a marker on min label field for making it mandatory to be filled
   * @param {boolean} maxLabelRequired - boolean to set a marker on max label field for making it mandatory to be filled
   * @param {boolean} isValid - boolean flag for determining the validity
   * @returns {Array} This function returns an array with specific validators to determine validity for component of type numeric range
   */

  static handleNumericRange(
    currentCareplanComponent,
    minLabelRequired,
    maxLabelRequired
  ) {
    let hasMinMaxValidationError = false;
    if (currentCareplanComponent.searchTerm.careplanComponentId) {
      if (currentCareplanComponent.searchTerm.hasOwnProperty('minLabel')) {
        if (
          currentCareplanComponent.searchTerm.minLabel != undefined &&
          currentCareplanComponent.searchTerm.minLabel != null
        ) {
          if (currentCareplanComponent.searchTerm.minLabel.trim() == '') {
            minLabelRequired = true;
          }
        } else {
          minLabelRequired = true;
        }
      }
      if (currentCareplanComponent.searchTerm.hasOwnProperty('maxLabel')) {
        if (
          currentCareplanComponent.searchTerm.maxLabel != undefined &&
          currentCareplanComponent.searchTerm.maxLabel != null
        ) {
          if (currentCareplanComponent.searchTerm.maxLabel.trim() == '') {
            maxLabelRequired = true;
          }
        } else {
          maxLabelRequired = true;
        }
      }
      if (currentCareplanComponent.searchTerm.minMaxValidationError == true) {
        hasMinMaxValidationError = true;
      }
    } else if (currentCareplanComponent.searchTerm.label !== undefined) {
      if (currentCareplanComponent.hasOwnProperty('minLabel')) {
        if (
          currentCareplanComponent.minLabel != undefined &&
          currentCareplanComponent.minLabel != null
        ) {
          if (currentCareplanComponent.minLabel.trim() == '') {
            minLabelRequired = true;
          }
        } else {
          minLabelRequired = true;
        }
      }
      if (currentCareplanComponent.hasOwnProperty('maxLabel')) {
        if (
          currentCareplanComponent.maxLabel != undefined &&
          currentCareplanComponent.minLabel != null
        ) {
          if (currentCareplanComponent.maxLabel.trim() == '') {
            maxLabelRequired = true;
          }
        } else {
          maxLabelRequired = true;
        }
      }
      if (currentCareplanComponent.searchTerm.minMaxValidationError == true) {
        hasMinMaxValidationError = true;
      }
    }
    return [minLabelRequired, maxLabelRequired, hasMinMaxValidationError];
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#handleNumericValue
   * @param {Object} currentCareplanComponent - careplan component for which media setting is evaluated
   * @param {boolean} isValid - boolean flag for determining the validity
   * @returns {Array} This function returns an array with specific validators to determine validity for component of type numeric value
   */

  static handleNumericValue(currentCareplanComponent) {
    let hasMinMaxValidationError = false;
    if (currentCareplanComponent.searchTerm.careplanComponentId) {
      if (currentCareplanComponent.searchTerm.minMaxValidationError == true) {
        hasMinMaxValidationError = true;
      }
    } else if (currentCareplanComponent.searchTerm.label !== undefined) {
      if (currentCareplanComponent.minMaxValidationError == true) {
        hasMinMaxValidationError = true;
      }
    }
    return [hasMinMaxValidationError];
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#validURL
   * @param {string} url - url for media settings which has to be validated
   * @returns {boolean} This function returns a boolean flag to determine the validity of the url
   */

  static validURL(url) {
    const pattern = new RegExp(
      '^(https:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'
    ); // query string
    return !!pattern.test(url);
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#validVideoExtension
   * @param {string} url - url for which video extension has to be validated
   * @returns {boolean} This function returns a boolean flag to determine the validity of the video extension
   */

  static validVideoExtension(url) {
    const extension = getExtension(url);
    if (extension.toLowerCase().match(/(mp4|ogg|webm)$/i)) {
      return true;
    }
    return false;
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#isComponentCollectionEmpty
   * @param {Array} componentCollection - list of careplan components associated with the template
   * @returns {boolean} This function returns a boolean flag to determine if the user added atleast one careplan component or not
   */

  static isComponentCollectionEmpty(componentCollection) {
    return componentCollection.length == 0;
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#hasTemplateOrganizations
   * @param {Array} careplanTemplateOrganizations - object containing attributes for careplan template organizations
   * @returns {boolean} This function returns a boolean flag to determine if the user selected atleast one organization for the template
   */

  static hasTemplateOrganizations(careplanTemplateOrganizations) {
    if (careplanTemplateOrganizations) {
      return careplanTemplateOrganizations.length > 0;
    }

    return false;
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#checkLogicJumps
   * @param {Array} components - array containing careplan components in the careplan
   * @param {Array} logicJumpsCollectionError - object containing array of logic jump errors
   * @returns {Boolean} This function returns whether an logic jump error exists
   */

  static checkLogicJumps(components) {
    let hasError = false;
    const collection = {};
    components.map((item, i) => {
      if (checkItemJumpListValidity(item)) {
        item.logicJumpCollection.map(jump => {
          if (jump && jump.destination <= i + 1 && jump.destination > -1) {
            hasError = true;
            collection[item.id] = true;
          }
        });
      }
    });

    if (hasError) return collection;
    return hasError;
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#checkJumpLimit
   * @param {Array} components - array containing careplan components in the careplan
   * @returns {Boolean|Object} This function returns whether an array of objects if an error is found or false is no errors are found
   */

  static checkJumpLimit(components) {
    const mainCollection = components.filter(
      component =>
        component.healthCategory === componentHealthCategories.MAIN_QUESTION
    );
    const limit = mainCollection.length;
    const collection = {};
    let hasError = false;
    mainCollection.map((item, i) => {
      if (checkItemJumpListValidity(item)) {
        item.logicJumpCollection.map(jump => {
          if (jump && jump.destination > limit) {
            hasError = true;
            collection[item.id] = true;
          }
        });
      }
    });
    if (hasError) return collection;
    return hasError;
  }

  /**
   * @memberof ValidateTemplate
   * @inner ValidateTemplate
   * @function ValidateTemplate#checkJumpContradictions
   * @param {Array} components - array containing careplan components in the careplan
   * @returns {Boolean|Object} This function returns whether an array of objects if an error is found or false is no errors are found
   */

  static checkJumpContradictions(components) {
    const mainCollection = components.filter(
      component =>
        component.collection.key === componentTypeOrder.NUMERIC_RANGE ||
        component.collection.key === componentTypeOrder.NUMERIC_VALUE
    );
    const collection = {};
    let hasError = false;
    mainCollection.map((item, i) => {
      if (checkItemJumpListValidity(item)) {
        item.logicJumpCollection.forEach((currJump, currIndex) => {
          item.logicJumpCollection.forEach((jump, index) => {
            if (
              currIndex !== index &&
              deepEqual(currJump.name, jump.name) &&
              currJump.value === jump.value
            ) {
              hasError = true;
              collection[item.id] = true;
            }
          });
        });
      }
    });
    if (hasError) return collection;
    return hasError;
  }
}

export default ValidateTemplate;
