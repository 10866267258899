import React from 'react';
import classNames from 'classnames';

import { Flex, Icon } from '@ui-common-files/components';
import { PrimaryColor } from '@ui-common-files/utils/colors';
import JumpSelection from '../JumpSelection';

import { BloodPressureJumpDataType } from './BloodPressureJump.type';

export const BloodPressureJumpColumns = (headers: {
  [key: string]: string;
}) => [
  {
    accessor: 'option',
  },
  {
    Header: headers.systolicValues,
    accessor: 'systolicValue',
    style: {
      justifyContent: 'center',
    },
  },
  {
    Header: headers.diastolicValues,
    accessor: 'diastolicValue',
    style: {
      justifyContent: 'center',
    },
  },
  {
    accessor: 'jumpControl',
    width: 170,
  },
];

export const BloodPressureJumpData: BloodPressureJumpDataType = ({
  choices,
  setLogicJumpValueForComponent,
  index,
  t,
  logicJumpCollection = [],
}) =>
  choices.map(({ name }) => {
    const arrIndex = logicJumpCollection.findIndex(item =>
      item?.options?.includes(name)
    );
    const jumpEnabled = arrIndex !== -1;
    const destination = logicJumpCollection[arrIndex]?.destination;

    const iconClassNames = classNames({
      'icon-checkbox__circle-svg': true,
      'icon-checkbox__circle-svg__unchecked': !jumpEnabled,
    });

    const toggleJump = () => {
      const collection = [...logicJumpCollection];
      if (jumpEnabled) collection.splice(arrIndex, 1);
      else
        collection.push({
          options: [name],
          destination: index + 2,
        });
      setLogicJumpValueForComponent('logicJumpCollection', collection);
    };

    const changeDestination = (newDestination: number) => {
      const collection = [...logicJumpCollection];
      collection[arrIndex].destination = newDestination;
      setLogicJumpValueForComponent('logicJumpCollection', collection);
    };

    return {
      option: (
        <Flex>
          <Icon
            cursor="pointer"
            name={`logicJump${!jumpEnabled ? 'Unchecked' : ''}`}
            fill={PrimaryColor.MAIN_COLOR}
            className={iconClassNames}
            size={24}
            onClick={toggleJump}
          />
          <>{t(`bloodPressureOptions.labels.${name}`)}</>
        </Flex>
      ),
      systolicValue: t(`bloodPressureOptions.systolicValues.${name}`),
      diastolicValue: t(`bloodPressureOptions.diastolicValues.${name}`),
      jumpControl: jumpEnabled ? (
        <JumpSelection
          parentComponentIndex={0}
          setDestination={changeDestination}
          destination={
            destination <= index + 1
              ? { jump: destination, hasError: true }
              : destination
          }
        />
      ) : null,
    };
  });
