import { ActionColors } from '../../utils/colors';

const taskTypes = {
  1: 'educational',
  2: 'tracking',
  3: 'normal',
};

const appointmentStatusIdToTranslationKeys = {
  1: 'types.appointmentStatus.accepted',
  2: 'types.appointmentStatus.reschedule',
  3: 'types.appointmentStatus.response',
};

const appointmentStatusIdToColor = {
  1: ActionColors.SUCCESS,
  2: ActionColors.ERROR,
  3: ActionColors.WARNING,
};

const appointmentStatusClass = {
  1: 'accepted',
  2: 'reschedule',
  3: 'response',
};

const wearablesDataTypesId = {
  1: 'WEIGHT',
  2: 'HEIGHT',
  3: 'STEPS',
  4: 'CALORIES',
  5: 'HEART_RATE',
  6: 'SLEEP',
  7: 'DISTANCE',
  8: 'BLOOD_PREASURE',
};

export {
  taskTypes,
  appointmentStatusIdToTranslationKeys,
  appointmentStatusIdToColor,
  appointmentStatusClass,
  wearablesDataTypesId,
};
