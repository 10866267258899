export const updateUserPasswordValidation = [
  {
    validation: /.{8,}/,
    message: 'MinChars',
  },
  {
    validation: /(?=.*[0-9!@#$%^&*()_+/[\]?><.,;:'"`~])/,
    message: 'NumberCharacters',
  },
  {
    validation: /(?=.*[a-zäöüß])(?=.*[A-ZÄÖÜẞ])/,
    message: 'Case',
  },
];
