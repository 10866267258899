import I18n from 'i18next';

export const DropDownTaskTypes = () => [
  { value: 1, label: I18n.t('types.taskType.educational') },
  { value: 2, label: I18n.t('types.taskType.tracking') },
  { value: 3, label: I18n.t('types.taskType.normal') },
];

export const DropDownTaskPriority = () => [
  { value: 1, label: I18n.t('types.taskPriority.high') },
  { value: 2, label: I18n.t('types.taskPriority.medium') },
  { value: 3, label: I18n.t('types.taskPriority.low') },
];

export const DropDownPhaseTypes = () => [
  { value: 1, label: I18n.t('types.phaseType.planning') },
  { value: 2, label: I18n.t('types.phaseType.treatment') },
  { value: 3, label: I18n.t('types.phaseType.followUp') },
  { value: 4, label: I18n.t('types.phaseType.diagnosis') },
  { value: 5, label: I18n.t('types.phaseType.screening') },
];

export const DropDownMedicationPhaseTypes = () => [
  { value: 1, label: I18n.t('types.phaseType.planning') },
  { value: 2, label: I18n.t('types.phaseType.treatment') },
  { value: 3, label: I18n.t('types.phaseType.followUp') },
  { value: 4, label: I18n.t('types.phaseType.diagnosis') },
  { value: 5, label: I18n.t('types.phaseType.screening') },
];

export const DropDownAppointmentTypes = () => [
  { value: 1, label: I18n.t('types.appointmentType.attending') },
  { value: 2, label: I18n.t('types.appointmentType.consultation') },
  { value: 3, label: I18n.t('types.appointmentType.discharge') },
  { value: 4, label: I18n.t('types.appointmentType.followup') },
  { value: 5, label: I18n.t('types.appointmentType.test') },
  { value: 6, label: I18n.t('types.appointmentType.transportation') },
  { value: 7, label: I18n.t('types.appointmentType.treatment') },
  { value: 8, label: I18n.t('types.appointmentType.surgeryDate') },
  { value: 10, label: I18n.t('types.appointmentType.videoCall') },
  { value: 9, label: I18n.t('types.appointmentType.others') },
];

export const DropDownMedicationAdministrationType = () => [
  { value: 1, label: I18n.t('types.medicationAdministrationType.narcotic') },
  { value: 2, label: I18n.t('types.medicationAdministrationType.oral') },
  { value: 3, label: I18n.t('types.medicationAdministrationType.intravenous') },
  {
    value: 4,
    label: I18n.t('types.medicationAdministrationType.subcutaneous'),
  },
];
