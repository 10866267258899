import React from 'react';

import { Flex, Tag } from '..';

import { SatellitesTableListService } from './SatellitesTableList.type';
import TagColorVariants from '../Tags/TagColorVariants.type';

export default function useSatellitesTableListService({
  satellites,
}: SatellitesTableListService) {
  const showTooltip = satellites.length > 1;
  const initialSatellite = satellites[0];
  const satellitesList = (
    <Flex flexDirection="column" className="satellitesList__tooltip">
      {satellites.slice(1).map(satellite => (
        <Tag
          shouldHaveEllipsis
          key={satellite.id}
          text={satellite.name}
          colorVariant={TagColorVariants.White}
        />
      ))}
    </Flex>
  );

  return {
    showTooltip,
    initialSatellite,
    satellitesList,
  };
}
