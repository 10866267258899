/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import NotificationRecord from './NotificationRecord';
import NavigationJSON from '../../Utils/navigation.json';
import serviceConfig from '../../../serviceConfig.json';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import { nonMedical } from '../../../config';
import { convertLinkToBlob } from '../../Utils/Asset/assetHelper';
import { NotificationType } from './Notification.type';
import { NotificationTypes } from '@type/Notifications';

const ThresholdsNotificationDropdown = ({
  offsetRight
}) => {
  const services = useSelector(state => state.services);
  const history = useHistory();
  const calcDropdownWidth = () => {
    return Math.min((offsetRight - 40) * 2, 320);
  };
  const [dropdownWidth, setDropdownWidth] = useReducer(
    (_state, width) => width,
    calcDropdownWidth()
  );
  const [notifications, setNotifications] = useReducer(
    (_state, record) => record,
    {
      threshold: null,
    }
  );
  const [isLoading, setIsLoading] = useReducer(
    (_state, loading) => loading,
    true
  );
  const redirectAndOpenResultsModal = (patientId, navigationData) => {
    const path = `/${NavigationJSON.PATIENT_OVERVIEW}/${patientId}`;
    const pathTab = '?tab=0';
    const stateData =
      history.location.pathname === path
        ? {
            ...navigationData,
            refreshCareplanCardViewer: true,
          }
        : navigationData;
    history.push(path + pathTab, stateData);
  };
  const renderThresholdTable = () => {
    if (
      notifications.threshold &&
      notifications.threshold.length &&
      (nonMedical == false || nonMedical == 'false')
    ) {
      return notifications.threshold.map(record => (
        <NotificationRecord
          key={`threshold-${record.id}`}
          type={NotificationType.Threshold}
          rowData={record}
          maxWidth={dropdownWidth}
          redirect={redirectAndOpenResultsModal}
        />
      ));
    }
  };

  useEffect(() => {
    const getNotifications = async () => {
      let thresholdResponse = {};
      try {
        if (services.thresholdNotification) {
          thresholdResponse = await axios
            .get(serviceConfig.brokerService.thresholdNotifications.uri)
            .then(async response => {
              const thresholdNotifications = response.data;
              const processedNotifications = await Promise.all(
                thresholdNotifications.newNotifications.map(
                  async notificationObj => {
                    if (notificationObj.patient.profilePicture.thumbnailURL) {
                      await convertLinkToBlob(
                        notificationObj.patient.profilePicture.thumbnailURL
                      )
                        .then(blob => {
                          notificationObj.patient.profilePicture = blob;
                        })
                        .catch(() => {
                          notificationObj.patient.profilePicture =
                            notificationObj.patient.profilePicture.thumbnailURL;
                        });
                    } else {
                      notificationObj.patient.profilePicture = `data:image;base64,${notificationObj.patient.profilePicture}`;
                    }
                    return notificationObj;
                  }
                )
              );
              thresholdNotifications.newNotifications = processedNotifications;
              return thresholdNotifications;
            });
        }
        if (Object.keys(thresholdResponse).length > 0) {
          const thresholdData = thresholdResponse ? thresholdResponse : {};

          setNotifications({
            threshold:
              thresholdData.numThresholdNotifications > 0 &&
              (nonMedical == false || nonMedical == 'false')
                ? thresholdData.newNotifications.splice(
                    0,
                    Math.min(3, thresholdData.numThresholdNotifications)
                  )
                : false,
          });
          setIsLoading(false);
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    getNotifications();
  }, []);

  useEffect(() => {
    setDropdownWidth(calcDropdownWidth());
  }, [offsetRight]);

  return (
    <div
      className="notification__table-container"
      style={{ width: dropdownWidth, right: offsetRight - dropdownWidth / 2 }}
    >
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{ height: '80px' }}
        >
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={40}
            width={40}
            marginTop="25px"
          />
        </Flex>
      )}
      {!isLoading && (
        <Flex flexDirection="column">
          {!notifications.threshold && (
            <div className="notification__no-messages">
              {i18n.t('threshold.noThresholds')}
            </div>
          )}
          {notifications.threshold && (
            <Box>
              <div className="notification__table-header">
                {i18n.t('threshold.thresholds')}
              </div>
              <div className="notification__table-content">
                {renderThresholdTable()}
              </div>
            </Box>
          )}
          <div
            role="button"
            className="notification__see-all"
            tabIndex={0}
            onClick={() => {
              history.push(
                `/${NavigationJSON.NOTIFICATION_CENTER}?notificationType=${NotificationTypes.THRESHOLD}`
              );
            }}
          >
            {i18n.t('threshold.seeAll')}
          </div>
        </Flex>
      )}
    </div>
  );
};

ThresholdsNotificationDropdown.propTypes = {
  offsetRight: PropTypes.number.isRequired,
  setShowThresholdsNotificationDropdown: PropTypes.func.isRequired,
  showThresholdsNotificationDropdown: PropTypes.bool.isRequired,
};

export default React.memo(ThresholdsNotificationDropdown);
