import React, { useEffect } from 'react';
import { AnsweringComponentsProps } from '../AnsweringTypes';
import { CaretaskComponent } from '../../../../../../types/Caretask';
import { useSelector } from 'react-redux';
import { selectCurrentComponent } from '../../CaretaskAnswering.reducer';

export default function AnsweringInformation({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  useEffect(() => {
    const jumpTo: number =
    currentComponent.hasLogicJump ? currentComponent.logicJumpPaths[0].destination : null;
    handleOnChange({
      answer: null,
      isSkipped: false,
      jumpTo,
    });
  }, []);
  return <div />;
}
