import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@ui-common-files/components';

import { HideFlashMessage } from '@global/Services';
import {
  PromptContent,
  PromptFooter,
} from 'src/Views/common/ConfirmationPrompt';
import usePatientDeleteModalService from './PatientDeleteModal.service';

import { PatientDeleteModalProps } from './PatientDeleteModal.type';

export default function PatientDeleteModal({
  deleteOperation,
  setDeleteOperation,
}: PatientDeleteModalProps) {
  const { closeMessagePrompt, onConfirmClose } = usePatientDeleteModalService({
    deleteOperation,
    setDeleteOperation,
  });
  const { t } = useTranslation();

  const Footer = (
    <PromptFooter close={closeMessagePrompt} confirmHandler={onConfirmClose} />
  );

  const Content = (
    <PromptContent
      message={`${t('common_labels.warning_patient_delete')} ${t(
        'common_labels.msg_confirmDelete'
      )} `}
    />
  );

  return (
    <Modal
      isConfirmationDialog
      title={deleteOperation.title}
      contentComponent={Content}
      footerComponent={Footer}
      openModal={deleteOperation.display}
      onClose={closeMessagePrompt}
      hideFlashMessage={HideFlashMessage}
    />
  );
}
