export const shouldDisplayComponents = (template, careTask) => {
  return (
    template.isEvaluating ||
    template.scoringScheme !== null ||
    template.logicJumpPaths.length > 0 ||
    careTask.thresholds.some(threshold => threshold[1].length > 0)
  );
};

export const checkLogicJumpsThresholdsAndScore = pathwayTemplates =>
  pathwayTemplates.some(template =>
    shouldDisplayComponents(template.template, template)
  );
