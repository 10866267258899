import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AsyncSelect from 'react-select/async';
import { careplanStyles } from '../../../utils/dropdownSelectStyles';
import { ActionColors } from '../../../utils/colors';
import Icon from '../../Icon/Icon';

const SingleSelectAddressSearchable = ({
  loadOptions,
  isDisabled,
  placeholder,
  value,
  inputValue,
  onInputChange,
  handleOnChange,
  hasError,
  validationMessage,
}) => {
  const inputWrapperClasses = classNames({
    disabled: isDisabled,
    'react-select-container': true,
    'searchable-input--error': hasError && !isDisabled,
  });

  return (
    <>
      <div className="datepicker-wrapper">
        <AsyncSelect
          defaultOptions
          loadOptions={loadOptions}
          isDisabled={isDisabled}
          isSearchable
          placeholder={placeholder}
          value={value}
          inputValue={inputValue}
          onInputChange={onInputChange}
          onChange={handleOnChange}
          styles={careplanStyles}
          className={inputWrapperClasses}
          classNamePrefix="react-select"
          hasError={hasError}
        />
        {hasError && (
          <div className="datepicker-error-icon">
            <Icon
              name="error"
              fill={ActionColors.ERROR}
              className="input__text__icon right-icon"
            />
          </div>
        )}
      </div>
      {hasError && (
        <span className="searchable-select__error">{validationMessage}</span>
      )}
    </>
  );
};

SingleSelectAddressSearchable.propTypes = {
  loadOptions: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  hasError: PropTypes.bool,
  validationMessage: PropTypes.string,
};

SingleSelectAddressSearchable.defaultProps = {
  value: '',
  hasError: false,
  validationMessage: '',
};

export default SingleSelectAddressSearchable;
