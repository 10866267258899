import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@ui-common-files/components';
import AnswerHeaderComponent from '../AnswerHeaderComponent';

import { GeneralAnswerType } from '../GeneralAnswer.type';
import { CareplanComponentAnswer } from '../../../../types/Careplan';

import { getIsThreshold } from '../../../ReportGenerator/Careplan/CareplanReport.utils';
import { renderComponentOptions } from './BloodPressureAnswer.service';
import {
  getComponentOccurrencesAnswer,
  getThresholdClass,
} from '../AnswerResult.services';

import '../AnswerResultCommon.scss';

function BloodPressureAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const { t } = useTranslation();

  const renderComponentAnswers = useCallback(
    (occurrenceAnswers: CareplanComponentAnswer[], index: number) => {
      let answerContent: JSX.Element | JSX.Element[] = (
        <Box className="answer-component_header-columns-column-answer">-</Box>
      );
      if (occurrenceAnswers.length > 0) {
        answerContent = occurrenceAnswers.map(
          (answer: CareplanComponentAnswer, indexAnswer: number) => {
            const answerOutput: string[] = [];
            if (answer.isSkipped) {
              answerOutput.push('-');
            } else {
              answerOutput.push(
                t(
                  `bloodPressureOptions.labels.${answer.answer_blood_pressure_value}`
                )
              );
            }

            return (
              <Box
                key={`col-header-answer-${componentIndex}-${indexAnswer}`}
                otherStyles={{ flex: 1, minWidth: 0 }}
              >
                {answerOutput.map(
                  (answerItem: string, indexAnswerOutput: number) => {
                    const isThreshold: boolean =
                      !answer.isSkipped &&
                      getIsThreshold(componentData, answer);
                    const headerContentStyles = getThresholdClass(isThreshold);
                    return (
                      <Box
                        key={`col-header-answer-item-${componentIndex}-${indexAnswer}-${indexAnswerOutput}`}
                        className={headerContentStyles}
                        otherStyles={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          padding: '0 4px',
                        }}
                      >
                        {answerItem}
                        {isThreshold === true && <>*</>}
                      </Box>
                    );
                  }
                )}
              </Box>
            );
          }
        );
      }
      return (
        <Flex
          key={`col-header-${componentIndex}-${index}`}
          className="answer-component_header-columns-column"
          alignItems="center"
          justifyContent="center"
        >
          {answerContent}
        </Flex>
      );
    },
    []
  );

  return (
    <Box className="answer-component">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName="BloodPressure"
        title={componentData.name}
        columnOccurrencesRenderer={() => {
          return getComponentOccurrencesAnswer(
            allOccurrences,
            componentData.answers,
            renderComponentAnswers
          );
        }}
      />
      <Box className="answer-component_options">
        {renderComponentOptions(allOccurrences, componentData, componentIndex)}
      </Box>
    </Box>
  );
}

export default BloodPressureAnswer;
