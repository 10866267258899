import I18n from 'i18next';
import Moment from 'moment';
import validateFirstnameLastname from './hooks/validateFirstnameLastname';

function calculateAge() {
  return 'TIMESTAMPDIFF(YEAR,patients.birthdate, NOW())';
}

const checkBirthdateAndAppointmentDates = (value, operators) => {
  if (
    operators.operator.type == 'between' ||
    operators.operator.type == 'not_between'
  ) {
    const startDate = value[0] && Date.parse(value[0]);
    const endDate = value[1] && Date.parse(value[1]);
    if (startDate && endDate && startDate > endDate) {
      return I18n.t('recurrence.endDateError');
    }
    return true;
  }
  if (value == null) {
    return 'datetime_empty';
  } else {
    return true;
  }
};

const checkAnswerDates = (value, operators) => {
  if (operators.operator.type == 'between') {
    if (value[0]) {
      const date1 =
        value[0].slice(0, 4) +
        '-' +
        value[0].slice(4, 6) +
        '-' +
        value[0].slice(6);
      const date2 =
        value[1].slice(0, 4) +
        '-' +
        value[1].slice(4, 6) +
        '-' +
        value[1].slice(6);
      const startDate = Date.parse(date1);
      const endDate = value[1] && Date.parse(date2);
      if (startDate && endDate && startDate > endDate) {
        return I18n.t('recurrence.endDateError');
      }
    }
  } else if (value == null) {
    return 'datetime_empty';
  }
  return true;
};

function createCustomFilterData(services) {
  const todaysDate = new Moment().format('YYYY-MM-DD');
  const careplanFilterData = [];
  const todaysAnswerDate = new Moment().format('YYYYMMDD');
  const filterData = [
    {
      id: 'patients.firstname',
      label: I18n.t('patients_complexsearch.label_firstName'),
      type: 'string',
      optgroup: I18n.t('nav.patients'),
      validation: {
        callback: function (value) {
          const isNotValid = validateFirstnameLastname(value);
          if (value) {
            if (isNotValid) {
              return I18n.t('patients_view.invalidFirstName');
            }
            return true;
          }
          return 'datetime_empty';
        },
      },
    },
    {
      id: 'patients.lastname',
      label: I18n.t('patients_complexsearch.label_lastName'),
      type: 'string',
      optgroup: I18n.t('nav.patients'),
      validation: {
        callback: function (value) {
          const isNotValid = validateFirstnameLastname(value);
          if (value) {
            if (isNotValid) {
              return I18n.t('patients_view.invalidFirstName');
            }
            return true;
          }
          return 'datetime_empty';
        },
      },
    },
    {
      id: 'patients.medicalRecord',
      label: I18n.t('patients_complexsearch.label_medicalRecord'),
      type: 'string',
      operators: [
        'equal',
        'not_equal',
        'in',
        'not_in',
        'is_null',
        'is_not_null',
      ],
      optgroup: I18n.t('nav.patients'),
      validation: {
        format: /^([^//?]*)$/,
        messages: {
          format: 'Invalid text',
        },
      },
    },
    {
      id: 'patients.room',
      label: I18n.t('patients_complexsearch.label_room'),
      type: 'integer',
      optgroup: I18n.t('nav.patients'),
    },
    {
      id: 'patients.ward',
      label: I18n.t('patients_complexsearch.label_ward'),
      type: 'string',
      optgroup: I18n.t('nav.patients'),
      validation: {
        format: /^([^//?]*)$/,
        messages: {
          format: 'Invalid text',
        },
      },
    },
    {
      id: calculateAge(),
      label: I18n.t('patients_complexsearch.label_age'),
      type: 'integer',
      optgroup: I18n.t('nav.patients'),
    },
    {
      id: 'patients.birthdate',
      label: I18n.t('patients_complexsearch.label_dateOfBirth'),
      type: 'date',
      optgroup: I18n.t('nav.patients'),
      validation: {
        format: Moment('YYYY-MM-DD').isValid(),
        callback: function (value, operators) {
          return checkBirthdateAndAppointmentDates(value, operators);
        },
        messages: {
          format: 'Invalid text',
        },
      },
      plugin: 'datepicker',
      plugin_config: {
        format: 'yyyy-mm-dd',
        todayBtn: 'linked',
        todayHighlight: true,
        autoclose: true,
      },
    },
    {
      id: 'patients.gender',
      label: I18n.t('patients_complexsearch.label_gender'),
      type: 'string',
      input: 'select',
      values: ['Male', 'Female'],
      operators: ['equal'],
      optgroup: I18n.t('nav.patients'),
    },
    {
      id: 'icds.code',
      label: I18n.t('patients_complexsearch.label_icdsCode'),
      type: 'string',
      optgroup: I18n.t('patients_complexsearch.label_icds'),
    },
    {
      id: 'icds.description',
      label: I18n.t('patients_complexsearch.label_icdsDescription'),
      type: 'string',
      optgroup: I18n.t('patients_complexsearch.label_icds'),
    },
    {
      id: 'ops.code',
      label: I18n.t('patients_complexsearch.label_opsCode'),
      type: 'string',
      optgroup: I18n.t('patients_complexsearch.label_ops'),
    },
    {
      id: 'ops.description',
      label: I18n.t('patients_complexsearch.label_opsDescription'),
      type: 'string',
      optgroup: I18n.t('patients_complexsearch.label_ops'),
    },
    {
      id: 'institutions.name',
      label: I18n.t('site_view.label_complexSearchSite'),
      type: 'string',
      optgroup: I18n.t('site_view.label_complexSearchSite'),
      unique: true,
      default_value: '.',
      validation: {
        format: /^([^//?]*)$/,
        messages: {
          format: 'Invalid text',
        },
      },
    },
  ];

  if (services && services.hasOwnProperty('careplanservice.myoncare.care')) {
    filterData.push(
      {
        id: 'careplans.name',
        label: I18n.t('patients_complexsearch.label_careplanName'),
        type: 'string',
        optgroup: I18n.t('nav.caretasks'),
        validation: {
          format: /^([^//?]*)$/,
          messages: {
            format: 'Invalid text',
          },
        },
      },
      {
        id: 'answers.answerForDate',
        label: I18n.t('patients_complexsearch.label_careplanAnswer'),
        type: 'date',
        operators: ['equal', 'between'],
        optgroup: I18n.t('nav.caretasks'),
        validation: {
          format: Moment('yyyymmdd').isValid(),
          callback: function (value, operators) {
            return checkAnswerDates(value, operators);
          },
        },
        plugin: 'datepicker',
        plugin_config: {
          format: 'yyyymmdd',
          todayBtn: 'linked',
          default_value: todaysDate,
          todayHighlight: true,
          autoclose: true,
        },
      },
      {
        id: 'answers.answerForDateToday',
        label: I18n.t('patients_complexsearch.label_careplanTodayAnswered'),
        type: 'date',
        operators: ['equal'],
        optgroup: I18n.t('nav.caretasks'),
        validation: {
          format: Moment('yyyymmdd').isValid(),
        },
        default_value: todaysAnswerDate,
        plugin: 'datepicker',
        plugin_config: {
          format: 'yyyymmdd',
          todayBtn: 'linked',
          default_value: todaysAnswerDate,
          todayHighlight: true,
          autoclose: true,
        },
      },
      {
        id: 'allOccurrences.score',
        label: I18n.t('patients_complexsearch.label_careplanScore'),
        type: 'integer',
        optgroup: I18n.t('nav.caretasks'),
      },
      {
        id: 'allOccurrences.isAnswered',
        label: I18n.t('patients_complexsearch.label_careplanAnswered'),
        type: 'string',
        input: 'select',
        values: {
          1: 'True',
          0: 'False',
        },
        optgroup: I18n.t('nav.caretasks'),
        operators: ['equal'],
      },
      {
        id: 'careplanComponents.name',
        label: I18n.t('patients_complexsearch.componentName'),
        type: 'string',
        optgroup: I18n.t('nav.careplanComponent'),
        validation: {
          format: /^([^//?]*)$/,
          messages: {
            format: 'Invalid text',
          },
        },
      },
      {
        id: 'careplanComponents.careplanComponentTypeId',
        label: I18n.t('patients_complexsearch.componentType'),
        input: 'select',
        optgroup: I18n.t('nav.careplanComponent'),
        values: {
          1: I18n.t('types.careplanComponentType.freeText'),
          2: I18n.t('types.careplanComponentType.singleChoice'),
          3: I18n.t('types.careplanComponentType.multipleChoice'),
          4: I18n.t('types.careplanComponentType.numericRange'),
          5: I18n.t('types.careplanComponentType.numericValue'),
          6: I18n.t('types.careplanComponentType.yesno'),
          7: I18n.t('types.careplanComponentType.painLocationChart'),
          8: I18n.t('types.careplanComponentType.sortable'),
          9: I18n.t('types.careplanComponentType.information'),
          10: I18n.t('types.careplanComponentType.groupDescription'),
          11: I18n.t('types.careplanComponentType.uploadFile'),
          12: I18n.t('types.careplanComponentType.datepicker'),
          13: I18n.t('types.careplanComponentType.bmi'),
        },
        operators: ['equal'],
        unique: true,
      }
    );
    careplanFilterData.push(
      {
        id: 'careplans.name',
        label: I18n.t('patients_complexsearch.label_careplanName'),
        type: 'string',
        optgroup: I18n.t('nav.caretasks'),
        validation: {
          format: /^([^//?]*)$/,
          messages: {
            format: 'Invalid text',
          },
        },
      },
      {
        id: 'answers.answerForDate',
        label: I18n.t('patients_complexsearch.label_careplanAnswer'),
        type: 'date',
        optgroup: I18n.t('nav.caretasks'),
        operators: ['equal', 'between'],
        validation: {
          format: Moment('yyyymmdd').isValid(),
          callback: function (value, operators) {
            return checkAnswerDates(value, operators);
          },
        },
        plugin: 'datepicker',
        plugin_config: {
          format: 'yyyymmdd',
          todayBtn: 'linked',
          default_value: todaysDate,
          todayHighlight: true,
          autoclose: true,
        },
      },
      {
        id: 'answers.answerForDateToday',
        label: I18n.t('patients_complexsearch.label_careplanTodayAnswered'),
        type: 'date',
        operators: ['equal'],
        optgroup: I18n.t('nav.caretasks'),
        default_value: todaysAnswerDate,
        validation: {
          format: Moment('yyyymmdd').isValid(),
        },
        plugin: 'datepicker',
        plugin_config: {
          format: 'yyyymmdd',
          todayBtn: 'linked',
          default_value: todaysAnswerDate,
          todayHighlight: true,
          autoclose: true,
        },
      },
      {
        id: 'allOccurrences.score',
        label: I18n.t('patients_complexsearch.label_careplanScore'),
        type: 'integer',
        optgroup: I18n.t('nav.caretasks'),
      },
      {
        id: 'allOccurrences.isAnswered',
        label: I18n.t('patients_complexsearch.label_careplanAnswered'),
        type: 'string',
        input: 'select',
        values: {
          1: 'True',
          0: 'False',
        },
        optgroup: I18n.t('nav.caretasks'),
        operators: ['equal'],
      },
      {
        id: 'careplanComponents.name',
        label: I18n.t('patients_complexsearch.componentName'),
        type: 'string',
        optgroup: I18n.t('nav.careplanComponent'),
        validation: {
          format: /^([^//?]*)$/,
          messages: {
            format: 'Invalid text',
          },
        },
      },
      {
        id: 'careplanComponents.careplanComponentTypeId',
        label: I18n.t('patients_complexsearch.componentType'),
        input: 'select',
        optgroup: I18n.t('nav.careplanComponent'),
        values: {
          1: I18n.t('types.careplanComponentType.fretText'),
          2: I18n.t('types.careplanComponentType.singleChoice'),
          3: I18n.t('types.careplanComponentType.multipleChoice'),
          4: I18n.t('types.careplanComponentType.numericRange'),
          5: I18n.t('types.careplanComponentType.numericValue'),
          6: I18n.t('types.careplanComponentType.yesNo'),
          7: I18n.t('types.careplanComponentType.painLocationChart'),
          8: I18n.t('types.careplanComponentType.sortable'),
          9: I18n.t('types.careplanComponentType.information'),
          10: I18n.t('types.careplanComponentType.groupDescription'),
          11: I18n.t('types.careplanComponentType.uploadFile'),
          12: I18n.t('types.careplanComponentType.datepicker'),
          13: I18n.t('types.careplanComponentType.bmi'),
        },
        operators: ['equal'],
        unique: true,
      }
    );
  }

  if (services && services.hasOwnProperty('appointmentservice.myoncare.care')) {
    filterData.push(
      {
        id: 'date(reminders.startsAt)',
        label: I18n.t('patients_complexsearch.label_appointmentDate'),
        type: 'date',
        optgroup: I18n.t('appointment.appointment'),
        validation: {
          format: Moment('yyyy-mm-dd').isValid(),
          callback: function (value, operators) {
            return checkBirthdateAndAppointmentDates(value, operators);
          },
        },
        plugin: 'datepicker',
        plugin_config: {
          format: 'yyyy-mm-dd',
          todayBtn: 'linked',
          todayHighlight: true,
          autoclose: true,
        },
      },
      {
        id: 'appointmentStatuses.name',
        label: I18n.t('patients_complexsearch.label_appointmentStatus'),
        type: 'string',
        optgroup: I18n.t('appointment.appointment'),
        validation: {
          format: /^([^//?]*)$/,
          messages: {
            format: 'Invalid text',
          },
        },
      },
      {
        id: 'appointmentTypes.name',
        label: I18n.t('patients_complexsearch.label_appointmentType'),
        input: 'select',
        optgroup: I18n.t('appointment.appointment'),
        values: {
          'types.appointmentType.attending': I18n.t(
            'types.appointmentType.attending'
          ),
          'types.appointmentType.consultation': I18n.t(
            'types.appointmentType.consultation'
          ),
          'types.appointmentType.discharge': I18n.t(
            'types.appointmentType.discharge'
          ),
          'types.appointmentType.followup': I18n.t(
            'types.appointmentType.followup'
          ),
          'types.appointmentType.test': I18n.t('types.appointmentType.test'),
          'types.appointmentType.transportation': I18n.t(
            'types.appointmentType.transportation'
          ),
          'types.appointmentType.treatment': I18n.t(
            'types.appointmentType.treatment'
          ),
          'types.appointmentType.surgeryDate': I18n.t(
            'types.appointmentType.surgeryDate'
          ),
          'types.appointmentType.others': I18n.t(
            'types.appointmentType.others'
          ),
          'types.appointmentType.videoCall': I18n.t(
            'types.appointmentType.videoCall'
          ),
        },
        operators: [
          'equal',
          'not_equal',
          'begins_with',
          'not_begins_with',
          'contains',
          'not_contains',
          'ends_with',
          'not_ends_with',
        ],
        unique: true,
      },
      {
        id: 'appointments.place',
        label: I18n.t('patients_complexsearch.label_appointmentLocation'),
        type: 'string',
        optgroup: I18n.t('appointment.appointment'),
        validation: {
          format: /^([^//?]*)$/,
          messages: {
            format: 'Invalid text',
          },
        },
      },
      {
        id: 'appointments.calendarId',
        label: I18n.t('patients_complexsearch.label_appointmentcalendarId'),
        type: 'string',
        optgroup: I18n.t('appointment.appointment'),
        validation: {
          format: /^([^//?]*)$/,
          messages: {
            format: 'Invalid text',
          },
        },
      },
      {
        id: 'appointments.detail',
        label: I18n.t('patients_complexsearch.label_appointmentDetail'),
        type: 'string',
        optgroup: I18n.t('appointment.appointment'),
        validation: {
          format: /^([^//?]*)$/,
          messages: {
            format: 'Invalid text',
          },
        },
      },
      {
        id: 'reminders.startsAt',
        label: I18n.t('patients_complexsearch.label_appointmentToday'),
        type: 'string',
        optgroup: I18n.t('appointment.appointment'),
        input: 'text',
        default_value: todaysDate,
        operators: ['equal'],
      }
    );
  }

  if (services && services.hasOwnProperty('medicationservice.myoncare.care')) {
    filterData.push({
      id: 'medications.name',
      label: I18n.t('patients_complexsearch.label_medicationName'),
      type: 'string',
      optgroup: I18n.t('nav.medications'),
      validation: {
        format: /^([^//?]*)$/,
        messages: {
          format: 'Invalid text',
        },
      },
    });
  }


  if (services === null) {
    return null;
  }
  return {
    defaultRule: {
      condition: 'AND',
      rules: [
        {
          id: 'patients.firstname',
          field: 'patients.firstname',
          type: 'string',
          input: 'text',
          operator: 'is_not_null',
          value: null,
        },
      ],
      valid: true,
    },
    filterData,
    careplanFilterData,
  };
}

export default createCustomFilterData;
