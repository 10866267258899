import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserPermission } from '@utils';
import {
  ActionTranslationKeys,
  ComponentTranslationKeys,
} from '@ui-common-files/utils';

import { fetchSatellites } from '../../Satellites/Satellites.api';

const useQrCodePanelService = (siteId: number, siteName: string) => {
  const [qrCodeType, setQrCodeType] = useState({
    value: 0,
    label: siteName,
  });
  const [qrCodeDropdownOptions, setQrCodeDropdownOptions] = useState([]);
  const { t } = useTranslation();
  const hasSatelliteCredentials = useUserPermission(
    t(ComponentTranslationKeys.SATELLITE),
    t(ActionTranslationKeys.READ),
    true
  );

  const getQrCodeTypeOptions = async () => {
    const result = await fetchSatellites(siteId);
    if (result?.data)
      setQrCodeDropdownOptions([qrCodeType, ...result.data.satellites]);
  };

  useEffect(() => {
    if (hasSatelliteCredentials) getQrCodeTypeOptions();
  }, [hasSatelliteCredentials]);

  return {
    qrCodeType,
    setQrCodeType,
    qrCodeDropdownOptions,
    hasSatelliteCredentials,
  };
};

export default useQrCodePanelService;
