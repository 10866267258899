import React from 'react';
import i18next from 'i18next';
import Dropdown from '../../../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import {FilterCriteriaMultipleOperatorProps} from './FilterCriteriaMultipleOperator.type';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';

const FilterCriteriaMultipleOperator = ({criteria}: FilterCriteriaMultipleOperatorProps) => {
    const {
      criteriaOperator,
      updateCriteriaOperator,
      currentFilter
    } = usePatientFilterContext();

    const options =[
      {
        value: 'And',
        label: i18next.t('filter_operators.and'),
        labelLocation: 'filter_operators.and'
      },
      {
        value: 'Or',
        label: i18next.t('filter_operators.or'),
        labelLocation: 'filter_operators.or'
      },
    ];

    const operator = 
      currentFilter !== null ? 
      { ...criteriaOperator[currentFilter], label: i18next.t(criteriaOperator[currentFilter].labelLocation) } : {label: ''};

    return (
      <Flex className="filters__criteria-operator">
        {criteria.position === 0 ? (
          <div className="filters__criteria-operator-text">
            {i18next.t('patients_complexsearch.where').toString()}
          </div>
        ) : criteria.position === 1 ? (
          <Dropdown
            value={operator}
            options={options}
            handleOnChange={updateCriteriaOperator}
          />
        ) : (
          <div className="filters__criteria-operator-text">
              {operator.label}
          </div>
        )}
      </Flex>
    )
}

export default FilterCriteriaMultipleOperator;