import { useContext } from 'react';
import { useOverviewStore } from '../Overview.store';
import DataPointType from '../OverviewDataChart/OverviewDataPointsSelection/OverviewDataPointsSelection.enum';
import { PatientOverviewContext } from 'src/Views/PatientOverview/PatientOverview';

const useHandleClose = () => {
  const { overviewDataPoints, setOverviewDataPoints } = useOverviewStore(
    state => ({
      overviewDataPoints: state.overviewDataPoints,
      setOverviewDataPoints: state.setOverviewDataPoints,
    })
  );

  const { setShouldGetGraphViewData } = useContext(PatientOverviewContext);

  const handleClose = (
    dataPointType: number,
    dataPointKey: number,
    dataPointScoreId: number,
    shouldGetGraphViewData: boolean = false
  ) => {
    const filteredDataPoints = overviewDataPoints?.selectedDataPoints?.filter(
      dataPoint =>
        dataPointType === DataPointType.HEALTH_DATA
          ? dataPoint.healthDataKey !== dataPointKey
          : !(
              dataPoint.scoreId === dataPointScoreId &&
              dataPoint.dataPointType === dataPointType
            )
    );
    setOverviewDataPoints({
      ...overviewDataPoints,
      selectedDataPoints: filteredDataPoints,
    });
    setShouldGetGraphViewData(shouldGetGraphViewData);
  };

  return {
    handleClose,
  };
};

export default useHandleClose;
