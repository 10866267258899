import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../../../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';

import { formatTimestamp } from '../../../../../Global/Date';
import { toLocalizeDate } from '../../../../../../caro-ui-commonfiles/utils/dateUtils';
import { Greys } from '../../../../../../caro-ui-commonfiles/utils/colors';
import IcdOps from '../../../../../types/Common/IcdOps.type';
import {
  CareplanReportFirstPageProps,
  ScoreChart,
} from './CareplanReportFirstPage.type';

import CareplanReportPageFooter from '../CareplanReportPageFooter/CareplanReportPageFooter';
import CareplanReportScoreChart from './CareplanReportScoreChart';
import ReportGeneratedDate from '../ReportGeneratedDate/ReportGeneratedDate';

import { getChartData } from './CareplanReportFirstPage.service';

import './CareplanReportFirstPage.scss';
import ReportGeneratedAnsweredBy from '../ReportGeneratedAnsweredBy/ReportGeneratedAnsweredBy';

const CareplanReportFirstPage = React.forwardRef<
  HTMLDivElement,
  CareplanReportFirstPageProps
>(({ totalPages, reportData }, ref): JSX.Element => {
  const { t } = useTranslation();
  const {
    allOccurrences,
    patient,
    institutions,
    name,
    description,
    totalScore,
    occurrenceScores,
    isEvaluating,
  } = reportData;

  const chartData: ScoreChart[] = getChartData(occurrenceScores);
  const hasAnsweredOccurrences = !!(
    allOccurrences &&
    allOccurrences.length > 0 &&
    allOccurrences.some(occurrence => occurrence.isAnswered)
  );
  return (
    <Box className="sub-page report-firstpage" ref={ref}>
      <Box className="first-page_header">
        <Box className="first-page_header-leftcolor" />
        <Box className="first-page_header-patient">
          <Flex
            className="first-page_header-patient-content"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box className="first-page_header-patient-content-name" flex={1}>
              <h1>
                {patient.firstname} {patient.lastname}
              </h1>
            </Box>
            <Box>
              {patient.medicalRecord && (
                <Box className="first-page_header-patient-content-info">
                  <span className="first-page_header-patient-content-info-label">
                    {t('patients_view.label_medicalRecord')}:
                  </span>
                  {patient.medicalRecord}
                </Box>
              )}
              {!patient.isBirthdayHardcoded && patient.birthdate && (
                <Box className="first-page_header-patient-content-info">
                  <span className="first-page_header-patient-content-info-label">
                    {t('patients_view.label_dateOfBirth')}:
                  </span>
                  {toLocalizeDate(patient.birthdate)}
                </Box>
              )}
              {patient.icds.length !== 0 && (
                <Box className="first-page_header-patient-content-info">
                  <span className="first-page_header-patient-content-info-label">
                    {t('newCareplan_view.icd_code')}:
                  </span>
                  {patient.icds.map((icd: IcdOps) => (
                    <span
                      key={`icd-${icd.id}`}
                      className="first-page_header-patient-content-info-icd"
                    >
                      {icd.code}
                    </span>
                  ))}
                </Box>
              )}
              {patient.gender && (
                <Box className="first-page_header-patient-content-info">
                  <span className="first-page_header-patient-content-info-label">
                    {t('patients_view.label_gender')}:
                  </span>
                  {patient.gender}
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
        <Box className="first-page_header-site">
          <Flex
            className="first-page_header-site-content"
            flexDirection="column"
          >
            <Box flex={1}>
              <img src={institutions[0].logo} />
            </Box>
            <Box className="first-page_header-site-content-name">
              {institutions[0].name}
            </Box>
            <Flex className="first-page_header-site-content-address">
              <Box className="first-page_header-site-content-icon">
                <Icon name="MapMarker" fill={Greys.DARK_GREY_70} size={19} />
              </Box>
              <Box>{institutions[0].place}</Box>
            </Flex>
            <Flex className="first-page_header-site-content-email">
              <Box className="first-page_header-site-content-icon">
                <Icon name="Email" fill={Greys.DARK_GREY_70} size={19} />
              </Box>
              <Box>{institutions[0].email}</Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box className="first-page_content">
        <h2 className="first-page_content-title">{name}</h2>
        <ReportGeneratedDate />
        {occurrenceScores.length > 0 && (
          <Box className="first-page_content-recurrence">
            <span>{t('recurrence.totalRecurrence')}:</span>
            {` ${occurrenceScores.length} ${t('recurrence.times')} | `}
            <span>{t('recurrence.lifespan')}:</span>
            {` ${allOccurrences[0].lifespan} ${t(
              `recurrence.${allOccurrences[0].lifespanUnit.toLowerCase()}`
            )} | `}
            {`${formatTimestamp(occurrenceScores[0].unixEpochs)}`}
            {occurrenceScores[0].unixEpochs !==
              occurrenceScores[occurrenceScores.length - 1].unixEpochs && (
              <>
                {` - ${formatTimestamp(
                  occurrenceScores[occurrenceScores.length - 1].unixEpochs
                )}`}
              </>
            )}
          </Box>
        )}
        <Box className="first-page_content-description">{description}</Box>

        <Flex flexDirection="row" className="first-page_content-answeredby">
          {hasAnsweredOccurrences && (
            <span>{`${t('PDFReport.answeredBy')}`}</span>
          )}
          {hasAnsweredOccurrences &&
            allOccurrences.map((occurrence, index: number) => {
              const { user: answerUser } = occurrence;
              const HCPRoleName =
                answerUser?.role?.name ?? t('occurenceView.N/A');
              const answeredByRole = answerUser.isPatient
                ? 'Patient'
                : HCPRoleName;
              return (
                <React.Fragment key={`${occurrence.id}-${index}`}>
                  <ReportGeneratedAnsweredBy
                    answeredByRole={answeredByRole}
                    answeredByName={answerUser.fullName}
                    answerDate={formatTimestamp(occurrence.unixEpochs)}
                  />
                  {index !== allOccurrences.length - 1 && (
                    <Box>&nbsp;|&nbsp;</Box>
                  )}
                </React.Fragment>
              );
            })}
        </Flex>
      </Box>
      {isEvaluating && occurrenceScores.length > 1 && (
        <CareplanReportScoreChart
          chartData={chartData}
          totalScore={totalScore}
        />
      )}
      <CareplanReportPageFooter pageNumber={1} totalPages={totalPages} />
    </Box>
  );
});

export default CareplanReportFirstPage;
