import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Box, Button, TextInput } from '@ui-common-files/components';

import {
  ButtonType,
  ButtonVariant,
  inputField,
  MaxCharLimits,
} from '@ui-common-files/utils';

import { PatientListHeaderProps } from './PatientsListHeader.type';
import PatientsListTabs from '../PatientsListTabs';
import FilterDropdownContainer from '../../Patients/Filter/FilterDropdown/FilterDropdownContainer/FilterDropdownContainer';

const PatientsListHeader = ({
  activeTab,
  onClickTabItem,
  oldPatientsCounter,
  newPatientsCounter,
  searchTerm,
  handlePatientSearch,
  setOpenResultsModal,
  showFilterSettings,
  setShowFilterSettings,
  selectedFilter,
  setDisplayFlashMsg,
  selectAdvancedFilter,
}: PatientListHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex>
        <PatientsListTabs
          activeTab={activeTab}
          onClickTabItem={onClickTabItem}
          oldPatientsCounter={oldPatientsCounter}
          newPatientsCounter={newPatientsCounter}
        />
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex className="filters" margin="0 0 10px 0">
          <Box margin="10px 20px 20px 20px">
            <Button
              label={t('newCareplan_view.exportAnswers')}
              type={ButtonType.BUTTON}
              onClick={() => {
                setOpenResultsModal(true);
              }}
              variant={ButtonVariant.CONTAINED}
            />
          </Box>
          <Box width="250px" margin="10px 0px 20px">
            <TextInput
              value={searchTerm}
              handleOnChange={handlePatientSearch}
              placeholder={t('common_labels.label_search')}
              variant={inputField.variant.CHAR_COUNT}
              maxChars={MaxCharLimits.searchInputs.searchKeyword}
            />
          </Box>
          <FilterDropdownContainer
            showFilterSettings={showFilterSettings}
            setShowFilterSettings={setShowFilterSettings}
            advancedFilterList={selectedFilter.allFilters}
            selectAdvancedFilter={selectAdvancedFilter}
            setDisplayFlashMsg={setDisplayFlashMsg}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PatientsListHeader;
