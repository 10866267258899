import { ApiClient } from '@global/Services';
import serviceConfig from '../../../../serviceConfig.json';

export const getPatientLogsByUuid = ({ controller, uuid, page, pageSize }) => {
  return ApiClient.GET({
    signal: controller.signal,
    url: serviceConfig.brokerService.getPatientLogsByUuid.uri,
    payload: { uuid, page, pageSize },
  });
};

export const getSessionLogBySessionId = sessionId => {
  return ApiClient.GET({
    url: serviceConfig.brokerService.getSessionLogBySessionId.uri,
    payload: { sessionId: sessionId },
  });
};
