import React from 'react';
import I18n from 'i18next';

import { ICDViewer } from '@ui-common-files/components';
import { anchorLinkRegex } from '@global/Utils/Regex/Regex';

export const getPrefilledTemplatePhaseType = (
  phaseTypes,
  templateAssociatedPhaseTypeId
) => {
  return phaseTypes.filter(item => {
    return item.id === templateAssociatedPhaseTypeId;
  });
};

export const renderIcds = (allIcds, searchTermIcds) => {
  const icdCollection = allIcds?.length > 0 ? allIcds : searchTermIcds;
  return <ICDViewer icds={icdCollection} shouldShowAll />;
};

export const renderValidationComponent = translationKey => {
  return (
    <div className="showHideContainer">
      <span className="templateRequired">{I18n.t(translationKey)}</span>
    </div>
  );
};

export const renderComponentValidation = translationKey => {
  return (
    <div className="showHideContainer">
      <span className="componentValidation">{I18n.t(translationKey)}</span>
    </div>
  );
};

export const descriptionValidator = (description: string) => {
  if (!description) return true;

  const fullDescription = description
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<');

  const matches = fullDescription.match(/<a.+?\/a>/g);
  if (matches === null) return true;

  let result = true;
  for (let i = 0; i < matches.length; i++)
    result &&= anchorLinkRegex.test(matches[i]);

  return result;
};
