import { ApiClient } from '@global/Services';

import { PAGE_SIZE } from '../CarePathWayBuilder.constant';

import serviceConfig from '../../../../../serviceConfig.json';

export const getCareTaskAPI = (signal, keyword, page, size = PAGE_SIZE) =>
  ApiClient.GET({
    url: serviceConfig.brokerService.templates.uri,
    payload: {
      searchValue: keyword,
      page,
      size,
    },
    signal,
  });
