import React from 'react';
import classNames from 'classnames';

import {
  CareplanComponentAnswer,
  CarePlanReportOccurrence,
} from '../../../types/Careplan';

const getAnswersOccurrenceById = (
  answers: CareplanComponentAnswer[],
  occurrenceId: number
): CareplanComponentAnswer[] => {
  return answers.filter(
    (answer: CareplanComponentAnswer) => answer.allOccurrenceId === occurrenceId
  );
};

const getComponentOccurrencesAnswer = (
  allOccurrences: CarePlanReportOccurrence[],
  answers: CareplanComponentAnswer[],
  renderContent: (
    occurrenceAnswers: CareplanComponentAnswer[],
    index: number,
    answerDate?: number
  ) => JSX.Element
): JSX.Element[] => {
  return allOccurrences.map(
    (occurrence: CarePlanReportOccurrence, index: number) => {
      const occurrenceAnswers: CareplanComponentAnswer[] =
        getAnswersOccurrenceById(answers, occurrence.id);

      return renderContent(occurrenceAnswers, index, occurrence.unixEpochs);
    }
  );
};

const getThresholdClass = (isThreshold: boolean): string => {
  return classNames({
    'answer-component_header-columns-column-answer': true,
    threshold: isThreshold,
  });
};

const occurrenceHasAnswer = (
  occurrenceAnswers: CareplanComponentAnswer[]
): boolean => {
  return occurrenceAnswers.length > 0 && !occurrenceAnswers[0].isSkipped;
};

export {
  getAnswersOccurrenceById,
  getComponentOccurrencesAnswer,
  getThresholdClass,
  occurrenceHasAnswer,
};
