/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useEffect } from 'react';
import I18n from 'i18next';
import '../../css/appointments.css';
import PropTypes from 'prop-types';

import FilterLayout from './FiltersLayoutComponent';

const FiltersComponentContext = createContext(null);

const FiltersComponent = ({ displayFilters, types, statuses, getFilters }) => {
  const HIDE_STATUS = true;
  const [statusesCheckList, setStatusesCheckList] = useState({});
  const [typesCheckList, setTypesCheckList] = useState({});

  useEffect(() => {
    if (types) {
      const typesObject = { ...typesCheckList };
      types.map(type => {
        typesObject[type.id] = true;
      });
      setTypesCheckList(typesObject);
    }
  }, [types]);

  useEffect(() => {
    if (statuses) {
      const statusesObject = { ...statusesCheckList };
      statuses.map(status => {
        statusesObject[status.id] = true;
      });
      setStatusesCheckList(statusesObject);
    }
  }, [statuses]);

  useEffect(() => {
    const typesIds = [];
    const statusesIds = [];

    if (typesCheckList) {
      for (var key in typesCheckList) {
        if (typesCheckList[key]) {
          typesIds.push(key);
        }
      }
    }
    if (statusesCheckList) {
      for (var key in statusesCheckList) {
        if (statusesCheckList[key]) {
          statusesIds.push(key);
        }
      }
    }

    getFilters({
      statusesIds,
      typesIds,
    });
  }, [statusesCheckList, typesCheckList]);

  return (
    <FiltersComponentContext.Provider
      value={{
        statusesCheckList,
        setStatusesCheckList,
        typesCheckList,
        setTypesCheckList,
      }}
    >
      <div
        className={`appointment-manager__filters-widget${
          !displayFilters ? ' hidden' : ''
        }`}
      >
        <div
          className={`appointment-manager__filters${
            !displayFilters ? ' hidden' : ''
          }`}
        >
          <FilterLayout
            filters={types}
            label={I18n.t('appointment.filterByType')}
            type="types"
          />
          {!HIDE_STATUS && (
            <FilterLayout
              filters={statuses}
              label={I18n.t('appointment.filterByStatus')}
              type="statuses"
            />
          )}
        </div>
      </div>
    </FiltersComponentContext.Provider>
  );
};

FiltersComponent.propTypes = {
  displayFilters: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.object).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFilters: PropTypes.func.isRequired,
};

export { FiltersComponent, FiltersComponentContext };
