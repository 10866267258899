import { useCallback, useContext } from 'react';
import moment from 'moment';

import { lifespanEnum } from '@ui-common-files/utils';
import { DateFormat } from '@ui-common-files/utils/dateUtils/dateUtil.type';
import { formatDate, formatStartOfDay } from '@global/Date';

import { PatientOverviewContext } from '../../../../PatientOverview/PatientOverview';

const useDateViewerService = (onChange, date, filter) => {
  const { devicesQuery, setDevicesQuery, setShouldGetGraphViewData } =
    useContext(PatientOverviewContext);

  const formattedEndDate = formatDate(date.end.toString(), 'YYYY-MM-DD');
  const formattedCurrentDate = formatDate(new Date(), 'YYYY-MM-DD');
  const isEndDateBeforeToday = formattedEndDate < formattedCurrentDate;

  const addDaysToDate = useCallback((date, days) => {
    const endDate = moment(date.end).add(days, 'days').endOf('day').toDate();
    const end = formatDate(endDate, 'YYYY-MM-DD HH:mm:ss');
    const startDate = moment(date.end).add(1, 'day');
    const start = formatStartOfDay(startDate);
    return { start, end };
  }, []);

  const subDaysToDate = useCallback((date, days) => {
    const startDate = moment(date.start).subtract(days, 'days');
    const start = formatStartOfDay(startDate);
    const endDate = moment(date.start).subtract(1, 'day').endOf('day').toDate();
    const end = formatDate(endDate, 'YYYY-MM-DD HH:mm:ss');
    return { start, end };
  }, []);

  const getNextDateRange = useCallback(
    (date, filter) => {
      let start = '';
      let end = '';
      switch (filter) {
        case lifespanEnum.WEEK:
          return addDaysToDate(date, 7);
        case lifespanEnum.MONTH:
          return addDaysToDate(date, 30);
        case lifespanEnum.YEAR: {
          return addDaysToDate(date, 365);
        }
        default: {
          const day = moment(date.start).add(1, 'days');
          start = formatStartOfDay(day);
          end = formatDate(
            day.endOf('day').toDate(),
            DateFormat.DATE_HOUR_MINUTE_SECOND
          );
          return { start, end };
        }
      }
    },
    [addDaysToDate]
  );

  const getPrevDateRange = useCallback(
    (date, filter) => {
      let start = '';
      let end = '';
      switch (filter) {
        case lifespanEnum.WEEK:
          return subDaysToDate(date, 7);
        case lifespanEnum.MONTH:
          return subDaysToDate(date, 30);
        case lifespanEnum.YEAR: {
          return subDaysToDate(date, 365);
        }
        default: {
          const day = moment(date.start).subtract(1, 'days');
          start = formatStartOfDay(day);
          end = formatDate(
            day.endOf('day').toDate(),
            DateFormat.DATE_HOUR_MINUTE_SECOND
          );
          return { start, end };
        }
      }
    },
    [subDaysToDate]
  );

  const getDateFormat = useCallback((date, filter) => {
    const start = moment(date.start);
    const end = moment(date.end);

    const startFormat = 'MMM DD';
    let endFormat = 'MMM DD, YYYY';

    switch (filter) {
      case lifespanEnum.WEEK: {
        if (start.month() === end.month()) {
          endFormat = 'DD, YYYY';
        }
        break;
      }
      case lifespanEnum.YEAR:
        return `${formatDate(start.toDate(), endFormat)} - ${formatDate(
          end.toDate(),
          endFormat
        )}`;
      case lifespanEnum.MONTH:
      case lifespanEnum.YTD:
        break;
      default:
        return formatDate(start.toDate(), endFormat);
    }

    return `${formatDate(start.toDate(), startFormat)} - ${formatDate(
      end.toDate(),
      endFormat
    )}`;
  }, []);

  const handleNext = useCallback(() => {
    setShouldGetGraphViewData(true);
    setDevicesQuery({
      ...devicesQuery,
      isTodayActive: false,
      isLastDataActive: false,
    });

    const newDateRange = getNextDateRange(date, filter);
    onChange(newDateRange);
  }, [date, filter, onChange]);

  const handlePrev = useCallback(() => {
    setShouldGetGraphViewData(true);
    setDevicesQuery({
      ...devicesQuery,
      isTodayActive: false,
      isLastDataActive: false,
    });

    const newDateRange = getPrevDateRange(date, filter);
    onChange(newDateRange);
  }, [date, filter, onChange]);

  return {
    getDateFormat,
    handleNext,
    handlePrev,
    isEndDateBeforeToday,
  };
};

export default useDateViewerService;
