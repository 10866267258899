import React from 'react';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

import {
  ModalContent,
  TextInput,
  Flex,
  Box,
  ComponentWithLabel,
  DualStateButton,
} from '@ui-common-files/components';
import WithingsDeviceList from './WithingsDeviceList';

import { PrimaryColor } from '@ui-common-files/utils';

import { useWithingsDevice } from './WithingsDevice.service';

import './WithingsDevice.style.scss';

function WithingsDevice(): React.ReactElement {
  const {
    values,
    onValueChange,
    isLoading,
    isConnecting,
    withingsDevices,
    createPatientWithingsAccount,
    onDelete,
  } = useWithingsDevice();

  const { t } = useTranslation();

  return (
    <ModalContent>
      <Box className="withings-device__modal-content-container">
        <Box className="withings-device__input-container">
          <ComponentWithLabel
            id="macAddress"
            htmlFor="macAddress"
            labelText={t('patientOverview.withings_device.common.mac_address')}
          >
            <TextInput
              value={values.macAddress}
              id="macAddress"
              name="macAddress"
              placeholder={t(
                'patientOverview.withings_device.input_mac-address.placeholder'
              )}
              handleOnChange={event => {
                onValueChange(event.target.value, 'macAddress');
              }}
              isDisabled={values.deviceTypes === null}
            />
          </ComponentWithLabel>
        </Box>

        <DualStateButton
          isLoading={isConnecting}
          onClick={createPatientWithingsAccount}
          isDisabled={values.macAddress.length === 0}
          label={t('patientOverview.withings_device.button_connect')}
          className={'withings-device__connect-button-container'}
          width="10%"
        />

        {isLoading ? (
          <Flex
            justifyContent="center"
            className="withings-device__list-loader-container"
          >
            <Loader
              className="loader"
              type="Oval"
              color={PrimaryColor.MAIN_COLOR}
              width={40}
              height={40}
            />
          </Flex>
        ) : (
          <WithingsDeviceList
            withingsDevices={withingsDevices}
            onDelete={onDelete}
          />
        )}
      </Box>
    </ModalContent>
  );
}

export default WithingsDevice;
