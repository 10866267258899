import React from 'react';
import { Box, Flex } from '@ui-common-files/components';
import { shouldDisplayComponents } from '@global/Careplan/Components/Utils';
import CareTaskComponentsInfoStep from '../PathWayReviewModal/ComponentsInfoStep/CareTaskComponentsInfoStep';
import './CareTaskInfoRender.styles.scss';

export const CareTaskInfoRender = ({ selectedCareTasks }) =>
  selectedCareTasks.map(careTask => {
    const { template, thresholds } = careTask;
    if (!shouldDisplayComponents(template, careTask)) return null;

    return (
      <Box key={`caretask-${template.id}`}>
        <Box>
          <Flex className="template-name">{template.name}</Flex>
          <CareTaskComponentsInfoStep
            key={`key-components-${template.id}`}
            careTaskTemplate={template}
            thresholds={thresholds}
            logicJumps={template.logicJumpPaths}
          />
        </Box>
      </Box>
    );
  });
