import axios from 'axios';
import serviceConfig from '../../serviceConfig.json';
import { ApiClient, ShowFlashMessage } from '../Global/Services';

const ShowCareplanRequest = async (id) => {
  try {
    const response = await ApiClient.GET({
      url: serviceConfig.brokerService.showCareplan.uri + id
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    ShowFlashMessage({
      type: error.response.data.type,
      messageKey: error.response.data.content
    });
    throw new Error(error.response);
  }
};

export default ShowCareplanRequest;
