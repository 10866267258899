import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@ui-common-files/components';

import OncoboxExportModalContent from 'src/Views/Patients/OncoboxExportModalContent';
import OncoboxExportModalFooter from 'src/Views/Patients/OncoboxExportModalFooter';
import ExportAllPatientsAnswersModalContent from 'src/Views/Careplans/ExportAllPatientsAnswersModalContent';
import OncoboxImportModalContent from 'src/Views/Patients/OncoboxImportModalContent';
import OncoboxImportModalFooter from 'src/Views/Patients/OncoboxImportModalFooter';
import ExportAllPatientsAnswersModalFooter from '../../Careplans/ExportAllPatientsAnswersModalFooter';
import usePatientsListService from '../PatientsList.service';
import { OncoBoxModalsProps } from './OncoBoxModals.type';
import useOncoBoxServices from './OncoBoxModals.service';

const OncoBoxModals = ({
  exportModal,
  importModal,
  HideFlashMessage,
  openResultsModal,
  setImportModalToggle,
  setExportModalToggle,
  setOpenResultsModal,
  filteredPatientsForCsvExport
}: OncoBoxModalsProps) => {
  const { t } = useTranslation();

  const {
    setNbrPatientsSelected,
    setDisableNext,
    setIsSelectAll,
    closeExportModal,
    isLoading,
    oncoBoxErrors,
    oncoBoxSubmitQuery,
    nbrPatientsSelected,
    disableNext,
    isSelectAll,
    oncoboxFile,
    selectedPatientsData,
    setIsLoading,
    closeOncoBoxExportModal,
    closeOncoBoxImportModal,
    setSelectedPatientsData,
    setOncoboxFile,
    setOncoboxErrors,
    setOncoboxSubmitQuery,
  } = useOncoBoxServices({
    setImportModalToggle,
    setExportModalToggle,
  });
  const {
    setDataForExportAnswers,
    dataForExportAnswers,
    isFilterSelected,
    isNewPatient,
  } = usePatientsListService();
  const renderOncoBoxImportModalFooter = () => {
    return (
      <OncoboxImportModalFooter
        closeModal={closeOncoBoxImportModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        oncoBoxFile={oncoboxFile}
        oncoBoxErrors={oncoBoxErrors}
      />
    );
  };
  const renderOncoBoxExportModalContent = () => {
    return (
      <OncoboxExportModalContent
        closeModal={closeOncoBoxExportModal}
        isLoading={isLoading}
        nbrPatientsSelected={nbrPatientsSelected}
        setNbrPatientsSelected={setNbrPatientsSelected}
        selectedPatientsData={selectedPatientsData}
        setSelectedPatientsData={setSelectedPatientsData}
        disableNext={disableNext}
        setDisableNext={setDisableNext}
        isSelectAll={isSelectAll}
        setIsSelectAll={setIsSelectAll}
      />
    );
  };

  const renderOncoBoxExportModalFooter = () => {
    return (
      <OncoboxExportModalFooter
        closeModal={closeOncoBoxExportModal}
        setSelectedPatientsData={setSelectedPatientsData}
        setNbrPatientsSelected={setNbrPatientsSelected}
        selectedPatientsData={selectedPatientsData}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    );
  };

  const renderExportModalContent = () => {
    return (
      <ExportAllPatientsAnswersModalContent
        closeModal={closeExportModal}
        setDataForExportAnswers={setDataForExportAnswers}
        isLoading={isLoading}
        isFilterSelected={isFilterSelected}
        filteredPatientsForCsvExport={filteredPatientsForCsvExport}
        isNewPatient={isNewPatient}
      />
    );
  };

  const renderExportModalFooter = () => {
    return (
      <ExportAllPatientsAnswersModalFooter
        closeModal={() => setOpenResultsModal(false)}
        dataForExportAnswers={dataForExportAnswers}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        filteredPatientsForCsvExport={filteredPatientsForCsvExport}
      />
    );
  };
  const renderOncoBoxImportModalContent = () => {
    return (
      <OncoboxImportModalContent
        oncoBoxFile={oncoboxFile}
        setOncoboxFile={setOncoboxFile}
        oncoBoxErrors={oncoBoxErrors}
        setOncoboxErrors={setOncoboxErrors}
        oncoBoxSubmitQuery={oncoBoxSubmitQuery}
        setOncoboxSubmitQuery={setOncoboxSubmitQuery}
        isLoading={isLoading}
      />
    );
  };

  return (
    <>
      <Modal
        title={t('patients_view.oncoboxExportTitle')}
        contentComponent={renderOncoBoxExportModalContent()}
        footerComponent={renderOncoBoxExportModalFooter()}
        openModal={exportModal}
        onClose={() => {
          closeOncoBoxExportModal();
        }}
        hideFlashMessage={HideFlashMessage}
      />

      <Modal
        title={t('patients_view.oncoboxImportTitle')}
        contentComponent={renderOncoBoxImportModalContent()}
        footerComponent={renderOncoBoxImportModalFooter()}
        openModal={importModal}
        onClose={() => {
          closeOncoBoxImportModal(false);
        }}
        hideFlashMessage={HideFlashMessage}
      />

      <Modal
        title={t('newCareplan_view.exportAnswers')}
        contentComponent={renderExportModalContent()}
        footerComponent={renderExportModalFooter()}
        openModal={openResultsModal}
        onClose={() => setOpenResultsModal(false)}
        hideFlashMessage={HideFlashMessage}
      />
    </>
  );
};

export default OncoBoxModals;
