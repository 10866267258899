import React from 'react';

const InformationIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.52 20.72V13.44H12.4V14.48H14.48V20.72H12.4V21.76H17.6V20.72H15.52ZM15 28C22.1802 28 28 22.1802 28 15C28 7.81984 22.1802 2 15 2C7.81984 2 2 7.81984 2 15C2 22.1802 7.81984 28 15 28ZM15 26.96C21.605 26.96 26.96 21.605 26.96 15C26.96 8.39496 21.605 3.04 15 3.04C8.39496 3.04 3.04 8.39496 3.04 15C3.04 21.605 8.39496 26.96 15 26.96ZM15 11.36C15.4137 11.36 15.8105 11.1956 16.1031 10.9031C16.3956 10.6105 16.56 10.2137 16.56 9.8C16.56 9.38626 16.3956 8.98947 16.1031 8.69691C15.8105 8.40436 15.4137 8.24 15 8.24C14.5863 8.24 14.1895 8.40436 13.8969 8.69691C13.6044 8.98947 13.44 9.38626 13.44 9.8C13.44 10.2137 13.6044 10.6105 13.8969 10.9031C14.1895 11.1956 14.5863 11.36 15 11.36Z"
      fill="#323232"
    />
  </svg>
);

export default InformationIcon;
