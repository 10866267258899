import wearablesActions from '../src/Utils/ReduxUtilities/Actions/wearables.json';

const wearablesState = {
  wearablesForCareplanResponse: null,
  wearablesActivityStatusResponse: null,
  wearablesStatusResponse: null,
  wearablesDataResponse: null,
  wearablesError: null,
};

const wearables = (state = wearablesState, actions) => {
  switch (actions.type) {
    case wearablesActions.GET_WEARABLES_FOR_CAREPLANS_RESPONSE:
      return {
        ...state,
        wearablesForCareplanResponse: actions.payload,
      };
    case wearablesActions.GET_WEARABLES_ACTIVITY_STATUS_RESPONSE:
      return {
        ...state,
        wearablesActivityStatusResponse: actions.payload,
      };
    case wearablesActions.WEARABLES_ERROR:
      return {
        ...state,
        wearablesError: actions.payload,
      };
    case wearablesActions.RESET_WEARABLES_DATA:
      return {
        ...state,
        wearablesForCareplanResponse: null,
        wearablesDataResponse: null,
        wearablesError: null,
      };
    case wearablesActions.RESET_WEARABLE_STATUS:
      return {
        ...state,
        wearablesActivityStatusResponse: null,
        wearablesStatusResponse: null,
      };
    default:
      return state;
  }
};

export default wearables;
