import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { roles } from '@utils';
import { fetchSatellites } from '../Satellites/Satellites.api';

const useSatelliteData = (
  siteId,
  setLoading,
  initialAllSatellite = [],
  initialSatellite = {},
  initialIsExternalHCP = false,
  isCreate = true,
) => {
  const { user } = useSelector(state => state.session);
  const [allSatellite, setAllSatellite] = useState(initialAllSatellite);
  const [satellite, setSatellite] = useState(initialSatellite);
  const [isExternalHCP, setIsExternalHCP] = useState(initialIsExternalHCP);

  useEffect(() => {
    isCreate && setSatellite('');
    if (isExternalHCP && user.role.name !== roles.EXTERNAL_HCP) {
      setLoading(true);
      fetchSatellites(siteId)
        .then(response => {
          if (response?.data) {
            setAllSatellite(response.data.satellites);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isExternalHCP, siteId]);

  return {
    allSatellite,
    satellite,
    isExternalHCP,
    setIsExternalHCP,
    setSatellite,
    setAllSatellite,
  };
};

export default useSatelliteData;
