/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';

import { Greys } from '../../utils/colors';
import iconDefinitions from '../Icon/iconDefinitions';
import { BodyChartProps } from './BodyChart.type';
import BodyPosition from './bodyPosition';

import '../../styles/main.scss';

function BodyChart({
  circlePos,
  type,
  onClick,
  viewBox,
  size,
  showAsReport,
}: BodyChartProps): JSX.Element {
  const iconName: string =
    type === BodyPosition.back ? 'bodyChartBack' : 'bodyChartFront';
  return (
    <div
      className="body-img__wrapper"
      style={{
        height: size.height,
        width: size.width,
        cursor: showAsReport ? '' : 'pointer',
      }}
    >
      {circlePos === []
        ? null
        : circlePos.map((pos, i) => {
            if (pos) {
              const circleClasses = classNames({
                'circle-style': true,
                'accept-input': !showAsReport,
                'show-report': showAsReport,
                'show-report-threshold': pos.isThreshold,
              });
              return (
                <div
                  key={`circle-${i}`}
                  className={circleClasses}
                  style={pos}
                />
              );
            }
            return null;
          })}
      <div
        className="body-img__style"
        style={{ height: size.height, width: size.width }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
          <g fill="none" fillRule="evenodd" onClick={onClick}>
            <path
              opacity="0.3"
              d={
                iconDefinitions.filter(
                  icon => icon.title.toLowerCase() === iconName.toLowerCase()
                )[0].svgElements[0].d
              }
              fill={Greys.LIGHT_GREY}
            />
          </g>
        </svg>
      </div>
    </div>
  );
}

BodyChart.defaultProps = {
  circlePos: [],
  type: 'front',
  viewBox: '0 0 122 300',
  size: { height: 300, width: 122 },
  onClick: () => {},
  showAsReport: false,
};

export default BodyChart;
