/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'i18next';
import axios from 'axios';
import Calendar from '../../../caro-ui-commonfiles/components/Calendar/Calendar';
import Checkbox from '../../../caro-ui-commonfiles/components/Checkbox/Checkbox';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';

import { ButtonType } from '../../../caro-ui-commonfiles/utils/button';
import CheckboxRadio from '../../../caro-ui-commonfiles/utils/CheckboxRadio';
import serviceConfig from '../../../serviceConfig.json';
import {
  ComponentTranslationKeys,
  EventTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { CalendarCareTaskEventStatus } from './PatientOverview.type';
import { appointmentStatusClass } from '../../../caro-ui-commonfiles/global/constants';
import { PatientOverviewContext } from './PatientOverview';
import calendarLocale from '../../Utils/CalendarLocale';

import '../../css/patientOverview.scss';

const PatientOverviewCalendar = ({ patientId, services }) => {
  const HIDE_STATUS = true;
  const ALL_FILTERS_SELECTED = {
    [EventTypes.MEDICATION]: services.hasOwnProperty(
      'medicationservice.myoncare.care'
    ),
    [EventTypes.CAREPLAN]: services.hasOwnProperty(
      'careplanservice.myoncare.care'
    ),
    [EventTypes.APPOINTMENT]: services.hasOwnProperty(
      'appointmentservice.myoncare.care'
    ),
  };
  const { refreshCalendar, setRefreshCalendar, isChatOpened } = useContext(
    PatientOverviewContext
  );

  const componentTypes = Object.keys(EventTypes);

  const [selectedComponentIds, setSelectedComponentIds] =
    useState(ALL_FILTERS_SELECTED);
  const [clearAll, setClearAll] = useState(true);
  const [dateRange, setDateRange] = useState({
    minDate: moment().startOf('month').toISOString(),
    maxDate: moment().endOf('month').toISOString(),
  });
  const [events, setEvents] = useState([]);
  const [displayFilters, setDisplayFilters] = useState(true);
  const [currLang, setCurrlang] = useState(I18n.language);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        serviceConfig.brokerService.filterPatientOverviewCalendarComponents.uri,
        {
          params: {
            selectedComponentIds: JSON.stringify(selectedComponentIds),
            patientId,

            minDate: dateRange.minDate,
            maxDate: dateRange.maxDate,
          },
        }
      );
      if (response) {
        const filteredEvents = response.data.map(ev => {
          return {
            ...ev,
            start: moment(ev.start).toDate(),
            end: moment(ev.end).toDate(),
          };
        });
        setEvents(filteredEvents);
        setRefreshCalendar(false);
      }
    };
    getData();
  }, [
    dateRange.maxDate,
    dateRange.minDate,
    patientId,
    selectedComponentIds,
    refreshCalendar,
    setRefreshCalendar,
  ]);

  const getCalendarRange = range => {
    setDateRange(range);
  };

  const clearAllFilters = () => {
    setClearAll(false);
    setSelectedComponentIds({});
  };

  const selectAllFilters = () => {
    setClearAll(true);
    setSelectedComponentIds(ALL_FILTERS_SELECTED);
  };

  I18n.on('languageChanged', function (lng) {
    setCurrlang(lng);
  });

  useEffect(() => {
    setClearAll(
      !Object.keys(selectedComponentIds).every(k => !selectedComponentIds[k])
    );
  }, [selectedComponentIds]);

  const updateEventProps = useCallback(event => {
    switch (event.status) {
      case CalendarCareTaskEventStatus.Sent:
        return {
          className: 'patient-overview-calendar-sent-event',
        };
      case CalendarCareTaskEventStatus.Answered:
        return {
          className: 'patient-overview-calendar-answered-event',
          style: {
            borderColor: event.scoreColor ? event.scoreColor : undefined,
          },
        };
      case CalendarCareTaskEventStatus.Deactivated:
        return {
          className: 'patient-overview-calendar-deactivated-event',
        };
      default:
        return {};
    }
  }, []);

  const renderTooltipComponent = eventData => (
    <div className="calendar-tooltip-container">
      <div>
        <b>
          {I18n.t(
            ComponentTranslationKeys[componentTypes[eventData.eventType]]
          )}
        </b>
      </div>
      <Box margin="10px 0 10px 0">
        <div>{eventData.title}</div>
        {eventData.eventType !== EventTypes.APPOINTMENT && (
          <div>{eventData.recurrenceText}</div>
        )}
        {eventData.eventType === EventTypes.APPOINTMENT && (
          <>
            <div>
              {moment(eventData.start).format('HH:mm')} -{' '}
              {moment(eventData.end).format('HH:mm')}
            </div>
            {!HIDE_STATUS && (
              <div>
                <b>{I18n.t('common_labels.status')}</b>
                <span
                  className={`rbc-calendar__tooltip__status ${
                    appointmentStatusClass[eventData.appointmentStatusId]
                  }`}
                >
                  {I18n.t(eventData.appointmentStatus)}
                </span>
              </div>
            )}
            <div>
              <b>{I18n.t('appointment.type')}</b>:{' '}
              {I18n.t(eventData.appointmentType)}
            </div>
            <div>
              <b>{I18n.t('appointment.place')}</b>: {I18n.t(eventData.location)}
            </div>
          </>
        )}
      </Box>
    </div>
  );

  return (
    <div className="calendar-outer-container">
      <div
        className={`calendar-filter-container${
          !displayFilters ? ' hidden' : ''
        }`}
      >
        <div className="calendar-filters">
          {services.hasOwnProperty('careplanservice.myoncare.care') && (
            <Box margin="10px 0 0 0">
              <Checkbox
                id="calendarFilterCareplans"
                name="careplans"
                value="careplans"
                inputWidth={30}
                label={I18n.t('types.componentType.careplans')}
                variant={CheckboxRadio.Default}
                checked={selectedComponentIds[EventTypes.CAREPLAN] || false}
                handleOnChange={e => {
                  setSelectedComponentIds({
                    ...selectedComponentIds,
                    [EventTypes.CAREPLAN]: e.target.checked,
                  });
                }}
              />
            </Box>
          )}
          {services.hasOwnProperty('medicationservice.myoncare.care') && (
            <Box margin="10px 0 0 0">
              <Checkbox
                id="calendarFilterMedications"
                name="medications"
                value="medications"
                inputWidth={30}
                label={I18n.t('types.componentType.medications')}
                variant={CheckboxRadio.Default}
                checked={selectedComponentIds[EventTypes.MEDICATION] || false}
                handleOnChange={e => {
                  setSelectedComponentIds({
                    ...selectedComponentIds,
                    [EventTypes.MEDICATION]: e.target.checked,
                  });
                }}
              />
            </Box>
          )}
          {services.hasOwnProperty('appointmentservice.myoncare.care') && (
            <Box margin="10px 0 0 0">
              <Checkbox
                id="calendarFilterAppt"
                name="appointments"
                value="appointments"
                inputWidth={30}
                label={I18n.t('types.componentType.appointments')}
                variant={CheckboxRadio.Default}
                checked={selectedComponentIds[EventTypes.APPOINTMENT] || false}
                handleOnChange={e => {
                  setSelectedComponentIds({
                    ...selectedComponentIds,
                    [EventTypes.APPOINTMENT]: e.target.checked,
                  });
                }}
              />
            </Box>
          )}
        </div>

        <Box margin="15px 0 0 0">
          <Button
            label={
              clearAll
                ? I18n.t('common_labels.label_clear_selections')
                : I18n.t('common_labels.label_selectAll_options')
            }
            type={ButtonType.BUTTON}
            onClick={clearAll ? clearAllFilters : selectAllFilters}
            variant="outlined"
          />
        </Box>
      </div>

      <div
        className={`patient-overview-calendar-container${
          !displayFilters ? ' hidden' : ''
        }`}
      >
        <Calendar
          events={events}
          customTooltipComponent={renderTooltipComponent}
          onEventChange={() => {}}
          onDoubleClickEvent={() => {}}
          getRange={getCalendarRange}
          filterAction
          filterActive={displayFilters}
          isChatOpened={isChatOpened}
          onFilter={() => {
            setDisplayFilters(!displayFilters);
          }}
          currLang={currLang}
          locale={calendarLocale()}
          key={currLang}
          eventPropGetter={updateEventProps}
        />
      </div>
    </div>
  );
};

PatientOverviewCalendar.propTypes = {
  patientId: PropTypes.string.isRequired,
  services: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default PatientOverviewCalendar;
