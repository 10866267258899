import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { MODAL_VARIANT } from '../../../../Utils/Layout/modalSection';

const ModalSection = ({
  children,
  hasOverflow,
  variant,
  hasColumnDirection,
  hasPadding,
}) => {
  const sectionStyles = cx({
    'component-container': true,
    'component-container--padding-20': hasPadding,
    'component-container--extended':
      variant !== MODAL_VARIANT.COL_W20 &&
      variant !== MODAL_VARIANT.COL_W30 &&
      variant !== MODAL_VARIANT.COL_W40 &&
      variant !== MODAL_VARIANT.COL_W60 &&
      variant !== MODAL_VARIANT.COL_W70 &&
      variant !== MODAL_VARIANT.COL_W80,
    'component-container--overflow': hasOverflow,
    section__top: hasColumnDirection && variant === MODAL_VARIANT.TOP,
    section__bottom: hasColumnDirection && variant === MODAL_VARIANT.BOTTOM,
    'col--w20': variant === MODAL_VARIANT.COL_W20,
    'col--w30': variant === MODAL_VARIANT.COL_W30,
    'col--w40': variant === MODAL_VARIANT.COL_W40,
    'col--w60': variant === MODAL_VARIANT.COL_W60,
    'col--w70': variant === MODAL_VARIANT.COL_W70,
    'col--w80': variant === MODAL_VARIANT.COL_W80,
  });

  return <div className={sectionStyles}>{children}</div>;
};

ModalSection.propTypes = {
  children: PropTypes.node.isRequired,
  hasOverflow: PropTypes.bool,
  variant: PropTypes.oneOf([
    MODAL_VARIANT.TOP,
    MODAL_VARIANT.BOTTOM,
    MODAL_VARIANT.COL_W20,
    MODAL_VARIANT.COL_W30,
    MODAL_VARIANT.COL_W70,
    MODAL_VARIANT.COL_W80,
  ]),
  hasColumnDirection: PropTypes.bool,
  hasPadding: PropTypes.bool,
};

ModalSection.defaultProps = {
  hasOverflow: true,
  hasColumnDirection: false,
  variant: MODAL_VARIANT.TOP,
  hasPadding: false,
};

export default ModalSection;
