import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import {
  colors,
  optionStyles,
  searchableStyles,
  inputCounterStyle
} from '../../utils/dropdownSelectStyles';
import maxCharLimits from '../../utils/maxcharlimits.json'

const Input = ({ isHidden, selectProps, ...innerProps }) => {
  return (
    <div style={inputCounterStyle}>
      <components.Input {...innerProps} maxLength={selectProps.maxLength} />
    </div>
  );
};

const IndicatorsContainer = ({ selectProps, ...innerProps }) => {
  let charCount = 0;
  if (selectProps.inputValue.length > 0) {
    charCount = selectProps.inputValue.length;
  } else if (selectProps.inputValue.length === 0 && selectProps.value) {
    charCount = selectProps.value.label.length;
  }
  return (
    <components.IndicatorsContainer {...innerProps}>
      {selectProps.maxLength &&
        <span className="helperText">
          {charCount}/{selectProps.maxLength}
        </span>
      }
      {selectProps.isLoading && <components.LoadingIndicator {...innerProps} />}
    </components.IndicatorsContainer>
  );
};


/*
 * Replace the single value selected
 */
const SingleValue = ({ data, selectProps, ...innerProps }) => {
  const colorBasedOnMenuState = selectProps.menuIsOpen
    ? colors.lightGrey
    : colors.darkGrey;

  return (
    <components.SingleValue {...innerProps}>
      <span style={{ color: `${colorBasedOnMenuState}` }}>{data.label}</span>
    </components.SingleValue>
  );
};

/*
 * Customize the option
 */
const formatOptionLabel = data => (
  <div style={optionStyles}>
    <span>{data.label}</span>
    {!data.__isNew__ && <Icon name={data.icon} style={{ flexShrink: 0 }} />}
  </div>
);

const SingleSelectQuestionSearchable = ({
  loadOptions,
  isDisabled,
  placeholder,
  defaultOptions,
  value,
  handleOnChange,
  hasError,
  validationMessage,
  limitSingle,
  maxLength,
}) => {
  limitSingle ? 75 : 50;
  const isValidNewOption = inputValue => inputValue && inputValue.length && inputValue.length <= limitSingle;
  const { t } = useTranslation();
  return (
    <>
      <AsyncCreatableSelect
        defaultOptions={defaultOptions}
        getOptionValue={value => value.label}
        loadOptions={loadOptions}
        isDisabled={isDisabled}
        isSearchable
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        onChange={handleOnChange}
        styles={searchableStyles}
        className="react-select-container"
        classNamePrefix="react-select"
        components={{ SingleValue, Input, IndicatorsContainer, formatOptionLabel }}
        hasError={hasError}
        isValidNewOption={isValidNewOption}
        formatCreateLabel={inputValue => `${t('role_view.label_create')} "${inputValue}"`}
      />
      {hasError && (
        <span className="searchable-select__error">{validationMessage}</span>
      )}
    </>
  );
};

SingleSelectQuestionSearchable.propTypes = {
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]).isRequired,
  loadOptions: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  /** Can be null or an object */
  value: PropTypes.object,
  handleOnChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  validationMessage: PropTypes.string,
  maxLength: PropTypes.number,
};

SingleSelectQuestionSearchable.defaultProps = {
  hasError: false,
  validationMessage: null,
  maxLength: maxCharLimits.carePlan.componentTitleMaxCharLimit,
};

export default SingleSelectQuestionSearchable;
