export enum DatePickerKeys {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year',
}

export enum DateFormat {
  DATE_HOUR_MINUTE_SECOND = 'YYYY-MM-DD HH:mm:ss',
}

export type DatePickerDateType = {
  [key in DatePickerKeys]: string;
};

export type CheckIfValidDateProps = (
  date: DatePickerDateType,
  maxDate?: Date,
  minDate?: Date
) => boolean;
