import axios from "axios";
import I18n from "i18next";
import config from "../../../../config";

async function initVideoCall(user, patientObject, jitsiUrl = null) {
  let roomId = "";
  if (patientObject) {
    try {
      await axios
        .get("/broker/patients/generateVideoRoomId")
        .then(function (res) {
          roomId = res.data;
        });
      return await axios
        .post("/broker/patients/sendVideoDetailsToFirebase", {
          userId: user.id,
          patientId: patientObject.id,
          jitsiUrl,
          startTime: new Date(),
          isAnswered: false,
        })
        .then(function () {
          return !jitsiUrl
            ? `https://meet-prod.myoncare.care/?roomId=${roomId}&patientId=${patientObject.id}&userId=${user.id}&selectedLanguage=${I18n.language}&patient=${patientObject.firebaseId}&projectId=${config.firebase.projectId}`
            : null;
        });
    } catch (error) {
      return {
        content: I18n.t(error.response.data.content),
        type: error.response.data.type,
      };
    }
  }
}

export default initVideoCall;
