import { useCallback, useMemo } from 'react';

const useIsLogicJumpSet = (item, editableComponent) => {
  const isLogicJumpSet = useMemo(() => {
    return item ? item.shouldLogicJumpToggle : false;
  }, [item, editableComponent]);

  return isLogicJumpSet;
};

const useInitialEditableComponent = (currComponent, isEditable) => {
  const initialEditableComponent = useMemo(() => {
    if (currComponent && currComponent.id > -1) {
      if (isEditable[currComponent.id]) return isEditable[currComponent.id];
      const initObj = {};
      initObj.jump = currComponent.shouldLogicJumpToggle || -1;
      initObj.thresholds =
        currComponent.shouldThresholdToggle ||
        currComponent.searchTerm.shouldThresholdToggle
          ? initObj.jump !== true
          : -1;
      initObj.showContent = false;
      return initObj;
    }
    return { jump: -1, thresholds: -1 };
  }, [currComponent, isEditable]);

  return initialEditableComponent;
};

const useIfEditable = (
  isEditable,
  setIsEditable,
  editableComponent,
  currComponent
) => {
  const getEditableState = value => (value > -1 ? false : -1);

  const setIfEditable = useCallback(
    (key, action) => {
      const actions = { ...isEditable };
      const componentIdAsKey = currComponent.id;
      if (action === true) {
        actions[componentIdAsKey] = {
          thresholds: getEditableState(editableComponent.thresholds),
          jump: getEditableState(editableComponent.jump),
          [key]: action,
          showContent: true,
        };
      } else {
        actions[componentIdAsKey] = {
          ...actions[componentIdAsKey],
          [key]: action,
        };
      }
      setIsEditable(actions);
    },
    [
      editableComponent.jump,
      editableComponent.thresholds,
      setIsEditable,
      currComponent,
    ]
  );

  return setIfEditable;
};

export { useInitialEditableComponent, useIfEditable, useIsLogicJumpSet };
