import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state = { ...initialState, user: { ...action.payload } };
      return state;
    },
    resetCurrentUser: () => {
      return initialState;
    },
  },
});

export const { setCurrentUser, resetCurrentUser } = sessionSlice.actions;
export default sessionSlice.reducer;
