import React from 'react';
import Loader from 'react-loader-spinner';

import { Button } from '../index';

import { ButtonVariant } from '../../utils/button';
import { PrimaryColor, Greys } from '../../utils/colors';
import { CustomLoaderProps } from './CustomLoader.type';

const CustomLoader: React.FC<CustomLoaderProps> = ({
  variant = ButtonVariant.CONTAINED,
  size = 'sm',
}) => {
  const loaderColor =
    variant === ButtonVariant.CONTAINED ? Greys.WHITE : PrimaryColor.MAIN_COLOR;

  return (
    <Button variant={variant} fullWidth size={size}>
      <Loader
        className="loader"
        type="Oval"
        color={loaderColor}
        width={20}
        height={20}
      />
    </Button>
  );
};

export default CustomLoader;
