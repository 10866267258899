import React from 'react';
import { ActionColors } from '../../utils/colors';
import '../../styles/main.scss';
import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import FlexBox from '../Flex/Flex';
import { FilterWarningMessageProps } from './FilterWarningMessage.type';

const FilterWarningMessage = ({ message } : FilterWarningMessageProps) => {
  return (
    <div
      className="patient-index__us-warning-filter-wrapper"
      style={{
        minHeight: 'auto',
        padding: '0 13px 0 8px',
      }}
    >
      <FlexBox
        justifyContent={'flex-start'}
        otherStyles={{
          flex: '1 1 0%',
        }}
      >
        <FlexBox alignItems="center">
          <FlexBox otherStyles={{ flexShrink: 0 }}>
            <Icon
              name="WarningFilter"
              size={39}
              fill={ActionColors.ERROR}
            />
          </FlexBox>
          <Box margin="0 0 0 8px">
            <span className="patient-index__us-warning-txt">
              {message}
            </span>
          </Box>
        </FlexBox>
      </FlexBox>
    </div>
  );
};

export default FilterWarningMessage;
