import React from 'react';
import Icon from '../Icon/Icon';
import '../../styles/main.scss';
import Tooltip from '../Tooltip/Tooltip';
import Position from '../../utils/positions';
import { Greys } from '../../utils/colors';

type LabelType = {
  id?: string,
  htmlFor?: string,
  text: string,
  hasIcon?: boolean,
  icon?: string,
  fill?: string,
  tooltip?: JSX.Element | string,
  tooltipPosition?: 'top' | 'bottom' | 'bottom-right' | 'bottom-left',
  textColor?: string,
  labelDescription?: JSX.Element | string,
  isScore?: boolean,
  isLongText?: boolean,
}
function Label ({
  id,
  htmlFor,
  text,
  hasIcon,
  icon,
  fill,
  tooltip,
  tooltipPosition,
  textColor,
  labelDescription,
  isScore,
  isLongText
}: LabelType): JSX.Element {
  
  function IconLabel () :JSX.Element{
    return ( hasIcon && tooltip ? (
      <Tooltip content={tooltip} position={tooltipPosition}>
        <Icon name={icon} size={24} fill={fill} />
      </Tooltip>
    ) : hasIcon ? (
      <Icon name={icon} size={24} fill={fill} />
    ) : null);
} 
  return (
    <div className="custom-label">
      <label
        className={
          isScore
            ? 'custom-label__label__width'
            : 'custom-label__label custom-label__label__height'
        }
        id={id}
        htmlFor={htmlFor}
        style={{
          color: textColor,
          maxWidth: isLongText ? null : '13rem',
          minHeight: '24px',
        }}
      >
        {text}
      </label>
      <label className="custom-label__label" style={{ paddingLeft: 7 }}>
        {labelDescription}
      </label>
      {IconLabel()}
    </div>
  );
};

Label.defaultProps = {
  hasIcon: false,
  icon: 'info',
  fill: Greys.DARK_GREY,
  tooltip: ' ',
  tooltipPosition: Position.bottom,
  textColor: Greys.DARK_GREY,
};

export default Label;
