import i18next from 'i18next';
import getCriteria, {
  filterCriteriaErrors,
} from '../../../../Utils/PatientFilter/PatientFilter';
import {
  CriteriaList,
  CriteriaType,
  FieldTypes,
  FilterTypes,
  PatientFilterErrorType,
} from './PatientFilter.type';

export const convertLinkToBlob = (link: string) => {
  return fetch(link)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .catch(error => Promise.reject(error));
};

export const isFilterValueEmpty = (filter: CriteriaType[]) => {
  const filterLength = filter.length;
  const firstFilterCheck = filter[0].check.id;
  const firstFilterValue = filter[0].value[0];
  return (
    filterLength === 1 &&
    firstFilterCheck !== 'is_empty' &&
    firstFilterCheck !== 'is_not_empty' &&
    firstFilterValue === ''
  );
};

export const checkIfValidValue = (criteria: CriteriaType[]) => {
  const filterLength = criteria.length;
  if (!filterLength) return false;

  const firstFilterCheck = criteria[0].check.id;
  const firstFilterValue = criteria[0].value[0];
  if (
    filterLength === 1 &&
    firstFilterCheck !== 'is_empty' &&
    firstFilterCheck !== 'is_not_empty' &&
    (firstFilterValue === '' ||
      (Array.isArray(firstFilterValue) && firstFilterValue.length === 0))
  )
    return false;
  return true;
};

export const extractValueByType = (
  value: (string | { code: string }[])[],
  type: FieldTypes
) => {
  if (value.length === 1) {
    if (
      (type === FieldTypes.ICD || type === FieldTypes.OPS) &&
      Array.isArray(value[0]) &&
      value[0].length
    )
      return value[0][0].code;
    return value[0];
  }
  return value;
};

export const generateFilterObject = (
  criteria: CriteriaType,
  value: unknown
) => ({
  field: criteria.field.id,
  check: criteria.check.id,
  value,
  ...(criteria.field.type === FieldTypes.ComponentAnswer
    ? {
        careplanComponentId: criteria.careplanComponentId,
        careplanComponentTypeId: criteria.careplanComponentTypeId,
      }
    : {}),
});

export const checkForErrors = (
  criteria: CriteriaList,
  selectedCriteriaType: FilterTypes
) => {
  let errors: { [key: string]: PatientFilterErrorType } = {};
  Object.values(FilterTypes).forEach((filterType: FilterTypes) => {
    const criteriaList = criteria[filterType];
    if (selectedCriteriaType !== filterType) return;
    criteriaList.map((criteria, position) => {
      const error = (filterCriteriaErrors as { [key: string]: Function })[
        criteria.field.id
      ]?.(criteria.value, {
        type: criteria.careplanComponentTypeId,
        range: criteria.careplanComponentMinMax,
      });
      if (error && !['is_empty', 'is_not_empty'].includes(criteria.check.id))
        errors = { ...errors, [`${criteria.field.id}_${position}`]: error };
    });
  });
  return Object.keys(errors).length ? errors : null;
};

export const initialCriteriaList = (
  userRoleIsPatientDemographicData: boolean
) => {
  const { patient: patientFilters, caretask: caretaskFilters } = getCriteria(
    userRoleIsPatientDemographicData
  );

  return {
    patient: [
      {
        field: {
          ...patientFilters[0],
          label: i18next.t(patientFilters[0].labelLocation),
        },
        check: {
          ...patientFilters[0].operators[0],
          label: i18next.t(patientFilters[0].operators[0].labelLocation),
        },
        value: [''],
        id: '0',
      },
    ],
    caretask: [
      {
        field: {
          ...caretaskFilters[0],
          label: i18next.t(caretaskFilters[0].labelLocation),
        },
        check: {
          ...caretaskFilters[0].operators[0],
          label: i18next.t(caretaskFilters[0].operators[0].labelLocation),
        },
        value: [''],
        id: '0',
      },
    ],
  };
};
