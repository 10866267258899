import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { ActionColors } from '../../utils/colors';
import '../../styles/main.scss';
import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import Flex from '../Flex/Flex';
import Checkbox from '../Checkbox/Checkbox';
import CheckBoxRadioVariants from '../../utils/CheckboxRadio';

function CheckboxWarning({
  usWarningCheckboxChecked,
  changeAwareCheckbox,
  changeConfirmCheckbox,
  nonMedical,
}) {
  return (
    <div className="warning-checkbox__wrapper">
      <Flex flexDirection="row" justifyContent="center" alignItems="center">
        <Box margin="0 5px 0 20px">
          <Icon name="WarningFilter" size={40} fill={ActionColors.ERROR} />
        </Box>
        <Flex
          flexDirection="column"
          otherStyles={{ margin: '5px 20px 20px 20px' }}
        >
          <div className="checkbox_style">
            <span>
              <Checkbox
                id="awareCheckbox"
                variant={CheckBoxRadioVariants.DEFAULT}
                inputWidth={20}
                checked={usWarningCheckboxChecked.awareCheckbox}
                handleOnChange={changeAwareCheckbox}
              />
            </span>
            <span className="checkbox-text">
              {nonMedical === false || nonMedical === 'false'
                ? I18n.t('newCareplan_view.us-warning1-step3')
                : I18n.t('newCareplan_view.us-warning1-step3_nonmedical')}
            </span>
          </div>
          <div className="checkbox_style">
            <span margin="10px 0 0">
              <Checkbox
                id="confirmCheckbox"
                variant={CheckBoxRadioVariants.DEFAULT}
                inputWidth={20}
                checked={usWarningCheckboxChecked.confirmCheckbox}
                handleOnChange={changeConfirmCheckbox}
              />
            </span>
            <span className="checkbox-text">
              {nonMedical === false || nonMedical === 'false'
                ? I18n.t('newCareplan_view.us-warning2-step3')
                : I18n.t('newCareplan_view.us-warning2-step3_nonmedical')}
            </span>
          </div>
        </Flex>
      </Flex>
    </div>
  );
}

CheckboxWarning.propTypes = {
  usWarningCheckboxChecked: PropTypes.objectOf(PropTypes.bool).isRequired,
  changeAwareCheckbox: PropTypes.func.isRequired,
  changeConfirmCheckbox: PropTypes.func.isRequired,
  nonMedical: PropTypes.oneOf([PropTypes.bool, PropTypes.string]).isRequired,
};

export default CheckboxWarning;
