/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import axios from 'axios';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import { ComponentColors } from '../../../caro-ui-commonfiles/utils/colors';
import {
  Actions,
  ActionTranslationKeys,
  ComponentTranslationKeys,
  ComponentTypes,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import PatientOverviewModalFooterForAppointments from '../common/PatientOverviewModalFooterForAppointments';
import {
  PatientOverviewModalHeaderForAppointments,
  PatientOverviewModalHeader,
} from '../common/PatientOverviewModalHeader';
import { Footer, AppointmentTitle } from './CardComponents';
import ApptShowModalContent from '../Appointments/ApptShowModalContent';
import ShowModalFooter from '../common/ShowModalFooter';
import serviceConfig from '../../../serviceConfig.json';
import AppointmentUpdateModalContent from '../Appointments/ApptUpdateModalContent';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import CardInfoContent from '../common/CardInfoContent';
import { MEDIA_QUERIES } from '@ui-common-files/utils/layout';

import { ApiClient, ShowFlashMessage } from '@global/Services';
import { isDateInPast } from '@global/Date';

const AppointmentCard = ({ data, apptSocket, patientId, elRefs }) => {
  const {
    setModalContent,
    setMessagePrompt,
    setLandingTab,
    closeMessagePrompt,
    closeModal,
    checkUserCredential,
    setDoFetch,
    doFetch,
  } = useContext(PatientOverviewContext);

  const isAppointmentInPast = useMemo(
    () => isDateInPast(data.reminderElement.endsAt),
    [data.reminderElement.endsAt]
  );
  const [sendAndUnassignEnabled, setSendAndUnassignEnabled] = useReducer(
    (_state, enabled) => enabled,
    data.appointmentStatusId !== 2
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const appointmentId = `appointment_${data.id}`;

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const getAppointmentResponse = async (associatedPathway = false) => {
    setLandingTab(false);
    try {
      const response = await axios.post(
        serviceConfig.brokerService.sendAppointment.uri,
        {
          reminderId: data.reminderElement.reminderId,
          isReactivate: true,
        }
      );
      setLandingTab(
        associatedPathway == true
          ? ComponentTypes.CAREPATHWAYS
          : ComponentTypes.APPOINTMENT
      );
      return response;
    } catch (error) {
      setLandingTab(false);
      setLandingTab(
        associatedPathway == true
          ? ComponentTypes.CAREPATHWAYS
          : ComponentTypes.APPOINTMENT
      );
      ShowFlashMessage({
        type: error.response.data.type,
        messageKey: error.response.data.content,
      });
    }
  };

  const sendAssignment = async () => {
    return getAppointmentResponse();
  };

  const unassignAppointment = async () => {
    setLandingTab(false);
    return await ApiClient.GET({
      url: serviceConfig.brokerService.unassignAppointment.uri,
      payload: {
        reminderId: data.reminderElement.reminderId,
        appointmentTypeId: data.appointmentType.id,
        isDeactivate: true,
      },
    }).then(response => {
      if (response) setLandingTab(ComponentTypes.APPOINTMENT);
    });
  };

  const deleteAppointment = async () => {
    setLandingTab(false);
    try {
      const response = await axios.get(
        serviceConfig.brokerService.deleteAppointment.uri,
        {
          params: {
            reminderId: data.reminderElement.reminderId,
          },
        }
      );
      if (response) {
        setLandingTab(ComponentTypes.APPOINTMENT);
        ShowFlashMessage({
          type: response.data.type,
          messageKey: response.data.content,
        });
        setDoFetch(!doFetch);
      }
    } catch (error) {
      ShowFlashMessage({
        type: error.response.data.type,
        messageKey: error.response.data.content,
      });
      setDoFetch(!doFetch);
    }
  };

  const sendHandler = data.reminderElement.firebaseKey ? null : sendAssignment;
  const unassignHandler = sendHandler ? null : unassignAppointment;
  const resultHandler = null;

  const doesAppointmentHavePathWays = carePathways => {
    return carePathways.data.length > 0;
  };

  const appointmentUpdateModal = (data, carePathways, patientId) => {
    setModalContent({
      title: `${I18n.t('appointment.editAppointment')}: ${data.name}`,
      content: (
        <AppointmentUpdateModalContent
          data={data}
          patientId={patientId}
          hasCarePathways={!!doesAppointmentHavePathWays(carePathways)}
          carePathways={carePathways}
        />
      ),
      footer: (
        <PatientOverviewModalFooterForAppointments
          componentType={ComponentTypes.APPOINTMENT}
          actionType={Actions.Update}
          hasCarePathways={!!doesAppointmentHavePathWays(carePathways)}
        />
      ),
      header: doesAppointmentHavePathWays(carePathways) ? (
        <PatientOverviewModalHeaderForAppointments />
      ) : (
        <PatientOverviewModalHeader
          settingsStepTranslation={I18n.t('steps.appointment_settings')}
        />
      ),
    });
  };

  const setEditAppointmentModalContent = async () => {
    const userHasUpdateCredential = await checkUserCredential(
      ComponentTranslationKeys.APPOINTMENT,
      ActionTranslationKeys.UPDATE
    );
    const carePathways = await axios.get(
      serviceConfig.brokerService.getCarepathwaysByPatientId.uri,
      {
        params: {
          patientId,
          appointmentId: data.id,
        },
      }
    );
    if (carePathways.data.length) {
      const userHasUpdateCareplanCredential = await checkUserCredential(
        ComponentTranslationKeys.CAREPLAN,
        ActionTranslationKeys.UPDATE
      );
      const [userHasSendPathwayCredential, userHasRevokePathwayCredential] = [
        await checkUserCredential(
          ComponentTranslationKeys.CAREPATHWAY,
          ActionTranslationKeys.SEND
        ),
        await checkUserCredential(
          ComponentTranslationKeys.CAREPATHWAY,
          ActionTranslationKeys.REVOKE
        ),
      ];

      if (
        userHasUpdateCredential &&
        userHasSendPathwayCredential &&
        userHasRevokePathwayCredential &&
        userHasUpdateCareplanCredential
      ) {
        appointmentUpdateModal(data, carePathways, patientId);
      }
    } else if (userHasUpdateCredential) {
      appointmentUpdateModal(data, carePathways, patientId);
    }
  };

  const onDeleteClick = async user => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.APPOINTMENT,
      ActionTranslationKeys.DELETE
    );
    const apptHasCarepathway = await axios.get(
      serviceConfig.brokerService.getCarepathwayByAppointmentId.uri,
      {
        params: {
          appointmentId: data.id,
        },
      }
    );
    let formatMessage = '',
      formatMessage_1 = `${I18n.t(
        'warningMessage.delete_appointment_associated_to_carePathway_1'
      )}`,
      formatMessage_2 = `${I18n.t(
        'warningMessage.delete_appointment_associated_to_carePathway_2'
      )}`;

    apptHasCarepathway.data.carepathwaysList.forEach(
      item => (formatMessage += `<br/>${item.name}<br/>`)
    );

    if (
      userHasCredential &&
      apptHasCarepathway.data.carepathwaysList.length > 0
    ) {
      setMessagePrompt({
        title: `${I18n.t('common_labels.msg_confirm_appt_delete_headline')}: ${
          data.name
        }`,
        content: (
          <PromptContent
            message={ReactHtmlParser(
              formatMessage_1 +
                `<br/>` +
                formatMessage +
                `<br/>` +
                formatMessage_2
            )}
          />
        ),
        footer: (
          <PromptFooter
            close={closeMessagePrompt}
            confirmHandler={() => deleteAppointment(user)}
          />
        ),
      });
    } else if (
      userHasCredential &&
      apptHasCarepathway.data.carepathwaysList.length == 0
    ) {
      setMessagePrompt({
        title: `${I18n.t('common_labels.msg_confirm_appt_delete_headline')}: ${
          data.name
        }`,
        content: (
          <PromptContent
            message={I18n.t('common_labels.msg_confirm_appt_delete')}
          />
        ),
        footer: (
          <PromptFooter
            close={closeMessagePrompt}
            confirmHandler={() => deleteAppointment(user)}
          />
        ),
      });
    }
  };

  const setShowAppointmentModalContent = async () => {
    const userHasCredential = await checkUserCredential(
      ComponentTranslationKeys.APPOINTMENT,
      ActionTranslationKeys.SHOW
    );
    if (userHasCredential) {
      setModalContent({
        title: `${I18n.t('breadcrumbs.showAppointment')}: ${data.name}`,
        content: <ApptShowModalContent data={data} />,
        footer: <ShowModalFooter close={closeModal} />,
      });
    }
  };

  return (
    <>
      <div className="assignment-card" id={appointmentId} ref={elRefs}>
        <AppointmentTitle
          iconName={ComponentTypes.APPOINTMENT}
          color={ComponentColors.APPOINTMENT}
          onEditIconClick={setEditAppointmentModalContent}
          onDeleteClick={onDeleteClick}
          onShowIconClick={setShowAppointmentModalContent}
          data={data}
          apptSocket={apptSocket}
          setSendAndUnassignEnabled={
            !isAppointmentInPast && setSendAndUnassignEnabled
          }
        />

        <Flex
          flexDirection={
            windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'
          }
          otherStyles={{ paddingLeft: '60px', marginTop: '10px' }}
        >
          <CardInfoContent data={data} variant="appointment" />

          <div className="empty-container" />
        </Flex>

        <Footer
          sendHandler={sendHandler}
          unassignHandler={unassignHandler}
          resultHandler={resultHandler}
          componentType={ComponentTypes.APPOINTMENT}
          firebaseKey={data.reminderElement.firebaseKey}
          sendAndUnassignEnabled={
            !isAppointmentInPast && sendAndUnassignEnabled
          }
        />
      </div>
    </>
  );
};

AppointmentCard.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  apptSocket: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AppointmentCard;
