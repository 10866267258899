/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import I18n from 'i18next';

import ToggleButton from '../../../../caro-ui-commonfiles/components/ToggleButton/ToggleButton';

const LogicJumpToggle = ({
  currComponent,
  setLogicJumpToggle,
  setIsEditable,
}) => {
  const [isToggleOn, setIsToggleOn] = useState(
    currComponent.shouldLogicJumpToggle || false
  );

  const toggleOnChange = () => {
    setIsToggleOn(!isToggleOn);
    setLogicJumpToggle(!isToggleOn);
    setIsEditable('jump', !isToggleOn ? true : -1);
  };

  return (
    <ToggleButton
      id={I18n.t('newQuestion_view.logicalJump')}
      checked={isToggleOn}
      handleOnChange={toggleOnChange}
    />
  );
};

LogicJumpToggle.propTypes = {
  currComponent: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
    ])
  ).isRequired,
  setLogicJumpToggle: PropTypes.func.isRequired,
  setIsEditable: PropTypes.func.isRequired,
};
export default withTranslation()(LogicJumpToggle);