import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { NavigationJSON } from '@ui-common-files/utils';

export const useSideBarMenuService = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const services = useSelector(state => state.services);

  const mapServices = () => {
    const mapedServices = [];
    services?.hasOwnProperty('patientservice.myoncare.care') &&
      mapedServices.push({
        id: 0,
        shouldDisplay: false,
        icon: 'patient',
        label: t('nav.patients'),
        url: `/${NavigationJSON.PATIENT}`,
      });
    services?.hasOwnProperty('templateservice.myoncare.care') &&
      mapedServices.push({
        id: 1,
        shouldDisplay: false,
        icon: 'careplan',
        label: t('nav.careManager'),
        url: `/${NavigationJSON.CAREPLAN}`,
      });
    services?.hasOwnProperty('appointmentservice.myoncare.care') &&
      mapedServices.push({
        id: 2,
        shouldDisplay: false,
        icon: 'appointmentManager',
        label: t('nav.appointmentTab'),
        url: `/${NavigationJSON.APPOINTMENTMANAGER}`,
      });
    services?.hasOwnProperty('assetservice.myoncare.care') &&
      mapedServices.push({
        id: 3,
        shouldDisplay: false,
        icon: 'assets',
        label: t('nav.assets'),
        url: `/${NavigationJSON.FILES}`,
      });
    services?.hasOwnProperty('administrationservice.myoncare.care') &&
      mapedServices.push({
        id: 4,
        shouldDisplay: false,
        icon: 'administration',
        label: t('nav.admin'),
        url: `/${NavigationJSON.ADMINISTRATION}`,
      });
    return mapedServices;
  };

  const [navigationClicked, setNavigationClicked] = useState(mapServices());

  const handleClick = (e, index) => {
    const currentLocation = history.location.pathname;
    const temp = navigationClicked;
    const matchedElement = temp.find(elem => elem.id === index);

    if (matchedElement.url !== currentLocation) {
      matchedElement.shouldDisplay = true;
      setNavigationClicked([...temp]);
    }
  };

  const determinateActiveLink = (match, location, url) => {
    const activeLink =
      match ||
      (url.includes(NavigationJSON.PATIENT) &&
        location.includes(NavigationJSON.PATIENT_OVERVIEW)) ||
      (url.includes(NavigationJSON.PATIENT) &&
        location.includes(NavigationJSON.ANSWER_CAREPLAN)) ||
      (url.includes(NavigationJSON.CAREPLAN) &&
        ((location.includes(NavigationJSON.CARETASK) &&
          !location.includes(NavigationJSON.CARETASKS)) ||
          location.includes(NavigationJSON.IMPORT_CAREPLAN_TEMPLATE)));
    return activeLink;
  };

  return {
    mapServices,
    determinateActiveLink,
    handleClick,
  };
};
