import axios from 'axios';

import serviceConfig from '../../serviceConfig.json';

import storeInfo from '../../store';
import { resetCurrentUser } from '../../reducers/session';

const logOut = async (setIsLoggedIn, history, user) => {
  if (user) {
    await axios.get(serviceConfig.brokerService.logOut.uri);
    setIsLoggedIn(false);
    history.index = 0;
    storeInfo.store.dispatch(resetCurrentUser());
  }
  history.push('/', { logout: true });
};

export default logOut;
