/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';

import ComponentWithLabel from '../AtomWithLabel/AtomWithLabel';
import DatePicker from '../../DateTimePicker/Datepicker';
import TimePicker from '../../DateTimePicker/TimePicker';
import Flex from '../../Flex/Flex';
import Box from '../../Box/Box';

const AppointmentRecurrence = ({
  onValueChange,
  values,
  isStartDatePickerDisabled,
  hasErrors,
}) => {
  const MINUTE_INCREMENT = 15;

  return (
    <>
      <ComponentWithLabel
        id="startDate"
        htmlFor="startDate"
        labelText={I18n.t('recurrence.startsFrom')}
      >
        <Flex flexDirection="row" justifyContent="space-between">
          <Box flex="1" margin="0 10px 0 0">
            <DatePicker
              date={values.startDate}
              placeholder={I18n.t('medication.startDate')}
              minDate={new Date()}
              handleDate={date => {
                onValueChange(date, 'startDate');
              }}
              disabled={isStartDatePickerDisabled}
              hasError={hasErrors.startDateTime}
            />
          </Box>
          <Box flex="1">
            <TimePicker
              value={values.startTime}
              minuteIncrement={MINUTE_INCREMENT}
              getTimeStamp={time => {
                onValueChange(time, 'startTime');
              }}
              hasError={hasErrors.startDateTime}
            />
          </Box>
        </Flex>
        {hasErrors.startDateTime && (
          <div className="datetimepicker__error">
            {I18n.t('appointment.startDateTimeError')}
          </div>
        )}
      </ComponentWithLabel>
      <ComponentWithLabel
        id="recurrence"
        htmlFor="repeat"
        labelText={I18n.t('recurrence.endsAt')}
      >
        <Flex flexDirection="row" justifyContent="space-between">
          <Box flex="1" margin="0 10px 0 0">
            <DatePicker
              date={values.endDate}
              minDate={new Date()}
              placeholder={I18n.t('recurrence.endsOn')}
              handleDate={date => {
                onValueChange(date, 'endDate');
              }}
              disabled={false}
              hasError={hasErrors.endDateTime}
            />
          </Box>
          <Box flex="1">
            <TimePicker
              value={values.endTime}
              minuteIncrement={MINUTE_INCREMENT}
              getTimeStamp={time => {
                onValueChange(time, 'endTime');
              }}
              hasError={hasErrors.endDateTime}
            />
          </Box>
        </Flex>
        {hasErrors.endDateTime && (
          <div className="datetimepicker__error">
            {I18n.t('appointment.endDateTimeError')}
          </div>
        )}
      </ComponentWithLabel>
    </>
  );
};

AppointmentRecurrence.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  hasErrors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.array,
      PropTypes.number,
      PropTypes.bool,
    ])
  ),
  isStartDatePickerDisabled: PropTypes.bool,
};

AppointmentRecurrence.defaultProps = {
  values: {},
  isStartDatePickerDisabled: false,
};

export default AppointmentRecurrence;
