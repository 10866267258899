import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  DataTable,
  Flex,
  IconButton,
  TextInput,
} from '@ui-common-files/components';
import { MaxCharLimits, inputField } from '@ui-common-files/utils';
import { TextInputAutoComplete } from '@ui-common-files/components/TextInput/TextInput.type';
import { dataTablePageSize } from '@global/Utils';
import { getSessionLogBySessionId } from './PatientLogs.api';
import ShowPatientErrorLog from './ShowPatientErrorLog';
import { usePatientLogsService } from './PatientLogs.service';

const PatientLogs: React.FC = reloadTable => {
  const {
    searchResult,
    isLoading,
    currentPage,
    query,
    showErrorLog,
    setShowErrorLog,
    handleSearch,
    patientLogsData,
    recordsTotal,
    handleTableOnChange,
  } = usePatientLogsService(reloadTable);
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        Header: 'Care Plan Name',
        accessor: 'carePlanName',
        minWidth: 200,
      },
      {
        Header: 'Occurrence Date',
        accessor: 'occurrenceDate',
      },
      {
        Header: 'Is Answered',
        accessor: 'isAnswered',
      },
      {
        Header: 'Session ID',
        accessor: 'sessionId',
      },
      {
        Header: 'Session Created At',
        accessor: 'sessionCreatedAt',
      },
      {
        Header: 'Answered By',
        accessor: 'answeredBy',
      },
      {
        Header: 'Answered At',
        accessor: 'answerAt',
      },
      {
        Header: 'Seen Date',
        accessor: 'seenDate',
      },
      {
        Header: '',
        accessor: 'rowOptions',
        Cell: props => (
          <Box>
            {props.row._original.errorData && (
              <IconButton
                type="button"
                name="view"
                size={30}
                onClick={() => {
                  getSessionLogBySessionId(props.row.sessionId).then(
                    response => {
                      if (response) {
                        setShowErrorLog(prevState => ({
                          ...prevState,
                          errorData: response.data,
                        }));
                      }
                    }
                  );
                  setShowErrorLog({
                    isOpen: true,
                    carePlanName: props.row.carePlanName,
                    sessionId: props.row.sessionId,
                    deviceOffset: props.original.deviceOffset,
                  });
                }}
                tooltipText={t('actions.show')}
                tooltipPosition="right"
              />
            )}
          </Box>
        ),
      },
    ];
  }, []);

  return (
    <Box flex="1">
      <Flex
        alignItems="center"
        justifyContent="center"
        otherStyles={{ width: '40%' }}
      >
        <TextInput
          autoComplete={TextInputAutoComplete.OFF}
          id="uuid"
          name="uuid"
          placeholder={t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={query.value}
          handleOnChange={e => handleSearch(e)}
        />
      </Flex>

      {searchResult && (
        <Box>
          <DataTable
            pagination={true}
            pageSize={true}
            pageSizeOptions={dataTablePageSize}
            isLoading={isLoading}
            columns={columns}
            data={patientLogsData}
            onTableChange={(index, size) => handleTableOnChange(index, size)}
            page={currentPage}
            pages={Math.ceil(recordsTotal / query.length)}
            totalRecord={recordsTotal}
          />
        </Box>
      )}
      {showErrorLog.isOpen && (
        <ShowPatientErrorLog
          onClose={() => {
            setShowErrorLog({
              isOpen: false,
              carePlanName: '',
              errorData: {},
              deviceOffset: '',
            });
          }}
          errorData={showErrorLog}
        />
      )}
    </Box>
  );
};
export default PatientLogs;
