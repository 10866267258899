import React from 'react';
import { Box } from '@ui-common-files/components';
import { formatDate } from '@global/Date';
import { ErrorDetailsProps } from './ErrorDetails.types';

const ErrorDetails: React.FC<ErrorDetailsProps> = ({
  sessionError,
  sessionId,
}) => {
  return (
    <Box>
      <Box>Error Message: {sessionError.message}</Box>
      <Box>Arguments: {JSON.stringify(sessionError.arguments)}</Box>
      <Box>
        Error Time: {formatDate(sessionError.errorTime, 'D MMM YYYY HH:mm')}
      </Box>
      <Box>Extra Params: {JSON.stringify(sessionError.extraParams)}</Box>
      <Box>sessionId: {sessionId}</Box>
    </Box>
  );
};

export default ErrorDetails;
