import React from 'react';

import {
  Box,
  Flex,
  HistorialStep,
  Historial,
} from '@ui-common-files/components';

export default function LinkedCaretasks({
  allCareplans,
  allNodes,
  allEdges,
  parentNode,
}) {
  const edges = allEdges.filter(edge => edge.source === parentNode.id);

  return (
    <Flex
      flexDirection="column"
      otherStyles={{ width: '100%', marginTop: 16, marginLeft: 20 }}
    >
      {edges.map(edge => {
        const nextNode = allNodes.filter(node => node.id === edge.target)[0];
        const nextNodeId = nextNode.templateId
          ? nextNode.templateId
          : nextNode.careplanId;
        const templateLabel = allCareplans.filter(careplan => {
          const { data = {} } = careplan;
          const { careTaskData = {}, templateId } = data;
          const careplanId = careTaskData.id || templateId || careplan.id;
          return careplanId === nextNodeId;
        });

        const firstTemplateLabel = templateLabel[0];
        const templateData = firstTemplateLabel?.data;
        const currentLabel =
          templateData?.label ||
          templateData?.name ||
          firstTemplateLabel?.label ||
          firstTemplateLabel?.name;

        return (
          <Historial>
            <HistorialStep type={edge.condition}>
              <Flex flexDirection="column" otherStyles={{ width: '100%' }}>
                <Box otherStyles={{ fontWeight: 600 }}>{currentLabel}</Box>
                <LinkedCaretasks
                  allCareplans={allCareplans}
                  allNodes={allNodes}
                  allEdges={allEdges}
                  parentNode={nextNode}
                />
              </Flex>
            </HistorialStep>
          </Historial>
        );
      })}
    </Flex>
  );
}
