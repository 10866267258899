import { useEffect, useState } from 'react';

import { SelectedFilter } from '../PatientListEditFIlter/PatientListEditFilter.type';

function useShowAppliedFilterWarning(selectedFilter: SelectedFilter) {
  const [showAppliedFilterWarning, setShowAppliedFilterWarning] =
    useState<boolean>(
      selectedFilter.savedFilter.id > 1 || selectedFilter.customFilter !== null
    );

  useEffect(() => {
    setShowAppliedFilterWarning(
      selectedFilter.savedFilter.id > 1 || selectedFilter.customFilter !== null
    );
  }, [selectedFilter]);

  return { showAppliedFilterWarning, setShowAppliedFilterWarning };
}

export default useShowAppliedFilterWarning;
