import React from 'react';
import i18next from 'i18next';
import Dropdown from '../../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import InlineFilterSeparator from '../FilterSeparator/FilterSeparator';
import { usePatientFilterContext } from '../PatientFilterContext/PatientFilterContext';

const FilterOperator = (): JSX.Element => {
  const {appliedFilters, filterOperator, setFilterOperator} = usePatientFilterContext();
  const getOperatorOptions = () => {
    if (appliedFilters.patient.length === 0 && appliedFilters.caretask.length === 0) {
      return [
        {
          value: '-',
          label: i18next.t('filter_operators.dash'),
          labelLocation: 'filter_operators.dash'
        },
      ]
    };
    return [
      {
        value: 'And',
        label: i18next.t('filter_operators.and'),
        labelLocation: 'filter_operators.and'
      },
      {
        value: 'Or',
        label: i18next.t('filter_operators.or'),
        labelLocation: 'filter_operators.or'
      },
    ]
  };

  const operator = {...filterOperator, label: i18next.t(filterOperator.labelLocation)};

  return  (
    <Flex className="filters__operator">
      <div className="filters__operator-dropdown">
        <Dropdown
          value={operator}
          options={getOperatorOptions()}
          handleOnChange={setFilterOperator}
        />
      </div>
      <InlineFilterSeparator />
    </Flex>
  )
};

export default FilterOperator;