/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import 'jQuery-QueryBuilder';
import $ from 'jquery';
import '../../styles/main.scss';
import '../../styles/bootstrap/bootstrap-datepicker-query-builder';
import '../../styles/bootstrap/bootstrap-datepicker3.min.css';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
const ComplexSearch = ({
  filters,
  rules,
  getSql,
  getRules,
  hide,
  hasError,
  onRuleChange,
  id,
  onValidateError,
}) => {
  const { t } = useTranslation();
  let QueryBuilder = $.fn.queryBuilder;
  QueryBuilder.regional[t('patients_complexsearch.code')] = {
    "__locale": t('patients_complexsearch.locale'),
    "add_rule": t('patients_complexsearch.add_rule'),
    "add_group": t('patients_complexsearch.add_group'),
    "delete_rule": t('patients_complexsearch.delete_rule'),
    "delete_group": t('patients_complexsearch.delete_group'),
    "conditions": {
      "AND": t('patients_complexsearch.AND'),
      "OR": t('patients_complexsearch.OR')
    },
    "operators": {
      "equal":   t('patients_complexsearch.equal'),
      "not_equal": t('patients_complexsearch.not_equal'),
      "in": t('patients_complexsearch.in'),
      "not_in": t('patients_complexsearch.not_in'),
      "less": t('patients_complexsearch.less'),
      "less_or_equal": t('patients_complexsearch.less_or_equal'),
      "greater": t('patients_complexsearch.greater'),
      "greater_or_equal": t('patients_complexsearch.greater_or_equal'),
      "between": t('patients_complexsearch.between'),
      "not_between": t('patients_complexsearch.not_between'),
      "begins_with": t('patients_complexsearch.begins_with'),
      "not_begins_with": t('patients_complexsearch.not_begins_with'),
      "contains": t('patients_complexsearch.contains'),
      "not_contains": t('patients_complexsearch.not_contains'),
      "ends_with": t('patients_complexsearch.ends_with'),
      "not_ends_with": t('patients_complexsearch.not_ends_with'),
      "is_empty": t('patients_complexsearch.is_empty'),
      "is_not_empty": t('patients_complexsearch.is_not_empty'),
      "is_null": t('patients_complexsearch.is_null'),
      "is_not_null": t('patients_complexsearch.is_not_null')
    },
    "errors": {
      "no_filter": t('patients_complexsearch.no_filter'),
      "empty_group": t('patients_complexsearch.empty_group'),
      "radio_empty": t('patients_complexsearch.radio_empty'),
      "checkbox_empty": t('patients_complexsearch.checkbox_empty'),
      "select_empty": t('patients_complexsearch.select_empty'),
      "string_empty": t('patients_complexsearch.string_empty'),
      "string_exceed_min_length": t('patients_complexsearch.string_exceed_min_length'),
      "string_exceed_max_length": t('patients_complexsearch.string_exceed_max_length'),
      "string_invalid_format": t('patients_complexsearch.string_invalid_format'),
      "number_nan": t('patients_complexsearch.number_nan'),
      "number_not_integer": t('patients_complexsearch.number_not_integer'),
      "number_not_double": t('patients_complexsearch.number_not_double'),
      "number_exceed_min": t('patients_complexsearch.number_exceed_min'),
      "number_exceed_max": t('patients_complexsearch.number_exceed_max'),
      "number_wrong_step": t('patients_complexsearch.number_wrong_step'),
      "datetime_invalid": t('patients_complexsearch.datetime_invalid'),
      "datetime_exceed_min": t('patients_complexsearch.datetime_exceed_min'),
      "datetime_exceed_max": t('patients_complexsearch.datetime_exceed_max')
    }
  };
  
  QueryBuilder.defaults({ lang_code: t('patients_complexsearch.code') });
  
  const modifyRulesForDateValue = rule => {
    const modifiedRules = rule;
    for (const i in modifiedRules) {
      const getRulesTemp = modifiedRules[i];
      for (const j in getRulesTemp) {
        if (getRulesTemp[j].rules) {
          modifyRulesForDateValue(getRulesTemp[j]);
        } else {
          if (getRulesTemp[j].id === 'reminders.startsAt') {
            modifiedRules[i][j].value = new Moment().format('YYYY-MM-DD');
          }
          if (
            getRulesTemp[j].id ===
            'dateToday(FROM_UNIXTIME(allOccurrences.unixEpochs))'
          ) {
            modifiedRules[i][j].value = new Moment().format('YYYY-MM-DD');
          }
          if (getRulesTemp[j].id === 'answers.answerForDateToday') {
            modifiedRules[i][j].value = new Moment().format('YYYYMMDD');
          }
        }
      }
    }
    return modifiedRules;
  };
  const validateBuilder = () => {
    const isValid = $(`#${id}`).queryBuilder('validate');
    hasError(!isValid);
    if (isValid) {
      const sql = $(`#${id}`).queryBuilder('getSQL', 'question_mark');
      const rule = $(`#${id}`).queryBuilder('getRules');
      getSql(sql);
      getRules(modifyRulesForDateValue(rule));
    } else {
      getSql(null);
      getRules(null);
    }
  };
  useEffect(() => {
    if (onRuleChange) {
      $(`#${id}`).on('afterCreateRuleInput.queryBuilder', function(e, rule) {
        onRuleChange(rule);
      });
    }
    $(`#${id}`).queryBuilder({
      filters,
      default_filter: filters[0].id,
      plugins: ['unique-filter'],
    });
    if (hide) $(`#${id}`).hide();
  }, []);
  useEffect(() => {
    $(`#${id}`).on('validationError.queryBuilder', function(e, node, error) {
      onValidateError({
        node,
        error,
      });
    });
    if (rules)
      $(`#${id}`).queryBuilder('setRules', modifyRulesForDateValue(rules));
  }, [rules]);
  useEffect(() => {
    $('input.form-control').trigger('keyup');
    validateBuilder();
  }, []);
  useEffect(() => {
    $(`#${id}`).on('rulesChanged.queryBuilder', function() {
      validateBuilder();
    });
  }, []);
  return <div className="query-builder" id={id} />;
};
ComplexSearch.propTypes = {
  /** Same Props as used in JQuery-QueryBuilder Library */
  filters: PropTypes.array,
  rules: PropTypes.object,
  getSql: PropTypes.func.isRequired,
  getRules: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  hide: PropTypes.bool,
  hasError: PropTypes.func,
  onRuleChange: PropTypes.func,
  onValidateError: PropTypes.func,
};

export default ComplexSearch;
