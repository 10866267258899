import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, CalendarSelector } from '@ui-common-files/components';
import { PatientOverviewContext } from 'src/Views/PatientOverview/PatientOverview';
import DateViewer from './DateViewer/DateViewer';

import calendarLocale from '../../../../Utils/CalendarLocale';

import { setDateRangeOnViewChange, selectors } from './OverviewDataCard.utils';

const OverviewDataCardHeader = ({
  selector,
  selectorValue,
  dateRange,
  onSelectorChange,
  onDateChange,
}) => {
  const { t } = useTranslation();

  const { devicesQuery, setDevicesQuery } = useContext(PatientOverviewContext);
  return (
    <Flex
      justifyContent="space-between"
      otherStyles={{
        padding: '16px 10px 16px 20px',
      }}
    >
      <Flex alignItems="center">
        <Box className="overview__card-title">
          {t('types.componentType.overview')}
        </Box>
      </Flex>
      {selector && (
        <Flex
          justifyContent="flex-end"
          otherStyles={{ flex: 1, marginRight: '16px' }}
        >
          <DateViewer
            date={dateRange}
            onChange={onDateChange}
            filter={selectorValue}
          />
        </Flex>
      )}
      <Flex justifyContent="flex-end">
        {selector && (
          <CalendarSelector
            view={selectorValue}
            filterAction={false}
            onView={filter => {
              if (selector) {
                const filterObject = {
                  filter,
                  isTodayActive: false,
                  isLastDataActive: false,
                  date: setDateRangeOnViewChange(filter, devicesQuery.date.end),
                };
                onSelectorChange(filterObject);
              }
            }}
            locale={calendarLocale()}
            selectors={selectors}
            devicesQuery={devicesQuery}
            setDevicesQuery={setDevicesQuery}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default OverviewDataCardHeader;
