import { createInstance } from '@jonkoops/matomo-tracker-react';

import config from '../../../../config';


const initAnalyticsInstance  = createInstance({
    urlBase: config.matomoURL,
    siteId: 1,
    userId: 'loggedOutUser',
    disabled: false,
    heartBeat: {
      active: true,
      seconds: 10,
    },
    linkTracking: false,
    configurations: {
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST',
    },
  });

export default initAnalyticsInstance;
