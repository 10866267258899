import React, { useState, useEffect } from 'react';
import I18n from 'i18next';

import axios from 'axios';
import moment from 'moment';
import ComponentWithLabel from '../../../caro-ui-commonfiles/components/molecules/AtomWithLabel/AtomWithLabel';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import '../../css/careplans.scss';
import '../../css/answers.css';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import DatePicker from '../../../caro-ui-commonfiles/components/DateTimePicker/Datepicker';
import MultipleSelection from '../../../caro-ui-commonfiles/components/MultipleSelectTagSearchable/MultipleSelectTagSearchable';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import serviceConfig from '../../../serviceConfig.json';
import Loader from 'react-loader-spinner';
import { PrimaryColor } from '../../../caro-ui-commonfiles/utils/colors';
import PatientsForAssign from '../PatientForAssign';

const OncoboxExportModalContent = ({
  closeModal,
  isLoading,
  nbrPatientsSelected,
  setNbrPatientsSelected,
  selectedPatientsData,
  setSelectedPatientsData,
  disableNext,
  setDisableNext,
  isSelectAll,
  setIsSelectAll,
}) => {
  const closeModalAndSync = () => {
    closeModal();
    setIsSelectAll(false);
    setSelectedPatientsData([]);
    setNbrPatientsSelected(0);
    setDisableNext(false);
  };
  const deselectAll = shouldSelectAll => {
    if (!shouldSelectAll) {
      setSelectedPatientsData([]);
      setNbrPatientsSelected(0);
    }
  };

  useEffect(() => {
    deselectAll(isSelectAll);
  }, [isSelectAll]);
  return (
    <ModalContent>
      {isLoading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          otherStyles={{
            top: '47.3%',
            left: '48.4%',
            position: 'fixed',
          }}
        >
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Flex>
      ) : (
        <ModalForm
          formId="assign-careplan-from-manager"
          handleFormSubmit={e => {
            e.preventDefault();
          }}
        >
          <PatientsForAssign
            isSelectAll={isSelectAll}
            setIsSelectAll={value => {
              deselectAll(value);
              setIsSelectAll(value);
            }}
            nbrPatientsSelected={nbrPatientsSelected}
            setNbrPatientsSelected={setNbrPatientsSelected}
            selectedPatientsData={selectedPatientsData}
            setSelectedPatientsData={setSelectedPatientsData}
            setDisableNext={setDisableNext}
            isOncobox={true}
          />
        </ModalForm>
      )}
    </ModalContent>
  );
};

export default OncoboxExportModalContent;
