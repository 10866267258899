import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { OVERALL_SCORE_COLOR } from '../../OverviewDataPointsSelection/CaretasksData/CaretasksData.constant';
import { OVERALL_SCORE, SYSTOLIC, DIASTOLIC } from './CustomTooltip.constant';

const useDataPointItemService = (
  dataPointUnit: string,
  isCircular: boolean
) => {
  const { t } = useTranslation();

  const {
    isBloodPressure,
    isOverallScore,
    isScoreValue,
    doesOverlapWithScore,
  } = useMemo(() => {
    const isBloodPressure =
      dataPointUnit === SYSTOLIC || dataPointUnit === DIASTOLIC;
    const isOverallScore = dataPointUnit === OVERALL_SCORE;
    const isScoreValue = isOverallScore || dataPointUnit === undefined;
    const doesOverlapWithScore = !isCircular && isScoreValue;

    return {
      isBloodPressure,
      isOverallScore,
      isScoreValue,
      doesOverlapWithScore,
    };
  }, [dataPointUnit, isCircular]);

  const containerClass = classNames(
    'overview-custom-tooltip_data-points_container',
    {
      'm-margin-large': doesOverlapWithScore,
      'm-margin-small': !isScoreValue,
    }
  );

  const getIndicatorStyles = (lineColor: string) => {
    return {
      background:
        isOverallScore && !isBloodPressure ? OVERALL_SCORE_COLOR : lineColor,
      width: 8,
      height: 8,
      ...(isCircular && { borderRadius: '50%' }),
    };
  };

  const getLabel = (dataPointName: string, dataPointUnit: string): string => {
    return isOverallScore
      ? `${t(dataPointUnit)}`
      : `${t(dataPointName)} ${t(dataPointUnit)}`;
  };

  return {
    containerClass,
    getIndicatorStyles,
    getLabel,
  };
};

export default useDataPointItemService;
