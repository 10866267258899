import React from 'react';

import { Box } from '@ui-common-files/components';

import './MedicationPlanReportPageFooter.scss';

const MedicationPlanReportPageFooter = (): JSX.Element => {
  return <Box className="page-footer"></Box>;
};

export default MedicationPlanReportPageFooter;
