import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { getDateDifference } from '@global/Date';
import { PatientOverviewContext } from '../../../PatientOverview/PatientOverview';
import { TodoAssessmentCardProps } from './TodoAssessmentCard.type';
import Occurrence from '@type/Common/Occurrence.enum';

export default function useTodoAssessmentCard({
  assessment,
}: TodoAssessmentCardProps) {
  const { t } = useTranslation();
  const assessmentExpiryTime = moment.unix(assessment.expiryTime).utc().toDate();
  const { navigateToCareplanAnswerView } = useContext(PatientOverviewContext);
  const [timeLeft, setTimeLeft] = useState(
    getDateDifference(assessmentExpiryTime)
  );
  const isActive = timeLeft.amount > 0;

  const expiryText = !isActive
    ? t('common_labels.expired')
    : t('common_labels.expires_in', {
        amount: timeLeft.amount,
        time: t(`lifespan.${timeLeft.type.toUpperCase()}`),
      });

  const onClick = () => {
    const { id, occurrenceId, isAssessment } = assessment;
    navigateToCareplanAnswerView({
      id,
      isAssessment,
      occurrenceId,
      setItemIdToUpdate: () => {},
    });
  };

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (timeLeft.type === Occurrence.Minute)
      interval = setInterval(() => {
        setTimeLeft(getDateDifference(assessmentExpiryTime));
      }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [assessment]);

  return {
    onClick,
    timeLeft,
    isActive,
    expiryText,
    t,
  };
}
