import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import SectionScoresWrapper from './SectionScoresWrapper';

import { isScoreSuitable } from './ScoresSection.utils';

function ScoresSection({
  scores,
  overallScore,
  isEvaluating,
}: {
  scores: { score: number | null; description: string }[];
  overallScore: number;
  isEvaluating: boolean;
}) {
  const { t } = useTranslation();

  const hasNaNTag = scores?.some(element => !isScoreSuitable(element.score));

  const overallScoreMemo = useMemo(
    () => ({
      score: isScoreSuitable(overallScore) ? overallScore : NaN,
      description: t('QuestionCatalog_view.sections.scores.overallScore'),
    }),
    [overallScore, t]
  );

  return (
    <Box margin="32px 0">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        otherStyles={{ marginBottom: '16px' }}
      >
        <h2 className="caretask-results__section-title">
          {t('QuestionCatalog_view.sections.scores.heading')}
        </h2>
      </Flex>
      {overallScore >= 0 && isEvaluating && (
        <SectionScoresWrapper element={overallScoreMemo} />
      )}
      {scores?.map((element, index) => {
        return (
          <SectionScoresWrapper
            element={element}
            hasNaNTag={index === scores.length - 1 && hasNaNTag}
          />
        );
      })}
    </Box>
  );
}

export default ScoresSection;
