import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import { PromptFooterProps } from '@type/ConfirmationPrompt';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';

import { ButtonVariant } from '../../../caro-ui-commonfiles/utils/button';
import { SubmissionTypes } from '../../../caro-ui-commonfiles/utils/componentTypes';

const PromptContent = ({ message }) => (
  <Box padding="24px" className="modal__content">
    <Flex
      flexDirection="column"
      flexWrap="wrap"
      otherStyles={{
        whiteSpace: 'pre-line',
        textAlign: 'center',
        wordBreak: 'break-word',
      }}
    >
      {message}
    </Flex>
  </Box>
);

const renderButtonTranslation = (comesFromAppointmentCard, submissionType) => {
  if (comesFromAppointmentCard) {
    return t('common_labels.label_send');
  }
  if (submissionType === SubmissionTypes.ASSIGN_AND_SEND) {
    return t('assignTemplate_view.send_immediately');
  }
  if (submissionType === SubmissionTypes.ASSIGN_ONLY) {
    return t('assignTemplate_view.add_to_assignments');
  }
  if (submissionType === SubmissionTypes.DEACTIVATE) {
    return t('common_buttons.deactivate');
  }
  if (submissionType === SubmissionTypes.CONFIRM) {
    return t('common_buttons.confirm');
  }
  if (submissionType === SubmissionTypes.ACKNOWLEDGE_EXPORT) {
    return t('qrCode.qrCodeDislaimerModalAcknowledgeButton');
  }
  if (submissionType === SubmissionTypes.PROCEED) {
    return t('common_buttons.proceed');
  }
  return t('common_buttons.delete');
};

const PromptFooter = ({
  close,
  confirmHandler,
  btnLabelLeft,
  btnLabelRight,
  hasOneBtn = false,
  comesFromAppointmentCard = false,
  submissionType,
  comingFromAppointment = false,
  setClose = true,
}: PromptFooterProps) => (
  <Box width="100%">
    <Flex justifyContent="flex-end">
      {!hasOneBtn && (
        <Box margin="0 16px 0 0">
          <Button
            label={btnLabelLeft || t('common_buttons.cancel')}
            onClick={close}
            variant={ButtonVariant.OUTLINED}
          />
        </Box>
      )}
      <Button
        label={
          btnLabelRight ||
          renderButtonTranslation(comesFromAppointmentCard, submissionType)
        }
        onClick={() => {
          confirmHandler();
          if (!comingFromAppointment && setClose) {
            close();
          }
        }}
      />
    </Flex>
  </Box>
);

const PromptFooterForCareplan = ({
  close,
  confirmHandler,
  btnLabelLeft,
  btnLabelRight,
  hasOneBtn,
  closePopUpMessage,
}: PromptFooterProps) => (
  <Box width="100%">
    <Flex justifyContent="flex-end">
      {!hasOneBtn && (
        <Box margin="0 16px 0 0">
          <Button
            label={btnLabelLeft || t('common_buttons.cancel')}
            onClick={closePopUpMessage || close}
            variant={ButtonVariant.OUTLINED}
          />
        </Box>
      )}
      <Button
        label={btnLabelRight || t('common_buttons.ok')}
        onClick={() => {
          confirmHandler();
          close();
        }}
      />
    </Flex>
  </Box>
);

PromptContent.propTypes = {
  message: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
};

export { PromptContent, PromptFooter, PromptFooterForCareplan };
