import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import PhoneInput from 'react-phone-number-input';

import {
  Box,
  ComponentWithLabel,
  TextInput,
  AddressSearch,
  Dropdown,
  ModalContent,
} from '@ui-common-files/components';
import {
  inputField,
  MaxCharLimits,
  PrimaryColor,
} from '@ui-common-files/utils';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import CustomCountrySelect from '../common/CustomCountrySelect/CustomCountrySelect';
import { useSatelliteForm } from './SatelliteForm.service';
import 'react-phone-number-input/style.css';
import '../../css/sites.css';
import '../../css/patient.css';

function SatelliteForm({
  closeModal,
  reloadTable,
  setSelectedSite,
  sites,
  selectedSite,
  setSites,
  id = null,
}) {
  const { t } = useTranslation();

  const {
    loading,
    satelliteData,
    satelliteFields,
    handleFormSubmit,
    handleSiteChange,
    onChangeField,
  } = useSatelliteForm({
    closeModal,
    reloadTable,
    setSelectedSite,
    setSites,
    selectedSite,
    id,
  });

  const { name, address, email, phoneNumber, serviceProviderId } =
    satelliteData;

  const isEditModal = id !== null;

  return (
    <ModalContent>
      {loading && (
        <Box className="roleOverlapSpinner">
          <Loader
            type="Oval"
            visible
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Box>
      )}
      {!loading && (
        <ModalForm
          formId={isEditModal ? 'edit-satellite-form' : 'create-satellite-form'}
          handleFormSubmit={handleFormSubmit}
        >
          <ModalSection>
            <Box margin="32px 20px">
              <ComponentWithLabel
                id="Name"
                htmlFor="Name"
                hasIcon={false}
                labelText={`* ${t('site_view.label_siteName')}`}
                tooltipText="Name"
                hasToggleButton={false}
                idToggleButton="toggleBtn"
                valueToggleButton="toggleBtn"
                isDisabledToggleButton={false}
                checked={false}
              >
                <TextInput
                  value={name}
                  id="Name"
                  name={t('site_view.label_siteName')}
                  placeholder={t('site_view.add_siteName')}
                  variant={inputField.variant.CHAR_COUNT}
                  maxLength={MaxCharLimits.sites.siteNameMaxCharLimit}
                  maxChars={MaxCharLimits.sites.siteNameMaxCharLimit}
                  handleOnChange={e => onChangeField('name', e.target.value)}
                  hasError={satelliteFields.name.hasError}
                  validationMessage={t(satelliteFields.name.errorMessage)}
                  isDisabled={isEditModal}
                />
              </ComponentWithLabel>
              <ComponentWithLabel
                id="Address"
                htmlFor="Address"
                hasIcon={false}
                labelText={`* ${t('site_view.label_address')}`}
                tooltipText="Address"
                hasToggleButton={false}
                idToggleButton="toggleBtn"
                valueToggleButton="toggleBtn"
                isDisabledToggleButton={false}
                checked={false}
              >
                <AddressSearch
                  value={address}
                  handleOnChange={value => onChangeField('address', value)}
                  placeholder={t('appointment.select address')}
                  hasError={satelliteFields.address.hasError}
                  validationMessage={t(satelliteFields.address.errorMessage)}
                />
              </ComponentWithLabel>
              <ComponentWithLabel
                id="Email"
                htmlFor="Email"
                hasIcon={false}
                labelText={`* ${t('site_view.label_email')}`}
                tooltipText="Email"
                hasToggleButton={false}
                idToggleButton="toggleBtn"
                valueToggleButton="toggleBtn"
                isDisabledToggleButton={false}
                checked={false}
              >
                <TextInput
                  value={email}
                  id="Email"
                  name={t('site_view.label_email')}
                  placeholder={t('patients_view.add_emailAddress')}
                  variant={inputField.variant.CHAR_COUNT}
                  maxLength={MaxCharLimits.sites.siteEmailMaxCharLimit}
                  maxChars={MaxCharLimits.sites.siteEmailMaxCharLimit}
                  handleOnChange={e => onChangeField('email', e.target.value)}
                  hasError={satelliteFields.email.hasError}
                  validationMessage={t(satelliteFields.email.errorMessage)}
                />
              </ComponentWithLabel>
              <ComponentWithLabel
                id="mobile"
                htmlFor="mobile"
                labelText={`* ${t('site_view.label_phone')}`}
              >
                <PhoneInput
                  defaultCountry="DE"
                  placeholder={t('site_view.add_phone')}
                  maxLength={MaxCharLimits.sites.sitePhoneMaxCharLimit}
                  value={phoneNumber}
                  className={`patients__phone-field${
                    satelliteFields.phoneNumber.hasError ? ' error' : ''
                  }`}
                  inputClassName="patients__phone-input"
                  onChange={value => onChangeField('phoneNumber', value)}
                  countrySelectComponent={props => (
                    <CustomCountrySelect {...props} />
                  )}
                />

                {satelliteFields.phoneNumber.hasError === true && (
                  <span className="patients__phone-field error">
                    {t(satelliteFields.phoneNumber.errorMessage)}
                  </span>
                )}
              </ComponentWithLabel>
            </Box>
          </ModalSection>
          <ModalSection>
            <Box margin="32px 0px 0px 23px">
              <ComponentWithLabel
                id="filterType"
                htmlFor="filterType"
                labelText={`* ${t('nav.site')}`}
                hasToggleButton={false}
                idToggleButton="toggleBtn"
                valueToggleButton="toggleBtn"
                isDisabledToggleButton={false}
              >
                <Dropdown
                  value={selectedSite}
                  options={sites}
                  isDisabled={isEditModal}
                  placeholder={t('site_view.add_site')}
                  handleOnChange={handleSiteChange}
                />
              </ComponentWithLabel>
              <ComponentWithLabel
                id="serviceProviderId"
                htmlFor="serviceProviderId"
                labelText={`${t('satellite_view.label_serviceProviderId')}`}
              >
                <TextInput
                  value={serviceProviderId}
                  id="serviceProviderId"
                  name={t('satellite_view.label_serviceProviderId')}
                  placeholder={t('satellite_view.add_serviceProviderId')}
                  variant={inputField.variant.CHAR_COUNT}
                  maxLength={MaxCharLimits.sites.siteNameMaxCharLimit}
                  maxChars={MaxCharLimits.sites.siteNameMaxCharLimit}
                  handleOnChange={e => onChangeField('serviceProviderId', e.target.value)}
                  hasError={satelliteFields.name.hasError}
                  validationMessage={t(satelliteFields.name.errorMessage)}
                />
              </ComponentWithLabel>
            </Box>
          </ModalSection>
        </ModalForm>
      )}
    </ModalContent>
  );
}

export { SatelliteForm };
