import { ApiClient } from '@global/Services';

import { NavigationJSON } from '@ui-common-files/utils';
import { BrowserHistoryPush } from '@global/Router/BrowserHistory/BrowserHistory';
import serviceConfig from '../../../../serviceConfig.json';

export const getCarePathWayContentForEdit = (
  id: number,
  signal: AbortSignal
) => {
  return ApiClient.GET({
    url: serviceConfig.brokerService.getCarePathwayTemplate.uri.replace(
      ':id',
      id
    ),
    signal,
    errorCallback: error => {
      if ([404, 500, 403].includes(error.response.status)) {
        BrowserHistoryPush(`/${NavigationJSON.CAREPLAN}`, {
          redirectToCaretaskList: false,
        });
        return error;
      }
    },
  });
};
