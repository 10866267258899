import React, { useEffect, useState, useReducer } from 'react';
import I18n from 'i18next';
import '../../../css/componentSettings.css';
import { withTranslation } from 'react-i18next';
import { Checkbox } from '@ui-common-files/components';

import { HideFlashMessage } from '@global/Services';

import Modal from '../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import TextInput from '../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import Icon from '../../../../caro-ui-commonfiles/components/Icon/Icon';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import Label from '../../../../caro-ui-commonfiles/components/Label/Label';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';
import IconButton from '../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import Tabs from '../../../../caro-ui-commonfiles/components/Tabs/Tabs';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';
import ASSET_TYPES from '../../../Utils/Asset/assetTypes';
import tabPosition from '../../../Utils/Administration/tabPosition';
import Assets from '../../Datatables/Assets';
import ModalSection from '../../common/Layout/Modal/ModalSection';
import ModalForm from '../../common/Layout/Modal/ModalForm';
import getAssetIcon from '../../../Utils/Asset/getAssetIcon';
import actions from '../../../Utils/actions';
import ShowAssetModalContent from '../../Files/ShowAssetModalContent';
import ModalFooter from '../../common/Layout/Modal/ModalFooter';
import ModalContent from '../../../../caro-ui-commonfiles/components/Modals/ModalContent';
import {
  showAssetRequest,
  getAssetAssociations,
  convertLinkToBlob,
} from '../../../Utils/Asset/assetHelper';
import { ViewCareplanModalFooter } from '../ViewCareplan';
import FlashMessage from '../../../../caro-ui-commonfiles/components/FlashMessage/FlashMessage';
import { useUserPermission } from '../../../Utils/hooks/getUserPermission';
import Tooltip from '../../../../caro-ui-commonfiles/components/Tooltip/Tooltip';

function MediaSettingsComponent({
  componentCollection,
  setComponentCollection,
  searchTermComponents,
  setFileReviewToggle,
}) {
  const [assetAssociations, setAssetAssociations] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [flashMessage, setFlashMessage] = useState({});
  const [attachedFile, setAttachedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [attachBtnClicked, setAttachBtnClicked] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);
  const [blobURL, setBlobURL] = useState(null);
  const [action, setAction] = useState('');
  const [assetDataToShow, setAssetDataToShow] = useState(null);

  const hasAssetSharePermission = useUserPermission(
    I18n.t('types.componentType.assets'),
    I18n.t('actions.share'),
    true
  );
  const changeTabPosition = () => {
    return tabPosition.FIRST;
  };
  const getActiveTab = (_state, data) => data;
  const [activeTab, setActiveTab] = useReducer(
    getActiveTab,
    changeTabPosition()
  );

  const getMatchedPosition = () => {
    return componentCollection.findIndex(elem => {
      return elem.id == searchTermComponents.id;
    });
  };
  const assetObj = componentCollection[getMatchedPosition()].asset;
  const onClickTabItem = tab => {
    setActiveTab(tab);
  };
  const matchingComponent = componentCollection[getMatchedPosition()];
  const isSyncAttachment =
    matchingComponent.isSyncAttachment ||
    matchingComponent.searchTerm.isSyncAttachment;
  const componentAsset =
    matchingComponent.asset || matchingComponent.searchTerm.asset;

  useEffect(() => {
    const componentCollectionTemp = [...componentCollection];
    const matchingComponentTemp = componentCollectionTemp[getMatchedPosition()];
    if (attachedFile && attachBtnClicked) {
      matchingComponentTemp.assetId = attachedFile && attachedFile.id;
      if (
        matchingComponentTemp.searchTerm.additional_configuration &&
        !matchingComponentTemp.searchTerm.additional_configuration.mediaURL
      )
        matchingComponentTemp.searchTerm.additional_configuration.mediaURL =
          attachedFile.directLink;
      if (matchingComponentTemp.searchTerm.additional_configuration)
        matchingComponentTemp.searchTerm.additional_configuration.isMedia =
          true;
      matchingComponentTemp.asset = attachedFile;
    }
    setComponentCollection(componentCollectionTemp);
  }, [attachedFile, attachBtnClicked]);

  const clearAttachedFileAndMedia = () => {
    setAttachBtnClicked(false);
    const componentCollectionTemp = [...componentCollection];
    const matchingComponentTemp = componentCollectionTemp[getMatchedPosition()];
    matchingComponentTemp.asset = null;
    if (typeof matchingComponentTemp.searchTerm === 'object')
      matchingComponentTemp.searchTerm.asset = null;
    if (matchingComponent.searchTerm.additional_configuration)
      matchingComponent.searchTerm.additional_configuration.mediaURL = '';
    setComponentCollection(componentCollectionTemp);
  };

  useEffect(() => {
    setAttachBtnClicked(false);

    const thumbnailURL = componentAsset
      ? componentAsset.assetTypeId === ASSET_TYPES.IMAGE
        ? componentAsset.directLink
        : componentAsset.thumbnailURL
      : null;
    if (thumbnailURL)
      convertLinkToBlob(thumbnailURL)
        .then(convertedUrl => {
          setBlobURL(convertedUrl);
        })
        .catch(error => {
          setBlobURL(null);
          throw new Error(error);
        });
  }, [componentCollection]);

  const onIsSyncAttachmentChange = e => {
    const isChekced = e.target.checked;
    const componentCollectionTemp = [...componentCollection];
    const matchingComponentTemp = componentCollectionTemp[getMatchedPosition()];

    matchingComponentTemp.isSyncAttachment = isChekced;
    matchingComponentTemp.searchTerm.isSyncAttachment = isChekced;

    setComponentCollection(componentCollectionTemp);
    if (isChekced) {
      setFileReviewToggle(true);
    }
  };

  const renderAttachedFile = () => {
    const { assetTypeId, thumbnailURL } = componentAsset;
    const assetName = componentAsset.name;
    return (
      <>
        <Box margin="24px 0px 0px 0px">
          <span className="attached-file__txt">
            {I18n.t('asset_view.attachedFile')}
          </span>
        </Box>
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={{ marginTop: '20px' }}
        >
          <Box>
            {thumbnailURL && blobURL ? (
              <Box className="img-object__fit">
                <img src={blobURL} width="24px" height="24px" />
              </Box>
            ) : (
              getAssetIcon(assetTypeId, 24)
            )}
          </Box>
          <div
            className="attached-file__div"
            style={{
              marginLeft: assetTypeId === ASSET_TYPES.IMAGE ? '16px' : null,
            }}
          >
            <span className="attached-file__name">{assetName}</span>
          </div>
          <div className="attached-file__buttons">
            <IconButton
              type="button"
              name="view"
              size={29}
              onClick={() => {
                setModalTitle(`${I18n.t('asset_view.showFile')}: ${assetName}`);
                setAction(actions.Show);
                Promise.all([
                  showAssetRequest(
                    componentAsset.id,
                    setAssetDataToShow,
                    setOpenModal,
                    setFlashMessage
                  ),
                  getAssetAssociations(componentAsset.id, setAssetAssociations),
                ]);
              }}
              tooltipText={I18n.t('actions.show')}
            />
            <IconButton
              type="button"
              name="remove"
              size={22}
              fill={ActionColors.DELETE}
              onClick={() => {
                clearAttachedFileAndMedia();
              }}
              tooltipText={I18n.t('toolTipsText.remove')}
            />
          </div>
        </Flex>
        <Box margin="24px 0px 0px 0px">
          <Checkbox
            id="is-sync-attachment"
            name="is-sync-attachment"
            label={I18n.t('asset_view.isSyncAttachment')}
            checked={isSyncAttachment}
            handleOnChange={onIsSyncAttachmentChange}
          />
        </Box>
      </>
    );
  };
  const renderMediaUrl = mediaURL => (
    <>
      <Box margin="14px 0 0 0">
        <Label text={I18n.t('asset_view.urlLink')} textColor="#979797" />
      </Box>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        otherStyles={{ marginTop: '9px' }}
      >
        <Box width="320px">
          <TextInput
            value={mediaURL}
            placeholder=""
            id="mediaURL"
            isDisabled
            maxChars={10}
            handleOnChange={() => {}}
          />
        </Box>
        <div style={{ marginLeft: '20px' }}>
          <IconButton
            type="button"
            name="remove"
            size={22}
            fill={ActionColors.DELETE}
            onClick={() => {
              clearAttachedFileAndMedia();
            }}
            tooltipText={I18n.t('toolTipsText.remove')}
          />
        </div>
      </Flex>
      <Box margin="8px 0px 0px 0px" width={310}>
        <span className="url-warning__txt">
          {I18n.t('asset_view.urlAttachWarning')}
        </span>
      </Box>
    </>
  );
  const renderCareplanComponentMediaSettings = () => {
    const mediaURL =
      matchingComponent &&
      matchingComponent.asset &&
      matchingComponent.asset.directLink;

    const condOne = !hasAssetSharePermission;
    const condTwo = !!(mediaURL && !matchingComponent.asset);
    const condThree = !!matchingComponent.asset;
    const condFour = !!(!attachedFile && assetObj && assetObj.id);
    return (
      <Flex className="toggleAndTextContainer label-toggle-content__component-settings">
        <div className="media-settings__div">
          <div>
            {!hasAssetSharePermission ? (
              <Tooltip
                content={I18n.t('asset_view.tooltip_label_notAllowedToShare')}
              >
                <Button
                  label={I18n.t('asset_view.selectFromFile')}
                  disabled={condOne || condTwo || condThree || condFour}
                  fullWidth
                  onClick={() => {
                    setAction(actions.Attach);
                    setModalTitle(I18n.t('asset_view.attachFile'));
                    setOpenModal(true);
                  }}
                >
                  <Icon name="attachment" />
                </Button>
              </Tooltip>
            ) : (
              <Button
                label={I18n.t('asset_view.selectFromFile')}
                disabled={condOne || condTwo || condThree || condFour}
                fullWidth
                onClick={() => {
                  setAction(actions.Attach);
                  setModalTitle(I18n.t('asset_view.attachFile'));
                  setOpenModal(true);
                }}
              >
                <Icon name="attachment" />
              </Button>
            )}
          </div>
          {mediaURL &&
            !matchingComponent.asset &&
            !matchingComponent.searchTerm.asset &&
            renderMediaUrl(mediaURL)}
          {matchingComponent.asset ||
          matchingComponent.searchTerm.asset ||
          (matchingComponent.asset && assetObj && assetObj.id)
            ? renderAttachedFile()
            : null}
        </div>
      </Flex>
    );
  };
  const renderModalFooter = () => {
    switch (action) {
      case actions.Show:
        return <ViewCareplanModalFooter close={closeModal} />;
      case actions.Attach:
        return (
          <ModalFooter
            close={closeModal}
            labelCTA={I18n.t('actions.attach')}
            onClickCTA={() => {
              setAttachBtnClicked(true);
              closeModal();
            }}
          />
        );
    }
  };
  const closeModal = () => {
    setOpenModal(false);
    setActiveTab(1);
  };
  const setActiveTabAndReload = tabId => {
    setReloadTable(!reloadTable);
    setActiveTab(tabId);
  };
  const renderModalContent = () => {
    switch (action) {
      case actions.Show:
        return (
          <ShowAssetModalContent
            assetDataToShow={assetDataToShow}
            shouldShowSites
            assetAssociations={assetAssociations}
          />
        );
      case actions.Attach:
        return (
          <ModalContent>
            <ModalForm>
              <ModalSection hasPadding>
                {flashMessage.content ? (
                  <FlashMessage
                    message={I18n.t(flashMessage.content)}
                    type={flashMessage.type}
                    onClick={() => {
                      setFlashMessage({});
                    }}
                  />
                ) : null}
                <div style={{ position: 'relative' }}>
                  <Tabs activeTab={activeTab} onClickTabItem={onClickTabItem}>
                    <div
                      label={I18n.t('asset_view.documents')}
                      tabId={tabPosition.FIRST}
                    >
                      <Assets
                        hasPagesize
                        reloadTable={reloadTable}
                        setReloadTable={setActiveTabAndReload}
                        setFlashMessage={setFlashMessage}
                        activeTab={ASSET_TYPES.DOCUMENT}
                        isAttachFile
                        setAttachedFile={setAttachedFile}
                        fetchedData={fetchedData}
                        setFetchedData={setFetchedData}
                        isPrivacyPolicy={false}
                      />
                    </div>
                    <div
                      label={I18n.t('asset_view.images')}
                      tabId={tabPosition.SECOND}
                    >
                      <Assets
                        hasPagesize
                        reloadTable={reloadTable}
                        setReloadTable={setActiveTabAndReload}
                        setFlashMessage={setFlashMessage}
                        activeTab={ASSET_TYPES.IMAGE}
                        isAttachFile
                        setAttachedFile={setAttachedFile}
                        fetchedData={fetchedData}
                        setFetchedData={setFetchedData}
                        isPrivacyPolicy={false}
                      />
                    </div>
                    <div
                      label={I18n.t('asset_view.videos')}
                      tabId={tabPosition.THIRD}
                    >
                      <Assets
                        hasPagesize
                        reloadTable={reloadTable}
                        setReloadTable={setActiveTabAndReload}
                        setFlashMessage={setFlashMessage}
                        activeTab={ASSET_TYPES.VIDEO}
                        isAttachFile
                        setAttachedFile={setAttachedFile}
                        fetchedData={fetchedData}
                        setFetchedData={setFetchedData}
                        isPrivacyPolicy={false}
                      />
                    </div>
                    <div
                      label={I18n.t('asset_view.audios')}
                      tabId={tabPosition.FOURTH}
                    >
                      <Assets
                        hasPagesize
                        reloadTable={reloadTable}
                        setReloadTable={setActiveTabAndReload}
                        setFlashMessage={setFlashMessage}
                        activeTab={ASSET_TYPES.AUDIO}
                        isAttachFile
                        setAttachedFile={setAttachedFile}
                        fetchedData={fetchedData}
                        setFetchedData={setFetchedData}
                        isPrivacyPolicy={false}
                      />
                    </div>
                  </Tabs>
                </div>
              </ModalSection>
            </ModalForm>
          </ModalContent>
        );
    }
  };
  return (
    <>
      <Modal
        title={modalTitle}
        contentComponent={renderModalContent()}
        footerComponent={renderModalFooter()}
        openModal={openModal}
        onClose={closeModal}
        hideFlashMessage={HideFlashMessage}
      />
      {renderCareplanComponentMediaSettings()}
    </>
  );
}
export default withTranslation()(MediaSettingsComponent);
