import React from 'react';
import I18n from 'i18next';
import moment from 'moment';
import Loader from 'react-loader-spinner';

import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';

import {
  Flex,
  Box,
  Label,
  Button,
  ModalContent,
  Historial,
  HistorialStep,
  ICDViewer,
  ComponentWithLabel,
} from '@ui-common-files/components';
import { ShowCaretaskData } from './CaretaskData';

import '../../css/careplans.scss';

import {
  ButtonVariant,
  PrimaryColor,
  getDurationTextForOverview,
  sortCarepathwayCareplanTemplateByStartingTime,
} from '@ui-common-files/utils';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';
import { getAction, getComponent } from '../../Utils/History';
import ShowCarePathwayTemplateViewComponent from '../../Utils/showCarePathwayTemplateViewComponent';

const ViewCarePathwayTemplate = props => {
  const carePathwayTemplate = props.carePathwayTemplatesForShow;
  const carePathwayTemplateWithThresholdsAndComponents =
    props.carePathwayTemplatesWithThresholdsForShow[0];
  const { ownershipLogs } = props;
  const { icds } = carePathwayTemplate;

  const getAllCareplanWithOccurrences = templates => {
    const carePathwayTemplates = [];
    for (let index = 0, { length } = templates; index < length; index++) {
      const template = templates[index];
      for (
        let subIndex = 0,
          count = template.carePathwayTemplateAppointmentType.length;
        subIndex < count;
        subIndex++
      ) {
        carePathwayTemplates.push({
          ...template,
          carePathwayTemplateAppointmentType:
            template.carePathwayTemplateAppointmentType[subIndex],
        });
      }
    }
    return carePathwayTemplates;
  };

  const renderCareplanTemplateHistory = () => {
    return ownershipLogs.map(log => (
      <Historial>
        <HistorialStep key="showTemplateHistorial">
          <div className="show-template-history">
            <span>
              {I18n.t('history.assignment', {
                user: log.user != undefined ? log.user.username : log.user,
                action: getAction(log.action).toLowerCase(),
                component: getComponent(log.component),
                interpolation: { escapeValue: false },
              })}
            </span>
            <span className="show-template__history-date">
              {`${moment(log.timeOfAction)
                .locale(I18n.language)
                .format('DD MMM YYYY')} - ${moment(log.timeOfAction).format(
                'HH:mm'
              )}`}
            </span>
          </div>
        </HistorialStep>
      </Historial>
    ));
  };
  const renderCareplansDuration = sortedCarePathwayTemplate => {
    return (
      <Flex flexDirection="column" otherStyles={{ width: '100%' }}>
        {sortedCarePathwayTemplate
          .filter(
            template => template.carePathwayTemplateAppointmentType.isRoot
          )
          .map(template => {
            const caretask = template.carePathwayTemplateAppointmentType;
            const { startText, endText, occurrence } =
              getDurationTextForOverview(
                caretask,
                carePathwayTemplate.appointmentType
              );
            return (
              <ShowCaretaskData
                caretaskName={template.name}
                caretask={caretask}
                allCareplans={sortedCarePathwayTemplate}
                startText={startText}
                occurrence={occurrence}
                endText={endText}
              />
            );
          })}
      </Flex>
    );
  };

  const CarePathwayTemplateInfo = carePathwayTemplate => {
    const sortedCarePathwayTemplate = getAllCareplanWithOccurrences(
      carePathwayTemplate.templates
    ).sort(sortCarepathwayCareplanTemplateByStartingTime);
    const nbOfRootTemplates = sortedCarePathwayTemplate.filter(
      template => template.carePathwayTemplateAppointmentType.isRoot
    ).length;
    return (
      <div className="carepathway_modal-layout-for-carePathway-template">
        <ComponentWithLabel
          id="pathwayName"
          htmlFor="pathwayName"
          labelText={I18n.t('newCareplan_view.carePathway')}
        >
          {carePathwayTemplate.name}
        </ComponentWithLabel>
        <ComponentWithLabel
          id="pathwayIcds"
          htmlFor="pathwayIcds"
          labelText={I18n.t('task.ICDReference')}
        >
          <ICDViewer icds={icds} shouldShowAll />
        </ComponentWithLabel>
        <ComponentWithLabel
          id="pathwayCareplans"
          htmlFor="pathwayCareplans"
          labelText={I18n.t('nav.caretasks')}
        >
          {nbOfRootTemplates}
        </ComponentWithLabel>
        <ComponentWithLabel
          id="pathway"
          htmlFor="pathway"
          labelText={I18n.t(
            'newCareplan_view.view_carePathwayTemplateSequence'
          )}
        >
          {renderCareplansDuration(sortedCarePathwayTemplate)}
        </ComponentWithLabel>
      </div>
    );
  };

  const CarePathwayTemplateWithComponents = ({
    carePathwayTemplateWithThresholdsAndComponents,
  }) => {
    const checkTemplateHaveThresholdsOrNot =
      careplanComponentsWithThresholds => {
        if (careplanComponentsWithThresholds.length > 0) {
          for (let i = 0; i < careplanComponentsWithThresholds.length; i++) {
            if (careplanComponentsWithThresholds[i][1].length > 0) {
              return true;
            }
          }
        }
        return false;
      };

    const checkTemplateHaveLogicJumpsOrNot = careplanTemplateWithLogicJumps => {
      if (Object.keys(careplanTemplateWithLogicJumps).length > 0) {
        return true;
      }
      return false;
    };

    return (
      <div className="carepathway_modal-layout-for-carePathway-template">
        <div>
          {carePathwayTemplateWithThresholdsAndComponents &&
            carePathwayTemplateWithThresholdsAndComponents.length > 0 &&
            carePathwayTemplateWithThresholdsAndComponents
              .sort((a, b) => a.template.name.localeCompare(b.template.name))
              .map(careplan => {
                const {
                  careplanComponentsWithThresholds,
                  careplanTemplateWithLogicJumps,
                } = careplan;
                const isThresholds = checkTemplateHaveThresholdsOrNot(
                  careplanComponentsWithThresholds
                );
                const isLogicJumps = checkTemplateHaveLogicJumpsOrNot(
                  careplanTemplateWithLogicJumps
                );

                const { scoringScheme, isEvaluating } = careplan.template;
                let hasScores = false;

                if (
                  isEvaluating &&
                  scoringScheme != null &&
                  scoringScheme.length > 0
                ) {
                  for (let i = 0; i < scoringScheme.length; i++) {
                    if (scoringScheme[i].max > 0) {
                      hasScores = true;
                    }
                  }
                }

                return (
                  <>
                    {(isThresholds || isLogicJumps || hasScores) && (
                      <Flex
                        otherStyles={{
                          margin: '20px 0px',
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        {careplan.template.name}
                      </Flex>
                    )}
                    {(isThresholds || isLogicJumps || hasScores) && (
                      <ShowCarePathwayTemplateViewComponent
                        careplanData={careplan}
                        careplans={careplanComponentsWithThresholds}
                        isEvaluating={careplan.template.isEvaluating}
                        careplanTemplateWithLogicJumps={
                          careplanTemplateWithLogicJumps
                        }
                      />
                    )}
                  </>
                );
              })}
        </div>
      </div>
    );
  };

  return (
    <>
      <ModalContent>
        {Object.keys(carePathwayTemplate).length !== 0 ? (
          <ModalForm>
            <ModalSection variant={MODAL_VARIANT.COL_W30}>
              <div className="history-label">
                <Label text={I18n.t('common_labels.history')} />
              </div>
              <div className="show-template__historial-wrapper">
                {ownershipLogs && renderCareplanTemplateHistory()}
              </div>
            </ModalSection>
            <ModalSection variant={MODAL_VARIANT.COL_W70}>
              <Box margin="0 0 0 -20px">
                {CarePathwayTemplateInfo(carePathwayTemplate)}
              </Box>
              <CarePathwayTemplateWithComponents
                carePathwayTemplateWithThresholdsAndComponents={
                  carePathwayTemplateWithThresholdsAndComponents
                }
              />
            </ModalSection>
          </ModalForm>
        ) : (
          <Flex
            justifyContent="center"
            alignItems="center"
            otherStyles={{
              width: '100%',
              height: '100%',
              position: 'inherit',
            }}
          >
            <Loader
              type="Oval"
              color={PrimaryColor.MAIN_COLOR}
              height={60}
              width={60}
            />
          </Flex>
        )}
      </ModalContent>
    </>
  );
};

const ViewCarePathwayTemplateModalFooter = ({ close }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        label={I18n.t('common_buttons.close')}
        type="button"
        onClick={close}
        variant={ButtonVariant.CONTAINED}
      />
    </div>
  );
};

export { ViewCarePathwayTemplate, ViewCarePathwayTemplateModalFooter };
