import { useEffect } from 'react';
import UseDelaySearchType from './UseDelaySearch.type';

let searchTimeout: NodeJS.Timeout;

function useDelaySearch({
  timeout = 800,
  input,
  callback,
}: UseDelaySearchType) {
  useEffect(() => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => callback(), timeout);

    return () => clearTimeout(searchTimeout);
  }, [input]);
}

export default useDelaySearch;
