import React, { useReducer, useEffect, useState } from 'react';
import I18n from 'i18next';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

import { Box, ModalContent, Flex, Tabs } from '@ui-common-files/components';

import { PrimaryColor } from '@ui-common-files/utils';

import PatientBasicInformation from '../../PatientBasicInformation';
import PatientManufacturer from '../../PatientManufacturer';
import PatientVisit from '../../PatientVisit';
import PatientDiagnosis from '../../PatientDiagnosis';
import PatientProcedures from '../../PatientProcedures';

import { ApiClient, ShowFlashMessage } from '@global/Services';

import { enableOpsPatientView } from '../../../../../config';
import serviceConfig from '../../../../../serviceConfig.json';

const PatientDetailsModalForUKF = ({ patientInfo, setUserHasPermission }) => {
  const [isLoading, setIsloading] = useState(false);
  const [patientAdditionalInformation, setPatientAdditionalInformation] =
    useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setIsloading(true);
    const getPatientAdditionalInformation = async () => {
      return await ApiClient.GET({
        url: serviceConfig.brokerService.getPatientAdditionalInformation.uri,
        payload: { patientId: patientInfo.id },
      })
        .then(response => {
          let patientData;
          if (response?.data) {
            patientData = response.data.response;
          }
          if (
            patientData &&
            patientData.icds &&
            patientData.durationIncisionStitches
          ) {
            setPatientAdditionalInformation(patientData);
          }
        })
        .catch(error => {
          setUserHasPermission(false);
          ShowFlashMessage({
            type: error.response.data.type,
            messageKey: error.response.data.content,
            isModalMessage: true,
          });
        })
        .finally(() => {
          setIsloading(false);
        });
    };

    if (enableOpsPatientView === 'false' || enableOpsPatientView === false) {
      getPatientAdditionalInformation();
    }
  }, []);

  useEffect(() => {
    if (patientInfo.id) setIsloading(false);
  }, [patientInfo.id]);

  const onClickTabItem = tab => {
    setActiveTab(tab);
  };

  const renderPatientDetailsTabs = () => {
    return (
      <Flex
        flexDirection="row"
        alignItems="flex-start"
        otherStyles={{ width: '85%' }}
      >
        <Tabs activeTab={activeTab} onClickTabItem={onClickTabItem}>
          <div label={I18n.t('hl7Attribute.sections.basicInfo')} tabId={0}>
            <PatientBasicInformation
              patientInfo={patientInfo}
              patientAdditionalInformation={patientAdditionalInformation}
              isLoading={isLoading}
              setIsloading={setIsloading}
            />
          </div>
          <div
            label={I18n.t('hl7Attribute.sections.patientDiagnosis')}
            tabId={1}
          >
            <PatientDiagnosis
              patientAdditionalInformation={patientAdditionalInformation}
              isLoading={isLoading}
            />
          </div>
          <div
            label={I18n.t('hl7Attribute.sections.patientProcedures')}
            tabId={2}
          >
            <PatientProcedures
              patientAdditionalInformation={patientAdditionalInformation}
              isLoading={isLoading}
            />
          </div>
          <div
            label={I18n.t('hl7Attribute.sections.patientManufacturer')}
            tabId={3}
          >
            <PatientManufacturer
              patientAdditionalInformation={patientAdditionalInformation}
              isLoading={isLoading}
            />
          </div>
          <div label={I18n.t('hl7Attribute.sections.patientVisit')} tabId={4}>
            <PatientVisit
              patientAdditionalInformation={patientAdditionalInformation}
              isLoading={isLoading}
            />
          </div>
        </Tabs>
      </Flex>
    );
  };

  return (
    <ModalContent>
      {isLoading && (
        <Box className="pdf-loader-container">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Box>
      )}
      {!isLoading && (
        <Box
          className="room-info-container"
          otherStyles={{ width: '100%', paddingLeft: 25 }}
        >
          {renderPatientDetailsTabs()}
        </Box>
      )}
    </ModalContent>
  );
};

PatientDetailsModalForUKF.propTypes = {
  patientInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default PatientDetailsModalForUKF;
