import { OverviewState } from './Overview.type';

export const STORE_NAME: string = 'OVERVIEW';

export const initialState: OverviewState = {
  overviewCaretasks: {
    isLoading: false,
    keyword: '',
    items: null,
    totalRecords: 0,
    page: 1,
  },
  overviewActivityStatus: null,
  overviewDataResponse: null,
  overviewError: null,
};
