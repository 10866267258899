import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../../../caro-ui-commonfiles/components/Icon/Icon';
import Button from '../../../../../caro-ui-commonfiles/components/Button/Button';
import FilterCriteriaList from '../FilterCriteria/FilterCriteriaList/FilterCriteriaList';
import FilterSeparator from '../FilterSeparator/FilterSeparator';
import { usePatientFilterContext } from '../PatientFilterContext/PatientFilterContext';
import { FilterContentProps, ShowFilterTypes } from './FilterContent.type';
import {
  ButtonColor,
  ButtonType,
  ButtonVariant,
} from '../../../../../caro-ui-commonfiles/utils/button';
import { Greys } from '../../../../../caro-ui-commonfiles/utils/colors';

function FilterContent({ type }: FilterContentProps) {
  const { filter, currentFilter, clearCurrentFilter } =
    usePatientFilterContext();
  const [showFilter, setShowFilter] = useState(ShowFilterTypes.Static);

  useEffect(() => {
    if (showFilter === ShowFilterTypes.Static && currentFilter === type)
      setShowFilter(ShowFilterTypes.Visible);

    if (showFilter !== ShowFilterTypes.Static) {
      const show =
        currentFilter === type
          ? ShowFilterTypes.Visible
          : ShowFilterTypes.Hidden;
      setShowFilter(show);
    }
  }, [currentFilter]);

  const Header = (
    <Flex className="filters__content-header">
      <Flex className="filters__content-header-title">
        <>
          {i18next.t('patients_complexsearch.filterTitle')}
          <FilterSeparator />
          <div className="filters__content-header-title__type">
            {i18next.t(`patients_complexsearch.${type}`).toString()}
          </div>
        </>
      </Flex>
      <button
        type="button"
        onClick={clearCurrentFilter}
        className="filters__content-header-close"
      >
        <Icon name="close" fill={Greys.DARK_GREY} size={20} />
      </button>
    </Flex>
  );

  const Footer = (
    <Flex className="filters__content-footer">
      <Button
        type={ButtonType.BUTTON}
        label={i18next.t('common_buttons.cancel')}
        variant={ButtonVariant.OUTLINED}
        onClick={clearCurrentFilter}
      />
      <Button
        type={ButtonType.BUTTON}
        label={i18next.t('common_buttons.apply')}
        variant={ButtonVariant.CONTAINED}
        color={ButtonColor.MAIN_COLOR}
        onClick={filter}
      />
    </Flex>
  );

  return (
    <Flex className={`filters__content filters__content__${showFilter}`}>
      {Header}
      <FilterCriteriaList type={type} />
      {Footer}
    </Flex>
  );
}

export default FilterContent;
