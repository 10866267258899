export const getCareplanComponentTypes = () => {
  return [
    {
      key: 1,
      value: 'types.careplanComponentType.freeText',
      icon: 'freetext',
      position: 1,
      label: 'Freetext',
    },
    {
      key: 2,
      value: 'types.careplanComponentType.singleChoice',
      icon: 'singleChoices',
      position: 2,
      label: 'Single choice',
    },
    {
      key: 3,
      value: 'types.careplanComponentType.multipleChoice',
      icon: 'multipleChoices',
      position: 3,
      label: 'Multiple choice',
    },
    {
      key: 4,
      value: 'types.careplanComponentType.numericRange',
      icon: 'numericRange',
      position: 4,
      label: 'Numeric range',
    },
    {
      key: 5,
      value: 'types.careplanComponentType.numericValue',
      icon: 'numericValue',
      position: 5,
      label: 'Numeric value',
    },
    {
      key: 6,
      value: 'types.careplanComponentType.yesno',
      icon: 'yesOrNo',
      position: 6,
      label: 'Yes/No',
    },
    {
      key: 7,
      value: 'types.careplanComponentType.painLocationChart',
      icon: 'painLocationChart',
      position: 7,
      label: 'Pain location chart',
    },
    {
      key: 8,
      value: 'types.careplanComponentType.sortable',
      icon: 'ranking',
      position: 8,
      label: 'Sortable',
    },
    {
      key: 9,
      value: 'types.careplanComponentType.information',
      icon: 'todos',
      position: 9,
      label: 'types.careplanComponentType.information',
    },
    {
      key: 10,
      value: 'types.careplanComponentType.groupDescription',
      icon: 'group',
      position: 10,
      label: 'Group Description',
    },
    {
      key: 11,
      value: 'types.careplanComponentType.uploadFile',
      icon: 'uploadFile',
      position: 11,
      label: 'File request',
    },
    {
      key: 12,
      value: 'types.careplanComponentType.datepicker',
      icon: 'datepicker',
      position: 12,
      label: 'Date request',
    },
    {
      key: 13,
      value: 'types.careplanComponentType.bmi',
      icon: 'bmi',
      position: 13,
      label: 'BMI',
    },
    {
      key: 14,
      value: 'types.careplanComponentType.bloodPressure',
      icon: 'bloodPressure',
      position: 14,
      label: 'Blood Pressure',
    },
  ];
};
