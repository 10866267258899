import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'i18next';
import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import ToolTip from '../../../caro-ui-commonfiles/components/Tooltip/Tooltip';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import Historial from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialLayout';
import HistorialStep from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialStep';
import ICDViewer from '../../../caro-ui-commonfiles/components/ICD/ICDViewer';

import {
  repeatTypesEnum,
  weekdays,
} from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import { getComponent, getAction } from '../../Utils/History';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import { FirebaseContext } from '../../Utils/Firebase/firebase';

const MedicationModalContentConfirmationView = ({
  values,
  startingArrays,
  icds,
  showAssignee,
  isShowView,
}) => {
  const { patientInfo } = useContext(PatientOverviewContext);
  const { hidePatientDemographics } = useContext(FirebaseContext);
  const dateFormat = 'DD MMM YYYY';

  const renderAssigneeInfoPane = () => {
    return (
      <div className="navigation-container">
        <div
          className="circular-picture-sm"
          style={{
            backgroundImage: `url(${patientInfo.profilePicture})`,
          }}
        />
        {!hidePatientDemographics && (
          <div className="care-team-member-name">
            {patientInfo.firstname} {patientInfo.lastname}
          </div>
        )}
      </div>
    );
  };

  const renderOccurrencInfo = () => {
    let recurrenceTimes = null;
    let recurrenceDays = null;
    if (startingArrays.startingTimes.length) {
      recurrenceTimes = startingArrays.startingTimes.map(time => (
        <div key={`startingTime-${time}`} className="content-text">
          {`${time}:00 - ${time + parseInt(values.lifespan)}:00`}
        </div>
      ));
    }

    if (values.recurrenceText && recurrenceTimes) return recurrenceTimes;

    if (
      startingArrays.startingDays.length &&
      values.repeatType.value === repeatTypesEnum.WEEKLY
    ) {
      const weekdaysTranslation = weekdays();
      recurrenceDays = startingArrays.startingDays.map((day, i) => (
        <div key={`weekday-${day}`} className="content-text">
          {i !== 0 && ', '}

          {weekdaysTranslation[day - 1].translation}
        </div>
      ));
    }

    return (
      <>
        {recurrenceTimes && recurrenceTimes}
        {recurrenceDays && (
          <Flex flexDirection="row">
            <span style={{ marginRight: '5px' }}>
              {I18n.t('recurrence.on')}
            </span>
            {recurrenceDays}
          </Flex>
        )}
        {values.endDate && !values.occurrenceCountCheck && (
          <div className="content-text">
            {`${I18n.t('recurrence.until')} ${moment(values.endDate)
              .locale(I18n.language)
              .format(dateFormat)}`}
          </div>
        )}
        {values.occurrenceCountCheck && (
          <div className="content-text">
            {`${I18n.t('recurrence.after')} ${values.occurrenceCount} ${I18n.t(
              'recurrence.occurences'
            )}`}
          </div>
        )}
      </>
    );
  };

  const renderMedicationInfoPanel = () => {
    return (
      <Flex flexDirection="row">
        <div className="info-container">
          <div className="section-container">
            <div>{I18n.t('reminderTypes.medication')}</div>
            <div className="content-text">{values.name}</div>
            {values.sober && (
              <div className="empty-stomach-text">
                {I18n.t('medication.sober')}
              </div>
            )}
          </div>

          <div className="section-container">
            <div>{I18n.t('medication.administrationType')}</div>
            <div className="content-text">
              {values.medicationAdministrationType.label}
            </div>
          </div>

          <div className="section-container">
            <div>{I18n.t('medication.dosage')}</div>

            {values.morning && (
              <div
                className="content-text"
                style={{ display: 'flex', width: '60%' }}
              >
                <div style={{ width: '50%' }}>
                  {I18n.t('medication.morning')}
                </div>
                <div style={{ width: '50%' }}>
                  {isShowView
                    ? values.dosageInMorning
                    : `${values.dosageInMorning} ${values.unit}`}
                </div>
              </div>
            )}
            {values.midday && (
              <div
                className="content-text"
                style={{ display: 'flex', width: '60%' }}
              >
                <div style={{ width: '50%' }}>
                  {I18n.t('medication.midday')}
                </div>
                <div style={{ width: '50%' }}>
                  {isShowView
                    ? values.dosageInAfternoon
                    : `${values.dosageInAfternoon} ${values.unit}`}
                </div>
              </div>
            )}
            {values.evening && (
              <div
                className="content-text"
                style={{ display: 'flex', width: '60%' }}
              >
                <div style={{ width: '50%' }}>
                  {I18n.t('medication.evening')}
                </div>
                <div style={{ width: '50%' }}>
                  {isShowView
                    ? values.dosageInEvening
                    : `${values.dosageInEvening} ${values.unit}`}
                </div>
              </div>
            )}
            {values.night && (
              <div
                className="content-text"
                style={{ display: 'flex', width: '60%' }}
              >
                <div style={{ width: '50%' }}>{I18n.t('medication.night')}</div>
                <div style={{ width: '50%' }}>
                  {isShowView
                    ? values.dosageAtNight
                    : `${values.dosageAtNight} ${values.unit}`}
                </div>
              </div>
            )}
            {values.customTimeChecked && (
              <div
                className="content-text"
                style={{ display: 'flex', width: '60%' }}
              >
                <div style={{ width: '50%' }}>
                  {`${values.customTime.hour}:00`}
                </div>
                <div style={{ width: '50%' }}>
                  {isShowView
                    ? values.dosageSpecificTime
                    : `${values.dosageSpecificTime} ${values.unit}`}
                </div>
              </div>
            )}
          </div>

          {icds.length > 0 && (
            <div className="section-container">
              <div>{I18n.t('common_labels.referenced_icd_code')}</div>
              <div className="content-text content-text-container">
                <ICDViewer icds={icds} shouldShowAll />
              </div>
            </div>
          )}

          <div className="section-container">
            <div>{I18n.t('medication.phaseType')}</div>
            <div className="content-text">{values.phaseType.label}</div>
          </div>

          <div className="section-container">
            <div>{I18n.t('recurrence.startsOn')}</div>
            <div className="content-text content-text-container">
              {moment(values.startDate)
                .locale(I18n.language)
                .format(dateFormat)}
            </div>
          </div>

          <div className="section-container">
            <div>{I18n.t('recurrence.title')}</div>
            <div className="content-text content-text-container">
              {renderOccurrencInfo()}
            </div>
          </div>

          {values.recurrenceText && (
            <div className="section-container">
              <div>{I18n.t('recurrence.title')}</div>
              <div className="content-text content-text-container">
                {values.recurrenceText}
              </div>
            </div>
          )}

          <div className="section-container">
            <div>{I18n.t('recurrence.lifespan')}</div>
            <div className="content-text content-text-container">
              {`${values.lifespan} ${values.lifespanUnit.label}`}
            </div>
          </div>
        </div>
      </Flex>
    );
  };

  const renderMedicationHistory = () =>
    values.ownershipLogs.map(log => (
      <Historial>
        <HistorialStep key={`log-${log.timeOfAction}`}>
          <div className="show-patient-history">
            <span>
              {I18n.t('history.assignment', {
                user: log.user.username,
                action: getAction(log.action).toLowerCase(),
                component: getComponent(log.component),
              })}
            </span>
            <span className="show-patient__history-date">
              {`${moment(log.timeOfAction)
                .locale(I18n.language)
                .format(dateFormat)} - ${moment(log.timeOfAction).format(
                'HH:mm'
              )}`}
            </span>
          </div>
        </HistorialStep>
      </Historial>
    ));

  return (
    <ModalForm>
      {showAssignee && (
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <Box padding="30px 20px 0 40px">
            <div className="section-container-nav">
              <div>{I18n.t('patientOverview.assignedTo')}</div>
            </div>
            {renderAssigneeInfoPane()}
          </Box>
        </ModalSection>
      )}

      {!showAssignee && (
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <div className="history-label">
            <Label text={I18n.t('common_labels.history')} />
            <div className="show-assignment__historial-wrapper">
              {renderMedicationHistory()}
            </div>
          </div>
        </ModalSection>
      )}

      <ModalSection variant={MODAL_VARIANT.COL_W70}>
        {renderMedicationInfoPanel()}
      </ModalSection>
    </ModalForm>
  );
};

MedicationModalContentConfirmationView.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  startingArrays: PropTypes.objectOf(PropTypes.array).isRequired,
  icds: PropTypes.arrayOf(PropTypes.object),
  showAssignee: PropTypes.bool,
  isShowView: PropTypes.bool,
};

MedicationModalContentConfirmationView.defaultProps = {
  icds: [],
  showAssignee: true,
  isShowView: false,
};

export default memo(MedicationModalContentConfirmationView);
