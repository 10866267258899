import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Flex,
  Checkbox,
  Tag,
  Icon,
  Label,
  TextInput,
} from '@ui-common-files/components';
import {
  CheckBoxRadioVariants,
  PrimaryColor,
  TagsType,
  ActionColors,
} from '@ui-common-files/utils';
import {
  renderFormattedJumpData,
  renderTagsForChoices,
} from '../LogicJump/logicJumpTags';
import { nonMedical } from '../../../../config';

export default function AnswerBloodPressureAndBMIBase({
  component,
  isEvaluating,
  hasSubScores,
  inputOption1,
  inputOption2,
  optionTranslation,
  getIsChecked,
  answerBMI = 0,
  isBMI = false,
}) {
  const { t } = useTranslation();
  let parsedOptionWeights = [];
  if (component.isScoreEnabled) {
    parsedOptionWeights = JSON.parse(component.optionWeights).weights;
  }
  const allZeroWeight = parsedOptionWeights.every(item => item === 0);
  const thresholdIndexs =
    component.componentThresholds.length &&
    component.componentThresholds[0].optionJson.optionIndex;
  const logicJumpDataForTags = renderFormattedJumpData(component);

  const originList = component.optionJson.options.map((option, i) => {
    const isChecked = getIsChecked(option);
    const showWeight = component.isScoreEnabled && !allZeroWeight;
    let optionWeight = parsedOptionWeights[i];
    if (showWeight && (optionWeight === null || optionWeight === 'null')) {
      optionWeight = 0;
    }

    return (
      <Box margin="5px 0">
        <Flex>
          <Checkbox
            id={option}
            label={t(`${optionTranslation}.${option}`)}
            variant={CheckBoxRadioVariants.NON_EDITABLE}
            checked={isChecked}
            handleOnChange={() => {}}
            disableActionOnly
          />
          {logicJumpDataForTags.isJumpSet &&
            logicJumpDataForTags.logicJumpCollection?.length > 0 &&
            logicJumpDataForTags.logicJumpCollection?.[i] &&
            renderTagsForChoices(logicJumpDataForTags, i)}
          {thresholdIndexs.length > 0 &&
            thresholdIndexs.includes(i) &&
            (nonMedical === false || nonMedical === 'false') &&
            isChecked && (
              <Box
                className="thresholdIconContainer"
                otherStyles={{ paddingTop: 6 }}
              >
                <Icon
                  name="threshold"
                  size={24}
                  fill={ActionColors.THRESHOLD}
                />
              </Box>
            )}
          {showWeight && (isEvaluating || hasSubScores) ? (
            <Box
              className="threshold-component__name"
              otherStyles={{ flex: 0 }}
            >
              <Tag
                text={`${t('analytic_view.Score')} ${optionWeight}`}
                fill={PrimaryColor.MAIN_COLOR}
                type={TagsType.SQUARED}
              />
            </Box>
          ) : null}
        </Flex>
      </Box>
    );
  });

  return (
    <Flex otherStyles={{ width: '100%' }}>
      <Box margin="5px 0">
        <Flex justifyContent="space-between">
          <Flex>
            <Label text={`${t(inputOption1.label)}:`} />
            <Flex justifyContent="end">
              <Box otherStyles={{ width: '75px', marginRight: '5px' }}>
                <TextInput
                  value={inputOption1.value.toString()}
                  name={inputOption1.name}
                  placeholder="0"
                  handleOnChange={() => {}}
                />
              </Box>
              <Label text={`${t(inputOption1.unit)}`} />
            </Flex>
          </Flex>
          <Flex>
            <Label text={`${t(inputOption2.label)}:`} />
            <Flex justifyContent="end">
              <Box otherStyles={{ width: '75px', marginRight: '5px' }}>
                <TextInput
                  value={inputOption2.value.toString()}
                  name={inputOption2.name}
                  placeholder="0"
                  handleOnChange={() => {}}
                />
              </Box>
              <Label text={`${t(inputOption2.unit)}`} />
            </Flex>
          </Flex>
          {isBMI && (
            <Flex>
              <Label text="BMI:" />
              <Flex justifyContent="end">
                <Box otherStyles={{ width: '75px' }}>
                  <TextInput
                    value={answerBMI.toString()}
                    name="bmi"
                    placeholder="0"
                    handleOnChange={() => {}}
                  />
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
        {originList}
      </Box>
    </Flex>
  );
}
