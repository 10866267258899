import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../../../../caro-ui-commonfiles/components/Icon/Icon';

import { Greys } from '../../../../../../caro-ui-commonfiles/utils/colors';
import { UpdatePasswordValidationProps } from './UpdatePasswordValidation.type';

import styles from './UpdatePasswordValidation.module.scss';

function UpdatePasswordValidation({
  validation,
  text,
  message,
}: UpdatePasswordValidationProps) {
  const { t } = useTranslation();
  const active = validation.test(text);
  return (
    <Flex className={styles.container}>
      <Box
        className={`${styles.container__checkCircle} ${
          active && styles.container__checkCircle__active
        }`}
      >
        <Icon name="Tick" size={8} fill={Greys.WHITE} />
      </Box>
      <Box
        className={`${styles.container__message} ${
          active && styles.container__message__active
        }`}
      >
        {t(`admin_view.password_validation${message}`)}
      </Box>
    </Flex>
  );
}

export default UpdatePasswordValidation;
