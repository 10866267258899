/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import { useWindowWidth, MEDIA_QUERIES } from '@ui-common-files/utils';

const BubbleImage = ({ note, blobURL }) => {
  const windowWidth = useWindowWidth(window.innerWidth);
  return (
    <a href={blobURL} target="_blank" rel="noopener noreferrer">
      {blobURL && (
        <img
          src={blobURL}
          alt={note}
          height="auto"
          width={windowWidth <= MEDIA_QUERIES.SCREEN_SM_IPAD ? '300px' : '100%'}
          className="note-bubble__image"
        />)}
    </a>
  );
};

BubbleImage.propTypes = {
  note: PropTypes.string.isRequired,
  blobURL: PropTypes.string.isRequired,
};

export default React.memo(BubbleImage);
