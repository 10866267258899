import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ComponentWithLabel from '../AtomWithLabel/AtomWithLabel';
import DatePicker from '../../DateTimePicker/Datepicker';
import TimePicker from '../../DateTimePicker/TimePicker';
import Dropdown from '../../Dropdown/Dropdown';
import Checkbox from '../../Checkbox/Checkbox';
import RadioButton from '../../RadioButton/RadioButton';
import NumberInput from '../../NumberInput/NumberInput';
import Flex from '../../Flex/Flex';
import Box from '../../Box/Box';
import WeeklyRecurrence from './WeeklyRecurrence';
import CheckboxRadio from '../../../utils/CheckboxRadio';
import { ComponentTypes } from '../../../utils/componentTypes';
import RecurrenceValidation from '../../../utils/Recurrence/RecurrenceValidation';

import useAvailableLifespan from '../../../utils/hooks/useAvailableLifespan';

const RecurrenceComponent = ({
  renderPhaseType,
  componentType,
  onValueChange,
  values,
  isStartDatePickerDisabled,
  validateRecurrence,
  hasErrors,
  setHasErrors,
  setStartingArrays,
  comesFromTemplateSettings,
  isDisabled,
}) => {
  const {
    repeatTypes,
    lifespanUnits,
    generateRepeatInterval,
  } = require('../../../utils/Recurrence/recurrenceUtils');
  const { t } = useTranslation();
  const lifespanUnitsObj = lifespanUnits();
  const lifespanUnitStartIndex = useMemo(() => 0, [componentType]);
  const availableLifespanUnits = useAvailableLifespan(
    values.repeatType,
    lifespanUnitStartIndex,
    values.lifespanUnit,
    value => onValueChange(value, 'lifespanUnit')
  );

  const checkWeekdayArray = () => {
    if (values.weeklyRecurrence.indexOf(true) < 0) {
      onValueChange(
        (values.weeklyRecurrence[values.startDate.getDay() - 1] = true),
        'weeklyRecurrence'
      );
    }
  };

  useEffect(() => {
    if (values.customTime && componentType === ComponentTypes.CAREPLAN)
      onValueChange(
        moment(values.startDate)
          .set('hour', values.customTime.hour)
          .set('minute', values.customTime.minute)
          .toDate(),
        'startDate'
      );
  }, [values.customTime]);

  useEffect(() => {
    if (validateRecurrence) {
      RecurrenceValidation(
        values,
        setHasErrors,
        hasErrors,
        setStartingArrays,
        comesFromTemplateSettings
      );
    }
  }, [validateRecurrence]);

  const renderLifespanUnit = () => (
    <Box flex="1" margin={hasErrors.lifespan ? '1px 0 0 0' : null}>
      {componentType !== ComponentTypes.MEDICATION && (
        <Dropdown
          value={values.lifespanUnit}
          options={availableLifespanUnits}
          isDisabled={isDisabled}
          placeholder="Lifespan"
          handleOnChange={event => {
            onValueChange(event, 'lifespanUnit');
          }}
        />
      )}
    </Box>
  );

  const renderLifespanDuration = () => (
    <NumberInput
      id="lifespanDuration"
      name="lifespanDuration"
      min={1}
      max={99}
      step={1}
      placeholder=""
      isDisabled={isDisabled}
      value={values.lifespan}
      changeValue={value => {
        onValueChange(value, 'lifespan');
      }}
      hasError={hasErrors.lifespan}
      validationMessage={t(hasErrors.lifespan)}
    />
  );

  const renderTimePicker = () => (
    <TimePicker
      isDisabled={isDisabled}
      value={values.customTime}
      isMinuteDisabled={false}
      minuteIncrement={15}
      getTimeStamp={time => {
        onValueChange(time, 'customTime');
      }}
    />
  );

  const renderOccurrenceCount = () => (
    <Box flex=" 1" maxWidth="50%">
      <NumberInput
        id="occurrenceCount"
        name="occurrenceCount"
        min={1}
        max={99}
        step={1}
        placeholder=""
        isDisabled={!values.occurrenceCountCheck || isDisabled}
        value={values.occurrenceCount}
        changeValue={value => {
          onValueChange(value, 'occurrenceCount');
        }}
        hasError={hasErrors.occurrenceCount}
        validationMessage="There must be at least one occurrence"
      />
    </Box>
  );

  return (
    <>
      {componentType === ComponentTypes.CAREPLANTEMPLATE && (
        <>
          <Flex>
            <Box width="23%">
              <ComponentWithLabel
                id="phaseType"
                htmlFor="phaseType"
                labelText={t('task.phaseType')}
              />
            </Box>
            <Box flex="1">{renderPhaseType()}</Box>
          </Flex>
          <Flex alignItems="baseline" otherStyles={{ marginBottom: '-9px' }}>
            <Box width="23%">
              <ComponentWithLabel
                id="careplanStartTime"
                htmlFor="careplanStartTime"
                labelText={t('newCareplan_view.careplanStartTime')}
              />
            </Box>

            <Box margin="10px 0 0 0" width="146px">
              {renderTimePicker()}
            </Box>
          </Flex>
        </>
      )}
      {componentType !== ComponentTypes.CAREPLANTEMPLATE && (
        <ComponentWithLabel
          id="startDate"
          htmlFor="startDate"
          labelText={t('recurrence.startsFrom')}
        >
          {componentType !== ComponentTypes.CAREPLAN &&
            componentType !== ComponentTypes.CAREPLANTEMPLATE && (
              <DatePicker
                date={values.startDate}
                minDate={new Date()}
                placeholder={t('medication.startDate')}
                handleDate={date => {
                  onValueChange(date, 'startDate');
                }}
                hasError={hasErrors.startDate}
                validationMessage={t('recurrence.startDateError')}
                shouldShowValidationMessage={false}
                disabled={isStartDatePickerDisabled || isDisabled}
              />
            )}
          {componentType === ComponentTypes.CAREPLAN && (
            <Flex flexDirection="row" justifyContent="space-between">
              <Box flex="1" margin="0 10px 0 0">
                <DatePicker
                  date={values.startDate}
                  minDate={new Date()}
                  placeholder={t('recurrence.startsFrom')}
                  handleDate={date => {
                    onValueChange(date, 'startDate');
                  }}
                  hasError={hasErrors.startDate}
                  validationMessage={t('recurrence.startDateError')}
                  shouldShowValidationMessage
                  disabled={isStartDatePickerDisabled || isDisabled}
                />
              </Box>

              <Box flex="1">{renderTimePicker()}</Box>
            </Flex>
          )}
          {componentType !== ComponentTypes.MEDICATION &&
            componentType !== ComponentTypes.CAREPLAN &&
            componentType !== ComponentTypes.CAREPLANTEMPLATE && (
              <>
                <Flex>
                  <Box width="50%">
                    <Box margin="10px 0 0 0">
                      <Checkbox
                        id="morning"
                        name="morning"
                        label={t('medication.morning')}
                        variant={CheckboxRadio.Default}
                        checked={values.morning}
                        isDisabled={isDisabled}
                        handleOnChange={e => {
                          onValueChange(e.target.checked, 'morning');
                        }}
                      />
                    </Box>
                  </Box>
                  <Box width="50%">
                    <Box margin="10px 0 0 0">
                      <Checkbox
                        id="midday"
                        name="midday"
                        label={t('medication.midday')}
                        variant={CheckboxRadio.Default}
                        checked={values.midday}
                        isDisabled={isDisabled}
                        handleOnChange={e => {
                          onValueChange(e.target.checked, 'midday');
                        }}
                      />
                    </Box>
                  </Box>
                </Flex>
                <Flex>
                  <Box width="50%">
                    <Box margin="10px 0 0 0">
                      <Checkbox
                        id="evening"
                        name="evening"
                        label={t('medication.evening')}
                        variant={CheckboxRadio.Default}
                        checked={values.evening}
                        isDisabled={isDisabled}
                        handleOnChange={e => {
                          onValueChange(e.target.checked, 'evening');
                        }}
                      />
                    </Box>
                  </Box>
                  <Box width="50%">
                    <Box margin="10px 0 0 0">
                      <Checkbox
                        id="night"
                        name="night"
                        label={t('medication.night')}
                        variant={CheckboxRadio.Default}
                        checked={values.night}
                        isDisabled={isDisabled}
                        handleOnChange={e => {
                          onValueChange(e.target.checked, 'night');
                        }}
                      />
                    </Box>
                  </Box>
                </Flex>
                <Flex>
                  <Box width="50%">
                    <Box margin="10px 0 0 0">
                      <Checkbox
                        id="customTime"
                        name="customTime"
                        label={t('medication.customTime')}
                        variant={CheckboxRadio.Default}
                        checked={values.customTimeChecked}
                        isDisabled={isDisabled}
                        handleOnChange={e => {
                          onValueChange(e.target.checked, 'customTimeChecked');
                        }}
                      />
                    </Box>
                  </Box>
                  <Box width="50%">
                    <Box margin="10px 0 0 0">
                      <Box width="100%">
                        <TimePicker
                          isDisabled={isDisabled}
                          value={values.customTime}
                          isMinuteDisabled
                          getTimeStamp={time => {
                            onValueChange(time, 'customTime');
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              </>
            )}
          {componentType !== ComponentTypes.MEDICATION &&
            componentType !== ComponentTypes.CAREPLAN &&
            componentType !== ComponentTypes.CAREPLANTEMPLATE &&
            hasErrors.recurrence && (
              <div className="recurrence__validation-message">
                {t('recurrence.warningNoTimeOfDaySelected')}
              </div>
            )}
        </ComponentWithLabel>
      )}

      {componentType !== ComponentTypes.CAREPLANTEMPLATE && (
        <ComponentWithLabel
          idToggleButton="repeat"
          htmlFor="repeat"
          labelText={t('common_labels.label_recurrence')}
          isDisabled={isDisabled}
        >
          <Box>
            <Flex
              flexDirection="row"
              alignItems="center"
              otherStyles={{ marginBottom: '10px' }}
            >
              <Box flex="1">
                <Dropdown
                  value={values.repeatType}
                  options={repeatTypes()}
                  isDisabled={isDisabled}
                  placeholder="Repeat"
                  handleOnChange={event => {
                    if (event.value === 2) checkWeekdayArray();
                    onValueChange(event, 'repeatType');
                  }}
                />
              </Box>
              <Box margin="0 10px" className="recurrence-text">
                {t('recurrence.every')}
              </Box>
              <Box flex="1">
                <Dropdown
                  value={values.repeatInterval}
                  options={generateRepeatInterval()}
                  isDisabled={false}
                  placeholder="Interval"
                  handleOnChange={event => {
                    onValueChange(event, 'repeatInterval');
                  }}
                />
              </Box>
              <Box margin="0 10px" className="recurrence-text">
                {values.repeatType
                  ? lifespanUnitsObj[values.repeatType.value].label
                  : lifespanUnitsObj[0].label}
              </Box>
            </Flex>
            {values.repeatType && values.repeatType.value === 2 && (
              <WeeklyRecurrence
                isDisabled={isDisabled}
                startDateWeekday={values.startDate.getDay()}
                weeklyRecurrence={values.weeklyRecurrence}
                hasErrors={hasErrors.recurrenceRepeat}
                selectedWeekDaysError={hasErrors.selectedWeekDaysError}
                handleOnChange={event =>
                  onValueChange(event, 'weeklyRecurrence')
                }
              />
            )}
          </Box>
        </ComponentWithLabel>
      )}

      {componentType === ComponentTypes.CAREPLANTEMPLATE && (
        <Flex alignItems="baseline">
          <Box width="30%">
            <ComponentWithLabel
              idToggleButton="repeat"
              htmlFor="repeat"
              labelText={t('common_labels.label_recurrence')}
              isDisabled={isDisabled}
            />
          </Box>

          <Box width="100%">
            <Flex
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              otherStyles={{
                marginBottom:
                  values.repeatType && values.repeatType.value === 2
                    ? null
                    : '28px',
                width: '100%',
              }}
            >
              <Box
                margin="20px 0 0 0"
                otherStyles={{ width: '146px', flexShrink: 0 }}
              >
                <Dropdown
                  value={values.repeatType}
                  options={repeatTypes()}
                  isDisabled={isDisabled}
                  placeholder="Repeat"
                  handleOnChange={event => {
                    if (event.value === 2) checkWeekdayArray();
                    onValueChange(event, 'repeatType');
                  }}
                />
              </Box>

              <Box margin="20px 10px 0" className="recurrence-text">
                {t('recurrence.every')}
              </Box>

              <Flex alignItems="center" otherStyles={{ marginTop: '20px' }}>
                <Box flex="0 0 70px">
                  <Dropdown
                    value={values.repeatInterval}
                    options={generateRepeatInterval()}
                    isDisabled={false}
                    placeholder="Interval"
                    handleOnChange={event => {
                      onValueChange(event, 'repeatInterval');
                    }}
                  />
                </Box>

                <Box margin="0 10px" className="recurrence-text">
                  {values.repeatType
                    ? lifespanUnitsObj[values.repeatType.value].label
                    : lifespanUnitsObj[0].label}
                </Box>
              </Flex>
            </Flex>

            {values.repeatType && values.repeatType.value === 2 && (
              <Box margin="0 0 28px 0">
                <WeeklyRecurrence
                  isDisabled={isDisabled}
                  startDateWeekday={values.startDate.getDay()}
                  weeklyRecurrence={values.weeklyRecurrence}
                  hasErrors={hasErrors.recurrenceRepeat}
                  selectedWeekDaysError={hasErrors.selectedWeekDaysError}
                  handleOnChange={event =>
                    onValueChange(event, 'weeklyRecurrence')
                  }
                  componentType={ComponentTypes.CAREPLANTEMPLATE}
                />
              </Box>
            )}
          </Box>
        </Flex>
      )}

      {componentType === ComponentTypes.CAREPLANTEMPLATE && (
        <Flex otherStyles={{ marginBottom: '10px' }}>
          <Box width="23%">
            <ComponentWithLabel
              idToggleButton="recurrence"
              htmlFor="recurrence"
              labelText={t('recurrence.occurences')}
            />
          </Box>

          <Box>
            <Flex flexDirection="row" alignItems="center">
              <div
                style={
                  hasErrors.occurrenceCount ? { marginBottom: '22px' } : null
                }
              />
              {renderOccurrenceCount()}
            </Flex>
          </Box>
        </Flex>
      )}

      {componentType !== ComponentTypes.CAREPLANTEMPLATE && (
        <ComponentWithLabel
          idToggleButton="recurrence"
          htmlFor="recurrence"
          labelText={t('recurrence.occurences')}
        >
          <Box>
            <Flex flexDirection="row" alignItems="center">
              <div
                style={
                  hasErrors.occurrenceCount ? { marginBottom: '22px' } : null
                }
              >
                {componentType !== ComponentTypes.CAREPLANTEMPLATE && (
                  <RadioButton
                    id="occurrenceCountCheck"
                    name="occurrenceCountCheck"
                    isDisabled={isDisabled}
                    variant={CheckboxRadio.Default}
                    checked={values.occurrenceCountCheck}
                    handleOnChange={event => {
                      onValueChange(
                        event.target.checked,
                        'occurrenceCountCheck'
                      );
                    }}
                  />
                )}
              </div>
              {renderOccurrenceCount()}
            </Flex>
            {componentType !== ComponentTypes.CAREPLANTEMPLATE && (
              <Flex
                flexDirection="row"
                alignItems="center"
                otherStyles={{ margin: '10px 0 0 0' }}
              >
                <RadioButton
                  id="endDateCheck"
                  name="endDateCheck"
                  isDisabled={isDisabled}
                  variant={CheckboxRadio.Default}
                  checked={!values.occurrenceCountCheck}
                  handleOnChange={event => {
                    onValueChange(
                      !event.target.checked,
                      'occurrenceCountCheck'
                    );
                  }}
                />
                <Box flex="1">
                  <DatePicker
                    date={values.endDate}
                    minDate={new Date()}
                    placeholder={t('recurrence.endsOn')}
                    handleDate={date => {
                      onValueChange(date, 'endDate');
                    }}
                    disabled={values.occurrenceCountCheck || isDisabled}
                    hasError={hasErrors.endDate}
                    shouldShowValidationMessage
                    validationMessage={t('recurrence.endDateError')}
                  />
                </Box>
              </Flex>
            )}
          </Box>
        </ComponentWithLabel>
      )}

      {componentType === ComponentTypes.MEDICATION &&
        componentType !== ComponentTypes.CAREPLAN &&
        componentType !== ComponentTypes.CAREPLANTEMPLATE &&
        values.repeatType &&
        values.repeatType.value === 1 && (
          <ComponentWithLabel
            id="lifespan"
            htmlFor="lifespan"
            labelText={t('recurrence.lifespanHoursOnly')}
          >
            <Flex flexDirection="row" alignItems="center">
              <Box flex="1" margin="0 10px 0 0">
                {renderLifespanDuration()}
              </Box>
              <Box flex="1">
                {componentType !== ComponentTypes.MEDICATION && (
                  <Dropdown
                    value={values.lifespanUnit}
                    options={availableLifespanUnits}
                    isDisabled={isDisabled}
                    placeholder="Lifespan"
                    handleOnChange={event => {
                      onValueChange(event, 'lifespanUnit');
                    }}
                  />
                )}
              </Box>
            </Flex>
          </ComponentWithLabel>
        )}

      {componentType !== ComponentTypes.MEDICATION &&
        componentType !== ComponentTypes.CAREPLANTEMPLATE && (
          <ComponentWithLabel
            idToggleButton="lifespan"
            htmlFor="lifespan"
            labelText={t('recurrence.lifespan')}
          >
            <Flex
              flexDirection="row"
              alignItems={hasErrors.lifespan ? null : 'center'}
            >
              <Box
                flex="1"
                maxWidth={comesFromTemplateSettings ? '58%' : null}
                margin="0 10px 0 0"
              >
                {renderLifespanDuration()}
              </Box>
              {renderLifespanUnit()}
            </Flex>
          </ComponentWithLabel>
        )}

      {componentType === ComponentTypes.CAREPLANTEMPLATE && (
        <Flex alignItems="baseline">
          <Box width="23%">
            <ComponentWithLabel
              idToggleButton="lifespan"
              htmlFor="lifespan"
              labelText={t('recurrence.lifespan')}
            />
          </Box>
          <Flex
            flexDirection="row"
            alignItems={hasErrors.lifespan ? null : 'center'}
          >
            <Box flex="1" maxWidth="41%" margin="0 10px 0 0">
              {renderLifespanDuration()}
            </Box>
            {renderLifespanUnit()}
          </Flex>
        </Flex>
      )}
    </>
  );
};

RecurrenceComponent.propTypes = {
  componentType: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  validateRecurrence: PropTypes.bool.isRequired,
  hasErrors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  setHasErrors: PropTypes.func.isRequired,
  setStartingArrays: PropTypes.func.isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.array,
      PropTypes.number,
      PropTypes.bool,
    ])
  ),
  isStartDatePickerDisabled: PropTypes.bool,
  comesFromTemplateSettings: PropTypes.bool,
};

RecurrenceComponent.defaultProps = {
  values: {},
  isStartDatePickerDisabled: false,
  comesFromTemplateSettings: false,
};

export default RecurrenceComponent;
