import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Box, Button } from '@ui-common-files/components';
import { ButtonType, ButtonVariant } from '@ui-common-files/utils';

import CustomLoader from 'src/Views/Login/CustomLoader';

import usePathWayReviewModalFooterService from './PathWayReviewModalFooter.service';

const PathWayReviewModalFooter: React.FC = (
  isFetchingModalContent,
  currentStep,
  setCurrentStep,
  carepathwayName,
  selectedCareTasks,
  disableButton,
  shouldDisplayComponentConfiguration
) => {
  const { t } = useTranslation();
  const {
    isLoading,
    setPathWayReview,
    submitCarePathWayTemplate,
    pathWayReview,
  } = usePathWayReviewModalFooterService();

  return (
    <Flex
      justifyContent={
        shouldDisplayComponentConfiguration ? 'space-between' : 'flex-end'
      }
      otherStyles={{ width: '100%', height: 36 }}
    >
      {shouldDisplayComponentConfiguration && (
        <Box>
          <Button
            label={
              selectedCareTasks.length > 0 && currentStep === 2
                ? t('common_buttons.back')
                : t('common_buttons.cancel')
            }
            type={ButtonType.BUTTON}
            form="form-create-carepathway"
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              if (selectedCareTasks.length > 0 && currentStep === 2) {
                setCurrentStep(1);
              } else {
                setPathWayReview({
                  title: carepathwayName,
                  id: null,
                  isOpen: false,
                });
              }
            }}
          />
        </Box>
      )}
      <Flex>
        {isLoading || isFetchingModalContent ? (
          <CustomLoader />
        ) : (
          <Button
            label={
              currentStep === 1
                ? t('common_buttons.next')
                : pathWayReview.shouldCloseBuilder
                ? t('common_buttons.saveAndClose')
                : t('common_buttons.save')
            }
            type={ButtonType.SUBMIT}
            form="form-create-carepathway"
            variant={ButtonVariant.CONTAINED}
            disabled={disableButton}
            onClick={() => {
              if (currentStep === 1) {
                setCurrentStep(2);
              } else {
                submitCarePathWayTemplate();
              }
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
export default PathWayReviewModalFooter;
