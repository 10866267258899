import roles from '../roles.json';

const getMentionDetails = mention => {
  let name;
  let tagTypeId;
  if (mention.type === 'user') {
    name = `${mention.firstname} ${mention.lastname}`;
    tagTypeId = 1;
  } else if (mention.type === 'role') {
    name = mention.name;
    tagTypeId = 2;
  } else {
    name = `(${mention.name}) ${roles.EXTERNAL_HCP}`;
    tagTypeId = 3;
  }
  return { name, tagTypeId };
};
export default getMentionDetails;
