import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Flex,
  Dropdown,
  IcdSelector,
  ComponentWithLabel,
} from '@ui-common-files/components';

import { fetchIcdsOrOpsData } from '@utils/PatientOverview/patientOverviewHelper';

import useCarePathWaySettingsService from '../PanelSidebar/CarePathWaySettings.service';

import './CarePathWaySettings.styles.scss';

const CarePathWaySettings: React.FC = () => {
  const {
    settings,
    icdsHasError,
    onValueChange,
    allAppointmentTypes,
    appointmentTypeHasError,
  } = useCarePathWaySettingsService();

  const { t } = useTranslation();

  return (
    <>
      <Flex className="care-task-list_options">
        <Box className="care-task-list_options-title">
          {t('steps.carepathway_settings')}
        </Box>
      </Flex>
      <Flex flexDirection="column" className="pathway-builder_settings_wrapper">
        <Box margin="10px 0">
          <ComponentWithLabel
            id="pathwayAppType"
            htmlFor="pathwayAppType"
            labelText={`* ${t('common_labels.label_referenceType')}`}
          >
            <Dropdown
              value={settings.appointmentType}
              options={allAppointmentTypes}
              placeholder={t('common_labels.add_referenceType')}
              handleOnChange={event => {
                onValueChange(event, 'appointmentType');
              }}
              hasError={appointmentTypeHasError}
            />
            {appointmentTypeHasError && (
              <span className="patients__phone-field error">
                {t('carePathway_builder.errors.select_reference_type')}
              </span>
            )}
          </ComponentWithLabel>
        </Box>

        <Box flex="1">
          <ComponentWithLabel
            id="icds"
            htmlFor="icds"
            labelText={`* ${t('task.ICDReference')}`}
          >
            <IcdSelector
              placeholder={t('newCareplan_view.placeholderICDReference')}
              maxLengthItem={5}
              onSelect={selected => {
                if (selected !== settings.icds) {
                  onValueChange(selected, 'icds');
                }
              }}
              fetchData={term => {
                return fetchIcdsOrOpsData(term, true);
              }}
              icdSelected={settings.icds}
              hasError={icdsHasError}
            />
            {icdsHasError && (
              <span className="patients__phone-field error">
                {t('carePathway_builder.errors.select_icd')}
              </span>
            )}
          </ComponentWithLabel>
        </Box>
      </Flex>
    </>
  );
};

export default CarePathWaySettings;
