import React from 'react';
import PropTypes from 'prop-types';

const StepSeparator = ({ isCurrent }) => {
  return (
    <div
      className={
        isCurrent ? 'stepper__separator current' : 'stepper__separator'
      }
    />
  );
};

StepSeparator.propTypes = { isCurrent: PropTypes.bool.isRequired };

export default StepSeparator;
