import React from 'react';

import { useShallow } from 'zustand/react/shallow';

import { Modal } from '@ui-common-files/components';
import { HideFlashMessage } from '@global/Services';

import PathWayReviewModalFooter from './PathWayReviewModalFooter/PathWayReviewModalFooter';
import CarePathwayHeader from './PathWayReviewHeader';
import usePathWayReviewModalService, {
  usePathWayReviewData,
} from './PathWayReviewModal.service';
import { useCarePathWayBuilderStore } from '../../CarePathWayBuilder.store';
import PathWayReviewModalContent from './PathWayReviewModalContent';

const PathWayReviewModal: React.FC = () => {
  const { nodes, settings, pathWayReview, setPathWayReview } =
    useCarePathWayBuilderStore(
      useShallow(state => ({
        nodes: state.nodes,
        settings: state.settings,
        pathWayReview: state.pathWayReview,
        setPathWayReview: state.setPathWayReview,
      }))
    );
  const { selectedCareTasks, isLoading } = usePathWayReviewData(nodes);

  const {
    usWarningCheckboxChecked,
    consentChecked,
    currentStep,
    setUsWarningCheckboxChecked,
    setCurrentStep,
    shouldDisplayComponentConfiguration,
  } = usePathWayReviewModalService(selectedCareTasks);
  const carepathwayName = settings.carePathwayName;

  return (
    <Modal
      title={pathWayReview.title}
      contentComponent={
        <PathWayReviewModalContent
          isLoading={isLoading}
          selectedCareTasks={selectedCareTasks}
          currentStep={currentStep}
          consentChecked={consentChecked}
          usWarningCheckboxChecked={usWarningCheckboxChecked}
          setUsWarningCheckboxChecked={setUsWarningCheckboxChecked}
        />
      }
      footerComponent={PathWayReviewModalFooter(
        isLoading,
        currentStep,
        setCurrentStep,
        carepathwayName,
        selectedCareTasks,
        consentChecked,
        shouldDisplayComponentConfiguration
      )}
      openModal={pathWayReview.isOpen}
      onClose={() => {
        setPathWayReview({
          title: null,
          id: null,
          isOpen: false,
        });
      }}
      headerComponent={CarePathwayHeader(
        currentStep,
        isLoading,
        shouldDisplayComponentConfiguration
      )}
      hideFlashMessage={HideFlashMessage}
    />
  );
};

export default PathWayReviewModal;
