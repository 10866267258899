import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useAbortController, useWindowDimensions } from '@global/Hooks';
import { useDebounce } from '@ui-common-files/utils';
import { navigation } from '@utils';
import {
  getCareplanReviewToAnswer,
  getReviewAssessments,
} from './ReviewAssessments.api';
import {
  AssessmentsTableColumns,
  convertResponseToRows,
} from './ReviewAssessments.util';
import { OnDropdownSelection, ReviewRow } from './ReviewAssessments.type';

export default function useReviewAssessmentsService() {
  const [initialReportsRequest, setInitialReportsRequest] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<ReviewRow[]>([]);
  const history = useHistory();
  const [pagination, setPagination] = useState({
    pages: 0,
    totalRecord: 0,
    size: 10,
    currentPage: 0,
  });
  const [search, setSearch] = useState('');
  const [assessments, setAssessments] = useState([]);
  const { t } = useTranslation();
  const debouncedSearch = useDebounce(search);
  const { getAbortController } = useAbortController();
  const { signal } = getAbortController();
  const { width } = useWindowDimensions();

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = e =>
    setSearch(e.target.value);

  const onDropdownSelection: OnDropdownSelection =
    (index, patientId, assetId, reviewAssessmentTemplateId) =>
    ({ value, label }) => {
      setRows(oldRows => {
        const newRows = [...oldRows];
        newRows[index].template = {
          index,
          value,
          label,
          patientId,
          assetId,
          reviewAssessmentTemplateId,
        };
        return newRows;
      });
    };

  const onReviewReportButtonClick = async (id, assetId, caretaskReviewId) => {
    const response = await getCareplanReviewToAnswer(
      assetId,
      id,
      caretaskReviewId
    );
    if (!response) return;
    history.push({
      pathname: `/${navigation.PATIENT_OVERVIEW}/${id}`,
      state: {
        isReportReview: true,
        assetId,
        occurrenceId: response.data?.occurrenceId,
        careplanId: response.data?.id,
        caretaskReviewName: response.data?.name,
      },
    });
  };

  const columns = AssessmentsTableColumns({
    assessments,
    onDropdownSelection,
    t,
    onReviewReportButtonClick,
    width,
  });

  const tableProps = {
    previousText: t('datatable.previous'),
    nextText: t('datatable.next'),
    noDataText: t('common_labels.label_noData'),
    pageText: t('datatable.showing'),
    ofText: t('datatable.of'),
    rowsText: t('datatable.entries'),
    rowsSelectorText: t('datatable.recordsPerPage'),
  };

  const fetchData = useCallback(
    async (currentPage = pagination.currentPage, size = pagination.size) => {
      const maxAllowedPages = Math.ceil(pagination.totalRecord / size) - 1;
      if (size !== pagination.size && currentPage > maxAllowedPages) {
        setPagination(oldPagination => ({
          ...oldPagination,
          currentPage: maxAllowedPages,
          size,
        }));
        return;
      }
      setIsLoading(true);
      const response = await getReviewAssessments({
        search: debouncedSearch,
        limit: size,
        offset: currentPage * size,
        signal,
      });

      if (response) {
        const { reportsForReview, reviewAssessments } = response.data;
        const newAssessments = reviewAssessments.map(assessment => ({
          value: assessment.id,
          label: assessment.name,
        }));
        setAssessments(newAssessments);

        const responseRows = convertResponseToRows(
          reportsForReview.patientAssets
        );
        setRows(responseRows);

        setPagination(oldPagination => ({
          ...oldPagination,
          currentPage,
          size,
          pages: reportsForReview.totalPages || 1,
          totalRecord: reportsForReview.totalCount,
        }));
      }
      setIsLoading(false);
    },
    [debouncedSearch, signal, pagination]
  );

  useEffect(() => {
    if (initialReportsRequest) setInitialReportsRequest(false);
    else if (pagination.currentPage !== 0)
      setPagination(oldPagination => ({
        ...oldPagination,
        currentPage: 0,
      }));
    else fetchData();
  }, [debouncedSearch]);

  return {
    pagination,
    rows,
    search,
    isLoading,
    fetchData,
    onInputChange,
    columns,
    tableProps,
    t,
  };
}
