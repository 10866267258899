/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import I18n from 'i18next';

import ModalForm from '../common/Layout/Modal/ModalForm';
import ModalSection from '../common/Layout/Modal/ModalSection';
import Label from '../../../caro-ui-commonfiles/components/Label/Label';
import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Box from '../../../caro-ui-commonfiles/components/Box/Box';
import Historial from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialLayout';
import HistorialStep from '../../../caro-ui-commonfiles/components/HistorialStep/HistorialStep';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import ToolTip from '../../../caro-ui-commonfiles/components/Tooltip/Tooltip';
import Icon from '../../../caro-ui-commonfiles/components/Icon/Icon';
import ICDViewer from '../../../caro-ui-commonfiles/components/ICD/ICDViewer';
import {
  repeatTypesEnum,
  weekdays,
} from '../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';
import { getAction, getComponent } from '../../Utils/History';
import { MODAL_VARIANT } from '../../Utils/Layout/modalSection';


const ApptInformationOverview = ({ values, icds, showAssignee }) => {
  const { patientInfo } = useContext(PatientOverviewContext);
  const dateFormat = 'DD MMM YYYY HH:mm';

  const renderAssigneeInfoPane = () => {
    return (
      <div className="navigation-container">
        <div
          className="circular-picture-sm"
          style={{
            backgroundImage: `url(${patientInfo.profilePicture})`,
          }}
        />
        <div className="care-team-member-name">
          {patientInfo.firstname} {patientInfo.lastname}
        </div>
      </div>
    );
  };

  const renderOccurrencInfo = () => {
    if (values.repeatType.value !== repeatTypesEnum.WEEKLY) {
      return <div className="content-text">{values.repeatType.label}</div>;
    }
    if (values.repeatType.value === repeatTypesEnum.WEEKLY) {
      const weekdaysTranslation = weekdays();
      const recurrenceDays = values.weeklyRecurrence.map((day, i) => {
        if (day) {
          return (
            <div key={`weekday-${i}`} className="content-text">
              {i !== 0 && ', '}
              {weekdaysTranslation[i].translation}
            </div>
          );
        }
      });

      return (
        <>
          {recurrenceDays && (
            <Flex flexDirection="row">
              <span style={{ marginRight: '5px' }}>
                {I18n.t('recurrence.on')}
              </span>
              {recurrenceDays}
            </Flex>
          )}
        </>
      );
    }
  };

  const renderApptInfoPane = () => {
    return (
      <Flex flexDirection="row">
        <div className="info-container">
          <div className="section-container">
            <div>{I18n.t('appointment.type')}</div>
            <div className="content-text">{values.appointmentType.label}</div>
          </div>
          <div className="section-container">
            <div>{I18n.t('appointment.title')}</div>
            <div className="content-text">{values.name}</div>
          </div>
          {icds.length > 0 && (
            <div className="section-container">
              <div>{I18n.t('common_labels.referenced_icd_code')}</div>
              <ICDViewer icds={icds} shouldShowAll />
            </div>
          )}
          {values.location && (
            <div className="section-container">
              <div>{I18n.t('appointment.place')}</div>
              <div className="content-text">
                {values.location.label
                  ? values.location.label
                  : values.location}
              </div>
            </div>
          )}
          {values.notes && (
            <div className="section-container">
              <div>{I18n.t('patientOverview.details')}</div>
              <div className="content-text content-text-container">
                {values.notes}
              </div>
            </div>
          )}
          <div className="section-container">
            <div>{I18n.t('recurrence.startsFrom')}</div>
            <div className="content-text content-text-container">
              {moment(values.startDate)
                .hour(values.startTime.hour)
                .minute(values.startTime.minute)
                .locale(I18n.language)
                .format(dateFormat)}
            </div>
          </div>
          <div className="section-container">
            <div>{I18n.t('recurrence.endsAt')}</div>
            <div className="content-text content-text-container">
              {moment(values.endDate)
                .hour(values.endTime.hour)
                .minute(values.endTime.minute)
                .locale(I18n.language)
                .format(dateFormat)}
            </div>
          </div>
          {values.recurrenceText && (
            <div className="section-container">
              <div>{I18n.t('recurrence.title')}</div>
              <div className="content-text content-text-container">
                {values.recurrenceText}
              </div>
            </div>
          )}
          {values.repeatType && (
            <div className="section-container">
              <div>{I18n.t('recurrence.title')}</div>
              <div className="content-text content-text-container">
                {renderOccurrencInfo()}
              </div>
            </div>
          )}
        </div>
      </Flex>
    );
  };

  const renderAssignmentHistory = () =>
    values.ownershipLogs.map(log => (
      <Historial>
        <HistorialStep>
          <div className="show-patient-history">
            <span>
              {I18n.t('history.assignment', {
                user: log.user.username,
                action: getAction(log.action).toLowerCase(),
                component: getComponent(log.component),
              })}
            </span>
            <span className="show-patient__history-date">
              {`${moment(log.timeOfAction)
                .locale(I18n.language)
                .format('DD MMM YYYY')} - ${moment(log.timeOfAction).format(
                  'HH:mm'
                )}`}
            </span>
          </div>
        </HistorialStep>
      </Historial>

    ));

  return (
    <ModalForm>
      {showAssignee && (
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <Box padding="30px 20px 0 40px">
            <div className="section-container-nav">
              <div>{I18n.t('patientOverview.assignedTo')}</div>
            </div>
            {renderAssigneeInfoPane()}
          </Box>
        </ModalSection>
      )}
      {!showAssignee && (
        <ModalSection variant={MODAL_VARIANT.COL_W30}>
          <div className="history-label">
            <Label text={I18n.t('common_labels.history')} />
            <div className="show-assignment__historial-wrapper">
              {renderAssignmentHistory()}
            </div>
          </div>
        </ModalSection>
      )}
      <ModalSection variant={MODAL_VARIANT.COL_W70}>
        {renderApptInfoPane()}
      </ModalSection>
    </ModalForm>
  );
};

ApptInformationOverview.propTypes = {
  values: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  icds: PropTypes.arrayOf(PropTypes.object),
  showAssignee: PropTypes.bool,
};

ApptInformationOverview.defaultProps = {
  icds: [],
  showAssignee: true,
};

export default memo(ApptInformationOverview);
