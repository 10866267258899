import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, DismissibleContainer } from '@ui-common-files/components';

import useSelectedDataPointsService from './SelectedDataPoints.service';
import useHandleClose from '../../../hooks/useHandleClose';

import DataPointType from '../OverviewDataPointsSelection.enum';
import { ComplexScoringType } from '../../../../../Careplans/ComplexScoreSetting/ComplexScoring.type';
import { getColor } from '../../../OverviewDataCard/OverviewDataCard.utils';

import './SelectedDataPoints.styles.scss';
import { SelectedPoint } from '../../../Overview.type';

const SelectedDataPoints = ({
  dataPoint,
  index,
  shouldGetGraphViewData = false,
  className,
}: {
  dataPoint: SelectedPoint;
  index: number;
  shouldGetGraphViewData: boolean;
  className: string,
}) => {
  const { t } = useTranslation();

  const { getTitle, getKey } = useSelectedDataPointsService();
  const { handleClose } = useHandleClose();
  const {
    dataPointType,
    scoreTypeId,
    maxScore,
    dataPointName,
    dataPointUnit,
    dataPointDescription,
    healthDataKey,
    scoreId,
    id: templateId,
  } = dataPoint;
  return (
    <DismissibleContainer
      className={className}
      key={getKey(templateId, healthDataKey)}
      onClose={() =>
        handleClose(
          dataPointType,
          healthDataKey,
          scoreId,
          shouldGetGraphViewData
        )
      }
    >
      <Box
        otherStyles={{
          background: getColor(dataPoint, index),
          width: 12,
          height: 12,
          borderRadius: index === 0 ? '50%' : undefined,
        }}
      />
      <Box otherStyles={{ marginLeft: '10px' }}>
        <Flex>
          <Box className="health_data_title">
            {t(getTitle(dataPointType, dataPointName, dataPointDescription))}
          </Box>
          {dataPointType === DataPointType.HEALTH_DATA && (
            <Box className="selected-item-description">{t(dataPointUnit)}</Box>
          )}
          {dataPointType === DataPointType.CARETASK &&
            scoreTypeId === ComplexScoringType.OverallScore && (
              <Box className="selected-item-description">0 - {maxScore}</Box>
            )}
        </Flex>

        <Box className="selected-item-description">
          {dataPointType === DataPointType.CARETASK
            ? dataPointName
            : t(dataPointDescription)}
        </Box>
      </Box>
    </DismissibleContainer>
  );
};

export default SelectedDataPoints;
