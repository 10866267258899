import templatesTableActions from '../../src/Utils/ReduxUtilities/Actions/Datatables/templates.json';

const initialState = {
  currentPage: 0,
  value: '',
  start: 0,
  length: 10,
  dir: 'asc',
  column: 'name',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case templatesTableActions.ON_TEMPLATE_TABLE_CHANGE: {
      const { length, dir, column, currentPage } = action.payload;
      const nextState = {
        ...state,
        start: currentPage * length,
        length,
        dir,
        column,
        currentPage,
      };
      if (JSON.stringify(state) !== JSON.stringify(nextState)) {
        return nextState;
      }
      return state;
    }

    case templatesTableActions.ON_SEARCH_TEMPLATES: {
      const nextState = {
        ...state,
        start: 0,
        currentPage: 0,
        value: action.payload,
      };
      if (JSON.stringify(state) !== JSON.stringify(nextState)) {
        return nextState;
      }
      return state;
    }
    case templatesTableActions.RESET_TEMPLATE_TABLES: {
      return {
        currentPage: 0,
        value: '',
        start: 0,
        length: 10,
        dir: 'asc',
        column: 'name',
      };     
    }

    default:
      return state;
  }
};

export default reducer;
