/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { ref, getDownloadURL } from 'firebase/storage';

import { Box } from '@ui-common-files/components';
import BubbleContent from '../Bubbles/BubbleContent';

import { PrimaryColor } from '@ui-common-files/utils';

import bubbleType from '../../../Utils/Chat/bubbleType';
import {
  isPreviewInViewer,
  convertLinkToBlob,
} from '../../../Utils/Asset/assetHelper';
import { getFileExtension, isDataImage } from '../../../Utils/Chat/chatUtility';

const Bubble = ({
  message,
  name,
  time,
  fileRef,
  messageTimestamp,
  variant,
  directLink,
  thumbnail,
  isFileData,
}) => {
  const [blobURL, setBlobURL] = useState(null);
  const [data, setData] = useReducer((_state, url) => url, null);
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [thumbnailBlobURL, setThumbnailBlobURL] = useState(null);

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const isImage = isFileData && isDataImage(message);

  useEffect(() => {
    if (directLink) {
      let link = directLink.split('?v=')[0];
      const lastIndex = link.lastIndexOf(' (');
      if (lastIndex !== -1) link = link.split(' (')[0];
      if (isPreviewInViewer(getFileExtension(link).toUpperCase())) {
        convertLinkToBlob(directLink).then(convertedUrl => {
          setBlobURL(convertedUrl);
        });
      } else {
        setBlobURL(directLink);
      }
    }
  }, [directLink]);

  useEffect(() => {
    if (thumbnail) {
      convertLinkToBlob(thumbnail).then(convertedUrl => {
        setThumbnailBlobURL(convertedUrl);
      });
    }
  }, [thumbnail]);

  useEffect(() => {
    setIsLoading(true);
    if (fileRef && !data && !directLink) {
      const filesStorageRef = ref(
        fileRef,
        `${moment(messageTimestamp).format('x')}_${message}`
      );

      getDownloadURL(filesStorageRef)
        .then(url => {
          setIsLoading(false);
          setData(url);
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  }, [fileRef]);

  return (
    <Box
      className={cx({
        'chat__message-container': true,
        'chat__message-container--user': variant !== 'patient',
      })}
    >
      <Box
        className={cx({
          'chat__message-bubble': variant === 'patient',
          'chat__message-bubble--user': variant !== 'patient',
        })}
      >
        {isLoading && isImage && (
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        )}

        <BubbleContent
          note={message}
          isFileData={isFileData}
          data={data}
          blobURL={blobURL}
          thumbnailBlobURL={thumbnailBlobURL}
        />
      </Box>

      <Box
        className={cx({
          'chat__message-bubble__details': true,
          'chat__message-bubble__details--user': variant !== 'patient',
        })}
      >
        <p className="chat__message-bubble__details__name">{name}</p>
        <p className="chat__message-bubble__details__time">{time}</p>
      </Box>
    </Box>
  );
};

Bubble.propTypes = {
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  fileRef: PropTypes.object,
  messageTimestamp: PropTypes.string,
  variant: PropTypes.oneOf([bubbleType.PATIENT, bubbleType.USER]).isRequired,
};

Bubble.defaultProps = {
  fileRef: undefined,
  messageTimestamp: undefined,
};

export default React.memo(Bubble);
