import React from 'react';
import { Box, Flex } from '@ui-common-files/components';

import useCaretaskAnsweringProgressBarService from './CaretaskAnsweringProgressBar.service';
import { CaretaskAnsweringProgressBarItemProps } from './CaretaskAnsweringProgressBar.type';

import './CaretaskAnsweringProgressBar.style.scss';

function CaretaskAnsweringProgressBarItem({
  totalComponents,
  currentComponent,
}: CaretaskAnsweringProgressBarItemProps) {
  const calculateProgress = () => {
    if (currentComponent >= 0) {
      return ((currentComponent + 1) / totalComponents) * 100;
    }
    return 0;
  };

  return (
    <Box>
      <Box className="answer-view__progressbar-container-item-trail">
        <Box
          className="answer-view__progressbar-container-item-trail-progress"
          otherStyles={{
            width: `${calculateProgress()}%`,
          }}
        />
      </Box>
    </Box>
  );
}

export default function CaretaskAnsweringProgressBar() {
  const {
    isAnsweringMain,
    mainComponentsCount,
    additionalComponentsCount,
    currentComponent,
    totalComponentsCount,
    displayMainComponentsCurrent,
  } = useCaretaskAnsweringProgressBarService();

  return (
    <Flex className="answer-view__progressbar-container">
      <Box className="answer-view__progressbar-container-item" flex={4}>
        <CaretaskAnsweringProgressBarItem
          totalComponents={mainComponentsCount}
          currentComponent={displayMainComponentsCurrent}
        />
      </Box>
      {!isAnsweringMain && (
        <Box
          className="answer-view__progressbar-container-item"
          flex={1}
          otherStyles={{ marginLeft: 5 }}
        >
          <CaretaskAnsweringProgressBarItem
            totalComponents={additionalComponentsCount}
            currentComponent={currentComponent}
          />
        </Box>
      )}
      <Box className="answer-view__progressbar-container-text">
        {currentComponent !== undefined
          ? `${currentComponent + 1}/${totalComponentsCount}`
          : `${totalComponentsCount}/${totalComponentsCount}`}
      </Box>
    </Flex>
  );
}
