import { useMemo } from 'react';
import {
  getConnectedEdges,
  useNodeId,
  useStore,
  ReactFlowState,
} from 'reactflow';

import { NUMBER_OF_ALLOWED_CONNECTION } from '../../CarePathWayBuilder.constant';

const selector = (s: ReactFlowState) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const useCareTaskNodeTargetHandleService = () => {
  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();

  const isHandleConnectable = useMemo(() => {
    const node = nodeInternals.get(nodeId);
    const connectedEdges = getConnectedEdges([node], edges).filter(
      edge => edge.target === nodeId
    );

    return connectedEdges.length < NUMBER_OF_ALLOWED_CONNECTION;
  }, [nodeInternals, edges, nodeId]);

  return { isHandleConnectable };
};

export default useCareTaskNodeTargetHandleService;
