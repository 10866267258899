import React from 'react';
import {
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  ErrorBar,
  Legend,
} from 'recharts';
import Loader from 'react-loader-spinner';

import { Box } from '@ui-common-files/components';
import { Greys, PrimaryColor } from '@ui-common-files/utils';

import { CustomDot, CustomTooltip, CustomLegend } from './ChartElements';
import NoDataComponent from './NoDataComponent';
import useOverViewDataChartService from './OverviewDataChart.service';
import { shouldRenderErrorBar } from './OverviewDataChart.utils';
import { GraphData } from '../Overview.type';

import '../Overview.styles.scss';

const OverviewDataChart = ({
  data,
  isChatOpened,
  isLoading,
}: {
  data: GraphData;
  isChatOpened: boolean;
  isLoading: boolean;
}) => {
  const {
    xAxisIntervalRenderer,
    getYAxisOrientation,
    getTicks,
    getStrokeColor,
    tickFormatter,
  } = useOverViewDataChartService();
  const axisStyles = {
    tick: { fontSize: 12, fill: Greys.LIGHT_GREY },
    tickLine: false,
    stroke: Greys.LIGHT_GREY_20,
  };

  return (
    <Box>
      {isLoading && (
        <Box className="pdf-loader-container">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={30}
            width={30}
          />
        </Box>
      )}
      {!isLoading && data.numberOfLines === 0 && <NoDataComponent />}
      {data.numberOfLines > 0 && !isLoading && (
        <Box>
          <Legend
            verticalAlign="top"
            content={<CustomLegend />}
            align="center"
            wrapperStyle={{
              position: 'relative',
              padding: '0 70px 0 60px',
              marginBottom: 40,
            }}
          />
          <Box>
            <ResponsiveContainer height={300} debounce={300}>
              <ComposedChart data={data.points} barGap={15} barSize={24}>
                <YAxis yAxisId="left" {...axisStyles} />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  {...axisStyles}
                  width={40}
                />
                <XAxis
                  dataKey="date"
                  interval={xAxisIntervalRenderer(isChatOpened)}
                  type="number"
                  domain={['auto', 'auto']}
                  scale="time"
                  ticks={getTicks}
                  tickFormatter={tick => tickFormatter(tick)}
                  {...axisStyles}
                />
                <CartesianGrid
                  stroke={Greys.LIGHT_GREY_20}
                  strokeWidth={1}
                  strokeDasharray="4"
                />
                <Tooltip
                  wrapperStyle={{ position: 'absolute', pointerEvents: 'none' }}
                  cursor={{
                    stroke: Greys.LIGHT_GREY_50,
                    strokeWidth: 1,
                    strokeDasharray: '3 4',
                  }}
                  content={<CustomTooltip />}
                  filterNull={false}
                />
                {Array.from({ length: data.numberOfLines }, (_, index) => (
                  <Line
                    yAxisId={getYAxisOrientation(index, data)}
                    connectNulls
                    type="monotone"
                    key={`line-${index}`}
                    dataKey={`line${index + 1}`}
                    stroke={getStrokeColor(index, data)}
                    strokeWidth={2}
                    dot={<CustomDot />}
                    activeDot={<CustomDot hover />}
                  >
                    {shouldRenderErrorBar(data, index) && (
                      <ErrorBar
                        dataKey="error"
                        width={4}
                        strokeWidth={2}
                        stroke={getStrokeColor(index, data)}
                        direction="y"
                      />
                    )}
                  </Line>
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OverviewDataChart;
