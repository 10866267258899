/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import '../../styles/main.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import ICDDropMenu from './ICDDropMenu';
import modificationType from '../../utils/modification.json';
import { ActionColors } from '../../utils/colors';
import Flex from '../Flex/Flex';

import { generateRandomId } from '../../utils';

const ICDSelect = ({
  icdSelected,
  onSelect,
  maxLengthItem,
  fetchData,
  placeholder,
  modification,
  hasError,
  isDisabled,
  isRenderedOnCaretaskBuilder,
}) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [itemFiltered, setItemFiltered] = useState([]);
  const [itemsHighlighted, setItemsHighlighted] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);
  const [itemsCounter, setItemsCounter] = useState(0);
  const [term, setTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [timer, setTimer] = useState(null);

  let termInput = '';

  useEffect(() => {
    const fetchIcds = async () => {
      try {
        const response = await fetchData(term);
        if (response) {
          const responseWithoutSelectedIcd = response.filter(
            item =>
              !itemSelected.find(selectedItem => selectedItem.id === item.id)
          );
          setItemFiltered(responseWithoutSelectedIcd);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setItemFiltered([]);
        throw new Error(error);
      }
    };
    if (!isTyping && term.length > 1) {
      fetchIcds();
    } else {
      setItemFiltered([]);
    }
  }, [term, isTyping]);

  /* ----------------  DROPDOWN EVENTS  ----------------*/

  const hideDropdownMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setDisplayMenu(false);
    document.removeEventListener('click', hideDropdownMenu);
  };

  /* ------------- ICD ITEMS HANDLER --------------- */

  /* *** Fetch Icd by term *** */

  const startFetch = async e => {
    e.preventDefault();
    e.stopPropagation();
    setTerm('');
    setDisplayMenu(false);

    const searchTerm = e.currentTarget.value;
    if (
      searchTerm &&
      searchTerm.length > 1 &&
      icdSelected.length < maxLengthItem
    ) {
      setIsLoading(true);
      setIsTyping(true);
      setTerm(searchTerm);
      if (timer !== null) {
        clearTimeout(timer);
        setTimer(null);
      }
      const currTimer = setTimeout(function () {
        setIsTyping(false);
      }, 1000);
      setTimer(currTimer);
    } else {
      setTerm('');
      setIsLoading(false);
    }
  };

  /* *** Highlight the Search criteria *** */

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span className="icd-select__item__searchable">
        {parts.map(part => {
          const key = `${part}-${generateRandomId()}`;
          return part.toLowerCase() === highlight.toLowerCase() ? (
            <b key={key} className="icd-select__item__searchable--highlighted">
              {part}
            </b>
          ) : (
            part
          );
        })}
      </span>
    );
  };

  /* *** Highlight the incoming items *** */

  useEffect(() => {
    const highlightTerm = () => {
      if (itemFiltered.length === 0 && !isTyping) {
        const emptyFetch = [];
        emptyFetch.push({ empty: true, modification });
        setItemsHighlighted([...emptyFetch]);
        setDisplayMenu(true);
      } else {
        const highlighted = itemFiltered.map(item => {
          return {
            description: getHighlightedText(item.description, term),
            code: getHighlightedText(item.code, term),
            id: item.id,
            modification: item.modification,
          };
        });
        if (highlighted.length > 0) {
          setItemsHighlighted(highlighted);
          setDisplayMenu(true);
          document.addEventListener('click', hideDropdownMenu);
        } else {
          setDisplayMenu(false);
        }
      }
    };
    highlightTerm();
  }, [itemFiltered]);

  /* *** Find item position in a list *** */

  const findItemPosition = (id, list) => {
    let pos = -1;
    list.forEach((item, index) => {
      if (item.id === id) {
        pos = index;
      }
    });
    return pos;
  };

  /* *** Select Icd by Id */

  const selectIcds = id => {
    if (findItemPosition(id, itemSelected) === -1) {
      const itemToList = itemFiltered[findItemPosition(id, itemFiltered)];
      const icdsOnContainer = itemSelected;
      icdsOnContainer.push(itemToList);
      setItemSelected(icdsOnContainer);
      onSelect(icdsOnContainer);
      setItemsCounter(itemsCounter + 1);
      setTerm('');
      termInput.value = '';
      return true;
    }
    return false;
  };

  /* *** Add items already selected to be rendered when component will mount *** */

  const loadSelections = useCallback(() => {
    if (icdSelected.length >= 0 && icdSelected.length <= maxLengthItem) {
      setItemSelected([...icdSelected]);
      setItemsCounter(icdSelected.length);
      onSelect(icdSelected);
    }
  }, [icdSelected]);

  useEffect(() => {
    loadSelections();
  }, [loadSelections]);

  /* *** Remove item from selection *** */

  const removeSelectedItem = item => {
    const newList = itemSelected.filter(newItem => newItem.id !== item.id);
    setItemSelected(newList);
    setItemsCounter(newList.length);
    onSelect(newList);
  };

  /* ----------------- ALL RENDER FUNCTIONS  ------------------- */

  /* *** Input *** */

  const inputICDWrapperClasses = classNames({
    'icd-select__input__wrapper': true,
    disabled: isDisabled,
    has_error: hasError && itemSelected.length === 0,
  });

  const input = () => (
    <div className={inputICDWrapperClasses}>
      <input
        type="text"
        autoComplete="on"
        className="icd-select__input"
        onKeyUp={startFetch}
        ref={elem => (termInput = elem)}
        placeholder={placeholder}
        disabled={isDisabled}
      />
      {isLoading ? (
        <div className="icd-select__loader__wrapper">
          <div className="icd-select__loader" />
        </div>
      ) : null}
      <span className="icd-select__input__counter">
        {`${itemsCounter}/${maxLengthItem}`}
      </span>
      {hasError && (
        <Icon
          name="error"
          fill={ActionColors.ERROR}
          className="input__text__icon right-icon"
        />
      )}
    </div>
  );

  const icdItems = item => (
    <div className="icd-select__item__selected" key={item.id}>
      <span className="icd-select__item__selected__content">
        <span className="icd-select__item__selected__header">
          <span className="icd-select__item__selected__revision">
            {item.modification == modificationType.OPS ? 'OPS:' : 'ICD-10:'}
          </span>
          <span className="icd-select__item__selected__code">{item.code}</span>
          <span className="icd-select__item__selected__version">
            {item.version}
          </span>
        </span>
        {!isDisabled && (
          <button
            type="button"
            className="icd-select__item__selected__close"
            onClick={() => removeSelectedItem(item)}
          >
            <Icon name="close" />
          </button>
        )}
      </span>
      <span className="icd-select__item__separator" />
      <span className="icd-select__item__selected__record">
        {item.description}
      </span>
    </div>
  );

  /* ***  Selected Items *** */

  const renderItemSelected = () =>
    itemSelected.map(item => {
      if (isRenderedOnCaretaskBuilder) {
        return <Flex otherStyles={{ width: '49%' }}>{icdItems(item)}</Flex>;
      }
      return icdItems(item);
    });

  /* *** ICD Component *** */

  const selectedItemsLayout = () => {
    if (isRenderedOnCaretaskBuilder) {
      return (
        <Flex
          flexWrap="wrap"
          alignItems="stretch"
          justifyContent="space-between"
        >
          {renderItemSelected()}
        </Flex>
      );
    }

    return renderItemSelected();
  };

  return (
    <>
      <div className="icd-select">
        {input()}
        {displayMenu &&
        term &&
        itemsHighlighted &&
        itemSelected.length < maxLengthItem ? (
          <ICDDropMenu
            items={itemsHighlighted}
            selectOnClick={id => {
              if (selectIcds(id)) setDisplayMenu(false);
            }}
          />
        ) : null}
      </div>

      <div className="icd-select__item--selected__wrapper">
        {selectedItemsLayout()}
      </div>
    </>
  );
};

ICDSelect.propTypes = {
  icdSelected: PropTypes.arrayOf(PropTypes.object),
  maxLengthItem: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  modification: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isRenderedOnCaretaskBuilder: PropTypes.bool,
};

ICDSelect.defaultProps = {
  icdSelected: [],
  maxLengthItem: 5,
  modification: modificationType.ICD10,
  isDisabled: false,
  isRenderedOnCaretaskBuilder: false,
};
export default ICDSelect;
