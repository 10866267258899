// emailRegex checks for
//    a username part which can contain non special characters
//    an @ symbol
//    a domain part, which can be an IP or alphanumeric with dots
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// passwordRegex checks for
//    at least 8 characters
//    Either a digit or a special character
//    a lowercase letter
//    an uppercase letter
export const passwordRegex =
  /(?=^.{8,}$)((?=.*\d)|(?=.*\W))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

// anchorLinkRegex is used to check for errors, it checks if an anchor tag href property
//    starts with https://
//    contains only the allowed characters (except @ to avoid redirection)
export const anchorLinkRegex =
  /href="https:\/\/[a-zA-Z0-9-._~:/?#[\]!$&'()*+,;%=]+"/i;

// personNameRegex checks for
//    string that does not start with any of the specified special characters or numeric digits (0-9)
//    special characters allowed in names are: `!@#$%^&*()_+=[]{};':"|\,.<>?~
//    the 'g' flags indicate that the pattern will be applied globally and in one line mode
export const personNameRegex = /^[^`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~0-9]+$/g;
