import React from 'react';
import classNames from 'classnames';

import { TagsType, TagsVariant, Greys } from '../../utils';
import variants from './TagsColorVariants';

import { TagsService } from './Tags.interface';

export default function useTagsService({
  type,
  variant,
  shouldHaveEllipsis,
  shouldWidthBeMaxContent,
  fill,
  colorVariant,
}: TagsService) {
  const tagsClass = classNames({
    tags: true,
    tags__squared: type === TagsType.SQUARED,
    tags__rounded: type === TagsType.ROUNDED,
    tags__rounded__outlined:
      type === TagsType.ROUNDED && variant === TagsVariant.OUTLINED,
    tags__rounded__contained:
      type === TagsType.ROUNDED && variant === TagsVariant.CONTAINED,
    tags__ellipsis: shouldHaveEllipsis,
    tags__arrow__outlined:
      type === TagsType.ARROW && variant === TagsVariant.OUTLINED,
    tags__maxContent: shouldWidthBeMaxContent,
  });

  const style =
    variants[type][colorVariant] ||
    (type === TagsType.SQUARED && {
      backgroundColor: fill,
      color: Greys.WHITE,
    });

  return {
    tagsClass,
    style,
  };
}
