import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import inputField from '../../utils/inputField';
import { Greys } from '../../utils/colors';

import '../../styles/main.scss';

const NumberInput = ({
  id,
  name,
  min,
  max,
  step,
  isFloatingNumber,
  isNegativeNumber,
  placeholder,
  isDisabled,
  autoComplete,
  value,
  changeValue,
  hasError,
  hasSuccess,
  validationMessage,
  maxDigitsForFloat,
  ...props
}) => {
  const handleOnChange = e => {
    const typedValue = e.target.value;
    const checkNumber = (inputValue, regex) => {
      let parsedValue = 0;
      if (inputValue !== '' && inputValue !== '-') {
        if (isFloatingNumber) {
          parsedValue = parseFloat(inputValue);
        } else {
          parsedValue = parseInt(inputValue, 10);
        }
      }
      if (
        inputValue === '' ||
        (isNegativeNumber && inputValue === '-') ||
        (regex.test(inputValue) && parsedValue >= min && parsedValue <= max)
      ) {
        changeValue(inputValue);
      }
    };
    const floatRegex = new RegExp(
      `^(-?(0|[1-9][0-9]*)(\\.[0-9]{0,${maxDigitsForFloat}})?)$`
    );

    if (isFloatingNumber) {
      checkNumber(typedValue, floatRegex);
    } else if (isNegativeNumber) {
      checkNumber(typedValue, /^(0|-|([1-9][0-9]*)|(-[1-9][0-9]*))$/);
    } else {
      checkNumber(typedValue, /^(0|([1-9][0-9]*))$/);
    }
  };

  const numberToBeChanged = parseInt(value, 10);
  const isNumberNaN = Number.isNaN(numberToBeChanged);

  const handleIncDecWhenFloatAndNegativeEnabled = action => {
    let numberWithStep;
    step = 0.01;
    if (action === 'add') {
      numberWithStep = (
        parseFloat(Number(value)) + parseFloat(Number(step))
      ).toFixed(2);
    } else if (action === 'sub') {
      numberWithStep = (
        parseFloat(Number(value)) - parseFloat(Number(step))
      ).toFixed(2);
    }
    changeValue(numberWithStep.toString());
  };

  const isFloatButNoNegative = n => {
    const isPositiveNumber = Math.sign(n) !== -1;
    const isFloat = Number(n) === n && n % 1 !== 0;
    if (isPositiveNumber && isFloat) {
      return true;
    }
    return false;
  };

  const handleIncrement = () => {
    if (isFloatingNumber) {
      handleIncDecWhenFloatAndNegativeEnabled('add');
    } else {
      const numberWithStep = numberToBeChanged + step;
      if (isNumberNaN) {
        changeValue(min.toString());
      } else if (numberWithStep >= max) {
        changeValue(max.toString());
      } else {
        changeValue(numberWithStep.toString());
      }
    }
  };

  const handleDecrement = () => {
    const isFloatValue = isFloatButNoNegative(parseFloat(Number(value)));
    if ((isNegativeNumber || isFloatValue) && isFloatingNumber) {
      handleIncDecWhenFloatAndNegativeEnabled('sub');
    } else {
      const numberWithStep = numberToBeChanged - step;
      if (isNumberNaN) {
        changeValue(min.toString());
      } else if (numberWithStep <= min) {
        changeValue(min.toString());
      } else if (numberWithStep >= max) {
        changeValue(max.toString());
      } else {
        changeValue(numberWithStep.toString());
      }
    }
  };

  /* Classes
   ========================================================================== */
  const inputFieldWrapperClasses = classNames({
    'input-field__number__wrapper': true,
    disabled: isDisabled,
    'input--error': hasError && !hasSuccess && !isDisabled,
    'input--success': hasSuccess && !hasError && !isDisabled,
  });

  const inputWrapperClasses = classNames({
    input__number__wrapper: true,
    disabled: isDisabled,
  });

  const inputClasses = classNames({
    input__text: true,
  });

  return (
    <>
      <div className={inputFieldWrapperClasses}>
        <div className={inputWrapperClasses}>
          <input
            // type="text" is requested in order to work with Regex and allow only numbers
            // Fix issue on Firefox and Safari of type="number"
            type="text"
            id={id}
            name={name}
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={handleOnChange}
            placeholder={placeholder}
            className={inputClasses}
            disabled={isDisabled}
            autoComplete={autoComplete}
            {...props}
          />
        </div>
        <div className="spinner__btn__wrapper">
          <div className="spinner__btn">
            <button
              type="button"
              className="increment"
              onClick={handleIncrement}
              disabled={isDisabled || value >= max}
            >
              <Icon
                name="plus"
                fill={Greys.LIGHT_GREY}
                size={8}
                className="spinner__btn__icon"
              />
            </button>
            <button
              type="button"
              className="decrement"
              onClick={handleDecrement}
              disabled={isDisabled || value <= min}
            >
              <Icon
                name="minus"
                fill={Greys.LIGHT_GREY}
                size={8}
                className="spinner__btn__icon"
              />
            </button>
          </div>
        </div>
      </div>
      {hasError && validationMessage && <span>{validationMessage}</span>}
    </>
  );
};

NumberInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  isFloatingNumber: PropTypes.bool,
  isNegativeNumber: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  autoComplete: PropTypes.oneOf([
    inputField.autocomplete.ON,
    inputField.autocomplete.OFF,
  ]),
  /** Can be null or a string */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  changeValue: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  hasSuccess: PropTypes.bool,
  validationMessage: PropTypes.string,
  maxDigitsForFloat: PropTypes.number,
};

NumberInput.defaultProps = {
  isDisabled: false,
  isFloatingNumber: false,
  isNegativeNumber: false,
  autoComplete: inputField.autocomplete.OFF,
  hasError: false,
  hasSuccess: false,
  validationMessage: null,
  maxDigitsForFloat: 3,
};

export default NumberInput;
