import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Flex, Box, Button, TextInput } from '@ui-common-files/components';

import {
  ButtonColor,
  ButtonVariant,
  MaxCharLimits,
  inputField,
} from '@ui-common-files/utils';

import { ErrorObject } from '../CarePathWayBuilder.type';

import usePanelHeaderService from './PanelHeader.service';
import useCarePathWaySettingsService from '../PanelSidebar/CarePathWaySettings.service';
import config from '../../../../../config';

import './PanelHeader.styles.scss';

const PanelHeader: React.FC = () => {
  const {
    nodes,
    errors,
    contentClasses,
    settingsHasError,
    hasErrorBasedOnId,
    updateSidebarVisibility,
  } = usePanelHeaderService();
  const { settings, onValueChange } = useCarePathWaySettingsService();

  const { t } = useTranslation();
  const carepathwayMaxNodesThresholds =
    config.carepathwayMaxNodesLimit - config.carepathwayMaxNodesThresholds;

  const careTasksCounterClassName = classNames({
    'panel-header_counter-content': true,
    'panel-header_counter-content_valid':
      nodes.length < carepathwayMaxNodesThresholds,
    'panel-header_counter-content_warning':
      nodes.length >= carepathwayMaxNodesThresholds,
    'panel-header_counter-content_error':
      nodes.length === config.carepathwayMaxNodesLimit,
  });

  return (
    <Flex className="panel-header">
      <Box className={contentClasses}>
        <Flex flexDirection="column" className="panel-header_content-inner">
          <Flex
            flexWrap="wrap"
            className="panel-header_content-inner_wrapper-elements"
          >
            <Box flex="1">
              <Box className="panel-header_content-inner_wrapper-elements-label">
                * {t('newCareplan_view.carePathwayName')}
              </Box>
              <TextInput
                value={settings.carePathwayName}
                name="carePathwayName"
                hasError={hasErrorBasedOnId('carePathwayName')}
                placeholder={t('newCareplan_view.add_carePathwayName')}
                variant={inputField.variant.CHAR_COUNT}
                maxChars={MaxCharLimits.carePathway.carePathwayNameMaxCharLimit}
                handleOnChange={event => {
                  onValueChange(event.target.value, 'carePathwayName');
                }}
              />
            </Box>

            <Flex className="panel-header_content-inner_add">
              <Box margin="0 16px 0 0">
                <Button
                  label={t('common_buttons.add_caretask')}
                  onClick={() => {
                    updateSidebarVisibility(true, 'care-task');
                  }}
                  disabled={nodes.length === config.carepathwayMaxNodesLimit}
                />
              </Box>

              <Button
                label={t('steps.carepathway_settings')}
                variant={ButtonVariant.OUTLINED}
                color={
                  settingsHasError ? ButtonColor.RED : ButtonColor.MAIN_COLOR
                }
                onClick={() => {
                  updateSidebarVisibility(true, 'settings');
                }}
              />
            </Flex>
          </Flex>
          <Box className="panel-header_content-inner_errors">
            {errors.map((error: ErrorObject) => (
              <Box key={`error-item-${error.id}`}>
                {t(error.translationKey)}
              </Box>
            ))}
          </Box>
        </Flex>
      </Box>
      <Box className="panel-header_counter">
        <Box className={careTasksCounterClassName}>
          {nodes.length} / {config.carepathwayMaxNodesLimit}
        </Box>
      </Box>
    </Flex>
  );
};

export default PanelHeader;
