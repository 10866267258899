import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionColors } from '../../../../caro-ui-commonfiles/utils/colors';
import '../../../css/careplanComponent.scss';
import NumericConditionInput from '../settingsForComponentTypes/NumericConditionInput';
import { isMainQuestion } from '../CareplanHelperUtility';

const Information = ({
  item,
  componentCollection,
  setLogicJumpValueForComponent,
  componentIndex,
  isLogicJumpOn,
  setLogicJumpError,
  componentTypeId,
}) => {
  const [itemCollection, setItemCollection] = useState(
    item.logicJumpCollection && item.logicJumpCollection.length > 0
      ? item.logicJumpCollection
      : [{ name: '', value: '', destination: -1 }]
  );

  const isFloatingNumber = item.searchTerm.careplanComponentId
    ? item.searchTerm.decimal
    : item.decimal;

  const addJumpCondition = () => {
    const obj = { name: '', value: '', destination: -1 };
    const newItemCollection = [...itemCollection, obj];
    setItemCollection(newItemCollection);
  };

  const deleteJumpConditions = optionIndex => {
    const updateItemCollection = [...componentCollection];
    updateItemCollection[componentIndex].logicJumpCollection.splice(
      optionIndex,
      1
    );
    setLogicJumpValueForComponent(
      'logicJumpCollection',
      updateItemCollection[componentIndex].logicJumpCollection
    );
    setItemCollection(item.logicJumpCollection);
  };

  return (
    <div>
      {isLogicJumpOn && (
        <>
          {itemCollection.map((elem, i) => {
            return (
              <NumericConditionInput
                index={i}
                item={elem}
                parentComponentIndex={componentIndex}
                itemCollection={itemCollection}
                setLogicJumpValueForComponent={setLogicJumpValueForComponent}
                isFloatingNumber={isFloatingNumber}
                isThreshold={false}
                color={ActionColors.THRESHOLD}
                setLogicJumpError={setLogicJumpError}
                deleteJumpConditions={deleteJumpConditions}
                componentTypeId={componentTypeId}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

Information.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  componentCollection: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  setLogicJumpValueForComponent: PropTypes.func.isRequired,
  isLogicJumpOn: PropTypes.bool.isRequired,
  setLogicJumpError: PropTypes.func.isRequired,
  componentIndex: PropTypes.number.isRequired,
};

export default Information;
