import serviceConfig from '../../../serviceConfig.json';
import { ApiClient } from '../../Global/Services';
import { convertLinkToBlob } from '../../Utils/Asset/assetHelper';

async function editUser(currenUser) {
  try {
    const response = await ApiClient.POST({
      url: serviceConfig.brokerService.editUser.uri,
      payload: {
        id: currenUser,
      },
    });
    if (response) {
      const userInfo = response.data;
      if (userInfo.user.profilePicture.directLink) {
        await convertLinkToBlob(userInfo.user.profilePicture.directLink)
          .then(blob => {
            userInfo.user.profilePicture = blob;
          })
          .catch(() => {
            userInfo.user.profilePicture =
              userInfo.user.profilePicture.directLink;
          });
      } else {
        userInfo.user.profilePicture = `data:image;base64,${userInfo.user.profilePicture}`;
      }
      userInfo.user.emailNotification = JSON.parse(
        userInfo.user.emailNotification
      );
      return userInfo;
    }
  } catch (error) {
    return {
      content: error.response.data.content,
      type: error.response.data.type,
    };
  }
}

export default editUser;
