enum Actions {
  Delete = 'Delete',
  Show = 'Show',
  Create = 'Create',
  Edit = 'Edit',
  ExportAnswers = 'ExportAnswers',
  Attach = 'Attach',
  Assign = 'Assign',
}

export default Actions;
