import React from 'react';

import {
  Box,
  CareTaskSequence,
  Flex,
  Historial,
  HistorialStep,
} from '@ui-common-files/components';
import LinkedCaretasks from './LinkedCaretasks';

import { sortEdgesByCondition } from '../CaretaskData/CaretaskData.utils';
import './CaretaskData.styles.scss';

export default function ShowCaretaskData({
  caretaskName,
  caretask,
  allCareplans,
  startText,
  occurrence,
  endText,
}) {
  const sortedEdges = sortEdgesByCondition(caretask.edges);
  const parentNode = caretask.nodes.filter(node => !node.parentId)[0];

  return (
    <Historial>
      <HistorialStep>
        <Flex
          flexDirection="column"
          otherStyles={{ width: '100%', marginBottom: 45 }}
        >
          <Flex otherStyles={{ fontWeight: 600, marginBottom: 14 }}>
            {caretaskName}
          </Flex>
          <Box width="55%" otherStyles={{ marginBottom: 22 }}>
            <CareTaskSequence
              startText={startText}
              occurrence={occurrence}
              endText={endText}
            />
          </Box>
          {caretask.isLinkable && (
            <LinkedCaretasks
              allCareplans={allCareplans}
              allNodes={caretask.nodes}
              allEdges={sortedEdges}
              parentNode={parentNode}
            />
          )}
        </Flex>
      </HistorialStep>
    </Historial>
  );
}
