import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CaretaskComponent } from '../../../../../../types/Caretask';
import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { AnsweringComponentsProps } from '../AnsweringTypes';

import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';

import getBodyClickPosition from '../../../../../../../caro-ui-commonfiles/components/BodyChart/getBodyClickPosition';
import BodyPosition from '../../../../../../../caro-ui-commonfiles/components/BodyChart/bodyPosition';
import BodyChart from '../../../../../../../caro-ui-commonfiles/components/BodyChart/BodyChart';
import Flex from '../../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import bodySwitchIcon from '../../../../../../../caro-ui-commonfiles/public/img/body-switch.png';
import { useReducer } from 'react';

export default function AnsweringPainlocation({
  handleOnChange,
}: AnsweringComponentsProps): JSX.Element {
  const BODY_CHART_SIZE = { width: 130, height: 363 };
  const { t } = useTranslation();

  const resetSelection = (state, param) => {
    if (
      param === undefined ||
      param === null ||
      Object.keys(param).length === 0 ||
      param.circlePos?.length === 0
    ) {
      return {};
    } else {
      const updatedSelection = {
        ...state,
        ...param,
      };

      const translation =
        updatedSelection.circlePos && updatedSelection.circlePos.length > 0
          ? getBodyClickPosition(
              {
                x: updatedSelection.circlePos[0].marginLeft,
                y: updatedSelection.circlePos[0].marginTop,
              },
              BODY_CHART_SIZE,
              updatedSelection.isBack
            )
          : null;

      return {
        ...updatedSelection,
        translation,
      };
    }
  };

  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);

  const defaultValue =
    currentAnswer?.answer !== null ? currentAnswer?.answer : {};

  const [selection, setSelection] = useReducer(resetSelection, defaultValue);

  useEffect(() => {
    handleOnChange({
      answer: selection,
      isSkipped: !selection.circlePos,
    });
  }, [selection, handleOnChange]);

  useEffect(() => {
    setSelection(defaultValue);
  }, [currentComponent]);

  const getCirclePos = e => {
    let pos = [];
    let coord = {};
    if (e) {
      const newPos = e.currentTarget.getBoundingClientRect();
      coord = {
        x: e.clientX - newPos.left,
        y: e.clientY - newPos.top,
      };

      pos = [
        {
          marginLeft: coord.x - 17.5,
          marginTop: coord.y - 15,
        },
      ];
    }
    setSelection({
      ...selection,
      circlePos: pos,
      x: coord.x / BODY_CHART_SIZE.width,
      y: coord.y / BODY_CHART_SIZE.height,
      translation: getBodyClickPosition(
        coord,
        BODY_CHART_SIZE,
        selection.isBack
      ),
    });
  };
  const getBodyPosition = isBack => {
    return isBack ? BodyPosition.back : BodyPosition.front;
  };
  return (
    <div className="answer-view__caretask-component-options">
      <Flex
        flexDirection="row"
        alignItems="flex-start"
        otherStyles={{ paddingLeft: '25%' }}
      >
        <Flex flexDirection="column" otherStyles={{ flex: 1 }}>
          <BodyChart
            circlePos={selection.circlePos}
            type={getBodyPosition(selection.isBack)}
            onClick={getCirclePos}
            size={{ ...BODY_CHART_SIZE }}
          />
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            otherStyles={{ minWidth: BODY_CHART_SIZE.width }}
          >
            <div className="body-chart__selected-part">
              {selection.translation &&
                selection.circlePos &&
                t(`bodyChartImage.${selection.translation}`)}
              {(!selection.translation || !selection.circlePos) &&
                t('bodyChartImage.noSelection')}
            </div>
            <IconButton
              name="close"
              onClick={() => setSelection({ circlePos: [], x: null, y: null })}
            />
          </Flex>
        </Flex>
        <div
          role="button"
          className="body-chart__switch"
          onClick={() => setSelection({ isBack: !selection.isBack })}
        >
          <div className="body-chart__switch-text">
            {t(`bodyChartImage.${getBodyPosition(!selection.isBack)}`)}
          </div>
          <img width={50} height={50} src={bodySwitchIcon} alt="switch-body" />
        </div>
      </Flex>
    </div>
  );
}
