const modalType = {
  ROLE_MANAGER: 'RoleManager',
  CUSTOM_FILTER: 'CustomFilter',
  SITE: 'Site',
  SATELLITE: 'Satellite',
  USER: 'User',
  MEDICATION: 'Medication',
  APPOINTMENT: 'Appointment',
  CAREPLAN: 'Careplan',
  EXPORT: 'Export',
  CAREPATHWAY: 'Carepathway',
};

export default modalType;
