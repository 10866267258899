import React, { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useOverviewStore } from '../../../Overview.store';
import DataPointType from '../OverviewDataPointsSelection.enum';
import { ComplexScoringType } from '../../../../../Careplans/ComplexScoreSetting/ComplexScoring.type';
import { getColor } from '../../../OverviewDataCard/OverviewDataCard.utils';

const useCaretasksDataService = () => {
  const {
    setOverviewCaretasks,
    overviewCaretasks,
    overviewDataPoints,
    setOverviewDataPoints,
  } = useOverviewStore(
    useShallow(state => ({
      overviewCaretasks: state.overviewCaretasks,
      setOverviewCaretasks: state.setOverviewCaretasks,
      overviewDataPoints: state.overviewDataPoints,
      setOverviewDataPoints: state.setOverviewDataPoints,
    }))
  );
  const [disabledScoringId, setDisabledScoringId] = useState([]);
  const { t } = useTranslation();

  const hasNoResultsBySearch: boolean =
    overviewCaretasks.keyword.length > 0 &&
    overviewCaretasks.items?.length === 0 &&
    !overviewCaretasks.isLoading;

  const hasNoTemplates: boolean =
    overviewCaretasks.keyword.length === 0 &&
    overviewCaretasks.items?.length === 0 &&
    !overviewCaretasks.isLoading;

  const getScoringItem = scoring => {
    const isSelected = disabledScoringId.includes(scoring.id);
    const shouldDisable =
      overviewDataPoints?.selectedDataPoints?.length >= 2 && !isSelected;
    const scoreDescription =
      scoring.description ||
      t('newCareplan_view.complex_score.total_score.text');
    const selectedScoreIndex =
      overviewDataPoints?.selectedDataPoints?.findIndex(
        dataPoint =>
          dataPoint.dataPointType === DataPointType.CARETASK &&
          dataPoint.scoreId === scoring.id
      );

    return { shouldDisable, scoreDescription, selectedScoreIndex };
  };

  const onPageChange = (page: number) =>
    setOverviewCaretasks({
      ...overviewCaretasks,
      items: null,
      page,
    });

  const onSelect = (
    e,
    scoringId,
    templateId,
    scoreTypeId,
    templateName,
    scoreDescription,
    totalScore
  ) => {
    e.preventDefault();
    setDisabledScoringId(prevDisabledScoringId => {
      if (prevDisabledScoringId.includes(scoringId)) {
        return prevDisabledScoringId.filter(id => id !== scoringId);
      }
      return [...prevDisabledScoringId, scoringId];
    });
    const index = overviewDataPoints?.selectedDataPoints?.length;
    const selectedScore = {
      selectedDataPoints: disabledScoringId
        ? [
            {
              dataPointType: DataPointType.CARETASK,
              id: templateId,
              scoreId: scoringId,
              scoreTypeId,
              dataPointName: templateName,
              dataPointDescription: scoreDescription,
              color: getColor(
                { scoreTypeId, dataPointType: DataPointType.CARETASK },
                index
              ),
              maxScore: totalScore,
            },
          ]
        : [],
    };

    if (overviewDataPoints === undefined) {
      setOverviewDataPoints(selectedScore);
    } else {
      const isAlreadySelected = overviewDataPoints.selectedDataPoints?.some(
        dataPoint => dataPoint.scoreId === scoringId
      );

      if (isAlreadySelected) {
        setOverviewDataPoints({
          ...overviewDataPoints,
          selectedDataPoints: overviewDataPoints.selectedDataPoints?.filter(
            dataPoint =>
              (dataPoint.dataPointType === DataPointType.CARETASK &&
                dataPoint.scoreId !== scoringId) ||
              dataPoint.dataPointType === DataPointType.HEALTH_DATA
          ),
        });
      } else {
        setOverviewDataPoints({
          ...overviewDataPoints,
          selectedDataPoints: [
            ...(overviewDataPoints.selectedDataPoints || []),
            ...selectedScore.selectedDataPoints,
          ],
        });
      }
    }
  };

  const getButtonClassName = (scoring, selectedScoreIndex) => {
    return classNames('caretasks_data_button', {
      'caretasks_data_button-overAll-selected':
        scoring.scoringTypeId === ComplexScoringType.OverallScore &&
        selectedScoreIndex !== -1 &&
        selectedScoreIndex !== undefined,
      'caretasks_data_button-first-selected':
        selectedScoreIndex === 0 &&
        scoring.scoringTypeId !== ComplexScoringType.OverallScore,
      'caretasks_data_button-second-selected':
        selectedScoreIndex === 1 &&
        scoring.scoringTypeId !== ComplexScoringType.OverallScore,
    });
  };

  return {
    hasNoResultsBySearch,
    hasNoTemplates,
    onPageChange,
    onSelect,
    getButtonClassName,
    getScoringItem,
  };
};

export default useCaretasksDataService;
