import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Box } from '@ui-common-files/components';

import { formatOptionalAttribute } from './MedicationPlanReport.utils';

import type {
  Medication,
  MedicationPlanPageSection,
} from './MedicationPlanReport.type';

const hasDosingSchedules = (dosingSchedule: Medication['dosingSchedule']) => {
  if (!dosingSchedule) return false;
  const dosingValues = Object.values(dosingSchedule);
  return dosingValues.some(dose => dose !== '0');
};
const Medications = ({ medications }: { medications: Medication[] }) => {
  const medicationRowElements: React.ReactNode[] = [];
  const medicationsLength = medications.length;
  medications.forEach((medication, index) => {
    const isFinalRecord = index === medicationsLength - 1;

    const medicationElement = (
      <tr
        className="medication-report-table-container_columns"
        key={medication.name}
      >
        <td colSpan={2}>
          {medication.activeElements.map((element, elIndex) => (
            <Box key={elIndex}>{element.name}</Box>
          ))}
        </td>
        <td colSpan={2}>{medication.name}</td>
        <td className="medication-report-table-container_align-center">
          {medication.activeElements.map((element, elIndex) => (
            <Box key={elIndex}>{element.awbmng}</Box>
          ))}
        </td>
        <td className="medication-report-table-container_align-center">
          {formatOptionalAttribute(medication.dosageForm)}
        </td>
        {hasDosingSchedules(medication.dosingSchedule) ? (
          [
            <td
              key="morning"
              className="medication-report-table-container_align-center"
            >
              {medication.dosingSchedule.morning}
            </td>,
            <td
              key="noon"
              className="medication-report-table-container_align-center"
            >
              {medication.dosingSchedule.noon}
            </td>,
            <td
              key="evening"
              className="medication-report-table-container_align-center"
            >
              {medication.dosingSchedule.evening}
            </td>,
            <td
              key="night"
              className="medication-report-table-container_align-center"
            >
              {medication.dosingSchedule.night}
            </td>,
          ]
        ) : (
          <td
            colSpan={4}
            className="medication-report-table-container_align-center"
          >
            {medication.freeTextDosingSchedule}
          </td>
        )}
        <td className="medication-report-table-container_align-center">
          {formatOptionalAttribute(medication.dosageUnit)}
        </td>
        <td>{formatOptionalAttribute(medication.notes)}</td>
        <td className="medication-report-table-container_align-center">
          {formatOptionalAttribute(medication.reason)}
        </td>
      </tr>
    );

    medicationRowElements.push(medicationElement);

    if (medication.boundAdditionalLine) {
      const medicationBoundAdditionalElement = (
        <tr key={`${medication.name}-additional`}>
          <td
            colSpan={14}
            className="medication-report-table-container_shifted-container"
          >
            <table>
              <tbody>
                <tr>
                  <td
                    className={classNames(
                      'medication-report-table-container_bound-additional-item',
                      'medication-report-table-container_align-left',
                      !isFinalRecord
                        ? 'medication-report-table-container_shifted-record'
                        : 'medication-report-table-container_shifted-record-final'
                    )}
                  >
                    {medication.boundAdditionalLine}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      );
      medicationRowElements.push(medicationBoundAdditionalElement);
    }
  });

  return <tbody>{medicationRowElements}</tbody>;
};

const MedicationPage = ({
  pageData,
}: {
  pageData: MedicationPlanPageSection[];
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box>
      {pageData.map((page, index) => (
        <Box
          key={`${page.freeTextSubheading}-${index}`}
          className="medication-report-table-container"
        >
          {page.freeTextSubheading && <h1>{page.freeTextSubheading}</h1>}
          {page.medications.length > 0 && (
            <table>
              <thead className="medication-report-table-container_columns">
                <tr>
                  <th colSpan={2} className="">
                    {t('medicationPdfReport.activeIngredient')}
                  </th>
                  <th
                    colSpan={2}
                    className="medication-report-table-container_align-left"
                  >
                    {t('medicationPdfReport.medicationName')}
                  </th>
                  <th>{t('medicationPdfReport.potency')}</th>
                  <th>{t('medicationPdfReport.form')}</th>
                  <th colSpan={4}>
                    <table
                      className={classNames(
                        'medication-report-table-container_dosage-container',
                        'medication-report-table-container_no-border'
                      )}
                    >
                      <thead>
                        <tr>
                          <th className="medication-report-table-container_rotated">
                            {t('medicationPdfReport.dosage.morning')}
                          </th>
                          <th className="medication-report-table-container_rotated">
                            {t('medicationPdfReport.dosage.noon')}
                          </th>
                          <th className="medication-report-table-container_rotated">
                            {t('medicationPdfReport.dosage.evening')}
                          </th>
                          <th className="medication-report-table-container_rotated">
                            {t('medicationPdfReport.dosage.night')}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </th>

                  <th>{t('medicationPdfReport.unit')}</th>
                  <th>{t('medicationPdfReport.notes')}</th>
                  <th>{t('medicationPdfReport.reason')}</th>
                </tr>
              </thead>
              <Medications medications={page.medications} />
            </table>
          )}
          <Box className="medication-report-table-container_footer">
            {page.additionalInfo.map(info => (
              <Box className="medication-report-table-container_footer_item">
                {info.text}
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export { MedicationPage };
