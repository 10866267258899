import storeInfo from '../../../../store';

import { removeMessage } from '../../../../caro-ui-commonfiles/components/FlashMessage/FlashMessages.reducer';

function HideFlashMessage(messageId: number): void {
  storeInfo.store.dispatch(removeMessage(messageId));
}

function DeleteAllModalMessages(): void {
  storeInfo.store.getState().flashMessages.messages.forEach(message => {
    if (message.isModalMessage) HideFlashMessage(message.id);
  });
}

export { HideFlashMessage, DeleteAllModalMessages };
