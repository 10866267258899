import React, { useEffect, useState, useLayoutEffect, useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useOverviewStore } from '../../../Overview.store';

const useOverviewDataModalContentService = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    overviewCaretasks,
    overviewActivityStatus,
  } = useOverviewStore(
    useShallow(state => ({
      overviewCaretasks: state.overviewCaretasks,
      overviewActivityStatus: state.overviewActivityStatus,
    }))
  );

  useEffect(() => {
    if (overviewCaretasks?.items && overviewActivityStatus?.activities) {
      setIsLoading(false);
    }
  }, [overviewCaretasks, overviewActivityStatus]);

  return {
    isLoading,
  };
};

export default useOverviewDataModalContentService;
