function toBoolean(input) {
  if (typeof input === 'boolean') {
    return input;
  }
  if (typeof input === 'string') {
    return input.toLowerCase() === 'true';
  }
  return false;
}

module.exports = toBoolean;
