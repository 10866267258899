/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  DataTable,
  Icon,
  CircularProgressBar as ImageProgressBar,
  IconButton,
  Box,
  OptionButtonDropdown,
  Tooltip,
  ICDViewer,
  Flex,
  SatellitesTableList,
} from '@ui-common-files/components';
import Animation from '../CellElements/Animationcomponent';

import {
  Position,
  ActionColors,
  Greys,
  ComponentTranslationKeys,
  ActionTranslationKeys,
} from '@ui-common-files/utils';
import {
  navigation as NavigationJSON,
  checkUserCredential,
  roles,
  useWindowWidthListener,
} from '@utils';
import { onTableChangeAction } from '../../../../actions/Datatables/patients';
import {
  processDataForPatientTable,
  getGenderTranslation,
  getActiveOptionPageSize,
  getBackgroundByHealtIndicatorCategory,
  getIconNameForHealthIndicatorByCategory,
  getFormattedBirthdate,
  getHealthStatusTranslationByCategory,
} from './indexPatientsLogic';

const PatiensListView = ({
  data,
  loading,
  onDelete,
  onEditPatient,
  onShowPatient,
  setFlashMessage,
  onChange,
}) => {
  const { t, i18n } = useTranslation();
  const { user } = useSelector(state => state.session);
  const tableReduxState = useSelector(state => state.patientTable);
  const services = useSelector(state => state.services);
  const reduxDispatcher = useDispatch();
  const [dataInfo, setDataInfo] = useState([]);
  const [isPatientDemographicData, setIsPatientDemographicData] =
    useState(false);
  const responsive = useWindowWidthListener();
  const history = useHistory();

  useEffect(() => {
    data && setIsPatientDemographicData(data.isPatientDemographicData);
    setDataInfo(processDataForPatientTable(data));
  }, [data]);

  const items = [
    { key: '10', value: '10' },
    { key: '25', value: '25' },
    { key: '50', value: '50' },
    { key: '100', value: '100' },
  ];

  const tableProps = {
    defaultSorted: [
      {
        id: 'healthIndicatorId',
        desc: false,
      },
    ],
    onSortedChange: newSorted => {
      reduxDispatcher(
        onTableChangeAction({
          page: tableReduxState.currPage,
          pageSize: tableReduxState.limit,
          dir: newSorted[0].desc ? 'desc' : 'asc',
          column: newSorted[0].id,
        })
      );
    },
    previousText: t('datatable.previous'),
    nextText: t('datatable.next'),
    noDataText: t('common_labels.label_noData'),
    pageText: t('datatable.showing'),
    ofText: t('datatable.of'),
    rowsText: t('datatable.entries'),
    rowsSelectorText: t('datatable.recordsPerPage'),
    getTdProps: (_, row, col) => ({
      onClick: async () => {
        const hasShowCredential = await checkUserCredential(
          ComponentTranslationKeys.PATIENT,
          ActionTranslationKeys.SHOW
        );
        const hasLessThanTwoSites = row.original.site.length <= 2;
        if (hasShowCredential.type) {
          setFlashMessage({
            type: hasShowCredential.type,
            content: hasShowCredential.content,
          });
        } else if (
          (col.id !== 'site' || hasLessThanTwoSites) &&
          col.id !== 'rowOptions' &&
          hasShowCredential
        ) {
          history.push(
            `/${NavigationJSON.PATIENT_OVERVIEW}/${row.original.id}`,
            {
              patientInfo: {
                ...row.original,
                profilePicture: row.original.healthIndicatorId.profilePicture,
                scoringSchemeCategory:
                  row.original.healthIndicatorId.scoringSchemeCategory,
                institutions: row.original.site,
              },
              shouldOpenChat: !responsive,
            }
          );
        }
      },
      style: {
        backgroundColor:
          col.id === 'healthIndicatorId'
            ? getBackgroundByHealtIndicatorCategory(
                row.original.healthIndicatorId.scoringSchemeCategory.id
              )
            : '',
        borderRadius: '4px 0 0 4px',
      },
    }),
    getTrProps: () => {
      return {
        style: {
          cursor: 'pointer',
          borderRadius: 4,
        },
      };
    },
  };

  const HealthIndicatorComponent = ({ category, profilePicture }) => {
    const responsiveClass = responsive ? ' --responsive' : '';
    return (
      <div className={`patients-health-indicator${responsiveClass}`}>
        <div className={`patients-health-indicator__icon${responsiveClass}`}>
          <Icon
            name={getIconNameForHealthIndicatorByCategory(category.id)}
            size={isPatientDemographicData ? 30 : 24}
            fill={category.color}
          />
        </div>
        {!isPatientDemographicData && (
          <Box margin={responsive ? '0 0 0 12px' : 0}>
            <ImageProgressBar
              value={100}
              size={48}
              color={category.color}
              imgSrc={profilePicture}
              strokeWidth={3}
            />
          </Box>
        )}
      </div>
    );
  };

  const SortHeaderComponent = ({ columId, text }) => {
    const textRef = useRef(null);
    const [isTextTruncatted, setIsTextTruncatted] = useState(false);

    useEffect(() => {
      const isTruncatted = () => {
        const isTruncattedLayout =
          textRef.current &&
          textRef.current.scrollWidth > textRef.current.clientWidth;
        setIsTextTruncatted(isTruncattedLayout);
      };
      isTruncatted();
    });

    const renderHeaderContentComponent = () => (
      <Flex alignItems="center" otherStyles={{ width: '100%' }}>
        {tableReduxState.column === columId ? (
          <Box width={20}>
            <Icon
              name="sortArrow"
              size={20}
              className={
                tableReduxState.column === columId &&
                tableReduxState.dir === 'desc'
                  ? 'desc-sort'
                  : ''
              }
            />
          </Box>
        ) : (
          <Box width={20}>
            <Icon
              name="sortArrowDefault"
              size={20}
              fill={Greys.LIGHT_GREY_50}
            />
          </Box>
        )}
        <div
          className="patients-table-header--text"
          style={{ display: 'inline-block' }}
          ref={textRef}
        >
          {text}
        </div>
      </Flex>
    );

    const renderResponsiveHeader = () => {
      if (isTextTruncatted) {
        return (
          <Tooltip position={Position.bottom} content={text}>
            {renderHeaderContentComponent()}
          </Tooltip>
        );
      }
      return renderHeaderContentComponent();
    };

    return renderResponsiveHeader();
  };

  const OptionsComponent = ({ original }) => {
    const renderOptionsButtons = () => (
      <>
        <Box margin="0 10px 0 0">
          <IconButton
            type="button"
            name="view"
            size={30}
            onClick={() => {
              onShowPatient(
                original.id,
                original.healthIndicatorId.healthScore,
                original.healthIndicatorId.scoringSchemeCategory
              );
            }}
            tooltipText={t('actions.show')}
          />
        </Box>
        <Box margin="0 10px 0 0">
          <IconButton
            type="button"
            name="edit"
            size={30}
            onClick={() => {
              onEditPatient(original.id);
            }}
            tooltipText={t('actions.edit')}
          />
        </Box>
        <Box margin="0 10px 0 0">
          <IconButton
            type="button"
            name="delete"
            size={30}
            fill={ActionColors.DELETE}
            onClick={() => {
              onDelete(
                original.id,
                original.firstname,
                original.lastname,
                original.collectionId
              );
            }}
            tooltipText={t('actions.delete')}
          />
        </Box>
      </>
    );
    const renderDropdownOptions = () => (
      <OptionButtonDropdown
        onClick={(e, item) => {
          if (item.key === 'edit') {
            onEditPatient(original.id);
          }
          if (item.key === 'delete') {
            onDelete(
              original.id,
              original.firstname,
              original.lastname,
              original.collectionId
            );
          }
          if (item.key === 'show') {
            onShowPatient(
              original.id,
              original.healthIndicatorId.healthScore,
              original.healthIndicatorId.scoringSchemeCategory
            );
          }
        }}
      />
    );

    if (responsive) {
      return renderDropdownOptions();
    }
    return renderOptionsButtons();
  };

  const TooltipContent = id => {
    return (
      <Flex>
        <Box margin="0 4px 0 0">{`${t('patients_view.healthStatus')}:`}</Box>
        <b>{getHealthStatusTranslationByCategory(id)}</b>
      </Flex>
    );
  };

  const columns = useMemo(() => {
    const columns = [];
    const demographicWidth = 170;
    columns.push({
      Header: ({ column }) => (
        <SortHeaderComponent
          columId={column.id}
          text={t('patients_view.priority')}
        />
      ),
      accessor: 'healthIndicatorId',
      headerClassName: 'patients-table__header',
      filterable: false,
      maxWidth: isPatientDemographicData ? 48 : responsive ? 80 : 100,
      style: {
        paddingTop: isPatientDemographicData && '10px',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginRight: isPatientDemographicData ? 68 : 20,
      },
      headerStyle: {
        paddingLeft: 0,
        marginRight: 20,
        minWidth: '100px',
      },
      Cell: ({ value, index }) => {
        const { scoringSchemeCategory, profilePicture } = value;
        return (
          <Tooltip
            position={Position.bottom}
            content={TooltipContent(scoringSchemeCategory.id)}
          >
            <Animation isOn delay={index * 50}>
              <HealthIndicatorComponent
                category={scoringSchemeCategory}
                profilePicture={profilePicture}
              />
            </Animation>
          </Tooltip>
        );
      },
    });
    columns.push({
      Header: ({ column }) => (
        <SortHeaderComponent
          columId={column.id}
          text={t('patients_view.label_compliance')}
        />
      ),
      headerClassName: 'patients-table__header',
      accessor: 'compliance',
      filterable: false,
      minWidth: isPatientDemographicData ? demographicWidth : 120,
      headerStyle: {
        paddingLeft: 0,
      },
      style: {
        paddingLeft: 20,
      },
    });
    if (isPatientDemographicData) {
      columns.push({
        Header: ({ column }) => (
          <SortHeaderComponent
            columId={column.id}
            text={t('patients_view.label_pseudonymCode')}
          />
        ),
        headerClassName: 'patients-table__header',
        accessor: 'pseudonymCode',
        filterable: false,
        minWidth: demographicWidth,
        headerStyle: {
          paddingLeft: 0,
        },
        style: {
          paddingLeft: 20,
          wordBreak: 'break-word',
          Cell: ({ value, index }) => {
            const { scoringSchemeCategory, profilePicture } = value;
            return (
              <Tooltip
                position={Position.bottom}
                content={TooltipContent(scoringSchemeCategory.id)}
              >
                <Animation isOn delay={index * 50}>
                  <HealthIndicatorComponent
                    category={scoringSchemeCategory}
                    profilePicture={profilePicture}
                  />
                </Animation>
              </Tooltip>
            );
          },
        },
      });
    }

    columns.push({
      Header: ({ column }) => (
        <SortHeaderComponent
          columId={column.id}
          text={`${t('patients_view.label_medicalRecord')}`}
        />
      ),
      headerClassName: 'patients-table__header',
      accessor: 'medicalrecord',
      filterable: false,
      minWidth: isPatientDemographicData ? demographicWidth : 140,
      headerStyle: {
        paddingLeft: 0,
      },
      style: {
        paddingLeft: 20,
        wordBreak: 'break-word',
      },
    });
    if (!isPatientDemographicData) {
      columns.push({
        Header: ({ column }) => (
          <SortHeaderComponent
            columId={column.id}
            text={t('patients_view.label_firstName')}
          />
        ),
        headerClassName: 'patients-table__header',
        accessor: 'firstname',
        filterable: false,
        minWidth: isPatientDemographicData ? demographicWidth : 120,
        headerStyle: {
          paddingLeft: 0,
        },
        style: {
          paddingLeft: 20,
          wordBreak: 'break-word',
        },
      });

      columns.push({
        Header: ({ column }) => (
          <SortHeaderComponent
            columId={column.id}
            text={t('patients_view.label_lastName')}
          />
        ),
        headerClassName: 'patients-table__header',
        accessor: 'lastname',
        filterable: false,
        minWidth: isPatientDemographicData ? demographicWidth : 120,
        headerStyle: {
          paddingLeft: 0,
        },
        style: {
          paddingLeft: 20,
          wordBreak: 'break-word',
        },
      });
    }
    columns.push({
      Header: ({ column }) => (
        <SortHeaderComponent
          columId={column.id}
          text={t('patients_view.label_gender')}
        />
      ),
      headerClassName: 'patients-table__header',
      accessor: 'gender',
      filterable: false,
      minWidth: isPatientDemographicData ? demographicWidth : 120,
      headerStyle: {
        paddingLeft: 0,
      },
      style: {
        paddingLeft: 20,
      },
      Cell: ({ value }) => <span>{getGenderTranslation(value)}</span>,
    });
    if (isPatientDemographicData)
      columns.push({
        Header: ({ column }) => (
          <SortHeaderComponent
            columId={column.id}
            text={t('patientOverview.labelAge')}
          />
        ),
        headerClassName: 'patients-table__header',
        accessor: 'age',
        filterable: false,
        minWidth: isPatientDemographicData ? demographicWidth : 120,
        headerStyle: {
          paddingLeft: 0,
        },
        style: {
          paddingLeft: 20,
        },
        Cell: ({ original, value }) => {
          const { isBirthdayHardcoded } = original;
          return !isBirthdayHardcoded ? value : null;
        },
      });
    if (!isPatientDemographicData)
      columns.push({
        Header: ({ column }) => (
          <SortHeaderComponent
            columId={column.id}
            text={t('patients_view.label_dateOfBirth')}
          />
        ),
        headerClassName: 'patients-table__header',
        accessor: 'birthdate',
        filterable: false,
        minWidth: isPatientDemographicData ? demographicWidth : 120,
        headerStyle: {
          paddingLeft: 0,
        },
        style: {
          paddingLeft: 20,
        },
        Cell: ({ original, value }) => {
          const { isBirthdayHardcoded } = original;
          return !isBirthdayHardcoded ? (
            <span>{getFormattedBirthdate(value)}</span>
          ) : null;
        },
      });
    columns.push({
      Header: () => {
        return <span>{t('newCareplan_view.icd_code')}</span>;
      },
      accessor: 'icds',
      sortable: false,
      filterable: false,
      style: { overflow: 'visible' },
      minWidth: isPatientDemographicData ? demographicWidth : 120,
      Cell: ({ value }) => {
        return <ICDViewer icds={value} />;
      },
    });
    if (services.satelliteRead && user.role.name !== roles.EXTERNAL_HCP)
      columns.push({
        Header: t('types.componentType.satellites'),
        accessor: 'satellites',
        sortable: false,
        filterable: false,
        minWidth: isPatientDemographicData ? demographicWidth : 120,
        Cell: ({ value: satellites }) =>
          satellites.length > 0 ? (
            <SatellitesTableList satellites={satellites} />
          ) : null,
      });
    columns.push({
      Header: '',
      accessor: 'rowOptions',
      sortable: false,
      filterable: false,
      minWidth: responsive ? 50 : 170,
      maxWidth: responsive ? 50 : 170,
      style: { overflow: 'visible', display: 'flex' },
      Cell: OptionsComponent,
    });
    return columns;
  }, [
    responsive,
    tableReduxState.dir,
    tableReduxState.column,
    isPatientDemographicData,
    i18n.language,
    services,
  ]);

  return (
    <DataTable
      data={dataInfo}
      columns={columns}
      pageSize
      pageSizeOptions={items}
      pagination
      isLoading={loading}
      onTableChange={onChange}
      page={tableReduxState.currPage}
      pages={data ? Math.ceil(data.recordsTotal / tableReduxState.limit) : 1}
      totalRecord={data ? data.recordsTotal : 0}
      tableProps={tableProps}
      pageSizeActiveOption={getActiveOptionPageSize(
        items,
        tableReduxState.limit
      )}
    />
  );
};

export default PatiensListView;
