import { Dispatch, SetStateAction } from 'react';

interface UserValues {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  profilePicture: string;
  ward: string;
  currentPassword: string;
  password: string;
  repeatPassword: string;
  currentEmail: string;
  role: {
    value: number;
    label: string;
  };
  oldRole: {
    value: number;
    label: string;
  };
  originSite: string;
  isTwoFactorAuthentication: string;
  isTermsAndConditions: string;
  isPrivacyPolicy: string;
  isConsentForTracking: string;
  emailNotification: string;
}

export interface UpdatePasswordComponentProps {
  showEditPassword: boolean;
  showHidePassword: () => unknown;
  values: UserValues;
  setValues: Dispatch<SetStateAction<UserValues>>;
  setHasErrors: Dispatch<SetStateAction<{ [key: string]: string }>>;
  hasErrors: {
    [key: string]: string;
  };
  onValueChange: (value: string, type: string) => unknown;
}

export interface UpdatePasswordComponentServiceProps {
  setHasErrors: Dispatch<SetStateAction<{ [key: string]: string }>>;
  showEditPassword: boolean;
  values: UserValues;
  setValues: Dispatch<SetStateAction<UserValues>>;
  onValueChange: (value: string, type: string) => unknown;
}

export type CurrentPasswordCheckRequest = {
  currentPassword: string;
};

export type CurrentPasswordCheckResponse = boolean;

export enum CurrentPasswordButtonState {
  Active = 'Active',
  Inactive = 'Inactive',
  Validating = 'Validating',
}
