
const setThresholdValueForComponent = (key, index, optionIndex, value, componentCollection) => {
    const updatedComponentCollection = [...componentCollection];
    if (key !== 'delete') {
      updatedComponentCollection[index][key].thresholds = value;
    } else {
      const { thresholds } = updatedComponentCollection[
        index
      ].thresholdCollection;
      if (thresholds.length > 0 && thresholds[optionIndex]) {
        const deletedThresholdId = thresholds[optionIndex].name.thresholdId;
        if (deletedThresholdId) {
          updatedComponentCollection[
            index
          ].thresholdCollection.deleteThresholdIds.push(deletedThresholdId);
        }
        updatedComponentCollection[index].thresholdCollection.thresholds.splice(
          optionIndex,
          1
        );
      }
    }
    return updatedComponentCollection
  };

export {
    setThresholdValueForComponent,
}