import React from 'react';
import Icon from '../Icon/Icon';
import { Greys } from '../../utils/colors';

import '../../styles/main.scss';

const BreadcrumbSeparator = ({ ...props }) => {
  return <Icon name="next" size={24} fill={Greys.LIGHT_GREY} {...props} />;
};

export default BreadcrumbSeparator;
