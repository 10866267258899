import moment from 'moment';
import { repeatTypesEnum, timeOfDay } from './recurrenceUtils';
import { differenceBetweenTwoDatesInDays } from '../dateUtils';

const RecurrenceValidation = (
  recurrenceSettings,
  setHasErrors,
  hasErrors,
  setStartingArrays,
  comesFromTemplateSettings
) => {
  const {
    repeatType,
    lifespan,
    lifespanUnit,
    weeklyRecurrence,
    occurrenceCount,
  } = recurrenceSettings;

  const repeatInterval = recurrenceSettings.repeatInterval.value;

  const getSortedDailyOccurrences = () => {
    const { morning, midday, evening, night, customTimeChecked, customTime } =
      recurrenceSettings;
    const dailyOccurrences = [];
    if (morning) dailyOccurrences.push(timeOfDay.MORNING);
    if (midday) dailyOccurrences.push(timeOfDay.MIDDAY);
    if (evening) dailyOccurrences.push(timeOfDay.EVENING);
    if (night) dailyOccurrences.push(timeOfDay.NIGHT);
    if (customTimeChecked !== false) dailyOccurrences.push(customTime.hour);
    if (dailyOccurrences.length) dailyOccurrences.sort((a, b) => a - b);

    return dailyOccurrences;
  };

  const getWeeklyOccurrences = () => {
    const checkedDays = [];
    if (weeklyRecurrence) {
      weeklyRecurrence.map((checked, i) => {
        if (checked) checkedDays.push(i + 1);
      });
    }
    return checkedDays;
  };

  const startingDays = getWeeklyOccurrences();

  const startingTimes = getSortedDailyOccurrences();

  const checkStartDate = () => {
    if (comesFromTemplateSettings) return false;
    let startDateTime = moment(recurrenceSettings.startDate);
    if (!startDateTime.isValid()) return true;
    if (
      startingTimes.length > 0 &&
      lifespanUnit.value === repeatTypesEnum.HOURLY
    ) {
      startDateTime = startDateTime.hour(
        parseInt(startingTimes[0], 10) + parseInt(lifespan, 10)
      );
      return startDateTime.isBefore(moment());
    }
    return startDateTime.isBefore(moment().format('YYYY-MM-DD'));
  };
  const taskValidityInDays = differenceBetweenTwoDatesInDays(
    recurrenceSettings.startDate,
    recurrenceSettings.endDate
  );

  const isSingleOccurrenceWithCount =
    Number(occurrenceCount) === 1 && recurrenceSettings.occurrenceCountCheck;

  const isSingleOccurrenceWithDates =
    !recurrenceSettings.occurrenceCountCheck && taskValidityInDays + 1 === 1;

  const extendedLifespanSingleRepetition =
    Number(repeatInterval) === 1 &&
    (isSingleOccurrenceWithCount || isSingleOccurrenceWithDates) &&
    Number(lifespan) >= 1;

  const checkForOverlap = (
    startArray,
    lifespanUnitFactor,
    repeatFrequency,
    lifespanDuration,
    checkSubsequentTime = true
  ) => {
    const lifespanDurationNum = parseFloat(lifespanDuration, 10);
    if (lifespanDuration > repeatFrequency * lifespanUnitFactor) {
      return 'recurrence.repeatingFrequencyError';
    }
    if (startArray.length < 2) {
      return false;
    }

    const endArray = [];
    let end;
    startArray.map(start => {
      end =
        (start + lifespanDurationNum) / lifespanUnitFactor > 1
          ? (start + lifespanDurationNum) % lifespanUnitFactor
          : start + lifespanDurationNum;
      endArray.push(end);
      return null;
    });
    let currStart = startArray[0];
    let currEnd = endArray[endArray.length - 1];

    if (
      checkSubsequentTime &&
      currStart < currEnd &&
      currEnd - currStart < lifespanDurationNum
    )
      return 'recurrence.weekdaysError';

    for (let i = 0; i < endArray.length - 1; i++) {
      for (let j = i + 1; j < startArray.length; j++) {
        currStart = startArray[j];
        currEnd = endArray[i];
        if (currStart >= currEnd) {
          if (currStart - startArray[j - 1] < lifespanDurationNum)
            return 'recurrence.dailyOccurrenceError';
          break;
        } else if (
          currStart < currEnd &&
          currEnd - currStart < lifespanDurationNum
        ) {
          return 'recurrence.dailyOccurrenceError';
        }
      }
    }
    return false;
  };

  const checkIfLifespanIsOverlapping = () => {
    const numDaysInYear = 365;
    const selectedUnit = lifespanUnit.value;
    let weeklyOverlap;
    let convertedLifespan;
    let lifespanUnitFactor;
    let isNotOverlapping;

    switch (repeatType.value) {
      case repeatTypesEnum.DAILY:
        if (selectedUnit === repeatTypesEnum.HOURLY) {
          if (extendedLifespanSingleRepetition && comesFromTemplateSettings) {
            isNotOverlapping = true;
          } else {
            lifespanUnitFactor = 24;
            return checkForOverlap(startingTimes, 24, repeatInterval, lifespan);
          }
        }
        if (selectedUnit === repeatTypesEnum.DAILY) {
          if (extendedLifespanSingleRepetition) {
            isNotOverlapping = true;
          } else {
            isNotOverlapping =
              (lifespan <= repeatInterval && startingTimes.length === 1) ||
              lifespan === 1;
          }
          return isNotOverlapping ? false : 'recurrence.repeatDailyOverlap';
        }
        return '';
      case repeatTypesEnum.WEEKLY:
        if (extendedLifespanSingleRepetition) {
          return false;
        }
        if (selectedUnit === repeatTypesEnum.HOURLY) {
          lifespanUnitFactor = 24;
          const lifespanLimitInDays = lifespan / 24 - 1;
          isNotOverlapping = checkForOverlap(
            startingDays,
            lifespanUnitFactor,
            repeatInterval,
            lifespanLimitInDays
          );
          if (lifespan < lifespanUnitFactor) return isNotOverlapping;
          weeklyOverlap = checkForOverlap(
            startingDays,
            7,
            repeatInterval,
            lifespan / lifespanUnitFactor
          );
          isNotOverlapping = !weeklyOverlap
            ? isNotOverlapping
            : !isNotOverlapping
            ? weeklyOverlap
            : 'recurrence.dailyRepeatFrequencyError';
          return isNotOverlapping;
        } else {
          if (startingTimes.length > 1) return 'recurrence.repeatDailyOverlap';
          lifespanUnitFactor = 7;
          convertedLifespan =
            selectedUnit === repeatTypesEnum.DAILY ? lifespan : lifespan * 7;
          return checkForOverlap(
            startingDays,
            lifespanUnitFactor,
            repeatInterval,
            convertedLifespan,
            true
          );
        }
      case repeatTypesEnum.MONTHLY:
        lifespanUnitFactor = 30;
        if (selectedUnit === repeatTypesEnum.HOURLY) {
          lifespanUnitFactor = 24;
          return checkForOverlap(
            startingTimes,
            lifespanUnitFactor,
            repeatInterval * 30,
            lifespan
          );
        }

        if (extendedLifespanSingleRepetition) {
          return false;
        }

        if (selectedUnit === repeatTypesEnum.DAILY) {
          isNotOverlapping = lifespan <= repeatInterval * lifespanUnitFactor;
          return isNotOverlapping ? false : 'recurrence.repeatDailyOverlap';
        }

        if (selectedUnit === repeatTypesEnum.WEEKLY) {
          isNotOverlapping =
            lifespan * 7 <= repeatInterval * lifespanUnitFactor;
          return isNotOverlapping ? false : 'recurrence.repeatWeeklyOverlap';
        }

        isNotOverlapping = lifespan <= repeatInterval;
        return isNotOverlapping ? false : 'recurrence.repeatMonthlyOverlap';

      case repeatTypesEnum.YEARLY:
        if (selectedUnit === repeatTypesEnum.HOURLY) {
          lifespanUnitFactor = 24;
          return checkForOverlap(
            startingTimes,
            lifespanUnitFactor,
            repeatInterval * numDaysInYear,
            lifespan
          );
        }

        if (extendedLifespanSingleRepetition) {
          return false;
        }

        if (selectedUnit === repeatTypesEnum.DAILY) {
          isNotOverlapping = lifespan <= repeatInterval * numDaysInYear;
          return isNotOverlapping ? false : 'recurrence.repeatDailyOverlap';
        }
        if (selectedUnit === repeatTypesEnum.WEEKLY) {
          isNotOverlapping = lifespan * 7 <= repeatInterval * numDaysInYear;
          return isNotOverlapping ? false : 'recurrence.repeatWeeklyOverlap';
        }

        if (selectedUnit === repeatTypesEnum.MONTHLY) {
          isNotOverlapping = lifespan <= repeatInterval * 12;
          return isNotOverlapping ? false : 'recurrence.repeatMonthlyOverlap';
        }

        isNotOverlapping = lifespan <= repeatInterval;
        return isNotOverlapping ? false : 'recurrence.repeatAnnualOverlap';

      default:
        return false;
    }
  };

  const temp = hasErrors;
  let weekInterval = [];
  if (!comesFromTemplateSettings) {
    weekInterval = [
      recurrenceSettings.startDate
        ? recurrenceSettings.startDate.getDay() !== 0
          ? recurrenceSettings.startDate.getDay()
          : 7
        : null,
      !recurrenceSettings.occurrenceCountCheck && recurrenceSettings.endDate
        ? recurrenceSettings.endDate.getDay() !== 0
          ? recurrenceSettings.endDate.getDay()
          : 7
        : null,
    ];
  }

  let showWeekError = true;
  startingDays.length &&
    startingDays.map(selectedDay => {
      if (weekInterval.includes(selectedDay)) showWeekError = false;
    });

  temp.startDate = checkStartDate();
  temp.lifespan = checkIfLifespanIsOverlapping();
  temp.recurrence = startingTimes.length < 1;
  temp.recurrenceRepeat =
    repeatType.value === repeatTypesEnum.WEEKLY
      ? startingDays.length < 1
      : false;

  temp.selectedWeekDaysError =
    repeatType.value === repeatTypesEnum.WEEKLY &&
    !comesFromTemplateSettings &&
    !recurrenceSettings.occurrenceCountCheck
      ? startingDays.length
        ? showWeekError
        : false
      : false;
  temp.endDate = false;
  if (!recurrenceSettings.occurrenceCountCheck) {
    temp.endDate = moment(recurrenceSettings.endDate).isValid()
      ? moment(recurrenceSettings.endDate).isBefore(
          moment(recurrenceSettings.startDate)
        )
      : true;
  }
  temp.occurrenceCount = !recurrenceSettings.occurrenceCountCheck
    ? false
    : occurrenceCount < 1;
  temp.recurrenceTag = recurrenceSettings.recurrenceTag;
  setHasErrors({ ...temp });
  setStartingArrays({
    startingDays,
    startingTimes,
  });
};

export default RecurrenceValidation;
