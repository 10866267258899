export const bloodPressureTypes = {
  OPTIMAL: { value: 'optimal', index: 0 },
  NORMAL: { value: 'normal', index: 1 },
  HIGH_NORMAL: { value: 'highNormal', index: 2 },
  HYPERTENSION_GRAD_1: { value: 'grade1Hypertension', index: 3 },
  HYPERTENSION_GRAD_2: { value: 'grade2Hypertension', index: 4 },
  HYPERTENSION_GRAD_3: { value: 'grade3Hypertension', index: 5 },
};

function getDiastolicBloodPressure(diastolicValue: number) {
  if (diastolicValue <= 80) {
    return bloodPressureTypes.OPTIMAL;
  }
  if (diastolicValue > 80 && diastolicValue <= 84) {
    return bloodPressureTypes.NORMAL;
  }
  if (diastolicValue >= 85 && diastolicValue <= 89) {
    return bloodPressureTypes.HIGH_NORMAL;
  }
  if (diastolicValue >= 90 && diastolicValue <= 99) {
    return bloodPressureTypes.HYPERTENSION_GRAD_1;
  }
  if (diastolicValue >= 100 && diastolicValue <= 109) {
    return bloodPressureTypes.HYPERTENSION_GRAD_2;
  }
  if (diastolicValue >= 110) {
    return bloodPressureTypes.HYPERTENSION_GRAD_3;
  }
  return null;
}

function getSystolicBloodPressure(systolicValue: number) {
  if (systolicValue < 120) {
    return bloodPressureTypes.OPTIMAL;
  }
  if (systolicValue >= 120 && systolicValue <= 129) {
    return bloodPressureTypes.NORMAL;
  }
  if (systolicValue >= 130 && systolicValue <= 139) {
    return bloodPressureTypes.HIGH_NORMAL;
  }
  if (systolicValue >= 140 && systolicValue <= 159) {
    return bloodPressureTypes.HYPERTENSION_GRAD_1;
  }
  if (systolicValue >= 160 && systolicValue <= 179) {
    return bloodPressureTypes.HYPERTENSION_GRAD_2;
  }
  if (systolicValue >= 180) {
    return bloodPressureTypes.HYPERTENSION_GRAD_3;
  }
  return null;
}

export function calculateBloodPressureResult(
  systolic: number,
  diastolic: number
) {
  const systolicResult = getSystolicBloodPressure(systolic);
  const diastolicResult = getDiastolicBloodPressure(diastolic);

  if (systolicResult === null || diastolicResult === null) {
    return null;
  }
  if (systolicResult.index === diastolicResult.index) {
    return systolicResult;
  }
  if (systolicResult.index > diastolicResult.index) {
    return systolicResult;
  }
  return diastolicResult;
}
