import { ApiClient, ShowFlashMessage } from '@global/Services';
import { FlashMessageTypes } from '@ui-common-files/types/components';
import serviceConfig from '../../../serviceConfig.json';

export function getAllSitesForModals() {
  return ApiClient.POST({
    url: serviceConfig.brokerService.getAllSitesForModals.uri,
  });
}

export function addSatellite(payload) {
  return ApiClient.POST({
    url: serviceConfig.brokerService.createSatellite.uri,
    payload,
    errorCallback: error => {
      if (error.response.status === 409) {
        ShowFlashMessage({
          type: error.response?.data?.type || FlashMessageTypes.Error,
          messageKey: error.response?.data?.content,
          message: error.message,
          isModalMessage: false,
        });
      }
    },
  });
}

export function getAllSatellites(controller, query, isActive) {
  const { start, length, dir, column, value } = query;

  return ApiClient.GET({
    signal: controller.signal,
    url: serviceConfig.brokerService.getAllSatellites.uri,
    payload: {
      start,
      length,
      dir,
      column,
      isActive,
      value: value.trim(),
    },
  });
}

export function editSatelliteRequest(id: string) {
  return ApiClient.GET({
    url: serviceConfig.brokerService.getSatelliteById.uri + id,
  });
}

export function updateSatelliteRequest(payload, id) {
  return ApiClient.PUT({
    url: serviceConfig.brokerService.getSatelliteById.uri + id,
    payload,
  });
}

export function fetchSatellites(siteId) {
  return ApiClient.GET({
    url:
      serviceConfig.brokerService.getActiveSatellitesByInstitutionId.uri +
      siteId,
  });
}

export function activateSatelliteRequest(id: string) {
  return ApiClient.GET({
    url: serviceConfig.brokerService.activateSatellite.uri + id,
  });
}

export function deactivateSatelliteRequest(id: string) {
  return ApiClient.GET({
    url: serviceConfig.brokerService.deactivateSatellite.uri + id,
  });
}
