import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Button, Flex, Modal } from '@ui-common-files/components';
import { ButtonType, ButtonVariant } from '@ui-common-files/utils';

import { HideFlashMessage } from '@global/Services';

import CustomLoader from '../../../../../Login/CustomLoader';
import {
  PromptContent,
  PromptFooter,
} from '../../../../../common/ConfirmationPrompt';

import useCaretaskAnsweringFooterService from './CaretaskAnsweringFooter.service';
import { CaretaskAnsweringFooterProps } from './CaretaskAnsweringFooter.type';
import { previousComponent } from '../../CaretaskAnswering.reducer';

import './CaretaskAnsweringFooter.style.scss';

export default function CaretaskAnsweringFooter({
  setCaretaskId,
  setAnsweredCaretask,
  occurrenceId,
  setFlashMessage,
}: CaretaskAnsweringFooterProps) {
  const {
    t,
    submitIsLoading,
    dispatch,
    isLastComponent,
    openModal,
    closeModal,
    cancelHandler,
    confirmHandler,
    disablePreviousButton,
    disableNextFinishButton,
    shouldDisplayPreviousButton,
    getSubmitAPI,
    submitButtonLabel,
    nextButtonLabel,
    handleNextButtonClick,
  } = useCaretaskAnsweringFooterService(
    setCaretaskId,
    setAnsweredCaretask,
    occurrenceId,
    setFlashMessage
  );

  return (
    <>
      <Modal
        isConfirmationDialog
        title={t('warningMessage.leave_careplan_template')}
        contentComponent={
          <PromptContent
            message={ReactHtmlParser(t('warningMessage.templateLoss'))}
          />
        }
        footerComponent={
          <PromptFooter
            close={closeModal}
            confirmHandler={confirmHandler}
            btnLabelRight={t('common_buttons.leave_without_saving')}
          />
        }
        openModal={openModal}
        onClose={closeModal}
        hideFlashMessage={HideFlashMessage}
      />
      <Flex
        className="answer-view__caretask-footer"
        justifyContent="space-between"
      >
        <Button
          label={t('common_buttons.cancel')}
          type={ButtonType.RESET}
          variant={ButtonVariant.NO_BORDER}
          onClick={cancelHandler}
        />
        <Flex>
          {shouldDisplayPreviousButton && (
            <Button
              label={t('common_buttons.previous')}
              type={ButtonType.BUTTON}
              variant={ButtonVariant.OUTLINED}
              onClick={() => dispatch(previousComponent())}
              style={{ marginRight: 10 }}
              disabled={disablePreviousButton()}
            />
          )}
          {!isLastComponent && !submitIsLoading && (
            <Button
              label={t(nextButtonLabel)}
              type={ButtonType.BUTTON}
              variant={ButtonVariant.CONTAINED}
              onClick={() => handleNextButtonClick()}
              disabled={disableNextFinishButton()}
            />
          )}
          {submitIsLoading && <CustomLoader />}
          {isLastComponent && !submitIsLoading && (
            <Button
              label={t(submitButtonLabel)}
              type={ButtonType.BUTTON}
              variant={ButtonVariant.CONTAINED}
              onClick={() => getSubmitAPI()}
              disabled={disableNextFinishButton()}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
}
