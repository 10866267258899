import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import usePatientsListService from '../PatientsList.service';

import { onFilterSelection } from '../../../../actions/Datatables/patientFilter';
import { FlashMessageData } from '../PatientList.type';

export default function useEditFilterService() {
  const reduxDispatcher = useDispatch();
  const {
    selectedFilter,
    setForceRender,
    setDisplayFlashMsg,
    setDisplayEditFilter,
  } = usePatientsListService();
  const updated = selectedFilter.allFilters.find(
    filter => filter?.filterId === selectedFilter.savedFilter.id
  );
  const { t } = useTranslation();

  const handleReloadFilters = () => {
    if (updated) {
      reduxDispatcher(
        onFilterSelection({
          ...selectedFilter.allFilters,
          ...selectedFilter.savedFilter,
          id: updated?.filterId,
          name: updated?.key,
          rule: JSON.parse(updated.rule),
        })
      );
      setForceRender(false);
    }
  };

  const handleSetFlashMessage = (msg: FlashMessageData) => {
    setDisplayFlashMsg(prevState => ({
      ...prevState,
      display: true,
      content: t(msg.content),
      type: msg.type,
    }));
  };
  const onEditFilterClose = () => {
    setDisplayEditFilter(false);
  };
  return {
    handleReloadFilters,
    handleSetFlashMessage,
    onEditFilterClose,
  };
}
