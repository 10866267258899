import React, { useCallback } from 'react';

import Box from '../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import AnswerHeaderComponent from '../AnswerHeaderComponent';

import { CareplanComponentAnswer } from '../../../../types/Careplan';
import { GeneralAnswerType } from '../GeneralAnswer.type';

import { getIsThreshold } from '../../../ReportGenerator/Careplan/CareplanReport.utils';
import {
  getThresholdClass,
  occurrenceHasAnswer,
  getComponentOccurrencesAnswer,
} from '../AnswerResult.services';

import '../AnswerResultCommon.scss';

function BmiAnswer({
  componentData,
  componentIndex,
  allOccurrences,
}: GeneralAnswerType): JSX.Element {
  const renderComponentAnswers = useCallback(
    (occurrenceAnswers: CareplanComponentAnswer[], index: number) => {
      const isThreshold: boolean = getIsThreshold(
        componentData,
        occurrenceAnswers[0]
      );
      const thresholdClass = getThresholdClass(isThreshold);
      let answerContent = '-';
      if (occurrenceHasAnswer(occurrenceAnswers)) {
        answerContent = occurrenceAnswers[0].answer_bmi;
        if (isThreshold) answerContent += '*';
      }
      return (
        <Flex
          key={`col-header-${componentIndex}-${index}`}
          className="answer-component_header-columns-column"
          alignItems="center"
          justifyContent="center"
        >
          <span className={thresholdClass}>{answerContent}</span>
        </Flex>
      );
    },
    []
  );
  return (
    <Box className="answer-component">
      <AnswerHeaderComponent
        componentIndex={componentIndex}
        iconName="Bmi"
        title={componentData.name}
        columnOccurrencesRenderer={() => {
          return getComponentOccurrencesAnswer(
            allOccurrences,
            componentData.answers,
            renderComponentAnswers
          );
        }}
      />
    </Box>
  );
}
export default BmiAnswer;
