import React, { useEffect } from 'react';
import i18n from 'i18next';
import PatientFilterSelect from '../PatientFilterSelect/PatientFilterSelectContainer/PatientFilterSelectContainer';
import FilterContent from '../FilterContent/FilterContent';
import { FilterContainerProps } from './FilterContainer.type';
import { usePatientFilterContext } from '../PatientFilterContext/PatientFilterContext';
import Flex from '../../../../../caro-ui-commonfiles/components/Flex/Flex';
import Button from '../../../../../caro-ui-commonfiles/components/Button/Button';
import {
  ButtonType,
  ButtonVariant,
} from '../../../../../caro-ui-commonfiles/utils/button';
import { FilterTypes } from '../PatientFilterContext/PatientFilter.type';
import FilterOperator from '../FilterOperator/FilterOperator';

const FilterContainer = ({ showFilterSettings }: FilterContainerProps) => {
  const { clearCurrentFilter, clearAllFilters } = usePatientFilterContext();
  useEffect(() => {
    if (!showFilterSettings) clearCurrentFilter();
  }, [showFilterSettings]);

  if (!showFilterSettings) return null;

  return (
    <div>
      <Flex className="filters">
        <Flex className="filters__dropdown-container">
          <div>
            <PatientFilterSelect type={FilterTypes.Patient} />
            <FilterContent type={FilterTypes.Patient} />
          </div>
          <div>
            <FilterOperator />
          </div>
          <div>
            <PatientFilterSelect type={FilterTypes.Caretask} />
            <FilterContent type={FilterTypes.Caretask} />
          </div>
          <Button
            type={ButtonType.BUTTON}
            label={i18n.t('patients_complexsearch.clearFilter')}
            variant={ButtonVariant.OUTLINED}
            onClick={clearAllFilters}
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default FilterContainer;
