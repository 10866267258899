import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';

import { PrimaryColor } from '../../utils/colors';
import Flex from '../Flex/Flex';
import Box from '../Box/Box';

const INITIAL_PAGE = 1;
const PAGE_SIZE = 5;

type InfiniteScrollingProps = {
  emptyItem: JSX.Element;
  dataFetcher: Function;
  renderItem: Function;
  idField?: string;
  pageSize?: number;
  itemIdToShowLoading?: number;
  itemIdToUpdate?: number;
  restPagesToUpdate?: number;
};

export default function InfiniteScrolling({
  emptyItem,
  dataFetcher,
  renderItem,
  idField = 'id',
  pageSize = PAGE_SIZE,
  itemIdToShowLoading = null,
  itemIdToUpdate = null,
  restPagesToUpdate = null,
}: InfiniteScrollingProps): JSX.Element {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemIdToShowLoader, setItemIdToShowLoader] = useState<number | null>(
    null
  );
  const [lastElement, setLastElement] = useState(null);
  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [resetFlag, setResetFlag] = useState<boolean>(false);

  const observerRef = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      setPage(currentPage => currentPage + 1);
    }
  });

  const fetchData = async () => {
    setIsLoading(true);
    const data = await dataFetcher(page, pageSize);
    setIsLoading(false);
    if (data) {
      if (data.length === 0 || data.length < pageSize) {
        setLastElement(null);
      }
      setData(oldData => [...oldData, ...data]);
    }
  };

  const fetchSingleItem = async () => {
    const data = await dataFetcher(1, 1, itemIdToUpdate);
    setItemIdToShowLoader(null);
    if (data) {
      setData(oldData => {
        const dataTemp = [...oldData];
        const indexOfItem = dataTemp.findIndex(
          item => item[idField] === itemIdToUpdate
        );
        dataTemp[indexOfItem] = { ...data[0] };
        return dataTemp;
      });
    }
  };

  const fetchRestPages = async () => {
    const itemIndex = data.findIndex(
      item => item[idField] === restPagesToUpdate
    );
    const itemPage = Math.ceil((itemIndex + 1) / pageSize);
    const pageStartItemIndex = itemPage * pageSize - pageSize;

    setData(oldData => oldData.slice(0, pageStartItemIndex));
    if (itemPage === page) {
      fetchData();
    } else {
      setPage(itemPage);
    }
  };

  useEffect(() => {
    if (resetFlag) {
      setData([]);
      setPage(INITIAL_PAGE);
      setResetFlag(false);
    } else if (!isLoading) {
      fetchData();
    }
  }, [page, resetFlag]);

  useEffect(() => {
    if (itemIdToUpdate) {
      setItemIdToShowLoader(itemIdToUpdate);
      fetchSingleItem();
    }
  }, [itemIdToUpdate]);

  useEffect(() => {
    if (restPagesToUpdate) {
      fetchRestPages();
    }
  }, [restPagesToUpdate]);

  useEffect(() => {
    setResetFlag(true);
  }, [dataFetcher]);

  useEffect(() => {
    setItemIdToShowLoader(itemIdToShowLoading);
  }, [itemIdToShowLoading]);

  useEffect(() => {
    if (lastElement && data.length % pageSize === 0) {
      observerRef.observe(lastElement);
    }

    return () => {
      if (lastElement) {
        observerRef.unobserve(lastElement);
      }
    };
  }, [lastElement]);

  if (!isLoading && data.length === 0) return emptyItem;

  return (
    <Box otherStyles={{ height: '100%', overflowY: 'auto' }}>
      {data.map(item => (
        <Box
          key={`scroll-item-${item[idField]}`}
          ref={setLastElement}
          otherStyles={{ position: 'relative' }}
        >
          {(itemIdToShowLoader === item[idField] ||
            itemIdToUpdate === item[idField]) && (
            <Flex
              justifyContent="center"
              alignItems="center"
              otherStyles={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: 'rgb(255 255 255 / 41%)',
                zIndex: 999,
              }}
            >
              <Loader
                type="Oval"
                color={PrimaryColor.MAIN_COLOR}
                height={20}
                width={20}
              />
            </Flex>
          )}
          {renderItem(item)}
        </Box>
      ))}
      {isLoading && (
        <Flex justifyContent="center" alignItems="center">
          <Loader
            type="Oval"
            color={PrimaryColor.MAIN_COLOR}
            height={60}
            width={60}
          />
        </Flex>
      )}
    </Box>
  );
}
