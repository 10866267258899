import { OperatingSystem } from "./OperatingSystems.enum";

export const checkOperatingSystem = (platform : string) => {
    const operatingSystems = Object.values(OperatingSystem);
    let currentOperatingSystem : OperatingSystem;
    operatingSystems.forEach(os => {
        if(platform.includes(os))
            currentOperatingSystem = os;
    })
    return currentOperatingSystem;
};