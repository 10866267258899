/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import I18n from 'i18next';
import axios from 'axios';

import Flex from '../../../caro-ui-commonfiles/components/Flex/Flex';
import Button from '../../../caro-ui-commonfiles/components/Button/Button';
import { ButtonType } from '../../../caro-ui-commonfiles/utils/button';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';
import { Title, Footer } from './CardComponents';
import EditCareplanModalContent from '../Careplans/EditCareplanModalContent';
import EditCareplanModalFooter from '../Careplans/EditCareplanModalFooter';
import EditCareplanModalHeader from '../Careplans/EditCareplanModalHeader';
import CareplanShowModalContent from '../Careplans/CareplanShowModalContent';
import { PromptContent, PromptFooter } from '../common/ConfirmationPrompt';
import ShowCareplanRequest from '../../Utils/ShowCareplanRequest';
import CareplanResultsModalFooter from '../Careplans/CareplanResultsModalFooter';
import CareplanResultsModalContent from '../Careplans/CareplanResultsModalContent';
import { ViewCareplanModalFooter } from '../Careplans/ViewCareplan';
import CareplanGraphCard from './CareplanGraphCard';
import CardInfoContent from '../common/CardInfoContent';
import { MEDIA_QUERIES } from '@ui-common-files/utils/layout';
import {
  ComponentTypes,
  Actions,
} from '../../../caro-ui-commonfiles/utils/componentTypes';
import { ComponentColors } from '../../../caro-ui-commonfiles/utils/colors';
import { convertLinkToBlob } from '../../Utils/Asset/assetHelper';
import serviceConfig from '../../../serviceConfig.json';

import '../../css/analytics.css';

const AssessmentCard = ({ data, elRefs }) => {
  const assessmentId = `assessment_${data.id}`;
  const {
    setModalContent,
    setFlashMessage,
    setMessagePrompt,
    setLandingTab,
    closeMessagePrompt,
    closeModal,
    showCareplanResults,
    setShowCareplanResults,
    navigateToCareplanAnswerView,
    setForceScroll,
  } = useContext(PatientOverviewContext);
  const history = useHistory();
  const stateData = history.location.state ? history.location.state : {};

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showModalGraph, setShowModalGraph] = useState(false);

  const checkEditCareplanCredential = async id => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.editCareplan.uri + id
      );
      if (response) {
        return response;
      }
    } catch (error) {
      closeModal();
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      throw new Error(error.response);
    }
  };

  const getCareplanAnswers = async id => {
    try {
      const response = await axios.get(
        serviceConfig.brokerService.getCareplanAnswers.uri + id
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
      throw new Error(error.response);
    }
  };

  const setEditCareplanModalContent = async careplanData => {
    careplanData.endDate = data.endsAt;
    careplanData.title = data.name;
    careplanData.phaseType = careplanData.phaseTypeObject
      ? {
          id: careplanData.phaseTypeObject.id,
          label: I18n.t(careplanData.phaseTypeObject.name),
        }
      : null;
    if (careplanData.patient.profilePicture.previewURL) {
      await convertLinkToBlob(careplanData.patient.profilePicture.previewURL)
        .then(blob => {
          careplanData.patient.profilePicture = blob;
        })
        .catch(() => {
          careplanData.patient.profilePicture =
            careplanData.patient.profilePicture.previewURL;
        });
    } else {
      careplanData.patient.profilePicture = `data:image;base64,${careplanData.patient.profilePicture}`;
    }
    setModalContent({
      title: `${I18n.t('patientOverview.editAssignment')}: ${data.name}`,
      content: <EditCareplanModalContent careplan={careplanData} data={data} />,
      footer: (
        <EditCareplanModalFooter
          componentType={ComponentTypes.CAREPLAN}
          actionType={Actions.UPDATE}
        />
      ),
      header: <EditCareplanModalHeader />,
    });
  };
  const sendCareplan = async () => {
    const response = await axios.get(
      serviceConfig.brokerService.sendCareplan.uri + data.id
    );
    return response;
  };

  const showCareplanAnswers = careplanData => {
    setModalContent({
      title: `${I18n.t('common_labels.label_results')}: ${data.name}`,
      content: (
        <CareplanResultsModalContent
          careplanData={[careplanData]}
          chosenDate={showCareplanResults.answerTimestamp}
        />
      ),
      footer: <CareplanResultsModalFooter careplanData={[careplanData]} />,
      header: null,
    });
  };

  const unassignCareplan = async () => {
    const response = await axios.get(
      serviceConfig.brokerService.unassignCareplan.uri + data.id
    );
    return response;
  };

  const deleteCareplan = async () => {
    setLandingTab(false);
    try {
      const response = await axios.get(
        serviceConfig.brokerService.deleteCareplan.uri + data.id
      );
      if (response) {
        setLandingTab(ComponentTypes.ASSESSMENT);
        setFlashMessage({
          type: response.data.type,
          content: response.data.content,
        });
      }
    } catch (error) {
      setFlashMessage({
        type: error.response.data.type,
        content: error.response.data.content,
      });
    }
  };
  const sendHandler =
    data.firebaseKey || data.isAssessment ? null : sendCareplan;
  const unassignHandler =
    sendHandler || data.isAssessment ? null : unassignCareplan;

  const clearThresholds = async answers => {
    const answerIds = [];
    for (let i = 0; i < answers.mapQuesAnswers.length; i++) {
      answerIds.push(answers.mapQuesAnswers[i].answer.id);
    }
    return await axios.post(
      serviceConfig.brokerService.clearCareplanThresholdNotifications.uri,
      {
        answerIds,
        careplan: data,
        patientId: answers.patient.id,
      }
    );
  };

  const resultHandler = data.isAnswered
    ? () =>
        getCareplanAnswers(data.id).then(res => {
          if (stateData.seen && stateData.seen === true) {
            clearThresholds(res);
          }
          showCareplanAnswers(res);
        })
    : null;

  useEffect(() => {
    function updateWidth() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    if (
      showCareplanResults &&
      showCareplanResults.careplanId === data.id &&
      resultHandler
    ) {
      resultHandler();
      setShowCareplanResults(false);
      history.replace(history.location.pathname, {
        ...history.location.state,
        careplanId: null,
        answerTimestamp: null,
        refreshCareplanCardViewer: null,
      });
    }
  }, [showCareplanResults, resultHandler]);

  const renderDeleteDialog = user => {
    setMessagePrompt({
      title: `${I18n.t(
        'common_labels.msg_confirm_template_delete_headline'
      )}: ${data.name}`,
      content: (
        <PromptContent
          message={`${I18n.t('common_labels.warning_careplan_delete')} ${I18n.t(
            'common_labels.msg_confirm_template_delete'
          )}`}
        />
      ),
      footer: (
        <PromptFooter
          close={closeMessagePrompt}
          confirmHandler={() => deleteCareplan(user)}
        />
      ),
    });
  };

  const renderShowModalContent = careplanDataForShow => {
    setModalContent({
      title: `${I18n.t('newCareplan_view.view')} ${data.name}`,
      content: (
        <CareplanShowModalContent careplanDataForShow={careplanDataForShow} />
      ),
      footer: <ViewCareplanModalFooter close={closeModal} />,
    });
  };

  const getAnswerButton = () => {
    if (data.isAnswerable && !data.firebaseKey) {
      return (
        <div className="margin-left-10">
          <Button
            label={
              data.isAssessment
                ? I18n.t('newCareplan_view.startNow')
                : I18n.t('newCareplan_view.answerNow')
            }
            type={ButtonType.BUTTON}
            onClick={() => {
              navigateToCareplanAnswerView({
                id: data.id,
                isAssessment: data.isAssessment,
                occurrenceId: data.occurrenceId,
              });
            }}
            variant="contained"
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="assignment-card">
      <Title
        iconName={ComponentTypes.ASSESSMENT}
        color={ComponentColors.CAREPLAN}
        data={data}
        onEditIconClick={() => {
          checkEditCareplanCredential(data.id).then(res => {
            setEditCareplanModalContent(res.data);
          });
        }}
        onDeleteClick={renderDeleteDialog}
        onShowIconClick={() => {
          return ShowCareplanRequest(data.id, setFlashMessage).then(
            response => {
              const careplanDataForShow = {
                ownershipLogs: response.ownershipLogs,
                lifespan: response.lifespan,
                carePathways: response.qc.carePathways.map(speciality => ({
                  label: speciality.name,
                  value: speciality.id,
                })),
                startDate: response.firstOccurrence,
                phaseType: response.phaseType,
                phaseTypeId: response.phaseTypeId,
                careplansWithThresholds: response.questionsWithThresholds,
                customTime: response.hours,
                lifespanUnit: response.lifespanUnit,
                recurrenceText: data.recurrenceText,
                title: response.qc.name,
                icds: data.icdRecords,
                nbrComponents: data.questionNumber,
                careplanLogicJumps: response.careplanLogicJumps,
                isEvaluating: response.isEvaluating,
              };
              renderShowModalContent(careplanDataForShow);
            }
          );
        }}
      />
      <Flex
        flexDirection={windowWidth < MEDIA_QUERIES.SCREEN_MD ? 'column' : 'row'}
        justifyContent="space-between"
        otherStyles={{ paddingLeft: '60px', margin: '10px 0' }}
      >
        <div id={assessmentId} ref={elRefs}>
          <CardInfoContent
            data={data}
            variant="careplan"
            setLandingTab={setLandingTab}
            setForceScroll={setForceScroll}
          />
        </div>
        <CareplanGraphCard
          data={data}
          key={data.id}
          show={showModalGraph}
          onClose={() => setShowModalGraph(false)}
        />
      </Flex>
      <Footer
        sendHandler={sendHandler}
        unassignHandler={unassignHandler}
        resultHandler={resultHandler}
        sendAndUnassignEnabled={false}
        componentType={ComponentTypes.ASSESSMENT}
        firebaseKey={data.firebaseKey}
        answerButton={getAnswerButton()}
      />
    </div>
  );
};

AssessmentCard.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default AssessmentCard;
