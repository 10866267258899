import serviceConfig from '../../../../../../../serviceConfig.json';

import { ApiClient } from '../../../../../../Global/Services';

export async function submitCaretaskAnswer(data, caretaskId) {
  return ApiClient.POST({
    url: `${serviceConfig.brokerService.saveCareplanAnswer.uri}${caretaskId}`,
    payload: data,
  });
}

export async function calculateScoreOfAnswers(answerData) {
  return ApiClient.POST({
    url: `${serviceConfig.brokerService.calculateScoreOfAnswers.uri}`,
    payload: answerData,
  });
}
