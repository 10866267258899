enum CheckBoxRadioVariants {
  Default = 'default',
  Threshold = 'threshold',
  InlineInput = 'inline-input',
  NonEditable = 'non-editable',
  AnswerView = 'answer-view',
  SuccessView = 'success-view',
  WarningView = 'warning-view',
}

export default CheckBoxRadioVariants;