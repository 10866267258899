import React from 'react';
import PropTypes from 'prop-types';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Icon from '../Icon/Icon';
import Box from '../Box/Box';

const CircularProgressBar = ({
  value,
  size,
  color,
  imgSrc,
  hasIcon,
  iconName,
  strokeWidth,
  isPatientDemographicData

}) => {

  const avatarPicture = () => {
    return (
      <>
        {
          hasIcon ? <Icon name={iconName} size={(size * 70) / 100} fill={color} />
            :
            <Box width="85%" height="85%">
              <div
                style={{
                  backgroundImage: `url(${imgSrc})`,
                }}
                className="progressbar__profile-pic"
              />
            </Box>
        }
      </>
    )
  }

  return (
    <div style={{ width: size, height: size }}>
      {!isPatientDemographicData ? <CircularProgressbarWithChildren
        value={value}
        strokeWidth={strokeWidth}
        styles={{
          root: { width: size, height: size },
          path: {
            stroke: color,
          },
        }}
      >
        {avatarPicture()}
      </CircularProgressbarWithChildren> :
        <>
          {avatarPicture()}
        </>

      }
    </div>
  );
};

CircularProgressBar.propTypes = {
  value: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  iconName: PropTypes.string,
};


CircularProgressBar.defaultProps = {
  value: 25,
  size: 45,
  color: '#1ab394',
};

export default CircularProgressBar;
