import React from 'react';

const MessageNotificationIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.5495 5H2.42557C0.847614 5 1.00437 5.57267 1.00437 7.05257L1.0043 23.1969C1.0043 24.6768 0.847614 25 2.42557 25H27.8177C29.3956 25 28.8365 24.6395 28.9974 23.1969V7.05257C28.9974 5.57267 29.1275 5 27.5495 5ZM28 24H2V6.5L15.135 16.5L28 6.5V24ZM15.135 15L3 6H27L15.135 15Z"
      fill="#323232"
    />
  </svg>
);

export default MessageNotificationIcon;
