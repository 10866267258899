import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import IconCheckbox from '../../../../caro-ui-commonfiles/components/IconCheckbox/IconCheckbox';
import Dropdown from '../../../../caro-ui-commonfiles/components/Dropdown/Dropdown';
import NumberInput from '../../../../caro-ui-commonfiles/components/NumberInput/NumberInput';
import {
  getThresholdOptions,
  setPrefilledThresholdFromDropdown,
} from '../CareplanHelperUtility';
import JumpSelection from '../LogicJump/JumpSelection';
import { useLogicJumpDestination } from '../../../Utils/hooks/logicJumpHooks';
import Flex from '../../../../caro-ui-commonfiles/components/Flex/Flex';

import iconCheckbox from '../../../../caro-ui-commonfiles/utils/iconCheckbox';
import defaultEnums from '../defaultEnums';
import {
  ActionColors,
  PrimaryColor,
} from '../../../../caro-ui-commonfiles/utils/colors';
import Box from '../../../../caro-ui-commonfiles/components/Box/Box';
import componentTypeOrder from '../careplanComponentTypeOrder';
import { deepEqual } from '../../../Global/Objects';

const handleOperatorDropdown = (
  state,
  index,
  itemCollection,
  setValueForComponent,
  setLogicJumpValueForComponent,
  isThreshold,
  setJumpContradictionError
) => {
  const dropdowninputvalue = [...itemCollection];
  dropdowninputvalue[index].name = state;
  const currOption = { ...dropdowninputvalue[index] };

  dropdowninputvalue.forEach((itemOption, pos) => {
    if (
      pos !== index &&
      deepEqual(itemOption.name, currOption.name) &&
      itemOption.value === currOption.value
    ) {
      setJumpContradictionError(index);
    }
  });
  if (isThreshold)
    setValueForComponent('thresholdCollection', index, dropdowninputvalue);
  else setLogicJumpValueForComponent('logicJumpCollection', dropdowninputvalue);
};

const handleConditionValueChange = (
  value,
  itemCollection,
  index,
  setThresholdValue,
  isThreshold,
  setValueForComponent,
  setLogicJumpValueForComponent,
  setJumpContradictionError
) => {
  const dropdowninputvalue = [...itemCollection];
  dropdowninputvalue[index].value = value;
  const currOption = { ...dropdowninputvalue[index] };

  dropdowninputvalue.forEach((itemOption, pos) => {
    if (
      pos !== index &&
      deepEqual(itemOption.name, currOption.name) &&
      itemOption.value === currOption.value
    ) {
      setJumpContradictionError(index);
    }
  });
  setThresholdValue(value);
  if (isThreshold) {
    setValueForComponent('thresholdCollection', index, dropdowninputvalue);
  } else {
    setLogicJumpValueForComponent('logicJumpCollection', dropdowninputvalue);
  }
};

const removeConditions = (
  index,
  setValueForComponent,
  isThreshold,
  deleteJumpConditions
) => {
  if (isThreshold) setValueForComponent('delete', index);
  else {
    deleteJumpConditions(index);
  }
};

const NumericValueJumpInputs = ({
  option,
  parentComponentIndex,
  index,
  itemCollection,
  setLogicJumpValueForComponent,
  setLogicJumpError,
  setValidationError,
}) => {
  const { destination, setDestination } = useLogicJumpDestination(
    option,
    parentComponentIndex,
    true
  );

  /* dependencies on itemCollection results in infinite loop */
  useEffect(() => {
    const dropdowninputvalue = [...itemCollection];
    if (destination > -1 && !destination.hasError) {
      dropdowninputvalue[index].destination = destination;
      setValidationError(destination);
      if (destination > parentComponentIndex + 1) setLogicJumpError(-index - 1);
    } else {
      const hasError =
        destination && destination.hasError ? index + 1 : -index - 1;
      setLogicJumpError(hasError);
      setValidationError(destination);
      dropdowninputvalue[index].destination = destination.hasError
        ? destination.jump
        : -1;
    }
    setLogicJumpValueForComponent('logicJumpCollection', dropdowninputvalue);
  }, [destination, index]);

  return (
    <div className="numeric-value-jump-condition_container">
      <JumpSelection
        parentComponentIndex={parentComponentIndex}
        destination={destination}
        setDestination={setDestination}
      />
    </div>
  );
};

const NumericConditionInput = ({
  index,
  item,
  parentComponentIndex,
  itemCollection,
  setValueForComponent,
  setLogicJumpValueForComponent,
  isFloatingNumber,
  isThreshold,
  setLogicJumpError,
  setJumpContradictionError,
  deleteJumpConditions,
  componentTypeId,
  min = defaultEnums.DEFAULT_NUMERIC_RANGE_MIN_VALUE,
  max = defaultEnums.NUMERIC_VALUE_MAX_VALUE,
}) => {
  const [validationError, setValidationError] = useState('');
  const [thresholdValue, setThresholdValue] = useState(null);

  useEffect(() => {
    const item = itemCollection[index];
    if (item?.value !== undefined) {
      setThresholdValue(item.value);
    }
  }, [itemCollection, index]);
  const getJumpConditionInputs = useCallback(
    (option, itemIndex) => {
      return (
        <NumericValueJumpInputs
          key={`sc-${itemIndex}`}
          option={option}
          parentComponentIndex={parentComponentIndex}
          index={itemIndex}
          itemCollection={itemCollection}
          setLogicJumpValueForComponent={setLogicJumpValueForComponent}
          setLogicJumpError={setLogicJumpError}
          setValidationError={setValidationError}
        />
      );
    },
    [
      itemCollection,
      itemCollection.length,
      parentComponentIndex,
      setLogicJumpValueForComponent,
      setLogicJumpError,
      setValidationError,
    ]
  );

  return (
    <Box>
      <Flex
        flexDirection="row"
        alignItems="center"
        otherStyles={{ paddingTop: 10 }}
      >
        {componentTypeId != componentTypeOrder.INFORMATION && (
          <div
            key={`unique-threshold-nv-${index}`}
            className="numericValueThresholdParent"
          >
            <div className="thresholdIconDelete">
              <IconCheckbox
                id={`checkbox-${parentComponentIndex}-${index}-${isThreshold}`}
                iconName={isThreshold ? 'threshold' : 'logicJump'}
                name={`isThreshold-${isThreshold}`}
                variant={iconCheckbox.variant.CIRCLE}
                checked
                handleOnChange={() =>
                  removeConditions(
                    index,
                    setValueForComponent,
                    isThreshold,
                    deleteJumpConditions
                  )
                }
                multiSelectEnabled
                size={24}
                fill={
                  isThreshold ? ActionColors.THRESHOLD : PrimaryColor.MAIN_COLOR
                }
              />
            </div>
            <div className="thresholdDropdownContainer">
              <div style={{ width: '100%' }}>
                <Dropdown
                  value={setPrefilledThresholdFromDropdown(item)}
                  options={getThresholdOptions()}
                  isDisabled={false}
                  isSearchable={false}
                  placeholder=""
                  handleOnChange={state => {
                    if (!thresholdValue) {
                      setThresholdValue(0);
                      const dropdowninputvalue = [...itemCollection];
                      if (isThreshold) {
                        dropdowninputvalue[index].value = 0;
                        setValueForComponent(
                          'thresholdCollection',
                          index,
                          dropdowninputvalue
                        );
                      } else {
                        dropdowninputvalue[index].value = '0';
                        setLogicJumpValueForComponent(
                          'logicJumpCollection',
                          dropdowninputvalue
                        );
                      }
                    }
                    handleOperatorDropdown(
                      state,
                      index,
                      itemCollection,
                      setValueForComponent,
                      setLogicJumpValueForComponent,
                      isThreshold,
                      setJumpContradictionError
                    );
                  }}
                  fontSize={14}
                  heightControl={36}
                />
              </div>
            </div>
            <div className="thresholdNumberContainer">
              <div style={{ width: '100%' }}>
                <NumberInput
                  value={thresholdValue}
                  id="specialId"
                  name="Name"
                  min={min}
                  max={max}
                  step={1}
                  placeholder="0"
                  isFloatingNumber={isFloatingNumber}
                  isNegativeNumber
                  changeValue={value =>
                    handleConditionValueChange(
                      value,
                      itemCollection,
                      index,
                      setThresholdValue,
                      isThreshold,
                      setValueForComponent,
                      setLogicJumpValueForComponent,
                      setJumpContradictionError
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
        {!isThreshold && getJumpConditionInputs(item, index)}
      </Flex>
      <Box margin="10px 0 0 50px" className="numeric-value-jump-container">
        {validationError.message && (
          <span className="validation-error">
            {I18n.t(validationError.message)}
          </span>
        )}
      </Box>
    </Box>
  );
};

NumericValueJumpInputs.propTypes = {
  option: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  itemCollection: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  index: PropTypes.number.isRequired,
  parentComponentIndex: PropTypes.number.isRequired,
  setLogicJumpValueForComponent: PropTypes.func.isRequired,
  setLogicJumpError: PropTypes.func.isRequired,
  setValidationError: PropTypes.func.isRequired,
};

NumericConditionInput.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  parentComponentIndex: PropTypes.number.isRequired,
  itemCollection: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
  setValueForComponent: PropTypes.func,
  setLogicJumpValueForComponent: PropTypes.func,
  isFloatingNumber: PropTypes.bool.isRequired,
  isThreshold: PropTypes.bool.isRequired,
  setLogicJumpError: PropTypes.func,
  deleteJumpConditions: PropTypes.func,
};

NumericConditionInput.defaultProps = {
  setLogicJumpError: () => {},
  deleteJumpConditions: () => {},
};

export default NumericConditionInput;
