import { ComponentColors } from '@ui-common-files/utils';
import { formatDistanceToNow } from '@utils/Medication/formatDistanceToNow';

export const useMedicationCardTitleService = (data, color) => {
  const scanningDate = data?.scanningDate ? new Date(data.scanningDate) : null;
  const lastUpdated = scanningDate
    ? formatDistanceToNow(scanningDate)
    : '';
  const fillColor =
    color === ComponentColors.MEDICATION && ComponentColors.MEDICATION;

  const title = data.asset ? data.asset.name : data.name;

  return { scanningDate, lastUpdated, title, fillColor };
};
