import React, { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@ui-common-files/components';
import { isEmptyString } from '@global/Strings';
import { CaretaskComponent } from '@type/Caretask';

import BloodAndBMIRowComponent from './BloodAndBMIRowComponent';
import BloodAndBMIRowComponentTitle from './BloodAndBMIRowComponentTitle';

import { ComponentAnswer } from '../../CaretaskAnswering.type';
import { AnsweringBloodPressureAndBMIBaseProps } from './AnsweringBloodPressureAndBMIBase.type';

import {
  selectCurrentComponent,
  selectCurrentAnswer,
} from '../../CaretaskAnswering.reducer';

export default function AnsweringBloodPressureAndBMIBase({
  handleOnChange,
  inputOption1,
  inputOption2,
  validator,
  isFloatingNumber = true,
  getJumpTo,
}: AnsweringBloodPressureAndBMIBaseProps): JSX.Element {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const currentAnswer: ComponentAnswer = useSelector(selectCurrentAnswer);
  const currentComponent: CaretaskComponent = useSelector(
    selectCurrentComponent
  );
  const defaultValue: number = currentAnswer?.answer || {
    [inputOption2.name]: 0,
    [inputOption1.name]: 0,
  };

  const checkValuesOnUpdate = (_state, bmiValues) => {
    const answeredInput1 = parseFloat(bmiValues[inputOption1.name]);
    const answeredInput2 = parseFloat(bmiValues[inputOption2.name]);
    const validatorResponse = validator(answeredInput1, answeredInput2);

    if (!isEmptyString(validatorResponse)) {
      setErrorMessage(validatorResponse);
      return bmiValues;
    }
    if (!isEmptyString(errorMessage)) setErrorMessage('');
    return {
      [inputOption2.name]:
        !bmiValues || bmiValues[inputOption2.name] < 0
          ? 0
          : bmiValues[inputOption2.name],
      [inputOption1.name]:
        !bmiValues || bmiValues[inputOption1.name] < 0
          ? 0
          : bmiValues[inputOption1.name],
    };
  };

  const [userInput, setUserInput] = useReducer(
    checkValuesOnUpdate,
    defaultValue
  );

  useEffect(() => {
    const systolic = userInput[inputOption1.name];
    const diastolic = userInput[inputOption2.name];
    const jumpCondition =
      currentComponent.hasLogicJump && systolic !== '' && diastolic !== '';
    handleOnChange({
      answer: userInput,
      isSkipped: parseFloat(systolic) <= 0 && parseFloat(diastolic) <= 0,
      hasError: !isEmptyString(errorMessage),
      jumpTo: jumpCondition
        ? getJumpTo(parseFloat(systolic), parseFloat(diastolic))
        : null,
    });
  }, [userInput, handleOnChange]);

  useEffect(() => {
    setUserInput(defaultValue);
  }, [currentComponent]);

  const updateBMIValues = field => value => {
    setUserInput({
      ...userInput,
      [field]: value,
    });
  };

  return (
    <Flex flexDirection="column">
      <Flex>
        <Flex flexDirection="column" otherStyles={{ marginRight: 40 }}>
          <BloodAndBMIRowComponentTitle label={inputOption1.label} />
          <BloodAndBMIRowComponentTitle label={inputOption2.label} />
        </Flex>
        <Flex flexDirection="column">
          <BloodAndBMIRowComponent
            unit={inputOption1.unit}
            value={userInput[inputOption1.name]}
            name={inputOption1.name}
            onChange={updateBMIValues(inputOption1.name)}
            hasError={!isEmptyString(errorMessage)}
            isFloatingNumber={isFloatingNumber}
          />
          <BloodAndBMIRowComponent
            unit={inputOption2.unit}
            value={userInput[inputOption2.name]}
            name={inputOption2.name}
            onChange={updateBMIValues(inputOption2.name)}
            hasError={!isEmptyString(errorMessage)}
            isFloatingNumber={isFloatingNumber}
          />
        </Flex>
      </Flex>
      {!isEmptyString(errorMessage) && (
        <Flex>
          <Box className="componentValidation">{t(errorMessage)}</Box>
        </Flex>
      )}
    </Flex>
  );
}
