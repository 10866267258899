import { WearablesDataTypes } from '@ui-common-files/utils';
import { GraphData } from '../Overview.type';

export const shouldRenderErrorBar = (data: GraphData, index: number) => {
  return (
    data.points.length > 0 &&
    (data.points[index]?.wearableDataTypeId1 === WearablesDataTypes.HEART_RATE ||
      data.points[index]?.wearableDataTypeId2 ===
        WearablesDataTypes.HEART_RATE ||
      data.points[index]?.wearableDataTypeId3 === WearablesDataTypes.HEART_RATE)
  );
};
