import I18n from 'i18next';
import serviceConfig from '../../serviceConfig.json';
import { ApiClient } from '../Global/Services';

class RoleHelperUtility {
  userSessionId;

  userAllComponentsPermissions;

  userLanguage = I18n.language;

  static async getRoleMatrixData(user, userLang) {
    const response = await ApiClient.GET({
      url:
        serviceConfig.brokerService.prefillComponentActions.uri + user.roleId,
      payload: {
        userLanguage: userLang,
      },
    });
    return response;
  }

  static async getRolePermissions(user) {
    const roleMatrixData = await RoleHelperUtility.getRoleMatrixData(
      user,
      I18n.language
    );
    const fetchedRolePermissions =
      roleMatrixData.data.prefillComponentActions.filter(elem => {
        return elem.component === I18n.t('types.componentType.userRoles');
      });
    return fetchedRolePermissions;
  }

  static async getLoggedInUserRolePermissions(user, component, action) {
    let userComponentPermissions;
    let UserComponentActionPermission;

    if (
      !this.userAllComponentsPermissions ||
      this.userSessionId !== user.sessionId ||
      this.userLanguage !== I18n.language
    ) {
      this.userLanguage = I18n.language;
      const response = await RoleHelperUtility.getRoleMatrixData(
        user,
        I18n.language
      );
      if (response) {
        this.userAllComponentsPermissions = response.data;
        this.userSessionId = user.sessionId;
      }
    }

    if (
      component === undefined &&
      action === undefined &&
      this.userAllComponentsPermissions &&
      this.userAllComponentsPermissions.prefillComponentActions.length > 0
    ) {
      return this.userAllComponentsPermissions.prefillComponentActions;
    }
    if (
      component !== undefined &&
      action === undefined &&
      this.userAllComponentsPermissions &&
      this.userAllComponentsPermissions.prefillComponentActions.length > 0
    ) {
      userComponentPermissions =
        this.userAllComponentsPermissions.prefillComponentActions.filter(
          elem => {
            return elem.component === component;
          }
        );
      return userComponentPermissions;
    }
    if (component !== undefined && action !== undefined) {
      if (
        this.userAllComponentsPermissions &&
        this.userAllComponentsPermissions.prefillComponentActions.length > 0
      ) {
        userComponentPermissions =
          this.userAllComponentsPermissions.prefillComponentActions.filter(
            elem => {
              return elem.component === component;
            }
          );
        if (userComponentPermissions.length > 0) {
          UserComponentActionPermission = userComponentPermissions.find(
            role => {
              return role.action === action;
            }
          );
        }
      }
      return UserComponentActionPermission;
    }
  }
}
export default RoleHelperUtility;
