/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useCallback,
} from 'react';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { HideFlashMessage } from '@global/Services';

import '../../../css/user.css';
import '../../../css/patientOverview.scss';
import '../../../css/careplans.scss';
import '../../../css/patient.css';

import { SubmissionTypes } from '../../../../caro-ui-commonfiles/utils/componentTypes';
import IcdSelector from '../../../../caro-ui-commonfiles/components/ICDDropdownSelect/ICDSelect';
import RecurrenceComponent from '../../../../caro-ui-commonfiles/components/molecules/Recurrence/AppointmentRecurrence';

import { CreateAppointmentContext } from './CreateAppointmentModal';
import communicationMode from '../../../Utils/communicationBitValues';
import icdThresholds from '../../../Utils/icdThresholds.json';
import serviceConfig from '../../../../serviceConfig.json';
import ApptModalContentForm from '../ApptModalContentForm';
import AppointmentSettingOverview from './AppointmentSettingOverview';
import Modal from '../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ModalForm from '../../common/Layout/Modal/ModalForm';

import { PromptContent, PromptFooter } from '../../common/ConfirmationPrompt';
import { resetPatientData } from '../../../../actions/patients';
import {
  apptRepeatTypes,
  getEndTime,
} from '../../../../caro-ui-commonfiles/utils/Recurrence/recurrenceUtils';

const AppointmentSettings = ({ onClose }) => {
  const { t } = useTranslation();

  const {
    currentStep,
    setCurrentStep,
    shouldValidateForm,
    setShouldValidateForm,
    setFlashMessage,
    shouldSubmitForm,
    dispatchFormSubmittor,
    existingPatient,
    didUpdate,
  } = useContext(CreateAppointmentContext);

  const currMinute = Math.ceil(moment().minute() / 15) * 15;
  const startTimeObj = {
    hour: moment().hour() + Math.floor(currMinute / 60),
    minute: currMinute % 60,
  };

  const [icds, setIcds] = useState([]);
  const patientReduxState = useSelector(state => state.patients);
  const reduxDispatcher = useDispatch();
  const repeatTypesArray = apptRepeatTypes();
  const [data, setData] = useState({
    users: [],
    maxIcdSelection: icdThresholds.APPOINTMENT,
    isSmsEnabled: false,
  });
  const [values, setValues] = useState({
    name: '',
    appointmentType: null,
    startDate: moment().toDate(),
    startTime: startTimeObj,
    repeatType: repeatTypesArray[0],
    repeatInterval: { value: 1, label: 1 },
    endDate: moment().toDate(),
    endTime: getEndTime(startTimeObj),
    attendees: [],
    weeklyRecurrence: new Array(7).fill(false),
    location: {},
    notes: '',
    communicationModeBitValue: communicationMode.PUSH_NOTIFICATION_BIT_VALUE,
  });

  const [hasErrors, setHasErrors] = useState({
    name: false,
    appointmentType: false,
    startDateTime: false,
    endDateTime: false,
    attendees: false,
    location: false,
    recurrenceRepeat: false,
  });

  const onValueChange = useCallback(
    (value, key) => {
      setValues({
        ...values,
        [key]: value,
      });
    },
    [values]
  );

  const [promptMessage, setPromptMessage] = useState({
    display: false,
    title: '',
    message: '',
  });

  const closeMessagePrompt = () => {
    promptMessage.createAppointment();
    onClose();
    dispatchFormSubmittor({
      submit: false,
      submissionType: false,
    });
  };

  const closeMessage = () => {
    setPromptMessage({
      display: false,
      title: '',
      message: '',
    });
  };

  useLayoutEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          serviceConfig.brokerService.addAppointment.uri,
          {
            params: {},
          }
        );
        if (response.data) {
          setData({
            users: response.data.users,
            maxIcdSelection: response.data.maxIcdSelection,
            isSmsEnabled: response.data.smsFlag,
          });
        }
      } catch (error) {
        onClose();
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
          display: true,
        });
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (patientReduxState.patientRequestError) {
      onClose();
      setFlashMessage({
        display: true,
        content: patientReduxState.patientRequestError.content,
        type: patientReduxState.patientRequestError.type,
      });
      reduxDispatcher(resetPatientData());
    }
  }, [patientReduxState]);

  useEffect(() => {
    const createAppointment = async () => {
      let patientId = '';
      patientId = existingPatient.id;
      let message = '';
      if (patientId !== '') {
        try {
          const response = await axios.post(
            serviceConfig.brokerService.createAppointment.uri,
            {
              patientId,
              selectedICDs: icds.map(icd => icd.id) || [],
              startDateTime: moment(values.startDate)
                .hour(values.startTime.hour)
                .minute(values.startTime.minute)
                .toISOString(),
              endDateTime: moment(values.endDate)
                .hour(values.endTime.hour)
                .minute(values.endTime.minute)
                .toISOString(),
              ...values,
              location: values.location.label,
            }
          );
          if (response) {
            let sendResponse = response;
            message = `${t(response.data.content)}`;
            const isSendOutImmediately =
              shouldSubmitForm.submissionType ===
              SubmissionTypes.ASSIGN_AND_SEND;
            if (isSendOutImmediately) {
              sendResponse = await axios.post(
                serviceConfig.brokerService.sendAppointment.uri,
                {
                  reminderId: response.data.reminderId,
                  isCreateAppointment: true
                }
              );
            }
            if (sendResponse) {
              didUpdate();
              setFlashMessage({
                type: sendResponse.data.type,
                content: isSendOutImmediately
                  ? `${message} ${t(sendResponse.data.content)}`
                  : message,
                display: true,
              });
            }
          }
        } catch (error) {
          setFlashMessage({
            type: error.response.data.type,
            content: `${t(error.response.data.content)}${message}`,
            display: true,
          });
        }
      }
    };

    if (shouldSubmitForm.submit === true) {
      if (currentStep === 3 && icds.length === 0) {
        setPromptMessage({
          display: true,
          title: `${t('warningMessage.carepathwayNoICD_title')}`,
          message: t('warningMessage.appointmentNoIcd'),
          createAppointment: createAppointment.bind(this),
        });
      } else if (currentStep === 3) {
        onClose();
        createAppointment();
        dispatchFormSubmittor({
          submit: false,
          submissionType: false,
        });
      }
    }
  }, [shouldSubmitForm]);

  const validateForm = () => {
    const startDateTime = moment(values.startDate)
      .hour(values.startTime.hour)
      .minute(values.startTime.minute)
      .unix();
    const endDateTime = moment(values.endDate)
      .hour(values.endTime.hour)
      .minute(values.endTime.minute)
      .unix();
    const currentTime = moment().unix();

    const nameError = values.name.trim().length < 5;
    const locationError = !values.location.label;
    const recurrenceRepeatError =
      values.repeatType.value !== 2
        ? false
        : !values.weeklyRecurrence.find(elem => elem === true);
    const startDateTimeError = startDateTime <= currentTime;
    const endDateTimeError =
      endDateTime <= currentTime || endDateTime <= startDateTime;

    setHasErrors({
      ...hasErrors,
      name: nameError,
      recurrenceRepeat: recurrenceRepeatError,
      startDateTime: startDateTimeError,
      endDateTime: endDateTimeError,
      location: locationError,
      appointmentType: !values.appointmentType,
    });

    setShouldValidateForm(false);

    return !(
      nameError ||
      recurrenceRepeatError ||
      startDateTimeError ||
      endDateTimeError ||
      locationError ||
      !values.appointmentType
    );
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    return false;
  };

  const fetchIcdData = term => {
    return axios(serviceConfig.getIcds.uri, {
      params: {
        searchTerm: term,
      },
    })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    if (shouldValidateForm === true) {
      if (validateForm()) setCurrentStep(currentStep + 1);
    }
  }, [shouldValidateForm]);

  return (
    <>
      {currentStep === 2 && (
        <ModalForm
          formId="create-appointment-form"
          handleFormSubmit={handleFormSubmit}
        >
          <ApptModalContentForm
            values={values}
            users={data.users}
            isSmsEnabled={data.isSmsEnabled}
            recurrenceComponent={
              <RecurrenceComponent
                onValueChange={onValueChange}
                values={values}
                hasErrors={hasErrors}
              />
            }
            icdSelectorComponent={
              <IcdSelector
                maxLengthItem={data.maxIcdSelection}
                onSelect={setIcds}
                icdSelected={icds}
                fetchData={fetchIcdData}
                placeholder={t('newCareplan_view.placeholderICDReference')}
              />
            }
            onValueChange={onValueChange}
            hasErrors={hasErrors}
          />
        </ModalForm>
      )}

      {currentStep === 3 && (
        <AppointmentSettingOverview values={values} icds={icds} showAssignee />
      )}
      {promptMessage.display && (
        <Modal
          isConfirmationDialog
          title={promptMessage.title}
          contentComponent={<PromptContent message={promptMessage.message} />}
          footerComponent={
            <PromptFooter
              comingFromAppointment
              close={closeMessagePrompt}
              confirmHandler={closeMessage}
              btnLabelLeft={t('common_buttons.ignore')}
              btnLabelRight={t('common_buttons.addIcd')}
            />
          }
          openModal={promptMessage.display}
          onClose={closeMessage}
          hideFlashMessage={HideFlashMessage}
        />
      )}
    </>
  );
};

AppointmentSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AppointmentSettings;
