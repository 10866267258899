import React from 'react';

import { ShowFlashMessage } from '../../../Global/Services';
import { useDownloadDesktopAppButtonService } from './DownloadDesktopAppButton.service';

import { FlashMessageTypes } from '../../../../caro-ui-commonfiles/types/components';
import Button from '../../../../caro-ui-commonfiles/components/Button/Button';
import {
  ButtonSize,
  ButtonType,
} from '../../../../caro-ui-commonfiles/utils/button';

import logo from '../../../img/oncare-download-desktop-app.png';

import styles from './DownloadDesktopAppBanner.module.scss';

function DownloadDesktopAppBanner() {
  const { link, isOnDesktop, t, checkFileExistance } =
    useDownloadDesktopAppButtonService();

  if (isOnDesktop) return null;

  const instanceLink = window.location.origin + link;
  const handleDownload = () => {
    if (checkFileExistance(link)) {
      window.open(instanceLink, '_blank');
    } else {
      ShowFlashMessage({
        type: FlashMessageTypes.Error,
        messageKey: 'DownloadError.fileNotFound',
      });
    }
  };

  return (
    <Button
      type={ButtonType.BUTTON}
      onClick={handleDownload}
      fullWidth
      size={ButtonSize.MD}
      id="download-desktop-app"
      className={styles.banner}
    >
      <div className={styles.banner__text}>
        <div className={styles.banner__title}>
          {t('downloadDesktopApp.title')}
        </div>
        <div className={styles.banner__subtitle}>
          {t('downloadDesktopApp.description')}
          <span className={styles.banner__subtitle__link}>
            {t('downloadDesktopApp.link')}
          </span>
        </div>
      </div>
      <img className={styles.banner__logo} src={logo} />
    </Button>
  );
}

export default DownloadDesktopAppBanner;
