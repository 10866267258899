import i18next from 'i18next';
import React from 'react';
import Flex from '../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import Icon from '../../../../../../caro-ui-commonfiles/components/Icon/Icon';
import { Greys } from '../../../../../../caro-ui-commonfiles/utils/colors';
import { usePatientFilterContext } from '../../PatientFilterContext/PatientFilterContext';
import PatientFilterSelectLabel from '../PatientFilterSelectLabel/PatientFilterSelectLabel';
import PatientFilterSelectOverflow from '../PatientFilterSelectOverflow/PatientFilterSelectOverflow';
import { PatientFilterSelectContentProps } from './PatientFilterSelect.type';

function PatientFilterSelectContent({ type }: PatientFilterSelectContentProps) {
  const {
    appliedFilters: { [type]: filters },
  } = usePatientFilterContext();
  const filterArr = filters.slice(0, 3);
  const overflow = filters.slice(3);
  return (
    <Flex className="filters__dropdown-inner">
      {!filters.length ? (
        <div className="filters__dropdown-inner-text">
          {i18next.t(`patients_complexsearch.${type}Criteria`).toString()}
        </div>
      ) : (
        <>
          {filterArr.map((filter, index) => (
            <PatientFilterSelectLabel
              key={index}
              filter={filter}
              position={index}
              type={type}
            />
          ))}
          {overflow.length ? (
            <PatientFilterSelectOverflow filters={overflow} type={type} />
          ) : null}
        </>
      )}
      <div className="filters__dropdown-inner-icon">
        <Icon name="arrowDown" fill={Greys.DARK_GREY} size={12} />
      </div>
    </Flex>
  );
}

export default PatientFilterSelectContent;
