import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@ui-common-files/components';

export default function BloodAndBMIRowComponentTitle({ label }) {
  const { t } = useTranslation();
  return (
    <Box otherStyles={{ height: 36, lineHeight: '36px', marginBottom: 20 }}>
      {t(label)}
    </Box>
  );
}
