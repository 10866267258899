import { useContext, useEffect, useRef, useMemo, useCallback } from 'react';
import {
  convertDateStringToMsTimestamp,
  isDateInFuture,
  isToday,
} from '@global/Date';
import { PatientOverviewContext } from '../../../../../PatientOverview/PatientOverview';
import { CustomBarProps } from '../../../Overview.type';
import { PADDING } from '../../OverviewMedicationChart.constant';
import {
  calculateBarWidth,
  calculateXPosition,
  checkOverflow,
} from '../../OverviewMedicationChart.utils';

const useOverviewMedicationCustomBarService = ({
  payload,
  x,
  width,
  background,
}: CustomBarProps) => {
  const foreignObjectRef = useRef(null);
  const { devicesQuery } = useContext(PatientOverviewContext);
  const { start, end } = devicesQuery.date;

  const startTimestamp = useMemo(() => convertDateStringToMsTimestamp(start), [
    start,
  ]);
  const endTimestamp = useMemo(() => convertDateStringToMsTimestamp(end), [
    end,
  ]);
  const totalRange = useMemo(() => endTimestamp - startTimestamp, [
    startTimestamp,
    endTimestamp,
  ]);

  const barStartDate = useMemo(
    () => Math.max(payload.fromDate, startTimestamp),
    [payload.fromDate, startTimestamp]
  );

  const barEndDate = useMemo(() => {
    const isActiveMedicationPlan =
      !payload.isUnAssigned && payload.medicationPlanId !== null;

    const timestamp =
      isToday(payload.toDate) || isActiveMedicationPlan
        ? endTimestamp
        : payload.toDate;

    return isDateInFuture(timestamp) && isActiveMedicationPlan
      ? Math.max(payload.toDate, endTimestamp)
      : Math.min(payload.toDate, endTimestamp);
  }, [payload.toDate, endTimestamp]);

  const chartWidth = background.width || width;

  const xPosition = useMemo(
    () =>
      calculateXPosition({
        barStartDate,
        chartWidth,
        totalRange,
        startTimestamp,
      }) + x,
    [barStartDate, chartWidth, totalRange, startTimestamp, x]
  );

  const barWidth = useMemo(
    () =>
      calculateBarWidth({
        barStartDate,
        barEndDate,
        chartWidth,
        totalRange,
      }),
    [barStartDate, barEndDate, chartWidth, totalRange]
  );

  const availableWidth = useMemo(() => barWidth - PADDING * 2, [barWidth]);

  const alignRight = useMemo(() => payload.fromDate < startTimestamp, [
    payload.fromDate,
    startTimestamp,
  ]);

  const checkTextOverflow = useCallback(() => {
    if (foreignObjectRef.current) {
      const isNameTextTruncated = checkOverflow(
        foreignObjectRef.current.querySelector('.medication-bar-name-text')
      );
      const isRecurrenceTextTruncated = checkOverflow(
        foreignObjectRef.current.querySelector(
          '.medication-bar-recurrence-text'
        )
      );

      const isTrimmed = isNameTextTruncated || isRecurrenceTextTruncated;
      payload.isTrimmed = isTrimmed;
    }
  }, [payload]);

  useEffect(() => {
    checkTextOverflow();
  }, [checkTextOverflow, start, end, payload]);

  return {
    xPosition,
    barWidth,
    availableWidth,
    alignRight,
    foreignObjectRef,
  };
};

export default useOverviewMedicationCustomBarService;
