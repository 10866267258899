import React, { useEffect, useMemo, useState } from 'react';

import { Action, Tag } from '@ui-common-files/components';

import { ActionColors } from '@ui-common-files/utils';

import { getFireBaseInfos } from './FirebaseInformations.api';
import serviceConfig from '../../../../serviceConfig.json';
import { isDataLink } from '../../../Utils/Chat/chatUtility';

const getFirebaseInformations = () => {
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const prepareDatatableRows = data => {
      const retrievedData = [...data];
      const rows = [];

      retrievedData.forEach(info => {
        let key = info[0].charAt(0).toUpperCase() + info[0].substring(1);
        rows.push({
          key: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
          value: info[1],
        });
      });
      return rows;
    };

    const fetchData = async () => {
      const url = serviceConfig.brokerService.getFireBaseInfos.uri;
      const response = await getFireBaseInfos(url);
      if (response?.data) {
        setFetchedData(prepareDatatableRows(Object.entries(response.data)));
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: () => {
          return;
        },
        accessor: 'key',
        headerClassName: '',
        sortable: true,
        filterable: false,
      },
      {
        Header: () => {
          return '';
        },
        accessor: 'value',
        headerClassName: '',
        sortable: true,
        filterable: false,
        Cell: ({ row }) => {
          const value = row.value;
          if (isDataLink(row.value))
            return (
              <Action href={value} target="_blank">
                {value}
              </Action>
            );
          if (typeof value === 'boolean')
            return (
              <Tag
                text={value ? 'OK' : 'Not Available'}
                hasMark
                fill={value ? ActionColors.SUCCESS : ActionColors.ERROR}
              />
            );
          return value;
        },
      },
    ];
  }, []);
  return {
    fetchedData,
    loading,
    columns,
  };
};
export default getFirebaseInformations;
