import React from 'react';

import { WearablesDataTypes } from '@ui-common-files/utils';
import { ChartDataKeys } from '@ui-common-files/utils/componentTypes';

const CustomDot = ({
  cx,
  cy,
  fill,
  stroke,
  payload,
  dataKey,
  hover = false,
}) => {
  if (cx === null || cy === null) {
    return null;
  }
  const size = hover ? 6 : 4;
  const opacity = hover ? 0.25 : 1;
  let fillColor = stroke;
  if (
    dataKey === ChartDataKeys.LINE1 ||
    (dataKey === ChartDataKeys.LINE2 &&
      payload?.wearableDataTypeId1 === WearablesDataTypes.BLOOD_PREASURE)
  ) {
    if (payload.overAllColorPoint1) {
      fillColor = payload.overAllColorPoint1;
    }
    return (
      <circle
        cx={cx}
        cy={cy}
        r={size}
        style={{ fill: hover ? fill : fillColor, fillOpacity: opacity }}
      />
    );
  }

  if (
    (dataKey === ChartDataKeys.LINE2 &&
      payload?.wearableDataTypeId1 !== WearablesDataTypes.BLOOD_PREASURE) ||
    dataKey === ChartDataKeys.LINE3
  ) {
    if (payload.overAllColorPoint2 || payload.overAllColorPoint3) {
      fillColor = payload.overAllColorPoint2
        ? payload.overAllColorPoint2
        : payload.overAllColorPoint3;
    }
    return (
      <rect
        x={cx - size}
        y={cy - size}
        width={size * 2}
        height={size * 2}
        style={{ fill: hover ? fill : fillColor, fillOpacity: opacity }}
      />
    );
  }
};

export default CustomDot;
