import patientsActions from '../src/Utils/ReduxUtilities/Actions/patients.json';

const patientsState = {
  addPatientResponse: null,
  createPatientResponse: null,
  editPatientResponse: null,
  updatePatientResponse: null,
  getPatientsByQueryResponse: null,
  patientRequestError: null,
  deletePatientResponse: null,
  showPatientResponse: null,
  abortedRequest: false,
};

const patientState = (state = patientsState, actions) => {
  switch (actions.type) {
    case patientsActions.ADD_PATIENT_RESPONSE:
      return {
        ...state,
        addPatientResponse: actions.addPatientResponse,
      };
    case patientsActions.CREATE_PATIENT_RESPONSE:
      return {
        ...state,
        createPatientResponse: actions.createPatientResponse,
      };
    case patientsActions.EDIT_PATIENT_RESPONSE:
      return {
        ...state,
        editPatientResponse: actions.editPatientResponse,
      };
    case patientsActions.UPDATE_PATIENT_RESPONSE:
      return {
        ...state,
        updatePatientResponse: actions.updatePatientResponse,
      };
    case patientsActions.GET_PATIENTS_BY_QUERY_RESPONSE:
      return {
        ...state,
        getPatientsByQueryResponse: actions.getPatientsByQueryResponse,
      };
    case patientsActions.GET_PATIENTS_BY_CUSTOM_FILTER_RESPONSE:
      return {
        ...state,
        getPatientsByQueryResponse: actions.getPatientsByQueryResponse,
      };
    case patientsActions.PATIENT_ERROR:
      return {
        ...state,
        getPatientsByQueryResponse: actions.getPatientsByQueryResponse
          ? actions.getPatientsByQueryResponse
          : null,
        patientRequestError: actions.patientRequestError,
      };
    case patientsActions.DELETE_PATIENT_RESPONSE:
      return {
        ...state,
        deletePatientResponse: actions.deletePatientResponse,
      };
    case patientsActions.SHOW_PATIENT_RESPONSE:
      return {
        ...state,
        showPatientResponse: actions.showPatientResponse,
      };
    case patientsActions.RESET_PATIENT_DATA:
      return {
        ...state,
        addPatientResponse: null,
        createPatientResponse: null,
        editPatientResponse: null,
        updatePatientResponse: null,
        deletePatientResponse: null,
        patientRequestError: null,
        showPatientResponse: null,
        abortedRequest: false,
      };
    case patientsActions.ABORTED_REQUEST:
      return {
        ...state,
        abortedRequest: actions.abortedRequest,
      };
    default:
      return state;
  }
};

export default patientState;
