import React, { useEffect, useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { HideFlashMessage } from '@global/Services';

import Modal from '../../../../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import Box from '../../../../../../../caro-ui-commonfiles/components/Box/Box';
import Flex from '../../../../../../../caro-ui-commonfiles/components/Flex/Flex';
import IconButton from '../../../../../../../caro-ui-commonfiles/components/IconButton/IconButton';
import { ActionColors } from '../../../../../../../caro-ui-commonfiles/utils/colors';
import ASSET_TYPES from '../../../../../../Utils/Asset/assetTypes';
import tabPosition from '../../../../../../Utils/Administration/tabPosition';
import getAssetIcon from '../../../../../..//Utils/Asset/getAssetIcon';
import Actions from '../../../../../../Utils/actions';
import ShowAssetModalContent from '../../../../../Files/ShowAssetModalContent';
import {
  showAssetRequest,
  getAssetAssociations,
  convertLinkToBlob,
  getAssetTypeId,
} from '../../../../../../Utils/Asset/assetHelper';
import { ViewCareplanModalFooter } from '../../../../../Careplans/ViewCareplan';

export default function uploadedFileViewer({
  selectedFile,
  setSelectedFile,
}): JSX.Element {
  const [assetAssociations, setAssetAssociations] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [flashMessage, setFlashMessage] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [blobURL, setBlobURL] = useState(null);
  const [action, setAction] = useState('');
  const [assetDataToShow, setAssetDataToShow] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const { t } = useTranslation();
  const changeTabPosition = () => {
    return tabPosition.FIRST;
  };
  const getActiveTab = (_state, data) => data;

  const [activeTab, setActiveTab] = useReducer(
    getActiveTab,
    changeTabPosition()
  );

  const clearSelectedFileAndMedia = () => {
    setSelectedFile(null);
  };

  useEffect(() => {
    if (selectedFile && selectedFile.assetId) {
      convertLinkToBlob(selectedFile.thumbnailURL)
        .then(convertedUrl => {
          setBlobURL(convertedUrl);
        })
        .catch(error => {
          setBlobURL(null);
          throw new Error(error);
        });
    }
  }, [selectedFile]);

  const renderSelectedFile = () => {
    return (
      <>
        <Box margin="0px 0px 0px 0px">
          <span className="attached-file__txt">
            {t('asset_view.attachedFile')}
          </span>
        </Box>
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={{ marginTop: '20px' }}
        >
          <Box margin="0px 0px 0px -3px">
            {selectedFile && blobURL ? (
              <Box className="img-object__fit">
                <img src={blobURL} width="24px" height="24px" />
              </Box>
            ) : (
              getAssetIcon(selectedFile.assetTypeId, 24)
            )}
          </Box>
          <div
            className="attached-file__div"
            style={{
              marginLeft:
                selectedFile.assetTypeId === ASSET_TYPES.IMAGE ? '16px' : null,
            }}
          >
            <span className="attached-file__name">{selectedFile.name}</span>
          </div>
          <div className="attached-file__buttons">
            <IconButton
              type="button"
              name="view"
              size={29}
              onClick={() => {
                setModalTitle(
                  `${t('asset_view.showFile')}: ${selectedFile.name}`
                );
                setAction(Actions.Show);
                Promise.all([
                  showAssetRequest(
                    selectedFile.assetId,
                    setAssetDataToShow,
                    setOpenModal,
                    setFlashMessage
                  ),
                  getAssetAssociations(
                    selectedFile.assetId,
                    setAssetAssociations
                  ),
                ]);
              }}
              tooltipText={t('actions.show')}
            />
            <IconButton
              type="button"
              name="remove"
              size={22}
              fill={ActionColors.DELETE}
              onClick={() => {
                clearSelectedFileAndMedia();
              }}
              tooltipText={t('toolTipsText.remove')}
            />
          </div>
        </Flex>
      </>
    );
  };
  const renderMediaUrl = () => {
    const extension = fileName
      ? fileName.substring(fileName.lastIndexOf('.') + 1)
      : '';
    const assetTypeId = getAssetTypeId(extension && extension.toUpperCase());
    return (
      <>
        <Box margin="0px 0px 0px 0px">
          <span className="attached-file__txt">
            {t('asset_view.attachedFile')}
          </span>
        </Box>
        <Flex
          flexDirection="row"
          alignItems="center"
          otherStyles={{ marginTop: '20px' }}
        >
          <Box margin="0px 0px 0px -3px">
            {assetTypeId === ASSET_TYPES.IMAGE ? (
              <Box className="img-object__fit">
                <img src={fileUrl} width="24px" height="24px" />
              </Box>
            ) : (
              getAssetIcon(assetTypeId, 24)
            )}
          </Box>
          <div
            className="attached-file__div"
            style={{
              marginLeft: '16px',
            }}
          >
            <span className="attached-file__name">{fileName}</span>
          </div>
          <div className="attached-file__buttons">
            <IconButton
              type="button"
              name="view"
              size={29}
              onClick={() => {
                setModalTitle(`${t('asset_view.showFile')}: ${fileName}`);
                setAction(Actions.Show);
                setOpenModal(true);
              }}
              tooltipText={t('actions.show')}
            />
            <IconButton
              type="button"
              name="remove"
              size={22}
              fill={ActionColors.DELETE}
              onClick={() => {
                clearSelectedFileAndMedia();
              }}
              tooltipText={t('toolTipsText.remove')}
            />
          </div>
        </Flex>
      </>
    );
  };

  const renderModalFooter = () => {
    return <ViewCareplanModalFooter close={closeModal} />;
  };
  const closeModal = () => {
    setOpenModal(false);
    setActiveTab(1);
  };
  const showFile = () => {
    const extension = fileName
      ? fileName.substring(fileName.lastIndexOf('.') + 1)
      : '';
    const assetTypeId = getAssetTypeId(extension && extension.toUpperCase());

    switch (fileUrl) {
      case assetTypeId === ASSET_TYPES.VIDEO && fileUrl:
        return (
          <video
            playsinline
            controls="true"
            className="uploadedFile-frame"
            frameBorder="0"
            src={fileUrl}
            type="video/mp4"
          ></video>
        );
      case assetTypeId === ASSET_TYPES.IMAGE && fileUrl:
        return <img src={fileUrl} className="uploadedFile-frame" />;
      case assetTypeId === ASSET_TYPES.DOCUMENT && fileUrl:
        return <iframe src={fileUrl} className="uploadedFile-frame" />;
      case assetTypeId === ASSET_TYPES.AUDIO && fileUrl:
        return (
          <div
            className="audio-style"
            style={{ width: '98%', marginLeft: '8px' }}
          >
            <audio
              playsinline
              controls="true"
              src={fileUrl}
              type="audio/mp3"
              width="90%"
              className="uploadedFile-frame"
            />
          </div>
        );
      default:
        return <div className="default-preview-icon__div"></div>;
    }
  };
  const renderModalContent = () => {
    return (
      <ShowAssetModalContent
        assetDataToShow={assetDataToShow}
        shouldShowSites={true}
        assetAssociations={assetAssociations}
      />
    );
  };
  return (
    <>
      <Modal
        width="103%"
        title={modalTitle}
        contentComponent={
          selectedFile && selectedFile.assetId
            ? renderModalContent()
            : showFile()
        }
        footerComponent={renderModalFooter()}
        openModal={openModal}
        onClose={closeModal}
        hideFlashMessage={HideFlashMessage}
      />
      {selectedFile && selectedFile.assetTypeId
        ? renderSelectedFile()
        : renderMediaUrl()}
    </>
  );
}
