/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';

import { MaxCharLimits } from '@ui-common-files/utils';
import { HideFlashMessage } from '@global/Services';

import DataTable from '../../../../caro-ui-commonfiles/components/DataTable/DataTable';
import Input from '../../../../caro-ui-commonfiles/components/TextInput/TextInput';
import inputField from '../../../../caro-ui-commonfiles/utils/inputField';
import Modal from '../../../../caro-ui-commonfiles/components/Modals/ModalLayout';
import ModalFooter from '../../common/Layout/Modal/ModalFooter';
import { EditSite } from '../../Sites/Edit';

import useSites from './Sites.service';

const Sites = props => {
  const {
    fetchedData,
    loading,
    query,
    setQuery,
    sort,
    setPageState,
    currentPage,
    setCurrentPage,
    recordsTotal,
    name,
    logo,
    id,
    title,
    address,
    email,
    serviceProviderId,
    onChangeServiceProviderIdText,
    phone,
    collectionId,
    displayModal,
    privacyPolicyFile,
    setPrivacyPolicyFile,
    privacyPolicyData,
    setPrivacyPolicyData,
    closeModal,
    onChangeNameText,
    onChangeEmailText,
    onChangeAddressText,
    onChangePhoneText,
    items,
    tableProps,
    columns,
  } = useSites(props);
  return (
    <>
      <DataTable
        data={fetchedData}
        columns={columns}
        pageSize
        pageSizeOptions={items}
        pagination
        isLoading={loading}
        tableProps={tableProps}
        onTableChange={(index, size) => {
          setQuery({
            value: query.value,
            start:
              fetchedData && index * size > recordsTotal ? 0 : index * size,
            length: size,
            dir: sort.dir,
            column: sort.column,
          });
          setCurrentPage(
            fetchedData && index * size > recordsTotal ? 0 : index
          );
          setPageState(size);
        }}
        page={currentPage}
        pages={Math.ceil(recordsTotal / query.length)}
        totalRecord={recordsTotal}
      />
      <div style={{ width: 250, position: 'absolute', top: 0, right: 0 }}>
        <Input
          id="specialId2"
          placeholder={I18n.t('common_labels.label_search')}
          maxChars={MaxCharLimits.searchInputs.searchKeyword}
          variant={inputField.variant.CHAR_COUNT}
          value={query.value}
          handleOnChange={e => {
            setQuery({
              value: e.target.value,
              start: 0,
              length: query.length,
              dir: sort.dir,
              column: sort.column,
            });
            setCurrentPage(0);
            setPageState(query.length);
          }}
        />
      </div>

      <Modal
        title={`${I18n.t('admin_view.label_editSite')}: ${title}`}
        contentComponent={
          <EditSite
            reloadTable={() => props.setReloadTable(!props.reloadTable)}
            showFlashMessage={props.showFlashMessage}
            closeModal={closeModal}
            childName={name}
            childAddress={address}
            childEmail={email}
            childPhone={phone}
            childServiceProviderId={serviceProviderId}
            id={id}
            logo={logo}
            collectionId={collectionId}
            childOnChangeNameText={onChangeNameText}
            childOnChangeAddressText={onChangeAddressText}
            childOnChangeEmailText={onChangeEmailText}
            childOnChangePhoneText={onChangePhoneText}
            privacyPolicyFile={privacyPolicyFile}
            setPrivacyPolicyFile={setPrivacyPolicyFile}
            privacyPolicyData={privacyPolicyData}
            setPrivacyPolicyData={setPrivacyPolicyData}
            childOnChangeServiceProviderIdText={onChangeServiceProviderIdText}
          />
        }
        footerComponent={
          <ModalFooter
            close={closeModal}
            labelCTA={I18n.t('common_buttons.update')}
            form="edit-site-form"
          />
        }
        openModal={displayModal}
        onClose={closeModal}
        hideFlashMessage={HideFlashMessage}
      />
    </>
  );
};
export default withTranslation()(Sites);
