import React, { PropsWithChildren } from 'react';
import { Box } from '@ui-common-files/components';

type OtherPagesProps = {
  headerComponent: React.ReactElement;
  footerComponent: React.ReactElement;
};

const MedicationPlanReportPage: React.FC<
  PropsWithChildren<OtherPagesProps>
> = ({ headerComponent, footerComponent, children }): React.ReactElement => {
  return (
    <Box className="sub-medication-plan-page">
      {headerComponent}
      <Box className="page-content">{children}</Box>
      {footerComponent}
    </Box>
  );
};

export default MedicationPlanReportPage;
