import { ScoringTypes } from '@type/Careplan';

export const STORE_NAME: string = 'PATH_WAY_BUILDER';
export const MAX_INCOMING_DEPTH: number = 3;
export const NUMBER_OF_ALLOWED_CONNECTION: number = 1;
export const PAGE_SIZE: number = 20;
export const NODE_X_DEDUCTION: number = 100;
export const NODE_Y_DEDUCTION: number = 50;
export const NODE_WIDTH: number = 160;
export const NODE_HEIGHT: number = 200;
export const SIDEBAR_CONTENT_HEIGHT: number = 512;
export const NODE_SORT_ORDER: ScoringTypes[] = [
  ScoringTypes.Well,
  ScoringTypes.Fair,
  ScoringTypes.Poor,
];
