import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import I18n from 'i18next';
import moment from 'moment';
import ModalContent from '../../../caro-ui-commonfiles/components/Modals/ModalContent';
import ApptInformationOverview from './ApptInformationOverview';
import serviceConfig from '../../../serviceConfig.json';
import { PatientOverviewContext } from '../PatientOverview/PatientOverview';

const ApptShowModalContent = ({ data }) => {
  const { setFlashMessage, closeModal } = useContext(PatientOverviewContext);
  const startDateTime = moment(data.reminderElement.startsAt);
  const endDateTime = moment(data.reminderElement.endsAt);
  const appointmentType = data.appointmentType;
  const appointmentTypeOrder =  appointmentType.appointmentTypeOrder;
  const [icds] = useState(data.icdRecords ? data.icdRecords : []);
  const [values, setValues] = useState({
    name: data.name,
    appointmentType: appointmentTypeOrder
      ? {value: appointmentTypeOrder, label: I18n.t(appointmentType.name)}
      : '',
    startDate: startDateTime,
    startTime: {
      hour: startDateTime.get('hour'),
      minute: startDateTime.get('minute'),
    },
    endDate: endDateTime,
    endTime: {
      hour: endDateTime.get('hour'),
      minute: endDateTime.get('minute'),
    },
    recurrenceText: '',
    ownershipLogs: [],
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          serviceConfig.brokerService.showAppointment.uri,
          {
            params: {
              reminderId: data.reminderElement.reminderId,
            },
          }
        );
        const responseData = response.data;
        if (responseData) {
          const { appointment } = response.data;
          setValues({
            ...values,
            recurrenceText: responseData.recurrenceText,
            ownershipLogs: responseData.ownershipLogs,
            attendees: responseData.attendees,
            notes: appointment.detail,
            location: appointment.place,
          });
        }
      } catch (error) {
        closeModal();
        setFlashMessage({
          type: error.response.data.type,
          content: error.response.data.content,
        });
      }
    };
    getData();
  }, []);

  return (
    <ModalContent>
      <ApptInformationOverview
        values={values}
        icds={icds}
        showAssignee={false}
      />
    </ModalContent>
  );
};

ApptShowModalContent.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ])
  ).isRequired,
};

export default ApptShowModalContent;
