import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
} from 'react';
import I18n from 'i18next';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Box,
  ComplexSearch,
  Flex,
  TextInput,
} from '@ui-common-files/components';
import UsFilterTopWarning from '../../../../caro-ui-commonfiles/components/UsFilterWarnings/UsFilterTopWarning';
import { FlashMessageTypes } from '../../../../caro-ui-commonfiles/types/components';
import ModalSection from '../../common/Layout/Modal/ModalSection';
import { CreateAppointmentContext } from './CreateAppointmentModal';
import ListPatients from '../../Datatables/PatientsAppointment';
import { inputField, MaxCharLimits } from '@ui-common-files/utils';

import serviceConfig from '../../../../serviceConfig.json';
import { useDelaySearch } from '@global/Hooks';
import { ApiClient } from '@global/Services';
import { deepEqual } from '@global/Objects';

import complexSearchData from '../../../Utils/complexSearchData';

const ListPatientsAppointment = ({ onSelect }) => {
  const { user } = useSelector(state => state.session);
  const services = useSelector(state => state.services);
  const { allFilters, savedFilter } = useSelector(
    state => state.patientFilters
  );

  const customFilterData = complexSearchData(services);
  const { setFlashMessage } = useContext(CreateAppointmentContext);

  const retrievedSelectedRule = savedFilter.rule;
  const defaultFilter = savedFilter.index;

  /* **** States **** */

  const [forceRender, setForceRender] = useState(true);
  const [selectedRule, setSelectedRule] = useState(retrievedSelectedRule);
  const [auxState, setAuxState] = useState(null);
  const [patients, setPatients] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [doFetch, setDoFetch] = useState(false);

  const [filterKey, setFilterKey] = useState({
    id: allFilters[defaultFilter].filterId,
    name: allFilters[defaultFilter].key,
  });
  /* **** Life Cicle Controls **** */
  const [showUsWarningTopPage, setShowUsWarningTopPage] = useState(
    allFilters[defaultFilter].filterId != 1
  );
  const actionTypes = {
    ON_SEARCH: 'on_search',
    ON_TABLE_CHANGE: 'on_table_change',
    ON_FILTER_CHANGE: 'on_filter_change',
  };

  const initialState = {
    currPage: 0,
    start: 0,
    limit: 10,
    dir: 'asc',
    column: 'medicalRecord',
    search: '',
    sqlQuery: null,
  };

  const reducer = (prevState, action) => {
    switch (action.type) {
      case actionTypes.ON_TABLE_CHANGE: {
        const nextState = {
          ...prevState,
          start: action.payload.page * action.payload.pageSize,
          limit: action.payload.pageSize,
          dir: action.payload.sort.dir,
          column: action.payload.sort.column,
          currPage: action.payload.page,
        };

        return deepEqual(nextState, prevState) ? prevState : nextState;
      }
      case actionTypes.ON_FILTER_CHANGE: {
        const nextState = {
          ...prevState,
          start: 0,
          currPage: 0,
          sqlQuery: action.payload,
        };

        return deepEqual(nextState, prevState) ? prevState : nextState;
      }
      case actionTypes.ON_SEARCH: {
        const nextState = {
          ...prevState,
          start: 0,
          currPage: 0,
          search: action.payload,
        };

        return deepEqual(nextState, prevState) ? prevState : nextState;
      }
      default:
        return prevState;
    }
  };

  const [actionState, dispatch] = useReducer(reducer, initialState);
  const abortController = new AbortController();

  /* -------------- Load  Patients filters and services ---------- */
  const doPatientSearch = useCallback(() => {
    if (searchTerm.length > 1 || searchTerm.length === 0) {
      dispatch({
        type: actionTypes.ON_SEARCH,
        payload: searchTerm,
      });
    }
  }, [searchTerm]);

  useDelaySearch({ input: searchTerm, callback: doPatientSearch });

  useEffect(() => {
    setLoading(true);
    const getUserData = async () =>
      axios
        .get(serviceConfig.patientService.index.uri)
        .then(response => {
          if (response.data.permissionDeniedMessage) {
            setFlashMessage({
              display: true,
              content: I18n.t(response.data.permissionDeniedMessage),
              type: FlashMessageTypes.Error,
            });
          } else if (response.data.type && response.data.type === 'ERROR') {
            setFlashMessage({
              display: true,
              content: I18n.t(response.data.content),
              type: FlashMessageTypes.Error,
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setPatients(null);
          setFlashMessage({
            display: true,
            content: I18n.t('common_labels.patient_service_error'),
            type: FlashMessageTypes.Error,
          });
        });

    getUserData(user);
  }, []);

  useEffect(() => {
    if (
      defaultFilter >= 0 &&
      allFilters.length > 0 &&
      allFilters[defaultFilter].rule
    ) {
      setSelectedRule(
        typeof allFilters[defaultFilter].rule == 'string'
          ? JSON.parse(allFilters[defaultFilter].rule)
          : allFilters[defaultFilter].rule
      );
      setFilterKey({
        id: allFilters[defaultFilter].filterId,
        name: allFilters[defaultFilter].key,
      });
    }
  }, [defaultFilter]);

  useEffect(() => {
    const handleChange = rule => {
      if (rule) setSelectedRule(rule);
      setForceRender(true);
    };
    handleChange(auxState);
  }, [forceRender]);

  useEffect(() => {
    const getPatientsByQuery = async () => {
      try {
        if (actionState.sqlQuery && actionState.sqlQuery.sql) {
          setLoading(true);
          ApiClient.GET({
            url: serviceConfig.brokerService.getPatientsByQuery.uri,
            signal: abortController.signal,
            payload: {
              sql: actionState.sqlQuery.sql,
              params: JSON.stringify(actionState.sqlQuery.params),
              getRule: JSON.stringify(selectedRule),
              filterKey,
              query: JSON.stringify({
                start: actionState.start,
                limit: actionState.limit,
                dir: actionState.dir,
                column: actionState.column,
                search: actionState.search,
              }),
            },
          }).then(filteredPatients => {
            setPatients(filteredPatients.data);
            setLoading(false);
            setDoFetch(false);
          });
        }
      } catch (error) {
        setLoading(false);
        setPatients(null);
        setDoFetch(false);
        if (error.response && error.response.data) {
          setDisplayFlashMsg({
            display: true,
            content: I18n.t(error.response.data.content),
            type: error.response.data.type,
          });
        }
      }
    };
    getPatientsByQuery();
  }, [actionState, doFetch]);

  useEffect(() => {
    return () => abortController.abort();
  }, []);

  function onSearchTermChange(e) {
    setSearchTerm(e.target.value);
  }

  /** **** Events Handlers ***** */

  const tableHandler = (page, pageSize, sort) => {
    dispatch({
      type: actionTypes.ON_TABLE_CHANGE,
      payload: {
        page,
        pageSize,
        sort,
      },
    });
  };

  /** **** */
  return (
    <ModalSection hasPadding>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex
          flexDirection="row"
          justifyContent="flex-start"
          otherStyles={{ flex: 1, marginRight: '20px' }}
        >
          {allFilters.length && defaultFilter > -1 ? (
            <UsFilterTopWarning
              message={I18n.t(
                'patients_view.us-market-warning__patient-index-top'
              )}
              items={allFilters}
              showUsWarningTopPage={showUsWarningTopPage}
              active={defaultFilter}
              onClick={(e, item) => {
                setFilterKey({
                  name: item.key,
                  id: item.filterId,
                });
                setAuxState(
                  typeof item.rule == 'string'
                    ? JSON.parse(item.rule)
                    : item.rule
                );
                setForceRender(false);
                setShowUsWarningTopPage(item.filterId !== 1);
              }}
              showEditIcon={false}
            />
          ) : null}
          {forceRender && (
            <ComplexSearch
              filters={customFilterData.filterData}
              rules={selectedRule}
              getSql={value => {
                dispatch({
                  type: actionTypes.ON_FILTER_CHANGE,
                  payload: value,
                });
              }}
              hide
            />
          )}
        </Flex>

        <Box width="250px">
          <TextInput
            value={searchTerm}
            placeholder={I18n.t('common_labels.label_search')}
            variant={inputField.variant.CHAR_COUNT}
            maxChars={MaxCharLimits.searchInputs.searchKeyword}
            handleOnChange={onSearchTermChange}
          />
        </Box>
      </Flex>

      <ListPatients
        data={patients}
        loading={loading}
        onChange={tableHandler}
        page={actionState.currPage}
        onSelect={onSelect}
      />
    </ModalSection>
  );
};

ListPatientsAppointment.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default ListPatientsAppointment;
