import { sanitize } from 'dompurify';

const sanitizeInput = (input: string, allowSafeHTML: boolean = false): string => {
    if (allowSafeHTML) {
        return sanitize(input, {
            ALLOWED_TAGS: ['b', 'u', 'i', 'span', 'p', 'strong', 'em'],
            ALLOWED_ATTR: ['style', 'class']
                });
    } else {
        return sanitize(input, {
            USE_PROFILES: { html: false }
        });
    }  
}

export { sanitizeInput };
